import React, { useState } from 'react';
import Icons from '../../constants/icons';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { checkIsSp } from '../../utils/fnUtil';

const AddButton = ({
  type, //roundを指定するとボタンがまるくなる
  onClick, //ボタンをクリックしたときにfunctionが発火する（urlより優先される）
  url, //ボタンをクリックした時の遷移先を指定する「'/main/invoice/list'」
  displayText, // trueを指定すると「新規追加」吹き出しが表示される
  disabled, //trueを指定するとdisableになる
  style, //styleを指定することが可能になる
  changePlusColor, //trueにするとplusボタンのデフォルトカラーがホワイトになる
  spListViewMode,
  screenType,
}) => {
  const navigate = useNavigate();

  const [state, setState] = useState(false);

  const buttonClassName = () => {
    let buttonClassName;
    if (type && type === 'round') {
      buttonClassName = 'add_button--round';
    } else if (type) {
      buttonClassName = 'add_button ' + type;
    } else {
      buttonClassName = 'add_button';
    }

    if (screenType) {
      buttonClassName = buttonClassName + ' screen_register_button'
    }
    return buttonClassName;
  };

  return spListViewMode ? (
    <a
      className={'addBtn_listView_sp'}
      // disabled={disabled}
      onClick={() => {
        onClick ? onClick() : navigate(url);
      }}
      style={style}
    >
      <p>
        <span></span>
        新規追加
      </p>
    </a>
  ) : (
    <div className="add_button--area">
      <button
        className={buttonClassName()}
        disabled={disabled}
        onMouseEnter={() => setState(true)}
        onMouseLeave={() => setState(false)}
        onClick={() => {
          onClick ? onClick() : navigate(url);
        }}
        style={style}
      >
        {changePlusColor ? (
          <img
            src={
              disabled
                ? checkIsSp()? Icons.icon.plusNv:Icons.icon.plusWh
                : state
                ? Icons.icon.plusNv
                : checkIsSp()? Icons.icon.plusNv:Icons.icon.plusWh
            }
          />
        ) : (
          <img
            src={
              disabled
                ? checkIsSp()? Icons.icon.plusNv:Icons.icon.plusWh
                : state
                ? checkIsSp()? Icons.icon.plusNv:Icons.icon.plusWh
                : Icons.icon.plusNv
            }
          />
        )}
      </button>
      {displayText && (
        <p className="add_button--text" style={{ zIndex: 100000 }}>
          新規追加
        </p>
      )}
    </div>
  );
};

export default AddButton;
