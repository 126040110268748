import { memo } from 'react';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Modal } from 'antd';
import { get } from 'lodash';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import {
  fetchPartnerList,
  matterDateActions,
} from '../../../redux/slice/matterDate';
import SearchTitle from '../../searchTitle';
import Button from '../../button';
import Avatar from 'antd/lib/avatar/avatar';
import classNames from 'classnames';
import CustomFormikInput from '../../customInput/customFormikInput';
import './style.scss';

const PartnerSearchModal = memo(({ visible, onCancel, formik, index }) => {
  const { partnerList, allPartnerList, partnerSearchParams, staffAssignList } =
    useSelector(state => state.matterDate);

  const dispatch = useDispatch();

  const { shift: currentShift } = formik.values;

  const { currentAssign } = currentShift[index] ?? {};

  const partnerSearchFormik = useFormik({
    initialValues: partnerSearchParams,
    onSubmit: values =>
      dispatch(matterDateActions.savePartnerSearchParams(values)),
  });

  useUpdateEffect(() => {
    visible && dispatch(fetchPartnerList(partnerSearchParams));
  }, [partnerSearchParams, visible]);

  return (
    <Modal
      style={{
        minWidth: 600,
      }}
      visible={visible}
      closable={false}
      footer={null}
      centered
      onCancel={onCancel}
      destroyOnClose={true}
      className="business_partner_search_modal common_modal_base"
    >
      <div className="common_modal_title">協力会社検索</div>
      <div className="common_modal_content">
        <div className="common_search_area">
          <SearchTitle />
          <div className="search_form_base search_form_partner">
            <CustomFormikInput
              formik={partnerSearchFormik}
              inputType="select"
              inputName="partnerCompanyId"
              initialValue={allPartnerList.map(p => p.partnerId)}
              selectBoxOptions={allPartnerList.map(p => p.partnerName)}
              placeholder="協力会社名を選択してください"
            />
            <div className="search_form_base--action">
              <Button text="検索" onClick={partnerSearchFormik.handleSubmit} />
            </div>
          </div>
        </div>
        <div className="table_area">
          <Table
            pagination={false}
            columns={[
              {
                title: '協力会社名',
                width: 300,
                render: (_, record) => (
                  <div className="detail_info detail_company">
                    <div>
                      <Avatar src={record.image} size={60} shape="square" />
                    </div>
                    <div>
                      <span>{record.partnerName}</span>
                    </div>
                  </div>
                ),
              },
              {
                title: '',
                align: 'center',
                render: (_, record) => {
                  const assignDuplication = staffAssignList.some(
                    a =>
                      a.assignId === record.staffId && a.assignAccountFlag === 2
                  );
                  // const addFinish = currentShift
                  //   .flatMap(s => s.shiftAssign)
                  //   .some(
                  //     a =>
                  //       a.assignId === record.partnerId &&
                  //       a.assignAccountFlag === 2
                  //   );

                  return (
                    <Button
                      onClick={() => {
                        const origin = get(
                          formik.values,
                          `shift.[${index}].shiftAssign.[${currentAssign}]`
                        );

                        const updateShiftAssign = {
                          ...get(
                            formik.values,
                            `shift.[${index}].shiftAssign.[${currentAssign}]`
                          ),
                          ...record,
                          image: record.image,
                          assignAccountFlag: 2,
                          assignId: record.partnerId,
                          assignName: record.partnerName,
                        };

                        handlerFormikFieldChange(
                          formik,
                          `shift.[${index}].shiftAssign.[${currentAssign}]`,
                          updateShiftAssign
                        );
                        onCancel();
                      }}
                      // disabled={assignDuplication || addFinish}
                      disabled={assignDuplication}
                      className={classNames('add_btn', {
                        // add_btn_disabled: assignDuplication || addFinish,
                        add_btn_disabled: assignDuplication
                      })}
                      text={
                        assignDuplication
                          ? 'アサイン重複'
                          // : addFinish
                          // ? '追加済'
                          : '追加'
                      }
                    />
                  );
                },
              },
            ]}
            dataSource={partnerList}
          />
        </div>
      </div>
    </Modal>
  );
});

export default PartnerSearchModal;
