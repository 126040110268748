import { memo } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import BirthdaySelector from "../../../components/birthdaySelector";
import StaffRegisterInfos from "../inputInfos";
import prefectures from "../../../constants/prefectures";
import {handleRadio} from "./functions";
import React, { useEffect } from 'react';
import { fetchRequest, zipApi } from "../../../api/fetch";
import {apiUrl} from "../../../api/apiUrl";
import {checkIsSp, handlerFormikFieldChange} from "../../../utils/fnUtil";
import {useSelector} from "react-redux";
import moment from "moment";


const style = {
  areaStyle: { width: '65%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const BasicInfos = memo(({ formik, isStaff }) => {
  const {postCode1, postCode2, year, month, day, gender, weekMethodFlag, previousDayMethodFlag,
    gettingUpMethodFlag, departureMethodFlag, arriveMethodFlag, endMethodFlag, joinDate,retireDate,
    employmentType, classification, taxClassification} = formik.values;

    const {
    retireReasonList,
    recruitingMediaList,
    userList,
  } = useSelector((state) => state.staff);

  // 住所検索
  useEffect(() => {
    zipApi(formik, 'postCode1', 'postCode2', 'prefCode', 'cityName', 'streetName');
  }, [postCode1, postCode2]);
  //

  const insertBirthDay = (type, e) => {
    if (type === 'year') {
      formik.setFieldValue('year',e)
    } else if (type === 'month') {
      formik.setFieldValue('month',e)
    } else if (type === 'day') {
      formik.setFieldValue('day',e)
    }
  }
  return (
      <div className={'staff_register--basic_infos_area'}>
        <div className={'staff_register--birthday_input_area'}>
          <div className={'staff_register--label_area'}>
            <label>
              生年月日
            </label>
          </div>
          <BirthdaySelector
              initialDate={{year, month, day}}
              onChange={insertBirthDay}
              formik={formik}
              forSp={checkIsSp()}
          />
        </div>

        <div className={'staff_register--gender'}>
          <CustomFormikInput
              {...StaffRegisterInfos.basicInfos.inputs[0]}
              formik={formik}
              inputType={"radioButton"}
              key={StaffRegisterInfos.basicInfos.inputs[0].inputName}
              inputName={"gender"}
              radioButtonLabelAndChecked={[
                { label: '男性', checked: gender == 1 },
                { label: '女性', checked: gender == 2 }
              ]}
              initialValue={[1, 2]}
          />
        </div>

        <div className="custom_input--area" style={checkIsSp()?{ width: '100%', gap: '8px' ,flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-end'}:{ width: '100%', gap: '8px' }}>
          <CustomFormikInput
              inputType="input"
              label="郵便番号"
              inputName={'postCode1'}
              key={'postCode1'}
              formik={formik}
              placeholder={'123'}
              style={{
                labelStyle: {width: '232px'},
                areaStyle: { width: checkIsSp()? 'calc(50% - 10px)':'366px' },
              }}
              // onKeyUp={onSearchZip}
          />
          <div style={{color: '#242424',height:'32px'}}>-</div>
          <CustomFormikInput
              inputType="input"
              inputName={'postCode2'}
              key={'postCode2'}
              formik={formik}
              placeholder={'4567'}
              style={{
                areaStyle: { width: checkIsSp()? 'calc(50% - 10px)':'110px' },
              }}
              // onKeyUp={onSearchZip}
          />
        </div>
        <CustomFormikInput
            formik={formik}
            inputType={'select'}
            label={'都道府県'}
            labelRequired={false}
            inputName={'prefCode'}
            initialValue={prefectures.filter(p=>p.prefecturesCode!="").map(p=>p.prefecturesCode)}
            selectBoxOptions={prefectures.filter(p=>p.prefecturesCode!="").map(p=>p.label)}
            placeholder={'都道府県'}
            style={{
              areaStyle: { width: checkIsSp()? '100%':'18%' },
              labelStyle: { width: '110px' },
              inputStyle: { minWidth: checkIsSp()? '100%':'142px' }}}
            {...StaffRegisterInfos.basicInfos.inputs[3]}
        />
        <CustomFormikInput
            formik={formik}
            inputType={'input'}
            label={'市区町村'}
            labelRequired={false}
            inputName={'cityName'}
            initialValue={''}
            placeholder={'市区町村'}
            style={style}
            {...StaffRegisterInfos.basicInfos.inputs[4]}
        />
        <CustomFormikInput
            formik={formik}
            inputType={'input'}
            label={'丁目番地'}
            labelRequired={false}
            inputName={'streetName'}
            initialValue={''}
            placeholder={'丁目番地'}
            style={style}
            {...StaffRegisterInfos.basicInfos.inputs[5]}
        />
        <CustomFormikInput
            formik={formik}
            inputType={'input'}
            label={'それ以降'}
            labelRequired={false}
            inputName={'buildingName'}
            initialValue={''}
            placeholder={'マンション名・建物名'}
            style={style}
            {...StaffRegisterInfos.basicInfos.inputs[6]}
        />
        <div
            className={'tel_area_for_CFI'}
            style={checkIsSp()? {width:'100%',alignItems:'flex-end'}:{ width: '600px', }}
        >
          <CustomFormikInput
              inputType="input"
              label="TEL(スマートフォン)"
              inputName={'tel1'}
              key={'tel1'}
              formik={formik}
              placeholder={'03'}
              unit={'-'}
              style={{
                  labelStyle: {width: checkIsSp()? '300%':'232px'},
                  areaStyle : checkIsSp()?{width:'calc(100% + 3 * 18.86px)'}:{},
                inputStyle: { width: checkIsSp()? '100%':'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
          <CustomFormikInput
              inputType="input"
              inputName={'tel2'}
              key={'tel2'}
              formik={formik}
              placeholder={'0000'}
              unit={'-'}
              style={{
                areaStyle : checkIsSp()?{width:'calc(100% + 3 * 18.86px)'}:{},
                inputStyle: { width: checkIsSp()? '100%':'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
          <CustomFormikInput
              inputType="input"
              inputName={'tel3'}
              key={'tel3'}
              formik={formik}
              placeholder={'0000'}
              style={{
                inputStyle: { width: checkIsSp()? '100%':'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
        </div>
        <div
            className={'tel_area_for_CFI'}
            style={checkIsSp()? {width:'100%',alignItems:'flex-end'}:{ width: '600px', }}
        >
          <CustomFormikInput
              inputType="input"
              label="TEL(自宅)"
              inputName={'fax1'}
              key={'fax1'}
              formik={formik}
              placeholder={'03'}
              unit={'-'}
              style={{
                  labelStyle: {width: checkIsSp()? '100%':'232px'},
                  areaStyle : checkIsSp()?{width:'calc(100% + 3 * 18.86px)'}:{},
                inputStyle: { width: checkIsSp()? '100%':'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
          <CustomFormikInput
              inputType="input"
              inputName={'fax2'}
              key={'fax2'}
              formik={formik}
              placeholder={'0000'}
              unit={'-'}
              style={{
                areaStyle : checkIsSp()?{width:'calc(100% + 3 * 18.86px)'}:{},
                inputStyle: { width: checkIsSp()? '100%':'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
          <CustomFormikInput
              inputType="input"
              inputName={'fax3'}
              key={'fax3'}
              formik={formik}
              placeholder={'0000'}
              style={{
                inputStyle: { width: checkIsSp()? '100%':'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
        </div>
        <CustomFormikInput
            formik={formik}
            inputType={'input'}
            label={'LINE ID'}
            labelRequired={false}
            inputName={'lineId'}
            placeholder={'LINE IDを入力してください'}
            style={style}
            {...StaffRegisterInfos.basicInfos.inputs[9]}
        />
          {!isStaff && (
              <>
                  <CustomFormikInput
                      formik={formik}
                      inputType={'input'}
                      label={'稼働希望日数'}
                      labelRequired={false}
                      inputName={'hopeAssign'}
                      placeholder={'0'}
                      style={style}
                      {...StaffRegisterInfos.basicInfos.inputs[10]}
                  />
                  <div className={'staff_register--shift_settings'}>
                      <div className={'staff_register--shift_settings_label'} style={{fontSize: checkIsSp() ? '12px' : '14px'}}>
                          勤怠デフォルト設定
                      </div>
                      <div
                          className={'staff_register--shift_setting_items'}
                      >
                          <div className={'staff_register--shift_setting_items_unity'}>
                              <CustomFormikInput
                                  formik={formik}
                                  {...StaffRegisterInfos.basicInfos.inputs[11]}
                                  radioButtonLabelAndChecked={[
                                      { label: 'システム入力', checked: weekMethodFlag == 0 },
                                      { label: '電話', checked: weekMethodFlag == 1 },
                                      { label: '報告不要', checked: weekMethodFlag == 2 }
                                  ]}
                              />
                              <CustomFormikInput
                                  formik={formik}
                                  {...StaffRegisterInfos.basicInfos.inputs[12]}
                                  radioButtonLabelAndChecked={[
                                      { label: 'システム入力', checked: previousDayMethodFlag == 0 },
                                      { label: '電話', checked: previousDayMethodFlag == 1 },
                                      { label: '報告不要', checked: previousDayMethodFlag == 2 }
                                  ]}
                              />
                          </div>
                          <div className={'staff_register--shift_setting_items_unity'}>
                              <CustomFormikInput
                                  formik={formik}
                                  {...StaffRegisterInfos.basicInfos.inputs[13]}
                                  radioButtonLabelAndChecked={[
                                      { label: 'システム入力', checked: gettingUpMethodFlag == 0 },
                                      { label: '電話', checked: gettingUpMethodFlag == 1 },
                                      { label: '報告不要', checked: gettingUpMethodFlag == 2 }
                                  ]}
                              />
                              <CustomFormikInput
                                  formik={formik}
                                  {...StaffRegisterInfos.basicInfos.inputs[14]}
                                  radioButtonLabelAndChecked={[
                                      { label: 'システム入力', checked: departureMethodFlag == 0 },
                                      { label: '電話', checked: departureMethodFlag == 1 },
                                      { label: '報告不要', checked: departureMethodFlag == 2 }
                                  ]}
                              />
                          </div>
                          <div className={'staff_register--shift_setting_items_unity'}>
                              <CustomFormikInput
                                  formik={formik}
                                  {...StaffRegisterInfos.basicInfos.inputs[15]}
                                  radioButtonLabelAndChecked={[
                                      { label: 'システム入力', checked: arriveMethodFlag == 0 },
                                      { label: '電話', checked: arriveMethodFlag == 1 },
                                      { label: '報告不要', checked: arriveMethodFlag == 2 }
                                  ]}
                              />
                              <CustomFormikInput
                                  formik={formik}
                                  {...StaffRegisterInfos.basicInfos.inputs[16]}
                                  radioButtonLabelAndChecked={[
                                      { label: 'システム入力', checked: endMethodFlag == 0 },
                                      { label: '電話', checked: endMethodFlag == 1 },
                                      { label: '報告不要', checked: endMethodFlag == 2 }
                                  ]}
                              />
                          </div>
                      </div>
                  </div>
                  <div className={'staff_register--unity_area'}>
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.basicInfos.inputs[17]}
                          customDisabledDate={(current) => current && current > moment(retireDate, "YYYY/MM/DD")}
                      />
                      <div className={'staff_register--input_unity'}>
                          <CustomFormikInput
                              formik={formik}
                              {...StaffRegisterInfos.basicInfos.inputs[18]}
                              customDisabledDate={(current) => current && current < moment(joinDate, "YYYY/MM/DD")}
                              extraOnChange={d => !d && formik.setFieldValue('retireReason', null)}
                          />
                          <CustomFormikInput
                              formik={formik}
                              {...StaffRegisterInfos.basicInfos.inputs[19]}
                              initialValue={retireReasonList.map(r=>r.value)}
                              selectBoxOptions={retireReasonList.map(r=>r.label)}
                              disabled={!retireDate}
                          />
                      </div>
                  </div>

                  <CustomFormikInput
                      formik={formik}
                      {...StaffRegisterInfos.basicInfos.inputs[20]}
                      initialValue={userList.map(u=>u?.userId)}
                      selectBoxOptions={userList.map(u=>`${u?.userId}:${u?.name?.lastName ?? ""} ${u?.name?.firstName ?? ""}`)}
                      showSearch={true}
                  />
                  <CustomFormikInput
                      formik={formik}
                      {...StaffRegisterInfos.basicInfos.inputs[21]}
                      initialValue={userList.map(u=>u?.userId)}
                      selectBoxOptions={userList.map(u=>`${u?.userId}:${u?.name?.lastName ?? ""} ${u?.name?.firstName ?? ""}`)}
                      showSearch={true}
                  />

                  <div className={'staff_register--radio_unity'} onChange={handleRadio}>
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.basicInfos.inputs[22]}
                          radioButtonLabelAndChecked={[
                              { label: '業務委託', checked: employmentType == 0 },
                              { label: 'パート・アルバイト', checked: employmentType == 1 }
                          ]}
                      />
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.basicInfos.inputs[23]}
                          radioButtonLabelAndChecked={[
                              { label: '甲', checked: taxClassification == 0 },
                              { label: '乙', checked: taxClassification == 1 }
                          ]}
                      />
                  </div>

                  <CustomFormikInput
                      formik={formik}
                      {...StaffRegisterInfos.basicInfos.inputs[24]}
                  />

                  <div className={'staff_register--unity_area_daily_pay'}>
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.basicInfos.inputs[25]}
                      />
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.basicInfos.inputs[26]}
                      />
                  </div>
                  <CustomFormikInput
                      formik={formik}
                      {...StaffRegisterInfos.basicInfos.inputs[27]}
                      initialValue={recruitingMediaList.map(r=>r.value)}
                      selectBoxOptions={recruitingMediaList.map(r=>r.label)}
                  />
                  <CustomFormikInput
                      formik={formik}
                      {...StaffRegisterInfos.basicInfos.inputs[28]}
                  />
                  <CustomFormikInput
                      formik={formik}
                      {...StaffRegisterInfos.basicInfos.inputs[29]}
                  />
                  <CustomFormikInput
                      formik={formik}
                      {...StaffRegisterInfos.basicInfos.inputs[30]}
                  />
              </>
          )}
      </div>
  );
});
export default BasicInfos;
