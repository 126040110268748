import { memo } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import BirthdaySelector from "../../../components/birthdaySelector";
import StaffRegisterInfos from "../inputInfos";
import prefectures from "../../../constants/prefectures";
import {handleRadio} from "./functions";
import React, { useEffect } from 'react';
import {fetchRequest} from "../../../api/fetch";
import {apiUrl} from "../../../api/apiUrl";
import {handlerFormikFieldChange} from "../../../utils/fnUtil";
import {useSelector} from "react-redux";
import CustomInput from "../../../components/customInput";
import {bankingFetch, staffActions} from "../../../redux/slice/staff";

const SalaryInfos = memo(({ formik, dispatch, isStaff }) => {
  const {payMethod, bankAccountFlag, bankName, bankBranchName, classification} = formik.values;
  const { bankingList, branchesList } = useSelector((state) => state.staff);
  useEffect(()=>{
      if(bankName){
          handlerFormikFieldChange(formik,'bankCode',bankingList?.find(b=> b.label == bankName)?.value);
          dispatch(bankingFetch(bankingList?.find(b=> b.label == bankName)?.value));
      } else {
          dispatch(staffActions.bankReset());
      }
  },[bankName])
    useEffect(()=>{
        if(bankBranchName && branchesList?.length > 0){
            handlerFormikFieldChange(formik,'bankBranchCode',branchesList?.find(b=> b?.label == bankBranchName)?.value ?? '');
        }
    },[bankBranchName]);
    return(
        <div className={'staff_register--background_infos_area'}>
            <CustomFormikInput
              inputType={isStaff ? 'radioButton' : 'select'}
              label={isStaff ? '給与受取方法' : '給与支払方法'}
              formik={formik}
              radioButtonLabelAndChecked={[
                  { label: '銀行振込', checked: payMethod == 0 },
                  { label: '手渡し', checked: payMethod == 1 }
              ]}
              {...StaffRegisterInfos.salaryInfos.inputs[0]}
              labelRequired={isStaff}
            />
          <CustomFormikInput
              {...StaffRegisterInfos.salaryInfos.inputs[1]}
              initialValue={bankingList?.map(b=>b?.label)}
              selectBoxOptions={bankingList?.map(b=>b?.label)}
              formik={formik}
              labelRequired={isStaff}
          />
          <div className={'staff_register--input_note_area'}>
            <CustomFormikInput
                {...StaffRegisterInfos.salaryInfos.inputs[2]}
                formik={formik}
                disabled={true}
                labelRequired={isStaff}
            />
          </div>
          <CustomFormikInput
              {...StaffRegisterInfos.salaryInfos.inputs[3]}
              initialValue={branchesList?.map(b=>b?.label)}
              selectBoxOptions={branchesList?.map(b=>b?.label)}
              formik={formik}
              labelRequired={isStaff}
          />
          <div className={'staff_register--input_note_area'}>
              <CustomFormikInput
                  {...StaffRegisterInfos.salaryInfos.inputs[4]}
                  formik={formik}
                  disabled={true}
                  labelRequired={isStaff}
              />
          </div>

          <div className={'staff_register--bankAccountNumber'}>
            <CustomFormikInput
                formik={formik}
                radioButtonLabelAndChecked={[
                    {label: '普通預金', checked: bankAccountFlag == 1},
                    {label: "当座預金", checked: bankAccountFlag == 2},
                    {label: "貯蓄預金", checked: bankAccountFlag == 4}
                ]}
                {...StaffRegisterInfos.salaryInfos.inputs[5]}
                labelRequired={isStaff}
            />
          </div>
          <CustomFormikInput
              formik={formik}
              {...StaffRegisterInfos.salaryInfos.inputs[6]}
              labelRequired={isStaff}
          />
          <CustomFormikInput
              formik={formik}
              {...StaffRegisterInfos.salaryInfos.inputs[7]}
              labelRequired={isStaff}
          />
        </div>
    );
});
export default SalaryInfos;
