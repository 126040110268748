import { memo } from 'react';
import Icons from '../../constants/icons';
import './style.scss';

const SearchTitle = memo(() => (
  <div className={'search_title'}>
    <img src={Icons.icon.downNv} />
    <span>検索条件</span>
  </div>
));

export default SearchTitle;
