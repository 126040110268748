import './style.scss';
import ModalBase from '../modalBase';
import Button from '../../button';
import Options from '../../../constants/options';
import { checkIsSp } from '../../../utils/fnUtil';
import { useEffect } from 'react';

const DeleteModal = ({
  deleteKey,
  deleteName,
  deleteFunction,
  deleteModalVisible,
  toggleVisible,
  displayNotice = true,
  disableKey,
}) => {

  //デリートモーダルを開いた時にtitleBerがモーダルの灰色背景より、前に出るので
  //listViewの削除ボタンを押した時にzIndexを下げて、それを元に戻す処理
  const adjustZIndex = () => {
    const commonPageBase = document.querySelector('.commonPage--base');
    commonPageBase.style.zIndex = 1;
  }

  return (
    <ModalBase
      dialogStyle={{
        width: checkIsSp() ? 'calc(100% - 64px)':'calc(600px - 100px)',
      }}
      modalVisible={deleteModalVisible}
    >
      <div className={'delete_modal--wrap'}>
        <div className={'delete_modal--text_area'}>
          <span className={'delete_modal--text_title'}>削除確認</span>
          <span className={'delete_modal--text_message'}>
            {deleteKey ? `${disableKey ? '' : deleteKey + '　'}${deleteName}` : `${deleteName}`}
            <br />
            を削除します。
            <br />
            よろしいですか？
          </span>
          {displayNotice && (
            <span className={'delete_modal--text_notice'}>
              ※同一のIDは使用不可となります。
            </span>
          )}
        </div>
        <div className={'delete_modal--button_area'}>
          <Button
            text={'戻る'}
            style={{ ...Options.buttonStyles.back, width: checkIsSp() ? '100%':'208px' }}
            onClick={() => {
              toggleVisible(false);
              adjustZIndex();
            }}
          />
          <Button
            text={'削除する'}
            style={{
              ...Options.buttonStyles.submit,
              width: checkIsSp() ? '100%':'208px',
              backgroundColor: '#D80245',
            }}
            onClick={() => {
              deleteFunction(deleteKey);
              adjustZIndex();
            }}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default DeleteModal;
