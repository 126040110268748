import { Column } from '@ant-design/plots';
import React from 'react';
import { memo } from 'react';

const RetireGraph = memo(({ graphData, isSp, retireReasonList, maxCount }) => {
  const labelColor = [
    '#FAF368',
    '#F7A56F',
    '#F1758B',
    '#F890D5',
    '#E26BC0',
    '#C08DDC',
    '#80ADE5',
    '#4ACDE5',
    '#4EEAE4',
    '#44D89E',
    '#E0F072',
    '#BAE372',
    '#CECECE',
    '#ABABAB',
  ];

  //グラフ設定
  const config = {
    data: graphData,
    isStack: true,
    xField: 'yearMonth',
    yField: 'value',
    seriesField: 'type',
    colorField: 'type', //グラフのカラーを指定
    pattern: ({ type }) => {
      //グラフのカラーを指定
      return {
        type: 'dot',
        cfg: {
          size: 0.000001,
          backgroundColor: labelColor[retireReasonList.indexOf(type)],
        },
      };
    },
    tooltip: {
      //フォーカスした時に表示される詳細データの設定
      customItems: item => {
        let newAry = [];
        retireReasonList?.map(i => {
          //表示するアイテムを準備
          return newAry.push({
            name: i,
            value: '0人',
            color: labelColor[retireReasonList.indexOf(i)],
          });
        });

        item
          .sort((g, f) => (g.yearMonth < f.yearMonth ? -1 : 1))
          .forEach(a => {
            if (newAry.some(k => k.name === a.name)) {
              newAry[retireReasonList.indexOf(a.name)].value = `${a.value}人`;
            }
          });
        return newAry;
      },
      domStyles: {
        'g2-tooltip': { width: '300px' },
        'g2-tooltip-list-item': { width: '100%', fontSize: '11px' },
      },
      position: 'top',
    },
    legend: false,
    autoFit: !isSp,
    width: 1000,
    xAxis: {
      //横軸のラベル部分の設定
      label: {
        style: {
          fill: '#242424',
          fontSize: isSp ? 12 : 14,
        },
      },
    },
    yAxis: {
      //縦軸のラベル部分の設定
      tickCount: 10,
      min: 0,
      max: maxCount < 10 ? 10 : maxCount, //データの最大値によって値を変更する必要あり
      label: {
        formatter: (text, item, i) => {
          if ((i & 1) === 0) {
            return Number(text).toLocaleString();
          } else {
            return '';
          }
        },
        style: {
          fill: '#242424',
          fontSize: isSp ? 12 : 14,
        },
      },
    },
    label: {
      position: 'middle', //棒グラフに記載されている文字の位置 'top', 'bottom', 'middle'
      formatter: text =>
        text.value !== 0 ? Number(text.value).toLocaleString() : '', //データ0を削除
      style: {
        fill: '#5A5F6C',
        fontSize: isSp ? 12 : 14,
      }, //棒グラフに記載されている文字のstyle
    },
  };

  return isSp ? (
    <div className={'analyze_trend--graph_area'}>
      {/*右側のラベル部分(SPでは上部)*/}
      <div
        className={'analyze_trend--graph_label_area'}
        style={{ width: '100%', gap: '10px' }}
      >
        {retireReasonList?.map((name, idx) => {
          return (
            <div
              className={'analyze_trend--label'}
              key={`analyze_trend${idx}`}
              style={{ width: '100%' }}
            >
              <div
                style={{ backgroundColor: labelColor[idx], width: '50px' }}
              />
              <span>{name}</span>
            </div>
          );
        })}
      </div>
      <div className={'analyze_trend--graph_unit'}>
        <span>人数(人)</span>
      </div>

      <div className={'analyze_trend--graph_display'} style={{ gap: '21px' }}>
        {/*グラフ部分*/}
        <div className={'analyze_trend--graph'}>
          <Column {...config} />
        </div>
      </div>
    </div>
  ) : (
    <div className={'analyze_trend--graph_area'}>
      <div className={'analyze_trend--graph_unit'}>
        <span>人数(人)</span>
      </div>

      <div className={'analyze_trend--graph_display'} style={{ gap: '21px' }}>
        {/*グラフ部分*/}
        <div className={'analyze_trend--graph'}>
          <Column {...config} />
        </div>

        {/*右側のラベル部分*/}
        <div
          className={'analyze_trend--graph_label_area'}
          style={{ width: '300px' }}
        >
          {retireReasonList?.map((name, idx) => {
            return (
              <div
                className={'analyze_trend--label'}
                key={`analyze_trend${idx}`}
                style={{ width: '300px' }}
              >
                <div style={{ backgroundColor: labelColor[idx] }} />
                <span>{name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default RetireGraph;
