import React from 'react';
import { Column } from '@ant-design/plots';
import { memo } from 'react';

const StaffGraph = memo(({ graphData, isSp, maxCount }) => {
  const labelName = ['退職', '新規', '既存'];
  const labelColor = ['#DCDCDC', '#68B8FF', '#DBEDFF'];

  //グラフ設定
  const config = {
    data: graphData,
    isStack: true,
    xField: 'yearMonth',
    yField: 'value',
    seriesField: 'type',
    colorField: 'type', //グラフのカラーを指定
    color: labelColor, //グラフのカラーを指定
    // interactions: [{ type: 'inactive' }], //グラフにフォーカスした時のアクションをオフにする
    legend: false,
    autoFit: !isSp,
    width: 1000,
    tooltip: {
      //フォーカスした時に表示される詳細データの設定
      customItems: item => {
        let newAry = [];
        item.map(obj => {
          return newAry.push({
            ...obj,
            value: `${Number(obj.value).toLocaleString()}人`,
          });
        });
        return newAry;
      },
      domStyles: {
        'g2-tooltip': { width: '130px' },
        'g2-tooltip-list-item': { width: '100%', fontSize: '11px' },
      },
      position: 'top',
    },
    xAxis: {
      //横軸のラベル部分の設定
      label: {
        style: {
          fill: '#242424',
          fontSize: isSp ? 12 : 14,
        },
      },
    },
    yAxis: {
      //縦軸のラベル部分の設定
      tickCount: 10,
      min: 0,
      max: maxCount === 0 ? 20 : maxCount,
      label: {
        formatter: (text, item, i) => {
          if ((i & 1) === 0) {
            return Number(text).toLocaleString();
          } else {
            return '';
          }
        },
        style: {
          fill: '#242424',
          fontSize: isSp ? 12 : 14,
        },
      },
    },
    label: {
      position: 'middle', //棒グラフに記載されている文字の位置 'top', 'bottom', 'middle'
      formatter: (text, item, i) => {
        //データ0を削除
        if (text.value !== 0) {
          return Number(text.value).toLocaleString();
        } else {
          return '';
        }
      },
      style: {
        fill: '#5A5F6C',
        fontSize: isSp ? 12 : 14,
      }, //棒グラフに記載されている文字のstyle
    },
  };

  return isSp ? (
    <div className={'analyze_trend--graph_area'}>
      {/*右側のラベル部分(SPでは上部)*/}
      <div className={'analyze_trend--graph_label_area'}>
        {labelName.map((name, idx) => {
          return (
            <div className={'analyze_trend--label'} key={`analyze_trend${idx}`}>
              <div style={{ backgroundColor: labelColor[idx] }} />
              <span>{name}</span>
            </div>
          );
        })}
      </div>
      <div className={'analyze_trend--graph_unit'}>
        <span>人数(人)</span>
      </div>

      <div className={'analyze_trend--graph_display'}>
        {/*グラフ部分*/}
        <div className={'analyze_trend--graph'}>
          <Column {...config} />
        </div>
      </div>
    </div>
  ) : (
    <div className={'analyze_trend--graph_area'}>
      <div className={'analyze_trend--graph_unit'}>
        <span>人数(人)</span>
      </div>

      <div className={'analyze_trend--graph_display'}>
        {/*グラフ部分*/}
        <div className={'analyze_trend--graph'}>
          <Column {...config} />
        </div>

        {/*右側のラベル部分*/}
        <div className={'analyze_trend--graph_label_area'}>
          {labelName.map((name, idx) => {
            return (
              <div
                className={'analyze_trend--label'}
                key={`analyze_trend${idx}`}
              >
                <div style={{ backgroundColor: labelColor[idx] }} />
                <span>{name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default StaffGraph;
