import Icons from '../../../constants/icons';
import './style.scss';
import React, { memo, useEffect, useState } from 'react';
import StaffPlanShiftInfos from './inputInfos';
import Button from '../../../components/button';
import AddButton from '../../../components/addButton';
import Options from '../../../constants/options';
import { staffPlanActions } from '../../../redux/slice/staffPlan';
import { checkIsSp, handlerFormikFieldChange } from '../../../utils/fnUtil';
import { useDispatch } from 'react-redux';
import options from '../../../constants/options';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import moment from 'moment';
import { csvDownloadFetch } from '../../../redux/slice/other';
import CustomInput from '../../../components/customInput';
// import CustomInput from '../../../components/customInput';

const StaffShiftDateList = memo(({ formik, staff, staffId, shiftsData }) => {
  const dispatch = useDispatch();
  const tableInfosShift = staff
    ? ['日付', 'シフト', '条件', '時間', '備考']
    : ['日付', 'シフト', '条件', '時間', 'スタッフ備考', '担当者備考'];

  const { date, shifts, corpShiftStyleFlag, lastSubmitDatetime, statusFlag, beforeEditShifts } =
    formik.values;

  const exportCsv = () => {
    let searchMonth = date?.month;

    if (searchMonth?.toString()?.length === 1) {
      searchMonth = `0${searchMonth}`;
    }

    let info = {
      url: Options.csvExport.type['staffPlanEdit'][0].url,
      params: { staffId, yearMonth: `${date.year}/${searchMonth}` },
    };

    dispatch(csvDownloadFetch(info)).then(data => {
      const blob = new Blob([data.payload], {
        type: 'text/csv',
      });

      if (data.error && data.error.message === 'Rejected') {
        return;
      }

      const ahref = document.createElement('a');
      ahref.download =
        Options.csvExport.type['staffPlanEdit'][0].fileName +
        '_' +
        moment().format('YYYYMMDDHHmmssSSS') +
        '.csv';
      ahref.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      ahref.dispatchEvent(clickEvt);
      ahref.remove();
    });
  };

  const buttonSettings = {
    viewPager: true,
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: exportCsv,
      style: Options.buttonStyles.csv,
    },
  };
  const getWeek = (year, month, day) => {
    const targetDay = new Date(year, month - 1, day);
    return require('japanese-holidays').isHoliday(targetDay)
      ? options.codes.weekDay[targetDay.getDay()] + '・祝日'
      : options.codes.weekDay[targetDay.getDay()];
  };
  // 翌月へ
  const addDate = async () => {
    dispatch(staffPlanActions.setAddDate(false));
    handlerFormikFieldChange(
      formik,
      'date',
      date.month > 11
        ? { ...date, month: 1, year: date.year + 1 }
        : { ...date, month: date.month + 1 }
    );
  };
  // 前月へ
  const reduceDate = () => {
    dispatch(staffPlanActions.setReduceDate(false));
    handlerFormikFieldChange(
      formik,
      'date',
      date.month < 2
        ? { ...date, month: 12, year: date.year - 1 }
        : { ...date, month: date.month - 1 }
    );
  };

  const isCheckAll = e => {
    const isChecked = e.target.checked;
    const newShiftsData = [...shifts];
    const checkBoxes = document.getElementsByClassName('custom_input--toggle_input');
    if (isChecked) {
      Array.from(checkBoxes)?.map(elm => elm.checked = true);
      newShiftsData?.map((obj, idx) => {
          handlerFormikFieldChange(formik, `shifts[${idx}].enableAssignFlag`, 1);
          handlerFormikFieldChange(
            formik,
            `shifts[${idx}].changeFlag`,
            true,
          );
        }
      );
    } else {
      shifts?.map((obj, idx) =>
        handlerFormikFieldChange(
          formik,
          `shifts[${idx}].enableAssignFlag`,
          shiftsData[idx]?.enableAssignFlag === 0 ? 0 : 1
        ));
      Array.from(checkBoxes)?.map((elm, idx) =>
        shiftsData[idx]?.enableAssignFlag === 0 ? elm.checked = false : elm.checked = true
      );
    }
  }

  return (
    <>
      <div className={'staff_plan--lower_part'}>
        <div className={'staff_plan--bar_area'}>
          <div className={'staff_plan--bar_content_status'}>
            <button
              className={'staff_plan--calender_prev_button'}
              onClick={() => {
                dispatch(staffPlanActions.setReduceDate(true));
                reduceDate();
              }}
            >
              <img src={Icons.icon.lightNv} />
              前月
            </button>

            <span className={'staff_plan--date_display'}>
              {date.year}年 {date.month}月分
            </span>

            <button
              className={'staff_plan--calender_next_button'}
              onClick={() => {
                dispatch(staffPlanActions.setAddDate(true));
                addDate();
              }}
            >
              次月
              <img src={Icons.icon.rightNv} />
            </button>
            <div className={'staff_plan--calender_status_area'}>
              <div
                className={
                  statusFlag == 1
                    ? 'staff_plan--calender_status_ture'
                    : 'staff_plan--calender_status'
                }
              >
                {statusFlag == 1 ? '提出済' : '未提出'}
              </div>
              <div className={'staff_plan--calender_update_info'}>
                提出日時{' '}
                {lastSubmitDatetime
                  ? moment(lastSubmitDatetime).format('YYYY/MM/DD HH:mm')
                  : '-'}
              </div>
            </div>
          </div>
          {!staff && (
            <div className={'staff_plan--bar_content_modal'}>
              <div className={'list_view--buttons_right'}>
                {Object.keys(buttonSettings).map(key => {
                  if (key !== 'viewPager') {
                    if (key === 'addButton') {
                      return (
                        <AddButton key={key} {...buttonSettings[key].props} />
                      );
                    } else {
                      return (
                        <Button
                          key={key}
                          icon={buttonSettings[key].icon}
                          text={buttonSettings[key].text}
                          style={buttonSettings[key].style}
                          onClick={buttonSettings[key].onClick}
                          iconStyle={buttonSettings[key].iconStyle}
                        />
                      );
                    }
                  }
                })}
              </div>
            </div>
          )}
        </div>

        <div className={'staff_plan--contents_area'}>
          <table className={'staff_plan--shift_table'} border="0">
            <tbody>
              <tr>
                {tableInfosShift.map((info, idx) => {
                  return (
                    !(corpShiftStyleFlag === 0 && (idx === 2 || idx === 3)) && (
                      <td
                        key={'staff_plan--td' + idx}
                        className={'staff_plan--td_' + idx}
                      >
                        {info === 'シフト' ?
                          <div className={'staff_plan--td_contents'} style={{width: '200px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            シフト(全日程可
                            <CustomInput
                              inputType={'checkBox'}
                              inputName={'checkAll'}
                              onChange={e => isCheckAll(e)}
                              initialValue={!shifts?.some(obj => obj?.enableAssignFlag === 0)}
                              key={!shifts?.some(obj => obj?.enableAssignFlag === 0)}
                            />
                            )
                          </div> :
                          <div className={'staff_plan--td_contents'}>{info}</div>
                        }
                      </td>
                    )
                  );
                })}
              </tr>

              {[...Array(new Date(date.year, date.month, 0).getDate())].map(
                (_, idx) => (
                  <tr
                    key={'staff_plan--tr' + idx}
                    className={
                      getWeek(date.year, date.month, idx + 1) === '日' ||
                      getWeek(date.year, date.month, idx + 1).includes('祝日')
                        ? 'staff_plan--shift_table_tr_sun'
                        : getWeek(date.year, date.month, idx + 1) === '土'
                        ? 'staff_plan--shift_table_tr_sat'
                        : 'staff_plan--shift_table_tr'
                    }
                  >
                    <th style={{ width: '10%', minWidth: '135px' }}>
                      <div
                        className={'staff_plan--th_shift_contents'}
                        style={{ paddingLeft: '24px' }}
                      >
                        <span className={'staff_plan--calender_font'}>
                          {idx + 1}日({getWeek(date.year, date.month, idx + 1)})
                        </span>
                      </div>
                    </th>
                    <th
                      style={{
                        width: corpShiftStyleFlag === 1 ? '7%' : '10%',
                        minWidth: '78px',
                      }}
                    >
                      <div
                        className={'staff_plan--th_shift_contents'}
                        style={{ width: '64px' }}
                        id={`toggle-radio-num${idx}`}
                      >
                        <CustomFormikInput
                          formik={formik}
                          relativeInputName={['enableAssignFlag']}
                          inputName={`shifts.[${idx}].enableAssignFlag`}
                          checkBoxChecked={shifts[idx]?.enableAssignFlag !== 1}
                          {...StaffPlanShiftInfos.inputs[0]}
                          disabled={statusFlag && (beforeEditShifts ? beforeEditShifts[idx]?.enableAssignFlag : false)}
                          onChange={() => {
                            handlerFormikFieldChange(
                              formik,
                              `shifts[${idx}].enableAssignFlag`,
                              shifts[idx].enableAssignFlag === 0 ? 1 : 0
                            );
                          }}
                          extraOnChange={e => {
                            handlerFormikFieldChange(
                              formik,
                              `shifts[${idx}].changeFlag`,
                              true
                            );
                          }}
                        />
                      </div>
                    </th>
                    {corpShiftStyleFlag === 1 && (
                      <>
                        <th style={{ width: '8%', minWidth: '106px' }}>
                          <div
                            className={'staff_plan--th_shift_contents'}
                            data-radio-num={idx + 1}
                          >
                            <CustomFormikInput
                              formik={formik}
                              relativeInputName={['shiftStyleFlag']}
                              inputName={`shifts.[${idx}].shiftStyleFlag`}
                              initialValue={[0, 1]}
                              radioButtonLabelAndChecked={[
                                {
                                  label: '終日',
                                  checked: shifts[idx]?.shiftStyleFlag == 0,
                                },
                                {
                                  label: '時間指定',
                                  checked: shifts[idx]?.shiftStyleFlag == 1,
                                },
                              ]}
                              {...StaffPlanShiftInfos.inputs[1]}
                              disabled={shifts[idx]?.enableAssignFlag == false}
                              onChange={() => {
                                handlerFormikFieldChange(
                                  formik,
                                  `shifts[${idx}].shiftStyleFlag`,
                                  shifts[idx].shiftStyleFlag === 0 ? 1 : 0
                                );
                              }}
                              extraOnChange={e => {
                                handlerFormikFieldChange(
                                  formik,
                                  `shifts[${idx}].changeFlag`,
                                  true
                                );
                              }}
                            />
                          </div>
                        </th>
                        <th style={{ width: '18%' }}>
                          <div className={'staff_plan--th_contents_time'}>
                            <CustomFormikInput
                              formik={formik}
                              {...StaffPlanShiftInfos.inputs[2]}
                              relativeInputName={['startTime']}
                              inputName={`shifts.[${idx}].startTime`}
                              otherErrorInputName={[
                                `shifts.[${idx}].shiftRange`,
                              ]}
                              disabled={
                                shifts[idx]?.enableAssignFlag == false ||
                                (shifts[idx]?.enableAssignFlag == true &&
                                  shifts[idx]?.shiftStyleFlag == 0)
                              }
                              extraOnChange={() => {
                                handlerFormikFieldChange(
                                  formik,
                                  `shifts[${idx}].changeFlag`,
                                  true
                                );
                              }}
                            />
                            <span
                              className={'staff_plan--th_contents_time_border'}
                              style={{fontWeight: 'normal'}}
                            >
                              〜
                            </span>
                            <CustomFormikInput
                              formik={formik}
                              {...StaffPlanShiftInfos.inputs[3]}
                              relativeInputName={['endTime']}
                              inputName={`shifts.[${idx}].endTime`}
                              otherErrorInputName={[
                                `shifts.[${idx}].shiftRange`,
                              ]}
                              disabled={
                                shifts[idx]?.enableAssignFlag == false ||
                                (shifts[idx]?.enableAssignFlag == true &&
                                  shifts[idx]?.shiftStyleFlag == 0)
                              }
                              extraOnChange={() => {
                                handlerFormikFieldChange(
                                  formik,
                                  `shifts[${idx}].changeFlag`,
                                  true
                                );
                              }}
                            />
                          </div>
                        </th>
                      </>
                    )}
                    <th
                      style={{
                        width: corpShiftStyleFlag === 1 ? '27%' : '40%',
                        minWidth: '200px',
                      }}
                    >
                      <div className={'staff_plan--th_shift_contents'}>
                        <CustomFormikInput
                          formik={formik}
                          {...StaffPlanShiftInfos.inputs[4]}
                          relativeInputName={['staffNote']}
                          inputName={`shifts.[${idx}].staffNote`}
                          extraOnChange={() => {
                            handlerFormikFieldChange(
                              formik,
                              `shifts[${idx}].changeFlag`,
                              true
                            );
                          }}
                        />
                      </div>
                    </th>
                    {!staff && (
                      <th
                        style={{
                          width: corpShiftStyleFlag === 1 ? '27%' : '40%',
                          minWidth: '200px',
                        }}
                      >
                        <div className={'staff_plan--th_shift_contents'}>
                          <CustomFormikInput
                            formik={formik}
                            {...StaffPlanShiftInfos.inputs[5]}
                            relativeInputName={['managerNote']}
                            inputName={`shifts.[${idx}].managerNote`}
                            extraOnChange={() => {
                              handlerFormikFieldChange(
                                formik,
                                `shifts[${idx}].changeFlag`,
                                true
                              );
                            }}
                          />
                        </div>
                      </th>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});

export default StaffShiftDateList;
