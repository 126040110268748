import './style.scss';
import './spStyle.scss';
import Icons from '../../constants/icons';
import { Select } from 'antd';
import React from 'react';
import AddButton from '../addButton';
const { Option } = Select;

const SelectBoxAddButton = ({
  inputName,
  placeholder,
  optionValues,
  optionTexts,
  selected,
  onChange,
  onBlur,
  disabled,
  changePlusColor,
  onClickAdd,
  useValue,
  usePopContainer,
}) => {
  return (
    <div className={'custom_select--area select_add_btn--area'}>
      <Select
        id={inputName}
        name={inputName}
        placeholder={placeholder ? placeholder : ''}
        {...(useValue
          ? {
              value: selected,
            }
          : {
              defaultValue: selected && selected,
            })}
        allowClear
        defaultActiveFirstOption={false}
        clearIcon={
          <img
            style={{ width: '30px', height: '30px' }}
            src={Icons.icon.xCircle}
          />
        }
        menuItemSelectedIcon={
          <div className="custom_select--option_check_mark" />
        }
        suffixIcon={
          disabled ? (
            <img src={Icons.icon.downGr} />
          ) : (
            <img src={Icons.icon.downNv} />
          )
        }
        listHeight={288}
        dropdownStyle={{ boxShadow: '0 0 8px #2424244D', borderRadius: '6px' }}
        onChange={onChange && onChange}
        onBlur={onBlur && onBlur}
        disabled={disabled ? disabled : false}
        getPopupContainer={
          usePopContainer ? triggerNode => triggerNode.parentNode : null
        }
      >
        {optionValues &&
          optionTexts &&
          optionTexts.map((text, idx) => {
            return (
              <Option value={optionValues[idx]} key={idx}>
                {text}
              </Option>
            );
          })}
      </Select>
      <AddButton
        changePlusColor={changePlusColor}
        onClick={onClickAdd}
        useType={'selectAddBtn'}
      />
    </div>
  );
};

export default SelectBoxAddButton;
