import { createSlice } from '@reduxjs/toolkit';

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    corporateId: '',
    userId: '',
    staffId: '',
    auth: '',
    deptId: '',
    userImage: '',
    staffImage: '',
    corporateName: '',
    fullName: '',
    accessToken: '',
    menuQuoteListFlag: null,
    menuQuoteApprovalListFlag: null,
    menuQuoteDocumentNumberFlag: null,
    menuQuoteTemplateListFlag: null,
    menuQuoteFlowListFlag: null,
    menuMatterListFlag: null,
    menuMatterDateListFlag: null,
    menuMatterAssignListFlag: null,
    menuMatterOpenListFlag: null,
    menuMatterAppliedListFlag: null,
    menuInvoiceListFlag: null,
    menuStaffListFlag: null,
    menuStaffPlanListFlag: null,
    menuStaffPayListFlag: null,
    menuStaffPayYearListFlag: null,
    menuAttendClosingFlag: null,
    menuAnalyzeTrendFlag: null,
    menuAnalyzeProfitFlag: null,
    menuClientListFlag: null,
    menuVenueListFlag: null,
    menuSettingScheduleFlag: null,
    menuUserListFlag: null,
    menuPartnerListFlag: null,
    menuCorporateListFlag: null,
    menuPayTermListFlag: null,
    menuNamingListFlag: null,
    menuIncomeTaxListFlag: null,
    unlockQuoteFlag: null,
    skipQuoteApprovalFlag: null,
    unlockInvoiceFlag: null,
    shiftStyleFlag: null,
    bankApiKey: '',
    weatherApiKey: ''
  },
  reducers: {
    setAccount: (state, action) => {
      state.corporateId = action.payload.corporateId;
      state.userId = action.payload.userId;
      state.staffId = action.payload.staffId;
      state.auth = action.payload.auth;
      state.deptId = action.payload.deptId;
      state.userImage = action.payload.userImage;
      state.staffImage = action.payload.staffImage;
      state.corporateName = action.payload.corporateName;
      state.fullName = action.payload.fullName;
      state.accessToken = action.payload.accessToken;
      state.menuQuoteListFlag = action.payload.menuQuoteListFlag;
      state.menuQuoteApprovalListFlag =
        action.payload.menuQuoteApprovalListFlag;
      state.menuQuoteDocumentNumberFlag =
        action.payload.menuQuoteDocumentNumberFlag;
      state.menuQuoteTemplateListFlag =
        action.payload.menuQuoteTemplateListFlag;
      state.menuQuoteFlowListFlag = action.payload.menuQuoteFlowListFlag;
      state.menuMatterListFlag = action.payload.menuMatterListFlag;
      state.menuMatterDateListFlag = action.payload.menuMatterDateListFlag;
      state.menuMatterAssignListFlag = action.payload.menuMatterAssignListFlag;
      state.menuMatterOpenListFlag = action.payload.menuMatterOpenListFlag;
      state.menuMatterAppliedListFlag =
        action.payload.menuMatterAppliedListFlag;
      state.menuInvoiceListFlag = action.payload.menuInvoiceListFlag;
      state.menuStaffListFlag = action.payload.menuStaffListFlag;
      state.menuStaffPlanListFlag = action.payload.menuStaffPlanListFlag;
      state.menuStaffPayListFlag = action.payload.menuStaffPayListFlag;
      state.menuStaffPayYearListFlag = action.payload.menuStaffPayYearListFlag;
      state.menuAttendClosingFlag = action.payload.menuAttendClosingFlag;
      state.menuAnalyzeTrendFlag = action.payload.menuAnalyzeTrendFlag;
      state.menuAnalyzeProfitFlag = action.payload.menuAnalyzeProfitFlag;
      state.menuClientListFlag = action.payload.menuClientListFlag;
      state.menuVenueListFlag = action.payload.menuVenueListFlag;
      state.menuSettingScheduleFlag = action.payload.menuSettingScheduleFlag;
      state.menuUserListFlag = action.payload.menuUserListFlag;
      state.menuPartnerListFlag = action.payload.menuPartnerListFlag;
      state.menuCorporateListFlag = action.payload.menuCorporateListFlag;
      state.menuPayTermListFlag = action.payload.menuPayTermListFlag;
      state.menuNamingListFlag = action.payload.menuNamingListFlag;
      state.menuIncomeTaxListFlag = action.payload.menuIncomeTaxListFlag;
      state.unlockQuoteFlag = action.payload.unlockQuoteFlag;
      state.skipQuoteApprovalFlag = action.payload.skipQuoteApprovalFlag;
      state.unlockInvoiceFlag = action.payload.unlockInvoiceFlag;
      state.unlockMatterFlag = action.payload.unlockMatterFlag;
      state.shiftStyleFlag = action.payload.shiftStyleFlag;
      state.employmentType = action.payload.employmentType;
      state.bankApiKey = action.payload.bankApiKey;
      state.weatherApiKey = action.payload.weatherApiKey;
    },
  },
});

export const { setAccount } = accountSlice.actions;

export default accountSlice.reducer;
