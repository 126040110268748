import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
import {
  staffPlanDetailUpdate,
  staffPlanDetailFetch,
  staffPlanActions,
  staffPlanCorpFetch,
} from '../../../redux/slice/staffPlan';
import { useFormik } from 'formik';
import {
  handlerFormikFieldChange,
  handlerFormikSubmit,
  checkIsSp,
} from '../../../utils/fnUtil';
import { useDispatch, useSelector } from 'react-redux';
import options from '../../../constants/options';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { globalActions } from '../../../redux/slice/global';
import Yup from '../../../utils/yupUtil';
import moment from 'moment';
import StaffShiftDateList from './StaffShiftDateList';
import StaffShiftDateListSp from './StaffShiftDateListSp';

const StaffPlanEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const tableInfos = [
    'スタッフID',
    'スタッフ名',
    '稼働希望日数',
    'シフト提出日数',
  ];
  const tableInfosShift = [
    '日付',
    'シフト',
    '条件',
    '時間',
    'スタッフ備考',
    '担当者備考',
  ];
  const { corporateId } = useSelector(state => state.account);
  const { csvExportVisible, searchParams, addDateFlag, reduceDateFlag } =
    useSelector(state => state.staffPlan);

  const [shiftsData, saveShiftsData] = useState([]);

  const isSp = checkIsSp();

  const tabErrorGroup = {
    matterDate: ['matterDate'],
  };
  const formik = useFormik({
    initialValues: {
      userPlanInfos: {
        userName: '',
        userLastUpdateDatetime: ``,
      },
      staffPlanInfos: {
        staffName: '',
        staffLastUpdateDatetime: ``,
      },
      hopeDateCount: 0,
      assign: 0,
      lastSubmitDatetime: '',
      statusFlag: 0,
      corpShiftStyleFlag: 1,
      date: {
        year: parseInt(searchParams.yearMonth.slice(0, 4)),
        month: parseInt(searchParams.yearMonth.slice(-2)),
      },
      shifts: [
        ...Array(
          new Date(
            parseInt(searchParams.yearMonth.slice(0, 4)),
            parseInt(searchParams.yearMonth.slice(-2)),
            0
          ).getDate()
        ),
      ].map((_, idx) => {
        return {
          date: idx + 1,
          enableAssignFlag: 0,
          shiftStyleFlag: 0,
          startTime: null,
          endTime: null,
          staffNote: null,
          managerNote: null,
        };
      }),
      workShifts: [],
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      shifts: Yup.array().of(
        Yup.object({
          startTime: Yup.string().nullable().label('開始時間'),
          endTime: Yup.string().nullable().label('終了時間'),
          shiftRange: Yup.string()
            .nullable()
            .test(
              'dateInvalid',
              '時間:開始時間は終了時間より前の時間を入力してください。',
              (_, context) => {
                const { shiftStyleFlag, startTime, endTime } =
                  context.from[0].value;
                if (
                  shiftStyleFlag == 0 ||
                  (startTime == null && endTime == null)
                ) {
                  return true;
                }
                return (
                  shiftStyleFlag == 1 &&
                  startTime &&
                  endTime &&
                  moment(endTime, 'HH:mm').isAfter(moment(startTime, 'HH:mm'))
                );
              }
            )
            .test(
              'dateInvalid',
              '時間:時間指定の場合時間を入力してください。',
              (_, context) => {
                const { shiftStyleFlag, startTime, endTime } =
                  context.from[0].value;
                return !(
                  shiftStyleFlag == 1 &&
                  startTime == null &&
                  endTime == null
                );
              }
            ),
          staffNote: Yup.string().nullable().max(200).label('スタッフ備考'),
          managerNote: Yup.string().nullable().max(200).label('担当者備考'),
        })
      ),
    }),
    onSubmit: values => {
      const shifts = values.shifts
        ?.filter(s => s?.changeFlag == true)
        .map(s => {
          return {
            ...s,
            year: values.date.year,
            month: values.date.month,
            staffId: location.state.id,
          };
        });
      const nonShift = [
        {
          year: date.year,
          month: date.month,
          date: 1,
          staffId: location.state.id,
          enableAssignFlag: 0,
          shiftStyleFlag: 0,
          startTime: null,
          endTime: null,
          staffNote: null,
          managerNote: null,
        },
      ];
      dispatch(
        staffPlanDetailUpdate({
          yearMonth: date.year + '/' + String(date.month).padStart(2, '0'),
          staffId: location.state.id,
          shifts:
            shifts.length == 0 && values.statusFlag == 0 ? nonShift : shifts,
        })
      )
        .unwrap()
        .then(result => {
          dispatch(globalActions.showSingleModal('更新しました'));
          navigate(-1);
        });
    },
  });

  const {
    date,
    shifts,
    assign,
    userPlanInfos,
    staffPlanInfos,
    hopeDateCount,
    statusFlag,
    workShifts,
  } = formik.values;
  const buttonStyle = {
    updateButton: {
      width: '240px',
      height: '44px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    backButton: {
      width: '240px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
  };
  useEffect(() => {
    dispatch(staffPlanCorpFetch({ corporateId, deletedFlag: 0 }))
      .unwrap()
      .then(result => {
        handlerFormikFieldChange(
          formik,
          'corpShiftStyleFlag',
          result?.detail?.shiftStyleFlag
        );
      });
  }, []);
  // データ取得処理
  useEffect(() => {
    dispatch(
      staffPlanDetailFetch({
        yearMonth: date.year + '-' + String(date.month).padStart(2, '0'),
        staffId: location.state.id,
      })
    )
      .unwrap()
      .then(result => {
        handlerFormikFieldChange(
          formik,
          'userPlanInfos.userName',
          result?.detail?.userName?.lastName +
            result?.detail?.userName?.firstName
        );
        handlerFormikFieldChange(
          formik,
          'userPlanInfos.userLastUpdateDatetime',
          result?.detail?.userLastUpdateDatetime
        );
        handlerFormikFieldChange(
          formik,
          'staffPlanInfos.staffName',
          result?.detail?.staffName?.lastName +
            result?.detail?.staffName?.firstName
        );
        handlerFormikFieldChange(
          formik,
          'staffPlanInfos.staffLastUpdateDatetime',
          result?.detail?.staffLastUpdateDatetime
        );
        handlerFormikFieldChange(
          formik,
          'hopeDateCount',
          result?.detail?.hopeDateCount
        );
        handlerFormikFieldChange(
          formik,
          'statusFlag',
          result?.detail?.statusFlag
        );
        handlerFormikFieldChange(
          formik,
          'lastSubmitDatetime',
          result?.detail?.lastSubmitDatetime
        );
        handlerFormikFieldChange(
          formik,
          'workShifts',
          [...Array(new Date(date.year, date.month, 0).getDate())].map(
            (_, idx) => {
              let ch = document.getElementById(
                `shifts.[${idx}].enableAssignFlag`
              );
              if (result?.detail?.shifts?.some(rs => rs?.date === idx + 1)) {
                ch.checked = result?.detail?.shifts?.some(
                  rs => rs?.date === idx + 1 && rs?.enableAssignFlag == 1
                );
                return {
                  ...result?.detail?.shifts?.find(rs => rs?.date === idx + 1),
                  changeFlag: false,
                };
              } else {
                if (ch?.checked) {
                  ch.checked = false;
                }
                return {
                  date: idx + 1,
                  enableAssignFlag: 0,
                  shiftStyleFlag: 0,
                  startTime: null,
                  endTime: null,
                  staffNote: null,
                  managerNote: null,
                  changeFlag: false,
                };
              }
            }
          )
        );
      });
  }, [date]);
  // ちゃんと処理する
  useEffect(() => {
    workShifts.length > 0 &&
      handlerFormikFieldChange(formik, 'shifts', workShifts);
    saveShiftsData(workShifts);
  }, [workShifts]);

  // 日数計算処理
  useEffect(() => {
    handlerFormikFieldChange(
      formik,
      'assign',
      shifts?.filter(s => s?.enableAssignFlag == 1).length
    );
  }, [shifts]);

  return isSp ? (
    <>
      <div className={'staff_plan--edit_page'}>
        <div className={'staff_plan--upper_part'}>
          {/*画面右上部の情報表示部分*/}
          <div className={'staff_plan--access_user_info_area'}>
            <div className={'staff_plan--access_user_data'}>
              <span>社員最終更新</span>
              <span>
                {userPlanInfos?.userLastUpdateDatetime?.replace(/-/g, '/')}
                {userPlanInfos?.userName ? userPlanInfos.userName : '-'}
              </span>
            </div>
            <div className={'staff_plan--access_user_data'}>
              <span>スタッフ最終更新</span>
              <span>
                {staffPlanInfos?.staffLastUpdateDatetime?.replace(/-/g, '/')}
                {staffPlanInfos?.staffLastUpdateDatetime
                  ? staffPlanInfos.staffName
                  : '-'}
              </span>
            </div>
          </div>
        </div>
        {/*画面上部のスタッフIDや稼働希望日数部分*/}
        <div className={'staff_plan--table'}>
          <div className={'staff_plan--table_header'}>{tableInfos[0]}</div>
          <div className={'staff_plan--table_body'}>{location.state.id}</div>
          <div className={'staff_plan--table_header'}>{tableInfos[1]}</div>
          <div className={'staff_plan--table_body'}>
            {staffPlanInfos?.staffName}
          </div>
          <div className={'staff_plan--table_header'}>{tableInfos[2]}</div>
          <div className={'staff_plan--table_body'}>
            <span className={'data_info'} style={{lineHeight: checkIsSp() ? '60px' : ''}}>{hopeDateCount ?? 0}</span>
            <span style={{lineHeight: checkIsSp() ? '60px' : ''}}>日</span>
          </div>
          <div className={'staff_plan--table_header'}>{tableInfos[3]}</div>
          <div className={'staff_plan--table_body'}>
            <span className={'data_info'} style={{lineHeight: checkIsSp() ? '60px' : ''}}>{assign}</span>
            <span style={{lineHeight: checkIsSp() ? '60px' : ''}}>日</span>
          </div>
        </div>
        <StaffShiftDateListSp formik={formik} staffId={location.state.id} shiftsData={shiftsData} />
      </div>
      <div
        className={'bottom_btn_area'}
        style={{ height: '92px', justifyContent: 'center' }}
      >
        <div className={'staff_register--submit_buttons'}>
          <Button
            text={'戻る'}
            style={buttonStyle.backButton}
            onClick={() => {
              navigate(`/staff-plan/list`);
            }}
          />
          <Button
            text={statusFlag == 1 ? '更新' : '提出済にする'}
            style={buttonStyle.updateButton}
            onClick={() => {
              handlerFormikSubmit(formik, tabErrorGroup);
            }}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className={'staff_plan--edit_page'}>
        <div className={'staff_plan--upper_part'}>
          {/*画面上部のスタッフIDや稼働希望日数部分*/}
          <table className={'staff_plan--table'} border="1">
            <tbody>
              <tr>
                {tableInfos.map((info, idx) => (
                  <td key={'staff_plan--td' + idx}>
                    <div className={'staff_plan--td_contents'}>{info}</div>
                  </td>
                ))}
              </tr>
              <tr>
                <th>
                  <div className={'staff_plan--th_contents'}>
                    {location.state.id}
                  </div>
                </th>
                <th>
                  <div className={'staff_plan--th_contents'}>
                    {staffPlanInfos?.staffName}
                  </div>
                </th>
                <th>
                  <div className={'staff_plan--th_contents'}>
                    <span className={'data_info'}>{hopeDateCount ?? 0}</span>日
                  </div>
                </th>
                <th>
                  <div className={'staff_plan--th_contents'}>
                    <span className={'data_info'}>{assign}</span>日
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
          {/*画面右上部の情報表示部分*/}
          <div className={'staff_plan--access_user_info_area'}>
            <div className={'staff_plan--access_user_data'}>
              <span>社員最終更新</span>
              <span>
                {userPlanInfos?.userLastUpdateDatetime?.replace(/-/g, '/')}
                {userPlanInfos?.userName ? userPlanInfos.userName : '-'}
              </span>
            </div>
            <div className={'staff_plan--access_user_data'}>
              <span>スタッフ最終更新</span>
              <span>
                {staffPlanInfos?.staffLastUpdateDatetime?.replace(/-/g, '/')}
                {staffPlanInfos?.staffLastUpdateDatetime
                  ? staffPlanInfos.staffName
                  : '-'}
              </span>
            </div>
          </div>
        </div>
        <StaffShiftDateList formik={formik} staffId={location.state.id} shiftsData={shiftsData} />
      </div>

      <div
        className={'bottom_btn_area'}
        style={{ height: '92px', justifyContent: 'center' }}
      >
        <div className={'staff_register--submit_buttons'}>
          <Button
            text={'戻る'}
            style={buttonStyle.backButton}
            onClick={() => {
              navigate(`/staff-plan/list`);
            }}
          />
          <Button
            text={statusFlag == 1 ? '更新' : '提出済にする'}
            style={buttonStyle.updateButton}
            onClick={() => {
              handlerFormikSubmit(formik, tabErrorGroup);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default StaffPlanEdit;
