import { useSelector } from 'react-redux';

const ImplementationDetail = () => {
  const { assignStaffDetail } = useSelector(state => state.staffMatter);

  return (
    <div className={'recruit_matter_detail--implementation_info_area'}>
      {[
        {
          title: '前日伝達事項',
          visibleFlag: assignStaffDetail?.previousDayInfoVisibilityFlag,
          info: assignStaffDetail?.previousDayInfo,
        },
        {
          title: '当日詳細',
          visibleFlag: assignStaffDetail?.shiftDayDetailVisibilityFlag,
          info: assignStaffDetail?.shiftDayDetail,
        },
        {
          title: '案件詳細',
          visibleFlag: assignStaffDetail?.matterDetailVisibilityFlag,
          info: assignStaffDetail?.matterDetail,
        },
        {
          title: '個別伝達事項',
          visibleFlag: assignStaffDetail?.privateInfoVisibilityFlag,
          info: assignStaffDetail?.privateInfo,
        },
      ]
        .filter(l => l.visibleFlag === 1)
        .map((l, i) => (
          <div
            key={l.title}
            className={'recruit_matter_detail--implementation_contents'}
            style={{
              borderBottom: i === 3 && 'none',
              paddingBottom: i === 3 && '0',
            }}
          >
            <label>{l.title}</label>
            <div className={'recruit_matter_detail--implementation_item'}>
              {l.info ?? ''}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ImplementationDetail;
