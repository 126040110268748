import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import {globalActions} from "./global";
import { store } from '../store';

const initialEditParam = {
  incomeAmountFrom: 0,
  incomeAmountTo: 0,
  calculationTaxFlag: 0,
  taxRate: 0,
  taxAmount: 0,
  kouTax0: 0,
  kouTax1: 0,
  kouTax2: 0,
  kouTax3: 0,
  kouTax4: 0,
  kouTax5: 0,
  kouTax6: 0,
  kouTax7: 0,
}
const initialState = {
  searchCount: 0,
  totalCount: 0,
  activeCount: 0,
  editParam: initialEditParam,
};

const initialParams = {
  // chartId: "A",
  count: 20,
  offset: 0,
};

export const incomeTaxInit = createAsyncThunk(
  'incometax/list',
  async (params, { dispatch }) => {
    const res = await fetchRequest(apiUrl.incomeTax.list, params ? params : initialParams);
    const item = res?.item ? res.item : {};
    let data = res;
    return {incomeTaxList: item, incomeTaxData: data, defaultTab: res?.header?.[0]?.['chartId']};
  }
);

export const incomeTaxInsert = createAsyncThunk(
  'income_tax/insert',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.incomeTax.insert, params, 'POST');
    dispatch(globalActions.showSingleModal(`登録しました`));
  }
);

export const incomeTaxHeaderUpdate = createAsyncThunk(
  'income_tax/header/update',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.incomeTax.headerUpdate, params, 'POST');
    dispatch(globalActions.showSingleModal(`更新しました`));
    dispatch(incomeTaxInit());
  }
);

export const incomeTaxUpdate = createAsyncThunk(
  'income_tax/update',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.incomeTax.update, params, 'POST');
    dispatch(globalActions.showSingleModal(`更新しました`));
  }
);

export const incomeTaxDelete = createAsyncThunk(
  'income_tax/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.incomeTax.delete, params, 'POST');
    store.dispatch(globalActions.showSingleModal('削除しました'));
    dispatch(incomeTaxInit());
  }
);

export const incomeTaxSlice = createSlice({
  name: 'incomeTax',
  initialState,
  reducers: {
  },
  extraReducers: {
    [incomeTaxInit.fulfilled]:(state, {payload}) => ({
      ...state,
      ...payload,
    }),
  }
});

export const incomeTaxActions = incomeTaxSlice.actions;
export default incomeTaxSlice.reducer;