import { memo } from 'react';
import { Modal } from 'antd';
import { globalActions } from '../../../redux/slice/global';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../button';
import classNames from 'classnames';
import './style.scss';

const GlobalModal = memo(() => {
  const dispatch = useDispatch();

  const { globalModalConfig } = useSelector(state => state.global);

  return (
    <Modal
      visible={!!globalModalConfig.title || !!globalModalConfig.message}
      centered
      footer={null}
      closable={false}
      width={480}
      onCancel={() => dispatch(globalActions.reset())}
      zIndex={9999}
    >
      <div className="global-modal">
        <div className="global-modal-title display-linebreak">
          {globalModalConfig.title}
        </div>
        {globalModalConfig.message && (
          <div
            className={classNames('modal-message', {
              'delete-message': globalModalConfig.deleteModal,
            })}
          >
            <span className="display-linebreak">
              {globalModalConfig.message}
            </span>
            <span className="tip">{globalModalConfig.tip}</span>
          </div>
        )}
        <div className="button-container">
          {(globalModalConfig.buttons ?? []).map((btn, i) => (
            <Button
              key={i}
              text={btn.btnText}
              style={btn.style}
              onClick={() => {
                dispatch(globalActions.reset());
                btn.btnAction && dispatch(btn.btnAction);
                btn.onClick?.();
              }}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
});

export default GlobalModal;
