import './style.scss';
import { useEffect } from 'react';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Copyright from '../../../components/copyright';
import { useNavigate } from 'react-router-dom';
import CsvImportModal from '../../../components/modal/csvImportModal';
import Options from '../../../constants/options';
import CsvExportModal from '../../../components/modal/csvExportModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  staffActions,
  staffDelete,
  staffInfoFetch,
  staffInit,
  staffListFetch,
} from '../../../redux/slice/staff';
import { useFormik } from 'formik';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import prefectures from '../../../constants/prefectures';
import options from '../../../constants/options';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import { clientActions } from '../../../redux/slice/client';
import { setCounter } from '../../../redux/slice/counter';
import { checkIsSp } from '../../../utils/fnUtil';

const StaffList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    namingCategoryList,
    namingList,
    certificationsList,
    staffList,
    userList,
    searchParams,
    csvImportVisible,
    csvExportVisible,
  } = useSelector(state => state.staff);
  const counterState = useSelector(state => state.counter);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => dispatch(staffActions.saveSearchParams(values)),
  });
  const {
    namingClassificationId,
    sortKey: sortColumn,
    sortMethod,
  } = formik.values;
  //ユーザーがお気に入りしたcsv出力設定(これをpropsでモーダルコンポーネントに渡す予定)
  const csvExportUserSettings = {
    ['基本情報']: true,
    ['身長・体重など']: true,
    ['給与支払情報']: true,
    ['交通情報']: false,
    ['学歴情報']: true,
    ['学歴・資格など']: true,
    ['属性情報']: true,
  };

  // 検索欄定義
  const StaffInfos = {
    inputs: [
      {
        inputType: 'input',
        label: 'スタッフID',
        inputName: 'staffId',
        placeholder: 'スタッフIDを入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '258px' },
        },
      },
      {
        inputType: 'input',
        label: '名前',
        inputName: 'staffName',
        placeholder: '名前を入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '258px' },
        },
      },
      {
        inputType: 'select',
        label: '性別',
        inputName: 'gender',
        showSearch: false,
        allowClear: false,
        initialValue: [1, 2, 99],
        selectedSelectBox: formik?.values?.gender,
        selectBoxOptions: ['男性', '女性', 'すべて'],
        placeholder: '選択してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '120px' },
        },
      },
      {
        inputType: 'select',
        label: '都道府県',
        mode: 'multiple',
        labelRequired: false, //ラベルの※マークの有無
        showSearch: false,
        allowClear: false,
        inputName: 'prefCode',
        initialValue: prefectures.map(p => p?.prefecturesCode),
        selectBoxOptions: prefectures.map(p => p?.label),
        placeholder: '選択してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '160px' },
        },
      },
      {
        inputType: 'select',
        label: 'ステータス',
        inputName: 'status',
        showSearch: false,
        allowClear: false,
        initialValue: options.codes.staffStatus.map(p => p?.value),
        selectBoxOptions: options.codes.staffStatus.map(p => p?.label),
        selectedSelectBox: formik?.values?.status,
        placeholder: '選択してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '150px' },
        },
      },
      {
        inputType: 'select',
        label: '身長',
        inputName: 'heightFrom',
        allowClear: true,
        initialValue: options.codes.height,
        selectedSelectBox: 100,
        selectBoxOptions: options.codes.height,
        placeholder: '選択してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: {
            width: checkIsSp() ? 'calc(50% - 15px)' : '160px',
            flexShrink: '0',
          },
        },
      },
      {
        inputType: 'select',
        label: ' ',
        inputName: 'heightTo',
        allowClear: true,
        initialValue: options.codes.height,
        selectedSelectBox: 200,
        selectBoxOptions: options.codes.height,
        placeholder: '選択してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: {
            width: checkIsSp() ? 'calc(50% - 15px)' : '160px',
            flexShrink: '0',
          },
        },
      },
      {
        inputType: 'select',
        label: '資格',
        inputName: 'certifications',
        showSearch: true,
        allowClear: false,
        mode: 'multiple',
        initialValue: certificationsList.map(p => p?.value),
        selectBoxOptions: certificationsList.map(p => p?.label),
        placeholder: '資格を選択してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '260px' },
        },
      },
      {
        inputType: 'select',
        label: '社内担当者',
        inputName: 'managerUserId',
        showSearch: true,
        allowClear: false,
        mode: 'multiple',
        initialValue: userList?.map(u => u?.userId),
        selectBoxOptions: userList?.map(
          u =>
            `${u?.userId}:${u?.name?.lastName ?? ''} ${
              u?.name?.firstName ?? ''
            }`
        ),
        placeholder: '社内担当者を入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '353px' },
        },
      },
      {
        inputType: 'select',
        label: '予備項目',
        showSearch: true,
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'namingClassificationId',
        initialValue: namingCategoryList.map(p => p?.value),
        selectBoxOptions: namingCategoryList.map(p => p?.label),
        // selectedSelectBox: namingCategoryList[0]?.value,
        placeholder: '予備項目を選択してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '235px' },
        },
      },
      {
        inputType: 'select', //inputのtype
        label: '', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'namingId',
        disabled: !namingClassificationId,
        showSearch: false,
        allowClear: false,
        mode: 'multiple',
        initialValue: namingList
          ?.filter(n => n?.namingClassificationId === namingClassificationId)
          .sort((a, b) => (parseInt(a.value) < parseInt(b.value) ? -1 : 1))
          .map(p => p?.value),
        selectBoxOptions: namingList
          ?.filter(n => n?.namingClassificationId === namingClassificationId)
          .sort((a, b) => (parseInt(a.value) < parseInt(b.value) ? -1 : 1))
          .map(p => `${p?.label}`),
        placeholder: '選択してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '291px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
    ],
    button: {
      width: '300px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#ffffff',
      fontSize: '14px',
      margin: '16px 0 0',
      padding: '0 0',
    },
  };

  // 検索欄実装部分
  const staffListSearchArea = () => (
    <div className={'staff_list--search_area'}>
      {StaffInfos.inputs.map(
        (info, idx) =>
          idx < 5 && (
            <CustomFormikInput {...info} formik={formik} key={info.inputName} />
          )
      )}
      <div className={'staff_list--search_area_height_unity'}>
        <CustomFormikInput
          {...StaffInfos.inputs[5]}
          formik={formik}
          key={StaffInfos.inputs[5].inputName}
        />
        <span className={'staff_list--height_from_to_span'}>〜</span>
        <CustomFormikInput
          {...StaffInfos.inputs[6]}
          formik={formik}
          key={StaffInfos.inputs[6].inputName}
        />
      </div>
      <CustomFormikInput
        {...StaffInfos.inputs[7]}
        formik={formik}
        key={StaffInfos.inputs[7].inputName}
      />
      <CustomFormikInput
        {...StaffInfos.inputs[8]}
        formik={formik}
        key={StaffInfos.inputs[8].inputName}
      />
      <div className={'staff_list--search_area_unity'}>
        <CustomFormikInput
          {...StaffInfos.inputs[9]}
          formik={formik}
          key={StaffInfos.inputs[9].inputName}
          selectedSelectBox={['StaffPreliminary1']}
          onChange={value => {
            handlerFormikFieldChange(
              formik,
              StaffInfos.inputs[9].inputName,
              value
            );
            handlerFormikFieldChange(
              formik,
              StaffInfos.inputs[10].inputName,
              []
            );
          }}
        />
        <CustomFormikInput
          {...StaffInfos.inputs[10]}
          formik={formik}
          key={StaffInfos.inputs[10].inputName}
        />
      </div>
    </div>
  );

  const searchFormInfo = {
    jsx: staffListSearchArea,
  };
  // テーブル定義
  const columnSettings = {
    staffId: { label: 'スタッフID', sort: 'asc', dataName: 'staffId' },
    image: { label: '画像', sort: 'none', dataName: 'staffImage' },
    staffName: { label: '名前', sort: 'none', dataName: 'staffName' },
    gender: { label: '性別', sort: 'none', dataName: 'gender' },
    prefName: { label: '都道府県', sort: 'none', dataName: 'prefCode' },
    nearestStation: {
      label: '最寄り駅',
      sort: 'none',
      dataName: 'nearestStation',
    },
    height: { label: '身長', sort: 'none', dataName: 'height' },
    mainManagerUserName: {
      label: 'メイン担当者',
      sort: 'none',
      dataName: 'mainManager',
    },
    subManagerUserName: {
      label: 'サブ担当者',
      sort: 'none',
      dataName: 'subManager',
    },
    status: { label: 'ステータス', sort: 'none', dataName: 'status' },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };
  // csv定義
  const buttonSettings = {
    viewPager: true,
    import: {
      icon: Icons.icon.import,
      text: 'CSV取込',
      onClick: () => dispatch(staffActions.setCsvImportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => dispatch(staffActions.setCsvExportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    addButton: {
      props: {
        type: '',
        onClick: () => {
          navigate('/staff/register');
        },
        url: '',
        displayText: true,
        disabled: '',
      },
    },
  };
  // ソート処理
  const changeSort = e => {
    dispatch(
      staffActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`,
      })
    );
  };
  // データ取得
  useEffect(() => {
    dispatch(staffInfoFetch());
    return () => {
      dispatch(staffActions.saveParam());
      dispatch(staffActions.refresh());
    };
  }, []);

  useEffect(() => {
    dispatch(staffListFetch(searchParams));
  }, [dispatch, searchParams]);
  useEffect(() => {
    dispatch(
      setCounter({
        ...counterState,
        staffCounter: staffList?.topCount ?? [0, 0],
      })
    );
  }, [staffList]);

  return (
    <>
      <div className={'commonPage--base'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'staffId'}
          primaryName={'staffName'}
          apiData={staffList}
          sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(staffActions.saveSearchParams({ count }))
          }
          onChangeOffset={offset => dispatch(staffActions.saveOffset(offset))}
          onDelete={staffId => {
            dispatch(staffDelete({ staffId }));
          }}
        />
        <Copyright />
      </div>

      <CsvImportModal
        importType={'staffList'}
        csvImportVisible={csvImportVisible}
        setCsvImportVisible={() =>
          dispatch(staffActions.setCsvImportVisible(!csvImportVisible))
        }
      />
      <CsvExportModal
        exportType={'staffList'}
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={() =>
          dispatch(staffActions.setCsvExportVisible(!csvExportVisible))
        }
        exportFunction={() => {}}
        searchParams={searchParams}
      />
    </>
  );
};

export default StaffList;
