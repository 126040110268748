import './style.scss';
import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
import { useLocation } from 'react-router-dom';
import Options from '../../../constants/options';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import prefectures from '../../../constants/prefectures';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { useFormik } from 'formik';
import {
  clientActions,
  clientInsert,
  clientUpdate,
  uploadImage,
  clientDetailFetch,
  clientInfoFetch,
  clientInit,
  getClientId,
} from '../../../redux/slice/client';
import {
  handlerFormikFieldChange,
  handlerFormikSubmit,
} from '../../../utils/fnUtil';
import { globalActions } from '../../../redux/slice/global';
import Yup from '../../../utils/yupUtil';
import { showApiError, zipApi } from '../../../api/fetch';
import moment from 'moment';
import { checkIsSp } from '../../../utils/fnUtil';
import { apiUrl } from '../../../api/apiUrl';

const isSp = checkIsSp();

const style = {
  areaStyle: { width: isSp ? '100%' : '65%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const prefStyle = {
  areaStyle: {
    width: isSp ? '100%' : '18%',
    minWidth: isSp ? '100%' : '244px',
  },
  labelStyle: { width: '110px' },
};

const managerStyle = {
  areaStyle: { width: isSp ? '100%' : '45%' },
  labelStyle: { width: '110px' },
};

const selectWthButtonStyle = {
  areaStyle: { width: isSp ? '100%' : '65%' },
  labelStyle: { width: '110px' },
  inputStyle: { color: '#bcbcbc' },
  buttonStyle: {},
};

const tabStyle = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'column',
  gap: '20px',
  marginTop: '26px',
  marginLeft: isSp ? '0px' : '70px',
  minWidth: isSp ? '100%' : '1000px',
};

const requestStyle = {
  areaStyle: { width: isSp ? '100%' : '768px' },
  inputStyle: {},
  labelStyle: { width: '130px' },
};

const remarkStyle = {
  areaStyle: { width: isSp ? '100%' : '80%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const ClientRegister = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const isEdit = location.pathname.slice(5).includes('edit');
  const isDeletedClient = isEdit && location?.state?.deletedFlag !== 0;
  const dispatch = useDispatch();

  const {
    payTermList,
    userList,
    editParams,
    zipSearch,
    namingLabel,
    namingListPriorityAsc,
  } = useSelector(state => state.client);
  const initialPayTermObj = payTermList?.filter(p => p.priority === 1)?.shift();
  const { userId } = useSelector(state => state.account);
  const tabErrorGroup = {
    common: [
      'ntaNumber',
      'division',
      'postCode1',
      'postCode2',
      'tel1',
      'tel2',
      'tel3',
      'fax1',
      'fax2',
      'fax3',
      'url',
      'managerUserId',
      'clientManagerName',
    ],
  };
  const [clientIds, setClientIds] = useState([]);
  const [isApiError, setIsApiError] = useState();
  const nowDate = new Date();

  const formik = useFormik({
    initialValues: {
      ...editParams,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      clientId: Yup.string()
        .nullable()
        .required()
        .matches(/^\d*$/, '取引先ID:半角数字を入力してください。')
        .test(
          'clientId',
          '取引先ID:0から始まる数字は無効です。',
          (value, context) => {
            if (value?.slice(0, 1) === '0') {
              return false;
            } else {
              return true;
            }
          }
        )
        .test(
          'clientId',
          '取引先ID:既に登録済みの取引先IDと重複しています。',
          (value, context) => {
            if (value === isApiError) {
              return false;
            } else {
              return true;
            }
          }
        )
        .label('取引先ID'),
      clientName: Yup.string().nullable().required().label('取引先名'),
      clientNameKana: Yup.string()
        .nullable()
        .required()
        .kana('取引先名(カナ)')
        .label('取引先名(カナ)'),
      startDate: Yup.string().required().nullable().label('開始日'),
      endDate: Yup.string().required().nullable().label('終了日'),
      ntaNumber: Yup.string()
        .required()
        .nullable()
        .ntaNumber('[基本情報]国税庁法人番号')
        .label('[基本情報]国税庁法人番号'),
      division: Yup.string()
        .required()
        .nullable()
        .label('[基本情報]支払方法区分'),
      postCode: Yup.string().nullable().postCode('[基本情報]郵便番号'),
      postCode1: Yup.string().nullable().integer('[基本情報]郵便番号'),
      postCode2: Yup.string().nullable().integer('[基本情報]郵便番号'),
      tel: Yup.string().nullable().tel('[基本情報]TEL'),
      tel1: Yup.string().nullable().integer('[基本情報]TEL'),
      tel2: Yup.string().nullable().integer('[基本情報]TEL'),
      tel3: Yup.string().nullable().integer('[基本情報]TEL'),
      fax: Yup.string().nullable().fax('[基本情報]FAX'),
      fax1: Yup.string().nullable().integer('[基本情報]FAX'),
      fax2: Yup.string().nullable().integer('[基本情報]FAX'),
      fax3: Yup.string().nullable().integer('[基本情報]FAX'),
      url: Yup.string().nullable().url('[基本情報]URL').label('[基本情報]URL'),
      managerUserId: Yup.string().nullable().label('[請求情報]社内担当者'),
      clientManagerName: Yup.string()
        .nullable()
        .label('[基本情報]取引先担当者'),
    }),
    onSubmit: values => {
      isEdit
        ? dispatch(
            clientUpdate({
              ...values,
              postCode:
                values.postCode1 && values.postCode2
                  ? {
                      number1: values.postCode1,
                      number2: values.postCode2,
                    }
                  : null,
              fax:
                values.fax1 && values.fax2 && values.fax3
                  ? {
                      number1: values.fax1,
                      number2: values.fax2,
                      number3: values.fax3,
                    }
                  : null,
              tel:
                values.tel1 && values.tel2 && values.tel3
                  ? {
                      number1: values.tel1,
                      number2: values.tel2,
                      number3: values.tel3,
                    }
                  : null,
            })
          )
            .unwrap()
            .then(result => {
              if (result?.code == '0') {
                dispatch(clientActions.zipSearchFlag(false));
                dispatch(globalActions.showSingleModal('更新しました'));
                navigate(-1);
              }
            })
        : dispatch(
            clientInsert({
              ...values,
              postCode:
                values.postCode1 && values.postCode2
                  ? {
                      number1: values.postCode1,
                      number2: values.postCode2,
                    }
                  : null,
              fax:
                values.fax1 && values.fax2 && values.fax3
                  ? {
                      number1: values.fax1,
                      number2: values.fax2,
                      number3: values.fax3,
                    }
                  : null,
              tel:
                values.tel1 && values.tel2 && values.tel3
                  ? {
                      number1: values.tel1,
                      number2: values.tel2,
                      number3: values.tel3,
                    }
                  : null,
            })
          )
            .unwrap()
            .then(result => {
              if (result?.code === '0') {
                dispatch(clientActions.zipSearchFlag(false));
                dispatch(globalActions.showSingleModal('登録しました'));
                navigate(-1);
              } else {
                showApiError(result); //fetchAPIでAPIエラーを返すメソッド
              }
            })
            .catch(() => {
              formik.setFieldError(
                'clientId',
                '取引先ID:既に登録済みの取引先IDと重複しています。'
              );
              setIsApiError(formik.values.clientId); //ID重複した時点の値をセットしてバリデーションで使用する
              formik.setFieldTouched({ clientId: true });
            });
    },
  });
  const {
    clientId,
    postCode1,
    postCode2,
    preliminary1,
    preliminary2,
    preliminary3,
    preliminary4,
    preliminary5,
    clientImage,
    division,
  } = formik.values;

  const handleFileChange = e => {
    let fileData = e.target.files[0];
    if (fileData?.size > 1024 * 1024) {
      alert('画像は1MB以下でアップロードしてください。');
    } else {
      const reader = new FileReader();
      reader.onload = async event => {
        const imageUri = event.currentTarget.result;
        dispatch(
          uploadImage({
            image: imageUri,
            contentFlag: 3,
            userId: userId,
          })
        ).then(
          ({ payload }) =>
            payload?.code === '0' &&
            handlerFormikFieldChange(formik, 'clientImage', payload.imageUrl)
        );
      };
      reader.readAsDataURL(fileData);
    }
  };

  const Common = () => (
    <div style={tabStyle}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
        }}
      >
        <CustomFormikInput
          inputType={'input'}
          label={'国税庁法人番号'}
          labelRequired={true}
          inputName={'ntaNumber'}
          placeholder={'国税庁法人番号を入力してください'}
          style={style}
          formik={formik}
        />
      </div>
      <div>
        <span
          style={
            isSp
              ? {
                  textAlign: 'right',
                  position: 'absolute',
                  right: '20px',
                  whiteSpace: 'nowrap',
                }
              : {
                  display: 'none',
                }
          }
        >
          {' '}
          国税庁法人番号検索は
          <a
            style={{ color: '#D80245', textDecoration: 'underline' }}
            href={apiUrl.houjinBangou}
            target="_blank"
          >
            こちら
            <img
              src={Icons.icon.externalLink}
              style={{
                marginLeft: '4px',
                width: '22px',
                height: '22px',
                verticalAlign: 'top',
              }}
            ></img>
          </a>
        </span>
      </div>
      <div
        className={'custom_input--area'}
        style={{ width: isSp ? '100%' : '50%' }}
      >
        <CustomFormikInput
          formik={formik}
          inputType={'radioButton'}
          radioButtonTitle={'区分'}
          labelRequired={true}
          inputName={'division'}
          radioButtonLabelAndChecked={[
            { label: '日系', checked: division === 0 },
            { label: '外資系', checked: division === 1 },
          ]}
          initialValue={['0', '1']}
          style={{
            labelStyle: { width: '110px' },
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
        }}
      >
        <div
          className={'custom_input--area'}
          style={
            isSp
              ? {
                  width: '100%',
                  gap: '8px',
                  display: 'contents',
                }
              : { width: '50%', gap: '8px' }
          }
        >
          <CustomFormikInput
            inputType="input"
            label="郵便番号"
            inputName={'postCode1'}
            key={'postCode1'}
            formik={formik}
            placeholder={'123'}
            unit={'-'}
            style={{
              labelStyle: isSp ? {} : { width: '110px' },
              areaStyle: {
                width: isSp ? 'calc(((100% - 40px) / 2) + 20px)' : '257px',
              },
              unitStyle: { width: '6px' },
            }}
          />
          <CustomFormikInput
            inputType="input"
            inputName={'postCode2'}
            key={'postCode2'}
            formik={formik}
            placeholder={'4567'}
            style={{
              areaStyle: isSp
                ? {
                    width: 'calc(((100% - 40px) / 2) + 20px)',
                    paddingLeft: '8px',
                  }
                : { width: '110px' },
            }}
          />
        </div>
        <span
          style={
            isSp
              ? {
                  display: 'none',
                }
              : {
                  width: '65%',
                  textAlign: 'right',
                  position: 'relative',
                  top: '-138px',
                  right: '0',
                  whiteSpace: 'nowrap',
                }
          }
        >
          {' '}
          国税庁法人番号検索は
          <a
            style={{ color: '#D80245', textDecoration: 'underline' }}
            href={apiUrl.houjinBangou}
            target="_blank"
          >
            こちら
            <img
              src={Icons.icon.externalLink}
              style={{
                marginLeft: '4px',
                width: '22px',
                height: '22px',
                verticalAlign: 'top',
              }}
            ></img>
          </a>
        </span>
      </div>
      <CustomFormikInput
        formik={formik}
        inputType={'select'}
        label={'都道府県'}
        labelRequired={false}
        inputName={'prefCode'}
        initialValue={prefectures
          .filter(p => p.prefecturesCode !== '')
          .map(p => p.prefecturesCode)}
        selectBoxOptions={prefectures
          .filter(p => p.prefecturesCode !== '')
          .map(p => p.label)}
        placeholder={'都道府県'}
        style={prefStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType={'input'}
        label={'市区町村'}
        labelRequired={false}
        inputName={'cityName'}
        placeholder={'市区町村を入力してください'}
        style={style}
      />
      <CustomFormikInput
        formik={formik}
        inputType={'input'}
        label={'丁目番地'}
        labelRequired={false}
        inputName={'streetName'}
        placeholder={'丁目番地を入力してください'}
        style={style}
      />
      <CustomFormikInput
        formik={formik}
        inputType={'input'}
        label={'建物名'}
        labelRequired={false}
        inputName={'buildingName'}
        placeholder={'建物名を入力してください'}
        style={style}
      />
      <div
        className={'tel_area_for_CFI'}
        style={{ width: isSp ? '100%' : '500px' }}
      >
        <CustomFormikInput
          inputType="input"
          label="TEL"
          inputName={'tel1'}
          key={'tel1'}
          formik={formik}
          placeholder={'03'}
          unit={'-'}
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                }
              : {},
            labelStyle: { width: '110px' },
            inputStyle: isSp
              ? {}
              : { width: 'calc(((500px - 110px - 24px) - 18.86px * 2) / 3)' },
          }}
        />
        <CustomFormikInput
          inputType="input"
          label={' '}
          inputName={'tel2'}
          key={'tel2'}
          formik={formik}
          placeholder={'0000'}
          unit={'-'}
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                }
              : {},
            labelStyle: isSp ? {} : { display: 'none' },
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((500px - 110px - 24px) - 18.86px * 2) / 3)',
                },
          }}
        />
        <CustomFormikInput
          inputType="input"
          label={' '}
          inputName={'tel3'}
          key={'tel3'}
          formik={formik}
          placeholder={'0000'}
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3))',
                }
              : {},
            labelStyle: isSp ? {} : { display: 'none' },
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((500px - 110px - 24px) - 18.86px * 2) / 3)',
                },
          }}
        />
      </div>
      <div
        className={'tel_area_for_CFI'}
        style={{ width: isSp ? '100%' : '500px' }}
      >
        <CustomFormikInput
          inputType="input"
          label="FAX"
          inputName={'fax1'}
          key={'fax1'}
          formik={formik}
          placeholder={'03'}
          unit={'-'}
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                }
              : {},
            labelStyle: { width: '110px' },
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((500px - 110px - 24px) - 18.86px * 2) / 3)',
                },
          }}
        />
        <CustomFormikInput
          inputType="input"
          label={' '}
          inputName={'fax2'}
          key={'fax2'}
          formik={formik}
          placeholder={'0000'}
          unit={'-'}
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                }
              : {},
            labelStyle: isSp ? {} : { display: 'none' },
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((500px - 110px - 24px) - 18.86px * 2) / 3)',
                },
          }}
        />
        <CustomFormikInput
          inputType="input"
          label={' '}
          inputName={'fax3'}
          key={'fax3'}
          formik={formik}
          placeholder={'0000'}
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3))',
                }
              : {},
            labelStyle: isSp ? {} : { display: 'none' },
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((500px - 110px - 24px) - 18.86px * 2) / 3)',
                },
          }}
        />
      </div>

      <CustomFormikInput
        formik={formik}
        inputType={'input'}
        label={'URL'}
        labelRequired={false}
        inputName={'url'}
        placeholder={'URLを入力してください'}
        style={style}
      />
      <CustomFormikInput
        formik={formik}
        inputType={'select'}
        label={'社内担当者'}
        inputName={'managerUserId'}
        initialValue={userList
          ?.filter(u => u.deletedFlag === '0')
          ?.map(u => u?.userId)}
        selectBoxOptions={userList
          ?.filter(u => u.deletedFlag === '0')
          ?.map(
            u =>
              `${u?.userId}:${u?.name?.lastName ?? ''} ${
                u?.name?.firstName ?? ''
              }`
          )}
        placeholder={'社内担当者を選択してください'}
        style={managerStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType={'input'}
        label={'取引先担当者'}
        labelRequired={false}
        inputName={'clientManagerName'}
        placeholder={'取引先担当者を入力してください'}
        style={managerStyle}
      />
    </div>
  );

  const Request = () => (
    <div style={{ ...tabStyle, marginLeft: isSp ? 0 : '50px' }}>
      <CustomFormikInput
        formik={formik}
        inputType={'select'}
        label={'デフォルト支払条件'}
        labelRequired={false}
        inputName={'payTermId'}
        initialValue={payTermList.map(p => p?.value)}
        selectBoxOptions={payTermList.map(p => p?.label)}
        placeholder={'デフォルト支払条件を選択してください'}
        style={requestStyle}
      />
    </div>
  );

  const Remark = () => (
    <div style={tabStyle}>
      <CustomFormikInput
        formik={formik}
        inputType={'select'}
        label={namingLabel
          ?.filter(n => n.namingId === `ClientPreliminary1`)
          .map(p => p?.label)}
        labelRequired={false}
        inputName={'preliminary1'}
        initialValue={namingListPriorityAsc
          ?.filter(
            n =>
              n.namingClassificationId === 'ClientPreliminary1' &&
              n.status !== 2
          )
          .map(p => p?.value)}
        selectBoxOptions={namingListPriorityAsc
          ?.filter(
            n =>
              n.namingClassificationId === 'ClientPreliminary1' &&
              n.status !== 2
          )
          .map(p => `${p?.namingId}:${p?.label}`)}
        selectedSelectBox={preliminary1}
        placeholder={'選択してください'}
        style={remarkStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType={'select'}
        label={namingLabel
          ?.filter(n => n.namingId === `ClientPreliminary2`)
          .map(p => p?.label)}
        labelRequired={false}
        inputName={'preliminary2'}
        initialValue={namingListPriorityAsc
          ?.filter(
            n =>
              n.namingClassificationId === 'ClientPreliminary2' &&
              n.status !== 2
          )
          .map(p => p?.value)}
        selectBoxOptions={namingListPriorityAsc
          ?.filter(
            n =>
              n.namingClassificationId === 'ClientPreliminary2' &&
              n.status !== 2
          )
          .map(p => `${p?.namingId}:${p?.label}`)}
        selectedSelectBox={preliminary2}
        placeholder={'選択してください'}
        style={remarkStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType={'select'}
        label={namingLabel
          ?.filter(n => n.namingId === `ClientPreliminary3`)
          .map(p => p?.label)}
        labelRequired={false}
        inputName={'preliminary3'}
        initialValue={namingListPriorityAsc
          ?.filter(
            n =>
              n.namingClassificationId === 'ClientPreliminary3' &&
              n.status !== 2
          )
          .map(p => p?.value)}
        selectBoxOptions={namingListPriorityAsc
          ?.filter(
            n =>
              n.namingClassificationId === 'ClientPreliminary3' &&
              n.status !== 2
          )
          .map(p => `${p?.namingId}:${p?.label}`)}
        selectedSelectBox={preliminary3}
        placeholder={'選択してください'}
        style={remarkStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType={'select'}
        label={namingLabel
          ?.filter(n => n.namingId === `ClientPreliminary4`)
          .map(p => p?.label)}
        labelRequired={false}
        inputName={'preliminary4'}
        initialValue={namingListPriorityAsc
          ?.filter(
            n =>
              n.namingClassificationId === 'ClientPreliminary4' &&
              n.status !== 2
          )
          .map(p => p?.value)}
        selectBoxOptions={namingListPriorityAsc
          ?.filter(
            n =>
              n.namingClassificationId === 'ClientPreliminary4' &&
              n.status !== 2
          )
          .map(p => `${p?.namingId}:${p?.label}`)}
        selectedSelectBox={preliminary4}
        placeholder={'選択してください'}
        style={remarkStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType={'select'}
        label={namingLabel
          ?.filter(n => n.namingId === `ClientPreliminary5`)
          .map(p => p?.label)}
        labelRequired={false}
        inputName={'preliminary5'}
        initialValue={namingListPriorityAsc
          ?.filter(
            n =>
              n.namingClassificationId === 'ClientPreliminary5' &&
              n.status !== 2
          )
          .map(p => p?.value)}
        selectBoxOptions={namingListPriorityAsc
          ?.filter(
            n =>
              n.namingClassificationId === 'ClientPreliminary5' &&
              n.status !== 2
          )
          .map(p => `${p?.namingId}:${p?.label}`)}
        selectedSelectBox={preliminary5}
        placeholder={'選択してください'}
        style={remarkStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType={'textBox'}
        label={'備考'}
        labelRequired={false}
        inputName={'note'}
        placeholder={'備考を入力してください'}
        style={remarkStyle}
      />
    </div>
  );

  const infos = {
    updates: isEdit
      ? [
          {
            label: '新規登録',
            datetime:
              moment(formik?.values?.createdUser?.datetime).format(
                'YYYY/MM/DD hh:mm:ss'
              ) ?? '-',
            name: formik?.values?.createdUser?.name ?? '-',
          },
          {
            label: '最終更新',
            datetime:
              moment(formik?.values?.updatedUser?.datetime).format(
                'YYYY/MM/DD hh:mm:ss'
              ) ?? '-',
            name: formik?.values?.updatedUser?.name ?? '-',
          },
        ]
      : [],
    basic: {
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: isEdit && isSp ? '32px' : isSp ? '32px' : '24px',
        minWidth: isSp ? '100%' : '500px',
      },
      inputs: [
        {
          inputType: 'input', //inputのtype
          label: '取引先ID', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'clientId', //DBのカラム名
          disabled: isEdit,
          placeholder: !isEdit && '取引先IDを入力してください',
          unit: '', //インプット横の単位
          className: {
            areaClass: isEdit && 'client_register--client_id_field',
          },
          style: {
            labelStyle: { width: isSp && isEdit ? 'auto' : '120px' },
            inputStyle: isEdit
              ? {
                  border: '0px',
                  background: '#e9f2fc',
                  color: '#2b3245',
                  marginLeft: isSp && isEdit ? '0' : '-15px',
                }
              : {},
          },
        },
        {
          inputType: 'input', //inputのtype
          label: '取引先名', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'clientName', //DBのカラム名
          placeholder: '取引先名を入力してください',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {},
            labelStyle: { width: '120px' },
            inputStyle: {},
          },
        },
        {
          inputType: 'input', //inputのtype
          label: '取引先名(カナ)', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'clientNameKana', //DBのカラム名
          placeholder: '取引先名(カナ)を入力してください',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {},
            labelStyle: { width: '120px' },
            inputStyle: {},
          },
        },
        {
          inputType: 'inputDate', //inputのtype
          label: '開始日', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'startDate', //DBのカラム名
          disabledFutureDate: formik.values.endDate,
          placeholder: 'YYYY/MM/DD',
          unit: '', //インプット横の単位
          style: {
            areaStyle: { width: isSp ? '50%' : '310px' },
            labelStyle: { width: '120px' },
            inputStyle: { width: '135px' },
          },
        },
        {
          inputType: 'inputDate', //inputのtype
          label: '終了日', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'endDate', //DBのカラム名
          disabledDate: formik.values.startDate,
          placeholder: 'YYYY/MM/DD',
          unit: '', //インプット横の単位
          style: {
            areaStyle: { width: isSp ? '50%' : '310px' },
            labelStyle: { width: '120px' },
            inputStyle: { width: '135px' },
          },
        },
      ],
    },

    detail: {
      tabs: {
        common: {
          label: '基本情報',
          jsx: Common,
        },
        request: {
          label: '請求情報',
          jsx: Request,
        },
        remark: {
          label: '備考情報',
          jsx: Remark,
        },
      },
    },
  };

  useEffect(() => {
    if (!isEdit) {
      dispatch(getClientId())
        .unwrap()
        .then(data => {
          let newClientIds = data?.clientId?.item?.[0]['clientId'] ?? 0;
          formik.setValues({
            ...editParams,
            clientId: Number(newClientIds) + 1,
            startDate: moment(nowDate).format('YYYY/M/D'),
            endDate: '2999/12/31',
          });
          setClientIds(newClientIds);
        })
        .catch(() => {
          formik.setValues({
            ...editParams,
            clientId: 1,
            startDate: moment(nowDate).format('YYYY/M/D'),
            endDate: '2999/12/31',
          });
          setClientIds(1);
        });
    }
    dispatch(clientInit());
    dispatch(clientActions.resetParam());
    dispatch(clientActions.zipSearchFlag(false));
    !isEdit && dispatch(clientActions.zipSearchFlag(true));
    dispatch(clientInfoFetch());
    isEdit &&
      dispatch(
        clientDetailFetch({
          clientId: location?.state?.id,
          deletedFlag: isDeletedClient ? location?.state?.deletedFlag : '0',
        })
      )
        .unwrap()
        .then(result => {
          formik.setValues({
            clientId: result?.detail?.clientId ?? null,
            clientName: result?.detail?.clientName ?? null,
            clientNameKana: result?.detail?.clientNameKana ?? null,
            startDate:
              result?.detail?.startDate ?? moment(nowDate).format('YYYY/M/D'),
            endDate: result?.detail?.endDate ?? '2999/12/31',
            clientImage: result?.detail?.clientImage ?? null,
            ntaNumber: result?.detail?.ntaNumber ?? null,
            division: result?.detail?.division ?? 0,
            postCode1: result?.detail?.postCode?.number1 ?? null,
            postCode2: result?.detail?.postCode?.number2 ?? null,
            prefCode: result?.detail?.prefCode ?? null,
            cityName: result?.detail?.cityName ?? null,
            streetName: result?.detail?.streetName ?? null,
            buildingName: result?.detail?.buildingName ?? null,
            tel1: result?.detail?.tel?.number1 ?? null,
            tel2: result?.detail?.tel?.number2 ?? null,
            tel3: result?.detail?.tel?.number3 ?? null,
            fax1: result?.detail?.fax?.number1 ?? null,
            fax2: result?.detail?.fax?.number2 ?? null,
            fax3: result?.detail?.fax?.number3 ?? null,
            url: result?.detail?.url ?? null,
            payTermId: result?.detail?.payTermId ?? null,
            managerUserId: result?.detail?.managerUserId ?? null,
            clientManagerName: result?.detail?.clientManagerName ?? null,
            preliminary1: result?.detail?.preliminary1 ?? null,
            preliminary2: result?.detail?.preliminary2 ?? null,
            preliminary3: result?.detail?.preliminary3 ?? null,
            preliminary4: result?.detail?.preliminary4 ?? null,
            preliminary5: result?.detail?.preliminary5 ?? null,
            note: result?.detail?.note ?? null,
            createdUser: result?.detail?.createdUser ?? {},
            updatedUser: result?.detail?.updatedUser ?? {},
          });
        })
        .then(() => {
          dispatch(clientActions.zipSearchFlag(true));
        });
  }, []);

  useEffect(() => {
    if (!isEdit) {
      formik.setFieldValue('payTermId', initialPayTermObj?.payTermsId);
    }
  }, [payTermList]);

  // 住所検索
  useEffect(() => {
    zipSearch &&
      zipApi(
        formik,
        'postCode1',
        'postCode2',
        'prefCode',
        'cityName',
        'streetName'
      );
  }, [postCode1, postCode2]);

  return (
    <div className={'client_register--area'}>
      <RegisterBase
        image={clientImage ? clientImage : Icons.icon.noImage}
        infos={infos}
        formik={formik}
        setImage={handleFileChange}
      />

      <div className={'bottom_btn_area'} style={{ height: '92px' }}>
        <div
          className={'client_register--submit_buttons'}
          style={
            isDeletedClient
              ? { marginTop: '24px', justifyContent: 'center' }
              : { marginTop: '24px' }
          }
        >
          <Button
            text={'戻る'}
            style={Options.buttonStyles.back}
            onClick={() => {
              dispatch(clientActions.zipSearchFlag(false));
              navigate(-1);
            }}
          />

          {!isDeletedClient && (
            <Button
              text={isEdit ? '更新' : '登録'}
              onClick={() => {
                handlerFormikSubmit(formik, tabErrorGroup);
              }}
              style={Options.buttonStyles.submit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientRegister;
