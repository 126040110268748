const prefectures = [
  {
    label: '北海道',
    value: '北海道',
    prefecturesCode: '1',
  },
  {
    label: '青森県',
    value: '青森県',
    prefecturesCode: '2',
  },
  {
    label: '岩手県',
    value: '岩手県',
    prefecturesCode: '3',
  },
  {
    label: '宮城県',
    value: '宮城県',
    prefecturesCode: '4',
  },
  {
    label: '秋田県',
    value: '秋田県',
    prefecturesCode: '5',
  },
  {
    label: '山形県',
    value: '山形県',
    prefecturesCode: '6',
  },
  {
    label: '福島県',
    value: '福島県',
    prefecturesCode: '7',
  },
  {
    label: '茨城県',
    value: '茨城県',
    prefecturesCode: '8',
  },
  {
    label: '栃木県',
    value: '栃木県',
    prefecturesCode: '9',
  },
  {
    label: '群馬県',
    value: '群馬県',
    prefecturesCode: '10',
  },
  {
    label: '埼玉県',
    value: '埼玉県',
    prefecturesCode: '11',
  },
  {
    label: '千葉県',
    value: '千葉県',
    prefecturesCode: '12',
  },
  {
    label: '東京都',
    value: '東京都',
    prefecturesCode: '13',
  },
  {
    label: '神奈川県',
    value: '神奈川県',
    prefecturesCode: '14',
  },
  {
    label: '新潟県',
    value: '新潟県',
    prefecturesCode: '15',
  },
  {
    label: '富山県',
    value: '富山県',
    prefecturesCode: '16',
  },
  {
    label: '石川県',
    value: '石川県',
    prefecturesCode: '17',
  },
  {
    label: '福井県',
    value: '福井県',
    prefecturesCode: '18',
  },
  {
    label: '山梨県',
    value: '山梨県',
    prefecturesCode: '19',
  },
  {
    label: '長野県',
    value: '長野県',
    prefecturesCode: '20',
  },
  {
    label: '岐阜県',
    value: '岐阜県',
    prefecturesCode: '21',
  },
  {
    label: '静岡県',
    value: '静岡県',
    prefecturesCode: '22',
  },
  {
    label: '愛知県',
    value: '愛知県',
    prefecturesCode: '23',
  },
  {
    label: '三重県',
    value: '三重県',
    prefecturesCode: '24',
  },
  {
    label: '滋賀県',
    value: '滋賀県',
    prefecturesCode: '25',
  },
  {
    label: '京都府',
    value: '京都府',
    prefecturesCode: '26',
  },
  {
    label: '大阪府',
    value: '大阪府',
    prefecturesCode: '27',
  },
  {
    label: '兵庫県',
    value: '兵庫県',
    prefecturesCode: '28',
  },
  {
    label: '奈良県',
    value: '奈良県',
    prefecturesCode: '29',
  },
  {
    label: '和歌山県',
    value: '和歌山県',
    prefecturesCode: '30',
  },
  {
    label: '鳥取県',
    value: '鳥取県',
    prefecturesCode: '31',
  },
  {
    label: '島根県',
    value: '島根県',
    prefecturesCode: '32',
  },
  {
    label: '岡山県',
    value: '岡山県',
    prefecturesCode: '33',
  },
  {
    label: '広島県',
    value: '広島県',
    prefecturesCode: '34',
  },
  {
    label: '山口県',
    value: '山口県',
    prefecturesCode: '35',
  },
  {
    label: '徳島県',
    value: '徳島県',
    prefecturesCode: '36',
  },
  {
    label: '香川県',
    value: '香川県',
    prefecturesCode: '37',
  },
  {
    label: '愛媛県',
    value: '愛媛県',
    prefecturesCode: '38',
  },
  {
    label: '高知県',
    value: '高知県',
    prefecturesCode: '39',
  },
  {
    label: '福岡県',
    value: '福岡県',
    prefecturesCode: '40',
  },
  {
    label: '佐賀県',
    value: '佐賀県',
    prefecturesCode: '41',
  },
  {
    label: '長崎県',
    value: '長崎県',
    prefecturesCode: '42',
  },
  {
    label: '熊本県',
    value: '熊本県',
    prefecturesCode: '43',
  },
  {
    label: '大分県',
    value: '大分県',
    prefecturesCode: '44',
  },
  {
    label: '宮崎県',
    value: '宮崎県',
    prefecturesCode: '45',
  },
  {
    label: '鹿児島県',
    value: '鹿児島県',
    prefecturesCode: '46',
  },
  {
    label: '沖縄県',
    value: '沖縄県',
    prefecturesCode: '47',
  },
  {
    label: '海外',
    value: '海外',
    prefecturesCode: '99',
  },
];
export default prefectures;
