import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import Button from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss';
import React, { useEffect } from 'react';
import StaffRegisterInfos from '../../staff/inputInfos';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Yup from '../../../utils/yupUtil';
import { handlerFormikSubmit, checkIsSp } from '../../../utils/fnUtil';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { globalActions } from '../../../redux/slice/global';
import BasicInfos from './basicInfos';
import AccessInfos from './accessInfos';
import BodyInfos from './bodyInfos';
import BackGroundInfos from './backgroundInfos';
import SalaryInfos from './salaryInfos';
import {
  bankingFetch,
  staffActions,
  staffDetailFetch,
  staffDetailUpdate,
  staffInfoFetch,
} from '../../../redux/slice/staff';

import MyPageForSp from '../forSp';

const MyPage = () => {
  const loginType = useSelector(state => state.loginType.type);
  const isStaff = loginType === 'staff';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountInfo = useSelector(state => state.account);
  const { editParams } = useSelector(state => state.staff);
  const { isOpenSideMenu } = useSelector(state => state.global);

  const tabErrorGroup = {
    common: [
      'subManagerName',
      'postCode1',
      'postCode2',
      'tel1',
      'tel2',
      'tel3',
      'fax1',
      'fax2',
      'fax3',
      'dependents',
    ],
    traffic: [
      'stationName',
      'timeRequired',
      'wayStation',
      'busStationNameFrom',
      'busStationNameTo',
    ],
    body: ['height', 'weight', 'shoeSize'],
    skill: ['admissionYear'],
    pay: [
      'bankName',
      'bankCode',
      'bankBranchName',
      'bankBranchCode',
      'bankAccountNumber',
      'bankAccountName',
    ],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editParams,
    validateOnMount: true,
    validate: () => {
      if (formik.errors.postCode1) {
        delete formik.errors.postCode2;
        delete formik.errors.postCode;
      } else if (formik.errors.postCode2) {
        delete formik.errors.postCode1;
        delete formik.errors.postCode;
      } else if (formik.errors.postCode) {
        delete formik.errors.postCode1;
        delete formik.errors.postCode2;
      }
      if (formik.errors.tel1) {
        delete formik.errors.tel2;
        delete formik.errors.tel3;
        delete formik.errors.tel;
      }
      if (formik.errors.fax1) {
        delete formik.errors.fax2;
        delete formik.errors.fax3;
        delete formik.errors.fax;
      }
    },
    validationSchema: Yup.object({
      staffId: Yup.string().required(),
      lastName: Yup.string().nullable().required().label('名前 姓'),
      firstName: Yup.string().nullable().required().label('名前 名'),
      lastNameKana: Yup.string()
        .nullable()
        .required()
        .kana('名前(カナ)セイ')
        .label('名前(カナ)セイ'),
      firstNameKana: Yup.string()
        .nullable()
        .required()
        .kana('名前(カナ)セイ')
        .label('名前(カナ)メイ'),
      mailAddress: Yup.string()
        .nullable()
        .required()
        .mail('メールアドレス')
        .mail_local('メールアドレス')
        .email('メールアドレスの形式が異なります。')
        .label('メールアドレス'),
      year: Yup.string().nullable().required().label('[基本情報]生年月日(年)'),
      month: Yup.string().nullable().required().label('[基本情報]生年月日(月)'),
      day: Yup.string().nullable().required().label('[基本情報]生年月日(日)'),
      postCode: Yup.string().nullable().postCode('[基本情報]郵便番号'),
      postCode1: Yup.string()
        .nullable()
        .required()
        .requiredPostCode('[基本情報]郵便番号')
        .checkPostCode('[基本情報]郵便番号')
        .label('[基本情報]郵便番号'),
      postCode2: Yup.string()
        .nullable()
        .required()
        .requiredPostCode('[基本情報]郵便番号')
        .checkPostCode('[基本情報]郵便番号')
        .label('[基本情報]郵便番号'),
      prefCode: Yup.string().nullable().required().label('[基本情報]都道府県'),
      cityName: Yup.string().nullable().required().label('[基本情報]市区町村'),
      streetName: Yup.string()
        .nullable()
        .required()
        .label('[基本情報]丁目番地'),
      tel: Yup.string().nullable().tel('[基本情報]TEL(スマートフォン)'),
      tel1: Yup.string()
        .nullable()
        .required()
        .requiredPhone('[基本情報]TEL(スマートフォン)')
        .checkPhone('[基本情報]TEL(スマートフォン)')
        .label('[基本情報]TEL(スマートフォン)'),
      tel2: Yup.string()
        .nullable()
        .required()
        .requiredPhone('[基本情報]TEL(スマートフォン)')
        .checkPhone('[基本情報]TEL(スマートフォン)')
        .label('[基本情報]TEL(スマートフォン)'),
      tel3: Yup.string()
        .nullable()
        .required()
        .requiredPhone('[基本情報]TEL(スマートフォン)')
        .checkPhone('[基本情報]TEL(スマートフォン)')
        .label('[基本情報]TEL(スマートフォン)'),
      fax: Yup.string().nullable().fax('[基本情報]TEL(自宅)'),
      fax1: Yup.string().nullable().fax('[基本情報]TEL(自宅)'),
      fax2: Yup.string().nullable().fax('[基本情報]TEL(自宅)'),
      fax3: Yup.string().nullable().fax('[基本情報]TEL(自宅)'),
      nearestStations: Yup.array().of(
        Yup.object({
          stationName: Yup.string().required().label('[交通情報]最寄駅'),
          timeRequired: Yup.string()
            .required()
            .nullable()
            .label('[交通情報]最寄駅までの所要時間(分)'),
          wayStation: Yup.string()
            .required()
            .nullable()
            .label('[交通情報]最寄駅までの交通手段'),
          busStationNameFrom: Yup.string()
            .when('wayStation', (value, schema) =>
              value === '1' ? schema.required() : schema.nullable()
            )
            .label('[交通情報]バス停名1'),
          busStationNameTo: Yup.string()
            .when('wayStation', (value, schema) =>
              value === '1' ? schema.required() : schema.nullable()
            )
            .label('[交通情報]バス停名2'),
        })
      ),
      height: Yup.string()
        .nullable()
        .required()
        .label('[身長・体重など]身長(cm)'),
      weight: Yup.string().nullable().integer('[身長・体重など]体重(kg)'),
      shoeSize: Yup.string()
        .nullable()
        .shoeSize('[身長・体重など]靴のサイズ(cm)'),
      admissionYear: Yup.string().nullable().integer('[学歴・資格など]入学年'),
      bankName: Yup.string()
        .nullable()
        .test(
          'bankName',
          '[給与振込情報]金融機関名:必須項目です。',
          (value, context) => {
            if (context.parent.payMethod1 == 1) {
              return true;
            } else if (context.parent.payMethod1 == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[給与振込情報]金融機関名'),
      bankCode: Yup.string()
        .nullable()
        .test(
          'bankCode',
          '[給与振込情報]銀行コード:必須項目です。',
          (value, context) => {
            if (context.parent.payMethod1 == 1) {
              return true;
            } else if (context.parent.payMethod1 == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[給与振込情報]銀行コード'),
      bankBranchName: Yup.string()
        .nullable()
        .test(
          'bankBranchName',
          '[給与振込情報]支店名:必須項目です。',
          (value, context) => {
            if (context.parent.payMethod1 == 1) {
              return true;
            } else if (context.parent.payMethod1 == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[給与振込情報]支店名'),
      bankBranchCode: Yup.string()
        .nullable()
        .test(
          'bankBranchCode',
          '[給与振込情報]支店コード:必須項目です。',
          (value, context) => {
            if (context.parent.payMethod1 == 1) {
              return true;
            } else if (context.parent.payMethod1 == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[給与振込情報]支店コード'),
      bankAccountNumber: Yup.string()
        .nullable()
        .test(
          'bankAccountNumber',
          '[給与振込情報]口座番号:必須項目です。',
          (value, context) => {
            if (context.parent.payMethod1 == 1) {
              return true;
            } else if (context.parent.payMethod1 == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[給与振込情報]口座番号'),
      bankAccountName: Yup.string()
        .nullable()
        .test(
          'bankAccountName',
          '[給与振込情報]口座名義人(カナ):必須項目です。',
          (value, context) => {
            if (context.parent.payMethod1 == 1) {
              return true;
            } else if (context.parent.payMethod1 == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .kana('[給与振込情報]口座名義人(カナ)')
        .label('[給与振込情報]口座名義人(カナ)'),
    }),
    onSubmit: values => {
      dispatch(
        staffDetailUpdate({
          ...values,
          nameKana: {
            firstName: values.firstNameKana,
            lastName: values.lastNameKana,
          },
          name: {
            firstName: values.firstName,
            lastName: values.lastName,
          },
          birthday:
            values.year && values.month && values.day
              ? {
                  year: values.year,
                  month: values.month,
                  day: values.day,
                }
              : null,
          postCode:
            values.postCode1 && values.postCode2
              ? {
                  number1: values.postCode1,
                  number2: values.postCode2,
                }
              : null,
          homeTel:
            values.fax1 && values.fax2 && values.fax3
              ? {
                  number1: values.fax1,
                  number2: values.fax2,
                  number3: values.fax3,
                }
              : null,
          daytimeTel:
            values.tel1 && values.tel2 && values.tel3
              ? {
                  number1: values.tel1,
                  number2: values.tel2,
                  number3: values.tel3,
                }
              : null,
          schoolName: values.profession1 == '1' ? null : values.schoolName,
          admissionYear:
            values.profession1 == '1' ? null : values.admissionYear,
          gender: Number(values.gender),
          taxClassification: values.taxClassification === '甲' ? 0 : 1,
          profession: Number(values.profession1),
          // dependents: Number(values.dependents.replace('人', '')),
          holdingItems:
            values.holdingItems.length === 0 ? [] : values.holdingItems,
          certifications:
            values.certifications.length === 0 ? [] : values.certifications,
          bankAccountFlag: values.bankAccountFlag1,
          payMethod: values.payMethod1,
        })
      )
        .then(() => {
          dispatch(globalActions.showSingleModal(`更新しました`));
          dispatch(staffActions.editReset());
          navigate('/');
        })
        .catch(error => {
          console.log(error);
        });
    },
  });

  useEffect(() => {
    if (!checkIsSp()) {
      dispatch(staffInfoFetch());
      dispatch(staffDetailFetch({ staffId: accountInfo.staffId }))
        .unwrap()
        .then(result => {
          formik.setValues({
            staffId: result?.detail?.staffId ?? '',
            lastName: result?.detail?.staffName?.lastName ?? '',
            firstName: result?.detail?.staffName?.firstName ?? '',
            lastNameKana: result?.detail?.staffNameKana?.lastName ?? '',
            firstNameKana: result?.detail?.staffNameKana?.firstName ?? '',
            mailAddress: result?.detail?.mailAddress ?? '',
            startDate: result?.detail?.startDate ?? '',
            endDate: result?.detail?.endDate ?? '',
            staffImage: result?.detail?.staffImage ?? '',
            year: result?.detail?.birthday?.year ?? null,
            month: result?.detail?.birthday?.month ?? null,
            day: result?.detail?.birthday?.day ?? null,
            gender: result?.detail?.gender ?? 0,
            postCode1: result?.detail?.postCode?.number1 ?? '',
            postCode2: result?.detail?.postCode?.number2 ?? '',
            prefCode: result?.detail?.prefCode ?? null,
            cityName: result?.detail?.cityName ?? '',
            streetName: result?.detail?.streetName ?? '',
            buildingName: result?.detail?.buildingName ?? '',
            tel1: result?.detail?.daytimeTel?.number1 ?? '',
            tel2: result?.detail?.daytimeTel?.number2 ?? '',
            tel3: result?.detail?.daytimeTel?.number3 ?? '',
            fax1: result?.detail?.homeTel?.number1 ?? '',
            fax2: result?.detail?.homeTel?.number2 ?? '',
            fax3: result?.detail?.homeTel?.number3 ?? '',
            lineId: result?.detail?.lineId ?? null,
            employmentType: result?.detail?.employmentType ?? 0,
            taxClassification:
              result?.detail?.taxClassification === 1 ? '乙' : '甲',
            dependents: result?.detail?.dependents
              ? result?.detail.dependents + '人'
              : '0人',
            nearestStations: result?.detail?.nearestStations ?? [],
            height: result?.detail?.height ?? null,
            weight: result?.detail?.weight ?? null,
            shoeSize: result?.detail?.shoeSize ?? null,
            wearSize: result?.detail?.wearSize ?? null,
            holdingItems: result?.detail?.holdingItems ?? [],
            certifications: result?.detail?.certifications ?? [],
            profession1:
              result?.detail?.profession !== null
                ? result.detail.profession
                : 1,
            schoolName: result?.detail?.schoolName ?? null,
            admissionYear: result?.detail?.admissionYear ?? null,
            payMethod1: result?.detail?.payMethod ?? 0,
            bankName: result?.detail?.bankName ?? null,
            bankCode: result?.detail?.bankCode ?? null,
            bankBranchName: result?.detail?.bankBranchName ?? null,
            bankBranchCode: result?.detail?.bankBranchCode ?? null,
            bankAccountFlag1: result?.detail?.bankAccountFlag ?? 1,
            bankAccountNumber: result?.detail?.bankAccountNumber ?? null,
            bankAccountName: result?.detail?.bankAccountName ?? null,
          });

          return result.detail;
        })
        .then((detail) => detail?.bankCode && dispatch(bankingFetch(detail?.bankCode)));
    }
  }, []);

  const mailField = {
    inputType: 'input',
    label: 'メールアドレス',
    inputName: 'mailAddress',
    placeholder: 'メールアドレス',
    labelRequired: true,
    style: {
      labelStyle: { width: '232px' },
    },
  };

  //各種ページjsx
  const basicArea = () => (
    <div className={'staff_edit--basic_area'}>
      <CustomFormikInput
        inputType={'label'}
        inputName={'staffId'}
        label={'スタッフID'}
        labelRequired={true}
        style={{
          labelStyle: { width: '232px' },
        }}
        formik={formik}
      />

      <div className={'staff_register--search_area_name'}>
        <div
          className={'staff_register--search_area_label'}
          style={{ width: '232px' }}
        >
          名前<span>*</span>
        </div>
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[1]}
          formik={formik}
        />
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[2]}
          formik={formik}
        />
      </div>

      <div className={'staff_register--search_area_name'}>
        <div
          className={'staff_register--search_area_label'}
          style={{ width: '232px' }}
        >
          名前(カナ)<span>*</span>
        </div>
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[3]}
          formik={formik}
        />
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[4]}
          formik={formik}
        />
      </div>

      <CustomFormikInput {...mailField} formik={formik} />
    </div>
  );

  //registerベースに送信する設定(マイページ)
  const myPageInfos = {
    basic: {
      style: {
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        gap: '24px',
      },
      jsx: basicArea,
    },
    detail: {
      tabs: {
        common: {
          label: '基本情報',
          jsx: () => <BasicInfos formik={formik} isStaff={isStaff} />,
        },
        traffic: {
          label: '交通情報',
          jsx: () => <AccessInfos formik={formik} isStaff={isStaff} />,
        },
        body: {
          label: '身長・体重など',
          jsx: () => <BodyInfos formik={formik} isStaff={isStaff} />,
        },
        skill: {
          label: '学歴・資格など',
          jsx: () => <BackGroundInfos formik={formik} isStaff={isStaff} />,
        },
        pay: {
          label: '給与受取情報',
          jsx: () => (
            <SalaryInfos
              formik={formik}
              dispatch={dispatch}
              isStaff={isStaff}
            />
          ),
        },
      },
    },
  };

  //送信ボタン設定
  const buttonStyle = {
    updateButton: {
      width: '240px',
      height: '44px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
  };

  useEffect(() => {
    let target = document.getElementsByClassName('register_base--basic_info');
    let targetChild = document.getElementsByClassName(
      'register_base--basic_info_input_area'
    );
    Array.from(target).forEach(elm => {
      elm.style.margin = '0';
      elm.style.width = '100%';
      elm.style.justifyContent = 'flex-start';
    });
    Array.from(targetChild).forEach(elm => {
      elm.style.width = '65%';
      elm.style.paddingLeft = '40px';
    });
  }, []);

  return (
    <>
      <div className={'myPage_forPc'}>
        <div className={'my_page--area'}>
          <RegisterBase
            imageShape={'round'}
            infos={myPageInfos}
            imageDisabled={true}
          />

          <div
            className={'my_page--bottom_btn_area'}
            style={{ height: '92px', width: isOpenSideMenu ? 'calc(100% - 200px)' : 'calc(100% - 56px)' }}
          >
            <Button
              text={'更新'}
              style={buttonStyle.updateButton}
              onClick={() => handlerFormikSubmit(formik, tabErrorGroup)}
            />
          </div>
        </div>
      </div>

      <div className={'myPage_forSp'}>{MyPageForSp()}</div>
    </>
  );
};

export default MyPage;
