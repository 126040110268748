import { memo, useState } from 'react';
import Pager from './index';
import CustomInput from '../customInput';

const Paging = memo(
  ({
    searchCount,
    currentPage,
    onChangeCount,
    setCurrentPage,
    onChangeOffset,
    component,
  }) => {
    const [listPerPage, setListPerPage] = useState(20);
    const pages =
      Math.floor(searchCount / listPerPage) +
      (searchCount % listPerPage ? 1 : 0);

    const changeListPerPage = e => {
      setListPerPage(e);
      onChangeCount?.(e);

      setCurrentPage(1);
    };

    const changeCurrentPage = e => {
      if (e.target.value === '+') {
        setCurrentPage(currentPage + 3);
      } else if (e.target.value === '-') {
        setCurrentPage(currentPage - 3);
      } else {
        setCurrentPage(Number(e.target.value));
      }
      setCurrentPage(current => {
        onChangeOffset?.(listPerPage * (current - 1));
        return current;
      });
    };

    return (
      <>
        <div className={'list_view--buttons_base'}>
          <div className={'list_view--buttons_left'}>
            <div className={'list_view--count_area'}>
              <p className={'list_view--count_search'}>
                検索結果：{searchCount}件
              </p>

              {searchCount !== 0 && (
                <p className={'list_view--count_page'}>
                  {currentPage}/{pages}ページ
                </p>
              )}
            </div>

            <div className={'list_view--count_select'}>
              <CustomInput
                onChange={changeListPerPage}
                inputType={'select'}
                inputName={'count_select'}
                selectBoxOptions={[20, 50, 100]}
                initialValue={[20, 50, 100]}
                selectedSelectBox={20}
                allowClear={false}
              />
              <p>件</p>
            </div>

            {searchCount !== 0 && (
              <Pager
                searchCount={!searchCount ? 0 : searchCount}
                listPerPage={listPerPage}
                currentPage={currentPage}
                setPage={changeCurrentPage}
              />
            )}
          </div>
        </div>
        {component}
        {searchCount > 0 && (
          <div className={'list_view--bottom_pager_wrapper'}>
            <Pager
              searchCount={searchCount}
              listPerPage={listPerPage}
              currentPage={currentPage}
              setPage={changeCurrentPage}
            />
          </div>
        )}
      </>
    );
  }
);

export default Paging;
