import BirthdaySelector from '../../../components/birthdaySelector';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Options from '../../../constants/options';
import React, { useEffect } from 'react';
import { zipApi } from '../../../api/fetch';

const MyPageForSpPage1 = ({ spFormik, currentPage }) => {
  // 住所検索
  useEffect(() => {
    zipApi(spFormik, 'post1', 'post2', 'prefCode', 'cityName', 'streetName');
  }, [spFormik.values.post1, spFormik.values.post2]);

  return (
    <div
      className={'myPageSp--info_base'}
      style={{ display: currentPage === 1 ? 'flex' : 'none' }}
    >
      <div className={'myPageSp--info_title'}>
        <p>
          <span></span>
          基本情報
        </p>
      </div>

      <div>
        <label style={{color: '#646464', fontSize: '12px'}}>
          生年月日
          <span style={{ color: '#D80245' }}>*</span>
        </label>
        <BirthdaySelector formik={spFormik} forSp={true} />
      </div>

      <CustomFormikInput
        inputType={'select'}
        showSearch={false}
        allowClear={false}
        inputName={'gender'}
        label={'性別'}
        labelRequired={true}
        initialValue={[1, 2]}
        selectBoxOptions={['男性', '女性']}
        placeholder={'性別'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '100%' },
        }}
      />

      <div
        style={{
          width: '100%',
          gap: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <CustomFormikInput
          inputType="input"
          label="郵便番号"
          labelRequired={true}
          inputName={'post1'}
          formik={spFormik}
          placeholder={'123'}
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: '50%' },
          }}
        />
        <span style={{ color: '#242424', lineHeight: '44px' }}>-</span>
        <CustomFormikInput
          inputType="input"
          inputName={'post2'}
          label={''}
          formik={spFormik}
          placeholder={'4567'}
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: '50%' },
          }}
        />
      </div>

      <CustomFormikInput
        inputType={'select'}
        showSearch={false}
        allowClear={false}
        inputName={'prefCode'}
        label={'都道府県'}
        labelRequired={true}
        initialValue={Object.keys(Options.codes.pref_code)}
        selectBoxOptions={Object.values(Options.codes.pref_code)}
        placeholder={'都道府県'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '100%' },
        }}
      />

      <CustomFormikInput
        inputType={'input'}
        inputName={'cityName'}
        label={'市区町村'}
        placeholder={'市区町村'}
        labelRequired={true}
        className={{ areaClass: 'column' }}
        formik={spFormik}
        style={{ areaStyle: { width: '100%' } }}
      />

      <CustomFormikInput
        inputType={'input'}
        inputName={'streetName'}
        label={'丁目番地'}
        placeholder={'丁目番地'}
        labelRequired={true}
        className={{ areaClass: 'column' }}
        formik={spFormik}
        style={{ areaStyle: { width: '100%' } }}
      />

      <CustomFormikInput
        inputType={'input'}
        inputName={'buildingName'}
        label={'それ以降'}
        placeholder={'マンション名・部屋番号等'}
        labelRequired={false}
        className={{ areaClass: 'column' }}
        formik={spFormik}
        style={{ areaStyle: { width: '100%' } }}
      />

      <div
        style={{
          width: '100%',
          gap: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <CustomFormikInput
          inputType="input"
          label="TEL(スマートフォン)"
          labelRequired={true}
          inputName={'dayTel1'}
          formik={spFormik}
          placeholder={'000'}
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: '30%' },
          }}
        />
        <span style={{ color: '#242424', lineHeight: '44px' }}>-</span>
        <CustomFormikInput
          inputType="input"
          inputName={'dayTel2'}
          label={''}
          formik={spFormik}
          placeholder={'0000'}
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: '30%' },
          }}
        />
        <span style={{ color: '#242424', lineHeight: '44px' }}>-</span>
        <CustomFormikInput
          inputType="input"
          inputName={'dayTel3'}
          label={''}
          formik={spFormik}
          placeholder={'0000'}
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: '30%' },
          }}
        />
      </div>

      <div
        style={{
          width: '100%',
          gap: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <CustomFormikInput
          inputType="input"
          label="TEL(自宅)"
          labelRequired={false}
          inputName={'homeTel1'}
          formik={spFormik}
          placeholder={'03'}
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: '30%' },
          }}
        />
        <span style={{ color: '#242424', lineHeight: '44px' }}>-</span>
        <CustomFormikInput
          inputType="input"
          inputName={'homeTel2'}
          label={''}
          formik={spFormik}
          placeholder={'0000'}
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: '30%' },
          }}
        />
        <span style={{ color: '#242424', lineHeight: '44px' }}>-</span>
        <CustomFormikInput
          inputType="input"
          inputName={'homeTel3'}
          label={''}
          formik={spFormik}
          placeholder={'0000'}
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: '30%' },
          }}
        />
      </div>

      <CustomFormikInput
        inputType={'input'}
        inputName={'lineId'}
        label={'LINE ID'}
        placeholder={'LINE IDを入力してください'}
        labelRequired={false}
        className={{ areaClass: 'column' }}
        formik={spFormik}
        style={{ areaStyle: { width: '100%' } }}
      />

      <p style={{fontSize: '12px'}}>
        <span className={'info_label'}>税額表区分</span>
        {spFormik?.values?.taxClassification ? '乙' : '甲'}
      </p>

      <p style={{fontSize: '12px'}}>
        <span className={'info_label'}>扶養人数</span>
        {spFormik?.values?.dependents}人
      </p>
    </div>
  );
};

export default MyPageForSpPage1;
