import './style.scss';
import Button from '../../button';
import Options from '../../../constants/options';
import { checkIsSp, handlerFormikSubmit } from '../../../utils/fnUtil';
import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import Icons from '../../../constants/icons';
import CustomFormikInput from '../../customInput/customFormikInput';
import Graphs from '../../graphs';
import {
  surveyOpen,
  surveyStaffAnswerResult,
} from '../../../redux/slice/survey';
import { store } from '../../../redux/store';
import { useSelector } from 'react-redux';

const SurveyStartModal = ({
  modalVisible,
  toggleVisible,
  surveyDate,
  formik,
  isSubmit,
  setIsSubmit,
  dispatch,
  setMoveApi,
}) => {
  const item = surveyDate;
  const { surveyStaffAnswerResultData } = useSelector(state => state.survey);
  const { surveyQuestionInfos } = formik.values;

  const [surveyId, setSurveyId] = useState(null); //アンケートID
  const [surveyType, setSurveyType] = useState(null); //アンケートタイプ
  const [startIndex, setStartIndex] = useState(0); //ページング位置
  const [choiceSurveyId, setChoiceSurveyId] = useState(null);

  const {
    account: { staffId },
  } = store.getState();

  const setInitialValues = idx => {
    //初期値を設問の数だけセットする。
    let ary = [];
    [...Array(item?.[idx].surveyQuestionInfos?.length)].forEach((_, i) =>
      ary.push({
        surveyId: item?.[idx]?.surveyId,
        surveyBodyNumber:
          item?.[idx].surveyQuestionInfos?.[i]?.surveyBodyNumber,
        answerChoice:
          // item?.[idx].surveyQuestionInfos?.[i]?.answerMethod === 0 ? '0' : '',
          item?.[idx].surveyQuestionInfos?.[i]?.answerMethod === 0 ? '' : '',
        answerText: '',
        requiredFlag:
          item?.[idx].surveyQuestionInfos?.[i]?.questionRequiredFlag,
        answerMethod: item?.[idx].surveyQuestionInfos?.[i]?.answerMethod,
      })
    );
    formik.setFieldValue('surveyQuestionInfos', ary);
  };

  const inputInfo = (questionInfo, index, type) => {
    //choice1〜10のnull以外の値をカウント
    let count = 0;
    for (let i = 1; i <= 10; i++) {
      const choice = questionInfo[`choice_${i}`];
      if (choice !== null) {
        count++;
      }
    }

    //typeに応じた情報を生成する
    const info = [...Array(count)].map((_, i) => {
      const radioValue =
        surveyQuestionInfos?.[index]?.[
          `answerChoice_${questionInfo?.surveyBodyNumber}`
        ];
      switch (type) {
        case 'value':
          return i;
        case 'radioChecked':
          return {
            label: questionInfo[`choice_${i + 1}`],
            // checked: radioValue ? radioValue == i : i === 0,
            checked: radioValue ? radioValue == i : i === null,
          };
        case 'name':
          return `surveyQuestionInfos.[${index}].answerChoice_${i}`;
        case 'label':
          return questionInfo[`choice_${i + 1}`];
        case 'checked':
          return false;
        default:
          return null;
      }
    });
    return info;
  };

  const isMultiSurvey = idx => {
    return item?.length > 1 && item?.length - 1 !== idx;
  };

  //ページング処理
  const handleClickNextPage = async () => {
    resetModalScrollPosition();
    const nextIndex = startIndex + 10;
    if (surveyType === 0) {
      await handlerFormikSubmit(formik);
    } else {
      if (nextIndex >= item?.[surveyId]?.surveyQuestionInfos?.length) {
        await handlerFormikSubmit(formik);
      } else {
        setStartIndex(nextIndex);
      }
    }
  };

  const displayedItems = item?.[surveyId]?.surveyQuestionInfos?.slice(
    startIndex,
    startIndex + 10
  );

  //モーダルスクロール位置のリセット処理
  const resetModalScrollPosition = () => {
    const modal = document.getElementById('survey_modal');
    modal?.scrollTo(0, 0);
  };

  const generationQuestionDescription = (info, description) => {
    let questionDescription = !description
      ? info?.questionDescription
      : description;

    if (questionDescription) {
      questionDescription = questionDescription.replace(/\n/g, '<br>');
      questionDescription = questionDescription.replace(
        /%image1%/g,
        info?.questionImage_1
          ? `<img class="survey_start_modal--question_image" src=${info?.questionImage_1} />`
          : ``
      );
      questionDescription = questionDescription.replace(
        /%image2%/g,
        info?.questionImage_2
          ? `<img class="survey_start_modal--question_image" src=${info?.questionImage_2} />`
          : ``
      );
      questionDescription = questionDescription.replace(
        /%image3%/g,
        info?.questionImage_3
          ? `<img class="survey_start_modal--question_image" src=${info?.questionImage_3} />`
          : ``
      );
      questionDescription = questionDescription.replace(
        /%image4%/g,
        info?.questionImage_4
          ? `<img class="survey_start_modal--question_image" src=${info?.questionImage_4} />`
          : ``
      );
      questionDescription = questionDescription.replace(
        /%image5%/g,
        info?.questionImage_5
          ? `<img class="survey_start_modal--question_image" src=${info?.questionImage_5} />`
          : ``
      );

      const linkRegex = /%html%<a href="([^"]+)">([^<]+)<\/a>%html%/g;
      questionDescription = questionDescription.replace(
        linkRegex,
        '<a href="$1" target="_blank">$2</a>'
      );

      const colorRegex = /%html%<p style="color:([^"]+)">([^<]+)<\/p>%html%/g;
      questionDescription = questionDescription.replace(
        colorRegex,
        (_, color, content) => `<p style="color: ${color};">${content}</p>`
      );
    }

    return (
      <div
        className="survey_start_modal--question_description_area"
        dangerouslySetInnerHTML={{ __html: questionDescription }}
      />
    );
  };

  const generationSurveyDescription = description => {
    let surveyDescription = description;

    if (surveyDescription) {
      surveyDescription = surveyDescription.replace(/\n/g, '<br>');

      const linkRegex = /%html%<a href="([^"]+)">([^<]+)<\/a>%html%/g;
      surveyDescription = surveyDescription.replace(
        linkRegex,
        '<a href="$1" target="_blank">$2</a>'
      );
    }

    return (
      <div
        className="survey_start_modal--question_description_area"
        dangerouslySetInnerHTML={{ __html: surveyDescription }}
      />
    );
  };
  
  const setAryIndex = idx => {
    return idx.length === 2 ? '' : startIndex > 0 ? startIndex + idx : idx;
  };

  useEffect(() => {
    if (isSubmit) {
      setSurveyId('Thx');
      dispatch(
        surveyStaffAnswerResult({
          staffId: staffId,
          surveyId: item?.[surveyId]?.surveyId ?? null,
          surveyBodyNumber:
            item?.[surveyId]?.surveyQuestionInfos?.map(
              i => i.surveyBodyNumber
            ) ?? null,
        })
      );
    }
  }, [isSubmit]);

  useEffect(() => {
    if (surveyId !== null && surveyId !== 'Thx') {
      if (surveyQuestionInfos?.length !== 0) {
        surveyQuestionInfos?.forEach(info => {
          dispatch(
            surveyOpen({
              surveyId: info?.surveyId,
              surveyBodyNumber: info?.surveyBodyNumber,
            })
          );
        });
      }
    }
  }, [surveyId]);

  return (
    <Modal
      visible={modalVisible}
      style={{ minWidth: 600 }}
      bodyStyle={{ height: 'fit-content', maxHeight: '849px' }}
      footer={null}
      centered
      closable={false}
      className="common_modal_base"
    >
      <div className={'survey_start_modal--wrap'} id={'survey_modal'}>
        {surveyId !== 'Thx' ? (
          <img
            className="survey_start_modal--close_btn"
            src={Icons.icon.cancelWh}
            onClick={() => {
              toggleVisible(false);
              setSurveyId(null);
              setStartIndex(0);
              resetModalScrollPosition();
              formik.resetForm();
              isSubmit && setMoveApi(true);
              isSubmit && setChoiceSurveyId(null);
              setIsSubmit(false);
            }}
          />
        ) : (
          <></>
        )}
        {surveyId === 'Thx' ? ( //結果表示
          <>
            <div className={'survey_start_modal--result_title'}>
              <span>ご協力ありがとうございました!</span>
              {surveyStaffAnswerResultData?.surveyQuestionInfos?.filter(
                d => d?.graphType !== null
              )?.length !== 0 &&
                surveyType === 0 && (
                  <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    みんなの回答はこんな感じ！
                  </span>
                )}
            </div>

            {surveyType === 0 && (
              <>
                {surveyStaffAnswerResultData?.surveyQuestionInfos?.map(
                  (obj, i) => {
                    return obj?.graphType !== null ? (
                      <div
                        className={'survey_start_modal--survey_area'}
                        key={`ssm--sa_a_${i}`}
                      >
                        <img
                          className={'survey_start_modal--survey_character'}
                          src={Icons.icon.character2}
                        />

                        <div className={'survey_start_modal--survey_no'}>
                          <span>{`Q${i + 1}`}</span>
                        </div>

                        <div className={'survey_start_modal--survey_info'}>
                          {generationQuestionDescription(
                            item?.filter(
                              t => t.surveyId === choiceSurveyId
                            )?.[0]?.surveyQuestionInfos?.[i],
                            item?.filter(
                              t => t.surveyId === choiceSurveyId
                            )?.[0]?.questionDescription
                          )}
                        </div>

                        <div className={'survey_start_modal--survey_input'}>
                          <Graphs data={obj} isStaff={true} />
                        </div>
                      </div>
                    ) : (
                      <></>
                    );
                  }
                )}
              </>
            )}

            <div
              className={'survey_start_modal--btn'}
              style={{
                paddingBottom: '64px',
              }}
            >
              <Button
                text={'閉じる'}
                style={{
                  ...Options.buttonStyles.back,
                  width: checkIsSp() ? '208px' : '240px',
                  height: checkIsSp() ? '40px' : '44px',
                }}
                onClick={() => {
                  toggleVisible(false);
                  setSurveyId(null);
                  setStartIndex(0);
                  resetModalScrollPosition();
                  isSubmit && setMoveApi(true);
                  isSubmit && setChoiceSurveyId(null);
                  setIsSubmit(false);
                  formik.resetForm();
                }}
              />
            </div>
          </>
        ) : surveyId === null ? ( //アンケート表示
          <>
            {item?.length > 1 && (
              <div
                className={'survey_start_modal--title'}
                style={{ marginBottom: '32px' }}
              >
                {`${item?.length}つのアンケートが届いてます`}
              </div>
            )}

            {item?.map((obj, idx) => (
              <React.Fragment key={`ssm--c_${idx}`}>
                {obj?.surveyThumbnailImage && (
                  <div className={'survey_start_modal--thumbnail_image'}>
                    <img src={obj.surveyThumbnailImage} />
                  </div>
                )}

                <div className={'survey_start_modal--title'}>
                  {obj?.surveyTitle}
                </div>

                <div className={'survey_start_modal--description'}>
                  {generationSurveyDescription(obj?.surveyDescription)}
                </div>

                <div
                  className={'survey_start_modal--btn'}
                  style={{
                    borderBottom: isMultiSurvey(idx)
                      ? 'solid 1px #A0AAB8'
                      : 'none',
                    paddingBottom: isMultiSurvey(idx) ? '40px' : '0',
                    marginBottom: isMultiSurvey(idx) ? '40px' : '64px',
                  }}
                >
                  <Button
                    text={'開始する'}
                    style={{
                      ...Options.buttonStyles.submit,
                      width: checkIsSp() ? '208px' : '240px',
                      height: checkIsSp() ? '40px' : '44px',
                    }}
                    onClick={() => {
                      setSurveyId(idx);
                      setSurveyType(obj.surveyType);
                      resetModalScrollPosition();
                      setInitialValues(idx);
                      setChoiceSurveyId(obj?.surveyId);
                    }}
                  />
                </div>
              </React.Fragment>
            ))}
          </>
        ) : (
          //アンケート内容表示
          <>
            <div
              className={'survey_start_modal--title'}
              style={{ marginBottom: '24px' }}
            >
              {item?.[surveyId]?.surveyTitle}
            </div>

            {(surveyType === 0
              ? item?.[surveyId]?.surveyQuestionInfos
              : displayedItems
            )?.map((info, idx) => (
              <div
                className={'survey_start_modal--survey_area'}
                key={`ssm--sa_${idx}`}
                style={{ marginTop: idx === 0 ? '24px' : '' }}
              >
                <img
                  className={'survey_start_modal--survey_character'}
                  src={Icons.icon.character2}
                />

                <div className={'survey_start_modal--survey_no'}>
                  <div
                    className={'survey_start_modal--is_required'}
                    style={{
                      backgroundColor:
                        info?.questionRequiredFlag === 0
                          ? '#0056D3'
                          : '#D80245',
                    }}
                  >
                    {info?.questionRequiredFlag === 0 ? '任意' : '必須'}
                  </div>
                  <span>{`Q${
                    (idx).length === 2
                      ? ''
                      : startIndex > 0
                      ? startIndex + (idx + 1)
                      : idx + 1
                  }`}</span>
                </div>

                <div className={'survey_start_modal--survey_info'}>
                  {generationQuestionDescription(info)}
                </div>

                <div className={'survey_start_modal--survey_input'}>
                  {info?.answerMethod === 0 ? (
                    <CustomFormikInput
                      inputType={'radioButton'}
                      inputName={`surveyQuestionInfos.[${setAryIndex(idx)}].answerChoice_${info?.surveyBodyNumber}`}
                      key={`surveyQuestionInfos.[${setAryIndex(idx)}].answerChoice_${info?.surveyBodyNumber}`}
                      initialValue={inputInfo(info, setAryIndex(idx), 'value')}
                      radioButtonLabelAndChecked={inputInfo(
                        info,
                        setAryIndex(idx),
                        'radioChecked'
                      )}
                      formik={formik}
                      onChange={e => {
                        formik.setFieldValue(
                          `surveyQuestionInfos.[${setAryIndex(idx)}].answerChoice_${info?.surveyBodyNumber}`,
                          `${e.target.value}`
                        );
                        formik.setFieldValue(
                          `surveyQuestionInfos.[${setAryIndex(idx)}].answerChoice`,
                          `${e.target.value}`
                        );
                      }}
                    />
                  ) : info?.answerMethod === 1 ? (
                    <CustomFormikInput
                      mode={'survey'}
                      inputType={'checkBox'}
                      inputName={inputInfo(info, setAryIndex(idx), 'name')}
                      initialValue={inputInfo(info, setAryIndex(idx), 'value')}
                      label={inputInfo(info, setAryIndex(idx), 'label')}
                      defaultChecked={inputInfo(info, setAryIndex(idx), 'checked')}
                      formik={formik}
                      onChange={e => {
                        const oldValue =
                          formik.values?.surveyQuestionInfos?.[setAryIndex(idx)]
                            ?.answerChoice ?? '';
                        const elm = e.target.id;
                        const newValue = parseInt(elm.slice(-1));
                        const updatedValue = e.target.checked
                          ? oldValue + newValue
                          : oldValue.replace(newValue, '');
                        formik.setFieldValue(
                          `surveyQuestionInfos.[${setAryIndex(idx)}].answerChoice`,
                          updatedValue
                        );
                      }}
                    />
                  ) : (
                    <CustomFormikInput
                      inputType={'textBox'}
                      defaultValue={surveyQuestionInfos?.[setAryIndex(idx)]?.answerText}
                      inputName={`surveyQuestionInfos.[${setAryIndex(idx)}].answerText`}
                      placeholder={'回答を入力してください'}
                      formik={formik}
                    />
                  )}
                </div>
              </div>
            ))}

            <div
              className={'survey_start_modal--btn'}
              style={{
                paddingBottom: '64px',
              }}
            >
              {startIndex !== 0 && (
                <Button
                  text={'戻る'}
                  style={{
                    ...Options.buttonStyles.back,
                    width: checkIsSp() ? '208px' : '240px',
                    height: checkIsSp() ? '40px' : '44px',
                  }}
                  onClick={() => {
                    setStartIndex(startIndex - 10 > 0 ? startIndex - 10 : 0);
                    resetModalScrollPosition();
                  }}
                />
              )}
              <Button
                text={
                  surveyType === 0
                    ? '送信'
                    : startIndex + 10 >=
                      item?.[surveyId]?.surveyQuestionInfos?.length
                    ? '送信'
                    : '次へ'
                }
                style={{
                  ...Options.buttonStyles.submit,
                  width: checkIsSp() ? '208px' : '240px',
                  height: checkIsSp() ? '40px' : '44px',
                }}
                onClick={handleClickNextPage}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SurveyStartModal;
