import { memo } from 'react';
import Icons from '../../constants/icons';
import { Tooltip } from 'antd';
import './style.scss';

const DeleteIcon = memo(({ title, onClick, classFlag = true, style = {} }) => (
  <Tooltip placement="top" title={title} onClick={onClick}>
    <img
      src={Icons.icon.xCircle}
      className={classFlag ? 'delete_icon' : ''}
      style={style}
    />
  </Tooltip>
));

export default DeleteIcon;
