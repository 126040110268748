import './style.scss';
import Icons from '../../../constants/icons';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Pager from '../../../components/pager';
import icons from '../../../constants/icons';
import CustomInput from '../../../components/customInput';
import Button from '../../../components/button';
import { Router, useNavigate } from 'react-router-dom';
import DeleteModal from '../../../components/modal/deleteModal';
import SearchSelectBox from '../../../components/searchSelectBox';
import AddButton from '../../../components/addButton';
import Options from '../../../constants/options';
import { isNull } from 'lodash';
import { useSelector } from 'react-redux';
import { checkIsSp } from '../../../utils/fnUtil';
import { memo } from 'react';

const CustomListView = memo(({
  searchFormInfo,
  columnSettings,
  buttonSettings,
  primaryKey,
  apiData,
  sortSettings,
  changeSort,
  onDelete,
  onChangeCount,
  onChangeOffset,
  changeTab,
  isSys,
}) => {
  const registerType = useLocation().pathname.match(/\/(.*)\//)[1];
  const navigate = useNavigate();
  const [isCheckAll, setCheckAll] = useState(false);
  const isSp = checkIsSp();

  const toggleChecks = boo => {
    const checkBoxes = document
      .getElementsByClassName('list_view--list_table')[0]
      .querySelectorAll('input, #check_');

    Array.prototype.forEach.call(checkBoxes, elm => {
      elm.checked = boo;
    });
  };

  const { activeTab } = useSelector(state => state.global);

  useEffect(() => {
    toggleChecks(isCheckAll);
  });

  const adjustZIndex = () => {
    const commonPageBase = document.querySelector('.commonPage--base');
    commonPageBase.style.zIndex = 'auto';
  }

  const searchCount = apiData?.totalCount;
  let data = apiData?.item;

  const [listPerPage, setListPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const pages =
    Math.floor(searchCount / listPerPage) + (searchCount % listPerPage ? 1 : 0);

  const changeListPerPage = e => {
    setListPerPage(e);
    onChangeCount?.(e);

    setCurrentPage(1);
  };

  const changeCurrentPage = e => {
    if (e.target.value === '+') {
      setCurrentPage(currentPage + 3);
    } else if (e.target.value === '-') {
      setCurrentPage(currentPage - 3);
    } else {
      setCurrentPage(Number(e.target.value));
    }
    setCurrentPage(current => {
      onChangeOffset?.(listPerPage * (current - 1));
      return current;
    });
  };

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteKey, setDeleteKey] = useState('');
  const [deleteName, setDeleteName] = useState('');

  const toggleDeleteModal = () => {
    setDeleteModalVisible(!deleteModalVisible);
  };

  const setDeleteInfo = (key, name) => {
    setDeleteKey(key);
    setDeleteName(name);

    toggleDeleteModal();
  };

  useEffect(() => {
    setListPerPage(20);
    setCurrentPage(1);
  }, [changeTab]);

  return (
    <>
      {searchFormInfo ? (
        <div className={'list_view--search_base'}>
          <div className={'list_view--search_title_area'}>
            <img src={Icons.icon.downNv} />
            <p>検索条件</p>
          </div>
          <div className={'list_view--search_form_area'}>
            {searchFormInfo.jsx
              ? searchFormInfo.jsx()
              : searchFormInfo.inputs.map((inputInfo, index) => {
                  if (inputInfo.inputType === 'searchSelect') {
                    return <SearchSelectBox key={index} {...inputInfo} />;
                  } else {
                    return <CustomInput key={index} {...inputInfo} />;
                  }
                })}
          </div>
          <div className={'list_view--search_button_area'}>
            <Button text={'検索'} style={Options.buttonStyles.search} />
          </div>
        </div>
      ) : (
        <></>
      )}

      {buttonSettings ? (
        <div className={'custom_list_view--buttons_base'}>
          <div className={'custom_list_view--buttons_left'}>
            {buttonSettings.viewPager && (
              <>
                <div
                  className={'list_view--count_area'}
                  style={{ paddingBottom: '4px', marginRight: '16px' }}
                >
                  <p className={'list_view--count_search'}>
                    検索結果：{Number(searchCount).toLocaleString()}件
                  </p>
                </div>

                <div className={'list_view--count_select'}>
                  <CustomInput
                    onChange={changeListPerPage}
                    inputType={'select'}
                    inputName={'count_select'}
                    selectBoxOptions={[20, 50, 100]}
                    initialValue={[20, 50, 100]}
                    selectedSelectBox={20}
                    allowClear={false}
                    usePopContainer={true}
                  />
                  <p>件</p>
                </div>

                <div style={isSp ? { width: '100%' } : {}}>
                  <Pager
                    searchCount={!searchCount ? 0 : searchCount}
                    listPerPage={listPerPage}
                    currentPage={currentPage}
                    setPage={changeCurrentPage}
                  />
                </div>

                <div
                  className={'list_view--count_area'}
                  style={{ paddingBottom: '4px' }}
                >
                  <p className={'list_view--count_page'}>
                    {currentPage}/{pages}ページ
                  </p>
                </div>
              </>
            )}
          </div>

          {/*システム管理者のみ表示*/}
          {isSys && (
            <div className={'custom_list_view--buttons_right'}>
              {Object.keys(buttonSettings).map(key => {
                if (key !== 'viewPager') {
                  if (key === 'addButton') {
                    return (
                      <AddButton
                        key={key}
                        {...buttonSettings[key].props}
                        screenType={true}
                        spListViewMode={isSp}
                      />
                    );
                  } else {
                    return (
                      <Button
                        key={key}
                        icon={buttonSettings[key].icon}
                        text={buttonSettings[key].text}
                        style={buttonSettings[key].style}
                        onClick={buttonSettings[key].onClick} ///修正
                        iconStyle={buttonSettings[key].iconStyle}
                      />
                    );
                  }
                }
              })}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}

      <div
        className={'custom_list_view--list_wrapper'}
        style={{ width: '100%', overflowX: 'scroll', whiteSpace: 'nowrap' }}
      >
        <table className={'list_view--list_table custom_list_view--list_table'}>
          <thead>
            <tr
              className={'custom_list_view--table_tr'}
              style={{ height: '35.5px', borderBottom: '1px solid #a0aab8' }}
            >
              <th rowSpan="3" style={{ borderRight: '2.5px solid #a0aab8' }}>
                <div className={'custom_list_view--td_center_contents'}>
                  <span>月額課税対象額</span>
                </div>
              </th>
              <th
                colSpan="8"
                style={{
                  borderRight: '2.5px solid #a0aab8',
                  borderBottom: '1px solid #a0aab8',
                }}
              >
                <div className={'custom_list_view--td_center_contents'}>
                  <span>甲</span>
                </div>
              </th>
              <th
                rowSpan="2"
                colSpan="2"
                style={{ borderRight: '1px solid #a0aab8' }}
              >
                <div className={'custom_list_view--td_center_contents'}>
                  <span>乙</span>
                </div>
              </th>
              {columnSettings.statics.map((obj, i) => {
                return (
                  <th
                    rowSpan="3"
                    className={'list_view--static_th'}
                    key={`customTh${obj.label}`}
                    style={{borderRight: i === 0 && '1px solid #A0AAB8'}}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {obj.label}
                    </div>
                  </th>
                );
              })}
            </tr>

            <tr
              className={'custom_list_view--table_tr'}
              style={{ height: '35.5px', borderBottom: '1px solid #a0aab8' }}
            >
              <th colSpan="8" style={{ borderRight: '2.5px solid #a0aab8' }}>
                <div className={'custom_list_view--td_center_contents'}>
                  <span>扶養人数別課税額</span>
                </div>
              </th>
            </tr>

            <tr>
              {Object.keys(columnSettings).map(key => {
                if (key !== 'statics' && key !== 'checkAll') {
                  return (
                    key !== 'image' &&
                    key !== 'incomeAmountFrom' &&
                    key !== 'incomeAmountTo' &&
                    key !== 'calculationTaxFlag' && (
                      <th
                        key={`customTh1${key}`}
                        onClick={changeSort}
                        id={columnSettings[key].dataName}
                        style={
                          key === 'kouTax7'
                            ? { borderRight: '2.5px solid #a0aab8' }
                            : key === 'taxRate'
                            ? {
                                minWidth: '100px',
                                borderRight: '1px solid #a0aab8',
                              }
                            : key === 'taxAmount'
                            ? { borderRight: '1px solid #a0aab8' }
                            : { borderRight: '1px solid #a0aab8' }
                        }
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {columnSettings[key].label}
                          {sortSettings.sortColumn ===
                          columnSettings[key].dataName ? (
                            sortSettings.sortMethod === 'asc' ? (
                              <img
                                src={icons.icon.downGr}
                                style={{ transform: 'rotate(180deg)' }}
                              />
                            ) : (
                              <img src={icons.icon.downGr} />
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </th>
                    )
                  );
                }
              })}
            </tr>
          </thead>

          <tbody>
            {data?.map((obj, index) => {
                return (
                  <tr
                    key={`customTr${index}`}
                    style={{
                      backgroundColor: index % 2 ? '#F8F8F8' : '#FFFFFF',
                      borderBottom: '1px solid #F3F3F3'
                    }}
                  >
                    <td style={{ borderRight: '2px dashed #E0E0E0' }}>
                      <div className={'custom_list_view--first_td'}>
                        {Object.keys(obj).map(key => {
                          return key === 'incomeAmountFrom' ? (
                            <React.Fragment key={`customDiv${key}`}>
                              <div
                                className={
                                  'custom_list_view--first_td_contents'
                                }
                              >
                                <span>
                                  {`${obj[key].toLocaleString() ?? 0} 円`}
                                </span>
                              </div>
                              <span>〜</span>
                            </React.Fragment>
                          ) : (
                            key === 'incomeAmountTo' && (
                              <div
                                className={
                                  'custom_list_view--first_td_contents'
                                }
                                key={`customDiv${key}`}
                              >
                                <span>
                                  {`${obj[key].toLocaleString()} 円`}
                                </span>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </td>

                    {Object.keys(obj).map(key => {
                      return key !== 'createdUser' &&
                        key !== 'updatedUser' &&
                        key !== 'incomeTaxId' &&
                        key !== 'calculationTaxFlag' &&
                        key !== 'incomeAmountFrom' &&
                        key !== 'incomeAmountTo' ? (
                        <td
                          key={key}
                          style={
                            key === 'kouTax7'
                              ? { borderRight: '2px dashed #E0E0E0' }
                              : { borderRight: '1px dashed #E0E0E0' }
                          }
                        >
                          <div className={'custom_list_view--td_contents'}>
                            {isNull(obj[key])
                              ? '-'
                              : !isNull(obj[key]) && key === 'taxRate'
                              ? `${obj[key].toLocaleString()}%`
                              : `${obj[key].toLocaleString()}円`}
                          </div>
                        </td>
                      ) : (
                        key === 'image' && (
                          <td
                            style={{ width: '90px', textAlign: 'center' }}
                            key={key}
                          >
                            <p
                              style={{
                                width: '60px',
                                height: '60px',
                                backgroundImage: `url(${obj[key]})`,
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                borderRadius: '50%',
                                margin: 'auto auto',
                              }}
                            ></p>
                          </td>
                        )
                      );
                    })}
                    {columnSettings.statics.map(staticObj => {
                      if (staticObj.label === '編集') {
                        return (
                          <td
                            style={{ textAlign: 'center' }}
                            key={staticObj.label}
                          >
                            <img
                              src={!isSys ? Icons.icon.editGr : staticObj.icon}
                              alt={'edit'}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                isSys &&
                                  navigate(`/${registerType}/edit`, {
                                    state: {
                                      id: obj[primaryKey],
                                      chartId: activeTab
                                        ? activeTab
                                        : apiData?.header?.[0]?.['chartId'],
                                      startDate: activeTab
                                        ? apiData?.header
                                            ?.filter(
                                              n => n.chartId === activeTab
                                            )
                                            .map(n => n.startDate)
                                        : apiData?.header?.[0]?.['startDate'],
                                    },
                                  });
                              }}
                            />
                          </td>
                        );
                      } else if (staticObj.label === '削除') {
                        return (
                          <td
                            style={{ textAlign: 'center' }}
                            key={staticObj.label}
                          >
                            <img
                              src={
                                !isSys ? Icons.icon.deleteSGr : staticObj.icon
                              }
                              alt={'delete'}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                isSys &&
                                  setDeleteInfo(
                                    obj[primaryKey],
                                    `月額課税対象額　${obj.incomeAmountFrom.toLocaleString()}円〜${obj.incomeAmountTo.toLocaleString()}円`
                                  );
                                adjustZIndex()
                              }}
                            />
                          </td>
                        );
                      } else {
                        return (
                          <td
                            style={{ textAlign: 'center' }}
                            key={staticObj.label}
                          >
                            <img
                              style={{ cursor: 'pointer' }}
                              src={staticObj.icon}
                              onClick={staticObj.onClick}
                            />
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>

        {buttonSettings && buttonSettings.viewPager && !isSp && (
          <div className={'list_view--bottom_pager_wrapper'}>
            <Pager
              searchCount={!searchCount ? 0 : searchCount}
              listPerPage={listPerPage}
              currentPage={currentPage}
              setPage={changeCurrentPage}
            />
          </div>
        )}
      </div>

      {buttonSettings && buttonSettings.viewPager && isSp && (
        <div className={'list_view--bottom_pager_wrapper'}>
          <Pager
            searchCount={!searchCount ? 0 : searchCount}
            listPerPage={listPerPage}
            currentPage={currentPage}
            setPage={changeCurrentPage}
          />
        </div>
      )}

      <DeleteModal
        deleteKey={deleteKey}
        deleteName={deleteName}
        deleteFunction={key => {
          onDelete?.(
            activeTab ? activeTab : apiData?.header[0]['chartId'],
            key,
            activeTab ? activeTab : apiData?.header[0]['startDate']
          );
          setDeleteModalVisible(false);
        }}
        deleteModalVisible={deleteModalVisible}
        toggleVisible={toggleDeleteModal}
        disableKey={true}
        displayNotice={false}
      />
    </>
  );
});

export default CustomListView;
