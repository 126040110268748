import React, { useState, memo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  quoteTemplateListFetch,
  quoteDocumentMail, quoteDetailMailFetch, quoteActions,
} from '../../../../redux/slice/quote';
import { store } from '../../../../redux/store';
import { globalActions } from '../../../../redux/slice/global';
import { useFormik } from 'formik';
import RegisterBase from '../../../../components/registerBase';
import Button from '../../../../components/button';
import PreviewModal from '../../../../components/modal/previewModal';
import Options from '../../../../constants/options';
import Icons from '../../../../constants/icons';
import { handlerFormikSubmit } from '../../../../utils/fnUtil';
import Yup from '../../../../utils/yupUtil';
import { getBase64Promise } from '../../../../utils/fnUtil';
import MailContents from './mailContents.jsx';
import './style.scss';
import { SuperFileReader } from "../../../../components/modal/csvImportModal";
import { cloneDeep } from "lodash";
import moment from "moment";
import { checkIsSp } from '../../../../utils/fnUtil';
const QuoteRegisterMail = memo(() => {
  const navigate = useNavigate();
  const checkedData = useLocation();
  const mailQuoteNumber = checkedData?.state?.id[0];
  const dispatch = useDispatch();
  // const { templateListParams } = useSelector(state => state.quote);
  const { mailParams, upFile, stampFlag } = useSelector(state => state.quote);
  //モダルの開閉をコントロール
  const [modalVisible, setModalVisible] = useState(false);
  const [replaceList, setReplaceList] = useState(false);
  const changeModalVisible = () => {
    setModalVisible(!modalVisible);
  };

  // <br>変換
  const replaceVal = string => {
    string = string.replace(/\r\n/g, '<br>');
    string = string.replace(/\n/g, '<br>');
    string = string.replace(/\s/g, '&nbsp;');
    return string;
  };

  const formatUploadFiles = async (files) => {
    let ret = [];

    for (let i = 0; i < files.length; i++) {
      const base64 = await new SuperFileReader().readAsDataURL(files[i]);
      ret.push({number: i + 1, fileName: files[i].name, file: base64})
    }

    return ret;
  }

  const formik = useFormik({
    initialValues: {
      ...mailParams,
      quoteNumber: mailQuoteNumber,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      toAddress: Yup.string().required().label('宛先To'),
      subject: Yup.string().required().label('件名'),
      message: Yup.string().required().label('本文'),
    }),

    onSubmit: async values => {
      store.dispatch(globalActions.setLoading(true));

      const savedMessage = cloneDeep(values.message);

      values.subject = replaceVal(values.subject);
      values.attendFiles = await formatUploadFiles(upFile);
      values.message = replaceVal(values.message);
      values.quoteNumber = checkedData?.state?.id;
      values.stampFlag = stampFlag ? 1 : 0;

      await dispatch(quoteDocumentMail(values)).then(({ error }) => {
        if (!error) {
          dispatch(quoteActions.setStampFlag(false));
          store.dispatch(globalActions.showSingleModal('送信しました'));
          navigate(-1);
        } else {
          values.message = savedMessage;
        }
      });

      store.dispatch(globalActions.setLoading(false));
    },
  });

  const infos = {
    detail: {
      tabs: {
        payTerm: {
          label: 'メール作成',
          jsx: () => <MailContents formik={formik} checkedData={checkedData.state} />,
        },
      },
    },
  };

  useEffect(()=>{
    if(checkedData?.state?.clientName){
      setReplaceList([
        mailQuoteNumber,
        checkedData?.state?.quoteNm,
        checkedData?.state?.nm,
        checkedData?.state?.clientName,
        checkedData?.state?.userLastName,
        checkedData?.state?.userFirstName,
        moment().format('M月'),
        moment().subtract(1, 'months').format('M月'),
        moment().subtract(2, 'months').format('M月'),
        moment().add(1, 'months').format('M月'),
        moment().add(2, 'months').format('M月'),
      ]);
    }else{
      dispatch(quoteDetailMailFetch({quoteNumber: mailQuoteNumber})).unwrap()
          .then(result => {
            setReplaceList([
              mailQuoteNumber,
              checkedData?.state?.quoteNm,
              checkedData?.state?.nm,
              result?.replaceInfo?.clientName
                  ? result?.replaceInfo?.clientName
                  : checkedData?.state?.clientName,
              result?.replaceInfo?.userLastName
                  ? result?.replaceInfo?.userLastName
                  : checkedData?.state?.userLastName,
              result?.replaceInfo?.userFirstName
                  ? result?.replaceInfo?.userFirstName
                  : checkedData?.state?.userFirstName,
              moment().format('M月'),
              moment().subtract(1, 'months').format('M月'),
              moment().subtract(2, 'months').format('M月'),
              moment().add(1, 'months').format('M月'),
              moment().add(2, 'months').format('M月'),
            ]);
          });
    }
  },[])
  return (
    <>
      <RegisterBase infos={infos} />

      <div className="bottom_btn_area" style={checkIsSp()?{ height: '128px',padding:'16px'}:{ height: '92px' }}>
      {checkIsSp()? <>
        <div className="pay_register--submit_buttons">
        <Button
            text="プレビュー"
            icon={Icons.icon.eye}
            style={{
              width: 'calc(100% - 32px) !important',
              height: '40px',
              borderRadius: '6px',
              border: '1px solid #2B3245',
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              color: '#2B3245',
              margin: '0',
              
            }}
            onClick={() => {
              changeModalVisible();
            }}
          />
        </div>
        <div className="pay_register--submit_buttons">
          <Button
            text="戻る"
            style={Options.buttonStyles.stampBack}
            onClick={() => {
              navigate(-1);
            }}
          />
          <Button
            text="送信"
            style={Options.buttonStyles.stampApproval}
            onClick={() => {
              handlerFormikSubmit(formik);
            }}
          />
         
        </div></>
        :
        <div className="pay_register--submit_buttons">
          <Button
            text="戻る"
            style={Options.buttonStyles.stampBack}
            onClick={() => {
              navigate(-1);
            }}
          />
          <Button
            text="送信"
            style={Options.buttonStyles.stampApproval}
            onClick={() => {
              handlerFormikSubmit(formik);
            }}
          />
          <Button
            text="プレビュー"
            icon={Icons.icon.eye}
            style={{
              width: '200px',
              height: '44px',
              borderRadius: '6px',
              border: '1px solid #2B3245',
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              color: '#2B3245',
              margin: '0',
              position: 'absolute',
              right: 'calc(1% + 20px)',
            }}
            onClick={() => {
              changeModalVisible();
            }}
          />
        </div>}
      </div>

      <PreviewModal
        modalVisible={modalVisible}
        changeModalVisible={changeModalVisible}
        dataSource={formik}
        name={checkedData?.state?.nm}
        replaceList={replaceList}
      />
    </>
  );
});

export default QuoteRegisterMail;
