import { get } from 'lodash';
import { memo } from 'react';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import CommonSwitch from '.';

const ReportSwitch = memo(({ formik, reportInputName, staffInputName, toggleSize }) => (
  <span className="report_switch">
    <CommonSwitch
      label="報告書表示"
      onChange={checked =>
        handlerFormikFieldChange(formik, reportInputName, checked ? 1 : 0)
      }
      checked={get(formik.values, reportInputName) === 1}
      toggleSize={toggleSize}
    />
    {staffInputName && (
      <CommonSwitch
        label="スタッフ入力"
        onChange={checked =>
          handlerFormikFieldChange(formik, staffInputName, checked ? 1 : 0)
        }
        checked={get(formik.values, staffInputName) === 1}
        toggleSize={toggleSize}
      />
    )}
  </span>
));

export default ReportSwitch;
