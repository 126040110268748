import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isNil, sumBy, toInteger } from 'lodash';
import { numberFormat, checkIsSp } from '../../../../utils/fnUtil';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import CostSettingModal from '../../../../components/modal/costSettingModal';
import Button from '../../../../components/button';
import './style.scss';

const CostSetting = memo(({ formik }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { quoteNumber } = formik.values;

  const isSp = checkIsSp();

  const {
    matterQuoteDetail: { quoteAmount },
  } = useSelector(state => state.matter);

  const restAmount =
    quoteAmount -
    toInteger(formik.values.matterQuoteAmount) -
    sumBy(
      formik.values.matterQuote.map(quote => toInteger(quote.matterQuoteAmount))
    );

  return (
    <div className="cost_setting">
      <div className="cost_setting--detail">
        {isSp ? (
          <table style={{ margin: '10px 0 35px 0', width: '100%' }}>
            <thead style={{ height: '30px' }}>
              <tr>
                <th>見積書金額</th>
              </tr>
            </thead>
            <tbody style={{ height: '70px' }}>
              <tr>
                <td>
                  {numberFormat(
                    quoteAmount ?? (isNil(quoteNumber) ? null : 0),
                    '円',
                    '-'
                  )}
                </td>
              </tr>
            </tbody>
            <thead style={{ height: '30px' }}>
              <tr>
                <th>見積残金額</th>
              </tr>
            </thead>
            <tbody style={{ height: '70px' }}>
              <tr>
                <td>
                  {numberFormat(
                    isNil(quoteNumber) ? null : restAmount,
                    '円',
                    '-'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table>
            <thead>
              <tr>
                <th>見積書金額</th>
                <th>見積残金額</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {numberFormat(
                    quoteAmount ?? (isNil(quoteNumber) ? null : 0),
                    '円',
                    '-'
                  )}
                </td>
                <td>
                  {numberFormat(
                    isNil(quoteNumber) ? null : restAmount,
                    '円',
                    '-'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <div>
        <CustomFormikInput
          formik={formik}
          unit="円"
          label="案件見積金額"
          inputName="matterQuoteAmount"
          placeholder="案件見積金額を入力してください"
          style={{
            areaStyle: {
              maxWidth: 562,
            },
            labelStyle: {
              width: 170,
              textAlign: 'right',
            },
          }}
        />
        <Button
          className="adjust-btn"
          text="同じ見積内の案件見積金額を調整する"
          onClick={() => setModalVisible(true)}
          disabled={quoteNumber === null}
          style={
            isSp
              ? {
                  width: '93%',
                  margin: '5px 0 0 0',
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              : {}
          }
        />
      </div>
      <CostSettingModal
        formik={formik}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </div>
  );
});

export default CostSetting;
