import Copyright from '../../components/copyright';
import Foundation from '../../components/foundation';
import './style.scss';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  BrowserRouter,
  useParams,
  useNavigate,
} from 'react-router-dom';
import FormInfos from '../../constants/formInfos';
import { loginFormOnSubmit } from '../../components/customForm/submitFunctions';
import { useSelector, useDispatch } from 'react-redux';
import { setAccount } from '../../redux/slice/account';
import { fetchLoginApi, getApiKeys } from '../../api/fetch';
import { setLoginType } from '../../redux/slice/loginType';
import { useLocation } from 'react-router-dom';
import { store } from '../../redux/store';
import { globalActions } from '../../redux/slice/global';
import { encryptPass } from '../../utils/fnUtil';

const Login = () => {
  const dispatch = useDispatch();

  // パラメータ取得処理
  let isParams = useLocation().search;

  if (Object.keys(isParams).length !== 0) {
    let shapingParam = isParams.substr(isParams.indexOf('=') + 1);
    FormInfos.login.loginUrl = isParams;
    FormInfos.login.isParams = shapingParam;
    FormInfos.forgetPass.isParams = shapingParam;
    FormInfos.login.inputs[0].initialValue = shapingParam;
    FormInfos.forgetPass.inputs[0].initialValue = shapingParam;
  } else {
    FormInfos.login.isParams = false;
  }

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    if (FormInfos.login.isParams && FormInfos.login.pageName === 'login') {
      const targetInputWithCheckBox = document.getElementsByClassName(
        'custom_input--input_with_check_box_area'
      );
      const targetButton = document.getElementsByClassName(
        'custom_form--submit_button_area'
      );
      targetInputWithCheckBox[0].style.marginBottom = '25px';
      targetButton[0].style.marginTop = '24px';
    }

    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const iPadMiniScreen = 800;
  let customButtonSize = {};

  windowDimensions.width > iPadMiniScreen
    ? (customButtonSize = {
        width: '300px',
        height: '44px',
        fontSize: '14px',
        margin: '40px 10px 24px',
      })
    : (customButtonSize = {
        width: '100%',
        height: '40px',
        fontSize: '12px',
        margin: '8px 0 0',
      });
  Object.assign(FormInfos.login.button.style, customButtonSize);

  let loginEmail = localStorage.getItem('login_email');
  loginEmail && (FormInfos.login.inputs[1].initialValue = loginEmail);
  loginEmail
    ? (FormInfos.login.inputs[1].checkBoxChecked = true)
    : (FormInfos.login.inputs[1].checkBoxChecked = false);

  const loginType = window.location.hostname.match(/backoffice/)
    ? 'backoffice'
    // : 'backoffice';
    : 'staff';

  const navigate = useNavigate();

  dispatch(setLoginType(loginType));

  const loginSubmit = async values => {
    const loginValues = {
      corporateId: values.corporate_id,
      mailAddress: values.mail_address,
      password: encryptPass(values.pass),
    };

    store.dispatch(globalActions.setLoading(true));

    await fetchLoginApi(loginType, loginValues)
      .then(async data => {
        if (data.code === 'L00000') {
          let userEmail = values.mail_address;
          let isCheck = document.getElementById('save_mail_address');
          if (isCheck.checked) {
            localStorage.setItem('login_email', userEmail);
          } else {
            localStorage.removeItem('login_email');
          }

          const token = data.token;
          const corporateName = data.item.corporate.corporateName;
          const menuMatterOpenListFlag =
            data.item.corporate.menuMatterOpenListFlag;
          const shiftStyle = data.item.corporate.shiftStyleFlag;
          const employmentType =
            loginType === 'staff' ? data.item?.staff?.employmentType : '';

          data = data.item[loginType === 'backoffice' ? 'user' : 'staff'];

          const apiKeys = await getApiKeys(
            values.corporate_id,
            token,
            loginType
          );

          let loginAccount;

          if (loginType === 'backoffice') {
            loginAccount = {
              ...data,
              fullName: `${data.lastName} ${data.firstName}`,
              corporateName: corporateName,
              accessToken: token,
              shiftStyleFlag: shiftStyle,
              bankApiKey: apiKeys?.bankApiKey ?? '',
              weatherApiKey: apiKeys?.weatherApiKey ?? '',
            };
          } else {
            loginAccount = {
              ...data,
              fullName: `${data.lastName} ${data.firstName}`,
              corporateName: corporateName,
              menuMatterOpenListFlag,
              accessToken: token,
              shiftStyleFlag: shiftStyle,
              employmentType: employmentType,
              bankApiKey: apiKeys?.bankApiKey ?? '',
              weatherApiKey: apiKeys?.weatherApiKey ?? '',
            };
          }

          dispatch(setAccount(loginAccount));

          const staffRequiredColumns = [
            'firstName',
            'firstNameKana',
            'lastName',
            'lastNameKana',
            'mailAddress',
            'birthday',
            'gender',
            'postCode',
            'prefCode',
            'cityName',
            'streetName',
            'daytimePhoneNumber',
            'height',
            'payMethod', // 0の場合、以下必須
            'bankName',
            'bankBranchName',
            'bankBranchCode',
            'bankAccountFlag',
            'bankAccountNumber',
            'bankAccountName',
          ];

          let staffRequiredCheckFlag = true;

          if (loginType === 'staff') {
            staffRequiredColumns.forEach(column => {
              if (!loginAccount[column] && loginAccount[column] != 0) {
                if (
                  !(
                    loginAccount?.payMethod != 0 &&
                    (column === 'bankName' ||
                      column === 'bankBranchName' ||
                      column === 'bankBranchCode' ||
                      column === 'bankAccountFlag' ||
                      column === 'bankAccountNumber' ||
                      column === 'bankAccountName')
                  )
                ) {
                  staffRequiredCheckFlag = false;
                }
              }
            });
          }

          if (loginType === 'staff' && !staffRequiredCheckFlag) {
            navigate('/my-page/edit');
          } else {
            navigate('/');
          }
        } else if (data.code === 'L20000') {
          navigate('/login');
          dispatch(
            globalActions.showSingleModal(
              '規定回数パスワードを誤ったため、ロックしました。\n' +
                'しばらくたってからお試しください。'
            )
          );
        } else {
          navigate('/login');
          dispatch(
            globalActions.showSingleModal(
              '法人ID、メールアドレス、パスワードのいずれかが正しくありません'
            )
          );
        }
      })
      .catch(reason => {
        navigate('/login');
        dispatch(
          globalActions.showSingleModal(
            '法人ID、メールアドレス、パスワードのいずれかが正しくありません'
          )
        );
      })
      .finally(() => store.dispatch(globalActions.setLoading(false)));
  };

  useEffect(() => {
    dispatch(
      setAccount({
        corporateId: '',
        userId: '',
        staffId: '',
        auth: '',
        deptId: '',
        userImage: '',
        staffImage: '',
        corporateName: '',
        fullName: '',
        accessToken: '',
        menuQuoteListFlag: null,
        menuQuoteApprovalListFlag: null,
        menuQuoteDocumentNumberFlag: null,
        menuQuoteTemplateListFlag: null,
        menuQuoteFlowListFlag: null,
        menuMatterListFlag: null,
        menuMatterDateListFlag: null,
        menuMatterAssignListFlag: null,
        menuMatterOpenListFlag: null,
        menuMatterAppliedListFlag: null,
        menuInvoiceListFlag: null,
        menuStaffListFlag: null,
        menuStaffPlanListFlag: null,
        menuStaffPayListFlag: null,
        menuStaffPayYearListFlag: null,
        menuAttendClosingFlag: null,
        menuAnalyzeTrendFlag: null,
        menuAnalyzeProfitFlag: null,
        menuClientListFlag: null,
        menuVenueListFlag: null,
        menuSettingScheduleFlag: null,
        menuUserListFlag: null,
        menuPartnerListFlag: null,
        menuCorporateListFlag: null,
        menuPayTermListFlag: null,
        menuNamingListFlag: null,
        menuIncomeTaxListFlag: null,
        unlockQuoteFlag: null,
        skipQuoteApprovalFlag: null,
        unlockInvoiceFlag: null,
        shiftStyleFlag: null,
        bankApiKey: '',
        weatherApiKey: '',
      })
    );
  }, []);

  return (
    <div className={'login--background'}>
      <Foundation formInfo={FormInfos.login} onSubmit={loginSubmit} />
      <Copyright style={{ position: 'absolute' }} />
    </div>
  );
};

export default Login;
