import './style.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Hamburger from '../hamburger';
import React, { createRef, memo, useEffect, useState } from 'react';
import Icons from '../../constants/icons';
import Options from '../../constants/options';
import { useSelector } from 'react-redux';
import { apiUrl } from '../../api/apiUrl';
import { globalActions } from '../../redux/slice/global';
import { store } from '../../redux/store';
import { fetchRequest } from '../../api/fetch';
import ChangePassModal from '../modal/changePassModal';

const SideMenu = () => {
  const base = createRef();
  const hamburger = createRef();
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const loginType = useSelector(state => state.loginType.type);

  const toggleOpen = () => {
    setOpen(!isOpen);
    base.current.classList.toggle('open');
    hamburger.current.classList.toggle('hamburger_right');

    const sheets = document.styleSheets;
    const sheet = sheets[sheets.length - 1];

    isOpen
      ? sheet.insertRule(
          '.side_menu--link_wrap:not(#各種マスタ):after, .side_menu--link_wrap_active:not(#各種マスタ):after { width: 36px }',
          sheet.cssRules.length
        )
      : sheet.insertRule(
          '.side_menu--link_wrap:not(#各種マスタ):after, .side_menu--link_wrap_active:not(#各種マスタ):after, .side_menu--link_wrap_li:not(#各種マスタ):after, .side_menu--link_wrap_li_active:not(#各種マスタ):after { width: 180px }',
          sheet.cssRules.length
        );

    store.dispatch(globalActions.toggleIsOpenSideMenu(!isOpen));
  };

  useEffect(() => {
    if (location.pathname !== '/' && !isOpen) {
      toggleOpen();
    }
  }, []);

  return (
    <>
      <div className={'side_menu--base'} ref={base}>
        <div className={'side_menu--wrap'} ref={hamburger} onClick={toggleOpen}>
          <div>
            <Hamburger isOpen={isOpen} />
          </div>
        </div>

        <MenuLinks
          isOpen={isOpen}
          path={location.pathname}
          toggleFunc={toggleOpen}
        />

        {isOpen ? (
          <div className={'side_menu--privacy_policy_wrap'}>
            <a
              className={'side_menu--privacy_policy'}
              href={
                loginType === 'backoffice'
                  ? apiUrl.operationManualBo
                  : apiUrl.operationManualStaff
              }
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              操作マニュアル
            </a>
            <a
              className={'side_menu--privacy_policy'}
              href={apiUrl.privacyPolicy}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              プライバシーポリシー
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const MenuLinks = ({ isOpen, path, toggleFunc }) => {
  const loginType = useSelector(state => state.loginType.type);
  const menus = Options.menus[loginType];
  let pageCat = path.split('/')[1];
  const menuKeys = Object.keys(menus);
  const accountInfo = useSelector(state => state.account);
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const isSys = accountInfo?.corporateId === '982803791707394' && accountInfo?.auth !== 2; //グッドウェーブ法人(prod)かつシステム管理者または法人管理者
  // const isSys = true;

  if (pageCat === 'staff-plan') pageCat = 'plan';
  if (pageCat === 'staff-pay') pageCat = 'pay';
  if (pageCat === 'venue') pageCat = 'client';

  const initMenus = () => {
    const uls = document.getElementsByClassName('side_menu--main_menu');
    if (uls) {
      Array.prototype.forEach.call(uls, elm => {
        if (elm.classList.contains('open_menu'))
          elm.classList.remove('open_menu');
      });
    }
  };

  const logoutAction = async () => {
    await fetchRequest(apiUrl.account.logout, {}, 'POST');
    navigate('/login');
  };

  const toggleOpenMenu = e => {
    let elem = '';

    if (e.target.tagName === 'IMG') {
      elem = e.target.parentElement;
    } else if (e.target.tagName === 'P') {
      elem = e.target.parentElement;
    } else if (e.target.tagName === 'DIV') {
      elem = e.target;
    }

    if (isOpen) {
      if (elem.parentElement.classList.contains('open_menu')) {
        elem.parentElement.classList.toggle('open_menu');
        // initMenus();
      } else {
        // initMenus();
        elem.parentElement.classList.toggle('open_menu');
        const subMenus = elem.parentElement.getElementsByTagName('li');

        subMenus[0] && subMenus[0].classList.add('first_sub_menu');
      }
    } else {
      toggleFunc();
    }
  };

  // if (isOpen) {
  //   initMenus();
  // }

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  let spMenuOverlay;
  let spMenuBase;
  let lineFirst;
  let lineSecond;
  let lineThird;

  const toggleMenu = () => {
    spMenuOverlay.classList.toggle('header--sp_side_menu_overlay_open');
    spMenuBase.classList.toggle('header--sp_side_menu_base_open');

    lineFirst.classList.toggle('header--sp_hamburger_line_first_open');
    lineSecond.classList.toggle('header--sp_hamburger_line_second_open');
    lineThird.classList.toggle('header--sp_hamburger_line_third_open');
  };

  return (
    <>
      {Object.values(menus).map((main, index) => {
        let menuCheckCount = 0;
        main.sub
          ? main.sub.map(sub => {
              if (sub.flag != '' ? accountInfo[sub.flag] == 1 : true) {
                menuCheckCount++;
              }
            })
          : menuCheckCount++;
        if (menuCheckCount === 0) {
          return;
        }
        return loginType === 'staff' ? (
          <ul
            className={'side_menu--main_menu'}
            key={menuKeys[index]}
            onClick={toggleOpenMenu}
            style={
              !isOpen &&
              (main.mainText === 'パスワード変更' ||
                main.mainText === 'ログアウト')
                ? { display: 'none' }
                : {}
            }
          >
            {main.mainText !== 'パスワード変更' && main.mainText !== 'ログアウト' ?
              <Link to={main.path}>
                <div
                  className={
                    path === main.path
                      ? 'side_menu--link_wrap_active'
                      : 'side_menu--link_wrap'
                  }
                  id={main.mainText}
                  // onClick={isOpen ? toggleFunc : () => {}}
                >
                  {main.icon && <img src={main.icon} alt={main.mainText} />}
                  <p>{main.mainText}</p>
                </div>
              </Link>
              :
              <div
                className={
                  path === main.path
                    ? 'side_menu--link_wrap_active'
                    : 'side_menu--link_wrap'
                }
                id={main.mainText}
                // onClick={isOpen ? toggleFunc : () => {}}
                onClick={
                  main.mainText === 'パスワード変更'
                    ? () => {
                      toggleModal();
                    }
                    : main.mainText === 'ログアウト'
                      ? logoutAction
                      : toggleMenu
                }
              >
                {main.icon && <img src={main.icon} alt={main.mainText} />}
                <p>{main.mainText}</p>
              </div>
            }

            {!isOpen ? (
              <div
                className={'side_menu--hover_text'}
                style={{ top: `${131 + 56 * index}px` }}
              >
                <p>{main.mainText}</p>
              </div>
            ) : (
              <></>
            )}
          </ul>
        ) : (
          <ul
            className={
              isOpen && pageCat === menuKeys[index]
                ? 'side_menu--main_menu open_menu'
                : 'side_menu--main_menu'
            }
            key={menuKeys[index]}
            onClick={toggleOpenMenu}
          >
            {main.path === '/' ? (
              <Link to={main.path}>
                <div
                  className={
                    path === '/'
                      ? 'side_menu--link_wrap_active'
                      : 'side_menu--link_wrap'
                  }
                  id={main.mainText}
                  // onClick={isOpen ? toggleFunc : () => {}}
                >
                  <img src={main.icon} alt={main.mainText} />
                  <p>{main.mainText}</p>
                </div>
              </Link>
            ) : main.path === '/survey' && !isSys ? (
              <></>
            ) : (
              <div
                className={
                  pageCat === menuKeys[index]
                    ? 'side_menu--link_wrap_active'
                    : 'side_menu--link_wrap'
                }
                id={main.mainText}
              >
                <img src={main.icon} alt={main.mainText} />
                <p>{main.mainText}</p>
              </div>
            )}

            {!isOpen ? (
              <div
                className={'side_menu--hover_text'}
                style={{ top: `${131 + 56 * index}px` }}
              >
                <p>{main.mainText}</p>
              </div>
            ) : (
              <></>
            )}

            {isOpen && main.sub ? (
              main.sub.map((sub, subIndex) => {
                if (sub.flag != '' ? accountInfo[sub.flag] === 0 : false) {
                  return;
                }
                return (
                  <li
                    key={subIndex}
                    className={isOpen && !subIndex ? 'first_sub_menu' : ''}
                    // onClick={toggleFunc}
                  >
                    <Link to={sub.path} onClick={() => sub?.setState(true)}>
                      <div
                        className={
                          !sub?.setState && path === sub.path
                            ? 'side_menu--link_wrap_li_active'
                            : 'side_menu--link_wrap_li'
                        }
                      >
                        <p>{sub.text}</p>
                      </div>
                    </Link>
                  </li>
                );
              })
            ) : (
              <></>
            )}
          </ul>
        );
      })}

      <ChangePassModal
        modalVisible={modalVisible}
        toggleVisible={toggleModal}
        backButton={true}
      />
    </>
  );
};

export default SideMenu;
