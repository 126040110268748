import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Icons from '../../constants/icons';
import { Pie, Column, DualAxes } from '@ant-design/plots';
import { graphConfig } from './graphConfig';
import { store } from '../../redux/store';
import { globalActions } from '../../redux/slice/global';
import './staffDashboardStyle.scss';
import './userDashboardStyle.scss';
import QuoteNumModal from '../../components/modal/quoteNumModal';
import AttendanceProcessModal from '../../components/modal/attendanceProcessModal';
import StaffDashboard from './staffDashboard';
import { dashboardInit } from '../../redux/slice/dashboard';
import { checkIsSp } from '../../utils/fnUtil';
import Copyright from '../../components/copyright';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const globalState = useSelector(state => state.global);
  const isSp = checkIsSp();
  const {
    count,
    age,
    years,
    position,
    gender,
    columnData,
    monthRanking,
    lineGraph,
    assignSituation,
    profit,
    matterSituation,
    lineConfig,
    pieLabels,
    column,
  } = useSelector(state => state.dashboard);

  useEffect(() => {
    loginType === 'backoffice' && dispatch(dashboardInit());
  }, []);

  const matterSituationLabel = [
    {
      division: 5,
      label: '起床報告',
    },
    {
      division: 6,
      label: '出発報告',
    },
    {
      division: 7,
      label: '到着報告',
    },
    {
      division: 8,
      label: '終業報告',
    },
    {
      division: 4,
      label: '前日報告',
    },
    {
      division: 3,
      label: '週報告',
    },
  ];

  const loginType = useSelector(state => state.loginType.type);

  const deleteZeroOfTime = time => {
    if (time.length !== 0) {
      if (time.startsWith('0')) {
        return time.slice(1);
      } else {
        return time;
      }
    } else {
      return '-';
    }
  };

  const displayRecessTime = (startTime, endTime) => {
    if (startTime && endTime) {
      return (
        ' (休憩 ' +
        deleteZeroOfTime(startTime) +
        '~' +
        deleteZeroOfTime(endTime) +
        ')'
      );
    } else {
      return ' (休憩なし)';
    }
  };

  const getWeek = date => {
    const JapaneseHolidays = require('japanese-holidays');

    const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
    let targetDay = new Date(date);

    const holiday = JapaneseHolidays.isHoliday(targetDay);

    if (date) {
      if (holiday) {
        return weekDay[targetDay.getDay()] + '・祝日';
      } else {
        return weekDay[targetDay.getDay()];
      }
    } else {
      return '';
    }
  };

  const searchStaffAssign = e => {
    //API叩く。
    //スタッフアサインに遷移する。
    //その時に条件をAPIに送信する。
    let condition = e.currentTarget.getAttribute('data-condition');
  };

  return (
    <>
      {loginType === 'backoffice' ? (
        <>
          <div className={'dashboard_user'}>
            {/*案件報告状況*/}
            <div className={'dashboard_user--matter_report_situation_area'}>
              <div className={'dashboard_user--title_bar'}>
                <div className={'dashboard_user--title'}>案件報告状況</div>

                <div className={'dashboard_user--date'}>
                  {moment(matterSituation?.date).format('YYYY/M/D')}
                </div>
              </div>

              <div className={'dashboard_user--text_display_area'}>
                {matterSituationLabel.map((c, index) => {
                  return (
                    <div
                      className={
                        'dashboard_user--text_display dashboard_user--on_click_area'
                      }
                      key={index}
                      onClick={() => {
                        if (account.menuMatterAssignListFlag == 0) {
                          dispatch(
                            globalActions.showSingleModal(`権限がありません`)
                          )
                        } else {
                          dispatch(globalActions.setNavigateFromDashboard(c?.label))
                          navigate('/matter/assign/list')
                        }
                      }}
                      data-condition={c?.label}
                    >
                      <div className={'dashboard_user--headline'}>
                        {c?.label}
                      </div>

                      <div className={'dashboard_user--item'}>
                        <div className={'dashboard_user--report'}>
                          <span>
                            {matterSituation?.item
                              .filter(u => u?.division === c?.division)
                              .map(obj => obj?.allCount)
                              ? matterSituation?.item
                                  .filter(u => u?.division === c?.division)
                                  .map(obj => obj?.allCount?.toLocaleString()) +
                                '人中'
                              : '0人中'}
                          </span>
                          <span>
                            {matterSituation?.item
                              .filter(u => u?.division === c?.division)
                              .map(obj => obj?.unreportedCount)
                              ? matterSituation?.item
                                  .filter(u => u?.division === c?.division)
                                  .map(obj =>
                                    obj?.unreportedCount.toLocaleString()
                                  ) + '人未報告'
                              : '0人未報告'}
                          </span>
                        </div>
                        {matterSituation?.item.filter(
                          u =>
                            u?.division === c?.division &&
                            u?.latenessCount !== 0
                        ).length !== 0 &&
                        matterSituation?.item
                          .filter(u => u?.division === c?.division)
                          .map(obj => obj?.latenessCount) ? (
                          <div className={'dashboard_user--alert'}>
                            <img src={Icons.icon.alertRd} />
                            <span>
                              {'うち' +
                                matterSituation?.item
                                  .filter(u => u?.division === c?.division)
                                  .map(obj =>
                                    obj?.latenessCount?.toLocaleString()
                                  ) +
                                '人遅刻'}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={'dashboard_user--image'}>
                        <img src={Icons.icon.rightNv} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/*アサイン状況*/}
            <div className={'dashboard_user--assign_situation_area'}>
              <div className={'dashboard_user--title_bar'}>
                <div className={'dashboard_user--title'}>アサイン状況</div>

                <div className={'dashboard_user--date'}>
                  {moment(assignSituation[0]?.date).format('YYYY/M/D') +
                    '〜' +
                    moment(assignSituation[13]?.date).format('YYYY/M/D')}
                </div>
              </div>

              <div className={'dashboard_user--calender'}>
                {assignSituation.map((obj, idx) => {
                  return (
                    <div
                      className={'dashboard_user--calender_items_area'}
                      key={idx}
                      style={{
                        borderRadius: isSp
                          ? idx === 0
                            ? '6px 6px 0 0'
                            : idx === 13 && '0 0 6px 6px'
                          : idx === 0
                          ? '6px 0 0 0'
                          : idx === 6
                          ? '0 6px 0 0'
                          : idx === 7
                          ? '0 0 0 6px'
                          : idx === 13
                          ? '0 0 6px 0'
                          : '',
                        borderBottom: isSp
                          ? idx !== 13 && '1px solid #A0AAB8'
                          : idx < 7 && '1px solid #A0AAB8',
                        borderRight:
                          !isSp &&
                          idx !== 6 &&
                          idx !== 13 &&
                          '1px solid #A0AAB8',
                        backgroundColor:
                          getWeek(obj.date) === '土'
                            ? '#D7E2F1'
                            : getWeek(obj.date) === '日'
                            ? '#FDF2F5'
                            : getWeek(obj.date).includes('祝')
                            ? '#FDF2F5'
                            : '#FFFFFF',
                      }}
                    >
                      <div
                        className={'dashboard_user--calender_item_header'}
                        style={{
                          color:
                            getWeek(obj.date) === '土'
                              ? '#3662A5'
                              : getWeek(obj.date) === '日'
                              ? '#D80245'
                              : getWeek(obj.date).includes('祝')
                              ? '#D80245'
                              : '',
                        }}
                      >
                        {moment(obj?.type).format('YYYY/M/D') +
                          '(' +
                          getWeek(obj?.type) +
                          ')'}
                      </div>

                      <div className={'dashboard_user--calender_item'}>
                        <span style={{ fontSize: '14px', color: '#6A6F7C' }}>
                          実施案件数
                        </span>
                        <span style={{ fontSize: '14px', color: '#242424' }}>
                          {Number(obj?.value).toLocaleString()}
                        </span>
                      </div>
                      <div className={'dashboard_user--calender_item'}>
                        <span style={{ fontSize: '14px', color: '#6A6F7C' }}>
                          アサイン枠数
                        </span>
                        <span style={{ fontSize: '14px', color: '#242424' }}>
                          {Number(obj?.assignValue).toLocaleString()}
                        </span>
                      </div>
                      <div className={'dashboard_user--calender_item'}>
                        <span style={{ fontSize: '14px', color: '#6A6F7C' }}>
                          未アサイン枠数
                        </span>
                        <span style={{ fontSize: '14px', color: '#242424' }}>
                          {Number(obj?.unAssignValue).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/*グラフ*/}
            <div className={'dashboard_user--graph_area'}>
              {isSp ? (
                <div className={'dashboard_user--graph_bar'}>
                  <div className={'dashboard_user--graph_date'}>
                    {`${moment(lineGraph[0]?.time).format('YYYY/M/D')} ~ 
                  ${moment(lineGraph[30]?.time).format('YYYY/M/D')}`}
                  </div>

                  <div className={'dashboard_user--graph_mark'}>
                    <div className={'dashboard_user--matter_mark_area'}>
                      <div className={'dashboard_user--matter_mark'} />
                      <span className={'dashboard_user--matter_text'}>
                        案件実施数
                      </span>
                    </div>

                    <div className={'dashboard_user--assign_mark_area'}>
                      <div className={'dashboard_user--assign_mark_unity'}>
                        <div className={'dashboard_user--assign_circle'} />
                        <div className={'dashboard_user--assign_line'} />
                      </div>
                      <span className={'dashboard_user--assign_text'}>
                        アサイン枠数
                      </span>
                    </div>
                  </div>
                  <div className={'dashboard_user--graph_title'}>件数(件)</div>
                </div>
              ) : (
                <div className={'dashboard_user--graph_bar'}>
                  <div className={'dashboard_user--graph_title'}>件数(件)</div>
                  <div className={'dashboard_user--graph_date'}>
                    {`${moment(lineGraph[0]?.time).format('YYYY/M/D')} ~ 
                  ${moment(lineGraph[30]?.time).format('YYYY/M/D')}`}
                  </div>
                  <div className={'dashboard_user--graph_mark'}>
                    <div className={'dashboard_user--matter_mark_area'}>
                      <div className={'dashboard_user--matter_mark'} />
                      <span className={'dashboard_user--matter_text'}>
                        案件実施数
                      </span>
                    </div>

                    <div className={'dashboard_user--assign_mark_area'}>
                      <div className={'dashboard_user--assign_mark_unity'}>
                        <div className={'dashboard_user--assign_circle'} />
                        <div className={'dashboard_user--assign_line'} />
                      </div>
                      <span className={'dashboard_user--assign_text'}>
                        アサイン枠数
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className={'dashboard_user--graph'}>
                {lineConfig ? (
                  <DualAxes
                    {...lineConfig}
                    data={[lineGraph, lineGraph]}
                    width={1300}
                    autoFit={!isSp}
                  />
                ) : (
                  <DualAxes
                    {...graphConfig.line}
                    data={[lineGraph, lineGraph]}
                    width={1300}
                    autoFit={!isSp}
                  />
                )}
              </div>
            </div>

            {/*見積と売上*/}
            <div className={'dashboard_user--quote_and_profit_area'}>
              <div className={'dashboard_user--quote'}>
                <div className={'dashboard_user--title_bar'}>
                  <div className={'dashboard_user--title'}>見積</div>
                </div>

                <div
                  className={
                    'dashboard_user--text_display_quote_and_profit dashboard_user--on_click_area'
                  }
                  onClick={() => {
                    account.menuQuoteListFlag == 0
                      ? dispatch(
                          globalActions.showSingleModal(`権限がありません`)
                        )
                      : navigate('/quote/list');
                  }}
                >
                  <div className={'dashboard_user--headline_and_item'}>
                    <div
                      className={'dashboard_user--headline'}
                      style={{ width: '60px' }}
                    >
                      未承認
                    </div>

                    <div
                      className={'dashboard_user--quote_item'}
                      style={{ flexWrap: 'nowrap' }}
                    >
                      <span
                        style={{
                          font: 'normal normal bold 20px Noto Sans CJK JP',
                        }}
                      >
                        {count.toLocaleString()}
                      </span>
                      <span>件</span>
                    </div>
                  </div>

                  <div className={'dashboard_user--image'}>
                    <img src={Icons.icon.rightNv} />
                  </div>
                </div>
              </div>

              <div className={'dashboard_user--profit'}>
                <div className={'dashboard_user--title_bar'}>
                  <div className={'dashboard_user--title'}>売上</div>
                </div>

                <div className={'dashboard_user--text_display_profit'}>
                  <div
                    className={'dashboard_user--profit_item'}
                    style={{
                      borderRadius: isSp ? '6px 6px 0 0' : '6px 0 0 6px',
                      borderRight: isSp ?? '1px solid #A0AAB8',
                      borderBottom: isSp && '1px solid #A0AAB8',
                    }}
                  >
                    <div
                      className={'dashboard_user--headline'}
                      style={{ width: '100px' }}
                    >
                      確定売上額
                    </div>
                    <div className={'dashboard_user--quote_item'}>
                      <span
                        style={{
                          font: 'normal normal bold 20px Noto Sans CJK JP',
                        }}
                      >
                        {Number(profit?.confirmProfit ?? 0).toLocaleString()}
                      </span>
                      <span>円</span>
                    </div>
                  </div>
                  <div
                    className={'dashboard_user--profit_item'}
                    style={{
                      borderRight: isSp ?? '1px solid #A0AAB8',
                      borderBottom: isSp && '1px solid #A0AAB8',
                    }}
                  >
                    <div
                      className={'dashboard_user--headline'}
                      style={{ width: '100px' }}
                    >
                      見込売上額
                    </div>
                    <div className={'dashboard_user--quote_item'}>
                      <span
                        style={{
                          font: 'normal normal bold 20px Noto Sans CJK JP',
                        }}
                      >
                        {Number(profit?.predictionProfit ?? 0).toLocaleString()}
                      </span>
                      <span>円</span>
                    </div>
                  </div>

                  <div
                    className={'dashboard_user--profit_item'}
                    style={{
                      borderRadius: isSp ? '0 0 6px 6px' : '0 6px 6px 0',
                    }}
                  >
                    <div
                      className={'dashboard_user--headline'}
                      style={{ width: '40px' }}
                    >
                      合計
                    </div>

                    <div className={'dashboard_user--quote_item'}>
                      <span
                        style={
                          isSp
                            ? {
                                display: 'inline',
                                position: 'relative',
                                top: '11px',
                              }
                            : {
                                display: 'flex',
                                alignItems: 'flex-end',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                columnGap: '8px',
                                rowGap: 0,
                              }
                        }
                      >
                        <span
                          style={{
                            font: 'normal normal bold 20px Noto Sans CJK JP',
                            paddingRight: isSp && '8px',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {Number(profit?.sumProfit ?? 0).toLocaleString()}
                        </span>
                        <span>円</span>
                        {isSp && <br />}
                        <span
                          style={
                            isSp
                              ? {
                                  fontSize: '14px',
                                  color: '#0056D3',
                                  bottom: '11px',
                                  position: 'relative',
                                }
                              : {
                                  fontSize: '14px',
                                  color: '#0056D3',
                                  whiteSpace: 'nowrap',
                                }
                          }
                        >
                          {(Number(profit?.diffProfit ?? 0) >= 0
                            ? '+ ' +
                              Number(profit?.diffProfit ?? 0).toLocaleString()
                            : '− ' +
                              Math.abs(
                                Number(profit?.diffProfit ?? 0)
                              ).toLocaleString()) + ' 円'}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*在籍スタッフ構成費*/}
            <div className={'dashboard_user--enrolled_staff_area'}>
              <div className={'dashboard_user--title_bar'}>
                <div className={'dashboard_user--title'}>
                  在籍スタッフ構成比
                </div>
              </div>

              <div className={'dashboard_user--enrolled_contents_area'}>
                {[...Array(4)].map((_, index) => {
                  return (
                    <div
                      className={'dashboard_user--enrolled_graph'}
                      key={index}
                    >
                      <div className={'dashboard_user--enrolled_graph_area'}>
                        <Pie
                          {...graphConfig.pie}
                          data={
                            index === 0
                              ? gender
                              : index === 1
                              ? age
                              : index === 2
                              ? years
                              : index === 3
                              ? position
                              : ''
                          }
                          color={graphConfig.pieColors[index]}
                        />
                      </div>
                      <div className={'dashboard_user--enrolled_graph_label'}>
                        <div
                          className={
                            'dashboard_user--enrolled_graph_label_title'
                          }
                        >
                          {graphConfig.pieTitles[index]}
                        </div>

                        {(pieLabels
                          ? pieLabels[index]
                          : graphConfig.pieLabels[index]
                        ).map((elm, idx) => {
                          return (
                            <div
                              className={'dashboard_user--maker_and_label'}
                              key={idx}
                            >
                              <div
                                className={'dashboard_user--maker'}
                                style={{ backgroundColor: elm.color }}
                              />
                              <div className={'dashboard_user--pie_label'}>
                                {elm.label.split('\n')[0]}
                                <br></br>
                                {elm.label.split('\n')[1]}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/*稼働状況*/}
            <div className={'dashboard_user--operation_situation_area'}>
              <div className={'dashboard_user--title_bar'}>
                <div className={'dashboard_user--title'}>稼働状況</div>
              </div>

              <div className={'dashboard_user--operation_situation_chart'}>
                <div
                  className={'dashboard_user--operation_situation_ranking_area'}
                >
                  <div className={'dashboard_user--ranking_title'}>
                    前月稼働枠数TOP50
                  </div>

                  <table className={'dashboard_user--chart'}>
                    <tr className={'dashboard_user--chart_headline'}>
                      <td style={{ width: '45px' }}></td>
                      <td
                        style={{
                          width: isSp
                            ? 'calc(100% - 45px)'
                            : 'calc(100% - 45px - 100px)',
                          textAlign: 'center',
                        }}
                      >
                        顧客名
                      </td>
                      <td style={{ width: '100px' }}>稼働枠数</td>
                    </tr>

                    {monthRanking?.map((elm, index) => {
                      return (
                        <tr className={'dashboard_user--ranking'}>
                          <td
                            style={{
                              width: '45px',
                              font:
                                index < 3
                                  ? 'normal normal bold 14px Noto Sans CJK JP'
                                  : '',
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              width: isSp
                                ? 'calc(100% - 45px)'
                                : 'calc(100% - 45px - 100px)',
                              textAlign: 'left',
                              font:
                                index < 3
                                  ? 'normal normal bold 14px Noto Sans CJK JP'
                                  : '',
                            }}
                          >
                            {elm.client}
                          </td>
                          <td
                            style={{
                              width: '100px',
                              font:
                                index < 3
                                  ? 'normal normal bold 14px Noto Sans CJK JP'
                                  : '',
                            }}
                          >
                            {Number(elm.value).toLocaleString()}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>

                <div className={'dashboard_user--operation_situation_graph'}>
                  <div
                    className={
                      'dashboard_user--operation_situation_graph_title_area'
                    }
                  >
                    <div className={'dashboard_user--square'} />
                    <div
                      className={
                        'dashboard_user--operation_situation_graph_title'
                      }
                    >
                      前月案件実施顧客数
                    </div>
                  </div>

                  <div
                    className={
                      'dashboard_user--operation_situation_graph_label'
                    }
                  >
                    件数(件)
                  </div>
                  <div
                    className={'dashboard_user--operation_situation_graph_area'}
                  >
                    {column ? (
                      <Column
                        {...column}
                        data={columnData}
                        width={1200}
                        autoFit={!isSp}
                      />
                    ) : (
                      <Column
                        {...graphConfig.column}
                        data={columnData}
                        width={1200}
                        autoFit={!isSp}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div*/}
          {/*  className={'dashboard_user--footer'}*/}
          {/*  style={isSp ? { flexFlow: 'column', paddingBottom: '100px' } : {}}*/}
          {/*>*/}
          {/*  <Copyright*/}
          {/*    style={*/}
          {/*      isSp*/}
          {/*        ? {*/}
          {/*            width: '100%',*/}
          {/*            position: 'relative',*/}
          {/*            margin: '0 auto',*/}
          {/*            textAlign: 'center',*/}
          {/*          }*/}
          {/*        : {}*/}
          {/*    }*/}
          {/*  />*/}
          {/*  <p*/}
          {/*    className={'dashboard_user--tag'}*/}
          {/*    style={*/}
          {/*      isSp*/}
          {/*        ? {*/}
          {/*            width: '92%',*/}
          {/*            textAlign: 'right',*/}
          {/*            whiteSpace: 'nowrap',*/}
          {/*            margin: '0 auto',*/}
          {/*          }*/}
          {/*        : {}*/}
          {/*    }*/}
          {/*  >*/}
          {/*    R2事業再構築*/}
          {/*  </p>*/}
          {/*</div>*/}
          <div className={'dashboard_user--footer'} style={isSp ? { flexFlow: 'column', paddingBottom: '100px' } : {}}>
            <p className={'dashboard_user--copy_right'}>
              ©︎ GOODWAVE All right reserved.
            </p>
            <p className={'dashboard_user--tag'}>R2事業再構築</p>
          </div>
          {/* 見積書No管理モダル */}
          <QuoteNumModal
            modalVisible={globalState.quoteNo}
            changeModalVisible={boo =>
              store.dispatch(globalActions.setQuoteNoModal(boo))
            }
          />

          {/* 給与締処理モダル */}
          <AttendanceProcessModal
            modalVisible={globalState.cutOffStaffPay}
            changeModalVisible={boo =>
              store.dispatch(globalActions.setCutOffModal(boo))
            }
          />
        </>
      ) : (
        <StaffDashboard />
      )}
    </>
  );
};

export default Dashboard;
