import { memo } from 'react';
import Icons from '../../../constants/icons';
import classNames from 'classnames';
import './style.scss';

const LayoutPattern = memo(({ pattern, onAddPattern, freeForm = false }) => (
  <div className="layout_pattern">
    <div
      className={classNames('layout_pattern--layout-container', {
        'layout_pattern--layout-container-free': freeForm,
        [`layout_pattern--layout-container-free-pattern-${pattern}`]: freeForm,
      })}
    >
      {[...Array(freeForm && pattern === 3 ? 4 : pattern)].map((_, i) => (
        <div className="layout_pattern--inner" onClick={onAddPattern} key={i}>
          <img src={freeForm ? Icons.icon.plusNv : Icons.icon.tablePlus} />
        </div>
      ))}
    </div>
    <div className="layout_pattern--text">{`パターン${pattern}`}</div>
  </div>
));

export default LayoutPattern;
