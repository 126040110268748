import './style.scss';
import staffAttribute from '../../constants/staffAttribute';
import prefectures from '../../constants/prefectures';
import HorizontalGraph from './horizontalGraph';
import PieGraph from './pieGraph';
import VerticalGraph from './verticalGraph';
import { memo, useEffect } from 'react';

const Graphs = memo(({ data, isStaff, labelType }) => {
  let { graphType, answerMethod } = data;

  const { gender, age, profession, position } = staffAttribute;

  const pref = prefectures;

  const labelColors = [
    '#4ACDE5',
    '#44D89E',
    '#80ADE5',
    '#C08DDC',
    '#E26BC0',
    '#F890D5',
    '#F1758B',
    '#F7A56F',
    '#CECECE',
    '#ABABAB',
  ];

  const checkGraphType = () => {

    let graphList = [];

    //スタッフ画面だったら
    if (isStaff) {
      if (graphType !== null) {
        graphList.push(graphType);
      }
    } else {
      if (graphType !== null) {
        graphList = graphType?.split(',')?.map(Number);
      } else {
        graphList.push(99);
      }
    }

    return graphList;
  };

  const checkIsData = () => {
    const allZero = data?.answerResultInfos?.every(obj => obj.rate === 0);
    return !allZero;
  }

  useEffect(() => {}, [labelType]);

  return (
    <div className="graph_component--display_area">

      {checkGraphType()?.map((g, index) => {
        return (
          <div
            className="graph_component--graph_unity"
            style={g !== 0 ? { flexFlow: 'column' } : {}}
          >
            {checkIsData() && graphType !== null && answerMethod !== 2 ? (
              <div
                className="graph_component--graph_area"
                style={g !== 0 ? { width: '100%' } : {}}
              >
                {g === 0 ? (
                  <PieGraph
                    data={data?.answerResultInfos}
                    color={labelColors}
                  />
                ) : g === 1 ? (
                  <VerticalGraph
                    data={data?.answerResultInfos}
                    color={labelColors}
                  />
                ) : g === 2 ? (
                  <HorizontalGraph
                    data={data?.answerResultInfos}
                    color={labelColors}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            {(checkGraphType().length - 1) === index ? (
              <div
                className="graph_component--label_area"
                style={g !== 0 ? { width: '100%' } : { paddingTop: '20px' }}
              >
                {data?.answerResultInfos?.map((obj, idx) => (
                  <div key={`gc--${idx}`}>
                    <div className="graph_component--mark_with_label">
                      <div
                        className="graph_component--mark"
                        style={{
                          backgroundColor:
                            graphType !== null ? labelColors[idx] : '#ABABAB',
                          border: obj?.isMyAnswer ? 'solid 1px #6A6F7C' : '',
                        }}
                      />
                      <span>
                        {graphType !== null
                          ? `${obj?.answer}･･･${obj?.rate?.toFixed(0)}%`
                          : `${obj?.answer}`}
                      </span>
                    </div>

                    {obj?.attributeInfos
                      ?.filter(
                        info =>
                          info?.aggregationProcessLabelId == labelType ?? 99
                      )
                      ?.sort((a, b) => a?.attributeId - b?.attributeId)
                      ?.map((atr, index) => {
                        const attributes = {
                          0: gender[atr?.attributeId],
                          1: age[atr?.attributeId],
                          2: profession[atr?.attributeId],
                          3: position[atr?.attributeId],
                          4: pref
                            ?.filter(
                              p => p.prefecturesCode === atr?.attributeId
                            )
                            ?.map(p => p.label),
                        };
                        return (
                          <div
                            className="graph_component--attribute_label"
                            key={index}
                          >
                            <span>{`${
                              attributes[atr?.aggregationProcessLabelId] ?? '-'
                            }･･･${atr?.attributeRate}%`}</span>
                          </div>
                        );
                      })}
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
});

export default Graphs;
