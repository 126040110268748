import { memo } from 'react';
import { get } from 'lodash';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Modal } from 'antd';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import {
  fetchUserList,
  matterDateActions,
} from '../../../redux/slice/matterDate';
import SearchTitle from '../../searchTitle';
import Button from '../../button';
import Pager from '../../pager';
import CustomFormikInput from '../../customInput/customFormikInput';
import Avatar from 'antd/lib/avatar/avatar';
import classNames from 'classnames';
import moment from 'moment';
import './style.scss';

const UserSearchModal = memo(({ visible, onCancel, index, formik }) => {
  const dispatch = useDispatch();

  const {
    userList,
    userSearchParams,
    userSearchCount,
    staffAssignList,
    assignDetail,
    deptList,
  } = useSelector(state => state.matterDate);

  const { shift: currentShift } = formik.values;

  const { dateInfo } = assignDetail ?? {};

  const pageSize = 10;

  const { currentAssign } = currentShift[index] ?? {};

  const userSearchFormik = useFormik({
    initialValues: {
      ...userSearchParams,
      matterDate:
        dateInfo?.matterDate &&
        moment(dateInfo.matterDate).format('YYYY-MM-DD'),
    },
    onSubmit: values =>
      dispatch(matterDateActions.saveUserSearchParams(values)),
  });

  useUpdateEffect(() => {
    visible && dispatch(fetchUserList(userSearchParams));
  }, [userSearchParams, visible]);

  return (
    <Modal
      style={{
        minWidth: 600,
      }}
      visible={visible}
      closable={false}
      footer={null}
      centered
      onCancel={onCancel}
      destroyOnClose={true}
      className="employee_search_modal common_modal_base"
    >
      <div className="common_modal_title">社員検索</div>
      <div className="common_modal_content">
        <div className="common_search_area">
          <SearchTitle />
          <div className="search_form_base search_form_employee">
            <CustomFormikInput
              formik={userSearchFormik}
              label="社員ID"
              inputName="userId"
              placeholder="社員IDを入力してください"
            />
            <CustomFormikInput
              formik={userSearchFormik}
              label="社員名"
              placeholder="社員名を入力してください"
              inputName="name"
            />
            <div className="search_form_base--action">
              <Button text="検索" onClick={userSearchFormik.handleSubmit} />
            </div>
          </div>
        </div>
        <div className="table_area">
          <Table
            pagination={false}
            columns={[
              {
                title: '社員ID',
                dataIndex: 'userId',
              },
              {
                title: '社員名',
                width: 200,
                render: (_, record) => (
                  <div className="detail_info">
                    <div>
                      <Avatar src={record.userImage} size={60} />
                    </div>
                    <div>
                      <span>
                        {
                          deptList.find(d => d.namingId === record.deptId)
                            ?.naming
                        }
                      </span>
                      <span>{`${record.name.lastName ?? ''} ${
                        record.name.firstName ?? ''
                      } `}</span>
                    </div>
                  </div>
                ),
              },
              {
                title: '',
                align: 'center',
                render: (_, record) => {
                  const assignDuplication = staffAssignList.some(
                    a =>
                      a.assignId === record.staffId && a.assignAccountFlag === 1
                  );
                  const addFinish = currentShift
                    .flatMap(s => s.shiftAssign)
                    .some(
                      a =>
                        a.assignId === record.userId &&
                        a.assignAccountFlag === 1
                    );

                  const checkAssign = currentShift?.[index]?.shiftAssign?.some( //同シフト枠に社員がセットされていないかを確認する
                    o => o?.userId === record?.userId && o?.assignAccountFlag === 1
                  );

                  return (
                    <Button
                      // disabled={assignDuplication || addFinish}
                      disabled={checkAssign}
                      className={classNames('add_btn', {
                        // add_btn_disabled: assignDuplication || addFinish,
                        add_btn_disabled: checkAssign,
                      })}
                      onClick={() => {
                        const updateShiftAssign = {
                          ...get(
                            formik.values,
                            `shift.[${index}].shiftAssign.[${currentAssign}]`
                          ),
                          ...record,
                          image: record.userImage,
                          assignAccountFlag: 1,
                          assignId: record.userId,
                          assignName: `${record.name.lastName ?? ''} ${
                            record.name.firstName ?? ''
                          }`,
                        };

                        handlerFormikFieldChange(
                          formik,
                          `shift.[${index}].shiftAssign.[${currentAssign}]`,
                          updateShiftAssign
                        );

                        onCancel();
                      }}
                      text={
                        // assignDuplication
                        //   ? 'アサイン重複'
                          // : addFinish
                          checkAssign
                          ? '追加済'
                          : '追加'
                      }
                    />
                  );
                },
              },
            ]}
            dataSource={userList}
          />
          <Pager
            searchCount={userSearchCount}
            listPerPage={pageSize}
            currentPage={userSearchParams.offset / pageSize + 1}
            setPage={e => {
              let nextOffset;
              if (e.target.value === '+') {
                nextOffset = userSearchParams.offset + pageSize * 3;
              } else if (e.target.value === '-') {
                nextOffset = userSearchParams.offset - pageSize * 3;
              } else {
                nextOffset = (Number(e.target.value) - 1) * pageSize;
              }

              dispatch(matterDateActions.saveUserSearchOffset(nextOffset));
            }}
          />
        </div>
      </div>
    </Modal>
  );
});

export default UserSearchModal;
