import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import StaffRegisterInfos from './inputInfos';
import React from 'react';
import { useSelector } from 'react-redux';

const BackGround = ({ formik }) => {
  const { profession1 } = formik.values;

  const { certificationsList } = useSelector(state => state.staff);

  return (
    <div className={'staff_register--background_infos_area'}>
      <CustomFormikInput
        formik={formik}
        {...StaffRegisterInfos.background.inputs[0]}
        initialValue={certificationsList?.map(h => h?.value)}
        selectBoxOptions={certificationsList?.map(h => h?.label)}
      />
      <CustomFormikInput
        formik={formik}
        radioButtonLabelAndChecked={[
          { label: '学生', checked: formik.values.profession1 == 0 },
          {
            label: 'その他(社会人、主婦など)',
            checked: formik.values.profession1 == 1,
          },
        ]}
        {...StaffRegisterInfos.background.inputs[1]}
      />
      {StaffRegisterInfos.background.inputs.map((obj, idx) => {
        return (
          idx > 1 && (
            <CustomFormikInput
              formik={formik}
              {...StaffRegisterInfos.background.inputs[idx]}
              disabled={profession1 != 0}
            />
          )
        );
      })}
    </div>
  );
};
export default BackGround;
