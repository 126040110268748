import React, { memo } from 'react';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import CustomInput from '../../../../components/customInput';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import Icons from '../../../../constants/icons';
import {
  dispatchError,
  handlerFormikFieldChange,
} from '../../../../utils/fnUtil';
import FileUpload from './fileUpload';
import { store } from '../../../../redux/store';
import { globalActions } from '../../../../redux/slice/global';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { apiUrl } from '../../../../api/apiUrl';

const MailContents = memo(({ formik, checkedData, upFile, setUpFile }) => {
  const menuTag = [
    {
      label: '見積書No',
      key: '##quote_number##',
    },
    {
      label: '案件名',
      key: '##matter_name##',
    },
    {
      label: '取引先名',
      key: '##client_name##',
    },
    {
      label: '取引先担当者',
      key: '##client_manager_name##',
    },
    {
      label: '社内案件担当者(姓)',
      key: '##matter_manager_last_name##',
    },
    {
      label: '社内案件担当者(名)',
      key: '##matter_manager_first_name##',
    },
    {
      label: '今月',
      key: '##this_month##',
    },
    {
      label: '先月',
      key: '##last_month##',
    },
    {
      label: '先々月',
      key: '##two_months_ago##',
    },
    {
      label: '翌月',
      key: '##next_month##',
    },
    {
      label: '翌々月',
      key: '##two_months_later##',
    },
  ];
  const { subject, message } = formik.values;

  const changeValue = (input, val) => {
    let textInput = document.getElementById(input);
    let insert = textInput.selectionStart;
    switch (input) {
      case 'subject':
        let subVal = subject.substr(0, insert) + val + subject.substr(insert);
        handlerFormikFieldChange(formik, 'subject', subVal);
        break;
      default:
        let mesVal = message.substr(0, insert) + val + message.substr(insert);
        handlerFormikFieldChange(formik, 'message', mesVal);
        break;
    }
  };

  const accountInfo = useSelector(state => state.account);
  const loginType = useSelector(state => state.loginType.type);
  const stampFlag = checkedData?.stampFlag;

  const openPdf = async num => {
    store.dispatch(globalActions.setLoading(true));
    await fetch(
      `${apiUrl.quote.documentPdf}?quoteNumber=${num}&stampFlag=${
        stampFlag ? 1 : 0
      }`,
      {
        headers: {
          'Content-Type': 'application/json',
          corporate_id: accountInfo.corporateId,
          access_token: accountInfo.accessToken,
          request_from: loginType,
        },
      }
    )
      .then(res => res.blob())
      .then(data => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);

        window.open(pdfUrl, '_blank');
      })
      .catch(error => {
        dispatchError(
          '取得に失敗しました\r\n通信環境の良い場所で再度お試しください'
        );
      })
      .finally(() => store.dispatch(globalActions.setLoading(false)));
  };

  return (
    <>
      <div className="quote_register--mail">
        <div className="quote_register--mail--table">
          <table>
            <thead>
              <tr>
                <th>宛先（複数の場合はカンマ区切りで入力してください）</th>
                <th>取引先</th>
                <th>書類</th>
                <th>その他書類（5つまで選択できます）</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div
                    className="quote_register--mail--table--td"
                    style={{ gap: '24px' }}
                  >
                    <CustomFormikInput
                      inputType="input"
                      formik={formik}
                      inputName="toAddress"
                      label="To"
                      labelRequired={true}
                      style={{ labelStyle: { width: '35px' } }}
                    />
                    <CustomFormikInput
                      inputType="input"
                      formik={formik}
                      inputName="ccAddress"
                      label="CC"
                      style={{ labelStyle: { width: '35px' } }}
                    />
                  </div>
                </td>
                <td>
                  <div
                    className="quote_register--mail--table--td"
                    style={{ paddingRight: '24px', minWidth: '120px' }}
                  >
                    <span>{checkedData?.nm}</span>
                  </div>
                </td>
                <td>
                  <div
                    className="quote_register--mail--table--td"
                    style={{ gap: '16px' }}
                  >
                    {checkedData?.id?.map((e, idx) => {
                      return (
                        <a
                          key={e}
                          className="quote_register--mail--files_nm"
                          onClick={() => openPdf(e)}
                        >
                          {e + checkedData?.quoteNm[idx]}
                        </a>
                      );
                    })}
                  </div>
                </td>
                <td>
                  <div
                    className="quote_register--mail--table--td"
                    style={{ gap: '2vh', alignItems: 'center' }}
                  >
                    <FileUpload />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="quote_register--mail--footer">
          <div className="quote_register--mail--tag_div">
            <Dropdown
              overlay={() => (
                <Menu
                  items={menuTag}
                  onClick={({ key }) => {
                    changeValue('subject', key);
                  }}
                />
              )}
              trigger={['click']}
              overlayClassName="quote_register--mail--tag--overlay"
            >
              <a className="quote_register--mail--tag">
                <img src={Icons.icon.tagBl} alt="tag" />
                <span>差し込みタグ</span>
                <CaretDownOutlined />
              </a>
            </Dropdown>
            <div>
              <CustomFormikInput
                label="件名"
                formik={formik}
                labelRequired={true}
                inputName="subject"
                placeholder="件名を入力してください"
                style={{ labelStyle: { width: '35px' } }}
              />
            </div>
          </div>
          <div className="quote_register--mail--tag_div">
            <Dropdown
              overlay={() => (
                <Menu
                  items={menuTag}
                  onClick={({ key }) => {
                    changeValue('message', key);
                  }}
                />
              )}
              trigger={['click']}
              overlayClassName="quote_register--mail--tag--overlay"
            >
              <a className="quote_register--mail--tag">
                <img src={Icons.icon.tagBl} alt="tag" />
                <span>差し込みタグ</span>
                <CaretDownOutlined />
              </a>
            </Dropdown>
            <div>
              <CustomFormikInput
                inputType="textBox"
                formik={formik}
                label="本文"
                labelRequired={true}
                inputName="message"
                style={{
                  labelStyle: { width: '35px' },
                  inputStyle: { height: '240px' },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default MailContents;
