import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { globalActions } from './global';
import { apiUrl } from '../../api/apiUrl';
import moment from 'moment';
import Options from '../../constants/options';

const initialSearchParams = {
  matterNumber: '',
  matterName: '',
  matterDateStart: '',
  matterDateEnd: '',
  clientId: null,
  venueId: null,
  matterManagerUserId: null,
  salesManagerUserId: null,
  matterStatus: null,
  offset: 0,
  count: 20,
  sortKey: 'matter_date',
  sortMethod: 'Desc',
};

const initialStaffSearchParams = {
  staffId: '',
  staffName: '',
  gender: null,
  staffPosition: null,
  staffEvaluation: null,
  holdingItemList: [],
  certificationList: [],
  enableAssignFlag: null,
  offset: 0,
  count: 20,
  appliedFlag: 0,
  sortKey: '',
  sortMethod: '',
  sort1: null,
  sort2: null,
  sort3: null,
};

const initialUserSearchParams = {
  userId: '',
  name: '',
  offset: 0,
  count: 10,
  status: 1,
};
const initialPartnerSearchParams = {
  partnerCompanyId: null,
  status: 1,
};

const initialState = {
  searchParams: initialSearchParams,
  matterDateList: [],
  clientList: [],
  venueList: [],
  allUserList: [],
  searchCount: 0,
  csvExportVisible: false,
  matterAssignDetail: null,
  assignDetail: null,
  assignStaffDetail: null,
  nearestStation: [],
  startStation: [],
  loading: false,
  staffSearchParams: initialStaffSearchParams,
  staffList: [],
  staffSearchCount: 0,
  holdingitemList: [],
  staffAssignList: [],
  deptList: [],
  certificationList: [],
  userSearchParams: initialUserSearchParams,
  userList: [],
  userSearchCount: 0,
  allPartnerList: [],
  partnerList: [],
  partnerSearchParams: initialPartnerSearchParams,
  calculationPayFlag: null,
};

export const matterDateInit = createAsyncThunk('matter/date/init', async () => {
  const [clientRes, venueRes, userRes] = await fetchMultiRequest([
    fetchRequest(apiUrl.client.list, { status: 1 }),
    fetchRequest(apiUrl.venue.list, { status: 1 }),
    fetchRequest(apiUrl.user.list, { status: 1 }),
  ]);

  return {
    clientList: clientRes?.item ?? [],
    venueList: venueRes?.item ?? [],
    allUserList: userRes?.item ?? [],
  };
});

export const fetchMatterDateList = createAsyncThunk(
  'matter/date/list',
  async params => await fetchRequest(apiUrl.matterDate.list, params)
);

export const fetchMatterDateAssignDetail = createAsyncThunk(
  'matter/date/assign/detail',
  async params => {
    const assignDetailRes = await fetchRequest(
      apiUrl.matterDate.assignDetail,
      params
    );

    let assignDetail = assignDetailRes?.item ?? {};

    let { matterDetail, dateInfo } = assignDetail;

    const matterDate = moment(dateInfo.matterDate).format('YYYY/MM/DD');

    const yearMonth = moment(dateInfo.matterDate).format('YYYY/MM');

    const [venueRes, namingRes, partnerRes, staffAssignRes, corporateRes] =
      await fetchMultiRequest([
        fetchRequest(apiUrl.venue.detail, {
          venueId: matterDetail.venueId,
          deletedFlag: 0,
        }),
        fetchRequest(apiUrl.naming.list, {
          namingClassificationId: ['Holdingitem', 'Certification', 'Dept'],
          status: 1,
        }),
        fetchRequest(apiUrl.partner.list, { status: 1 }),
        fetchRequest(apiUrl.matterStaffAssign.list, {
          matterStartDate: matterDate,
          matterEndDate: matterDate,
        }),
        fetchRequest(apiUrl.corporate.detail, {
          corporateId: params.corporateId,
          deletedFlag: 0,
        }),
      ]);

    const {
      nearestStation1,
      nearestStation2,
      nearestStation3,
      nearestStation4,
      nearestStation5,
    } = venueRes?.detail ?? {};

    const postCode =
      venueRes?.detail?.postCode?.number1 && venueRes?.detail?.postCode?.number2
        ? `${venueRes?.detail?.postCode?.number1}-${venueRes?.detail?.postCode?.number2}`
        : null;

    const address = `${
      venueRes?.detail?.prefCode
        ? Options.codes.pref_code[venueRes?.detail?.prefCode]
        : ''
    }${venueRes?.detail?.cityName ?? ''}${venueRes?.detail?.streetName ?? ''}${
      venueRes?.detail?.buildingName ?? ''
    }`;

    const tel =
      venueRes?.detail?.tel?.number1 &&
      venueRes?.detail?.tel?.number2 &&
      venueRes?.detail?.tel?.number3
        ? `${venueRes?.detail?.tel?.number1}-${venueRes?.detail?.tel?.number2}-${venueRes?.detail?.tel?.number3}`
        : '';

    const fax =
      venueRes?.detail?.fax?.number1 &&
      venueRes?.detail?.fax?.number2 &&
      venueRes?.detail?.fax?.number3
        ? `${venueRes?.detail?.fax?.number1}-${venueRes?.detail?.fax?.number2}-${venueRes?.detail?.fax?.number3}`
        : '';

    const namingItem = namingRes?.item ?? [];

    assignDetail = {
      ...assignDetail,
      postCode: postCode,
      address: address,
      tel: tel,
      fax: fax,
    };

    return {
      assignDetail,
      nearestStation: [
        nearestStation1,
        nearestStation2,
        nearestStation3,
        nearestStation4,
        nearestStation5,
      ].filter(Boolean),
      holdingItemList: namingItem.filter(
        naming => naming.namingClassificationId === 'Holdingitem'
      ),
      certificationList: namingItem.filter(
        naming => naming.namingClassificationId === 'Certification'
      ),
      deptList: namingItem.filter(
        naming => naming.namingClassificationId === 'Dept'
      ),
      staffAssignList: staffAssignRes?.item ?? [],
      allPartnerList: partnerRes?.item ?? [],
      calculationPayFlag: corporateRes?.detail?.calculationPayFlag ?? 2,
    };
  }
);

export const updateMatterDateAssignDetail = createAsyncThunk(
  'matter/date/assign/update',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matterDate.assignUpdate, params, 'POST');

    dispatch(globalActions.showSingleModal('更新しました'));
  }
);

export const assignConfirm = createAsyncThunk(
  'matter/date/assign/confirm',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matterDate.assignConfirm, params, 'POST');

    dispatch(matterDateActions.refresh());
  }
);

export const sendAssignMail = createAsyncThunk(
  'matter/date/assign/mail',
  async (params, { dispatch }) => {
    const sendRes = await fetchRequest(
      apiUrl.matterDate.sendAssginMail,
      params,
      'POST'
    );

    sendRes?.total > 0 &&
      dispatch(globalActions.showSingleModal('送信しました'));
  }
);

export const fetchMatterRecommendStaffList = createAsyncThunk(
  'matter/recommend/staff/list',
  async params =>
    await fetchRequest(apiUrl.matterDate.recommendStaffList, params)
);

export const fetchMatterRecommendDetail = createAsyncThunk(
  'matter/recommend/staff/detail',
  async params =>
    await fetchRequest(apiUrl.matterDate.recommendStaffList, params)
);

export const fetchUserList = createAsyncThunk(
  'matter/assign/user/list',
  async params => await fetchRequest(apiUrl.user.list, params)
);

export const fetchPartnerList = createAsyncThunk(
  'matter/assign/partner/list',
  async params => await fetchRequest(apiUrl.partner.list, params)
);

export const fetchAssignStaffDetail = createAsyncThunk(
  'matter/date/assign/staff/detail',
  async params => {
    const [assignStaffDetailRes, venueRes, userRes, corporateRes] =
      await fetchMultiRequest([
        fetchRequest(apiUrl.matterDate.assignStaffDetail, params),
        fetchRequest(apiUrl.venue.detail, {
          venueId: params.venueId,
          deletedFlag: 0,
        }),
        fetchRequest(apiUrl.user.list),
        fetchRequest(apiUrl.corporate.detail, {
          corporateId: params.corporateId,
          deletedFlag: 0,
        }),
      ]);

    const {
      nearestStation1,
      nearestStation2,
      nearestStation3,
      nearestStation4,
      nearestStation5,
    } = venueRes?.detail ?? {};

    const assignStaffDetail = assignStaffDetailRes?.item ?? {};

    return {
      assignStaffDetail: {
        ...assignStaffDetail,
        advanceApprovalFlag: assignStaffDetail.advanceApprovalFlag ?? 0,
      },
      calculationPayFlag: corporateRes?.detail?.calculationPayFlag ?? 2,
      nearestStation: [
        nearestStation1,
        nearestStation2,
        nearestStation3,
        nearestStation4,
        nearestStation5,
      ].filter(Boolean),
      startStation:
        assignStaffDetail.assignAccountFlag === 0
          ? assignStaffDetail.nearestStation ?? []
          : [],
      allUserList: (userRes?.item ?? []).map(u => ({
        ...u,
        userName: u.name ? `${u.name.lastName} ${u.name.firstName}` : '',
      })),
    };
  }
);

export const updateAssignStaffDetail = createAsyncThunk(
  'matter/date/assign/staff/update',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matterDate.assignStaffUpdate, params, 'POST');

    dispatch(globalActions.showSingleModal('更新しました'));
  }
);

export const matterDateSlice = createSlice({
  name: 'matterDate',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    saveStaffSearchParams: (state, { payload }) => ({
      ...state,
      staffSearchParams: {
        ...state.staffSearchParams,
        ...payload,
        offset: 0,
      },
    }),
    setStaffSearchSort: (state, { payload }) => {
      state.staffSearchParams = {
        ...state.staffSearchParams,
        ...payload,
      };
    },
    saveUserSearchParams: (state, { payload }) => ({
      ...state,
      userSearchParams: {
        ...state.userSearchParams,
        ...payload,
        offset: 0,
      },
    }),
    savePartnerSearchParams: (state, { payload }) => ({
      ...state,
      partnerSearchParams: {
        ...state.partnerSearchParams,
        ...payload,
      },
    }),
    saveStaffSearchOffset: (state, { payload }) => {
      state.staffSearchParams.offset = payload;
    },
    saveUserSearchOffset: (state, { payload }) => {
      state.userSearchParams.offset = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },

    matterAssignDetailClear: state => {
      state.assignDetail = null;
    },
    clearStaffList: state => {
      state.staffList = [];
      state.staffSearchCount = 0;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [matterDateInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchMatterDateList.fulfilled]: (state, { payload }) => {
      const searchCount = payload.searchCount ?? 0;
      state.matterDateList = payload.item ?? [];
      state.searchCount = searchCount;
    },
    [fetchMatterRecommendStaffList.fulfilled]: (state, { payload }) => {
      state.staffList = (payload.item ?? []).map((s, i) => ({
        ...s,
        no: i + 1,
      }));
      state.staffSearchCount = payload.total ?? 0;
    },
    [fetchMatterRecommendDetail.fulfilled]: (state, { payload }) => {
      const [targetStaff] = payload.item ?? [];
      const index = state.staffList.findIndex(
        s => s.staffId === targetStaff.staffId
      );
      state.staffList[index] = targetStaff;
    },
    [fetchUserList.fulfilled]: (state, { payload }) => {
      state.userList = payload.item ?? [];
      state.userSearchCount = payload.searchCount ?? 0;
    },
    [fetchPartnerList.fulfilled]: (state, { payload }) => {
      state.partnerList = payload.item ?? [];
    },
    [fetchMatterDateAssignDetail.pending]: state => {
      state.loading = true;
    },
    [fetchMatterDateAssignDetail.rejected]: state => {
      state.loading = false;
    },
    [fetchMatterDateAssignDetail.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [fetchAssignStaffDetail.pending]: state => {
      state.loading = true;
    },
    [fetchAssignStaffDetail.rejected]: state => {
      state.loading = false;
    },
    [fetchAssignStaffDetail.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
  },
});

export const matterDateActions = matterDateSlice.actions;
export default matterDateSlice.reducer;
