import CustomForm from '../customForm';
import './style.scss';
import { Link } from 'react-router-dom';
import Icons from '../../constants/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MessageModal from '../modal/messageModal';

const Foundation = ({ formInfo, onSubmit }) => {

  const loginType = useSelector(state => state.loginType.type);

  const [closeModal, setCloseModal] = useState(formInfo.loginErrorMessage ? true : false);

  const toggleCloseModal = () => {
    setCloseModal(!closeModal);
  };

  const foundationClassName = () => {
    let className = formInfo.pageName + '--foundation';
    if (formInfo.isParams && formInfo.pageName === 'login') {
      className = className + ' is_corporate_id';
    } else if (formInfo.pageName === 'forgetPass' && formInfo.isParams) {
      className = 'forget_pass--foundation_no_input';
    } else if (formInfo.pageName === 'forgetPass') {
      className = 'forget_pass--foundation';
    } else if (formInfo.pageName === 'changePass') {
      className = 'change_pass--foundation';
    }
    return className;
  }

  return (
    <>
      <div className={foundationClassName()}>
        {formInfo.pageName === 'login' ? (
          <>
            {/* ロゴ画像を返却 */}
            <div className={formInfo.pageName + '--logo_area'}>
              {loginType === 'backoffice' ? (
                <img src={Icons.logo.yoko} alt={'PROMOTION TECHNOLOGY'} />
              ) : (
                <img src={Icons.logo.forStaff} alt={'PROMOTION TECHNOLOGY'} style={{width: '274px'}} />
              )}
            </div>
            <div className={formInfo.pageName + '--form_area'}>
              <CustomForm formInfo={formInfo} onSubmit={onSubmit} />
            </div>
            <div className={formInfo.pageName + '--url_area'}>
              <div>
                <Link to={`/forget-password/`}>パスワード新規作成およびお忘れの方はこちら</Link>
              </div>
            </div>
          </>
        ) : formInfo.pageName === 'forgetPass' || formInfo.pageName === 'changePass' ? (
          <>
            <div className='forget_pass--title_area'>
              <span>{formInfo.pageTitle}</span>
            </div>
            <div className='forget_pass--form_area'>
              <CustomForm formInfo={formInfo} onSubmit={onSubmit} />
            </div>
          </>
        ) : (
          <>
            <div className={formInfo.pageName + '--logo_area'}>{formInfo.pageTitle}</div>
          </>
        )}
      </div>

      <MessageModal
        toggleVisible={toggleCloseModal}
        modalVisible={closeModal}
        message={'ログインできません'}
        text={formInfo.loginErrorMessage}
      />
    </>

  );
};

export default Foundation;
