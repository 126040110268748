import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertDatetime } from '../../../../utils/fnUtil';
import Button from '../../../../components/button';
import Options from '../../../../constants/options';
import './style.scss';

const PayDetail = () => {
  const { staffPayEditList } = useSelector(state => state.staffPay);

  const navigate = useNavigate();

  const getWeek = (year, month, day) => {
    const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
    let targetDay = new Date(year, month - 1, day);

    return weekDay[targetDay.getDay()];
  };

  var noNum = 0;
  var payBaseAmountTotal = 0;
  var overtimePayAmountTotal = 0;
  var payCutAmountTotal = 0;
  var allowanceAmountTotal = 0;
  var fareAmountTotal = 0;
  var busAmountTotal = 0;
  var taxiAmountTotal = 0;
  var communicationAmountTotal = 0;
  var deliveryAmountTotal = 0;
  var othersAmountTotal = 0;
  var beforePayAmountTotal = 0;
  var payAmountAllowance = 0;

  const tableDiligenceTitle = [
    '案件名',
    '勤務時間',
    '支給',
    '基本給',
    '残業',
    '遅刻・早退',
    '手当',
    '交通費',
    'バス',
    'タクシー',
    '通信',
    '宅配',
    'その他',
    '前払金',
    '合計',
    '小計',
  ];

  return (
    <>
      {staffPayEditList.staffPayDetail.payList.map((payList, idx) => {
        payBaseAmountTotal += payList.payBaseAmount * 1;
        overtimePayAmountTotal += payList.overtimePayAmount * 1;
        payCutAmountTotal += payList.payCutAmount * 1;
        allowanceAmountTotal += payList.allowanceAmount * 1;
        fareAmountTotal += payList.fareAmount * 1;
        busAmountTotal += payList.busAmount * 1;
        taxiAmountTotal += payList.taxiAmount * 1;
        communicationAmountTotal += payList.communicationAmount * 1;
        deliveryAmountTotal += payList.deliveryAmount * 1;
        othersAmountTotal += payList.othersAmount * 1;
        beforePayAmountTotal += payList.beforePayAmount * 1;
        payAmountAllowance += payList.totalAmount * 1;
        return (
          <div key={idx}>
            {/*日付*/}
            <div className={'pay_detail_sp--diligence_top'}>
              <div className={'pay_detail_sp--diligence_top_left'}>
                {payList.matterDate.split('/')[1]}月
                {payList.matterDate.split('/')[2]}
                日（
                {getWeek(
                  payList.matterDate.split('/')[0],
                  payList.matterDate.split('/')[1],
                  payList.matterDate.split('/')[2]
                )}
                ）
              </div>
            </div>
            {/*案件名*/}
            <div className={'pay_detail_sp--diligence_title'}>
              <div className={''}>{payList.matterName}</div>
            </div>
            {/*勤務時間*/}
            <div className={'pay_detail_sp--diligence_title'}>
              <div className={''}>{tableDiligenceTitle[1]}</div>
            </div>
            <div
              className={'pay_detail_sp--diligence_contents'}
              style={{ height: '80px' }}
            >
              <div className={''}>
                {convertDatetime(payList.punchInDatetime).split(' ')[1]}
                {'~'}
                {convertDatetime(payList.punchOutDatetime).split(' ')[1]}
                <br></br>
                {' （遅刻:'}
                {payList.payCutTime ?? 0}
                {'分、残業:'}
                {payList.overtime ?? 0}
                {'分）'}
              </div>
            </div>
            {/*支給*/}
            <div className={'pay_detail_sp--diligence_title'}>
              <div className={''}>{tableDiligenceTitle[2]}</div>
            </div>
            {/*基本給*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '40px', padding: '10px 0 0 0' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[3]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.payBaseAmount
                  ? payList.payBaseAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            {/*残業*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[4]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.overtimePayAmount
                  ? payList.overtimePayAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            {/*遅刻早退*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[5]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.payCutAmount
                  ? '▲' + payList.payCutAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            {/*手当*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[6]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.allowanceAmount
                  ? payList.allowanceAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            {/*交通費*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[7]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.fareAmount ? payList.fareAmount.toLocaleString() : '0'}
                円
              </div>
            </div>
            {/*バス*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[8]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.busAmount ? payList.busAmount.toLocaleString() : '0'}円
              </div>
            </div>
            {/*タクシー*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[9]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.taxiAmount ? payList.taxiAmount.toLocaleString() : '0'}
                円
              </div>
            </div>
            {/*通信*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[10]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.communicationAmount
                  ? payList.communicationAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            {/*宅配*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[11]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.deliveryAmount
                  ? payList.fareAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            {/*その他*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[12]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.othersAmount
                  ? payList.othersAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            {/*前払金*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[13]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.beforePayAmount
                  ? payList.beforePayAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            {/*合計*/}
            <div
              className={'pay_detail_sp--diligence_contents_list'}
              style={{ height: '30px', padding: '20px 0', fontWeight: 'bold' }}
            >
              <div className={'pay_detail_sp--diligence_contents_list_left'}>
                {tableDiligenceTitle[14]}
              </div>
              <div className={'pay_detail_sp--diligence_contents_list_right'}>
                {payList.totalAmount
                  ? payList.totalAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            <div
              className={'pay_detail_sp--diligence_contents_list_border_last'}
            ></div>
          </div>
        );
      })}
      {/*小計*/}
      <div className={'pay_detail_sp--diligence_small_total'}>
        <div className={''}>{tableDiligenceTitle[15]}</div>
      </div>
      {/*基本給*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '40px', padding: '10px 0 0 0' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[3]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {payBaseAmountTotal ? payBaseAmountTotal.toLocaleString() : '0'}円
        </div>
      </div>
      {/*残業*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[4]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {overtimePayAmountTotal
            ? overtimePayAmountTotal.toLocaleString()
            : '0'}
          円
        </div>
      </div>
      {/*遅刻早退*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[5]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {payCutAmountTotal ? '▲' + payCutAmountTotal.toLocaleString() : '0'}円
        </div>
      </div>
      {/*手当*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[6]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {allowanceAmountTotal ? allowanceAmountTotal.toLocaleString() : '0'}円
        </div>
      </div>
      {/*交通費*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[7]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {fareAmountTotal ? fareAmountTotal.toLocaleString() : '0'}円
        </div>
      </div>
      {/*バス*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[8]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {busAmountTotal ? busAmountTotal.toLocaleString() : '0'}円
        </div>
      </div>
      {/*タクシー*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[9]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {taxiAmountTotal ? taxiAmountTotal.toLocaleString() : '0'}円
        </div>
      </div>
      {/*通信*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[10]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {communicationAmountTotal
            ? communicationAmountTotal.toLocaleString()
            : '0'}
          円
        </div>
      </div>
      {/*宅配*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[11]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {deliveryAmountTotal ? deliveryAmountTotal.toLocaleString() : '0'}円
        </div>
      </div>
      {/*その他*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[12]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {othersAmountTotal ? othersAmountTotal.toLocaleString() : '0'}円
        </div>
      </div>
      {/*前払金*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[13]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {beforePayAmountTotal ? beforePayAmountTotal.toLocaleString() : '0'}円
        </div>
      </div>
      {/*合計*/}
      <div
        className={'pay_detail_sp--diligence_contents_list'}
        style={{ height: '30px', padding: '20px 0', fontWeight: 'bold' }}
      >
        <div className={'pay_detail_sp--diligence_contents_list_left'}>
          {tableDiligenceTitle[14]}
        </div>
        <div className={'pay_detail_sp--diligence_contents_list_right'}>
          {payAmountAllowance ? payAmountAllowance.toLocaleString() : '0'}円
        </div>
      </div>
      <div
        className={'pay_detail_sp--diligence_contents_list_border_last'}
      ></div>

      <div className={'bottom_btn_area'} style={{ height: '92px' }}>
        <div className={'quote_register--submit_buttons'}>
          <Button
            text={'戻る'}
            style={Options.buttonStyles.stampBack}
            onClick={() => {
              navigate('/staff-pay/list');
            }}
          />
        </div>
      </div>
    </>
  );
};
export default PayDetail;
