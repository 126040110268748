import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Options from '../../../constants/options';
import PayDetail from './payDetail';
import PayDetailSp from './payDetailForSp';
import PayTotalSp from './payDetailForSp/payTotal';
import PayStampSp from './payDetailForSp/payStamp';
import {
  staffPayActions,
  staffPayDetailFetch,
} from '../../../redux/slice/staffPay';
import { checkIsSp } from '../../../utils/fnUtil';
import './style.scss';

const { TabPane } = Tabs;

const StaffPayEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { editParams, staffPayEditList } = useSelector(state => state.staffPay);

  const isSp = checkIsSp();

  useEffect(() => {
    dispatch(
      staffPayActions.setEdit({
        staffId: location?.state?.id,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      staffPayDetailFetch({ ...editParams, staffId: location?.state?.id })
    );
  }, [dispatch, editParams]);

  const tabList = isSp
    ? [
        {
          tab: '勤務情報',
          component: <PayDetailSp />,
        },
        {
          tab: '支払情報',
          component: (
            <PayTotalSp dataSource={staffPayEditList.staffPayDetail} />
          ),
        },
        {
          tab: '承認選択',
          component: (
            <PayStampSp dataSource={staffPayEditList.staffPayDetail} />
          ),
        },
      ]
    : [
        {
          tab: '支払明細',
          component: <PayDetail />,
        },
      ];

  return isSp ? (
    <>
      <div className={'page_base--container'}>
        <div className="staff_pay_edit--title display-linebreak">
          <div className="staff_pay_edit--title--img_area">
            <img
              src={
                staffPayEditList.staffDetail.staffImage
                  ? staffPayEditList.staffDetail.staffImage
                  : ''
              }
              alt="account"
              className="staff_pay_edit--title--img"
            />
            <span className="staff_pay_edit--title--id_name">
              <span className="staff_pay_edit--title--id">
                {staffPayEditList.staffDetail.staffId
                  ? staffPayEditList.staffDetail.staffId
                  : ''}
              </span>
              <span className="staff_pay_edit--title--name">
                {staffPayEditList.staffDetail.staffName.lastName +
                  ' ' +
                  staffPayEditList.staffDetail.staffName.firstName}
              </span>
            </span>
          </div>
          <div className="staff_pay_edit--title--info1">
            <span>TEL(スマートフォン)</span>
            <span>
              {staffPayEditList.staffDetail.daytimeTel != null
                ? staffPayEditList.staffDetail.daytimeTel.number1 +
                  '-' +
                  staffPayEditList.staffDetail.daytimeTel.number2 +
                  '-' +
                  staffPayEditList.staffDetail.daytimeTel.number3
                : ''}
            </span>
          </div>
          <div className="staff_pay_edit--title--info1">
            <div>最寄駅</div>
            <div>
              {staffPayEditList.staffDetail.nearestStations
                .map(c => [c.stationName])
                .join(',')}
            </div>
          </div>
          <div className="staff_pay_edit--title--info1">
            <div>給与支払方法</div>
            <div>
              {Options.codes.payMethod[staffPayEditList.staffDetail.payMethod]}
            </div>
          </div>
        </div>

        <div className="page_base--tabs staff_pay_edit--tabs">
          <Tabs defaultActiveKey="0">
            {tabList.map(item => (
              <TabPane tab={item.tab} key={item.tab}>
                {item.component}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className={'page_base--container'}>
        <div className="staff_pay_edit--title display-linebreak">
          <div className="staff_pay_edit--title_left">
            <img
              src={
                staffPayEditList.staffDetail.staffImage
                  ? staffPayEditList.staffDetail.staffImage
                  : ''
              }
              alt="account"
              className="staff_pay_edit--title--img"
            />
            <div>
              <div className="staff_pay_edit--title--id">
                {staffPayEditList.staffDetail.staffId
                  ? staffPayEditList.staffDetail.staffId
                  : ''}
              </div>
              <div className="staff_pay_edit--title--name">
                {staffPayEditList.staffDetail.staffName.lastName +
                  ' ' +
                  staffPayEditList.staffDetail.staffName.firstName}
              </div>
            </div>
          </div>
          <div className="staff_pay_edit--title_right">
            <div className="staff_pay_edit--title--info1">
              <div>TEL(スマートフォン)</div>
              <div>
                {staffPayEditList.staffDetail.daytimeTel != null
                  ? staffPayEditList.staffDetail.daytimeTel.number1 +
                    '-' +
                    staffPayEditList.staffDetail.daytimeTel.number2 +
                    '-' +
                    staffPayEditList.staffDetail.daytimeTel.number3
                  : ''}
              </div>
            </div>
            <div className="staff_pay_edit--title--info2">
              <div className="staff_pay_edit--title--info1">
                <div>最寄駅</div>
                <div>
                  {staffPayEditList.staffDetail.nearestStations
                    .map(c => [c.stationName])
                    .join(',')}
                </div>
              </div>
              <div className="staff_pay_edit--title--info1">
                <div>給与支払方法</div>
                <div>
                  {
                    Options.codes.payMethod[
                      staffPayEditList.staffDetail.payMethod
                    ]
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page_base--tabs staff_pay_edit--tabs">
          <Tabs defaultActiveKey="0">
            {tabList.map(item => (
              <TabPane tab={item.tab} key={item.tab}>
                {item.component}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default StaffPayEdit;
