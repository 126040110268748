import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import prefectures from '../../constants/prefectures';
import options from '../../constants/options';
import moment from 'moment';
import { store } from '../store';

const namingValidList = [
  'StaffPreliminary1',
  'StaffPreliminary2',
  'StaffPreliminary3',
  'StaffPreliminary4',
  'StaffPreliminary5',
];

const CategorySearchParams = {
  namingClassificationId:
    'Index,StaffPreliminary1,StaffPreliminary2,StaffPreliminary3,' +
    'StaffPreliminary4,StaffPreliminary5,Certification,Holdingitem,' +
    'RetireReason,RecruitingMedia',
  status: 1,
  sort: 'namingClassificationIdAsc',
};

const initialSearchParams = {
  staffId: null,
  staffName: null,
  gender: 99,
  prefCode: [],
  status: 99,
  certifications: [],
  heightFrom: null,
  heightTo: null,
  managerUserName: [],
  namingClassificationId: null,
  namingId: [],
  offset: 0,
  count: 20,
  sort: 'staffIdDesc',
  sortColumn: 'staffId',
  sortMethod: 'Desc',
};
const initialEditParams = {
  staffId: null,
  staffImage: null,
  lastName: null,
  firstName: null,
  lastNameKana: null,
  firstNameKana: null,
  mailAddress: null,
  startDate: moment(new Date(), 'YYYY/MM/DD').format('YYYY/MM/DD'),
  endDate: '2099/12/31',
  year: null,
  month: null,
  day: null,
  gender: 1,
  postCode1: null,
  postCode2: null,
  prefCode: null,
  cityName: null,
  streetName: null,
  buildingName: null,
  tel1: null,
  tel2: null,
  tel3: null,
  fax1: null,
  fax2: null,
  fax3: null,
  lineId: null,
  hopeAssign: null,
  weekMethodFlag: 0,
  previousDayMethodFlag: 0,
  gettingUpMethodFlag: 0,
  departureMethodFlag: 0,
  arriveMethodFlag: 0,
  endMethodFlag: 0,
  joinDate: null,
  retireDate: null,
  retireReason: null,
  mainManagerUserId: null,
  subManagerUserId: null,
  employmentType: 0,
  classification: 0,
  taxClassification: 0,
  dependents: null,
  dailyPay: null,
  hourlyPay: null,
  recruitMedia: null,
  recruitCost: null,
  position: 0,
  evaluation: null,
  nearestStations: [],
  height: null,
  weight: null,
  shoeSize: null,
  wearSize: null,
  holdingItems: [],
  certifications: [],
  occupation: 1,
  profession: 1,
  schoolName: null,
  admissionYear: null,
  payMethod: null,
  bankName: null,
  bankCode: null,
  bankBranchName: null,
  bankBranchCode: null,
  bankAccountFlag: 1,
  bankAccountNumber: null,
  bankAccountName: null,
  preliminary1: null,
  preliminary2: null,
  preliminary3: null,
  preliminary4: null,
  preliminary5: null,
  note: null,
  sendMailFlag: false,
};

const initialState = {
  searchParams: initialSearchParams,
  searchWorkParams: initialSearchParams,
  editParams: initialEditParams,
  searchCount: 0,
  totalCount: 0,
  activeCount: 0,
  namingCategoryList: [],
  namingList: [],
  certificationsList: [],
  retireReasonList: [],
  recruitingMediaList: [],
  staffList: [],
  userList: [],
  venueList: [],
  payTermList: [],
  bankingList: [],
  branchesList: [],
  corporateBranchesList: [],
  csvImportVisible: false,
  csvExportVisible: false,
  nextId: 0,
};

export const staffListFetch = createAsyncThunk('staff/list', async params => {
  const staffRes = await fetchRequest(apiUrl.staff.list, params);
  const item = staffRes?.item.map(c => {
    return {
      ...c,
      staffName: `${c?.staffName?.lastName ?? ''} ${
        c?.staffName?.firstName ?? ''
      } `,
      gender: options.codes.gender.find(g => g.value === c.gender)?.label,
      image: c?.staffImage,
      mainManagerUserName: `${c?.mainManagerUserName?.lastName ?? ''} ${
        c?.mainManagerUserName?.firstName ?? ''
      } `,
      subManagerUserName: `${c?.subManagerUserName?.lastName ?? ''} ${
        c?.subManagerUserName?.firstName ?? ''
      } `,
      prefName: `${
        prefectures.find(p => p?.prefecturesCode === c?.prefCode)?.label ?? '-'
      }`,
      editIconState: c?.status === -1 ? 1 : 0,
      deleteIconState: c?.status === -1 ? 2 : 0,
      deletedFlag: c?.status === -1 ? 1 : 0,
      status: c?.status === 1 ? '有効' : c?.status === 0 ? '無効' : '削除済',
    };
  });
  return {
    staffList: {
      item,
      count: staffRes?.searchCount ?? 0,
      topCount: [staffRes?.totalCount ?? 0, staffRes?.activeCount ?? 0],
    },
  };
});

export const staffDetailFetch = createAsyncThunk(
  'staff/detail',
  async params => {
    return await fetchRequest(apiUrl.staff.detail, params, 'GET');
  }
);
export const staffInfoFetch = createAsyncThunk('staff/info', async params => {
  const [userRes, namingRes] = await Promise.all([
    fetchRequest(apiUrl.user.list, { status: 1 }),
    fetchRequest(apiUrl.naming.list, CategorySearchParams),
  ]);
  return {
    userList: userRes.item ?? [],
    namingCategoryList: namingRes?.item
      ?.sort((a, b) => (a.namingId < b.namingId ? -1 : 1))
      ?.filter(n => namingValidList.includes(n?.namingId ?? ''))
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    namingList: namingRes?.item
      ?.filter(n => n?.namingClassificationId !== 'Index')
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: `${n?.namingId}: ${n?.naming}`,
        };
      }),
    certificationsList: namingRes?.item
      ?.filter(n => n?.namingClassificationId == 'Certification')
      .sort((a, b) => (parseInt(a.priority) < parseInt(b.priority) ? -1 : 1))
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    retireReasonList: namingRes?.item
      ?.filter(n => n?.namingClassificationId == 'RetireReason')
      .sort((a, b) => (parseInt(a.priority) < parseInt(b.priority) ? -1 : 1))
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    recruitingMediaList: namingRes?.item
      ?.filter(n => n?.namingClassificationId == 'RecruitingMedia')
      .sort((a, b) => (parseInt(a.priority) < parseInt(b.priority) ? -1 : 1))
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    holdingItemsList: namingRes?.item
      ?.filter(
        n =>
          n?.namingClassificationId == 'Holdingitem' &&
          n?.namingId !== '98' &&
          n?.namingId !== '99'
      )
      .sort((a, b) => (parseInt(a.priority) < parseInt(b.priority) ? -1 : 1))
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: `${n?.priority}: ${n?.naming}`,
        };
      }),
  };
});
export const bankingCodeFetch = createAsyncThunk(
  'banking/code/info',
  async params => {
    const { bankApiKey } = store.getState().account;
    const bankRes = await fetchRequest(apiUrl.bank_code_jp, {
      apiKey: bankApiKey,
      limit: 2000,
    });
    const bankItem = bankRes?.data?.map(b => {
      return {
        label: b?.name,
        value: b?.code,
      };
    });
    return {
      bankingList: bankItem ?? [],
    };
  }
);

export const bankingFetch = createAsyncThunk('banking/info', async params => {
  const { bankApiKey } = store.getState().account;
  const bankRes = await fetchRequest(
    `${apiUrl.bank_code_jp}/${params}/branches`,
    {
      apiKey: bankApiKey,
      limit: 2000,
    }
  );
  const branchesItem = bankRes?.data?.map(b => {
    return {
      label: b?.name,
      value: b?.code,
    };
  });
  return {
    branchesList: branchesItem ?? [],
  };
});

export const multiBankingFetch = createAsyncThunk('banking/info', async params => {
  const { bankApiKey } = store.getState().account;
  let paramsAry = params ?? [];
  const defParams = {
    apiKey: bankApiKey,
    limit: 2000,
  }

  let branchesItems = {};

  if (paramsAry.length !== 0) {
    const [
      branchesItem0,
      branchesItem1,
      branchesItem2,
      branchesItem3,
      branchesItem4
    ] = await fetchMultiRequest(
      paramsAry?.map(p => {
        if (p) return fetchRequest(`${apiUrl.bank_code_jp}/${p}/branches`, defParams);
      })
    );

    branchesItems = {
      branchesItem0,
      branchesItem1,
      branchesItem2,
      branchesItem3,
      branchesItem4
    };

    branchesItems = Object.keys(branchesItems)?.map(d => branchesItems[d]?.data?.map(d => {
      return { label: d?.name, value: d?.code };
    }));
  }

  return {
    corporateBranchesList: branchesItems ?? []
  }
});

export const uploadImage = createAsyncThunk(
  'staff/upload/image',
  async params => await fetchRequest(apiUrl.other.imageUpload, params, 'POST')
);
export const staffInsert = createAsyncThunk(
  'staff/insert',
  async params => await fetchRequest(apiUrl.staff.insert, params, 'POST')
);
export const staffUpdate = createAsyncThunk(
  'staff/update',
  async params => await fetchRequest(apiUrl.staff.update, params, 'POST')
);

export const staffDetailUpdate = createAsyncThunk(
  'staff/detail/update',
  async params => await fetchRequest(apiUrl.staff.updateStaff, params, 'POST')
);

export const staffDelete = createAsyncThunk(
  'staff/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.staff.delete, params, 'POST');
    dispatch(staffActions.refresh());
  }
);

export const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveParam: (state, { payload }) => {
      state.searchWorkParams = { ...state.searchParams };
    },
    resetParam: (state, { payload }) => {
      state.searchParams = {
        ...state.searchWorkParams,
        offset: 0,
        count: 20,
      };
      state.searchWorkParams = initialSearchParams;
    },
    setEdit: state => {
      state.editParam = { ...state.editParam };
    },
    refresh: state => {
      state.searchParams = initialSearchParams;
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvImportVisible: (state, { payload }) => {
      state.csvImportVisible = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    editReset: (state, { payload }) => {
      state.editParams = initialEditParams;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    bankReset: (state, { payload }) => {
      state.branchesList = [];
    },
  },
  extraReducers: {
    [staffListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffDetailFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffInfoFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [bankingCodeFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [bankingFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [multiBankingFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const staffActions = staffSlice.actions;
export default staffSlice.reducer;
