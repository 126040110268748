import { memo } from 'react';
import CustomFormikInput from '../customInput/customFormikInput';
import DeleteIcon from '../deleteIcon';
import ReportSwitch from '../switch/reportSwitch';
import './style.scss';

const InputItem = memo(
  ({
    formik,
    label,
    onDelete,
    itemInputName,
    contentInputName,
    reportInputName,
    staffInputName,
    relativeInputName,
    toggleSize,
  }) => {
    return (
      <div className="input_item">
        <DeleteIcon title="削除" onClick={onDelete} />
        <div className="input_item--main">
          <CustomFormikInput
            formik={formik}
            inputName={itemInputName}
            relativeInputName={relativeInputName}
            label={label}
            placeholder="項目名を入力してください"
          />
          <ReportSwitch
            formik={formik}
            reportInputName={reportInputName}
            staffInputName={staffInputName}
            toggleSize={toggleSize}
          />
        </div>
        <CustomFormikInput
          formik={formik}
          inputName={contentInputName}
          inputType="textBox"
          label=" "
          placeholder="入力してください"
        />
      </div>
    );
  }
);

export default InputItem;
