import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  analyzeActions,
  analyzeGraphList,
  analyzeStaffInit,
} from '../../../redux/slice/analyze';
import { useFormik } from 'formik';
import CsvExportModal from '../../../components/modal/csvExportModal';
import Button from '../../../components/button';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Copyright from '../../../components/copyright';
import Options from '../../../constants/options';
import Icons from '../../../constants/icons';
import Yup from '../../../utils/yupUtil';
import ProfitGraph from './profitGraph';
import './style.scss';
import ListView from '../../../components/listView';
import { checkIsSp, handlerFormikSubmit } from '../../../utils/fnUtil';
import moment from 'moment';

const monthDivSty = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

const AnalyzeProfit = () => {
  const dispatch = useDispatch();
  const isSp = checkIsSp();
  const {
    searchParams,
    deptList,
    userList,
    clientList,
    profitGraphX,
    profitGraphY,
    profitList,
    csvExportVisible,
  } = useSelector(state => state.analyze);
  const { isOpenSideMenu } = useSelector(state => state.global);

  const columnSettings = {
    matterName: { label: '案件名', sort: 'desc', dataName: 'matterName' },
    salesManagerUserName: {
      label: '営業担当者',
      sort: 'none',
      dataName: 'salesManagerUserName',
    },
    matterManagerUserName: {
      label: '案件担当者',
      sort: 'none',
      dataName: 'matterManagerUserName',
    },
    quoteAmount: { label: '予算金額', sort: 'none', dataName: 'quoteAmount' },
    actualAmount: { label: '実績金額', sort: 'none', dataName: 'actualAmount' },
    staffCount: {
      label: '自社スタッフ数',
      sort: 'none',
      dataName: 'staffCount',
    },
    userCount: { label: '社員数', sort: 'none', dataName: 'userCount' },
    partnerCount: { label: '外注数', sort: 'none', dataName: 'partnerCount' },
    rate: { label: '自社手配率', sort: 'none', dataName: 'rate' },
    statics: [],
  };

  const buttonSettings = {
    viewPager: true,
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => dispatch(analyzeActions.setCsvExportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    ignoreAddBtn: isSp,
  };

  const changeSort = e => {
    dispatch(
      analyzeActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`,
      })
    );
  };

  //検索条件の開閉
  const toggleOpenSearchForm = () => {
    const searchFormArea = document.querySelector(
      '.list_view--search_form_area'
    );
    const searchButtonArea = document.querySelector(
      '.list_view--search_button_area'
    );
    const searchBase = document.querySelector('.list_view--search_base');
    const triangle = document.getElementById('searchFormTriangle');

    triangle.style.transition = 'all .5s';

    if (
      triangle.style.transform === '' ||
      triangle.style.transform === 'rotate(0deg)'
    ) {
      searchFormArea.style.opacity = '0';
      searchButtonArea.style.opacity = '0';
      triangle.style.transform = 'rotate(180deg)';
      searchBase.style.maxHeight = '20px';
    } else {
      searchFormArea.style.opacity = '1';
      searchButtonArea.style.opacity = '1';
      triangle.style.transform = 'rotate(0deg)';
      searchBase.style.maxHeight = '1000px';
    }
  };

  //グラフの最大値を算出する処理
  const graphMaxCount = (data, boolean) => {
    let yearList = [];
    data?.forEach(obj => {
      yearList.push(obj.yearMonth);
    });

    const set = new Set(yearList);
    const newArr = [...set]; //yearListの重複した値を削除

    let numList = [];

    newArr?.forEach(y => {
      //各yearMonthの合算値を配列にまとめる
      let num = 0;
      data
        ?.filter(d => d?.yearMonth === y)
        .forEach(obj => {
          num = num + obj[boolean ? 'value' : 'count'];
        });
      numList.push(num);
    });

    let maxCount = 0;
    if (numList.length !== 0) {
      //配列の中から最大値を取得
      const aryMax = (a, b) => Math.max(a, b);
      maxCount = numList?.reduce(aryMax);
    }

    //配列の中から最大値を丸める
    const round = n =>
      (m => m * Math.round(n / m) * 2)(10 ** (`${n}`.length - 1));

    return round(maxCount);
  };

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    validationSchema: Yup.object({
      yearMonthFrom: Yup.string().required().label('年月'),
      yearMonthTo: Yup.string().required().label('年月'),
    }),
    onSubmit: values => {
      dispatch(analyzeActions.saveSearchParams(values));
    },
  });

  const { sortKey: sortColumn, sortMethod } = formik.values;

  useEffect(() => {
    dispatch(analyzeStaffInit()).then(() =>
      formik.setValues({
        ...searchParams,
      })
    );
    dispatch(analyzeGraphList(searchParams));
  }, [dispatch, searchParams]);

  return (
    <>
      <div className="commonPage--base" style={{ paddingBottom: '0', zIndex: 3 }}>
        {/* 検索条件部分 */}
        <div className="list_view--search_base">
          <div className="list_view--search_title_area">
            <img
              id="searchFormTriangle"
              src={Icons.icon.downNv}
              onClick={toggleOpenSearchForm}
            />
            <p>検索条件</p>
          </div>
          <div className="list_view--search_form_area">
            <div style={{ width: isSp ? '100%' : 'auto' }}>
              <p style={{ marginBottom: '8px' }}>年月</p>
              <div style={monthDivSty}>
                <CustomFormikInput
                  inputType="inputMonth"
                  inputName="yearMonthFrom"
                  formik={formik}
                  placeholder="YYYY/MM"
                  disabledFutureDate={moment(
                    formik?.values?.yearMonthTo,
                    'YYYY-M'
                  ).format('YYYY/M')}
                />
                <div>～</div>
                <CustomFormikInput
                  inputType="inputMonth"
                  inputName="yearMonthTo"
                  formik={formik}
                  placeholder="YYYY/MM"
                  disabledDate={moment(
                    formik?.values?.yearMonthFrom,
                    'YYYY-M'
                  ).format('YYYY/M')}
                />
              </div>
            </div>
            <div style={{ width: isSp ? '100%' : 'auto' }}>
              <p style={{ marginBottom: '8px' }}>部署</p>
              <CustomFormikInput
                inputType="select"
                inputName="deptId"
                formik={formik}
                showSearch={true}
                allowClear={false}
                mode="multiple"
                initialValue={deptList?.map(e => e.val)}
                selectBoxOptions={deptList?.map(e => e.text)}
                placeholder="部署名を入力してください"
                style={{
                  areaStyle: { width: isSp ? '100%' : '258px' },
                }}
              />
            </div>
            <div style={{ width: isSp ? '100%' : 'auto' }}>
              <p style={{ marginBottom: '8px' }}>営業担当者</p>
              <CustomFormikInput
                inputType="select"
                inputName="managerUserId"
                formik={formik}
                mode="multiple"
                showSearch={true}
                allowClear={false}
                initialValue={userList?.map(e => e.val)}
                selectBoxOptions={userList?.map(e => e.text)}
                placeholder="営業担当者を入力してください"
                style={{
                  areaStyle: { width: isSp ? '100%' : '400px' },
                }}
              />
            </div>
            <div style={{ width: isSp ? '100%' : 'auto' }}>
              <p style={{ marginBottom: '8px' }}>取引先</p>
              <CustomFormikInput
                inputType="select"
                inputName="clientId"
                key={'clientId'}
                formik={formik}
                showSearch={true}
                allowClear={true}
                initialValue={clientList?.map(e => e.val)}
                selectBoxOptions={clientList?.map(e => e.text)}
                selectedSelectBox={formik.values.clientId}
                placeholder="取引先を入力してください"
                style={{
                  areaStyle: { width: isSp ? '100%' : '300px' },
                }}
              />
            </div>
          </div>
          <div className="list_view--search_button_area">
            <Button
              text="検索"
              style={
                isSp
                  ? { ...Options.buttonStyles.search, width: '100%' }
                  : Options.buttonStyles.search
              }
              onClick={() => handlerFormikSubmit(formik)}
            />
          </div>
        </div>
      </div>

      {/* 本体部分 */}
      <div className="analyze_profit--main" style={{ zIndex: 2 }}>
        {/* グラフ部分 */}
        <ProfitGraph
          graphDataX={profitGraphX}
          graphDataY={profitGraphY}
          isSp={isSp}
          maxCountX={graphMaxCount(profitGraphX, true)}
          maxCountY={graphMaxCount(profitGraphY, false)}
        />
      </div>
      {/* リスト部分 */}
      <div className={'analyze_profit--list'} style={{ zIndex: 2 }}>
        <ListView
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'matterName'}
          primaryName={'matterName'}
          apiData={profitList}
          sortSettings={{ sortColumn, sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(analyzeActions.saveSearchParams({ count }))
          }
          onChangeOffset={offset => dispatch(analyzeActions.saveOffset(offset))}
        />
      </div>

      <Copyright
        style={{
          zIndex: 1,
          width: isSp ? '100%' : isOpenSideMenu ? 'calc(100% - 200px)' : 'calc(100% - 56px)'
      }} />

      {/* csv出力モダル */}
      <CsvExportModal
        exportType="analyzeProfitList"
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={() =>
          dispatch(analyzeActions.setCsvExportVisible(!csvExportVisible))
        }
        exportFunction={() => {}}
        searchParams={searchParams}
      />
    </>
  );
};

export default AnalyzeProfit;
