import { memo } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import BirthdaySelector from "../../../components/birthdaySelector";
import StaffRegisterInfos from "./inputInfos";
import prefectures from "../../../constants/prefectures";
import React, { useEffect } from 'react';
import { zipApi } from "../../../api/fetch";

const style = {
  areaStyle: { width: '65%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const Basic = memo(({ formik, isStaff }) => {
  const {postCode1, postCode2, gender} = formik.values;

  // 住所検索
  useEffect(() => {
    zipApi(formik, 'postCode1', 'postCode2', 'prefCode', 'cityName', 'streetName');
  }, [postCode1, postCode2]);

  return (
      <div className={'staff_register--basic_infos_area'}>
        <div className={'staff_register--birthday_input_area'}>
          <div className={'staff_register--label_area'}>
            <label>
              生年月日
              <span style={{color: '#D80245'}}>*</span>
            </label>
          </div>
          <BirthdaySelector
            formik={formik}
          />
        </div>

        <div className={'staff_register--gender'}>
          <CustomFormikInput
              {...StaffRegisterInfos.basic.inputs[0]}
              formik={formik}
              inputType={"radioButton"}
              key={'gender'}
              inputName={"gender"}
              radioButtonLabelAndChecked={[
                { label: '男性', checked: gender == 1 },
                { label: '女性', checked: gender == 2 }
              ]}
          />
        </div>

        <div className="custom_input--area" style={{ width: '100%', gap: '8px' }}>
          <CustomFormikInput
              inputType="input"
              label="郵便番号"
              labelRequired={true}
              inputName={'postCode1'}
              key={'postCode1'}
              formik={formik}
              placeholder={'123'}
              style={{
                labelStyle: {width: '232px'},
                areaStyle: { width: '366px' },
              }}
              // onKeyUp={onSearchZip}
          />
          <div style={{color: '#242424'}}>-</div>
          <CustomFormikInput
              inputType="input"
              inputName={'postCode2'}
              key={'postCode2'}
              formik={formik}
              placeholder={'4567'}
              style={{
                areaStyle: { width: '110px' },
              }}
              // onKeyUp={onSearchZip}
          />
        </div>
        <CustomFormikInput
            formik={formik}
            inputType={'select'}
            label={'都道府県'}
            labelRequired={false}
            inputName={'prefCode'}
            initialValue={prefectures.map(p=>p.prefecturesCode)}
            selectBoxOptions={prefectures.map(p=>p.label)}
            placeholder={'都道府県'}
            style={{
              areaStyle: { width: '18%' },
              labelStyle: { width: '110px' },
              inputStyle: { minWidth: '142px' }}}
            {...StaffRegisterInfos.basic.inputs[3]}
        />
        <CustomFormikInput
            formik={formik}
            inputType={'input'}
            label={'市区町村'}
            labelRequired={false}
            inputName={'cityName'}
            placeholder={'市区町村'}
            style={style}
            {...StaffRegisterInfos.basic.inputs[4]}
        />
        <CustomFormikInput
            formik={formik}
            inputType={'input'}
            label={'丁目番地'}
            labelRequired={false}
            inputName={'streetName'}
            initialValue={''}
            placeholder={'丁目番地'}
            style={style}
            {...StaffRegisterInfos.basic.inputs[5]}
        />
        <CustomFormikInput
            formik={formik}
            inputType={'input'}
            label={'それ以降'}
            labelRequired={false}
            inputName={'buildingName'}
            initialValue={''}
            placeholder={'マンション名・部屋番号等'}
            style={style}
            {...StaffRegisterInfos.basic.inputs[6]}
        />
        <div
            className={'tel_area_for_CFI'}
            style={{ width: '600px', }}
        >
          <CustomFormikInput
              inputType="input"
              label="TEL(スマートフォン)"
              labelRequired={true}
              inputName={'tel1'}
              key={'tel1'}
              formik={formik}
              placeholder={'000'}
              unit={'-'}
              style={{
                  labelStyle: {width: '232px'},
                inputStyle: { width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
          <CustomFormikInput
              inputType="input"
              inputName={'tel2'}
              key={'tel2'}
              formik={formik}
              placeholder={'0000'}
              unit={'-'}
              style={{
                inputStyle: { width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
          <CustomFormikInput
              inputType="input"
              inputName={'tel3'}
              key={'tel3'}
              formik={formik}
              placeholder={'0000'}
              style={{
                inputStyle: { width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
        </div>
        <div
            className={'tel_area_for_CFI'}
            style={{ width: '600px', }}
        >
          <CustomFormikInput
              inputType="input"
              label="TEL(自宅)"
              inputName={'fax1'}
              key={'fax1'}
              formik={formik}
              placeholder={'03'}
              unit={'-'}
              style={{
                  labelStyle: {width: '232px'},
                inputStyle: { width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
          <CustomFormikInput
              inputType="input"
              inputName={'fax2'}
              key={'fax2'}
              formik={formik}
              placeholder={'0000'}
              unit={'-'}
              style={{
                inputStyle: { width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
          <CustomFormikInput
              inputType="input"
              inputName={'fax3'}
              key={'fax3'}
              formik={formik}
              placeholder={'0000'}
              style={{
                inputStyle: { width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)' },
              }}
          />
        </div>
        <CustomFormikInput
            formik={formik}
            inputType={'input'}
            placeholder={'LINE IDを入力してください'}
            {...StaffRegisterInfos.basic.inputs[9]}
        />
        <CustomFormikInput
          formik={formik}
          {...StaffRegisterInfos.basic.inputs[10]}
        />
        <CustomFormikInput
          formik={formik}
          inputName={'dependents'}
          {...StaffRegisterInfos.basic.inputs[11]}
        />
      </div>
  );
});
export default Basic;
