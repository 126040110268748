import './style.scss';
import React, { createRef, useEffect, useRef, useState } from 'react';
import Button from '../button';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Icons from '../../constants/icons';
import ChangePassModal from '../modal/changePassModal';
import { Link } from 'react-router-dom';
import Options from '../../constants/options';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import { globalActions } from '../../redux/slice/global';
import { rootReducer, store } from '../../redux/store';
import { setAccount } from '../../redux/slice/account';
import SpConfirmModal from "../modal/spConfirmModal";
import {handlerFormikSubmit} from "../../utils/fnUtil";
import ConfirmModal from "../modal/confirmModal";

const Header = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const loginType = useSelector(state => state.loginType.type);
  const menus = Options.menus[loginType];
  const location = useLocation();
  const query = useLocation().search;
  const currentPath = query
    ? `${location.pathname}${query}`
    : location.pathname;
  const accountState = useSelector(state => state.account);
  const accountInfo = {
    image:
      loginType === 'backoffice'
        ? accountState.userImage
        : accountState.staffImage,
    corporate: accountState.corporateName,
    name: accountState.fullName,
  };

  const accountAction = createRef();
  const viewAccountAction = () => {
    if (loginType === 'backoffice') accountAction.current.classList.toggle('hidden');
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  let spMenuOverlay;
  let spMenuBase;
  let lineFirst;
  let lineSecond;
  let lineThird;

  const toggleMenu = () => {
    spMenuOverlay.classList.toggle('header--sp_side_menu_overlay_open');
    spMenuBase.classList.toggle('header--sp_side_menu_base_open');

    lineFirst.classList.toggle('header--sp_hamburger_line_first_open');
    lineSecond.classList.toggle('header--sp_hamburger_line_second_open');
    lineThird.classList.toggle('header--sp_hamburger_line_third_open');
  };

  const toggleSubMenu = e => {
    const targetClass = e.target.classList;
    let lis;

    if (
      targetClass.contains('header--sp_side_menu_link') ||
      targetClass.contains('header--sp_side_menu_link_active')
    ) {
      lis = e.target.parentNode.querySelectorAll('li');
    } else if (targetClass.contains('header--sp_side_menu_link_img_area')) {
      lis = e.target.parentNode.parentNode.querySelectorAll('li');
    } else if (e.target.tagName === 'IMG') {
      lis = e.target.parentNode.parentNode.parentNode.querySelectorAll('li');
    } else if (e.target.tagName === 'SPAN') {
      lis = e.target.parentNode.parentNode.querySelectorAll('li');
    } else if (e.target.tagName === 'UL') {
      lis = e.target.querySelectorAll('li');
    }

    const ul = lis[0].parentNode;

    lis.forEach(elm => {
      if (elm.style.height) {
        elm.style.height = '0';
        elm.style.opacity = '0';
      } else {
        elm.style.height = '56px';
        elm.style.opacity = '1';
      }
    });
  };

  const toggleSubMenuSp = mainText => {
    const ul = document.getElementById(`spSideMenu_${mainText}`);

    ul.classList.toggle('show');
    ul.childNodes.forEach(elm => {
      elm.childNodes[0].childNodes[0].classList.toggle('show');
    });
  };

  useEffect(() => {
    const spAccount = document.querySelector(
      '.header--sp_side_menu_account_area'
    );
    const spMenu = document.querySelector('.header--sp_side_menu_menu_area');

    spMenuOverlay = document.querySelector('.header--sp_side_menu_overlay');
    spMenuBase = document.querySelector('.header--sp_side_menu_base');
    lineFirst = document.querySelector('.header--sp_hamburger_line_first');
    lineSecond = document.querySelector('.header--sp_hamburger_line_second');
    lineThird = document.querySelector('.header--sp_hamburger_line_third');

    // spMenu.style.top = spAccount.clientHeight + 60 + 'px';
    // spMenu.style.height = `calc(100vh - 60px - ${spAccount.clientHeight}px`;
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutAction = async () => {
    // setLogoutModalVisible(false);
    await fetchRequest(apiUrl.account.logout, {}, 'POST');
    navigate('/login');
  };

  return (
    <>
      <div className={'header--base'}>
        <div className={'header--logo_area'}>
          <Link to={'/'}>
            {loginType === 'backoffice' ? (
              <img src={Icons.logo.yoko} alt={'PROMOTION TECHNOLOGY'} />
            ) : (
              <img
                src={Icons.logo.forStaff}
                alt={'PROMOTION TECHNOLOGY'}
                style={{ width: '274px' }}
              />
            )}
          </Link>
        </div>

        <div className={'header--account_area'}>
          <img
            src={accountInfo.image}
            alt={'社員画像'}
            onClick={viewAccountAction}
          />
          <p>
            <span>{accountInfo.corporate}</span>
            <span>{accountInfo.name}</span>
          </p>

          <div
            className={'header--account_action_box hidden'}
            ref={accountAction}
          >
            <span
              onClick={() => toggleModal()}
              className={'header--account_action'}
            >
              パスワード変更
            </span>
            <span
              onClick={() => setLogoutModalVisible(true)}
              className={'header--account_action'}
            >
              ログアウト
            </span>
          </div>
        </div>

        <div className={'header--sp_hamburger'} onClick={toggleMenu}>
          <span className={'header--sp_hamburger_line_first'}></span>
          <span className={'header--sp_hamburger_line_second'}></span>
          <span className={'header--sp_hamburger_line_third'}></span>
        </div>
      </div>

      <div className={'header--sp_side_menu'}>
        <div className={'header--sp_side_menu_overlay'}></div>
        <div
          className={'header--sp_side_menu_base'}
          style={
            loginType === 'staff' ? { width: '280px' } : { width: '200px' }
          }
        >
          {loginType === 'backoffice' && (
            <div className={'header--sp_side_menu_account_area'}>
              <p className={'header--sp_side_menu_account_image_area'}>
                <img src={accountInfo.image} alt={'社員画像'} />
              </p>

              <div className={'header--sp_side_menu_account_info_area'}>
                <span>{accountInfo.corporate}</span>
                <p>{accountInfo.name} 様</p>
              </div>
            </div>
          )}
          <div
            className={'header--sp_side_menu_menu_area'}
            style={
              loginType === 'staff'
                ? {
                    position: 'fixed',
                    top: '60px',
                    padding: '35px 0 50px 0',
                    height: '100%',
                  }
                : { width: '200px' }
            }
          >
            {loginType === 'backoffice' ? (
              <>
                {Object.values(menus).map(main => {
                  return main.path === '/' ? (
                    <Link
                      to={main.path}
                      key={`spSideMenu_${main.mainText}`}
                      onClick={toggleMenu}
                    >
                      <div
                        className={
                          currentPath === main.path
                            ? 'header--sp_side_menu_link_active'
                            : 'header--sp_side_menu_link'
                        }
                        style={
                          loginType === 'staff'
                            ? {}
                            : { padding: '0', borderTop: '1px solid #7B7B7B' }
                        }
                      >
                        <div className={'header--sp_side_menu_link_img_area'}>
                          <img src={main.icon} alt={main.mainText} />
                        </div>
                        <span>{main.mainText}</span>
                      </div>
                    </Link>
                  ) : (
                    <ul
                      className={'header--sp_side_menu_link_ul'}
                      key={`spSideMenu_${main.mainText}`}
                      onClick={() => toggleSubMenuSp(main.mainText)}
                      id={`spSideMenu_${main.mainText}`}
                    >
                      <div
                        className={
                          main.sub.some(
                            o => o.path === currentPath && !o.setState
                          )
                            ? 'header--sp_side_menu_link_active'
                            : 'header--sp_side_menu_link'
                        }
                        key={`spSideMenu_${main.mainText}`}
                        style={loginType === 'staff' ? {} : { padding: '0' }}
                      >
                        <div className={'header--sp_side_menu_link_img_area'}>
                          <img src={main.icon} alt={main.mainText} />
                        </div>
                        <span>{main.mainText}</span>
                      </div>

                      {main.sub &&
                        main.sub.map(sub => {
                          return (
                            <li key={sub.text} onClick={toggleMenu}>
                              <Link
                                to={sub.path}
                                onClick={() => sub?.setState(true)}
                              >
                                <div
                                  className={
                                    !sub?.setState && currentPath === sub.path
                                      ? 'header--sp_side_menu_link_sub_active'
                                      : 'header--sp_side_menu_link_sub'
                                  }
                                >
                                  <span>{sub.text}</span>
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  );
                })}

                <div
                  className={'header--sp_side_menu_link'}
                  style={loginType === 'staff' ? {} : { paddingLeft: '12px' }}
                  onClick={() => {
                    toggleMenu();
                    toggleModal();
                  }}
                >
                  <span>パスワード変更</span>
                </div>

                <div
                  className={'header--sp_side_menu_link'}
                  style={loginType === 'staff' ? {} : { paddingLeft: '12px' }}
                  onClick={()=>setLogoutModalVisible(true)}
                >
                  <span>ログアウト</span>
                </div>

                <a
                  style={{
                    display: 'block',
                    width: 'fit-content',
                    fontSize: '14px',
                    color: 'white',
                    boxSizing: 'border-box',
                    padding: '0',
                    margin: '60px 0 0 12px',
                    borderBottom: '1px solid white',
                  }}
                  href={apiUrl.operationManualBo}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  操作マニュアル
                </a>

                <a
                  style={{
                    display: 'block',
                    width: 'fit-content',
                    fontSize: '14px',
                    color: 'white',
                    boxSizing: 'border-box',
                    padding: '0',
                    margin: '20px 0 0 12px',
                    borderBottom: '1px solid white',
                  }}
                  href={apiUrl.privacyPolicy}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  プライバシーポリシー
                </a>
              </>
            ) : (
              <>
                {Object.values(menus).map(main => {
                  return (
                    <Link
                      to={main.path}
                      key={`spSideMenu_${main.mainText}`}
                      onClick={
                        main.mainText === 'パスワード変更'
                          ? () => {
                              toggleMenu();
                              toggleModal();
                            }
                          : main.mainText === 'ログアウト'
                          ? ()=>setLogoutModalVisible(true)
                          : toggleMenu
                      }
                    >
                      <div
                        className={
                          currentPath === main.path
                            ? 'header--sp_side_menu_link_active'
                            : 'header--sp_side_menu_link'
                        }
                        style={
                          !main.icon
                            ? { paddingLeft: '32.2px' }
                            : { paddingLeft: '20.2px' }
                        }
                      >
                        {main.icon && (
                          <div className={'header--sp_side_menu_link_img_area'}>
                            <img src={main.icon} alt={main.mainText} />
                          </div>
                        )}
                        <span>{main.mainText}</span>
                      </div>
                    </Link>
                  );
                })}

                <a
                  style={{
                    display: 'block',
                    width: 'fit-content',
                    fontSize: '12px',
                    color: 'white',
                    boxSizing: 'border-box',
                    padding: '0',
                    margin: '40px 0 0 32.2px',
                    borderBottom: '1px solid white',
                  }}
                  href={apiUrl.operationManualStaff}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  操作マニュアル
                </a>

                <a
                  style={{
                    display: 'block',
                    width: 'fit-content',
                    fontSize: '12px',
                    color: 'white',
                    boxSizing: 'border-box',
                    padding: '0',
                    margin: '20px 0 0 32.2px',
                    borderBottom: '1px solid white',
                  }}
                  href={apiUrl.privacyPolicy}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  プライバシーポリシー
                </a>
              </>
            )}
          </div>
        </div>
      </div>

      <ChangePassModal
        modalVisible={modalVisible}
        toggleVisible={toggleModal}
        backButton={true}
      />
      <ConfirmModal
          modalVisible={logoutModalVisible}
          title={'ログアウトしますか？'}
          onOkMessage={'ログアウト'}
          onCancel={() => setLogoutModalVisible(false)}
          onOk={() => logoutAction()}
      />
    </>
  );
};

export default Header;
