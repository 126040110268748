const domain = 'https://promotion-technology.com';
// const domain = 'http://localhost:8080';


const baseUrl = `${domain}/gw`;

// 操作マニュアル【管理画面操作マニュアル】
const operationManualUrlBo =
  'https://promotion-technology-prd-static.s3.ap-northeast-1.amazonaws.com/promotion_technology%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf';
// 操作マニュアル【スタッフ用操作マニュアル】
const operationManualUrlStaff =
  'https://promotion-technology-prd-static.s3.ap-northeast-1.amazonaws.com/promotion_technology%E3%82%B9%E3%82%BF%E3%83%83%E3%83%95%E7%94%A8%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf';
// プライバシーポリシー
const privacyPolicyUrl = 'https://promo-tech.jp/main/privacy_policy/';
//CSV取込フォーマットダウンロード
const fileDownloadUrl =
  'https://goodwave-dev-common-document.s3.ap-northeast-1.amazonaws.com';

export const apiUrl = {
  //外部郵便番号検索API
  ZIP_SEARCH_URL: 'https://zipcloud.ibsnet.co.jp/api/search',

  //金融機関取得API
  bank_code_jp: 'https://apis.bankcode-jp.com/v2/banks',

  //座標取得
  geocoding: 'https://api.openweathermap.org/geo/1.0/zip',

  //国税庁法人番号検索
  houjinBangou: 'https://www.houjin-bangou.nta.go.jp/',

  //ジョルダン乗換案内
  norikaeApi: 'https://www.jorudan.co.jp/norikae/cgi/nori.cgi',

  //法人印鑑image
  corporateStampImage: `${domain}/images/common/icon_corporate-stamp_image_r.png`,

  operationManualBo: `${operationManualUrlBo}`,
  operationManualStaff: `${operationManualUrlStaff}`,

  privacyPolicy: `${privacyPolicyUrl}`,

  fetchUrl: {
    getApiKeys: `${baseUrl}/other/external_api`,
    checkSession: `${baseUrl}/other/session/check`,
  },

  account: {
    login: `${baseUrl}/account/login`,
    logout: `${baseUrl}/account/logout`,
    forgotUrl: `${baseUrl}/account/forgot/password/url`,
    forgotUrlValid: `${baseUrl}/account/forgot/password/url/valid`,
    forgotUpdate: `${baseUrl}/account/forgot/password/update`,
    change: `${baseUrl}/account/password/update`,
  },
  home: {
    matter: `${baseUrl}/home/matter_assign_status`,
    assign: `${baseUrl}/home/assign_status`,
    profit: `${baseUrl}/home/profit`,
    composition: `${baseUrl}/home/composition_ratio`,
    implemented: `${baseUrl}/home/implemented_status`,
  },
  staff: {
    list: `${baseUrl}/staff_setting/list`,
    insert: `${baseUrl}/staff_setting/insert`,
    detail: `${baseUrl}/staff_setting/detail`,
    update: `${baseUrl}/staff_setting/update`,
    updateStaff: `${baseUrl}/staff_setting/staff/update`,
    delete: `${baseUrl}/staff_setting/delete`,
    downloadCsv: `${baseUrl}/staff_setting/list/csv-export`,
  },
  invoice: {
    list: `${baseUrl}/invoice/list`,
    detail: `${baseUrl}/invoice/detail`,
    insert: `${baseUrl}/invoice/insert`,
    update: `${baseUrl}/invoice/update`,
    delete: `${baseUrl}/invoice/delete`,
    request: `${baseUrl}/invoice/request`,
    lock: `${baseUrl}/invoice/lock`,
    documentDetail: `${baseUrl}/invoice/document/detail`,
    documentUpdate: `${baseUrl}/invoice/document/update`,
    documentPdf: `${baseUrl}/invoice/document/pdf`,
    documentEditPdf: `${baseUrl}/invoice/document/edit/pdf`,
  },
  matter: {
    list: `${baseUrl}/matter/list`,
    quoteList: `${baseUrl}/matter/quote/list`,
    assignStateList: `${baseUrl}/matter/staff/assign/status/list`,
    detail: `${baseUrl}/matter/detail`,
    reportDetail: `${baseUrl}/matter/report/detail`,
    reportUpdate: `${baseUrl}/matter/report/update`,
    reportPdf: `${baseUrl}/matter/report/pdf`,
    create: `${baseUrl}/matter/create`,
    update: `${baseUrl}/matter/update`,
    delete: `${baseUrl}/matter/delete`,
    updateLock: `${baseUrl}/matter/lock/update`,
    updateReportSubmit: `${baseUrl}/matter/report/submit/update`,
    classList: `${baseUrl}/system_setting/class/list`,
    scheduleList: `${baseUrl}/matter/schedule/list`,
    downloadCsv: `${baseUrl}/matter/list/csv-export`,
    assignListCsv: `${baseUrl}/matter/staff/assign/list/csv-export`
  },
  matterDate: {
    list: `${baseUrl}/matter/date/list`,
    assignDetail: `${baseUrl}/matter/date/assign/detail`,
    assignStaffDetail: `${baseUrl}/matter/date/assign/staff/detail`,
    assignStaffUpdate: `${baseUrl}/matter/date/assign/staff/update`,
    assignUpdate: `${baseUrl}/matter/date/assign/update`,
    assignConfirm: `${baseUrl}/matter/date/assign/confirm`,
    sendAssginMail: `${baseUrl}/matter/date/assign/mail`,
    recommendStaffList: `${baseUrl}/matter/recommend/staff/list`,
    appliedList: `${baseUrl}/matter/date/applicant/list`,
    apply: `${baseUrl}/matter/date/applicant/apply`,
    downloadCsv: `${baseUrl}/matter/date/list/csv-export`,
    appliedDownloadCsv: `${baseUrl}/matter/date/applicant/list/csv-export`,
    copy: `${baseUrl}/matter/copy`,
  },
  matterStaffAssign: {
    list: `${baseUrl}/matter/staff/assign/list`,
  },
  staffMatterAssign: {
    list: `${baseUrl}/staff/assign/list`,
  },
  staffRecruitMatter: {
    list: `${baseUrl}/matter/recruitment/list`,
    detail: `${baseUrl}/matter/recruitment/detail`,
    create: `${baseUrl}/matter/recruitment/create`,
    update: `${baseUrl}/matter/recruitment/update`,
    delete: `${baseUrl}/matter/recruitment/delete`,
  },
  staffMatter: {
    list: `${baseUrl}/staff/matter/list`,
  },
  client: {
    list: `${baseUrl}/client_setting/client/list`,
    detail: `${baseUrl}/client_setting/client/detail`,
    insert: `${baseUrl}/client_setting/client/insert`,
    update: `${baseUrl}/client_setting/client/update`,
    delete: `${baseUrl}/client_setting/client/delete`,
    downloadCsv: `${baseUrl}/client_setting/client/list/csv-export`,
  },
  venue: {
    list: `${baseUrl}/client_setting/venue/list`,
    initialInfo: `${baseUrl}/client_setting/venue/initialInfo`,
    detail: `${baseUrl}/client_setting/venue/detail`,
    insert: `${baseUrl}/client_setting/venue/insert`,
    update: `${baseUrl}/client_setting/venue/update`,
    delete: `${baseUrl}/client_setting/venue/delete`,
    downloadCsv: `${baseUrl}/client_setting/venue/list/csv-export`,
  },
  user: {
    list: `${baseUrl}/system_setting/user/list`,
    detail: `${baseUrl}/system_setting/user/detail`,
    reference: `${baseUrl}/system_setting/user/reference`,
    insert: `${baseUrl}/system_setting/user/insert`,
    update: `${baseUrl}/system_setting/user/update`,
    delete: `${baseUrl}/system_setting/user/delete`,
    downloadCsv: `${baseUrl}/system_setting/user/list/csv-export`,
  },
  naming: {
    list: `${baseUrl}/system_setting/naming/list`,
    next_id: `${baseUrl}/system_setting/naming/next_id`,
    insert: `${baseUrl}/system_setting/naming/insert`,
    update: `${baseUrl}/system_setting/naming/update`,
    delete: `${baseUrl}/system_setting/naming/delete`,
    downloadCsv: `${baseUrl}/system_setting/naming/list/csv-export`,
  },
  quote: {
    list: `${baseUrl}/quote/list`,
    insert: `${baseUrl}/quote/insert`,
    detail: `${baseUrl}/quote/detail`,
    update: `${baseUrl}/quote/update`,
    requestList: `${baseUrl}/quote/request/list`,
    delete: `${baseUrl}/quote/delete`,
    documentDetail: `${baseUrl}/quote/document/detail`,
    documentInsert: `${baseUrl}/quote/document/insert`,
    documentUpdate: `${baseUrl}/quote/document/update`,
    documentStampRequest: `${baseUrl}/quote/document/stamp_request`,
    documentStatusUpdate: `${baseUrl}/quote/document/status/update`,
    documentMail: `${baseUrl}/quote/document/mail`,
    documentPdf: `${baseUrl}/quote/document/pdf`,
    documentEditPdf: `${baseUrl}/quote/document/edit/pdf`,
    template: `${baseUrl}/quote/template/list`,
    templateDetail: `${baseUrl}/quote/template/detail`,
    lock: `${baseUrl}/quote/lock`,
    numUpdate: `${baseUrl}/quote/number/manage`,
    count: `${baseUrl}/quote/request/count`,
    downloadCsv: `${baseUrl}/quote/list/csv-export`,
    approvalDetail: `${baseUrl}/quote/approval_flow/detail`,
  },
  corporate: {
    list: `${baseUrl}/system_setting/corporate/list`,
    insert: `${baseUrl}/system_setting/corporate/insert`,
    detail: `${baseUrl}/system_setting/corporate/detail`,
    update: `${baseUrl}/system_setting/corporate/update`,
    delete: `${baseUrl}/system_setting/corporate/delete`,
  },
  payment: {
    list: `${baseUrl}/system_setting/payment/list`,
    insert: `${baseUrl}/system_setting/payment/insert`,
    update: `${baseUrl}/system_setting/payment/update`,
    multiUpdate: `${baseUrl}/system_setting/payment/priority`,
    delete: `${baseUrl}/system_setting/payment/delete`,
  },
  other: {
    imageUpload: `${baseUrl}/other/image/upload`,
    checkCsv: `${baseUrl}/other/csv/check`,
    uploadCsv: `${baseUrl}/other/csv/upload`,
    // downloadCsv: `${baseUrl}/other/csv/download`,
    favorite: `${baseUrl}/other/csv/favorite_item`,
    favoriteUpdate: `${baseUrl}/other/csv/favorite_item/update`,
  },
  partner: {
    list: `${baseUrl}/system_setting/partner/list`,
    detail: `${baseUrl}/system_setting/partner/detail`,
    insert: `${baseUrl}/system_setting/partner/insert`,
    update: `${baseUrl}/system_setting/partner/update`,
    delete: `${baseUrl}/system_setting/partner/delete`,
    downloadCsv: `${baseUrl}/system_setting/partner/list/csv-export`,
  },
  approval: {
    list: `${baseUrl}/quote/approval_flow/list`,
    detail: `${baseUrl}/quote/approval_flow/detail`,
    insert: `${baseUrl}/quote/approval_flow/insert`,
    update: `${baseUrl}/quote/approval_flow/update`,
    delete: `${baseUrl}/quote/approval_flow/delete`,
  },
  incomeTax: {
    list: `${baseUrl}/system_setting/income_tax/list`,
    insert: `${baseUrl}/system_setting/income_tax/insert`,
    headerUpdate: `${baseUrl}/system_setting/income_tax/header/update`,
    update: `${baseUrl}/system_setting/income_tax/update`,
    delete: `${baseUrl}/system_setting/income_tax/delete`,
  },
  salesTax: {
    list: `${baseUrl}/system_setting/salestax/list`,
  },
  template: {
    list: `${baseUrl}/quote/template/list`,
    sort: `${baseUrl}/quote/template/list/sort`,
    update: `${baseUrl}/quote/template/update`,
    insert: `${baseUrl}/quote/template/insert`,
    delete: `${baseUrl}/quote/template/delete`,
  },
  largeClass: {
    list: `${baseUrl}/system_setting/large_class/list`,
  },
  setting: {
    list: `${baseUrl}/matter/schedule/list`,
    update: `${baseUrl}/matter/schedule/update`,
    delete: `${baseUrl}/matter/schedule/delete`,
  },
  staffPlan: {
    list: `${baseUrl}/staff_schedule/list`,
    detail: `${baseUrl}/staff_schedule/detail`,
    update: `${baseUrl}/staff_schedule/update`,
    request: `${baseUrl}/staff_schedule/request`,
    remind: `${baseUrl}/staff_schedule/remind`,
    deadline: `${baseUrl}/staff_schedule/deadline`,
    downloadCsv: `${baseUrl}/staff_schedule/list/csv-export`,
    detailDownloadCsv: `${baseUrl}/staff_schedule/detail/csv-export`,
  },
  staffPay: {
    list: `${baseUrl}/staff_pay/list`,
    detail: `${baseUrl}/staff_pay/detail`,
    update: `${baseUrl}/staff_pay/update`,
    statusUpdate: `${baseUrl}/staff_pay/status/update`,
    yearList: `${baseUrl}/staff_pay/year/list`,
    closing: `${baseUrl}/staff_pay/closing`,
    downloadCsv: `${baseUrl}/staff_pay/list/csv-export`,
    zenginDownloadCsv: `${baseUrl}/staff_pay/list/text-export`,
    yearDownloadCsv: `${baseUrl}/staff_pay/year/list/csv-export`,
  },
  analyse: {
    salesList: `${baseUrl}/analyse/sales/list`,
    salesGraph: `${baseUrl}/analyse/sales/graph`,
    staff: `${baseUrl}/analyse/staff/graph`,
    downloadCsv: `${baseUrl}/analyse/sales/list/csv-export`,
  },
  staffSchedule: {
    attend: `${baseUrl}/staff_schedule/attend`,
  },
  survey: {
    list: `${baseUrl}/survey/list`,
    insert: `${baseUrl}/survey/insert`,
    update: `${baseUrl}/survey/update`,
    detail: `${baseUrl}/survey/detail`,
    targetCount: `${baseUrl}/survey/target_count`,
    result: `${baseUrl}/survey/result`,
    delete: `${baseUrl}/survey/delete`,
    downloadCsv: `${baseUrl}/survey/list/csv-export`,
    staffList: `${baseUrl}/survey/staff/list`,
    staffAnswerInsert: `${baseUrl}/survey/staff/insert`,
    surveyOpen: `${baseUrl}/survey/open`,
    surveyStaffAnswerResult: `${baseUrl}/survey/staff/result`,
    surveyAnswerResult: `${baseUrl}/survey/result`,
    downloadCsvDetail: `${baseUrl}/survey/list/csv-export/detail`,
  },

  templateCsv: `${fileDownloadUrl}`,
};
