import classNames from 'classnames';
import './style.scss';
import { useEffect } from 'react';
import { checkIsSp } from '../../utils/fnUtil';

/* 汎用ボタン */

const Button = ({
  width,
  theme = 'black',
  type = 'button',
  fontSize,
  icon,
  text,
  style = {},
  onClick,
  disabled,
  className,
  iconStyle,
  surveyDisabled,
  ...restProps
}) => {
  const btnClassName = classNames('gw-btn', className, {
    [`gw-btn-${width}`]: width,
    [`gw-btn-${theme}`]: theme,
    [`gw-btn-${fontSize}`]: fontSize,
    [`gw-btn-disabled`]: disabled,
    [`gw-btn-survey_disabled`]: surveyDisabled,
  });
  const isSp = checkIsSp();
  return (
    <button
      disabled={disabled}
      contentEditable={false}
      className={btnClassName}
      type={type}
      style={{
        ...style,
        whiteSpace: 'normal',
        paddingTop: '0',
        paddingBottom: '0',
      }}
      onClick={onClick}
      {...restProps}
    >
      {icon ? (
        <img
          src={icon}
          style={
            iconStyle
              ? {
                  ...iconStyle,
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  marginRight: text ? '5px' : '',
                }
              : {
                  width: '24px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  marginRight: text ? '5px' : '',
                }
          }
        />
      ) : (
        <></>
      )}
      <span
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          pointerEvents: 'none',
          fontSize: isSp ? '12px' : '14px',
          lineHeight:
            style && style.height ? style.height : isSp ? '20px' : '42px',
          height: style && style.height ? style.height : isSp ? '20px' : '42px',
          fontWeight: '500',
        }}
      >
        {text && text !== null && text !== undefined
          ? String(text).split('\n')[0]
          : ''}
        {text && text !== null && text !== undefined
          ? String(text).split('\n')[1]
          : ''}
      </span>
    </button>
  );
};

export default Button;
