import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  venueInsert,
  venueUpdate,
  uploadImage,
  venueInitialInfoFetch,
  venueDetailFetch,
  venueInfoFetch,
  venueCheckListFetch,
} from '../../../redux/slice/venue';
import { globalActions } from '../../../redux/slice/global';
import { useFormik } from 'formik';
import RegisterBase from '../../../components/registerBase';
import Button from '../../../components/button';
import AddLineButton from '../../../components/addLineButton';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Icons from '../../../constants/icons';
import Options from '../../../constants/options';
import prefectures from '../../../constants/prefectures';
import {
  deleteArrayItem,
  handlerFormikFieldChange,
  handlerFormikSubmit,
} from '../../../utils/fnUtil';
import { fetchApi, fetchRequest, zipApi } from '../../../api/fetch';
import Yup from '../../../utils/yupUtil';
import './style.scss';
import moment from 'moment/moment';
import { checkIsSp } from '../../../utils/fnUtil';
import { apiUrl } from '../../../api/apiUrl';
import DeleteIcon from '../../../components/deleteIcon';

const isSp = checkIsSp();

const tabStyle = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'column',
  gap: '20px',
  marginTop: '26px',
  marginLeft: isSp ? '0px' : '70px',
  minWidth: isSp ? '100%' : '500px',
};

const style = {
  areaStyle: { width: isSp ? '100%' : '70%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const urlStyle = {
  areaStyle: { width: isSp ? '100%' : '90%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const prefStyle = {
  areaStyle: {
    width: isSp ? '100%' : '18%',
    minWidth: isSp ? '100%' : '244px',
  },
  labelStyle: { width: '110px' },
};

const remarkStyle = {
  areaStyle: { width: isSp ? '100%' : '80%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const VenueRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isEdit = location.pathname.slice(5).includes('edit');
  const { namingListPriorityAsc, namingCategoryList, editParams, venueList } =
    useSelector(state => state.venue);
  const { userId, weatherApiKey } = useSelector(state => state.account);

  const tabErrorGroup = {
    common: [
      'postCode1',
      'postCode2',
      'tel1',
      'tel2',
      'tel3',
      'fax1',
      'fax2',
      'fax3',
    ],
  };

  const getCoordinate = async (zip) => {
    const requestUri = `${apiUrl.geocoding}?zip=${zip},jp&appid=${weatherApiKey}`
    const json = await fetch(requestUri).then(res => res.json());

    return (json);
  }

  const formik = useFormik({
    initialValues: {
      ...editParams,
      nearestStation: [''],
      suggestions: [],
      venueNameInputType: '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      venueId: Yup.string()
        .required()
        .nullable()
        .nonZeroInteger('開催地ID')
        .label('開催地ID'),
      venueName: Yup.string()
        .required()
        .nullable()
        .test('listCheck', '開催地名：重複しています。', (_, context) =>
          !venueSuggestList?.some(
            u => u?.venueName === context.parent.venueName
          )
            ? true
            : editVenueName === context.parent.venueName
        )
        .label('開催地名'),
      venueNameKana: Yup.string()
        .required()
        .nullable()
        .kana('開催地名(カナ)')
        .label('開催地名(カナ)'),
      postCode1: Yup.string().nullable().integer('[基本情報]郵便番号'),
      postCode2: Yup.string().nullable().integer('[基本情報]郵便番号'),
      tel1: Yup.string().nullable().integer('[基本情報]TEL'),
      tel2: Yup.string().nullable().integer('[基本情報]TEL'),
      tel3: Yup.string().nullable().integer('[基本情報]TEL'),
      fax1: Yup.string().nullable().integer('[基本情報]FAX'),
      fax2: Yup.string().nullable().integer('[基本情報]FAX'),
      fax3: Yup.string().nullable().integer('[基本情報]FAX'),
    }),
    onSubmit: async values => {
      let val = {
        ...values,
        venueId: Number(values.venueId),
        postCode: {
          number1: values.postCode1,
          number2: values.postCode2,
        },
        fax: {
          number1: values.fax1,
          number2: values.fax2,
          number3: values.fax3,
        },
        tel: {
          number1: values.tel1,
          number2: values.tel2,
          number3: values.tel3,
        },
      };

      const geoApiData = await getCoordinate(`${values.postCode1}-${values.postCode2}`);

      if (geoApiData?.lat && geoApiData.lon) {
        val.venueLatitude = geoApiData?.lat;
        val.venueLongitude = geoApiData?.lon;
      }

      Array.from({ length: 5 }).map((_, idx) => val[`nearestStation${idx + 1}`] = values.nearestStation[idx]);
      isEdit
        ? dispatch(venueUpdate(val))
            .unwrap()
            .then(() => {
              dispatch(globalActions.showSingleModal('更新しました'));
              navigate(-1);
            })
        : dispatch(venueInsert(val))
            .unwrap()
            .then(() => {
              dispatch(globalActions.showSingleModal('登録しました'));
              navigate(-1);
            });
    },
  });
  const {
    venueId,
    venueFlag,
    venueImage,
    venueName,
    venueNameKana,
    preliminary1,
    preliminary2,
    preliminary3,
    preliminary4,
    preliminary5,
    nearestStation,
    suggestions,
    venueSuggestList,
    editVenueName,
  } = formik.values;

  const handleFileChange = e => {
    let fileData = e.target.files[0];
    if (fileData?.size > 1024 * 1024) {
      alert('画像は1MB以下でアップロードしてください。');
    } else {
      const reader = new FileReader();
      reader.onload = async event => {
        const imageUri = event.currentTarget.result;
        dispatch(
          uploadImage({
            image: imageUri,
            contentFlag: 3,
            userId: userId,
          })
        ).then(
          ({ payload }) =>
            payload?.code === '0' &&
            handlerFormikFieldChange(formik, 'venueImage', payload.imageUrl)
        );
      };
      reader.readAsDataURL(fileData);
    }
  };

  const Common = () => (
    <div style={tabStyle}>
      <div className={'venue_register--post_code_area'}>
        <CustomFormikInput
          label="郵便番号"
          inputName="postCode1"
          formik={formik}
          placeholder="123"
          unit="-"
          style={{
            areaStyle: { width: isSp ? 'calc(50% + 16px)' : 'auto' },
            labelStyle: isSp ? {} : { width: '110px' },
            inputStyle: { width: '110px' },
            unitStyle: isSp ? { width: '6px' } : {},
          }}
        />
        <CustomFormikInput
          inputName="postCode2"
          formik={formik}
          placeholder="4567"
          style={{
            areaStyle: { width: isSp ? '50%' : '110px' },
          }}
        />
      </div>

      <CustomFormikInput
        formik={formik}
        inputType="select"
        label="都道府県"
        inputName="prefCode"
        initialValue={prefectures.map(p => p.prefecturesCode)}
        selectBoxOptions={prefectures.map(p => p.label)}
        placeholder="都道府県"
        style={prefStyle}
      />
      <CustomFormikInput
        formik={formik}
        label="市区町村"
        inputName="cityName"
        placeholder="市区町村を入力してください"
        style={style}
      />
      <CustomFormikInput
        formik={formik}
        label="丁目番地"
        inputName="streetName"
        placeholder="丁目番地を入力してください"
        style={style}
      />
      <CustomFormikInput
        formik={formik}
        label="建物名"
        inputName="buildingName"
        placeholder="建物名を入力してください"
        style={style}
      />
      <div
        className={'tel_area_for_CFI'}
        style={{ width: isSp ? '100%' : '600px' }}
      >
        <CustomFormikInput
          label="TEL"
          inputName="tel1"
          formik={formik}
          placeholder="03"
          unit="-"
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                }
              : {},
            labelStyle: { width: '110px' },
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)',
                },
          }}
        />
        <CustomFormikInput
          inputName="tel2"
          label={' '}
          formik={formik}
          placeholder="0000"
          unit="-"
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                }
              : {},
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)',
                },
            labelStyle: isSp ? {} : { display: 'none' },
          }}
        />
        <CustomFormikInput
          inputName="tel3"
          label={' '}
          formik={formik}
          placeholder="0000"
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3))',
                }
              : {},
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)',
                },
            labelStyle: isSp ? {} : { display: 'none' },
          }}
        />
      </div>
      <div
        className={'tel_area_for_CFI'}
        style={{ width: isSp ? '100%' : '600px' }}
      >
        <CustomFormikInput
          label="FAX"
          inputName="fax1"
          formik={formik}
          placeholder={'03'}
          unit="-"
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                }
              : {},
            labelStyle: { width: '110px' },
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)',
                },
          }}
        />
        <CustomFormikInput
          inputName="fax2"
          label={' '}
          formik={formik}
          placeholder="0000"
          unit="-"
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                }
              : {},
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)',
                },
            labelStyle: isSp ? {} : { display: 'none' },
          }}
        />
        <CustomFormikInput
          inputName="fax3"
          label={' '}
          formik={formik}
          placeholder="0000"
          style={{
            areaStyle: isSp
              ? {
                  width: 'calc(((100% - 18.86px * 2) / 3))',
                }
              : {},
            inputStyle: isSp
              ? {}
              : {
                  width: 'calc(((600px - 110px - 24px) - 18.86px * 2) / 3)',
                },
            labelStyle: isSp ? {} : { display: 'none' },
          }}
        />
      </div>
      <CustomFormikInput
        formik={formik}
        label="URL"
        inputName="url"
        placeholder="URLを入力してください"
        style={urlStyle}
      />

      <div className={'venue_register--nearest_station_area'}>
        {nearestStation.map((_, i) => (
          <div
            style={{
              display: 'flex',
              alignItems: isSp ? 'flex-end' : 'center',
              gap: '16px',
              width: '100%'
            }}
            key={i}
          >
            <CustomFormikInput
              formik={formik}
              label={i === 0 ? '最寄り駅' : isSp ? '' : '　'}
              inputName={`nearestStation[${i}]`}
              placeholder="駅名を入力してください"
              style={style}
            />
            {i === nearestStation.length - 1 ?
              <AddLineButton
                onClick={() =>
                  nearestStation.length > 4
                    ? ''
                    : handlerFormikFieldChange(formik, 'nearestStation', [
                      ...nearestStation,
                      '',
                    ])
                }
                disabled={nearestStation.length > 4}
                style={{marginBottom: isSp ? '2px' : ''}}
              /> :
              <DeleteIcon
                title="削除"
                onClick={() => {
                  handlerFormikFieldChange(formik,'nearestStation', deleteArrayItem(nearestStation, i))
                }}
                classFlag={false}
                style={{marginBottom: isSp ? '4px' : '', marginRight: isSp ? '4px' : ''}}
              />
            }
          </div>
        ))}
      </div>
    </div>
  );

  const Remark = () => (
    <div style={tabStyle}>
      <CustomFormikInput
        formik={formik}
        inputType="select"
        label={namingCategoryList
          ?.filter(n => n.namingId === `ClientPreliminary1`)
          .map(p => p?.label)}
        inputName="preliminary1"
        initialValue={namingListPriorityAsc
          ?.filter(n => n.namingClassificationId === 'ClientPreliminary1')
          .map(p => p?.value)}
        selectBoxOptions={namingListPriorityAsc
          ?.filter(n => n.namingClassificationId === 'ClientPreliminary1')
          .map(p => `${p?.namingId}:${p?.label}`)}
        selectedSelectBox={preliminary1}
        placeholder="選択してください"
        style={remarkStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType="select"
        label={namingCategoryList
          ?.filter(n => n.namingId === `ClientPreliminary2`)
          .map(p => p?.label)}
        inputName="preliminary2"
        initialValue={namingListPriorityAsc
          ?.filter(n => n.namingClassificationId === 'ClientPreliminary2')
          .map(p => p?.value)}
        selectBoxOptions={namingListPriorityAsc
          ?.filter(n => n.namingClassificationId === 'ClientPreliminary2')
          .map(p => `${p?.namingId}:${p?.label}`)}
        selectedSelectBox={preliminary2}
        placeholder="選択してください"
        style={remarkStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType="select"
        label={namingCategoryList
          ?.filter(n => n.namingId === `ClientPreliminary3`)
          .map(p => p?.label)}
        inputName="preliminary3"
        initialValue={namingListPriorityAsc
          ?.filter(n => n.namingClassificationId === 'ClientPreliminary3')
          .map(p => p?.value)}
        selectBoxOptions={namingListPriorityAsc
          ?.filter(n => n.namingClassificationId === 'ClientPreliminary3')
          .map(p => `${p?.namingId}:${p?.label}`)}
        selectedSelectBox={preliminary3}
        placeholder="選択してください"
        style={remarkStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType="select"
        label={namingCategoryList
          ?.filter(n => n.namingId === `ClientPreliminary4`)
          .map(p => p?.label)}
        inputName="preliminary4"
        initialValue={namingListPriorityAsc
          ?.filter(n => n.namingClassificationId === 'ClientPreliminary4')
          .map(p => p?.value)}
        selectBoxOptions={namingListPriorityAsc
          ?.filter(n => n.namingClassificationId === 'ClientPreliminary4')
          .map(p => `${p?.namingId}:${p?.label}`)}
        selectedSelectBox={preliminary4}
        placeholder="選択してください"
        style={remarkStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType="select"
        label={namingCategoryList
          ?.filter(n => n.namingId === `ClientPreliminary5`)
          .map(p => p?.label)}
        inputName="preliminary5"
        initialValue={namingListPriorityAsc
          ?.filter(n => n.namingClassificationId === 'ClientPreliminary5')
          .map(p => p?.value)}
        selectBoxOptions={namingListPriorityAsc
          ?.filter(n => n.namingClassificationId === 'ClientPreliminary5')
          .map(p => `${p?.namingId}:${p?.label}`)}
        selectedSelectBox={preliminary5}
        placeholder="選択してください"
        style={remarkStyle}
      />
      <CustomFormikInput
        formik={formik}
        inputType="textBox"
        label="備考"
        inputName="note"
        placeholder="備考を入力してください"
        style={remarkStyle}
      />
    </div>
  );

  const infos = {
    updates: isEdit
      ? [
          {
            label: '新規登録',
            datetime:
              moment(formik?.values?.createdUser?.datetime).format(
                'YYYY/MM/DD hh:mm:ss'
              ) ?? '-',
            name: formik?.values?.createdUser?.name ?? '-',
          },
          {
            label: '最終更新',
            datetime:
              moment(formik?.values?.updatedUser?.datetime).format(
                'YYYY/MM/DD hh:mm:ss'
              ) ?? '-',
            name: formik?.values?.updatedUser?.name ?? '-',
          },
        ]
      : [],
    basic: {
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: isEdit && isSp ? '32px 16px' : isSp ? '32px 16px' : '24px 16px',
        minWidth: isSp ? '100%' : '500px',
      },
      inputs: [
        {
          label: '開催地ID', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'venueId', //DBのカラム名
          initialValue: '', //inputの初期値
          disabled: isEdit,
          value: isEdit ? venueId : '',
          placeholder: !isEdit && '開催地IDを入力してください',
          className: {
            areaClass: isEdit && 'venue_register--venue_id_field',
          },
          style: {
            labelStyle: { width: isSp && isEdit ? 'auto' : '120px' },
            inputStyle: isEdit
              ? {
                  border: '0px',
                  background: '#e9f2fc',
                  color: '#2b3245',
                  marginLeft: isSp && isEdit ? '0' : '-15px',
                }
              : {},
          },
        },
        {
          inputType: 'radioButton', //inputのtype
          radioButtonTitle: '種別', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          useValue: true,
          key: 'venueFlag',
          radioButtonLabelAndChecked: [
            { label: '自社店舗', checked: venueFlag == 0 },
            { label: '催事場', checked: venueFlag == 1 },
            { label: '展示会場', checked: venueFlag == 2 },
            { label: 'その他', checked: venueFlag == 9 },
          ], //DBのカラム名
          inputName: 'venueFlag',
          initialValue: [0, 1, 2, 9],
          style: {
            labelStyle: { width: '120px' },
          },
        },
        {
          label: '開催地名', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'venueName', //DBのカラム名
          inputType: 'suggest',
          initialValue: '', //inputの初期値
          suggestions: suggestions,
          value: venueName,
          onChange: (event, { newValue, method }) => {
            handlerFormikFieldChange(formik, 'venueName', newValue);
          },
          onBlur: () => {},
          onFocus: () => {
            const inputValue = venueName.trim().toLowerCase();
            const inputLength = inputValue.length;
            handlerFormikFieldChange(
              formik,
              'suggestions',
              inputLength === 0
                ? []
                : venueSuggestList?.filter(
                    v =>
                      v?.venueName?.toLowerCase().slice(0, inputLength) ===
                      inputValue
                  )
            );
          },
          placeholder: '開催地名を入力してください',
          style: {
            labelStyle: { width: '120px' },
          },
        },
        {
          label: '開催地名(カナ)', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'venueNameKana', //DBのカラム名
          initialValue: '', //inputの初期値
          value: isEdit ? venueNameKana : '',
          placeholder: '開催地名(カナ)を入力してください',
          style: {
            labelStyle: { width: '120px' },
          },
        },
      ],
    },

    detail: {
      tabs: {
        common: {
          label: '基本情報',
          jsx: Common,
        },
        remark: {
          label: '備考情報',
          jsx: Remark,
        },
      },
    },
  };

  const setFormikData = data => {
    let formatData = {
      ...data,
      venueId: data?.venueId ?? null,
      venueFlag: data?.venueFlag == 0 || data?.venueFlag ? data.venueFlag : 0,
      venueName: data?.venueName ?? '',
      venueNameKana: data?.venueNameKana ?? null,
      postCode1: data?.postCode?.number1 ?? null,
      postCode2: data?.postCode?.number2 ?? null,
      prefCode: data?.prefCode ?? null,
      cityName: data?.cityName ?? null,
      streetName: data?.streetName ?? null,
      buildingName: data?.buildingName ?? null,
      tel1: data?.tel?.number1 ?? null,
      tel2: data?.tel?.number2 ?? null,
      tel3: data?.tel?.number3 ?? null,
      fax1: data?.fax?.number1 ?? null,
      fax2: data?.fax?.number2 ?? null,
      fax3: data?.fax?.number3 ?? null,
      url: data?.url ?? null,
      nearestStation: data?.nearestStation ?? [''],
      note: data?.note ?? '',
      editVenueName: isEdit && data?.venueName,
    };

    formik.setValues(formatData);
  };

  // 住所検索
  useEffect(() => {
    zipApi(
      formik,
      'postCode1',
      'postCode2',
      'prefCode',
      'cityName',
      'streetName'
    );
  }, [formik.values.postCode1, formik.values.postCode2]);

  useEffect(() => {
    dispatch(venueInfoFetch());
    !isEdit &&
      dispatch(venueInitialInfoFetch())
        .unwrap()
        .then(data => {
          formik.setFieldValue('venueId', data.newVenueId);
          formik.setFieldValue('venueSuggestList', data.item);
        });
    isEdit &&
      dispatch(
        venueDetailFetch({
          venueId: location?.state?.id,
          deletedFlag: location?.state?.deletedFlag,
        })
      )
        .unwrap()
        .then(data => {
          setFormikData(data.detail);
          let nearestStationList = [];
          nearestStationList.push(data?.detail?.nearestStation1);
          nearestStationList.push(data?.detail?.nearestStation2);
          nearestStationList.push(data?.detail?.nearestStation3);
          nearestStationList.push(data?.detail?.nearestStation4);
          nearestStationList.push(data?.detail?.nearestStation5);
          const isNearestStationList = nearestStationList.filter(n => n !== '' && n !== null).length === 0;
          formik.setFieldValue(
            'nearestStation',
            isNearestStationList ? [''] : nearestStationList.filter(n => n !== ''  && n !== null));
          dispatch(venueInitialInfoFetch())
            .unwrap()
            .then(data => {
              formik.setFieldValue('venueSuggestList', data.item);
            });
        });
  }, []);

  useEffect(() => {
    const inputValue = venueName.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (!isEdit) {
      handlerFormikFieldChange(
        formik,
        'suggestions',
        inputLength === 0
          ? []
          : venueSuggestList?.filter(
              v =>
                v?.venueName?.toLowerCase().slice(0, inputLength) === inputValue
            )
      );
    }
  }, [venueName]);

  if (isEdit) {
    return (
      <>
        <div className="venue_register">
          <RegisterBase
            image={venueImage ? venueImage : Icons.icon.noImage}
            infos={infos}
            formik={formik}
            setImage={handleFileChange}
          />
        </div>
        <div className={'bottom_btn_area'} style={{ height: '92px' }}>
          <div
            className={'venue_register--submit_buttons'}
            style={{ marginTop: '24px' }}
          >
            <Button
              text={'戻る'}
              style={Options.buttonStyles.back}
              onClick={() => {
                navigate(-1);
              }}
            />
            {location.state.deletedFlag === 0 && (
              <Button
                text={'更新'}
                style={Options.buttonStyles.submit}
                onClick={() => {
                  handlerFormikSubmit(formik, tabErrorGroup);
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="venue_register">
          <RegisterBase
            image={venueImage ? venueImage : Icons.icon.noImage}
            infos={infos}
            formik={formik}
            setImage={handleFileChange}
          />
        </div>
        <div className={'bottom_btn_area'} style={{ height: checkIsSp() ? '88px' : '92px' }}>
          <div
            className={'venue_register--submit_buttons'}
            style={{ marginTop: '24px' }}
          >
            <Button
              text={'戻る'}
              style={Options.buttonStyles.back}
              onClick={() => {
                navigate(-1);
              }}
            />
            <Button
              text={'登録'}
              style={Options.buttonStyles.submit}
              onClick={() => {
                handlerFormikSubmit(formik, tabErrorGroup);
              }}
            />
          </div>
        </div>
      </>
    );
  }
};

export default VenueRegister;
