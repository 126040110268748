import React, { useState, useEffect, memo } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { Table, Dropdown, Menu, Tooltip } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import CustomInput from '../../../../../../components/customInput';
import CustomFormikInput from '../../../../../../components/customInput/customFormikInput';
import Icons from '../../../../../../constants/icons';
import './style.scss';
import {
  checkIsSp,
  handlerFormikFieldChange,
} from '../../../../../../utils/fnUtil';
import options from '../../../../../../constants/options';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions } from '../../../../../../redux/slice/quote';
import prefectures from '../../../../../../constants/prefectures';
import moment from 'moment/moment';

//並び替え関係
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);
const DragHandle = SortableHandle(() => (
  <Tooltip title="並替え" overlayClassName="insert_table--tool_tip">
    <img src={Icons.icon.sortNv} alt="sort" style={{ cursor: 'grab' }} />
  </Tooltip>
));

const { Column } = Table;

const InfoTable = memo(({ formik, invoiceFlag, salesTaxList, selectDate }) => {
  const { calculation, deleteFlag } = useSelector(state => state.quote);
  const informationData = formik.values;

  const {
    calculationTaxFlag,
    quoteAmount,
    quoteAmountTotal,
    quoteAmountTax,
    quoteBody,
  } = formik.values;
  const [dataFocus, setDataFocus] = useState('');
  const dispatch = useDispatch();
  var startIdx = -1;
  function isNumeric(val) {
    return /^-?\d+$/.test(val) && val != null;
  }

  const totalLine = [...new Array(3)].map((v, i) => quoteBody?.length - 2 + i);

  //　合計計算処理
  useEffect(() => {
    if (!calculation) {
      var calcFlag = false;
      const quoteAmountSum = quoteBody
        ?.filter(q => !totalLine.includes(q?.rowIndex))
        .reduce((s, q) => {
          const sum =
            isNumeric(q?.quantity) && isNumeric(q?.amount)
              ? s + Number(q?.quantity) * Number(q?.amount)
              : s;
          return sum;
        }, 0);
      var quoteAmountTaxSum = quoteBody
        ?.filter(q => !totalLine.includes(q?.rowIndex))
        .reduce((s, q) => {
          const sum =
            isNumeric(q?.quantity) &&
            isNumeric(q?.amount) &&
            salesTaxList?.find(t => t?.salesTaxId === q?.salesTaxId)?.taxRate
              ? s +
                Number(q?.quantity) *
                  Number(q?.amount) *
                  salesTaxList?.find(t => t?.salesTaxId === q?.salesTaxId)
                    ?.taxRate
              : s;
          return sum;
        }, 0);

      switch (calculationTaxFlag) {
        case 0:
          quoteAmountTaxSum = Math.ceil(quoteAmountTaxSum * 0.01);
          break;
        case 1:
          quoteAmountTaxSum = Math.floor(quoteAmountTaxSum * 0.01);
          break;
        case 2:
          quoteAmountTaxSum = Number(quoteAmountTaxSum * 0.01).toFixed(0);
          break;
        default:
          break;
      }
      if (quoteAmountSum !== quoteAmount) {
        handlerFormikFieldChange(formik, 'quoteAmount', quoteAmountSum);
        calcFlag = true;
      }
      if (quoteAmountTaxSum !== quoteAmountTax) {
        handlerFormikFieldChange(formik, 'quoteAmountTax', quoteAmountTaxSum);
        calcFlag = true;
      }
      if (quoteAmountTotal !== quoteAmount + quoteAmountTaxSum) {
        handlerFormikFieldChange(
          formik,
          'quoteAmountTotal',
          quoteAmountSum + quoteAmountTaxSum
        );
        calcFlag = true;
      }
      calcFlag !== calculation &&
        dispatch(quoteActions.setCalculation(calcFlag));
    }
  }, [quoteBody]);
  useEffect(() => {
    if (calculation) {
      const work = quoteBody
        .filter(q => !totalLine.includes(q?.rowIndex))
        .map((q, i) => {
          return {
            ...q,
            totalAmount:
              isNumeric(q?.quantity) && isNumeric(q?.amount)
                ? q?.quantity * q?.amount
                : 0,
            salesTaxAmount:
              isNumeric(q?.quantity) &&
              isNumeric(q?.amount) &&
              salesTaxList?.find(t => t?.salesTaxId === q?.salesTaxId)?.taxRate
                ? q?.quantity *
                  q?.amount *
                  salesTaxList?.find(t => t?.salesTaxId === q?.salesTaxId)
                    ?.taxRate *
                  0.01
                : 0,
            rowIndex: i + 1,
            index: i,
          };
        });
      handlerFormikFieldChange(
        formik,
        'quoteBody',
        work.concat(quoteBody?.filter(q => totalLine.includes(q?.rowIndex)))
      );
      dispatch(quoteActions.setCalculation(false));
    }
  }, [calculation]);
  useEffect(() => {
    if (dataFocus === '') {
      return;
    }
    var ele = document.getElementById(dataFocus.target.id);
    ele.focus();
  }, [dataFocus]);
  //並び替え関係　ちゃんと動く
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let newData = arrayMoveImmutable(
        quoteBody.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      handlerFormikFieldChange(formik, 'quoteBody', newData);
      dispatch(quoteActions.setCalculation(true));
    }
  };
  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = quoteBody.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  //削除関係
  const handleDelete = key => {
    if (deleteFlag) {
      return;
    }
    dispatch(quoteActions.setDelete(true));
    const newData = quoteBody
      .filter(item => item.rowIndex !== key)
      .filter(q => !totalLine.includes(q?.rowIndex))
      .map((q, i) => {
        return {
          ...q,
          index: i,
          rowIndex: i + 1,
        };
      })
      .concat(
        options.codes.quoteBodyTotal.map((o, i) => {
          return { ...o, rowIndex: totalLine[i] - 1 };
        })
      );
    handlerFormikFieldChange(formik, 'quoteBody', newData);
    dispatch(quoteActions.setDelete(false));
  };

  const handleAdd = rowIndex => {
    if (deleteFlag) {
      return;
    }
    dispatch(quoteActions.setDelete(true));

    const newData = {
      rowIndex: rowIndex + 1,
      rowType: 1,
      detail: '',
      quantity: null,
      unit: null,
      amount: null,
      salesTaxId: salesTaxList[0]?.value ?? null,
      totalAmount: 0,
      salesTaxAmount: 0,
      index: rowIndex,
      invoiceDate: invoiceFlag ? selectDate : '',
    };
    let sliceData = quoteBody.slice();

    sliceData = sliceData.map((obj, idx) => {
      return obj.rowIndex > rowIndex
        ? { ...obj, rowIndex: obj.rowIndex + 1, index: obj.index + 1 }
        : obj;
    });
    sliceData.splice(-3, 0, newData);
    sliceData = sliceData.map((obj, idx) => {
      return sliceData[sliceData.findIndex(o => o.rowIndex === idx + 1)];
    });

    handlerFormikFieldChange(formik, 'quoteBody', sliceData);
    dispatch(quoteActions.setDelete(false));
  };

  const handleCell = index =>
    quoteBody[index].rowType === 1 ? { colSpan: 1 } : { colSpan: 0 };

  const convertFullWidthNumberToHalfWidth = str => {
    const removedComma = str.replace(/,|，/g, '');
    const ret = removedComma.replace(/[０-９]/g, s => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });

    return Number(ret);
  };

  useEffect(() => {
    if (!formik.values.quoteDocumentDate) {
      formik.setFieldValue(
        'quoteDocumentDate',
        moment(new Date()).format('YYYY/M/D')
      );
    }
  }, [formik.values.quoteDocumentDate]);

  useEffect(() => {
    if (!formik.values.quoteDocumentLimit) {
      formik.setFieldValue('quoteDocumentLimit', '御見積後1ヶ月');
    }
  }, [formik.values.quoteDocumentLimit]);

  // useEffect(() => {
  //   if (!invoiceFlag) {
  //     (async () => {
  //       const pRes = await fetchRequest(apiUrl.payment.list);
  //
  //       let res;
  //       pRes.item.forEach(obj => {
  //         if (obj.payTermsId == informationData.payTermsId) {
  //           res = obj.payTermsName;
  //         }
  //       });
  //
  //       return res;
  //     })().then(v => (document.getElementById('payTermsName').innerText = v));
  //   }
  // }, [informationData.matterName]);

  const [focusTo, setFocusTo] = useState(null);

  useEffect(() => {
    window.addEventListener('keydown', e => {
      if (e.key === 'Tab') {
        e.preventDefault();
      }
    });
  }, []);

  // useEffect(() => {
  //   if (focusTo) {
  //     document.getElementById(focusTo.id).focus();
  //   }
  // }, [focusTo])

  return (
    <>
      <div className="quote_register--quotation_info">
        <div className="insert_table quote_register--quotation_info--table">
          <div className="quote_register--quotation_info--table--title">
            {invoiceFlag ? '請求書' : '見積書'}
          </div>

          {/* ヘッダ */}
          <div className="quote_register--quotation_info--table--header">
            <div className="quote_register--quotation_info--table--header--left">
              <div className="quote_register--quotation_info--table--header--left--company_nm">
                {informationData?.clientName + ' 御中'}
              </div>
              <div>
                下記の通り{invoiceFlag ? 'ご請求' : 'お見積り'}申し上げます
              </div>
              <div className="quote_register--quotation_info--table--header--left--info">
                <div className="quote_register--quotation_info--table--header--left--info--left">
                  <span>件名</span>
                  <span>{invoiceFlag ? '支払期限' : '支払条件'}</span>
                  <span>{invoiceFlag ? '振込先' : '有効期限'}</span>
                </div>
                <div className="quote_register--quotation_info--table--header--left--info--right">
                  <span>{informationData?.matterName}</span>
                  <span>
                    {invoiceFlag
                      ? informationData?.invoiceDocumentDate?.indexOf(
                          selectDate
                        ) !== undefined
                        ? informationData?.paymentLimitDate[
                            informationData?.invoiceDocumentDate?.indexOf(
                              selectDate
                            )
                          ]
                        : ''
                      : informationData?.payTermName}
                  </span>
                  {invoiceFlag ? (
                    <span className="header-right-column">
                      {
                        informationData?.bankingList?.find(
                          b => b?.value == informationData?.bankMasterId
                        )?.label
                      }
                    </span>
                  ) : (
                    <CustomFormikInput
                      formik={formik}
                      inputType={'input'}
                      key={
                        formik.quoteDocumentLimit
                          ? formik.quoteDocumentLimit
                          : 'QDL'
                      }
                      inputName="quoteDocumentLimit"
                    />
                  )}
                </div>
              </div>
              <div
                className="quote_register--quotation_info--table--header--left--total"
                style={
                  informationData?.corporateInfo?.quoteAmountViewFlag === 3
                    ? { visibility: 'hidden' }
                    : {}
                }
              >
                <div>合計金額</div>
                <div>
                  {informationData?.corporateInfo?.quoteAmountViewFlag === 0
                    ? (quoteAmount + Number(quoteAmountTax)).toLocaleString() +
                      '円 (税込)'
                    : informationData?.corporateInfo?.quoteAmountViewFlag === 1
                    ? quoteAmount?.toLocaleString() + '円 (税抜)'
                    : ''}
                </div>
              </div>
            </div>
            <div>
              <div className="quote_register--quotation_info--table--header--right">
                <div className="quote_register--quotation_info--table--header--right--catalog">
                  <span>No</span>
                  <span>{invoiceFlag ? '請求日' : '見積日'}</span>
                </div>
                <div style={{ maxWidth: checkIsSp() ? '177px' : '' }}>
                  {invoiceFlag ? (
                    <>
                      <div className="quote_register--quotation_info--table--header--left--info--right">
                        <span className="header-right-column">
                          {informationData?.invoiceNumber}
                        </span>
                        <span className="header-right-column">
                          {selectDate}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <span className="quote_register--quotation_info--table--header--right--no">
                        <div
                          style={{
                            width: 'auto',
                            flexShrink: '0',
                            paddingRight: '15px',
                          }}
                        >
                          {informationData?.quoteNumber}
                        </div>
                        <CustomFormikInput
                          inputType="input"
                          formik={formik}
                          inputName="quoteDocumentBranchNumber"
                          style={{
                            areaStyle: { width: checkIsSp() ? '100%' : '82px' },
                          }}
                        />
                      </span>
                      <span className={'quote_register--custom_input_date'}>
                        <CustomFormikInput
                          inputType="inputDate"
                          formik={formik}
                          inputName="quoteDocumentDate"
                          placeholder=""
                          style={{
                            inputStyle: { width: checkIsSp() ? '100%' : '' },
                          }}
                        />
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="quote_register--quotation_info--table--header--right--info">
                <span>{informationData?.corporateInfo?.name ?? ''}</span>
                <span>
                  〒
                  {informationData?.corporateInfo?.postCode?.number1 &&
                  informationData?.corporateInfo?.postCode?.number2
                    ? `${informationData?.corporateInfo?.postCode?.number1} - ${informationData?.corporateInfo?.postCode?.number2}`
                    : ''}
                </span>
                <span>
                  {`${
                    prefectures?.find(
                      p =>
                        p?.prefecturesCode ===
                        informationData?.corporateInfo?.prefCode
                    )?.label ?? ''
                  }${informationData?.corporateInfo?.cityName ?? ''}${
                    informationData?.corporateInfo?.streetName ?? ''
                  }`}
                </span>
                <span>
                  {informationData?.corporateInfo?.buildingName ?? ''}
                </span>
                <span>
                  TEL：
                  {informationData?.corporateInfo?.tel?.number1 &&
                  informationData?.corporateInfo?.tel?.number2 &&
                  informationData?.corporateInfo?.tel?.number3
                    ? `${informationData?.corporateInfo?.tel?.number1} -
                ${informationData?.corporateInfo?.tel?.number2} -
                ${informationData?.corporateInfo?.tel?.number3}`
                    : ''}
                </span>
                <span>
                  FAX：
                  {informationData?.corporateInfo?.fax?.number1 &&
                  informationData?.corporateInfo?.fax?.number2 &&
                  informationData?.corporateInfo?.fax?.number3
                    ? `${informationData?.corporateInfo?.fax?.number1} -
                ${informationData?.corporateInfo?.fax?.number2} -
                ${informationData?.corporateInfo?.fax?.number3}`
                    : ''}
                </span>

                <span>
                  {informationData?.invoiceRegistrationNumber && `登録番号：T${informationData?.invoiceRegistrationNumber}` }
                </span>
              </div>
            </div>
          </div>

          {/* 明細 */}
          <div className="quote_register--quotation_info--table--area">
            <Table
              //subpage
              pagination={false}
              dataSource={quoteBody}
              rowKey="index"
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            >
              <Column
                title=""
                dataIndex="index"
                key="index"
                className="insert_table--column_order info_table--column_order"
                render={(_, record) =>
                  !totalLine.includes(record.rowIndex) && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <DragHandle />
                      <Dropdown
                        trigger={['click']}
                        placement="top"
                        arrow={{
                          pointAtCenter: true,
                        }}
                        overlay={() => {
                          let shallowCopy = quoteBody.slice();
                          let idx = quoteBody.findIndex(
                            item => record.rowIndex === item.rowIndex
                          );
                          return (
                            <Menu
                              selectable
                              defaultSelectedKeys={[quoteBody[idx].rowType]}
                              items={options.codes.quoteItem}
                              onSelect={({ key }) => {
                                shallowCopy[idx].rowType = Number(key);
                                shallowCopy[idx].amount = '';
                                shallowCopy[idx].detail =
                                  key == 3 ? '小計' : '';
                                shallowCopy[idx].quantity = '';
                                shallowCopy[idx].unit = '';
                                shallowCopy[idx].totalAmount = '';
                                shallowCopy[idx].invoiceDate = invoiceFlag
                                  ? selectDate
                                  : '';
                                handlerFormikFieldChange(
                                  formik,
                                  'quoteBody',
                                  shallowCopy
                                );
                              }}
                              style={{
                                padding: '0',
                                width: '88px',
                                height: '82px',
                                background: '#FFFFFF',
                                borderRadius: '3px',
                              }}
                            />
                          );
                        }}
                      >
                        <a
                          style={{
                            height: '32px',
                            width: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '8px',
                          }}
                          onClick={e => e.preventDefault()}
                        >
                          <img
                            src={Icons.icon.downNv}
                            alt="select"
                            style={{
                              height: '16px',
                              display: 'inline-block',
                              padding: '0 4px',
                            }}
                          />
                        </a>
                      </Dropdown>
                    </div>
                  )
                }
              />
              <Column
                title="摘要"
                key="detail"
                // width={450}
                className="info_table--column"
                render={(_, record) =>
                  !totalLine.includes(record.rowIndex) && (
                    <CustomInput
                      // formik={formik}
                      inputType="input"
                      inputName={`quoteBody.[${record.index}].detail`}
                      relativeInputName={['detail']}
                      style={{
                        areaStyle: { minWidth: '340px' },
                        inputStyle:
                          quoteBody[record.index]?.rowType === 3
                            ? { textAlign: 'right' }
                            : { textAlign: 'left' },
                      }}
                      onBlur={e => {
                        formik.setValues({
                          ...formik.values,
                          quoteBody: formik.values.quoteBody.map((obj, idx) => {
                            if (obj.rowIndex - 1 === record.index) {
                              return { ...obj, detail: e.target.value };
                            } else {
                              return obj;
                            }
                          }),
                        });
                      }}
                      value={
                        formik.values?.quoteBody[record.index]?.detail
                          ? formik.values?.quoteBody[record.index]?.detail
                          : formik.values?.quoteBody[record.index]?.rowType ===
                            3
                          ? '小計'
                          : ''
                      }
                    />
                  )
                }
                onCell={(record, index) =>
                  totalLine.includes(record.rowIndex)
                    ? { colSpan: 4 }
                    : quoteBody[index].rowType === 1
                    ? { colSpan: 1 }
                    : quoteBody[index].rowType === 2
                    ? { colSpan: 6 }
                    : { colSpan: 5 }
                }
              />
              <Column
                title="数量"
                // key="quantity"
                className="info_table--column"
                render={(_, record) => (
                  <CustomInput
                    formik={formik}
                    replaceNumber={true}
                    inputName={`quoteBody.[${record.index}].quantity`}
                    relativeInputName={['quantity']}
                    style={{
                      inputStyle: { textAlign: 'right' },
                      areaStyle: { minWidth: '30px' },
                    }}
                    onBlur={e => {
                      if (typeof e.target.value !== 'number') {
                        e.target.value = convertFullWidthNumberToHalfWidth(
                          e.target.value
                        );
                      }
                      formik.setValues({
                        ...formik.values,
                        quoteBody: formik.values.quoteBody.map((obj, idx) => {
                          if (obj.rowIndex - 1 === record.index) {
                            return { ...obj, quantity: e.target.value };
                          } else {
                            return obj;
                          }
                        }),
                      });
                    }}
                    value={formik.values?.quoteBody[record.index]?.quantity}
                  />
                )}
                onCell={(record, index) =>
                  totalLine.includes(record.rowIndex)
                    ? { colSpan: 0 }
                    : handleCell(index)
                }
              />
              <Column
                title="単位"
                key="unit"
                className="info_table--column"
                render={(_, record) => (
                  <CustomInput
                    formik={formik}
                    inputType="input"
                    inputName={`quoteBody.[${record.index}].unit`}
                    relativeInputName={['unit']}
                    style={{ areaStyle: { minWidth: '44px' } }}
                    onBlur={e => {
                      formik.setValues({
                        ...formik.values,
                        quoteBody: formik.values.quoteBody.map((obj, idx) => {
                          if (obj.rowIndex - 1 === record.index) {
                            return { ...obj, unit: e.target.value };
                          } else {
                            return obj;
                          }
                        }),
                      });
                    }}
                    value={formik.values?.quoteBody[record.index]?.unit}
                  />
                )}
                onCell={(record, index) =>
                  totalLine.includes(record.rowIndex)
                    ? { colSpan: 0 }
                    : handleCell(index)
                }
              />
              <Column
                title="単価"
                key="amount"
                className="info_table--column"
                render={(_, record) => (
                  <CustomInput
                    formik={formik}
                    replaceNumber={true}
                    inputName={`quoteBody.[${record.index}].amount`}
                    relativeInputName={['amount']}
                    style={{
                      inputStyle: { textAlign: 'right' },
                      areaStyle: { minWidth: '44px' },
                    }}
                    onBlur={e => {
                      if (typeof e.target.value !== 'number') {
                        e.target.value = convertFullWidthNumberToHalfWidth(
                          e.target.value
                        );
                      }
                      formik.setValues({
                        ...formik.values,
                        quoteBody: formik.values.quoteBody.map((obj, idx) => {
                          if (obj.rowIndex - 1 === record.index) {
                            return { ...obj, amount: e.target.value };
                          } else {
                            return obj;
                          }
                        }),
                      });
                    }}
                    value={formik.values?.quoteBody[record.index]?.amount}
                  />
                )}
                onCell={(record, index) =>
                  totalLine.includes(record.rowIndex)
                    ? { colSpan: 0 }
                    : handleCell(index)
                }
              />
              <Column
                title="消費税"
                key="salesTaxId"
                // width={96}
                className="info_table--column"
                render={(_, record) =>
                  totalLine.includes(record.rowIndex) ? (
                    <div>
                      {record.rowIndex === totalLine[0]
                        ? '小計'
                        : record.rowIndex === totalLine[1]
                        ? '消費税'
                        : record.rowIndex === totalLine[2]
                        ? '合計'
                        : ''}
                    </div>
                  ) : (
                    <CustomInput
                      formik={formik}
                      inputType="select"
                      inputName={`quoteBody.[${record.index}].salesTaxId`}
                      relativeInputName={['salesTaxId']}
                      initialValue={salesTaxList?.map(t => t?.value)}
                      selectBoxOptions={salesTaxList?.map(t => t?.label)}
                      style={{ areaStyle: { minWidth: '90px' } }}
                      onChange={e => {
                        formik.setValues({
                          ...formik.values,
                          quoteBody: formik.values.quoteBody.map((obj, idx) => {
                            if (obj.rowIndex - 1 === record.index) {
                              return { ...obj, salesTaxId: e };
                            } else {
                              return obj;
                            }
                          }),
                        });
                      }}
                      selectedSelectBox={
                        formik.values?.quoteBody[record.index]?.salesTaxId ??
                        salesTaxList[salesTaxList.length - 1]?.value
                      }
                    />
                  )
                }
                onCell={(_, index) => handleCell(index)}
              />
              <Column
                title="金額"
                key="totalAmount"
                className="info_table--column"
                render={(_, record) => {
                  //行合計は0の時
                  let totalAmountIsZero =
                    record.totalAmount === 0 ||
                    record.totalAmount === undefined ||
                    record.totalAmount === null
                      ? ''
                      : record.totalAmount.toLocaleString();

                  let sublineTotal = 0;

                  if (record.rowType === 3) {
                    let subtotalLine = quoteBody.slice(
                      startIdx + 1,
                      record.index
                    );
                    startIdx = record.index;
                    sublineTotal = subtotalLine
                      .map(item => item.totalAmount)
                      .reduce((acr, cur) => acr + cur, 0);
                    sublineTotal = sublineTotal === 0 ? '' : sublineTotal;
                  }

                  return record.rowType === 3 ? (
                    <div style={{ textAlign: 'right' }}>
                      {sublineTotal.toLocaleString()}
                    </div>
                  ) : (
                    <div
                      style={
                        record.rowIndex === 'total'
                          ? {
                              textAlign: 'right',
                              font: 'normal normal bold 14px Noto Sans CJK JP',
                              minWidth: '150px',
                            }
                          : {
                              textAlign: 'right',
                              minWidth: '150px',
                            }
                      }
                    >
                      {record.rowIndex === totalLine[0]
                        ? quoteAmount === undefined || quoteAmount === null
                          ? ''
                          : quoteAmount.toLocaleString()
                        : record.rowIndex === totalLine[1]
                        ? quoteAmountTax === undefined ||
                          quoteAmountTax === null
                          ? ''
                          : quoteAmountTax.toLocaleString()
                        : record.rowIndex === totalLine[2]
                        ? quoteAmountTax === undefined ||
                          quoteAmountTax === null
                          ? ''
                          : (
                              quoteAmount + Number(quoteAmountTax)
                            ).toLocaleString()
                        : totalAmountIsZero}
                    </div>
                  );
                }}
                onCell={(_, index) =>
                  quoteBody[index].rowType === 3
                    ? { colSpan: 1 }
                    : handleCell(index)
                }
              />
              <Column
                title=""
                key="action"
                className="insert_table--column_action info_table--column_action"
                render={(_, record) =>
                  !totalLine.includes(record.rowIndex) && (
                    <div>
                      <Tooltip
                        title="行追加"
                        overlayClassName="insert_table--tool_tip"
                      >
                        <img
                          src={Icons.icon.plusNv}
                          alt="plus"
                          style={{ cursor: 'pointer', width: '32px' }}
                          onClick={() => {
                            handleAdd(record.rowIndex);
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title="行削除"
                        overlayClassName="insert_table--tool_tip"
                      >
                        <img
                          src={
                            quoteBody?.length == 4
                              ? Icons.icon.deleteSGr
                              : Icons.icon.deleteSNv
                          }
                          alt="delete"
                          style={
                            quoteBody?.length == 4
                              ? { cursor: 'not-allowed' }
                              : { cursor: 'pointer' }
                          }
                          onClick={() => {
                            if (quoteBody?.length == 4) {
                              return;
                            }
                            handleDelete(record.rowIndex);
                          }}
                        />
                      </Tooltip>
                    </div>
                  )
                }
              />
            </Table>
          </div>

          <div className="quote_register--quotation_info--table--note">
            <div className="quote_register--quotation_info--table--note--header">
              備考
            </div>
            <div className="quote_register--quotation_info--table--note--input">
              <CustomFormikInput
                formik={formik}
                inputType="textBox"
                inputName="note"
                style={{ inputStyle: { height: '264px' } }}
                onBlur={e => {
                  formik.setFieldValue('note', e.target.value);
                }}
                defaultValue={formik?.values?.note ? formik?.values?.note : ''}
                useValue={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default InfoTable;
