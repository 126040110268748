import { memo } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '../../../../components/checkbox';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import Options from '../../../../constants/options';
import prefectures from '../../../../constants/prefectures';
import { checkIsSp, handlerFormikFieldChange } from '../../../../utils/fnUtil';
import './style.scss';

const StaffMatterTab = memo(({ type, formik }) => {
  const { values } = formik;

  const disabled = values[`${type}RecruitFlag`] === 0;

  const ageOptions = [...Array(100)].map((_, i) => i);

  const heightOptions = [...Array(250)].map((_, i) => i);

  const { holdingItemList } = useSelector(state => state.staffRecruitMatter);

  const isSp = checkIsSp();

  return (
    <div className="staff_matter_tab">
      <div>
        <CustomFormikInput
          formik={formik}
          inputType="radioButton"
          inputName={`${type}RecruitFlag`}
          radioButtonTitle="募集可否"
          radioButtonLabelAndChecked={[
            {
              label: '可',
              checked: values[`${type}RecruitFlag`] == 1,
            },
            {
              label: '不可',
              checked: values[`${type}RecruitFlag`] == 0,
            },
          ]}
          initialValue={[1, 0]}
          style={{
            areaStyle: { width: 'auto' },
            labelStyle: {
              width: 175,
            },
          }}
          useValue={true}
          onChange={e => {
            handlerFormikFieldChange(
              formik,
              `${type}RecruitFlag`,
              e.target.value == '1' ? 1 : 0
            );
          }}
        />
      </div>
      <div>
        <div className="staff_matter_tab--item">募集条件</div>
        <div className="staff_matter_tab--item_list">
          <CustomFormikInput
            formik={formik}
            inputType="radioButton"
            inputName={`${type}EvaluationFlag`}
            radioButtonTitle="スタッフ評価"
            disabled={disabled}
            radioButtonLabelAndChecked={[
              {
                label: '指定しない',
                checked: values[`${type}EvaluationFlag`] == 0,
              },
              {
                label: '指定する',
                checked: values[`${type}EvaluationFlag`] == 1,
              },
            ]}
            initialValue={[0, 1]}
            style={{
              areaStyle: { width: 'auto' },
              labelStyle: {
                width: 175,
              },
            }}
          />
          <CustomFormikInput
            formik={formik}
            disabled={disabled || values[`${type}EvaluationFlag`] == 0}
            placeholder="選択してください"
            inputType="select"
            mode="multiple"
            showSearch={false}
            allowClear={false}
            initialValue={Options.codes.staffEvaluation.map(e => e.value)}
            selectBoxOptions={Options.codes.staffEvaluation.map(e => e.label)}
            inputName={`${type}Evaluation`}
            style={{
              areaStyle: {
                width: isSp ? '100%' : 291,
              },
            }}
          />
        </div>
        <div className="staff_matter_tab--item_list">
          <CustomFormikInput
            formik={formik}
            inputType="radioButton"
            inputName={`${type}GenderFlag`}
            radioButtonTitle="性別"
            disabled={disabled}
            radioButtonLabelAndChecked={[
              {
                label: '指定しない',
                checked: values[`${type}GenderFlag`] == 0,
              },
              { label: '指定する', checked: values[`${type}GenderFlag`] == 1 },
            ]}
            initialValue={[0, 1]}
            style={{
              areaStyle: { width: 'auto' },
              labelStyle: { width: 175 },
            }}
          />
          {isSp ? (
            <div style={{ display: 'flex', gap: '16px' }}>
              <Checkbox
                label="男性"
                disabled={disabled || values[`${type}GenderFlag`] == 0}
                checked={values[`${type}Gender`] === 1}
                onChange={e =>
                  handlerFormikFieldChange(
                    formik,
                    `${type}Gender`,
                    e.target.checked ? 1 : null
                  )
                }
              />
              <Checkbox
                label="女性"
                disabled={disabled || values[`${type}GenderFlag`] == 0}
                checked={values[`${type}Gender`] === 2}
                onChange={e =>
                  handlerFormikFieldChange(
                    formik,
                    `${type}Gender`,
                    e.target.checked ? 2 : null
                  )
                }
              />
            </div>
          ) : (
            <>
              <Checkbox
                label="男性"
                disabled={disabled || values[`${type}GenderFlag`] == 0}
                checked={values[`${type}Gender`] === 1}
                onChange={e =>
                  handlerFormikFieldChange(
                    formik,
                    `${type}Gender`,
                    e.target.checked ? 1 : null
                  )
                }
              />
              <Checkbox
                label="女性"
                disabled={disabled || values[`${type}GenderFlag`] == 0}
                checked={values[`${type}Gender`] === 2}
                onChange={e =>
                  handlerFormikFieldChange(
                    formik,
                    `${type}Gender`,
                    e.target.checked ? 2 : null
                  )
                }
              />
            </>
          )}
        </div>
        <div className="staff_matter_tab--item_list">
          <CustomFormikInput
            formik={formik}
            disabled={disabled}
            inputName={`${type}PrefFlag`}
            inputType="radioButton"
            radioButtonTitle="スタッフ在住都道府県"
            radioButtonLabelAndChecked={[
              {
                label: '指定しない',
                checked: values[`${type}PrefFlag`] == 0,
              },
              { label: '指定する', checked: values[`${type}PrefFlag`] == 1 },
            ]}
            initialValue={[0, 1]}
            style={{
              areaStyle: { width: 'auto' },
              labelStyle: { width: 175 },
            }}
          />
          <CustomFormikInput
            formik={formik}
            disabled={disabled || values[`${type}PrefFlag`] == 0}
            placeholder="都道府県を選択してください"
            inputType="select"
            mode="multiple"
            allowClear={false}
            initialValue={prefectures.map(p => p.prefecturesCode)}
            selectBoxOptions={prefectures.map(p => p.label)}
            inputName={`${type}Pref`}
            style={{
              areaStyle: {
                width: isSp ? '100%' : 291,
              },
            }}
          />
        </div>
        <div className="staff_matter_tab--item_list">
          <CustomFormikInput
            formik={formik}
            disabled={disabled}
            inputName={`${type}AgeFlag`}
            inputType="radioButton"
            radioButtonTitle="年齢"
            radioButtonLabelAndChecked={[
              { label: '指定しない', checked: values[`${type}AgeFlag`] == 0 },
              { label: '指定する', checked: values[`${type}AgeFlag`] == 1 },
            ]}
            initialValue={[0, 1]}
            style={{
              areaStyle: { width: 'auto' },
              labelStyle: { width: 175 },
            }}
          />
          {isSp ? (
            <div style={{ display: 'flex' }}>
              <CustomFormikInput
                formik={formik}
                disabled={disabled || values[`${type}AgeFlag`] == 0}
                placeholder="00"
                inputType="select"
                initialValue={ageOptions}
                selectBoxOptions={ageOptions}
                inputName={`${type}AgeFrom`}
                style={{
                  areaStyle: {
                    width: 122,
                  },
                }}
                unit="〜"
              />
              <CustomFormikInput
                formik={formik}
                disabled={disabled || values[`${type}AgeFlag`] == 0}
                placeholder="00"
                inputType="select"
                initialValue={ageOptions}
                selectBoxOptions={ageOptions}
                inputName={`${type}AgeTo`}
                style={{
                  areaStyle: {
                    width: 102,
                    marginLeft: '8px',
                  },
                }}
              />
            </div>
          ) : (
            <>
              <CustomFormikInput
                formik={formik}
                disabled={disabled || values[`${type}AgeFlag`] == 0}
                placeholder="00"
                inputType="select"
                initialValue={ageOptions}
                selectBoxOptions={ageOptions}
                inputName={`${type}AgeFrom`}
                style={{
                  areaStyle: {
                    width: 122,
                  },
                }}
                unit="〜"
              />
              <CustomFormikInput
                formik={formik}
                disabled={disabled || values[`${type}AgeFlag`] == 0}
                placeholder="00"
                inputType="select"
                initialValue={ageOptions}
                selectBoxOptions={ageOptions}
                inputName={`${type}AgeTo`}
                style={{
                  areaStyle: {
                    width: 100,
                  },
                }}
              />
            </>
          )}
        </div>
        <div className="staff_matter_tab--item_list">
          <CustomFormikInput
            formik={formik}
            disabled={disabled}
            inputType="radioButton"
            inputName={`${type}HeightFlag`}
            radioButtonTitle="身長"
            radioButtonLabelAndChecked={[
              {
                label: '指定しない',
                checked: values[`${type}HeightFlag`] == 0,
              },
              { label: '指定する', checked: values[`${type}HeightFlag`] == 1 },
            ]}
            initialValue={[0, 1]}
            style={{
              areaStyle: { width: 'auto' },
              labelStyle: { width: 175 },
            }}
          />
          {isSp ? (
            <div style={{ display: 'flex' }}>
              <CustomFormikInput
                formik={formik}
                disabled={disabled || values[`${type}HeightFlag`] == 0}
                placeholder="00"
                inputType="select"
                initialValue={heightOptions}
                selectBoxOptions={heightOptions}
                inputName={`${type}HeightFrom`}
                style={{
                  areaStyle: {
                    width: 122,
                  },
                }}
                unit="〜"
              />
              <CustomFormikInput
                formik={formik}
                disabled={disabled || values[`${type}HeightFlag`] == 0}
                placeholder="00"
                inputType="select"
                initialValue={heightOptions}
                selectBoxOptions={heightOptions}
                inputName={`${type}HeightTo`}
                style={{
                  areaStyle: {
                    width: 102,
                    marginLeft: '8px',
                  },
                }}
              />
            </div>
          ) : (
            <>
              <CustomFormikInput
                formik={formik}
                disabled={disabled || values[`${type}HeightFlag`] == 0}
                placeholder="00"
                inputType="select"
                initialValue={heightOptions}
                selectBoxOptions={heightOptions}
                inputName={`${type}HeightFrom`}
                style={{
                  areaStyle: {
                    width: 122,
                  },
                }}
                unit="〜"
              />
              <CustomFormikInput
                formik={formik}
                disabled={disabled || values[`${type}HeightFlag`] == 0}
                placeholder="00"
                inputType="select"
                initialValue={heightOptions}
                selectBoxOptions={heightOptions}
                inputName={`${type}HeightTo`}
                style={{
                  areaStyle: {
                    width: 100,
                  },
                }}
              />
            </>
          )}
        </div>
        <div className="staff_matter_tab--custom-input">
          {isSp ? (
            <>
              <label
                style={{
                  whiteSpace: 'nowrap',
                  flexShrink: 0,
                }}
              >
                表示設定
              </label>
              <CustomFormikInput
                formik={formik}
                inputType={'radioButton'}
                inputName={`${type}PossibleDisplayFlag`}
                disabled={disabled}
                radioButtonLabelAndChecked={[
                  {
                    label: '指定しない',
                    checked: formik.values[`${type}PossibleDisplayFlag`] == 0,
                  },
                  {
                    label: '指定する(出勤可能なスタッフのみ)',
                    checked: formik.values[`${type}PossibleDisplayFlag`] == 1,
                  },
                  {
                    label: '指定する(出勤不可なスタッフのみ)',
                    checked: formik.values[`${type}PossibleDisplayFlag`] == 2,
                  },
                ]}
                initialValue={[0, 1, 2]}
              />
            </>
          ) : (
            <>
              <label
                style={{
                  whiteSpace: 'nowrap',
                  width: '175px',
                  flexShrink: 0,
                }}
              >
                表示設定
              </label>
              <CustomFormikInput
                formik={formik}
                inputType={'radioButton'}
                inputName={`${type}PossibleDisplayFlag`}
                disabled={disabled}
                radioButtonLabelAndChecked={[
                  {
                    label: '指定しない',
                    checked: formik.values[`${type}PossibleDisplayFlag`] == 0,
                  },
                  {
                    label: '指定する(出勤可能なスタッフのみ)',
                    checked: formik.values[`${type}PossibleDisplayFlag`] == 1,
                  },
                  {
                    label: '指定する(出勤不可なスタッフのみ)',
                    checked: formik.values[`${type}PossibleDisplayFlag`] == 2,
                  },
                ]}
                initialValue={[0, 1, 2]}
              />
            </>
          )}
        </div>
      </div>
      <div className="staff_matter_tab--item">スタッフ表示情報</div>
      <div className="staff_matter_tab--item_detail">
        <CustomFormikInput
          formik={formik}
          disabled={disabled}
          labelRequired
          label="募集案件タイトル"
          placeholder="募集案件タイトルを入力してください"
          inputName={`${type}MatterTitle`}
          style={{
            labelStyle: {
              width: 175,
            },
            inputStyle: {
              width: 400,
            },
          }}
        />
        <CustomFormikInput
          formik={formik}
          disabled={disabled}
          labelRequired
          label="募集業務"
          placeholder="募集業務を入力してください"
          inputName={`${type}MatterJob`}
          style={{
            labelStyle: {
              width: 175,
            },
            inputStyle: {
              width: 400,
            },
          }}
        />
        <CustomFormikInput
          formik={formik}
          disabled={disabled}
          labelRequired
          label="業務概要"
          placeholder="業務概要を入力してください"
          inputName={`${type}MatterDetail`}
          inputType="textBox"
          style={{
            labelStyle: {
              width: 175,
            },
            inputStyle: {
              width: 531,
            },
          }}
        />
        <CustomFormikInput
          formik={formik}
          disabled={disabled}
          labelRequired
          label="服装"
          placeholder="選択してください"
          inputName={`${type}Wear`}
          inputType="select"
          mode="multiple"
          allowClear={false}
          initialValue={holdingItemList.map(h => h.namingId)}
          selectBoxOptions={holdingItemList.map(h => h.naming)}
          style={{
            labelStyle: {
              width: 175,
            },
            inputStyle: {
              width: 291,
            },
            areaStyle: {
              width: isSp ? '100%' : 400,
            },
          }}
        />
        <CustomFormikInput
          formik={formik}
          disabled={disabled}
          labelRequired
          label="単価"
          placeholder="0"
          inputName={`${type}PayAmount`}
          unit="円"
          style={{
            labelStyle: {
              width: 175,
            },
            inputStyle: {
              width: 110,
            },
            areaStyle: {
              maxWidth: 285,
            },
          }}
        />
        <CustomFormikInput
          formik={formik}
          disabled={disabled}
          labelRequired
          label="交通費支給"
          placeholder="交通費支給有無を入力してください"
          inputName={`${type}PayFare`}
          style={{
            labelStyle: {
              width: 175,
            },
            inputStyle: {
              width: 400,
            },
          }}
        />
      </div>
    </div>
  );
});

export default StaffMatterTab;
