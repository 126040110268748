import './style.scss';
import { useEffect } from 'react';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Copyright from '../../../components/copyright';
import Options from '../../../constants/options';
import CsvExportModal from '../../../components/modal/csvExportModal';
import StaffPlanInfos from './inputInfos';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  staffPlanActions,
  staffPlanFetch,
  staffPlanRemind,
  staffPlanRequestUpdate,
  staffPlanUserFetch,
} from '../../../redux/slice/staffPlan';
import { setCounter } from '../../../redux/slice/counter';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Button from '../../../components/button';
import { globalActions } from '../../../redux/slice/global';
import { csvDownloadFetch } from '../../../redux/slice/other';
import { checkIsSp } from '../../../utils/fnUtil';
import moment from 'moment/moment';

const StaffPlanList = () => {
  const dispatch = useDispatch();
  const { searchParams, staffPlanList, userList, csvExportVisible } =
    useSelector(state => state.staffPlan);

  const counterState = useSelector(state => state.counter);

  const isSp = checkIsSp();

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(staffPlanActions.saveSearchParams(values));
    },
  });

  const { sortKey: sortColumn, sortMethod, yearMonth } = formik.values;

  const getCheckedIndex = params => {
    const checkAll = document.getElementById('checkAll').checked;
    const checks = document.querySelectorAll('input[id^="check_"]');

    let ret = '';
    if (checkAll) {
      ret = 'all';
    } else {
      ret = [];
      checks.forEach((elm, index) => {
        if (elm.checked) {
          ret.push(index);
        }
      });
      ret.filter(v => v);
    }
    if (ret.length < 1) {
      dispatch(globalActions.showSingleModal(`１つ以上選択してください`));
      return;
    }
    const param =
      ret === 'all'
        ? staffPlanList?.item?.map(i => {
            return i.staffId;
          })
        : staffPlanList?.item
            ?.filter((item, i) => ret.includes(i))
            .map(i => {
              return i.staffId;
            });

    // ステータス更新
    if (params === 1) {
      dispatch(staffPlanRequestUpdate({ staffId: param, yearMonth }))
        .unwrap()
        .then(
          result =>
            result?.code == '0' &&
            dispatch(globalActions.showSingleModal('更新しました'))
        );
    }
    // リマインドメール送信
    else if (params === 2) {
      dispatch(staffPlanRemind({ staffId: param, yearMonth }))
        .unwrap()
        .then(
          result =>
            result?.code == '0' &&
            dispatch(globalActions.showSingleModal('送信しました'))
        );
    }
  };

  // データ取得
  useEffect(() => {
    dispatch(staffPlanUserFetch());
  }, []);

  useEffect(() => {
    dispatch(
      staffPlanFetch({
        ...searchParams,
        managerUserId:
          searchParams.managerUserId.length > 0
            ? searchParams.managerUserId
            : null,
      })
    );
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(
      setCounter({
        ...counterState,
        staffPlanCounter: staffPlanList?.topCount ?? [0, 0],
      })
    );
  }, [staffPlanList]);

  useEffect(() => {
    let tableTds = document.getElementsByTagName('td');

    Array.from(tableTds).map((td, idx) => {
      if (td.innerText === '未提出') {
        td.innerHTML =
          '<td style={{border-right: 1px dashed rgb(224, 224, 224)}}>' +
          "<div class='staff_plan_list--status_div'><div class='staff_plan_list--status_false'>未提出</div></div></td>";
      } else if (td.innerText === '提出済') {
        td.innerHTML =
          '<td style={{border-right: 1px dashed rgb(224, 224, 224)}}>' +
          "<div class='staff_plan_list--status_div'><div class='staff_plan_list--status_true'>提出済</div></div></td>";
      }
    });
  });

  // 検索欄実装部分
  const staffPlanListSearchArea = () => (
    <div className={'staff_plan_list--search_area'}>
      <div className={'staff_plan_list--input_area'}>
        {StaffPlanInfos.listSearchArea.inputs.map((info, idx) => {
          if (info.inputName === 'managerUserId') {
            return (
              <CustomFormikInput
                {...info}
                formik={formik}
                initialValue={userList?.map(u => u?.userId)}
                selectBoxOptions={userList?.map(
                  u =>
                    `${u?.userId}:${u?.name?.lastName ?? ''} ${
                      u?.name?.firstName ?? ''
                    }`
                )}
              />
            );
          }
          if (info.inputName === 'yearMonth') {
            return (
              <CustomFormikInput
                {...info}
                formik={formik}
                initialValue={searchParams?.yearMonth}
                key={'staff_plan_list--search_area_input_' + idx}
              />
            );
          }
          if (info.inputName === 'staffId') {
            return (
              <CustomFormikInput
                {...info}
                formik={formik}
                initialValue={searchParams?.staffId}
              />
            );
          }
          if (info.inputName === 'staffName') {
            return (
              <CustomFormikInput
                {...info}
                formik={formik}
                initialValue={searchParams?.staffName}
              />
            );
          }
          if (info.inputName === 'status') {
            return (
              <CustomFormikInput
                {...info}
                formik={formik}
                selectedSelectBox={searchParams?.status}
              />
            );
          } else {
            return (
              <CustomFormikInput
                {...info}
                formik={formik}
                key={'staff_plan_list--search_area_input_' + idx}
              />
            );
          }
        })}
      </div>
    </div>
  );

  const searchFormInfo = {
    jsx: staffPlanListSearchArea,
  };

  const columnSettings = {
    checkAll: true,
    staffId: { label: 'スタッフID', sort: 'asc', dataName: 'staffId' },
    staffName: { label: 'スタッフ名', sort: 'none', dataName: 'staffName' },
    firstSubmitDatetime: {
      label: '初回提出日時',
      sort: 'none',
      dataName: 'firstSubmitDatetime',
    },
    lastSubmitDatetime: {
      label: '最終提出日時',
      sort: 'none',
      dataName: 'lastSubmitDatetime',
    },
    mainManagerName: {
      label: 'メイン担当者',
      sort: 'none',
      dataName: 'mainManagerName',
    },
    subManagerName: {
      label: 'サブ担当者',
      sort: 'none',
      dataName: 'subManagerName',
    },
    status: { label: 'ステータス', sort: 'none', dataName: 'status' },
    statics: [{ label: '編集', icon: Icons.icon.editSNv }],
  };

  const exportCsv = () => {
    let newObj;
    if (searchParams) {
      newObj = { ...searchParams };
      Object.keys(newObj).forEach(key => {
        if (!newObj?.[key] || newObj?.[key]?.length === 0) {
          delete newObj?.[key];
        }
      });
    }

    let info = {
      url: Options.csvExport.type['staffPlanList'][0].url,
      params: { ...newObj },
    };

    dispatch(csvDownloadFetch(info)).then(data => {
      const blob = new Blob([data.payload], {
        type: 'text/csv',
      });

      if (data.error && data.error.message === 'Rejected') {
        return;
      }

      const ahref = document.createElement('a');
      ahref.download =
        Options.csvExport.type['staffPlanList'][0].fileName +
        '_' +
        moment().format('YYYYMMDDHHmmssSSS') +
        '.csv';
      ahref.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      ahref.dispatchEvent(clickEvt);
      ahref.remove();
    });
  };

  const buttonSettings = {
    viewPager: true,
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: exportCsv,
      style: Options.buttonStyles.csv,
    },
    ignoreAddBtn: isSp,
  };

  const changeSort = e => {
    dispatch(
      staffPlanActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`,
      })
    );
  };

  return (
    <>
      <div className={'commonPage--base'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'staffId'}
          primaryName={'staffName'}
          apiData={staffPlanList}
          sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(staffPlanActions.saveSearchParams({ count }))
          }
          onChangeOffset={offset =>
            dispatch(staffPlanActions.saveOffset(offset))
          }
        />
        <Copyright />
      </div>
      <div
        className={
          isSp
            ? 'staff_plan_list--button_area bottom_btn_area'
            : 'staff_plan_list--button_area'
        }
      >
        <Button
          text="提出済にする"
          style={{
            backgroundColor: '#0056d3',
            width: '240px',
            maxWidth: '240px',
            height: '44px',
            display: 'flex',
            justifyContent: 'center',
            margin: '0 0',
          }}
          onClick={() => getCheckedIndex(1)}
        />
        <Button
          text={'リマインド送信'}
          icon={Icons.icon.mailNvBold}
          style={
            isSp
              ? {
                  ...Options.buttonStyles.mailButton,
                  maxWidth: '240px',
                  maxHeight: '44px',
                }
              : Options.buttonStyles.mailButton
          }
          onClick={() => {
            getCheckedIndex(2);
          }}
        />
      </div>
    </>
  );
};

export default StaffPlanList;
