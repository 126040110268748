import { memo } from 'react';
import { useSelector } from 'react-redux';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';

const MatterRemark = memo(({ formik }) => {
  const { preliminaryIndexList, preliminaryList } = useSelector(
    state => state.matter
  );

  const disabled = !!formik.values.quoteNumber;

  return (
    <div className={'register_base--detail_info_tab_page'}>
      {[
        ...[...Array(5)].map((_, i) => ({
          inputType: 'select',
          label: preliminaryIndexList.find(
            p => p.namingId === `PromotionPreliminary${i + 1}`
          )?.naming,
          inputName: `preliminary${i + 1}`,
          initialValue: preliminaryList
            .filter(
              p => p.namingClassificationId === `PromotionPreliminary${i + 1}`
            )
            .map(p => p.namingId),
          selectBoxOptions: preliminaryList
            .filter(
              p => p.namingClassificationId === `PromotionPreliminary${i + 1}`
            )
            .map(p => `${p.namingId}:${p.naming}`),
          placeholder: '選択してください',
          disabled,
        })),
        {
          inputType: 'textBox',
          label: '備考',
          inputName: 'note',
          placeholder: '備考を入力してください',
          disabled,
        },
      ].map(input => (
        <CustomFormikInput
          {...input}
          formik={formik}
          key={input.inputName}
          style={{
            areaStyle: {
              maxWidth: 979,
            },
            labelStyle: {
              width: 170,
              textAlign: 'right',
            },
          }}
        />
      ))}
    </div>
  );
});

export default MatterRemark;
