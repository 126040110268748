import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import moment from 'moment';

const searchParams = {
  surveyTitle: null,
  searchDateFrom: null,
  searchDateTo: null,
  surveyType: null,
  status: 99,
  sort: '',
  count: 20,
  offset: 0,
};

const registerParams = {
  surveyId: null,
  targetMethod: 0,
  staffList: null,
  targetType: 1,
  gender: 0,
  ages: '0',
  agesCheckBox: '',
  professions: '0',
  professionsCheckBox: '',
  staffPositions: '0',
  staffPositionsCheckBox: '',
  prefFlag: 0,
  prefList: [],
  targetCount: 0,
  note: '',
  surveyTitle: '',
  surveyType: 0,
  clientName: '',
  surveyDescription: '',
  surveyBatchFlag: 0,
  surveyThumbnailImage: '',
  surveyQuestionInfos: [
    {
      surveyBodyNumber: null,
      answerDate: '',
      answerDateFrom: '',
      answerDateTo: '',
      questionRequiredFlag: 1,
      questionDescription: '',
      questionImage_1: '',
      questionImage_2: '',
      questionImage_3: '',
      questionImage_4: '',
      questionImage_5: '',
      answerMethod: 0,
      choice_1: '',
      choice_2: '',
      choice_3: '',
      choice_4: '',
      choice_5: '',
      choice_6: '',
      choice_7: '',
      choice_8: '',
      choice_9: '',
      choice_10: '',
      answerResultFlag: 0,
      answerResultGraphType: null,
      aggregationProcessGraphType: [],
      aggregationProcessLabelList: '',
      folder: [{}],
      choices: [{ choice: '' }, { choice: '' }],
      label0: [],
      label1: [],
      label2: [],
      label3: [],
      label4: [],
      batchFlag: 0,
    },
  ],
};

const initialState = {
  surveySearchCount: 0,
  searchParams,
  registerParams,
  surveyList: [],
  staffSurveyList: [],
  surveyStaffAnswerResultData: {},
  targetCount: 0,
  surveyResultData: {},
  csvImportVisible: false,
  csvExportVisible: false,
};

export const surveyListFetch = createAsyncThunk('survey/list', async params => {
  const surveyRes = await fetchRequest(apiUrl.survey.list, params);
  const item = surveyRes?.item?.filter(i => i?.deletedFlag === null || i?.deletedFlag == 0).map(s => {
    return {
      ...s,
      surveyDateFromTo: `${moment(s?.answerDateFrom).format(
        'YYYY/M/D'
      )}〜${moment(s?.answerDateTo).format('YYYY/M/D')}`,
      surveyInfos: s?.surveyDateInfo.map(obj => {
        if (obj?.dateFrom && obj?.dateTo) {
          obj.date = `${moment(obj?.dateFrom).format('YYYY/M/D')}〜${moment(
            obj?.dateTo
          ).format('YYYY/M/D')}`;
        } else {
          obj.date = moment(obj?.date).format('YYYY/M/D');
        }
        return obj;
      }),
    };
  });

  return {
    surveyList: { item },
    surveySearchCount: surveyRes?.searchCount,
  };
});

export const surveyInsert = createAsyncThunk(
  'survey/insert',
  async params => await fetchRequest(apiUrl.survey.insert, params, 'POST')
);

export const surveyUpdate = createAsyncThunk(
  'survey/update',
  async params => await fetchRequest(apiUrl.survey.update, params, 'POST')
);

export const surveyDetailFetch = createAsyncThunk(
  'survey/detail',
  async params => await fetchRequest(apiUrl.survey.detail, params, 'GET')
);

export const surveyDelete = createAsyncThunk(
  'survey/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.survey.delete, params, 'POST');
    dispatch(surveyActions.refresh());
  }
);

export const surveyTargetCount = createAsyncThunk(
  'survey/target_count',
  async params => {
    const surveyTargetCountRes = await fetchRequest(
      apiUrl.survey.targetCount,
      params,
      'GET'
    );
    return {
      targetCount: surveyTargetCountRes?.targetCount,
    };
  }
);

export const staffSurveyListFetch = createAsyncThunk(
  'survey/staff/list',
  async params => {
    const res = await fetchRequest(apiUrl.survey.staffList, params, 'GET');

    //配列内の重複削除
    const staffSurveyList = [];
    const uniqueSurveyIds = new Set();

    res?.item?.forEach((obj) => {
      if (!uniqueSurveyIds.has(obj.surveyId)) {
        staffSurveyList.push(obj);
        uniqueSurveyIds.add(obj.surveyId);
      } else {
        const existingObj = staffSurveyList.find((item) => item.surveyId === obj.surveyId);
        obj.surveyQuestionInfos.forEach((info) => {
          if (!existingObj.surveyQuestionInfos.some((existingInfo) => existingInfo.surveyBodyNumber === info.surveyBodyNumber)) {
            existingObj.surveyQuestionInfos.push(info);
          }
        });
      }
    });

    return {
      staffSurveyList: staffSurveyList?.filter(i => i?.answerStatus !== 2)?.sort((a, b) => { //企業アンケートを上位に表示し、さらにその中でsurveyIdを昇順に並び替え
        if (a.surveyType > b.surveyType) {
          return -1;
        } else if (a.surveyType < b.surveyType) {
          return 1;
        } else {
          return a.surveyId - b.surveyId;
        }
      }),
    }
  }
);

export const staffSurveyAnswerInsert = createAsyncThunk(
  'survey/staff/insert',
  async params => await fetchRequest(apiUrl.survey.staffAnswerInsert, params, 'POST')
);

export const surveyOpen = createAsyncThunk(
  'survey/open',
  async params => await fetchRequest(apiUrl.survey.surveyOpen, params, 'POST')
);

export const surveyStaffAnswerResult = createAsyncThunk(
  'survey/staff/result',
  async params => {
    const res = await fetchRequest(apiUrl.survey.surveyStaffAnswerResult, params, 'GET');
    //表示しないものはfilterする
    return {
      surveyStaffAnswerResultData: {
        ...res?.detail,
      }
    };
  }
);

export const surveyAnswerResult = createAsyncThunk(
  'survey/result',
  async params => {
    const res = await fetchRequest(apiUrl.survey.surveyAnswerResult, params, 'GET');

    return {
      surveyResultData: {
        ...res?.detail,
      }
    };
  }
);

export const surveyDownloadCsvDetail = createAsyncThunk(
  'survey/list/csv-export/detail',
  async info => {
    const csvFile = await fetchRequest(info?.url, info?.params, 'GET', 'csv');
    return csvFile;
  }
);

export const surveySlice = createSlice({
  name: 'survey',
  initialState: initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    countFresh:  (state, { payload }) => {
      state.targetCount = payload;
    },
    setSearch: state => {
      state.searchParams = { ...state.searchParams };
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvImportVisible: (state, { payload }) => {
      state.csvImportVisible = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
  },
  extraReducers: {
    [surveyListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffSurveyListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [surveyDetailFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [surveyTargetCount.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [surveyStaffAnswerResult.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [surveyAnswerResult.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const surveyActions = surveySlice.actions;
export default surveySlice.reducer;
