import React, { useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { Table, Dropdown, Menu, Tooltip } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions } from '../../../../../redux/slice/quote';
import CustomInput from '../../../../../components/customInput';
import CustomFormikInput from '../../../../../components/customInput/customFormikInput';
import Icons from '../../../../../constants/icons';
import './style.scss';

const tragSty = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '8px',
  marginRight: '16px',
};

//プルダウンメニュー
const items = [
  {
    label: '通常',
    key: 1,
    className: 'insert_table--pull_down--sub_menu',
  },
  {
    label: '見出し行',
    key: 2,
    className: 'insert_table--pull_down--sub_menu',
  },
  {
    label: '小計行',
    key: 3,
    className: 'insert_table--pull_down--sub_menu',
  },
];

//並び替え関係
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);
const DragHandle = SortableHandle(() => (
  <Tooltip title="並替え" overlayClassName="insert_table--tool_tip">
    <img
      src={Icons.icon.sortNv}
      alt="sort"
      style={{
        cursor: 'grab',
      }}
    />
  </Tooltip>
));

const InsertTable = ({ getVal }) => {
  const dispatch = useDispatch();
  const { templateBody } = useSelector(state => state.quote);

  //並び替え関係
  const onSortEnd = ({ oldIndex, newIndex }) => {
    let splicData = getVal(templateBody);
    if (oldIndex !== newIndex) {
      let newData = arrayMoveImmutable(
        splicData.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      // console.log('Sorted items: ', splicData);
      newData.map((e, i) => (e.rowIndex = i + 1));
      dispatch(quoteActions.setTemplateBody(newData));
    }
  };
  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = templateBody.findIndex(
      x => x.rowIndex === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  //削除関係
  const handleDelete = i => {
    let splicData = getVal(templateBody);
    const newData = splicData.filter(item => item.rowIndex !== i);
    dispatch(quoteActions.setTemplateBody(newData));
  };

  //add関係
  const handleAdd = () => {
    if (templateBody.length >= 50) {
      return;
    }
    const count = Math.max.apply(
      null,
      templateBody.map(c => c.rowIndex)
    );
    const newData = {
      rowIndex: count + 1,
      rowType: 1,
      detail: '',
      amount: '',
      quantity: '',
      unit: '',
    };
    let splicData = getVal(templateBody);
    dispatch(quoteActions.setTemplateBody([...splicData, newData]));
  };

  const handleCell = i => ({
    colSpan: templateBody[i].rowType == 1 || 2 ? 1 : 0,
  });

  const convertFullWidthNumberToHalfWidth = str => {
    const removedComma = str.replace(/,|，/g, '');
    const ret = removedComma.replace(/[０-９]/g, s => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });

    return Number(ret);
  };

  const columns = [
    {
      title: '',
      width: 72,
      className: 'insert_table--column_order',
      render: r => (
        <div style={tragSty}>
          <DragHandle />
          <Dropdown
            trigger={['click']}
            placement="top"
            arrow={{
              pointAtCenter: true,
            }}
            overlay={() => {
              let idx = templateBody.findIndex(
                item => r.rowIndex === item.rowIndex
              );
              return (
                <Menu
                  selectable
                  defaultSelectedKeys={[templateBody[idx].rowType]}
                  items={items}
                  onSelect={({ key }) => {
                    let splicData = getVal(templateBody);
                    let shallowCopy = splicData.slice();
                    shallowCopy[idx] = {
                      detail: '',
                      amount: '',
                      quantity: '',
                      unit: '',
                      rowIndex: shallowCopy[idx].rowIndex,
                      rowType: Number(key),
                      index: shallowCopy[idx].index,
                    };
                    dispatch(quoteActions.setTemplateBody(shallowCopy));
                  }}
                  style={{
                    padding: '0',
                    width: '88px',
                    height: '82px',
                    background: 'rgba(255, 255, 255)',
                    borderRadius: '3px',
                  }}
                />
              );
            }}
          >
            <a onClick={e => e.preventDefault()}>
              <img
                src={Icons.icon.downNv}
                alt="select"
                style={{ height: '16px' }}
              />
            </a>
          </Dropdown>
        </div>
      ),
    },
    {
      title: '摘要',
      width: 700,
      className: 'insert_table--column',
      render: rowData => (
        <CustomInput
          inputName={`detail${rowData?.rowIndex - 1}`}
          value={rowData?.detail}
          defaultValue={
            templateBody[rowData?.rowIndex - 1]?.rowType == 3 ? '小計' : ''
          }
          disabled={templateBody[rowData?.rowIndex - 1]?.rowType == 3}
          style={{
            inputStyle: {
              textAlign:
                templateBody[rowData?.rowIndex - 1]?.rowType == 3
                  ? 'right'
                  : 'left',
            },
          }}
        />
      ),
      onCell: (_, i) => ({
        colSpan:
          templateBody[i].rowType == 1
            ? 1
            : templateBody[i].rowType == 2
            ? 4
            : 3,
      }),
    },
    {
      title: '数量',
      width: 150,
      className: 'insert_table--column',
      render: rowData => (
        <CustomInput
          inputName={`quantity${rowData.rowIndex - 1}`}
          value={rowData.quantity}
          onBlur={e => {
            if (typeof e.target.value !== 'number') {
              e.target.value = convertFullWidthNumberToHalfWidth(
                e.target.value
              );
            }
          }}
        />
      ),
      onCell: (_, i) => ({
        colSpan:
          templateBody[i].rowType == 1
            ? 1
            : templateBody[i].rowType == 2
            ? 0
            : 0,
      }),
    },
    {
      title: '単位',
      width: 120,
      className: 'insert_table--column',
      render: rowData => (
        <CustomInput
          inputName={`unit${rowData.rowIndex - 1}`}
          value={rowData.unit}
        />
      ),
      onCell: (_, i) => ({
        colSpan:
          templateBody[i].rowType == 1
            ? 1
            : templateBody[i].rowType == 2
            ? 0
            : 0,
      }),
    },
    {
      title: '単価',
      width: 150,
      className: 'insert_table--column',
      render: rowData => (
        <CustomInput
          inputName={`amount${rowData.rowIndex - 1}`}
          value={rowData.amount}
          onBlur={e => {
            if (typeof e.target.value !== 'number') {
              e.target.value = convertFullWidthNumberToHalfWidth(
                e.target.value
              );
            }
          }}
        />
      ),
      onCell: (_, i) => ({
        colSpan:
          templateBody[i].rowType == 1
            ? 1
            : templateBody[i].rowType == 2
            ? 0
            : 1,
      }),
    },
    {
      title: '',
      width: 76,
      className: 'insert_table--column_action',
      render: rowData => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: '12px',
          }}
        >
          <Tooltip title="行追加" overlayClassName="insert_table--tool_tip">
            <img
              src={Icons.icon.plusNv}
              alt="plus"
              style={{ cursor: 'pointer', width: '32px' }}
              onClick={handleAdd}
            />
          </Tooltip>
          <Tooltip title="行削除" overlayClassName="insert_table--tool_tip">
            <img
              src={
                templateBody.length == 1
                  ? Icons.icon.deleteSGr
                  : Icons.icon.deleteSNv
              }
              alt="delete"
              style={{
                cursor: templateBody.length == 1 ? 'not-allowed' : 'pointer',
              }}
              onClick={() => {
                if (templateBody.length == 1) {
                  return;
                }
                handleDelete(rowData.rowIndex);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="insert_table">
      <Table
        pagination={false}
        dataSource={templateBody}
        rowKey="rowIndex"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        columns={columns}
      />
    </div>
  );
};

export default InsertTable;
