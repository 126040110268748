import { memo, useEffect } from "react";
import { useUpdateEffect, useWindowSize } from 'react-use';
import { Avatar, Tooltip, Descriptions } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, pick, toInteger } from 'lodash';
import {
  handlerFormikFieldChange,
  numberFormat,
  numberConvert,
  diffMinute,
  convertToNumber,
  checkIsSp,
} from '../../../../utils/fnUtil';
import { sendAssignMail } from '../../../../redux/slice/matterDate';
import Options from '../../../../constants/options';
import Button from '../../../../components/button';
import Checkbox from '../../../../components/checkbox';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import Icons from '../../../../constants/icons';
import classNames from 'classnames';
import moment from 'moment';

import './style.scss';

const applicationStyle = {
  border: '1px solid #242424',
  borderRadius: '10px',
  boxSizing: 'content-box',
  display: 'inline-block',
  fontSize: '14px',
  height: '20px',
  lineHeight: '20px',
  textAlign: 'center',
  width: '60px',
};

const ShiftAssignCell = memo(({ formik, assign, s, i, j }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { assignDetail, calculationPayFlag } =
    useSelector(state => state.matterDate) ?? {};

  const { dateInfo, matterDetail } = assignDetail ?? {};

  const { width } = useWindowSize();

  const isSp = checkIsSp();

  const {
    punchInDatetime,
    punchOutDatetime,
    overtimePay,
    payCutHourlyPay,
    totalAmount,
    allowanceAmount,
    assignAccountFlag,
  } = assign;

  const { payBaseFlag, hourlyPayBase, dailyPayBase, restMinute } = s;

  useUpdateEffect(() => {
    assignAccountFlag === 0 &&
      handlerFormikFieldChange(
        formik,
        `shift.[${i}].shiftAssign.${j}.assignPayAmount`,
        numberConvert(
          (payBaseFlag == 0
            ? (diffMinute(punchInDatetime, punchOutDatetime) -
                toInteger(restMinute)) *
                (toInteger(hourlyPayBase) / 60) < 0
              ? 0
              : (diffMinute(punchInDatetime, punchOutDatetime) -
                  toInteger(restMinute)) *
                (toInteger(hourlyPayBase) / 60)
            : toInteger(dailyPayBase)) +
            convertToNumber(overtimePay) -
            convertToNumber(payCutHourlyPay) +
            toInteger(allowanceAmount) +
            toInteger(totalAmount),
          calculationPayFlag
        )
      );
  }, [
    punchInDatetime,
    punchOutDatetime,
    overtimePay,
    payCutHourlyPay,
    calculationPayFlag,
    payBaseFlag,
    hourlyPayBase,
    dailyPayBase,
    allowanceAmount,
    totalAmount,
    assignAccountFlag,
    restMinute,
    assign,
    s
  ]);
  //
  // useEffect(() => {
  //   assignAccountFlag === 0 &&
  //   handlerFormikFieldChange(
  //     formik,
  //     `shift.[${i}].shiftAssign.${j}.assignPayAmount`,
  //     numberConvert(
  //       (payBaseFlag == 0
  //         ? (diffMinute(punchInDatetime, punchOutDatetime) -
  //           toInteger(restMinute)) *
  //         (toInteger(hourlyPayBase) / 60) < 0
  //           ? 0
  //           : (diffMinute(punchInDatetime, punchOutDatetime) -
  //             toInteger(restMinute)) *
  //           (toInteger(hourlyPayBase) / 60)
  //         : toInteger(dailyPayBase)) +
  //       convertToNumber(overtimePay) -
  //       convertToNumber(payCutHourlyPay) +
  //       toInteger(allowanceAmount) +
  //       toInteger(totalAmount),
  //       calculationPayFlag
  //     )
  //   );
  // }, [assign, s]);

  const reportInputComponnet = (
    <Checkbox
      checked={assign.reportInputFlag === 1}
      onChange={e =>
        handlerFormikFieldChange(
          formik,
          `shift.[${i}].shiftAssign.[${j}].reportInputFlag`,
          e.target.checked ? 1 : 0
        )
      }
    />
  );

  const baseInfoComponent = (
    <div className="display-linebreak">
      <div>
        <Avatar src={assign.image} size={60} />
        {assign.appliedFlag === 1 && (
          <span style={{ ...applicationStyle }}>応募</span>
        )}
      </div>
      <div>
        <span>
          {/*{Options.codes[*/}
          {/*  assign.assignAccountFlag === 0 ? 'position' : 'assignAccountType'*/}
          {/*].find(*/}
          {/*  a =>*/}
          {/*    a.value ===*/}
          {/*    (assign.assignAccountFlag === 0*/}
          {/*      ? assign.staffPosition*/}
          {/*      : assign.assignAccountFlag)*/}
          {/*)?.label ?? ''}*/}

          {Options.codes['shiftPosition'][assign.shiftPosition] ?? ''}
        </span>
        <span>
          <span>{assign.assignId}</span>
          <span>{assign.assignName}</span>
        </span>
        <span>
          {assign.assignAccountFlag !== 2 &&
            `自社 ${
              assign.assignAccountFlag === 1
                ? '社員'
                : Options.codes.employmentType[assign.employmentType] ?? ''
            }`}
        </span>
        {assign.assignAccountFlag === 0 && (
          <span>
            <span>{`基本時給 ${numberFormat(
              assign.hourlyPay,
              '円',
              '-'
            )}`}</span>
            <span>{`基本日給 ${numberFormat(
              assign.dailyPay,
              '円',
              '-'
            )}`}</span>
          </span>
        )}
        {assign.assignAccountFlag === 2 && (
          <CustomFormikInput
            formik={formik}
            inputName={`shift.[${i}].shiftAssign.${j}.partnerStaffName`}
            placeholder="スタッフ名を入力してください"
            style={{
              inputStyle: {
                width: 260,
              },
            }}
          />
        )}
      </div>
    </div>
  );

  const emptyAssginComponent = (
    <>
      <span>{Options.codes.shiftPosition[assign.shiftPosition]}</span>
      <Button
        text="スタッフ検索"
        className="assign_btn"
        onClick={() =>
          handlerFormikFieldChange(formik, `shift.[${i}]`, {
            ...s,
            staffSearchModalVisible: true,
            shiftPosition: assign.shiftPosition,
            currentAssign: j,
          })
        }
      />
      <Button
        text="社員検索"
        className="assign_btn"
        onClick={() =>
          handlerFormikFieldChange(formik, `shift.[${i}]`, {
            ...s,
            userSearchModalVisible: true,
            shiftPosition: assign.shiftPosition,
            currentAssign: j,
          })
        }
      />
      <Button
        text="協力会社検索"
        className="assign_btn"
        onClick={() =>
          handlerFormikFieldChange(formik, `shift.[${i}]`, {
            ...s,
            partnerSearchModalVisible: true,
            shiftPosition: assign.shiftPosition,
            currentAssign: j,
          })
        }
      />
    </>
  );

  const reportItemCompoennt = (
    <div>
      {Options.codes.reportType.map(r => (
        <span key={r.label}>
          <label className="display-linebreak">{r.label}:</label>
          {assign[r.method] === 0 && assign[r.fieldName]
            ? moment(assign[r.fieldName]).format('YYYY/M/D HH:mm')
            : '-'}
        </span>
      ))}
    </div>
  );

  const punchComponent = (
    <div className="punch-memo-area">
      <div>
        {assign.assignAccountFlag === 0 ? (
          <CustomFormikInput
            formik={formik}
            inputName={`shift.[${i}].shiftAssign.${j}.punchInDatetime`}
            otherErrorInputName={[
              `shift.[${i}].shiftAssign.${j}.punchDatetime`,
            ]}
            label="始業打刻"
            placeholder="00:00"
            className={{
              labelClass:
                s.workStartDatetime &&
                (assign.punchInDate
                  ? moment(`${assign.punchInDate} ${assign.punchInDatetime}:00`)
                  : moment()
                ).isAfter(
                  moment(`${dateInfo?.matterDate} ${s.workStartDatetime}:00`)
                ) &&
                'warning',
            }}
            style={{
              areaStyle: {
                gap: '24px 8px',
              },
            }}
          />
        ) : (
          <div>
            <span className="display-linebreak empty-label">
              {`${'始業打刻'}\t`}
              <span>-</span>
            </span>
          </div>
        )}
        {assign.assignAccountFlag === 0 ? (
          <CustomFormikInput
            formik={formik}
            inputName={`shift.[${i}].shiftAssign.${j}.punchOutDatetime`}
            otherErrorInputName={[
              `shift.[${i}].shiftAssign.${j}.punchDatetime`,
            ]}
            label="終業打刻"
            placeholder="00:00"
            className={{
              labelClass:
                s.workEndDatetime &&
                (assign.punchOutDate
                  ? moment(
                      `${assign.punchOutDate} ${assign.punchOutDatetime}:00`
                    )
                  : moment()
                ).isAfter(
                  moment(`${dateInfo?.matterDate} ${s.workEndDatetime}:00`)
                ) &&
                'warning',
            }}
            style={{
              areaStyle: {
                marginTop: 10,
                gap: '24px 8px',
              },
            }}
          />
        ) : (
          <div>
            <span className="display-linebreak empty-label">
              {`${'終業打刻'}\t`}
              <span>-</span>
            </span>
          </div>
        )}
        {assign.punchMemo && (
          <Tooltip
            title={
              <span className="display-linebreak">{assign.punchMemo}</span>
            }
            placement="bottom"
          >
            <img src={Icons.icon.chatNv} className="punch-memo-image" />
          </Tooltip>
        )}
      </div>
    </div>
  );

  const overtimeComponent = (
    <div>
      <CustomFormikInput
        placeholder="0"
        label="残業時間"
        formik={formik}
        disabled={assign.assignAccountFlag === 1}
        extraOnChange={v => {
          if (
            s.payBaseFlag == 0 &&
            s.hourlyPayBase >= 0 &&
            assign.assignAccountFlag === 0
          ) {
            handlerFormikFieldChange(
              formik,
              `shift.[${i}].shiftAssign.${j}.overtimePay`,

              numberConvert(
                (s.hourlyPayBase / 60) * toInteger(v),
                calculationPayFlag
              )
            );
          }
        }}
        inputName={`shift.[${i}].shiftAssign.${j}.overtime`}
        unit="分"
        style={{
          areaStyle: {
            gap: '24px 8px',
          },
          inputStyle: {
            width: 75,
            textAlign: 'right',
          },
        }}
      />
      <CustomFormikInput
        formik={formik}
        inputName={`shift.[${i}].shiftAssign.${j}.overtimePay`}
        placeholder="0"
        label="残業金額"
        disabled={assign.assignAccountFlag === 1}
        unit="円"
        style={{
          areaStyle: {
            marginTop: 10,
            gap: '24px 8px',
          },
          inputStyle: {
            width: 75,
            textAlign: 'right',
          },
        }}
      />
    </div>
  );

  const payCutComponent = (
    <div>
      <CustomFormikInput
        placeholder="0"
        label="減給時間"
        disabled={assign.assignAccountFlag === 1}
        unit="分"
        formik={formik}
        inputName={`shift.[${i}].shiftAssign.${j}.payCutTime`}
        extraOnChange={v => {
          s.payBaseFlag == 0 &&
            s.hourlyPayBase >= 0 &&
            assign.assignAccountFlag === 0 &&
            handlerFormikFieldChange(
              formik,
              `shift.[${i}].shiftAssign.${j}.payCutHourlyPay`,

              numberConvert(
                (s.hourlyPayBase / 60) * toInteger(v),
                calculationPayFlag
              )
            );
        }}
        style={{
          areaStyle: {
            gap: '24px 8px',
          },
          inputStyle: {
            width: 75,
            textAlign: 'right',
          },
        }}
      />
      <CustomFormikInput
        formik={formik}
        inputName={`shift.[${i}].shiftAssign.${j}.payCutHourlyPay`}
        disabled={assign.assignAccountFlag === 1}
        placeholder="0"
        unit="円"
        label="減給金額"
        style={{
          areaStyle: {
            marginTop: 10,
            gap: '24px 8px',
          },
          inputStyle: {
            width: 75,
            textAlign: 'right',
          },
        }}
      />
    </div>
  );

  const payAmountComponent = (
    <div>
      {assign.assignAccountFlag === 2 ? (
        <CustomFormikInput
          formik={formik}
          inputName={`shift.[${i}].shiftAssign.${j}.assignPayAmount`}
          placeholder="0"
          unit="円"
          style={{
            inputStyle: {
              textAlign: 'right',
              width: 75,
            },
          }}
        />
      ) : (
        <>
          <span>
            {assign.assignAccountFlag === 0 &&
              numberFormat(assign.assignPayAmount, '円', '-')}
          </span>
          <span>{`${assign.assignAccountFlag === 0 ? 'うち' : ''}立替金`}</span>
          <span>{numberFormat(assign.totalAmount, '円', '-')}</span>
        </>
      )}
    </div>
  );

  const actionComponent = (
    <div>
      <div>
        <Tooltip title="削除">
          <Button
            text={isSp ? '削除' : ''}
            className={classNames('assign-delete-btn', {
              'unlock-btn-full': assign.assignAccountFlag === 2,
            })}
            icon={Icons.icon.deleteSWh}
            onClick={() => {
              handlerFormikFieldChange(
                formik,
                `shift.[${i}].shiftAssign.${j}`,
                pick(assign, ['shiftId', 'shiftPosition'])
              );
            }}
          />
        </Tooltip>
        {assign.assignAccountFlag !== 2 && (
          <Tooltip
            title={() =>
              assign.notificationFlag === 1 ? 'アサイン再通知' : 'アサイン通知'
            }
          >
            <Button
              text={
                isSp
                  ? assign.notificationFlag === 1
                    ? 'アサイン\n再通知'
                    : 'アサイン\n通知'
                  : ''
              }
              icon={
                assign.notificationFlag === 1
                  ? Icons.icon.mailOpNv
                  : Icons.icon.mailNv
              }
              disabled={
                !(assign.priority >= 0) || ![2, 3].includes(dateInfo?.status)
              }
              onClick={async () => {
                await dispatch(
                  sendAssignMail({
                    matterDateNumber: [dateInfo.matterDateNumber],
                    assignId: assign.assignId,
                    assignAccountFlag: assign.assignAccountFlag,
                  })
                ).unwrap();

                assign.notificationFlag !== 1 &&
                  handlerFormikFieldChange(
                    formik,
                    `shift.[${i}].shiftAssign.${j}.notificationFlag`,
                    1
                  );
              }}
              style={{ color: '#2b3245' }}
            />
          </Tooltip>
        )}
      </div>
      {[0, 1].includes(assign.assignAccountFlag) && (
        <Button
          text="個別実施詳細"
          disabled={!(assign.priority >= 0)}
          onClick={() =>
            navigate('/matter/implementation', {
              state: {
                matterNumber: matterDetail.matterNumber,
                venueId: matterDetail.venueId,
                matterDateNumber: dateInfo.matterDateNumber,
                shiftId: s.shiftId,
                assignAccountFlag: assign.assignAccountFlag,
                assignId: assign.assignId,
              },
            })
          }
        />
      )}
    </div>
  );

  return isSp ? (
    <div className="detail-table-sp">
      <Descriptions column={4} colon={false}>
        <Descriptions.Item span={2} label="No">
          {j + 1}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="報告書入力">
          {reportInputComponnet}
        </Descriptions.Item>
        {!isNil(assign.assignId) ? (
          <>
            <Descriptions.Item span={4} label="スタッフ">
              {baseInfoComponent}
            </Descriptions.Item>
            <Descriptions.Item span={4} label="通知日時">
              {reportItemCompoennt}
            </Descriptions.Item>
            <Descriptions.Item span={4} label="勤怠">
              {punchComponent}
            </Descriptions.Item>
            <Descriptions.Item span={4} label="残業">
              {overtimeComponent}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="遅刻・早退">
              {payCutComponent}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="支払金額">
              {payAmountComponent}
            </Descriptions.Item>
            <Descriptions.Item span={4}>{actionComponent}</Descriptions.Item>
          </>
        ) : (
          <Descriptions.Item span={4} label="スタッフ" className="empty-assign">
            {emptyAssginComponent}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  ) : (
    <tr>
      <td>{j + 1}</td>
      <td>{reportInputComponnet}</td>
      {!isNil(assign.assignId) ? (
        <>
          <td>{baseInfoComponent}</td>
          <td>{reportItemCompoennt}</td>
          <td>{punchComponent}</td>
          <td>{overtimeComponent}</td>
          <td>{payCutComponent}</td>
          <td>{payAmountComponent}</td>
          <td>{actionComponent}</td>
        </>
      ) : (
        <td colSpan="7" className="empty-assign">
          {emptyAssginComponent}
        </td>
      )}
    </tr>
  );
});

export default ShiftAssignCell;
