import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import Options from '../../constants/options';
import moment from 'moment';

const initialSearchParams = {
  matterStartDate: moment().format('YYYY/MM/DD'),
  matterEndDate: moment().format('YYYY/MM/DD'),
  assignAccountFlag: [],
  matterName: '',
  venueId: null,
  shiftName: '',
  mainManagerUserId: [],
  assignId: '',
  assignName: '',
  status: Options.statusMap.staffAssignStatus.map(s => s.value),
  excessFlag: Options.codes.excessFlag.map(s => s.value),
  offset: 0,
  count: 20,
  sortKey: 'matter_date',
  sortMethod: 'Desc',
};

const initialState = {
  searchParams: initialSearchParams,
  matterStaffAssignList: [],
  venueList: [],
  userList: [],
  loading: false,
};

export const matterStaffAssignInit = createAsyncThunk(
  'matter/staff/assign/init',
  async () => {
    const [venueRes, userRes] = await fetchMultiRequest([
      fetchRequest(apiUrl.venue.list, { status: 1 }),
      fetchRequest(apiUrl.user.list, { status: 1 }),
    ]);

    return {
      venueList: venueRes?.item ?? [],
      userList: userRes?.item ?? [],
    };
  }
);

export const fetchMatteStaffAssignList = createAsyncThunk(
  'matter/staff/assign/list',
  async params => await fetchRequest(apiUrl.matterStaffAssign.list, params)
);

export const matterStaffAssignSlice = createSlice({
  name: 'matterStaffAssign',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    reset: () => initialState,
  },
  extraReducers: {
    [matterStaffAssignInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchMatteStaffAssignList.fulfilled]: (state, { payload }) => {
      state.matterStaffAssignList = payload.item ?? [];
      state.searchCount = payload.total ?? 0;
    },
  },
});

export const matterStaffAssignActions = matterStaffAssignSlice.actions;
export default matterStaffAssignSlice.reducer;
