import './style.scss';
import Header from '../../components/header';
import SideMenu from '../../components/sideMenu';
import TitleBar from '../../components/titleBar';
import StaffList from '../staff/list';
import { Component, createElement, memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Options from '../../constants/options';
import ModalBase from '../../components/modal/modalBase';
import MessageModal from '../../components/modal/messageModal';
import { useSelector } from 'react-redux';
import { store } from '../../redux/store';
import { globalActions } from '../../redux/slice/global';
import { checkIsSp } from '../../utils/fnUtil';
import { checkSession } from '../../api/fetch';

const Main = memo(props => {
  const { icon, title, screen, PageComponent } = props;
  const path = useLocation().pathname;
  const counterSubjects = Options.counterSubject.path[path]
    ? Options.counterSubject.path[path]
    : ''; // 一覧画面のタイトルバーの中に表示している総数や有効数などの表記を取得

  const accountState = useSelector(state => state.account);
  const loginType = useSelector(state => state.loginType.type);
  const navigate = useNavigate();

  useEffect(() => {
    checkSession(accountState.corporateId, accountState.accessToken, loginType)
      .then(j => {
        if (j.code != 0) {
          store.dispatch(globalActions.showSingleModal('ログインしてください'));
          navigate('/login');
        }
      })
      .catch(err => {
        store.dispatch(globalActions.showSingleModal('ログインしてください'));
        navigate('/login');
      });
  }, [])

  if (
    !accountState.corporateId ||
    (!accountState.userId && !accountState.staffId) ||
    !accountState.accessToken
  ) {
    store.dispatch(globalActions.showSingleModal('ログインしてください'));
    navigate('/login');
  }

  const { counter } = useSelector(state => state[screen]) ?? {};

  const listCounter = useSelector(state => state.counter[`${screen}Counter`]);

  const counterStrAry =
    typeof counterSubjects === 'object'
      ? counterSubjects.map((sub, index) => {
          if (checkIsSp()) {
            return [sub, counter?.[index] ?? listCounter[index]];
          } else {
            return `${sub} ${
              counter?.[index]
                ? Number(counter?.[index]).toLocaleString()
                : Number(listCounter[index]).toLocaleString()
            }`;
          }
        })
      : [];

  const scrollToPosition = (top = 0) => {
    const contentArea = document.querySelector('.main--content_area');

    try {
      /**
       * Latest API
       */
      contentArea.scroll({
        top: top,
        left: 0,
        behavior: 'smooth',
      });
    } catch (_) {
      /**
       * Fallback
       */
      contentArea.scrollTo(0, top);
    }
  };

  useEffect(() => {
    if (!checkIsSp()) scrollToPosition();
  }, [window.matchMedia]);

  return (
    <>
      <Header />
      <div className={'main--page_area'}>
        <SideMenu />
        <div className={'main--content_area'}>
          <TitleBar icon={icon} title={title} counter={counterStrAry} />
          <PageComponent />
        </div>
      </div>
    </>
  );
});

export default Main;
