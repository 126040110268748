import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import moment from 'moment';
import { graphConfig } from '../../pages/dashboard/graphConfig';

const initialState = {
  staffSchedule: null,
  staffPay: null,
  staffAssignMatter: [],
  count: 0,
  age: [],
  years: [{}],
  position: [{}],
  monthRanking: [{}],
  gender: [{}],
  columnData: [{}],
  lineGraph: [{}],
  assignSituation: [],
  matterDateStart: moment().format('YYYY/M/D') ?? '',
  matterDateEnd: moment().add(1, 'months').subtract(1, 'days').format('YYYY/M/D') ?? '',
};

export const dashboardInit = createAsyncThunk(
  'dashboard/init',
  async params => {
    const yearMonth = moment().format('YYYY/MM');
    const [
      matterRes,
      assignRes,
      compositionRes,
      implementedRes,
      quoteRes,
      profitRes,
      matterAssignRes,
    ] = await fetchMultiRequest([
      fetchRequest(apiUrl.home.matter),
      fetchRequest(apiUrl.home.assign),
      fetchRequest(apiUrl.home.composition),
      fetchRequest(apiUrl.home.implemented),
      fetchRequest(apiUrl.quote.count),
      fetchRequest(apiUrl.home.profit), // エラーになる
      // fetchRequest(apiUrl.corporate.detail, params), // 案件状況報告
      fetchRequest(
        apiUrl.matter.assignStateList,
        {
          matterDateStart: initialState?.matterDateStart,
          matterDateEnd: initialState?.matterDateEnd
        }),
    ]);
    const lienMaxValue = Math.max.apply(
      null,
      assignRes?.item?.graphData
        ?.map(s => {
          return s?.value;
        })
        .concat(
          assignRes?.item?.graphData?.map(s => {
            return s?.assignValue;
          })
        )
    );
    const clientMaxValue = Math.max.apply(
      null,
      implementedRes?.item?.implementedClients.map(s => {
        return s?.value;
      })
    );
    var column = graphConfig.column;
    var pieLabels = graphConfig.pieLabels;
    var lineConfig = graphConfig.line;
    column.yAxis.max = clientMaxValue + 10;
    lineConfig.yAxis.value.max = lienMaxValue === 0 ? 10 : lienMaxValue + 10;
    lineConfig.yAxis.assignValue.max =
      lienMaxValue === 0 ? 10 : lienMaxValue + 10;
    [...Array(4)].map((_, index) => {
      if (index === 0) {
        pieLabels[index] = pieLabels[index].map((elm, idx) => {
          var label = `${elm.label} ･･･　${compositionRes?.item?.gender[idx]?.value}%`;
          return { ...elm, label: label };
        });
      }
      if (index === 1) {
        pieLabels[index] = pieLabels[index].map((elm, idx) => {
          var label = `${elm.label} ･･･　${compositionRes?.item?.age[idx]?.value}%`;
          return { ...elm, label: label };
        });
      }
      if (index === 2) {
        pieLabels[index] = pieLabels[index].map((elm, idx) => {
          var label = `${elm.label} ･･･　${compositionRes?.item?.years[idx]?.value}%`;
          return { ...elm, label: label };
        });
      }
      if (index === 3) {
        pieLabels[index] = pieLabels[index].map((elm, idx) => {
          var label = `${elm.label} ･･･　${compositionRes?.item?.position[idx]?.value}%`;
          return { ...elm, label: label };
        });
      }
    });

    const assignCount = () => { //折線グラフのアサイン枠数の正しいデータを作成
      let assignCountAry = [];
      matterAssignRes?.item?.map(obj => {
        return assignCountAry.push({
          matterDate: obj?.matterDate,
          count: obj?.matterShiftInfo ?
            obj?.matterShiftInfo?.directorQuantity +
            obj?.matterShiftInfo?.adQuantity +
            obj?.matterShiftInfo?.manQuantity +
            obj?.matterShiftInfo?.womanQuantity +
            obj?.matterShiftInfo?.unisexQuantity
            : 0
        });
      });

      let result = [];
      assignCountAry.forEach((obj) => {
        let existingObj = result.find((o) => o.matterDate === obj.matterDate);
        if (existingObj) {
          existingObj.count += obj.count;
        } else {
          result.push(obj);
        }
      });

      return result;
    };

    //折線グラフの正しいデータを既存の折線グラフに上書き
    let newGraphData = [ ...assignRes?.item?.graphData];
    assignCount().forEach((obj) => {
      const sameDateIndex = assignRes?.item?.graphData?.findIndex(o => o.type === obj.matterDate);
      newGraphData[sameDateIndex].assignValue = obj.count;
    });

    return {
      count: quoteRes?.item?.requestCount ?? 0,
      gender: compositionRes?.item?.gender,
      age: compositionRes?.item?.age,
      position: compositionRes?.item?.position,
      years: compositionRes?.item?.years,
      columnData: implementedRes?.item?.implementedClients,
      monthRanking: implementedRes?.item?.ranking,
      lineGraph: newGraphData.map(l => {
        return {
          ...l,
          time: moment(l?.type).format('YYYY-MM-DD'),
          date: l?.type,
        };
      }),
      assignSituation: assignRes?.item?.ranking.map(l => {
        return {
          ...l,
          time: moment(l?.type).format('YYYY-MM-DD'),
          date: l?.type,
        };
      }),
      profit: profitRes?.item,
      matterSituation: matterRes,
      lineConfig: lineConfig,
      pieLabels: pieLabels,
      column: column,
    };
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [dashboardInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const dashboardActions = dashboardSlice.actions;
export default dashboardSlice.reducer;
