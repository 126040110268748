import './style.scss';
import { useEffect } from 'react';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Copyright from '../../../components/copyright';
import { useNavigate } from 'react-router-dom';
import Options from '../../../constants/options';
import CsvImportModal from '../../../components/modal/csvImportModal';
import CsvExportModal from '../../../components/modal/csvExportModal';
import prefectures from '../pref';
import { useDispatch, useSelector } from 'react-redux';
import {
  clientActions,
  clientDelete,
  clientListFetch,
  clientInit,
} from '../../../redux/slice/client';
import { useFormik } from 'formik';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { setCounter } from '../../../redux/slice/counter';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import options from '../../../constants/options';
import { checkIsSp } from '../../../utils/fnUtil';

const ClientList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    namingCategoryList,
    namingList,
    clientList,
    userList,
    searchParams,
    csvImportVisible,
    csvExportVisible,
  } = useSelector(state => state.client);
  const counterState = useSelector(state => state.counter);
  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      if (values?.prefCode.some(p => p === '')) {
        values.prefCode = [''];
      }
      dispatch(clientActions.saveSearchParams(values));
    },
  });
  const {
    namingClassificationId,
    sortKey: sortColumn,
    sortMethod,
  } = formik.values;
  const searchInfos = {
    inputs: [
      {
        inputType: 'input', //inputのtype
        label: '取引先ID', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'clientId', //DBのカラム名
        placeholder: '取引先IDを入力してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '235px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'input', //inputのtype
        label: '取引先名', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'clientName', //DBのカラム名
        placeholder: '取引先名を入力してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '290px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'select', //inputのtype
        label: '都道府県', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'prefCode',
        showSearch: false,
        mode: 'multiple',
        allowClear: false,
        placeholder: '都道府県を選択してください',
        initialValue: prefectures.map(p => p?.prefecturesCode),
        selectBoxOptions: prefectures.map(p => p?.label),
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '160px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'select', //inputのtype
        showSearch: true,
        mode: 'multiple',
        allowClear: false,
        label: '社内担当者', //ラベルの名前
        initialValue: userList
          ?.filter(u => u.deletedFlag === '0')
          ?.map(p => p?.value),
        selectBoxOptions: userList
          ?.filter(u => u.deletedFlag === '0')
          ?.map(p => p?.label),
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'managerUserName', //DBのカラム名
        placeholder: '社内担当者を選択してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '290px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'select', //inputのtype
        showSearch: false,
        allowClear: true,
        label: '予備項目', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'namingClassificationId',
        initialValue: namingCategoryList.map(p => p?.value),
        selectBoxOptions: namingCategoryList.map(p => p?.label),
        placeholder: '予備項目を選択してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: {
            width: checkIsSp() ? '100%' : '235px',
          },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'select', //inputのtype
        showSearch: false,
        mode: 'multiple',
        allowClear: false,
        label: '', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        disabled: !namingClassificationId,
        inputName: 'namingId',
        initialValue: namingList
          ?.filter(n => n?.namingClassificationId === namingClassificationId)
          .map(p => p?.value),
        selectBoxOptions: namingList
          ?.filter(n => n?.namingClassificationId === namingClassificationId)
          .map(p => `${p?.namingId}:${p?.label}`),
        placeholder: '選択してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: {
            width: checkIsSp() ? '100%' : '291px',
            marginLeft: checkIsSp() ?? '-20px',
            marginTop: checkIsSp() && '-14px',
          },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'select', //inputのtype
        showSearch: false,
        allowClear: false,
        label: 'ステータス', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'status',
        initialValue: options.codes.clientStatus.map(s => s.value),
        selectBoxOptions: options.codes.clientStatus.map(s => s.label),
        selectedSelectBox: formik?.values?.status,
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '150px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
    ],
  };

  const clientListSearchArea = () => (
    <div className={'client_list--input_area'}>
      <CustomFormikInput
        {...searchInfos.inputs[0]}
        formik={formik}
        key={searchInfos.inputs[0].inputName}
      />
      <CustomFormikInput
        {...searchInfos.inputs[1]}
        formik={formik}
        key={searchInfos.inputs[1].inputName}
      />
      <CustomFormikInput
        {...searchInfos.inputs[2]}
        formik={formik}
        key={searchInfos.inputs[2].inputName}
      />
      <CustomFormikInput
        {...searchInfos.inputs[3]}
        formik={formik}
        key={searchInfos.inputs[3].inputName}
      />
      <div className={'client_list--input_area'}>
        <CustomFormikInput
          {...searchInfos.inputs[4]}
          formik={formik}
          key={searchInfos.inputs[4].inputName}
          onChange={value => {
            handlerFormikFieldChange(
              formik,
              searchInfos.inputs[4].inputName,
              value
            );
            handlerFormikFieldChange(
              formik,
              searchInfos.inputs[5].inputName,
              []
            );
          }}
        />
        <CustomFormikInput
          {...searchInfos.inputs[5]}
          formik={formik}
          disabled={!namingClassificationId}
          key={searchInfos.inputs[5].inputName}
        />
      </div>
      <CustomFormikInput
        {...searchInfos.inputs[6]}
        formik={formik}
        key={searchInfos.inputs[6].inputName}
      />
    </div>
  );
  const searchFormInfo = {
    jsx: clientListSearchArea,
  };
  const columnSettings = {
    clientId: { label: '取引先ID', sort: 'asc', dataName: 'clientId' },
    clientName: { label: '取引先名', sort: 'none', dataName: 'clientName' },
    prefCode: { label: '都道府県', sort: 'none', dataName: 'prefCode' },
    managerUserName: {
      label: '社内担当者',
      sort: 'none',
      dataName: 'managerUserName',
    },
    preliminary: { label: '予備項目1', sort: 'none', dataName: 'preliminary' },
    status: { label: 'ステータス', sort: 'none', dataName: 'status' },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    import: {
      icon: Icons.icon.import,
      text: 'CSV取込',
      onClick: () => dispatch(clientActions.setCsvImportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => dispatch(clientActions.setCsvExportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    addButton: {
      props: {
        type: '', //roundを指定するとボタンがまるくなる
        onClick: () => navigate('/client/register'), //ボタンをクリックしたときにfunctionが発火する（urlより優先される）
        url: '', //ボタンをクリックした時の遷移先を指定する「'/main/invoice/list'」
        displayText: true, // trueを指定すると「新規追加」吹き出しが表示される
        disabled: '', //trueを指定するとdisableになる
        // style: {backgroundColor: '#FFFFFF'}, //styleを指定することが可能になる
      },
    },
  };

  const changeSort = e => {
    dispatch(
      clientActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`,
      })
    );
  };

  useEffect(() => {
    dispatch(clientInit());
    const searchBase =document.querySelector('.list_view--search_base');
    searchBase.style.zIndex = 2;
    return () => {
      dispatch(clientActions.saveParam());
      dispatch(clientActions.refresh());
    };
  }, []);
  useEffect(() => {
    dispatch(clientListFetch(searchParams));
  }, [dispatch, searchParams]);
  useEffect(() => {
    dispatch(clientActions.editReset());
    dispatch(clientActions.zipSearchFlag(false));
    dispatch(
      setCounter({
        ...counterState,
        clientCounter: clientList?.topCount ?? [0, 0],
      })
    );
  }, [clientList]);

  return (
    <>
      <div className={'commonPage--base'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'clientId'}
          primaryName={'clientName'}
          apiData={clientList}
          sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(clientActions.saveSearchParams({ count }))
          }
          onChangeOffset={offset => dispatch(clientActions.saveOffset(offset))}
          onDelete={clientId => {
            dispatch(clientDelete({ clientId }));
          }}
        />
        <Copyright />
      </div>

      <CsvImportModal
        importType={'clientList'}
        csvImportVisible={csvImportVisible}
        setCsvImportVisible={() =>
          dispatch(clientActions.setCsvImportVisible(!csvImportVisible))
        }
      />

      <CsvExportModal
        exportType={'clientList'}
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={() =>
          dispatch(clientActions.setCsvExportVisible(!csvExportVisible))
        }
        exportFunction={() => {}}
        searchParams={searchParams}
      />
    </>
  );
};

export default ClientList;
