import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import { globalActions } from './global';

const CategorySearchParams = {
  namingClassificationId: 'Index',
  status: 1,
  sort: 'namingIdAsc',
};
const initialSearchParams = {
  namingClassificationId: [],
  naming: '',
  status: 99,
  offset: 0,
  count: 20,
  sort: 'namingClassificationIdAsc',
  sortColumn: 'namingClassificationId',
  sortMethod: 'Asc',
};
const notUpdateCategory = [
  'Index',
  'Dept',
  'RetireReason',
  'PurchaseMotive',
  'RecruitingMedia',
  'Certification',
];

const initialState = {
  searchParams: initialSearchParams,
  searchWorkParams: initialSearchParams,
  searchCount: 0,
  totalCount: 0,
  activeCount: 0,
  namingCategoryList: [],
  namingList: [],
  editParam: [],
  csvImportVisible: false,
  csvExportVisible: false,
  nextId: 0,
};

export const namingCategoryInit = createAsyncThunk(
  'naming/category/init',
  async () => {
    const init = [
      {
        naming: '初期設定項目',
        namingId: 'Index',
        labelAndValue: 'Index:初期設定項目',
      },
    ];
    const res = await fetchRequest(apiUrl.naming.list, {
      ...CategorySearchParams,
      sort: 'namingIdAsc',
    });
    const item =
      res?.item ??
      [].map(n => {
        return {
          ...n,
          label: n?.naming,
          value: n?.namingId,
          labelAndValue: n?.namingId + n?.naming,
        };
      });
    return {
      namingCategoryList: item.concat(init),
    };
  }
);

export const namingFetch = createAsyncThunk('naming/list', async params => {
  const [namingRes, categoryRes] = await Promise.all([
    fetchRequest(apiUrl.naming.list, params),
    fetchRequest(apiUrl.naming.list, CategorySearchParams),
  ]);

  const item = namingRes?.item?.map(n => {
    const editStatus = n?.namingClassificationId === 'Index' && notUpdateCategory.includes(n?.namingId)
      ? 1
      : n?.status === 1
      ? 0
      : 1;
    const deleteStatus = n?.namingClassificationId === 'Index' && notUpdateCategory.includes(n?.namingId)
      ? 2
      : n?.status === 1
      ? 0
      : 2;
    return {
      ...n,
      namingClassificationIdLabel: `${n?.namingClassificationId}: 
           ${
             categoryRes?.item?.find(
               c => c?.namingId == n?.namingClassificationId
             )?.naming != undefined
               ? categoryRes?.item?.find(
                   c => c?.namingId == n?.namingClassificationId
                 )?.naming
               : '初期設定項目'
           }`,
      namingLabel: n?.namingId + ' : ' + n?.naming,
      editIconState: editStatus,
      deleteIconState: deleteStatus,
      deletedFlag: editStatus,
      status: n?.status === 1 ? '表示' : '削除済',
    };
  });
  return {
    namingList: {
      item,
      categoryItem: categoryRes,
      count: namingRes?.searchCount ?? 0,
      totalCount: namingRes?.totalCount ?? 0,
      activeCount: namingRes?.activeCount ?? 0,
    },
  };
});
export const namingGetId = createAsyncThunk(
  'naming/next_id',
  async params => await fetchRequest(apiUrl.naming.next_id, params)
);

export const namingInsert = createAsyncThunk(
  'naming/insert',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.naming.insert, params, 'POST');
    dispatch(globalActions.showSingleModal(`登録しました`));
  }
);

export const namingUpdate = createAsyncThunk(
  'naming/update',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.naming.update, params, 'POST');
    dispatch(globalActions.showSingleModal(`更新しました`));
  }
);

export const namingDelete = createAsyncThunk(
  'naming/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.naming.delete, params, 'POST');
    dispatch(namingActions.refresh());
  }
);

export const namingSlice = createSlice({
  name: 'naming',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveParam: (state, { payload }) => {
      state.searchWorkParams = { ...state.searchParams };
    },
    resetParam: (state, { payload }) => {
      state.searchParams = {
        ...state.searchWorkParams,
        offset: 0,
        count: 20,
      };
      state.searchWorkParams = initialSearchParams;
    },
    setEdit: state => {
      state.editParam = { ...state.editParam };
    },
    refresh: state => {
      state.searchParams = initialSearchParams;
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvImportVisible: (state, { payload }) => {
      state.csvImportVisible = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
  },
  extraReducers: {
    [namingCategoryInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [namingFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [namingGetId.fulfilled]: (state, { payload }) => ({
      ...state,
      nextId: payload?.nextNamingId ?? 1,
    }),
  },
});

export const namingActions = namingSlice.actions;
export default namingSlice.reducer;
