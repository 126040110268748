import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  quoteFlowFetch,
  quoteActions,
  quoteFlowDelete,
} from '../../../../redux/slice/quote';
import { store } from '../../../../redux/store';
import { globalActions } from '../../../../redux/slice/global';
import ListView from '../../../../components/listView';
import Copyright from '../../../../components/copyright';
import Icons from '../../../../constants/icons';
import './style.scss';
import { checkIsSp } from '../../../../utils/fnUtil';
import AddButton from '../../../../components/addButton';

const QuoteFlowList = () => {
  const dispatch = useDispatch();
  const { flowList } = useSelector(state => state.quote);
  const isSp = checkIsSp();
  const columnSettings = {
    approvalFlowName: {
      label: '承認フロー名',
      sort: 'asc',
      dataName: 'approvalFlowName',
    },
    step1Approver: {
      label: '1次承認',
      sort: 'none',
      dataName: 'step1Approver',
    },
    step2Approver: {
      label: '2次承認',
      sort: 'none',
      dataName: 'step2Approver',
    },
    step3Approver: {
      label: '3次承認',
      sort: 'none',
      dataName: 'step3Approver',
    },
    step4Approver: {
      label: '4次承認',
      sort: 'none',
      dataName: 'step4Approver',
    },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };
  const buttonSettings = {
    addButton: {
      props: {
        url: '/quote/flow/register', //ボタンをクリックした時の遷移先を指定する「'/main/invoice/list'」
        displayText: true, // trueを指定すると「新規追加」吹き出しが表示される
        style: { cursor: 'pointer' }, //styleを指定することが可能になる
      },
    },
  };
  const sortColumn = '';
  const sortMethod = '';

  useEffect(() => {
    const handleAddButton = document.getElementsByClassName('add_button--text');

    if (!isSp) {
      handleAddButton[0].innerHTML = '新規登録';
    }
  });
  useEffect(() => {
    dispatch(quoteFlowFetch());
    dispatch(quoteActions.editReset());
  }, []);

  const apiDataList = {
    count: 0,
    item: flowList,
  };

  // 削除
  const onDelete = approvalFlowId => {
    dispatch(quoteFlowDelete({ approvalFlowId: approvalFlowId })).then(
      ({ error }) => {
        if (error) {
          return;
        } else {
          store.dispatch(globalActions.showSingleModal('削除しました。'));
          dispatch(quoteFlowFetch());
        }
      }
    );
  };

  return (
    <>
      <div className="quote_flow_list">
        {isSp && (
          <div>
            <AddButton
              url="/quote/flow/register"
              displayText={true}
              spListViewMode={isSp}
              style={isSp ? { width: '92%', margin: '72px auto 0' } : {}}
            />
          </div>
        )}

        <div className="commonPage--base">
          <ListView
            columnSettings={columnSettings}
            buttonSettings={isSp ? '' : buttonSettings}
            apiData={apiDataList}
            sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
            primaryName="approvalFlowName"
            primaryKey="approvalFlowId"
            onDelete={onDelete}
            displayNotice={false}
          />
          <Copyright />
        </div>
      </div>
    </>
  );
};

export default QuoteFlowList;
