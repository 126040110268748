import crypto from 'crypto';
import cryptoRandomString from 'crypto-random-string';
import {
  transform,
  isNumber,
  isArray,
  isNaN,
  isObject,
  ceil,
  floor,
  round,
  isEqual,
  pick,
  toInteger,
  toNumber,
  isNil,
} from 'lodash';
import { notification } from 'antd';
import moment from 'moment';
import Icons from '../constants/icons';
import { store } from '../../src/redux/store';
import { globalActions } from '../redux/slice/global';

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getBase64Promise = img => {
  const reader = new FileReader();
  return new Promise(resolve => {
    reader.addEventListener('load', () => resolve(reader.result));
    reader.readAsDataURL(img);
  });
};

export const downloadFile = (content, fileName) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display:none';
  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), content], {
    type: 'text/csv',
  });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.parentNode.removeChild(a);
};

export const createTimeOptions = length =>
  Array.from({ length }, (_, k) => ({
    label: `${k}`.padStart(2, 0),
    value: `${k}`.padStart(2, 0),
  }));

export const createNumberOptions = (length = 151, from = 100) =>
  Array.from({ length }, (_, k) => ({
    label: from + k,
    value: from + k,
  }));

export const handlerFormikFieldChange = (formik, field, value) => {
  formik.setFieldValue(field, value);
  setTimeout(() => formik.setFieldTouched(field, true));
};

export const handlerFormikFieldPaymentChange = (formik, field, value) => {
  if (value !== '9900') {
    formik.setFieldValue(field, value);
    setTimeout(() => formik.setFieldTouched(field, true));
  } else {
    formik.setFieldValue('paymentBankCode', '');
  }
};

export const spliceArray = (orgArray, targetIndex, target) => {
  const temp = [...orgArray];

  temp.splice(targetIndex, 1, target);

  return temp;
};

export const deleteArrayItem = (orgArray, targetIndex) => {
  const temp = [...orgArray];

  temp.splice(targetIndex, 1);

  return temp;
};

export const spliceArrayByMultiValue = (orgArray, targetIndex, values = []) => {
  const temp = [...orgArray];

  temp.splice(targetIndex, values.length, ...values);

  return temp;
};

export const replaceArray = (orgArray, from, target = []) =>
  orgArray.splice(from, target.length, ...target) && orgArray;

export const swapArray = (array, from, to) => {
  const temp = [...array];

  [temp[to], temp[from]] = [temp[from], temp[to]];
  return temp;
};

export const generatePassword = () =>
  cryptoString(cryptoRandomString({ length: 8 }));

export const cryptoString = target =>
  crypto.createHash('sha256').update(target, 'utf8').digest('hex');

export const isAllNullOrAllNotNull = (fileds = []) =>
  fileds.every(f => f) || fileds.every(f => !f);

export const MoneyFormatString = value => {
  if (typeof value !== 'string') {
    return value;
  }
  var preffix = '¥';
  var code = value.indexOf('-') >= 0 ? '-' : '';
  var money = value.replace('-', '').replace('¥', '');

  return `${code}${preffix}${money}`;
};
export const validDatePickChecker = (dateString, type, state, setter) => {
  const anotherField = type === 'from' ? 'to' : 'from';
  if (dateString && state[anotherField]) {
    if (type === 'from') {
      if (moment(dateString).isSameOrBefore(moment(state[anotherField]))) {
        setter({ ...state, [type]: moment(dateString) });
      } else {
        setter({
          ...state,
          [type]: moment(state[anotherField]),
        });
      }
    }
    if (type === 'to') {
      if (moment(state[anotherField]).isSameOrBefore(moment(dateString))) {
        setter({ ...state, [type]: moment(dateString) });
      } else {
        setter({
          ...state,
          [type]: moment(state[anotherField]),
        });
      }
    }
  } else {
    if (dateString) {
      setter({ ...state, [type]: moment(dateString) });
    } else {
      setter({ ...state, [type]: null });
    }
  }
};

export const updateAccountAuth = _account => {
  let account = { ..._account };
  const brandAuth = [
    'tag_flag',
    'tag_category_flag',
    'tagList_flag',
    'notice_flag',
    'notified_flag',
    'notice_list_flag',
    'shop_mst_flag',
    'product_master_flag',
    'best_list_flag',
  ];
  const shopAuth = [
    'coordinate_flag',
    'blog_flag',
    'shop_analysis_flag',
    'brand_analysis_flag',
    'ranking_flag',
    'user_mst_flag',
    'brand_mst_flag',
  ];
  const adminAuth = [
    'payment_reward_flag',
    'reward_rate_setting_flag',
    'payment_reward_confirmation_flag',
    'corporation_master_flag',
  ];
  brandAuth.forEach(menu => {
    account[menu] =
      (account.user_auth === '0' ||
        account.user_auth === '1' ||
        account.user_auth === '2') &&
      account?.[menu] === 1
        ? 1
        : 0;
  });
  shopAuth.forEach(menu => {
    account[menu] =
      (account.user_auth === '0' ||
        account.user_auth === '1' ||
        account.user_auth === '2' ||
        account.user_auth === '3') &&
      account?.[menu] === 1
        ? 1
        : 0;
  });
  adminAuth.forEach(menu => {
    account[menu] =
      (account.user_auth === '0' || account.user_auth === '1') &&
      account?.[menu] === 1
        ? 1
        : 0;
  });
  return account;
};

export const caculateTaxIncludePrice = (sellingPrice, taxRate = 0) =>
  sellingPrice || sellingPrice === '0'
    ? sellingPrice * (1 + taxRate / 100)
    : '';

export const getCheckedIndex = () => {
  const checkAll = document.getElementById('checkAll').checked;
  const checks = document.querySelectorAll('input[id^="check_"]');

  let ret = '';

  if (checkAll) {
    ret = 'all';
  } else {
    ret = [];
    checks.forEach((elm, index) => {
      if (elm.checked) {
        ret.push(index);
      }
    });

    ret.filter(v => v);
  }

  return ret;
};

export const mapColumn = (data, columnSettings, primaryKey) =>
  transform(
    columnSettings,
    (result, columnSetting, column) => {
      !['checkAll', 'statics'].includes(column) &&
        (result[column] = columnSetting.jsx?.(data) ?? data[column]);
      primaryKey && (result.primaryKey ??= data[primaryKey]);
    },
    {}
  );

export const numberFormat = (number, suffix = '', defaultValue = '') =>
  isNumber(number)
    ? `${new Intl.NumberFormat().format(number)}${suffix}`
    : defaultValue;

export const stringNumberFormat = number => {
  const replacedNumber = `${number ?? ''}`?.replaceAll?.(',', '');
  const formatNumber =
    number === '' || number == null
      ? number
      : new Intl.NumberFormat().format(replacedNumber);

  return isNaN(formatNumber) || 'NaN' === formatNumber ? number : formatNumber;
};

export const handlerFormikSubmit = (formik, tabErrorGroup = {}) => {
  notification.destroy();

  formik.handleSubmit();

  if (!formik.isValid) {
    const [primaryError] = Object.keys(formik.errors);

    const nextActiveTab = Object.entries(tabErrorGroup).find(([, fields]) =>
      fields.includes(primaryError)
    )?.[0];

    nextActiveTab && changeActiveTab(nextActiveTab);

    handlerFormikErrors(formik.errors, tabErrorGroup);
  }
};

export const handlerFormikErrors = (errors, tabErrorGroup = {}, activetab) =>
  Object.entries(errors).map(([field, error]) => {
    const nexActiveTab =
      activetab ??
      Object.entries(tabErrorGroup).find(([, fields]) =>
        fields.includes(field)
      )?.[0];

    isArray(error)
      ? error
          .filter(Boolean)
          .forEach(e => handlerFormikErrors(e, tabErrorGroup, nexActiveTab))
      : isObject(error)
      ? handlerFormikErrors(error, tabErrorGroup, nexActiveTab)
      : openNotification(error, () => {
          nexActiveTab && changeActiveTab(nexActiveTab);
        });
  });

export const changeActiveTab = activeTab =>
  store.dispatch(globalActions.changeActiveTab(activeTab));

export const openNotification = (message, onClick) =>
  notification.open({
    className: 'error_notification',
    placement: 'bottom',
    duration: 0,
    closeIcon: (
      <img
        src={Icons.icon.closeRd}
        className="noterror_notification_close_icon"
      />
    ),
    message,
    onClick,
  });

export const dispatchError = (message, navTab) => {
  openNotification(
    message,
    () => navTab && store.dispatch(globalActions.changeActiveTab(navTab))
  );
};

export const splitBy = (array = [], predicate) => {
  const target = array.filter(a => predicate(a));
  const rest = array.filter(a => !predicate(a));

  return [target, rest];
};

const SHA256 = require('crypto-js/sha256');

export const encryptPass = str => {
  const hash1 = SHA256(str);
  const hash2 = SHA256('gw-databank');
  const hash3 = SHA256(`${hash1}${hash2}`);

  return hash3.toString();
};

export const generatePass = () => {
  let ret = new Array(6);

  const strList = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  const numList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const strLength = Math.floor(Math.random() * (ret.length - 1)) + 1;
  const numLength = ret.length - strLength;

  for (let i = 0; i < strLength; i++) {
    const pos = Math.floor(Math.random() * ret.length);
    if (!ret[pos]) {
      ret[pos] = strList[Math.floor(Math.random() * strList.length)];
    } else {
      i--;
    }
  }

  for (let i = 0; i < numLength; i++) {
    const pos = Math.floor(Math.random() * ret.length);
    if (!ret[pos]) {
      ret[pos] = numList[Math.floor(Math.random() * numList.length)];
    } else {
      i--;
    }
  }

  return ret;
};

export const diffMinute = (startTime, endTime) =>
  moment(startTime, 'HH:mm', true).isValid() &&
  moment(endTime, 'HH:mm', true).isValid()
    ? moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'minute')
    : 0;

export const calculateTime = (startTime, endTime, restMinute) => {
  if (
    moment(startTime, 'HH:mm', true).isValid() &&
    moment(endTime, 'HH:mm', true).isValid()
  ) {
    const diff = moment(endTime, 'HH:mm').diff(
      moment(startTime, 'HH:mm').add(restMinute ?? 0, 'm'),
      'minute'
    );
    return `${diff < 0 ? '-' : ''}${moment
      .utc()
      .startOf('day')
      .add({
        minutes: Math.abs(diff),
      })
      .format('HH:mm')}`;
  }
};
export const fullWidth2HalfWidth = src => {
  return src.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

export const checkIsSp = () => {
  return (
    window.matchMedia && window.matchMedia('(max-device-width: 800px)').matches
  );
};

export const isTimeAfter = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }

  return moment(moment(date1).format('HH:mm'), 'HH:mm').isAfter(
    moment(date2, 'HH:mm')
  );
};

export const numberConvert = (number, calculationTaxFlag = 0) => {
  return isNumber(number)
    ? {
        0: ceil,
        1: floor,
        2: round,
      }[calculationTaxFlag](number, 0)
    : '';
};

export const convertToNumber = str => {
  const convert = toNumber(str);
  return isNaN(convert) ? 0 : convert;
};

export const compareMemoObject = (pre, next, fields = []) =>
  fields.every(
    field =>
      pre.formik.values[pre[field]] === next.formik.values[next[field]] &&
      pre.formik.errors[pre[field]] === next.formik.errors[next[field]] &&
      pre.formik.touched[pre[field]] === next.formik.touched[next[field]]
  );

export const convertDatetime = datetime => {
  let ret = datetime?.replace(/\s+/g, '');

  ret = ret?.replaceAll('-', '/');
  ret = ret?.split('');
  ret?.splice(10, 0, ' ');

  for (let i = 0; i < 3; i++) {
    ret?.pop();
  }

  if (ret?.[8] === '0') {
    ret?.splice(8, 1);
  }
  if (ret?.[5] === '0') {
    ret?.splice(5, 1);
  }

  ret = ret?.join('');

  return ret;
};

export const pdfDownload = pdf => {
  const newBlob = new Blob([pdf], { type: 'application/pdf' });

  const objUrl = window.URL.createObjectURL(newBlob);

  let link = document.createElement('a');
  link.href = objUrl;
  link.download = `報告書_${moment().format('YYYYMMDDHHmmss')}.pdf`;
  link.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(objUrl);
  }, 250);
};

export const compareObject = (a, b, fields) =>
  isEqual(pick(a, fields), pick(b, fields));

export const removeFromEnd = (arr = [], maxLength) => {
  while (arr.length > maxLength) {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (isNil(arr[i].assignId)) {
        arr.splice(i, 1);
        break;
      }
    }
  }
  return arr;
};
