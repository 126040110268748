import './style.scss';
import Button from '../../button';
import Options from '../../../constants/options';
import { checkIsSp } from '../../../utils/fnUtil';
import Graphs from '../../graphs';
import { useSelector } from 'react-redux';
import CustomFormikInput from '../../customInput/customFormikInput';
import { useFormik } from 'formik';
import { Modal } from 'antd';

const SurveyResultModal = ({ modalVisible, toggleVisible }) => {
  const { surveyResultData } = useSelector(state => state.survey);

  const formik = useFormik({
    initialValues: {
      surveyAnswerResultGraphType: null,
    },
  });

  const createSelectBoxOptions = initialValue => {
    return initialValue?.map(value => {
      return value === 0
        ? '性別'
        : value === 1
        ? '年代'
        : value === 2
        ? '職業'
        : value === 3
        ? 'スタッフポジション'
        : '居住都道府県';
    });
  };

  return (
    <Modal
      visible={modalVisible}
      style={{
        minWidth: 600,
      }}
      bodyStyle={{ height: 'fit-content', maxHeight: 849 }}
      footer={null}
      centered
      closable={false}
      className="common_modal_base"
      onCancel={() => {
        toggleVisible(false);
        formik.setFieldValue('surveyAnswerResultGraphType', null);
      }}
    >
      <div className={'survey_result_modal--wrap'}>
        <div className={'survey_result_modal--title'}>集計結果</div>

        <div className={'survey_result_modal--survey_info'}>
          {`設問${surveyResultData?.surveyBodyNumber}　${surveyResultData?.questionDescription}`}
        </div>

        <div className={'survey_result_modal--survey_info_select'}>
          <CustomFormikInput
            formik={formik}
            inputType={'select'}
            showSearch={false}
            inputName={'surveyAnswerResultGraphType'}
            initialValue={surveyResultData?.aggregationProcessLabelList
              ?.split(',')
              ?.map(Number)}
            selectBoxOptions={createSelectBoxOptions(
              surveyResultData?.aggregationProcessLabelList
                ?.split(',')
                ?.map(Number)
            )}
            placeholder={'指定なし'}
          />
        </div>

        <div className={'survey_result_modal--graph'}>
          <Graphs
            data={surveyResultData}
            labelType={formik?.values?.surveyAnswerResultGraphType}
          />
        </div>

        <div className={'survey_result_modal--btn'}>
          <Button
            text={'閉じる'}
            style={{
              ...Options.buttonStyles.back,
              width: checkIsSp() ? '100%' : '208px',
            }}
            onClick={() => {
              toggleVisible(false);
              formik.setFieldValue('surveyAnswerResultGraphType', null);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SurveyResultModal;
