
const AssignStateListInputs = {
  search: [
    {
      inputType: 'inputDate',
      label: '実施期間',
      inputName: 'matterDateStart',
      placeholder: 'YYYY/MM/DD',
      className: { areaClass: 'matter_list--date_column' },
      style: { inputStyle: { width: 165 } },
    },
    {
      inputType: 'inputDate',
      label: '　',
      inputName: 'matterDateEnd',
      placeholder: 'YYYY/MM/DD',
      className: { areaClass: 'matter_list--date_column' },
      style: { inputStyle: { width: 165 } },
    },
    {
      inputType: 'input',
      label: '案件No',
      inputName: 'matterNumber',
      placeholder: '案件No.を入力してください',
      className: { areaClass: 'column' },
    },
    {
      inputType: 'input',
      label: '案件名',
      inputName: 'matterName',
      placeholder: '案件名を入力してください',
      className: { areaClass: 'column' },
    },
    {
      inputType: 'select',
      label: '開催地',
      inputName: 'venueId',
      placeholder: '開催地を入力してください',
      className: { areaClass: 'column' },
    },
    {
      inputType: 'select',
      label: '社内案件担当者',
      inputName: 'matterManagerUserId',
      placeholder: '社内案件担当者を入力してください',
      className: { areaClass: 'column' },
    },
    {
      inputType: 'input',
      label: 'シフト名',
      inputName: 'shiftName',
      placeholder: 'シフト名を入力してください',
      className: { areaClass: 'column' },
    },
    {
      inputType: 'select',
      label: 'ステータス',
      inputName: 'status',
      mode: 'multiple',
      allowClear: false,
      showSearch: false,
      initialValue: [0, 1, 2, 3],
      selectBoxOptions: ['シフト未登録', 'アサイン未確定', 'アサイン確定済', '実績報告済'],
      placeholder: 'すべて',
      className: { areaClass: 'column' },
    },
  ]
}

export default AssignStateListInputs;