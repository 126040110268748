import './style.scss';
import './spStyle.scss';
import Icons from '../../constants/icons';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
const { Option } = Select;

const MultiSelectBox = ({
  label,
  labelRequired,
  className,
  inputName,
  placeholder,
  optionValues,
  optionTexts,
  selected,
  onChange,
  onBlur,
  disabled,
  style,
  useValue,
  error,
  touched,
}) => {
  const [selectValues, setSelectValues] = useState(selected ? selected : []);
  const [updateSelectBox, setUpdateSelectBox] = useState(false);

  const shapingTag = async () => {
    let elmParent = await document.getElementsByClassName(
      'ant-select-selection-item-content'
    );
    let elmChild = await document.getElementsByClassName(
      'ant-select-selection-item'
    );
    let elmChildChild = await document.getElementsByClassName(
      'ant-select-selection-overflow-item-rest'
    );
    Array.from(elmParent).map((elm, index) => {
      elm.style.width = '100%';
      elm.style.marginRight = '-4px';
      elm.style.backgroundColor = '#ffffff';
      elm.style.color = '#BCBCBC';
      elm.textContent = placeholder ? placeholder : '選択してください';
    });
    Array.from(elmChild).map((elm, index) => {
      elm.style.backgroundColor = '#ffffff';
      elm.style.border = 'none';
    });
    Array.from(elmChildChild).map((elm, index) => {
      elm.style.backgroundColor = '#ffffff';
    });
  };

  const updateValue = value => {
    shapingTag();
    let newSelectAry = value;
    setSelectValues(newSelectAry);

    if (onChange) {
      onChange(value);
    }
  };

  const deleteValue = e => {
    let deleteValue = e.currentTarget.getAttribute('data-value');
    let newAry = selectValues.slice();
    let index = newAry.indexOf(deleteValue);
    newAry.splice(index, 1);
    setSelectValues(newAry);
    setUpdateSelectBox(!updateSelectBox);

    if (onChange) {
      onChange(newAry);
    }
  };

  useEffect(() => {
    shapingTag();
  });

  useEffect(() => {
    useValue && setSelectValues(selected);
  }, [selected, useValue]);

  return (
    <div
      className={
        className && className.areaClass
          ? `multi_select--select_label_area ${className.areaClass}`
          : 'multi_select--select_label_area'
      }
      style={style && style.areaStyle ? style.areaStyle : {}}
    >
      {label && (
        <div
          className={
            className && className.labelClass
              ? `multi_select--label ${className.labelClass}`
              : 'multi_select--label'
          }
          style={style && style.labelStyle ? style.labelStyle : {}}
        >
          {label}
          {labelRequired && <span>*</span>}
        </div>
      )}

      <div
        className={
          className && className.inputClass
            ? `multi_select--select ${className.inputClass}`
            : 'multi_select--select'
        }
        style={style && style.inputStyle ? style.inputStyle : {}}
      >
        <Select
          id={inputName}
          name={inputName}
          mode="multiple"
          showArrow
          allowClear
          style={
            style && style.inputStyle ? style.inputStyle : { width: '100%' }
          }
          placeholder={placeholder ? placeholder : ''}
          {...(useValue
            ? {
                value: selectValues,
              }
            : {
                defaultValue: selectValues,
              })}
          clearIcon={
            <img
              style={{ width: '30px', height: '30px' }}
              src={Icons.icon.xCircle}
            />
          }
          menuItemSelectedIcon={
            <div className="custom_select--option_check_mark" />
          }
          suffixIcon={
            disabled ? (
              <img src={Icons.icon.downGr} />
            ) : (
              <img src={Icons.icon.downNv} />
            )
          }
          filterOption={false}
          showSearch={false}
          maxTagCount={0}
          tagRender={() => {
            return <></>;
          }}
          defaultActiveFirstOption={false}
          size={'large'}
          onClear={updateValue}
          listHeight={288}
          dropdownStyle={{
            boxShadow: '0 0 8px #2424244D',
            borderRadius: '6px',
          }}
          onChange={value => updateValue((value ?? []).filter(Boolean) ?? null)}
          onBlur={onBlur && onBlur}
          disabled={disabled ? disabled : false}
          key={updateSelectBox}
          className={classNames({
            'custom_input--error': error && touched,
          })}
          getPopupContainer={trigger => trigger.parentElement}
        >
          {optionValues &&
            optionValues.map((value, index) => {
              return (
                <Option value={value} key={index}>
                  {optionTexts[index]}
                </Option>
              );
            })}
        </Select>
        {/*選択したものが表示されるエリア*/}
        {Array.isArray(selectValues) && selectValues.length !== 0 && (
          <div className={'multi_select--display_value_area'}>
            {selectValues.map((value, index) => {
              return (
                <div className={'multi_select--item'} key={index}>
                  <div
                    className={`multi_select--value attribute_${inputName}`}
                    data-value={value}
                  >
                    {optionTexts
                      ? optionTexts[optionValues?.indexOf(value)]
                      : null}
                  </div>
                  <img
                    onClick={deleteValue}
                    data-value={value}
                    src={Icons.icon.xCircle}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectBox;
