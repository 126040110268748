import React, { useEffect, useMemo, useState } from 'react';
import Copyright from '../../components/copyright';
import Foundation from '../../components/foundation';
import FormInfos from '../../constants/formInfos';
import './style.scss';
import Icons from '../../constants/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { encryptPass } from '../../utils/fnUtil';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import { store } from '../../redux/store';
import { globalActions } from '../../redux/slice/global';

const ChangePass = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    if (FormInfos.login.isParams && FormInfos.login.pageName === 'login') {
      const targetInputWithCheckBox = document.getElementsByClassName(
        'custom_input--input_with_check_box_area'
      );
      const targetButton = document.getElementsByClassName('submitButtonArea');
      targetInputWithCheckBox[0].style.marginBottom = '25px';
      targetButton[0].style.marginTop = '45px';
    }

    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const iPadMiniScreen = 800;
  let customButtonSize = {};

  windowDimensions.width > iPadMiniScreen
    ? (customButtonSize = {
        width: '300px',
        height: '44px',
        fontSize: '14px',
        margin: '40px 0',
      })
    : (customButtonSize = {
        width: '100%',
        height: '40px',
        fontSize: '12px',
        margin: '8px 0 32px',
      });
  Object.assign(FormInfos.changePass.button.style, customButtonSize);

  const navigate = useNavigate();
  const location = useLocation();

  const [isValidUrl, setIsValidUrl] = useState(false);
  const [userInfo, setUserInfo] = useState({
    requestFrom: window.location.hostname.match(/backoffice/)
      ? 'backoffice'
      : 'staff',
    corporateId: '',
    mailAddress: '',
  });

  const checkUrlValid = async query => {
    const res = await fetch(`${apiUrl.account.forgotUrlValid}?query=${query}`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {},
    });
    return res.json();
  };

  useMemo(() => {
    const query = new URLSearchParams(location.search).get('query');

    if (query) {
      checkUrlValid(query).then(data => {
        setIsValidUrl(!(data.code === 'L30005' || data.code === 'L20010'));
      });

      const queryArray = atob(query).split(',');
      const cId = atob(queryArray[0]);
      const mail = atob(queryArray[1]);

      setUserInfo({
        ...userInfo,
        corporateId: cId,
        mailAddress: mail,
      });
    } else {
      setIsValidUrl(false);
    }
  }, []);

  const onSubmit = () => {
    store.dispatch(globalActions.setLoading(true));
    const query = new URLSearchParams(location.search).get('query');

    fetch(apiUrl.account.forgotUpdate, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        request_from: userInfo.requestFrom,
      },
      body: JSON.stringify({
        corporateId: userInfo.corporateId,
        mailAddress: userInfo.mailAddress,
        newPassword: encryptPass(document.getElementById('confirm_pass').value),
        query: query
      }),
    })
      .then(() => {
        store.dispatch(globalActions.setLoading(false));
        navigate(`/login?corporateId=${userInfo.corporateId}`);
        store.dispatch(globalActions.showSingleModal('変更しました'));
      })
      .catch(() => {
        store.dispatch(globalActions.setLoading(false));
        navigate('/login');
        store.dispatch(globalActions.showSingleModal('失敗しました'));
      });
  };

  return (
    <div className={'change_pass--background'}>
      {!isValidUrl ? (
        <div className={'change_pass--session_expired_area'}>
          <div className={'change_pass--title_mark_area'}>
            <img src={Icons.icon.alertGr} />
            <span>ページの有効期限が切れています。</span>
          </div>

          <div className={'change_pass--text_area'}>
            <span>
              お手数ですが、
              <Link
                style={{ color: '#D80245', borderBottom: '1px solid #D80245' }}
                to={`/forget-password/`}
              >
                こちら
              </Link>
              {' からもう一度'}
            </span>
            <span>からパスワード再設定の手続きをお願いします。</span>
          </div>
        </div>
      ) : (
        <>
          <Foundation formInfo={FormInfos.changePass} onSubmit={onSubmit} />

          <Copyright />
        </>
      )}
    </div>
  );
};

export default ChangePass;
