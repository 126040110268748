import { memo, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import { checkIsSp } from '../../../../utils/fnUtil';
import './style.scss';

const MatterInfo = memo(({ formik, stateId }) => {
  const { state } = useLocation();

  const isSp = checkIsSp();

  const {
    quoteList,
    clientList,
    venueList,
    userList,
    matterCategoryList,
    matterClassList,
  } = useSelector(state => state.matter);

  const onChangeQuote = v => {
    const {
      quoteNumber = null,
      matterName = '',
      clientId = null,
      matterManagerUserId = null,
      clientManagerName = null,
      matterCategory = null,
      matterCategoryOther = '',
      matterIndustryPrimary = null,
      matterIndustryPrimaryOther = '',
      matterIndustrySecondary = null,
      preliminary1 = null,
      preliminary2 = null,
      preliminary3 = null,
      preliminary4 = null,
      preliminary5 = null,
      note = '',
    } = quoteList.find(quote => quote.quoteNumber == v) ?? {};

    formik.setValues({
      ...formik.values,
      quoteNumber,
      matterName,
      clientId,
      matterManagerUserId,
      salesManagerUserId: matterManagerUserId,
      clientManagerUserName: clientManagerName,
      matterCategory,
      matterCategoryOther,
      matterIndustryPrimary,
      matterIndustryPrimaryOther,
      matterIndustrySecondary,
      preliminary1,
      preliminary2,
      preliminary3,
      preliminary4,
      preliminary5,
      note,
    });
  };

  const { matterNumber, quoteNumber, matterIndustryPrimary, clientId } =
    formik.values;

  const selectDisabled = quoteNumber === null && clientId === null;

  const disabled =
    (quoteNumber === null && clientId === null) || quoteNumber !== null;

  const middleClassList =
    matterClassList.find(
      largeClass => largeClass.largeClassId === matterIndustryPrimary
    )?.middleClass ?? [];

  const style = {
    areaStyle: {
      maxWidth: isSp ? '100%' : 979,
    },
    labelStyle: {
      width: 170,
      textAlign: 'right',
    },
  };

  const targetVenueList = venueList.filter(v =>
    (clientList.find(c => c.clientId === clientId)?.venueIdList ?? []).includes(
      v.venueId
    )
  );

  useEffect(() => {
    if (state?.quoteNumber) {
      onChangeQuote(state.quoteNumber);
    }
  }, []);

  return (
    <div className={'register_base--detail_info_tab_page'}>
      {[
        {
          inputType: 'label',
          label: '案件No',
          inputName: 'matterNumber',
          disabled: true,
          className: {
            areaClass: stateId && isSp && 'matter_register--matter_number_field',
          },
          style: {
            ...style,
            areaStyle: {
              ...style.areaStyle,
              display: matterNumber ? '' : 'none',
            },
            labelStyle: {
              ...style.labelStyle,
              pointerEvents: 'none',
              width: stateId && isSp ? 'auto' : '170px'
            },
          },
        },
        {
          inputType: 'select',
          label: '見積書No',
          inputName: 'quoteNumber',
          placeholder: '見積書を選択してください',
          initialValue: quoteList.map(quote => quote.quoteNumber),
          selectBoxOptions: quoteList.map(
            quote => `${quote.quoteNumber}:${quote.matterName}`
          ),
          onChange: onChangeQuote,
          disabled: (quoteNumber === null && clientId !== null) || matterNumber,
          style: {
            ...style,
            areaStyle: {
              maxWidth: 610,
            },
          },
        },
        {
          inputType: 'select',
          label: '取引先',
          labelRequired: true,
          inputName: 'clientId',
          initialValue: clientList.map(client => client.clientId),
          selectBoxOptions: clientList.map(
            client => `${client.clientId}:${client.clientName}`
          ),
          placeholder: '取引先を選択してください',
          disabled: quoteNumber !== null,
          extraOnChange: v => {
            const { managerUserId, clientManagerName } =
              clientList.find(c => c.clientId === v) ?? {};

            // formik.setFieldValue('venueId', null);
            formik.setFieldValue('matterManagerUserId', managerUserId ?? null);
            formik.setFieldValue('salesManagerUserId', managerUserId ?? null);
            formik.setFieldValue(
              'clientManagerUserName',
              clientManagerName ?? ''
            );
          },
          style,
        },
        {
          inputType: 'select',
          label: '開催地',
          labelRequired: true,
          inputName: 'venueId',
          initialValue: venueList.map(venue => venue.venueId),
          selectBoxOptions: venueList.map(
            venue => `${venue.venueId}:${venue.venueName}`
          ),
          placeholder: '開催地を選択してください',
          style,
          // disabled: selectDisabled,
        },
        {
          label: '案件名',
          labelRequired: true,
          inputName: 'matterName',
          placeholder: '案件名を入力してください',
          disabled,
          style,
        },
        {
          union: true,
          inputs: [
            {
              inputType: 'select',
              label: '案件カテゴリ',
              labelRequired: true,
              inputName: 'matterCategory',
              placeholder: '案件種類を選択してください',
              initialValue: matterCategoryList.map(
                category => category.namingId
              ),
              selectBoxOptions: matterCategoryList.map(
                category => category.naming
              ),
              disabled,
              style: {
                ...style,
                areaStyle: isSp ? { maxWidth: '100%' } : { maxWidth: 979 },
              },
            },
            {
              inputName: 'matterCategoryOther',
              placeholder: '案件カテゴリを入力してください',
              disabled:
                disabled ||
                matterCategoryList.find(
                  m => m.namingId === formik.values.matterCategory
                )?.naming !== 'その他',
              style: {
                ...style,
                areaStyle: isSp ? { maxWidth: '100%' } : { maxWidth: 979 },
              },
            },
          ],
        },
        {
          union: true,
          inputs: [
            {
              inputType: 'select',
              label: '業界',
              labelRequired: true,
              inputName: 'matterIndustryPrimary',
              placeholder: '大分類を選択してください',
              initialValue: matterClassList.map(
                largeClass => largeClass.largeClassId
              ),
              selectBoxOptions: matterClassList.map(
                largeClass => largeClass.largeClassName
              ),
              disabled,
              style,
              extraOnChange: () =>
                formik.setFieldValue('matterIndustrySecondary', null),
            },
            matterIndustryPrimary === '017'
              ? {
                  inputName: 'matterIndustryPrimaryOther',
                  placeholder: '大分類を入力してください',
                  disabled: disabled,
                  style,
                }
              : {
                  inputType: 'select',
                  inputName: 'matterIndustrySecondary',
                  placeholder: '中分類を選択してください',
                  initialValue: middleClassList.map(
                    middleClass => middleClass.middleClassId
                  ),
                  selectBoxOptions: middleClassList.map(
                    middleClass => middleClass.middleClassName
                  ),
                  disabled: disabled || !formik.values.matterIndustryPrimary,
                  style,
                },
          ],
        },
        {
          inputType: 'select',
          label: '案件担当者',
          labelRequired: true,
          inputName: 'matterManagerUserId',
          placeholder: '案件担当者を選択してください',
          initialValue: userList.map(user => +user.userId),
          selectBoxOptions: userList.map(
            user =>
              `${user.userId}:${user.name.lastName} ${user.name.firstName}`
          ),
          disabled: selectDisabled,
          usePopContainer: false,
          style,
        },
        {
          inputType: 'select',
          label: '営業担当者',
          labelRequired: true,
          inputName: 'salesManagerUserId',
          placeholder: '営業担当者を選択してください',
          initialValue: userList.map(user => +user.userId),
          selectBoxOptions: userList.map(
            user =>
              `${user.userId}:${user.name.lastName} ${user.name.firstName}`
          ),
          disabled: selectDisabled,
          style,
        },
        {
          label: '取引先担当者',
          inputName: 'clientManagerUserName',
          placeholder: '取引先担当者を入力してください',
          disabled,
          style,
        },
      ].map((inputInfo, i) =>
        inputInfo.union ? (
          <div
            className="input_union"
            key={i}
            style={
              isSp ? { width: '100%', height: 'auto', display: 'flex', flexFlow: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', rowGap: '8px'} : { maxWidth: 979 }
            }
          >
            {inputInfo.inputs.map(input => (
              <CustomFormikInput
                {...input}
                formik={formik}
                key={input.inputName}
              />
            ))}
          </div>
        ) : (
          <CustomFormikInput
            {...inputInfo}
            formik={formik}
            key={inputInfo.inputName}
          />
        )
      )}
    </div>
  );
});

export default MatterInfo;
