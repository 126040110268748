import './style.scss';
import ModalBase from '../modalBase';
import Button from '../../button';
import Options from '../../../constants/options';
import { checkIsSp } from '../../../utils/fnUtil';
import {message} from "antd";

const ConfirmModal = ({
  message, onOk, onCancel, modalVisible, title, onOkMessage = null
}) => {
  return (
    <ModalBase
      dialogStyle={{ width: checkIsSp() ? 'calc(100% - 64px)':'calc(600px - 100px)'}}
      modalVisible={modalVisible}
    >
      <div className={'delete_modal--wrap'}>
        <div className={'delete_modal--text_area'}>
          <p style={{
            margin: '0',
            fontSize: '20px',
            fontWeight: 'bold'
          }}>
            {title}
          </p>
          <span className={'delete_modal--text_message'}>
            {message}
          </span>
        </div>
        <div className={'delete_modal--button_area'}>
          <Button
            text={'戻る'}
            style={{ ...Options.buttonStyles.back, width: checkIsSp() ? '100%':'208px' }}
            onClick={onCancel}
          />
          <Button
            text={onOkMessage ? onOkMessage : '登録'}
            style={{
              ...Options.buttonStyles.submit,
              width: checkIsSp() ? '100%':'208px',
            }}
            onClick={onOk}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default ConfirmModal;
