import './style.scss';

const Copyright = ({ style }) => {
  return (
    <p className={'copyright--contents'} style={style ? style : {}}>
      ©︎ GOODWAVE All right reserved.
    </p>
  );
};

export default Copyright;
