import './style.scss';
import ModalBase from '../modalBase';
import Button from '../../button';
import { useState } from 'react';

const MessageModal = ({ message, text, modalVisible, toggleVisible }) => {

  const closeModal = () => {
    toggleVisible(false);
  };
  return (
    <ModalBase
      dialogStyle={{
        display: 'flex',
        flexFlow: 'column',
        gap: '64px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '500px'
      }}
      modalVisible={modalVisible}
      closeModalFunction={closeModal}
    >
      <p className={'message_modal--message_area'}>{message}</p>

      <p className={'message_modal--text_area'}>{text}</p>
      
      <Button
        text={'戻る'}
        style={{
          width: '240px',
          height: '44px',
          borderRadius: '6px',
          backgroundColor: '#E9F2FC',
          color: '#486083',
          fontSize: '16px',
          margin: '0 0',
          userSelect: 'none',
        }}
        onClick={closeModal}
      />
    </ModalBase>
  );
};

export default MessageModal;
