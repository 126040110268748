import { memo } from 'react';
import ReportItem from './item/reportItem';

const ReportConfirm = memo(({ formik }) => {
  return (
    <div className="implementation_tab_content">
      {[
        {
          item: '',
          confirmItem:
            formik.values.notificationFlag === 1
              ? 'メール再送信'
              : 'メール送信',
        },
        {
          item: '週確認',
          confirmItem: '週報告確認',
          flag: 'staffWeekMethodFlag',
          dateTime: 'staffWeekDatetime',
          planDateTimeItem: 'staffWeekPlanDatetime',
          confirmUserItem: 'managerWeekUserId',
          confirmDateTimeItem: 'managerWeekDatetime',
        },
        {
          item: '前日報告',
          confirmItem: '前日報告確認',
          flag: 'staffPreviousDayMethodFlag',
          dateTime: 'staffPreviousDayDatetime',
          planDateTimeItem: 'staffPreviousDayPlanDatetime',
          confirmUserItem: 'managerPreviousDayUserId',
          confirmDateTimeItem: 'managerPreviousDayDatetime',
        },
        {
          item: '起床報告',
          confirmItem: '起床報告確認',
          flag: 'staffGettingUpMethodFlag',
          dateTime: 'staffGettingUpDatetime',
          planDateTimeItem: 'staffGettingUpPlanDatetime',
          confirmUserItem: 'managerGettingUpUserId',
          confirmDateTimeItem: 'managerGettingUpDatetime',
          format: 'HH:mm',
        },
        {
          item: '出発報告',
          confirmItem: '当日出発報告確認',
          flag: 'staffDepartureMethodFlag',
          dateTime: 'staffDepartureDatetime',
          planDateTimeItem: 'staffDeparturePlanDatetime',
          confirmUserItem: 'managerDepartureUserId',
          confirmDateTimeItem: 'managerDepartureDatetime',
          format: 'HH:mm',
        },
        {
          item: '到着報告',
          confirmItem: '当日到着報告確認',
          flag: 'staffArriveMethodFlag',
          dateTime: 'staffArriveDatetime',
          punchDateTimeItem: 'punchInDatetime',
          planDateTimeItem: 'staffArrivePlanDatetime',
          confirmUserItem: 'managerArriveUserId',
          confirmDateTimeItem: 'managerArriveDatetime',
          format: 'HH:mm',
        },
        {
          item: '終業報告',
          confirmItem: '当日終業報告確認',
          flag: 'staffEndMethodFlag',
          dateTime: 'staffEndDatetime',
          punchDateTimeItem: 'punchOutDatetime',
          planDateTimeItem: 'staffEndPlanDatetime',
          confirmUserItem: 'managerEndUserId',
          confirmDateTimeItem: 'managerEndDatetime',
          format: 'HH:mm',
        },
      ].map(option => (
        <ReportItem key={option.label} {...option} formik={formik} />
      ))}
    </div>
  );
});

export default ReportConfirm;
