import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { pdfDownload } from '../../utils/fnUtil';
import { globalActions } from './global';
import { apiUrl } from '../../api/apiUrl';
import moment from 'moment';

const initialSearchParams = {
  matterNumber: '',
  quoteNumber: '',
  matterName: '',
  matterStartDate: '',
  matterEndDate: '',
  clientId: null,
  venueId: null,
  matterManagerUserId: null,
  salesManagerUserId: null,
  preliminaryIndex: null,
  preliminary: [],
  matterStatus: [],
  offset: 0,
  count: 20,
  sortKey: 'matter_number',
  sortMethod: 'Desc',
};

const initialQuoteDetail = {
  quoteNumber: null,
  clientName: null,
  quoteAmount: null,
  quoteAmountBalance: null,
  matterQuote: [],
};

const initialState = {
  searchParams: initialSearchParams,
  matterList: [],
  clientList: [],
  venueList: [],
  userList: [],
  namingList: [],
  preliminaryIndexList: [],
  preliminaryList: [],
  quoteList: [],
  matterCategoryList: [],
  matterClassList: [],
  searchCount: 0,
  csvExportVisible: false,
  matterDetail: null,
  matterQuoteDetail: initialQuoteDetail,
  matterReportDetail: null,
  matterFormatList: [],
  purchaseMotiveList: [],
  counter: [0, 0],
  loading: false,
};

const preliminaryList = [...Array(5)].map(
  (_, i) => `PromotionPreliminary${i + 1}`
);

export const matterInit = createAsyncThunk('matter/init', async () => {
  const [
    clientRes,
    venueRes,
    userRes,
    preliminaryIndexRes,
    preliminaryDetailRes,
  ] = await fetchMultiRequest([
    fetchRequest(apiUrl.client.list, { status: 1 }),
    fetchRequest(apiUrl.venue.list, { status: 1 }),
    fetchRequest(apiUrl.user.list, { status: 1 }),
    fetchRequest(apiUrl.naming.list, {
      namingClassificationId: 'INDEX',
      namingId: preliminaryList,
      sort: 'namingIdAsc',
      staus: 1,
    }),
    fetchRequest(apiUrl.naming.list, {
      namingClassificationId: preliminaryList,
      sort: 'namingIdAsc',
      staus: 1,
    }),
  ]);

  return {
    clientList: clientRes?.item ?? [],
    venueList: venueRes?.item ?? [],
    userList: userRes?.item ?? [],
    preliminaryIndexList: preliminaryIndexRes?.item ?? [],
    preliminaryList: preliminaryDetailRes?.item ?? [],
  };
});

export const matterDetailInit = createAsyncThunk(
  'matter/detail/init',
  async () => {
    const [
      quoteRes,
      clientRes,
      venueRes,
      userRes,
      preliminaryIndexRes,
      namingRes,
      classRes,
    ] = await fetchMultiRequest([
      fetchRequest(apiUrl.quote.list),
      fetchRequest(apiUrl.client.list, { status: 1 }),
      fetchRequest(apiUrl.venue.list, { status: 1 }),
      fetchRequest(apiUrl.user.list, { status: 1 }),
      fetchRequest(apiUrl.naming.list, {
        namingClassificationId: 'INDEX',
        namingId: preliminaryList,
        sort: 'namingIdAsc',
        status: 1,
      }),
      fetchRequest(apiUrl.naming.list, {
        namingClassificationId: [...preliminaryList, 'PromotionCategory'],
        status: 1,
      }),
      fetchRequest(apiUrl.matter.classList),
    ]);

    const namingItem = namingRes?.item ?? [];

    return {
      quoteList: quoteRes?.item ?? [],
      clientList: clientRes?.item ?? [],
      venueList: venueRes?.item ?? [],
      userList: userRes?.item ?? [],
      matterCategoryList: namingItem.filter(
        naming => naming.namingClassificationId === 'PromotionCategory'
      ),
      preliminaryIndexList: preliminaryIndexRes?.item ?? [],
      preliminaryList: namingItem.filter(naming =>
        /PromotionPreliminary/.test(naming.namingClassificationId)
      ),
      matterClassList: classRes?.item ?? [],
    };
  }
);

export const fetchMatterList = createAsyncThunk(
  'matter/list',
  async params => await fetchRequest(apiUrl.matter.list, params)
);

export const fetchMatterDetail = createAsyncThunk(
  'matter/detail',
  async params => await fetchRequest(apiUrl.matter.detail, params)
);

export const fetchMatterReportDetail = createAsyncThunk(
  'matter/report/detail',
  async params => {
    const [detailRes, listRes, motiveRes] = await fetchMultiRequest([
      fetchRequest(apiUrl.matter.reportDetail, params),
      fetchRequest(apiUrl.matter.list, {
        sortMethod: 'Desc',
      }),
      fetchRequest(apiUrl.naming.list, {
        namingClassificationId: 'PurchaseMotive',
        status: 1,
      }),
    ]);

    return {
      matterReportDetail: convertReportDetail(detailRes),
      matterFormatList: (listRes?.item ?? []).filter(
        matter => matter.matterNumber !== params.matterNumber
      ),
      purchaseMotiveList: motiveRes?.item ?? [],
    };
  }
);

export const fetchMatterReportPdf = createAsyncThunk(
  'matter/report/pdf',
  async params => {
    const pdf = await fetchRequest(
      apiUrl.matter.reportPdf,
      params,
      'GET',
      'blob'
    );

    pdfDownload(pdf);
  }
);

export const fetchReportFormat = createAsyncThunk(
  'matter/report/format',
  async params => {
    const detailRes = await fetchRequest(apiUrl.matter.reportDetail, params);

    return convertReportDetail(detailRes, true);
  }
);

export const updateMatter = createAsyncThunk(
  'matter/update',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matter[params.updateType], params, 'POST');

    dispatch(
      globalActions.showSingleModal(
        `${params.updateType === 'update' ? '更新' : '登録'}しました`
      )
    );
  }
);

export const updateMatterReport = createAsyncThunk(
  'matter/update/report',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matter.reportUpdate, params, 'POST');

    dispatch(globalActions.showSingleModal('更新しました'));
  }
);

export const deleteMatter = createAsyncThunk(
  'matter/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matter.delete, params, 'POST');

    dispatch(matterActions.refresh());
  }
);

export const updateLock = createAsyncThunk(
  'matter/lock/update',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matter.updateLock, params, 'POST');

    dispatch(matterActions.refresh());
  }
);

export const updateReportSubmit = createAsyncThunk(
  'matter/report/submit/update',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matter.updateReportSubmit, params, 'POST');

    dispatch(matterActions.refresh());
  }
);

export const imageUpload = createAsyncThunk(
  'matter/image/update',
  async image => {
    const uploadRes = await fetchRequest(
      apiUrl.other.imageUpload,
      {
        contentFlag: 0,
        image,
      },
      'POST'
    );

    return uploadRes?.imageUrl;
  }
);

export const fetchMatterQuoteList = createAsyncThunk(
  'matter/quote/list',
  async params => {
    const { matterNumber, updateType, ...rest } = params;
    const quoteRes = await fetchRequest(apiUrl.matter.quoteList, rest);

    const {
      quoteNumber,
      quoteAmount,
      clientName,
      quoteAmountBalance,
      matterQuote = [],
    } = quoteRes?.item ?? {};

    return {
      quoteNumber,
      quoteAmount,
      clientName,
      quoteAmountBalance,
      matterQuote: matterQuote.filter(
        quote =>
          params.updateType === 'create' ||
          quote.matterNumber !== params.matterNumber
      ),
    };
  }
);

export const matterSlice = createSlice({
  name: 'matter',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    clearReferrerMatter: state => ({
      ...state,
      referrerMatter: null,
    }),
    clearMatterQuoteDetail: state => ({
      ...state,
      matterQuoteDetail: initialQuoteDetail,
    }),
    matterDetailClear: state => {
      state.matterDetail = null;
      state.matterQuoteDetail = initialQuoteDetail;
      state.matterReportDetail = null;
    },
    updateQuoteAmount: (state, { payload }) => {
      state.matterQuoteDetail.quoteAmountBalance = payload;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [matterInit.fulfilled]: (state, { payload }) => ({ ...state, ...payload }),
    [fetchMatterList.fulfilled]: (state, { payload }) => {
      const searchCount = payload.searchCount ?? 0;
      state.matterList = (payload.item ?? []).map(m => ({
        ...m,
        deleteIconState: m.deleteLockFlag,
      }));
      state.searchCount = searchCount;
      state.counter = [payload.totalCount ?? 0, searchCount];
    },
    [fetchMatterDetail.fulfilled]: (state, { payload }) => {
      const detail = payload.item ?? {};
      state.matterDetail = {
        ...detail,
        matterDate: (detail.matterDate ?? []).map(date => ({
          ...date,
          matterStartDatetime: moment(date.matterStartDatetime).format('HH:mm'),
          matterEndDatetime: moment(date.matterEndDatetime).format('HH:mm'),
        })),
      };
    },
    [fetchMatterReportDetail.pending]: state => {
      state.loading = true;
    },
    [fetchMatterReportDetail.rejected]: state => {
      state.loading = false;
    },
    [fetchMatterReportDetail.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [fetchReportFormat.fulfilled]: (state, { payload }) => ({
      ...state,
      matterReportDetail: {
        ...state.matterReportDetail,
        ...payload,
      },
    }),
    [matterDetailInit.pending]: state => {
      state.loading = true;
    },
    [matterDetailInit.rejected]: state => {
      state.loading = false;
    },
    [matterDetailInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [fetchMatterQuoteList.fulfilled]: (state, { payload }) => {
      state.matterQuoteDetail = payload;
    },
  },
});

const convertReportDetail = (detailRes, applyFormat) => {
  const {
    info,
    reportPage,
    folder,
    staffVoice,
    guestVoice,
    guestAttribute,
    free,
    ...rest
  } = detailRes?.item ?? {};

  //流入ルート
  const ir = guestVoice?.inflowRoute;
  const iro = ir
    ? ir.split(',').reduce((result, item) => {
        const [key, value] = item.split(':');
        result[key] = value === 'null' ? null : parseInt(value, 10);
        return result;
      }, {})
    : {};

  // //購入動機
  const m = guestVoice?.motive;
  const mo = m
    ? m.split(',').reduce((obj, str) => {
        const [key, value] = str.split(':');
        obj[`staffMotiveId_${key}`] =
          value === 'null' ? null : parseInt(value, 10);
        return obj;
      }, {})
    : {};

  //継続購入可否
  const r = guestVoice?.repeating;
  const ro = r
    ? r.split(',').reduce((result, item) => {
        const [key, value] = item.split(':');
        result[key] = value === 'null' ? null : parseInt(value, 10);
        return result;
      }, {})
    : {};

  return {
    ...(!applyFormat && { ...rest }),
    ...(info && { info }),
    reportPage,
    folder,
    staffVoice,
    ...(guestVoice && {
      guestVoice: {
        ...guestVoice,
        // motive: guestVoice.motive?.split(',') ?? null,
        reportTotalFlag: guestVoice.reportTotalFlag ?? 1,
        staffTotalFlag: guestVoice.staffTotalFlag ?? 1,
        dmRate: iro?.[0] ?? null,
        passingRate: iro?.[1] ?? null,
        snsRate: iro?.[2] ?? null,
        dailyUseRate: iro?.[3] ?? null,
        otherRate: iro?.[4] ?? null,
        firstRate: ro?.[0] ?? null,
        twiceRate: ro?.[1] ?? null,
        ...mo,
      },
    }),
    ...(guestAttribute && { guestAttribute }),
    free,
  };
};

export const matterActions = matterSlice.actions;
export default matterSlice.reducer;
