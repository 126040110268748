import { memo, useEffect } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import StaffRegisterInfos from "./inputInfos";
import {deleteArrayItem, handlerFormikFieldChange} from "../../../utils/fnUtil";
import AddButton from "../../../components/addButton";
import DeleteIcon from "../../../components/deleteIcon";

const Access = memo(({ formik }) => {

    const {nearestStations} = formik.values;

    useEffect(() => {
      if (nearestStations.length === 0) {
        handlerFormikFieldChange(formik, 'nearestStations', [...nearestStations, {}])
      }
    }, []);

  return(
      <div className={'staff_register--access_infos_area'}>
          {nearestStations?.map((_, i) => (
              <div className={'staff_edit--access_unity_area'}>
                  <div className={'staff_edit--access_input_button_area'}>
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.access.inputs[0]}
                          inputName={`nearestStations.[${i}].stationName`}
                          relativeInputName={[`nearestStations.[${i}]`]}
                      />
                      <div className={'staff_edit--delete_button_area'}>
                          <DeleteIcon
                              title="削除"
                              onClick={() =>
                                  handlerFormikFieldChange(
                                      formik,
                                      'nearestStations',
                                      deleteArrayItem(nearestStations, i)
                                  )
                              }
                          />
                      </div>
                  </div>

                  <CustomFormikInput
                      formik={formik}
                      {...StaffRegisterInfos.access.inputs[1]}
                      inputName={`nearestStations.[${i}].timeRequired`}
                      relativeInputName={[`nearestStations.[${i}]`]}
                  />


                  <div className={'staff_edit--access_input_area'} data-num={i}>
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.access.inputs[2]}
                          inputName={`nearestStations.[${i}].wayStation`}
                          relativeInputName={[`nearestStations.[${i}]`]}
                      />
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.access.inputs[3]}
                          inputName={`nearestStations.[${i}].busStationNameFrom`}
                          relativeInputName={[`nearestStations.[${i}]`]}
                          disabled={nearestStations[i]?.wayStation !== 1}
                      />
                    <span>〜</span>
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.access.inputs[4]}
                          inputName={`nearestStations.[${i}].busStationNameTo`}
                          relativeInputName={[`nearestStations.[${i}]`]}
                          disabled={nearestStations[i]?.wayStation !== 1}
                      />
                  </div>
              </div>
          ))}

          <AddButton
              type={'staff_edit--add_button'}
              onClick={() =>
                  handlerFormikFieldChange(formik, 'nearestStations', [...nearestStations, {}])
              }
              disabled={nearestStations.length > 4}
          />
      </div>
  )
});
export default Access;
