const StaffPlanShiftInfos = {
  search : [
    {
      inputType: 'selectBox',
      inputName: 'search_year',
      unit: '年',
      style: {
        areaStyle: {width: '200px'},
      }
    },
    {
      inputType: 'selectBox',
      inputName: 'search_month',
      unit: '月',
      style: {
        areaStyle: {width: '200px'},
      }
    }
  ],
}

export default StaffPlanShiftInfos;