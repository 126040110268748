import './style.scss';
import { useEffect } from 'react';

const ModalBase = props => {
  const children = props.children;
  const { dialogStyle, modalVisible, closeModalFunction } = props;

  return (
    <div
      className={'modal_base--overlay'}
      style={modalVisible ? { display: 'flex' } : { display: 'none' }}
      id={'overlay'}
      onClick={closeModalFunction ? closeModalFunction : () => {}}
    >
      <div className={'modal_base--dialog'} style={dialogStyle} onClick={event => event.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default ModalBase;
