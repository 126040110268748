import { memo } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteArrayItem,
  handlerFormikFieldChange,
} from '../../../../utils/fnUtil';
import Options from '../../../../constants/options';
import AddLineButton from '../../../../components/addLineButton';
import SwitchInput from '../../../../components/switch/switchInput';
import InputItem from '../../../../components/inputItem';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import './style.scss';

const GuestVoice = ({ formik }) => {
  const {
    values: { guestVoice },
    setFieldValue,
  } = formik;

  const voiceInput = guestVoice?.voiceInput ?? [];

  const { purchaseMotiveList } = useSelector(state => state.matter);

  const percentOption = [...Array(101)].map((_, i) => i);

  return (
    <div className="customer_voice">
      <div className="tab_content">
        <SwitchInput
          item="総人数"
          formik={formik}
          reportInputName="guestVoice.reportTotalFlag"
          staffInputName="guestVoice.staffTotalFlag"
          toggleSize={'default'}
          inputComponent={
            <CustomFormikInput
              formik={formik}
              inputName="guestVoice.voiceTotalNumber"
              placeholder="0"
              style={{
                inputStyle: {
                  width: 160,
                },
              }}
              unit="人"
            />
          }
        />
        <SwitchInput
          item="流入経路"
          formik={formik}
          reportInputName={'guestVoice.reportInflowRouteFlag'}
          staffInputName={'guestVoice.staffInflowRouteFlag'}
          toggleSize={'default'}
          inputComponent={
            <div className="customer_voice--inputs_custom">
              <CustomFormikInput
                label="DM"
                formik={formik}
                inputName="guestVoice.dmRate"
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                placeholder="00"
                otherErrorInputName={['guestVoice.staffInflowRouteTotal']}
                unit="%"
              />
              <CustomFormikInput
                label="通りがかり"
                formik={formik}
                inputName="guestVoice.passingRate"
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                placeholder="00"
                otherErrorInputName={['guestVoice.staffInflowRouteTotal']}
                unit="%"
              />
              <CustomFormikInput
                label="SNS"
                formik={formik}
                inputName="guestVoice.snsRate"
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                placeholder="00"
                otherErrorInputName={['guestVoice.staffInflowRouteTotal']}
                unit="%"
              />
              <CustomFormikInput
                label="普段から利用している"
                formik={formik}
                inputName="guestVoice.dailyUseRate"
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                placeholder="00"
                otherErrorInputName={['guestVoice.staffInflowRouteTotal']}
                unit="%"
              />
              <CustomFormikInput
                label="その他"
                formik={formik}
                inputName="guestVoice.otherRate"
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                placeholder="00"
                otherErrorInputName={['guestVoice.staffInflowRouteTotal']}
                unit="%"
              />
            </div>
          }
        />
        <SwitchInput
          item="購入動機"
          formik={formik}
          reportInputName={'guestVoice.reportMotiveFlag'}
          staffInputName={'guestVoice.staffMotiveFlag'}
          toggleSize={'default'}
          inputComponent={
            <div className="customer_voice--inputs_custom">
              {purchaseMotiveList?.length > 0 ? [...purchaseMotiveList]?.sort((a, b) => a.priority - b.priority)?.map(obj =>
                <CustomFormikInput
                  label={obj.naming}
                  formik={formik}
                  inputName={`guestVoice.staffMotiveId_${obj.namingId}`}
                  inputType="select"
                  initialValue={percentOption}
                  selectBoxOptions={percentOption}
                  placeholder="00"
                  otherErrorInputName={['guestVoice.staffMotiveRateTotal']}
                  unit="%"
                />
              ) : <></>}
            </div>
          }
        />
        <SwitchInput
          item="継続購入可否"
          formik={formik}
          reportInputName={'guestVoice.reportContinueFlag'}
          staffInputName={'guestVoice.staffContinueFlag'}
          toggleSize={'default'}
          inputComponent={
            <div className="customer_voice--inputs_custom">
              <CustomFormikInput
                label="初回"
                formik={formik}
                inputName="guestVoice.firstRate"
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                placeholder="00"
                otherErrorInputName={['guestVoice.staffContinueRouteTotal']}
                unit="%"
              />
              <CustomFormikInput
                label="2回以上"
                formik={formik}
                inputName="guestVoice.twiceRate"
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                placeholder="00"
                otherErrorInputName={['guestVoice.staffContinueRouteTotal']}
                unit="%"
              />
            </div>
          }
        />
        <SwitchInput item="入力項目" showSwitch={false} />
      </div>
      <div className="customer_voice--inputs">
        {voiceInput.map((_, i) => (
          <InputItem
            formik={formik}
            key={i}
            relativeInputName={['guestVoice.voiceInput']}
            itemInputName={`guestVoice.voiceInput.[${i}].inputTitle`}
            contentInputName={`guestVoice.voiceInput.[${i}].inputText`}
            reportInputName={`guestVoice.voiceInput.[${i}].reportVoiceFlag`}
            staffInputName={`guestVoice.voiceInput.[${i}].staffVoiceFlag`}
            toggleSize={'default'}
            label="入力項目"
            onDelete={() =>
              setFieldValue(
                'guestVoice.voiceInput',
                deleteArrayItem(voiceInput, i)
              )
            }
          />
        ))}
        <div>
          <AddLineButton
            title="入力項目追加"
            onClick={() =>
              setFieldValue('guestVoice.voiceInput', [
                ...voiceInput,
                {
                  reportVoiceFlag: 1,
                  staffVoiceFlag: 1,
                },
              ])
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GuestVoice;
