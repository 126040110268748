import { checkIsSp } from '../../../utils/fnUtil';

const isSp = checkIsSp();

const StaffPlanInfos = {
  listSearchArea: {
    inputs: [
      {
        inputType: 'inputMonth',
        label: '年月',
        inputName: 'yearMonth',
        initialValue: '',
        placeholder: 'YYYY/MM',
        className: {
          areaClass: 'staff_plan--input_month_area',
        },
      },
      {
        inputType: 'input',
        label: 'スタッフID',
        inputName: 'staffId',
        initialValue: '',
        placeholder: 'スタッフIDを入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: isSp ? { width: '100%' } : { width: '258px' },
        },
      },
      {
        inputType: 'input',
        label: 'スタッフ名',
        inputName: 'staffName',
        initialValue: '',
        placeholder: 'スタッフ名を入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: isSp ? { width: '100%' } : { width: '20%' },
        },
      },
      {
        inputType: 'select',
        mode: 'multiple',
        showSearch: true,
        label: 'メイン担当者',
        inputName: 'managerUserId',
        placeholder: 'メイン担当者を入力してください',
        style: {
          area: { gap: '0' },
          areaStyle: isSp ? { width: '100%' } : { width: '400px' },
        },
        className: { areaClass: 'column' },
      },
      {
        inputType: 'select',
        showSearch: false,
        allowClear: false,
        label: 'ステータス',
        inputName: 'status', //DBのカラム名
        initialValue: [99, 0, 1], //inputの初期値
        selectedSelectBox: 'すべて',
        selectBoxOptions: ['すべて', '未提出', '提出済'],
        className: {
          areaClass: 'column', //column
          labelClass: '',
          inputClass: '',
        },
        style: {
          areaStyle: isSp ? { width: '100%' } : { width: '150px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
    ],
    button: {
      width: '300px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#ffffff',
      fontSize: '14px',
      margin: '16px 0 0',
      padding: '0 0',
    },
  },
  basic: {
    inputs: [
      {
        inputType: 'selectBox',
        label: '生年月日',
        labelRequired: true,
        inputName: 'priority',
        initialValue: ['1', '2', '3'],
        selectedSelectBox: '1',
        selectBoxOptions: ['1', '2', '3'],
        placeholder: '表示優先順位を選択してください',
        style: {
          areaStyle: { width: '24%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '性別',
        labelRequired: true,
        inputName: 'gender',
        initialValue: ['男性', '女性', 'その他'],
        selectedSelectBox: '女性',
        selectBoxOptions: ['男性', '女性', 'その他'],
        placeholder: '表示優先順位を選択してください',
        style: {
          areaStyle: { width: '364px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'inputWithInput',
        label: '郵便番号',
        inputName: ['zip_code_1', 'zip_code_2'],
        initialValue: ['123', '4567'],
        placeholder: ['123', '4567'],
        unit: ['-', ''],
        style: {
          areaStyle: { width: '32%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '都道府県',
        inputName: 'pref_name',
        initialValue: ['1', '2', '3'],
        selectedSelectBox: '1',
        selectBoxOptions: ['千葉県', '2', '3'],
        placeholder: '都道府県',
        style: {
          areaStyle: { width: '392px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '市区町村',
        inputName: 'city_name',
        initialValue: 'xxxxxx市',
        placeholder: '市区町村を入力してください',
        style: {
          areaStyle: { width: '67%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '丁目番地',
        inputName: 'street_name',
        initialValue: 'xxxxxxxx0-0-00',
        placeholder: '丁目番地を入力してください',
        style: {
          areaStyle: { width: '67%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: 'マンション・部屋番号等',
        inputName: 'building_name',
        initialValue: 'xxxxxxマンション 00000',
        placeholder: 'マンション・部屋番号等を入力してください',
        style: {
          areaStyle: { width: '67%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'inputTell',
        label: 'TEL(スマートフォン)',
        labelRequired: true,
        inputName: [
          'phone_area_number',
          'phone_city_number',
          'phone_subscriber_number',
        ],
        initialValue: ['03', '0000', '0000'],
        placeholder: ['03', '0000', '0000'],
        unit: ['-', '-', ''],
        style: {
          areaStyle: { width: '626px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'inputTell',
        label: 'TEL(自宅)',
        inputName: [
          'tel_area_number',
          'tel_city_number',
          'tel_subscriber_number',
        ],
        initialValue: ['03', '0000', '0000'],
        placeholder: ['03', '0000', '0000'],
        unit: ['-', '-', ''],
        style: {
          areaStyle: { width: '626px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: 'LINE ID',
        inputName: 'line_id',
        initialValue: 'yamaddddddddddddddddaaaaaaaaa',
        placeholder: 'LINE IDを登録してください',
        style: {
          areaStyle: { width: '67%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: 'アサイン希望日数',
        inputName: 'hope_assign',
        initialValue: '15',
        placeholder: '0',
        style: {
          areaStyle: { width: '383px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'inputDate',
        label: '入社日',
        inputName: 'join_date',
        initialValue: '2016/04/01',
        placeholder: 'YYYY/MM/DD',
        style: {
          areaStyle: { width: '421px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'inputDate',
        label: '退職日',
        inputName: 'retire_date',
        initialValue: '',
        placeholder: 'YYYY/MM/DD',
        style: {
          areaStyle: { width: '237px' },
        },
      },
      {
        inputType: 'selectBox',
        inputName: 'retire_reason',
        initialValue: ['男', '女', 'その他'],
        selectedSelectBox: '',
        selectBoxOptions: ['男', '女', 'その他'],
        placeholder: '退職理由を選択してください',
        style: {
          areaStyle: { width: 'calc(100% - 237px)' },
        },
      },
      {
        inputType: 'selectBox',
        label: '社内担当者(メイン)',
        inputName: 'main_manager_name',
        initialValue: ['男', '女', 'その他'],
        selectedSelectBox: '男',
        selectBoxOptions: ['田中 太郎', '女', 'その他'],
        placeholder: '社内担当者(メイン)を選択してください',
        style: {
          areaStyle: { width: '67%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '社内担当者(サブ)',
        inputName: 'sub_manager_name',
        initialValue: ['男', '女', 'その他'],
        selectedSelectBox: '男',
        selectBoxOptions: ['田中 さぶ太', '女', 'その他'],
        placeholder: '社内担当者(サブ)を選択してください',
        style: {
          areaStyle: { width: '67%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        radioButtonTitle: '契約携帯',
        labelRequired: true,
        radioButtonLabelAndChecked: [
          { label: '業務委託', checked: true },
          { label: 'パート・アルバイト', checked: false },
        ],
        inputName: 'employment_type',
        initialValue: ['hoge1', 'hoge2'],
        inputType: 'radioButton',
        style: {
          areaStyle: {},
          labelStyle: { width: '232px' },
        },
      },
      {
        radioButtonTitle: '区分',
        radioButtonLabelAndChecked: [
          { label: '甲', checked: true },
          { label: '乙', checked: false },
        ],
        inputName: 'classification',
        initialValue: ['hoge1', 'hogw2'],
        inputType: 'radioButton',
        style: {
          areaStyle: {},
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '扶養人数',
        inputName: 'dependents',
        initialValue: '',
        placeholder: '0000',
        unit: '人',
        style: {
          areaStyle: { width: '388px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '基本日給',
        inputName: 'daily_pay',
        initialValue: '',
        placeholder: '基本日給を入力してください',
        unit: '円',
        style: {
          areaStyle: { width: '100%' },
          labelStyle: { width: '56px' },
        },
      },
      {
        inputType: 'input',
        label: '基本時給',
        inputName: 'hourly_pay',
        initialValue: '',
        placeholder: '基本時給を入力してください',
        unit: '円',
        style: {
          areaStyle: { width: '100%' },
        },
      },
      {
        inputType: 'selectBox',
        label: '採用媒体',
        inputName: 'recruit_media',
        initialValue: ['hoge1', 'hoge2', 'hoge3'],
        selectedSelectBox: '',
        selectBoxOptions: ['マイナビ', 'リクナビ', 'エン転職'],
        placeholder: '採用媒体を選択してください',
        style: {
          areaStyle: { width: '67%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '採用単価',
        inputName: 'recruit_cost',
        initialValue: '',
        placeholder: '0',
        style: {
          areaStyle: { width: '480px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: 'ポジション',
        inputName: 'staff_rank',
        initialValue: ['男', '女', 'その他'],
        selectedSelectBox: '',
        selectBoxOptions: ['男', '女', 'その他'],
        placeholder: 'ポジションを選択してください',
        style: {
          areaStyle: { width: '40%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '評価',
        inputName: 'evaluation',
        initialValue: ['男', '女', 'その他'],
        selectedSelectBox: '',
        selectBoxOptions: ['男', '女', 'その他'],
        placeholder: '評価を選択してください',
        style: {
          areaStyle: { width: '40%' },
          labelStyle: { width: '232px' },
        },
      },
    ],
  },
  access: {
    inputs: [
      {
        inputType: 'input',
        label: '最寄駅',
        inputName: 'nearest_station_1',
        initialValue: '',
        placeholder: '駅名を入力してください',
        style: {
          areaStyle: { width: '75%', marginTop: '4px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '最寄駅までの所要時間(分)',
        inputName: 'time_required_1',
        initialValue: ['1', '2', '3'],
        selectedSelectBox: '',
        selectBoxOptions: ['1', '2', '3'],
        placeholder: '0',
        unit: '分',
        style: {
          areaStyle: { width: '359px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '最寄駅までの交通手段',
        inputName: 'way_station_1',
        initialValue: ['自転車', 'バス', 'その他'],
        selectedSelectBox: '’',
        selectBoxOptions: ['自転車', 'バス', 'その他'],
        placeholder: '選択してください',
        style: {
          areaStyle: { width: '410px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'inputWithInput',
        inputName: ['bus_station_name_from_1', 'bus_station_name_to_1'],
        initialValue: ['', ''],
        placeholder: [
          'バス停名を入力してください',
          'バス停名を入力してください',
        ],
        unit: ['~', ''],
        style: {
          areaStyle: { width: 'calc(100% - 410px)' },
          labelStyle: { width: '232px' },
        },
      },
    ],
  },
  body: {
    inputs: [
      {
        inputType: 'selectBox',
        label: '身長',
        inputName: 'height',
        initialValue: ['155', '160', '165'],
        selectedSelectBox: '',
        selectBoxOptions: ['155', '160', '165'],
        placeholder: '155',
        unit: 'cm',
        style: {
          areaStyle: { width: '398px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '体重',
        inputName: 'weight',
        initialValue: '',
        placeholder: '40',
        unit: 'kg',
        style: {
          areaStyle: { width: '398px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '靴のサイズ',
        inputName: 'shoe_size',
        initialValue: '',
        placeholder: '24.0',
        unit: 'cm',
        style: {
          areaStyle: { width: '398px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '服のサイズ',
        inputName: 'wear_size',
        initialValue: ['S', 'M', 'L'],
        selectedSelectBox: '',
        selectBoxOptions: ['S', 'M', 'L'],
        placeholder: 'M',
        style: {
          areaStyle: { width: '367px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'pickUpSelectBox',
        label: '持っているもの',
        inputName: 'items',
        initialValue: [
          'pants_suit',
          'skirt_suit',
          'black_sneaker',
          'white_sneaker',
          'black_chino',
          'beige_chino',
        ],
        selectBoxOptions: [
          'パンツスーツ',
          'スカートスーツ',
          '黒スニーカー',
          '白スニーカー',
          '黒チノパン',
          'ベージュチノパン',
        ],
        placeholder: '選択してください',
        style: {
          areaStyle: { width: '81%' },
          labelStyle: { width: '232px' },
        },
      },
    ],
  },
  background: {
    inputs: [
      {
        inputType: 'pickUpSelectBox',
        label: '資格',
        inputName: 'certification',
        initialValue: [
          'pants_suit',
          'skirt_suit',
          'black_sneaker',
          'white_sneaker',
          'black_chino',
          'beige_chino',
        ],
        selectBoxOptions: [
          'パンツスーツ',
          'スカートスーツ',
          '黒スニーカー',
          '白スニーカー',
          '黒チノパン',
          'ベージュチノパン',
        ],
        placeholder: '選択してください',
        style: {
          areaStyle: { width: '81%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        radioButtonTitle: '職業',
        radioButtonLabelAndChecked: [
          { label: '学生', checked: true },
          { label: 'その他(社会人、主婦など)', checked: false },
        ],
        inputName: 'occupation',
        initialValue: ['student', 'hoge2'],
        inputType: 'radioButton',
        style: {
          areaStyle: {},
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '学校名',
        inputName: 'school_name',
        initialValue: '',
        placeholder: '学校名を入力してください',
        style: {
          areaStyle: { width: '68%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '入学年',
        inputName: 'admission_year',
        initialValue: '',
        placeholder: '2022',
        unit: '年',
        style: {
          areaStyle: { width: '29%' },
          labelStyle: { width: '232px' },
        },
      },
    ],
  },
  salary: {
    inputs: [
      {
        inputType: 'selectBox',
        label: '給与支払方法',
        inputName: 'pay_method',
        initialValue: ['155', '160', '165'],
        selectedSelectBox: '',
        selectBoxOptions: ['155', '160', '165'],
        placeholder: '給与支払方法を選択してください',
        style: {
          areaStyle: { width: '583px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '金融期間名',
        inputName: 'bank_name',
        initialValue: '',
        placeholder: '振込先金融機関名を入力してください',
        style: {
          areaStyle: { width: '583px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '銀行コード',
        inputName: 'bank_code',
        initialValue: '',
        placeholder: '0000',
        style: {
          areaStyle: { width: '366px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '支店名',
        inputName: 'bank_branch_name',
        initialValue: '',
        placeholder: '支店名を入力してください',
        style: {
          areaStyle: { width: '583px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '支払コード',
        inputName: 'bank_branch_code',
        initialValue: '',
        placeholder: '0000',
        style: {
          areaStyle: { width: '366px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        radioButtonTitle: '口座種別',
        radioButtonLabelAndChecked: [
          { label: '普通', checked: true },
          { label: '当座', checked: false },
          { label: '貯蓄', checked: false },
        ],
        inputName: 'bank_account_type',
        initialValue: ['hoge1', 'hoge2', 'hoge3'],
        inputType: 'radioButton',
        style: {
          areaStyle: {},
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '口座番号',
        inputName: 'bank_account_number',
        initialValue: '',
        placeholder: '0000000',
        style: {
          areaStyle: { width: '583px' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'input',
        label: '口座名義人(カナ)',
        inputName: 'bank_account_name',
        initialValue: '',
        placeholder: '口座名義人(カナ)を入力してください',
        style: {
          areaStyle: { width: '67%' },
          labelStyle: { width: '232px' },
        },
      },
    ],
  },
  attribute: {
    inputs: [
      {
        inputType: 'selectBox',
        label: '予備項目1',
        inputName: 'idx_stbk1',
        initialValue: ['予備項目1', '予備項目2', '予備項目3'],
        selectedSelectBox: '予備項目3',
        selectBoxOptions: ['予備項目1', '予備項目2', '予備項目3'],
        placeholder: '選択してください',
        style: {
          areaStyle: { width: '87%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '予備項目2',
        inputName: 'idx_stbk2',
        initialValue: ['予備項目1', '予備項目2', '予備項目3'],
        selectedSelectBox: '予備項目1',
        selectBoxOptions: ['予備項目1', '予備項目2', '予備項目3'],
        placeholder: '選択してください',
        style: {
          areaStyle: { width: '87%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '予備項目3',
        inputName: 'idx_stbk3',
        initialValue: ['予備項目1', '予備項目2', '予備項目3'],
        selectedSelectBox: '予備項目3',
        selectBoxOptions: ['予備項目1', '予備項目2', '予備項目3'],
        placeholder: '選択してください',
        style: {
          areaStyle: { width: '87%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '予備項目4',
        inputName: 'idx_stbk4',
        initialValue: ['予備項目1', '予備項目2', '予備項目3'],
        selectedSelectBox: '予備項目1',
        selectBoxOptions: ['予備項目1', '予備項目2', '予備項目3'],
        placeholder: '選択してください',
        style: {
          areaStyle: { width: '87%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'selectBox',
        label: '予備項目5',
        inputName: 'idx_stbk5',
        initialValue: ['予備項目1', '予備項目2', '予備項目3'],
        selectedSelectBox: '予備項目2',
        selectBoxOptions: ['予備項目1', '予備項目2', '予備項目3'],
        placeholder: '選択してください',
        style: {
          areaStyle: { width: '87%' },
          labelStyle: { width: '232px' },
        },
      },
      {
        inputType: 'textBox',
        label: '備考',
        inputName: 'note',
        initialValue: '特にありません',
        placeholder: '備考を入力してください',
        style: {
          areaStyle: { width: '87%' },
          labelStyle: { width: '232px' },
        },
      },
    ],
  },
};

export default StaffPlanInfos;
