import { memo, useState, Fragment } from 'react';
import { get, toInteger } from 'lodash';
import {
  deleteArrayItem,
  handlerFormikFieldChange,
  checkIsSp,
} from '../../../../utils/fnUtil';
import Button from '../../../../components/button';
import CaculateTable from '../../../../components/caculateTable';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import DeleteIcon from '../../../../components/deleteIcon';
import EmptyLayout from '../../../../components/emptyLayout';
import LayoutModal from '../../../../components/modal/layoutModal';
import ReportSwitch from '../../../../components/switch/reportSwitch';
import AddLineButton from '../../../../components/addLineButton';
import CreateTableModal from '../../../../components/modal/createTableModal';
import Icons from '../../../../constants/icons';
import classNames from 'classnames';
import './style.scss';

const PerformanceTable = memo(({ formik }) => {
  const {
    values: { reportPage },
  } = formik;

  const initialSelected = {
    selectedPage: -1,
    selectedLayout: -1,
    selectedTable: -1,
  };

  const [selected, setSelected] = useState(initialSelected);

  const [createTableModalVisible, setCreateTableModalVisible] = useState(false);

  const [layoutModalVisible, setLayoutModalVisible] = useState(false);

  const isSp = checkIsSp();

  const onAddPattern = layoutPattern => {
    handlerFormikFieldChange(
      formik,
      `reportPage.[${selected.selectedPage}].layout]`,
      [
        ...get(reportPage, `[${selected.selectedPage}].layout`, []),
        {
          layoutPattern,
          table: [...Array(layoutPattern)].fill({}),
        },
      ]
    );
    setLayoutModalVisible(false);
  };

  return (
    <div className="performance_table">
      {reportPage.map((page, i) => (
        <Fragment key={i}>
          <div className="performance_table--title">
            <CustomFormikInput
              formik={formik}
              inputName={`reportPage.[${i}].pageName`}
              placeholder={'ページ名を入力してください'}
              style={{
                areaStyle: { width: 302 },
              }}
            />
          </div>
          <div
            className={classNames('performance_table--content', {
              'performance_table--empty': !(page.layout ?? []).length > 0,
            })}
            style={isSp ? { paddingBottom: 0, height: '750px' } : {}}
          >
            <DeleteIcon
              title="ページ削除"
              onClick={() => {
                handlerFormikFieldChange(
                  formik,
                  'reportPage',
                  deleteArrayItem(reportPage, i)
                );
              }}
              style={isSp ? { width: '30px', height: '30px' } : {}}
            />
            <ReportSwitch
              formik={formik}
              reportInputName={`reportPage.[${i}].reportFlag`}
              staffInputName={`reportPage.[${i}].staffFlag`}
              toggleSize={'default'}
            />
            {(page.layout ?? []).length > 0 ? (
              <div className="performance_table--layout_container_father">
                <div className="performance_table--layout_container_wrapper">
                  {page.layout.map((layout, j) => (
                    <div
                      className={classNames(
                        'performance_table--layout_container',
                        {
                          [`performance_table--layout_container_${
                            (layout?.table ?? []).length
                          }`]: true,
                        }
                      )}
                      key={j}
                      style={isSp ? { marginTop: '100px' } : {}}
                    >
                      <DeleteIcon
                        title="レイアウト削除"
                        onClick={() => {
                          handlerFormikFieldChange(
                            formik,
                            `reportPage.[${i}].layout]`,
                            deleteArrayItem(page.layout, j)
                          );
                        }}
                        style={isSp ? { top: '102px' } : {}}
                      />
                      {layout.table.map(
                        ({ tableColumns, tableRows, totalDisplayFlag }, k) =>
                          tableColumns ? (
                            <CaculateTable
                              key={`${j}${k}`}
                              formik={formik}
                              inputName={`reportPage.[${i}].layout.[${j}].table.[${k}]`}
                              tableSettings={{
                                tableColumns,
                                tableRows,
                                totalDisplayFlag,
                              }}
                              onDeleteTable={() =>
                                handlerFormikFieldChange(
                                  formik,
                                  `reportPage.[${i}].layout.[${j}].table.[${k}]`,
                                  {}
                                )
                              }
                            />
                          ) : (
                            <EmptyLayout
                              key={j + k}
                              onClick={() => {
                                setSelected({
                                  selectedPage: i,
                                  selectedLayout: j,
                                  selectedTable: k,
                                });
                                setCreateTableModalVisible(true);
                              }}
                            />
                          )
                      )}
                    </div>
                  ))}
                </div>
                {page.layout.length < 2 && (
                  <Button
                    icon={Icons.icon.plusNv}
                    text="レイアウト追加"
                    theme="white"
                    onClick={() => {
                      setSelected(pre => ({
                        ...pre,
                        selectedPage: i,
                      }));
                      setLayoutModalVisible(true);
                    }}
                    style={isSp ? { left: '10px' } : {}}
                  />
                )}
              </div>
            ) : (
              <div
                className="performance_table--description"
                style={isSp ? { paddingTop: '30px' } : {}}
              >
                <span>レイアウトを追加してください</span>
                <span>実績表のレイアウトを設定できます</span>
                <Button
                  icon={Icons.icon.plusNv}
                  text="レイアウト追加"
                  theme="white"
                  onClick={() => {
                    setSelected(pre => ({
                      ...pre,
                      selectedPage: i,
                    }));
                    setLayoutModalVisible(true);
                  }}
                />
              </div>
            )}
          </div>
        </Fragment>
      ))}
      <LayoutModal
        modalVisible={layoutModalVisible}
        onBack={() => setLayoutModalVisible(false)}
        onAddPattern={onAddPattern}
      />
      <CreateTableModal
        currentTableCount={(reportPage[selected.selectedPage]?.layout ?? [])
          .flatMap(l => l.table)
          .map(t => toInteger(t.tableRows))
          .reduce((pre, next) => pre + next, 0)}
        modalVisible={createTableModalVisible}
        onCreateTable={tableSetting => {
          handlerFormikFieldChange(
            formik,
            `reportPage.[${selected.selectedPage}].layout.[${selected.selectedLayout}].table.[${selected.selectedTable}]`,
            tableSetting
          );
          setCreateTableModalVisible(false);
        }}
        onCancel={() => setCreateTableModalVisible(false)}
      />
      <AddLineButton
        title={isSp ? '' : 'ページ追加'}
        onClick={() =>
          handlerFormikFieldChange(formik, 'reportPage', [
            ...reportPage,
            {
              reportFlag: 1,
              staffFlag: 1,
              pageName: '',
              layout: [],
            },
          ])
        }
      />
    </div>
  );
});

export default PerformanceTable;
