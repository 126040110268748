const SurveyInputs = {
  title: [
    {
      inputType: 'input',
      label: 'タイトル',
      labelRequired: true,
      inputName: 'surveyTitle',
      placeholder: 'タイトルを入力してください',
      style: {
        areaStyle: { maxWidth: '712px' },
        labelStyle: { width: '232px' },
      },
    },
    {
      inputType: 'radioButton',
      labelRequired: true,
      radioButtonTitle: 'アンケート種別',
      inputName: 'surveyType',
      initialValue: ['0', '1'],
      style: {
        areaStyle: { width: 'auto' },
        labelStyle: { width: '232px' },
      },
    },
    {
      inputType: 'input',
      inputName: 'clientName',
      placeholder: '取引先名を入力してください',
      style: {
        areaStyle: { maxWidth: '456px' },
      },
    },
    {
      inputType: 'textBox',
      labelRequired: true,
      inputName: 'surveyDescription',
      label: 'アンケート説明',
      placeholder: '説明を入力してください',
      style: {
        labelStyle: { width: '232px' },
      },
    },
  ],
  content: [
    {
      inputType: 'inputDate',
      label: '回答日',
      labelRequired: true,
      placeholder: 'YYYY/MM/DD',
      style: {
        labelStyle: { width: '232px', fontWeight: 'bold' },
      },
    },
    {
      inputType: 'inputDate',
      label: '回答日',
      labelRequired: true,
      placeholder: 'YYYY/MM/DD',
      style: {
        labelStyle: { width: '232px', fontWeight: 'bold' },
      },
    },
    {
      inputType: 'inputDate',
      placeholder: 'YYYY/MM/DD',
      style: {
        labelStyle: { width: '232px' },
      },
    },
    {
      inputType: 'toggleButton',
      label: '必須',
      inputName: 'questionRequiredFlag',
      checkBoxChecked: true,
    },
    {
      inputType: 'textBox',
      inputName: 'questionDescription',
      placeholder: '設問内容を入力してください',
      style: {
        labelStyle: { width: '232px' },
      },
    },
    {
      inputType: 'radioButton',
      labelRequired: true,
      radioButtonTitle: '回答方法',
      initialValue: ['0', '1', '2'],
      style: {
        labelStyle: { width: '232px', fontWeight: 'bold' },
      },
    },
    {
      inputType: 'input',
      placeholder: '選択肢を入力してください',
      style: {
        areaStyle: {width: '85%', minWidth: '600px', maxWidth: '900px'},
        labelStyle: { width: 'fit-contents' },
      },
    },
    {
      inputType: 'radioButton',
      labelRequired: true,
      radioButtonTitle: '回答結果表示方法',
      initialValue: ['0', '1'],
      style: {
        labelStyle: { width: '232px', fontWeight: 'bold' },
      },
    },
    {
      inputType: 'select',
      showSearch: false,
      inputName: 'answerResultGraphType',
      initialValue: [0, 1, 2],
      selectBoxOptions: ['円グラフ', '縦棒グラフ', '横棒グラフ'],
      placeholder: '表示方法を選択してください',
      style: {
        areaStyle: {
          paddingLeft: '252px',
          maxWidth: '700px',
        },
      },
    },
    {
      inputType: 'multiSelect',
      showSearch: false,
      inputName: 'aggregationProcessGraphType',
      initialValue: ['0', '1', '2'],
      selectBoxOptions: ['円グラフ', '縦棒グラフ', '横棒グラフ'],
      placeholder: '表示方法を選択してください',
    },
    {
      inputType: 'checkBox',
      mode: 'survey',
      initialValue: ['0', '1', '2', '3', '4'],
      label: ['性別', '年代', '職業', 'スタッフポジション', '居住都道府県'],
    },
  ],
};

export default SurveyInputs;
