import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Descriptions } from 'antd';
import { get, toInteger } from 'lodash';
import { imageUpload } from '../../../../../redux/slice/matter';
import Button from '../../../../../components/button';
import CustomFormikInput from '../../../../../components/customInput/customFormikInput';
import ImageCell from '../../../../../components/modal/fileUploadModal/imageCell';
import Icons from '../../../../../constants/icons';
import {
  checkIsSp,
  handlerFormikFieldChange,
  numberFormat,
} from '../../../../../utils/fnUtil';
import './style.scss';

const PaymentItem = memo(({ label, inputName, formik, custom }) => {
  const dispatch = useDispatch();

  const { values } = formik;

  const paymentDetail = get(values, inputName) ?? {};

  const { width } = useWindowSize();

  const {
    advanceAmount1,
    advanceAmount2,
    advanceAmount3,
    advanceAmount4,
    advanceAmount5,
    totalAmount,
  } = paymentDetail;

  const [tableVibisble, setTableVisible] = useState(false);

  const updateImage = (i, img, fileName) => {
    handlerFormikFieldChange(
      formik,
      `${inputName}.advanceReceiptImage${i + 1}`,
      img
    );
    handlerFormikFieldChange(
      formik,
      `${inputName}.advanceReceiptImage${i + 1}Name`,
      fileName
    );
  };

  useEffect(() => {
    handlerFormikFieldChange(
      formik,
      `${inputName}.totalAmount`,
      [
        advanceAmount1,
        advanceAmount2,
        advanceAmount3,
        advanceAmount4,
        advanceAmount5,
      ]
        .map(toInteger)
        .reduce((pre, next) => pre + next, 0)
    );
  }, [
    advanceAmount1,
    advanceAmount2,
    advanceAmount3,
    advanceAmount4,
    advanceAmount5,
  ]);

  const costContainer = (
    <div className="payment_item--cost_container">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="payment_item--cost">
          <CustomFormikInput
            formik={formik}
            inputName={`${inputName}.advance${i + 1}`}
            label={`立替金(${label})${i + 1}`}
            placeholder="名目を入力してください"
            style={{
              labelStyle: {
                width: 100,
                justifyContent: 'flex-start',
              },
              inputStyle: {
                width: 450,
                marginRight: checkIsSp() ? 0 : 10,
              },
            }}
            disabled={formik?.values?.advanceApprovalFlag}
          />
          <CustomFormikInput
            formik={formik}
            inputName={`${inputName}.advanceAmount${i + 1}`}
            placeholder="0"
            otherErrorInputName={[`${inputName}.totalAmount`]}
            unit="円"
            style={{
              inputStyle: { width: 200 },
            }}
            disabled={formik?.values?.advanceApprovalFlag}
          />
        </div>
      ))}
    </div>
  );

  const costImages = (
    <div>
      <div className="payment_item--cost_images">
        {[...Array(5)].map((_, i) => (
          <ImageCell
            image={paymentDetail[`advanceReceiptImage${i + 1}`]}
            fileName={paymentDetail[`advanceReceiptImage${i + 1}Name`]}
            key={i}
            onDelete={() => updateImage(i, '', '')}
            onAddImage={async (img, fileName) => {
              const imageUrl = await dispatch(imageUpload(img)).unwrap();

              updateImage(i, imageUrl, fileName);
            }}
            approvalFlag={formik?.values?.advanceApprovalFlag}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className="payment_item">
      <Button
        text={`${label}立替金`}
        icon={Icons.icon.plusNv}
        onClick={() => setTableVisible(!tableVibisble)}
      />
      {tableVibisble &&
        (width > 800 && !custom ? (
          <table style={{width: '1360px'}}>
            <thead>
              <th>立替金</th>
              <th>合計</th>
              <th>領収書</th>
            </thead>
            <tbody>
              <tr>
                <td>{costContainer}</td>
                <td>{numberFormat(totalAmount, '円', '-')}</td>
                <td>{costImages}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <Descriptions layout="vertical" column={1} colon={false}>
            <Descriptions.Item label="立替金">
              {costContainer}
            </Descriptions.Item>
            <Descriptions.Item label="合計">
              {numberFormat(totalAmount, '円', '-')}
            </Descriptions.Item>
            <Descriptions.Item label="領収書">{costImages}</Descriptions.Item>
          </Descriptions>
        ))}
    </div>
  );
});

export default PaymentItem;
