import options from "../../../constants/options";

const StaffPlanShiftInfos = {
  inputs: [
    {
      inputType: 'toggleButton',
      style: {
        areaStyle: {width: '64px'},
      }
    },
    {
      relativeInputName:['shiftStyleFlag'],
      initialValue:[0,1],
      inputType: 'radioButton',
    },
    {
      inputType: 'inputTime', //inputのtype
      relativeInputName:['startTime'],
      initialValue: '', //inputの初期値
      placeholder: '09:00',
      unit: '', //インプット横の単位
    },
    {
      inputType: 'inputTime', //inputのtype
      relativeInputName:['endTime'],
      initialValue: '', //inputの初期値
      placeholder: '15:00',
      unit: '', //インプット横の単位
    },
    {
      inputType: 'input',
      initialValue: null,
      placeholder: '備考を入力してください',
      style: {
        areaStyle: {width: '100%', paddingRight: '24px'},
        labelStyle: {},
        inputStyle: {width: '80%'},
      }
    },
    {
      inputType: 'input',
      initialValue: '',
      placeholder: '備考を入力してください',
      className: {
        areaClass: '480px',
      },
      style: {
        areaStyle: {width: '100%', paddingRight: '24px'},
        labelStyle: {},
        inputStyle: {},
      }
    }
  ],
  search : [
    {
      inputType: 'select',
      inputName: 'search_year',
      unit: '年',
      style: {
        areaStyle: {width: '200px'},
      },
      initialValue: options.codes.yearOption,
      selectBoxOptions: options.codes.yearOption,
    },
    {
      inputType: 'select',
      inputName: 'search_month',
      unit: '月',
      initialValue: options.codes.monthOption,
      selectBoxOptions: options.codes.monthOption,
      style: {
        areaStyle: {width: '200px'},
      }
    }
  ],
}

export default StaffPlanShiftInfos;
