import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import {useLocation, useNavigate} from 'react-router-dom';
import './style.scss';
import AddButton from '../../../components/addButton';
import CustomFormikInput from "../../../components/customInput/customFormikInput";
import {useFormik} from "formik";
import {
  invoiceActions,
  invoiceDetail,
  invoiceDetailGetInfo,
  invoiceInsert,
  invoiceUpdate
} from "../../../redux/slice/invoice";
import {useDispatch, useSelector} from "react-redux";
import {checkIsSp, deleteArrayItem, handlerFormikFieldChange, handlerFormikSubmit} from "../../../utils/fnUtil";
import AddLineButton from "../../../components/addLineButton";
import DeleteIcon from "../../../components/deleteIcon";
import moment from "moment/moment";
import Yup from "../../../utils/yupUtil";
import {globalActions} from "../../../redux/slice/global";
import {fetchMatterDetail} from "../../../redux/slice/matter";
import {clientDetailFetch} from "../../../redux/slice/client";

const InvoiceRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isEdit = location?.state?.id ? true : false;
  let radioButtons = document.getElementsByClassName('invoice_register--radio');
  if (!checkIsSp()) {
  Array.from(radioButtons).map((elm) => {
    if (elm.innerText === 'クレジットカード') {
      elm.style.width = '140px';
      elm.style.marginLeft = '56px';
    } else if (elm.innerText === 'コンビニ支払') {
      elm.style.width = '112px';
      elm.style.marginLeft = '28px';
    } else if (elm.innerText === '郵便振替' && checkIsSp()) {
      elm.style.marginLeft = '0';
      elm.style.marginRight = '20px';
    }else if (elm.innerText !== '銀行振込' && elm.innerText !== '代金引換') {
      elm.style.marginLeft = '28px';
    }
  })
  }
  const {
    editParams,
    namingList,
    clientList,
    userList,
    matterList,
    paymentList,
    bankingList,
    namingCategoryList,
    matterCategoryList,
    PromotionCategoryList,
    PromotionIndustryPrimaryList,
    PromotionIndustrySecondaryList,
    navigation,
    officeMasters,
  } = useSelector((state) => state.invoice);
  const {corporateId} = useSelector((state) =>state.account);

  const tabErrorGroup = {
    common: ['matterNumber', 'matterName', 'clientId', 'matterManagerUserId',
    'salesManagerUserId', 'payTermsId', 'orderStatus', 'invoiceTiming',
    'billedDate', 'payMethod', 'bankMasterId', 'billedDateDulpicate'],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editParams,
    validateOnMount: true,
    validationSchema: Yup.object({
      matterNumber: Yup.string().nullable().invoiceRequired('[請求書情報]案件No.').label('[請求書情報]案件No.'),
      matterName: Yup.string().nullable().required().label('[請求書情報]案件名'),
      clientId: Yup.number().nullable().required().label('[請求書情報]取引先'),
      matterManagerUserId: Yup.number().nullable().required().label('[請求書情報]社内案件担当者'),
      salesManagerUserId: Yup.number().nullable().required().label('[請求書情報]社内営業担当者'),
      payTermsId: Yup.number().nullable().required().label('[請求書情報]支払条件'),
      orderStatus: Yup.number().nullable().required().label('[請求書情報]受注ステータス'),
      invoiceTiming: Yup.number().nullable().required().label('[請求書情報]請求タイミング'),
      billedDate: Yup.array().nullable().required().ArrayRequired('[請求書情報]請求日').label('[請求書情報]:請求日'),
      payMethod: Yup.number().nullable().required().label('[請求書情報]支払方法'),
      bankMasterId: Yup.number().nullable().required().label('[請求書情報]支払先口座'),
      matterCategory: Yup.number().nullable().required().label('[請求書情報]案件カテゴリ'),
      matterIndustryPrimary: Yup.number().nullable().required().label('[請求書情報]業界'),
    }),
    onSubmit: values => {
      if(isEdit){
        dispatch(invoiceUpdate({
          ...values,
          billedDate: values?.billedDate?.filter(b=> b != ''),
        }))
            .unwrap()
            .then((result) => {
              if(result?.code == "0"){
                dispatch(globalActions.showSingleModal('更新しました'));
                navigate(navigation, { state: {id:result?.params?.invoiceNumber}});
              }
            });
      }else{
        dispatch(invoiceInsert({
          ...values,
          billedDate: values?.billedDate?.filter(b=> b != ''),
        }))
            .unwrap()
            .then((result) => {
              if(result?.code == "0"){
                dispatch(globalActions.showSingleModal('登録しました'));
                navigate(navigation, { state: {id:result?.params?.invoiceNumber}});
              }
            });
        }
      },
  });
  const {
    matterNumber,
    invoiceNumber,
    orderStatus,
    invoiceTiming,
    payMethod,
    billedDate,
    matterCategory,
    matterIndustryPrimary,
    createdUser,
    updatedUser,
    clientId,
    payTermsId
  } = formik.values;
  const InvoiceInfos = [
    {
      inputType: 'select',
      label: '案件No',
      labelRequired: true,
      inputName: 'matterNumber',
      initialValue: matterList?.map(m=>m?.value),
      selectBoxOptions: matterList?.map(m=>m?.label),
      placeholder: '案件Noを選択してください',
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'68%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'input',
      label: '案件名',
      labelRequired: true,
      inputName: 'matterName',
      placeholder: '案件名を入力してください',
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'68%'},
        labelStyle: {width: '232px'},
      },
      disabled: matterNumber || invoiceNumber || (!isEdit && !matterNumber)
    },
    {
      inputType: 'select',
      label: '取引先',
      labelRequired: true,
      inputName: 'clientId',
      disabled: matterNumber || invoiceNumber || (!isEdit && !matterNumber),
      initialValue: clientList?.map(m=>m?.value),
      selectBoxOptions: clientList?.map(m=>m?.label),
      placeholder: '取引先を選択してください',
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'68%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'input',
      label: '取引先担当者',
      inputName: 'clientManagerName',
      initialValue: '',
      placeholder: '取引先担当者を入力してください',
      disabled: matterNumber || invoiceNumber || (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'68%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'select',
      label: '社内案件担当者',
      labelRequired: true,
      inputName: 'matterManagerUserId',
      initialValue: userList?.map(m=>m?.value),
      selectBoxOptions: userList?.map(m=>m?.label),
      placeholder: '担当者を選択してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'68%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'select',
      label: '社内営業担当者',
      labelRequired: true,
      inputName: 'salesManagerUserId',
      initialValue: userList?.map(m=>m?.value),
      selectBoxOptions: userList?.map(m=>m?.label),
      placeholder: ' 担当者を選択してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'68%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'select',
      label: '支払条件',
      labelRequired: true,
      inputName: 'payTermsId',
      initialValue: paymentList.filter(p=>p?.value == payTermsId).map(p=>p?.value)
          .concat(paymentList.filter(p=>p?.value != payTermsId).map(p=>p?.value)),
      selectBoxOptions: paymentList.filter(p=>p?.value == payTermsId).map(p=>p.label)
          .concat(paymentList.filter(p=>p?.value != payTermsId).map(p=>p?.label)),
      placeholder: ' 支払条件を選択してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'42%', minWidth: checkIsSp() ? '100%':'500px'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'radioButton',
      radioButtonTitle: '受注ステータス',
      labelRequired: true,
      radioButtonLabelAndChecked: [
        {label: '見積中', checked: orderStatus == 0},
        {label: "受注済", checked: orderStatus == 1},
        {label: "失注", checked: orderStatus == 2}
      ],
      inputName: 'orderStatus',
      initialValue: [0,1,2],
      disabled: (!isEdit && !matterNumber),
      className: { areaClass: '', },
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'596px'},
        labelStyle: {width: '232px'},
        inputStyle: {gap: checkIsSp()?'16px':'40px'},
        displayStyle: {width: '84px'},
      }
    },
    {
      inputType: 'radioButton',
      radioButtonTitle: '請求タイミング',
      labelRequired: true,
      radioButtonLabelAndChecked: [
        {label: '一括請求', checked: invoiceTiming == 0},
        {label: "分割請求", checked: invoiceTiming == 1}
      ],
      inputName: 'invoiceTiming',
      initialValue: [0,1],
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'596px'},
        labelStyle: {width: '232px'},
        inputStyle: {gap: checkIsSp()?'16px':'40px'},
        displayStyle: {width: '84px'},
      }
    },
    {
      inputType: 'inputDate',
      label: '請求日',
      labelRequired: true,
      inputName: 'billedDate',
      initialValue: '',
      placeholder: 'YYYY/MM/DD',
      disabled: (!isEdit && !matterNumber),
      style: {
        labelStyle: {width: checkIsSp() ? '100%':'232px'},
        areaStyle: checkIsSp() ? {width:'165px'}:{},
      }
    },
    {
      inputType: 'inputDate',
      label: '案件最終実施日',
      inputName: 'matterLastDate',
      initialValue: '',
      placeholder: 'YYYY/MM/DD',
      disabled: (!isEdit && !matterNumber),
      style: {
        labelStyle: {width: checkIsSp() ? '100%':'232px'},
        areaStyle: checkIsSp() ? {width:'165px'}:{},
      }
    },
    {
      inputType: 'radioButton',
      radioButtonTitle: '支払方法',
      labelRequired: true,
      radioButtonLabelAndChecked: [
        {label: '銀行振込', checked: payMethod == 0},
        {label: "口座振替", checked: payMethod == 1},
        {label: "クレジットカード", checked:  payMethod == 2},
        {label: '現金支払', checked: payMethod == 3},
        {label: "代金引換", checked: payMethod == 4},
        {label: "コンビニ支払", checked: payMethod == 5},
        {label: "郵便振替", checked: payMethod == 6},
      ],
      inputName: 'payMethod',
      initialValue: [0,1,2,3,4,5,6],
      disabled: (!isEdit && !matterNumber),
      className: {
        displayClass: 'invoice_register--radio',
      },
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'850px'},
        labelStyle: {width: checkIsSp() ? '100%':'232px'},
        inputStyle: {columnGap: '12px'},
        displayStyle: checkIsSp()? {width:'140px',marginLeft:'0'}: {width: '84px'},
      }
    },
    {
      inputType: 'select',
      label: '支払先口座',
      labelRequired: true,
      inputName: 'bankMasterId',
      initialValue: bankingList?.map(b=>b?.value),
      selectBoxOptions: bankingList?.map(b=>b?.label),
      placeholder: ' 支払先口座を選択してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'42%', minWidth: checkIsSp() ? '100%':'500px'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'select',
      label: '案件カテゴリ',
      labelRequired: true,
      inputName: 'matterCategory',
      initialValue: PromotionCategoryList?.map(p=>p?.value),
      selectBoxOptions: PromotionCategoryList?.map(p=>p?.label),
      disabled: (!isEdit && !matterNumber),
      placeholder: '案件種別を選択してください',
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'42%', minWidth: checkIsSp() ? '100%':'500px'},
        labelStyle: {width: '232px'},
      },
    },
    {
      inputType: 'input',
      inputName: 'matterCategoryOther',
      disabled:  (!isEdit && !matterNumber) || matterCategory != "12",
      initialValue: '',
      placeholder: '案件カテゴリを入力してください',
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'44.5%'},
        inputStyle: { minWidth: checkIsSp()?'100%':'274px' },
      }
    },
    {
      inputType: 'select',
      label: '業界',
      labelRequired: true,
      inputName: 'matterIndustryPrimary',
      initialValue: matterCategoryList?.map(e => e.largeClassId),
      selectBoxOptions: matterCategoryList?.map(
          e => `${e.largeClassId}:${e.largeClassName}`),
      placeholder: '大分類を選択してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'42%', minWidth: checkIsSp() ? '100%':'500px'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'input',
      inputName: 'matterIndustryPrimaryOther',
      disabled: (!isEdit && !matterNumber) || matterIndustryPrimary != "017",
      initialValue: '',
      placeholder: '中分類を入力してください',
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'44.5%'},
        inputStyle: { minWidth: checkIsSp() ? '100%':'274px' },
      }
    },
    {
      inputType: 'select',
      inputName: 'matterIndustrySecondary',
      initialValue: matterCategoryList?.find(e => e?.largeClassId === matterIndustryPrimary)?.
              middleClass?.map(p=>p?.middleClassId),
      selectBoxOptions: matterCategoryList?.find(e => e?.largeClassId === matterIndustryPrimary)?.
              middleClass?.map(e=>`${e?.middleClassId}:${e?.middleClassName}`),
      placeholder: '中分類を選択してください',
      disabled: (!isEdit && !matterNumber) || matterIndustryPrimary == "017",
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'44.5%'},
        inputStyle: { minWidth: checkIsSp() ? '100%':'274px' },
      }
    },
    {
      inputType: 'select',
      label: namingCategoryList?.filter(n=> n.namingId === `PromotionPreliminary1`).map(p=>p?.label),
      inputName: 'preliminary1',
      initialValue: namingList?.filter(n=>
          n.namingClassificationId == 'PromotionPreliminary1').map(p=>p?.value),
      selectBoxOptions: namingList?.filter(n=>
          n.namingClassificationId == 'PromotionPreliminary1').map(p=>p?.label),
      placeholder: ' 選択してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'87%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'select',
      label: namingCategoryList?.filter(n=> n.namingId === `PromotionPreliminary2`).map(p=>p?.label),
      inputName: 'preliminary2',
      initialValue: namingList?.filter(n=>
          n.namingClassificationId === 'PromotionPreliminary2').map(p=>p?.value),
      selectBoxOptions: namingList?.filter(n=>
          n.namingClassificationId === 'PromotionPreliminary2').map(p=>p?.label),
      placeholder: ' 選択してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'87%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'select',
      label: namingCategoryList?.filter(n=> n.namingId === `PromotionPreliminary3`).map(p=>p?.label),
      inputName: 'preliminary3',
      initialValue: namingList?.filter(n=>
          n.namingClassificationId === 'PromotionPreliminary3').map(p=>p?.value),
      selectBoxOptions: namingList?.filter(n=>
          n.namingClassificationId === 'PromotionPreliminary3').map(p=>p?.label),
      placeholder: ' 選択してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'87%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'select',
      label: namingCategoryList?.filter(n=> n.namingId === `PromotionPreliminary4`).map(p=>p?.label),
      inputName: 'preliminary4',
      initialValue: namingList?.filter(n=>
          n.namingClassificationId === 'PromotionPreliminary4').map(p=>p?.value),
      selectBoxOptions: namingList?.filter(n=>
          n.namingClassificationId === 'PromotionPreliminary4').map(p=>p?.label),
      placeholder: ' 選択してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'87%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'select',
      label: namingCategoryList?.filter(n=> n.namingId === `PromotionPreliminary5`).map(p=>p?.label),
      inputName: 'preliminary5',
      initialValue: namingList?.filter(n=>
          n.namingClassificationId == 'PromotionPreliminary5').map(p=>p?.value),
      selectBoxOptions: namingList?.filter(n=>
          n.namingClassificationId == 'PromotionPreliminary5').map(p=>p?.label),
      disabled: (!isEdit && !matterNumber),
      placeholder: ' 選択してください',
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'87%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'textBox',
      label: '備考',
      inputName: 'note',
      initialValue: '',
      placeholder: '備考を入力してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'87%'},
        labelStyle: {width: '232px'},
      }
    },
    {
      inputType: 'label',
      label: '請求書No',
      labelRequired: true,
      inputName: 'invoiceViewStatus',
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'68%'},
        labelStyle: {width: '232px'},
      }
    },
  ]
  const officeMasterInfo =
    {
      inputType: 'select',
      label: '請求書表示住所',
      inputName: 'branchNumber',
      initialValue: officeMasters?.map(p=>p?.value),
      selectBoxOptions: officeMasters?.map(p=>p.label),
      placeholder: ' 事業所名を選択してください',
      disabled: (!isEdit && !matterNumber),
      style: {
        areaStyle: {width: checkIsSp() ? '100%':'42%', minWidth: checkIsSp() ? '100%':'500px'},
        labelStyle: {width: '232px'},
      }
    };

  const invoiceRegistrationNumberInfo = {
    key: 'invoiceRegistrationNumber',
    formik: formik,
    inputType: 'input',
    label: '登録番号',
    inputName: 'invoiceRegistrationNumber',
    placeholder: '適格請求書発行事業者登録番号を入力してください',
    style: {
      areaStyle: {width: checkIsSp() ? '100%':'42%', minWidth: checkIsSp() ? '100%':'500px'},
      labelStyle: {width: '232px'},
    }
  }

  useEffect(()=>{
    dispatch(invoiceDetailGetInfo({corporateId, deletedFlag: 0}));
    location?.state?.id &&
      dispatch(invoiceDetail({invoiceNumber: location?.state?.id})).unwrap().then((result)=>{
        formik.setValues({
          invoiceViewStatus: result?.detail?.quoteNumber
              ? `${result?.detail?.invoiceNumber}  (見積No.${result?.detail?.quoteNumber})`
              : `${result?.detail?.invoiceNumber}  (案件No.${result?.detail?.matterNumber})`,
          matterNumber: result?.detail?.matterNumber,
          matterName: result?.detail?.matterName,
          invoiceNumber: result?.detail?.invoiceNumber,
          clientId: result?.detail?.clientId,
          clientManagerName: result?.detail?.clientManagerName,
          matterManagerUserId: result?.detail?.matterManagerUserId,
          salesManagerUserId: result?.detail?.salesManagerUserId,
          payTermsId: result?.detail?.payTermsId,
          invoiceRegistrationNumber: result?.detail?.invoiceRegistrationNumber,
          orderStatus: result?.detail?.orderStatus,
          invoiceTiming: result?.detail?.invoiceTiming,
          billedDate:result?.detail?.billedDate,
          matterLastDate: result?.detail?.matterLastDate,
          payMethod: result?.detail?.payMethod,
          bankMasterId: result?.detail?.bankMasterId,
          matterCategory: result?.detail?.matterCategory,
          matterCategoryOther: result?.detail?.matterCategoryOther,
          matterIndustryPrimary: result?.detail?.matterIndustryPrimary,
          matterIndustryPrimaryOther: result?.detail?.matterIndustryPrimaryOther,
          matterIndustrySecondary: result?.detail?.matterIndustrySecondary,
          preliminary1: result?.detail?.preliminary1,
          preliminary2: result?.detail?.preliminary2,
          preliminary3: result?.detail?.preliminary3,
          preliminary4: result?.detail?.preliminary4,
          preliminary5: result?.detail?.preliminary5,
          note: result?.detail?.note,
          createdUser: result?.detail?.createdUser,
          updatedUser: result?.detail?.updatedUser,
          branchNumber: result?.detail?.branchNumber,
        });
      });
  },[])
  useEffect(()=>{
    if(matterNumber && !location?.state?.id){
      dispatch(fetchMatterDetail({matterNumber: matterNumber})).unwrap().then((result)=>{
        handlerFormikFieldChange(formik,'matterName',result?.item?.matterName);
        handlerFormikFieldChange(formik,'clientId',result?.item?.clientId);
        handlerFormikFieldChange(formik,'clientManagerName',result?.item?.clientManagerUserName);
        handlerFormikFieldChange(formik,'matterManagerUserId',result?.item?.matterManagerUserId);
        handlerFormikFieldChange(formik,'salesManagerUserId',result?.item?.salesManagerUserId);
        handlerFormikFieldChange(formik,'matterLastDate',result?.item?.matterLastDate);
        handlerFormikFieldChange(formik,'matterCategory',result?.item?.matterCategory);
        handlerFormikFieldChange(formik,'matterCategoryOther',result?.item?.matterCategoryOther);
        handlerFormikFieldChange(formik,'matterIndustryPrimary',result?.item?.matterIndustryPrimary);
        handlerFormikFieldChange(formik,'matterIndustryPrimaryOther',result?.item?.matterIndustryPrimaryOther);
        handlerFormikFieldChange(formik,'matterIndustrySecondary',result?.item?.matterIndustrySecondary);
        handlerFormikFieldChange(formik,'preliminary1',result?.item?.preliminary1);
        handlerFormikFieldChange(formik,'preliminary2',result?.item?.preliminary2);
        handlerFormikFieldChange(formik,'preliminary3',result?.item?.preliminary3);
        handlerFormikFieldChange(formik,'preliminary4',result?.item?.preliminary4);
        handlerFormikFieldChange(formik,'preliminary5',result?.item?.preliminary5);
        handlerFormikFieldChange(formik,'orderStatus',1);
        handlerFormikFieldChange(formik,'invoiceTiming',0);
        handlerFormikFieldChange(formik,'payMethod',0);
        if(bankingList.length > 0) {
          handlerFormikFieldChange(formik, 'bankMasterId', bankingList[0]?.bankMasterId);
        }
        if(result?.item?.matterDate.length > 0){
          handlerFormikFieldChange(formik,'matterLastDate',
              result?.item?.matterDate[result?.item?.matterDate.length - 1].matterDate);
        }

      });
    }
  },[matterNumber])
  useEffect(()=>{
    if(clientId && !location?.state?.id){
      dispatch(clientDetailFetch({
        clientId: clientId,
        deletedFlag: '0',
      })).unwrap().then((result)=>{
        handlerFormikFieldChange(formik,'payTermsId',
            paymentList.filter(p=>
                p?.value == result?.detail?.payTermId ?? "".toString()).length > 0
                  ? result?.detail?.payTermId
                  : null
        );
      })
    }
  },[clientId])
  useEffect(()=>{
    matterCategory != "12" && handlerFormikFieldChange(formik,'matterCategoryOther','');
  },[matterCategory])
  useEffect(()=>{
    matterIndustryPrimary !== "017"
      ? handlerFormikFieldChange(formik,'matterIndustryPrimaryOther','')
      : handlerFormikFieldChange(formik,'matterIndustrySecondary',null);
  },[matterIndustryPrimary])
  const ClaimRegisterInfo = () => (
      <div style={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '20px',
        marginTop: '26px',
      }}>
        {invoiceNumber
            ? <CustomFormikInput formik={formik} {...InvoiceInfos[24]}/>
            : <CustomFormikInput formik={formik} {...InvoiceInfos[0]}/>}
        <CustomFormikInput formik={formik} {...InvoiceInfos[1]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[2]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[3]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[4]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[5]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[6]}/>
        <CustomFormikInput formik={formik} {...officeMasterInfo} />
        <CustomFormikInput formik={formik} {...invoiceRegistrationNumberInfo} />
        <CustomFormikInput formik={formik} {...InvoiceInfos[7]}/>
        <CustomFormikInput
            formik={formik}
            onChange={()=> {
              handlerFormikFieldChange(formik, 'invoiceTiming', invoiceTiming === 1 ? 0 : 1);
              handlerFormikFieldChange(formik, 'billedDate', []);
            }}
            {...InvoiceInfos[8]}
        />
        {billedDate.length > 0
            ? billedDate.map((_, i)=> {
                return(
                    <div
                        style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
                        key={i}
                    >
                      <CustomFormikInput
                          formik={formik}
                          label= {i == 0 ? '請求日':' '}
                          inputType="inputDate"
                          inputName={`billedDate.[${i}]`}
                          otherErrorInputName={['billedDateDulpicate']}
                          placeholder="YYYY/MM/DD"
                          disabled={!isEdit && !matterNumber}
                          // disabledDate={moment().format('YYYY/MM/DD')}
                          style={{labelStyle: {width: checkIsSp() ? '100%':'232px'},areaStyle: checkIsSp() ? {width:'165px'}:{},}}
                          labelRequired={i == 0 ? true:false}
                      />
                      {invoiceTiming===1 && (
                        i === billedDate.length-1 ? (
                            <AddLineButton
                                title="追加"
                                onClick={() =>
                                    billedDate.length > 4
                                        ? ''
                                        : handlerFormikFieldChange(formik,
                                            'billedDate',
                                            [...billedDate, ''])
                                }
                            />
                        ) : (
                            <>
                              <DeleteIcon
                                  title="削除"
                                  onClick={() =>
                                      handlerFormikFieldChange(formik,'billedDate', deleteArrayItem(billedDate, i))
                                  }
                                  classFlag={false}
                              />
                            </>
                        ))
                      }
                    </div>
                )})
            : (<div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <CustomFormikInput
                      formik={formik}
                      label="請求日"
                      inputType="inputDate"
                      inputName={`billedDate.[0]`}
                      otherErrorInputName={['billedDateDulpicate']}
                      placeholder="YYYY/MM/DD"
                      disabled={!isEdit && !matterNumber}
                      // disabledDate={moment().format('YYYY/MM/DD')}
                      style={{labelStyle: {width: checkIsSp() ? '100%':'232px'},areaStyle: checkIsSp() ? {width:'165px'}:{},}}
                      labelRequired={true}
                  />
                  {invoiceTiming===1 && (
                      <AddLineButton
                          onClick={() =>
                              billedDate.length > 4
                                  ? ''
                                  : handlerFormikFieldChange(formik,
                                      'billedDate',
                                      [...billedDate, ''])
                          }
                      />
                  )}
                </div>)
        }
        <CustomFormikInput formik={formik} {...InvoiceInfos[10]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[11]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[12]}/>
        <div className={'invoice_register--matter_category_area'}>
          <CustomFormikInput formik={formik} {...InvoiceInfos[13]}/>
          <CustomFormikInput formik={formik} {...InvoiceInfos[14]}/>
        </div>
        <div className={'invoice_register--matter_category_area'}>
          <CustomFormikInput formik={formik} {...InvoiceInfos[15]}/>
          {matterIndustryPrimary === "017"
              ? (<CustomFormikInput formik={formik} {...InvoiceInfos[16]}/>)
              : (<CustomFormikInput formik={formik} {...InvoiceInfos[17]}/>)
          }
          {/*<CustomFormikInput formik={formik} {...InvoiceInfos[17]}/>*/}
        </div>
        <CustomFormikInput formik={formik} {...InvoiceInfos[18]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[19]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[20]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[21]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[22]}/>
        <CustomFormikInput formik={formik} {...InvoiceInfos[23]}/>
      </div>
  );

  const infos = {
    updates: isEdit
        ? [
          {
            label: '新規登録:',
            datetime:
                moment(createdUser?.datetime).format(
                    'YYYY/MM/DD HH:mm:ss'
                ) ?? '-',
            name: createdUser?.name ?? '-',
          },
          {
            label: '最終更新:',
            datetime:
                moment(updatedUser?.datetime).format(
                    'YYYY/MM/DD HH:mm:ss'
                ) ?? '-',
            name: updatedUser?.name ?? '-',
          },
        ]
        : [],
    detail: {
      tabs: {
        common: {
          label: '請求書情報',
          // inputs: [{}, {}],
          jsx: ClaimRegisterInfo,
        }
      },
    },
  };

  return (
      <>

        <RegisterBase image={Icons.test.account} imageShape={'round'} infos={infos} />

        <div className={'invoice_register--button_area'}>

          <div className={'invoice_register--submit_buttons'}>
            <Button
                text={`${isEdit ? "更新" : "登録"}して戻る`}
                style={Options.buttonStyles.back}
                onClick={() => {
                  handlerFormikSubmit(formik, tabErrorGroup);
                  dispatch(invoiceActions.setNavigation(-1))
                }}
            />
            <Button
                text={`${isEdit ? "更新" : "登録"}して書類へ`}
                style={Options.buttonStyles.submit}
                onClick={() => {
                  handlerFormikSubmit(formik, tabErrorGroup);
                  dispatch(invoiceActions.setNavigation('/invoice/create'))
                }
            } />
          </div>
        </div>
      </>
  );
};

export default InvoiceRegister;

