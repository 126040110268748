import './style.scss';
import { createElement, useState } from 'react';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Copyright from '../../../components/copyright';
import store from '../../../redux/store';
import { loginFormOnSubmit } from '../../../components/customForm/submitFunctions';
import { loginFormSchema } from '../../../utils/schemas';
import { Router, useNavigate } from 'react-router-dom';
import Options from '../../../constants/options';
import CsvImportModal from '../../../components/modal/csvImportModal';
import CsvExportModal from '../../../components/modal/csvExportModal';
import { testData } from '../../../components/listView/testData';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { useEffect } from 'react';
import { forEach } from 'lodash';
import { object } from 'yup';
import { fetchApi } from '../../../api/fetch';
import { useDispatch, useSelector } from 'react-redux';
import { setCounter } from '../../../redux/slice/counter';
import { useFormik } from 'formik';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import {
  userActions,
  userDelete,
  userInit,
  userListFetch,
} from '../../../redux/slice/user';
import options from '../../../constants/options';
import { checkIsSp } from '../../../utils/fnUtil';

const UserList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountInfo = useSelector(state => state.account);
  const authParam = options.codes.authority
    .filter(v => v?.value >= accountInfo.auth)
    .map(p => p?.value);
  const {
    namingCategoryList,
    namingList,
    deptList,
    userList,
    searchParams,
    csvImportVisible,
    csvExportVisible,
  } = useSelector(state => state.user);
  const counterState = useSelector(state => state.counter);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => dispatch(userActions.saveSearchParams(values)),
  });
  const {
    namingClassificationId,
    sortKey: sortColumn,
    sortMethod,
  } = formik.values;

  const searchInfos = {
    inputs: [
      {
        inputType: 'input', //inputのtype
        label: '社員ID', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'userId', //DBのカラム名
        placeholder: '社員IDを入力してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '258px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'input', //inputのtype
        label: '名前', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'name', //DBのカラム名
        placeholder: '名前を入力してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '300px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'select', //inputのtype
        label: '部署', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'deptId',
        initialValue: deptList?.map(p => p?.value),
        selectBoxOptions: deptList?.map(p => p?.label),
        showSearch: false,
        placeholder: '部署を選択してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '260px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'input', //inputのtype
        label: 'メールアドレス', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'mailAddress', //DBのカラム名
        placeholder: 'メールアドレスを入力してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '300px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'select', //inputのtype
        label: '権限', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'auth',
        initialValue: options.codes.authority
          .filter(v => v?.value >= accountInfo.auth)
          .map(p => p?.value),
        selectBoxOptions: options.codes.authority
          .filter(v => v?.value >= accountInfo.auth)
          .map(p => p?.label),
        selectedSelectBox: null,
        showSearch: false,
        placeholder: '権限を選択してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '260px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'select', //inputのtype
        label: '予備項目', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'namingClassificationId',
        initialValue: namingCategoryList.map(p => p?.value),
        selectBoxOptions: namingCategoryList.map(p => p?.label),
        showSearch: false,
        placeholder: '予備項目を選択してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '230px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'select', //inputのtype
        label: '', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        mode: 'multiple',
        allowClear: false,
        disabled: !namingClassificationId,
        inputName: 'namingId',
        initialValue: namingList
          ?.filter(n => n?.namingClassificationId === namingClassificationId)
          .map(p => p?.value),
        selectBoxOptions: namingList
          ?.filter(n => n?.namingClassificationId === namingClassificationId)
          .map(p => `${p.namingId}:${p?.label}`),
        placeholder: '予備項目を選択してください',
        showSearch: false,
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: {
            width: checkIsSp() ? '100%' : '291px',
            marginLeft: checkIsSp() ? '0px' : '-20px',
          },
          labelStyle: {},
          inputStyle: {},
        },
      },
      {
        inputType: 'select', //inputのtype
        label: 'ステータス', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        showSearch: false,
        allowClear: false,
        inputName: 'status',
        initialValue: options.codes.userStatus.map(p => p?.value),
        selectBoxOptions: options.codes.userStatus.map(p => p?.label),
        selectedSelectBox: formik?.values?.status,
        placeholder: 'ステータスを選択してください',
        unit: '', //インプット横の単位
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '150px' },
          labelStyle: {},
          inputStyle: {},
        },
      },
    ],
  };
  const userListSearchArea = () => (
    <div className={'user_list--input_area'}>
      <CustomFormikInput
        {...searchInfos.inputs[0]}
        formik={formik}
        key={searchInfos.inputs[0].inputName}
      />
      <CustomFormikInput
        {...searchInfos.inputs[1]}
        formik={formik}
        key={searchInfos.inputs[1].inputName}
      />
      <CustomFormikInput
        {...searchInfos.inputs[2]}
        formik={formik}
        key={searchInfos.inputs[2].inputName}
      />
      <CustomFormikInput
        {...searchInfos.inputs[3]}
        formik={formik}
        key={searchInfos.inputs[3].inputName}
      />
      <CustomFormikInput
        {...searchInfos.inputs[4]}
        formik={formik}
        key={searchInfos.inputs[4].inputName}
      />
      <div className={'user_list--input_area'}>
        <CustomFormikInput
          {...searchInfos.inputs[5]}
          formik={formik}
          key={searchInfos.inputs[5].inputName}
          onChange={value => {
            handlerFormikFieldChange(
              formik,
              searchInfos.inputs[5].inputName,
              value
            );
            handlerFormikFieldChange(
              formik,
              searchInfos.inputs[6].inputName,
              []
            );
          }}
        />
        <CustomFormikInput
          {...searchInfos.inputs[6]}
          formik={formik}
          key={searchInfos.inputs[6].inputName}
          disabled={!namingClassificationId}
        />
      </div>
      <CustomFormikInput
        {...searchInfos.inputs[7]}
        formik={formik}
        key={searchInfos.inputs[7].inputName}
      />
    </div>
  );
  const searchFormInfo = {
    jsx: userListSearchArea,
  };

  const columnSettings = {
    userId: { label: '社員ID', sort: 'asc', dataName: 'userId' },
    image: { label: '画像', sort: 'none', dataName: 'userImage' },
    name: { label: '名前', sort: 'none', dataName: 'userName' },
    dept: { label: '部署', sort: 'none', dataName: 'deptId' },
    mail: { label: 'メールアドレス', sort: 'none', dataName: 'mailAddress' },
    authority: { label: '権限', sort: 'none', dataName: 'userAuth' },
    preliminary1: {
      label: `${namingCategoryList
        .filter(n => n?.namingId === 'UserPreliminary1')
        .map(p => p?.naming)}`,
      sort: 'none',
      dataName: 'preliminary',
    },
    status: { label: 'ステータス', sort: 'none', dataName: 'status' },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    import: {
      icon: Icons.icon.import,
      text: 'CSV取込',
      onClick: () => dispatch(userActions.setCsvImportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => dispatch(userActions.setCsvExportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    addButton: {
      props: {
        type: '', //roundを指定するとボタンがまるくなる
        onClick: () => {
          navigate('/master/user/register');
        }, //ボタンをクリックしたときにfunctionが発火する（urlより優先される）
        url: '', //ボタンをクリックした時の遷移先を指定する「'/main/invoice/list'」
        displayText: true, // trueを指定すると「新規追加」吹き出しが表示される
        disabled: '', //trueを指定するとdisableになる
        // style: {backgroundColor: '#FFFFFF'}, //styleを指定することが可能になる
      },
    },
  };

  // ソート処理
  const changeSort = e => {
    if (e.target.id === 'preliminary1') {
      e.target.id = 'preliminary';
    }

    dispatch(
      userActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`,
      })
    );
  };
  const handleChangeListPerPage = num => {
    dispatch(userActions.saveSearchParams({ count: num }));
  };

  const handleChangePage = num => {
    dispatch(userActions.saveOffset(num));
  };
  // データ取得
  useEffect(() => {
    dispatch(userInit());
    const searchBase =document.querySelector('.list_view--search_base');
    searchBase.style.zIndex = 2;
  }, []);

  useEffect(() => {
    dispatch(
      userListFetch({ ...searchParams, auth: searchParams.auth ?? authParam })
    );
  }, [dispatch, searchParams]);
  useEffect(() => {
    dispatch(
      setCounter({ ...counterState, userCounter: userList?.topCount ?? [0, 0] })
    );
  }, [userList]);

  return (
    <>
      <div className={'commonPage--base'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'userId'}
          primaryName={'name'}
          apiData={userList}
          sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={handleChangeListPerPage}
          onChangeOffset={handleChangePage}
          onDelete={userId => {
            dispatch(userDelete({ targetUserId: Number(userId) }));
          }}
        />
        <Copyright />
      </div>

      <CsvImportModal
        importType={'userList'}
        csvImportVisible={csvImportVisible}
        setCsvImportVisible={() =>
          dispatch(userActions.setCsvImportVisible(!csvImportVisible))
        }
      />

      <CsvExportModal
        exportType={'userList'}
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={() =>
          dispatch(userActions.setCsvExportVisible(!csvExportVisible))
        }
        exportFunction={() => {}}
        searchParams={searchParams}
      />
    </>
  );
};

export default UserList;
