import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions } from '../../../../../redux/slice/quote';
import { Table } from 'antd';
import CustomFormikInput from '../../../../../components/customInput/customFormikInput';
import './style.scss';

const data = [
  {
    key: '1',
    note: '',
  },
];
const { Column } = Table;

const TableFooter = ({ formik, getVal }) => {
  const dispatch = useDispatch();
  const { templateBody } = useSelector(state => state.quote);
  return (
    <div className="table_footer">
      <Table pagination={false} dataSource={data}>
        <Column title="" key="null1" width={72} />
        <Column
          title="備考"
          key="note"
          width={1120}
          render={() => (
            <div
              onChange={() => {
                dispatch(quoteActions.setTemplateBody(getVal(templateBody)));
              }}
            >
              <CustomFormikInput
                inputType="textBox"
                formik={formik}
                inputName="note"
              />
            </div>
          )}
        />
        <Column title="" key="null1" width={76} />
      </Table>
    </div>
  );
};

export default TableFooter;
