import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest, fetchMultiRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import { globalActions } from './global';
import options from '../../constants/options';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { store } from '../store';

const initialQuoteDocument = {
  quoteNumber: '',
  matterName: '', // 案件名
  clientId: '', // 取引先ID
  quoteDocumentBranchNumber: '', //見積書枝番
  quoteDocumentDate: '', // 見積日
  quoteDocumentLimit: '', // 有効期限
  payTermsId: '', // 支払条件ID
  // 法人情報
  corporateInfo: {
    name: '',
    // 郵便番号
    postCode: {
      number1: '',
      number2: '',
    },
    prefCode: '', // 都道府県
    cityName: '', // 市区町村
    streetName: '', // 街
    buildingName: '', // 建物名
    // 電話
    tel: {
      number1: '',
      number2: '',
      number3: '',
    },
    fax: {
      number1: '',
      number2: '',
      number3: '',
    },
    quoteAmountViewFlag: 0, // 見積請求金額表示
  },
  quoteAmount: 0, // 見積合計金額
  quoteAmountTotal: 0, // 見積そう合計
  quoteAmountTax: 0, // 税金
  calculationTaxFlag: 0, // 消費税計算
  // 見積Bodyの配列
  quoteBody: options.codes.quoteBody.concat(
    options.codes.quoteBodyTotal.map((o, i) => {
      return { ...o, rowIndex: i + 2 };
    })
  ),
  stampMessage: '', // 捺印申請メッセージ
  stampLimitDate: '', // 捺印期限
  stampDatetime: '', // 捺印日時
  approvalSteps: [], // 承認ステータス
  note: '', //,
  message: '', //　承認否認メッセージ
  stampFlag: false,
  applyUserList: [],
};

// 見積テンプレート　登録編集
const templateEdit = {
  templateId: null,
  templateType: 0,
  templateName: '',
  templateBody: [
    {
      rowIndex: 1,
      rowType: 1,
      detail: null,
      quantity: null,
      unit: null,
      amount: null,
    },
  ],
  note: '',
};
// 見積テンプレートボディ
// const templateBody = [
//   {
//     rowIndex: 1,
//     rowType: 1,
//     detail: null,
//     quantity: null,
//     unit: null,
//     amount: null,
//   },
// ];

const templateBody = [...new Array(10)].map((v, i) => {
  return {
    rowIndex: 1 + i,
    rowType: 1,
    detail: null,
    quantity: null,
    unit: null,
    amount: null,
  };
});

// 見積一覧　詳細
const quoteDetail = {
  clientId: null,
  clientManagerName: '',
  matterName: '',
  managerUserId: null,
  managerDeptId: '',
  approvalFlowId: null,
  payTermsId: null,
  orderStatus: 1,
  invoiceTiming: 0,
  invoiceNumber: null,
  invoicePlanDates: [],
  matterLastDate: '',
  paymentMethod: 0,
  bankMasterId: null,
  matterCategory: {
    category: '',
    categoryOther: '',
    industryPrimary: '',
    industryPrimaryOther: '',
    industrySecondary: '',
  },
  preliminary1: null,
  preliminary2: null,
  preliminary3: null,
  preliminary4: null,
  preliminary5: null,
  note: '',
};
// 承認フロー　詳細
const flowDetail = {
  approvalFlowName: '',
  availableDept: [],
  availableUser: [],
  step1Approver: [],
  step2Approver: [],
  step3Approver: [],
  step4Approver: [],
  stepApprover: [[]],
};
// メール送信
const mailParams = {
  quoteNumber: null,
  toAddress: '',
  ccAddress: '',
  subject: '',
  message: '',
  attendFiles: [],
};

const initialState = {
  quoteNumberId: null,
  salesTaxList: [],
  templateList: [],
  quoteDocument: initialQuoteDocument,
  calculation: false,
  deleteFlag: false,
  isEdit: false,
  approvalModalFlag: false,
  denialModalFlag: false,
  approvalApplyModalFlag: false,
  pdfOutputFlag: false,
  navigation: null,
  templateEdit: templateEdit,
  quoteDetail: quoteDetail,
  templateListParams: [],
  flowList: [],
  flowDetail: flowDetail,
  stampFlag: false,
  mailFlag: false,
  mailParams: mailParams,
  selOpt: {},
  templateBody: templateBody,
  deptList: [],
  quoteList: [],
  listSelOpt: {},
  namingSelOpt: [],
  upFile: [],
  replaceInfo: [],
  confirmModalVisible: false,
  applyModalVisible: false,
  totalStepUserList: [],
  stepNow: 0,
  officeMasters: [],
  isCreateUserSkipFlag: false,
};

// 見積書作成　詳細取得
export const quoteDocumentInit = createAsyncThunk(
  'quote/document/init',
  async params => {
    const [quoteRes, templateRes, salesTaxRes, corpRes, paymentRes] =
      await Promise.all([
        fetchRequest(apiUrl.quote.documentDetail, params),
        fetchRequest(apiUrl.quote.template),
        fetchRequest(apiUrl.salesTax.list, {sort: 'salesTaxIdDesc'}),
        fetchRequest(apiUrl.corporate.detail, params),
        fetchRequest(apiUrl.payment.list),
      ]);
    const applyFlow = await fetchRequest(apiUrl.quote.approvalDetail, {
      approvalFlowId: quoteRes?.detail?.approvalFlowId,
    });
    const userRes = await fetchRequest(apiUrl.user.detail, {
      userId: quoteRes?.detail?.createdUser?.id, deletedFlag: '0'
    }).then(data => data?.skipQuoteApprovalFlag === 1).catch(() => false);

    var stepApprover;
    switch (quoteRes?.detail?.approvalStatus) {
      case 1:
        stepApprover = applyFlow?.detail?.step1Approver?.map(a => {
          return a?.userId;
        });
        break;
      case 2:
        stepApprover = applyFlow?.detail?.step2Approver?.map(a => {
          return a?.userId;
        });
        break;
      case 3:
        stepApprover = applyFlow?.detail?.step3Approver?.map(a => {
          return a?.userId;
        });
        break;
      case 4:
        stepApprover = applyFlow?.detail?.step4Approver?.map(a => {
          return a?.userId;
        });
        break;
      default:
        break;
    }
    const templateList = templateRes?.item?.map(t => {
      return {
        ...t,
        label: `${t?.templateId}: ${t?.templateName}`,
        value: t?.templateId,
      };
    });
    const salesTaxList = salesTaxRes?.item?.map(t => {
      return {
        ...t,
        label: `${t?.taxRate}`,
        value: t?.salesTaxId,
      };
    })
      .sort((a, b) => {
        return a.value > b.value ? -1 : 1;
      });

    const totalLine = [...new Array(3)].map((v, i) =>
      quoteRes?.detail?.quoteBody.length === 0
        ? 2 + i
        : quoteRes?.detail?.quoteBody.length + 1 + i
    );
    var quoteAmountTaxSum =
      quoteRes.detail?.quoteBody?.length === 0
        ? 0
        : quoteRes.detail?.quoteBody
            ?.filter(q => !totalLine.includes(q?.rowIndex))
            .reduce((s, q) => {
              const taxRate =
                salesTaxRes?.item?.find(t => t?.salesTaxId == q?.salesTaxId)
                  ?.taxRate ?? 1;
              return s + q?.quantity * q?.amount * taxRate;
            }, 0);
    switch (quoteRes.detail?.calculationTaxFlag) {
      case 0:
        quoteAmountTaxSum = Math.ceil(quoteAmountTaxSum / 100);
        break;
      case 1:
        quoteAmountTaxSum = Math.floor(quoteAmountTaxSum / 100);
        break;
      case 2:
        quoteAmountTaxSum = Number(quoteAmountTaxSum / 100).toFixed(0);
        break;
      default:
        break;
    }

    const totalStepUserList = () => {
      let newAry = [];
      newAry[0] = quoteRes?.detail?.createdUser?.id;
      newAry[1] = applyFlow?.detail?.step1Approver?.map(d => d.userId);
      newAry[2] = applyFlow?.detail?.step2Approver?.map(d => d.userId);
      newAry[3] = applyFlow?.detail?.step3Approver?.map(d => d.userId);
      newAry[4] = applyFlow?.detail?.step4Approver?.map(d => d.userId);
      return newAry;
    }

    const stepNow = () => {
      let num = 0;
      if (quoteRes?.detail?.approvalSteps[0]?.status === 1) {
        num = 1;
      } else if (quoteRes?.detail?.approvalSteps[1]?.status === 1) {
        num = 2;
      } else if (quoteRes?.detail?.approvalSteps[2]?.status === 1) {
        num = 3;
      } else if (quoteRes?.detail?.approvalSteps[3]?.status === 1) {
        num = 4;
      }
      return num;
    }

    return {
      salesTaxList,
      templateList,
      isEdit: quoteRes.detail?.quoteBody?.length !== 0,
      quoteDocument: {
        ...quoteRes.detail,
        quoteBody:
          quoteRes.detail?.quoteBody?.length === 0
            ? options.codes.quoteBody
                .map(q => {
                  return {
                    ...q,
                    salesTaxId:
                      salesTaxList.length > 0 ? salesTaxList[0].value : null,
                  };
                })
                .concat(
                  options.codes.quoteBodyTotal.map((o, i) => {
                    return { ...o, rowIndex: totalLine[i] };
                  })
                )
            : quoteRes.detail?.quoteBody
                .map((q, i) => {
                  return {
                    ...q,
                    index: i,
                  };
                })
                .concat(
                  options.codes.quoteBodyTotal.map((o, i) => {
                    return { ...o, rowIndex: totalLine[i] };
                  })
                ),
        calculationTaxFlag:
          quoteRes?.detail?.calculationTaxFlag === null
            ? corpRes?.detail?.calculationTaxFlag
            : quoteRes?.detail?.calculationTaxFlag,
        quoteAmountTotal:
          quoteRes?.detail?.quoteAmount ?? 0 + quoteAmountTaxSum,
        quoteAmountTax: quoteAmountTaxSum,
        payTermName: paymentRes?.item?.find(
          p => p?.payTermsId == quoteRes?.detail?.payTermsId
        )?.payTermsName,
        stampFlag: false,
        approvalSteps: [
          {
            approver: quoteRes?.detail?.createdUser?.name,
            approverCount: 1,
            datetime: quoteRes?.detail?.stampDatetime,
            message: quoteRes?.detail?.stampMessage,
            status: 3,
            step: 0,
          },
        ].concat(quoteRes?.detail?.approvalSteps),
        applyUserList: stepApprover,
        createdUser: quoteRes?.detail?.createdUser,
        totalStepUserList: totalStepUserList(),
        stepNow: stepNow(),
      },
      isCreateUserSkipFlag: userRes,
    };
  }
);

export const quoteCopyDocumentInit = createAsyncThunk(
  'quote/document/init',
  async params => {
    const newQuoteNumber = params?.newQuoteNumber;
    delete params['newQuoteNumber'];

    const [
      copyQuoteRes,
      templateRes,
      salesTaxRes,
      corpRes,
      paymentRes,
      newQuoteRes,
    ] = await Promise.all([
      fetchRequest(apiUrl.quote.documentDetail, params),
      fetchRequest(apiUrl.quote.template),
      fetchRequest(apiUrl.salesTax.list, {sort: 'salesTaxIdDesc'}),
      fetchRequest(apiUrl.corporate.detail, params),
      fetchRequest(apiUrl.payment.list),
      fetchRequest(apiUrl.quote.documentDetail, {
        ...params,
        quoteNumber: newQuoteNumber,
      }),
    ]);
    const templateList = templateRes?.item?.map(t => {
      return {
        ...t,
        label: `${t?.templateId}: ${t?.templateName}`,
        value: t?.templateId,
      };
    });
    const salesTaxList = salesTaxRes?.item?.map(t => {
      return {
        ...t,
        label: `${t?.taxRate}`,
        value: t?.salesTaxId,
      };
    })
      .sort((a, b) => {
        return a.value > b.value ? -1 : 1;
      });

    const totalLine = [...new Array(3)].map(
      (v, i) => copyQuoteRes?.detail?.quoteBody.length + 1 + i
    );
    var quoteAmountTaxSum =
      copyQuoteRes.detail?.quoteBody?.length === 0
        ? 0
        : copyQuoteRes.detail?.quoteBody
            ?.filter(q => !totalLine.includes(q?.rowIndex))
            .reduce((s, q) => {
              const taxRate =
                salesTaxRes?.item?.find(t => t?.salesTaxId == q?.salesTaxId)
                  ?.taxRate ?? 1;
              return s + q?.quantity * q?.amount * taxRate;
            }, 0);
    switch (copyQuoteRes.detail?.calculationTaxFlag) {
      case 0:
        quoteAmountTaxSum = Math.ceil(quoteAmountTaxSum / 100);
        break;
      case 1:
        quoteAmountTaxSum = Math.floor(quoteAmountTaxSum / 100);
        break;
      case 2:
        quoteAmountTaxSum = Number(quoteAmountTaxSum / 100).toFixed(0);
        break;
      default:
        break;
    }

    return {
      salesTaxList,
      templateList,
      isEdit: copyQuoteRes.detail?.quoteBody?.length !== 0,
      quoteDocument: {
        ...newQuoteRes.detail,
        quoteBody:
          copyQuoteRes.detail?.quoteBody?.length === 0
            ? options.codes.quoteBody
                .map(q => {
                  return {
                    ...q,
                    salesTaxId:
                      salesTaxList.length > 0 ? salesTaxList[0].value : null,
                  };
                })
                .concat(
                  options.codes.quoteBodyTotal.map((o, i) => {
                    return { ...o, rowIndex: totalLine[i] };
                  })
                )
            : copyQuoteRes.detail?.quoteBody
                .map((q, i) => {
                  return {
                    ...q,
                    index: i,
                  };
                })
                .concat(
                  options.codes.quoteBodyTotal.map((o, i) => {
                    return { ...o, rowIndex: totalLine[i] };
                  })
                ),
        calculationTaxFlag:
          copyQuoteRes?.detail?.calculationTaxFlag === null
            ? corpRes?.detail?.calculationTaxFlag
            : copyQuoteRes?.detail?.calculationTaxFlag,
        quoteAmountTotal:
          copyQuoteRes?.detail?.quoteAmount ?? 0 + quoteAmountTaxSum,
        quoteAmountTax: quoteAmountTaxSum,
        payTermName: paymentRes?.item?.find(
          p => p?.payTermsId == copyQuoteRes?.detail?.payTermsId
        ).payTermsName,
        stampFlag: false,
      },
    };
  }
);

export const quoteDocumentInsert = createAsyncThunk(
  'quote/document/insert',
  async params =>
    await fetchRequest(apiUrl.quote.documentInsert, params, 'POST')
);
export const quoteDocumentUpdate = createAsyncThunk(
  'quote/document/update',
  async params =>
    await fetchRequest(apiUrl.quote.documentUpdate, params, 'POST')
);
// 見積書PDF出力
export const quoteDocumentPdf = createAsyncThunk(
  'quote/document/pdf',
  async params => {
    const result = await fetchRequest(
      apiUrl.quote.documentPdf,
      params,
      'GET',
      'arraybuffer'
    );
    const blob = new Blob([result], { type: 'application/pdf' });

    if (blob.size == 0) {
      return null;
    }
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileName = `見積書_${moment().format('YYYYMMDDHHmmss')}.pdf`;
    a.download = fileName;
    a.href = url;
    a.target = '_self';
    a.click();
    a.remove();
    setTimeout(() => {
      window.open(url, '_blank');
    }, 1);
    return result;
  }
);

// 見積一覧　詳細取得
export const quoteDetailMailFetch = createAsyncThunk(
  'quote/detail/mail',
  async params => {
    const quoteRes = await fetchRequest(apiUrl.quote.detail, params);
    const userRes = await fetchRequest(apiUrl.user.detail, {
      userId: quoteRes?.detail?.managerUserId,
      deletedFlag: 0,
    });
    return {
      replaceInfo: {
        clientName: quoteRes?.detail?.clientManagerName,
        userFirstName: userRes?.userName?.firstName,
        userLastName: userRes?.userName?.lastName,
      },
    };
  }
);

// 保存前見積書PDF出力
export const quoteDocumentEditPdf = createAsyncThunk(
  'quote/document/edit/pdf',
  async params => {
    const result = await fetchRequest(
      apiUrl.quote.documentEditPdf,
      params,
      'POST',
      'arraybuffer'
    );
    const blob = new Blob([result], { type: 'application/pdf' });

    if (blob.size == 0) {
      return null;
    }
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileName = `見積書_${moment().format('YYYYMMDDHHmmss')}.pdf`;
    a.download = fileName;
    a.href = url;
    a.target = '_self';
    a.click();
    a.remove();
    setTimeout(() => {
      window.open(url, '_blank');
    }, 1);
    return result;
  }
);

// 承認状態更新
export const quoteDocumentStatus = createAsyncThunk(
  'quote/document/status/update',
  async params =>
    await fetchRequest(apiUrl.quote.documentStatusUpdate, params, 'POST')
);

// 見積テンプレート 詳細取得
export const quoteTemplateDetailFetch = createAsyncThunk(
  'quote/template/detail',
  async params => {
    const res = await fetchRequest(apiUrl.quote.templateDetail, params);
    const body = res?.detail?.templateBody
      .map((t, i) => {
        return {
          ...t,
          salesTaxId: null,
          totalAmount: null,
          salesTaxAmount: null,
          index: i,
          rowIndex: i + 1,
        };
      })
      .concat(options.codes.quoteBodyTotal);
    return {
      ...res,
      template: body,
    };
  }
);
export const quoteTemplateInfoFetch = createAsyncThunk(
  'quote/template/info',
  async params => {
    const [quoteTemplateRes] = await Promise.all([
      fetchRequest(apiUrl.template.list, params),
    ]);
    return {
      quoteTemplateRes,
    };
  }
);

// 見積テンプレート 登録
export const quoteTemplateUpdate = createAsyncThunk(
  'quote/template/update',
  async params => await fetchRequest(apiUrl.template.update, params, 'POST')
);

// 見積テンプレート 更新
export const quoteTemplateInsert = createAsyncThunk(
  'quote/template/insert',
  async params => await fetchRequest(apiUrl.template.insert, params, 'POST')
);

// 見積テンプレート 削除
export const quoteTemplateDelete = createAsyncThunk(
  'quote/template/delete',
  async params => await fetchRequest(apiUrl.template.delete, params, 'POST')
);

// 見積テンプレート 一覧取得
export const quoteTemplateListFetch = createAsyncThunk(
  'quote/template/list',
  async params => {
    const templateRes = await fetchRequest(apiUrl.template.list, params);
    return {
      templateListParams: templateRes?.item,
    };
  }
);

// 見積テンプレート 並び替え
export const quoteTemplateSort = createAsyncThunk(
  'quote/template/sort',
  async params => await fetchRequest(apiUrl.template.sort, params, 'POST')
);

// 承認フロー 一覧取得
export const quoteFlowFetch = createAsyncThunk(
  'quote/flow/list',
  async params => {
    const flowRes = await fetchRequest(apiUrl.approval.list, params);
    const item = flowRes?.item?.map(n => {
      return {
        ...n,
        deleteIconState: n?.updateLockFlag,
      };
    });
    return {
      flowList: item,
    };
  }
);

// 承認フロー 詳細取得
export const quoteFlowDetail = createAsyncThunk(
  'quote/flow/detail',
  async params => await fetchRequest(apiUrl.approval.detail, params)
);

// 承認フロー 登録
export const quoteFlowInsert = createAsyncThunk(
  'quote/flow/insert',
  async params => await fetchRequest(apiUrl.approval.insert, params, 'POST')
);

// 承認フロー 更新
export const quoteFlowUpdate = createAsyncThunk(
  'quote/flow/update',
  async params => await fetchRequest(apiUrl.approval.update, params, 'POST')
);

// 承認フロー 削除
export const quoteFlowDelete = createAsyncThunk(
  'quote/flow/delete',
  async params => await fetchRequest(apiUrl.approval.delete, params, 'POST')
);

// 見積　一覧取得
export const quoteListFetch = createAsyncThunk('quote/list', async params => {
  const quoteRes = await fetchRequest(apiUrl.quote.list, params);
  return {
    quoteList: quoteRes,
  };
});

// 見積一覧　詳細取得
export const quoteDetailFetch = createAsyncThunk(
  'quote/detail',
  async params => {
    const listData = await fetchRequest(apiUrl.quote.list, params, 'GET');
    const detailData = await fetchRequest(apiUrl.quote.detail, params, 'GET');
    const userRes = await fetchRequest(apiUrl.user.detail, {
      userId: detailData?.detail?.createdUser?.id, deletedFlag: '0'
    }).then(data => data?.skipQuoteApprovalFlag === 1).catch(() => false);

    return {
      ...detailData,
      isLock: listData?.item[0]?.deleteLockFlag,
      quoteStatus: listData?.item[0]?.approvalStatus,
      isCreateUserSkipFlag: userRes,
    };
  }
);

// 見積一覧 登録
export const quoteInsert = createAsyncThunk(
  'quote/insert',
  async params => await fetchRequest(apiUrl.quote.insert, params, 'POST')
);

// 見積一覧 更新
export const quoteUpdate = createAsyncThunk(
  'quote/update',
  async params => await fetchRequest(apiUrl.quote.update, params, 'POST')
);

// 捺印申請
export const quoteDocumentStampRequest = createAsyncThunk(
  'quote/document/stamp_request',
  async params =>
    await fetchRequest(apiUrl.quote.documentStampRequest, params, 'POST')
);

// 社員 一覧取得
export const quoteUserList = createAsyncThunk(
  'quote/user/list',
  async params => {
    const userRes = await fetchRequest(apiUrl.user.list, params);
    return {
      userList: userRes?.item,
    };
  }
);

// 名称 部署取得
export const quoteDeptList = createAsyncThunk('quote/dept/list', async () => {
  const namingRes = await fetchRequest(apiUrl.naming.list, {
    namingClassificationId: 'Dept',
    count: 999,
    sort: 'namingIdAsc',
  });
  return {
    deptList: namingRes?.item,
  };
});

// 見積書メール 送信
export const quoteDocumentMail = createAsyncThunk(
  'quote/document/mail',
  async params => await fetchRequest(apiUrl.quote.documentMail, params, 'POST')
);

// 見積No.管理
export const quoteNumUpdate = createAsyncThunk(
  'quote/number/update',
  async params => await fetchRequest(apiUrl.quote.numUpdate, params, 'POST')
);

// 見積詳細　セレクト取得
export const quoteInit = createAsyncThunk('quote/init', async params => {
  const [
    clientRes,
    corRes,
    userRes,
    paymentRes,
    matterRes,
    namingRes,
    pre1Res,
    pre2Res,
    pre3Res,
    pre4Res,
    pre5Res,
  ] = await fetchMultiRequest([
    fetchRequest(apiUrl.client.list, params?.clientList),
    fetchRequest(apiUrl.corporate.detail, params?.corporate),
    fetchRequest(apiUrl.user.list, params?.userList),
    fetchRequest(apiUrl.payment.list),
    fetchRequest(apiUrl.matter.classList),
    fetchRequest(apiUrl.naming.list, {
      namingClassificationId: 'PromotionCategory',
      sort: 'namingIdAsc',
      status: 1,
    }),
    fetchRequest(apiUrl.naming.list, {
      namingClassificationId: 'PromotionPreliminary1',
      sort: 'namingIdAsc',
      status: 1,
    }),
    fetchRequest(apiUrl.naming.list, {
      namingClassificationId: 'PromotionPreliminary2',
      sort: 'namingIdAsc',
      status: 1,
    }),
    fetchRequest(apiUrl.naming.list, {
      namingClassificationId: 'PromotionPreliminary3',
      sort: 'namingIdAsc',
      status: 1,
    }),
    fetchRequest(apiUrl.naming.list, {
      namingClassificationId: 'PromotionPreliminary4',
      sort: 'namingIdAsc',
      status: 1,
    }),
    fetchRequest(apiUrl.naming.list, {
      namingClassificationId: 'PromotionPreliminary5',
      sort: 'namingIdAsc',
      status: 1,
    }),
  ]);
  const clientSel = clientRes?.item?.map(c => ({
    val: c?.clientId,
    opt: c?.clientName,
  }));
  const userSel = userRes?.item?.map(u => ({
    val: u?.userId,
    opt: u?.name.lastName + u?.name.firstName,
  }));
  const paymentSel = paymentRes?.item?.map(p => ({
    val: Number(p?.payTermsId),
    opt: p?.payTermsName,
  }));
  const matterSel = matterRes?.item;
  const matterCategorySel = namingRes?.item.map(e => ({
    val: e.namingId,
    opt: e.naming,
  }));
  const pre1Sel = pre1Res?.item.map(pre => ({
    val: pre.namingId,
    opt: pre.naming,
  }));
  const pre2Sel = pre2Res?.item.map(pre => ({
    val: pre.namingId,
    opt: pre.naming,
  }));
  const pre3Sel = pre3Res?.item.map(pre => ({
    val: pre.namingId,
    opt: pre.naming,
  }));
  const pre4Sel = pre4Res?.item.map(pre => ({
    val: pre.namingId,
    opt: pre.naming,
  }));
  const pre5Sel = pre5Res?.item.map(pre => ({
    val: pre.namingId,
    opt: pre.naming,
  }));
  const officeMasters = corRes?.detail?.officeMasters?.map((o, idx) => {
    return {
      ...o,
      value: idx + 1,
      label: `${idx + 1}:${o?.officeName}`,
    };
  });
  return {
    selOpt: {
      clientSel,
      userSel,
      paymentSel,
      matterSel,
      matterCategorySel,
      pre1Sel,
      pre2Sel,
      pre3Sel,
      pre4Sel,
      pre5Sel,
      officeMasters,
    },
  };
});

// 見積一覧　セレクト取得
export const quoteListInit = createAsyncThunk(
  'quote/list/init',
  async params => {
    const [clientRes, userRes] = await fetchMultiRequest([
      fetchRequest(apiUrl.client.list, params.clientList),
      fetchRequest(apiUrl.user.list, params.userList),
      fetchRequest(apiUrl.naming.list, {
        namingClassificationId: 'PromotionPreliminary1',
        sort: 'namingIdAsc',
      }),
    ]);
    const clientSel = clientRes?.item?.map(c => {
      return {
        val: c?.clientId,
        opt: c?.clientName,
      };
    });
    const userSel = userRes?.item?.map(u => {
      return {
        val: u?.userId,
        opt: u?.name.lastName + u?.name.firstName,
      };
    });

    return {
      listSelOpt: {
        clientSel,
        userSel,
      },
    };
  }
);

// 見積一覧　予備項目セレクト取得
export const quoteListNaming = createAsyncThunk(
  'quote/list/naming',
  async params => {
    const [namingRes] = await Promise.all([
      fetchRequest(apiUrl.naming.list, params),
    ]);
    const namingSel = namingRes?.item;
    return {
      namingSelOpt: namingSel,
    };
  }
);

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    setEdit: state => {
      state.editParam = { ...state.editParam };
    },
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvImportVisible: (state, { payload }) => {
      state.csvImportVisible = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    setCalculation: (state, { payload }) => {
      state.calculation = payload;
    },
    setDelete: (state, { payload }) => {
      state.deleteFlag = payload;
    },
    setQuoteNumber: (state, { payload }) => {
      state.quoteNumberId = payload;
    },
    setNavigation: (state, { payload }) => {
      state.navigation = payload;
    },
    setApprovalModal: (state, { payload }) => {
      state.approvalModalFlag = payload;
    },
    setDenialModal: (state, { payload }) => {
      state.denialModalFlag = payload;
    },
    setApprovalApplyModal: (state, { payload }) => {
      state.approvalApplyModalFlag = payload;
    },
    setPdfOutputFlag: (state, { payload }) => {
      state.pdfOutputFlag = payload;
    },
    setConfirmFlag: (state, { payload }) => {
      state.confirmModalVisible = payload;
    },
    setMailFlag: (state, { payload }) => {
      state.mailFlag = payload;
    },
    setApplyModalFlag: (state, { payload }) => {
      state.applyModalVisible = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    editReset: state => {
      state.templateEdit = templateEdit;
      state.flowDetail = flowDetail;
      state.templateBody = templateBody;
    },
    setStampFlag: (state, { payload }) => {
      state.stampFlag = payload;
    },
    setTemplateBody: (state, { payload }) => {
      state.templateBody = payload;
    },
    setUpFile: (state, { payload }) => {
      state.upFile = payload;
    },
  },
  extraReducers: {
    [quoteDocumentInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteTemplateInfoFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteTemplateDetailFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      templateEdit: {
        ...payload?.detail,
        note: payload?.detail?.note ?? '',
      },
    }),
    [quoteDetailFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      quoteDetail: {
        ...payload?.detail,
      },
    }),
    [quoteDetailMailFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteTemplateListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteFlowFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteDocumentPdf.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
      pdfOutputFlag: false,
    }),
    [quoteFlowDetail.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteUserList.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteDeptList.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteListInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [quoteListNaming.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const quoteActions = quoteSlice.actions;
export default quoteSlice.reducer;
