import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { staffDashboardActions, staffDashboardInit } from '../../redux/slice/staffDashboard';
import { useNavigate } from 'react-router';
import { pick } from 'lodash';
import Options from '../../constants/options';
import moment from 'moment';
import StaffDashBordContentSp from './staffDashBordContentSp';
import { checkIsSp } from '../../utils/fnUtil';
import Icons from '../../constants/icons';
import SurveyStartModal from '../../components/modal/surveyStartModal';
import { useFormik } from 'formik';
import Yup from '../../utils/yupUtil';
import { staffSurveyAnswerInsert, staffSurveyListFetch } from '../../redux/slice/survey';

const StaffDashboard = memo(() => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { staffId, corporateId } = useSelector(state => state.account);

  const { staffSchedule, staffPay, maxMatterDate, staffAssignMatter, surveyStartVisible, surveyAnswerInfoParams } =
    useSelector(state => state.staffDashboard);

  const { staffSurveyList } = useSelector(state => state.survey);

  const { staffConfirmFromDate, staffConfirmToDate, staffApproval } =
    staffPay ?? {};

  const [isSubmit, setIsSubmit] = useState(false);
  const [isMoveApi, setIsMoveApi] = useState(false);

  const formik = useFormik({
    initialValues: surveyAnswerInfoParams,
    enableReinitialize: true,
    validateOnMount: true,
    validate: () => {
      formik.errors?.surveyQuestionInfos?.map((err, i) => {
        if (err) {
          if (err.answerChoice) {
            err.answerChoice = `Q${i + 1}は必須です。`
          } else if (err.answerText) {
            err.answerText = `Q${i + 1}は必須です。`
          }
        }
        return formik.errors;
      });
    },
    validationSchema: Yup.object({
      surveyQuestionInfos: Yup.array().of(
        Yup.object({
          requiredFlag: Yup.number()
            .nullable()
            .label('必須フラグ'),
          answerMethod: Yup.number()
            .nullable()
            .label('回答方法'),
          answerChoice: Yup.string()
            .nullable()
            .test(
              'answerChoice',
              '回答は必須です。',
              (_, context) => {
                const {answerMethod, requiredFlag} = context.parent;
                if (answerMethod !== 2 && requiredFlag === 1) {
                  return context.from[0].value.answerChoice;
                } else {
                  return true
                }
              }),
          answerText: Yup.string()
            .nullable()
            .test(
              'answerText',
              '回答は必須です。',
              (_, context) => {
                const {answerMethod, requiredFlag} = context.parent;
                if (answerMethod === 2 && requiredFlag === 1) {
                  return context.from[0].value.answerText;
                } else {
                  return true
                }
              })
        })
      )
    }),
    onSubmit: values => {

      const newValues = values?.surveyQuestionInfos?.map(obj => {
        if (obj?.answerChoice === '') {
          obj.answerChoice = null;
        } else if (obj?.answerChoice?.length > 1) {
          obj.answerChoice = Array.from(obj?.answerChoice)?.join(",");
        }
        if (obj?.answerText === '') {
          obj.answerText = null;
        }
        return obj;
      });

      dispatch(
        staffSurveyAnswerInsert({
          answerInfos: [...newValues],
          corporateId,
          staffId,
          deletedFlag: '0',
          surveyId: values?.surveyQuestionInfos?.[0]?.surveyId,
        })
      ).then(() => {
        setIsSubmit(true);
        formik.resetForm();
      });
    },
  });

  useEffect(() => {
    dispatch(
      staffDashboardInit({
        staffId,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    //スタッフ用アンケート一覧取得
    dispatch(
      staffSurveyListFetch({
        corporateId: String(corporateId),
        staffId: Number(staffId),
      })
    ).then(data => {
        formik.setFieldValue(
          'staffSurveyList',
          data?.payload?.staffSurveyList
        )
      setIsMoveApi(false)
      }
    );
  }, [dispatch, isMoveApi])

  const previousDayMatter = staffAssignMatter.find(m => m.dateFlag === 1);

  return (
    <>
      <div className={'dashboard_staff'}>
        <div className={'dashboard_staff--user_notice'}>
          {staffSchedule?.statusFlag === 0 && (
            <div className={'dashboard_staff--contents_notice_alert'}>
              シフトがまだ提出されていません。
            </div>
          )}
          {previousDayMatter?.advanceApprovalFlag === 2 && (
            <div
              className={'dashboard_staff--contents_notice_alert action_alert'}
              onClick={() =>
                navigate('/recruit-matter/detail', {
                  state: {
                    matterNumber: previousDayMatter.matterNumber,
                    matterDateNumber: previousDayMatter.matterDateNumber,
                    shiftId: previousDayMatter.shiftId,
                    assignAccountFlag: 0,
                    assignId: staffId,
                    activeTab: 'variousApplication',
                  },
                })
              }
            >
              立替申請が差戻しされました。
            </div>
          )}
          {moment().isBetween(
            moment(staffConfirmFromDate),
            moment(staffConfirmToDate)
          ) &&
            staffApproval !== 3 && (
              <div
                className={'dashboard_staff--contents_notice_alert'}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  navigate('/staff-pay/approval', {
                    state: {
                      id: staffId,
                      year: staffPay.year,
                      month: staffPay.month,
                    },
                  })
                }
              >
                給与承認を行ってください。
              </div>
            )}
          {maxMatterDate && (
            <div className={'dashboard_staff--contents_notice'}>
              {moment(maxMatterDate).format('M/D')}
              までに実施される案件のアサイン状況が公開されました。
            </div>
          )}
        </div>
        {[...Array(7)]
          .map((_, i) => {
            const matterDate = moment()
              .add(
                {
                  0: 0,
                  1: -1,
                  2: 1,
                }[i] ?? i - 1,
                'days'
              )
              .format('YYYY/M/D');

            return staffAssignMatter.filter(a => a.matterDate === matterDate)
              .length === 0
              ? [matterDate]
              : staffAssignMatter.filter(a => a.matterDate === matterDate);
          })
          .map((dateInfo, i) => {
            const state = pick(dateInfo[0], [
              'matterNumber',
              'matterDateNumber',
              'shiftId',
              'assignAccountFlag',
              'assignId',
            ]);

            const { label, nextPlanDatetime } =
              Options.statusMap.staffAssignStatus.find(
                s => s.value === dateInfo[0]?.status
              ) ?? {};

            return (
              <div key={i} className={'dashboard_staff--matter_info'}>
                <div
                  className={'dashboard_staff--matter_title'}
                  style={{ backgroundColor: i === 0 && '#3377DB' }}
                >
                  {`${
                    [0, 1, 2].includes(i)
                      ? `${
                          {
                            0: '本日',
                            1: '昨日',
                            2: '明日',
                          }[i]
                        } (${
                          typeof dateInfo[0] === 'object'
                            ? dateInfo[0]?.matterDate
                            : dateInfo[0]
                        }) `
                      : typeof dateInfo[0] === 'object'
                      ? dateInfo[0]?.matterDate
                      : dateInfo[0]
                  }実施の案件`}
                </div>
                <div
                  className={'dashboard_staff--matter_contents'}
                  style={
                    dateInfo?.length >= 2
                      ? {
                          display: 'flex',
                          flexFlow: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                        }
                      : {}
                  }
                >
                  {dateInfo?.length >= 2 ? (
                    dateInfo?.map(obj => {
                      //同じ日に案件が複数あった場合
                      const state = pick(obj, [
                        'matterNumber',
                        'matterDateNumber',
                        'shiftId',
                        'assignAccountFlag',
                        'assignId',
                      ]);

                      const { label, nextPlanDatetime } =
                        Options.statusMap.staffAssignStatus.find(
                          s => s.value === obj.status
                        ) ?? {};

                      return obj?.matterNumber && obj?.openFlag === 1 ? (
                        <>
                          {checkIsSp() ? ( //sp版
                            <StaffDashBordContentSp
                              props={obj}
                              nextPlanDatetime={nextPlanDatetime}
                              navigate={navigate}
                              state={state}
                              label={label}
                            />
                          ) : (
                            <div
                              className={'dashboard_staff--matter_items_area'}
                            >
                              <div className={'dashboard_staff--matter_items'}>
                                <div className={'dashboard_staff--matter_name'}>
                                  {obj.matterName}
                                </div>

                                <div
                                  className={'dashboard_staff--matter_details'}
                                >
                                  <div
                                    className={'dashboard_staff--matter_label'}
                                  >
                                    実施場所
                                  </div>
                                  <div
                                    className={'dashboard_staff--matter_value'}
                                  >
                                    {obj.venueName}
                                  </div>
                                </div>

                                <div
                                  className={'dashboard_staff--matter_details'}
                                >
                                  <div
                                    className={'dashboard_staff--matter_label'}
                                  >
                                    開催地最寄駅
                                  </div>
                                  <div
                                    className={'dashboard_staff--matter_value'}
                                  >
                                    {obj.venueNearestStation}
                                  </div>
                                </div>

                                <div
                                  className={'dashboard_staff--matter_details'}
                                >
                                  <div
                                    className={'dashboard_staff--matter_label'}
                                  >
                                    勤務時間
                                  </div>
                                  <div
                                    className={'dashboard_staff--matter_value'}
                                  >
                                    {`${
                                      obj.workStartDatetime ||
                                      obj.workEndDatetime
                                        ? `${obj.workStartDatetime}~${obj.workEndDatetime}`
                                        : ''
                                    }${
                                      obj.restMinute !== null
                                        ? `(休憩${obj.restMinute}分)`
                                        : ''
                                    }`}
                                  </div>
                                </div>

                                <div
                                  className={'dashboard_staff--matter_details'}
                                >
                                  <div
                                    className={'dashboard_staff--matter_label'}
                                  >
                                    出発予定時間
                                  </div>
                                  <div
                                    className={'dashboard_staff--matter_value'}
                                  >
                                    {obj?.staffDeparturePlanDatetime &&
                                      moment(
                                        obj.staffDeparturePlanDatetime
                                      ).format('HH:mm')}
                                  </div>
                                </div>
                              </div>

                              <div
                                className={'dashboard_staff--matter_right_area'}
                                style={{
                                  marginTop: 70,
                                }}
                              >
                                {nextPlanDatetime &&
                                  obj[nextPlanDatetime] &&
                                  moment().isBefore(
                                    moment(obj[nextPlanDatetime])
                                  ) && obj?.staffEndDatetime === null && (
                                    <div
                                      className={'dashboard_staff--alert'}
                                      onClick={() =>
                                        navigate('/recruit-matter/detail', {
                                          state: {
                                            ...state,
                                            activeTab: 'attendanceReport',
                                          },
                                        })
                                      }
                                    >
                                      {`${moment(obj[nextPlanDatetime]).format(
                                        'M/D HH:mm'
                                      )}までに${label}をしてください`}
                                    </div>
                                  )}
                                <div
                                  className={'dashboard_staff--matter_link'}
                                  onClick={() =>
                                    navigate('/recruit-matter/detail', {
                                      state,
                                    })
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  案件詳細を表示
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className={'dashboard_staff--no_matter'}>
                          確定している案件がありません
                        </div>
                      );
                    })
                  ) : typeof dateInfo[0] === 'object' &&
                    dateInfo[0]?.matterNumber &&
                    dateInfo[0]?.openFlag === 1 ? ( //案件が一つの場合
                    <>
                      {checkIsSp() ? ( //sp版
                        <StaffDashBordContentSp
                          props={dateInfo[0]}
                          nextPlanDatetime={nextPlanDatetime}
                          navigate={navigate}
                          state={state}
                          label={label}
                        />
                      ) : (
                        <>
                          <div className={'dashboard_staff--matter_items'}>
                            <div className={'dashboard_staff--matter_name'}>
                              {dateInfo[0]?.matterName}
                            </div>

                            <div className={'dashboard_staff--matter_details'}>
                              <div className={'dashboard_staff--matter_label'}>
                                実施場所
                              </div>
                              <div className={'dashboard_staff--matter_value'}>
                                {dateInfo[0]?.venueName}
                              </div>
                            </div>

                            <div className={'dashboard_staff--matter_details'}>
                              <div className={'dashboard_staff--matter_label'}>
                                開催地最寄駅
                              </div>
                              <div className={'dashboard_staff--matter_value'}>
                                {dateInfo[0]?.venueNearestStation}
                              </div>
                            </div>

                            <div className={'dashboard_staff--matter_details'}>
                              <div className={'dashboard_staff--matter_label'}>
                                勤務時間
                              </div>
                              <div className={'dashboard_staff--matter_value'}>
                                {`${
                                  dateInfo[0]?.workStartDatetime ||
                                  dateInfo[0]?.workEndDatetime
                                    ? `${dateInfo[0]?.workStartDatetime}~${dateInfo[0]?.workEndDatetime}`
                                    : ''
                                }${
                                  dateInfo[0]?.restMinute !== null
                                    ? `(休憩${dateInfo[0]?.restMinute}分)`
                                    : ''
                                }`}
                              </div>
                            </div>

                            <div className={'dashboard_staff--matter_details'}>
                              <div className={'dashboard_staff--matter_label'}>
                                出発予定時間
                              </div>
                              <div className={'dashboard_staff--matter_value'}>
                                {dateInfo[0]?.staffDeparturePlanDatetime &&
                                  moment(
                                    dateInfo[0]?.staffDeparturePlanDatetime
                                  ).format('HH:mm')}
                              </div>
                            </div>
                          </div>

                          <div
                            className={'dashboard_staff--matter_right_area'}
                            style={{
                              marginTop: 70,
                            }}
                          >
                            {nextPlanDatetime &&
                              dateInfo[0][nextPlanDatetime] &&
                              moment().isBefore(
                                moment(dateInfo[0][nextPlanDatetime])
                              ) && dateInfo[0]?.staffEndDatetime === null && (
                                <div
                                  className={'dashboard_staff--alert'}
                                  onClick={() =>
                                    navigate('/recruit-matter/detail', {
                                      state: {
                                        ...state,
                                        activeTab: 'attendanceReport',
                                      },
                                    })
                                  }
                                >
                                  {`${moment(
                                    dateInfo[0][nextPlanDatetime]
                                  ).format(
                                    'M/D HH:mm'
                                  )}までに${label}をしてください`}
                                </div>
                              )}
                            <div
                              className={'dashboard_staff--matter_link'}
                              onClick={() =>
                                navigate('/recruit-matter/detail', {
                                  state,
                                })
                              }
                              style={{ cursor: 'pointer' }}
                            >
                              案件詳細を表示
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className={'dashboard_staff--no_matter'}>
                      確定している案件がありません
                    </div>
                  )}
                </div>
              </div>
            );

            // const state = pick(dateInfo, [
            //   'matterNumber',
            //   'matterDateNumber',
            //   'shiftId',
            //   'assignAccountFlag',
            //   'assignId',
            // ]);
            //
            // const { label, nextPlanDatetime } =
            //   Options.statusMap.staffAssignStatus.find(
            //     s => s.value === dateInfo.status
            //   ) ?? {};
            //
            // return (
            //   <div key={i} className={'dashboard_staff--matter_info'}>
            //     <div
            //       className={'dashboard_staff--matter_title'}
            //       style={{ backgroundColor: i === 0 && '#3377DB' }}
            //     >
            //       {`${
            //         [0, 1, 2].includes(i)
            //           ? `${
            //               {
            //                 0: '本日',
            //                 1: '昨日',
            //                 2: '明日',
            //               }[i]
            //             } (${dateInfo.matterDate}) `
            //           : dateInfo.matterDate
            //       }実施の案件`}
            //     </div>
            //     <div className={'dashboard_staff--matter_contents'}>
            //       {dateInfo.matterNumber && dateInfo.openFlag === 1 ? (
            //         <>
            //           {checkIsSp() ? //sp版
            //             <StaffDashBordContentSp
            //               props={dateInfo}
            //               nextPlanDatetime={nextPlanDatetime}
            //               navigate={navigate}
            //               state={state}
            //               label={label}
            //             /> :
            //             <>
            //               <div className={'dashboard_staff--matter_items'}>
            //                 <div className={'dashboard_staff--matter_name'}>
            //                   {dateInfo.matterName}
            //                 </div>
            //
            //                 <div className={'dashboard_staff--matter_details'}>
            //                   <div className={'dashboard_staff--matter_label'}>
            //                     実施場所
            //                   </div>
            //                   <div className={'dashboard_staff--matter_value'}>
            //                     {dateInfo.venueName}
            //                   </div>
            //                 </div>
            //
            //                 <div className={'dashboard_staff--matter_details'}>
            //                   <div className={'dashboard_staff--matter_label'}>
            //                     開催地最寄駅
            //                   </div>
            //                   <div className={'dashboard_staff--matter_value'}>
            //                     {dateInfo.venueNearestStation}
            //                   </div>
            //                 </div>
            //
            //                 <div className={'dashboard_staff--matter_details'}>
            //                   <div className={'dashboard_staff--matter_label'}>
            //                     勤務時間
            //                   </div>
            //                   <div className={'dashboard_staff--matter_value'}>
            //                     {`${
            //                       dateInfo.workStartDatetime ||
            //                       dateInfo.workEndDatetime
            //                         ? `${dateInfo.workStartDatetime}~${dateInfo.workEndDatetime}`
            //                         : ''
            //                     }${
            //                       dateInfo.restMinute !== null
            //                         ? `(休憩${dateInfo.restMinute}分)`
            //                         : ''
            //                     }`}
            //                   </div>
            //                 </div>
            //
            //                 <div className={'dashboard_staff--matter_details'}>
            //                   <div className={'dashboard_staff--matter_label'}>
            //                     出発予定時間
            //                   </div>
            //                   <div className={'dashboard_staff--matter_value'}>
            //                     {dateInfo?.staffDeparturePlanDatetime &&
            //                       moment(
            //                         dateInfo.staffDeparturePlanDatetime
            //                       ).format('HH:mm')}
            //                   </div>
            //                 </div>
            //               </div>
            //
            //               <div
            //                 className={'dashboard_staff--matter_right_area'}
            //                 style={{
            //                   marginTop: 70,
            //                 }}
            //               >
            //                 {nextPlanDatetime &&
            //                   dateInfo[nextPlanDatetime] &&
            //                   moment().isBefore(
            //                     moment(dateInfo[nextPlanDatetime])
            //                   ) && (
            //                     <div
            //                       className={'dashboard_staff--alert'}
            //                       onClick={() =>
            //                         navigate('/recruit-matter/detail', {
            //                           state: {
            //                             ...state,
            //                             activeTab: 'attendanceReport',
            //                           },
            //                         })
            //                       }
            //                     >
            //                       {`${moment(dateInfo[nextPlanDatetime]).format(
            //                         'M/D HH:mm'
            //                       )}までに${label}をしてください`}
            //                     </div>
            //                   )}
            //                 <div
            //                   className={'dashboard_staff--matter_link'}
            //                   onClick={() =>
            //                     navigate('/recruit-matter/detail', {
            //                       state,
            //                     })
            //                   }
            //                   style={{cursor: 'pointer'}}
            //                 >
            //                   案件詳細を表示
            //                 </div>
            //               </div>
            //             </>
            //           }
            //         </>
            //       ) : (
            //         <div className={'dashboard_staff--no_matter'}>
            //           確定している案件がありません
            //         </div>
            //       )}
            //     </div>
            //   </div>
            // );
          })}
      </div>
      {staffSurveyList?.length > 0 ?
        <div
          className={'bottom_btn_area'}
          style={{
            height: checkIsSp() ? '129px' : '208px',
            backgroundColor: 'transparent',
          }}
        >
          <div className={'dashboard_staff--icon_area'}>
            <div className={'dashboard_staff--red_circle_on_character'}>
              <span>{staffSurveyList?.length ?? 0}</span>
            </div>
            <img
              src={Icons.icon.character}
              onClick={() => {
                dispatch(staffDashboardActions.setSurveyStartVisible(true));
              }}
            />
          </div>
        </div>
        :
        <></>
      }
      <p className={'dashboard_staff--copy_right'}>
        ©︎ GOODWAVE All right reserved.
      </p>
      <SurveyStartModal
        modalVisible={surveyStartVisible}
        toggleVisible={() =>
          dispatch(
            staffDashboardActions.setSurveyStartVisible(!surveyStartVisible)
          )
        }
        surveyDate={staffSurveyList}
        formik={formik}
        isSubmit={isSubmit}
        setIsSubmit={setIsSubmit}
        dispatch={dispatch}
        setMoveApi={setIsMoveApi}
      />
    </>
  );
});

export default StaffDashboard;
