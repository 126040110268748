import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import { useNavigate } from 'react-router-dom';
import Yup from '../../../utils/yupUtil';
import CustomInput from '../../../components/customInput';
import { log } from '@craco/craco/lib/logger';
import { useDispatch, useSelector } from 'react-redux';
import {
  namingActions,
  namingGetId,
  namingInsert,
} from '../../../redux/slice/naming';
import { useFormik } from 'formik';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { globalActions } from '../../../redux/slice/global';
import { useLocation } from 'react-router';
import {
  handlerFormikFieldChange,
  handlerFormikSubmit,
} from '../../../utils/fnUtil';
import { useEffect } from 'react';
import { checkIsSp } from '../../../utils/fnUtil';

const buttonStyle = {
  registerButton: {
    width: '240px',
    height: '44px',
    border: '1px solid #0056D3',
    borderRadius: '6px',
    backgroundColor: '#0056D3',
    color: '#FFFFFF',
    fontSize: '14px',
    margin: '0 0',
    padding: '0 0',
    userSelect: 'none',
  },
  backButton: {
    width: '240px',
    height: '44px',
    borderRadius: '6px',
    backgroundColor: '#E9F2FC',
    color: '#486083',
    fontSize: '14px',
    margin: '0 0',
    padding: '0 0',
    userSelect: 'none',
  },
};

const NamingRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { namingCategoryList, nextId } = useSelector(state => state.naming);
  const tabErrorGroup = {
    naming: ['naming', 'namingClassificationId', 'namingId', 'namingKana'],
  };
  const formik = useFormik({
    initialValues: {
      naming: null,
      namingClassificationId: null,
      namingId: '',
      namingKana: null,
      note: '',
      priority: 1,
      displayFlag: 1,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      namingClassificationId: Yup.string()
        .nullable()
        .required()
        .label('[名称情報]名称区分'),
      namingId: Yup.string()
        .required()
        .nullable()
        .integer('[名称情報]名称ID')
        .label('[名称情報]名称ID'),
      naming: Yup.string().required().nullable().label('[名称情報]名称'),
      namingKana: Yup.string()
        .nullable()
        .kana('[名称情報]名称カナ')
        .label('[名称情報]名称カナ'),
      priority: Yup.string()
        .required()
        .nullable()
        .label('[名称情報]表示優先順'),
    }),
    onSubmit: values => {
      dispatch(namingInsert(values))
        .unwrap()
        .then(() => {
          dispatch(
            globalActions.showSingleModal(
              `${state?.id ? '更新' : '登録'}しました`
            )
          );
          navigate(-1);
        });
    },
  });
  const { namingClassificationId } = formik.values;

  useEffect(() => {
    namingClassificationId != null &&
      dispatch(namingGetId({ namingClassificationId: namingClassificationId }));
  }, [namingClassificationId]);

  useEffect(() => {
    if (namingClassificationId) {
      formik.setFieldValue('namingId', nextId);
    }
  }, [nextId]);

  useEffect(() => {
    dispatch(namingActions.resetParam());
  }, []);

  const namingInfo = [
    //インプットの幅がデザイン通りだとipad airサイズにしたときにはみ出してしまうのでpxではなく%指定にしています。
    {
      inputType: 'select',
      label: '名称区分',
      labelRequired: true,
      inputName: 'namingClassificationId',
      initialValue: namingCategoryList
        .filter(
          n =>
            n?.namingId != 'Index' &&
            n?.namingId != 'Holdingitem' &&
            n?.namingId != 'RetireReason' &&
            n?.namingId != 'PromotionCategory'
        )
        .map(n => n?.namingId),
      selectBoxOptions: namingCategoryList
        .filter(
          n =>
            n?.namingId != 'Index' &&
            n?.namingId != 'Holdingitem' &&
            n?.namingId != 'RetireReason' &&
            n?.namingId != 'PromotionCategory'
        )
        .map(n => `${n?.namingId}:${n?.naming}`),
      placeholder: '名称区分を選択してください',
      style: {
        areaStyle: { width: checkIsSp() ? '100%' : '67%' },
        labelStyle: { width: '232px' },
      },
    },
    {
      inputType: 'input',
      label: '名称ID',
      labelRequired: true,
      inputName: 'namingId',
      useValue: true,
      defaultValue: '',
      placeholder: '名称IDを入力してください',
      style: {
        areaStyle: { width: checkIsSp() ? '100%' : '67%' },
        labelStyle: { width: '232px' },
      },
      disabled: !namingClassificationId,
    },
    {
      inputType: 'input',
      label: '名称',
      labelRequired: true,
      inputName: 'naming',
      initialValue: '',
      placeholder: '名称を入力してください',
      style: {
        areaStyle: { width: checkIsSp() ? '100%' : '67%' },
        labelStyle: { width: '232px' },
      },
      disabled: !namingClassificationId,
    },
    {
      inputType: 'input',
      label: '名称(カナ)',
      inputName: 'namingKana',
      initialValue: '',
      placeholder: '名称(カナ)を入力してください',
      style: {
        areaStyle: { width: checkIsSp() ? '100%' : '67%' },
        labelStyle: { width: '232px' },
      },
      disabled: !namingClassificationId,
    },
    {
      inputType: 'select',
      label: '表示優先順',
      labelRequired: true,
      inputName: 'priority',
      initialValue: [...Array(100)].map((_, i) => i + 1),
      selectedSelectBox: '1',
      selectBoxOptions: [...Array(100)].map((_, i) => i + 1),
      placeholder: '表示優先順位を選択してください',
      style: {
        areaStyle: { width: checkIsSp() ? '100%' : '359px' },
        labelStyle: { width: '232px' },
      },
      disabled: !namingClassificationId,
    },
    {
      inputType: 'textBox',
      label: '備考',
      inputName: 'note',
      initialValue: '',
      placeholder: '備考を入力してください',
      style: {
        areaStyle: { width: checkIsSp() ? '100%' : '87%' },
        labelStyle: { width: '232px' },
      },
      disabled: !namingClassificationId,
    },
  ];

  const NamingRegisterInfo = () => (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '20px',
        marginTop: checkIsSp() ? '0px' : '26px',
      }}
    >
      {namingInfo.map((info, idx) => (
        <CustomFormikInput {...info} formik={formik} key={info.inputName} />
      ))}
    </div>
  );

  const infos = {
    detail: {
      tabs: {
        naming: {
          label: '名称情報',
          jsx: NamingRegisterInfo,
        },
      },
    },
  };

  return (
    <>
      <RegisterBase
        image={Icons.test.account}
        imageShape={'round'}
        infos={infos}
      />

      <div
        className={'bottom_btn_area'}
        style={{ height: '92px', justifyContent: 'center' }}
      >
        <div className={'staff_register--submit_buttons'}>
          <Button
            text={'戻る'}
            style={buttonStyle.backButton}
            onClick={() => {
              navigate(`/master/naming/list`);
            }}
          />
          <Button
            text={'登録'}
            style={buttonStyle.registerButton}
            onClick={() => {
              handlerFormikSubmit(formik, tabErrorGroup);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default NamingRegister;
