import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import moment from 'moment';

const surveyAnswerInfoParams = {
  staffSurveyList: [],
  surveyQuestionInfos: []
};

const initialState = {
  staffSchedule: null,
  staffPay: null,
  staffAssignMatter: [],
  maxMatterDate: null,
  surveyStartVisible: false,
  surveyAnswerInfoParams,
};

export const staffDashboardInit = createAsyncThunk(
  'staff/dashboard/init',
  async params => {
    const [
      staffScheduleRes,
      staffPayRes,
      staffPayNextMonthRes,
      staffAssignMatterRes,
    ] = await fetchMultiRequest([
      fetchRequest(apiUrl.staffPlan.deadline),
      fetchRequest(apiUrl.staffPay.detail, {
        ...params,
        year: moment().format('YYYY'),
        month: moment().format('MM'),
      }),
      fetchRequest(apiUrl.staffPay.detail, {
        ...params,
        year: moment().add(1, 'M').format('YYYY'),
        month: moment().add(1, 'M').format('MM'),
      }),
      fetchRequest(apiUrl.staffMatterAssign.list, {
        ...params,
        currentDate: moment().format('YYYY/MM/DD'),
      }),
    ]);

    const { assignMatter, maxMatterDate } = staffAssignMatterRes ?? {};
    return {
      staffSchedule: {statusFlag: staffScheduleRes?.status},
      staffPay: {
        year: staffPayNextMonthRes?.detail?.staffApproval
          ? staffPayNextMonthRes?.params?.year
          : staffPayRes?.params?.year,
        month: staffPayNextMonthRes?.detail?.staffApproval
          ? staffPayNextMonthRes?.params?.month
          : staffPayRes?.params?.month,
        staffApproval: staffPayNextMonthRes?.detail?.staffApproval
          ? staffPayNextMonthRes?.detail?.staffApproval
          : staffPayRes?.detail?.staffApproval,
        staffConfirmFromDate: staffPayNextMonthRes?.detail?.staffApproval
          ? staffPayNextMonthRes?.detail?.staffConfirmFromDate
          : staffPayRes?.detail?.staffConfirmFromDate,
        staffConfirmToDate: staffPayNextMonthRes?.detail?.staffApproval
          ? staffPayNextMonthRes?.detail?.staffConfirmToDate
          : staffPayRes?.detail?.staffConfirmToDate,
      },
      staffAssignMatter: assignMatter ?? [],
      maxMatterDate,
    };
  }
);

export const staffDashboardSlice = createSlice({
  name: 'staffDashboard',
  initialState,
  reducers: {
    reset: () => initialState,
    setSurveyStartVisible: (state, { payload }) => {
      state.surveyStartVisible = payload;
    },
  },
  extraReducers: {
    [staffDashboardInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const staffDashboardActions = staffDashboardSlice.actions;
export default staffDashboardSlice.reducer;
