import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './slice/account';
import loginTypeReducer from './slice/loginType';
import matterReducer from './slice/matter';
import matterDateReducer from './slice/matterDate';
import matterStaffAssignReducer from './slice/matterStaffAssign';
import staffRecruitMatterReducer from './slice/staffRecruitMatter';
import matterAppliedReducer from './slice/matterApplied';
import staffMatterReducer from './slice/staffMatter';
import staffDashboardReducer from './slice/staffDashboard';
import globalReducer from './slice/global';
import counterReducer from './slice/counter';
import namingReducer from './slice/naming';
import clientReducer from './slice/client';
import payTermsReducer from './slice/payTerm';
import staffReducer from './slice/staff';
import { staffPayReducer, staffPayYearReducer } from './slice/staffPay';
import userReducer from './slice/user';
import incomeTaxReducer from './slice/incomeTax';
import venueReducer from './slice/venue';
import quoteReducer from './slice/quote';
import invoiceReducer from './slice/invoice';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import corporateReducer from './slice/corporate';
import { staffPlanReducer, staffPlanDetailReducer } from './slice/staffPlan';
import analyzeReducer from './slice/analyze';
import dashboardReducer from './slice/dashboard';
import matterAssignStateReducer from './slice/matterAssignState';
import surveyReducer from './slice/survey';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['account', 'loginType'],
};

const reducers = combineReducers({
  global: globalReducer,
  loginType: loginTypeReducer,
  account: accountReducer,
  matter: matterReducer,
  matterDate: matterDateReducer,
  matterAssignState: matterAssignStateReducer,
  matterStaffAssign: matterStaffAssignReducer,
  staffRecruitMatter: staffRecruitMatterReducer,
  matterApplied: matterAppliedReducer,
  staffMatter: staffMatterReducer,
  counter: counterReducer,
  naming: namingReducer,
  client: clientReducer,
  payTerms: payTermsReducer,
  staff: staffReducer,
  staffPay: staffPayReducer,
  staffPayYear: staffPayYearReducer,
  user: userReducer,
  incomeTax: incomeTaxReducer,
  venue: venueReducer,
  quote: quoteReducer,
  staffPlan: staffPlanReducer,
  corporate: corporateReducer,
  invoice: invoiceReducer,
  staffPlanDetail: staffPlanDetailReducer,
  staffDashboard: staffDashboardReducer,
  analyze: analyzeReducer,
  dashboard: dashboardReducer,
  survey: surveyReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
});
