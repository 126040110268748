import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import Button from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss';
import { useEffect, useState } from 'react';
import incomeTaxInputs from '../config/inputInfo';
import { useFormik } from 'formik';
import Yup from '../../../utils/yupUtil';
import {
  incomeTaxInit,
  incomeTaxInsert,
  incomeTaxUpdate,
} from '../../../redux/slice/incomeTax';
import { globalActions } from '../../../redux/slice/global';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { handlerFormikSubmit, checkIsSp } from '../../../utils/fnUtil';
import { useDispatch, useSelector } from 'react-redux';

const IncomeTaxRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [disabled, setDisabled] = useState(false);
  const [disabledSecond, setDisabledSecond] = useState(true);

  const loginUserAuth = useSelector(state => state.account.auth);
  const isSys = loginUserAuth === 0;
  const { incomeTaxData } = useSelector(state => state.incomeTax);

  const isSp = checkIsSp();

  const shapingData = data => {
    Object.keys(data).forEach(key => {
      data[key] = Number(data[key]);
    });
    return data;
  };

  const tabErrorGroup = {
    common: [
      'incomeAmountFrom',
      'incomeAmountTo',
      'taxRate',
      'taxAmount',
      'kouTax0',
      'kouTax1',
      'kouTax2',
      'kouTax3',
      'kouTax4',
      'kouTax5',
      'kouTax6',
      'kouTax7',
    ],
  };

  const formik = useFormik({
    initialValues: {
      incomeAmountFrom: 0,
      incomeAmountTo: 0,
      calculationTaxFlag: 0,
      taxRate: 0,
      taxAmount: 0,
      kouTax0: 0,
      kouTax1: 0,
      kouTax2: 0,
      kouTax3: 0,
      kouTax4: 0,
      kouTax5: 0,
      kouTax6: 0,
      kouTax7: 0,
    },
    validate: values => {
      let incomeAmountFromError = formik?.errors?.incomeAmountFrom;
      let incomeAmountToError = formik?.errors?.incomeAmountTo;
      if (incomeAmountFromError && incomeAmountToError) {
        delete formik.errors.incomeAmountTo;
      }
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      incomeAmountFrom: Yup.string()
        .required()
        .integer('[所得税登録]月額課税対象額')
        .test(
          'listCheck',
          '[所得税登録]月額課税対象額：既に対象の範囲で登録がされています',
          (_, context) =>
            !incomeTaxData?.item?.some(
              u =>
                Number(u?.incomeAmountFrom) <=
                  Number(context.parent.incomeAmountFrom) &&
                Number(context.parent.incomeAmountFrom) <=
                  Number(u?.incomeAmountTo)
            )
        )
        .label('[所得税登録]月額課税対象額'),
      incomeAmountTo: Yup.string()
        .required()
        .integer('[所得税登録]月額課税対象額')
        .incomeAmountTo('[所得税登録]月額課税対象額')
        .test(
          'listCheck',
          '[所得税登録]月額課税対象額：既に対象の範囲で登録がされています',
          (_, context) =>
            !incomeTaxData?.item?.some(
              u =>
                Number(u?.incomeAmountFrom) <=
                  Number(context.parent.incomeAmountTo) &&
                Number(context.parent.incomeAmountTo) <=
                  Number(u?.incomeAmountTo)
            )
        )
        .label('[所得税登録]月額課税対象額'),
      kouTax0: Yup.string()
        .required()
        .integer('[所得税登録](甲)課税額(0人)')
        .label('[所得税登録](甲)課税額(0人)'),
      kouTax1: Yup.string()
        .required()
        .integer('[所得税登録](甲)課税額(1人)')
        .label('[所得税登録](甲)課税額(1人)'),
      kouTax2: Yup.string()
        .required()
        .integer('[所得税登録](甲)課税額(2人)')
        .label('[所得税登録](甲)課税額(2人)'),
      kouTax3: Yup.string()
        .required()
        .integer('[所得税登録](甲)課税額(3人)')
        .label('[所得税登録](甲)課税額(3人)'),
      kouTax4: Yup.string()
        .required()
        .integer('[所得税登録](甲)課税額(4人)')
        .label('[所得税登録](甲)課税額(4人)'),
      kouTax5: Yup.string()
        .required()
        .integer('[所得税登録](甲)課税額(5人)')
        .label('[所得税登録](甲)課税額(5人)'),
      kouTax6: Yup.string()
        .required()
        .integer('[所得税登録](甲)課税額(6人)')
        .label('[所得税登録](甲)課税額(6人)'),
      kouTax7: Yup.string()
        .required()
        .integer('[所得税登録](甲)課税額(7人)')
        .label('[所得税登録](甲)課税額(7人)'),
      taxRate: Yup.string()
        .required()
        // .integer('[所得税登録]課税率')
        .test(
          'taxRate',
          '[所得税登録]課税率:小数点第4位までで入力してください',
          (value, context) => {
            let numbers = String(value).split('.');
            if (numbers[1]?.length > 4) {
              return false;
            } else {
              return true;
            }
          }
        )
        .label('[所得税登録]課税率'),
      taxAmount: Yup.string()
        .required()
        .integer('[所得税登録]課税額')
        .label('[所得税登録]課税額'),
    }),
    onSubmit: values => {
      let bodyData = shapingData(values);
      bodyData.chartId = location?.state?.chartId;
      bodyData.startDate = location?.state?.startDate;

      dispatch(incomeTaxInsert(bodyData))
        .unwrap()
        .then(() => {
          dispatch(globalActions.showSingleModal(`登録しました`));
          navigate(-1);
        });
    },
  });

  const { calculationTaxFlag } = formik.values;

  const handleChange = e => {
    let targetElm = e.target.name;
    let targetValue = e.target.value;

    if (targetElm === 'calculationTaxFlag' && targetValue === '0') {
      setDisabled(false);
      setDisabledSecond(true);
    } else if (targetElm === 'calculationTaxFlag' && targetValue === '1') {
      setDisabled(true);
      setDisabledSecond(false);
    }
  };

  useEffect(() => {
    dispatch(
      incomeTaxInit({
        chartId: location?.state?.chartId,
        startDate: location?.state?.startDate,
      })
    );
  }, []);

  const incomeTaxRegisterInfo = () => (
    <div className={'income_tax--display'}>
      <div className={'income_tax--unity'}>
        <CustomFormikInput {...incomeTaxInputs[0]} formik={formik} />
        <span style={isSp ? { bottom: '10px', position: 'relative' } : {}}>
          〜
        </span>
        <CustomFormikInput {...incomeTaxInputs[1]} formik={formik} />
      </div>

      <div className={'income_tax--label_and_table_area'}>
        <div className={'income_tax--label_area'}>
          <label>
            (甲)課税額<span>*</span>
          </label>
        </div>
        {isSp ? (
          <>
            <div className={'income_tax--table_area'}>
              <table className={'income_tax--table_sp'} border="1">
                <tbody>
                  {[...Array(8)].map((_, idx) => (
                    <tr>
                      <td
                        className={'income_tax--table_sp_header'}
                        key={'income_tax_register--tr_' + idx}
                      >
                        {idx + '人'}
                      </td>
                      <td
                        className={'income_tax--table_sp_contents'}
                        key={'income_tax_register--td_' + idx}
                      >
                        <div className={'income_tax--td_contents'}>
                          <CustomFormikInput
                            {...incomeTaxInputs[6]}
                            inputName={incomeTaxInputs[6].inputName + idx}
                            formik={formik}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <table className={'income_tax--table'} border="1">
            <tbody>
              <tr>
                {[...Array(8)].map((_, idx) => (
                  <th key={'income_tax_register--tr_' + idx}>{idx + '人'}</th>
                ))}
              </tr>
              <tr>
                {[...Array(8)].map((_, idx) => (
                  <td key={'income_tax_register--td_' + idx}>
                    <div className={'income_tax--td_contents'}>
                      <CustomFormikInput
                        {...incomeTaxInputs[6]}
                        inputName={incomeTaxInputs[6].inputName + idx}
                        formik={formik}
                      />
                    </div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}
      </div>

      <div className={'income_tax--area'} onChange={handleChange}>
        <div className={'income_tax--contents_area'}>
          <CustomFormikInput
            {...incomeTaxInputs[2]}
            formik={formik}
            radioButtonLabelAndChecked={[
              { label: '課税率', checked: calculationTaxFlag == 0 },
            ]}
          />
          <CustomFormikInput
            {...incomeTaxInputs[3]}
            formik={formik}
            disabled={disabled}
          />
        </div>
        <div className={'income_tax--contents_area'}>
          <CustomFormikInput
            {...incomeTaxInputs[4]}
            formik={formik}
            radioButtonLabelAndChecked={[
              { label: '課税額', checked: calculationTaxFlag == 1 },
            ]}
          />
          <CustomFormikInput
            {...incomeTaxInputs[5]}
            formik={formik}
            disabled={disabledSecond}
          />
        </div>
      </div>
    </div>
  );

  const infos = {
    detail: {
      tabs: {
        common: {
          label: '所得税',
          jsx: incomeTaxRegisterInfo,
        },
      },
    },
  };

  const buttonStyle = {
    registerButton: {
      width: '240px',
      height: '44px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    backButton: {
      width: '240px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
  };

  return (
    isSys && (
      <>
        <RegisterBase
          image={Icons.test.account}
          imageShape={'round'}
          infos={infos}
        />

        <div
          className={'bottom_btn_area'}
          style={{ height: '92px', justifyContent: 'center' }}
        >
          <div className={'staff_register--submit_buttons'}>
            <Button
              text={'戻る'}
              style={buttonStyle.backButton}
              onClick={() => {
                navigate(`/master/income-tax/list`);
              }}
            />
            <Button
              text={'登録'}
              style={buttonStyle.registerButton}
              onClick={() => {
                handlerFormikSubmit(formik, tabErrorGroup);
              }}
            />
          </div>
        </div>
      </>
    )
  );
};

export default IncomeTaxRegister;
