import Icons from './icons';
import {
  loginFormSchema,
  forgetPassFormSchema,
  changePassFormSchema,
  changePassModalFormSchema,
} from '../utils/schemas';
import { loginFormOnSubmit } from '../components/customForm/submitFunctions';

const FormInfos = {
  login: {
    pageName: 'login',
    pageTitle: Icons.logo.yoko,
    isParams: '',
    form: {
      action: '',
      submitFunction: loginFormOnSubmit,
      formClassName: 'login-form',
      validation: loginFormSchema,
    },
    inputs: [
      {
        inputType: 'input',
        label: '法人ID',
        inputName: 'corporate_id',
        initialValue: '',
        placeholder: '法人IDを入力してください',
        className: {
          areaClass: 'column',
        },
        displayError: true,
      },
      {
        inputName: 'mail_address',
        inputNameCheckBox: 'save_mail_address',
        inputType: 'inputWithCheckBox',
        initialValue: '',
        checkBoxChecked: '',
        label: ['メールアドレス', 'メールアドレスを保存する'],
        placeholder: 'メールアドレスを入力してください',
        className: {
          areaClass: 'column',
        },
        displayError: true,
      },
      {
        inputType: 'inputPassword',
        label: 'パスワード',
        inputName: 'pass',
        initialValue: '',
        placeholder: 'パスワードを入力してください',
        autoComplete: true,
        className: {
          outLineClass: 'column',
          areaClass: '',
        },
        displayError: true,
      },
    ],
    button: {
      width: 'small',
      text: 'ログイン',
      style: {
        fontWeight: 'bold',
        backgroundColor: '#0056d3',
        color: '#ffffff',
        borderRadius: '6px',
      },
    },
  },
  forgetPass: {
    pageName: 'forgetPass',
    pageTitle: 'パスワードをお忘れの方',
    isParams: '',
    form: {
      action: '',
      submitFunction: loginFormOnSubmit,
      formClassName: 'forget_pass_form',
      validation: forgetPassFormSchema,
    },
    inputs: [
      {
        inputType: 'input',
        label: '法人ID',
        inputName: 'corporate_id',
        initialValue: '',
        placeholder: '法人IDを入力してください',
        className: {
          areaClass: 'column',
        },
        displayError: true,
      },
      {
        inputType: 'input',
        label: 'メールアドレス',
        inputName: 'mail_address',
        initialValue: '',
        placeholder: 'メールアドレスを入力してください',
        className: {
          areaClass: 'column',
        },
        displayError: true,
      },
    ],
    button: {
      width: 'small',
      text: '送信',
      style: {
        fontWeight: 'bold',
        backgroundColor: '#0056d3',
        color: '#ffffff',
        borderRadius: '6px',
        height: '44px',
        width: '240px',
        margin: '20px 0 0',
      },
    },
    subButton: {
      width: 'small',
      text: '戻る',
      style: {
        fontWeight: 'bold',
        backgroundColor: '#e9f2fc',
        color: '#486083',
        borderRadius: '6px',
        height: '44px',
        width: '240px',
        margin: '20px 0 0',
      },
    },
  },
  changePass: {
    pageName: 'changePass',
    pageTitle: 'パスワード再設定',
    form: {
      action: '',
      submitFunction: loginFormOnSubmit,
      formClassName: 'change_pass_form',
      validation: changePassFormSchema,
    },
    inputs: [
      {
        inputType: 'inputPassword',
        label: '新しいパスワード',
        inputName: 'new_pass',
        initialValue: '',
        placeholder: '新しいパスワードを入力してください',
        className: {
          outLineClass: 'column',
        },
        displayError: true,
      },
      {
        inputType: 'inputPassword',
        label: '新しいパスワード(再入力)',
        inputName: 'confirm_pass',
        initialValue: '',
        placeholder: '新しいパスワードを入力してください',
        className: {
          outLineClass: 'column',
        },
        displayError: true,
      },
    ],
    button: {
      width: 'small',
      text: '変更',
      style: {
        fontWeight: 'bold',
        backgroundColor: '#0056d3',
        color: '#ffffff',
        borderRadius: '6px',
      },
    },
  },
  changePassModal: {
    pageName: 'forgetPass',
    form: {
      submitFunction: loginFormOnSubmit,
      formClassName: 'change_pass_modal_form',
      validation: changePassModalFormSchema,
    },
    inputs: [
      {
        inputType: 'inputPassword',
        label: '現在のパスワード',
        inputName: 'now_pass',
        initialValue: '',
        placeholder: '現在のパスワードを入力してください',
        className: {
          outLineClass: 'column',
        },
        displayError: true,
      },
      {
        inputType: 'inputPassword',
        label: '新しいパスワード',
        inputName: 'new_pass',
        initialValue: '',
        placeholder: '新しいパスワードを入力してください',
        className: {
          outLineClass: 'column',
        },
        displayError: true,
      },
      {
        inputType: 'inputPassword',
        label: '新しいパスワード(再入力)',
        inputName: 'confirm_pass',
        initialValue: '',
        placeholder: '新しいパスワードを入力してください',
        className: {
          outLineClass: 'column',
        },
        displayError: true,
      },
    ],
    button: {
      width: 'small',
      text: '変更',
      fontSize: '14px',
      style: {
        fontWeight: 'bold',
        backgroundColor: '#0056d3',
        color: '#ffffff',
        borderRadius: '6px',
        height: '44px',
        width: '240px',
        margin: '20px 0 0',
      },
    },
    subButton: {
      width: 'small',
      text: '戻る',
      fontSize: '14px',
      style: {
        fontWeight: 'bold',
        backgroundColor: '#e9f2fc',
        color: '#486083',
        borderRadius: '6px',
        height: '44px',
        width: '240px',
        margin: '20px 0 0',
      },
    },
  },
  testInputs: {
    inputs: [
      // インプットデイトウィズインプットデイトのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   inputType: 'inputDateWithInputDate', //inputのtype
      //   label: '開始日時', //ラベルの名前
      //   inputName: ['start_day', 'end_day'], //DBのカラム名
      //   initialValue: ['', '2022/07/21'], //inputの初期値
      //   placeholder: 'YYYY/MM/DD',
      //   unit: ['〜'], //インプット横の単位
      //   className: {
      //     areaClass: 'claim_list_input_date_area',
      //     labelClass: '',
      //     inputClass: '',
      //   },
      //   style: {
      //     areaStyle: {width: '100%'},
      //     labelStyle: {},
      //     inputStyle: {},
      //   }
      // },

      // ラジオボタンとインプットの複合のフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーー
      {
        radioButtonTitle: '(乙)計算方法',
        labelRequired: true,
        radioButtonLabelAndChecked: [
          {label: '課税率', id: 'tax_rate_button', checked: true},
          {label: "課税額", id: 'tax_amount_button', checked: false},
        ],
        inputName: ['calc_method', 'tax_rate', 'tax_amount'],
        initialValue: ['', ''],
        inputType: 'radioWithInput',
        className: {
          areaClass: '',
          labelClass: '',
          inputClass: '',
          displayClass: '',
        },
        style: {
          areaStyle: {},
          labelStyle: {},
          inputStyle: {},
          displayStyle: {},
        }
      },

      //インプットテルのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   inputType: 'inputTell',
      //   label: '電話番号',
      //   labelRequired: true,
      //   inputName: ['tell_1', 'tell_2', 'tell_3'],
      //   initialValue: ['', '', ''],
      //   placeholder: ['03', '1234', '5678'],
      //   unit: ['-', '-', ''],
      //   className: {
      //     areaClass: '',
      //     labelClass: '',
      //     inputClass: '',
      //   },
      //   style: {
      //     areaStyle: {},
      //     labelStyle: {},
      //     inputStyle: {},
      //   }
      // },

      //インプットパスワードのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   inputType: 'inputPassword',
      //   label: 'パスワード',
      //   labelRequired: true,
      //   inputName: 'pass',
      //   initialValue: '',
      //   placeholder: 'パスワードを入力してください',
      //   className: {
      //     outLineClass: '',
      //     areaClass: '',
      //   },
      // },

      //インプットウィズチェックボックスのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   inputName: 'mail_address',
      //   labelRequired: true,
      //   inputNameCheckBox: 'save_mail_address',
      //   inputType: 'inputWithCheckBox',
      //   initialValue: '',
      //   checkBoxChecked: '',
      //   label: ['メールアドレス', 'メールアドレスを保存する'],
      //   placeholder: 'メールアドレスを入力してください',
      //   className: {
      //     areaClass: '',
      //   },
      // },

      //インプットのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   inputType: 'input',
      //   label: '法人ID',
      //   inputName: 'corporate_id',
      //   initialValue: '',
      //   placeholder: '法人IDを入力してください',
      //   className: {
      //     areaClass: '',
      //   },
      // },

      //テキスト
      // {
      //   inputType: 'textBox',
      //   label: '備考',
      //   inputName: 'text_area',
      //   initialValue: '',
      //   placeholder: '備考を入力してください',
      //   className: {
      //     areaClass: '',
      //   },
      // },

      // {
      //   inputType: 'inputTell',
      //   label: '電話番号',
      //   inputName: ['tell_1', 'tell_2', 'tell_3'],
      //   initialValue: ['', '', ''],
      //   placeholder: ['03', '1234', '5678'],
      //   className: {
      //     areaClass: '',
      //     labelClass: '',
      //     inputClass: '',
      //   },
      //   style: {
      //     areaStyle: {},
      //     labelStyle: {},
      //     inputStyle: {},
      //   }
      // },
      // {
      //   inputType: 'inputPassword',
      //   label: 'パスワード',
      //   inputName: 'pass',
      //   initialValue: '',
      //   placeholder: 'パスワードを入力してください',
      //   className: {
      //     areaClass: 'column',
      //   },
      // },

      //インプットウィズインプットのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーー
      {
        inputType: 'inputWithInput',
        label: '名前',
        inputName: ['last_name', 'first_name'],
        initialValue: ['', ''],
        placeholder: ['セイ', 'メイ'],
        unit: ['年', ''],
      },

      //ラジオボタンのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   radioButtonTitle: '雇用形態',
      //   labelRequired: true,
      //   radioButtonLabelAndChecked: [
      //     {label: '業務委託', checked: true},
      //     {label: "パート・アルバイト", checked: false},
      //     // {label: "正社員", checked: false}
      //   ],
      //   inputName: 'employment',
      //   initialValue: ['business_format', 'part_time', 'hoge'],
      //   inputType: 'radioButton',
      //   className: {
      //     areaClass: '',
      //     labelClass: '',
      //     inputClass: '',
      //     displayClass: '',
      //   },
      //   style: {
      //     areaStyle: {},
      //     labelStyle: {},
      //     inputStyle: {},
      //     displayStyle: {},
      //   }
      // },

      //トグルボタンのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   // toggleButtonText: {
      //   //   default: 'OFF',
      //   //   checked: 'ON'
      //   // },
      //   inputName: 'is_work_shift',
      //   checkBoxChecked: true,
      //   inputType: 'toggleButton',
      //   className: {
      //     areaClass: '',
      //     labelClass: '',
      //   },
      //   style: {
      //     areaStyle: {},
      //     labelStyle: {},
      //     inputStyle: {},
      //   }
      // },

      // チェックボックスのフォーマット (複数のチェックボックスを作成したい場合)ーーーーーーーーーーーーーー
      {
        inputName: ['h', 'ho', 'hog'],
        inputType: 'checkBox',
        initialValue: [true, false, false],
        label: ['開催地ID', '市区町村' , '新規登録日時'],
        className: {
          inputClass: '',
        },
        style: {
          inputStyle: {},
        }
      },

      //チェックボックスのフォーマット (一つのチェックボックスを作成したい場合)ーーーーーーーーーーーーーー
      // {
      //   inputName: 'hoge',
      //   inputType: 'checkBox',
      //   initialValue: false,
      //   label: '全選択',
      //   className: {
      //     areaClass: '',
      //   },
      //   style: {
      //     areaStyle: {},
      //     labelStyle: {},
      //     inputStyle: {},
      //   }
      // },

      //セレクトボックスのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   inputType: 'selectBox', //inputのtype
      //   label: '資格', //ラベルの名前
      //   labelRequired: true, //ラベルの※マークの有無
      //   inputName: 'certification', //DBのカラム名
      //   initialValue: ['dog', 'cat', 'bard'], //inputの初期値
      //   selectedSelectBox: 'dog',
      //   selectBoxOptions: ['犬', '猫', '鳥'],
      //   placeholder: '好きなペットを選択してください',
      //   unit: '', //インプット横の単位
      //   className: {
      //     areaClass: 'column', //column
      //     labelClass: '',
      //     inputClass: '',
      //   },
      //   style: {
      //     areaStyle: {},
      //     labelStyle: {},
      //     inputStyle: {},
      //   }
      // },

      //セレクトボックスとアドボタンが一つになったもののフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   inputType: 'selectBoxWithAddButton', //inputのtype
      //   label: '好きなペット', //ラベルの名前
      //   labelRequired: true, //ラベルの※マークの有無
      //   inputName: 'like_pet', //DBのカラム名
      //   initialValue: ['dog', 'cat', 'bard'], //inputの初期値
      //   selectedSelectBox: 'dog',
      //   selectBoxOptions: ['犬', '猫', '鳥'],
      //   placeholder: '好きなペットを選択してください',
      //   unit: '', //インプット横の単位
      //   className: {
      //     areaClass: '',
      //     labelClass: '',
      //     inputClass: '',
      //   },
      //   style: {
      //     areaStyle: {},
      //     labelStyle: {},
      //     inputStyle: {},
      //     buttonStyle: {},
      //   }
      // },

      //セレクトボックスウィズセレクトボックスのフォーマットーーーーーーーーーーーーーーーーーーーーーーーー
      {
        inputType: 'selectBoxWithSelectBox', //inputのtype
        label: '資格', //ラベルの名前
        labelRequired: true, //ラベルの※マークの有無
        inputName: ['certification', 'certification2'], //DBのカラム名
        initialValue: [
          ['dog', 'cat', 'bard'],
          ['hoge', 'huga', 'hige'],
        ], //inputの初期値
        selectedSelectBox: ['dog', 'hoge'],
        selectBoxOptions: [
          ['犬', '猫', '鳥'],
          ['ほげ', 'フガ', 'ヒゲ']
        ],
        placeholder: ['好きなペットを選択してください', '好きな文字を選択してください'],
        unit: ['/', ''], //インプット横の単位
        className: {
          areaClass: 'claim_list_select_with_select',
          labelClass: '',
          inputClass: '',
        },
        style: {
          areaStyle: {},
          labelStyle: {},
          inputStyle: {},
        }
      },

      //選択したものが複数表示されるセレクトボックスのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   inputType: 'pickUpSelectBox', //inputのtype
      //   isButton: false, //trueにするとボタンが追加される
      //   label: '資格', //ラベルの名前
      //   labelRequired: true, //ラベルの※マークの有無
      //   inputName: 'certification', //DBのカラム名
      //   initialValue: ['dog', 'cat', 'bard', 'bard2', 'bard3', 'raion'], //inputの初期値
      //   selectBoxOptions: ['犬', '猫', '鳥', '白鳥', 'ニワトリ', 'ライオン'],
      //   placeholder: '好きなペットを選択してください',
      //   unit: '', //インプット横の単位
      //   className: {
      //     areaClass: 'column', //column
      //     labelClass: '',
      //     inputClass: '',
      //   },
      //   style: {
      //     areaStyle: {},
      //     labelStyle: {},
      //     inputStyle: {},
      //   }
      // },

      // インプットデイトのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
      {
        inputType: 'inputDate', //inputのtype
        label: '開始日時', //ラベルの名前
        labelRequired: true, //ラベルの※マークの有無
        inputName: 'start_day', //DBのカラム名
        initialValue: '', //inputの初期値
        placeholder: 'YYYY/MM/DD',
        unit: '', //インプット横の単位
        className: {
          areaClass: '', //column
          labelClass: '',
          inputClass: '',
        },
        style: {
          areaStyle: {},
          labelStyle: {},
          inputStyle: {},
        }
      },

      //インプットマンスのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   inputType: 'inputMonth', //inputのtype
      //   label: '開始日時', //ラベルの名前
      //   labelRequired: true, //ラベルの※マークの有無
      //   inputName: 'start_time', //DBのカラム名
      //   initialValue: '', //inputの初期値
      //   placeholder: '15:00',
      //   unit: '', //インプット横の単位
      //   className: {
      //     areaClass: '', //column
      //     labelClass: '',
      //     inputClass: '',
      //   },
      //   style: {
      //     areaStyle: {},
      //     labelStyle: {},
      //     inputStyle: {},
      //   }
      // },

      //インプットタイムのフォーマットーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
      // {
      //   label: '前日報告', //ラベルの名前
      //   inputType: 'inputTime', //inputのtype
      //   inputName: 'start_time', //DBのカラム名
      //   initialValue: '', //inputの初期値
      //   placeholder: '00:00',
      //   unit: '', //インプット横の単位
      //   className: {
      //     areaClass: '',
      //     labelClass: '',
      //     inputClass: '',
      //   },
      //   style: {
      //     areaStyle: {},
      //     labelStyle: {},
      //     inputStyle: {},
      //   }
      // },
    ],
  },
};

export default FormInfos;
