import './style.scss';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import SurveyInputs from './inputs';
import Icons from '../../../constants/icons';
import {
  deleteArrayItem,
  handlerFormikFieldChange,
} from '../../../utils/fnUtil';
import AddButton from '../../../components/addButton';
import { Tooltip } from 'antd';
import ImageUploader from '../../../components/imageUploader/imageUploader';
import { imageUpload } from '../../../redux/slice/matter';
import { useDispatch } from 'react-redux';
import Options from '../../../constants/options';
import Button from '../../../components/button';
import moment from 'moment/moment';
import React, { useState } from 'react';
import SurveyResultModal from '../../../components/modal/surveyResultModal';
import { surveyAnswerResult } from '../../../redux/slice/survey';
import { memo } from 'react';
import DeleteIcon from '../../../components/deleteIcon';

const helpMessage =
  '画像埋め込み：%image1% ~ %image5%' +
  '\r\n\r\n' +
  '\r\n埋め込みタグ：' +
  '\r\nリンク ' +
  '\r\n例)%html%<a href="https://mmm.jp">リンク</a>%html%' +
  '\r\n文字色変更' +
  '\r\n例)%html%<p style="color:bule">青色</p>%html%';

const addSty = {
  width: '36px',
  height: '36px',
  cursor: 'pointer',
};

const delSty = {
  width: '26px',
  height: '26px',
  cursor: 'pointer',
  position: 'absolute',
  right: '10px',
  top: '6px',
};

const delSty2 = {
  width: '26px',
  height: '26px',
  cursor: 'pointer',
};

const SurveyRegisterTab = memo(({ formik, isEdit }) => {
  const {
    values: { surveyType, surveyQuestionInfos },
    setFieldValue,
  } = formik;

  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const toggleDeleteModal = () => {
    setModalVisible(!modalVisible);
  };

  const adjustZIndex = () => {
    const commonPageBase = document.querySelector('.commonPage--base');
    commonPageBase.style.zIndex = 'auto';
  };

  const checkBatchFlag = index => {
    return surveyQuestionInfos?.[index].batchFlag === 1;
  };

  const checkAnswerMethod = index => {
    const answerMethod = surveyQuestionInfos?.[index].answerMethod;
    return answerMethod == 0
      ? '単一選択'
      : answerMethod == 1
      ? '複数選択'
      : '入力式';
  };

  const checkGraphType = (param, index) => {
    const graphType = surveyQuestionInfos?.[index]?.[param];
    return graphType == 0
      ? '円グラフ'
      : graphType == 1
      ? '縦棒グラフ'
      : '横棒グラフ';
  };

  const exportGraphType = (index, param) => {
    const graphTypeData = surveyQuestionInfos?.[index]?.[param];
    const graphTypes = ['円グラフ', '縦棒グラフ', '横棒グラフ'];
    const result = [];

    graphTypeData?.forEach(number => {
      if (number >= 0 && number < graphTypes.length) {
        result.push(graphTypes[number]);
      }
    });

    return result.join(',');
  };

  const checkGraphLabel = (i, index) => {
    let label;
    const graphLabel =
      surveyQuestionInfos?.[i]?.[`label${index}`]?.[0] === 'on';
    if (index === 0 && graphLabel) {
      label = '性別';
    } else if (index === 1 && graphLabel) {
      label = '年代';
    } else if (index === 2 && graphLabel) {
      label = '職業';
    } else if (index === 3 && graphLabel) {
      label = 'スタッフポジション';
    } else if (index === 4 && graphLabel) {
      label = '居住都道府県';
    } else {
      label = '';
    }
    return label;
  };

  const exportTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
    const day = String(tomorrow.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div className={'survey_register_tab--area'}>
        <div className={'survey_register_tab--survey_title_area'}>
          <div className={'survey_register_tab--survey_title_contents'}>
            <CustomFormikInput
              {...SurveyInputs.title[0]}
              formik={formik}
              disabled={surveyQuestionInfos?.some(obj => obj?.batchFlag === 1)}
            />

            <div className={'survey_register_tab--survey_type_area'}>
              <CustomFormikInput
                {...SurveyInputs.title[1]}
                formik={formik}
                radioButtonLabelAndChecked={[
                  { label: '毎日アンケート', checked: surveyType == 0 },
                  { label: '企業アンケート', checked: surveyType == 1 },
                ]}
                disabled={surveyQuestionInfos?.some(
                  obj => obj?.batchFlag === 1
                )}
              />
              <CustomFormikInput
                {...SurveyInputs.title[2]}
                formik={formik}
                disabled={
                  surveyQuestionInfos?.some(obj => obj?.batchFlag === 1) ||
                  surveyType == 0
                }
              />
            </div>

            <CustomFormikInput
              {...SurveyInputs.title[3]}
              formik={formik}
              disabled={surveyQuestionInfos?.some(obj => obj?.batchFlag === 1)}
            />
          </div>

          <div className={'survey_register_tab--survey_title_image'}>
            <div
              className="report_image--images"
              style={{ width: 'fit-content' }}
            >
              {surveyQuestionInfos?.some(obj => obj?.batchFlag === 1) ? (
                <div className={'survey_register_tab--image'}>
                  <img
                    src={
                      !formik?.values?.surveyThumbnailImage
                        ? Icons.icon.photoB
                        : formik?.values?.surveyThumbnailImage
                    }
                  />
                </div>
              ) : (
                <ImageUploader
                  image={formik.values.surveyThumbnailImage}
                  onDelete={() => {
                    handlerFormikFieldChange(
                      formik,
                      `surveyThumbnailImage`,
                      ''
                    );
                  }}
                  onAddImage={async img => {
                    const imageUrl = await dispatch(imageUpload(img)).unwrap();

                    handlerFormikFieldChange(
                      formik,
                      `surveyThumbnailImage`,
                      imageUrl
                    );
                    handlerFormikFieldChange(
                      formik,
                      `surveyThumbnailImageActive`,
                      1
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className={'survey_register_tab--contents_area'}>
          {surveyQuestionInfos?.map((obj, i) => {
            return (
              <div
                className={'survey_register_tab--contents'}
                key={`survey_register_tab--contents_${i}`}
              >
                {formik?.values?.surveyQuestionInfos?.[i]?.batchFlag === 0 &&
                formik?.values?.surveyQuestionInfos?.length > 1 ? (
                  <DeleteIcon
                    title="削除"
                    style={delSty}
                    onClick={() => {
                      setFieldValue(
                        'surveyQuestionInfos',
                        deleteArrayItem(surveyQuestionInfos, i)
                      );
                      formik?.errors?.surveyQuestionInfos?.splice(i, 1);
                    }}
                  />
                ) : (
                  <></>
                )}

                <div
                  className={'survey_register_tab--contents_info'}
                  key={`s_r_t--c_i_${surveyType}`}
                >
                  <div
                    className={
                      'survey_register_tab--contents_info_date_mass_add_btn'
                    }
                  >
                    {/*回答日*/}
                    {checkBatchFlag(i) ? (
                      <>
                        <div className="survey_register_tab--text_area">
                          <div className="survey_register_tab--text_area_label">
                            回答日<span>*</span>
                          </div>
                          <div className="survey_register_tab--text_area_date">
                            {surveyType == 0 ? (
                              <>
                                {moment(
                                  formik?.values?.surveyQuestionInfos?.[i]
                                    ?.answerDate
                                ).format('YYYY/MM/DD')}
                              </>
                            ) : (
                              <>
                                {`${moment(
                                  formik?.values?.surveyQuestionInfos?.[i]
                                    ?.answerDateFrom
                                ).format('YYYY/MM/DD')} 〜 ${moment(
                                  formik?.values?.surveyQuestionInfos?.[i]
                                    ?.answerDateTo
                                ).format('YYYY/MM/DD')}`}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {surveyType == 0 ? (
                          <CustomFormikInput
                            {...SurveyInputs.content[0]}
                            formik={formik}
                            inputName={`surveyQuestionInfos.[${i}].answerDate`}
                            disabledDate={moment(
                              exportTomorrowDate(),
                              'YYYY/MM/DD'
                            ).format('YYYY/MM/DD')}
                          />
                        ) : (
                          <div
                            className={
                              'survey_register_tab--contents_info_date_mass'
                            }
                          >
                            <CustomFormikInput
                              {...SurveyInputs.content[1]}
                              formik={formik}
                              inputName={`surveyQuestionInfos.[${i}].answerDateFrom`}
                              disabledFutureDate={
                                formik?.values?.surveyQuestionInfos?.[i]
                                  .answerDateTo
                                  ? moment(
                                      formik?.values?.surveyQuestionInfos?.[i]
                                        .answerDateTo,
                                      'YYYY/MM/DD'
                                    ).format('YYYY/MM/DD')
                                  : false
                              }
                              disabledDate={
                                !formik?.values?.surveyQuestionInfos?.[i]
                                  .answerDateTo
                                  ? exportTomorrowDate()
                                  : false
                              }
                            />
                            <span>〜</span>
                            <CustomFormikInput
                              {...SurveyInputs.content[2]}
                              formik={formik}
                              inputName={`surveyQuestionInfos.[${i}].answerDateTo`}
                              disabledDate={moment(
                                formik?.values?.surveyQuestionInfos?.[i]
                                  .answerDateFrom,
                                'YYYY/MM/DD'
                              ).format('YYYY/MM/DD')}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {checkBatchFlag(i) && (
                      <Button
                        icon={Icons.icon.analyzeNv}
                        text={'グラフ表示'}
                        style={{
                          ...Options.buttonStyles.csv,
                          width: '154px',
                        }}
                        onClick={() => {
                          toggleDeleteModal();
                          adjustZIndex();
                          dispatch(
                            surveyAnswerResult({
                              surveyId: formik.values.surveyId,
                              surveyBodyNumber:
                                surveyQuestionInfos?.[i]?.surveyBodyNumber,
                            })
                          );
                        }}
                      />
                    )}
                  </div>

                  {/*設問*/}
                  <div
                    className={'survey_register_tab--question_description_area'}
                  >
                    <div className={'survey_register_tab--label_area'}>
                      <span className={'survey_register_tab--label'}>{`設問${
                        i + 1
                      }`}</span>
                      <span className={'survey_register_tab--label_required'}>
                        *
                      </span>
                      <Tooltip
                        placement="bottom"
                        title={helpMessage}
                        mouseEnterDelay={0.5}
                        overlayClassName="attendance_process_modal--tooltip"
                        overlayInnerStyle={{
                          borderRadius: '6px',
                          padding: '22px 24px',
                          minWidth: '100%',
                          fontSize: '12px',
                        }}
                      >
                        <img
                          src={Icons.icon.help}
                          alt="help"
                          style={{
                            width: '20px',
                            height: '20px',
                            padding: '2.5px 0 0',
                          }}
                        />
                      </Tooltip>
                    </div>

                    <div className={'survey_register_tab--input_area'}>
                      {checkBatchFlag(i) ? (
                        <>
                          <div className={'survey_register_tab--required_area'}>
                            <span
                              className={'survey_register_tab--required_text'}
                            >
                              {surveyQuestionInfos?.[i]
                                ?.questionRequiredFlag === 1
                                ? '必須'
                                : '任意'}
                            </span>
                          </div>
                          <div
                            className={
                              'survey_register_tab--question_description_text'
                            }
                          >
                            {surveyQuestionInfos?.[i]?.questionDescription}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={'survey_register_tab--required_area'}>
                            <span>必須</span>
                            <CustomFormikInput
                              {...SurveyInputs.content[3]}
                              formik={formik}
                              inputName={`surveyQuestionInfos.[${i}].questionRequiredFlag`}
                              checkBoxChecked={obj?.questionRequiredFlag === 1}
                              key={obj?.questionRequiredFlag}
                              onChange={e =>
                                formik.setFieldValue(
                                  `surveyQuestionInfos.[${i}].questionRequiredFlag`,
                                  e.target.checked === true ? 1 : 0
                                )
                              }
                            />
                          </div>
                          <CustomFormikInput
                            {...SurveyInputs.content[4]}
                            formik={formik}
                            inputName={`surveyQuestionInfos.[${i}].questionDescription`}
                          />
                        </>
                      )}
                    </div>
                  </div>

                  {/*画像*/}
                  <div className={'survey_register_tab--image_area'}>
                    <div className={'survey_register_tab--image_label'}>
                      画像
                    </div>

                    {checkBatchFlag(i) ? (
                      <div className={'survey_register_tab--image_title_area'}>
                        <div className={'report_image--images'}>
                          {[...Array(5)].map((_, idx) => (
                            <div
                              className={'survey_register_tab--image'}
                              key={`s_r_t--i_${idx}`}
                            >
                              <img
                                src={
                                  surveyQuestionInfos[i]?.[
                                    `questionImage_${idx + 1}`
                                  ] ?? Icons.icon.photoB
                                }
                              />
                            </div>
                          ))}
                        </div>
                        <div className={'survey_register_tab--image_title'}>
                          {[...Array(5)].map((_, idx) => (
                            <span key={`s_r_t--i_t_${idx}`}>{`画像${
                              idx + 1
                            }`}</span>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <>
                        {surveyQuestionInfos[i]?.folder?.map((f, index) => (
                          <div
                            className={'survey_register_tab--image_title_area'}
                            key={`s_r_t--i_t_a${index}${i}`}
                          >
                            <div
                              className="report_image--images"
                              key={`r_i--i${index}`}
                            >
                              {Array(5)
                                .fill('')
                                .map((_, k) => (
                                  <ImageUploader
                                    image={f[`folderImage${k + 1}`]}
                                    key={`folderImage${k}${index}${i}`}
                                    onDelete={() => {
                                      handlerFormikFieldChange(
                                        formik,
                                        `surveyQuestionInfos[${i}].folder.[${index}].folderImage${
                                          k + 1
                                        }`,
                                        ''
                                      );
                                    }}
                                    onAddImage={async img => {
                                      const imageUrl = await dispatch(
                                        imageUpload(img)
                                      ).unwrap();

                                      handlerFormikFieldChange(
                                        formik,
                                        `surveyQuestionInfos[${i}].folder[${index}].folderImage${
                                          k + 1
                                        }`,
                                        imageUrl
                                      );
                                      handlerFormikFieldChange(
                                        formik,
                                        `surveyQuestionInfos[${i}].folder[${index}].folderImage${
                                          k + 1
                                        }Active`,
                                        1
                                      );
                                    }}
                                  />
                                ))}
                            </div>
                            <div className={'survey_register_tab--image_title'}>
                              {[...Array(5)].map((_, idx) => (
                                <span key={`s_r_t--i_t_${idx}`}>{`画像${
                                  idx + 1
                                }`}</span>
                              ))}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>

                  {/*回答方法*/}
                  {checkBatchFlag(i) ? (
                    <div
                      className={'survey_register_tab--answer_method_text_area'}
                    >
                      <div className="survey_register_tab--text_area_label">
                        回答方法<span>*</span>
                      </div>
                      <div
                        className={'survey_register_tab--answer_method_text'}
                      >
                        <div
                          className={
                            'survey_register_tab--answer_method_text_unity'
                          }
                        >
                          {checkAnswerMethod(i)}
                        </div>
                        {[...Array(10)].map((_, index) => {
                          return !surveyQuestionInfos?.[i]?.[
                            `choice_${index + 1}`
                          ] ? (
                            <></>
                          ) : (
                            <div
                              className={
                                'survey_register_tab--answer_method_text_unity'
                              }
                              key={`srt--amtu_${index}`}
                            >
                              <span style={{ color: '#bcbcbc' }}>{`選択肢${
                                index + 1
                              }`}</span>
                              <span>
                                {
                                  surveyQuestionInfos?.[i]?.[
                                    `choice_${index + 1}`
                                  ]
                                }
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <>
                      <CustomFormikInput
                        {...SurveyInputs.content[5]}
                        initialValue={
                          surveyType == 0 ? ['0', '1'] : ['0', '1', '2']
                        }
                        formik={formik}
                        inputName={`surveyQuestionInfos.[${i}].answerMethod`}
                        radioButtonLabelAndChecked={
                          surveyType == 0
                            ? [
                                {
                                  label: '単一選択',
                                  checked:
                                    surveyQuestionInfos[i].answerMethod == 0,
                                },
                                {
                                  label: '複数選択',
                                  checked:
                                    surveyQuestionInfos[i].answerMethod == 1,
                                },
                              ]
                            : [
                                {
                                  label: '単一選択',
                                  checked:
                                    surveyQuestionInfos[i].answerMethod == 0,
                                },
                                {
                                  label: '複数選択',
                                  checked:
                                    surveyQuestionInfos[i].answerMethod == 1,
                                },
                                {
                                  label: '入力式',
                                  checked:
                                    surveyQuestionInfos[i].answerMethod == 2,
                                },
                              ]
                        }
                        onChange={e => {
                          const answerMethodId = Number(e.target.id);
                          formik.setFieldValue(
                            `surveyQuestionInfos.[${i}].answerMethod`,
                            answerMethodId
                          );
                          if (answerMethodId === 2) {
                            formik.setFieldValue(
                              `surveyQuestionInfos.[${i}].aggregationProcessGraphType`,
                              []
                            );
                          }
                        }}
                      />
                      {surveyQuestionInfos[i].answerMethod != 2 ? (
                        surveyQuestionInfos[i]?.choices?.map((obj, idx) => {
                          return (
                            <div
                              className={'survey_register_tab--choices'}
                              key={`s${i}c${idx}`}
                            >
                              <CustomFormikInput
                                {...SurveyInputs.content[6]}
                                inputName={`surveyQuestionInfos.[${i}].choices.[${idx}].choice`}
                                label={`選択肢${idx + 1}`}
                                formik={formik}
                                key={`s${i}c${idx}c${idx + 1}_h`}
                              />
                              {idx ===
                              surveyQuestionInfos[i]?.choices?.length - 1 ? (
                                <AddButton
                                  style={addSty}
                                  type="round"
                                  onClick={() => {
                                    setFieldValue(
                                      `surveyQuestionInfos.[${i}].choices`,
                                      [
                                        ...surveyQuestionInfos[i]?.choices,
                                        { choice: '' },
                                      ]
                                    );
                                  }}
                                  disabled={
                                    surveyQuestionInfos[i]?.choices?.length >=
                                    10
                                  }
                                />
                              ) : (
                                <img
                                  style={delSty2}
                                  src={Icons.icon.xCircleWh}
                                  alt="del"
                                  onClick={() => {
                                    setFieldValue(
                                      `surveyQuestionInfos.[${i}].choices`,
                                      deleteArrayItem(
                                        surveyQuestionInfos[i]?.choices,
                                        idx
                                      )
                                    );
                                  }}
                                />
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {/*回答結果表示方法*/}
                  {checkBatchFlag(i) ? (
                    <>
                      {surveyType == 0 ? (
                        <div
                          className={
                            'survey_register_tab--answer_method_text_area'
                          }
                        >
                          <div className="survey_register_tab--text_area_label">
                            回答結果表示方法<span>*</span>
                          </div>
                          <div
                            className={
                              'survey_register_tab--answer_method_text'
                            }
                          >
                            <div
                              className={
                                'survey_register_tab--answer_method_text_unity'
                              }
                            >
                              {surveyQuestionInfos[i]?.answerResultFlag === 1
                                ? '表示しない'
                                : checkGraphType('answerResultGraphType', i)}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <CustomFormikInput
                        {...SurveyInputs.content[7]}
                        formik={formik}
                        inputName={`surveyQuestionInfos.[${i}].answerResultFlag`}
                        radioButtonLabelAndChecked={[
                          {
                            label: '表示する',
                            checked:
                              surveyQuestionInfos[i].answerResultFlag == 0,
                          },
                          {
                            label: '表示しない',
                            checked:
                              surveyType == 1 ||
                              surveyQuestionInfos[i].answerResultFlag == 1,
                          },
                        ]}
                        disabled={surveyType == 1}
                        className={{
                          labelClass:
                            surveyType == 1 && 'answerResultFlag_disabled',
                        }}
                        onChange={e =>
                          formik.setFieldValue(
                            `surveyQuestionInfos.[${i}].answerResultFlag`,
                            Number(e.target.value)
                          )
                        }
                        key={`s_${surveyType}_a`}
                      />
                      <CustomFormikInput
                        {...SurveyInputs.content[8]}
                        formik={formik}
                        inputName={`surveyQuestionInfos.[${i}].answerResultGraphType`}
                        disabled={
                          surveyType == 1 ||
                          surveyQuestionInfos[i].answerResultFlag == 1
                        }
                        onChange={e =>
                          formik.setFieldValue(
                            `surveyQuestionInfos.[${i}].answerResultGraphType`,
                            e === undefined ? null : e
                          )
                        }
                        key={`surveyQuestionInfos.[${i}].answerResultGraphType`}
                      />
                    </>
                  )}

                  {/*集計処理*/}
                  {isEdit &&
                  checkAnswerMethod(i) === '入力式' &&
                  checkBatchFlag(i) ? (
                    <></>
                  ) : (
                    <div
                      className={'survey_register_tab--aggregation_graph_area'}
                    >
                      <div
                        className={
                          'survey_register_tab--aggregation_graph_label'
                        }
                      >
                        <div className={'survey_register_tab--label'}>
                          <span
                            style={{
                              color:
                                surveyQuestionInfos[i].answerMethod == 2
                                  ? '#BCBCBC'
                                  : '#646464',
                            }}
                          >
                            集計処理
                          </span>
                          <span>*</span>
                        </div>
                        <span
                          style={{
                            marginRight: '5px',
                            color:
                              surveyQuestionInfos[i].answerMethod == 2
                                ? '#BCBCBC'
                                : '#646464',
                          }}
                        >
                          (複数選択可)
                        </span>
                      </div>
                      {checkBatchFlag(i) ? (
                        <span>
                          {exportGraphType(i, 'aggregationProcessGraphType')}
                        </span>
                      ) : (
                        <CustomFormikInput
                          {...SurveyInputs.content[9]}
                          formik={formik}
                          inputName={`surveyQuestionInfos.[${i}].aggregationProcessGraphType`}
                          onChange={e =>
                            formik.setFieldValue(
                              `surveyQuestionInfos.[${i}].aggregationProcessGraphType`,
                              e
                            )
                          }
                          key={`surveyQuestionInfos.[${i}].aggregationProcessGraphType`}
                          disabled={surveyQuestionInfos[i].answerMethod == 2}
                        />
                      )}
                    </div>
                  )}

                  {/*集計処理ラベル*/}
                  <div className={'survey_register_tab--checkboxes'}>
                    {checkBatchFlag(i) ? (
                      <>
                        {[...Array(5)].map((_, index) => {
                          return surveyQuestionInfos?.[i]?.[
                            `label${index}`
                          ]?.[0] === 'on' ? (
                            <span>{checkGraphLabel(i, index)}</span>
                          ) : (
                            <></>
                          );
                        })}
                      </>
                    ) : (
                      <CustomFormikInput
                        {...SurveyInputs.content[10]}
                        formik={formik}
                        inputName={[
                          `surveyQuestionInfos.[${i}].label0`,
                          `surveyQuestionInfos.[${i}].label1`,
                          `surveyQuestionInfos.[${i}].label2`,
                          `surveyQuestionInfos.[${i}].label3`,
                          `surveyQuestionInfos.[${i}].label4`,
                        ]}
                        defaultChecked={[
                          surveyQuestionInfos[i]?.label0?.[0] === 'on',
                          surveyQuestionInfos[i]?.label1?.[0] === 'on',
                          surveyQuestionInfos[i]?.label2?.[0] === 'on',
                          surveyQuestionInfos[i]?.label3?.[0] === 'on',
                          surveyQuestionInfos[i]?.label4?.[0] === 'on',
                        ]}
                        key={surveyQuestionInfos.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          {/*ボタンエリア*/}
          <div className={'corporate_register--address_list_add_btn_area'}>
            <AddButton
              style={addSty}
              type="round"
              onClick={() => {
                setFieldValue('surveyQuestionInfos', [
                  ...surveyQuestionInfos,
                  {
                    surveyBodyNumber: null,
                    answerDate: '',
                    answerDateFrom: '',
                    answerDateTo: '',
                    questionRequiredFlag: 1,
                    questionDescription: '',
                    questionImage_1: '',
                    questionImage_2: '',
                    questionImage_3: '',
                    questionImage_4: '',
                    questionImage_5: '',
                    answerMethod: 0,
                    choice_1: '',
                    choice_2: '',
                    choice_3: '',
                    choice_4: '',
                    choice_5: '',
                    choice_6: '',
                    choice_7: '',
                    choice_8: '',
                    choice_9: '',
                    choice_10: '',
                    answerResultFlag: 0,
                    answerResultGraphType: null,
                    aggregationProcessGraphType: [],
                    aggregationProcessLabelList: '',
                    folder: [{}],
                    choices: [{ choice: '' }, { choice: '' }],
                    label0: [],
                    label1: [],
                    label2: [],
                    label3: [],
                    label4: [],
                    batchFlag: 0,
                  },
                ]);
                formik?.errors?.surveyQuestionInfos?.push({
                  answerDate: '回答日:必須項目です。',
                  answerDateFrom: '回答日(開始):必須項目です。',
                  answerDateTo: '回答日(終了):必須項目です。',
                  questionDescription: '設問内容:必須項目です。',
                  choices: [{ choice: '選択肢:一つ以上入力してください。' }],
                  answerResultGraphType: '回答結果表示方法:必須項目です。',
                  aggregationProcessGraphType: '集計処理:必須項目です。',
                });
              }}
            />
          </div>
        </div>
      </div>

      <SurveyResultModal
        modalVisible={modalVisible}
        toggleVisible={toggleDeleteModal}
      />
    </>
  );
});

export default SurveyRegisterTab;
