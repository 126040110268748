import Icons from '../../../constants/icons';
import './style.scss';
import React, { useEffect, useState } from 'react';
import StaffPlanShiftInfos from './inputInfos';
import CustomInput from '../../../components/customInput';
import Button from '../../../components/button';
import { useNavigate } from 'react-router-dom';
import AttendForStaffSp from './forStaffSp';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { useSelector } from 'react-redux';
import moment from 'moment';

const StaffPlanConfirm = () => {
  const navigate = useNavigate();
  const accountInfo = useSelector(state => state.account);

  const nowDate = new Date();

  const nowYear = nowDate.getFullYear();

  const nowMonth = nowDate.getMonth();

  let selectBoxYearOptions = [];
  [...Array(11)].map((_, idx) => {
    selectBoxYearOptions.push(nowYear + idx);
    selectBoxYearOptions.push(nowYear - idx);
  });
  selectBoxYearOptions = Array.from(new Set(selectBoxYearOptions));
  selectBoxYearOptions.sort((a, b) => a - b);

  let selectBoxMonthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const [date, setDate] = useState({
    year: nowYear,
    month: nowMonth + 1,
  });
  const padZero = (num) => {
    return num.toString().padStart(2, '0');
  };

  const openStartDay = () => {
    const previousDay = moment().subtract(1, 'd');
    const year = previousDay.year();
    const month = padZero(previousDay.month() + 1);
    const day = padZero(previousDay.date());

    return `${year}${month}${day}`;
  };
  const openEndDay =
    (moment().add(7, 'd').month() + 1).toString() +
    moment().add(7, 'd').date().toString().padStart(2, '0');

  const [apiData, setApiData] = useState([]);
  const { item } = apiData;

  const getApiData = async () => {
    const res = await fetchRequest(
      apiUrl.staffSchedule.attend,
      {
        staffId: accountInfo.staffId,
        year: date.year,
        month: date.month,
      },
      'GET'
    );
    setApiData(res);
  };

  const displayShift = () => {
    let searchYear = document.getElementById('search_year').value;
    let searchMonth = document.getElementById('search_month').value;
    setDate({ ...date, year: searchYear, month: searchMonth });
  };

  const addDate = async () => {
    let newEndMonth;
    if (date.month > 11) {
      setDate({ ...date, month: 1, year: Number(date.year) + 1 });
    } else {
      setDate({ ...date, month: Number(date.month) + 1 });
    }
  };

  const reduceDate = () => {
    setDate({ ...date, month: date.month - 1 });
    if (date.month < 2) {
      setDate({ ...date, month: 12, year: Number(date.year) - 1 });
    } else {
      setDate({ ...date, month: Number(date.month) - 1 });
    }
  };

  const tableInfosShift = [
    '日付',
    'シフト提出',
    '案件名',
    '勤務時間',
    '開催地最寄駅',
  ];

  const getWeek = (year, month, day) => {
    const JapaneseHolidays = require('japanese-holidays');

    const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
    let targetDay = new Date(year, month - 1, day);

    const holiday = JapaneseHolidays.isHoliday(targetDay);

    if (holiday) {
      return `${weekDay[targetDay.getDay()]}・祝日`;
    } else {
      return weekDay[targetDay.getDay()];
    }
  };

  const buttonStyle = {
    searchButton: {
      width: '240px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    shiftEditButton: {
      width: '140px',
      height: '44px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
  };

  useEffect(() => {
    getApiData();
  }, [date]);

  return (
    <>
      <div className={'attend_forPc'}>
        <div className={'staff_plan_register--search_area'}>
          <div className={'staff_plan_register--input_area'}>
            <CustomInput
              {...StaffPlanShiftInfos.search[0]}
              initialValue={selectBoxYearOptions}
              selectBoxOptions={selectBoxYearOptions}
              selectedSelectBox={nowYear}
            />
            <CustomInput
              {...StaffPlanShiftInfos.search[1]}
              initialValue={selectBoxMonthOptions}
              selectBoxOptions={selectBoxMonthOptions}
              selectedSelectBox={nowMonth + 1}
            />
          </div>
          <div className={'staff_plan_register--button_area'}>
            <Button
              text={'表示'}
              style={buttonStyle.searchButton}
              onClick={displayShift}
            />
          </div>
        </div>

        <div className={'staff_plan--edit_page'}>
          <div className={'staff_plan--lower_part'}>
            <div className={'staff_plan--bar_area'}>
              <div className={'staff_plan--bar_content_status'}>
                <button
                  className={'staff_plan--calender_prev_button'}
                  onClick={reduceDate}
                >
                  <img src={Icons.icon.lightNv} />
                  前月
                </button>

                <span
                  style={{
                    width: '131px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {date.month}月提出分
                </span>

                <button
                  className={'staff_plan--calender_next_button'}
                  onClick={addDate}
                >
                  次月
                  <img src={Icons.icon.rightNv} />
                </button>
                <div className={'staff_plan--calender_status_area'}>
                  <div
                    className={
                      apiData?.status
                        ? 'staff_plan_confirm--calender_status_ture '
                        : 'staff_plan--calender_status'
                    }
                  >
                    {apiData?.status ? '提出済' : '未提出'}
                  </div>
                  <div className={'staff_plan--calender_update_info'}>
                    提出日時{' '}
                    {apiData?.submitDate
                      ? apiData?.submitDate.replace(/-/g, '/')
                      : '-'}
                  </div>
                </div>

                <div
                  className={'staff_plan--calender_status_area'}
                  style={{ marginLeft: '0' }}
                >
                  <div className={'staff_plan_confirm--assign_info'}>
                    <div className={'staff_plan_confirm--assign_info_label'}>
                      出勤可能日数：
                    </div>
                    <div className={'staff_plan_confirm--assign_info_label'}>
                      {apiData?.availableCount !== 0
                        ? apiData?.availableCount
                        : '0'}
                      日
                    </div>
                  </div>
                  <div className={'staff_plan_confirm--assign_info'}>
                    <div className={'staff_plan_confirm--assign_info_label'}>
                      出勤確定日数：
                    </div>
                    <div className={'staff_plan_confirm--assign_info_label'}>
                      {apiData?.attendCount !== 0 ? apiData?.attendCount : '0'}
                      日
                    </div>
                  </div>
                </div>
              </div>

              <Button
                icon={Icons.icon.editGr}
                text={'シフト入力'}
                style={buttonStyle.shiftEditButton}
                onClick={() => {
                  navigate('/staff-plan/register', {
                    state: {
                      year: date.year,
                      month: date.month,
                    },
                  });
                }}
              />
            </div>

            <div className={'staff_plan--contents_area'}>
              <table
                className={'staff_plan--shift_table staff_plan_confirm--table'}
                border="0"
              >
                <tbody>
                  <tr>
                    {tableInfosShift.map((info, idx) => (
                      <td
                        key={'staff_plan--td' + idx}
                        className={
                          'staff_plan--td_' +
                          idx +
                          ' staff_plan_confirm--td_' +
                          idx
                        }
                      >
                        <div className={'staff_plan--td_contents'}>{info}</div>
                      </td>
                    ))}
                  </tr>

                  {[...Array(new Date(date.year, date.month, 0).getDate())].map(
                    (_, idx) => (
                      <tr
                        key={'staff_plan--tr' + idx}
                        style={{minHeight: '80px'}}
                        // onClick={() => {
                        //   const attendDetail = (item ?? []).find(
                        //     i => i.date === idx + 1
                        //   );
                        //   attendDetail?.matterNumber &&
                        //     attendDetail?.assignFlag &&
                        //     openStartDay <=
                        //       date.month.toString() +
                        //         (idx + 1).toString().padStart(2, '0') &&
                        //     date.month.toString() +
                        //       (idx + 1).toString().padStart(2, '0') <=
                        //       openEndDay &&
                        //     navigate('/recruit-matter/detail', {
                        //       state: {
                        //         matterNumber: attendDetail.matterNumber,
                        //         matterDateNumber: attendDetail.matterDateNumber,
                        //         shiftId: attendDetail.shiftId,
                        //         assignAccountFlag: 0,
                        //         assignId: accountInfo.staffId,
                        //       },
                        //     });
                        // }}
                        className={
                          getWeek(date.year, date.month, idx + 1) === '日' ||
                          getWeek(date.year, date.month, idx + 1).includes(
                            '祝日'
                          )
                            ? `staff_plan--shift_table_tr_sun ${
                                item &&
                                item[
                                  item?.findIndex(
                                    obj => obj.date === idx + 1
                                  ) ?? 0
                                ]
                                  ? 'staff_plan_confirm--table_tr'
                                  : ''
                              }`
                            : getWeek(date.year, date.month, idx + 1) === '土'
                            ? `staff_plan--shift_table_tr_sat ${
                                item &&
                                item[
                                  item?.findIndex(
                                    obj => obj.date === idx + 1
                                  ) ?? 0
                                ]
                                  ? 'staff_plan_confirm--table_tr'
                                  : ''
                              }`
                            : `staff_plan--shift_table_tr ${
                                item &&
                                item[
                                  item?.findIndex(
                                    obj => obj.date === idx + 1
                                  ) ?? 0
                                ]
                                  ? 'staff_plan_confirm--table_tr'
                                  : ''
                              }`
                        }
                      >
                        {/*日付表示部分*/}
                        <th style={{ width: '15%', minWidth: '135px' }}>
                          <div
                            className={'staff_plan--th_shift_contents'}
                            style={{ paddingLeft: '64px' }}
                          >
                            <span className={'staff_plan--calender_font'} style={{lineHeight: '80px'}}>
                              {(idx + 1)}日(
                              {getWeek(date.year, date.month, idx + 1)})
                            </span>
                          </div>
                        </th>

                        {/*アサイン可否表示部分*/}
                        <th style={{ width: '13%', minWidth: '78px' }}>
                          <div
                            className={'staff_plan--th_shift_contents'}
                            style={{
                              width: '64px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              paddingLeft: '8px',
                            }}
                            data-radio-num={idx + 1}
                          >
                            {item &&
                            item[
                              item?.findIndex(obj => obj?.date === idx + 1) ?? 0
                            ] ? (
                              <div className={'assign_circle'}></div>
                            ) : (
                              <div className={'assign_border'}></div>
                            )}
                          </div>
                        </th>

                        {/*案件名表示部分*/}
                        <th style={{ width: '32%' }}>
                          <div
                            className={
                              'staff_plan--th_shift_contents matter_name_area staff_plan_confirm--th_item'
                            }
                          >
                            {item?.filter(obj => obj?.date === idx + 1)
                              ?.length >= 2 ? (
                              <div className={'staff_plan--custom_matter_name-area'}>
                                {item?.filter(obj => obj?.date === idx + 1)?.map((data, index) => {
                                  return <div
                                    className={
                                      (() => {
                                        const attendDetail = data ?? [];
                                        const padZero = (num) => {
                                          return num.toString().padStart(2, '0');
                                        };

                                        const year = date.year; // date.year を使って年を取得する（必要に応じて修正）
                                        const month = padZero(date.month); // 月を0埋め
                                        const day = padZero(idx + 1); // idx の値を0埋め

                                        const dateToCheck = `${year}${month}${day}`;

                                        return attendDetail?.matterNumber &&
                                        attendDetail?.assignFlag &&
                                        openStartDay() <= dateToCheck
                                          ? 'staff_plan--custom_click_elm'
                                          : '';
                                      })()}
                                    onClick={() => {
                                      const attendDetail = data ?? [];
                                      const padZero = (num) => {
                                        return num.toString().padStart(2, '0');
                                      };

                                      const year = date.year; // date.year を使って年を取得する（必要に応じて修正）
                                      const month = padZero(date.month); // 月を0埋め
                                      const day = padZero(idx + 1); // idx の値を0埋め

                                      const dateToCheck = `${year}${month}${day}`;

                                      attendDetail?.matterNumber &&
                                      attendDetail?.assignFlag &&
                                      openStartDay() <= dateToCheck &&
                                      navigate('/recruit-matter/detail', {
                                        state: {
                                          matterNumber: attendDetail.matterNumber,
                                          matterDateNumber: attendDetail.matterDateNumber,
                                          shiftId: attendDetail.shiftId,
                                          assignAccountFlag: 0,
                                          assignId: accountInfo.staffId,
                                        },
                                      });
                                    }}
                                    key={`staff_plan--custom_matter_name-area${index}`}
                                  >
                                    {data?.matterName ?? '-'}
                                  </div>
                                })}
                              </div>
                            ) : (
                              <div
                                  className={
                                    (() => {
                                      const attendDetail = (item ?? []).find(i => i.date === idx + 1);
                                      const padZero = (num) => {
                                        return num.toString().padStart(2, '0');
                                      };

                                      const year = date.year; // date.year を使って年を取得する（必要に応じて修正）
                                      const month = padZero(date.month); // 月を0埋め
                                      const day = padZero(idx + 1); // idx の値を0埋め

                                      const dateToCheck = `${year}${month}${day}`;

                                      return attendDetail?.matterNumber &&
                                      attendDetail?.assignFlag &&
                                      openStartDay() <= dateToCheck
                                          // && dateToCheck <= openEndDay
                                          ? 'staff_plan--custom_click_elm'
                                          : '';
                                    })()
                                  }
                                  onClick={() => {
                                    const attendDetail = (item ?? []).find(i => i.date === idx + 1);
                                    const padZero = (num) => {
                                      return num.toString().padStart(2, '0');
                                    };

                                    const year = date.year; // date.year を使って年を取得する（必要に応じて修正）
                                    const month = padZero(date.month); // 月を0埋め
                                    const day = padZero(idx + 1); // idx の値を0埋め

                                    const dateToCheck = `${year}${month}${day}`;

                                    attendDetail?.matterNumber &&
                                    attendDetail?.assignFlag &&
                                    openStartDay() <= dateToCheck &&
                                    navigate('/recruit-matter/detail', {
                                      state: {
                                        matterNumber: attendDetail.matterNumber,
                                        matterDateNumber: attendDetail.matterDateNumber,
                                        shiftId: attendDetail.shiftId,
                                        assignAccountFlag: 0,
                                        assignId: accountInfo.staffId,
                                      },
                                    });
                                  }}
                              >
                                {item?.[
                                  item?.findIndex(
                                    obj => obj.date === idx + 1
                                  ) ?? 100
                                ]?.assignFlag
                                  ? item[
                                      item?.findIndex(
                                        obj => obj.date === idx + 1
                                      )
                                    ]?.matterName
                                  : '-'
                                }
                              </div>
                            )}
                            {/*{item &&*/}
                            {/*item[*/}
                            {/*  item?.findIndex(obj => obj?.date === idx + 1) ??*/}
                            {/*    100*/}
                            {/*]?.assignFlag*/}
                            {/*  ? item[*/}
                            {/*      item?.findIndex(obj => obj.date === idx + 1)*/}
                            {/*    ]?.matterName*/}
                            {/*  : '-'}*/}
                          </div>
                        </th>

                        {/*勤務時間表示部分*/}
                        <th style={{ width: '20%' }}>
                          {item &&
                          item[
                            item?.findIndex(obj => obj?.date === idx + 1) ?? 100
                          ]?.assignFlag ? (
                            <div
                              className={
                                'staff_plan--th_contents_time staff_plan_confirm--th_item'
                              }
                            >
                              {item?.filter(obj => obj?.date === idx + 1)
                                ?.length >= 2 ? (
                                <div className={'staff_plan--custom_matter_name-area'}>
                                  {item?.filter(obj => obj?.date === idx + 1)?.map((data, index) => {
                                    return (
                                      <div key={`${data?.workingStart}${data?.workingEnd}${index}`}>
                                        {data?.workingStart
                                          ?.split(' ')[1]
                                          ?.substring(0, 5) ?? '-'}
                                        〜
                                        {data?.workingEnd
                                          ?.split(' ')[1]
                                          ?.substring(0, 5) ?? '-'}
                                        {data?.restTime ? ` (休憩 ${data.restTime ?? '-'}分)` : ' (休憩 - )'}
                                        {/*{item?.[*/}
                                        {/*item?.findIndex(*/}
                                        {/*  obj => obj.date === idx + 1*/}
                                        {/*) ?? 100*/}
                                        {/*  ]?.workingEnd*/}
                                        {/*  ? item[*/}
                                        {/*  item?.findIndex(*/}
                                        {/*    obj => obj.date === idx + 1*/}
                                        {/*  ) ?? 0*/}
                                        {/*    ]?.workingEnd*/}
                                        {/*    .split(' ')[1]*/}
                                        {/*    .substring(0, 5)*/}
                                        {/*  : '-'}*/}
                                        {/*{item?.[*/}
                                        {/*  item?.findIndex(obj => obj?.date === idx + 1)*/}
                                        {/*  ].workingStart &&*/}
                                        {/*item?.[*/}
                                        {/*  item?.findIndex(obj => obj?.date === idx + 1)*/}
                                        {/*  ].workingEnd*/}
                                        {/*  ? ' (休憩 ' +*/}
                                        {/*  item?.[*/}
                                        {/*    item?.findIndex(*/}
                                        {/*      obj => obj?.date === idx + 1*/}
                                        {/*    )*/}
                                        {/*    ].workingStart*/}
                                        {/*    .split(' ')[1]*/}
                                        {/*    .substring(0, 5) +*/}
                                        {/*  '~' +*/}
                                        {/*  item?.[*/}
                                        {/*    item?.findIndex(*/}
                                        {/*      obj => obj?.date === idx + 1*/}
                                        {/*    )*/}
                                        {/*    ].workingEnd*/}
                                        {/*    .split(' ')[1]*/}
                                        {/*    .substring(0, 5) +*/}
                                        {/*  ')'*/}
                                        {/*  : ' (休憩 - )'}*/}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <>
                                  {item?.[
                                  item?.findIndex(
                                    obj => obj.date === idx + 1
                                  ) ?? 100
                                    ]?.workingStart
                                    ? item[
                                      item?.findIndex(
                                        obj => obj.date === idx + 1
                                      ) ?? 0
                                      ]?.workingStart
                                      .split(' ')[1]
                                      .substring(0, 5)
                                    : '-'}
                                  〜
                                  {item?.[
                                  item?.findIndex(
                                    obj => obj.date === idx + 1
                                  ) ?? 100
                                    ]?.workingEnd
                                    ? item[
                                    item?.findIndex(
                                      obj => obj.date === idx + 1
                                    ) ?? 0
                                      ]?.workingEnd
                                      .split(' ')[1]
                                      .substring(0, 5)
                                    : '-'}
                                  {item?.[
                                    item?.findIndex(obj => obj?.date === idx + 1)
                                    ].workingStart &&
                                  item?.[
                                    item?.findIndex(obj => obj?.date === idx + 1)
                                    ].workingEnd
                                    ? ' (休憩 ' +
                                    item?.[
                                      item?.findIndex(
                                        obj => obj?.date === idx + 1
                                      )
                                      ].workingStart
                                      .split(' ')[1]
                                      .substring(0, 5) +
                                    '~' +
                                    item?.[
                                      item?.findIndex(
                                        obj => obj?.date === idx + 1
                                      )
                                      ].workingEnd
                                      .split(' ')[1]
                                      .substring(0, 5) +
                                    ')'
                                    : ' (休憩 - )'}
                                </>
                              )}
                              {/*{item[*/}
                              {/*  item?.findIndex(obj => obj?.date === idx + 1) ??*/}
                              {/*    100*/}
                              {/*].workingStart*/}
                              {/*  ? item[*/}
                              {/*      item?.findIndex(*/}
                              {/*        obj => obj?.date === idx + 1*/}
                              {/*      ) ?? 0*/}
                              {/*    ].workingStart*/}
                              {/*      .split(' ')[1]*/}
                              {/*      .substring(0, 5)*/}
                              {/*  : '-'}*/}
                              {/*〜*/}
                              {/*{item[*/}
                              {/*  item?.findIndex(obj => obj?.date === idx + 1) ??*/}
                              {/*    100*/}
                              {/*].workingEnd*/}
                              {/*  ? item[*/}
                              {/*      item?.findIndex(*/}
                              {/*        obj => obj?.date === idx + 1*/}
                              {/*      ) ?? 0*/}
                              {/*    ].workingEnd*/}
                              {/*      .split(' ')[1]*/}
                              {/*      .substring(0, 5)*/}
                              {/*  : '-'}*/}
                              {/*{item[*/}
                              {/*  item?.findIndex(obj => obj?.date === idx + 1)*/}
                              {/*].workingStart &&*/}
                              {/*item[*/}
                              {/*  item?.findIndex(obj => obj?.date === idx + 1)*/}
                              {/*].workingEnd*/}
                              {/*  ? ' (休憩 ' +*/}
                              {/*    item[*/}
                              {/*      item?.findIndex(*/}
                              {/*        obj => obj?.date === idx + 1*/}
                              {/*      )*/}
                              {/*    ].workingStart*/}
                              {/*      .split(' ')[1]*/}
                              {/*      .substring(0, 5) +*/}
                              {/*    '~' +*/}
                              {/*    item[*/}
                              {/*      item?.findIndex(*/}
                              {/*        obj => obj?.date === idx + 1*/}
                              {/*      )*/}
                              {/*    ].workingEnd*/}
                              {/*      .split(' ')[1]*/}
                              {/*      .substring(0, 5) +*/}
                              {/*    ')'*/}
                              {/*  : ' (休憩 - )'}*/}
                            </div>
                          ) : (
                            <div
                              className={
                                'staff_plan--th_contents_time staff_plan_confirm--th_item'
                              }
                            >
                              -
                            </div>
                          )}
                        </th>

                        {/*勤務地最寄駅*/}
                        <th style={{ width: '20%' }}>
                          <div
                            className={
                              'staff_plan--th_shift_contents staff_plan_confirm--th_item'
                            }
                          >
                            {item?.filter(obj => obj?.date === idx + 1)
                              ?.length >= 2 ? (
                              <div className={'staff_plan--custom_matter_name-area'}>
                                {item?.filter(obj => obj?.date === idx + 1)?.map((data, index) => {
                                  return <div key={`${data?.workingStart}${data?.workingEnd}${data?.nearStation}${index}`}>{data?.nearStation ?? '-'}</div>
                                })}
                              </div>
                            ) : (
                              <>
                                {item?.[
                                item?.findIndex(
                                  obj => obj.date === idx + 1
                                ) ?? 100
                                  ]?.nearStation
                                  ? item[
                                    item?.findIndex(
                                      obj => obj.date === idx + 1
                                    )
                                    ]?.nearStation
                                  : '-'
                                }
                              </>
                            )}
                            {/*{item &&*/}
                            {/*item[*/}
                            {/*  item?.findIndex(obj => obj?.date === idx + 1) ??*/}
                            {/*    100*/}
                            {/*]?.assignFlag &&*/}
                            {/*item[*/}
                            {/*  item?.findIndex(obj => obj?.date === idx + 1) ??*/}
                            {/*    100*/}
                            {/*]?.nearStation*/}
                            {/*  ? item[*/}
                            {/*      item?.findIndex(*/}
                            {/*        obj => obj?.date === idx + 1*/}
                            {/*      ) ?? 0*/}
                            {/*    ].nearStation*/}
                            {/*  : '-'}*/}
                          </div>
                        </th>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className={'attend_forStaffSp'}>{AttendForStaffSp()}</div>
    </>
  );
};

export default StaffPlanConfirm;
