import '../../../../components/listView/style.scss';
import './style.scss';
import Icons from '../../../../constants/icons';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import Pager from '../../../../components/pager';
import icons from '../../../../constants/icons';
import CustomInput from '../../../../components/customInput';
import Button from '../../../../components/button';
import { useNavigate } from 'react-router-dom';
import SearchSelectBox from '../../../../components/searchSelectBox';
import AddButton from '../../../../components/addButton';
import Options from '../../../../constants/options';
import { checkIsSp, mapColumn } from '../../../../utils/fnUtil';
import classNames from 'classnames';
import { isNil } from 'lodash';
import React from 'react';

const CustomListView = ({
  searchFormInfo,
  columnSettings,
  buttonSettings,
  primaryKey,
  apiData,
  sortSettings,
  changeSort,
  onSearch,
  onChangeCount,
  onChangeOffset,
  tip,
  searchTitle = '検索条件',
  emptyText,
  disabledTip,
  spView,
  keepCheckedFlag = false,
  defaultOpened,
}) => {
  // const registerType = useLocation().pathname.match(/\/(.*)\//)[1];
  // const navigate = useNavigate();
  const [isCheckAll, setCheckAll] = useState(false);

  const toggleChecks = boo => {
    const checkBoxes =
      document
        .getElementsByClassName('list_view--list_table')?.[0]
        ?.querySelectorAll('input, #check_') ?? [];

    Array.prototype.forEach.call(checkBoxes, elm => {
      elm.checked = boo;
    });
  };

  const { width } = useWindowSize();

  const toggleCheckAll = () => {
    setCheckAll(!isCheckAll);

    toggleChecks(!isCheckAll);
  };

  const searchCount = apiData.count ? apiData.count : 0;
  // const data = apiData.item;
  const data = (apiData?.item ?? []).map(item =>
    mapColumn(item, columnSettings, primaryKey)
  );

  const doubleBookingData = (apiData?.item ?? []).map(data =>
    data.doubleBookingList
  );

  useEffect(() => {
    if (!keepCheckedFlag) toggleChecks(isCheckAll);
  });

  const [listPerPage, setListPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const pages =
    Math.floor(searchCount / listPerPage) + (searchCount % listPerPage ? 1 : 0);

  const changeListPerPage = e => {
    setListPerPage(e);
    onChangeCount?.(e);

    setCurrentPage(1);
  };

  const changeCurrentPage = e => {
    if (e.target.value === '+') {
      setCurrentPage(currentPage + 3);
    } else if (e.target.value === '-') {
      setCurrentPage(currentPage - 3);
    } else {
      setCurrentPage(Number(e.target.value));
    }
    setCurrentPage(current => {
      onChangeOffset?.(listPerPage * (current - 1));
      return current;
    });
  };

  const toggleOpenSearchForm = () => {
    const searchFormArea = document.querySelector(
      '.list_view--search_form_area'
    );
    const searchButtonArea = document.querySelector(
      '.list_view--search_button_area'
    );
    const searchBase = document.querySelector('.list_view--search_base');
    const triangle = document.getElementById('searchFormTriangle');

    triangle.style.transition = 'all .5s';

    if (
      triangle.style.transform === '' ||
      triangle.style.transform === 'rotate(0deg)'
    ) {
      searchFormArea.style.opacity = '0';
      searchButtonArea.style.opacity = '0';
      triangle.style.transform = 'rotate(180deg)';
      searchBase.style.maxHeight = checkIsSp() ? '80px' : '20px';
    } else {
      searchFormArea.style.opacity = '1';
      searchButtonArea.style.opacity = '1';
      triangle.style.transform = 'rotate(0deg)';
      searchBase.style.maxHeight = '1000px';
    }
  };

  const statusColors = ['#BCBCBC', '#D80245', '#383F52', '#28D300'];
  const positions = ['ディレクター', "アシスタントディレクター", "スタッフ(男性)", "スタッフ(女性)", "スタッフ(性別不問)"]

  const toggleOpenDetail = (targetId) => {
    const targetOpenDetail = document.querySelector(
      `.custom_list_view--assign_show_detail_${targetId}`
    );
    const targetOpenDetailData = document.querySelector(
      `.custom_list_view--assign_show_detail_data_${targetId}`
    );

    const allowButton = document.getElementById(`allowButton${targetId}`);
    const targetTd = document.querySelector(`.show_target_td_${targetId}`);

    allowButton.style.transition = 'all .5s';

    if (
      allowButton.style.transform === '' ||
      allowButton.style.transform === 'rotate(0deg)'
    ) {
      targetOpenDetail.style.opacity = '1';
      targetOpenDetailData.style.opacity = '1';
      targetTd.style.opacity = '1';
      allowButton.style.transform = 'rotate(90deg)';
      targetOpenDetail.style.height = 'fit-content';
      targetOpenDetailData.style.height = 'fit-content';
      targetTd.style.height = 'fit-content';
      targetTd.style.padding = '0 0 0 80px';
      // targetTd.style.padding = '7px 0 7px 80px';
    } else {
      targetOpenDetail.style.opacity = '0';
      targetOpenDetailData.style.opacity = '0';
      allowButton.style.transform = 'rotate(0deg)';
      targetTd.style.height = '0';
      targetOpenDetail.style.height = '0';
      targetOpenDetailData.style.height = '0';
      targetTd.style.padding = '0 0';
      targetOpenDetailData.style.padding = '0 0';
    }
  };

  useEffect(() => {//この処理は検索、ページャーを動かした時に初期状態(アコーディオンの開閉)に戻す処理
    const icon = document.getElementsByClassName('allow_icon_right');
    const tds = document.getElementsByClassName('show_target_td');
    const elm = document.getElementsByClassName('assign_show_detail_elm');
    if (defaultOpened) {
      Array.from(icon)?.map(obj => obj.style.transform = 'rotate(90deg)')
      Array.from(tds)?.forEach(obj => {
        obj.style.opacity = '1';
        obj.style.height = 'fit-content';
        obj.style.padding = '0 0 0 80px'
        // obj.style.padding = '7px 0 7px 80px'
      })
      Array.from(elm)?.forEach(obj => {
        obj.style.opacity = '1';
        obj.style.height = 'fit-content';
      })
    } else {
      Array.from(icon)?.map(obj => obj.style.transform = 'rotate(0deg)')
      Array.from(tds)?.forEach(obj => {
        obj.style.opacity = '0';
        obj.style.height = '0';
        obj.style.padding = '0 0'
      })
      Array.from(elm)?.forEach(obj => {
        obj.style.opacity = '0';
        obj.style.height = '0';
      })
    }
  }, [apiData]);

  return (
    <>
      {searchFormInfo ? (
        <div
          className={classNames('list_view--search_base', {
            'list_view--search_base_with_tip': tip,
          })}
        >
          {tip && <div className="list_view--search_title_tip">{tip}</div>}
          <div className={'list_view--search_title_area'}>
            <img
              id={'searchFormTriangle'}
              src={Icons.icon.downNv}
              onClick={toggleOpenSearchForm}
            />
            <p>{searchTitle}</p>
          </div>
          {!disabledTip && (
            <>
              <div className={'list_view--search_form_area'}>
                {searchFormInfo.jsx
                  ? searchFormInfo.jsx()
                  : searchFormInfo.inputs.map((inputInfo, index) => {
                      if (inputInfo.inputType === 'searchSelect') {
                        return <SearchSelectBox key={index} {...inputInfo} />;
                      } else {
                        return <CustomInput key={index} {...inputInfo} />;
                      }
                    })}
              </div>
              <div className={'list_view--search_button_area'}>
                <Button
                  text={'検索'}
                  style={
                    checkIsSp()
                      ? { ...Options.buttonStyles.search, width: '100%' }
                      : Options.buttonStyles.search
                  }
                  onClick={() => {
                    onSearch();
                    setCurrentPage(1);
                  }}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}

      {buttonSettings ? (
        <div className={'list_view--buttons_base'}>
          {checkIsSp() ? (
            <>
              {buttonSettings.viewPager && (
                <>
                  <p className={'list_view--count_search'}>
                    検索結果：{searchCount}件
                  </p>

                  <div className={'list_view--count_forSp'}>
                    <CustomInput
                      onChange={changeListPerPage}
                      inputType={'select'}
                      inputName={'count_select'}
                      selectBoxOptions={[20, 50, 100]}
                      initialValue={[20, 50, 100]}
                      selectedSelectBox={20}
                      allowClear={false}
                      style={{ areaStyle: { width: '120px' } }}
                      usePopContainer={true}
                    />
                    <span>件</span>
                  </div>

                  {apiData.count !== 0 && (
                    <div className={'list_view--pagerWrap_forSp'}>
                      <Pager
                        searchCount={!searchCount ? 0 : searchCount}
                        listPerPage={listPerPage}
                        currentPage={currentPage}
                        setPage={changeCurrentPage}
                      />
                    </div>
                  )}
                  {searchCount !== 0 && (
                    <p className={'list_view--count_page'}>
                      {currentPage}/{pages}ページ
                    </p>
                  )}

                  {!buttonSettings.ignoreAddBtn && (
                    <AddButton
                      spListViewMode={true}
                      onClick={() => buttonSettings.addButton.props.onClick()}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <div className={'list_view--buttons_left'}>
                {buttonSettings.viewPager && (
                  <>
                    <div className={'list_view--count_area'}>
                      <p className={'list_view--count_search'}>
                        検索結果：{Number(searchCount).toLocaleString()}件
                      </p>
                    </div>

                    {!(emptyText && searchCount === 0) && (
                      <div className={'list_view--count_select'}>
                        <CustomInput
                          onChange={changeListPerPage}
                          inputType={'select'}
                          inputName={'count_select'}
                          selectBoxOptions={[20, 50, 100]}
                          initialValue={[20, 50, 100]}
                          selectedSelectBox={20}
                          allowClear={false}
                          usePopContainer={true}
                        />
                        <p>件</p>
                      </div>
                    )}

                    {apiData.count !== 0 && (
                      <Pager
                        searchCount={!searchCount ? 0 : searchCount}
                        listPerPage={listPerPage}
                        currentPage={currentPage}
                        setPage={changeCurrentPage}
                      />
                    )}
                    <div className={'list_view--count_area'}>
                      {searchCount !== 0 && (
                        <p className={'list_view--count_page'}>
                          {currentPage}/{pages}ページ
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className={'list_view--buttons_right'}>
                {Object.keys(buttonSettings).map(key => {
                  if (key !== 'viewPager' && key !== 'ignoreAddBtn') {
                    if (key === 'addButton') {
                      return (
                        <AddButton key={key} {...buttonSettings[key].props} screenType={true} />
                      );
                    }
                    if (key === 'staffYearSel') {
                      return <CustomInput key={key} {...buttonSettings[key]} />;
                    } else {
                      return (
                        <Button
                          key={key}
                          icon={buttonSettings[key].icon}
                          text={buttonSettings[key].text}
                          style={buttonSettings[key].style}
                          onClick={buttonSettings[key].onClick}
                          iconStyle={buttonSettings[key].iconStyle}
                        />
                      );
                    }
                  }
                })}
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}

      {disabledTip ? (
        <span className="list_view--empty_text">{disabledTip}</span>
      ) : emptyText && searchCount === 0 ? (
        <span className="list_view--empty_text">{emptyText}</span>
      ) : spView && width <= 800 ? (
        spView
      ) : (
        <div className={'list_view--list_wrapper custom_list_view--list_wrapper'} style={{overflowX: 'auto'}}>
          <table className={'list_view--list_table custom_list_view'}>
            <thead>
              <tr>
                {columnSettings.checkAll && (
                  <th key={'checkAll'} id={'th_checkAll'}>
                    <CustomInput
                      inputName={'checkAll'}
                      inputType={'checkBox'}
                      initialValue={false}
                      onChange={toggleCheckAll}
                      style={{
                        areaStyle: { justifyContent: 'center', gap: 'unset' },
                      }}
                    />
                  </th>
                )}
                {Object.keys(columnSettings).map(key => {
                  if (key !== 'statics' && key !== 'checkAll' && key !== 'assignCountDetail' && key !== 'matterDateNumber') {
                    return key !== 'image' ? (
                      <th
                        key={key}
                        onClick={changeSort}
                        id={columnSettings[key].dataName}
                        className={
                          columnSettings[key].sort === ''
                            ? 'list_view_table_th'
                            : ''
                        }
                        style={{
                          width: key==='status' ? '100px' : '',
                          tableLayout: 'fixed',
                          textAlign: key === 'status' ? 'center' : '',
                          pointerEvents: key==='assignCount' && 'none'
                        }}
                      >
                        {columnSettings[key].label}
                        {sortSettings.sortColumn ===
                        columnSettings[key].dataName ? (
                          sortSettings.sortMethod === 'Asc' ? (
                            <div className={'list_view--sort_icon'}>
                              <img
                                src={icons.icon.downGr}
                                style={{ transform: 'rotate(180deg)' }}
                                id={columnSettings[key].dataName}
                              />
                            </div>
                          ) : (
                            <div className={'list_view--sort_icon'}>
                              <img
                                src={icons.icon.downGr}
                                id={columnSettings[key].dataName}
                              />
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                      </th>
                    ) : (
                      <th
                        key={key}
                        style={{ cursor: 'unset', border: 'none' }}
                      ></th>
                    );
                  }
                })}
                {columnSettings.statics.map(obj => {
                  return (
                    <th className={'list_view--static_th'} key={obj.label}>
                      {obj.label}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {data.map((obj, index) => {
                return (
                  <React.Fragment key={`t_body_${index}`}>
                    <tr
                      key={index}
                      className={classNames({
                        'tr-excess':
                          (apiData.item ?? [])[index]?.excessFlag === 1,
                      })}
                      style={{
                        backgroundColor: index % 2 ? '#F8F8F8' : '#FFFFFF',
                      }}
                    >
                      {Object.keys(obj)
                        .filter(
                          key =>
                            key !== 'primaryKey' &&
                            key !== 'assignCountDetail' &&
                            key !== 'matterDateNumber'
                        )
                        .map(key => {
                          return key === 'assignCount' ? (
                            <td
                              key={key}
                              style={{
                                borderRight: '1px dashed #E0E0E0',
                                width: '100px',
                              }}
                            >
                              <div className="custom_list_view--matter_assign_state_td">
                                <span>{`D：${obj[key]['d']['count'] ?? '-'}/${
                                  obj[key]['d']['max'] ?? '-'
                                }人`}</span>
                                <br />
                                <span>{`AD：${obj[key]['ad']['count'] ?? '-'}/${
                                  obj[key]['ad']['max'] ?? '-'
                                }人`}</span>
                                <br />
                                <span>{`S(男性)：${
                                  obj[key]['man']['count'] ?? '-'
                                }/${obj[key]['man']['max'] ?? '-'}人`}</span>
                                <br />
                                <span>{`S(女性)：${
                                  obj[key]['woman']['count'] ?? '-'
                                }/${obj[key]['woman']['max'] ?? '-'}人`}</span>
                                <br />
                                <span>{`S(性別不問)：${
                                  obj[key]['gender']['count'] ?? '-'
                                }/${obj[key]['gender']['max'] ?? '-'}人`}</span>
                                <br />
                              </div>
                            </td>
                          ) : key === 'status' ? (
                            <td
                              key={key}
                              style={{
                                borderRight: '1px dashed #E0E0E0',
                                textAlign: 'center',
                              }}
                            >
                              <div
                                className="custom_list_view--matter_assign_state_status"
                                style={{
                                  backgroundColor: statusColors[obj[key]],
                                  textAlign: 'center',
                                  width: '150px',
                                }}
                              >
                                <span>
                                  {obj[key] === 0
                                    ? 'シフト未登録'
                                    : obj[key] === 1
                                    ? 'アサイン未確定'
                                    : obj[key] === 2
                                    ? 'アサイン確定済'
                                    : obj[key] === 3 && '実績報告済'}
                                </span>
                              </div>
                            </td>
                          ) : (
                            key !== 'assignCountDetail' && (
                              <td
                                style={{
                                  width: '90px',
                                  textAlign: 'left',
                                  borderRight: '1px dashed #E0E0E0',
                                  whiteSpace: 'nowrap',
                                }}
                                key={key}
                              >
                                {key === 'matterDate' && (
                                  <img
                                    id={`allowButton${index}`}
                                    className={'allow_icon_right'}
                                    src={Icons.icon.rightNv}
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      transform: defaultOpened
                                        ? 'rotate(90deg)'
                                        : '',
                                    }}
                                    onClick={() => toggleOpenDetail(index)}
                                  />
                                )}
                                {typeof obj[key] === 'function'
                                  ? obj[key]()
                                  : !isNil(obj[key])
                                  ? !Number.isNaN(obj[key])
                                    ? obj[key]
                                    : ''
                                  : ''}
                              </td>
                            )
                          );
                        })}

                      {columnSettings.statics.map(staticObj => {
                        return (
                          <React.Fragment key={staticObj.label}>
                            <td
                              style={{ textAlign: 'center' }}
                              key={staticObj.label}
                            >
                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                to={'/matter/assign/detail'}
                              >
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={staticObj.icon}
                                  onClick={() =>
                                    localStorage.setItem(
                                      'matter_date_number',
                                      obj.matterDateNumber,
                                    )
                                  }
                                />
                              </Link>
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>

                    {/*//アコーディオン部分*/}
                    <tr
                      style={{
                        height: defaultOpened ? 'auto' : 0,
                        opacity: '1',
                        borderBottom: 'none',
                        backgroundColor: '#F3F3F3',
                      }}
                    >
                      <td
                        colSpan="2"
                        className={`show_target_td_${index} show_target_td`}
                        style={{
                          height: defaultOpened ? 'auto' : 0,
                          opacity: defaultOpened ? '1' : '0',
                          paddingLeft: '80px',
                        }}
                      >
                        <div
                          className={`custom_list_view--assign_show_detail_${index} assign_show_detail_elm`}
                          style={
                            defaultOpened
                              ? { height: 'fit-content', opacity: '1' }
                              : { height: 0, opacity: '0' }
                          }
                        >
                          {Object.keys(obj.assignCount)?.map(key => obj.assignCount[key]['max'] !== 0 && obj.assignCount[key]['max'] !== null ?
                            <div
                              className={'custom_list_view--position_elm'}
                              style={{ lineHeight: '70px' }}
                              key={`custom_${key}_elm`}
                            >
                              <span>
                                {key === 'd' ? positions[0] : key === 'ad' ? positions[1] : key === 'man' ? positions[2] : key === 'woman' ? positions[3] : key === 'gender' ? positions[4] : ''}
                              </span>
                            </div> :
                            <></>
                          )}
                          {/*{positions.map(p => {*/}
                          {/*  return (*/}
                          {/*    <div*/}
                          {/*      className={'custom_list_view--position_elm'}*/}
                          {/*      style={{ lineHeight: '70px' }}*/}
                          {/*      key={`custom_${p}`}*/}
                          {/*    >*/}
                          {/*      <span>{p}</span>*/}
                          {/*    </div>*/}
                          {/*  );*/}
                          {/*})}*/}
                        </div>
                      </td>
                      <td
                        colSpan="7"
                        className={`show_target_td_${index}`}
                        style={{
                          height: defaultOpened ? 'auto' : 0,
                          opacity: '1',
                          paddingLeft: '32px',
                        }}
                      >
                        <div
                          className={`custom_list_view--assign_show_detail_data_${index} assign_show_detail_elm`}
                          style={{
                            height: defaultOpened ? 'fit-content' : 0,
                            opacity: defaultOpened ? '1' : '0',
                            maxWidth: '1280px',
                            boxSizing: 'border-box',
                          }}
                        >
                          {Object.keys(obj.assignCountDetail)?.map(key => {
                            return obj.assignCount[key]['max'] !== 0 && obj.assignCount[key]['max'] !== null && (
                              <div
                                className={'custom_list_view--position_elm'}
                                style={{ lineHeight: '70px' }}
                                key={key}
                              >
                                {obj.assignCountDetail[key].length === 0
                                  ? '-'
                                  : obj.assignCountDetail[key].map((e, idx) => (
                                    <>
                                      <span>{`${e}`}</span>
                                      <span style={{color: '#D80245'}}>{`${doubleBookingData?.[index]?.[key]?.[idx] === 1 ? ' (Ｗブッキング)' : ''}`}</span>
                                      <span>{`${
                                        obj.assignCountDetail[key].length -
                                          1 ===
                                        idx
                                          ? ''
                                          : ','
                                      }`}</span>
                                  </>
                                    ))}
                              </div>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {buttonSettings && buttonSettings.viewPager && apiData.count !== 0 && (
        <div className={'list_view--bottom_pager_wrapper'}>
          <Pager
            searchCount={searchCount}
            listPerPage={listPerPage}
            currentPage={currentPage}
            setPage={changeCurrentPage}
          />
        </div>
      )}
    </>
  );
};

export default CustomListView;
