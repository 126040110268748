import { memo } from 'react';
import { useWindowSize } from 'react-use';
import { Modal } from 'antd';
import Button from '../../button';
import LayoutPattern from './layoutPattern';
import './style.scss';

const LayoutModal = memo(({ modalVisible, onBack, onAddPattern, freeForm }) => {
  const { width } = useWindowSize();

  return (
    <Modal
      visible={modalVisible}
      footer={null}
      style={{
        minWidth: 'calc(95% - 80px)',
      }}
      centered
      closable={false}
      className="common_modal_base layout_modal_base"
      onCancel={onBack}
    >
      <div className="layout_modal">
        <div className="layout_modal--title">レイアウト追加</div>
        <div className="layout_modal--message display-linebreak">
          {freeForm ? 'フリーフォーム' : '全体実績'}に追加する
          {width < 800 && '\n'}
          レイアウトを選択してください
        </div>
        <div className="layout_modal--layout">
          {[1, 2, 3].map(v => (
            <LayoutPattern
              pattern={v}
              key={v}
              onAddPattern={() => onAddPattern(v)}
              freeForm={freeForm}
            />
          ))}
        </div>
        <div className="layout_modal--action">
          <Button text="戻る" onClick={onBack} />
        </div>
      </div>
    </Modal>
  );
});

export default LayoutModal;
