const StaffPayDetailInputs = {
  search : [
    {
      inputType: 'select',
      inputName: 'search_year',
      unit: '年',
      style: {
        areaStyle: {width: '224px'},
      }
    },
    {
      inputType: 'select',
      inputName: 'search_month',
      unit: '月',
      style: {
        areaStyle: {width: '224px'},
      }
    }
  ],
}

export default StaffPayDetailInputs;