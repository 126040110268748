import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'lodash';
import {
  staffRecruitMatterActions,
  deleteStaffRecruitMatter,
  fetchStaffRecruitMatterList,
  staffRecruitMatterInit,
} from '../../../redux/slice/staffRecruitMatter';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Options from '../../../constants/options';
import prefectures from '../../../constants/prefectures';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Copyright from '../../../components/copyright';
import moment from 'moment';
import './style.scss';

const StaffRecruitMatterList = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    searchParams,
    staffRecruitMatterList,
    searchCount,
    venueList,
    userList,
    matterCategoryList,
  } = useSelector(state => state.staffRecruitMatter);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values =>
      dispatch(staffRecruitMatterActions.saveSearchParams(values)),
  });

  const {
    recruitmentDateFrom,
    recruitmentDateTo,
    sortKey: sortColumn,
    sortMethod,
  } = formik.values;

  const searchFormInfo = {
    jsx: () =>
      [
        {
          union: true,
          inputs: [
            {
              inputType: 'inputDate',
              label: '募集日',
              inputName: 'recruitmentDateFrom',
              placeholder: 'YYYY/MM/DD',
              unit: '～',
              customDisabledDate: current =>
                recruitmentDateTo && current > moment(recruitmentDateTo),
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                inputStyle: { width: 165 },
              },
            },
            {
              inputType: 'inputDate',
              label: ' ',
              inputName: 'recruitmentDateTo',
              customDisabledDate: current =>
                current < moment(recruitmentDateFrom),
              placeholder: 'YYYY/MM/DD',
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                areaStyle: {
                  marginLeft: -18,
                },
                inputStyle: { width: 165 },
              },
            },
          ],
        },
        {
          label: '案件No',
          inputName: 'matterNumber',
          placeholder: '案件Noを入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: 228 },
          },
        },
        {
          label: '案件名',
          inputName: 'matterName',
          initialValue: '',
          placeholder: '案件名を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: 400 },
          },
        },
        {
          inputType: 'select',
          label: '開催地',
          inputName: 'venueId',
          initialValue: venueList.map(venue => venue.venueId),
          selectBoxOptions: venueList.map(
            venue => `${venue.venueId}:${venue.venueName}`
          ),
          placeholder: '開催地を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          label: '社内案件担当者',
          inputType: 'select',
          inputName: 'matterManagerUserId',
          mode: 'multiple',
          allowClear: false,
          initialValue: userList.map(user => user.userId),
          selectBoxOptions: userList.map(
            user =>
              `${user.userId}:${user.name.lastName} ${user.name.firstName}`
          ),
          placeholder: '社内案件担当者を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          label: '社内営業担当者',
          inputType: 'select',
          inputName: 'salesManagerUserId',
          placeholder: '社内営業担当者を入力してください',
          mode: 'multiple',
          allowClear: false,
          initialValue: userList.map(user => user.userId),
          selectBoxOptions: userList.map(
            user =>
              `${user.userId}:${user.name.lastName} ${user.name.firstName}`
          ),
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        ,
        {
          label: '案件カテゴリ',
          inputType: 'select',
          inputName: 'matterCategory',
          initialValue: matterCategoryList.map(n => n.namingId),
          selectBoxOptions: matterCategoryList.map(n => n.naming),
          placeholder: '案件カテゴリを選択してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          label: '実施都道府県',
          inputType: 'select',
          inputName: 'prefCode',
          initialValue: prefectures.map(p => p.prefecturesCode),
          selectBoxOptions: prefectures.map(p => p.label),
          placeholder: '都道府県を選択してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 291,
            },
          },
        },
        {
          inputType: 'select',
          label: 'ステータス',
          inputName: 'status',
          initialValue: Object.keys(Options.statusMap.staffRecruit),
          selectBoxOptions: Object.entries(Options.statusMap.staffRecruit).map(
            ([_, { label }]) => label
          ),
          placeholder: 'すべて',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: 150 },
          },
        },
      ].map((inputInfo, i) =>
        inputInfo.union ? (
          <div className="input_union" key={i}>
            {inputInfo.inputs.map(input => (
              <CustomFormikInput
                {...input}
                formik={formik}
                key={input.inputName}
              />
            ))}
          </div>
        ) : (
          <CustomFormikInput
            {...inputInfo}
            formik={formik}
            key={inputInfo.inputName}
          />
        )
      ),
  };

  const changeSort = e =>
    dispatch(
      staffRecruitMatterActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
      })
    );

  const columnSettings = {
    openDate: { label: '募集開始日', dataName: 'open_date' },
    closeDate: { label: '募集終了日', dataName: 'close_date' },
    matterNumber: { label: '案件No', dataName: 'matter_number' },
    matterName: { label: '案件名', dataName: 'matter_name' },
    venueName: { label: '開催地', dataName: 'venue_name' },
    matterManagerUserName: {
      label: '社内案件担当者',
      dataName: 'matter_manager_user_name',
    },
    salesManagerUserName: {
      label: '社内営業担当者',
      dataName: 'sales_manager_user_name',
    },
    matterCategoryName: {
      label: '案件カテゴリ',
      dataName: 'matter_category_name',
    },
    prefCode: {
      label: '実施都道府県',
      dataName: 'pref_code',
      jsx: recruitMatter =>
        prefectures.find(p => p.prefecturesCode === recruitMatter.prefCode)
          ?.label,
    },
    maximum: { label: '枠数', dataName: 'maximum' },
    applicant: { label: '応募者数', dataName: 'applicant' },
    ratio: { label: '倍率', dataName: 'ratio' },
    status: {
      label: 'ステータス',
      dataName: 'status',
      jsx: recruitMatter =>
        Options.statusMap.staffRecruit[recruitMatter.status]?.label,
    },
    statics: [
      {
        label: '編集',
        icon: Icons.icon.editSNv,
        onClick: (_, i) =>
          navigate('/matter/recruit/edit', {
            state: pick(staffRecruitMatterList[i], [
              'matterNumber',
              'matterDateNumber',
              'shiftId',
            ]),
          }),
      },
      {
        label: '削除',
        icon: Icons.icon.deleteSNv,
      },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    addButton: {
      props: {
        type: '',
        onClick: () => navigate('/matter/recruit/register'),
        url: '',
        displayText: true,
        disabled: '',
      },
    },
  };

  useEffect(() => {
    dispatch(staffRecruitMatterInit());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStaffRecruitMatterList(searchParams));
  }, [dispatch, searchParams]);

  return (
    <>
      <div className={'commonPage--base staff_recruit_list matter_base'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey="matterNumber"
          primaryName="matterName"
          apiData={{
            count: searchCount,
            item: staffRecruitMatterList,
          }}
          sortSettings={{ sortColumn, sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(
              staffRecruitMatterActions.saveSearchParams({
                count,
              })
            )
          }
          onChangeOffset={offset =>
            dispatch(staffRecruitMatterActions.saveOffset(offset))
          }
          onDelete={(_, i) =>
            dispatch(
              deleteStaffRecruitMatter(
                pick(staffRecruitMatterList[i], [
                  'matterNumber',
                  'matterDateNumber',
                  'shiftId',
                ])
              )
            )
          }
        />
        <Copyright />
      </div>
    </>
  );
};

export default StaffRecruitMatterList;
