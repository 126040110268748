import { memo } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import StaffRegisterInfos from "../inputInfos";
import React, { useEffect } from 'react';
import {useSelector} from "react-redux";

const BodyInfos = memo(({ formik }) => {

  const { holdingItemsList } = useSelector((state) => state.staff);
  // const {holdingItems} = formik.values;
  // useEffect(()=>{
  //     holdingItems?.sort((a, b) => ((parseInt(a) < parseInt(b)) ? -1 : 1))
  // },[holdingItems])
  return(
      <div className={'staff_register--body_infos_area'}>
          {StaffRegisterInfos.bodyInfos.inputs.map((info, idx) => {
              return idx < 4 && (
                  <CustomFormikInput
                      formik={formik}
                      {...info}
                      key={info.inputName}
                  />);
          })}
          <CustomFormikInput
              formik={formik}
              {...StaffRegisterInfos.bodyInfos.inputs[4]}
              initialValue={holdingItemsList?.map(h=>h?.value)}
              selectBoxOptions={holdingItemsList?.map(h=>h?.label)}
          />
      </div>
  )
});
export default BodyInfos;
