import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import { quoteFlowFetch, quoteInit } from '../../../redux/slice/quote';
import AddButton from '../../../components/addButton';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Icons from '../../../constants/icons';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { checkIsSp } from '../../../utils/fnUtil';

const labelSty = { width: '175px', textAlign: 'right', color: '#646464' };
const divSty = checkIsSp()
  ? { display: 'flex', gap: '8px', width: '100%', flexDirection: 'column' }
  : { display: 'flex', gap: '8px', width: '83%' };
const areaSty = {
  minWidth: checkIsSp() ? '100%' : '473px',
  width: checkIsSp() ? '100%' : '80%',
};

const QuoteTermContents = memo(({ formik, preliminaryList }) => {
  const dispatch = useDispatch();
  const {
    selOpt: {
      clientSel,
      userSel,
      paymentSel,
      matterSel,
      matterCategorySel,
      pre1Sel,
      pre2Sel,
      pre3Sel,
      pre4Sel,
      pre5Sel,
      officeMasters,
    },
    flowList,
  } = useSelector(state => state.quote);
  const theOtherVal = matterCategorySel?.find(e => e.opt === 'その他');
  const materOtherVal = matterSel?.find(e => e.largeClassName === 'その他');
  const { corporateId } = useSelector(state => state.account);

  const { invoicePlanDates, invoiceTiming, managerUserId, matterCategory } =
    formik.values;

  const apiParams = {
    clientList: {
      status: 1,
      count: 999,
      sort: 'clientIdAsc',
    },
    userList: {
      status: 1,
      count: 999,
      sort: 'userCodeAsc',
    },
    corporate: {
      corporateId,
      deletedFlag: 0,
    },
  };

  useEffect(() => dispatch(quoteInit(apiParams)), [dispatch]);
  useEffect(() => {
    if (matterCategory.industryPrimary === null) {
      formik.setFieldValue('matterCategory.industryPrimaryOther', null);
    }
  }, [matterCategory.industryPrimary]);

  const disableFlow = managerUserId === null ? true : false;
  const disableCategory =
    matterCategory.category === theOtherVal?.val ? false : true;
  const disableMatter =
    matterCategory.industryPrimary === materOtherVal?.largeClassId
      ? false
      : true;

  const middleClass =
    matterSel?.find(
      largeClass => largeClass.largeClassId === matterCategory.industryPrimary
    )?.middleClass ?? [];

  return (
    <div className="quote_register--term">
      {/* 取引先 */}
      {/* 支払条件インプットと連動する処理 */}
      <CustomFormikInput
        key="clientId"
        formik={formik}
        inputType="select"
        label="取引先"
        labelRequired={true}
        inputName="clientId"
        initialValue={clientSel?.map(e => e.val)}
        selectBoxOptions={clientSel?.map(e => `${e.val}:${e.opt}`)}
        placeholder="取引先を選択してください"
        style={{
          areaStyle: { width: checkIsSp() ? '100%' : '64%' },
          labelStyle: { width: '175px' },
        }}
        extraOnChange={e => {
          if (e) {
            fetchRequest(apiUrl.client.detail, { clientId: e }).then(client => {
              dispatch(
                quoteFlowFetch({ userId: client?.detail?.managerUserId })
              ).then(({ payload }) => {
                formik.setValues({
                  ...formik.values,
                  clientId: e,
                  clientManagerName:
                    client?.detail?.clientManagerName === null
                      ? ''
                      : client?.detail?.clientManagerName,
                  payTermsId:
                    client?.detail?.payTermId !== 0
                      ? client?.detail?.payTermId
                      : null,
                  managerUserId: userSel.find(
                    obj => obj.val == client?.detail?.managerUserId
                  )
                    ? client?.detail?.managerUserId
                    : null,
                  approvalFlowId:
                    client?.detail?.managerUserId &&
                    payload?.flowList?.length > 0
                      ? payload?.flowList[0]?.approvalFlowId
                      : null,
                });
              });
            });
          }
        }}
      />
      {/* 取引先担当者 */}
      <CustomFormikInput
        key="clientManagerName"
        formik={formik}
        inputType="input"
        label="取引先担当者"
        inputName="clientManagerName"
        placeholder="取引先担当者を入力してください"
        style={{
          labelStyle: { width: '175px' },
          areaStyle: { width: checkIsSp() ? '100%' : '64%' },
        }}
      />
      {/* 案件名 */}
      <CustomFormikInput
        key="matterName"
        formik={formik}
        inputType="input"
        label="案件名"
        labelRequired={true}
        inputName="matterName"
        placeholder="案件名を入力してください"
        style={{
          labelStyle: { width: '175px' },
          areaStyle: { width: checkIsSp() ? '100%' : '64%' },
        }}
      />
      {/* 社内担当者 */}
      <CustomFormikInput
        key="managerUserId"
        formik={formik}
        inputType="select"
        label="社内担当者"
        labelRequired={true}
        inputName="managerUserId"
        initialValue={userSel?.map(e => e.val)}
        selectBoxOptions={userSel?.map(e => `${e.val}:${e.opt}`)}
        placeholder="社内担当者を選択してください"
        style={{
          areaStyle: { width: checkIsSp() ? '100%' : '64%' },
          labelStyle: { width: '175px' },
        }}
        extraOnChange={e => {
          //  承認フローセレクトボックス値
          dispatch(quoteFlowFetch({ userId: e })).then(({ payload }) => {
            formik.setFieldValue(
              'approvalFlowId',
              e ? payload?.flowList[0]?.approvalFlowId : null
            );
          });
        }}
      />
      {/* 承認フロー */}
      <CustomFormikInput
        key="approvalFlowId"
        formik={formik}
        inputType="select"
        label="承認フロー"
        labelRequired={true}
        inputName="approvalFlowId"
        initialValue={flowList.map(e => e.approvalFlowId)}
        selectBoxOptions={flowList.map(
          e => `${e.approvalFlowId}:${e.approvalFlowName}`
        )}
        disabled={disableFlow}
        placeholder="承認フローを選択してください"
        style={{
          areaStyle: {
            width: checkIsSp() ? '100%' : '40%',
            minWidth: checkIsSp() ? '100%' : '455px',
          },
          labelStyle: { width: '175px' },
        }}
      />
      {/* 支払条件 */}
      <CustomFormikInput
        key="payTermsId"
        formik={formik}
        inputType="select"
        label="支払条件"
        labelRequired={true}
        inputName="payTermsId"
        initialValue={paymentSel?.map(e => e.val)}
        selectBoxOptions={paymentSel?.map(e => `${e.val}:${e.opt}`)}
        placeholder="支払条件を選択してください"
        style={{
          areaStyle: {
            width: checkIsSp() ? '100%' : '40%',
            minWidth: checkIsSp() ? '100%' : '455px',
          },
          labelStyle: { width: '175px' },
        }}
      />
      {/*/!* 見積書表示住所 *!/*/}
      <CustomFormikInput
        key="officeNumber"
        formik={formik}
        inputType="select"
        label="見積書表示住所"
        inputName="officeNumber"
        initialValue={officeMasters?.map(o => o.value)}
        selectBoxOptions={officeMasters?.map(o => o.label)}
        placeholder="事業所名を選択してください"
        style={{
          areaStyle: {
            width: checkIsSp() ? '100%' : '40%',
            minWidth: checkIsSp() ? '100%' : '455px',
          },
          labelStyle: { width: '175px' },
        }}
      />

      <CustomFormikInput
        key="invoiceRegistrationNumber"
        formik={formik}
        inputType="input"
        label="登録番号"
        inputName="invoiceRegistrationNumber"
        placeholder="適格請求書発行事業者登録番号を入力してください"
        style={{
          areaStyle: {
            width: checkIsSp() ? '100%' : '40%',
            minWidth: checkIsSp() ? '100%' : '455px',
          },
          labelStyle: { width: '175px' },
        }}
      />

      {/* 受注ステータス */}
      <CustomFormikInput
        key="orderStatus"
        formik={formik}
        inputType="radioButton"
        radioButtonTitle="受注ステータス"
        labelRequired={true}
        inputName="orderStatus"
        radioButtonLabelAndChecked={
          formik.values.orderStatus == 1
            ? [
                { label: '見積中', checked: false },
                { label: '受注済', checked: true },
                { label: '失注', checked: false },
              ]
            : formik.values.orderStatus == 2
            ? [
                { label: '見積中', checked: false },
                { label: '受注済', checked: false },
                { label: '失注', checked: true },
              ]
            : [
                { label: '見積中', checked: true },
                { label: '受注済', checked: false },
                { label: '失注', checked: false },
              ]
        }
        initialValue={[0, 1, 2]}
        style={{
          labelStyle: { width: '175px' },
          inputStyle: { columnGap: '54px' },
        }}
      />
      {/* 請求タイミング */}
      <div
        onChange={e => {
          if (e.target.value != 1) {
            formik.setFieldValue('invoicePlanDates', ['']);
          }
        }}
      >
        <CustomFormikInput
          key="invoiceTiming"
          formik={formik}
          inputType="radioButton"
          radioButtonTitle="請求タイミング"
          labelRequired={true}
          inputName="invoiceTiming"
          radioButtonLabelAndChecked={[
            { label: '一括請求', checked: invoiceTiming == 0 },
            { label: '分割請求', checked: invoiceTiming == 1 },
          ]}
          initialValue={[0, 1]}
          style={{
            labelStyle: { width: '175px' },
            inputStyle: { columnGap: '40px' },
          }}
        />
      </div>
      {/* 請求(予定)日 */}
      {invoicePlanDates.map((_, i) => {
        let len = invoicePlanDates.length;
        return (
          <React.Fragment key={i}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: len === i + 1 ? '16px' : '1px',
              }}
            >
              <CustomFormikInput
                key="invoicePlanDates"
                formik={formik}
                inputType="inputDate"
                label="請求(予定)日"
                labelRequired={true}
                inputName={`invoicePlanDates[${i}]`}
                placeholder="YYYY/MM/DD"
                style={{
                  labelStyle: {
                    width: '175px',
                    visibility: i === 0 ? '' : 'hidden',
                  },
                  areaStyle: checkIsSp() ? { width: '165px' } : {},
                }}
              />
              {len === i + 1 ? (
                <AddButton
                  style={{
                    width: '36px',
                    height: '36px',
                    cursor: 'pointer',
                    display: invoiceTiming == 1 ? '' : 'none',
                  }}
                  type="round"
                  onClick={() => {
                    if (invoiceTiming.length > 19) {
                      return;
                    } else {
                      formik.setFieldValue('invoicePlanDates', [
                        ...invoicePlanDates,
                        '',
                      ]);
                    }
                  }}
                />
              ) : (
                <img
                  src={Icons.icon.xCircleWh}
                  alt="del"
                  style={{
                    width: '26px',
                    height: '30px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    let shallowCopy = formik.values.invoicePlanDates.slice();
                    shallowCopy.splice(i, 1);
                    formik.setFieldValue('invoicePlanDates', shallowCopy);
                  }}
                />
              )}
            </div>
          </React.Fragment>
        );
      })}
      {/* 案件最終実施日 */}
      <CustomFormikInput
        key="matterLastDate"
        formik={formik}
        inputType="inputDate"
        label="案件最終実施日"
        inputName="matterLastDate"
        placeholder="YYYY/MM/DD"
        style={{
          labelStyle: labelSty,
          areaStyle: checkIsSp() ? { width: '165px' } : {},
        }}
      />
      {/* 支払方法 */}
      <div
        style={
          checkIsSp()
            ? { display: 'flex', flexDirection: 'column', gap: '16px' }
            : { display: 'flex', gap: '68px' }
        }
      >
        {/* 支払方法--銀行振込~口座振替 */}
        <CustomFormikInput
          formik={formik}
          inputType="radioButton"
          radioButtonTitle="支払方法"
          labelRequired={true}
          inputName="paymentMethod"
          radioButtonLabelAndChecked={
            formik.values.paymentMethod == '1'
              ? [
                  { label: '銀行振込', checked: false },
                  { label: '口座振替', checked: true },
                ]
              : formik.values.paymentMethod > '1'
              ? [
                  { label: '銀行振込', checked: false },
                  { label: '口座振替', checked: false },
                ]
              : [
                  { label: '銀行振込', checked: true },
                  { label: '口座振替', checked: false },
                ]
          }
          initialValue={[0, 1]}
          style={{
            areaStyle: { width: 'auto' },
            labelStyle: { width: '175px' },
            inputStyle: checkIsSp()
              ? { columnGap: '0', width: '100%' }
              : { columnGap: '40px' },
            displayStyle: checkIsSp() ? { width: '140px' } : {},
          }}
        />
        {/* 支払方法--クレジットカード~現金支払 */}
        <CustomFormikInput
          formik={formik}
          inputType="radioButton"
          inputName="paymentMethod"
          radioButtonLabelAndChecked={
            formik.values.paymentMethod == '2'
              ? [
                  { label: 'クレジットカード', checked: true },
                  { label: '現金支払', checked: false },
                ]
              : formik.values.paymentMethod == '3'
              ? [
                  { label: 'クレジットカード', checked: false },
                  { label: '現金支払', checked: true },
                ]
              : [
                  { label: 'クレジットカード', checked: false },
                  { label: '現金支払', checked: false },
                ]
          }
          initialValue={[2, 3]}
          style={{
            inputStyle: checkIsSp()
              ? { columnGap: '0', width: '100%' }
              : { columnGap: '40px' },
            areaStyle: { width: 'unset' },
            displayStyle: checkIsSp() ? { width: '140px' } : {},
          }}
        />
      </div>
      {/* 支払方法--代金引換~郵便振替 */}
      {checkIsSp() ? (
        <CustomFormikInput
          formik={formik}
          inputType="radioButton"
          inputName="paymentMethod"
          radioButtonLabelAndChecked={
            formik.values.paymentMethod == '4'
              ? [
                  { label: '代金引換', checked: true },
                  { label: 'コンビニ支払', checked: false },
                  { label: '郵便振替', checked: false },
                ]
              : formik.values.paymentMethod == '5'
              ? [
                  { label: '代金引換', checked: false },
                  { label: 'コンビニ支払', checked: true },
                  { label: '郵便振替', checked: false },
                ]
              : formik.values.paymentMethod == '6'
              ? [
                  { label: '代金引換', checked: false },
                  { label: 'コンビニ支払', checked: false },
                  { label: '郵便振替', checked: true },
                ]
              : [
                  { label: '代金引換', checked: false },
                  { label: 'コンビニ支払', checked: false },
                  { label: '郵便振替', checked: false },
                ]
          }
          initialValue={[4, 5, 6]}
          style={{
            inputStyle: checkIsSp()
              ? { columnGap: '0', width: '100%', marginTop: '-8px' }
              : { columnGap: '40px' },
            displayStyle: checkIsSp() ? { width: '140px' } : {},
          }}
        />
      ) : (
        <CustomFormikInput
          formik={formik}
          inputType="radioButton"
          radioButtonTitle=" "
          inputName="paymentMethod"
          radioButtonLabelAndChecked={
            formik.values.paymentMethod == '4'
              ? [
                  { label: '代金引換', checked: true },
                  { label: 'コンビニ支払', checked: false },
                  { label: '郵便振替', checked: false },
                ]
              : formik.values.paymentMethod == '5'
              ? [
                  { label: '代金引換', checked: false },
                  { label: 'コンビニ支払', checked: true },
                  { label: '郵便振替', checked: false },
                ]
              : formik.values.paymentMethod == '6'
              ? [
                  { label: '代金引換', checked: false },
                  { label: 'コンビニ支払', checked: false },
                  { label: '郵便振替', checked: true },
                ]
              : [
                  { label: '代金引換', checked: false },
                  { label: 'コンビニ支払', checked: false },
                  { label: '郵便振替', checked: false },
                ]
          }
          initialValue={[4, 5, 6]}
          style={{
            labelStyle: { width: '175px' },
            inputStyle: checkIsSp()
              ? { columnGap: '0', width: '100%' }
              : { columnGap: '40px' },
            displayStyle: checkIsSp() ? { width: '140px' } : {},
          }}
        />
      )}
      {/* 案件カテゴリ */}
      <div style={divSty}>
        <CustomFormikInput
          key="matterCategoryCategory"
          formik={formik}
          inputType="select"
          label="案件カテゴリ"
          labelRequired={true}
          inputName="matterCategory.category"
          initialValue={matterCategorySel?.map(e => e.val)}
          selectBoxOptions={matterCategorySel?.map(e => `${e.val}:${e.opt}`)}
          placeholder="案件種別を選択してください"
          style={{
            labelStyle: { width: '175px' },
            areaStyle: areaSty,
          }}
          extraOnChange={e => {
            if (e != theOtherVal?.val) {
              formik.setFieldValue('matterCategory.categoryOther', null);
            }
          }}
        />
        <CustomFormikInput
          formik={formik}
          inputType="input"
          inputName="matterCategory.categoryOther"
          disabled={disableCategory}
          placeholder="案件カテゴリを入力してください"
          style={{
            inputStyle: { minWidth: checkIsSp() ? '100%' : '274px' },
          }}
        />
      </div>
      {/* 業界 */}
      <div style={divSty}>
        <CustomFormikInput
          key="matterCategoryIndustryPrimary"
          formik={formik}
          inputType="select"
          label="業界"
          labelRequired={true}
          inputName="matterCategory.industryPrimary"
          initialValue={matterSel?.map(e => e.largeClassId)}
          selectBoxOptions={matterSel?.map(
            e => `${e.largeClassId}:${e.largeClassName}`
          )}
          placeholder="大分類を選択してください"
          style={{
            labelStyle: { width: '175px' },
            areaStyle: areaSty,
          }}
          extraOnChange={e => {
            if (e !== materOtherVal.largeClassId) {
              formik.setFieldValue('matterCategory.industryPrimaryOther', null);
            } else {
              formik.setFieldValue('matterCategory.industrySecondary', null);
            }
          }}
        />
        {disableMatter ? (
          <CustomFormikInput
            formik={formik}
            inputType="select"
            inputName="matterCategory.industrySecondary"
            initialValue={middleClass.map(e => e.middleClassId)}
            selectBoxOptions={middleClass.map(
              e => `${e.middleClassId}:${e.middleClassName}`
            )}
            disabled={matterCategory.industryPrimary === null}
            placeholder="中分類を入力してください"
            style={{
              inputStyle: { minWidth: '274px' },
            }}
          />
        ) : (
          <CustomFormikInput
            inputName="matterCategory.industryPrimaryOther"
            formik={formik}
            placeholder="中分類を入力してください"
            style={{
              inputStyle: { minWidth: '274px' },
            }}
          />
        )}
      </div>
      {/* 予備項目 */}
      {preliminaryList.map((item, i) => (
        <CustomFormikInput
          key={'preliminary' + (i + 1)}
          formik={formik}
          inputType="select"
          label={item}
          inputName={'preliminary' + (i + 1)}
          initialValue={eval(`pre${i + 1}Sel`)?.map(e => e.val)}
          selectBoxOptions={eval(`pre${i + 1}Sel`)?.map(
            e => `${e.val}:${e.opt}`
          )}
          placeholder="選択してください"
          style={{
            areaStyle: { width: checkIsSp() ? '100%' : '83%' },
            labelStyle: { width: '175px' },
          }}
        />
      ))}
      {/* 備考 */}
      <CustomFormikInput
        formik={formik}
        inputType="textBox"
        label="備考"
        inputName="note"
        placeholder="備考を入力してください"
        style={{
          labelStyle: { width: '175px' },
          areaStyle: { width: checkIsSp() ? '100%' : '83%' },
        }}
      />
    </div>
  );
});

export default QuoteTermContents;
