import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import prefectures from '../../constants/prefectures';

const namingValidList = [
  'ClientPreliminary1',
  'ClientPreliminary2',
  'ClientPreliminary3',
  'ClientPreliminary4',
  'ClientPreliminary5',
];
const CategorySearchParams = {
  namingClassificationId:
    'Index,ClientPreliminary1,ClientPreliminary2,ClientPreliminary3,' +
    'ClientPreliminary4,ClientPreliminary5',
  sort: 'namingIdAsc',
  status: 1,
};

const initialSearchParams = {
  venueId: '', // 開催地ID
  venueName: '',
  venueFlag: [], // 種別 (0: 自社店舗, 1: 催事場, 2: 展示会場, 9: その他)
  prefCode: [], // 都道府県
  namingClassificationId: null, // 予備項目 名称区分ID
  namingId: [],
  status: 99,
  count: 20,
  offset: 0,
  sort: 'venueIdDesc',
  sortColumn: 'venueId',
  sortMethod: 'Desc',
};
const initialEditParams = {
  venueId: null,
  venueName: '',
  venueFlag: 0,
  venueImage: null,
  venueNameKana: null,
  postCode1: null,
  postCode2: null,
  prefCode: null,
  cityName: null,
  streetName: '',
  buildingName: null,
  tel1: null,
  tel2: null,
  tel3: null,
  fax1: null,
  fax2: null,
  fax3: null,
  url: null,
  nearestStation: [''],
  nearestStation1: null,
  nearestStation2: null,
  nearestStation3: null,
  nearestStation4: null,
  nearestStation5: null,
  preliminary1: null,
  preliminary2: null,
  preliminary3: null,
  preliminary4: null,
  preliminary5: null,
  note: null,
};

const initialState = {
  searchParams: initialSearchParams,
  editParams: initialEditParams,
  searchWorkParams: initialSearchParams,
  searchCount: 0,
  totalCount: 0,
  activeCount: 0,
  namingCategoryList: [],
  namingList: [],
  clientList: [],
  userList: [],
  venueList: [],
  csvImportVisible: false,
  csvExportVisible: false,
  zipSearch: false,
  nextId: 0,
};

export const venueInit = createAsyncThunk('venue/init', async () => {
  const namingRes = await fetchRequest(
    apiUrl.naming.list,
    CategorySearchParams
  );
  return {
    namingCategoryList: namingRes?.item
      ?.sort((a, b) => (a.namingId < b.namingId ? -1 : 1))
      ?.filter(n => namingValidList.includes(n?.namingId ?? ''))
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    namingList: namingRes?.item
      ?.sort((a, b) => (a.namingId < b.namingId ? -1 : 1))
      ?.filter(n => n?.namingClassificationId !== 'Index')
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    namingListPriorityAsc: namingRes?.item
      ?.sort((a, b) => (a.priority < b.priority ? -1 : 1))
      ?.filter(n => n?.namingClassificationId !== 'Index')
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
  };
});

export const venueListFetch = createAsyncThunk('venue/list', async params => {
  const venueRes = await fetchRequest(apiUrl.venue.list, params);
  const item = venueRes?.item?.map(c => {
    const prefName = prefectures.find(
      p => p?.prefecturesCode == c?.prefCode
    )?.label;
    return {
      ...c,
      prefName: `${prefName ? prefName : ''}`,
      idxClbk: c?.preliminary1?.naming,
      editIconState: c?.status !== 1 ? 1 : 0,
      deleteIconState: c?.status == '2' ? 1 : c?.status == '0' ? 2 : 0,
      status: c?.status === 1 ? '表示' : c?.status === 0 ? '非表示' : '削除済',
      venueFlag:
        c?.venueFlag == 1
          ? '催事場'
          : c?.venueFlag == 0
          ? '自社店舗'
          : c?.venueFlag == 2
          ? '展示会場'
          : 'その他',
      deletedFlag: c?.deletedFlag,
    };
  });
  return {
    venueList: {
      item,
      count: venueRes?.searchCount ?? 0,
      totalCount: venueRes?.totalCount ?? 0,
      activeCount: venueRes?.activeCount ?? 0,
      topCount: [
        venueRes?.totalCount?.toLocaleString() ?? 0,
        venueRes?.activeCount?.toLocaleString() ?? 0,
      ],
    },
  };
});
export const venueCheckListFetch = createAsyncThunk(
  'venue/checkList',
  async params => await fetchRequest(apiUrl.venue.list)
);
export const venueInitialInfoFetch = createAsyncThunk(
  'venue/initialInfo',
  async params => {
    return await fetchRequest(apiUrl.venue.initialInfo);
  }
);
export const venueDetailFetch = createAsyncThunk(
  'venue/detail',
  async params => {
    return await fetchRequest(apiUrl.venue.detail, params);
  }
);
export const venueInfoFetch = createAsyncThunk('venue/info', async params => {
  const [venueRes] = await Promise.all([fetchRequest(apiUrl.venue.list)]);
  return {
    venueRes,
  };
});

export const uploadImage = createAsyncThunk(
  'venue/upload/image',
  async params => await fetchRequest(apiUrl.other.imageUpload, params, 'POST')
);
export const venueInsert = createAsyncThunk(
  'venue/insert',
  async params => await fetchRequest(apiUrl.venue.insert, params, 'POST')
);
export const venueUpdate = createAsyncThunk(
  'venue/update',
  async params => await fetchRequest(apiUrl.venue.update, params, 'POST')
);

export const venueDelete = createAsyncThunk(
  'venue/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.venue.delete, params, 'POST');
    dispatch(venueActions.refresh());
  }
);

export const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveParam: (state, { payload }) => {
      state.searchWorkParams = { ...state.searchParams };
    },
    setEdit: state => {
      state.editParams = { ...state.editParams };
    },
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvImportVisible: (state, { payload }) => {
      state.csvImportVisible = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    editReset: (state, { payload }) => {
      state.editParams = initialEditParams;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    zipSearchFlag: (state, { payload }) => {
      state.zipSearch = payload;
    },
  },
  extraReducers: {
    [venueInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [venueListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [venueDetailFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [venueInfoFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      venueList: payload?.venueRes?.item ?? [],
    }),
  },
});

export const venueActions = venueSlice.actions;
export default venueSlice.reducer;
