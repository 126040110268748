import React, { useState, useEffect } from 'react';
import ModalBase from '../modalBase';
import CustomInput from '../../customInput';
import Button from '../../button';
import Options from '../../../constants/options';
import './style.scss';
import { checkIsSp } from '../../../utils/fnUtil';

const PreviewModal = props => {
  const { modalVisible, changeModalVisible, dataSource, name, replaceList } =
    props;

  const data = dataSource.values;
  const previewSubject = data.subject
    .replaceAll('##quote_number##', replaceList[0])
    .replaceAll('##matter_name##', replaceList[1])
    .replaceAll('##client_name##', replaceList[2])
    .replaceAll('##client_manager_name##', replaceList[3])
    .replaceAll('##matter_manager_first_name##', replaceList[4])
    .replaceAll('##matter_manager_last_name##', replaceList[5])
    .replaceAll('##this_month##', replaceList[6])
    .replaceAll('##last_month##', replaceList[7])
    .replaceAll('##two_months_ago##', replaceList[8])
    .replaceAll('##next_month##', replaceList[9])
    .replaceAll('##two_months_later##', replaceList[10]);
  const previewMessage = data.message
    .replaceAll('##quote_number##', replaceList[0])
    .replaceAll('##matter_name##', replaceList[1])
    .replaceAll('##client_name##', replaceList[2])
    .replaceAll('##client_manager_name##', replaceList[3])
    .replaceAll('##matter_manager_first_name##', replaceList[4])
    .replaceAll('##matter_manager_last_name##', replaceList[5])
    .replaceAll('##this_month##', replaceList[6])
    .replaceAll('##last_month##', replaceList[7])
    .replaceAll('##two_months_ago##', replaceList[8])
    .replaceAll('##next_month##', replaceList[9])
    .replaceAll('##two_months_later##', replaceList[10]);

  const dialogStyle = {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: checkIsSp()? 'calc(100% - 32px)':'calc(100% - 288px)',
    height: 'calc(100% - 240px)',
    // padding: '64px 40px 24px 40px',
    padding: '0',
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  return (
    <ModalBase dialogStyle={dialogStyle} modalVisible={modalVisible}>
      <div className="preview_modal">
        <div className="import_modal--title_area">
          <p>{name}</p>
        </div>
        <table>
          <tbody>
            <tr>
              <td>To</td>
              <td>{data.toAddress}</td>
            </tr>
            <tr>
              <td>Cc</td>
              <td>{data.ccAddress}</td>
            </tr>
            <tr>
              <td>件名</td>
              <td>{previewSubject}</td>
            </tr>
            <tr>
              <td>本文</td>
              <td>{previewMessage}</td>
            </tr>
          </tbody>
        </table>

        <div className="preview_modal--btndiv">
          <Button
            text={'戻る'}
            style={
              checkIsSp()
                ? {
                    width: '100%',
                    height: '44px',
                    borderRadius: '6px',
                    backgroundColor: '#E9F2FC',
                    color: '#486083',
                    fontSize: '14px',
                    margin: '0 0 24px',
                    padding: '0 0',
                    userSelect: 'none',
                    boxSizing: 'border-box',
                  }
                : Options.buttonStyles.previewBack
            }
            onClick={changeModalVisible}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default PreviewModal;
