import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RegisterBase from '../../../components/registerBase';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import payTermInfo from '../../../constants/payTermInfo';
import Yup from '../../../utils/yupUtil';
import { checkIsSp, handlerFormikSubmit } from '../../../utils/fnUtil';
import {
  payTermsInit,
  payTermsInsert,
  payTermsUpdate,
} from '../../../redux/slice/payTerm';
import { globalActions } from '../../../redux/slice/global';

const PayTermRegister = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const isSp = checkIsSp();

  const { editParam, payTermsList } = useSelector(state => state.payTerms);

  const item =
    payTermsList?.find(n => n.payTermsId === location?.state?.id) ?? editParam;

  useEffect(() => {
    isEdit &&
      dispatch(payTermsInit())
        .unwrap()
        .then(data => {
          let items =
            data.payTermsList?.find(
              n => n.payTermsId === location?.state?.id
            ) ?? editParam;
          formik.setFieldValue(...items);
        });
  }, [editParam]);

  const tabErrorGroup = {
    payTerm: [
      'payTermsName',
      'payCategory',
      'closingDate',
      'payPeriodDay',
      'payPeriodMonth',
    ],
  };
  const formik = useFormik({
    initialValues: {
      closingDate: item?.closingDate ? item.closingDate : null,
      closingDateName: item?.closingDateName ?? '',
      payCategory: item?.payCategory ? item.payCategory : 0,
      payDate: item?.payDate ? item.payDate : null,
      payPeriodDay: item?.payPeriodDay ? item.payPeriodDay : null,
      payPeriodMonth: item?.payPeriodMonth ? item.payPeriodMonth : null,
      payTermsName: item?.payTermsName ?? '',
      priority: item?.priority ?? '',
      payTermsId: item?.payTermsId ?? '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      payTermsName: Yup.string()
        .required()
        .nullable()
        .label('[支払条件]支払条件名'),
      payCategory: Yup.string().nullable().selected('[支払条件]支払条件区分'),
      closingDate: Yup.string().nullable().selected('[支払条件]締日'),
      payDate: Yup.string()
        .nullable()
        .when('payCategory', (value, schema) =>
          value === '1' ? schema.required() : schema.nullable()
        )
        .label('[支払条件]日数'),
      payPeriodMonth: Yup.string()
        .nullable()
        .when('payCategory', (value, schema) =>
          value === '0' ? schema.required() : schema.nullable()
        )
        .label('[支払条件]支払期限月'),
      payPeriodDay: Yup.string()
        .nullable()
        .when('payCategory', (value, schema) =>
          value === '0' ? schema.required() : schema.nullable()
        )
        .label('[支払条件]支払期限日'),
    }),
    onSubmit: values => {
      dispatch(isEdit ? payTermsUpdate(values) : payTermsInsert(values))
        .unwrap()
        .then(() => {
          dispatch(
            globalActions.showSingleModal(`${isEdit ? '更新' : '登録'}しました`)
          );
          navigate(-1);
        });
    },
  });
  const { payCategory } = formik.values;

  const isEdit = useLocation().pathname.slice(5).includes('edit');

  const PayTermContents = () => {
    return (
      <div className="pay_register--term">
        <div className="pay_register--term--input">
          <CustomFormikInput
            formik={formik}
            inputType="input"
            label="支払条件名"
            labelRequired={true}
            inputName="payTermsName"
            value={isEdit ? location.state.id : ''}
            placeholder="支払条件名を入力してください"
            style={{
              areaStyle: isSp
                ? { width: '100%' }
                : { width: '67%', minWidth: '800px' },
              labelStyle: isSp ? { width: '100%' } : { width: '232px' },
            }}
          />
        </div>
        <div className="pay_register--term--input">
          <CustomFormikInput
            formik={formik}
            inputType="radioButton"
            radioButtonTitle="支払方法区分"
            labelRequired={true}
            useValue={true}
            inputName="payCategory"
            radioButtonLabelAndChecked={[
              { label: '締日基準', checked: payCategory == 0 },
              { label: '日数基準', checked: payCategory == 1 },
            ]}
            initialValue={[0, 1]}
            style={{ labelStyle: { width: '232px' } }}
            disabled={item?.deleteLockFlag == 1}
          />
        </div>
        <div className="pay_register--term--input">
          <CustomFormikInput
            formik={formik}
            inputType="select"
            label="締日"
            labelRequired={true}
            inputName="closingDate"
            initialValue={payTermInfo.closingDate.map(c => c.value)}
            selectBoxOptions={payTermInfo.closingDate.map(c => c.label)}
            placeholder="締日を選択してください"
            style={{
              areaStyle: isSp ? { width: '100%' } : { width: '492px' },
              labelStyle: isSp ? { width: '100%' } : { width: '232px' },
            }}
            disabled={item?.deleteLockFlag == 1}
          />
        </div>
        {payCategory == 1 ? (
          <div
            className="pay_register--term--input"
            style={{ marginBottom: '0' }}
          >
            <CustomFormikInput
              formik={formik}
              inputType="select"
              label="日数"
              labelRequired={true}
              inputName="payDate"
              initialValue={[...new Array(100)].map((v, i) => i + 1)}
              selectBoxOptions={[...new Array(100)].map((v, i) => i + 1 + '日')}
              placeholder="日数を選択してください"
              style={{
                areaStyle: isSp ? { width: '100%' } : { width: '492px' },
                labelStyle: isSp ? { width: '100%' } : { width: '232px' },
              }}
              disabled={item?.deleteLockFlag == 1}
            />
          </div>
        ) : (
          <div className="pay_term_register--select_unity">
            <CustomFormikInput
              formik={formik}
              inputType="select"
              label="支払期限"
              labelRequired={true}
              inputName={`payPeriodMonth`}
              initialValue={payTermInfo.payPeriodMonth.map(c => c.value)}
              selectBoxOptions={payTermInfo.payPeriodMonth.map(c => c.label)}
              placeholder="支払期限月"
              style={{
                areaStyle: isSp ? { width: '100%' } : { width: '492px' },
                labelStyle: isSp ? { width: '100%' } : { width: '232px' },
              }}
              disabled={item?.deleteLockFlag == 1}
            />
            <div className={'pay_term_register--slash'}>/</div>
            <CustomFormikInput
              inputType="select"
              inputName={'payPeriodDay'}
              key={'payPeriodDay'}
              formik={formik}
              initialValue={payTermInfo.payPeriodDay.map(c => c.value)}
              selectBoxOptions={payTermInfo.payPeriodDay.map(c => c.label)}
              placeholder="支払期限日"
              style={{
                areaStyle: isSp ? { width: '100%' } : { width: '241px' },
              }}
              disabled={item?.deleteLockFlag == 1}
            />
          </div>
        )}
      </div>
    );
  };

  const infos = {
    detail: {
      tabs: {
        payTerm: {
          label: '支払条件',
          jsx: PayTermContents,
        },
      },
    },
  };

  useEffect(() => {
    if (isSp) {
      const targetElm = document.getElementsByClassName('sub--content_area');
      Array.from(targetElm).forEach(elm => {
        elm.style.paddingBottom = '181px';
      });
    }
  }, []);

  return (
    <>
      <RegisterBase infos={infos} />
      <div
        className={'bottom_btn_area'}
        style={{ height: isSp ? '88px' : '92px' }}
      >
        <div
          className={'pay_register--submit_buttons'}
          style={isSp ? { padding: '0 16px', alignItems: 'center' } : {}}
        >
          <Button
            text={'戻る'}
            style={
              isSp
                ? Options.buttonStyles.stampBackSp
                : Options.buttonStyles.stampBack
            }
            onClick={() => {
              navigate(-1);
            }}
          />
          <Button
            text={isEdit ? '更新' : '登録'}
            style={
              isSp
                ? Options.buttonStyles.stampApprovalSp
                : Options.buttonStyles.stampApproval
            }
            onClick={() => {
              handlerFormikSubmit(formik, tabErrorGroup);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PayTermRegister;
