import './style.scss';
import RegisterBase from '../../../../components/registerBase';
import Icons from '../../../../constants/icons';
import moment from 'moment';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { convertDatetime, handlerFormikSubmit } from '../../../../utils/fnUtil';
import { useSelector, useDispatch } from 'react-redux';
import { globalActions } from '../../../../redux/slice/global';
import { useLocation } from 'react-router-dom';
import {
  staffPayDetailFetch,
  staffPayUpdateFetch,
} from '../../../../redux/slice/staffPay';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import Button from '../../../../components/button';

const StaffPayApprovalForSp = () => {
  const loginType = useSelector(state => state.loginType.type);
  const staffId = useSelector(state => state.account.staffId);
  const location = useLocation();
  const dispatch = useDispatch();

  // 年月
  const yearMonth = {
    year: location?.state?.year,
    month: location?.state?.month,
  };

  const formik = useFormik({
    initialValues: {
      staffPayApproval: {
        netPayAmount: 0,
        payTotalAmount: 0,
        advancePayTotalAmount: 0,
        incomeTaxAmount: 0,
        beforePayTotalAmount: 0,
        payList: [],
      },
      staffApprovalSp: '3',
      staffNoteSp: '',
      showButton: true,
    },
    onSubmit: values => {
      let val = {
        year: yearMonth.year,
        month: yearMonth.month,
        staffId: staffId,
        mailFlag: values.sendMailFlag,
        staffApproval: Number(values.staffApprovalSp),
        staffNote: values.staffNoteSp,
      };
      dispatch(staffPayUpdateFetch(val))
        .unwrap()
        .then(() => {
          dispatch(globalActions.showSingleModal('送信しました'));
        });
    },
  });

  const { staffApprovalSp, staffNoteSp, showButton } = formik.values;

  const tableTitle = ['総支給予定額', '給与手当', '立替金', '所得税', '前払金'];

  const tableDiligenceTitle = [
    '案件名',
    '勤務時間',
    '支給',
    '基本給',
    '残業',
    '遅刻・早退',
    '手当',
    '立替金',
    'その他',
    '前払金',
    '合計',
  ];

  const getWeek = (year, month, day) => {
    const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
    let targetDay = new Date(year, month - 1, day);

    return weekDay[targetDay.getDay()];
  };

  const setFormikData = data => {
    let formatData = {
      ...data,
      staffPayApproval: data?.staffPayEditList?.staffPayDetail,
      sendMailFlag: data?.staffPayEditList?.staffPayDetail?.sendMailFlag ?? 0,
      staffApprovalSp:
        String(data?.staffPayEditList?.staffPayDetail?.staffApproval) ?? '3',
      staffNoteSp: data?.staffPayEditList?.staffPayDetail?.staffNote ?? '',
    };

    formik.setValues(formatData);
  };

  useEffect(() => {
    dispatch(
      staffPayDetailFetch({
        staffId: staffId,
        year: yearMonth.year,
        month: yearMonth.month,
      })
    )
      .unwrap()
      .then(data => {
        setFormikData(data);
        formik.setFieldValue('showButton', data?.detail?.staffApproval !== 3);
      });
  }, []);

  const payApprovalForSp = () => (
    <div className={'staff_pay_approval_sp--body'}>
      {/*表題（○年○月度） */}
      <div className={'staff_pay_approval_sp--title'}>
        {yearMonth.year}
        {'年'}
        {yearMonth.month}
        {'月度'}
      </div>
      {/*給与エリア */}
      <div className={'staff_pay_approval_sp--sub_title'}>{'給与'}</div>
      <div className={'staff_pay_approval_sp--pay_detail'}>
        <div className={'staff_pay_approval_sp--item_box_title'}>
          <div className={'staff_pay_approval_sp--item_box_title_left'}>
            {tableTitle[0]}
          </div>
          <div className={'staff_pay_approval_sp--item_box_title_right'}>
            {formik?.values?.staffPayApproval?.netPayAmount
              ? formik?.values?.staffPayApproval?.netPayAmount.toLocaleString()
              : '0'}
            円
          </div>
        </div>
        <div className={'staff_pay_approval_sp--item_box_contents'}>
          <div className={'staff_pay_approval_sp--item_box_contents_left'}>
            {tableTitle[1]}
          </div>
          <div className={'staff_pay_approval_sp--item_box_contents_right'}>
            {formik?.values?.staffPayApproval?.payTotalAmount
              ? formik?.values?.staffPayApproval?.payTotalAmount.toLocaleString()
              : '0'}
            円
          </div>
        </div>
        <div className={'staff_pay_approval_sp--item_box_contents'}>
          <div className={'staff_pay_approval_sp--item_box_contents_left'}>
            {tableTitle[2]}
          </div>
          <div className={'staff_pay_approval_sp--item_box_contents_right'}>
            {formik?.values?.staffPayApproval?.advancePayTotalAmount
              ? formik?.values?.staffPayApproval?.advancePayTotalAmount.toLocaleString()
              : '0'}
            円
          </div>
        </div>
        <div
          className={'staff_pay_approval_sp--item_box_contents_border'}
        ></div>
        <div className={'staff_pay_approval_sp--item_box_contents'}>
          <div className={'staff_pay_approval_sp--item_box_contents_left'}>
            {tableTitle[3]}
          </div>
          <div className={'staff_pay_approval_sp--item_box_contents_right'}>
            {formik?.values?.staffPayApproval?.incomeTaxAmount
              ? formik?.values?.staffPayApproval?.incomeTaxAmount.toLocaleString()
              : '0'}
            円
          </div>
        </div>
        <div className={'staff_pay_approval_sp--item_box_contents'}>
          <div className={'staff_pay_approval_sp--item_box_contents_left'}>
            {tableTitle[4]}
          </div>
          <div className={'staff_pay_approval_sp--item_box_contents_right'}>
            {formik?.values?.staffPayApproval?.beforePayTotalAmount
              ? formik?.values?.staffPayApproval?.beforePayTotalAmount.toLocaleString()
              : '0'}
            円
          </div>
        </div>
      </div>
      {/*勤怠エリア */}
      <div
        className={'staff_pay_approval_sp--sub_title'}
        style={{ padding: '50px 0 5px 0' }}
      >
        {'勤怠'}
      </div>
      {formik?.values?.staffPayApproval?.payList?.map((payList, idx) => {
        return (
          <div key={idx}>
            <div className={'staff_pay_approval_sp--diligence_top'}>
              <div className={'staff_pay_approval_sp--diligence_top_left'}>
                {payList?.matterDate.split('/')[1]}月
                {payList?.matterDate.split('/')[2]}
                日（
                {getWeek(
                  payList?.matterDate.split('/')[0],
                  payList?.matterDate.split('/')[1],
                  payList?.matterDate.split('/')[2]
                )}
                ）
              </div>
            </div>
            <div className={'staff_pay_approval_sp--diligence_title'}>
              <div className={''}>{payList?.matterName}</div>
            </div>
            <div className={'staff_pay_approval_sp--diligence_title'}>
              <div className={''}>{tableDiligenceTitle[1]}</div>
            </div>
            <div
              className={'staff_pay_approval_sp--diligence_contents'}
              style={{ height: '80px' }}
            >
              <div className={''}>
                {payList?.punchInDatetime && convertDatetime(payList?.punchInDatetime)?.split(' ')[1]}
                {'~'}
                {payList?.punchOutDatetime && convertDatetime(payList?.punchOutDatetime)?.split(' ')[1]}
                <br></br>
                {' （遅刻:'}
                {payList?.payCutTime ?? 0}
                {'分、残業:'}
                {payList?.overtime ?? 0}
                {'分）'}
              </div>
            </div>
            <div className={'staff_pay_approval_sp--diligence_title'}>
              <div className={''}>{tableDiligenceTitle[2]}</div>
            </div>
            <div
              className={'staff_pay_approval_sp--diligence_contents_list'}
              style={{ height: '40px', padding: '10px 0 0 0' }}
            >
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_left'
                }
              >
                {tableDiligenceTitle[3]}
              </div>
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_right'
                }
              >
                {payList?.payBaseAmount
                  ? payList?.payBaseAmount?.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            <div
              className={'staff_pay_approval_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_left'
                }
              >
                {tableDiligenceTitle[4]}
              </div>
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_right'
                }
              >
                {payList?.overtimePayAmount
                  ? payList?.overtimePayAmount?.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            <div
              className={'staff_pay_approval_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_left'
                }
              >
                {tableDiligenceTitle[5]}
              </div>
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_right'
                }
              >
                {payList?.payCutAmount
                  ? payList?.payCutAmount?.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            <div
              className={'staff_pay_approval_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_left'
                }
              >
                {tableDiligenceTitle[6]}
              </div>
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_right'
                }
              >
                {payList?.allowanceAmount
                  ? payList?.allowanceAmount?.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            <div
              className={'staff_pay_approval_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_left'
                }
              >
                {tableDiligenceTitle[7]}
              </div>
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_right'
                }
              >
                {payList?.advanceAmount
                  ? payList?.advanceAmount?.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            <div
              className={'staff_pay_approval_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_left'
                }
              >
                {tableDiligenceTitle[8]}
              </div>
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_right'
                }
              >
                {payList?.othersAmount
                  ? payList?.othersAmount?.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            <div
              className={'staff_pay_approval_sp--diligence_contents_list'}
              style={{ height: '30px' }}
            >
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_left'
                }
              >
                {tableDiligenceTitle[9]}
              </div>
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_right'
                }
              >
                {payList?.beforePayAmount
                  ? payList?.beforePayAmount?.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            <div
              className={'staff_pay_approval_sp--diligence_contents_list'}
              style={{ height: '30px', padding: '20px 0', fontWeight: 'bold' }}
            >
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_left'
                }
              >
                {tableDiligenceTitle[10]}
              </div>
              <div
                className={
                  'staff_pay_approval_sp--diligence_contents_list_right'
                }
              >
                {payList?.totalAmount
                  ? payList?.totalAmount?.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
            <div
              className={
                'staff_pay_approval_sp--diligence_contents_list_border_last'
              }
            ></div>
          </div>
        );
      })}
      <CustomFormikInput
        formik={formik}
        inputType="radioButton"
        inputName="staffApprovalSp"
        radioButtonLabelAndChecked={[
          { label: '上記内容に承認します', checked: staffApprovalSp === '3' },
          {
            label: '上記内容に誤りがあります',
            checked: staffApprovalSp === '2',
          },
        ]}
        initialValue={['3', '2']}
      />
      <div style={{ height: '200px', marginTop: '16px' }}>
        <CustomFormikInput
          formik={formik}
          inputType={'textBox'}
          inputName="staffNoteSp"
          placeholder={'誤りがある場合は、該当箇所を記載してください'}
          style={{
            inputStyle: {
              height: '150px',
            },
          }}
        />
      </div>
    </div>
  );

  //送信ボタン設定
  const buttonStyle = {
    registerButton: {
      width: '160px',
      height: '40px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    }
  };

  const myPageInfos = {
    detail: {
      tabs: {
        payApprovalForSp: {
          jsx: payApprovalForSp,
        },
      },
    },
  };

  //要素を修正する処理
  useEffect(() => {
    let targetElm = document.getElementsByClassName('register_base--container');
    let targetNoteSpan = document.getElementsByClassName(
      'register_base--container_notice'
    );
    let targetRegisterBase = document.getElementsByClassName(
      'register_base--container'
    );
    let targetRegisterBaseDetail = document.getElementsByClassName(
      'register_base--detail_info_base'
    );

    let targetTabLine = document.getElementsByClassName('ant-tabs-nav');

    Array.from(targetNoteSpan).forEach(elm => {
      elm.remove();
    });

    Array.from(targetElm).forEach(elm => {
      elm.style.margin = '0px auto 0';
    });

    Array.from(targetRegisterBase).forEach(elm => {
      elm.style.marginBottom = '40px';
    });

    Array.from(targetRegisterBaseDetail).forEach(elm => {
      elm.style.padding = '20px 5%';
      elm.style.width = '90%';
      elm.style.marginBottom = '93px';
    });

    Array.from(targetTabLine).forEach(elm => {
      elm.remove();
    });
  });

  return (
    <>
      <RegisterBase
        image={Icons.icon.noImage}
        imageShape={'round'}
        infos={myPageInfos}
      />
      {showButton && (
        <div className={'staff_pay_approval_sp--submit_btn_area'} style={{ height: '88px' }}>
          <div className={'staff_pay_approval_sp--submit_buttons'}>
            <Button
              text={'送信'}
              style={buttonStyle.registerButton}
              onClick={() => handlerFormikSubmit(formik)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StaffPayApprovalForSp;
