import { memo } from 'react';
import { isNil, toInteger } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Descriptions } from 'antd';
import { globalActions } from '../../../../redux/slice/global';
import {
  handlerFormikFieldChange,
  deleteArrayItem,
  calculateTime,
  splitBy,
  removeFromEnd,
  numberConvert,
  diffMinute,
  checkIsSp,
} from '../../../../utils/fnUtil';
import Button from '../../../../components/button';
import AddLineButton from '../../../../components/addLineButton';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import DeleteIcon from '../../../../components/deleteIcon';
import PartnerSearchModal from '../../../../components/modal/searchModal/partnerSearchModal';
import UserSearchModal from '../../../../components/modal/searchModal/userSearchModal';
import StaffSearchModal from '../../../../components/modal/searchModal/staffSearchModal';
import ShiftAssignCell from './shiftAssignCell';
import classNames from 'classnames';
import './style.scss';
import { matterDateActions } from '../../../../redux/slice/matterDate';

const AssignSetting = memo(({ formik }) => {
  const {
    values: { shift },
  } = formik;

  const numberOptions = {
    initialValue: [...Array(100)].map((_, i) => i + 1),
    selectBoxOptions: [...Array(100)].map((_, i) => i + 1),
  };

  const dispatch = useDispatch();

  const { width } = useWindowSize();

  const { calculationPayFlag } = useSelector(state => state.matterDate) ?? {};

  const isSp = checkIsSp();

  return (
    <div className="assign_setting">
      {shift.map((s, i) => {
        const {
          directorQuantityTemp,
          adQuantityTemp,
          manQuantityTemp,
          womanQuantityTemp,
          unisexQuantityTemp,
          payBaseFlag,
          hourlyPayBase,
          dailyPayBase,
          workStartDatetime,
          workEndDatetime,
          restMinute,
        } = s;

        const shiftNameComponent = (
          <>
            <CustomFormikInput
              formik={formik}
              inputName={`shift.[${i}].shiftName`}
              placeholder="シフト名を入力してください"
              style={{
                areaStyle: {
                  marginTop: isSp ? 0 : 10,
                  paddingLeft: isSp ? 0 : 10,
                  paddingRight: 50,
                  width: isSp ? '85%' : '100%',
                },
              }}
            />
            <DeleteIcon
              title="削除"
              onClick={() =>
                handlerFormikFieldChange(
                  formik,
                  'shift',
                  deleteArrayItem(shift, i)
                )
              }
            />
          </>
        );

        const workMinute = calculateTime(
          s.workStartDatetime,
          s.workEndDatetime,
          s.restMinute
        );

        const basePayComponent = (
          <div>
            <CustomFormikInput
              formik={formik}
              inputName={`shift.[${i}].payBaseFlag`}
              inputType="radioButton"
              radioButtonLabelAndChecked={[
                {
                  label: '時給計算',
                  checked: s.payBaseFlag == 0,
                },
                { label: '日給計算', checked: s.payBaseFlag == 1 },
              ]}
              initialValue={[0, 1]}
              style={{
                areaStyle: {
                  width: 100,
                },
              }}
            />
            <CustomFormikInput
              placeholder="0"
              formik={formik}
              unit="円"
              inputName={`shift.[${i}].${
                s.payBaseFlag == 0 ? 'hourlyPayBase' : 'dailyPayBase'
              }`}
              style={{
                areaStyle: {
                  margin: '10px 5px 10px 0',
                  width: 103,
                },
                inputStyle: {
                  textAlign: 'right',
                },
              }}
            />
            <CustomFormikInput
              formik={formik}
              label="交通費含む"
              inputName={`shift.[${i}].includeFareFlag`}
              inputType="checkBox"
              onChange={e =>
                handlerFormikFieldChange(
                  formik,
                  `shift.[${i}].includeFareFlag`,
                  e.target.checked ? 1 : 0
                )
              }
            />
          </div>
        );

        const payAmountComponent = (
          <CustomFormikInput
            formik={formik}
            inputName={`shift.[${i}].payAmount`}
            placeholder="0"
            style={{
              inputStyle: {
                textAlign: 'right',
              },
            }}
          />
        );

        const arriveDatetimeComponet = (
          <CustomFormikInput
            formik={formik}
            inputName={`shift.[${i}].arriveStationDatetime`}
            placeholder="00:00"
          />
        );

        const gatheringDatetimeComponent = (
          <CustomFormikInput
            formik={formik}
            inputName={`shift.[${i}].gatheringDatetime`}
            placeholder="00:00"
          />
        );

        const workDatetimeComponent = (
          <div>
            <CustomFormikInput
              formik={formik}
              inputName={`shift.[${i}].workStartDatetime`}
              otherErrorInputName={[`shift.[${i}].workDatetime`]}
              placeholder="00:00"
              style={{
                areaStyle: {
                  maxWidth: 75,
                },
              }}
            />
            <span className="separate">~</span>
            <CustomFormikInput
              formik={formik}
              inputName={`shift.[${i}].workEndDatetime`}
              otherErrorInputName={[`shift.[${i}].workDatetime`]}
              placeholder="00:00"
              style={{
                areaStyle: {
                  paddingRight: 5,
                  maxWidth: 75,
                },
              }}
            />
            <span>
              実働
              {workMinute ?? '--:--'}
            </span>
          </div>
        );

        const restMinuteComponent = (
          <CustomFormikInput
            formik={formik}
            inputName={`shift.[${i}].restMinute`}
            placeholder="00"
            inputType="select"
            initialValue={[...Array(301)].map((_, i) => i)}
            selectBoxOptions={[...Array(301)].map((_, i) => i)}
          />
        );

        const pickupComponent = (
          <CustomFormikInput
            formik={formik}
            inputName={`shift.[${i}].pickup`}
            placeholder="ピックアップ担当者を入力してください"
          />
        );

        const quantityComponent = (
          <div className="quantity-wrapper">
            <span className="table_label">必要人員</span>
            {[
              {
                inputName: `shift.[${i}].directorQuantityTemp`,
                label: 'ディレクター',
                shiftPosition: 0,
              },
              {
                inputName: `shift.[${i}].adQuantityTemp`,
                label: 'アシスタントディレクター',
                shiftPosition: 1,
              },
              {
                inputName: `shift.[${i}].manQuantityTemp`,
                label: 'スタッフ(男性)',
                shiftPosition: 2,
              },
              {
                inputName: `shift.[${i}].womanQuantityTemp`,
                label: 'スタッフ(女性)',
                shiftPosition: 3,
              },
              {
                inputName: `shift.[${i}].unisexQuantityTemp`,
                label: 'スタッフ(性別不問)',
                shiftPosition: 4,
              },
            ].map(input => (
              <CustomFormikInput
                {...input}
                {...numberOptions}
                key={input.inputName}
                formik={formik}
                placeholder="0"
                usePopContainer={false}
                inputType="select"
                style={{
                  areaStyle: {
                    marginRight: 10,
                  },
                  inputStyle: {
                    width: 90,
                  },
                }}
              />
            ))}
          </div>
        );

        const noteComponent = (
          <CustomFormikInput
            formik={formik}
            inputName={`shift.[${i}].note`}
            label="備考"
            placeholder="備考を入力してください"
          />
        );

        const settingBtnComponent = (
          <Button
            text="設定する"
            className="assign_btn"
            disabled={
              ![
                directorQuantityTemp,
                adQuantityTemp,
                manQuantityTemp,
                womanQuantityTemp,
                unisexQuantityTemp,
              ].some(t => t > 0)
            }
            onClick={() => {
              try {
                const updateShiftAssign = [
                  directorQuantityTemp,
                  adQuantityTemp,
                  manQuantityTemp,
                  womanQuantityTemp,
                  unisexQuantityTemp,
                ].flatMap((q, m) => {
                  const quantity = q ?? 0;

                  const tempAssign = [...Array(quantity)].map(() => ({
                    shiftPosition: m,
                  }));

                  const shiftAssign = s.shiftAssign.filter(
                    a => a.shiftPosition === m
                  );

                  const [emptyAssign, _] = splitBy(shiftAssign, s =>
                    isNil(s.assignId)
                  );

                  if (
                    shiftAssign.length > quantity &&
                    shiftAssign.length - emptyAssign.length > quantity
                  ) {
                    dispatch(
                      globalActions.showSingleModal('空き枠が足りません')
                    );
                    throw new Error();
                  }

                  tempAssign.splice(
                    0,
                    Math.min(shiftAssign.length, quantity),
                    ...(shiftAssign.length < quantity
                      ? shiftAssign
                      : removeFromEnd([...shiftAssign], quantity))
                  );

                  return tempAssign;
                });

                handlerFormikFieldChange(formik, `shift.[${i}]`, {
                  ...s,
                  directorQuantity: directorQuantityTemp,
                  adQuantity: adQuantityTemp,
                  manQuantity: manQuantityTemp,
                  womanQuantity: womanQuantityTemp,
                  unisexQuantity: unisexQuantityTemp,
                  shiftAssign: updateShiftAssign,
                });
              } catch (err) {}
            }}
          />
        );

        const plannedPayment = numberConvert(
          `${payBaseFlag}` === '0'
            ? ((diffMinute(workStartDatetime, workEndDatetime) -
                (restMinute ?? 0)) *
                toInteger(hourlyPayBase)) /
                60
            : toInteger(dailyPayBase),
          calculationPayFlag
        );

        const assignDetailComponent = (
          <>
            {s.shiftAssign.map((assign, j) => (
              <ShiftAssignCell
                assign={assign}
                s={s}
                i={i}
                j={j}
                formik={formik}
              />
            ))}
            <StaffSearchModal
              visible={s.staffSearchModalVisible}
              index={i}
              shiftId={s.shiftId}
              plannedPayment={plannedPayment}
              formik={formik}
              onCancel={() => {
                handlerFormikFieldChange(
                  formik,
                  `shift.[${i}].staffSearchModalVisible`,
                  false
                );

                dispatch(matterDateActions.clearStaffList());
              }}
            />
            <UserSearchModal
              visible={s.userSearchModalVisible}
              index={i}
              formik={formik}
              onCancel={() =>
                handlerFormikFieldChange(
                  formik,
                  `shift.[${i}].userSearchModalVisible`,
                  false
                )
              }
            />
            <PartnerSearchModal
              visible={s.partnerSearchModalVisible}
              index={i}
              formik={formik}
              onCancel={() =>
                handlerFormikFieldChange(
                  formik,
                  `shift.[${i}].partnerSearchModalVisible`,
                  false
                )
              }
            />
          </>
        );

        return isSp ? (
          <>
            <div className="base-table-sp">
              <Descriptions column={2} colon={false}>
                <Descriptions.Item span={2} label={shiftNameComponent} />
                <Descriptions.Item span={2} label="基本給(円)">
                  {basePayComponent}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="請求金額(1名分/円)">
                  {payAmountComponent}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="駅到着時間">
                  {arriveDatetimeComponet}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="集合時間">
                  {gatheringDatetimeComponent}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="就業時間" />
                <Descriptions.Item span={2} label="">
                  {workDatetimeComponent}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="休憩時間(分)">
                  {restMinuteComponent}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="ピックアップ担当者" />
                <Descriptions.Item span={2} label="">
                  {pickupComponent}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="">
                  {quantityComponent}
                  {noteComponent}
                  {settingBtnComponent}
                </Descriptions.Item>
              </Descriptions>
            </div>
            {s.shiftAssign?.length > 0 && assignDetailComponent}
          </>
        ) : (
          <div key={i} className="assign_setting--table_container">
            {shiftNameComponent}
            <table className="assign_setting--base_table">
              <thead>
                <tr>
                  <th>基本給(円)</th>
                  <th>請求金額(1名分/円)</th>
                  <th>駅到着時間</th>
                  <th>集合時間</th>
                  <th>就業時間</th>
                  <th>休憩時間(分)</th>
                  <th>ピックアップ担当者</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pay-base-cell">{basePayComponent}</td>
                  <td>{payAmountComponent}</td>
                  <td>{arriveDatetimeComponet}</td>
                  <td>{gatheringDatetimeComponent}</td>
                  <td>{workDatetimeComponent}</td>
                  <td>{restMinuteComponent}</td>
                  <td>{pickupComponent}</td>
                  <td rowSpan="3" className="setting_btn">
                    {settingBtnComponent}
                  </td>
                </tr>
                <tr>
                  <td colSpan="8">{quantityComponent}</td>
                </tr>
                <tr>
                  <td colSpan="7">{noteComponent}</td>
                </tr>
              </tbody>
            </table>
            {s.shiftAssign?.length > 0 && (
              <table
                className={classNames('assign_setting--staff_table', {
                  'assign_setting--staff_table_empty': s.shiftAssign.every(a =>
                    isNil(a.assignId)
                  ),
                })}
              >
                <thead>
                  <tr>
                    <th>No</th>
                    <th className="display-linebreak">{'報告書\n入力'}</th>
                    <th>スタッフ</th>
                    <th>通知日時</th>
                    <th>勤怠</th>
                    <th>残業</th>
                    <th>遅刻・早退</th>
                    <th>支払金額</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{assignDetailComponent}</tbody>
              </table>
            )}
          </div>
        );
      })}
      <AddLineButton
        title="行追加"
        onClick={() =>
          handlerFormikFieldChange(formik, 'shift', [
            ...shift,
            {
              includeFareFlag: 0,
              payBaseFlag: 0,
              shiftAssign: [],
            },
          ])
        }
      />
    </div>
  );
});

export default AssignSetting;
