import React, { useEffect } from 'react';
import {
  deleteArrayItem,
  handlerFormikFieldChange,
} from '../../../utils/fnUtil';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import StaffRegisterInfos from '../edit/inputInfos';
import DeleteIcon from '../../../components/deleteIcon';
import AddButton from '../../../components/addButton';
import { useSelector } from 'react-redux';

const MyPageForSpPage2 = ({ spFormik, currentPage }) => {
  const { nearestStations } = spFormik.values;
  const { holdingItemsList } = useSelector((state) => state.staff);

  const heightValue = () => {
    let height = [];
    [...Array(251)].map((_, index) => {
      if (index > 99) {
        height[index] = index;
      }
    });

    return height;
  };

  useEffect(() => {
    if (nearestStations.length === 0) {
      handlerFormikFieldChange(spFormik, 'nearestStations', [
        ...nearestStations,
        {},
      ]);
    }
  }, []);

  return (
    <div
      className={'myPageSp--info_base page2'}
      style={{ display: currentPage === 2 ? 'flex' : 'none' }}
    >
      <div className={'myPageSp--info_title'}>
        <p>
          <span></span>
          交通情報
        </p>
      </div>

      {nearestStations?.map((o, i) => {
        return (
          <div className={'myPageSp--access_box'} key={`access_box_${i}`}>
            <div className={'myPageSp--access_delete_btn'}>
              <DeleteIcon
                title="削除"
                onClick={() =>
                  handlerFormikFieldChange(
                    spFormik,
                    'nearestStations',
                    deleteArrayItem(nearestStations, i)
                  )
                }
              />
            </div>

            <CustomFormikInput
              formik={spFormik}
              {...StaffRegisterInfos.access.inputs[0]}
              inputName={`nearestStations.[${i}].stationName`}
              relativeInputName={[`nearestStations.[${i}]`]}
              className={{ areaClass: 'column' }}
              style={{
                areaStyle: { width: '95%', margin: '24px auto' },
              }}
            />

            <CustomFormikInput
              formik={spFormik}
              {...StaffRegisterInfos.access.inputs[1]}
              inputName={`nearestStations.[${i}].timeRequired`}
              relativeInputName={[`nearestStations.[${i}]`]}
              className={{ areaClass: 'column' }}
              style={{
                areaStyle: { width: '50%', margin: '24px auto 24px 2.5%' },
              }}
              allowClear={false}
            />

            <div data-num={i}>
              <CustomFormikInput
                formik={spFormik}
                {...StaffRegisterInfos.access.inputs[2]}
                inputName={`nearestStations.[${i}].wayStation`}
                relativeInputName={[`nearestStations.[${i}]`]}
                className={{ areaClass: 'column' }}
                style={{
                  areaStyle: { width: '95%', margin: '24px auto 0' },
                }}
                allowClear={false}
              />
              <CustomFormikInput
                formik={spFormik}
                {...StaffRegisterInfos.access.inputs[3]}
                inputName={`nearestStations.[${i}].busStationNameFrom`}
                relativeInputName={[`nearestStations.[${i}]`]}
                disabled={nearestStations[i]?.wayStation !== 1}
                style={{
                  areaStyle: { width: '95%', margin: '16px auto 0' },
                }}
              />
              <span
                style={{
                  height: '24px',
                  width: '100%',
                  display: 'inline-block',
                  textAlign: 'center',
                  lineHeight: '24px',
                  color:
                    nearestStations[i]?.wayStation !== 1
                      ? '#bcbcbc'
                      : '#242424',
                }}
              >
                〜
              </span>
              <CustomFormikInput
                formik={spFormik}
                {...StaffRegisterInfos.access.inputs[4]}
                inputName={`nearestStations.[${i}].busStationNameTo`}
                relativeInputName={[`nearestStations.[${i}]`]}
                disabled={nearestStations[i]?.wayStation !== 1}
                style={{
                  areaStyle: { width: '95%', margin: '0 auto' },
                }}
              />
            </div>
          </div>
        );
      })}

      <AddButton
        type={'round'}
        onClick={() =>
          handlerFormikFieldChange(spFormik, 'nearestStations', [
            ...nearestStations,
            {},
          ])
        }
        disabled={nearestStations.length > 4}
      />

      <div className={'myPageSp--info_title'}>
        <p>
          <span></span>
          身長・体重など
        </p>
      </div>

      <CustomFormikInput
        inputType={'select'}
        showSearch={false}
        allowClear={true}
        inputName={'height'}
        labelRequired={true}
        label={'身長(cm)'}
        initialValue={heightValue()}
        selectBoxOptions={heightValue()}
        placeholder={'155'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '55%' },
        }}
        unit={'cm'}
      />

      <CustomFormikInput
        inputType={'input'}
        inputName={'weight'}
        label={'体重(kg)'}
        placeholder={'0'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '55%' },
        }}
        unit={'kg'}
      />

      <CustomFormikInput
        inputType={'input'}
        inputName={'shoeSize'}
        label={'靴のサイズ(cm)'}
        placeholder={'0'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '55%' },
        }}
        unit={'cm'}
      />

      <CustomFormikInput
        inputType={'select'}
        showSearch={false}
        allowClear={true}
        inputName={'wearSize'}
        label={'服のサイズ'}
        initialValue={[0, 1, 2, 3, 4, 5, 6]}
        selectBoxOptions={['XS', 'S', 'M', 'L', 'XL', '2L', '3L']}
        placeholder={'M'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '55%' },
        }}
        unit={'  '}
      />

      <CustomFormikInput
        inputType={'select'}
        showSearch={false}
        allowClear={false}
        mode={'multiple'}
        inputName={'holdingItems'}
        label={'持っているもの'}
        initialValue={holdingItemsList?.map(h=>h?.value)}
        selectBoxOptions={holdingItemsList?.map(h=>h?.label)}
        placeholder={'選択してください'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '100%' },
        }}
      />
    </div>
  );
};

export default MyPageForSpPage2;
