import { useSelector } from 'react-redux';
import loadingGif from '../../images/icon/load-b.gif';
import './style.scss';

const Loading = () => {
  const { loading, multiLoading, primaryLoading } = useSelector(
    state => state.global
  );

  return (
    <>
      {(loading || multiLoading || primaryLoading) && (
        <div className="gw-loading">
          <div>
            <img src={loadingGif} className="loading-image" />
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
