import { memo } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import BirthdaySelector from "../../../components/birthdaySelector";
import StaffRegisterInfos from "../inputInfos";
import prefectures from "../../../constants/prefectures";
import {handleRadio} from "./functions";
import React, { useEffect } from 'react';
import {fetchRequest} from "../../../api/fetch";
import {apiUrl} from "../../../api/apiUrl";
import {deleteArrayItem, handlerFormikFieldChange} from "../../../utils/fnUtil";
import {useSelector} from "react-redux";
import CustomInput from "../../../components/customInput";
import Icons from "../../../constants/icons";
import AddButton from "../../../components/addButton";
import DeleteIcon from "../../../components/deleteIcon";
import { checkIsSp } from '../../../utils/fnUtil';

const AccessInfos = memo(({ formik }) => {
    const {nearestStations} = formik.values;

  return(
      <div className={'staff_register--access_infos_area'}>
          {nearestStations?.map((_, i) => (
              <div className={'staff_edit--access_unity_area'}>
                  <div className={'staff_edit--access_input_button_area'}>
                      {checkIsSp() ? (
                        <><div className={'staff_edit--delete_button_area'}>
                              <DeleteIcon
                                  title="削除"
                                  onClick={() => handlerFormikFieldChange(
                                      formik,
                                      'nearestStations',
                                      deleteArrayItem(nearestStations, i)
                                  )} />
                          </div><CustomFormikInput
                                  formik={formik}
                                  {...StaffRegisterInfos.accessInfos.inputs[0]}
                                  inputName={`nearestStations.[${i}].stationName`}
                                  relativeInputName={['stationName']} /></>
                      ):
                      (<><CustomFormikInput
                              formik={formik}
                              {...StaffRegisterInfos.accessInfos.inputs[0]}
                              inputName={`nearestStations.[${i}].stationName`}
                              relativeInputName={['stationName']} /><div className={'staff_edit--delete_button_area'}>
                                  <DeleteIcon
                                      title="削除"
                                      onClick={() => handlerFormikFieldChange(
                                          formik,
                                          'nearestStations',
                                          deleteArrayItem(nearestStations, i)
                                      )} />
                              </div></>)}
                  </div>

                  <CustomFormikInput
                      formik={formik}
                      {...StaffRegisterInfos.accessInfos.inputs[1]}
                      inputName={`nearestStations.[${i}].timeRequired`}
                      relativeInputName={['timeRequired']}
                  />


                  <div className={'staff_edit--access_input_area'} data-num={i}>
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.accessInfos.inputs[2]}
                          inputName={`nearestStations.[${i}].wayStation`}
                          relativeInputName={['wayStation']}
                      />
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.accessInfos.inputs[3]}
                          inputName={`nearestStations.[${i}].busStationNameFrom`}
                          relativeInputName={['busStationNameFrom']}
                          disabled={nearestStations[i]?.wayStation !== 1}
                      />
                      <CustomFormikInput
                          formik={formik}
                          {...StaffRegisterInfos.accessInfos.inputs[4]}
                          inputName={`nearestStations.[${i}].busStationNameTo`}
                          relativeInputName={['busStationNameTo']}
                          disabled={nearestStations[i]?.wayStation !== 1}
                      />
                  </div>
              </div>
          ))}

          <AddButton
              type={'staff_edit--add_button'}
              onClick={() =>
                  handlerFormikFieldChange(formik, 'nearestStations', [...nearestStations, {}])
              }
              disabled={nearestStations.length > 4}
          />
      </div>
  )
});
export default AccessInfos;
