import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../components/button';
import Options from '../../../../../constants/options';
import './style.scss';

const PayTotal = props => {
  const { dataSource } = props;

  const navigate = useNavigate();

  //コンマを入れる
  const toThousands = num => {
    let isMinus = num < 0;

    if (isMinus) {
      num = num * -1;
    }
    var num = (num || 0).toString(),
      result = '';
    while (num.length > 3) {
      result = ',' + num.slice(-3) + result;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }

    if (isMinus) {
      result = '▲' + result;
    }
    return result;
  };

  const colWid = 120;
  const columnAdd = [
    {
      title: '支給',
      key: 'payment',
      render: () => (
        <div className="pay_total--table--column--payment">支給</div>
      ),
      width: 36,
    },
    {
      title: '給与手当',
      dataIndex: 'salaryAllowance',
      key: 'salaryAllowance',
      width: colWid,
      render: record => {
        return toThousands(record) + '円';
      },
    },
    {
      title: '立替金',
      dataIndex: 'advanceMoney',
      key: 'advanceMoney',
      width: colWid,
      render: record => {
        return toThousands(record) + '円';
      },
    },
    { title: '', key: 'paymentNull' },
  ];
  const dataAdd = [
    {
      key: '1',
      salaryAllowance: dataSource.payTotalAmount,
      advanceMoney: dataSource.advancePayTotalAmount,
    },
  ];

  const columnMin = [
    {
      title: '控除',
      key: 'subsidy',
      render: () => (
        <div className="pay_total--table--column--payment">控除</div>
      ),
      width: 36,
    },
    {
      title: '所得税',
      dataIndex: 'incomeTax',
      key: 'incomeTax',
      width: colWid,
      render: record => {
        return toThousands(record) + '円';
      },
    },
    {
      title: '前払金',
      dataIndex: 'advancePayment',
      key: 'advancePayment',
      width: colWid,
      render: record => {
        return toThousands(record) + '円';
      },
    },
    { title: '', key: 'subsidyNull' },
  ];
  const dataMin = [
    {
      key: '1',
      incomeTax: dataSource.incomeTaxAmount,
      advancePayment: dataSource.beforePayTotalAmount,
    },
  ];
  const addMinTotal =
    dataAdd[0].salaryAllowance * 1 +
    dataAdd[0].advanceMoney * 1 -
    dataMin[0].incomeTax * 1 -
    dataMin[0].advancePayment * 1;

  return (
    <>
      {/*支給*/}
      <div className={'pay_detail_sp--diligence_small_total'}>
        <div className={''}>{columnAdd[0].title}</div>
      </div>
      {/*給与手当*/}
      <div className={'pay_detail_sp--diligence_title'}>
        <div className={''}>{columnAdd[1].title}</div>
      </div>
      <div
        className={'pay_detail_sp--diligence_contents'}
        style={{ height: '80px' }}
      >
        <div className={''}>
          {columnAdd[1].render(dataAdd[0].salaryAllowance)}
        </div>
      </div>
      {/*立替金*/}
      <div className={'pay_detail_sp--diligence_title'}>
        <div className={''}>{columnAdd[2].title}</div>
      </div>
      <div
        className={'pay_detail_sp--diligence_contents'}
        style={{ height: '80px' }}
      >
        <div className={''}>{columnAdd[2].render(dataAdd[0].advanceMoney)}</div>
      </div>

      {/*控除*/}
      <div className={'pay_detail_sp--diligence_small_total'}>
        <div className={''}>{columnMin[0].title}</div>
      </div>
      {/*所得税*/}
      <div className={'pay_detail_sp--diligence_title'}>
        <div className={''}>{columnMin[1].title}</div>
      </div>
      <div
        className={'pay_detail_sp--diligence_contents'}
        style={{ height: '80px' }}
      >
        <div className={''}>{columnMin[1].render(dataMin[0].incomeTax)}</div>
      </div>
      {/*前払金*/}
      <div className={'pay_detail_sp--diligence_title'}>
        <div className={''}>{columnMin[2].title}</div>
      </div>
      <div
        className={'pay_detail_sp--diligence_contents'}
        style={{ height: '80px' }}
      >
        <div className={''}>
          {columnMin[2].render(dataMin[0].advancePayment)}
        </div>
      </div>
      <div
        className={'pay_detail_sp--diligence_contents_list_border_last'}
      ></div>

      {/*差引支払合計*/}
      <div className={'pay_detail_sp--diligence_small_total'}>
        <div className={''}>{'差引支払合計'}</div>
      </div>
      <div
        className={'pay_detail_sp--diligence_contents'}
        style={{ height: '80px' }}
      >
        <div className={''}>{toThousands(addMinTotal) + '円'}</div>
      </div>
      <div
        className={'pay_detail_sp--diligence_contents_list_border_last'}
      ></div>

      <div className={'bottom_btn_area'} style={{ height: '92px' }}>
        <div className={'quote_register--submit_buttons'}>
          <Button
            text={'戻る'}
            style={Options.buttonStyles.stampBack}
            onClick={() => {
              navigate('/staff-pay/list');
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PayTotal;
