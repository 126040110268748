import { useNavigate } from 'react-router-dom';
import './style.scss';
import React, { useEffect, useState } from 'react';
import StaffPlanShiftInfos from './inputInfos';
import Button from '../../../components/button';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
  staffPlanDetailUpdate,
  staffPlanDetailFetch,
  staffPlanActions,
} from '../../../redux/slice/staffPlan';
import {
  handlerFormikSubmit,
  handlerFormikFieldChange,
  checkIsSp,
} from '../../../utils/fnUtil';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import ShiftPlanForSp from '../forSp';
import StaffShiftDateList from '../edit/StaffShiftDateList';
import Yup from '../../../utils/yupUtil';
import moment from 'moment';
import { globalActions } from '../../../redux/slice/global';
import { quoteActions } from '../../../redux/slice/quote';
import ConfirmModal from '../../../components/modal/confirmModal';

const StaffPlanRegister = () => {
  const navigate = useNavigate();
  const { searchParams, addDateFlag, reduceDateFlag, shiftFlag } = useSelector(
    state => state.staffPlan
  );
  const loginType = useSelector(state => state.loginType.type);
  const { staffId, shiftStyleFlag } = useSelector(state => state.account);
  const [shiftsData, saveShiftsData] = useState([]);

  const isSp = checkIsSp();
  const dispatch = useDispatch();

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  //表示ボタンformik
  const searchFormik = useFormik({
    initialValues: {
      search_year: parseInt(searchParams.yearMonth.slice(0, 4)),
      search_month: parseInt(searchParams.yearMonth.slice(-2)),
    },
    enableReinitialize: true,
    onSubmit: values => {
      handlerFormikFieldChange(updateFormik, 'date', {
        year: parseInt(values.search_year),
        month: parseInt(values.search_month),
      });
    },
  });
  const updateFormik = useFormik({
    initialValues: {
      assign: 0,
      lastSubmitDatetime: '',
      statusFlag: 0,
      corpShiftStyleFlag: shiftStyleFlag,
      date: {
        year: parseInt(searchParams.yearMonth.slice(0, 4)),
        month: parseInt(searchParams.yearMonth.slice(-2)),
      },
      shifts: [
        ...Array(
          new Date(
            parseInt(searchParams.yearMonth.slice(0, 4)),
            parseInt(searchParams.yearMonth.slice(-2)),
            0
          ).getDate()
        ).map((_, idx) => {
          return {
            date: idx + 1,
            enableAssignFlag: 0,
            shiftStyleFlag: 0,
            startTime: null,
            endTime: null,
            staffNote: null,
            managerNote: null,
          };
        }),
      ],
      beforeEditShifts: [
        ...Array(
          new Date(
            parseInt(searchParams.yearMonth.slice(0, 4)),
            parseInt(searchParams.yearMonth.slice(-2)),
            0
          ).getDate()
        ).map((_, idx) => {
          return {
            date: idx + 1,
            enableAssignFlag: 0,
            shiftStyleFlag: 0,
            startTime: null,
            endTime: null,
            staffNote: null,
            managerNote: null,
          };
        }),
      ],
      workShifts: [],
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      shifts: Yup.array().of(
        Yup.object({
          startTime: Yup.string().nullable().label('開始時間'),
          endTime: Yup.string().nullable().label('終了時間'),
          shiftRange: Yup.string()
            .nullable()
            .test(
              'dateInvalid',
              '時間:開始時間は終了時間より前の時間を入力してください。',
              (_, context) => {
                const { shiftStyleFlag, startTime, endTime } =
                  context.from[0].value;
                if (
                  shiftStyleFlag == 0 ||
                  (startTime == null && endTime == null)
                ) {
                  return true;
                }
                return (
                  shiftStyleFlag == 1 &&
                  startTime &&
                  endTime &&
                  moment(endTime, 'HH:mm').isAfter(moment(startTime, 'HH:mm'))
                );
              }
            )
            .test(
              'dateInvalid',
              '時間:時間指定の場合時間を入力してください。',
              (_, context) => {
                const { shiftStyleFlag, startTime, endTime } =
                  context.from[0].value;
                return !(
                  shiftStyleFlag == 1 &&
                  startTime == null &&
                  endTime == null
                );
              }
            ),
          staffNote: Yup.string().nullable().max(31).label('スタッフ備考'),
        })
      ),
    }),
    onSubmit: values => {
      const shifts = values.shifts
        ?.filter(s => s?.changeFlag == true)
        .map(s => {
          return {
            ...s,
            year: values.date.year,
            month: values.date.month,
            staffId: staffId,
          };
        });
      const nonShift = [
        {
          year: date.year,
          month: date.month,
          date: 1,
          staffId: staffId,
          enableAssignFlag: 0,
          shiftStyleFlag: 0,
          startTime: null,
          endTime: null,
          staffNote: null,
          managerNote: null,
        },
      ];
      dispatch(
        staffPlanDetailUpdate({
          yearMonth: date.year + '/' + String(date.month).padStart(2, '0'),
          staffId: staffId,
          status: shiftFlag ? 0 : 1,
          shifts:
            shifts.length == 0 && values.statusFlag == 0 ? nonShift : shifts,
        })
      )
        .unwrap()
        .then(result => {
          if (result.code == '0') {
            dispatch(
              globalActions.showSingleModal(
                `シフトを${shiftFlag ? '一時保存' : '提出'}しました`
              )
            );
            dispatch(staffPlanActions.setShiftFlag(false));
            navigate('/');
          }
          dispatch(staffPlanActions.setShiftFlag(false));
        });
    },
  });
  const { date, workShifts } = updateFormik.values;

  // 前月次月をクリックしたとき
  useEffect(() => {
    dispatch(
      staffPlanDetailFetch({
        yearMonth: date.year + '/' + String(date.month).padStart(2, '0'),
        staffId: staffId,
      })
    )
      .unwrap()
      .then(result => {
        handlerFormikFieldChange(
          updateFormik,
          'statusFlag',
          result?.detail?.statusFlag
        );
        handlerFormikFieldChange(
          updateFormik,
          'lastSubmitDatetime',
          result?.detail?.lastSubmitDatetime
        );
        handlerFormikFieldChange(
          updateFormik,
          'workShifts',
          [...Array(new Date(date.year, date.month, 0).getDate())].map(
            (_, idx) => {
              let ch = document.getElementById(
                `shifts.[${idx}].enableAssignFlag`
              );
              if (result?.detail?.shifts?.some(rs => rs?.date === idx + 1)) {
                ch.checked = result?.detail?.shifts?.some(
                  rs => rs?.date === idx + 1 && rs.enableAssignFlag == 1
                )
                  ? true
                  : false;
                return {
                  ...result?.detail?.shifts?.find(rs => rs?.date === idx + 1),
                  changeFlag: false,
                };
              } else {
                if (ch?.checked) {
                  ch.checked = false;
                }
                return {
                  date: idx + 1,
                  enableAssignFlag: 0,
                  shiftStyleFlag: 0,
                  startTime: null,
                  endTime: null,
                  staffNote: null,
                  managerNote: null,
                  changeFlag: false,
                };
              }
            }
          )
        );

        updateFormik.setFieldValue(
          'beforeEditShifts',
          [...Array(new Date(date.year, date.month, 0).getDate())].map(
            (_, idx) => {
              let ch = document.getElementById(
                `shifts.[${idx}].enableAssignFlag`
              );
              if (result?.detail?.shifts?.some(rs => rs?.date === idx + 1)) {
                ch.checked = result?.detail?.shifts?.some(
                  rs => rs?.date === idx + 1 && rs.enableAssignFlag == 1
                )
                  ? true
                  : false;
                return {
                  ...result?.detail?.shifts?.find(rs => rs?.date === idx + 1),
                  changeFlag: false,
                };
              } else {
                if (ch?.checked) {
                  ch.checked = false;
                }
                return {
                  date: idx + 1,
                  enableAssignFlag: 0,
                  shiftStyleFlag: 0,
                  startTime: null,
                  endTime: null,
                  staffNote: null,
                  managerNote: null,
                  changeFlag: false,
                };
              }
            }
          )
        );
      });
  }, [date]);

  // ちゃんと処理する
  useEffect(() => {
    workShifts.length > 0 &&
      handlerFormikFieldChange(updateFormik, 'shifts', workShifts);
    saveShiftsData(workShifts);
  }, [workShifts]);

  const buttonStyle = {
    updateButton: {
      width: '240px',
      height: '44px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    backButton: {
      width: '240px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
      color: '#0056D3',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
      border: '1px solid #0056D3',
    },
    searchButton: {
      width: '240px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
  };

  return (
    <>
      <div className={'shift_forPc'}>
        <div className={'staff_plan_register--search_area'}>
          <div className={'staff_plan_register--input_area'}>
            <CustomFormikInput
              formik={searchFormik}
              {...StaffPlanShiftInfos.search[0]}
            />
            <CustomFormikInput
              formik={searchFormik}
              {...StaffPlanShiftInfos.search[1]}
            />
          </div>
          <div className={'staff_plan_register--button_area'}>
            <Button
              text={'表示'}
              style={buttonStyle.searchButton}
              onClick={() => {
                handlerFormikSubmit(searchFormik);
              }}
            />
          </div>
        </div>
        <div className={'staff_plan--edit_page'}>
          <StaffShiftDateList formik={updateFormik} staff={staffId} shiftsData={shiftsData} />
        </div>
        <div
          className={'bottom_btn_area'}
          style={{ height: '92px', justifyContent: 'center' }}
        >
          {/*<div className={'staff_register--submit_buttons'}>*/}
            {/*<Button*/}
            {/*  text={'一時保存'}*/}
            {/*  style={buttonStyle.backButton}*/}
            {/*  onClick={() => {*/}
            {/*    dispatch(staffPlanActions.setShiftFlag(true));*/}
            {/*    handlerFormikSubmit(updateFormik);*/}
            {/*  }}*/}
            {/*/>*/}
            <Button
              text={'確定'}
              style={buttonStyle.updateButton}
              onClick={() => setConfirmModalVisible(true)}
            />
          {/*</div>*/}
        </div>
      </div>

      <ConfirmModal
        modalVisible={confirmModalVisible}
        onOk={() => {
          setConfirmModalVisible(false);
          handlerFormikSubmit(updateFormik);
        }}
        onCancel={() => {
          setConfirmModalVisible(false);
        }}
        title={'シフトを提出しますか？'}
        message={'一度出勤可能にした日程は、提出後に変更できません。'}
        onOkMessage={'提出'}
      />

      {loginType === 'staff' && isSp && (
        <div className={'shift_forSp'}>{<ShiftPlanForSp shiftsData={shiftsData} />}</div>
      )}
    </>
  );
};

export default StaffPlanRegister;
