import { createSlice, createAsyncThunk, legacy_createStore } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';

const moment = require('moment');

const searchParams = {
  yearMonthFrom: moment(new Date(), 'YYYY-M')
    .year(moment().year() - 1)
    .format('YYYY/M'),
  yearMonthTo: moment(new Date(), 'YYYY-M')
    .month(moment().month() - 1)
    .format('YYYY/M'),
  deptId: [],
  managerUserId: [],
  clientId: null,
  sort:'matterNameDesc',
  sortColumn: 'matterName',
  sortMethod: 'Desc',
  count: 20,
  offset: 0,
};

const searchStaffParams = {
  yearMonthFrom: moment(new Date(), 'YYYY-M')
    .year(moment().year() - 1)
    .format('YYYY/M'),
  yearMonthTo: moment(new Date(), 'YYYY-M')
    .month(moment().month() - 1)
    .format('YYYY/M'),
  deptId: [],
  managerUserId: [],
};

const initialState = {
  salesList: [],
  searchParams: searchParams,
  searchStaffParams: searchStaffParams,
  selOpt: {},
  deptList: [],
  retireReasonList: [],
  userList: [],
  staffAnalyze: [],
  shiftAnalyze: [],
  retireAnalyze: [],
  arrangeAnalyzeY: [],
  arrangeAnalyzeX: [],
  profitGraphX: [],
  profitGraphY: [],
  profitList: [],
  csvExportVisible: false,
};

export const analyzeGraphList = createAsyncThunk('analyse/profit/list', async params => {
  const [profitGraphRes, profitListRes] = await fetchMultiRequest([
    fetchRequest(apiUrl.analyse.salesGraph, params ? params : searchParams),
    fetchRequest(apiUrl.analyse.salesList, params ? params : searchParams),
  ]);

  const profitGraphX = () => {
    let objAry = [];
    profitGraphRes?.sales?.sort((a,b) => (a.yearMonth < b.yearMonth) ? -1 : 1)?.forEach((obj) => {
      for (let i = 0; i < 2; i++) {
        let type = '';
        let value = 0;

        if (i === 0) {
          type = '当年累計売上'
          value = obj.currentYearTotalSales
        } else {
          type = '前年累計売上'
          value = obj.beforeYearTotalSales
        }

        objAry.push({yearMonth: moment(obj.yearMonth, 'YYYY-M').format('YYYY年M月'), type, value})
      }
    });
    return objAry
  };

  const profitGraphY = () => {
    let objAry = [];
    profitGraphRes?.sales?.sort((a,b) => (a.yearMonth < b.yearMonth) ? -1 : 1)?.forEach((obj) => {
      for (let i = 0; i < 3; i++) {
        let name = '';
        let count = 0;

        if (i === 0) {
          name = '予算(当年)'
          count = obj.currentYearQuote
        } else if (i === 1) {
          name = '売上(当年)'
          count = obj.currentYearSales
        } else {
          name = '売上(前年)'
          count = obj.beforeYearSales ?? 0 //値がない
        }

        objAry.push({yearMonth: moment(obj.yearMonth, 'YYYY-M').format('YYYY年M月'), name, count})
      }
    });
    return objAry
  };

  const item = profitListRes?.item?.map(d=>{
    return {
      matterName: d?.matterName,
      salesManagerUserName: `${d?.salesManagerUserName?.lastName + d?.salesManagerUserName?.firstName}`,
      matterManagerUserName: `${d?.matterManagerUserName?.lastName + d?.matterManagerUserName?.firstName}`,
      quoteAmount: `${d?.quoteAmount?.toLocaleString()}円`,
      actualAmount: `${d?.actualAmount?.toLocaleString()}円`,
      staffCount: `${d?.staffCount?.toLocaleString()}人`,
      userCount: `${d?.userCount?.toLocaleString()}人`,
      partnerCount: `${d?.partnerCount?.toLocaleString()}人`,
      rate: `${d?.rate}%`,
    }
  });

  return {
    profitGraphX: profitGraphX(),
    profitGraphY: profitGraphY(),
    profitList: {
      item: item ?? [],
      count: profitListRes?.searchCount ?? 0,
      totalCount: profitListRes?.searchCount ?? 0,
      activeCount: profitListRes?.searchCount ?? 0,
    },
  }
});

export const analyzeStaffInit = createAsyncThunk('analyse/staff/list', async params => {
  const [namingRes, userRes, clientRes] = await fetchMultiRequest([
    fetchRequest(apiUrl.naming.list, {
      namingClassificationId: 'Dept,RetireReason',
      sort: 'namingIdAsc'
    }),
    fetchRequest(apiUrl.user.list, {
      sort: 'userCodeAsc',
    }),
    fetchRequest(apiUrl.client.list, {
      sort: 'clientIdAsc',
    }),
  ]);

  const deptList = namingRes?.item?.filter(
    n => n.namingClassificationId === 'Dept' &&
    n.status !== 2
  )?.map(d => {
    return {
      val: Number(d?.namingId),
      text: `${d?.namingId}:${d?.naming}`,
    }
  });

  const retireReasonList = namingRes?.item?.sort((a, b) => a.priority - b.priority)?.filter(
    n => n.namingClassificationId === 'RetireReason' &&
    n.status !== 2
  )?.map(d => d.naming);

  const userList = userRes?.item?.filter(c => c.status !== 2)?.map(u => {
    return {
      val: Number(u?.userId),
      text: `${u.userId}:${u?.name?.lastName + u?.name?.firstName}`,
    }
  });

  const clientList = clientRes?.item?.filter(c => c.status !== 2)?.map(c => {
    return {
      val: Number(c?.clientId),
      text: `${c.clientId}:${c?.clientName}`,
    }
  });

  return {
    deptList,
    userList,
    clientList,
    retireReasonList,
  };
});

export const analyzeStaffList = createAsyncThunk('analyze/trend', async params => {
  const [analyzeStaffRes, retireReasonRes] = await fetchMultiRequest([
    fetchRequest(apiUrl.analyse.staff, params ? params : searchStaffParams),
    fetchRequest(apiUrl.naming.list, {
      namingClassificationId: 'RetireReason',
      sort: 'namingIdAsc'
    }),
  ]);

  const staffAnalyze = () => {
    let objAry = [];
    analyzeStaffRes?.staffTransition?.sort((a,b) => (a.yearMonth < b.yearMonth) ? -1 : 1)?.forEach((obj) => {
      for (let i = 0; i < 3; i++) {
        let type = '';
        let value = 0;

        if (i === 0) {
          type = '退職'
          value = obj.retirement
        } else if (i === 1) {
          type = '新規'
          value = obj.newcomer
        } else {
          type = '既存'
          value = obj.existent
        }

        objAry.push({yearMonth: moment(obj.yearMonth, 'YYYY-M').format('YYYY年M月'), type, value})
      }
    });
    return objAry
  };

  const shiftAnalyze = () => {
    let objAry = [];
    analyzeStaffRes?.staffShift?.sort((a,b) => (a.yearMonth < b.yearMonth) ? -1 : 1)?.forEach((obj) => {
      for (let i = 0; i < 3; i++) {
        let type = '';
        let value = 0;

        if (i === 0) {
          type = '在籍スタッフ'
          value = obj.staff
        } else if (i === 1) {
          type = 'シフト'
          value = obj.shiftCount
        } else {
          type = 'シフト確定'
          value = obj.shiftConfirm
        }

        objAry.push({yearMonth: moment(obj.yearMonth, 'YYYY-M').format('YYYY年M月'), type, value})
      }
    });
    return objAry
  };

  const retireAnalyze = () => {
    let objAry = [];
    let res = analyzeStaffRes?.retirementReason;
    const retireReasonList = retireReasonRes?.item?.sort((a, b) => a.priority - b.priority)?.filter(
      n => n.status !== 2)?.map(d => d.naming);

    let newRes = res.map(obj => { //離職理由のデータに離職理由の表示順を追加する
      return {
        ...obj,
        priority: retireReasonList.indexOf(obj.retirementText) >! 0 ? retireReasonList.indexOf(obj.retirementText) : null,
      }
    });
    
    newRes?.sort((a,b) => {
      if (a.yearMonth < b.yearMonth) return -1;
      if (a.yearMonth > b.yearMonth) return 1;
      if (a.priority < b.priority) return -1;
      if (a.priority > b.priority) return 1;
      return 0;
    })?.forEach(obj => {
      objAry.push({
        yearMonth: moment(obj.yearMonth, 'YYYY-M').format('YYYY年M月'),
        type: obj.retirementText,
        value: obj.retirementCount
      })
    });

    return objAry
  };

  const arrangeAnalyzeY = () => {
    let objAry = [];
    analyzeStaffRes?.arrangementRate?.sort((a,b) => (a.yearMonth < b.yearMonth) ? -1 : 1)?.forEach((obj) => {
      for (let i = 0; i < 3; i++) {
        let type = '';
        let value = 0;

        if (i === 0) {
          type = '協力会社'
          value = obj.partnerCount
        } else if (i === 1) {
          type = '社員'
          value = obj.userCount
        } else {
          type = '自社スタッフ'
          value = obj.staffCount
        }

        objAry.push({yearMonth: moment(obj.yearMonth, 'YYYY-M').format('YYYY年M月'), type, value})
      }
    });
    return objAry
  };

  const arrangeAnalyzeX = () => {
    let objAry = [];
    analyzeStaffRes?.arrangementRate?.sort((a,b) => (a.yearMonth < b.yearMonth) ? -1 : 1)?.forEach((obj) => {
      objAry.push({yearMonth: moment(obj.yearMonth, 'YYYY-M').format('YYYY年M月'), count: obj.rate})
    });
    return objAry
  };

  return {
    staffAnalyze: staffAnalyze(),
    shiftAnalyze: shiftAnalyze(),
    retireAnalyze: retireAnalyze(),
    arrangeAnalyzeX: arrangeAnalyzeX(),
    arrangeAnalyzeY: arrangeAnalyzeY(),
  }
});

export const analyzeSlice = createSlice({
  name: 'analyze',
  initialState: initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveSearchStaffParams: (state, { payload }) => ({
      ...state,
      searchStaffParams: {
        ...state.searchStaffParams,
        ...payload,
        offset: 0,
      },
    }),
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    setSearch: state => {
      state.searchParams = { ...state.searchParams };
    },
    setStaffSearch: state => {
      state.searchStaffParams = { ...state.searchStaffParams };
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
  },
  extraReducers: {
    [analyzeStaffInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [analyzeStaffList.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [analyzeGraphList.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const analyzeActions = analyzeSlice.actions;
export default analyzeSlice.reducer;
