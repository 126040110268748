import { memo } from 'react';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import SwitchInput from '../../../../components/switch/switchInput';
import './style.scss';

const PerformanceInformation = memo(({ formik }) => {
  const numberOptions = [...Array(1001).keys()];
  return (
    <div className="tab_content">
      {[
        {
          item: '案件名',
          reportInputName: 'info.reportMatterNameFlag',
          staffInputName: 'info.staffMatterNameFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder="案件名を入力してください"
              inputName="info.matterName"
            />
          ),
        },
        {
          item: '実施概要',
          reportInputName: 'info.reportEventAboutFlag',
          staffInputName: 'info.staffEventAboutFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder="実施概要を入力してください"
              inputName="info.eventAbout"
              inputType="textBox"
            />
          ),
        },
        {
          item: '実施目的',
          reportInputName: 'info.reportEventPurposeFlag',
          staffInputName: 'info.staffEventPurposeFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder="実施目的を入力してください"
              inputName="info.eventPurpose"
            />
          ),
        },
        {
          item: '実施日別天気・気温',
          reportInputName: 'info.reportEventWeatherFlag',
          toggleSize: 'default',
        },
        {
          item: '会場',
          reportInputName: 'info.reportVenueFlag',
          staffInputName: 'info.staffVenueFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder="会場を入力してください"
              inputName="info.venue"
            />
          ),
        },
        {
          item: '入場条件',
          reportInputName: 'info.reportAdmissionTermFlag',
          staffInputName: 'info.staffAdmissionTermFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder="入場条件を入力してください"
              inputName="info.admissionTerm"
            />
          ),
        },

        {
          item: '事前準備',
          reportInputName: 'info.reportStandbyFlag',
          staffInputName: 'info.staffStandbyFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder="事前準備を入力してください"
              inputName="info.standby"
            />
          ),
        },

        {
          item: '主導者',
          reportInputName: 'info.reportPresidentFlag',
          staffInputName: 'info.staffPresidentFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder="主導者を入力してください"
              inputName="info.president"
            />
          ),
        },

        {
          item: '運営統括',
          reportInputName: 'info.reportManagerLeaderFlag',
          staffInputName: 'info.staffManagerLeaderFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder="運営統括を入力してください"
              inputName="info.managerLeader"
            />
          ),
        },

        {
          item: '会場運営',
          reportInputName: 'info.reportManagerFlag',
          staffInputName: 'info.staffManagerFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder="会場運営を入力してください"
              inputName="info.manager"
            />
          ),
        },

        {
          item: 'スタッフ人数',
          reportInputName: 'info.reportStaffNumbersFlag',
          staffInputName: 'info.staffStaffNumbersFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder={'0'}
              inputType="input"
              inputName="info.staffNumbers"
              style={{
                areaStyle: { width: 103 },
              }}
              unit="人"
            />
          ),
        },
        {
          item: 'スタッフ服装',
          reportInputName: 'info.reportStaffWearFlag',
          staffInputName: 'info.staffStaffWearFlag',
          toggleSize: 'default',
          inputComponent: (
            <CustomFormikInput
              formik={formik}
              placeholder="スタッフ服装を入力してください"
              inputName="info.staffWear"
            />
          ),
        },
      ].map(item => (
        <SwitchInput {...item} formik={formik} key={item.item} />
      ))}
    </div>
  );
});

export default PerformanceInformation;
