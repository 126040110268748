import './style.scss';
import Icons from '../../constants/icons';
import { useEffect, useRef, useState } from 'react';

/*
<SearchSelectBox
        inputName={'test'}
        label={'test'}
        placeholder={'テストしてます'}
        optionValues={['aiueo', 'kakikukeko', 'sasisuseso', 'tatituteto', 'naninuneno']}
        optionTexts={['アイウエオaaaaaaaaa', 'かきくけこaaaaaaaaaa', 'さしすせそ', 'たちつてと', 'なにぬねの']}
        multiSelect={true}
        style={{input: {width: '500px'}}}
      />
 */

const SearchSelectBox = ({
  inputName,
  label,
  placeholder,
  optionValues,
  optionTexts,
  style,
  multiSelect,
  topLabel
}) => {
  const [isOpenUl, setOpenUl] = useState(false);
  const [searchedIndex, setSearchedIndex] = useState(
    optionValues.map((v, i) => i)
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  const optionsRef = useRef(null);
  optionsRef.current = selectedOptions;

  let inputElement;

  const searchOptions = e => {
    const reg = new RegExp(e.target.value.replace(/\s+/g, ''), 'i');

    let includes = optionTexts
      .map((text, index) => {
        return text.replace(/\s+/g, '').match(reg) ? index : '';
      })
      .filter(v => v || v === 0);

    setSearchedIndex(includes);
  };

  const inputFromSearchList = e => {
    inputElement.value = e.target.innerText;
    document.querySelector(`#${inputName}_selected_values`).value = e.target.id;

    e.target.style.fontWeight = 'bold';
    e.target.childNodes[1].style.opacity = '1';

    const lis = e.target.parentNode.childNodes;

    lis.forEach((elm, index) => {
      if (elm.innerText !== e.target.innerText) {
        elm.style.fontWeight = 'normal';
        elm.childNodes[1].style.opacity = '0';
      }
    });
  };

  const multiSelectFromSearchList = e => {
    const selectOption = {
      value: e.target.id,
      text: e.target.innerText,
      hidden: false,
    };

    let duplicate = false;

    selectedOptions.forEach(obj => {
      if (obj.value === selectOption.value) duplicate = true;
    });

    if (!duplicate) {
      setSelectedOptions([...selectedOptions, selectOption]);
    } else {
      setSelectedOptions(
        selectedOptions
          .map(opt => {
            return opt.value === selectOption.value ? '' : opt;
          })
          .filter(v => v)
      );

      const borderArea = inputElement.parentNode;
      const iconArea = borderArea.querySelector(
        '.search_select--multi_selected_value_area'
      );

      iconArea.childNodes.forEach(elm => {
        if (elm.id.split('_')[2] === selectOption.value) {
          iconArea.removeChild(elm);
        }
      });

      inputElement.placeholder = iconArea.childNodes.length
        ? ''
        : placeholder;
    }
  };

  const addSelectedOptionsIcon = () => {
    const borderArea = inputElement.parentNode;
    const iconArea = borderArea.querySelector(
      '.search_select--multi_selected_value_area'
    );
    const hiddenInput = document.querySelector(`#${inputName}_selected_values`);

    const eclipseIcon = document.createElement('span');
    eclipseIcon.className = 'search_select--multi_selected_icon';
    eclipseIcon.innerText = '...';
    eclipseIcon.id = `${inputName}_eclipse_icon`;
    eclipseIcon.style.borderRadius = '50%';
    eclipseIcon.style.width = '12px';

    selectedOptions.forEach((obj, index) => {
      if (!iconArea.querySelector(`#selected_icon_${obj.value}`)) {
        const borderArea = inputElement.parentNode;
        const iconArea = borderArea.querySelector(
          '.search_select--multi_selected_value_area'
        );

        const selectedIcon = document.createElement('span');
        selectedIcon.className = 'search_select--multi_selected_icon';
        selectedIcon.innerText = obj.text;
        selectedIcon.id = `selected_icon_${obj.value}`;

        const deleteIcon = document.createElement('img');
        deleteIcon.src = Icons.icon.xCircle;
        deleteIcon.style.right = 'unset';
        deleteIcon.style.bottom = 'unset';
        deleteIcon.style.position = 'relative';
        deleteIcon.style.display = 'inline-block';
        deleteIcon.style.width = '26px';
        deleteIcon.style.marginRight = '-6px';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.id = `${obj.value}_delete_icon`;

        deleteIcon.addEventListener('click', e => {
          e.stopPropagation();

          // const deleteValue = e.target.id.split('_')[0];

          setSelectedOptions(
            optionsRef.current
              .map((o, idx) => {
                return o.value === obj.value ? '' : o;
              })
              .filter(v => v)
          );

          iconArea.removeChild(selectedIcon);

          inputElement.placeholder = iconArea.childNodes.length
            ? ''
            : placeholder;
        });

        selectedIcon.appendChild(deleteIcon);
        iconArea.appendChild(selectedIcon);

        if (iconArea.clientWidth >= borderArea.clientWidth * 0.8) {
          selectedIcon.hidden = true;
          setSelectedOptions(
            selectedOptions.map((opt, idx) => {
              return idx === index ? { ...opt, hidden: true } : opt;
            })
          );
        }

        inputElement.placeholder = '';
      } else if (
        !iconArea.querySelector(`#${inputName}_eclipse_icon`) &&
        selectedOptions.find(object => object.hidden)
      ) {
        iconArea.appendChild(eclipseIcon);
      }
    });

    hiddenInput.value = selectedOptions
      .map((option, index) => {
        return option.value;
      })
      .join(',');
  };

  const checkEclipse = () => {
    const borderArea = inputElement.parentNode;
    const iconArea = borderArea.querySelector(
      '.search_select--multi_selected_value_area'
    );
    const eclipseIcon = iconArea.querySelector(`#${inputName}_eclipse_icon`);

    if (
      eclipseIcon &&
      iconArea.clientWidth - 44 < borderArea.clientWidth * 0.8
    ) {
      selectedOptions.forEach((obj, index) => {
        if (obj.hidden) {
          // iconArea.removeChild(eclipseIcon);

          if (iconArea.clientWidth - 44 < borderArea.clientWidth * 0.8) {
            let selectedIcon;

            iconArea.childNodes.forEach((elm, i) => {
              const val = elm.id.split('_')[0];
              if (obj.value === val) {
                selectedIcon = elm;
                elm.hidden = false;
              }
            });
            // if (selectedIcon) selectedIcon.hidden = false;

            if (iconArea.clientWidth - 44 >= borderArea.clientWidth * 0.8) {
              selectedIcon.hidden = true;
              return;
            } else {
              setSelectedOptions(
                selectedOptions.map((opt, idx) => {
                  return idx === index ? { ...opt, hidden: false } : opt;
                })
              );
            }
          }
        }
      });

      if (
        iconArea.querySelector(`#${inputName}_eclipse_icon`) &&
        !selectedOptions.find(object => object.hidden)
      ) {
        iconArea.removeChild(eclipseIcon);
      } else if (iconArea.lastChild !== eclipseIcon) {
        iconArea.removeChild(eclipseIcon);
        iconArea.appendChild(eclipseIcon);
      }
    }

    const lis = iconArea.parentNode.parentNode.querySelector('ul').childNodes;

    lis.forEach((elm, index) => {
      elm.style.fontWeight = 'normal';
      if (elm.childNodes[1]) {
        elm.childNodes[1].style.opacity = '0';
      }

      selectedOptions.forEach(obj => {
        if (obj.value === elm.id) {
          elm.style.fontWeight = 'bold';
          elm.childNodes[1].style.opacity = '1';
        }
      });
    });
  };

  useEffect(() => {
    inputElement = document.getElementById(`input_${inputName}`);

    if (multiSelect) {
      addSelectedOptionsIcon();
      checkEclipse();

      // console.log(selectedOptions);
      // console.log(
      //   document.querySelector(`#${inputName}_selected_values`).value
      // );
    }
  });

  const initInputAndChecks = e => {
    let input;

    if (multiSelect) {
      input = document.querySelector(`#${inputName}_selected_values`);

      const borderArea = inputElement.parentNode;
      const iconArea = borderArea.querySelector(
        '.search_select--multi_selected_value_area'
      );

      iconArea.childNodes.forEach(elm => {
        iconArea.removeChild(elm);
      });

      inputElement.placeholder = placeholder;
    } else {
      input =
        e.target.tagName === 'IMG'
          ? e.target.parentNode.querySelector('input[type="input"]')
          : e.target;
    }

    input.value = '';
    setSelectedOptions([]);

    const lis = e.target.parentNode.querySelector('ul').childNodes;

    lis.forEach((elm, index) => {
      elm.style.fontWeight = 'normal';
      elm.childNodes[1].style.opacity = '0';
    });
  };

  const toggleDeleteButton = (e, visible) => {
    let input;

    if (multiSelect) {
      input = document.querySelector(`#${inputName}_selected_values`);
    } else {
      input =
        e.target.tagName === 'IMG'
          ? e.target.parentNode.querySelector('input')
          : e.target;
    }

    const icon = document.getElementById(`${inputName}_downIcon`);

    if (visible) {
      if (input.value) {
        icon.setAttribute('src', Icons.icon.xCircle);
        // icon.style.width = '24px';
        icon.style.right = '8px';
        icon.style.bottom = '7px';
        icon.style.cursor = 'pointer';

        icon.addEventListener('click', e => initInputAndChecks(e));
      }
    } else {
      icon.setAttribute('src', Icons.icon.downNv);
      // icon.style.width = '16px';
      icon.style.right = '16px';
      icon.style.bottom = '14px';
      icon.style.cursor = 'default';

      icon.removeEventListener('click', e => initInputAndChecks(e));
    }
  };

  return (
    <div
      className={topLabel ? 'search_select--area_top' : 'search_select--area'}
      style={style && style.area ? style.area : {}}
    >
      <span
        className={topLabel ? 'search_select--label_top' : 'search_select--label'}
        style={style && style.label ? style.label : {}}
      >
        {label}
      </span>

      <input id={`${inputName}_selected_values`} hidden />

      {multiSelect ? (
        <div className={'search_select--input_ul_wrap'}>
          <div
            className={'search_select--multi_input_area'}
            style={style && style.input ? style.input : {}}
            id={`${inputName}_multi_input_area`}
          >
            <div className={'search_select--multi_selected_value_area'}></div>

            <input
              id={`input_${inputName}`}
              className={'search_select--multi_input'}
              type={'input'}
              name={inputName}
              placeholder={placeholder}
              onFocus={() => setOpenUl(true)}
              onBlur={() =>
                setTimeout(() => {
                  setOpenUl(false);
                }, 100)
              }
              onChange={searchOptions}
              onMouseOver={e => toggleDeleteButton(e, true)}
              onMouseLeave={e => toggleDeleteButton(e, false)}
            />
          </div>

          <SearchSelectUl
            optionValues={optionValues}
            optionTexts={optionTexts}
            searchedIndex={searchedIndex}
            isOpen={isOpenUl}
            handleClick={multiSelectFromSearchList}
          />
        </div>
      ) : (
        <div className={'search_select--input_ul_wrap'}>
          <input
            id={`input_${inputName}`}
            className={'search_select--input'}
            type={'input'}
            name={inputName}
            placeholder={placeholder}
            style={style && style.input ? style.input : {}}
            onFocus={() => setOpenUl(true)}
            onBlur={() =>
              setTimeout(() => {
                setOpenUl(false);
              }, 100)
            }
            onChange={searchOptions}
            onMouseOver={e => toggleDeleteButton(e, true)}
            onMouseLeave={e => toggleDeleteButton(e, false)}
          />

          <SearchSelectUl
            optionValues={optionValues}
            optionTexts={optionTexts}
            searchedIndex={searchedIndex}
            isOpen={isOpenUl}
            handleClick={inputFromSearchList}
          />
        </div>
      )}

      {multiSelect ? (
        <img
          id={`${inputName}_downIcon`}
          src={Icons.icon.downNv}
          alt={'down'}
          onMouseOver={e => toggleDeleteButton(e, true)}
          onMouseLeave={e => toggleDeleteButton(e, false)}
        />
      ) : (
        <img
          id={`${inputName}_downIcon`}
          src={Icons.icon.downNv}
          alt={'down'}
          onMouseOver={e => {
            e.stopPropagation();
            toggleDeleteButton(e, true)
          }}
          onMouseLeave={e => {
            e.stopPropagation();
            toggleDeleteButton(e, false)
          }}
        />
      )}
    </div>
  );
};

export default SearchSelectBox;

const SearchSelectUl = ({
  optionValues,
  optionTexts,
  searchedIndex,
  isOpen,
  handleClick,
}) => {
  return (
    <ul
      className={'search_select--ul'}
      style={isOpen ? { padding: '5px 0', opacity: '1' } : {}}
    >
      {searchedIndex.length ? (
        searchedIndex.map((value, index) => {
          return (
            <li
              key={index}
              className={'search_select--li'}
              id={optionValues[value]}
              style={isOpen ? { height: '20px', padding: '8px 16px' } : {}}
              onClick={handleClick}
              // id={`li_${optionValues[value]}_${index}`}
            >
              {optionTexts[value]}
              <div className={'search_select--li_check_mark'}></div>
            </li>
          );
        })
      ) : (
        <li
          className={'search_select--li'}
          value={''}
          style={isOpen ? { height: '20px', padding: '8px 16px' } : {}}
        >
          データがありません
        </li>
      )}
    </ul>
  );
};
