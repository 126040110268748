import { memo } from 'react';
import Icons from '../../../constants/icons';
import DeleteIcon from '../../deleteIcon';
import BaseUpload from '../../imageUploader';
import './style.scss';

const ImageCell = memo(({ image, fileName, onDelete, onAddImage, approvalFlag }) =>
  image ? (
    <div className="image_cell_container">
      <div className="image_cell">
        {!approvalFlag && <DeleteIcon onClick={onDelete} />}
        <img src={image} />
      </div>
      <div className="image_cell_name"> {fileName}</div>
    </div>
  ) : (
    <BaseUpload onChange={onAddImage} approvalFlag={approvalFlag}>
      <div className="image_cell_container">
        <div className="image_cell">
          <img src={Icons.icon.photoB} className="empty-image" />
        </div>
      </div>
    </BaseUpload>
  )
);

export default ImageCell;
