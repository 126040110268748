import { memo, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteArrayItem,
  handlerFormikFieldChange,
  checkIsSp,
} from '../../../../utils/fnUtil';
import { transform } from 'lodash';
import { imageUpload } from '../../../../redux/slice/matter';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import AddLineButton from '../../../../components/addLineButton';
import Button from '../../../../components/button';
import DeleteIcon from '../../../../components/deleteIcon';
import FreeFormCell from '../../../../components/freeFormCell';
import LayoutModal from '../../../../components/modal/layoutModal';
import ReportSwitch from '../../../../components/switch/reportSwitch';
import Icons from '../../../../constants/icons';
import classNames from 'classnames';
import './style.scss';

const FreeForm = memo(({ formik }) => {
  const {
    values: { free },
  } = formik;

  const dispatch = useDispatch();

  const initialSelected = {
    selectedPage: -1,
    setSelectedCell: -1,
  };

  const [selected, setSelected] = useState(initialSelected);

  const [layoutModalVisible, setLayoutModalVisible] = useState(false);

  const isSp = checkIsSp();

  const onAddPattern = pattern => {
    handlerFormikFieldChange(
      formik,
      `free.[${selected.selectedPage}].layoutPattern`,
      pattern
    );
    setLayoutModalVisible(false);
  };

  return (
    <div className="free_form">
      {free.map((f, i) => (
        <Fragment key={i}>
          <div className="free_form--title">
            <CustomFormikInput
              formik={formik}
              inputName={`free.[${i}].pageName`}
              placeholder={'ページ名を入力してください'}
              style={{
                areaStyle: { width: 302 },
              }}
            />
          </div>
          <div
            className={classNames('free_form--content', {
              'free_form--empty': !f.layoutPattern > 0,
            })}
          >
            <DeleteIcon
              title="ページ削除"
              onClick={() =>
                handlerFormikFieldChange(
                  formik,
                  'free',
                  deleteArrayItem(free, i)
                )
              }
              style={isSp ? { width: '30px', height: '30px' } : {}}
            />
            <ReportSwitch
              formik={formik}
              reportInputName={`free[${i}].reportFlag`}
              toggleSize={'default'}
            />
            {f.layoutPattern > 0 ? (
              <div className="free_form--layout_container_wrapper">
                <div
                  className={classNames(
                    'free_form--layout_container',
                    `free_form--container_pattern_${f.layoutPattern}`
                  )}
                  style={isSp ? { marginTop: '78px' } : {}}
                >
                  <DeleteIcon
                    title="レイアウト削除"
                    onClick={() =>
                      handlerFormikFieldChange(formik, `free[${i}]`, {
                        reportFlag: 1,
                        staffFlag: 1,
                      })
                    }
                    style={isSp ? { top: '102px' } : {}}
                  />
                  {[...Array(f.layoutPattern === 3 ? 4 : f.layoutPattern)].map(
                    (_, k) => (
                      <FreeFormCell
                        index={k}
                        key={k}
                        formik={formik}
                        inputName={`free.[${i}]`}
                        onAddImage={async img => {
                          const imageUrl = await dispatch(
                            imageUpload(img)
                          ).unwrap();

                          handlerFormikFieldChange(
                            formik,
                            `free.[${i}].form${k + 1}Image`,
                            imageUrl
                          );
                        }}
                        onImageButton={() =>
                          setSelected({
                            selectedPage: i,
                            selectedCell: k,
                          })
                        }
                      />
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className="free_form--description">
                <span>レイアウトを追加してください</span>
                <span>フリーフォームのレイアウトを設定できます</span>
                <Button
                  icon={Icons.icon.plusNv}
                  text="レイアウト追加"
                  theme="white"
                  onClick={() => {
                    setSelected(pre => ({
                      ...pre,
                      selectedPage: i,
                    }));
                    setLayoutModalVisible(true);
                  }}
                />
              </div>
            )}
          </div>
        </Fragment>
      ))}
      <LayoutModal
        modalVisible={layoutModalVisible}
        onBack={() => setLayoutModalVisible(false)}
        onAddPattern={onAddPattern}
        freeForm={true}
      />
      <AddLineButton
        title={isSp ? '' : 'ページ追加'}
        onClick={() =>
          handlerFormikFieldChange(formik, 'free', [
            ...free,
            {
              reportFlag: 1,
              staffFlag: 1,
              pageName: '',
              ...transform([1, 2, 3, 4], (result, i) => {
                result[`form${i}Text`] = null;
                result[`form${i}Image`] = null;
              }),
            },
          ])
        }
      />
    </div>
  );
});

export default FreeForm;
