import { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
  fetchMatterDateAssignDetail,
  updateMatterDateAssignDetail,
} from '../../../redux/slice/matterDate';
import {
  convertToNumber,
  handlerFormikSubmit,
  numberConvert,
} from '../../../utils/fnUtil';
import { globalActions } from '../../../redux/slice/global';
import { isNil } from 'lodash';
import { Tabs } from 'antd';
import { checkIsSp } from '../../../utils/fnUtil';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import MatterDateInfo from './tabs/matterDateInfo';
import AssignSetting from './tabs/assignSetting';
import Yup from '../../../utils/yupUtil';
import moment from 'moment';
import './style.scss';

const { TabPane } = Tabs;

const AssignDetail = memo(() => {
  let { state, pathname } = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { assignDetail, loading, calculationPayFlag } = useSelector(
    state => state.matterDate
  );

  const { corporateId } = useSelector(state => state.account);

  const { matterDetail } = assignDetail ?? {};

  const { activeTab } = useSelector(state => state.global);

  const isSp = checkIsSp();

  const tabErrorGroup = {
    matterDateInfo: ['matterDateNumber'],
    assignSetting: ['shift'],
  };

  const formik = useFormik({
    initialValues: {
      matterDateNumber: '',
      dateInfo: {
        venueNearestStation: null,
        meetingPlace: null,
        placeLocation: null,
        placeLocationOther: '',
        detailText: '',
        matterDetail: '',
        previousDayInfo: '',
        shiftDayDetail: '',
      },
      shift: [],
    },
    validationSchema: Yup.object({
      matterDateNumber: Yup.string().required('[実施情報]:開催日'),
      shift: Yup.array().of(
        Yup.object({
          hourlyPayBase: Yup.string()
            .nullable()
            .integerString('[アサイン設定]:基本時給'),
          dailyPayBase: Yup.string()
            .nullable()
            .integerString('[アサイン設定]:基本日給'),
          assignPayAmount: Yup.string()
            .nullable()
            .numberString('[アサイン設定]:支払金額'),
          arriveStationDatetime: Yup.string()
            .nullable()
            .time('[アサイン設定]:駅到着時間'),
          gatheringDatetime: Yup.string()
            .nullable()
            .time('[アサイン設定]:集合時間'),
          workStartDatetime: Yup.string()
            .nullable()
            .time('[アサイン設定]:就業開始時間'),
          workEndDatetime: Yup.string()
            .nullable()
            .time('[アサイン設定]:就業終了時間'),
          workDatetime: Yup.string()
            .nullable()
            .test(
              'dateInvalid',
              '[アサイン設定]就業時間:開始時間は終了時間より前の時間を入力してください',
              (_, context) => {
                const { workStartDatetime, workEndDatetime } =
                  context.from[0].value;

                return (
                  !workStartDatetime ||
                  !workEndDatetime ||
                  moment(workEndDatetime, 'HH:mm').isAfter(
                    moment(workStartDatetime, 'HH:mm')
                  )
                );
              }
            ),
          shiftAssign: Yup.array().of(
            Yup.object({
              punchInDatetime: Yup.string()
                .nullable()
                .time('[アサイン設定]:始業打刻'),
              punchOutDatetime: Yup.string()
                .nullable()
                .time('[アサイン設定]:終業打刻'),
              punchDatetime: Yup.string()
                .nullable()
                .test(
                  'dateInvalid',
                  '[アサイン設定]勤怠打刻:始業打刻は終業打刻より前の時間を入力してください',
                  (_, context) => {
                    const { punchInDatetime, punchOutDatetime } =
                      context.from[0].value;

                    return (
                      !punchInDatetime ||
                      !punchOutDatetime ||
                      moment(punchOutDatetime, 'HH:mm').isAfter(
                        moment(punchInDatetime, 'HH:mm')
                      )
                    );
                  }
                ),
              overtime: Yup.string()
                .nullable()
                .integerString('[アサイン設定]:残業時間'),
              overtimePay: Yup.string()
                .nullable()
                .numberString('[アサイン設定]:残業金額'),
              payCutTime: Yup.string()
                .nullable()
                .integerString('[アサイン設定]:減給時間'),
              payCutHourlyPay: Yup.string()
                .nullable()
                .numberString('[アサイン設定]:減給金額'),
            })
          ),
        })
      ),
    }),
    onSubmit: values => {
      const { shift, dateInfo, matterDateNumber } = values;
      dispatch(
        updateMatterDateAssignDetail({
          dateInfo: {
            ...dateInfo,
            placeLocationOther:
              dateInfo.placeLocation === 99
                ? dateInfo.placeLocationOther
                : null,
          },
          matterDateNumber,
          shift: shift.map(s => ({
            ...s,
            shiftAssign: (s.shiftAssign ?? [])
              .map((a, j) => ({
                ...a,
                priority: j,
                payAmount:
                  a.assignAccountFlag === 2
                    ? numberConvert(
                        convertToNumber(a.assignPayAmount),
                        calculationPayFlag
                      )
                    : a.assignPayAmount,
                punchInDatetime: a.punchInDatetime
                  ? `${dateInfo.punchInDate ?? dateInfo.matterDate} ${
                      a.punchInDatetime
                    }:00`
                  : null,
                punchOutDatetime: a.punchOutDatetime
                  ? `${dateInfo.punchOutDate ?? dateInfo.matterDate} ${
                      a.punchOutDatetime
                    }:00`
                  : null,
                matterDetailVisibilityFlag: a.matterDetailVisibilityFlag ?? 1,
                previousDayInfoVisibilityFlag:
                  a.previousDayInfoVisibilityFlag ?? 0,
                shiftDayDetailVisibilityFlag:
                  a.shiftDayDetailVisibilityFlag ?? 1,
                overtimeStartDatetime:
                  a.overtimeStartDatetime &&
                  `${dateInfo.matterDate} ${a.overtimeStartDatetime}:00`,
                overtimeEndDatetime:
                  a.overtimeEndDatetime &&
                  `${dateInfo.matterDate} ${a.overtimeEndDatetime}:00`,
                staffWeekMethodFlag: a.assignAccountFlag === 2 ? 2 : a.staffWeekMethodFlag ?? 0,
                staffPreviousDayMethodFlag: a.assignAccountFlag === 2 ? 2 : a.staffPreviousDayMethodFlag ?? 0,
                staffGettingUpMethodFlag: a.assignAccountFlag === 2 ? 2 : a.staffGettingUpMethodFlag ?? 0,
                staffDepartureMethodFlag: a.assignAccountFlag === 2 ? 2 : a.staffDepartureMethodFlag ?? 0,
                staffArriveMethodFlag: a.assignAccountFlag === 2 ? 2 : a.staffArriveMethodFlag ?? 0,
                staffEndMethodFlag: a.staffEndMethodFlag ?? 0,
                staffGettingUpPlanDatetime:
                  a.staffGettingUpPlanDatetime ?? null,
                staffDeparturePlanDatetime:
                  a.staffDeparturePlanDatetime ?? null,
                staffArrivePlanDatetime:
                  a.staffArrivePlanDatetime ??
                  (s.gatheringDatetime &&
                    `${dateInfo.matterDate} ${s.gatheringDatetime}:00`),
                staffEndPlanDatetime:
                  a.staffEndPlanDatetime ??
                  (s.workEndDatetime &&
                    `${dateInfo.matterDate} ${s.workEndDatetime}:00`),
              }))
              .filter(a => !isNil(a.assignId)),
            arriveStationDatetime:
              s.arriveStationDatetime &&
              `${dateInfo.matterDate} ${s.arriveStationDatetime}:00`,
            gatheringDatetime:
              s.gatheringDatetime &&
              `${dateInfo.matterDate} ${s.gatheringDatetime}:00`,
            workStartDatetime:
              s.workStartDatetime &&
              `${dateInfo.matterDate} ${s.workStartDatetime}:00`,
            workEndDatetime:
              s.workEndDatetime &&
              `${dateInfo.matterDate} ${s.workEndDatetime}:00`,
          })),
        })
      ).unwrap();
      // .then(() => navigate(localStorage.getItem('matter_date_number') ? '/matter/assign-state/list' : '/matter/date/list'));
    },
  });

  const tabs = [
    {
      key: 'matterDateInfo',
      label: '実施情報',
      children: <MatterDateInfo formik={formik} />,
    },
    {
      key: 'assignSetting',
      label: 'アサイン設定',
      children: <AssignSetting formik={formik} />,
    },
  ];

  useEffect(() => {
    if (localStorage.getItem('matter_date_number')) {
      state = { id: localStorage.getItem('matter_date_number') };
    }
    state?.id &&
      dispatch(
        fetchMatterDateAssignDetail({
          matterDateNumber: state.id,
          corporateId,
        })
      );
  }, [state?.id]);

  useEffect(() => {
    assignDetail &&
      formik.setValues({
        ...formik.values,
        dateInfo: assignDetail.dateInfo,
        shift: assignDetail.shift.map(s => ({
          ...s,
          directorQuantityTemp: s.directorQuantity,
          adQuantityTemp: s.adQuantity,
          manQuantityTemp: s.manQuantity,
          womanQuantityTemp: s.womanQuantity,
          unisexQuantityTemp: s.unisexQuantity,
          shiftAssign: [
            s.directorQuantity ?? 0,
            s.adQuantity ?? 0,
            s.manQuantity ?? 0,
            s.womanQuantity ?? 0,
            s.unisexQuantity ?? 0,
          ]
            .flatMap((q, i) => [...Array(q)].map(() => ({ shiftPosition: i })))
            .map((a, m) => s.shiftAssign.find(a => a.priority === m) ?? a),
        })),
        matterDateNumber: assignDetail.dateInfo?.matterDateNumber ?? '',
      });
  }, [assignDetail]);

  useEffect(() => {
    state?.fromDetail &&
      dispatch(globalActions.changeActiveTab('assignSetting'));
  }, [state?.fromDetail]);

  return (
    !loading && (
      <>
        <div className={'page_base--container assign_detail_page'}>
          <span className={'page_base--container_notice'}>*は必須項目です</span>
          <div className="assign_detail_page--title display-linebreak">
            <div
              className="assign_detail_page--title-left"
              style={isSp ? { fontSize: 12 } : {}}
            >
              <span>{`案件No.  ${
                matterDetail?.matterNumber ?? ''
              }\t\t見積書No.   ${matterDetail?.quoteNumber ?? ''}`}</span>
              <span>{matterDetail?.matterName}</span>
            </div>
            <div
              className="assign_detail_page--title-right"
              style={isSp ? { margin: '10px 0' } : {}}
            >
              <div>
                <span>{`取引先\t ${matterDetail?.clientName ?? ''}`}</span>
                <span>{`開催地\t ${matterDetail?.venueName ?? ''}`}</span>
              </div>
              <div>
                <span>{`案件担当者\t ${
                  matterDetail?.matterManagerUserName ?? ''
                }`}</span>
                <span>{`営業担当者\t ${
                  matterDetail?.salesManagerUserName ?? ''
                }`}</span>
              </div>
              <div>
                <span>{`取引先担当者\t ${
                  matterDetail?.clientManagerUserName ?? ''
                }`}</span>
                {isSp ?? <span>{` `}</span>}
              </div>
            </div>
          </div>
          <div
            className="page_base--tabs"
            style={isSp ? { padding: '30px 20px' } : {}}
          >
            <Tabs
              activeKey={activeTab ? activeTab : tabs[0].key}
              onChange={tab => dispatch(globalActions.changeActiveTab(tab))}
            >
              {tabs.map(tab => (
                <TabPane tab={tab.label} key={tab.key}>
                  {tab.children}
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
        <div
          className={'bottom_btn_area'}
          style={isSp ? { height: '100px' } : {}}
        >
          <div className={'bottom_btn_area--common_actions'}>
            <Button
              text={'戻る'}
              style={Options.buttonStyles.back}
              onClick={() =>
                navigate(
                  localStorage.getItem('matter_date_number')
                    ? '/matter/assign-state/list'
                    : '/matter/date/list'
                )
              }
            />
            <Button
              text={'更新'}
              style={Options.buttonStyles.submit}
              onClick={() => handlerFormikSubmit(formik, tabErrorGroup)}
            />
          </div>
        </div>
      </>
    )
  );
});

export default AssignDetail;
