import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomFormikInput from './../../../../../components/customInput/customFormikInput';
import Button from '../../../../../components/button';
import Options from '../../../../../constants/options';
import Icons from '../../../../../constants/icons';
import { staffPayUpdateFetch } from '../../../../../redux/slice/staffPay';
import { globalActions } from '../../../../../redux/slice/global';
import Yup from '../../../../../utils/yupUtil';
import './style.scss';
import { handlerFormikSubmit } from '../../../../../utils/fnUtil';

const PayStamp = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataSource } = props;
  const { editParams } = useSelector(state => state.staffPay);
  const formik = useFormik({
    initialValues: {
      managerApproval: String(dataSource.managerApproval),
      managerNote: dataSource.managerNote ?? '',
      staffApproval: String(dataSource.staffApproval),
      staffNote: dataSource.staffNote ?? '',
      accountantApproval: String(dataSource.accountantApproval),
      accountantNote: dataSource.accountantNote ?? '',
      sendMailFlag: dataSource.sendMailFlag,
      mailFlag: true,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      managerNote: Yup.string()
        .nullable()
        .max(
          200,
          'スタッフ担当社員承認備考：全角200、半角400文字以内で入力してください'
        )
        .label('スタッフ担当社員承認備考'),
      staffNote: Yup.string()
        .nullable()
        .max(
          200,
          'スタッフ承認備考：全角200、半角400文字以内で入力してください'
        )
        .label('スタッフ承認備考'),
      accountantNote: Yup.string()
        .nullable()
        .max(200, '備考欄：全角200、半角400文字以内で入力してください')
        .label('経理最終備考'),
    }),
    onSubmit: values => {
      let val = {
        ...editParams,
        ...values,
        mailFlag: values.mailFlag ? 1 : 0,
      };
      dispatch(staffPayUpdateFetch(val))
        .unwrap()
        .then(() => {
          dispatch(globalActions.showSingleModal('更新しました'));
          navigate('/staff-pay/list');
        });
    },
  });

  const {
    managerApproval,
    managerNote,
    staffApproval,
    staffNote,
    accountantApproval,
    accountantNote,
    sendMailFlag,
    mailFlag,
  } = formik.values;

  const labelWidth = '15%';
  return (
    <>
      <div className="pay_stamp">
        <div className="pay_stamp--each_area">
          <div className="pay_stamp--staff_employee">
            <CustomFormikInput
              formik={formik}
              radioButtonTitle="スタッフ担当社員承認"
              inputType="radioButton"
              inputName="managerApproval"
              radioButtonLabelAndChecked={[
                { label: '承認', checked: managerApproval === '3' },
                { label: '否認', checked: managerApproval === '2' },
                { label: '未承認', checked: managerApproval === '1' },
              ]}
              initialValue={[3, 2, 1]}
              style={{
                areaStyle: { width: 'auto' },
                labelStyle: { width: labelWidth },
              }}
            />
            {sendMailFlag === 1 && (
              <Button
                text={'給与確認通知再送信'}
                icon={Icons.icon.mailOpWh}
                style={{
                  width: '238px',
                  height: '42px',
                  border: '1px solid #2B3245',
                  borderRadius: '6px',
                  background: '#7B7B7B',
                  color: '#ffffff',
                  fontSize: '14px',
                  margin: '0 0',
                  padding: '0 0',
                  userSelect: 'none',
                  boxSizing: 'content-box',
                  position: 'absolute',
                  bottom: '0',
                  right: '0',
                }}
                onClick={() => {
                  let val = {
                    ...editParams,
                    mailFlag: 1,
                  };
                  dispatch(staffPayUpdateFetch(val))
                    .unwrap()
                    .then(() => {
                      dispatch(
                        globalActions.showSingleModal(
                          '給与確認通知を再送信しました'
                        )
                      );
                    });
                }}
              />
            )}
            {sendMailFlag !== 1 && (
              <CustomFormikInput
                formik={formik}
                inputType="checkBox"
                label="勤怠確認通知メールを送信する"
                inputName="mailFlag"
                style={{
                  areaStyle: {
                    position: 'absolute',
                    top: '0',
                    right: '0',
                  },
                }}
              />
            )}
          </div>
          <CustomFormikInput
            formik={formik}
            inputType="textBox"
            label="備考"
            inputName="managerNote"
            value={''}
            placeholder="備考を入力してくさい"
            style={{
              labelStyle: { width: labelWidth },
            }}
          />
        </div>
        <div className="pay_stamp--each_area">
          <div className="pay_stamp--staff_employee">
            <CustomFormikInput
              formik={formik}
              radioButtonTitle="スタッフ承認"
              inputType="radioButton"
              inputName="staffApproval"
              radioButtonLabelAndChecked={[
                { label: '承認', checked: staffApproval === '3' },
                { label: '否認', checked: staffApproval === '2' },
                { label: '未承認', checked: staffApproval === '1' },
              ]}
              initialValue={[3, 2, 1]}
              style={{
                areaStyle: { width: 'auto' },
                labelStyle: { width: labelWidth },
              }}
            />

            <CustomFormikInput
              formik={formik}
              inputType="textBox"
              label="備考"
              inputName="staffNote"
              value={''}
              placeholder="備考を入力してくさい"
              style={{
                labelStyle: { width: labelWidth },
              }}
            />
          </div>
        </div>
        <div className="pay_stamp--each_area">
          <div className="pay_stamp--staff_employee">
            <CustomFormikInput
              formik={formik}
              radioButtonTitle="経理最終承認"
              inputType="radioButton"
              inputName="accountantApproval"
              radioButtonLabelAndChecked={[
                { label: '承認', checked: accountantApproval === '3' },
                { label: '否認', checked: accountantApproval === '2' },
                { label: '未承認', checked: accountantApproval === '1' },
              ]}
              initialValue={[3, 2, 1]}
              style={{
                areaStyle: { width: 'auto' },
                labelStyle: { width: labelWidth },
              }}
            />

            <CustomFormikInput
              formik={formik}
              inputType="textBox"
              label="備考"
              inputName="accountantNote"
              value={''}
              placeholder="備考を入力してくさい"
              style={{
                labelStyle: { width: labelWidth },
              }}
            />
          </div>
        </div>
      </div>

      <div className={'bottom_btn_area'} style={{ height: '92px' }}>
        <div className={'quote_register--submit_buttons'}>
          <Button
            text={'戻る'}
            style={Options.buttonStyles.stampBack}
            onClick={() => {
              navigate('/staff-pay/list');
            }}
          />
          <Button
            text={'更新'}
            style={Options.buttonStyles.stampApproval}
            onClick={() => {
              handlerFormikSubmit(formik);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PayStamp;
