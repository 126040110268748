import { useEffect } from 'react';
import { Modal } from 'antd';
import { useFormik } from 'formik';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import Options from '../../../constants/options';
import Button from '../../button';
import Checkbox from '../../checkbox';
import CustomFormikInput from '../../customInput/customFormikInput';
import './style.scss';

const CreateTableModal = ({
  modalVisible,
  onCreateTable,
  onCancel,
  currentTableCount,
}) => {
  const formik = useFormik({
    initialValues: {
      tableRows: null,
      tableColumns: null,
      totalDisplayFlag: 1,
    },
    onSubmit: onCreateTable,
  });

  const {
    values: { totalDisplayFlag },
  } = formik;

  const rowOptions = [...Array(20)]
    .map((_, i) => i + 1)
    .filter(i => i + currentTableCount <= 20);

  useEffect(() => {
    !modalVisible && formik.resetForm();
  }, [modalVisible]);

  return (
    <Modal
      visible={modalVisible}
      style={{
        minWidth: 600,
      }}
      footer={null}
      centered
      closable={false}
      destroyOnClose={true}
      onCancel={onCancel}
      className="create_table_modal_base"
    >
      <div className="create_table_modal">
        <div className="common_modal_title">表作成</div>
        <div className="create_table_modal--item">
          <span className="create_table_modal--item_label">表示列数</span>
          <CustomFormikInput
            placeholder="0"
            formik={formik}
            label="タテ"
            inputType="select"
            inputName="tableColumns"
            initialValue={[1, 2, 3]}
            selectBoxOptions={[1, 2, 3]}
            style={{
              areaStyle: {
                width: 161,
                marginRight: 20,
              },
            }}
          />
          <span className="create_table_modal--item_text">×</span>
          <CustomFormikInput
            formik={formik}
            placeholder="0"
            label="ヨコ"
            inputType="select"
            inputName="tableRows"
            initialValue={rowOptions}
            selectBoxOptions={rowOptions}
            style={{
              areaStyle: {
                width: 161,
              },
            }}
          />
        </div>
        <div className="create_table_modal--item">
          <span className="create_table_modal--item_label">全体合計</span>
          <Checkbox
            label="表示する"
            checked={totalDisplayFlag === 1}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'totalDisplayFlag',
                totalDisplayFlag === 1 ? 0 : 1
              )
            }
          />
        </div>
        <div className={'create_table_modal--actions'}>
          <Button
            text={'戻る'}
            style={Options.buttonStyles.back}
            onClick={onCancel}
          />
          <Button
            text={'追加'}
            style={Options.buttonStyles.submit}
            onClick={formik.handleSubmit}
            disabled={!formik.values.tableRows || !formik.values.tableColumns}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateTableModal;
