import './style.scss';
import Header from '../../components/header';
import TitleBar from '../../components/titleBar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { store } from '../../redux/store';
import { globalActions } from '../../redux/slice/global';
import { checkSession } from '../../api/fetch';
import { useEffect } from "react";

const Sub = props => {
  const { icon, title, PageComponent } = props;

  const accountState = useSelector(state => state.account);
  const loginType = useSelector(state => state.loginType.type);
  const navigate = useNavigate();

  useEffect(() => {
    checkSession(accountState.corporateId, accountState.accessToken, loginType)
      .then(j => {
        if (j.code != 0) {
          store.dispatch(globalActions.showSingleModal('ログインしてください'));
          navigate('/login');
        }
      })
      .catch(err => {
        store.dispatch(globalActions.showSingleModal('ログインしてください'));
        navigate('/login');
      });
  }, [])

  if (
    !accountState.corporateId ||
    (!accountState.userId && !accountState.staffId) ||
    !accountState.accessToken
  ) {
    store.dispatch(globalActions.showSingleModal('ログインしてください'));
    navigate('/login');
  }

  return (
    <>
      <Header />
      <div className={'sub--page_area'}>
        <TitleBar icon={icon} title={title} counter={[]} />
        <div className={'sub--content_area'}>
          <PageComponent />
        </div>
      </div>
    </>
  );
};

export default Sub;
