import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Icons from '../../constants/icons';
import { DatePicker, TimePicker, AutoComplete } from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import './style.scss';
import './spStyle.scss';
import SelectBox from '../selectBox';
import MultiSelectBox from '../multiSelectBox';
import SelectBoxAddButton from '../selectBoxAddButton';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import { checkIsSp } from '../../utils/fnUtil';

const CustomInput = ({
  isParam,
  inputType,
  inputName,
  inputId,
  inputNameCheckBox,
  initialValue,
  label,
  labelRequired,
  selectBoxOptions,
  selectedSelectBox,
  checkBoxChecked,
  className,
  placeholder,
  unit,
  toggleButtonText,
  radioButtonTitle,
  radioButtonLabelAndChecked,
  style,
  isButton,
  disabled,
  value,
  onClick,
  onChange,
  onBlur,
  touched,
  error,
  setFieldValue,
  onFocus,
  disabledTime,
  disabledDate,
  disabledFutureDate,
  customDisabledDate,
  disabledHours,
  disabledMinutes,
  useValue = false,
  changePlusColor,
  onClickAdd,
  showSearch,
  defaultValue,
  usePopContainer,
  submitErrorFlg,
  allowClear = false,
  mode = null,
  autoComplete,
  suggestions = [],
  displayError,
  disabledOptions,
  option,
  defaultChecked,
}) => {
  const dateFormat = 'YYYY/M/D';
  const monthFormat = 'YYYY/MM';

  const [state, setState] = useState({
    value: initialValue ? initialValue : !initialValue && value ? value : '',
    radioButtonValue: false,
    inputWithCheckBoxValue: initialValue ? initialValue : '',
    radioButtonChecked: false,
    check:
      inputType === 'inputWithCheckBox' ||
      inputType === 'toggleButton' ||
      (inputType === 'checkBox' && checkBoxChecked)
        ? checkBoxChecked
        : false,
    checkValue: initialValue ? initialValue : false,
    toggle: false,
    isHidden: inputName === 'corporate_id' && isParam ? true : false,
    isInputType: inputType === 'inputPassword' ? 'password' : 'text',
    selectAddButton: false,
    inputTime: false,
    antdIcon: !!initialValue,
  });

  const [inputWithInputValue, setInputWithInputValue] = useState({
    value_0: initialValue ? initialValue[0] : '',
    value_1: initialValue ? initialValue[1] : '',
    value_2: initialValue ? initialValue[2] : '',
  });

  const [selectValue, setSelectValue] = useState(
    inputType === 'pickUpSelectBox' && selectedSelectBox
      ? selectedSelectBox
      : []
  );
  let pickUpSelectBoxValues = Array.from(new Set(selectValue)).filter(Boolean);

  const [checkBoxMultipleValue, setCheckBoxMultipleValue] =
    useState(initialValue);

  const [pickUpSelectBoxOptions, setPickUpSelectBoxOptions] = useState(
    inputType === 'pickUpSelectBox' && selectedSelectBox
      ? selectedSelectBox
      : []
  );
  let pickUpSelectBoxOptionsFilter = Array.from(
    new Set(pickUpSelectBoxOptions)
  ).filter(Boolean);

  //インプットタイムの設定値
  const inputTimeFormat = 'HH:mm';

  const divOutLine = () => {
    let divOutLine = className && className.outLineClass;
    if (inputType === 'inputPassword' && divOutLine === 'column') {
      divOutLine = 'custom_input--password_column';
    } else if (inputType === 'inputPassword' && divOutLine) {
      divOutLine = 'custom_input--password ' + divOutLine;
    } else if (inputType === 'inputPassword' && !displayError) {
      divOutLine = 'custom_input--password_not_error';
    } else {
      divOutLine = 'custom_input--password';
    }
    return divOutLine;
  };

  //classNameを変更する処理を記載ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const areaClassName = () => {
    let areaClassName = className && className.areaClass && className.areaClass;

    if (inputType === 'selectBox' && areaClassName === 'column') {
      areaClassName = 'custom_input--select_box_area_column';
    } else if (inputType === 'select' && areaClassName === 'column') {
      areaClassName = 'custom_input--select_box_area_column';
    } else if (areaClassName === 'column' && displayError) {
      areaClassName = 'custom_input--area_column';
    } else if (areaClassName === 'column' && !displayError) {
      areaClassName = 'custom_input--area_column_not_error';
    } else if (inputType === 'inputTell') {
      areaClassName
        ? (areaClassName = 'custom_input--tell_area ' + areaClassName)
        : (areaClassName = 'custom_input--tell_area');
    } else if (inputType === 'label') {
      areaClassName
        ? (areaClassName = `custom_input--area ${areaClassName} no_display_input_field`)
        : (areaClassName = `custom_input--area no_display_input_field`);
    } else if (inputType === 'selectAddBtn') {
      areaClassName
        ? (areaClassName = `custom_input--area ${areaClassName} custom_select_add_button_area`)
        : (areaClassName = `custom_input--area custom_select_add_button_area`);
    } else if (inputType === 'textBox') {
      areaClassName
        ? (areaClassName = 'custom_input--text_area ' + areaClassName)
        : (areaClassName = 'custom_input--text_area');
    } else if (inputType === 'selectBox' || inputType === 'select') {
      areaClassName
        ? (areaClassName = 'custom_input--select_box_area ' + areaClassName)
        : (areaClassName = 'custom_input--select_box_area');
    } else if (inputType === 'selectBoxWithAddButton') {
      areaClassName
        ? (areaClassName =
            'custom_input--select_box_add_button_area ' + areaClassName)
        : (areaClassName = 'custom_input--select_box_add_button_area');
    } else if (inputType === 'selectBoxWithSelectBox') {
      areaClassName
        ? (areaClassName =
            'custom_input--select_with_select_area ' + areaClassName)
        : (areaClassName = 'custom_input--select_with_select_area');
    } else if (inputType === 'inputPassword') {
      areaClassName
        ? (areaClassName = 'custom_input--password_area ' + areaClassName)
        : (areaClassName = 'custom_input--password_area');
    } else if (inputType === 'inputWithInput') {
      areaClassName
        ? (areaClassName =
            'custom_input--input_with_input_area ' + areaClassName)
        : (areaClassName = 'custom_input--input_with_input_area');
    } else if (inputType === 'checkBox') {
      areaClassName
        ? (areaClassName = 'custom_input--check_box_area ' + areaClassName)
        : (areaClassName = 'custom_input--check_box_area');
    } else if (inputType === 'toggleButton') {
      areaClassName
        ? (areaClassName = 'custom_input--toggle_button ' + areaClassName)
        : (areaClassName = 'custom_input--toggle_button');
    } else if (inputType === 'radioButton') {
      areaClassName
        ? (areaClassName = 'custom_input--radio_button ' + areaClassName)
        : (areaClassName = 'custom_input--radio_button');
    } else if (
      inputType === 'inputDate' ||
      inputType === 'inputYear' ||
      inputType === 'inputMonth' ||
      inputType === 'inputTime'
    ) {
      areaClassName
        ? (areaClassName = 'custom_input--area_ant_design ' + areaClassName)
        : (areaClassName = 'custom_input--area_ant_design');
    } else if (areaClassName) {
      areaClassName = 'custom_input--area ' + areaClassName;
    } else if (state.isHidden) {
      areaClassName = 'custom_input--area_column_is_corporate_id';
    } else {
      areaClassName = 'custom_input--area';
    }
    return areaClassName;
  };

  //labelClassNameを変更する処理を記載ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const labelClassName = () => {
    let labelClassName =
      className && className.labelClass && className.labelClass;
    if (
      inputType === 'inputDate' ||
      inputType === 'inputYear' ||
      inputType === 'inputMonth' ||
      inputType === 'inputTime'
    ) {
      labelClassName
        ? (labelClassName = 'custom_input--label_ant_design ' + labelClassName)
        : (labelClassName = 'custom_input--label_ant_design');
    } else if (inputType === 'inputTell') {
      labelClassName
        ? (labelClassName = 'custom_input--tell_label ' + labelClassName)
        : (labelClassName = 'custom_input--tell_label');
    } else if (inputType === 'selectBox' || inputType === 'select') {
      labelClassName
        ? (labelClassName = 'custom_input--select_box_label ' + labelClassName)
        : (labelClassName = 'custom_input--select_box_label');
    } else if (inputType === 'selectBoxWithSelectBox') {
      labelClassName
        ? (labelClassName =
            'custom_input--select_with_select_label ' + labelClassName)
        : (labelClassName = 'custom_input--select_with_select_label');
    } else if (inputType === 'inputPassword') {
      labelClassName
        ? (labelClassName = 'custom_input--password_label ' + labelClassName)
        : (labelClassName = 'custom_input--password_label');
    } else if (inputType === 'inputWithInput') {
      labelClassName
        ? (labelClassName =
            'custom_input--input_with_input_label ' + labelClassName)
        : (labelClassName = 'custom_input--input_with_input_label');
    } else if (inputType === 'toggleButton') {
      labelClassName
        ? (labelClassName = 'custom_input--toggle_label ' + labelClassName)
        : (labelClassName = 'custom_input--toggle_label');
    } else if (inputType === 'radioButton') {
      labelClassName
        ? (labelClassName = 'custom_input--radio_title_area ' + labelClassName)
        : (labelClassName = 'custom_input--radio_title_area');
    } else {
      labelClassName
        ? (labelClassName = 'custom_input--label ' + labelClassName)
        : (labelClassName = 'custom_input--label');
    }
    return labelClassName;
  };

  //inputClassNameを変更する処理を記載ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const inputClassName = () => {
    let inputClassName =
      className && className.inputClass && className.inputClass;
    if (
      inputType === 'inputDate' ||
      inputType === 'inputYear' ||
      inputType === 'inputMonth' ||
      inputType === 'inputTime'
    ) {
      inputClassName
        ? (inputClassName =
            'custom_input--input_area_ant_design ' + inputClassName)
        : (inputClassName = 'custom_input--input_area_ant_design');
    } else if (inputType === 'inputTell') {
      inputClassName
        ? (inputClassName = 'custom_input--tell_input_area ' + inputClassName)
        : (inputClassName = 'custom_input--tell_input_area');
    } else if (inputType === 'selectBox' || inputType === 'select') {
      inputClassName
        ? (inputClassName =
            'custom_input--select_box_input_area ' + inputClassName)
        : (inputClassName = 'custom_input--select_box_input_area');
    } else if (inputType === 'selectBoxWithSelectBox') {
      inputClassName
        ? (inputClassName =
            'custom_input--select_with_select_input_area ' + inputClassName)
        : (inputClassName = 'custom_input--select_with_select_input_area');
    } else if (inputType === 'inputPassword') {
      inputClassName
        ? (inputClassName =
            'custom_input--password_input_area ' + inputClassName)
        : (inputClassName = 'custom_input--password_input_area');
    } else if (inputType === 'inputWithInput' && unit) {
      inputClassName
        ? (inputClassName =
            'custom_input--input_with_input_input_area ' + inputClassName)
        : (inputClassName = 'custom_input--input_with_input_input_area');
    } else if (inputType === 'inputWithInput' && !unit) {
      inputClassName
        ? (inputClassName =
            'custom_input--input_with_input_input_area_no_unit ' +
            inputClassName)
        : (inputClassName =
            'custom_input--input_with_input_input_area_no_unit');
    } else if (inputType === 'radioButton') {
      inputClassName
        ? (inputClassName = 'custom_input--radio_input_area ' + inputClassName)
        : (inputClassName = 'custom_input--radio_input_area');
    } else if (inputType === 'suggest') {
      inputClassName
        ? (inputClassName =
            'custom_input--suggest_input_area ' + inputClassName)
        : (inputClassName = 'custom_input--suggest_input_area');
    } else {
      inputClassName
        ? (inputClassName = 'custom_input--input_area ' + inputClassName)
        : (inputClassName = 'custom_input--input_area');
    }
    return inputClassName;
  };

  //displayClassNameを変更する処理を記載 (ラジオボタン)ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const displayClassName = () => {
    let displayClassName =
      className && className.displayClass && className.displayClass;
    if (inputType === 'radioButton') {
      displayClassName
        ? (displayClassName =
            'custom_input--radio_display_area ' + displayClassName)
        : (displayClassName = 'custom_input--radio_display_area');
    }
    return displayClassName;
  };

  //inputの値を変更する処理ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const handleChange = e => {
    setFieldValue && setFieldValue(inputName, e.target.value);
    if (inputType === 'inputWithInput' || inputType === 'inputTell') {
      let targetParentElm = e.target.getAttribute('data-num');
      setInputWithInputValue({
        ...inputWithInputValue,
        ['value_' + targetParentElm]: e.target.value,
      });
      // setState({
      //   ...state,
      //   value: state.value.map((value, index) => {
      //     return Array.prototype.filter.call(e.target.parentNode.childNodes, (node) => node.nodeName === 'INPUT')[index].value
      //   }),
      // });
    } else if (inputType === 'inputWithCheckBox') {
      setState({ ...state, inputWithCheckBoxValue: e.target.value });
    } else {
      setState({ ...state, value: e.target.value });
      setTimeout(() => {
        document.getElementById(e.target.id).focus();
      }, 0);
    }
  };

  const handleCalenderChange = (_, value) => {
    setFieldValue?.(inputName, value);
    document.getElementById(inputName).value
      ? setState({ ...state, antdIcon: true })
      : setState({ ...state, antdIcon: false });
  };

  const [datePickerKey, setDatePickerKey] = useState(false);

  const deleteCalender = async e => {
    setFieldValue?.(inputName, '');
    await setState({ ...state, antdIcon: false, value: null });
    await setDatePickerKey(prev => !prev);
    document.getElementById(inputName).value = '';
    document.getElementById(inputName).title = '';
  };

  const outClick = () => {
    document.getElementById(inputName).value
      ? setState({ ...state, antdIcon: true })
      : setState({ ...state, antdIcon: false });
  };

  //選択したものが表示されるセレクトボックスの値を管理する処理ーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const pickUpSelectBoxHandleChange = e => {
    if (e.target.value) {
      setSelectValue([...selectValue, e.target.value]);
      setPickUpSelectBoxOptions([
        ...pickUpSelectBoxOptions,
        e.target.options[e.target.selectedIndex].text,
      ]);
    } else {
      setSelectValue([...selectValue, e.target.value]);
    }

    const options = e.target.options;
    Array.from(options).map((option, index) => {
      if (index === 0) {
        option.selected = true;
      }
    });
  };

  const noSelectDate = current => {
    if (inputType === 'inputDate') {
      return (
        current && current < moment(disabledDate, 'YYYY/M/D').add(-1, 'day').endOf('day')
      );
    } else if (inputType === 'inputMonth') {
      return (
        current && current < moment(disabledDate, 'YYYY/M').endOf('month').add(-1, 'day')
      );
    }
  };

  const noSelectFutureDate = current => {
    if (inputType === 'inputDate') {
      return (
        current &&
        current > moment(disabledFutureDate, 'YYYY/M/D').add(0, 'day').endOf('day')
      );
    } else if (inputType === 'inputMonth') {
      return (
        current &&
        current > moment(disabledFutureDate, 'YYYY/M').endOf('month').add(0, 'day')
      );
    }
  };

  //選択したものが表示されるセレクトボックスの値を削除する処理ーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const valueDelete = e => {
    let targetParentElement = document.getElementsByClassName(
      'pick_item ' + inputName
    );
    let targetElement = e.target.parentNode.parentNode;

    targetParentElement = [].slice.call(targetParentElement);
    let deleteTargetElementNumber = targetParentElement.indexOf(targetElement);

    setSelectValue(
      pickUpSelectBoxValues.filter((value, index) => value !== targetElement.id)
    );
    setPickUpSelectBoxOptions(
      pickUpSelectBoxOptionsFilter.filter(
        (value, index) => index !== deleteTargetElementNumber
      )
    );
  };

  //チェックボックスの値を変更する処理ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const onClickCheckBox = e => {
    if (inputType === 'checkBox' && Array.isArray(inputName)) {
      setState({ ...state, checkValue: e.target.checked });
    } else {
      setFieldValue && setFieldValue(inputNameCheckBox, e.target.checked);
      setState({ ...state, check: e.target.checked });
    }
  };

  //複数のチェックボックスの時のstate管理ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const onClickCheckBoxMultiple = e => {
    let targetCheckBoxValue = e.target.value;
    if (targetCheckBoxValue === 'true') {
      setCheckBoxMultipleValue(prevState =>
        prevState.map((value, index) => (index == e.target.id ? false : value))
      );
    } else {
      setCheckBoxMultipleValue(prevState =>
        prevState.map((value, index) => (index == e.target.id ? true : value))
      );
    }
  };

  //アイコンを押した時の処理ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const toggleIcon = e => {
    if (inputType === 'inputPassword') {
      state.toggle
        ? setState({ ...state, toggle: false, isInputType: 'password' })
        : setState({ ...state, toggle: true, isInputType: 'text' });
    } else if (
      inputType === 'inputTime' ||
      inputType === 'inputDate' ||
      inputType === 'inputMonth'
    ) {
      let targetInputElement = document.getElementById(inputName);
      targetInputElement.value = '';
      !targetInputElement.value && setState({ ...state, toggle: false });
    }
  };

  //selectBoxWithAddButtonのマウスオーバーした時の処理ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const selectOnMouseEnter = () => {
    let targetAddButton = document.getElementById(
      inputName ? inputName + '_add_button' : ''
    );
    targetAddButton.style.backgroundColor = '#0056D3';
    setState({ ...state, selectAddButton: true });
  };

  //selectBoxWithAddButtonのマウスリーブした時の処理ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  const selectOnMouseLeave = () => {
    let targetAddButton = document.getElementById(
      inputName ? inputName + '_add_button' : ''
    );
    targetAddButton.style.backgroundColor = '#7B7B7B';
    setState({ ...state, selectAddButton: false });
  };

  //セレクトボックスアドボタンを押した時の処理
  const addSelectBox = () => {
    //支払い条件管理＿登録＿締日基準ページに遷移する
  };

  useEffect(() => {
    //セレクトボックスの文字色を変更する処理ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    if (
      inputType === 'selectBox' ||
      inputType === 'selectBoxWithAddButton' ||
      inputType === 'pickUpSelectBox'
    ) {
      let targetSelectBox = document.getElementById(inputName);
      let selectBoxValue = targetSelectBox.value;
      !selectBoxValue
        ? (targetSelectBox.style.color = '#bcbcbc')
        : (targetSelectBox.style.color = '#242424');
    } else if (inputType === 'selectBoxWithSelectBox') {
      let targetSelectBox = document.getElementById(inputName[0]);
      let targetSelectBoxSecond = document.getElementById(inputName[1]);
      let selectBoxValue = targetSelectBox.value;
      let selectBoxValueSecond = targetSelectBoxSecond.value;
      !selectBoxValue
        ? (targetSelectBox.style.color = '#bcbcbc')
        : (targetSelectBox.style.color = '#242424');
      !selectBoxValueSecond
        ? (targetSelectBoxSecond.style.color = '#bcbcbc')
        : (targetSelectBoxSecond.style.color = '#242424');
    }
  });

  const initialCustomSelectValue = value => {
    let aryNum = initialValue?.indexOf(value);
    return selectBoxOptions?.[aryNum];
  };
  const [customSelectValue, setCustomSelectValue] = useState(
    selectedSelectBox ? initialCustomSelectValue(selectedSelectBox) : ''
  );
  const [customSelectValueHidden, setCustomSelectValueHidden] = useState(
    selectedSelectBox ? selectedSelectBox : ''
  );
  const [openOption, setOpenOption] = useState(false);
  const [customSelectIcon, setCustomSelectIcon] = useState(false);

  const toggleOptions = () => {
    // setOpenOption(!openOption);
    setTimeout(() => {
      setOpenOption(!openOption);
    }, 180);
  };
  const clickCustomSelectOption = e => {
    // return new Promise(resolve => {
    //   let clickOption = e.target.firstChild.textContent;
    //   let clickOptionNum = e.currentTarget.getAttribute('data-num');
    //   setCustomSelectValue(clickOption);
    //   // setCustomSelectValueHidden(initialValue[clickOptionNum]);
    //
    //   resolve();
    // });
    let clickOption = e.target.firstChild.textContent;
    let clickOptionNum = e.currentTarget.getAttribute('data-num');
    setCustomSelectValue(clickOption);
    setCustomSelectValueHidden(initialValue[clickOptionNum]);
  };
  const toggleCustomSelectIcon = () => {
    setCustomSelectIcon(!customSelectIcon);
    // if (customSelectValue) {
    //   setCustomSelectIcon(!customSelectIcon);
    // }
  };
  const deleteCustomSelectValue = () => {
    setCustomSelectValue('');
  };

  const getSuggestionValue = suggestion => {
    return suggestion;
  };
  const autosuggestInputProps = {
    placeholder,
    value: value,
    onChange,
    onBlur,
    onFocus,
    className:
      error && touched
        ? 'custom_input--input_field_error'
        : 'custom_input--input_field',
  };

  return !inputType || inputType === 'input' ? (
    <div
      className={areaClassName()}
      style={style && style.areaStyle}
      hidden={state.isHidden}
    >
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label
            className={disabled && 'custom_input--label_disabled'}
            htmlFor={inputName}
          >
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      {error && touched && areaClassName() === 'custom_input--area_column' && (
        <div className="custom_input--error">{error}</div>
      )}
      <div className={inputClassName()}>
        <input
          id={inputId ? inputId : inputName}
          name={inputName}
          className={
            error && touched
              ? 'custom_input--input_field_error'
              : 'custom_input--input_field'
          }
          {...(useValue
            ? {
                value,
              }
            : {
                defaultValue: defaultValue ? defaultValue : state.value,
              })}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          type={state.isInputType}
          style={style && style.inputStyle}
          disabled={disabled ? disabled : false}
          onClick={onClick ? onClick : () => {}}
        />
        {unit && (
          <div
            className={
              disabled ? 'custom_input--unit_disable' : 'custom_input--unit'
            }
            style={style && style.unitStyle}
          >
            {unit}
          </div>
        )}
      </div>
    </div>
  ) : //インプットとインプット消す予定ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'inputWithInput' ? (
    <div className={areaClassName()} style={style && style.areaStyle}>
      {/*{label && (*/}
      {/*  <div className={labelClassName()} style={style && style.labelStyle}>*/}
      {/*    <label htmlFor={inputName}>*/}
      {/*      {label}*/}
      {/*      {labelRequired && <span>*</span>}*/}
      {/*    </label>*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*<div className={inputClassName()} style={style && style.inputStyle}>*/}
      {/*  {inputName.map((obj, idx) => (*/}
      {/*    <div*/}
      {/*      className={'custom_input--input_with_input_with_unit'}*/}
      {/*      key={'inputWithInput' + inputName[0] + idx}*/}
      {/*    >*/}
      {/*      <input*/}
      {/*        id={inputName[idx]}*/}
      {/*        name={inputName[idx]}*/}
      {/*        className={*/}
      {/*          error*/}
      {/*            ? 'custom_input--input_field_error'*/}
      {/*            : 'custom_input--input_field'*/}
      {/*        }*/}
      {/*        defaultValue={inputWithInputValue['value_' + idx]}*/}
      {/*        placeholder={placeholder && placeholder[idx]}*/}
      {/*        onChange={handleChange}*/}
      {/*        type={state.isInputType}*/}
      {/*        style={style && style.inputStyle}*/}
      {/*        disabled={disabled ? disabled : false}*/}
      {/*        data-num={idx}*/}
      {/*      />*/}
      {/*      {unit && unit[idx] && (*/}
      {/*        <div className={'custom_input--unit'}>{unit[idx]}</div>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}
    </div>
  ) : //セレクトボックス削除予定ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'selectBox' ? (
    <div className={areaClassName()} style={style && style.areaStyle}>
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label htmlFor={inputName}>
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      <div className={inputClassName()}>
        <div className="custom_input--select_box_input_with_icon">
          <select
            id={inputName}
            name={inputName}
            {...(useValue
              ? {
                  value,
                }
              : {
                  defaultValue: selectedSelectBox ? selectedSelectBox : '',
                })}
            onChange={onChange ? onChange : handleChange}
            className={
              error && touched
                ? 'custom_input--input_field_error'
                : 'custom_input--select_box_input_field'
            }
            onBlur={onBlur}
            style={style && style.inputStyle}
            disabled={disabled ? disabled : false}
          >
            {placeholder && (
              <option value="" hidden>
                {placeholder}
              </option>
            )}
            {initialValue.map((value, idx) => (
              <option value={value} key={value}>
                {selectBoxOptions[idx]}
              </option>
            ))}
          </select>
          <span className="custom_input--icon_field">
            <img
              className="select_icon"
              src={disabled ? Icons.icon.downGr : Icons.icon.downNv}
            />
          </span>
        </div>
        {unit && <div className="custom_input--select_box_unit">{unit}</div>}
      </div>
    </div>
  ) : //インプットとチェックボックスのセットーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'inputWithCheckBox' ? (
    <div className="custom_input--input_with_check_box_area">
      <div className={areaClassName()} style={style && style.areaStyle}>
        {label[0] && (
          <div className={labelClassName()} style={style && style.labelStyle}>
            <label htmlFor={inputName}>
              {label[0]}
              {labelRequired && <span>*</span>}
            </label>
          </div>
        )}
        {error &&
          touched &&
          areaClassName() === 'custom_input--area_column' && (
            <div className="custom_input--error">{error}</div>
          )}
        <div className={inputClassName()}>
          <input
            id={inputName}
            name={inputName}
            className={
              error && touched
                ? 'custom_input--input_field_error'
                : 'custom_input--input_field'
            }
            defaultValue={state.inputWithCheckBoxValue}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={onBlur}
            type={state.isInputType}
            style={style && style.inputStyle}
            disabled={disabled ? disabled : false}
          />
        </div>
      </div>
      <div className="custom_input--input_with_checkbox">
        <input
          id={inputNameCheckBox}
          name={inputNameCheckBox}
          value={state.check}
          className="custom_input--input_with_checkbox_field"
          onClick={onClickCheckBox}
          defaultChecked={checkBoxChecked ? checkBoxChecked : false}
          type="checkbox"
        />
        <label htmlFor={inputNameCheckBox}>{label[1]}</label>
      </div>
    </div>
  ) : //インプットデータとインプットタイムとインプットマンスーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'inputDate' ||
    inputType === 'inputTime' ||
    inputType === 'inputYear' ||
    inputType === 'inputMonth' ? (
    <div className={areaClassName()} style={style && style.areaStyle}>
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label htmlFor={inputName}>
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      {error && touched && areaClassName() === 'custom_input--area_column' && (
        <div className="custom_input--error">{error}</div>
      )}
      <div className={inputClassName()}>
        {inputType === 'inputDate' ? (
          <DatePicker
            id={inputName}
            name={inputName}
            allowClear={true}
            suffixIcon={<img src={Icons.icon.calendar} />}
            clearIcon={<img src={Icons.icon.xCircle} />}
            placeholder={placeholder}
            onChange={handleCalenderChange}
            {...(useValue && {
              value: value ? moment(value, 'YYYY-M-D') : null,
            })}
            onBlur={outClick}
            defaultValue={state.value ? moment(state.value, 'YYYY-M-D') : null}
            showToday={false}
            format={'YYYY/M/D'}
            disabled={disabled ? disabled : false}
            disabledDate={
              customDisabledDate ??
              (disabledDate
                ? noSelectDate
                : disabledFutureDate
                ? noSelectFutureDate
                : null)
            }
            onClick={onClick}
            status={error && touched && 'error'}
            getPopupContainer={trigger => trigger.parentElement}
            inputReadOnly={checkIsSp()}
          />
        ) : inputType === 'inputYear' ? (
          <DatePicker
            id={inputName}
            name={inputName}
            allowClear={allowClear}
            suffixIcon={<img src={Icons.icon.calendar} />}
            clearIcon={<img src={Icons.icon.xCircle} />}
            locale={locale}
            placeholder={placeholder}
            picker="year"
            defaultValue={state?.value ?  moment(state.value, 'YYYY') : ''}
            disabled={disabled ? disabled : false}
            disabledDate={disabledDate ? noSelectDate : null}
            status={error && touched && 'error'}
            onChange={handleCalenderChange}
            {...(useValue && {
              value: value ? moment(value, 'YYYY') : null,
            })}
            getPopupContainer={trigger => trigger.parentElement}
            format={'YYYY'}
            inputReadOnly={checkIsSp()}
          />
        ) : inputType === 'inputMonth' ? (
          <DatePicker
            id={inputName}
            name={inputName}
            allowClear={false}
            suffixIcon={<img src={Icons.icon.calendar} />}
            clearIcon={<img src={Icons.icon.xCircle} />}
            locale={locale}
            placeholder={placeholder}
            picker="month"
            defaultValue={state.value ? moment(state.value, 'YYYY-M') : ''}
            format={'YYYY/M'}
            disabled={disabled ? disabled : false}
            disabledDate={
              customDisabledDate ??
              (disabledDate
                ? noSelectDate
                : disabledFutureDate
                ? noSelectFutureDate
                : null)
            }
            status={error && touched && 'error'}
            onChange={handleCalenderChange}
            {...(useValue && {
              value: value ? moment(value, 'YYYY-M') : null,
            })}
            getPopupContainer={trigger => trigger.parentElement}
            inputReadOnly={checkIsSp()}
          />
        ) : (
          inputType === 'inputTime' && (
            <TimePicker
              id={inputName}
              name={inputName}
              allowClear={true}
              suffixIcon={
                !disabled ? (
                  <img src={Icons.icon.clockGr} />
                ) : (
                  <img src={Icons.icon.clockRgr} />
                )
              }
              clearIcon={<img src={Icons.icon.xCircle} />}
              format={inputTimeFormat}
              placeholder={placeholder}
              onChange={handleCalenderChange}
              onBlur={outClick}
              {...(useValue && {
                value: value ? moment(value, inputTimeFormat) : null,
              })}
              defaultValue={
                state.value ? moment(state.value, inputTimeFormat) : ''
              }
              disabledTime={disabledTime ? disabledTime : null}
              disabled={disabled ? disabled : false}
              status={error && touched && 'error'}
              getPopupContainer={trigger => trigger.parentElement}
              inputReadOnly={checkIsSp()}
            />
          )
        )}
        {unit && <div className="custom_input--select_box_unit">{unit}</div>}
      </div>
    </div>
  ) : //インプットパスワードーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'inputPassword' ? (
    <div className={divOutLine()}>
      <div className={areaClassName()} style={style && style.areaStyle}>
        {label && (
          <div className={labelClassName()} style={style && style.labelStyle}>
            <label htmlFor={inputName}>
              {label}
              {labelRequired && <span>*</span>}
            </label>
          </div>
        )}
        {!submitErrorFlg && error && touched && (
          <div className="custom_input--password_error">{error}</div>
        )}
        <div className={inputClassName()}>
          <input
            id={inputName}
            name={inputName}
            autoComplete={
              autoComplete && autoComplete === true ? 'on' : 'new-password'
            }
            className={
              error && touched
                ? 'custom_input--input_field_error'
                : 'custom_input--input_field'
            }
            defaultValue={state.value}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={onBlur}
            type={state.isInputType}
            style={style && style.inputStyle && style.inputStyle}
            disabled={disabled ? disabled : false}
          />
        </div>
      </div>
      <span className="custom_input--icon_field" onClick={toggleIcon}>
        <img
          className="icon input_password--i_icon"
          src={state.toggle ? Icons.icon.eye : Icons.icon.eyeOff}
        />
      </span>
    </div>
  ) : //セレクトボックスと追加ボタン削除予定ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'selectBoxWithAddButton' ? (
    <div
      id="select_box_add_button_component"
      className={areaClassName()}
      style={style && style.areaStyle}
    >
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label htmlFor={inputName}>
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      <div className={inputClassName()} style={style && style.inputStyle}>
        <div className="custom_input--select_box_input_with_icon">
          <select
            id={inputName}
            name={inputName}
            defaultValue={selectedSelectBox ? selectedSelectBox : ''}
            onChange={onChange ? onChange : handleChange}
            className={
              error && touched
                ? 'custom_input--input_field_error'
                : 'custom_input--select_box_add_button_field'
            }
            onBlur={onBlur}
            style={style && style.inputStyle}
            disabled={disabled ? disabled : false}
          >
            {placeholder && (
              <option value="" hidden>
                {placeholder}
              </option>
            )}
            {initialValue.map((value, idx) => (
              <option value={value} key={value}>
                {selectBoxOptions[idx]}
              </option>
            ))}
          </select>
          <span className="custom_input--icon_field">
            <img
              className="select_icon"
              src={disabled ? Icons.icon.downGr : Icons.icon.downNv}
            />
          </span>
        </div>
        <button
          className="custom_input--select_box_add_button"
          onMouseEnter={selectOnMouseEnter}
          onMouseLeave={selectOnMouseLeave}
          onClick={addSelectBox}
          style={style && style.buttonStyle}
          id={inputName && inputName + '_add_button'}
        >
          <img
            className={'custom_input--select_box_add_button_icon'}
            src={state.selectAddButton ? Icons.icon.plusNv : Icons.icon.plusWh}
          />
        </button>
      </div>
    </div>
  ) : //チェックボックスーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'checkBox' ? (
    mode === 'survey' ? (
      inputName?.map((obj, idx) => (
        <div
          className={areaClassName()}
          style={style && style.areaStyle}
          key={'check_box--' + label[idx] + idx}
        >
          <label
            className={labelClassName()}
            htmlFor={inputName?.[idx] ? inputName?.[idx] : label[idx]}
            style={style && style.labelStyle}
          >
            <input
              id={inputName?.[idx] ? inputName?.[idx] : label[idx]}
              name={inputName?.[idx] ? inputName?.[idx] : label[idx]}
              onClick={onClick ? onClick : onClickCheckBoxMultiple}
              onChange={e => onChange?.(e, idx)}
              defaultChecked={defaultChecked[idx]}
              type="checkbox"
              style={style && style.inputStyle}
              disabled={disabled}
            />
            <span className="check_mark"></span>
          </label>
          <label className={'check_box--label_text'} htmlFor={inputName?.[idx] ? inputName?.[idx] : label[idx]} style={{color: disabled ? '#bcbcbc' : '#242424'}}>
            {label[idx]}
          </label>
        </div>
      ))
    ) :
    mode === 'custom' ? (
      option.map((o, idx) => (
        <div
          className={areaClassName()}
          style={style && style.areaStyle}
          key={'check_box--' + o.label + idx}
        >
          <label
            className={labelClassName()}
            htmlFor={o.label}
            style={style && style.labelStyle}
          >
            <input
              id={o.label}
              name={o.label}
              onChange={e => onChange?.(e, idx)}
              checked={(value ?? []).includes(o.value)}
              type="checkbox"
              style={style && style.inputStyle}
              disabled={disabled}
            />
            <span className="check_mark"></span>
          </label>
          <label className={'check_box--label_text'} htmlFor={o.label}>
            {o.label}
          </label>
        </div>
      ))
    ) : Array.isArray(inputName) ? (
      inputName.map((obj, idx) => (
        <div
          className={areaClassName()}
          style={style && style.areaStyle}
          key={'check_box--' + label[idx] + idx}
        >
          <label
            className={labelClassName()}
            htmlFor={label[idx]}
            style={style && style.labelStyle}
          >
            <input
              id={label[idx]}
              name={label[idx]}
              onClick={onClick ? onClick : onClickCheckBoxMultiple}
              onChange={e => onChange?.(e, idx)}
              defaultChecked={
                Array.isArray(initialValue) &&
                (initialValue[idx] || initialValue.includes(initialValue[idx]))
              }
              type="checkbox"
              style={style && style.inputStyle}
              disabled={disabled}
            />
            <span className="check_mark"></span>
          </label>
          <label className={'check_box--label_text'} htmlFor={label[idx]}>
            {label[idx]}
          </label>
        </div>
      ))
    ) : (
      <div className={areaClassName()} style={style && style.areaStyle}>
        <label
          className={labelClassName()}
          htmlFor={inputName}
          style={style && style.labelStyle}
        >
          <input
            id={inputName}
            name={inputName}
            value={state.check}
            onClick={onClick}
            onChange={onChange}
            defaultChecked={state.value ? true : false}
            type="checkbox"
            style={style && style.inputStyle}
            disabled={disabled}
          />
          <span className="check_mark"></span>
        </label>
        <label className={'check_box--label_text'} htmlFor={inputName} style={style?.textStyle}>
          {label}
        </label>
      </div>
    ) //電話ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  ) : inputType === 'inputTell' ? (
    <div className={areaClassName()} style={style && style.areaStyle}>
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label htmlFor={inputName[0]}>
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      <div className={inputClassName()} style={style && style.inputStyle}>
        {inputName.map((obj, idx) => (
          <React.Fragment key={'input_tel_' + inputName[idx] + idx}>
            <input
              id={inputName[idx]}
              name={inputName[idx]}
              className={
                error && touched
                  ? 'custom_input--input_field_error'
                  : 'custom_input--input_field'
              }
              defaultValue={inputWithInputValue['value_' + idx]}
              placeholder={placeholder && placeholder[idx]}
              onChange={handleChange}
              type={state.isInputType}
              style={style && style.inputStyle}
              disabled={disabled ? disabled : false}
              data-num={idx}
            />
            {unit && unit[idx] && (
              <div
                className="custom_input--select_box_unit"
                style={{ width: 'auto' }}
              >
                {unit[idx]}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  ) : //トグルボタンーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'toggleButton' ? (
    <div className={areaClassName()} style={style && style.areaStyle}>
      <input
        id={inputName}
        name={inputName}
        value={state.check}
        {...(useValue
          ? {
              checked: state.checked,
              onChange,
            }
          : {
              defaultChecked: state.checked,
            })}
        className="custom_input--toggle_input"
        type="checkbox"
        onClick={onClickCheckBox}
        defaultChecked={checkBoxChecked ? checkBoxChecked : false}
        style={style && style.inputStyle}
        disabled={disabled}
      />
      <label className={labelClassName()} style={style && style.labelStyle}>
        <span
          className={
            state.check
              ? 'custom_input--toggle_text_on'
              : 'custom_input--toggle_text_off'
          }
        >
          {toggleButtonText &&
            (state.check ? toggleButtonText.checked : toggleButtonText.default)}
        </span>
      </label>
    </div>
  ) : //テキストボックスーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'textBox' ? (
    <div className={areaClassName()} style={style && style.areaStyle}>
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label htmlFor={inputName}>
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      <div className={inputClassName()}>
        <textarea
          id={inputName}
          name={inputName}
          className={classNames('custom_input--text_area_field', {
            'custom_input--text_area_field_disabled': disabled,
            'custom_input--text_area_field_error': touched && error,
          })}
          {...(useValue
            ? {
                value,
              }
            : {
                defaultValue: defaultValue ? defaultValue : state.value,
              })}
          placeholder={placeholder}
          onChange={handleChange}
          type="text"
          style={style && style.inputStyle}
          disabled={disabled ? disabled : false}
          onBlur={onBlur ? onBlur : () => {}}
        />
        {unit && <div className="custom_input--unit">{unit}</div>}
      </div>
    </div>
  ) : //セレクトウィズセレクトボックス消す予定ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'selectBoxWithSelectBox' ? (
    <div className={areaClassName()} style={style && style.areaStyle}>
      {/*{label && (*/}
      {/*  <div className={labelClassName()} style={style && style.labelStyle}>*/}
      {/*    <label htmlFor={inputName}>*/}
      {/*      {label}*/}
      {/*      {labelRequired && <span>*</span>}*/}
      {/*    </label>*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*<div className={inputClassName()} style={style && style.inputStyle}>*/}
      {/*  {inputName.map((obj, idx) => (*/}
      {/*    <React.Fragment key={'selectBoxWithSelectBox_' + idx}>*/}
      {/*      <div className={'custom_input--select_box_input_with_icon'}>*/}
      {/*        <select*/}
      {/*          id={inputName[idx]}*/}
      {/*          name={inputName[idx]}*/}
      {/*          defaultValue={*/}
      {/*            selectedSelectBox[idx] ? selectedSelectBox[idx] : ''*/}
      {/*          }*/}
      {/*          onChange={handleChange}*/}
      {/*          className="custom_input--select_box_input_field"*/}
      {/*          style={style && style.inputStyle}*/}
      {/*          disabled={disabled ? disabled : false}*/}
      {/*        >*/}
      {/*          {placeholder && (*/}
      {/*            <option value="" hidden>*/}
      {/*              {placeholder[idx]}*/}
      {/*            </option>*/}
      {/*          )}*/}
      {/*          {initialValue[idx].map((value, index) => (*/}
      {/*            <option value={value} key={value}>*/}
      {/*              {selectBoxOptions[idx][index]}*/}
      {/*            </option>*/}
      {/*          ))}*/}
      {/*        </select>*/}
      {/*        <span className="custom_input--icon_field">*/}
      {/*          <img*/}
      {/*            className="select_icon"*/}
      {/*            src={disabled ? Icons.icon.downGr : Icons.icon.downNv}*/}
      {/*          />*/}
      {/*        </span>*/}
      {/*      </div>*/}
      {/*      {unit && unit[idx] && (*/}
      {/*        <div className={'custom_input--select_with_select_unit'}>*/}
      {/*          {unit[idx]}*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </React.Fragment>*/}
      {/*  ))}*/}
      {/*</div>*/}
    </div>
  ) : //選択したものが表示されるセレクトボックス使わないから消す予定ーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'pickUpSelectBox' ? (
    <div
      className="custom_input—pick_up_select_box_area"
      style={style && style.areaStyle}
    >
      {label && (
        <div
          className="custom_input—pick_up_select_box_label"
          style={style && style.labelStyle}
        >
          <label htmlFor={inputName}>
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      <div className="custom_input—pick_up_select_box_input_area">
        <div
          className="custom_input—pick_up_select_box_input_button_area"
          style={style && style.inputStyle}
        >
          <div className="custom_input—pick_up_select_box_input_with_icon">
            <select
              id={`${inputName}_selected_values`}
              name={inputName}
              onChange={pickUpSelectBoxHandleChange}
              className={
                isButton
                  ? 'custom_input--select_box_add_button_field'
                  : 'custom_input--select_box_input_field'
              }
              style={style && style.inputStyle}
              disabled={disabled ? disabled : false}
            >
              {placeholder && (
                <option value="" hidden>
                  {placeholder}
                </option>
              )}
              {initialValue.map((value, idx) => (
                <option value={value} key={value}>
                  {selectBoxOptions[idx]}
                </option>
              ))}
            </select>
            <span className="custom_input--icon_field">
              <img
                className="select_icon"
                src={disabled ? Icons.icon.downGr : Icons.icon.downNv}
              />
            </span>
            <input
              type="hidden"
              name={inputName}
              value={pickUpSelectBoxValues}
            />
          </div>
          {isButton && (
            <button
              className="custom_input--select_box_add_button display_select_box_button"
              onMouseEnter={selectOnMouseEnter}
              onMouseLeave={selectOnMouseLeave}
              onClick={addSelectBox}
              style={style && style.buttonStyle}
              id={inputName && inputName + '_add_button'}
            >
              <img
                className={'display_select_box_button_icon'}
                src={
                  state.selectAddButton ? Icons.icon.plusNv : Icons.icon.plusWh
                }
              />
            </button>
          )}
        </div>
        {pickUpSelectBoxValues.length !== 0 && (
          <div className={'custom_input—pick_up_select_box_value_area'}>
            {pickUpSelectBoxOptionsFilter.map((elm, index) => (
              <div
                id={pickUpSelectBoxValues[index]}
                className={'pick_item ' + inputName}
                key={'pick_up_item_' + index}
              >
                {elm}
                <span
                  className="custom_input--icon_field"
                  onClick={valueDelete}
                >
                  <img className="select_icon" src={Icons.icon.xCircle} />
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : //ラジオボタンーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  inputType === 'radioButton' ? (
    <div
      className={areaClassName()}
      style={style && style.areaStyle}
      onChange={onChange}
    >
      {radioButtonTitle && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <p>{radioButtonTitle}</p>
          {labelRequired ? <span>*</span> : <></>}
        </div>
      )}
      <div className={inputClassName()} style={style && style.inputStyle}>
        {[...Array(radioButtonLabelAndChecked.length)].map((_, idx) => {
          return (
            <div
              className={displayClassName()}
              style={style && style.displayStyle}
              key={initialValue[idx] + String(idx)}
            >
              <label>
                <input
                  id={initialValue[idx]}
                  name={inputName}
                  defaultValue={initialValue[idx]}
                  className={
                    disabled
                      ? 'custom_input--radio_button_input_disable'
                      : 'custom_input--radio_button_input'
                  }
                  type="radio"
                  style={style && style.inputStyle}
                  {...(useValue
                    ? {
                        checked: radioButtonLabelAndChecked[idx].checked,
                        onChange,
                      }
                    : {
                        defaultChecked: radioButtonLabelAndChecked[idx].checked,
                      })}
                  disabled={disabled}
                />
                <span
                  className={
                    disabled
                      ? 'custom_input--radio_button_text_disable'
                      : 'custom_input--radio_button_text'
                  }
                >
                  {radioButtonLabelAndChecked[idx].label}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  ) : inputType === 'select' ? (
    <div className={areaClassName()} style={style && style.areaStyle}>
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label
            className={disabled && 'custom_input--label_disabled'}
            htmlFor={inputName}
          >
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      <div className={inputClassName()} style={style?.selectStyle}>
        <SelectBox
          inputName={inputName}
          placeholder={placeholder}
          optionValues={initialValue}
          optionTexts={selectBoxOptions}
          selected={selectedSelectBox}
          disabledOptions={disabledOptions}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          useValue={useValue}
          showSearch={showSearch}
          error={error}
          touched={touched}
          usePopContainer={usePopContainer}
          allowClear={allowClear}
          mode={mode}
        />
        {unit && (
          <div
            className={
              disabled
                ? 'custom_input--select_box_unit_disabled'
                : 'custom_input--select_box_unit'
            }
          >
            {unit}
          </div>
        )}
      </div>
    </div>
  ) : inputType === 'multiSelect' ? (
    <MultiSelectBox
      label={label}
      labelRequired={labelRequired}
      className={className}
      inputName={inputName}
      placeholder={placeholder}
      optionValues={initialValue}
      optionTexts={selectBoxOptions}
      selected={selectedSelectBox}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      style={style}
      useValue={useValue}
      error={error}
      touched={touched}
    />
  ) : inputType === 'selectAddBtn' ? (
    <div className={areaClassName()} style={style && style.areaStyle}>
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label htmlFor={inputName}>
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      <div className={inputClassName()}>
        <SelectBoxAddButton
          inputName={inputName}
          placeholder={placeholder}
          optionValues={initialValue}
          optionTexts={selectBoxOptions}
          selected={selectedSelectBox}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          changePlusColor={changePlusColor}
          onClickAdd={onClickAdd}
          useValue={useValue}
          usePopContainer={usePopContainer}
        />
        {unit && <div className="custom_input--select_box_unit">{unit}</div>}
      </div>
    </div>
  ) : inputType === 'label' ? (
    <div
      className={areaClassName()}
      style={style && style.areaStyle}
      hidden={state.isHidden}
    >
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label htmlFor={inputName}>
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      {error && touched && areaClassName() === 'custom_input--area_column' && (
        <div className="custom_input--error">{error}</div>
      )}
      <div className={inputClassName()}>
        <input
          id={inputId ? inputId : inputName}
          name={inputName}
          className="custom_input--input_field custom_input--no_display_filed"
          {...(useValue
            ? {
                value,
              }
            : {
                defaultValue: defaultValue ? defaultValue : state.value,
              })}
          type="text"
          style={style && style.inputStyle}
        />
        {unit && <div className="custom_input--unit">{unit}</div>}
      </div>
    </div>
  ) : inputType === 'suggest' ? (
    <div
      className={areaClassName()}
      style={style && style.areaStyle}
      hidden={state.isHidden}
    >
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label htmlFor={inputName}>
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      {error && touched && areaClassName() === 'custom_input--area_column' && (
        <div className="custom_input--error">{error}</div>
      )}
      <div className={inputClassName()}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          renderSuggestion={s => {
            return <div className={'input-suggest'}>{s?.venueName}</div>;
          }}
          getSuggestionValue={getSuggestionValue}
          inputProps={autosuggestInputProps}
        />
        {unit && (
          <div className="custom_input--unit" style={style && style.unitStyle}>
            {unit}
          </div>
        )}
      </div>
    </div>
  ) : inputType === 'comboBox' ? (
    <div className={areaClassName()} style={style && style.areaStyle}>
      {label && (
        <div className={labelClassName()} style={style && style.labelStyle}>
          <label
            className={disabled && 'custom_input--label_disabled'}
            htmlFor={inputName}
          >
            {label}
            {labelRequired && <span>*</span>}
          </label>
        </div>
      )}
      <div className={inputClassName()}>
        <AutoComplete
          value={value}
          placeholder={placeholder}
          options={(selectBoxOptions ?? []).map(o => ({
            value: o,
            label: o,
          }))}
          onSelect={data => {}}
          onChange={v => {
            onChange(v);
          }}
          disabled={disabled}
        />
        {unit && (
          <div
            className={
              disabled
                ? 'custom_input--select_box_unit_disabled'
                : 'custom_input--select_box_unit'
            }
          >
            {unit}
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CustomInput;
