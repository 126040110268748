import './style.scss';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { formikUtils } from './formikUtils';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Options from '../../../constants/options';
import Button from '../../../components/button';
import MyPageForSpPage1 from './page1';
import MyPageForSpPage2 from './page2';
import MyPageForSpPage3 from './page3';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from '../../../redux/slice/global';
import Yup from '../../../utils/yupUtil';
import {
  handlerFormikErrors,
} from '../../../utils/fnUtil';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { bankingCodeFetch } from '../../../redux/slice/staff';

const MyPageForSp = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const stepCanvas = useRef(null);
  const { staffId } = useSelector(state => state.account);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const spFormik = useFormik({
    initialValues: { ...formikUtils.initialValues },
    validateOnMount: true,
    validationSchema: Yup.object({
      lastName: Yup.string().nullable().required().label('名前 姓'),
      firstName: Yup.string().nullable().required().label('名前 名'),
      lastNameKana: Yup.string()
        .nullable()
        .required()
        .kana('名前(カナ)セイ')
        .label('名前(カナ)セイ'),
      firstNameKana: Yup.string()
        .nullable()
        .required()
        .kana('名前(カナ)セイ')
        .label('名前(カナ)メイ'),
      mailAddress: Yup.string()
        .nullable()
        .required()
        .email('メールアドレスの形式が異なります。')
        .label('メールアドレス'),
      year: Yup.string().nullable().required().label('[基本情報]生年月日(年)'),
      month: Yup.string().nullable().required().label('[基本情報]生年月日(月)'),
      day: Yup.string().nullable().required().label('[基本情報]生年月日(日)'),
      prefCode: Yup.string().nullable().required().label('[基本情報]都道府県'),
      cityName: Yup.string().nullable().required().label('[基本情報]市区町村'),
      streetName: Yup.string()
        .nullable()
        .required()
        .label('[基本情報]丁目番地'),
      post1: Yup.string().nullable().required().integer('[基本情報]郵便番号'),
      post2: Yup.string().nullable().required().integer('[基本情報]郵便番号'),
      dayTel1: Yup.string()
        .nullable()
        .required()
        .integer('[基本情報]TEL(スマートフォン)'),
      dayTel2: Yup.string()
        .nullable()
        .required()
        .integer('[基本情報]TEL(スマートフォン)'),
      dayTel3: Yup.string()
        .nullable()
        .required()
        .integer('[基本情報]TEL(スマートフォン)'),
      homeTel1: Yup.string().nullable().integer('[基本情報]TEL(自宅)'),
      homeTel2: Yup.string().nullable().integer('[基本情報]TEL(自宅)'),
      homeTel3: Yup.string().nullable().integer('[基本情報]TEL(自宅)'),
      dependents: Yup.string().nullable().integer('[基本情報]扶養人数'),
      nearestStations: Yup.array().of(
        Yup.object({
          stationName: Yup.string().required().label('[交通情報]最寄駅'),
          timeRequired: Yup.string()
            .required()
            .label('[交通情報]最寄駅までの所要時間(分)'),
          wayStation: Yup.string()
            .required()
            .label('[交通情報]最寄駅までの交通手段'),
          busStationNameFrom: Yup.string()
            .when('wayStation', (value, schema) =>
              value === '1' ? schema.required() : schema.nullable()
            )
            .label('[交通情報]バス停名'),
          busStationNameTo: Yup.string()
            .when('wayStation', (value, schema) =>
              value === '1' ? schema.required() : schema.nullable()
            )
            .label('[交通情報]バス停名'),
        })
      ),
      height: Yup.string()
        .nullable()
        .required()
        .label('[身長・体重など]身長(cm)'),
      weight: Yup.string().nullable().integer('[身長・体重など]体重(kg)'),
      shoeSize: Yup.string()
        .nullable()
        .shoeSize('[身長・体重など]靴のサイズ(cm)'),
      admissionYear: Yup.string().nullable().integer('[学歴・資格など]入学年'),
      bankName: Yup.string()
        .nullable()
        .test(
          'bankName',
          '[給与振込情報]金融機関名:必須項目です。',
          (value, context) => {
            if (context.parent.payMethod == 1) {
              return true;
            } else if (context.parent.payMethod == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[給与振込情報]金融機関名'),
      bankCode: Yup.string()
        .nullable()
        .test(
          'bankCode',
          '[給与振込情報]銀行コード:必須項目です。',
          (value, context) => {
            if (context.parent.payMethod == 1) {
              return true;
            } else if (context.parent.payMethod == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[給与振込情報]銀行コード'),
      bankBranchName: Yup.string()
        .nullable()
        .test(
          'bankBranchName',
          '[給与振込情報]支店名:必須項目です。',
          (value, context) => {
            if (context.parent.payMethod == 1) {
              return true;
            } else if (context.parent.payMethod == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[給与振込情報]支店名'),
      bankBranchCode: Yup.string()
        .nullable()
        .test(
          'bankBranchCode',
          '[給与振込情報]支店コード:必須項目です。',
          (value, context) => {
            if (context.parent.payMethod == 1) {
              return true;
            } else if (context.parent.payMethod == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[給与振込情報]支店コード'),
      bankAccountNumber: Yup.string()
        .nullable()
        .test(
          'bankAccountNumber',
          '[給与振込情報]口座番号:必須項目です。',
          (value, context) => {
            if (context.parent.payMethod == 1) {
              return true;
            } else if (context.parent.payMethod == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[給与振込情報]口座番号'),
      bankAccountName: Yup.string()
        .nullable()
        .test(
          'bankAccountName',
          '[給与振込情報]口座名義人(カナ):必須項目です。',
          (value, context) => {
            if (context.parent.payMethod == 1) {
              return true;
            } else if (context.parent.payMethod == 0 && value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .kana('[給与振込情報]口座名義人(カナ)')
        .label('[給与振込情報]口座名義人(カナ)'),
    }),

    onSubmit: async values => {
      const formatValues = {
        ...values,
        nameKana: {
          firstName: values.firstNameKana,
          lastName: values.lastNameKana,
        },
        name: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
        birthday: {
          year: values.year,
          month: values.month,
          day: values.day,
        },
        postCode:
          values.post1 && values.post2
            ? {
                number1: values.post1,
                number2: values.post2,
              }
            : null,
        homeTel:
          values.homeTel1 && values.homeTel2 && values.homeTel3
            ? {
                number1: values.homeTel1,
                number2: values.homeTel2,
                number3: values.homeTel3,
              }
            : null,
        daytimeTel:
          values.dayTel1 && values.dayTel2 && values.dayTel3
            ? {
                number1: values.dayTel1,
                number2: values.dayTel2,
                number3: values.dayTel3,
              }
            : null,
        schoolName: values.profession == '1' ? null : values.schoolName,
        admissionYear: values.profession == '1' ? null : values.admissionYear,
        gender: Number(values.gender),
        taxClassification: values.taxClassification === '甲' ? 0 : 1,
        profession: Number(values.profession),
        // dependents: Number(values.dependents.replace('人', '')),
        holdingItems:
          values.holdingItems.length === 0 ? null : values.holdingItems,
        certifications:
          values.certifications.length === 0 ? null : values.certifications,
      };

      for (let v in formatValues) {
        if (!formatValues[v]) {
          delete formatValues[v];
        }
      }

      await fetchRequest(apiUrl.staff.updateStaff, {
        ...formatValues,
        payMethod: Number(values.payMethod),
        gender: Number(values.gender),
        profession: Number(values.profession),
      }, 'POST')
        .then(result => {
          if (result.code === '0') {
            navigate('/');
            dispatch(globalActions.showSingleModal(`更新しました`));
          } else {
            dispatch(globalActions.showSingleModal(`更新に失敗しました`));
          }
        })
        .catch(() => {
          dispatch(globalActions.showSingleModal(`更新に失敗しました`));
        });
    },
  });

  const handleSubmit = () => {
    notification.destroy();

    spFormik.handleSubmit();

    if (!spFormik.isValid) {
      const primaryError = Object.keys(spFormik.errors);
      let errorPage = 1;

      for (let k in formikUtils.pageGroups) {
        if (formikUtils.pageGroups[k].find(v => v == primaryError[0])) {
          errorPage = Number(k);
        }
      }

      setCurrentPage(errorPage);

      handlerFormikErrors(spFormik.errors);
    }
  };

  const writeSteps = () => {
    const canvas = stepCanvas.current;
    canvas.width = window.innerWidth * 0.92 * 0.9;
    canvas.height = 16;
    const width = canvas.width;

    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#0056D3';
    ctx.beginPath();
    ctx.arc(8, 8, 8, 0, 360 * Math.PI * 2, false);
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.moveTo(16, 8);
    ctx.lineTo(width / 2 - 8, 8);
    ctx.strokeStyle = currentPage > 1 ? '#0056D3' : '#BCBCBC';
    ctx.lineWidth = 2;
    ctx.stroke();

    ctx.fillStyle = currentPage > 1 ? '#0056D3' : '#BCBCBC';
    ctx.beginPath();
    ctx.arc(width / 2, 8, 8, 0, Math.PI * 2, false);
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.moveTo(width / 2 + 8, 8);
    ctx.lineTo(width - 16, 8);
    ctx.strokeStyle = currentPage > 2 ? '#0056D3' : '#BCBCBC';
    ctx.lineWidth = 2;
    ctx.stroke();

    ctx.fillStyle = currentPage > 2 ? '#0056D3' : '#BCBCBC';
    ctx.beginPath();
    ctx.arc(width - 8, 8, 8, 0, Math.PI * 2, false);
    ctx.fill();
    ctx.closePath();
  };

  const scrollTop = (top = 0) => {
    const contentArea = document.querySelector('.main--content_area');

    try {
      contentArea.scroll({
        top: top,
        left: 0,
        behavior: 'smooth',
      });
    } catch (_) {
      contentArea.scrollTo(0, top);
    }
  };

  const getData = async () => {
    const resData = await fetchRequest(apiUrl.staff.detail, {
      staffId: staffId,
      deletedFlag: 0,
    });

    const formatData = {
      ...resData.detail,
      lastName: resData?.detail?.staffName.lastName,
      firstName: resData?.detail?.staffName.firstName,
      lastNameKana: resData?.detail?.staffNameKana.lastName,
      firstNameKana: resData?.detail?.staffNameKana.firstName,
      year: resData?.detail?.birthday?.year,
      month: resData?.detail?.birthday?.month,
      day: resData?.detail?.birthday?.day,
      post1: resData?.detail?.postCode?.number1,
      post2: resData?.detail?.postCode?.number2,
      dayTel1: resData?.detail?.daytimeTel?.number1,
      dayTel2: resData?.detail?.daytimeTel?.number2,
      dayTel3: resData?.detail?.daytimeTel?.number3,
      homeTel1: resData?.detail?.homeTel?.number1,
      homeTel2: resData?.detail?.homeTel?.number2,
      homeTel3: resData?.detail?.homeTel?.number3,
    };

    await spFormik.setValues(formatData);
  };

  useEffect(() => {
    writeSteps();
    scrollTop();
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, []);

  useLayoutEffect(() => {
    dispatch(bankingCodeFetch());
  }, []);

  return (
    <>
      <div className={'myPageSp--wrap'}>
        <div className={'myPageSp--static_area'}>
          <span className={'myPageSp--static_area_notice'}>
            *は必須項目です
          </span>

          <div className={'myPageSp--static_area_step'}>
            <canvas ref={stepCanvas}></canvas>
            <span
              className={'myPageSp--static_area_step_number'}
              style={{ left: '0', color: '#0056D3' }}
            >
              1
            </span>
            <span
              className={'myPageSp--static_area_step_number'}
              style={{
                left: 'calc(50% - 8px)',
                color: currentPage >= 2 ? '#0056D3' : '#BCBCBC',
              }}
            >
              2
            </span>
            <span
              className={'myPageSp--static_area_step_number'}
              style={{
                right: '0',
                color: currentPage >= 3 ? '#0056D3' : '#BCBCBC',
              }}
            >
              3
            </span>
          </div>

          <div
            className={'myPageSp--basic'}
            style={{ display: currentPage === 1 ? 'flex' : 'none' }}
          >
            <p>
              <span>スタッフID　</span>
              {spFormik?.values?.staffId}
            </p>

            <div className={'myPageSp--basic_contents'}>
              <CustomFormikInput
                inputType={'input'}
                inputName={'lastName'}
                label={'名前'}
                placeholder={'姓'}
                labelRequired={true}
                className={{ areaClass: 'column' }}
                formik={spFormik}
                style={{ areaStyle: { width: '100%' } }}
              />

              <CustomFormikInput
                inputType={'input'}
                inputName={'firstName'}
                placeholder={'名'}
                formik={spFormik}
                style={{ areaStyle: { width: '100%' } }}
              />
            </div>

            <div className={'myPageSp--basic_contents'}>
              <CustomFormikInput
                inputType={'input'}
                inputName={'lastNameKana'}
                label={'名前(カナ)'}
                placeholder={'セイ'}
                labelRequired={true}
                className={{ areaClass: 'column' }}
                formik={spFormik}
                style={{ areaStyle: { width: '100%' } }}
              />

              <CustomFormikInput
                inputType={'input'}
                inputName={'firstNameKana'}
                placeholder={'メイ'}
                formik={spFormik}
                style={{ areaStyle: { width: '100%' } }}
              />
            </div>

            <CustomFormikInput
              inputType={'input'}
              inputName={'mailAddress'}
              label={'メールアドレス'}
              placeholder={'メールアドレスを入力してください'}
              labelRequired={true}
              className={{ areaClass: 'column' }}
              formik={spFormik}
              style={{ areaStyle: { width: '100%' } }}
            />
          </div>
        </div>

        <MyPageForSpPage1 spFormik={spFormik} currentPage={currentPage} />
        <MyPageForSpPage2 spFormik={spFormik} currentPage={currentPage} />
        <MyPageForSpPage3 spFormik={spFormik} currentPage={currentPage} />
      </div>

      <div className={'myPageSp--bottom_float'}>
        {currentPage > 1 && (
          <Button
            text={'前に戻る'}
            style={{
              ...Options.buttonStyles.back,
              width: '160px',
              height: '40px',
            }}
            onClick={() => {
              setCurrentPage(currentPage - 1);
              scrollTop();
            }}
          />
        )}
        <Button
          text={currentPage === 3 ? '登録' : '次へ'}
          style={{
            ...Options.buttonStyles.submit,
            width: '160px',
            height: '40px',
          }}
          onClick={
            currentPage === 3
              ? () => {
                  handleSubmit();
                }
              : () => {
                  setCurrentPage(currentPage + 1);
                  scrollTop();
                }
          }
        />
      </div>
    </>
  );
};

export default MyPageForSp;
