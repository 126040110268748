import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  quoteActions,
  quoteInsert,
  quoteUpdate,
  quoteDetailFetch,
  quoteFlowFetch,
} from '../../../redux/slice/quote';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import { useFormik } from 'formik';
import RegisterBase from '../../../components/registerBase';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import {
  handlerFormikFieldChange,
  handlerFormikSubmit,
} from '../../../utils/fnUtil';
import Yup from '../../../utils/yupUtil';
import QuoteTermContents from './quoteTermContents.jsx';
import './style.scss';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { checkIsSp } from '../../../utils/fnUtil';
import moment from 'moment/moment';
import ConfirmModal from '../../../components/modal/confirmModal';

const QuoteRegister = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const [isBack, setIsBack] = useState(false);
  const isEdit = useLocation().pathname.slice(5).includes('edit');
  const [isLock, setIsLock] = useState(false);
  const [quoteStatus, setQuoteStatus] = useState(null);
  const [isCreateUserSkipFlag, setIsCreateUserSkipFlag] = useState(false);
  const isCopy =
    useLocation().pathname.slice(5).includes('register') &&
    location?.state?.copyMode;
  const copyMode = isCopy ? location.state.copyMode : '';
  const [preliminaryList, setPreliminaryList] = useState([]);
  const { confirmModalVisible } = useSelector(state => state.quote);
  const formik = useFormik({
    initialValues: {
      clientId: null, // 取引先
      clientManagerName: '', // 取引先担当者
      matterName: '', // 案件名
      managerUserId: null, // 社内担当者
      managerDeptId: null,
      approvalFlowId: null, // 承認フロー
      payTermsId: null, // 支払条件
      officeNumber: null,
      invoiceRegistrationNumber: '',
      orderStatus: 0, // 受注ステータス
      invoiceTiming: 0, // 請求タイミング
      invoiceNumber: '',
      invoicePlanDates: [''], // 請求予定日
      matterLastDate: '', // 案件最終実施日
      paymentMethod: 0, // 支払方法
      bankMasterId: null,
      matterCategory: {
        category: null, // 案件カテゴリ
        categoryOther: '', // 案件カテゴリインプット
        industryPrimary: null, // 業界大分類
        industryPrimaryOther: null, // 業界中分類
        industrySecondary: null,
      },
      preliminary1: null,
      preliminary2: null,
      preliminary3: null,
      preliminary4: null,
      preliminary5: null,
      note: '',
      workApprovalFlowId: null,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      clientId: Yup.number().nullable().numberRequired('取引先'),
      matterName: Yup.string().required().label('案件名').max(100),
      managerUserId: Yup.number().nullable().numberRequired('社内担当者'),
      approvalFlowId: Yup.number()
        .nullable()
        .required('承認フローを選択してください。'),
      payTermsId: Yup.number().nullable().numberRequired('支払条件'),
      invoicePlanDates: Yup.array().test(
        'invoicePlanDates',
        '請求(予定)日:必須項目です。',
        (value, context) => {
          if (value[0] === '' || value[0] === null || value[0] === undefined) {
            return false;
          } else {
            return true;
          }
        }
      ),
      matterCategory: Yup.object({
        category: Yup.number().nullable().numberRequired('案件カテゴリ'),
        industryPrimary: Yup.number().nullable().numberRequired('業界'),
      }),
      invoiceRegistrationNumber: Yup.string().nullable().max(13).label('登録番号')
    }),

    onSubmit: values => {
      // データ処理
      values.invoicePlanDates = [...new Set(values.invoicePlanDates)].filter(
        s => {
          return s && s.trim();
        }
      );

      switch (isEdit) {
        case true:
          if (isLock) {
            store.dispatch(
              globalActions.showSingleModal(
                'ロックされているため更新できませんでした'
              )
            );
            isBack
              ? navigate(-1)
              : navigate('/quote/register/quotation', {
                  state: { id: location.state.id },
                });
          } else {
            values.quoteNumber = location.state.id;
            dispatch(quoteUpdate(values)).then(({ error, payload }) => {
              if (error) {
                return;
              } else {
                store.dispatch(globalActions.showSingleModal('更新しました'));
                isBack
                  ? navigate(-1)
                  : navigate('/quote/register/quotation', {
                      state: { id: payload.item.quoteNumber },
                    });
              }
            });
          }

          break;
        case false:
          dispatch(quoteInsert(values)).then(({ error, payload }) => {
            dispatch(quoteActions.setQuoteNumber(payload.item.quoteNumber));
            if (error) {
              return;
            } else {
              store.dispatch(globalActions.showSingleModal('登録しました'));
              isBack
                ? navigate(-1)
                : navigate('/quote/register/quotation', {
                    state: {
                      id: payload.item.quoteNumber,
                      copyId: copyMode === 'all' ? location.state.id : '',
                    },
                  });
            }
          });

          break;
      }
    },
  });
  const { createdUser, updatedUser, workApprovalFlowId, approvalFlowId } =
    formik.values;
  const getPreliminaryList = async () => {
    const res = await fetchRequest(
      apiUrl.naming.list,
      { namingClassificationId: 'Index' },
      'GET'
    );

    let ary = res.item;

    ary.forEach((obj, idx) => {
      if (!obj.namingId.includes('PromotionPreliminary')) {
        delete ary[idx];
      }
    });

    ary = ary.filter(v => v);

    const list = ary.map((obj, idx) => {
      return ary[
        ary.findIndex(obj => obj.namingId === `PromotionPreliminary${idx + 1}`)
      ].naming;
    });

    setPreliminaryList(list);
  };

  useEffect(() => {
    getPreliminaryList();

    if (isEdit || isCopy) {
      // 見積詳細取得Apiを叩く処理
      dispatch(quoteDetailFetch({ quoteNumber: location.state.id })).then(
        ({ payload }) => {
          setIsLock(payload.isLock);
          setQuoteStatus(payload?.quoteStatus);
          setIsCreateUserSkipFlag(payload?.isCreateUserSkipFlag);
          formik.setValues(payload.detail);
          dispatch(quoteFlowFetch({ userId: payload.detail.managerUserId }));
          handlerFormikFieldChange(
            formik,
            'workApprovalFlowId',
            payload?.detail?.approvalFlowId
          );
        }
      );
    }
  }, []);

  const infos = {
    updates: isEdit
      ? [
          {
            label: '新規登録:',
            datetime:
              moment(createdUser?.datetime).format('YYYY/MM/DD HH:mm:ss') ??
              '-',
            name: createdUser?.name ?? '-',
          },
          {
            label: '最終更新:',
            datetime:
              moment(updatedUser?.datetime).format('YYYY/MM/DD HH:mm:ss') ??
              '-',
            name: updatedUser?.name ?? '-',
          },
        ]
      : [],
    detail: {
      tabs: {
        quoteTerm: {
          label: '見積情報',
          jsx: () => (
            <QuoteTermContents
              formik={formik}
              preliminaryList={preliminaryList}
            />
          ),
        },
      },
    },
  };

  return (
    // 編集画面
    <>
      <RegisterBase infos={infos} />

      <div
        className="bottom_btn_area"
        style={{ height: checkIsSp() ? '88px' : '88px' }}
      >
        <div
          className="quote_register--submit_buttons"
          style={checkIsSp() ? { alignItems: 'center', justifyContent: 'space-between', gap: '7px' } : {}}
        >
          <Button
            text={`${isEdit ? '更新' : '登録'}して戻る`}
            style={Options.buttonStyles.stampBack}
            onClick={() => {
              setIsBack(true);
              if (workApprovalFlowId && !isCreateUserSkipFlag && (quoteStatus === 3 || quoteStatus === 1)) {
                dispatch(quoteActions.setConfirmFlag(true));
              } else {
                handlerFormikSubmit(formik);
              }
              // workApprovalFlowId
              //   ? dispatch(quoteActions.setConfirmFlag(true))
              //   : handlerFormikSubmit(formik);
            }}
          />
          {isEdit && (
            <Button
              text={`更新せず書類へ`}
              style={Options.buttonStyles.stampApproval}
              onClick={() => {
                setIsBack(false);
                navigate('/quote/register/quotation', {
                  state: { id: location.state.id },
                });
              }}
            />
          )}
          <Button
            text={`${isEdit ? '更新' : '登録'}して書類へ`}
            style={Options.buttonStyles.stampApproval}
            onClick={() => {
              setIsBack(false);
              // workApprovalFlowId && workApprovalFlowId != approvalFlowId
              if (workApprovalFlowId && !isCreateUserSkipFlag && (quoteStatus === 3 || quoteStatus === 1)) {
                dispatch(quoteActions.setConfirmFlag(true));
              } else {
                handlerFormikSubmit(formik);
              }
              // workApprovalFlowId
              //   ? dispatch(quoteActions.setConfirmFlag(true));
              //   : handlerFormikSubmit(formik);
            }}
          />
        </div>
      </div>
      <ConfirmModal
        modalVisible={confirmModalVisible}
        onOk={() => {
          dispatch(quoteActions.setConfirmFlag(false));
          handlerFormikSubmit(formik);
        }}
        onCancel={() => {
          dispatch(quoteActions.setConfirmFlag(false));
        }}
        message={'承認が解除されますが、更新してよろしいですか？'}
      />
    </>
  );
};

export default QuoteRegister;
