import './style.scss';
import { useEffect, useState } from 'react';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Copyright from '../../../components/copyright';
import { useNavigate } from 'react-router-dom';
import Options from '../../../constants/options';
import CsvImportModal from '../../../components/modal/csvImportModal';
import CsvExportModal from '../../../components/modal/csvExportModal';
import { useDispatch, useSelector } from 'react-redux';
import { setCounter } from '../../../redux/slice/counter';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import { fetchApi, fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { checkIsSp } from '../../../utils/fnUtil';

const PartnerList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const counterState = useSelector(state => state.counter);
  const [csvImportVisible, setCsvImportVisible] = useState(false);
  const [csvExportVisible, setCsvExportVisible] = useState(false);
  const [apiData, setApiData] = useState({ item: [] });
  const loginType = useSelector(state => state.loginType.type);
  const account = useSelector(state => state.account);

  const [userList, setUserList] = useState([]);

  const [searchInfo, setSearchInfo] = useState({
    partnerCompanyName: '',
    managerUserId: '',
    status: '',
    offset: 0,
    count: 20,
    sort: {
      column: 'partnerName',
      method: 'Desc',
    },
  });

  const [searchStatus, setSearchStatus] = useState('');
  const [searchManagerUserId, setSearchManagerUserId] = useState('');

  const searchFormInfo = {
    inputs: [
      {
        inputType: 'input', //inputのtype
        label: '協力会社名', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'searchPartnerName', //DBのカラム名
        placeholder: '協力会社名を入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: 400 },
        },
      },
      {
        inputType: 'select', //inputのtype
        showSearch: true,
        mode: 'multiple',
        allowClear: false,
        label: '社内担当者', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'searchManagerUser', //DBのカラム名
        placeholder: '担当者を選択してください',
        initialValue: userList
          ?.filter(u => u.deletedFlag === '0')
          ?.map(
            obj => `${obj.name.lastName}${obj.name.firstName}_${obj.userId}`
          ),
        selectBoxOptions: userList
          ?.filter(u => u.deletedFlag === '0')
          ?.map(
            obj => `${obj.userId}:${obj.name.lastName} ${obj.name.firstName}`
          ),
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: 400 },
        },
        key: userList[0] ? userList[0] : '',
        onChange: e =>
          setSearchManagerUserId(
            e
              .toString()
              .split(',')
              .map(v => v.split('_')[1])
              .join(',')
          ),
      },
      {
        inputType: 'select',
        showSearch: false,
        allowClear: false,
        label: 'ステータス', //ラベルの名前
        labelRequired: false, //ラベルの※マークの有無
        inputName: 'searchStatus',
        initialValue: [99, 1, 0, -1],
        selectBoxOptions: ['すべて', '表示中', '非表示', '削除済'],
        selectedSelectBox: 'すべて',
        placeholder: 'すべて',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : 150 },
        },
        onChange: e => setSearchStatus(e),
      },
    ],
  };

  const toggleCsvImportVisible = bool => {
    setCsvImportVisible(bool === undefined ? !csvImportVisible : bool);
  };
  const toggleCsvExportVisible = bool => {
    setCsvExportVisible(bool === undefined ? !csvExportVisible : bool);
  };

  const csvExportFunction = category => {
    alert(category);
  };

  const changeSort = e => {
    if (e.target.id === searchInfo.sort.column) {
      setSearchInfo({
        ...searchInfo,
        sort: {
          ...searchInfo.sort,
          method: searchInfo.sort.method === 'Desc' ? 'Asc' : 'Desc',
        },
      });
    } else {
      setSearchInfo({
        ...searchInfo,
        sort: {
          column: e.target.id,
          method: 'Desc',
        },
      });
    }
  };

  const columnSettings = {
    partnerId: { label: '協力会社ID', sort: 'desc', dataName: 'partnerId' },
    partnerName: { label: '協力会社名', sort: 'none', dataName: 'partnerName' },
    partnerManagerName: {
      label: '協力会社担当者',
      sort: 'none',
      dataName: 'partnerManagerName',
    },
    managerUserName: {
      label: '社内担当者',
      sort: 'none',
      dataName: 'managerUserName',
    },
    status: { label: 'ステータス', sort: 'none', dataName: 'status' },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    import: {
      icon: Icons.icon.import,
      text: 'CSV取込',
      onClick: () => {
        toggleCsvImportVisible();
      },
      style: Options.buttonStyles.csv,
    },
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => {
        toggleCsvExportVisible();
      },
      style: Options.buttonStyles.csv,
    },
    addButton: {
      props: {
        type: '', //roundを指定するとボタンがまるくなる
        onClick: () => navigate('/master/partner/register'), //ボタンをクリックしたときにfunctionが発火する（urlより優先される）
        url: '', //ボタンをクリックした時の遷移先を指定する「'/main/invoice/list'」
        displayText: true, // trueを指定すると「新規追加」吹き出しが表示される
        disabled: '', //trueを指定するとdisableになる
        style: { cursor: 'pointer' }, //styleを指定することが可能になる
      },
    },
  };

  const updateApiData = data => {
    const titleCount = [data?.totalCount ?? 0, data?.activeCount ?? 0];

    const formatData = {
      ...data,
      item: data.item.map((obj, index) => {
        return obj.status === -1
          ? { ...obj, status: '削除済', editIconState: 1, deleteIconState: 2 }
          : obj.status === 1
          ? { ...obj, status: '表示中' }
          : obj.status === 0
          ? { ...obj, status: '非表示' }
          : obj;
      }),
    };

    setApiData(formatData);
    dispatch(setCounter({ ...counterState, partnerCounter: titleCount }));
    store.dispatch(globalActions.setLoading(false));
  };

  const getApiData = async () => {
    let apiData;
    let requestBody = { ...searchInfo };
    for (let k in requestBody) {
      if (requestBody[k] === '' || requestBody[k] === undefined) {
        delete requestBody[k];
      }
    }

    store.dispatch(globalActions.setLoading(true));

    await fetchApi(
      'partner',
      'list',
      'GET',
      loginType,
      account.corporateId,
      account.accessToken,
      {
        ...requestBody,
        sort: `${searchInfo.sort.column}${searchInfo.sort.method}`,
      }
    )
      .then(data => {
        apiData = data;

        apiData = { ...apiData, count: data.searchCount };
        apiData.item = apiData.item.map((obj, idx) => {
          return {
            ...obj,
            managerUserName: `${obj.managerUserName?.lastName ?? ''} ${
              obj.managerUserName?.firstName ?? ''
            }`,
          };
        });
      })
      .then(() => {
        updateApiData(apiData);
      })
      .catch(error => {
        navigate('/');
        store.dispatch(globalActions.setLoading(false));
        store.dispatch(
          globalActions.showSingleModal(
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
          )
        );
      });

    await fetchApi(
      'user',
      'list',
      'GET',
      loginType,
      account.corporateId,
      account.accessToken,
      { count: 100000, offset: 0 }
    )
      .then(data => {
        setUserList(data.item);
      })
      .catch(error => {
        navigate('/');
        store.dispatch(globalActions.setLoading(false));
        store.dispatch(
          globalActions.showSingleModal(
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
          )
        );
      });
  };

  useEffect(() => {
    // document.getElementsByClassName('add_button--text')[0].innerHTML =
    //   '新規登録';

    getApiData();
  }, [searchInfo]);

  const handleSearch = () => {
    const pName = document.getElementById('searchPartnerName')?.value;

    setSearchInfo({
      ...searchInfo,
      partnerName: pName,
      managerUserId: searchManagerUserId,
      status: searchStatus || searchStatus === 0 ? searchStatus : '',
    });
  };

  const handleDelete = key => {
    fetchRequest(apiUrl.partner.delete, { partnerCompanyId: key }, 'POST').then(
      () => {
        store.dispatch(globalActions.showSingleModal('削除しました'));
        getApiData();
      }
    );
  };

  const handleChangeListPerPage = num => {
    setSearchInfo({ ...searchInfo, count: num });
  };

  const handleChangePage = num => {
    setSearchInfo({ ...searchInfo, offset: num });
  };

  if (!account.menuPartnerListFlag) {
    navigate('/');
    store.dispatch(
      globalActions.showSingleModal('ご利用いただけないメニューです')
    );
  }

  return (
    <>
      <div className={'commonPage--base'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'partnerId'}
          primaryName={'partnerName'}
          apiData={apiData}
          sortSettings={{
            sortColumn: searchInfo.sort.column,
            sortMethod: searchInfo.sort.method,
          }}
          changeSort={changeSort}
          onSearch={handleSearch}
          onDelete={handleDelete}
          onChangeCount={handleChangeListPerPage}
          onChangeOffset={handleChangePage}
          displayNotice={false}
        />
        <Copyright />
      </div>
      <CsvImportModal
        importType={'partnerList'}
        csvImportVisible={csvImportVisible}
        setCsvImportVisible={toggleCsvImportVisible}
      />

      <CsvExportModal
        exportType={'partnerList'}
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={toggleCsvExportVisible}
        exportFunction={csvExportFunction}
        searchParams={searchInfo}
      />
    </>
  );
};

export default PartnerList;
