import './style.scss';
import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import { useEffect } from 'react';
import Button from '../../../components/button';
import { useLocation } from 'react-router-dom';
import Options from '../../../constants/options';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import CustomInput from '../../../components/customInput';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { notification } from 'antd';
import { fetchApi, fetchRequest, showApiError } from '../../../api/fetch';
import { encryptPass, handlerFormikSubmit } from '../../../utils/fnUtil';
import Yup from '../../../utils/yupUtil';
import { useFormik } from 'formik';
import { cloneDeep, isNull, isNumber, values } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from '../../../redux/slice/global';
import user, { userActions, userListFetch } from '../../../redux/slice/user';
import { quoteFlowFetch } from '../../../redux/slice/quote';
import * as yup from 'yup';
import { store } from '../../../redux/store';
import { apiUrl } from '../../../api/apiUrl';
import { checkIsSp } from '../../../utils/fnUtil';

const moment = require('moment');

const style = {
  areaStyle: checkIsSp() ? { width: '165px' } : {},
  labelStyle: { width: checkIsSp() ? '100%' : '110px' },
  inputStyle: {},
};

const retireStyle = {
  areaStyle: checkIsSp()
    ? { width: '165px', marginLeft: '0px' }
    : { marginLeft: '-50px' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const userStyle = {
  areaStyle: { width: checkIsSp() ? '100%' : '415px' },
  labelStyle: { width: '110px' },
  inputStyle: { width: checkIsSp() ? '100%' : '281px' },
};

const approveStyle = {
  areaStyle: { width: checkIsSp() ? '100%' : '85%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const departmentStyle = {
  areaStyle: { width: checkIsSp() ? '100%' : '50%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const parentStyle = {
  areaStyle: {
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  labelStyle: {},
  inputStyle: {},
  textStyle: {
    fontWeight: 'bold',
  },
};

const childStyle = {
  areaStyle: {
    marginLeft: '40px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  labelStyle: {},
  inputStyle: {},
};

const tabStyle = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'column',
  gap: '20px',
  marginTop: checkIsSp() ? 0 : '26px',
  marginLeft: checkIsSp() ? '0px' : '70px',
  minWidth: checkIsSp() ? '100%' : '800px',
};

const remarkStyle = {
  areaStyle: { width: checkIsSp() ? '100%' : '85%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const authority = ['システム管理者', '法人管理者', '社員'];
const authority2 = ['法人管理者', '社員'];
const authority3 = ['社員'];
const detail = {
  userId: null,
  mailAddress: null,
  deletedFlag: null,
  userImage: null,
  name: {
    lastName: null,
    firstName: null,
  },
  nameKana: {
    lastName: null,
    firstName: null,
  },
  startDate: null,
  endDate: null,
  joinDate: null,
  retireDate: null,
  deptId: null,
  approvalFlowIdList: [],
  referenceUserId: null,
  auth: null,
  availableMenus: {
    menuQuoteListFlag: null,
    menuQuoteApprovalListFlag: null,
    menuQuoteDocumentNumberFlag: null,
    menuQuoteTemplateListFlag: null,
    menuQuoteFlowListFlag: null,
    menuMatterListFlag: null,
    menuMatterDateListFlag: null,
    menuMatterAssignListFlag: null,
    menuMatterOpenListFlag: null,
    menuMatterAppliedListFlag: null,
    menuInvoiceListFlag: null,
    menuStaffListFlag: null,
    menuStaffPlanListFlag: null,
    menuStaffPayListFlag: null,
    menuStaffPayYearListFlag: null,
    menuAttendClosingFlag: null,
    menuAnalyzeTrendFlag: null,
    menuAnalyzeProfitFlag: null,
    menuClientListFlag: null,
    menuVenueListFlag: null,
    menuSettingScheduleFlag: null,
    menuUserListFlag: null,
    menuPartnerListFlag: null,
    menuCorporateListFlag: null,
    menuPayTermListFlag: null,
    menuNamingListFlag: null,
    menuIncomeTaxListFlag: null,
  },
  unlockQuoteFlag: null,
  skipQuoteApprovalFlag: null,
  unlockInvoiceFlag: null,
  preliminary1: null,
  preliminary2: null,
  preliminary3: null,
  preliminary4: null,
  preliminary5: null,
  note: null,
  createdUser: {
    id: null,
    name: null,
    datetime: '',
  },
  updatedUser: {
    id: null,
    name: null,
    datetime: '',
  },
};
const namingValidList = [
  'UserPreliminary1',
  'UserPreliminary2',
  'UserPreliminary3',
  'UserPreliminary4',
  'UserPreliminary5',
];
const UserRegister = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const isEdit = location.pathname.slice(5).includes('edit');
  const loginType = useSelector(state => state.loginType.type);
  const accountInfo = useSelector(state => state.account);
  const isUser = accountInfo?.auth === 2;
  const [apiData, setApiData] = useState(detail);
  const [idxClassNaming, setIdxClassNaming] = useState(null);
  const [idxNaming, setIdxNaming] = useState(null);
  const [deptNaming, setDeptNaming] = useState(null);
  const [appNaming, setAppNaming] = useState(null);
  const [referenceFlg, setReferenceFlg] = useState(false);
  const [isUserFlag, setIsUserFlag] = useState(false);
  const { userList } = useSelector(state => state.user);
  const { flowList } = useSelector(state => state.quote);
  const getApiData = async () => {
    let apiDataTem;
    await fetchApi(
      'user',
      'detail',
      'GET',
      // 'staff',
      // '123',
      // 'test',
      loginType,
      accountInfo.corporateId,
      accountInfo.accessToken,
      { userId: location.state.id, deletedFlag: location.state.deletedFlag }
    ).then(data => {
      apiDataTem = data;
    });

    if (apiDataTem?.deptId) {
      dispatch(
        quoteFlowFetch({
          deptId: apiDataTem?.deptId,
          count: 999,
          sort: 'approvalFlowNameAsc',
        })
      )
        .unwrap()
        .then(data =>
          //ここが原因
          formik.setFieldValue(
            'approvalFlowIdList',
            apiDataTem?.approvalFlowIdList?.map(f => String(f))
          )
        );
    }

    if (apiDataTem?.referenceUserId) {
      setReferenceFlg(true);
    }

    setApiData(apiDataTem);
    apiDataTem.auth =
      apiDataTem.auth == 0
        ? 'システム管理者'
        : apiDataTem.auth == 1
        ? '法人管理者'
        : '社員';
    formik.setValues(apiDataTem);
    if (apiDataTem?.deptId) {
      dispatch(
        quoteFlowFetch({
          deptId: apiDataTem?.deptId,
          count: 999,
          sort: 'approvalFlowNameAsc',
        })
      )
        .unwrap()
        .then(data =>
          formik.setFieldValue(
            'approvalFlowIdList',
            apiDataTem?.approvalFlowIdList?.map(f => String(f))
          )
        );
    } else if (!apiDataTem?.deptId) {
      formik.setFieldValue('approvalFlowIdList', []);
    }
    formik.setFieldValue('passwordConfirm', apiDataTem?.password);
  };

  const getDeptNaming = async () => {
    let tem;
    await fetchApi(
      'naming',
      'list',
      'GET',
      // 'staff',
      // '123',
      // 'test',
      loginType,
      accountInfo.corporateId,
      accountInfo.accessToken,
      {
        namingClassificationId: 'Dept',
        sort: 'priorityAsc',
      }
    ).then(data => {
      tem = data;
    });
    setDeptNaming(tem.item);
    getIdxNaming();
  };

  const getIdxNaming = async id => {
    let tem;
    await fetchApi(
      'naming',
      'list',
      'GET',
      // 'staff',
      // '123',
      // 'test',
      loginType,
      accountInfo.corporateId,
      accountInfo.accessToken,
      {
        namingClassificationId:
          'UserPreliminary1,UserPreliminary2,UserPreliminary3,UserPreliminary4,UserPreliminary5',
        sort: 'priorityAsc',
      }
    ).then(data => {
      tem = data;
    });
    setIdxNaming(tem.item);
    getAppNaming();
  };
  const getAppNaming = async id => {
    let tem;
    await fetchApi(
      'approval',
      'list',
      'GET',
      // 'staff',
      // '123',
      // 'test'
      loginType,
      accountInfo.corporateId,
      accountInfo.accessToken
    ).then(data => {
      tem = data;
    });
    setAppNaming(tem.item);
    getIdxClassNaming();
  };
  const getIdxClassNaming = async () => {
    let tem;
    await fetchApi(
      'naming',
      'list',
      'GET',
      // 'staff',
      // '123',
      // 'test',
      loginType,
      accountInfo.corporateId,
      accountInfo.accessToken,
      {
        namingClassificationId: 'Index',
        sort: 'namingIdAsc',
      }
    ).then(data => {
      tem = data;
    });
    setIdxClassNaming(
      tem?.item
        ?.filter(n => namingValidList.includes(n?.namingId ?? ''))
        .map(n => {
          return {
            ...n,
          };
        })
    );
  };
  useEffect(() => {
    if (isEdit) {
      getApiData();
    } else {
      dispatch(userListFetch({ sort: 'userIdDesc' }))
        .unwrap()
        .then(data => {
          let newUserId = data?.userList?.item?.[0]['userId'];
          if (newUserId) {
            formik.setValues({
              ...formik.initialValues,
              userId: Number(newUserId) + 1,
            });
          } else {
            formik.setValues({
              ...formik.initialValues,
              userId: 1
            });
          }
        });
    }
    getDeptNaming();
  }, []);

  useEffect(() => {
    const basicInfoImageAreaWrapOverlay = document.querySelector('.register_base--basic_info_image_area_wrap_overlay');
    basicInfoImageAreaWrapOverlay.style.borderRadius = '50%';
    if (isUser) {
      setReferenceFlg(true);
    }
  }, []);

  const getReferenceInfo = async e => {
    if (
      document.getElementById('referenceUserId').value == '' ||
      document.getElementById('referenceUserId').value == null
    ) {
      if (!isUser) {
        setReferenceFlg(false);
      } else {
        setIsUserFlag(false);
      }
    } else {
      let apiDataTem;
      await fetchApi(
        'user',
        'reference',
        'GET',
        // 'staff',
        // '123',
        // 'test',
        loginType,
        accountInfo.corporateId,
        accountInfo.accessToken,
        { userId: document.getElementById('referenceUserId').value }
      ).then(data => {
        apiDataTem = data;
        notification.destroy();
        if (data.displayMessage !== '') {
          showApiError(data);
          document.getElementById(e.target.id).className =
            'custom_input--input_field_error';
        } else {
          document.getElementById(e.target.id).className =
            'custom_input--input_field';
        }
      });

      if (apiDataTem.userId != '' && apiDataTem.userId != null) {
        if (isUser) {
          setIsUserFlag(true);
        } else {
          setReferenceFlg(true);
        }
        formik.setValues({
          ...formik.values,
          auth:
            apiDataTem.auth == 0
              ? 'システム管理者'
              : apiDataTem.auth == 1
              ? '法人管理者'
              : '社員',
          // availableMenus: apiDataTem.availableMenus,
        });
        document.getElementById(
          'availableMenus.menuAnalyzeProfitFlag'
        ).checked = apiDataTem.availableMenus.menuAnalyzeProfitFlag == 1;
        document.getElementById('availableMenus.menuAnalyzeTrendFlag').checked =
          apiDataTem.availableMenus.menuAnalyzeTrendFlag == 1;
        document.getElementById(
          'availableMenus.menuAttendClosingFlag'
        ).checked = apiDataTem.availableMenus.menuAttendClosingFlag == 1;
        document.getElementById('availableMenus.menuClientListFlag').checked =
          apiDataTem.availableMenus.menuClientListFlag == 1;
        document.getElementById(
          'availableMenus.menuCorporateListFlag'
        ).checked = apiDataTem.availableMenus.menuCorporateListFlag == 1;
        document.getElementById(
          'availableMenus.menuIncomeTaxListFlag'
        ).checked = apiDataTem.availableMenus.menuIncomeTaxListFlag == 1;
        document.getElementById('availableMenus.menuInvoiceListFlag').checked =
          apiDataTem.availableMenus.menuInvoiceListFlag == 1;
        document.getElementById(
          'availableMenus.menuMatterAppliedListFlag'
        ).checked = apiDataTem.availableMenus.menuMatterAppliedListFlag == 1;
        document.getElementById(
          'availableMenus.menuMatterAssignListFlag'
        ).checked = apiDataTem.availableMenus.menuMatterAssignListFlag == 1;
        document.getElementById(
          'availableMenus.menuAssignStaffStatesFlag'
        ).checked = apiDataTem.availableMenus.menuAssignStaffStatesFlag == 1;
        document.getElementById(
          'availableMenus.menuMatterDateListFlag'
        ).checked = apiDataTem.availableMenus.menuMatterDateListFlag == 1;
        document.getElementById('availableMenus.menuMatterListFlag').checked =
          apiDataTem.availableMenus.menuMatterListFlag == 1;
        document.getElementById(
          'availableMenus.menuMatterOpenListFlag'
        ).checked = apiDataTem.availableMenus.menuMatterOpenListFlag == 1;
        document.getElementById('availableMenus.menuNamingListFlag').checked =
          apiDataTem.availableMenus.menuNamingListFlag == 1;
        document.getElementById('availableMenus.menuPartnerListFlag').checked =
          apiDataTem.availableMenus.menuPartnerListFlag == 1;
        document.getElementById('availableMenus.menuPayTermListFlag').checked =
          apiDataTem.availableMenus.menuPayTermListFlag == 1;
        document.getElementById(
          'availableMenus.menuQuoteApprovalListFlag'
        ).checked = apiDataTem.availableMenus.menuQuoteApprovalListFlag == 1;
        document.getElementById(
          'availableMenus.menuQuoteDocumentNumberFlag'
        ).checked = apiDataTem.availableMenus.menuQuoteDocumentNumberFlag == 1;
        document.getElementById(
          'availableMenus.menuQuoteFlowListFlag'
        ).checked = apiDataTem.availableMenus.menuQuoteFlowListFlag == 1;
        document.getElementById('availableMenus.menuQuoteListFlag').checked =
          apiDataTem.availableMenus.menuQuoteListFlag == 1;
        document.getElementById(
          'availableMenus.menuQuoteTemplateListFlag'
        ).checked = apiDataTem.availableMenus.menuQuoteTemplateListFlag == 1;
        document.getElementById(
          'availableMenus.menuSettingScheduleFlag'
        ).checked = apiDataTem.availableMenus.menuSettingScheduleFlag == 1;
        document.getElementById('availableMenus.menuStaffListFlag').checked =
          apiDataTem.availableMenus.menuStaffListFlag == 1;
        document.getElementById('availableMenus.menuStaffPayListFlag').checked =
          apiDataTem.availableMenus.menuStaffPayListFlag == 1;
        document.getElementById(
          'availableMenus.menuStaffPayYearListFlag'
        ).checked = apiDataTem.availableMenus.menuStaffPayYearListFlag == 1;
        document.getElementById(
          'availableMenus.menuStaffPlanListFlag'
        ).checked = apiDataTem.availableMenus.menuStaffPlanListFlag == 1;
        document.getElementById('availableMenus.menuUserListFlag').checked =
          apiDataTem.availableMenus.menuUserListFlag == 1;
        document.getElementById('availableMenus.menuVenueListFlag').checked =
          apiDataTem.availableMenus.menuVenueListFlag == 1;
      } else {
        if (!isUser) {
          setReferenceFlg(false);
        } else {
          setIsUserFlag(false);
        }
      }
      selectChild('mk');
      selectChild('ak');
      selectChild('sk');
      selectChild('stk');
      selectChild('ssk');
      selectChild('shk');
      selectChild('bs');
      selectChild('tk');
      selectChild('hs');
      selectChild('all');
    }
  };

  const shapedData = obj => {
    const values = cloneDeep(obj);

    return new Promise(resolve => {
      if (document.getElementById('availableMenus.menuQuoteListFlag') != null) {
        values.availableMenus.menuQuoteListFlag = document.getElementById(
          'availableMenus.menuQuoteListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuQuoteApprovalListFlag =
          document.getElementById('availableMenus.menuQuoteApprovalListFlag')
            .checked
            ? 1
            : 0;
        values.availableMenus.menuQuoteDocumentNumberFlag =
          document.getElementById('availableMenus.menuQuoteDocumentNumberFlag')
            .checked
            ? 1
            : 0;
        values.availableMenus.menuQuoteTemplateListFlag =
          document.getElementById('availableMenus.menuQuoteTemplateListFlag')
            .checked
            ? 1
            : 0;
        values.availableMenus.menuQuoteFlowListFlag = document.getElementById(
          'availableMenus.menuQuoteFlowListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuMatterListFlag = document.getElementById(
          'availableMenus.menuMatterListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuMatterDateListFlag = document.getElementById(
          'availableMenus.menuMatterDateListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuMatterAssignListFlag =
          document.getElementById('availableMenus.menuMatterAssignListFlag')
            .checked
            ? 1
            : 0;
        values.availableMenus.menuAssignStaffStatesFlag =
          document.getElementById('availableMenus.menuAssignStaffStatesFlag')
            .checked
            ? 1
            : 0;
        values.availableMenus.menuMatterOpenListFlag = document.getElementById(
          'availableMenus.menuMatterOpenListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuMatterAppliedListFlag =
          document.getElementById('availableMenus.menuMatterAppliedListFlag')
            .checked
            ? 1
            : 0;
        values.availableMenus.menuInvoiceListFlag = document.getElementById(
          'availableMenus.menuInvoiceListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuStaffListFlag = document.getElementById(
          'availableMenus.menuStaffListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuStaffPlanListFlag = document.getElementById(
          'availableMenus.menuStaffPlanListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuStaffPayListFlag = document.getElementById(
          'availableMenus.menuStaffPayListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuStaffPayYearListFlag =
          document.getElementById('availableMenus.menuStaffPayYearListFlag')
            .checked
            ? 1
            : 0;
        values.availableMenus.menuAttendClosingFlag = document.getElementById(
          'availableMenus.menuAttendClosingFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuAnalyzeTrendFlag = document.getElementById(
          'availableMenus.menuAnalyzeTrendFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuAnalyzeProfitFlag = document.getElementById(
          'availableMenus.menuAnalyzeProfitFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuClientListFlag = document.getElementById(
          'availableMenus.menuClientListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuVenueListFlag = document.getElementById(
          'availableMenus.menuVenueListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuSettingScheduleFlag = document.getElementById(
          'availableMenus.menuSettingScheduleFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuUserListFlag = document.getElementById(
          'availableMenus.menuUserListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuPartnerListFlag = document.getElementById(
          'availableMenus.menuPartnerListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuCorporateListFlag = document.getElementById(
          'availableMenus.menuCorporateListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuPayTermListFlag = document.getElementById(
          'availableMenus.menuPayTermListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuNamingListFlag = document.getElementById(
          'availableMenus.menuNamingListFlag'
        ).checked
          ? 1
          : 0;
        values.availableMenus.menuIncomeTaxListFlag = document.getElementById(
          'availableMenus.menuIncomeTaxListFlag'
        ).checked
          ? 1
          : 0;
      } else {
        resolve(values);
      }

      resolve(values);
    });
  };

  //パスワードが指定されていない場合乱数でパスワードを設定する処理
  const generatePass = () => {
    let ret = new Array(6);

    const strList = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    const numList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const strLength = Math.floor(Math.random() * (ret.length - 1)) + 1;
    const numLength = ret.length - strLength;

    for (let i = 0; i < strLength; i++) {
      const pos = Math.floor(Math.random() * ret.length);
      if (!ret[pos]) {
        ret[pos] = strList[Math.floor(Math.random() * strList.length)];
      } else {
        i--;
      }
    }

    for (let i = 0; i < numLength; i++) {
      const pos = Math.floor(Math.random() * ret.length);
      if (!ret[pos]) {
        ret[pos] = numList[Math.floor(Math.random() * numList.length)];
      } else {
        i--;
      }
    }

    let newRet = '';
    ret.map(obj => {
      return (newRet = newRet + obj);
    });

    return newRet;
  };

  const nowDate = new Date();

  //メールチェック
  const emailRules = /^[a-z0-9\-._+]{1,64}@+[a-z0-9@._-]*$/;

  //パスワードチェック
  const passWordRules = /^(?=.*[a-z])(?=.*[0-9])([a-zA-Z0-9]{6,})$/;

  const tabErrorGroup = {
    // affiliation: ['deptId', 'approvalFlowIdList'],
    authority: ['auth', 'referenceUserId'],
  };
  const formik = useFormik({
    initialValues: {
      userId: '',
      mailAddress: '',
      password: '',
      passwordConfirm: '',
      deletedFlag: null,
      userImage: null,
      userName: {
        lastName: '',
        firstName: '',
      },
      userNameKana: {
        lastName: '',
        firstName: '',
      },
      phone: null,
      tel: {
        number1: null,
        number2: null,
        number3: null,
      },
      startDate: !isEdit && moment(nowDate).format('YYYY/M/D'),
      endDate: !isEdit && '2999/12/31',
      joinDate: null,
      retireDate: null,
      deptId: null,
      approvalFlowIdList: [],
      referenceUserId: null,
      auth: null,
      availableMenus: {
        mk: 1,
        menuQuoteListFlag: 1,
        menuQuoteApprovalListFlag: 1,
        menuQuoteDocumentNumberFlag: 1,
        menuQuoteTemplateListFlag: 1,
        menuQuoteFlowListFlag: 1,
        menuMatterListFlag: 1,
        menuMatterDateListFlag: 1,
        menuMatterAssignListFlag: 1,
        menuAssignStaffStatesFlag: 1,
        menuMatterOpenListFlag: 1,
        menuMatterAppliedListFlag: 1,
        menuInvoiceListFlag: 1,
        menuStaffListFlag: 1,
        menuStaffPlanListFlag: 1,
        menuStaffPayListFlag: 1,
        menuStaffPayYearListFlag: 1,
        menuAttendClosingFlag: 1,
        menuAnalyzeTrendFlag: 1,
        menuAnalyzeProfitFlag: 1,
        menuClientListFlag: 1,
        menuVenueListFlag: 1,
        menuSettingScheduleFlag: 1,
        menuUserListFlag: 1,
        menuPartnerListFlag: 1,
        menuCorporateListFlag: 1,
        menuPayTermListFlag: 1,
        menuNamingListFlag: 1,
        menuIncomeTaxListFlag: 1,
      },
      unlockQuoteFlag: 0,
      skipQuoteApprovalFlag: 0,
      unlockMatterFlag: 0,
      unlockInvoiceFlag: 0,
      preliminary1: null,
      preliminary2: null,
      preliminary3: null,
      preliminary4: null,
      preliminary5: null,
      note: '',
    },
    validate: values => {
      if (formik.errors.tel) {
        delete formik.errors.tel;
      }
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      userId: Yup.string()
        .required()
        .matches('^[0-9]*$', '社員ID:社員IDは数字で入力してください。')
        .test(
          'userId',
          '社員ID:0から始まる社員IDは無効です。',
          (value, context) => {
            if (value?.slice(0, 1) === '0') {
              return false;
            } else {
              return true;
            }
          }
        )
        .label('社員ID'),
      mailAddress: Yup.string()
        .required()
        .matches(emailRules, { message: 'メールアドレス:形式が異なります。' })
        .email('メールアドレス:形式が異なります。')
        // .test(
        //   'mailAddress',
        //   'メールアドレス:入力したメールアドレスはすでに存在します。',
        //   (value, context) => {
        //     if (!isEdit && userList?.item?.some(u=>u.deleteIconState === 0 && u.mailAddress === value)) {
        //       return false;
        //     } else {
        //       return true;
        //     }
        // })
        .label('メールアドレス'),
      userName: Yup.object({
        lastName: Yup.string().required().label('名前 姓'),
        firstName: Yup.string().required().label('名前 名'),
      }),
      userNameKana: Yup.object({
        lastName: Yup.string()
          .required()
          .kana('名前(カナ)セイ')
          .label('名前(カナ)セイ'),
        firstName: Yup.string()
          .required()
          .kana('名前(カナ)メイ')
          .label('名前(カナ)メイ'),
      }),
      startDate: Yup.date().required().label('利用開始日'),
      endDate: Yup.date().required().label('利用終了日'),
      auth: Yup.string().nullable().required().label('[権限設定]権限'),
      // auth: Yup.string()
      //   .nullable()
      //   .test('auth', '権限:必須項目です。', (value, context) => {
      //     if (value == '' || value == null || value == undefined) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   }),
      password: Yup.string()
        .nullable()
        .matches(passWordRules, {
          message: 'パスワード:6桁以上英数混在で入力してください',
        })
        .label('パスワード'),
      passwordConfirm: Yup.string()
        .nullable()
        .when('password', (value, schema) =>
          !value ? schema.nullable() : schema.required()
        )
        .oneOf(
          [yup.ref('password'), null],
          'パスワード(再入力):パスワードと一致しません。'
        )
        .label('パスワード(再入力)'),
      phone: Yup.string().nullable().phone('TEL'),
      tel: Yup.object({
        number1: Yup.string()
          .nullable()
          .test('tel.number2', 'TEL:形式が異なります。', value => {
            if (formik.errors.phone) {
              return false;
            } else {
              return true;
            }
          }),
        number2: Yup.string()
          .nullable()
          .test('tel.number2', 'TEL:形式が異なります。', value => {
            if (formik.errors.phone) {
              return false;
            } else {
              return true;
            }
          }),
        number3: Yup.string()
          .nullable()
          .test('tel.number3', 'TEL:形式が異なります。', value => {
            if (formik.errors.phone) {
              return false;
            } else {
              return true;
            }
          }),
      }).nullable(),
      deptId: Yup.string().nullable().required().label('[所属情報]部署'),
      approvalFlowIdList: Yup.array().nullable().arrayRequired('[所属情報]承認フロー').label('[所属情報]承認フロー'),
      referenceUserId: Yup.string()
        .nullable()
        // .test(
        //   'referenceUserId',
        //   '[権限設定]参照元社員:存在しない社員IDです',
        //   value => {
        //     if (!!value && userList?.item?.some(u => u?.userId === value)) {
        //       return true;
        //     } else if (
        //       !!value &&
        //       userList?.item?.some(u => u?.userId !== value)
        //     ) {
        //       return false;
        //     } else {
        //       return true;
        //     }
        //   }
        // )
        .label('[権限設定]参照元社員'),
    }),
    onSubmit: async values => {
      await shapedData(values).then(shapedValues => {
        values.availableMenus = shapedValues.availableMenus;

        if (isEdit) {
          values.startDate = moment(values.startDate).format('YYYY/MM/DD');
          values.endDate = moment(values.endDate).format('YYYY/MM/DD');
          values.joinDate =
            values.joinDate && values.joinDate != '' && values.joinDate != null
              ? moment(values.joinDate).format('YYYY/MM/DD')
              : null;
          values.retireDate =
            values.retireDate &&
            values.retireDate != '' &&
            values.retireDate != null
              ? moment(values.retireDate).format('YYYY/MM/DD')
              : null;
          values.userImage = uploadFlg ? userImageSrc : apiData.userImage;
        } else {
          values.userImage = uploadFlg ? userImageSrc : values.userImage;
        }

        if (!isEdit && values?.password) {
          values.password = encryptPass(values?.password);
        } else if (!isEdit && !values?.password) {
          values.password = encryptPass(generatePass());
        }

        values.passwordConfirm = values.password;

        // if (values?.auth === 'システム管理者') {
        //   values.auth = 0;
        // } else if (values?.auth === '法人管理者') {
        //   values.auth = 1;
        // } else if (values?.auth === '社員') {
        //   values.auth = 2;
        // } else {
        //   values.auth = null;
        // }

        if (
          document.getElementById('availableMenus.menuQuoteListFlag') != null
        ) {
          values.unlockQuoteFlag = document.getElementById('unlockQuoteFlag')
            .checked
            ? 1
            : 0;
          values.skipQuoteApprovalFlag = document.getElementById(
            'skipQuoteApprovalFlag'
          ).checked
            ? 1
            : 0;
          values.unlockMatterFlag = document.getElementById('unlockMatterFlag')
            .checked
            ? 1
            : 0;
          values.unlockInvoiceFlag = document.getElementById(
            'unlockInvoiceFlag'
          ).checked
            ? 1
            : 0;
        }

        if (values?.approvalFlowIdList) {
          values.approvalFlowIdList = values?.approvalFlowIdList.map(Number);
        }

        if (!values?.tel?.number1) {
          values.tel = null;
        }

        if (!values?.note) {
          values.note = null;
        }
      });

      store.dispatch(globalActions.setLoading(true));

      switch (isEdit) {
        case true:
          values.userId = location.state.id;

          fetchApi(
            'user',
            'update',
            'POST',
            loginType,
            accountInfo.corporateId,
            accountInfo.accessToken,
            {
              ...values,
              auth:
                values?.auth === 'システム管理者'
                  ? 0
                  : values?.auth === '法人管理者'
                  ? 1
                  : values?.auth === '社員'
                  ? 2
                  : null,
            }
          )
            .then(res => {
              if (res.code === '0') {
                dispatch(globalActions.showSingleModal('更新しました'));
                dispatch(userActions.saveOffset(0));
                dispatch(userActions.saveSearchParams({ count: 20 }));
                navigate(-1);
              } else {
                showApiError(res);
              }
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => store.dispatch(globalActions.setLoading(false)));
          break;
        case false:
          fetchApi(
            'user',
            'insert',
            'POST',
            loginType,
            accountInfo.corporateId,
            accountInfo.accessToken,
            {
              ...values,
              auth:
                values?.auth === 'システム管理者'
                  ? 0
                  : values?.auth === '法人管理者'
                  ? 1
                  : values?.auth === '社員'
                  ? 2
                  : null,
            }
          )
            .then(res => {
              if (res.code === '0') {
                dispatch(globalActions.showSingleModal('登録しました'));
                dispatch(userActions.saveOffset(0));
                dispatch(userActions.saveSearchParams({ count: 20 }));
                navigate(-1);
              } else {
                showApiError(res);
              }
            })
            .catch(error => {
              console.log('失敗', error);
            })
            .finally(() => store.dispatch(globalActions.setLoading(false)));
          break;
      }
    },
  });

  const [uploadFlg, setUploadFlg] = useState(false);

  const [userImageSrc, setUserImageSrc] = useState(
    formik.values.userImage ?? null
  );

  const userImageInputChange = e => {
    let fileData = e.target.files[0];

    if (fileData) {
      if (fileData.size > 1024 * 1024) {
        alert('画像は1MB以下でアップロードしてください。');
      } else {
        const reader = new FileReader();
        reader.onload = async event => {
          const base64Text = event.currentTarget.result;

          const body = {
            image: base64Text,
            contentFlag: '3',
          };

          let imageUrl = '';
          await fetchApi(
            'other',
            'imageUpload',
            'POST',
            // 'staff',
            // '123',
            // 'test',
            loginType,
            accountInfo.corporateId,
            accountInfo.accessToken,
            body
          ).then(data => (imageUrl = data.imageUrl));

          setUserImageSrc(imageUrl);
          setUploadFlg(true);
        };
        reader.readAsDataURL(fileData);
      }
    }
  };

  const handleDept = e => {
    dispatch(
      quoteFlowFetch({ deptId: e, count: 999, sort: 'approvalFlowNameAsc' })
    )
      .unwrap()
      .then(() => formik.setFieldValue('deptId', e ?? null));

    if (!e) {
      formik.setFieldValue('approvalFlowIdList', []);
      formik.touched.approvalFlowIdList = undefined;
    }
  };

  function selectAll(id) {
    if ('mk' == id) {
      document.getElementById('availableMenus.menuQuoteListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById(
        'availableMenus.menuQuoteApprovalListFlag'
      ).checked = document.getElementById(id).checked;
      document.getElementById(
        'availableMenus.menuQuoteDocumentNumberFlag'
      ).checked = document.getElementById(id).checked;
      document.getElementById(
        'availableMenus.menuQuoteTemplateListFlag'
      ).checked = document.getElementById(id).checked;
      document.getElementById('availableMenus.menuQuoteFlowListFlag').checked =
        document.getElementById(id).checked;
    }
    if ('ak' == id) {
      document.getElementById('availableMenus.menuMatterListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById('availableMenus.menuMatterDateListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById(
        'availableMenus.menuMatterAssignListFlag'
      ).checked = document.getElementById(id).checked;
      document.getElementById(
        'availableMenus.menuAssignStaffStatesFlag'
      ).checked = document.getElementById(id).checked;
      document.getElementById('availableMenus.menuMatterOpenListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById(
        'availableMenus.menuMatterAppliedListFlag'
      ).checked = document.getElementById(id).checked;
    }
    if ('sk' == id) {
      document.getElementById('availableMenus.menuInvoiceListFlag').checked =
        document.getElementById(id).checked;
    }
    if ('stk' == id) {
      document.getElementById('availableMenus.menuStaffListFlag').checked =
        document.getElementById(id).checked;
    }
    if ('ssk' == id) {
      document.getElementById('availableMenus.menuStaffPlanListFlag').checked =
        document.getElementById(id).checked;
    }
    if ('shk' == id) {
      document.getElementById('availableMenus.menuStaffPayListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById(
        'availableMenus.menuStaffPayYearListFlag'
      ).checked = document.getElementById(id).checked;
      document.getElementById('availableMenus.menuAttendClosingFlag').checked =
        document.getElementById(id).checked;
    }
    if ('bs' == id) {
      document.getElementById('availableMenus.menuAnalyzeTrendFlag').checked =
        document.getElementById(id).checked;
      document.getElementById('availableMenus.menuAnalyzeProfitFlag').checked =
        document.getElementById(id).checked;
    }
    if ('tk' == id) {
      document.getElementById('availableMenus.menuClientListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById('availableMenus.menuVenueListFlag').checked =
        document.getElementById(id).checked;
    }
    if ('hs' == id) {
      document.getElementById(
        'availableMenus.menuSettingScheduleFlag'
      ).checked = document.getElementById(id).checked;
    }
    if ('all' == id) {
      document.getElementById('availableMenus.menuUserListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById('availableMenus.menuPartnerListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById('availableMenus.menuCorporateListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById('availableMenus.menuPayTermListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById('availableMenus.menuNamingListFlag').checked =
        document.getElementById(id).checked;
      document.getElementById('availableMenus.menuIncomeTaxListFlag').checked =
        document.getElementById(id).checked;
    }
  }
  function selectChild(id) {
    let checkFlg = true;
    if ('mk' == id) {
      if (
        !document.getElementById('availableMenus.menuQuoteListFlag').checked ||
        !document.getElementById('availableMenus.menuQuoteApprovalListFlag')
          .checked ||
        !document.getElementById('availableMenus.menuQuoteDocumentNumberFlag')
          .checked ||
        !document.getElementById('availableMenus.menuQuoteTemplateListFlag')
          .checked ||
        !document.getElementById('availableMenus.menuQuoteFlowListFlag').checked
      ) {
        checkFlg = false;
      }
    }
    if ('ak' == id) {
      if (
        !document.getElementById('availableMenus.menuMatterListFlag').checked ||
        !document.getElementById('availableMenus.menuMatterDateListFlag')
          .checked ||
        !document.getElementById('availableMenus.menuMatterAssignListFlag')
          .checked ||
        !document.getElementById('availableMenus.menuAssignStaffStatesFlag')
          .checked ||
        !document.getElementById('availableMenus.menuMatterOpenListFlag')
          .checked ||
        !document.getElementById('availableMenus.menuMatterAppliedListFlag')
          .checked
      ) {
        checkFlg = false;
      }
    }
    if ('sk' == id) {
      if (
        !document.getElementById('availableMenus.menuInvoiceListFlag').checked
      ) {
        checkFlg = false;
      }
    }
    if ('stk' == id) {
      if (
        !document.getElementById('availableMenus.menuStaffListFlag').checked
      ) {
        checkFlg = false;
      }
    }
    if ('ssk' == id) {
      if (
        !document.getElementById('availableMenus.menuStaffPlanListFlag').checked
      ) {
        checkFlg = false;
      }
    }
    if ('shk' == id) {
      if (
        !document.getElementById('availableMenus.menuStaffPayListFlag')
          .checked ||
        !document.getElementById('availableMenus.menuStaffPayYearListFlag')
          .checked ||
        !document.getElementById('availableMenus.menuAttendClosingFlag').checked
      ) {
        checkFlg = false;
      }
    }
    if ('bs' == id) {
      if (
        !document.getElementById('availableMenus.menuAnalyzeTrendFlag')
          .checked ||
        !document.getElementById('availableMenus.menuAnalyzeProfitFlag').checked
      ) {
        checkFlg = false;
      }
    }
    if ('tk' == id) {
      if (
        !document.getElementById('availableMenus.menuClientListFlag').checked ||
        !document.getElementById('availableMenus.menuVenueListFlag').checked
      ) {
        checkFlg = false;
      }
    }
    if ('hs' == id) {
      if (
        !document.getElementById('availableMenus.menuSettingScheduleFlag')
          .checked
      ) {
        checkFlg = false;
      }
    }
    if ('all' == id) {
      if (
        !document.getElementById('availableMenus.menuUserListFlag').checked ||
        !document.getElementById('availableMenus.menuPartnerListFlag')
          .checked ||
        !document.getElementById('availableMenus.menuCorporateListFlag')
          .checked ||
        !document.getElementById('availableMenus.menuPayTermListFlag')
          .checked ||
        !document.getElementById('availableMenus.menuNamingListFlag').checked ||
        !document.getElementById('availableMenus.menuIncomeTaxListFlag').checked
      ) {
        checkFlg = false;
      }
    }
    document.getElementById(id).checked = checkFlg;
  }

  const handleAuth = e => {
    if (e === 'システム管理者' || e === '法人管理者') {
      document.getElementById('mk').checked = true;
      document.getElementById('shk').checked = true;
      document.getElementById('all').checked = true;
      selectAll('mk');
      selectAll('shk');
      selectAll('all');
    } else if (e === '社員') {
      document.getElementById('mk').checked = false;
      document.getElementById(
        'availableMenus.menuQuoteListFlag'
      ).checked = true;
      document.getElementById(
        'availableMenus.menuQuoteApprovalListFlag'
      ).checked = true;
      document.getElementById(
        'availableMenus.menuQuoteDocumentNumberFlag'
      ).checked = false;
      document.getElementById(
        'availableMenus.menuQuoteTemplateListFlag'
      ).checked = true;
      document.getElementById(
        'availableMenus.menuQuoteFlowListFlag'
      ).checked = false;
      document.getElementById('shk').checked = false;
      document.getElementById(
        'availableMenus.menuStaffPayListFlag'
      ).checked = true;
      document.getElementById(
        'availableMenus.menuStaffPayYearListFlag'
      ).checked = true;
      document.getElementById(
        'availableMenus.menuAttendClosingFlag'
      ).checked = false;
      document.getElementById('all').checked = false;
      document.getElementById('availableMenus.menuUserListFlag').checked = true;
      document.getElementById(
        'availableMenus.menuPartnerListFlag'
      ).checked = true;
      document.getElementById(
        'availableMenus.menuCorporateListFlag'
      ).checked = false;
      document.getElementById(
        'availableMenus.menuPayTermListFlag'
      ).checked = false;
      document.getElementById(
        'availableMenus.menuNamingListFlag'
      ).checked = true;
      document.getElementById(
        'availableMenus.menuIncomeTaxListFlag'
      ).checked = true;
    }
    document.getElementById('ak').checked = true;
    document.getElementById('sk').checked = true;
    document.getElementById('stk').checked = true;
    document.getElementById('ssk').checked = true;
    document.getElementById('bs').checked = true;
    document.getElementById('tk').checked = true;
    document.getElementById('hs').checked = true;
    selectAll('ak');
    selectAll('sk');
    selectAll('stk');
    selectAll('ssk');
    selectAll('bs');
    selectAll('tk');
    selectAll('hs');
    formik.setFieldValue('auth', e);
  };

  const Affiliation = () => (
    <div style={tabStyle}>
      <div className="custom_input--area">
        <CustomFormikInput
          inputType={'inputDate'}
          formik={formik}
          key={'joinDate'}
          label={'入社日'}
          labelRequired={false}
          inputName={'joinDate'}
          disabledFutureDate={formik.values.retireDate}
          placeholder={'YYYY/MM/DD'}
          style={style}
        />
        <CustomFormikInput
          inputType={'inputDate'}
          formik={formik}
          key={'retireDate'}
          label={'退職日'}
          labelRequired={false}
          inputName={'retireDate'}
          disabledDate={formik.values.joinDate}
          placeholder={'YYYY/MM/DD'}
          style={retireStyle}
        />
      </div>
      <CustomFormikInput
        inputType={'select'}
        formik={formik}
        key={'deptId'}
        label={'部署'}
        labelRequired={true}
        inputName={'deptId'}
        initialValue={
          deptNaming == null ? null : deptNaming.map(p => p?.namingId)
        }
        selectBoxOptions={
          deptNaming == null ? null : deptNaming.map(p => p?.naming)
        }
        selectedSelectBox={apiData?.deptId}
        placeholder={'部署を選択してください'}
        style={departmentStyle}
        onChange={handleDept}
      />
      <CustomFormikInput
        inputType={'multiSelect'}
        formik={formik}
        label={'承認フロー'}
        labelRequired={true}
        inputName={'approvalFlowIdList'}
        initialValue={flowList?.map(p => String(p?.approvalFlowId))}
        selectBoxOptions={flowList?.map(p => p?.approvalFlowName)}
        placeholder={'承認フローを選択してください'}
        style={approveStyle}
        disabled={!formik.values.deptId ? true : false}
      />
    </div>
  );

  const Remark = () => (
    <div style={tabStyle}>
      <CustomFormikInput
        inputType={'select'}
        formik={formik}
        label={idxClassNaming
          ?.filter(n => n.namingId === `UserPreliminary1`)
          .map(p => p?.naming)}
        labelRequired={false}
        inputName={'preliminary1'}
        initialValue={idxNaming
          ?.filter(n => n?.namingClassificationId === 'UserPreliminary1')
          .map(p => p?.namingId)}
        selectBoxOptions={idxNaming
          ?.filter(n => n?.namingClassificationId === 'UserPreliminary1')
          .map(p => `${p?.namingId}:${p?.naming}`)}
        selectedSelectBox={apiData?.preliminary1}
        placeholder={'選択してください'}
        style={remarkStyle}
      />
      <CustomFormikInput
        inputType={'select'}
        formik={formik}
        label={idxClassNaming
          ?.filter(n => n.namingId === `UserPreliminary2`)
          .map(p => p?.naming)}
        labelRequired={false}
        inputName={'preliminary2'}
        initialValue={idxNaming
          ?.filter(n => n?.namingClassificationId === 'UserPreliminary2')
          .map(p => p?.namingId)}
        selectBoxOptions={idxNaming
          ?.filter(n => n?.namingClassificationId === 'UserPreliminary2')
          .map(p => `${p?.namingId}:${p?.naming}`)}
        selectedSelectBox={apiData?.preliminary2}
        placeholder={'選択してください'}
        style={remarkStyle}
      />
      <CustomFormikInput
        inputType={'select'}
        formik={formik}
        label={idxClassNaming
          ?.filter(n => n.namingId === `UserPreliminary3`)
          .map(p => p?.naming)}
        labelRequired={false}
        inputName={'preliminary3'}
        initialValue={idxNaming
          ?.filter(n => n?.namingClassificationId === 'UserPreliminary3')
          .map(p => p?.namingId)}
        selectBoxOptions={idxNaming
          ?.filter(n => n?.namingClassificationId === 'UserPreliminary3')
          .map(p => `${p?.namingId}:${p?.naming}`)}
        selectedSelectBox={apiData?.preliminary3}
        placeholder={'選択してください'}
        style={remarkStyle}
      />
      <CustomFormikInput
        inputType={'select'}
        formik={formik}
        label={idxClassNaming
          ?.filter(n => n.namingId === `UserPreliminary4`)
          .map(p => p?.naming)}
        labelRequired={false}
        inputName={'preliminary4'}
        initialValue={idxNaming
          ?.filter(n => n?.namingClassificationId === 'UserPreliminary4')
          .map(p => p?.namingId)}
        selectBoxOptions={idxNaming
          ?.filter(n => n?.namingClassificationId === 'UserPreliminary4')
          .map(p => `${p?.namingId}:${p?.naming}`)}
        selectedSelectBox={apiData?.preliminary4}
        placeholder={'選択してください'}
        style={remarkStyle}
      />
      <CustomFormikInput
        inputType={'select'}
        formik={formik}
        label={idxClassNaming
          ?.filter(n => n.namingId === `UserPreliminary5`)
          .map(p => p?.naming)}
        labelRequired={false}
        inputName={'preliminary5'}
        initialValue={idxNaming
          ?.filter(n => n?.namingClassificationId === 'UserPreliminary5')
          .map(p => p?.namingId)}
        selectBoxOptions={idxNaming
          ?.filter(n => n?.namingClassificationId === 'UserPreliminary5')
          .map(p => `${p?.namingId}:${p?.naming}`)}
        selectedSelectBox={apiData?.preliminary5}
        placeholder={'選択してください'}
        style={remarkStyle}
      />
      <CustomFormikInput
        inputType={'textBox'}
        key={'note'}
        formik={formik}
        label={'備考'}
        labelRequired={false}
        inputName={'note'}
        placeholder={'備考を入力してください'}
        style={remarkStyle}
      />
    </div>
  );
  const Authority = () => (
    <div style={tabStyle}>
      <div
        style={
          checkIsSp()
            ? { flexDirection: 'row', justifyContent: 'flex-start', gap: '8px' }
            : {}
        }
      >
        <div
          className="custom_input--area"
          style={checkIsSp() ? { marginBottom: '20px' } : {}}
        >
          <div className="custom_input--label" style={{ width: '110px' }}>
            <label>見積機能権限</label>
          </div>
          <CustomFormikInput
            inputType={'checkBox'}
            formik={formik}
            label={'ロック解除'}
            inputName={'unlockQuoteFlag'}
            initialValue={
              isEdit
                ? formik.values.unlockQuoteFlag == 1
                  ? ['unlockQuoteFlag']
                  : ''
                : ''
            }
            disabled={isUser}
          />
          <CustomFormikInput
            inputType={'checkBox'}
            formik={formik}
            label={'承認スキップ'}
            inputName={'skipQuoteApprovalFlag'}
            initialValue={
              isEdit
                ? formik.values.skipQuoteApprovalFlag == 1
                  ? ['skipQuoteApprovalFlag']
                  : ''
                : ''
            }
            disabled={isUser}
          />
        </div>
        <div
          className="custom_input--area"
          style={checkIsSp() ? { marginBottom: '20px' } : {}}
        >
          <div className="custom_input--label" style={{ width: '110px' }}>
            <label>案件機能権限</label>
          </div>
          <CustomFormikInput
            inputType={'checkBox'}
            formik={formik}
            label={'ロック解除'}
            inputName={'unlockMatterFlag'}
            initialValue={
              isEdit
                ? formik.values.unlockMatterFlag == 1
                  ? ['unlockMatterFlag']
                  : ''
                : ''
            }
            disabled={isUser}
          />
        </div>
        <div className="custom_input--area">
          <div className="custom_input--label" style={{ width: '110px' }}>
            <label>請求機能権限</label>
          </div>
          <CustomFormikInput
            inputType={'checkBox'}
            formik={formik}
            label={'ロック解除'}
            inputName={'unlockInvoiceFlag'}
            initialValue={
              isEdit
                ? formik.values.unlockInvoiceFlag == 1
                  ? ['unlockInvoiceFlag']
                  : ''
                : ''
            }
            disabled={isUser}
          />
        </div>
      </div>
      <CustomFormikInput
        inputType={'input'}
        key={'referenceUserId'}
        formik={formik}
        label={'参照元社員'}
        inputName={'referenceUserId'}
        placeholder={'参照元社員を入力してください'}
        style={userStyle}
        onBlur={e => getReferenceInfo(e)}
        disabled={isUser && isEdit}
      />
      <CustomFormikInput
        inputType={'select'}
        formik={formik}
        disabled={isUser && isEdit ? true : isUser ? isUserFlag : referenceFlg}
        label={'権限'}
        labelRequired={true}
        inputName={'auth'}
        initialValue={
          accountInfo.auth === 0
            ? authority
            : accountInfo.auth === 1
            ? authority2
            : accountInfo.auth === 2 && authority3
        }
        selectBoxOptions={
          accountInfo.auth === 0
            ? authority
            : accountInfo.auth === 1
            ? authority2
            : accountInfo.auth === 2 && authority3
        }
        placeholder={'権限を選択してください'}
        style={userStyle}
        onChange={handleAuth}
      />

      <div className={'user_register--menu_area'}>
        <div className={'user_register--menu_title'}>
          <span
            style={{
              color: '#646464',
              fontWeight: 'bold',
              width: checkIsSp() ? 'auto' : '104px',
              textAlign: 'right'
          }}>
            利用メニュー
          </span>
          <span style={{color: '#D80245'}}>*</span>
        </div>

        <div className={'user_register--menu_box'}>
          <div>
            <div
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'見積管理'}
                inputName={'mk'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuQuoteListFlag == 1 &&
                    formik.values.availableMenus
                      .menuQuoteApprovalListFlag == 1 &&
                    formik.values.availableMenus
                      .menuQuoteDocumentNumberFlag == 1 &&
                    formik.values.availableMenus
                      .menuQuoteTemplateListFlag == 1 &&
                    formik.values.availableMenus.menuQuoteFlowListFlag == 1
                      ? ['mk']
                      : ''
                    : 'mk'
                }
                style={parentStyle}
                onClick={() => selectAll('mk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'見積一覧'}
                inputName={'availableMenus.menuQuoteListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuQuoteListFlag == 1
                      ? ['menuQuoteListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('mk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'申請一覧'}
                inputName={'availableMenus.menuQuoteApprovalListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus
                      .menuQuoteApprovalListFlag == 1
                      ? ['menuQuoteApprovalListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('mk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'見積書No管理'}
                inputName={'availableMenus.menuQuoteDocumentNumberFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus
                      .menuQuoteDocumentNumberFlag == 1
                      ? ['menuQuoteDocumentNumberFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('mk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'見積テンプレート一覧'}
                inputName={'availableMenus.menuQuoteTemplateListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus
                      .menuQuoteTemplateListFlag == 1
                      ? ['menuQuoteTemplateListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('mk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'見積承認フロー一覧'}
                inputName={'availableMenus.menuQuoteFlowListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuQuoteFlowListFlag == 1
                      ? ['menuQuoteFlowListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('mk')}
              />
            </div>
            <div
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'案件管理'}
                inputName={'ak'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuMatterListFlag == 1 &&
                    formik.values.availableMenus.menuMatterDateListFlag ==
                    1 &&
                    formik.values.availableMenus.menuMatterAssignListFlag ==
                    1 &&
                    formik.values.availableMenus.menuAssignStaffStatesFlag ==
                    1 &&
                    formik.values.availableMenus.menuMatterOpenListFlag ==
                    1 &&
                    formik.values.availableMenus
                      .menuMatterAppliedListFlag == 1
                      ? ['ak']
                      : ''
                    : 'ak'
                }
                style={parentStyle}
                onClick={() => selectAll('ak')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'案件一覧'}
                inputName={'availableMenus.menuMatterListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuMatterListFlag == 1
                      ? ['menuMatterListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('ak')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'実施日別案件一覧'}
                inputName={'availableMenus.menuMatterDateListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuMatterDateListFlag == 1
                      ? ['menuMatterDateListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('ak')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'スタッフ報告状況'}
                inputName={'availableMenus.menuMatterAssignListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuMatterAssignListFlag ==
                    1
                      ? ['menuMatterAssignListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('ak')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'アサインスタッフ状況'}
                inputName={'availableMenus.menuAssignStaffStatesFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuAssignStaffStatesFlag ==
                    1
                      ? ['menuAssignStaffStatesFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('ak')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'スタッフ募集案件一覧'}
                inputName={'availableMenus.menuMatterOpenListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuMatterOpenListFlag == 1
                      ? ['menuMatterOpenListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('ak')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'実施日別募集者一覧'}
                inputName={'availableMenus.menuMatterAppliedListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus
                      .menuMatterAppliedListFlag == 1
                      ? ['menuMatterAppliedListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('ak')}
              />
            </div>
            <div
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'請求管理'}
                inputName={'sk'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuInvoiceListFlag == 1
                      ? ['sk']
                      : ''
                    : 'sk'
                }
                style={parentStyle}
                onClick={() => selectAll('sk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'請求一覧'}
                inputName={'availableMenus.menuInvoiceListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuInvoiceListFlag == 1
                      ? ['menuInvoiceListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('sk')}
              />
            </div>
          </div>
          <div>
            <div
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'スタッフ管理'}
                inputName={'stk'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuStaffListFlag == 1
                      ? ['stk']
                      : ''
                    : 'stk'
                }
                style={parentStyle}
                onClick={() => selectAll('stk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'スタッフ一覧'}
                inputName={'availableMenus.menuStaffListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuStaffListFlag == 1
                      ? ['menuStaffListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('stk')}
              />
            </div>
            <div
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'スタッフシフト管理'}
                inputName={'ssk'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuStaffPlanListFlag == 1
                      ? ['ssk']
                      : ''
                    : 'ssk'
                }
                style={parentStyle}
                onClick={() => selectAll('ssk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'スタッフシフト一覧'}
                inputName={'availableMenus.menuStaffPlanListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuStaffPlanListFlag == 1
                      ? ['menuStaffPlanListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('ssk')}
              />
            </div>
            <div
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'スタッフ支払管理'}
                inputName={'shk'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuStaffPayListFlag ==
                    1 &&
                    formik.values.availableMenus.menuStaffPayYearListFlag ==
                    1 &&
                    formik.values.availableMenus.menuAttendClosingFlag == 1
                      ? ['shk']
                      : ''
                    : 'shk'
                }
                style={parentStyle}
                onClick={() => selectAll('shk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'スタッフ支払一覧'}
                inputName={'availableMenus.menuStaffPayListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuStaffPayListFlag == 1
                      ? ['menuStaffPayListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('shk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'年間給与一覧'}
                inputName={'availableMenus.menuStaffPayYearListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuStaffPayYearListFlag ==
                    1
                      ? ['menuStaffPayYearListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('shk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'給与締め処理'}
                inputName={'availableMenus.menuAttendClosingFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuAttendClosingFlag == 1
                      ? ['menuAttendClosingFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('shk')}
              />
            </div>
            <div
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'分析'}
                inputName={'bs'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuAnalyzeTrendFlag ==
                    1 &&
                    formik.values.availableMenus.menuAnalyzeProfitFlag == 1
                      ? ['bs']
                      : ''
                    : 'bs'
                }
                style={parentStyle}
                onClick={() => selectAll('bs')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'スタッフ動向分析'}
                inputName={'availableMenus.menuAnalyzeTrendFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuAnalyzeTrendFlag == 1
                      ? ['menuAnalyzeTrendFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('bs')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'売上分析'}
                inputName={'availableMenus.menuAnalyzeProfitFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuAnalyzeProfitFlag == 1
                      ? ['menuAnalyzeProfitFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('bs')}
              />
            </div>
          </div>
          <div>
            <div
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'取引先管理'}
                inputName={'tk'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuClientListFlag == 1 &&
                    formik.values.availableMenus.menuVenueListFlag == 1
                      ? ['tk']
                      : ''
                    : 'tk'
                }
                style={parentStyle}
                onClick={() => selectAll('tk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'取引先一覧'}
                inputName={'availableMenus.menuClientListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuClientListFlag == 1
                      ? ['menuClientListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('tk')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'開催地一覧'}
                inputName={'availableMenus.menuVenueListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuVenueListFlag == 1
                      ? ['menuVenueListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('tk')}
              />
            </div>
            <div
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'表示制御設定'}
                inputName={'hs'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuSettingScheduleFlag ==
                    1
                      ? ['hs']
                      : ''
                    : 'hs'
                }
                style={parentStyle}
                onClick={() => selectAll('hs')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'案件スケジュール一覧'}
                inputName={'availableMenus.menuSettingScheduleFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuSettingScheduleFlag ==
                    1
                      ? ['menuSettingScheduleFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('hs')}
              />
            </div>
            <div
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'各種マスタ'}
                inputName={'all'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuUserListFlag == 1 &&
                    formik.values.availableMenus.menuPartnerListFlag == 1 &&
                    formik.values.availableMenus.menuCorporateListFlag ==
                    1 &&
                    formik.values.availableMenus.menuPayTermListFlag == 1 &&
                    formik.values.availableMenus.menuNamingListFlag == 1 &&
                    formik.values.availableMenus.menuIncomeTaxListFlag == 1
                      ? ['all']
                      : ''
                    : 'all'
                }
                style={parentStyle}
                onClick={() => selectAll('all')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                label={'社員一覧'}
                disabled={referenceFlg}
                inputName={'availableMenus.menuUserListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuUserListFlag == 1
                      ? ['menuUserListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('all')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'協力会社一覧'}
                inputName={'availableMenus.menuPartnerListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuPartnerListFlag == 1
                      ? ['menuPartnerListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('all')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'法人一覧'}
                inputName={'availableMenus.menuCorporateListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuCorporateListFlag == 1
                      ? ['menuCorporateListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('all')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'支払条件一覧'}
                inputName={'availableMenus.menuPayTermListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuPayTermListFlag == 1
                      ? ['menuPayTermListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('all')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'名称一覧'}
                inputName={'availableMenus.menuNamingListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuNamingListFlag == 1
                      ? ['menuNamingListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('all')}
              />
              <CustomFormikInput
                inputType={'checkBox'}
                formik={formik}
                disabled={referenceFlg}
                label={'所得税一覧'}
                inputName={'availableMenus.menuIncomeTaxListFlag'}
                initialValue={
                  isEdit || referenceFlg
                    ? formik.values.availableMenus.menuIncomeTaxListFlag == 1
                      ? ['menuIncomeTaxListFlag']
                      : ''
                    : ''
                }
                style={childStyle}
                onClick={() => selectChild('all')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const navigate = useNavigate();
  const infos = {
    updates: isEdit
      ? [
          {
            label: '新規登録',
            datetime:
              moment(apiData?.createdUser?.datetime).format(
                'YYYY/MM/DD HH:mm:ss'
              ) ?? '-',
            name: apiData?.createdUser?.name ?? '-',
          },
          {
            label: '最終更新',
            datetime:
              moment(apiData?.updatedUser?.datetime).format(
                'YYYY/MM/DD HH:mm:ss'
              ) ?? '-',
            name: apiData?.updatedUser?.name ?? '-',
          },
        ]
      : [],
    basic: {
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '24px 7px',
        minWidth: checkIsSp() ? '100px' : '550px',
      },
      inputs: [
        {
          inputType: 'input', //inputのtype
          formik: formik,
          label: '社員ID', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'userId', //DBのカラム名
          value: isEdit ? location?.state?.id : '',
          disabled: isEdit,
          placeholder: '社員IDを入力してください',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {},
            labelStyle: { width: checkIsSp() ? '100%' : '130px' },
            inputStyle: isEdit
              ? {
                  border: '0px',
                  background: '#e9f2fc',
                  color: '#2b3245',
                  marginLeft: '-15px',
                }
              : {},
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'userName.lastName',
          label: '名前', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'userName.lastName', //DBのカラム名
          placeholder: '姓',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp()
                ? '100%'
                : 'calc(100% - ((100% - 145px - 24px) / 2 + 16px))',
            },
            labelStyle: { width: '130px' },
            inputStyle: { width: '100%' },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'userName.firstName',
          label: '', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'userName.firstName', //DBのカラム名
          placeholder: '名',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp() ? '100%' : 'calc((100% - 145px - 24px) / 2)',
              marginLeft: checkIsSp() ? 0 : '9px',
            },
            labelStyle: { width: '0' },
            inputStyle: { width: 'calc(100%)' },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'userNameKana.lastName',
          label: '名前(カナ)', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'userNameKana.lastName', //DBのカラム名
          placeholder: 'セイ',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp()
                ? '100%'
                : 'calc(100% - ((100% - 145px - 24px) / 2 + 16px))',
            },
            labelStyle: { width: '130px' },
            inputStyle: { width: '100%' },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'userNameKana.firstName',
          label: '', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'userNameKana.firstName', //DBのカラム名
          placeholder: 'メイ',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp()
                ? '100%'
                : 'calc((100% - (100% - (100% - 145px - 24px) / 2))',
              marginLeft: checkIsSp() ? 0 : '9px',
            },
            labelStyle: { width: '0' },
            inputStyle: { width: 'calc(100%)' },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'tel.number1',
          label: 'TEL', //ラベルの名前
          labelRequired: false, //ラベルの※マークの有無
          inputName: 'tel.number1', //DBのカラム名
          placeholder: '03',
          unit: '-', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp() ? 'calc(((100% - 72px) / 3) + 23px)' : '285px',
            },
            labelStyle: { width: '130px' },
            inputStyle: {},
            unitStyle: { width: 'auto' },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'tel.number2',
          label: '', //ラベルの名前
          labelRequired: false, //ラベルの※マークの有無
          inputName: 'tel.number2', //DBのカラム名
          placeholder: '0000',
          unit: '-', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp() ? 'calc(((100% - 72px) / 3) + 23px)' : '131px',
            },
            unitStyle: { width: 'auto'},
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'tel.number3',
          label: '', //ラベルの名前
          labelRequired: false, //ラベルの※マークの有無
          inputName: 'tel.number3', //DBのカラム名
          placeholder: '0000',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp() ? 'calc(((100% - 72px) / 3) + 10px)' : '110px',
            },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'mailAddress',
          label: 'メールアドレス', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'mailAddress', //DBのカラム名
          placeholder: 'メールアドレスを入力してください',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {},
            labelStyle: { width: checkIsSp() ? '100%' : '130px' },
            inputStyle: {},
          },
        },
        isEdit
          ? {
              style: {
                areaStyle: { display: 'none' },
                labelStyle: { width: '130px' },
                inputStyle: {},
              },
            }
          : {
              inputType: 'inputPassword', //inputのtype
              key: 'password',
              submitErrorFlg: true,
              formik: formik,
              label: checkIsSp() ? 'パスワード ※未入力の場合は自動でセットされます' : 'パスワード', //ラベルの名前
              labelRequired: false, //ラベルの※マークの有無
              inputName: 'password', //DBのカラム名
              autoComplete: false,
              placeholder:  checkIsSp() ? 'パスワードを入力してください' : 'パスワードを入力してください※未入力の場合は自動でセットされます',
              unit: '', //インプット横の単位
              style: {
                areaStyle: { width: '100%' },
                labelStyle: { width: checkIsSp() ? '100%' : '130px' },
                inputStyle: {},
              },
            },
        isEdit
          ? {
              style: {
                areaStyle: { display: 'none' },
                labelStyle: { width: '130px' },
                inputStyle: {},
              },
            }
          : {
              inputType: 'inputPassword', //inputのtype
              submitErrorFlg: true,
              label: 'パスワード(再入力)', //ラベルの名前
              labelRequired: false, //ラベルの※マークの有無
              inputName: 'passwordConfirm', //DBのカラム名
              placeholder: 'パスワードを再入力してください',
              unit: '', //インプット横の単位
              style: {
                areaStyle: {},
                labelStyle: { width: '130px' },
                inputStyle: {},
              },
            },
        {
          inputType: 'inputDate', //inputのtype
          formik: formik,
          key: 'startDate',
          label: '利用開始日', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'startDate', //DBのカラム名
          disabledFutureDate: formik.values.endDate,
          placeholder: 'YYYY/MM/DD',
          unit: '', //インプット横の単位
          style: {
            areaStyle: checkIsSp()
              ? { width: '165px', marginRight: '20px' }
              : {},
            labelStyle: { width: '130px' },
            inputStyle: checkIsSp() ? { width: '165px' } : {},
          },
        },
        {
          inputType: 'inputDate', //inputのtype
          formik: formik,
          key: 'endDate',
          label: '利用終了日', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'endDate', //DBのカラム名
          disabledDate: formik.values.startDate,
          placeholder: 'YYYY/MM/DD',
          unit: '', //インプット横の単位
          style: {
            areaStyle: checkIsSp() ? { width: '165px' } : {},
            labelStyle: { width: '130px' },
            inputStyle: checkIsSp() ? { width: '165px' } : {},
          },
        },
      ],
    },

    detail: {
      tabs: {
        affiliation: {
          label: '所属情報',
          jsx: Affiliation,
        },
        authority: {
          label: '権限設定',
          jsx: Authority,
        },
        remark: {
          label: '備考情報',
          jsx: Remark,
        },
      },
    },
  };

  const editInfos = {
    updates: [
      {
        label: '新規登録',
        datetime: moment(apiData?.createdUser?.datetime).format(
          'YYYY/MM/DD HH:mm:ss'
        ),
        name: apiData?.createdUser?.name,
      },
      {
        label: '最終更新',
        datetime: moment(apiData.updatedUser.datetime).format(
          'YYYY/MM/DD HH:mm:ss'
        ),
        name: apiData.updatedUser.name,
      },
    ],
    basic: {
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '24px 7px',
        minWidth: checkIsSp() ? '100%' : '550px',
      },
      inputs: [
        {
          inputType: 'input', //inputのtype
          formik: formik,
          label: '社員ID', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'userId', //DBのカラム名
          value: isEdit ? location?.state?.id : '',
          disabled: isEdit,
          placeholder: '社員IDを入力してください',
          unit: '', //インプット横の単位
          className: {
            areaClass: checkIsSp() ? 'user_register--user_id_field' : '',
          },
          style: {
            areaStyle: {},
            labelStyle: { width: checkIsSp() ? 'auto' : '130px' },
            inputStyle: {
              border: '0px',
              background: '#e9f2fc',
              color: '#2b3245',
              marginLeft: checkIsSp() ? 0 : '-15px',
            },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'userName.lastName',
          label: '名前', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'userName.lastName', //DBのカラム名
          placeholder: '姓',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp()
                ? '100%'
                : 'calc(100% - ((100% - 145px - 24px) / 2 + 16px))',
            },
            labelStyle: { width: '130px' },
            inputStyle: { width: '100%' },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'userName.firstName',
          label: '', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'userName.firstName', //DBのカラム名
          placeholder: '名',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp()
                ? '100%'
                : 'calc((100% - (100% - (100% - 145px - 24px) / 2))',
              marginLeft: checkIsSp() ? 0 : '9px',
            },
            labelStyle: { width: '0' },
            inputStyle: { width: '100%'},
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'userNameKana.lastName',
          label: '名前(カナ)', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'userNameKana.lastName', //DBのカラム名
          placeholder: 'セイ',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp()
                ? '100%'
                : 'calc(100% - ((100% - 145px - 24px) / 2 + 16px))',
            },
            labelStyle: { width: '130px' },
            inputStyle: { width: '100%' },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'userNameKana.firstName',
          label: '', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'userNameKana.firstName', //DBのカラム名
          placeholder: 'メイ',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp()
                ? '100%'
                : 'calc((100% - (100% - (100% - 145px - 24px) / 2))',
              marginLeft: checkIsSp() ? 0 : '9px',
            },
            labelStyle: { width: '0' },
            inputStyle: { width: 'calc(100%)' },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'tel.number1',
          label: 'TEL', //ラベルの名前
          labelRequired: false, //ラベルの※マークの有無
          inputName: 'tel.number1', //DBのカラム名
          placeholder: '03',
          unit: '-', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp() ? 'calc(((100% - 72px) / 3) + 24px)' : '285px',
            },
            labelStyle: { width: '130px' },
            inputStyle: {},
            unitStyle: { width: 'auto'},
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'tel.number2',
          label: '', //ラベルの名前
          labelRequired: false, //ラベルの※マークの有無
          inputName: 'tel.number2', //DBのカラム名
          placeholder: '0000',
          unit: '-', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp() ? 'calc(((100% - 72px) / 3) + 24px)' : '131px',
            },
            unitStyle: { width: 'auto'},
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'tel.number3',
          label: '', //ラベルの名前
          labelRequired: false, //ラベルの※マークの有無
          inputName: 'tel.number3', //DBのカラム名
          placeholder: '0000',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {
              width: checkIsSp() ? 'calc(((100% - 72px) / 3) + 10px)' : '110px',
            },
          },
        },
        {
          inputType: 'input', //inputのtype
          formik: formik,
          key: 'mailAddress',
          label: 'メールアドレス', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'mailAddress', //DBのカラム名
          placeholder: 'メールアドレスを入力してください',
          unit: '', //インプット横の単位
          style: {
            areaStyle: {},
            labelStyle: { width: checkIsSp() ? '100%' : '130px' },
            inputStyle: {},
          },
        },
        {
          inputType: 'inputDate', //inputのtype
          formik: formik,
          key: 'startDate',
          label: '利用開始日', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'startDate', //DBのカラム名
          disabledFutureDate: formik.values.endDate,
          placeholder: 'YYYY/MM/DD',
          unit: '', //インプット横の単位
          style: {
            areaStyle: checkIsSp() ? { width: '165px' } : {},
            labelStyle: { width: '130px' },
            inputStyle: {},
          },
        },
        {
          inputType: 'inputDate', //inputのtype
          formik: formik,
          key: 'endDate',
          label: '利用終了日', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'endDate', //DBのカラム名
          disabledDate: formik.values.startDate,
          placeholder: 'YYYY/MM/DD',
          unit: '', //インプット横の単位
          style: {
            areaStyle: checkIsSp() ? { width: '165px' } : {},
            labelStyle: { width: '130px' },
            inputStyle: {},
          },
        },
      ],
    },

    detail: {
      tabs: {
        affiliation: {
          label: '所属情報',
          jsx: Affiliation,
        },
        authority: {
          label: '権限設定',
          jsx: Authority,
        },
        remark: {
          label: '備考情報',
          jsx: Remark,
        },
      },
    },
  };

  if (isEdit) {
    return (
      <>
        <RegisterBase
          image={uploadFlg ? userImageSrc : formik.values.userImage}
          imageShape={'round'}
          infos={editInfos}
          setImage={userImageInputChange}
          formik={formik}
        />
        <div className={'bottom_btn_area'} style={{ height: '92px' }}>
          <div
            className={'user_register--submit_buttons'}
            style={
              location?.state?.deletedFlag === 0
                ? { marginTop: '24px' }
                : { marginTop: '24px', justifyContent: 'center' }
            }
          >
            <Button
              text={'戻る'}
              style={{ ...Options.buttonStyles.back, width: '240px' }}
              onClick={() => {
                navigate(`/master/user/list`);
                dispatch(userActions.saveOffset(0));
              }}
            />
            {location?.state?.deletedFlag === 0 && (
              <Button
                text={'更新'}
                style={{ ...Options.buttonStyles.submit, width: '240px' }}
                onClick={() => {
                  handlerFormikSubmit(formik, tabErrorGroup);
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <RegisterBase
          image={userImageSrc ? userImageSrc : Icons.icon.noImage}
          imageShape={'round'}
          infos={infos}
          setImage={userImageInputChange}
          formik={formik}
        />

        <div className={'bottom_btn_area'} style={{ height: '92px' }}>
          <div
            className={'user_register--submit_buttons'}
            style={{ marginTop: '24px' }}
          >
            <Button
              text={'戻る'}
              style={{ ...Options.buttonStyles.back, width: '240px' }}
              onClick={() => {
                navigate(`/master/user/list`);
                dispatch(userActions.saveOffset(0));
                notification.destroy();
              }}
            />
            <Button
              text={'登録'}
              style={{ ...Options.buttonStyles.submit, width: '240px' }}
              onClick={() => {
                handlerFormikSubmit(formik, tabErrorGroup);
              }}
            />
          </div>
        </div>
      </>
    );
  }
};

export default UserRegister;
