import { memo } from 'react';
import { omitBy, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import Icons from '../../../../constants/icons';
import moment from 'moment';
import './style.scss';
import { apiUrl } from '../../../../api/apiUrl';

const TrafficInfo = memo(({ formik }) => {
  const { assignStaffDetail, nearestStation, startStation } = useSelector(
    state => state.matterDate
  );

  const arriveDatetime =
    assignStaffDetail?.arriveStationDatetime &&
    moment(assignStaffDetail.arriveStationDatetime);

  return (
    <div className="implementation_tab_content traffic_info">
      <CustomFormikInput
        formik={formik}
        label="出発駅"
        placeholder="出発駅を選択してください"
        inputType="comboBox"
        initialValue={startStation}
        selectBoxOptions={startStation}
        inputName="departureStation"
        style={{
          areaStyle: {
            maxWidth: 785,
          },
        }}
      />
      <CustomFormikInput
        formik={formik}
        label="開催地最寄駅"
        placeholder="開催地最寄駅を選択してください"
        inputType="comboBox"
        initialValue={nearestStation}
        selectBoxOptions={nearestStation}
        inputName="venueNearestStation"
        style={{
          areaStyle: {
            maxWidth: 785,
          },
        }}
      />
      <CustomFormikInput
        formik={formik}
        label="集合場所"
        placeholder="集合場所を入力してください"
        inputName="meetingPlace"
        style={{
          areaStyle: {
            maxWidth: 785,
          },
        }}
      />
      <span className="custom_unit">
        <a
          href={`${apiUrl.norikaeApi}?${new URLSearchParams(
            omitBy(
              {
                eki1: formik.values.departureStation,
                eki2: formik.values.venueNearestStation,
                Cway: 1,
                Cfp: 1,
                Czu: 2,
                S: '検索',
                Csg: 1,
                ...(arriveDatetime && {
                  Dyy: arriveDatetime.year(),
                  Dmm: arriveDatetime.format('M'),
                  Ddd: arriveDatetime.format('D'),
                  Dhh: arriveDatetime.format('H'),
                  Dmn1: arriveDatetime.format('mm')[0],
                  Dmn2: arriveDatetime.format('mm')[1],
                }),
              },
              isNil
            )
          )}`}
          target="_blank"
        >
          乗換案内で検索
          <img src={Icons.icon.externalLink} />
        </a>
      </span>
    </div>
  );
});

export default TrafficInfo;
