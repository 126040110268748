import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect, useWindowSize } from 'react-use';
import { Modal, Radio } from 'antd';
import {
  checkIsSp,
  convertToNumber,
  diffMinute,
  handlerFormikFieldChange,
  numberConvert,
} from '../../../utils/fnUtil';
import { sumBy, toInteger } from 'lodash';
import CustomFormikInput from '../../customInput/customFormikInput';
import Button from '../../button';
import Options from '../../../constants/options';
import './style.scss';
import PaymentItem from '../../../pages/matter/implementationDetail/tabs/item/paymentItem';

const ReportModal = memo(({ formik, modalVisible, onUpdate, onCancel }) => {
  const overtimeOptions = [...Array(601)].map((_, i) => i);

  const { assignDetail } = useSelector(state => state.staffMatter);

  const {
    shift: { payBaseFlag, hourlyPayBase, dailyPayBase },
    calculationPayFlag,
  } = assignDetail ?? {};

  const {
    punchInDatetime,
    punchOutDatetime,
    payCutHourlyPay,
    overtimePay,
    allowanceAmount,
    advance,
  } = formik.values;

  const { width } = useWindowSize();

  useUpdateEffect(() => {
    handlerFormikFieldChange(
      formik,
      `payAmount`,
      numberConvert(
        (`${payBaseFlag}` === '0'
          ? diffMinute(punchInDatetime, punchOutDatetime) *
              (toInteger(hourlyPayBase) / 60) +
            convertToNumber(overtimePay) -
            convertToNumber(payCutHourlyPay)
          : toInteger(dailyPayBase) +
            convertToNumber(overtimePay) -
            convertToNumber(payCutHourlyPay)) +
          toInteger(allowanceAmount) +
          sumBy((advance ?? []).map(ad => toInteger(ad.totalAmount))),
        calculationPayFlag
      )
    );
  }, [
    punchInDatetime,
    punchOutDatetime,
    overtimePay,
    payCutHourlyPay,
    calculationPayFlag,
    payBaseFlag,
    hourlyPayBase,
    dailyPayBase,
    allowanceAmount,
    advance,
  ]);

  useEffect(() => {
    const targetElm = document.querySelector('.header--base');
    if (checkIsSp()) {
      targetElm.style.zIndex = 1;
    }
  }, []);

  return (
    <Modal
      visible={modalVisible}
      style={{
        minWidth: 600,
      }}
      footer={null}
      centered
      closable={false}
      onCancel={onCancel}
      className="report_modal_base"
    >
      <div className="report_modal">
        <div className="report_modal_title">終業報告</div>

        <div className="report_modal--contents">
          <div className="report_modal--item">
            <CustomFormikInput
              placeholder="0"
              formik={formik}
              label="残業時間"
              inputName="overtime"
              unit="分"
              inputType="select"
              initialValue={overtimeOptions}
              selectBoxOptions={overtimeOptions}
              extraOnChange={v => {
                if (payBaseFlag == 0 && hourlyPayBase >= 0) {
                  handlerFormikFieldChange(
                    formik,
                    `overtimePay`,
                    numberConvert(
                      (hourlyPayBase / 60) * toInteger(v),
                      calculationPayFlag
                    )
                  );
                }
              }}
              style={{
                areaStyle: {
                  width: width < 800 ? 160 : 250,
                },
              }}
            />
          </div>
          <div className="report_modal--item_satisfaction">
            <span>満足度</span>
            <span style={{marginBottom: checkIsSp() ? '16px' : 0}}> 本日実施した案件の満足度を選択してください</span>
            {Options.codes.satisfactionLevel.map(option => (
              <Radio.Group
                key={option.label}
                onChange={e =>
                  handlerFormikFieldChange(
                    formik,
                    'satisfactionLevel',
                    e.target.value
                  )
                }
                value={formik.values.satisfactionLevel}
              >
                <Radio key={option.label} value={option.value} style={{marginBottom: checkIsSp() ? '22px' : 0}}>
                  {option.label}
                </Radio>
              </Radio.Group>
            ))}
          </div>

          <div className="payment_tab_content--item_list custom_payment_tab_content--item_list">
            {Options.codes.advanceCategory.map((category, i) => (
              <PaymentItem
                formik={formik}
                inputName={`advance.[${i}]`}
                label={category.label}
                key={category.label}
                custom={true}
              />
            ))}
          </div>

          <div className="report_modal--item">
            <CustomFormikInput
              placeholder="遅刻・早退理由を入力してください"
              formik={formik}
              label="遅刻・早退理由"
              inputName="punchMemo"
              inputType="textBox"
            />
          </div>
        </div>

        <div className={'report_modal--actions'}>
          <Button
            text={'戻る'}
            style={{
              ...Options.buttonStyles.back,
              height: checkIsSp() ? '40px' : '44px',
              fontSize: checkIsSp() ? '12px' : '14px',
            }}
            onClick={onCancel}
          />
          <Button
            text={'登録'}
            style={{
              ...Options.buttonStyles.submit,
              height: checkIsSp() ? '40px' : '44px',
              fontSize: checkIsSp() ? '12px' : '14px',
            }}
            onClick={onUpdate}
          />
        </div>
      </div>
    </Modal>
  );
});

export default ReportModal;
