import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { fetchStaffMatterReportDetail } from '../../../../redux/slice/staffMatter';
import { imageUpload } from '../../../../redux/slice/matter';
import { checkIsSp, handlerFormikFieldChange } from '../../../../utils/fnUtil';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import CaculateTable from '../../../../components/caculateTable';
import Checkbox from '../../../../components/checkbox';
import ImageUploader from '../../../../components/imageUploader/imageUploader';
import EmptyLayout from '../../../../components/emptyLayout';
import Options from '../../../../constants/options';
import './style.scss';

const ReportDetail = ({ formik }) => {
  const {
    values: {
      info,
      reportPage,
      folder,
      staffVoice,
      guestVoice,
      guestAttribute,
    },
  } = formik;

  const dispatch = useDispatch();

  const { purchaseMotiveList } = useSelector(state => state.staffMatter);

  const { staffId } = useSelector(state => state.account);

  const { state } = useLocation();

  const percentOption = [...Array(101)].map((_, i) => i);

  useEffect(() => {
    state?.matterNumber &&
      dispatch(
        fetchStaffMatterReportDetail({
          matterNumber: state.matterNumber,
          userId: staffId,
        })
      );
  }, [staffId, state]);

  const infoItem = Options.codes.reportInfo.filter(
    l =>
      info?.[`staff${l.item.charAt(0).toUpperCase()}${l.item.slice(1)}Flag`] ===
      1
  );

  return (
    <div className="report_tab">
      {infoItem.length > 0 && (
        <div className="report_tab--section" style={{gap: checkIsSp() ? '24px' : '', marginBottom: checkIsSp() ? '48px' : ''}}>
          <div className="report_tab--column">実績情報</div>
          {infoItem.map(l => (
            <CustomFormikInput
              {...l}
              formik={formik}
              key={l.label}
              inputName={`info.${l.item}`}
              placeholder={l.placeholder ?? `${l.label}を入力してください`}
              style={{
                inputStyle: { maxWidth: 784 },
                labelStyle: { width: 170 },
                areaStyle: {
                  marginBottom: checkIsSp() ? '8px' : '', width: checkIsSp() ? '100%' : ''
                },
                ...(l.inputType === 'select' && {
                  areaStyle: {
                    maxWidth: 350,
                  },
                }),
              }}
            />
          ))}
        </div>
      )}
      {(reportPage ?? []).filter(r => r.staffFlag === 1).length > 0 && (
        <div>
          <div className="report_tab--column" style={{fontSize: checkIsSp() ? '12px' : '14px'}}>実績報告</div>
          {reportPage.map(
            (p, i) =>
              p.staffFlag === 1 && (
                <div key={i}>
                  <div className="report_tab--table_title" style={{fontSize: checkIsSp() ? '12px' : '14px'}}>{p.pageName}</div>
                  <div className="report_tab--table_container">
                    {p.layout.map((layout, j) => (
                      <div
                        className="performance_table--layout_container"
                        key={j}
                      >
                        {layout.table.map(
                          ({ tableColumns, tableRows, totalDisplayFlag }, k) =>
                            tableColumns ? (
                              <CaculateTable
                                key={`${j}${k}`}
                                formik={formik}
                                inputName={`reportPage.[${i}].layout.[${j}].table.[${k}]`}
                                tableSettings={{
                                  tableColumns,
                                  tableRows,
                                  totalDisplayFlag,
                                }}
                                staffInput={true}
                                onDeleteTable={() =>
                                  handlerFormikFieldChange(
                                    formik,
                                    `reportPage.[${i}].layout.[${j}].table.[${k}]`,
                                    {}
                                  )
                                }
                              />
                            ) : (
                              <EmptyLayout key={j + k} onClick={() => {}} />
                            )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      )}
      {(folder ?? []).filter(r => r.staffFlag === 1).length > 0 && (
        <div>
          <div className="report_tab--column" style={{fontSize: checkIsSp() ? '12px' : '14px'}}>報告画像</div>
          {folder.map(
            (f, i) =>
              f.staffFlag === 1 && (
                <div key={i}>
                  <div className="report_tab--text display-linebreak">
                    {f.folderName && (
                      <span className="folder-name"> {f.folderName}</span>
                    )}
                    <span className="folder-tip" style={{fontSize: checkIsSp() ? '12px' : '14px'}}>
                      png,gif,jpg,jpeg形式のみアップロード可。画像は5M以下でアップロードしてください
                      。
                    </span>
                  </div>
                  <div className="report_tab--images">
                    {Array(16)
                      .fill('')
                      .map((_, k) => (
                        <ImageUploader
                          image={f[`folderImage${k + 1}`]}
                          imageChecked={f[`folderImage${k + 1}Active`] === 1}
                          key={k}
                          onAddImage={async img => {
                            const imageUrl = await dispatch(
                              imageUpload(img)
                            ).unwrap();
                            handlerFormikFieldChange(
                              formik,
                              `folder[${i}].folderImage${k + 1}`,
                              imageUrl
                            );
                            handlerFormikFieldChange(
                              formik,
                              `folder[${i}].folderImage${k + 1}Active`,
                              1
                            );
                          }}
                          onDelete={() => {
                            handlerFormikFieldChange(
                              formik,
                              `folder.[${i}].folderImage${k + 1}`,
                              ''
                            );
                          }}
                        />
                      ))}
                  </div>
                </div>
              )
          )}
        </div>
      )}
      {(staffVoice ?? []).filter(r => r.staffVoiceFlag === 1).length > 0 && (
        <div style={{marginBottom: checkIsSp() ? '46px' : ''}}>
          <div className="report_tab--column" style={{fontSize: checkIsSp() ? '12px' : '14px', marginTop:  checkIsSp() ? '18px' : ''}}>スタッフの声</div>
          {staffVoice.map(
            (s, i) =>
              s.staffVoiceFlag === 1 && (
                <CustomFormikInput
                  formik={formik}
                  label={s.inputTitle}
                  key={i}
                  placeholder="入力してください"
                  inputName={`staffVoice.[${i}].inputText`}
                  inputType="textBox"
                  style={{
                    inputStyle: { maxWidth: 784, height: 88 },
                    labelStyle: { width: 170 },
                  }}
                />
              )
          )}
        </div>
      )}
      {([
        guestVoice?.staffMotiveFlag,
        guestVoice?.staffInflowRouteFlag,
        guestVoice?.staffContinueFlag,
      ].some(f => f === 1) ||
        (guestVoice?.voiceInput ?? []).filter(r => r.staffVoiceFlag === 1)
          .length > 0) && (
        <div className="guest_attribute_input">
          <div className="report_tab--column report_tab--for_sp_label">お客様の声</div>
          {guestVoice?.staffTotalFlag === 1 && (
            <CustomFormikInput
              formik={formik}
              label="総人数"
              inputName="guestVoice.voiceTotalNumber"
              placeholder="0"
              style={{
                inputStyle: {
                  width: 160,
                },
                labelStyle: { width: 170, fontSize: checkIsSp() ? '12px' : '14px' },
                areaStyle: {marginBottom: checkIsSp() ? '16px' : ''}
              }}
              unit="人"
            />
          )}
          {guestVoice?.staffInflowRouteFlag === 1 && (
            <div className={checkIsSp() ? 'report_tab--custom_input report_tab--for_sp_label' : 'new_area'} style={{marginBottom: checkIsSp() ? '6px' : ''}}>
              <label style={{marginBottom: checkIsSp() ? 0 : ''}}>流入経路</label>
              <div className={'new_area--input'}>
                {Options.codes.guestVoiceInflowRouteGroup.map(option => (
                  <CustomFormikInput
                    key={option.label}
                    formik={formik}
                    label={option.label}
                    inputType="select"
                    initialValue={percentOption}
                    selectBoxOptions={percentOption}
                    placeholder="00"
                    unit="%"
                    inputName={option.inputName}
                    otherErrorInputName={['guestVoice.staffInflowRouteTotal']}
                    // style={{
                    //   labelStyle: { width: checkIsSp() ? 50 : 80, color: '#242424' },
                    //   areaStyle: {
                    //     width: 163,
                    //     marginRight: 70,
                    //     marginBottom: checkIsSp() ? '8px' : ''
                    //   },
                    // }}
                  />
                ))}
              </div>
            </div>
          )}
          {guestVoice?.staffMotiveFlag === 1 && (
            <div className={checkIsSp() ? 'report_tab--custom_input report_tab--for_sp_label' : 'new_area'}>
              <label className="report_tab--wrap_label report_tab--for_sp_label" style={{marginBottom: checkIsSp() ? 0 : ''}}>購入動機</label>
              <div className={'new_area--input'}>
                {purchaseMotiveList?.length > 0 ? [...purchaseMotiveList]?.sort((a, b) => a.priority - b.priority)?.map(obj =>
                  <CustomFormikInput
                    label={obj.naming}
                    formik={formik}
                    inputName={`guestVoice.staffMotiveId_${obj.namingId}`}
                    inputType="select"
                    initialValue={percentOption}
                    selectBoxOptions={percentOption}
                    placeholder="00"
                    otherErrorInputName={['guestVoice.staffMotiveRateTotal']}
                    unit="%"
                    // style={{
                    //   labelStyle: {
                    //     textAlign: 'right',
                    //     minWidth: checkIsSp() ? 50 : 80,
                    //   },
                    //   areaStyle: {
                    //     width: 163,
                    //     marginRight: 70,
                    //   },
                    // }}
                  />
                ) : <></>}
              </div>
            </div>
          )}
          {guestVoice?.staffContinueFlag === 1 && (
            <div className={checkIsSp() ? 'report_tab--custom_input report_tab--for_sp_label' : 'new_area'}>
              <label style={{marginBottom: checkIsSp() ? 0 : ''}}>継続購入可否</label>
              <div className={'new_area--input'}>
                {Options.codes.guestVoiceContinueRouteGroup.map(option => (
                  <CustomFormikInput
                    key={option.label}
                    formik={formik}
                    label={option.label}
                    inputType="select"
                    initialValue={percentOption}
                    selectBoxOptions={percentOption}
                    placeholder="00"
                    unit="%"
                    inputName={option.inputName}
                    otherErrorInputName={['guestVoice.staffContinueRouteTotal']}
                    // style={{
                    //   labelStyle: { width: checkIsSp() ? 50 : 80, color: '#242424' },
                    //   areaStyle: {
                    //     width: 163,
                    //     marginRight: 70,
                    //     marginBottom: checkIsSp() ? '8px' : ''
                    //   },
                    // }}
                  />
                ))}
              </div>
            </div>
          )}
          {(guestVoice?.voiceInput ?? []).map(
            (s, i) =>
              s.staffVoiceFlag === 1 && (
                <CustomFormikInput
                  formik={formik}
                  label={s.inputTitle}
                  key={i}
                  placeholder="入力してください"
                  inputName={`guestVoice.voiceInput.[${i}].inputText`}
                  inputType="textBox"
                  style={{
                    inputStyle: { maxWidth: 784, height: 88 },
                    labelStyle: { width: 170 },
                  }}
                />
              )
          )}
        </div>
      )}
      {[
        guestAttribute?.staffTotalFlag,
        guestAttribute?.staffGenderRatioFlag,
        guestAttribute?.staffGuestFlag,
        guestAttribute?.staffAgeGroupFlag,
      ].some(f => f === 1) && (
        <div className="guest_attribute_input">
          <div className="report_tab--column report_tab--for_sp_label">お客様の属性</div>
          {guestAttribute?.staffTotalFlag === 1 && (
            <CustomFormikInput
              formik={formik}
              label="総人数"
              inputName="guestAttribute.totalNumber"
              placeholder="0"
              style={{
                inputStyle: {
                  width: 160,
                },
                labelStyle: { width: 170, marginRight: 105, fontSize: checkIsSp() ? '12px' : '14px' },
                areaStyle: {marginBottom: checkIsSp() ? '16px' : ''}
              }}
              unit="人"
            />
          )}
          {guestAttribute?.staffGenderRatioFlag === 1 && (
            <div className="report_tab--custom_input report_tab--for_sp_label" style={{marginBottom: checkIsSp() ? '16px' : ''}}>
              <label style={{marginBottom: checkIsSp() ? 0 : ''}}>男女比</label>
              <CustomFormikInput
                label="男性"
                formik={formik}
                inputName="guestAttribute.manRate"
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                placeholder="00"
                otherErrorInputName={['guestAttribute.manWomanRateTotal']}
                unit="%"
                style={{
                  labelStyle: { width: checkIsSp() ? 50 : 80, color: '#242424' },
                  areaStyle: {
                    width: 163,
                    marginRight: 70,
                  },
                }}
              />
              <CustomFormikInput
                label="女性"
                formik={formik}
                inputName="guestAttribute.womanRate"
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                otherErrorInputName={['guestAttribute.manWomanRateTotal']}
                placeholder="00"
                unit="%"
                style={{
                  labelStyle: { width: checkIsSp() ? 50 : 80, color: '#242424' },
                }}
              />
            </div>
          )}
          {guestAttribute?.staffGuestFlag === 1 && (
            <div className="report_tab--custom_input report_tab--for_sp_label" style={{marginBottom: checkIsSp() ? '6px' : ''}}>
              <label style={{marginBottom: checkIsSp() ? 0 : ''}}>客層比</label>
              {Options.codes.guestGroup.map(option => (
                <CustomFormikInput
                  key={option.label}
                  formik={formik}
                  label={option.label}
                  inputType="select"
                  initialValue={percentOption}
                  selectBoxOptions={percentOption}
                  placeholder="00"
                  unit="%"
                  inputName={option.inputName}
                  otherErrorInputName={['guestAttribute.segmentRateTotal']}
                  style={{
                    labelStyle: { width: checkIsSp() ? 50 : 80, color: '#242424' },
                    areaStyle: {
                      width: 163,
                      marginRight: 70,
                      marginBottom: checkIsSp() ? '8px' : ''
                    },
                  }}
                />
              ))}
            </div>
          )}
          {guestAttribute?.staffAgeGroupFlag === 1 && (
            <div className="report_tab--custom_input">
              <label className="report_tab--wrap_label report_tab--for_sp_label" style={{marginBottom: checkIsSp() ? 0 : ''}}>年代別客層</label>
              <div className="report_tab--custom_input_wrap" style={{gap: checkIsSp() ? 22 : ''}}>
                {Options.codes.ageGroup.map(option => (
                  <CustomFormikInput
                    key={option.label}
                    formik={formik}
                    label={option.label}
                    inputType="select"
                    initialValue={percentOption}
                    selectBoxOptions={percentOption}
                    placeholder="00"
                    unit="%"
                    inputName={option.inputName}
                    otherErrorInputName={['guestAttribute.ageRateTotal']}
                    style={{
                      labelStyle: {
                        textAlign: 'right',
                        minWidth: checkIsSp() ? 50 : 80,
                      },
                      areaStyle: {
                        width: 163,
                        marginRight: 70,
                      },
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportDetail;
