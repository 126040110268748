import React, { useEffect, memo } from 'react';
import AddButton from '../../../../components/addButton';
import Icons from '../../../../constants/icons';
import './style.scss';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import {
  deleteArrayItem,
} from '../../../../utils/fnUtil';
import { useDispatch, useSelector } from 'react-redux';
import { bankingFetch, multiBankingFetch, staffActions } from '../../../../redux/slice/staff';
import { checkIsSp } from '../../../../utils/fnUtil';

const BillInfo = memo(({ detailValue, isEdit, formik }) => {
  const labelSty = checkIsSp()?{ width: '100%', textAlign: 'left', color: '#646464' }:{ width: '175px', textAlign: 'right', color: '#646464' };
  const boxSty = {
    border: '1px solid #A0AAB8',
    margin: '26px 0',
    position: 'relative',
  };
  const addSty = {
    width: '36px',
    height: '36px',
    cursor: 'pointer',
    marginTop: '-2px',
  };
  const delSty = {
    width: '26px',
    height: '30px',
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
    top: '6px',
  };

  const {
    values: { bankMasters },
    setFieldValue,
  } = formik;

  const { bankingList, corporateBranchesList } = useSelector(state => state.staff);
  const dispatch = useDispatch();

  useEffect(async () => {
    await bankMasters.forEach((o, i) => {
      const bankName = o.bankName;
      if (bankName) {
        formik.setFieldValue(`bankMasters.[${i}].bankCode`, bankingList?.find(b => b.label === bankName && bankName !== 'ゆうちょ銀行')?.value ?? '');
        // formik.setValues({
        //   ...formik.values,
        //   bankMasters: formik.values.bankMasters.map((obj, idx) => {
        //     if (idx === i) {
        //       return {
        //         ...obj,
        //         bankCode: bankingList?.find(b => b.label === bankName && bankName !== 'ゆうちょ銀行')?.value ?? '',
        //       };
        //     } else {
        //       return obj;
        //     }
        //   }),
        // });
        dispatch(
          bankingFetch(bankingList?.find(b => b.label === bankName)?.value ?? '')
        );
      } else {
        dispatch(staffActions.bankReset());
      }
    });
    dispatch(
      multiBankingFetch([
        bankingList?.find(b => b.label === bankMasters?.[0]?.bankName)?.value ?? '',
        bankingList?.find(b => b.label === bankMasters?.[1]?.bankName)?.value ?? '',
        bankingList?.find(b => b.label === bankMasters?.[2]?.bankName)?.value ?? '',
        bankingList?.find(b => b.label === bankMasters?.[3]?.bankName)?.value ?? '',
        bankingList?.find(b => b.label === bankMasters?.[4]?.bankName)?.value ?? '',
      ])
    );
  }, [
    bankMasters?.[0]?.bankName,
    bankMasters?.[1]?.bankName,
    bankMasters?.[2]?.bankName,
    bankMasters?.[3]?.bankName,
    bankMasters?.[4]?.bankName
  ]);

  useEffect( async () => {
    await dispatch(
      multiBankingFetch([
        bankingList?.find(b => b.label === bankMasters?.[0]?.bankName)?.value ?? '',
        bankingList?.find(b => b.label === bankMasters?.[1]?.bankName)?.value ?? '',
        bankingList?.find(b => b.label === bankMasters?.[2]?.bankName)?.value ?? '',
        bankingList?.find(b => b.label === bankMasters?.[3]?.bankName)?.value ?? '',
        bankingList?.find(b => b.label === bankMasters?.[4]?.bankName)?.value ?? '',
      ])
    ).then((data) => {
      bankMasters?.forEach((o, i) => {
        const bankBranchName = o.bankBranchName;
        if (bankBranchName) {
          formik.setFieldValue(
            `bankMasters.[${i}].bankBranchCode`,
            data?.payload?.corporateBranchesList?.[i]?.find(b => b.label === bankBranchName)?.value ??
            bankMasters?.[i]?.bankBranchCode
          );
          // formik.setValues({
          //   ...formik.values,
          //   bankMasters: formik.values.bankMasters.map((obj, idx) => {
          //     if (idx === i) {
          //       return {
          //         ...obj,
          //         bankBranchCode: branchesList?.find(
          //           b => b.label == bankBranchName
          //         )?.value ?? '',
          //       };
          //     } else {
          //       return obj;
          //     }
          //   }),
          // });
        }
      })
    });

  }, [
    bankMasters?.[0]?.bankBranchName,
    bankMasters?.[1]?.bankBranchName,
    bankMasters?.[2]?.bankBranchName,
    bankMasters?.[3]?.bankBranchName,
    bankMasters?.[4]?.bankBranchName,
    bankMasters?.length,
  ]);

  return (
    <>
      {formik?.values?.bankMasters?.map((obj, i) => {
        return (
          <div style={boxSty} key={`bankMaster_${i}`}>
            <img
              src={Icons.icon.xCircleWh}
              alt="del"
              style={delSty}
              onClick={() => {
                setFieldValue('bankMasters', deleteArrayItem(bankMasters, i));
              }}
            />

            <div className="corporate_register--def_set corporate_register--bill_info">
              {/* 振込金用機関名 */}
              <CustomFormikInput
                inputType="select"
                label="振込金融機関名"
                labelRequired={true}
                inputName={`bankMasters.[${i}].bankName`}
                key={`bankMasters.[${i}].bankName`}
                formik={formik}
                placeholder="振込先金融機関名を入力してください"
                style={{
                  areaStyle: { width: checkIsSp()?'100%':'500px' },
                  labelStyle: labelSty,
                }}
                initialValue={bankingList?.map(b => b?.label)}
                selectBoxOptions={bankingList?.map(b => b?.label)}
              />
              {/* 銀行コード */}
              <div className={'corporate_register--pay_info_unity'}>
                <CustomFormikInput
                  inputType="input"
                  label="銀行コード"
                  labelRequired={true}
                  inputName={`bankMasters.[${i}].bankCode`}
                  key={`bankMasters.[${i}].bankCode`}
                  formik={formik}
                  placeholder="0000"
                  style={{
                    areaStyle: { width: '100%' },
                    inputStyle: {width: '110px'}
                  }}
                  disabled={true}
                />
              </div>
              {/* 支店名 */}
              <CustomFormikInput
                inputType="select"
                label="支店名"
                labelRequired={true}
                placeholder="支店名を入力してください"
                inputName={`bankMasters.[${i}].bankBranchName`}
                key={`bankMasters.[${i}].bankBranchName`}
                formik={formik}
                style={{
                  areaStyle: { width: checkIsSp()?'100%':'500px' },
                  labelStyle: labelSty,
                }}
                initialValue={corporateBranchesList?.[i]?.map(b => b?.label)}
                selectBoxOptions={corporateBranchesList?.[i]?.map(b => b?.label)}
              />
              {/* 支店コード */}
              <div className={'corporate_register--pay_info_unity'}>
                <CustomFormikInput
                  inputType="input"
                  label="支店コード"
                  labelRequired={true}
                  inputName={`bankMasters.[${i}].bankBranchCode`}
                  key={`bankMasters.[${i}].bankBranchCode`}
                  formik={formik}
                  placeholder="0000"
                  style={{
                    areaStyle: { width: '100%' },
                    inputStyle: {width: '110px'}
                  }}
                  disabled={true}
                />
              </div>
              {/* 口座種別 */}
              <CustomFormikInput
                inputType="radioButton"
                radioButtonTitle="口座種別"
                labelRequired={true}
                inputName={`bankMasters.[${i}].bankAccountFlag`}
                key={`bankMasters.[${i}].bankAccountFlag`}
                formik={formik}
                radioButtonLabelAndChecked={[
                  {
                    label: '普通',
                    checked: formik?.values?.bankMasters[i].bankAccountFlag
                      ? formik?.values?.bankMasters[i].bankAccountFlag == 1
                      : true,
                  },
                  {
                    label: '当座',
                    checked: formik?.values?.bankMasters[i].bankAccountFlag
                      ? formik?.values?.bankMasters[i].bankAccountFlag == 2
                      : false,
                  },
                  {
                    label: '貯蓄',
                    checked: formik?.values?.bankMasters[i].bankAccountFlag
                      ? formik?.values?.bankMasters[i].bankAccountFlag == 4
                      : false,
                  },
                ]}
                initialValue={[1, 2, 4]}
                style={{
                  labelStyle: labelSty,
                  areaStyle: { margin: '10px 0' },
                }}
              />
              {/* 口座番号 */}
              <CustomFormikInput
                inputType="input"
                label="口座番号"
                labelRequired={true}
                inputName={`bankMasters.[${i}].bankAccountNumber`}
                key={`bankMasters.[${i}].bankAccountNumber`}
                formik={formik}
                placeholder="0000000"
                style={{
                  inputStyle: { width: checkIsSp()?'100%':'300px' },
                  labelStyle: labelSty,
                }}
              />
              {/* 口座名義(カナ) */}
              <CustomFormikInput
                inputType="input"
                label="口座名義(カナ)"
                labelRequired={true}
                inputName={`bankMasters.[${i}].bankAccountName`}
                key={`bankMasters.[${i}].bankAccountName`}
                formik={formik}
                placeholder="口座名義(カナ)を入力してください"
                style={{
                  inputStyle: { width: checkIsSp()?'100%':'717px' },
                  labelStyle: labelSty,
                }}
              />
              {/* 表示優先順 */}
              <CustomFormikInput
                inputType="select"
                label="表示優先順"
                labelRequired={true}
                inputName={`bankMasters.[${i}].priority`}
                key={`bankMasters.[${i}].priority`}
                formik={formik}
                showSearch={false}
                selectBoxOptions={formik.values.bankMasters.map((v, i) => i+1)}
                initialValue={formik.values.bankMasters.map((v, i) => i+1)}
                placeholder="0"
                style={{
                  areaStyle: { width: checkIsSp()?'100%':'338px' },
                  labelStyle: labelSty,
                }}
              />
            </div>
          </div>
        );
      })}
      <div style={{ float: 'left' }}>
        <AddButton
          style={addSty}
          type="round"
          onClick={() => {
            setFieldValue('bankMasters', [
              ...bankMasters,
              {
                bankAccountFlag: 1,
                bankAccountName: '',
                bankAccountNumber: '',
                bankBranchCode: '',
                bankBranchName: null,
                bankCode: '',
                bankName: null,
                priority: null,
              },
            ]);
          }}
          disabled={formik?.values?.bankMasters?.length > 4}
        />
      </div>
    </>
  );
});

export default BillInfo;
