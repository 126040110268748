import { apiUrl } from './apiUrl';
import { store } from '../../src/redux/store';
import {
  changeActiveTab,
  dispatchError,
  handlerFormikFieldChange,
} from '../utils/fnUtil';
import { isNil, omitBy } from 'lodash';
import { globalActions } from '../redux/slice/global';
import prefectures from '../constants/prefectures';
import fetchJsonp from 'fetch-jsonp';

export const fetchApi = async (
  category,
  name,
  method,
  loginType,
  corporateId,
  accessToken,
  body
) => {
  return new Promise((resolve, reject) => {
    let requestUri = apiUrl[category][name];

    if (method === 'GET' && body) {
      let queries = '';

      Object.keys(body).forEach((key, index) => {
        if (!index) {
          queries += `?${key}=${body[key]}`;
        } else {
          queries += `&${key}=${body[key]}`;
        }
      });

      requestUri += queries;
    }

    const headers = {
      'Content-Type': 'application/json',
      corporate_id: corporateId,
      access_token: accessToken,
      request_from: loginType,
    };

    const requestBody = JSON.stringify({ ...body });

    let requestParam =
      method === 'POST'
        ? {
            method,
            headers,
            body: requestBody,
          }
        : {
            headers: headers,
          };

    fetch(requestUri, requestParam)
      .catch(error => {
        reject(`error : ${error}`);
      })
      .then(response => response.json())
      .then(data => {
        resolve(data);
      });
  });
};

export const fetchLoginApi = (loginType, loginValues) => {
  return new Promise((resolve, reject) => {
    const requestUri = apiUrl.account.login;

    const header = {
      'Content-Type': 'application/json',
      request_from: loginType,
    };

    const body = {
      corporateId: loginValues.corporateId,
      mailAddress: loginValues.mailAddress,
      password: loginValues.password,
    };

    const requestParam = {
      method: 'POST',
      headers: header,
      body: JSON.stringify(body),
    };

    fetch(requestUri, requestParam)
      .then(response => response.json())
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(`error : ${error}`);
      });
  });
};

export const fetchRequest = async (
  url,
  params,
  method = 'GET',
  contentType = 'json',
  errorHidden =false
) => {
  const {
    loginType: { type },
    account: { corporateId, accessToken, userId, staffId },
  } = store.getState();

  const headers = {
    'Content-Type': 'application/json',
    request_from: type,
    corporate_id: corporateId,
    access_token: accessToken,
  };

  const requestParams = {
    ...params,
    ...(method === 'POST' &&
      (type === 'backoffice'
        ? {
            userId,
          }
        : { staffId, userId: staffId })),
  };

  const config = {
    method,
    headers,
    ...(method === 'POST' && { body: JSON.stringify(requestParams) }),
  };

  method === 'GET' &&
    (url = `${url}?${new URLSearchParams(
      omitBy({ ...requestParams }, isNil)
    )}`);

  let json;

  store.dispatch(globalActions.setLoading(true));

  try {
    const response = await fetch(url, config);
    // PDFの処理追加
    if (contentType === 'arraybuffer' || contentType === 'csv') {
      store.dispatch(globalActions.setLoading(false));
      return response.arrayBuffer();
    }
    json = await response[response.ok ? contentType : 'json']();

    if (response.ok) {
      store.dispatch(globalActions.setLoading(false));

      return json;
    }
  } catch (error) {}

  const { displayMessage, navTab, code } = json ?? {};

  navTab && changeActiveTab(navTab);
  if(!errorHidden){
    dispatchError(
        `${
            displayMessage ||
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
        }`,
        navTab
    );
  }

  store.dispatch(globalActions.loadingOff());

  return Promise.reject(json);
};

export const fetchMultiRequest = async requests => {
  store.dispatch(globalActions.setMultiLoading(true));

  const res = await Promise.all(requests);

  store.dispatch(globalActions.setMultiLoading(false));

  return res;
};

export const showApiError = async json => {
  const { displayMessage, navTab } = json ?? {};

  navTab && changeActiveTab(navTab);

  dispatchError(
    `${
      displayMessage ||
      '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
    }`,
    navTab
  );

  return Promise.reject(json);
};

export const zipApi = async (formik, zip1, zip2, pref, city, street) => {
  const zipcode = `${formik.values[zip1]}${formik.values[zip2]}`;

  //追加分
  const streetName = formik?.values?.streetName;
  const cityName = formik?.values?.cityName;

  try {
    let addressRes;

    if (/^\d{7}$/.test(zipcode)) {
      store.dispatch(globalActions.setLoading(true));
      await fetchJsonp(`${apiUrl.ZIP_SEARCH_URL}?zipcode=${zipcode}`, {
        timeout: 10000,
      })
        .then(response => {
          store.dispatch(globalActions.setLoading(false));

          return response.json();
        })
        .then(data => (addressRes = data))
        .catch(() => {
          store.dispatch(globalActions.setLoading(false));
          dispatchError(
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
          );
        });
    }

    const address = addressRes?.results[0];

    if (address) {
      formik.setFieldValue(pref, address?.prefcode);
      formik.setFieldValue(
        city,
        cityName?.includes(address?.address2) ? cityName : address?.address2
      );
      formik.setFieldValue(
        street,
        cityName?.includes(address?.address3) && streetName ?
          streetName : cityName?.includes(address?.address3) ?
            '' : streetName?.includes(address?.address3) ?
              streetName : address?.address3
      );
    }
  } catch (error) {}
};

export const zipApiList = async (formik, index) => {
  const zipcode = `${formik?.values?.officeMasters?.[index]?.zip1}${formik?.values?.officeMasters?.[index]?.zip2}`;
  const pref = `officeMasters[${index}].prefCode`;
  const city = `officeMasters[${index}].cityName`;
  const street = `officeMasters[${index}].streetName`;
  const streetName = formik?.values?.officeMasters[index]?.streetName;
  const cityName = formik?.values?.officeMasters[index]?.cityName;

  try {
    let addressRes;

    if (/^\d{7}$/.test(zipcode)) {
      store.dispatch(globalActions.setLoading(true));
      await fetchJsonp(`${apiUrl.ZIP_SEARCH_URL}?zipcode=${zipcode}`, {
        timeout: 10000,
      })
        .then(response => {
          store.dispatch(globalActions.setLoading(false));

          return response.json();
        })
        .then(data => (addressRes = data))
        .catch(() => {
          store.dispatch(globalActions.setLoading(false));
          dispatchError(
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
          );
        });
    }

    const address = addressRes?.results[0];
    if (address) {
      formik.setFieldValue(pref, address?.prefcode);
      formik.setFieldValue(
        city,
        cityName?.includes(address.address2) ? cityName : address.address2
      );
      formik.setFieldValue(
        street,
        cityName?.includes(address?.address3) && streetName ?
          streetName : cityName?.includes(address?.address3) ?
            '' : streetName?.includes(address?.address3) ?
              streetName : address?.address3
      );
    }
  } catch (error) {}
};

export const getApiKeys = async (cId, aT, type) => {
  let data = {};

  await fetch(apiUrl.fetchUrl.getApiKeys, {
    method: 'GET',
    headers: {
      corporate_id: cId,
      access_token: aT,
      request_from: type,
    },
  })
    .then(r => r.json())
    .then(j => {
      data = j;
    });

  return data;
};

export const checkSession = async (cId, aT, type) => {
  let result = false;

  await fetch(apiUrl.fetchUrl.checkSession, {
    method: 'GET',
    headers: {
      corporate_id: cId,
      access_token: aT,
      request_from: type,
    },
  })
    .then(r => r.json())
    .then(j => {
      result = j;
    })
    .catch(err => {
      result = err;
    });

  return result;
};
