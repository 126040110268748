import classNames from 'classnames';
import './style.scss';
import { checkIsSp } from "../../utils/fnUtil";

const Checkbox = ({ label, onChange, checked, className, disabled = false }) => {
  const isSp = checkIsSp();

  return (
    <div
      className={classNames('gw-checkbox-wrapper', className, {
        'gw-checkbox-disabled': disabled,
      })}
    >
      <div>
        <label
          className={classNames('gw-checkbox-label', {
            'gw-checkbox-active': checked,
          })}
          style={isSp ? {fontSize: '12px'} : {}}
        >
          {label}
          <input
            className="gw-checkbox"
            type="checkbox"
            disabled={disabled}
            checked={checked}
            onChange={onChange}
          />
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
