import './style.scss';
import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import React, { useEffect } from 'react';
import Button from '../../../components/button';
import { useNavigate } from 'react-router-dom';
import StaffRegisterInfos from '../inputInfos';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Yup from '../../../utils/yupUtil';
import {
  checkIsSp,
  handlerFormikFieldChange,
  handlerFormikSubmit,
} from '../../../utils/fnUtil';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { globalActions } from '../../../redux/slice/global';
import BasicInfos from './basicInfos';
import BodyInfos from './bodyInfos';
import SalaryInfos from './salaryInfos';
import BackGroundInfos from './backgroundInfos';
import AttributeInfos from './attributeInfos';
import AccessInfos from './accessInfos';
import {
  bankingCodeFetch,
  staffActions,
  staffInfoFetch,
  staffInsert,
  staffListFetch,
  staffUpdate,
} from '../../../redux/slice/staff';
import { uploadImage } from '../../../redux/slice/client';
import moment from 'moment';

const StaffRegister = () => {
  //ログイン情報やページングに必要な設定
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountInfo = useSelector(state => state.account);
  const { editParams } = useSelector(state => state.staff);

  //各種API
  const handleFileChange = e => {
    let fileData = e.target.files[0];
    if (fileData?.size > 1024 * 1024) {
      alert('画像は1MB以下でアップロードしてください。');
    } else {
      const reader = new FileReader();
      reader.onload = async event => {
        const imageUri = event.currentTarget.result;
        dispatch(
          uploadImage({
            image: imageUri,
            contentFlag: 3,
            userId: accountInfo.userId,
          })
        ).then(
          ({ payload }) =>
            payload?.code === '0' &&
            handlerFormikFieldChange(formik, 'staffImage', payload.imageUrl)
        );
      };
      reader.readAsDataURL(fileData);
    }
  };

  //formik設定と登録API
  const tabErrorGroup = {
    common: [
      'subManagerName',
      'postCode',
      'postCode1',
      'postCode2',
      'tel',
      'tel1',
      'tel2',
      'tel3',
      'fax',
      'fax1',
      'fax2',
      'fax3',
      'hopeAssign',
      'dependents',
      'dailyPay',
      'hourlyPay',
      'recruitCost',
    ],
    body: ['weight', 'shoeSize'],
    skill: ['admissionYear'],
    pay: ['bankCode', 'bankAccountNumber'],
  };



  const formik = useFormik({
    initialValues: editParams,
    validateOnMount: true,
    validationSchema: Yup.object({
      staffId: Yup.string()
        .nullable()
        .required()
        .matches(/^\d*$/, 'スタッフID:半角数字を入力してください。')
        .max(8, 'スタッフID:8桁以下で入力してください')
        .test(
          'staffId',
          'スタッフID:0から始まるスタッフIDは無効です。',
          (value, context) => {
            if (value?.slice(0, 1) === '0') {
              return false;
            } else {
              return true;
            }
          }
        )
        .label('スタッフID'),
      lastName: Yup.string().nullable().required().label('名前 姓'),
      firstName: Yup.string().nullable().required().label('名前 名'),
      lastNameKana: Yup.string()
        .nullable()
        .required()
        .kana('名前(カナ)セイ')
        .label('名前(カナ)セイ'),
      firstNameKana: Yup.string()
        .nullable()
        .required()
        .kana('名前(カナ)セイ')
        .label('名前(カナ)メイ'),
      mailAddress: Yup.string()
        .nullable()
        .required()
        .mail('メールアドレス')
        .mail_local('メールアドレス')
        .label('メールアドレス'),
      startDate: Yup.string().nullable().required().label('利用開始日'),
      endDate: Yup.string().nullable().required().label('利用終了日'),
      // bankCode: Yup.string()
      //   .when('bankName', (value, schema) =>
      //     value === 'ゆうちょ銀行'
      //       ? schema.max(0, '銀行コード:未記入にしてください。')
      //       : schema
      //   )
      //   .nullable()
      //   .label('金融機関名'),
      bankAccountNumber: Yup.string()
        .when('bankName', (value, schema) =>
          value === 'ゆうちょ銀行' ? schema.required() : schema.nullable()
        )
        .label('口座番号'),
      postCode: Yup.string().nullable().postCode('[基本情報]郵便番号'),
      postCode1: Yup.string().nullable().integer('[基本情報]郵便番号'),
      postCode2: Yup.string().nullable().integer('[基本情報]郵便番号'),
      tel: Yup.string().nullable().tel('[基本情報]TEL(スマートフォン)'),
      tel1: Yup.string().nullable().integer('[基本情報]TEL(スマートフォン)'),
      tel2: Yup.string().nullable().integer('[基本情報]TEL(スマートフォン)'),
      tel3: Yup.string().nullable().integer('[基本情報]TEL(スマートフォン)'),
      fax: Yup.string().nullable().fax('[基本情報]TEL(自宅)'),
      fax1: Yup.string().nullable().integer('[基本情報]TEL(自宅)'),
      fax2: Yup.string().nullable().integer('[基本情報]TEL(自宅)'),
      fax3: Yup.string().nullable().integer('[基本情報]TEL(自宅)'),
      hopeAssign: Yup.string().nullable().integer('[基本情報]稼働希望日数'),
      dependents: Yup.string().nullable().integer('[基本情報]扶養人数'),
      dailyPay: Yup.string().nullable().integer('[基本情報]基本日給'),
      hourlyPay: Yup.string().nullable().integer('[基本情報]基本時給'),
      recruitCost: Yup.string().nullable().integer('[基本情報]採用単価'),
      weight: Yup.string().nullable().integer('[身長・体重など]体重(kg)'),
      shoeSize: Yup.string()
        .nullable()
        .shoeSize('[身長・体重など]靴のサイズ(cm)'),
      admissionYear: Yup.string().nullable().integer('入学年'),
      // subManagerUserId: Yup.string().nullable().identical('[基本情報]サブ担当者'),
    }),
    onSubmit: values => {
      dispatch(
        staffInsert({
          ...values,
          nameKana: {
            firstName: values.firstNameKana,
            lastName: values.lastNameKana,
          },
          name: {
            firstName: values.firstName,
            lastName: values.lastName,
          },
          birthday:
            values.year && values.month && values.day
              ? {
                  year: values.year,
                  month: values.month,
                  day: values.day,
                }
              : null,
          postCode:
            values.postCode1 && values.postCode2
              ? {
                  number1: values.postCode1,
                  number2: values.postCode2,
                }
              : null,
          homeTel:
            values.fax1 && values.fax2 && values.fax3
              ? {
                  number1: values.fax1,
                  number2: values.fax2,
                  number3: values.fax3,
                }
              : null,
          daytimeTel:
            values.tel1 && values.tel2 && values.tel3
              ? {
                  number1: values.tel1,
                  number2: values.tel2,
                  number3: values.tel3,
                }
              : null,
          sendMailFlag: values.sendMailFlag ? 1 : 0,
        })
      )
        .then(result => {
          if (result?.payload?.code === '0') {
            dispatch(globalActions.showSingleModal(`登録しました`));
            dispatch(staffActions.editReset());
            navigate(-1);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
  });
  const { sendMailFlag, staffImage, startDate, endDate } = formik.values;

  //各種ページjsx
  const baseArea = () => (
    <div className={'staff_edit--basic_area'}>
      <CustomFormikInput
        {...StaffRegisterInfos.base.inputs[0]}
        formik={formik}
        key={StaffRegisterInfos.base.inputs[0].inputName}
      />

      <div className={'staff_register--search_area_name'} style={{gap: checkIsSp() ? '8px' : '16px'}}>
        <div className={'staff_register--search_area_label'} style={{fontSize: checkIsSp() ? '12px' : '14px'}}>
          名前<span>*</span>
        </div>
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[1]}
          formik={formik}
          key={StaffRegisterInfos.base.inputs[1].inputName}
        />
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[2]}
          formik={formik}
          key={StaffRegisterInfos.base.inputs[2].inputName}
        />
      </div>

      <div className={'staff_register--search_area_name'} style={{gap: checkIsSp() ? '8px' : '16px'}}>
        <div className={'staff_register--search_area_label'} style={{fontSize: checkIsSp() ? '12px' : '14px'}}>
          名前(カナ)<span>*</span>
        </div>
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[3]}
          formik={formik}
          key={StaffRegisterInfos.base.inputs[3].inputName}
        />
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[4]}
          formik={formik}
          key={StaffRegisterInfos.base.inputs[4].inputName}
        />
      </div>

      <CustomFormikInput
        {...StaffRegisterInfos.base.inputs[5]}
        formik={formik}
        key={StaffRegisterInfos.base.inputs[5].inputName}
      />

      <div className={'staff_edit--calendar_area'}>
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[6]}
          formik={formik}
          customDisabledDate={current =>
            current && current > moment(endDate, 'YYYY/MM/DD')
          }
        />
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[7]}
          formik={formik}
          customDisabledDate={current =>
            current && current < moment(startDate, 'YYYY/MM/DD')
          }
        />
      </div>
    </div>
  );

  //registerベースに送信する設定(管理側)
  const infos = {
    basic: {
      style: {
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        gap: '24px',
      },
      jsx: baseArea,
    },

    detail: {
      tabs: {
        common: {
          label: '基本情報',
          jsx: () => <BasicInfos formik={formik} />,
        },
        traffic: {
          label: '交通情報',
          jsx: () => <AccessInfos formik={formik} />,
        },
        body: {
          label: '身長・体重など',
          jsx: () => <BodyInfos formik={formik} />,
        },
        skill: {
          label: '学歴・資格など',
          jsx: () => <BackGroundInfos formik={formik} />,
        },
        pay: {
          label: '給与支払情報',
          jsx: () => <SalaryInfos formik={formik} dispatch={dispatch} />,
        },
        naming: {
          label: '属性情報',
          jsx: () => <AttributeInfos formik={formik} />,
        },
      },
    },
  };

  //送信ボタン設定
  const buttonStyle = {
    registerButton: {
      width: '240px',
      height: '44px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    backButton: {
      width: '240px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
  };

  useEffect(() => {
    const targetElm = document.querySelector('.register_base--basic_info_image_area_wrap_overlay');
    targetElm.style.borderRadius = '50%';

    dispatch(staffActions.resetParam());
    dispatch(staffInfoFetch());
    dispatch(bankingCodeFetch());
    dispatch(staffListFetch({ sort: 'staffIdDesc', count: 1 }))
      .unwrap()
      .then(e =>
        formik.setFieldValue('staffId', e?.staffList?.item?.[0]?.staffId ? e?.staffList?.item?.[0]?.staffId + 1 : 1)
      );
  }, []);
  return (
    <>
      <RegisterBase
        image={staffImage ? staffImage : Icons.icon.noImage}
        imageShape={'round'}
        infos={infos}
        formik={formik}
        setImage={handleFileChange}
      />
      <div className={'bottom_btn_area'} style={{ height: '136px' }}>
        <div className={'staff_register--mail_send_check'}>
          <CustomFormikInput
            inputType={'checkBox'}
            label={'登録通知メールを送信する'}
            inputName={'sendMailFlag'}
            key={'sendMailFlag'}
            formik={formik}
            onChange={() => {
              handlerFormikFieldChange(formik, 'sendMailFlag', !sendMailFlag);
            }}
          />
        </div>

        <div className={'staff_register--submit_buttons'}>
          <Button
            text={'戻る'}
            style={{
              ...buttonStyle.backButton,
              height: checkIsSp() ? '40px' : '44px',
              fontSize: checkIsSp() ? '12px' : '14px',
            }}
            onClick={() => {
              dispatch(staffActions.editReset());
              navigate(`/staff/list`);
            }}
          />
          <Button
            text={'登録'}
            style={{
              ...buttonStyle.registerButton,
              height: checkIsSp() ? '40px' : '44px',
              fontSize: checkIsSp() ? '12px' : '14px',
            }}
            onClick={() => handlerFormikSubmit(formik, tabErrorGroup)}
          />
        </div>
      </div>
    </>
  );
};

export default StaffRegister;
