import react, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCounter } from '../../../redux/slice/counter';
import {
  venueActions,
  venueDelete,
  venueListFetch,
  venueInit,
} from '../../../redux/slice/venue';
import { store } from '../../../redux/store';
import global, { globalActions } from '../../../redux/slice/global';
import { useFormik } from 'formik';
import { fetchApi, fetchRequest, zipApi } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import ListView from '../../../components/listView';
import Copyright from '../../../components/copyright';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import CsvImportModal from '../../../components/modal/csvImportModal';
import CsvExportModal from '../../../components/modal/csvExportModal';
import Options from '../../../constants/options';
import Icons from '../../../constants/icons';
import prefectures from '../../../constants/prefectures';
import { checkIsSp } from '../../../utils/fnUtil';
import './style.scss';

const isSp = checkIsSp();

const VenueList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiData, setApiData] = useState({ item: [] });
  const [allVenue, setAllVenue] = useState({ item: [] });
  const searchInfo = useSelector(state => state.corporate.searchParams);

  const loginType = useSelector(state => state.loginType.type);
  const accountInfo = useSelector(state => state.account);
  const {
    namingCategoryList,
    namingList,
    namingListPriorityAsc,
    venueList,
    searchParams,
    csvImportVisible,
    csvExportVisible,
  } = useSelector(state => state.venue);
  const counterState = useSelector(state => state.counter);
  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => dispatch(venueActions.saveSearchParams(values)),
  });
  const {
    namingClassificationId,
    sortKey: sortColumn,
    sortMethod,
  } = formik.values;

  const status = [
    { label: 'すべて', value: 99 },
    { label: '表示', value: 1 },
    { label: '削除済', value: 0 },
  ];
  const venueFlagOptVal = [
    // {
    //   val: '',
    //   opt: 'すべて',
    // },
    {
      val: 0,
      opt: '自社店舗',
    },
    {
      val: 1,
      opt: '催事場',
    },
    {
      val: 2,
      opt: '展示会場',
    },
    {
      val: 9,
      opt: 'その他',
    },
  ];

  const updateApiData = data => {
    const titleCount = [data?.totalCount ?? 0, data?.activeCount ?? 0];

    const formatData = {
      ...data,
      item: data.item.map((obj, index) => {
        return obj.status === -1
          ? { ...obj, editIconState: 1, deleteIconState: 2 }
          : obj;
      }),
    };

    setApiData(formatData);
    dispatch(setCounter({ ...counterState, corporateCounter: titleCount }));
    store.dispatch(globalActions.setLoading(false));
  };

  const searchInfos = {
    inputs: [
      {
        inputType: 'input', //inputのtype
        label: '開催地ID', //ラベルの名前
        inputName: 'venueId', //DBのカラム名
        placeholder: '開催地IDを入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: isSp ? '100%' : '258px' },
        },
      },
      {
        inputType: 'select', //inputのtype
        showSearch: false,
        mode: 'multiple',
        allowClear: false,
        label: '種別', //ラベルの名前
        placeholder: '種別を選択してください',
        inputName: 'venueFlag',
        initialValue: venueFlagOptVal.map(e => e?.val),
        selectBoxOptions: venueFlagOptVal.map(e => e?.opt),
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: isSp ? '100%' : '136px' },
        },
      },
      {
        inputType: 'input', //inputのtype
        label: '開催地名', //ラベルの名前
        inputName: 'venueName', //DBのカラム名
        placeholder: '開催地名を入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: isSp ? '100%' : '300px' },
        },
      },
      {
        inputType: 'select', //inputのtype
        showSearch: false,
        mode: 'multiple',
        allowClear: false,
        label: '都道府県', //ラベルの名前
        placeholder: '都道府県を選択してください',
        inputName: 'prefCode',
        initialValue: prefectures.map(p => p?.prefecturesCode),
        selectBoxOptions: prefectures.map(p => p?.label),
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: isSp ? '100%' : '136px' },
        },
      },
      {
        inputType: 'select', //inputのtype
        showSearch: false,
        allowClear: true,
        label: '予備項目', //ラベルの名前
        placeholder: '予備項目を選択してください',
        inputName: 'namingClassificationId',
        initialValue: namingCategoryList.map(p => p?.value),
        selectBoxOptions: namingCategoryList.map(p => p?.label),
        selectedSelectBox: 'ClientPreliminary1',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: isSp ? '100%' : '228px' },
        },
      },
      {
        inputType: 'select', //inputのtype
        showSearch: false,
        mode: 'multiple',
        allowClear: false,
        label: '', //ラベルの名前
        inputName: 'namingId',
        initialValue: namingListPriorityAsc
          ?.filter(n => n?.namingClassificationId === namingClassificationId)
          .map(p => p?.value),
        selectBoxOptions: namingListPriorityAsc
          ?.filter(n => n?.namingClassificationId === namingClassificationId)
          .map(p => `${p?.namingId}:${p?.label}`),
        placeholder: '選択してください',
        disabled: !namingClassificationId,
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: isSp
            ? { width: '100%', marginTop: '-14px' }
            : { width: '291px', marginLeft: '-20px' },
        },
      },
      {
        inputType: 'select', //inputのtype
        showSearch: false,
        allowClear: false,
        label: 'ステータス', //ラベルの名前
        inputName: 'status',
        initialValue: status.map(s => s.value),
        selectBoxOptions: status.map(s => s.label),
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: isSp ? '100%' : '150px' },
        },
      },
    ],
  };
  const venueListSearchArea = () => (
    <div className={'venue_list--input_area'}>
      <CustomFormikInput
        {...searchInfos.inputs[0]}
        formik={formik}
        key={searchInfos.inputs[0].inputName}
      />
      <CustomFormikInput
        {...searchInfos.inputs[1]}
        formik={formik}
        key={searchInfos.inputs[1].inputName}
      />
      <CustomFormikInput
        {...searchInfos.inputs[2]}
        formik={formik}
        key={searchInfos.inputs[2].inputName}
      />
      <CustomFormikInput
        {...searchInfos.inputs[3]}
        formik={formik}
        key={searchInfos.inputs[3].inputName}
      />
      <div className={'venue_list--input_area'}>
        <CustomFormikInput
          {...searchInfos.inputs[4]}
          formik={formik}
          key={searchInfos.inputs[4].inputName}
        />
        <CustomFormikInput
          {...searchInfos.inputs[5]}
          formik={formik}
          key={searchInfos.inputs[5].inputName}
        />
      </div>
      <CustomFormikInput
        {...searchInfos.inputs[6]}
        formik={formik}
        key={searchInfos.inputs[6].inputName}
      />
    </div>
  );
  const searchFormInfo = {
    jsx: venueListSearchArea,
  };

  const columnSettings = {
    venueId: { label: '開催地ID', sort: 'asc', dataName: 'venueId' },
    venueFlag: { label: '種別', sort: 'none', dataName: 'venueFlag' },
    venueName: { label: '開催地名', sort: 'none', dataName: 'venueName' },
    prefName: { label: '都道府県', sort: 'none', dataName: 'prefCode' },
    idxClbk: {
      label: `${namingCategoryList
        .filter(n => n?.namingId === 'ClientPreliminary1')
        .map(p => p?.naming)}`,
      sort: 'none',
      dataName: 'preliminary',
    },
    status: { label: 'ステータス', sort: 'none', dataName: 'status' },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    import: {
      icon: Icons.icon.import,
      text: 'CSV取込',
      onClick: () => dispatch(venueActions.setCsvImportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => dispatch(venueActions.setCsvExportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    addButton: {
      props: {
        onClick: () => {
          navigate('/venue/register');
        }, //ボタンをクリックしたときにfunctionが発火する（urlより優先される）
        displayText: true, // trueを指定すると「新規追加」吹き出しが表示される
      },
    },
  };

  const changeSort = e => {
    dispatch(
      venueActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`,
      })
    );
  };

  const getApiData = async () => {
    let apiData;
    let requestBody = { ...searchInfo };

    for (let k in requestBody) {
      if (requestBody[k] === '' || requestBody[k] === undefined) {
        delete requestBody[k];
      }
    }

    store.dispatch(globalActions.setLoading(true));

    await fetchApi(
      'venue',
      'list',
      'GET',
      loginType,
      accountInfo.corporateId,
      accountInfo.accessToken,
      {
        ...requestBody,
        sort: `${searchInfo.sort.column}${searchInfo.sort.method}`,
      }
    )
      .then(data => {
        apiData = data;
      })
      .then(() => {
        updateApiData(apiData);
      })
      .catch(error => {
        navigate('/');
        store.dispatch(globalActions.setLoading(false));
        store.dispatch(
          globalActions.showSingleModal(
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
          )
        );
      });

    await fetchApi(
      'venue',
      'list',
      'GET',
      loginType,
      accountInfo.corporateId,
      accountInfo.accessToken,
      { offset: 0 }
    )
      .then(data => {
        setAllVenue(data);
      })
      .catch(error => {
        navigate('/');
        store.dispatch(globalActions.setLoading(false));
        store.dispatch(
          globalActions.showSingleModal(
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
          )
        );
      });
  };

  useEffect(() => {
    dispatch(venueInit());
    const searchBase =document.querySelector('.list_view--search_base');
    searchBase.style.zIndex = 2;
    return () => {
      dispatch(venueActions.saveParam());
      dispatch(venueActions.refresh());
    };
  }, []);
  useEffect(() => {
    dispatch(venueListFetch(searchParams));
  }, [dispatch, searchParams]);
  useEffect(() => {
    dispatch(venueActions.editReset());
    dispatch(venueActions.zipSearchFlag(false));
    dispatch(
      setCounter({
        ...counterState,
        venueCounter: venueList?.topCount ?? [0, 0],
      })
    );
  }, [venueList]);

  const handleDelete = key => {
    fetchRequest(apiUrl.venue.delete, { venueId: key }, 'POST').then(() => {
      store.dispatch(globalActions.showSingleModal('削除しました'));
      getApiData();
    });
  };

  return (
    <>
      <div className="commonPage--base">
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey="venueId"
          primaryName="venueName"
          apiData={venueList}
          sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={searchCount =>
            dispatch(venueActions.saveSearchParams({ count: searchCount }))
          }
          onChangeOffset={offset => dispatch(venueActions.saveOffset(offset))}
          onDelete={handleDelete}
        />
        <Copyright />
      </div>

      <CsvImportModal
        importType="venueList"
        csvImportVisible={csvImportVisible}
        setCsvImportVisible={() =>
          dispatch(venueActions.setCsvImportVisible(!csvImportVisible))
        }
      />

      <CsvExportModal
        exportType="venueList"
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={() =>
          dispatch(venueActions.setCsvExportVisible(!csvExportVisible))
        }
        searchParams={searchParams}
      />
    </>
  );
};

export default VenueList;
