import { Router, useNavigate } from 'react-router-dom';
import Icons from '../../../constants/icons';
import Copyright from '../../../components/copyright';
import React, { useEffect, useState } from 'react';
import './style.scss';
import CustomListView from '../customListView';
import { useDispatch, useSelector } from 'react-redux';
import {
  incomeTaxDelete,
  incomeTaxHeaderUpdate,
  incomeTaxInit,
} from '../../../redux/slice/incomeTax';
import { globalActions } from '../../../redux/slice/global';
import { Tabs } from 'antd';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import CustomInput from '../../../components/customInput';
import Button from '../../../components/button';
import moment from 'moment';
import { useFormik } from 'formik';
import Yup from '../../../utils/yupUtil';
import { handlerFormikSubmit, checkIsSp } from '../../../utils/fnUtil';

const IncomeTaxList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { activeTab } = useSelector(state => state.global);
  const loginUserAuth = useSelector(state => state.account.auth);
  const isSys = loginUserAuth === 0;
  const { TabPane } = Tabs;
  const isSp = checkIsSp();
  const { isOpenSideMenu } = useSelector(state => state.global);

  const { incomeTaxData } = useSelector(state => state.incomeTax);

  const [searchInfo, setSearchInfo] = useState({
    chartId: '',
    startDate: '',
    offset: 0,
    count: 20,
  });

  const columnSettings = {
    incomeAmountFrom: {
      label: '月額課税対象額',
      sort: 'none',
      dataName: 'incomeAmountFrom',
    },
    incomeAmountTo: {
      label: '月額課税対象額',
      sort: 'none',
      dataName: 'incomeAmountTo',
    },
    kouTax0: { label: '0人', sort: 'none', dataName: 'kouTax0' },
    kouTax1: { label: '1人', sort: 'none', dataName: 'kouTax1' },
    kouTax2: { label: '2人', sort: 'none', dataName: 'kouTax2' },
    kouTax3: { label: '3人', sort: 'none', dataName: 'kouTax3' },
    kouTax4: { label: '4人', sort: 'none', dataName: 'kouTax4' },
    kouTax5: { label: '5人', sort: 'none', dataName: 'kouTax5' },
    kouTax6: { label: '6人', sort: 'none', dataName: 'kouTax6' },
    kouTax7: { label: '7人', sort: 'none', dataName: 'kouTax7' },
    taxRate: { label: '課税率', sort: 'none', dataName: 'taxRate' },
    taxAmount: { label: '課税額', sort: 'none', dataName: 'taxAmount' },
    calculationTaxFlag: {
      label: '計算方法',
      sort: 'none',
      dataName: 'calculationTaxFlag',
    },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    addButton: {
      props: {
        onClick: () => {
          navigate('/master/income-tax/register', {
            state: {
              chartId: activeTab
                ? activeTab
                : incomeTaxData?.header?.[0]?.['chartId'],
              startDate: activeTab
                ? formik.values[`startDate${activeTab}`]
                : formik.values[
                    `startDate${incomeTaxData?.header?.[0]?.['chartId']}`
                  ],
            },
          });
        },
        displayText: true,
      },
    },
  };

  const [{ sortColumn, sortMethod }, setSort] = useState({
    sortColumn: 'incomeAmountFrom',
    sortMethod: 'asc',
  });

  const changeSort = e => {
    if (e.target.id === sortColumn) {
      sortMethod === 'asc'
        ? setSort({ sortColumn: sortColumn, sortMethod: 'desc' })
        : setSort({ sortColumn: sortColumn, sortMethod: 'asc' });
    } else {
      setSort({ sortColumn: e.target.id, sortMethod: 'asc' });
    }
  };

  const handleChangeListPerPage = num => {
    setSearchInfo({ ...searchInfo, count: num });
    dispatch(
      incomeTaxInit({
        ...searchInfo,
        count: num,
        chartId: activeTab
          ? activeTab
          : incomeTaxData?.header?.[0]?.['chartId'],
      })
    );
  };

  const handleChangePage = num => {
    setSearchInfo({ ...searchInfo, offset: num });
    dispatch(
      incomeTaxInit({
        ...searchInfo,
        offset: num,
        chartId: activeTab
          ? activeTab
          : incomeTaxData?.header?.[0]?.['chartId'],
      })
    );
  };

  const onDelete = (chartId, incomeTaxId, startDate) => {
    dispatch(incomeTaxDelete({ chartId, incomeTaxId, startDate }));
  };

  const tabErrorGroup = {
    A: ['startDateA'],
    B: ['startDateB'],
  };
  const formik = useFormik({
    initialValues: {
      [`startDate${incomeTaxData?.header[0]['chartId']}`]:
        incomeTaxData?.header && incomeTaxData.header[0]['startDate'],
      [`startDate${incomeTaxData?.header[1]['chartId']}`]:
        incomeTaxData?.header && incomeTaxData.header[1]['startDate'],
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      startDateA: Yup.string().required().label('適用開始日'),
      startDateB: Yup.string().required().label('適用開始日'),
    }),
    onSubmit: values => {
      dispatch(
        incomeTaxHeaderUpdate({
          chartId: activeTab
            ? activeTab
            : incomeTaxData?.header?.[0]?.['chartId'],
          startDate: activeTab
            ? values[`startDate${activeTab}`]
            : values[`startDate${incomeTaxData?.header?.[0]?.['chartId']}`],
        })
      );
    },
  });

  useEffect(() => {
    dispatch(incomeTaxInit());
  }, []);

  const thisYearIncomeTaxList = () => (
    <>
      <div className={'income_tax--list_start_date'}>
        <div className={'income_tax--list_label'}>
          適用開始日<span>*</span>
        </div>
        <CustomFormikInput
          formik={formik}
          inputType={'inputDate'}
          inputName={`startDate${incomeTaxData?.header?.[0]?.['chartId']}`}
          key={`startDate${incomeTaxData?.header?.[0]?.['chartId']}`}
          placeholder={'選択してください'}
          disabled={!isSys}
          style={isSp ? { areaStyle: { width: '40%' } } : {}}
        />
      </div>

      <CustomListView
        columnSettings={columnSettings}
        buttonSettings={buttonSettings}
        primaryKey={'incomeTaxId'}
        primaryName={'income_tax_name'}
        apiData={incomeTaxData}
        sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
        changeSort={changeSort}
        onDelete={onDelete}
        onChangeCount={handleChangeListPerPage}
        onChangeOffset={handleChangePage}
        changeTab={activeTab}
        isSys={isSys}
      />
    </>
  );

  const nextYearIncomeTaxList = () => (
    <>
      <div className={'income_tax--list_start_date'}>
        <div className={'income_tax--list_label'}>
          適用開始日<span>*</span>
        </div>
        <CustomFormikInput
          formik={formik}
          inputType={'inputDate'}
          inputName={`startDate${incomeTaxData?.header?.[1]?.['chartId']}`}
          key={`startDate${incomeTaxData?.header?.[1]?.['chartId']}`}
          placeholder={'選択してください'}
          disabled={!isSys}
          style={isSp ? { areaStyle: { width: '40%' } } : {}}
        />
      </div>

      <CustomListView
        columnSettings={columnSettings}
        buttonSettings={buttonSettings}
        primaryKey={'incomeTaxId'}
        primaryName={'income_tax_name'}
        apiData={incomeTaxData}
        sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
        changeSort={changeSort}
        onDelete={onDelete}
        onChangeCount={handleChangeListPerPage}
        onChangeOffset={handleChangePage}
        changeTab={activeTab}
        isSys={isSys}
      />
    </>
  );

  const tabsInfo = {
    [`${incomeTaxData?.header[0]['chartId']}`]: {
      label:
        incomeTaxData?.header &&
        moment(incomeTaxData.header[0]['startDate']).format('YYYY/M/D'),
      jsx: thisYearIncomeTaxList,
    },
    [`${incomeTaxData?.header[1]['chartId']}`]: {
      label:
        incomeTaxData?.header &&
        moment(incomeTaxData.header[1]['startDate']).format('YYYY/M/D'),
      jsx: nextYearIncomeTaxList,
    },
  };

  const buttonStyle = {
    registerButton: {
      width: '240px',
      height: '44px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
  };

  return (
    <>
      <div
        className={'commonPage--base income_tax--list_page'}
        style={{ paddingTop: '68px' }}
      >
        <div className={'income_tax--border'} />

        <Tabs
          defaultActiveKey={incomeTaxData?.header[0]['chartId']}
          activeKey={activeTab ? activeTab : Object.keys(tabsInfo)[0]}
          onChange={tab => {
            dispatch(globalActions.changeActiveTab(tab));
            dispatch(
              incomeTaxInit({
                ...searchInfo,
                chartId:
                  activeTab === 'A'
                    ? 'B'
                    : activeTab === 'B'
                    ? 'A'
                    : incomeTaxData?.header?.[1]?.['chartId'],
                offset: 0,
                count: 20,
              })
            );
          }}
          style={{ margin: isSp ? '0 20px' : '0 40px' }}
        >
          {Object.keys(tabsInfo).map(key => {
            return (
              <TabPane tab={tabsInfo[key].label} key={key}>
                {tabsInfo[key].inputs ? (
                  <div className={'register_base--detail_info_tab_page'}>
                    {tabsInfo[key].inputs.map((obj, index) => {
                      return tabsInfo ? (
                        <CustomFormikInput
                          formik={tabsInfo}
                          key={`${tabsInfo[key].label}_${index}`}
                          {...obj}
                        />
                      ) : (
                        <CustomInput
                          key={`${tabsInfo[key].label}_${index}`}
                          {...obj}
                        />
                      );
                    })}
                  </div>
                ) : tabsInfo[key].jsx ? (
                  tabsInfo[key].jsx()
                ) : (
                  tabsInfo[key].component
                )}
              </TabPane>
            );
          })}
        </Tabs>

        {/*システム管理者のみ表示*/}
        {isSys && (
          <div
            className={'income_tax_list--button_area'}
            style={{
              width: isSp
                ? '100%'
                : isOpenSideMenu
                ? 'calc(100% - 200px)'
                : 'calc(100% - 56px)',
              transition: 'width .2s',
            }}
          >
            <Button
              text={'更新'}
              style={buttonStyle.registerButton}
              onClick={() => {
                handlerFormikSubmit(formik, tabErrorGroup);
              }}
            />
          </div>
        )}
      </div>
      <Copyright
        style={{
          marginBottom: isSys ? '100px' : 0,
          width: isSp
            ? '100%'
            : isOpenSideMenu
            ? 'calc(100% - 200px)'
            : 'calc(100% - 56px)',
          transition: 'width .2s',
        }}
      />
    </>
  );
};

export default IncomeTaxList;
