import './style.scss';
import CustomInput from '../customInput';
import React, { useEffect, useState } from 'react';
import CustomFormikInput from '../customInput/customFormikInput';
import { checkIsSp } from '../../utils/fnUtil';

const BirthdaySelector = ({ initialDate, onChange, formik, forSp }) => {
  const [selectedYear, setYear] = useState(initialDate?.year ?? null);
  const [isLeapYear, setIsLeapYear] = useState(false);
  const [selectedMonth, setMonth] = useState(initialDate?.month ?? null);

  const generateYears = () => {
    let years = [];
    const thisYear = new Date().getFullYear();

    for (let i = 0; i < 120; i++) {
      years.push(thisYear - i);
    }

    return years;
  };

  const checkLeapYear = year => {
    setIsLeapYear(new Date(year, 2, 0).getDate() === 29);
  };

  let birthdays = {
    years: generateYears(),
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    days:
      selectedMonth == '2' || (formik?.values?.month == '2' && isLeapYear)
        ? [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
          ]
        : selectedMonth == '2' || formik?.values?.month == '2'
        ? [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28,
          ]
        : selectedMonth == '4' ||
          selectedMonth == '6' ||
          selectedMonth == '9' ||
          selectedMonth == '11' ||
          formik?.values?.month == '4' ||
          formik?.values?.month == '6' ||
          formik?.values?.month == '9' ||
          formik?.values?.month == '11'
        ? [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
          ]
        : [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
          ],
  };

  const restProps = {
    inputType: 'select',
    style: {
      areaStyle: { width: '134px' },
      inputStyle: { width: '110px' },
    },
  };

  const selectYear = e => {
    setYear(e);
    onChange && onChange('year', e);
  };

  const selectMonth = e => {
    setMonth(e);
    onChange && onChange('month', e);
  };

  const selectDay = e => {
    onChange && onChange('day', e);
  };

  useEffect(() => {
    if (formik) {
      checkLeapYear(formik.values.year);
    } else {
      checkLeapYear(selectedYear);
    }
  }, [formik?.values?.year, selectedYear]);

  return forSp ? (
    <>
      {/* <label style={{color: '#646464', fontSize: '12px'}}>
        生年月日
        <span style={{ color: '#D80245' }}>*</span>
      </label> */}

      <div style={{marginTop: '8px' ,width:'100%'}}>
        <CustomFormikInput
          inputType={'select'}
          showSearch={false}
          allowClear={false}
          inputName={'year'}
          key={'year'}
          initialValue={birthdays.years}
          selectBoxOptions={birthdays.years}
          placeholder={'YYYY'}
          unit={'年'}
          formik={formik}
          style={{
            areaStyle: { width: '100%' },
            // inputStyle: { width: '110px' },
          }}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '16px',
            marginTop: checkIsSp() ? '8px' : '16px',
          }}
        >
          <CustomFormikInput
            inputType={'select'}
            inputName={'month'}
            key={'month'}
            showSearch={false}
            allowClear={false}
            initialValue={birthdays.months}
            selectBoxOptions={birthdays.months}
            placeholder={'MM'}
            unit={'月'}
            disabled={!formik.values.year}
            formik={formik}
            style={{
              areaStyle: { width: '50%' },
              // inputStyle: { width: '110px' },
            }}
          />
          <CustomFormikInput
            inputType={'select'}
            inputName={'day'}
            key={'day'}
            showSearch={false}
            allowClear={false}
            initialValue={birthdays.days}
            selectBoxOptions={birthdays.days}
            placeholder={'DD'}
            unit={'日'}
            disabled={!formik.values.month}
            formik={formik}
            style={{
              areaStyle: { width: '50%' },
              // inputStyle: { width: '110px' },
            }}
          />
        </div>
      </div>
    </>
  ) : (
    <div className={'birthday_selector--wrap'}>
      <CustomFormikInput
        {...restProps}
        inputName={formik ? 'year' : 'birthday_year'}
        key={formik ? 'year' : 'birthday_year'}
        initialValue={birthdays.years}
        selectBoxOptions={birthdays.years}
        placeholder={'YYYY'}
        unit={'年'}
        onChange={formik ? (() => {})() : selectYear}
        selectedSelectBox={formik ? null : selectedYear}
        formik={formik}
      />
      <CustomFormikInput
        {...restProps}
        inputName={formik ? 'month' : 'birthday_ month'}
        key={formik ? 'month' : 'birthday_ month'}
        initialValue={birthdays.months}
        selectBoxOptions={birthdays.months}
        placeholder={'MM'}
        unit={'月'}
        onChange={formik ? (() => {})() : selectMonth}
        disabled={formik ? !formik.values.year : !selectedYear}
        selectedSelectBox={formik ? null : selectedMonth}
        formik={formik}
      />
      <CustomFormikInput
        {...restProps}
        inputName={formik ? 'day' : 'birthday_day'}
        key={formik ? 'day' : 'birthday_day'}
        initialValue={birthdays.days}
        selectBoxOptions={birthdays.days}
        placeholder={'DD'}
        unit={'日'}
        onChange={formik ? (() => {})() : selectDay}
        disabled={formik ? !formik.values.month : !selectedMonth}
        selectedSelectBox={
          formik ? null : initialDate.day ? initialDate.day : null
        }
        formik={formik}
      />
    </div>
  );
};

export default BirthdaySelector;
