import * as Yup from 'yup';
import { isNumber } from 'lodash';
import { isAllNullOrAllNotNull } from './fnUtil';
import moment from 'moment';

Yup.setLocale({
  mixed: {
    required: ({ label }) => `${label ? `${label}:` : ''}必須項目です。`,
    // notType: ({ label }) => `${label ? `${label}:` : ''}数字で入力してください`,
  },
  string: {
    min: ({ min, label }) =>
      `${label ? `${label}:` : ''}${min}文字以上入力してください`,
    max: ({ max, label }) =>
      `${label ? `${label}:` : ''}${max}文字以内で入力して下さい`,
  },
  number: {
    max: ({ max, label }) => `${label ? `${label}:` : ''}最大${max}`,
    positive: ({ label }) =>
      `${label ? `${label}:` : ''}0以上の数字で入力してください`,
  },
});

Yup.addMethod(Yup.number, 'numberRequired', function (fieldName) {
  return this.test(
    'numberRequired',
    `${fieldName ? `${fieldName}:` : ''}必須項目です。`,
    value => isNumber(value)
  );
});

Yup.addMethod(Yup.array, 'arrayRequired', function (fieldName) {
  return this.test(
    'arrayRequired',
    `${fieldName ? `${fieldName}:` : ''}必須項目です。`,
    value => (value ?? []).length > 0
  );
});

Yup.addMethod(Yup.string, 'integerString', function (message) {
  return this.test('integerString', (v, { path, createError }) => {
    let mes;

    if (v) {
      if (!v.match(/^(0|[1-9]\d{0,})$/)) {
        mes = `${message}:半角数字のみで入力してください`;
      } else if (!v.match(/^(0|[1-9]\d{0,8})$/)) {
        mes = `${message}:10桁以下の数字で入力してください`;
      }
    }

    return (
      !mes ||
      createError({
        message: mes,
        path,
      })
    );
  });
});

Yup.addMethod(Yup.string, 'integer', function (message) {
  const reg = /^[0-9]+$/;

  return this.test(
    'integer',
    `${message}:数字で入力してください`,
    v => !v || reg.test(v.replaceAll(',', ''))
  );
});

Yup.addMethod(Yup.string, 'nonZeroInteger', function (message) {
  // const reg = /^[1-9]+$|0+$/;
  const reg =/^[1-9]\d*/;

  return this.test(
    'nonZeroInteger',
    `${message}:数字で入力してください`,
    v => !v || reg.test(v.replaceAll(',', ''))
  );
});

Yup.addMethod(Yup.string, 'numberString', function (message) {
  const reg = /^[0-9]+(\.[0-9]{0,2})?$/;

  return this.test(
    'numberString',
    `${message}:10桁以下の数字で入力してください`,
    v => !v || (reg.test(v) && v < 1e9)
  );
});

Yup.addMethod(Yup.string, 'ntaNumber', function (message) {
  const reg = /0|^[1-9]\d{0,12}$/;

  return this.test(
    'ntaNumber',
    `${message}:13桁の数字で入力してください`,
    v => !v || reg.test(v.replaceAll(',', ''))
  );
});
Yup.addMethod(Yup.string, 'tel', function (message) {
  return this.test(
    'tel',
    `${message}:形式が異なります`,
    (_, context) =>
      isAllNullOrAllNotNull([
        context.parent.tel1,
        context.parent.tel2,
        context.parent.tel3,
      ]) &&
      /^(\s*|\d+)$/.test(
        `${context.parent.tel1 ?? ''}${context.parent.tel2 ?? ''}${
          context.parent.tel3 ?? ''
        }`
      )
  );
});
Yup.addMethod(Yup.string, 'phone', function (message) {
  return this.test(
    'phone',
    `${message}:形式が異なります`,
    (_, context) =>
      isAllNullOrAllNotNull([
        context.parent?.tel?.number1,
        context.parent?.tel?.number2,
        context.parent?.tel?.number3,
      ]) &&
      /^(\s*|\d+)$/.test(
        `${context.parent?.tel?.number1 ?? ''}${
          context.parent?.tel?.number2 ?? ''
        }${context.parent?.tel?.number3 ?? ''}`
      )
  );
});
Yup.addMethod(Yup.string, 'requiredPhone', function (message) {
  return this.test('tel', `${message}:必須項目です。`, (_, context) =>
    isAllNullOrAllNotNull([
      context.parent.tel1,
      context.parent.tel2,
      context.parent.tel3,
    ])
  );
});
Yup.addMethod(Yup.string, 'checkPhone', function (message) {
  return this.test('tel', `${message}:形式が異なります。`, (_, context) =>
    /^(\s*|\d+)$/.test(
      `${context.parent?.tel1 ?? ''}${context.parent?.tel2 ?? ''}${
        context.parent?.tel3 ?? ''
      }`
    )
  );
});
Yup.addMethod(Yup.string, 'fax', function (message) {
  return this.test(
    'fax',
    `${message}:形式が異なります`,
    (_, context) =>
      isAllNullOrAllNotNull([
        context.parent.fax1,
        context.parent.fax2,
        context.parent.fax3,
      ]) &&
      /^(\s*|\d+)$/.test(
        `${context.parent.fax1 ?? ''}${context.parent.fax2 ?? ''}${
          context.parent.fax3 ?? ''
        }`
      )
  );
});

Yup.addMethod(Yup.string, 'postCode', function (message) {
  return this.test(
    'postCode',
    `${message}:形式が異なります。`,
    (_, context) =>
      isAllNullOrAllNotNull([
        context.parent.postCode1,
        context.parent.postCode2,
      ]) &&
      /^(\s*|\d{7})$/.test(
        `${context.parent.postCode1 ?? ''}${context.parent.postCode2 ?? ''}`
      )
  );
});
Yup.addMethod(Yup.string, 'requiredPostCode', function (message) {
  return this.test('postCode', `${message}:必須項目です。`, (_, context) =>
    isAllNullOrAllNotNull([context.parent.postCode1, context.parent.postCode2])
  );
});
Yup.addMethod(Yup.string, 'checkPostCode', function (message) {
  return this.test('postCode', `${message}:形式が異なります。`, (_, context) =>
    /^(\s*|\d{7})$/.test(
      `${context.parent.postCode1 ?? ''}${context.parent.postCode2 ?? ''}`
    )
  );
});
Yup.addMethod(Yup.string, 'kana', function (message) {
  return this.test(
    'kana',
    `${message}:形式が異なります`,
    value =>
      !value ||
      /^[ア-ヴ゛゜ァ-ォャ-ョー「」、ｱ-ﾝﾞﾟｧ-ｫｬ-ｮｰ｡｢｣､　 ]+$/.test(value)
  );
});
Yup.addMethod(Yup.string, 'url', function (message) {
  return this.test(
    'url',
    `${message}:形式が異なります`,
    value =>
      !value || /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/.test(value)
  );
});
Yup.addMethod(Yup.string, 'mail_local', function (message) {
  return this.test(
    'mail_local',
    `${message}:@より前は64文字以内で入力してください`,
    value =>
      !value ||
      /(^[a-zA-Z.0-9-._/#?!%&~+]{1,64})+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
        value
      )
  );
});
Yup.addMethod(Yup.string, 'mail', function (message) {
  return this.test(
    'mail',
    `${message}:形式が異なります`,
    value =>
      !value ||
      /^[a-zA-Z.0-9-._/#?!%&~+]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'selected', function (message) {
  return this.test('selected', `${message}:必須項目です`, value => !!value);
});
Yup.addMethod(Yup.string, 'identical', function (message) {
  return this.test(
    'identical',
    `${message}:メイン担当者と重複しています`,
    (_, context) =>
      !!context.parent.mainManagerUserId &&
      context.parent.mainManagerUserId !== context.parent.subManagerUserId
  );
});

Yup.addMethod(Yup.string, 'incomeAmountTo', function (message) {
  return this.test(
    'incomeAmountTo',
    `${message}:小なり大で設定してください`,
    (_, context) =>
      Number(context.parent.incomeAmountFrom) < Number(context.parent.incomeAmountTo)
  );
});

Yup.addMethod(Yup.string, 'time', function (message) {
  return this.test(
    'time',
    `${message}:形式が異なります`,
    value => !value || moment(value, 'HH:mm', true).isValid()
  );
});

Yup.addMethod(Yup.array, 'ArrayRequired', function (message) {
  return this.test(
    'ArrayRequired',
    `${message}:必須項目です`,
    (value, context) => {
      if (value[0] === '' || value[0] === null || value[0] === undefined) {
        return false;
      } else {
        return true;
      }
    }
  );
});
Yup.addMethod(Yup.string, 'listCheck', function (message, list) {
  return this.test(
    'listCheck',
    `${message}:重複しています`,
    (_, context) => !list?.some(u => u?.venueName == context.parent.venueName)
  );
});
Yup.addMethod(Yup.string, 'invoiceRequired', function (fieldName) {
  return this.test(
    'invoiceRequired',
    `${fieldName ? `${fieldName}:` : ''}必須項目です。`,
    (_, context) =>
      context.parent.matterNumber != null ||
      context.parent.invoiceNumber != null
  );
});
Yup.addMethod(Yup.string, 'diffFromToDate', function () {
  return this.test(
    'diffFromToDate',
    `年月:検索期間は1年以内で選択してください`,
    (_, context) => {
      const yearMonthFrom = new Date(context.parent.yearMonthFrom);
      const yearMonthTo = new Date(context.parent.yearMonthTo);
      const fromNum =
        yearMonthFrom.getFullYear() * 12 + yearMonthFrom.getMonth();
      const toNum = yearMonthTo.getFullYear() * 12 + yearMonthTo.getMonth();
      const diff = toNum - fromNum;
      if (diff > 11) {
        return false;
      } else {
        return true;
      }
    }
  );
});
Yup.addMethod(Yup.string, 'shoeSize', function (message) {
  return this.test(
    'shoeSize',
    `${message}:形式が異なります`,
    // value => !value || /^([1-9]\d*|0)(\.\d+)?$/.test(value)
    value => !value || /^([1-9][0-9]{0,1}|0)(\.[5]{1})?$/.test(value)
  );
});

export default Yup;
