import { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Tabs } from 'antd';
import { transform } from 'lodash';
import { globalActions } from '../../../redux/slice/global';
import {
  handlerFormikSubmit,
  handlerFormikFieldChange,
  checkIsSp,
} from '../../../utils/fnUtil';
import {
  fetchStaffRecruitMatterDetail,
  staffRecruitMatterDetailInit,
  updateStaffRecruitMatter,
  fetchMatterDate,
  fetchMatterShift,
  staffRecruitMatterActions,
} from '../../../redux/slice/staffRecruitMatter';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import Icons from '../../../constants/icons';
import StaffMatterTab from './tabs/staffMatterTab';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Yup from '../../../utils/yupUtil';
import moment from 'moment';
import './style.scss';

const StaffMatterRegister = memo(() => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isSp = checkIsSp();

  const {
    staffRecruitMatterDetail,
    matterList,
    matterDateList,
    matterShiftList,
    loading,
  } = useSelector(state => state.staffRecruitMatter);

  const { activeTab } = useSelector(state => state.global);

  const tabError = [
    {
      inputName: 'MatterTitle',
      label: '募集案件タイトル',
    },
    {
      inputName: 'MatterJob',
      label: '募集業務',
    },
    {
      inputName: 'MatterDetail',
      label: '募集概要',
    },
    {
      inputName: 'Wear',
      label: '服装',
      type: 'array',
    },
    {
      inputName: 'PayAmount',
      label: '単価',
      integerString: true,
    },
    {
      inputName: 'PayFare',
      label: '交通費支給',
    },
  ];

  const formik = useFormik({
    initialValues: {
      openDate: moment().format('YYYY/MM/DD'),
      closeDate: moment().format('YYYY/MM/DD'),
      shiftId: [],
      directorRecruitFlag: 0,
      directorEvaluationFlag: 0,
      directorEvaluation: [],
      directorGenderFlag: 0,
      directorGender: null,
      directorPrefFlag: 0,
      directorPref: [],
      directorAgeFlag: 0,
      directorAgeFrom: 0,
      directorAgeTo: 99,
      directorHeightFlag: 0,
      directorHeightFrom: 0,
      directorHeightTo: 250,
      directorPossibleDisplayFlag: 0,
      directorMatterTitle: '',
      directorMatterJob: '',
      directorMatterDetail: '',
      directorWear: [],
      directorPayAmount: '',
      directorPayFare: '',
      adRecruitFlag: 0,
      adEvaluationFlag: 0,
      adEvaluation: [],
      adGenderFlag: 0,
      adGender: null,
      adPrefFlag: 0,
      adPref: [],
      adAgeFlag: 0,
      adAgeFrom: 0,
      adAgeTo: 99,
      adHeightFlag: 0,
      adHeightFrom: 0,
      adHeightTo: 250,
      adPossibleDisplayFlag: 0,
      adMatterTitle: '',
      adMatterJob: '',
      adMatterDetail: '',
      adWear: [],
      adPayAmount: '',
      adPayFare: '',
      staffRecruitFlag: 0,
      staffEvaluationFlag: 0,
      staffEvaluation: [],
      staffGenderFlag: 0,
      staffGender: null,
      staffPrefFlag: 0,
      staffPref: [],
      staffAgeFlag: 0,
      staffAgeFrom: 0,
      staffAgeTo: 99,
      staffHeightFlag: 0,
      staffHeightFrom: 0,
      staffHeightTo: 250,
      staffPossibleDisplayFlag: 0,
      staffMatterTitle: '',
      staffMatterJob: '',
      staffMatterDetail: '',
      staffWear: [],
      staffPayAmount: '',
      staffPayFare: '',
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      matterNumber: Yup.string().nullable().required().label('案件'),
      matterDateNumber: Yup.string().nullable().required().label('案件実施日'),
      openDate: Yup.string().nullable().required().label('募集開始日'),
      closeDate: Yup.string().nullable().required().label('募集終了日'),
      shiftId: Yup.array().nullable().arrayRequired('募集対象シフト'),
      dateRange: Yup.string()
        .nullable()
        .test(
          'dateInvalid',
          '募集期間:開始日は終了日より前の日付を入力してください。',
          (_, context) => {
            const { openDate, closeDate } = context.parent;
            return (
              openDate &&
              closeDate &&
              moment(closeDate).isSameOrAfter(moment(openDate))
            );
          }
        ),
      directorRecruitFlag: Yup.number()
        .nullable()
        .numberRequired('ディレクター募集可否'),
      adRecruitFlag: Yup.number()
        .nullable()
        .numberRequired('アシスタントディレクター募集可否'),
      staffRecruitFlag: Yup.number()
        .nullable()
        .numberRequired('スタッフ募集可否'),
      ...transform(
        [
          {
            label: '[ディレクター]',
            prefix: 'director',
          },
          {
            label: '[アシスタントディレクター]',
            prefix: 'ad',
          },
          {
            label: '[スタッフ]',
            prefix: 'staff',
          },
        ],
        (result, i) => {
          tabError.forEach(j => {
            const label = `${i.label}${j.label}`;

            const type = j.type ?? 'string';

            let then = Yup[type]().nullable().required().label(label);

            type === 'array' && (then = then.arrayRequired(label));

            j.integerString && (then = then.integerString(label));

            result[`${i.prefix}${j.inputName}`] = Yup[type]().when(
              `${i.prefix}RecruitFlag`,
              {
                is: 1,
                then,
              }
            );
          });
        },
        {}
      ),
    }),
    onSubmit: values => {
      dispatch(
        updateStaffRecruitMatter({
          ...values,
          updateType: state ? 'update' : 'create',
          shiftId: values.shiftId.join(','),
          directorWear: (values.directorWear ?? []).join(','),
          adWear: (values.adWear ?? []).join(','),
          staffWear: (values.staffWear ?? []).join(','),
          directorEvaluation:
            values.directorEvaluation?.length > 0
              ? values.directorEvaluation.join(',')
              : null,
          directorPref:
            values.directorPref?.length > 0
              ? values.directorPref.join(',')
              : null,
          adEvaluation:
            values.adEvaluation?.length > 0
              ? values.adEvaluation.join(',')
              : null,
          adPref: values.adPref?.length > 0 ? values.adPref.join(',') : null,
          staffEvaluation:
            values.staffEvaluation?.length > 0
              ? values.staffEvaluation.join(',')
              : null,
          staffPref:
            values.staffPref?.length > 0 ? values.staffPref.join(',') : null,
        })
      )
        .unwrap()
        .then(() => state ? null : navigate(-1));
    },
  });

  const {
    values: { matterNumber, matterDateNumber, shiftId, openDate, closeDate },
  } = formik;

  const {
    directorQuantity,
    adQuantity,
    manQuantity,
    womanQuantity,
    unisexQuantity,
  } = matterShiftList
    .filter(s => shiftId.includes(s.shiftId))
    .reduce(
      (pre, next) => ({
        directorQuantity:
          (pre.directorQuantity ?? 0) + (next.directorQuantity ?? 0),
        adQuantity: (pre.adQuantity ?? 0) + (next.adQuantity ?? 0),
        manQuantity: (pre.manQuantity ?? 0) + (next.manQuantity ?? 0),
        womanQuantity: (pre.womanQuantity ?? 0) + (next.womanQuantity ?? 0),
        unisexQuantity: (pre.unisexQuantity ?? 0) + (next.unisexQuantity ?? 0),
      }),
      {}
    );

  const tabs = [
    {
      key: 'director',
      label: 'ディレクター',
      children: <StaffMatterTab formik={formik} type="director" />,
    },
    {
      key: 'ad',
      label: 'アシスタントディレクター',
      children: <StaffMatterTab formik={formik} type="ad" />,
    },
    {
      key: 'staff',
      label: 'スタッフ',
      children: <StaffMatterTab formik={formik} type="staff" />,
    },
  ];

  const tabErrorGroup = transform(
    tabs,
    (result, i) => {
      result[i.key] = tabError.map(t => `${i.key}${t.inputName}`);
    },
    {}
  );

  useEffect(() => {
    dispatch(
      staffRecruitMatterDetailInit({
        matterNumber: state?.matterNumber,
      })
    );
  }, [dispatch, state]);

  useEffect(() => {
    state && dispatch(fetchStaffRecruitMatterDetail(state));

    return () => dispatch(staffRecruitMatterActions.clearDetail());
  }, [state]);

  useEffect(() => {
    staffRecruitMatterDetail &&
      formik.setValues({
        ...formik.values,
        ...staffRecruitMatterDetail,
        directorPayAmount: staffRecruitMatterDetail?.directorPayAmount ?? '',
        adPayAmount: staffRecruitMatterDetail?.adPayAmount ?? '',
        staffPayAmount: staffRecruitMatterDetail?.staffPayAmount ?? '',
        oldShiftId: (staffRecruitMatterDetail?.shiftId ?? []).join(','),
        directorEvaluation: staffRecruitMatterDetail?.directorEvaluation ?? [],
        directorPref: staffRecruitMatterDetail?.directorPref ?? [],
        adEvaluation: staffRecruitMatterDetail?.adEvaluation ?? [],
        adPref: staffRecruitMatterDetail?.adPref ?? [],
        staffEvaluation: staffRecruitMatterDetail?.staffEvaluation ?? [],
        staffPref: staffRecruitMatterDetail?.staffPref ?? [],
      });
  }, [staffRecruitMatterDetail]);

  useEffect(() => {
    matterNumber &&
      dispatch(
        fetchMatterDate({
          matterNumber,
          status: 1,
        })
      );
  }, [matterNumber]);

  useEffect(() => {
    matterNumber &&
      matterDateNumber &&
      dispatch(
        fetchMatterShift({
          matterNumber,
          matterDateNumber,
        })
      );
  }, [matterNumber, matterDateNumber]);

  return (
    !loading && (
      <>
        <div
          className={'page_base--container staff_matter_register_page'}
          style={isSp ? { paddingTop: '72px' } : {}}
        >
          <div className={'register_base--update_area'}>
            {!isSp && (
              <span className="display-linebreak register_base--update_text">
                {state &&
                  staffRecruitMatterDetail?.updatedUserDatetime &&
                  `新規登録 ${
                    staffRecruitMatterDetail?.createdUserDatetime ?? ''
                  } ${
                    staffRecruitMatterDetail?.createdUserName ?? ''
                  }\t最終更新 ${
                    staffRecruitMatterDetail?.updatedUserDatetime ?? ''
                  } ${staffRecruitMatterDetail?.updatedUserName ?? ''}`}
              </span>
            )}
          </div>

          {isSp ? (
            <div
              style={{
                display: 'flex',
                position: 'absolute',
                top: 0,
                height: '60px',
              }}
            >
              <div
                className="staff_matter_register_page--title"
                style={{ position: 'relative', flexShrink: '0' }}
              >
                <span
                  style={{
                    display: 'block',
                    fontSize: '12px',
                    color: '#d80245',
                  }}
                >
                  *は必須項目です
                </span>
                <img src={Icons.icon.downNv} />
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    color: '#242424',
                    margin: '0 30px 0 10px',
                  }}
                >
                  基本情報
                </span>
              </div>

              {state && staffRecruitMatterDetail?.updatedUserDatetime && (
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                  }}
                >
                  <p
                    style={{
                      fontSize: '12px',
                      color: '#7b7b7b',
                      whiteSpace: 'nowrap',
                      margin: '0',
                    }}
                  >
                    {`新規登録 ${
                      staffRecruitMatterDetail?.createdUserDatetime ?? ''
                    } ${staffRecruitMatterDetail?.createdUserName ?? ''}`}
                  </p>
                  <p
                    style={{
                      fontSize: '12px',
                      color: '#7b7b7b',
                      whiteSpace: 'nowrap',
                      margin: '0',
                    }}
                  >{`最終更新 ${
                    staffRecruitMatterDetail?.updatedUserDatetime ?? ''
                  } ${staffRecruitMatterDetail?.updatedUserName ?? ''}`}</p>
                </div>
              )}
            </div>
          ) : (
            <div className="staff_matter_register_page--title">
              <img src={Icons.icon.downNv} />
              <span>基本情報</span>
              <span>*は必須項目です</span>
            </div>
          )}

          <div className="staff_matter_register_page--base">
            <CustomFormikInput
              formik={formik}
              label="案件"
              labelRequired
              placeholder="案件を選択してください"
              extraOnChange={() => {
                handlerFormikFieldChange(formik, 'matterDateNumber', null);
                handlerFormikFieldChange(formik, 'shiftId', []);
              }}
              className={{
                areaClass: 'column',
                inputClass: state && 'no_display',
              }}
              style={{
                areaStyle: {
                  width: 400,
                },
              }}
              inputName="matterNumber"
              inputType="select"
              initialValue={matterList.map(matter => matter.matterNumber)}
              selectBoxOptions={matterList.map(
                matter => `${matter.matterNumber}:${matter.matterName}`
              )}
              unit={state && staffRecruitMatterDetail?.matterNumber}
            />
            <CustomFormikInput
              formik={formik}
              label="案件実施日"
              labelRequired
              initialValue={matterDateList.map(
                matterDate => matterDate.matterDateNumber
              )}
              selectBoxOptions={matterDateList.map(
                matterDate => matterDate.matterDate
              )}
              inputType="select"
              inputName="matterDateNumber"
              placeholder="実施日を選択してください"
              className={{ areaClass: 'column' }}
              extraOnChange={() =>
                handlerFormikFieldChange(formik, 'shiftId', [])
              }
              style={{
                areaStyle: {
                  width: 710,
                },
              }}
              unit={
                isSp ? (
                  ''
                ) : (
                  <span
                    className="staff_matter_register_page--unit display-linebreak"
                    style={{ marginTop: '25px' }}
                  >
                    開催地：
                    {`${
                      matterList.find(
                        matter => matter.matterNumber === matterNumber
                      )?.venueName ?? '-'
                    }\t${
                      matterDateList.find(
                        matterDate =>
                          matterDate.matterDateNumber === matterDateNumber
                      )?.venueNearestStation ?? ''
                    }`}
                  </span>
                )
              }
            />

            {isSp && (
              <p
                className="staff_matter_register_page--unit display-linebreak"
                style={{ margin: '0', fontSize: '12px' }}
              >
                開催地：
                {`${
                  matterList.find(
                    matter => matter.matterNumber === matterNumber
                  )?.venueName ?? '-'
                }\t${
                  matterDateList.find(
                    matterDate =>
                      matterDate.matterDateNumber === matterDateNumber
                  )?.venueNearestStation ?? ''
                }`}
              </p>
            )}
          </div>
          <div className="staff_matter_register_page--base">
            <div className="input_union">
              <CustomFormikInput
                formik={formik}
                label="募集日"
                labelRequired
                customDisabledDate={current =>
                  current < moment().add(-1, 'days') ||
                  (closeDate && current > moment(closeDate))
                }
                otherErrorInputName={['dateRange']}
                placeholder="YYYY/MM/DD"
                className={{
                  areaClass: 'matter_list--date_column',
                }}
                style={{
                  inputStyle: { width: 165 },
                }}
                inputType="inputDate"
                inputName="openDate"
                unit="～"
              />
              <CustomFormikInput
                label=" "
                formik={formik}
                customDisabledDate={current =>
                  current < moment().add(-1, 'days') ||
                  current < moment(openDate)
                }
                placeholder="YYYY/MM/DD"
                otherErrorInputName={['dateRange']}
                className={{
                  areaClass: 'matter_list--date_column',
                }}
                style={{
                  areaStyle: {
                    marginLeft: -14,
                  },
                  inputStyle: { width: 165 },
                }}
                inputType="inputDate"
                inputName="closeDate"
              />
            </div>
            <CustomFormikInput
              formik={formik}
              label="募集対象シフト"
              labelRequired
              placeholder="募集対象シフトを選択してください"
              className={{ areaClass: 'column' }}
              style={{
                areaStyle: {
                  width: 810,
                },
              }}
              initialValue={matterShiftList.map(shift => shift.shiftId)}
              selectBoxOptions={matterShiftList.map(
                shift =>
                  `${shift.shiftId}${
                    shift.shiftName ? `:${shift.shiftName}` : ''
                  }`
              )}
              disabledOptions={matterShiftList
                .filter(
                  s =>
                    s.recruitFlag === 1 &&
                    !(staffRecruitMatterDetail?.shiftId ?? []).includes(
                      s.shiftId
                    )
                )
                .map(shift => shift.shiftId)}
              inputType="select"
              inputName="shiftId"
              mode="multiple"
              allowClear={false}
              unit={
                isSp ? (
                  ''
                ) : (
                  <div
                    className="staff_matter_register_page--unit display-linebreak"
                    style={{ whiteSpace: 'normal' }}
                  >
                    <span>{`ディレクター:${
                      directorQuantity ?? '-'
                    }人\tアシスタントディレクター:${
                      adQuantity ?? '-'
                    }人`}</span>
                    <span>{`スタッフ(男性):${
                      manQuantity ?? '-'
                    }人\tスタッフ(女性):${
                      womanQuantity ?? '-'
                    }人 \tスタッフ(性別不問):${unisexQuantity ?? '-'}人`}</span>
                  </div>
                )
              }
            />

            {isSp && (
              <div
                className="staff_matter_register_page--unit display-linebreak"
                style={{ margin: '0', fontSize: '12px' }}
              >
                <span>{`ディレクター:${directorQuantity ?? '-'}人`}</span>
                <span>{`アシスタントディレクター:${adQuantity ?? '-'}人`}</span>
                <span>{`スタッフ(男性):${manQuantity ?? '-'}人`}</span>
                <span>{`スタッフ(女性):${womanQuantity ?? '-'}人`}</span>
                <span>{`スタッフ(性別不問):${unisexQuantity ?? '-'}人`}</span>
              </div>
            )}
          </div>
          <div
            className="page_base--tabs"
            style={isSp ? { marginTop: '0' } : {}}
          >
            <Tabs
              activeKey={activeTab ? activeTab : tabs[0].key}
              onChange={tab => dispatch(globalActions.changeActiveTab(tab))}
            >
              {tabs.map(tab => (
                <Tabs.TabPane tab={tab.label} key={tab.key}>
                  {tab.children}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </div>
        <div
          className={'bottom_btn_area'}
          style={isSp ? { height: '88px' } : {}}
        >
          <div className={'bottom_btn_area--common_actions'}>
            <Button
              text={'戻る'}
              style={Options.buttonStyles.back}
              onClick={() => navigate(-1)}
            />
            <Button
              text={state ? '更新' : '登録'}
              style={Options.buttonStyles.submit}
              onClick={() => handlerFormikSubmit(formik, tabErrorGroup)}
            />
          </div>
        </div>
      </>
    )
  );
});

export default StaffMatterRegister;
