import React, { useState } from 'react';
import ModalBase from '../modalBase';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { csvZenginDownloadFetch } from '../../../redux/slice/other';
import moment from 'moment';
import './style.scss';
import { values } from 'lodash';

const AllBankOutputModal = ({
  modalVisible,
  changeModalVisible,
  getCheckedStaffId,
  searchParams,
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { allBankTransferDate: moment().format('YYYY/MM/DD') },
    enableReinitialize: true,
    onSubmit: values => {
      let newObj;
      if (searchParams) {
        newObj = { ...searchParams };
        Object.keys(newObj).forEach(key => {
          if (!newObj?.[key] || newObj?.[key]?.length === 0) {
            delete newObj?.[key];
          }
        });
      }

      dispatch(
        csvZenginDownloadFetch({
          ...newObj,
          payDay: allBankTransferDate,
          staffId: getCheckedStaffId(),
        })
      ).then(data => {
        const blob = new Blob([data.payload], {
          type: 'text/csv',
        });

        if (data.error && data.error.message === 'Rejected') {
          return;
        }

        const ahref = document.createElement('a');
        ahref.download =
          'staff_payment_' + moment().format('YYYYMMDDHHmmss') + '.txt';
        ahref.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        ahref.dispatchEvent(clickEvt);
        ahref.remove();
      });
    },
  });

  const { allBankTransferDate } = formik.values;

  return (
    <ModalBase
      dialogStyle={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 40px',
        width: '600px',
        height: '363px',
      }}
      modalVisible={modalVisible}
    >
      <div className="all_bank_modal--main">
        <div className={'import_modal--title_area'}>
          <p>全銀データ出力</p>
        </div>
        <div className="all_bank_modal--sel_date">
          <CustomFormikInput
            formik={formik}
            inputType="inputDate" //inputのtype
            label="振込指定日" //ラベルの名前
            inputName="allBankTransferDate" //DBのカラム名
            placeholder="YYYY/MM/DD"
            style={{ labelStyle: { position: 'absolute', left: '-94px' } }}
          />
        </div>
        <div className="all_bank_modal--btndiv">
          <Button
            text={'戻る'}
            style={Options.buttonStyles.stampBack}
            onClick={changeModalVisible}
          />
          <Button
            text={'出力'}
            style={Options.buttonStyles.stampApproval}
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default AllBankOutputModal;
