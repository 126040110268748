import moment from 'moment';

let body = {
  staffImage: 'rrr',
  name: {
    firstName: '',
    lastName: '',
  },
  nameKana: {
    firstName: '',
    lastName: '',
  },
  postCode: {
    number1: '',
    number2: '',
  },
  daytimeTel: {},
  homeTel: {},
  holdingItems: ['0001', '0002', '0003', '0004', '0005', '0006'],
  certifications: ['0001', '0002', '0003'],
  gender: 1,
  employmentType: 0,
  taxClassification: 0,
  profession: 1,
  bankAccountFlag: 1,
};

const bodyCheck = (body) => {

  Object.keys(body).map(key => {
    if (typeof body[key] === 'object' && body[key].length === 0) {
      delete body[key];
    } else if (Array.isArray(body[key]) && body[key].length !== 0) {
        body[key].map((obj, index) => {
          delete body[key];
        });
    } else if (typeof body[key] === 'object' && Object.keys(body[key]).length === 0) {
      delete body[key];
    }
  });
}

const getInputValues = () => {

  let inputValues = document.getElementsByTagName('input');
  let textValues = document.getElementById('');

  if (textValues) {
    if (textValues.textContent) {
      body.note = textValues.textContent;
    } else {
      delete body.note;
    }
  }

  Array.from(inputValues).map((input) => {
    if (input.id === 'staff_id' && input.value) {
      if (input.value) {
        body.staffId = Number(input.value);
      } else {
        delete body.staffId;
      }
    } else if (input.id === 'last_name') {
      if (!body.name) {
        body.name = {}
      }
      if (input.value) {
        body.name.lastName = input.value;
      } else {
        delete body.name.lastName;
      }
    } else if (input.id === 'first_name') {
      if (!body.name) {
        body.name = {}
      }
      if (input.value) {
        body.name.firstName = input.value;
      } else {
        delete body.name.firstName;
      }
    } else if (input.id === 'last_name_kana') {
      if (!body.nameKana) {
        body.name = {}
      }
      if (input.value) {
        body.nameKana.lastName = input.value;
      } else {
        delete body.nameKana.lastName;
      }
    } else if (input.id === 'first_name_kana') {
      if (!body.nameKana) {
        body.name = {}
      }
      if (input.value) {
        body.nameKana.firstName = input.value;
      } else {
        delete body.nameKana.firstName;
      }
    } else if (input.id === 'mail_address') {
      if (input.value) {
        body.mailAddress = input.value;
      } else {
        delete body.mailAddress;
      }
    } else if (input.id === 'start_date') {
      if (input.value) {
        body.startDate = moment(input.value).format('YYYY/MM/DD');
      } else {
        delete body.startDate;
      }
    } else if (input.id === 'end_date') {
      if (input.value) {
        body.endDate = moment(input.value).format('YYYY/MM/DD');
      } else {
        delete body.endDate;
      }
    } else if (input.id === 'zip_code_1') {
      if (!body.postCode) {
        body.postCode = {}
      }
      if (input.value) {
        body.postCode.number1 = input.value;
      } else {
        delete body.postCode.number1;
      }
    } else if (input.id === 'zip_code_2') {
      if (!body.postCode) {
        body.postCode = {}
      }
      if (input.value) {
        body.postCode.number2 = input.value;
      } else {
        delete body.postCode.number2;
      }
    } else if (input.id === 'city_name') {
      if (input.value) {
        body.cityName = input.value;
      } else {
        delete body.cityName;
      }
    } else if (input.id === 'street_name') {
      if (input.value) {
        body.streetName = input.value;
      } else {
        delete body.streetName;
      }
    } else if (input.id === 'building_name') {
      if (input.value) {
        body.buildingName = input.value;
      } else {
        delete body.buildingName;
      }
    } else if (input.id === 'phone_area_number') {
      if (!body.daytimeTel) {
        body.daytimeTel = {}
      }
      if (input.value) {
        body.daytimeTel.number1 = input.value;
      } else {
        delete body.daytimeTel.number1;
      }
    } else if (input.id === 'phone_city_number') {
      if (!body.daytimeTel) {
        body.daytimeTel = {}
      }
      if (input.value) {
        body.daytimeTel.number2 = input.value;
      } else {
        delete body.daytimeTel.number2;
      }
    } else if (input.id === 'phone_subscriber_number') {
      if (!body.daytimeTel) {
        body.daytimeTel = {}
      }
      if (input.value) {
        body.daytimeTel.number3 = input.value;
      } else {
        delete body.daytimeTel.number3;
      }
    } else if (input.id === 'tel_area_number') {
      if (!body.homeTel) {
        body.homeTel = {}
      }
      if (input.value) {
        body.homeTel.number1 = input.value;
      } else {
        delete body.homeTel.number1;
      }
    } else if (input.id === 'tel_city_number') {
      if (!body.homeTel) {
        body.homeTel = {}
      }
      if (input.value) {
        body.homeTel.number2 = input.value;
      } else {
        delete body.homeTel.number2;
      }
    } else if (input.id === 'tel_subscriber_number') {
      if (!body.homeTel) {
        body.homeTel = {}
      }
      if (input.value) {
        body.homeTel.number3 = input.value;
      } else {
        delete body.homeTel.number3;
      }
    } else if (input.id === 'line_id') {
      if (input.value) {
        body.lineId = input.value;
      } else {
        delete body.lineId;
      }
    } else if (input.id === 'hope_assign') {
      if (input.value) {
        body.hopeAssign = Number(input.value);
      } else {
        delete body.hopeAssign;
      }
    } else if (input.id === 'join_date') {
      if (input.value) {
        body.joinDate = moment(input.value).format('YYYY/MM/DD');
      } else {
        delete body.joinDate;
      }
    } else if (input.id === 'retire_date') {
      if (input.value) {
        body.retireDate = moment(input.value).format('YYYY/MM/DD');
      } else {
        delete body.retireDate;
      }
    } else if (input.id === 'dependents') {
      if (input.value) {
        body.dependents = Number(input.value);
      } else {
        delete body.dependents;
      }
    } else if (input.id === 'daily_pay') {
      if (input.value) {
        body.dailyPay = Number(input.value);
      } else {
        delete body.dailyPay;
      }
    } else if (input.id === 'hourly_pay') {
      if (input.value) {
        body.hourlyPay = Number(input.value);
      } else {
        delete body.hourlyPay;
      }
    } else if (input.id === 'recruit_cost') {
      if (input.value) {
        body.recruitCost = Number(input.value);
      } else {
        delete body.recruitCost;
      }
    } else if (input.id === 'weight') {
      if (input.value) {
        body.weight = Number(input.value);
      } else {
        delete body.weight;
      }
    } else if (input.id === 'shoe_size') {
      if (input.value) {
        body.shoeSize = Number(input.value);
      } else {
        delete body.shoeSize;
      }
    } else if (input.id === 'school_name') {
      if (input.value) {
        body.schoolName = input.value;
      } else {
        delete body.schoolName;
      }
    } else if (input.id === 'admission_year') {
      if (input.value) {
        body.admissionYear = Number(input.value);
      } else {
        delete body.admissionYear;
      }
    } else if (input.id === 'bank_name') {
      if (input.value) {
        body.bankName = input.value;
      } else {
        delete body.bankName;
      }
    } else if (input.id === 'bank_code') {
      if (input.value) {
        body.bankCode = input.value;
      } else {
        delete body.bankCode;
      }
    } else if (input.id === 'bank_branch_name') {
      if (input.value) {
        body.bankBranchName = input.value;
      } else {
        delete body.bankBranchName;
      }
    } else if (input.id === 'bank_branch_code') {
      if (input.value) {
        body.bankBranchCode = input.value;
      } else {
        delete body.bankBranchCode;
      }
    } else if (input.id === 'bank_account_number') {
      if (input.value) {
        body.bankAccountNumber = input.value;
      } else {
        delete body.bankAccountNumber;
      }
    } else if (input.id === 'bank_account_name') {
      if (input.value) {
        body.bankAccountName = input.value;
      } else {
        delete body.bankAccountName;
      }
    }
  });

  let certificationElms = document.getElementsByClassName('attribute_certification');
  let itemsElms = document.getElementsByClassName('attribute_items');

  let aryCertificationElms = [];
  Array.from(certificationElms).map((elm) => {
    aryCertificationElms.push(elm.getAttribute('data-value'));
  });
  let setCertificationElms = new Set(aryCertificationElms);
  body.certifications = [...setCertificationElms];

  let aryItemsElms = [];
  Array.from(itemsElms).map((elm) => {
    aryItemsElms.push(elm.getAttribute('data-value'));
  });
  let setAryItemsElms = new Set(aryItemsElms);
  body.holdingItems = [...setAryItemsElms];

  bodyCheck(body);

  return body;
};

export default getInputValues;

export const handleGender = e => {
  body.gender = Number(e.target.value);
}

export const handleRadio = e => {
  let targetElm = e.target.name;
  let targetValue = Number(e.target.value);

  if (targetElm === 'employment_type') {
    body.employmentType = targetValue;
  } else if (targetElm === 'classification') {
    body.taxClassification = targetValue;
  }
}