import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { globalActions } from './global';
import { apiUrl } from '../../api/apiUrl';
import moment from 'moment';
import Options from '../../constants/options';

const initialSearchParams = {
  matterStartDate: moment().format('YYYY/MM/DD'),
  matterEndDate: moment().format('YYYY/MM/DD'),
  matterCategory: null,
  preCode: [],
  cityName: '',
  displaySort: 'open_date DESC',
  offset: 0,
  count: 20,
};

const initialState = {
  searchParams: initialSearchParams,
  matterDetail: null,
  assignDetail: null,
  assignStaffDetail: null,
  reportDetail: null,
  purchaseMotiveList: [],
  toggle: false,
  holdingItemList: [],
  matterCategoryList: [],
  matterList: [],
  searchCount: 0,
};

export const staffMatterListInit = createAsyncThunk(
  'staff/matter/list/init',
  async () => {
    const [namingRes] = await fetchMultiRequest([
      fetchRequest(apiUrl.naming.list, {
        namingClassificationId: ['PromotionCategory', 'Holdingitem'],
        status: 1,
      }),
    ]);

    const namingItem = namingRes?.item ?? [];

    return {
      matterCategoryList: namingItem.filter(
        naming => naming.namingClassificationId === 'PromotionCategory'
      ),
      holdingItemList: namingItem.filter(
        naming => naming.namingClassificationId === 'Holdingitem'
      ),
    };
  }
);

export const fetchRecruitMatterList = createAsyncThunk(
  'staff/matter/list',
  async (params, { getState }) => {
    return await fetchRequest(apiUrl.staffMatter.list, {
      ...params,
      staffId: getState().account.staffId,
    });
  }
);

export const matterApply = createAsyncThunk(
  'staff/matter/apply',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matterDate.apply, params, 'POST');

    dispatch(staffMatterActions.listRefresh());
  }
);

export const staffMatterDetailInit = createAsyncThunk(
  'staff/matter/detail',
  async params => {
    const [
      matterDetailRes,
      assginDetailRes,
      assignStaffDetailRes,
      motiveRes,
      corporateRes,
    ] = await fetchMultiRequest([
      fetchRequest(apiUrl.matter.detail, params),
      fetchRequest(apiUrl.matterDate.assignDetail, params),
      fetchRequest(apiUrl.matterDate.assignStaffDetail, params),
      fetchRequest(apiUrl.naming.list, {
        namingClassificationId: 'PurchaseMotive',
        status: 1,
      }),
      fetchRequest(apiUrl.corporate.detail, {
        corporateId: params.corporateId,
        deletedFlag: 0,
      }),
    ]);

    const assignDetail = assginDetailRes?.item ?? {};

    const venueRes = await fetchRequest(apiUrl.venue.detail, {
      venueId: matterDetailRes.item.venueId,
      deletedFlag: 0,
    });

    const venueDetail = {
      post:
        venueRes?.detail?.postCode?.number1 &&
        venueRes?.detail?.postCode?.number2
          ? `〒${venueRes?.detail?.postCode?.number1}-${venueRes?.detail?.postCode?.number2}`
          : '',
      address: `${
        venueRes?.detail?.prefCode
          ? Options.codes.pref_code[venueRes?.detail?.prefCode]
          : ''
      }${venueRes?.detail?.cityName ?? ''}${
        venueRes?.detail?.streetName ?? ''
      }${venueRes?.detail?.buildingName ?? ''}`,
      tel:
        venueRes?.detail?.tel?.number1 &&
        venueRes?.detail?.tel?.number2 &&
        venueRes?.detail?.tel?.number3
          ? `TEL：${venueRes?.detail?.tel?.number1}-${venueRes?.detail?.tel?.number2}-${venueRes?.detail?.tel?.number3}`
          : '',
      fax:
        venueRes?.detail?.fax?.number1 &&
        venueRes?.detail?.fax?.number2 &&
        venueRes?.detail?.fax?.number3
          ? `TEL：${venueRes?.detail?.fax?.number1}-${venueRes?.detail?.fax?.number2}-${venueRes?.detail?.fax?.number3}`
          : '',
    };

    return {
      matterDetail: { ...matterDetailRes?.item, venueDetail: venueDetail },
      assignDetail: {
        ...assignDetail,
        calculationPayFlag: corporateRes?.detail?.calculationPayFlag ?? 2,
        shift: (assignDetail.shift ?? []).find(
          s => s.shiftId === params.shiftId
        ),
      },
      assignStaffDetail: assignStaffDetailRes?.item,
      purchaseMotiveList: motiveRes?.item ?? [],
    };
  }
);

export const fetchMatterDetail = createAsyncThunk(
  'staff/matter/detail',
  async params => await fetchRequest(apiUrl.matter.detail, params)
);

export const updateAssignStaffDetail = createAsyncThunk(
  'staff/assign/detail/update',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matterDate.assignStaffUpdate, params, 'POST');

    dispatch(globalActions.showSingleModal('更新しました'));

    dispatch(staffMatterActions.refresh());
  }
);

export const fetchStaffMatterReportDetail = createAsyncThunk(
  'staff/matter/report/detail',
  async params => {
    const reportDetailRes = await fetchRequest(
      apiUrl.matter.reportDetail,
      params
    );
    const ir = reportDetailRes?.item?.guestVoice?.inflowRoute;
    const iro = ir
      ? ir.split(',').reduce((result, item) => {
        const [key, value] = item.split(':');
        result[key] = value === 'null' ? null : parseInt(value, 10);
        return result;
      }, {})
      : {};

    // //購入動機
    const m = reportDetailRes?.item?.guestVoice?.motive;
    const mo = m
      ? m.split(',').reduce((obj, str) => {
        const [key, value] = str.split(':');
        obj[`staffMotiveId_${key}`] =
          value === 'null' ? null : parseInt(value, 10);
        return obj;
      }, {})
      : {};

    //継続購入可否
    const r = reportDetailRes?.item?.guestVoice?.repeating;
    const ro = r
      ? r.split(',').reduce((result, item) => {
        const [key, value] = item.split(':');
        result[key] = value === 'null' ? null : parseInt(value, 10);
        return result;
      }, {})
      : {};
    return {
      ...reportDetailRes?.item,
      guestVoice: {
        ...reportDetailRes?.item?.guestVoice,
        dmRate: iro?.[0] ?? null,
        passingRate: iro?.[1] ?? null,
        snsRate: iro?.[2] ?? null,
        dailyUseRate: iro?.[3] ?? null,
        otherRate: iro?.[4] ?? null,
        firstRate: ro?.[0] ?? null,
        twiceRate: ro?.[1] ?? null,
        ...mo,
      }
    }
  }
);

export const updateStaffMatterReport = createAsyncThunk(
  'staff/matter/update/report',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.matter.reportUpdate, params, 'POST');

    dispatch(globalActions.showSingleModal('更新しました'));
  }
);

export const staffMatterSlice = createSlice({
  name: 'staffMatter',
  initialState,
  reducers: {
    refresh: state => {
      state.toggle = !state.toggle;
    },
    listRefresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    reset: () => initialState,
  },
  extraReducers: {
    [staffMatterListInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchRecruitMatterList.fulfilled]: (state, { payload }) => {
      state.matterList = payload?.item;
      state.searchCount = payload.total ?? 0;
    },
    [staffMatterDetailInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchStaffMatterReportDetail.fulfilled]: (state, { payload }) => {
      const { guestVoice, ...rest } = payload ?? {};
      state.reportDetail = {
        ...rest,
        guestVoice: {
          ...guestVoice,
          motive: guestVoice.motive?.split(',') ?? null,
        },
      };
    },
  },
});

export const staffMatterActions = staffMatterSlice.actions;
export default staffMatterSlice.reducer;
