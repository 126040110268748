const payTermInfo = {
  payCategory: [
    {
      label: '締日基準',
      value: 0,
    },
    {
      label: '日数基準',
      value: 1,
    },
  ],
  closingDate: [...Array(29)].map((_, i) => {
    return {
      label: i === 28 ? '月末' : i + 1 + '日',
      value: i === 28 ? 99 : i + 1,
    };
  }),
  payPeriodMonth: [...Array(13)].map((_, i) => {
    if (i === 0) {
      return {
        label: '当月',
        value: i + 1,
      };
    } else if (i === 1) {
      return {
        label: '翌月',
        value: i + 1,
      };
    } else if (i === 2) {
      return {
        label: '翌々月',
        value: i + 1,
      };
    } else {
      return {
        label: i + 'ヶ月後',
        value: i + 1,
      };
    }
  }),
  payPeriodDay: [...Array(29)].map((_, i) => {
    return {
      label: i === 28 ? '月末' : i + 1 + '日',
      value: i === 28 ? 99 : i + 1,
    };
  }),
  payCategoryPayDay: [...Array(100)].map((_, i) => {
    return {
      label: i + 1 + '日',
      value: i + 1,
    };
  }),
};
export default payTermInfo;
