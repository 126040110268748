import React, { useEffect, useState } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';

const ForgetPassComplete = () => {

  const navigate = useNavigate();

  return (
    <div className={'forget_pass_complete--background'}>
      test
    </div>
  )
}

export default ForgetPassComplete;