import React from 'react';
import { DualAxes } from '@ant-design/plots';
import { memo } from 'react';

const ArrangeGraph = memo(({ graphDataY, graphDataX, isSp, maxCount }) => {
  const labelName = ['自社手配率', '協力会社', '社員', '自社スタッフ'];
  const labelColor = ['#EC5E3A', '#DCDCDC', '#68B8FF', '#DBEDFF'];

  const config = {
    data: [graphDataY, graphDataX],
    xField: 'yearMonth',
    yField: ['value', 'count'],
    legend: false,
    autoFit: !isSp,
    width: 1000,
    tooltip: {
      //フォーカスした時に表示される詳細データの設定
      customItems: item => {
        let newAry = [];
        item.forEach(obj => {
          if (obj.name === 'count') {
            newAry[0] = {
              ...obj,
              name: '自社手配率',
              value: `${obj.value}%`,
              color: labelColor[0],
            };
          } else if (obj.name === '協力会社') {
            newAry[1] = {
              ...obj,
              value: `${Number(obj.value).toLocaleString()}人`,
              color: labelColor[1],
            };
          } else if (obj.name === '社員') {
            newAry[2] = {
              ...obj,
              value: `${Number(obj.value).toLocaleString()}人`,
              color: labelColor[2],
            };
          } else if (obj.name === '自社スタッフ') {
            newAry[3] = {
              ...obj,
              value: `${Number(obj.value).toLocaleString()}人`,
              color: labelColor[3],
            };
          }
        });
        return newAry;
      },
      domStyles: {
        'g2-tooltip': { width: '200px' },
        'g2-tooltip-list-item': { width: '100%', fontSize: '11px' },
      },
      position: 'top',
    },
    xAxis: {
      //横軸のラベル部分の設定
      label: {
        style: {
          fill: '#242424',
          fontSize: 14,
        },
      },
    },
    yAxis: {
      //縦軸のラベル部分の設定
      value: {
        tickCount: 10,
        min: 0,
        max: maxCount === 0 ? 20 : maxCount,
        count: {},
        label: {
          formatter: (text, item, i) => {
            if ((i & 1) === 0) {
              return Number(text).toLocaleString();
            } else {
              return '';
            }
          },
          style: {
            fill: '#242424',
            fontSize: isSp ? 12 : 14,
          },
        },
      },
      count: {
        min: 0,
        max: 103, //折れ線グラフの上限は%だから上限は上限は100???
        tickCount: 10,
        label: false,
      },
    },
    geometryOptions: [
      {
        geometry: 'column',
        isStack: true,
        seriesField: 'type',
        color: ['#DCDCDC', '#68B8FF', '#DBEDFF'], //グラフのカラーを指定
        label: {
          position: 'middle',
          formatter: text =>
            text.value !== 0 ? Number(text.value).toLocaleString() : '', //データ0を削除
          style: {
            fill: '#5A5F6C',
            fontSize: isSp ? 12 : 14,
          },
        },
      },
      {
        geometry: 'line',
        smooth: false,
        label: {
          formatter: text => {
            return `${text.count}%`;
          },
          style: {
            fill: '#6A6F7C',
            fontSize: isSp ? 12 : 14,
          },
        },
        point: {
          size: 5,
          shape: 'circle',
          style: {
            fill: '#EC5E3A',
            stroke: '#EC5E3A',
            lineWidth: 2,
          },
        },
        lineStyle: {
          lineWidth: 3,
          stroke: '#EC5E3A',
        },
      },
    ],
  };

  return isSp ? (
    <div className={'analyze_trend--graph_area'}>
      {/*右側のラベル部分*/}
      <div
        className={'analyze_trend--graph_label_area'}
        style={{ width: '100%' }}
      >
        {labelName.map((name, idx) => {
          return idx === 0 ? (
            <div
              className={'analyze_trend--label'}
              key={`analyze_trend${idx}`}
              style={{ width: '100%' }}
            >
              <div
                className={'analyze_trend--mark_unity'}
                style={{ width: '50px' }}
              >
                <div className={'analyze_trend--mark_circle'} />
                <div className={'analyze_trend--mark_line'} />
              </div>
              <span>{name}</span>
            </div>
          ) : (
            <div
              className={'analyze_trend--label'}
              key={`analyze_trend${idx}`}
              style={{ width: '40%' }}
            >
              <div
                style={{ backgroundColor: labelColor[idx], width: '50px' }}
              />
              <span>{name}</span>
            </div>
          );
        })}
      </div>
      <div className={'analyze_trend--graph_unit'}>
        <span>人数(人)</span>
      </div>

      <div className={'analyze_trend--graph_display'}>
        {/*グラフ部分*/}
        <div className={'analyze_trend--graph'}>
          <DualAxes {...config} />
        </div>
      </div>
    </div>
  ) : (
    <div className={'analyze_trend--graph_area'}>
      <div className={'analyze_trend--graph_unit'}>
        <span>人数(人)</span>
      </div>

      <div className={'analyze_trend--graph_display'}>
        {/*グラフ部分*/}
        <div className={'analyze_trend--graph'}>
          <DualAxes {...config} />
        </div>

        {/*右側のラベル部分*/}
        <div
          className={'analyze_trend--graph_label_area'}
          style={{ width: '144px' }}
        >
          {labelName.map((name, idx) => {
            return idx === 0 ? (
              <div
                className={'analyze_trend--label'}
                key={`analyze_trend${idx}`}
                style={{ width: '144px' }}
              >
                <div className={'analyze_trend--mark_unity'}>
                  <div className={'analyze_trend--mark_circle'} />
                  <div className={'analyze_trend--mark_line'} />
                </div>
                <span>{name}</span>
              </div>
            ) : (
              <div
                className={'analyze_trend--label'}
                key={`analyze_trend${idx}`}
                style={{ width: '144px' }}
              >
                <div style={{ backgroundColor: labelColor[idx] }} />
                <span>{name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default ArrangeGraph;
