import './style.scss';
import Icons from '../../../constants/icons';
import Button from '../../button';
import Options from '../../../constants/options';
import ModalBase from '../modalBase';
import React, { useEffect, useState } from 'react';
import CustomInput from '../../customInput';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import {
  csvDownloadFetch,
  favoriteItemFetch,
  updateFavoriteItemFetch,
} from '../../../redux/slice/other';
import { showApiError } from '../../../api/fetch';
import moment from 'moment';

const CsvExportModal = ({
  exportType,
  csvExportVisible,
  setCsvExportVisible,
  exportFunction,
  searchParams,
}) => {
  const closeModal = () => {
    notification.destroy();
    setCsvExportVisible(false);
  };

  const dispatch = useDispatch();

  const [isSaveFavorite, setIsSaveFavorite] = useState(false);

  const toggleExportCheckAll = e => {
    const checkBoxes = Options.csvExport.type[exportType].flatMap(
      (obj, index) => {
        return obj.labels
          .filter(l => !l.placeholder)
          .map(o => document.querySelector(`input[name="${o.label}"]`));
      }
    );

    checkBoxes.forEach(elm => {
      elm.checked = e.target.checked;
    });
  };

  const toggleSave = () => {
    setIsSaveFavorite(!isSaveFavorite);

    const checkedColumns = Options.csvExport.type[exportType].flatMap(
      (obj, index) => {
        return obj.labels
          .filter(l => !l.placeholder)
          .map(o => {
            if (document.querySelector(`input[name="${o.label}"]`).checked) {
              return o.columns;
            }
          });
      }
    );

    const returnColumns = checkedColumns.filter(v => v);

    let newObj = {};
    if (returnColumns) {
      returnColumns.map(item => {
        return (newObj[item[0]] = 1);
      });
    }

    dispatch(
      updateFavoriteItemFetch({
        screenType: Options.csvExport.type[exportType][0].screenType,
        favorite: newObj,
      })
    );
  };

  const handleDownload = () => {
    const checkedColumns = Options.csvExport.type[exportType].flatMap(
      (obj, index) => {
        return obj.labels
          .filter(l => !l.placeholder)
          .map(o => {
            if (document.querySelector(`input[name="${o.label}"]`).checked) {
              return o.columns;
            }
          });
      }
    );

    const returnColumns = checkedColumns.filter(v => v);

    if (returnColumns.length === 0) {
      const data = {
        displayMessage: '出力する項目を選択してください。',
      };
      showApiError(data);
      return;
    }

    notification.destroy();

    let newObj;
    if (searchParams) {
      newObj = { ...searchParams };
      Object.keys(newObj).forEach(key => {
        if (!newObj?.[key] || newObj?.[key]?.length === 0) {
          delete newObj?.[key];
        }
      });
    }

    let info = {
      url: Options.csvExport.type[exportType][0].url,
      params: { ...newObj, columns: returnColumns },
    };

    dispatch(csvDownloadFetch(info)).then(data => {
      const blob = new Blob([data.payload], {
        type: 'text/csv',
      });

      //　ファイルの中身を確認して正常であれば出力します
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const fileContent = event.target.result;
        if (isJsonString(fileContent)) {
          const obj = JSON.parse(fileContent);
          if (obj.displayMessage) {
            showApiError(obj);
            return;
          }
        }

        if (data.error && data.error.message === 'Rejected') {
          return;
        }

        const ahref = document.createElement('a');
        ahref.download =
          Options.csvExport.type[exportType][0].fileName +
          '_' +
          moment().format('YYYYMMDDHHmmss') +
          '.csv';
        ahref.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        ahref.dispatchEvent(clickEvt);
        ahref.remove();
      };
      fileReader.readAsText(blob);
    });
  };

  const initChecks = favorite => {
    document.querySelector('input[name="exportCheckAll"]').checked = true;

    const checkBoxes = Options.csvExport.type[exportType].flatMap(
      (obj, index) => {
        return obj.labels
          .filter(l => !l.placeholder)
          .map(o => document.querySelector(`input[name="${o.label}"]`));
      }
    );

    checkBoxes.forEach(elm => {
      const csvObj = Options.csvExport.type[exportType].flatMap(
        (obj, index) => {
          return obj.labels.filter(o => o?.label === elm.name);
        }
      );
      elm.checked = favorite[csvObj[0].columns[0]] === 1;
    });
    checkCheckBoxes();
  };

  const checkCheckBoxes = () => {
    const checkBoxes = Options.csvExport.type[exportType].flatMap(
      (obj, index) => {
        return obj.labels
          .filter(l => !l.placeholder)
          .map(o => document.querySelector(`input[name="${o.label}"]`));
      }
    );

    let isCheckedAll = true;

    checkBoxes.forEach(elm => {
      if (!elm.checked) {
        isCheckedAll = false;
      }
    });

    document.querySelector('input[name="exportCheckAll"]').checked =
      isCheckedAll;
  };

  // 文字列がJson形式かチェックします
  const isJsonString = str => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   !isSaveFavorite && initChecks();
  // });

  useEffect(() => {
    //お気に入り情報を取得する
    dispatch(
      favoriteItemFetch({
        screenType: Options.csvExport.type[exportType][0].screenType,
      })
    ).then(data => {
      const favorite = data?.payload?.item?.favorite;
      !isSaveFavorite && initChecks(favorite);
    });
  }, []);

  return (
    <ModalBase
      dialogStyle={{
        width: '600px',
      }}
      modalVisible={csvExportVisible}
    >
      <div className={'export_modal--title_area'}>
        <p>CSV出力</p>
        <Button
          text={'お気に入り項目設定'}
          style={{ ...Options.buttonStyles.csv, width: '240px' }}
          icon={Icons.icon.bookmarkNv}
          onClick={toggleSave}
        />
      </div>
      <p style={{ margin: '8px 0 40px 0', fontSize: '16px' }}>
        出力する項目を選択してください。
      </p>

      <CustomInput
        inputName={'exportCheckAll'}
        inputType={'checkBox'}
        label={'全選択'}
        onChange={toggleExportCheckAll}
      />

      <div className={'export_modal--check_box_area'}>
        {Options.csvExport.type[exportType].map((obj, index) => {
          return (
            <React.Fragment key={index}>
              {obj.category && (
                <div
                  className={classNames(
                    'export_modal--check_box_area_category',
                    {
                      ['export_modal--check_box_area_category_disabled']:
                        obj.disabled,
                    }
                  )}
                >
                  {obj.category}
                </div>
              )}
              {obj.labels.map((l, j) => (
                <CustomInput
                  key={j}
                  inputName={l.label}
                  inputType={'checkBox'}
                  label={l.label}
                  className={{
                    areaClass: classNames('export_modal--check_box_content', {
                      'export_modal--check_box_content_placeholder':
                        l.placeholder,
                    }),
                  }}
                  style={
                    l.newLine
                      ? { areaStyle: { width: '50%' } }
                      : { areaStyle: {} }
                  }
                  onChange={checkCheckBoxes}
                />
              ))}
            </React.Fragment>
          );
        })}
      </div>

      <div className={'export_modal--button_area'}>
        <Button
          text={'戻る'}
          style={Options.buttonStyles.back}
          onClick={closeModal}
        />
        <Button
          text={'ダウンロード'}
          style={Options.buttonStyles.submit}
          onClick={handleDownload}
        />
      </div>
    </ModalBase>
  );
};

export default CsvExportModal;
