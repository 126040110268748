import { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
  fetchAssignStaffDetail,
  updateAssignStaffDetail,
} from '../../../redux/slice/matterDate';
import { Tabs } from 'antd';
import { globalActions } from '../../../redux/slice/global';
import {
  convertToNumber,
  handlerFormikFieldChange,
  handlerFormikSubmit,
  numberConvert,
  diffMinute,
  checkIsSp,
} from '../../../utils/fnUtil';
import { sumBy, toInteger, transform } from 'lodash';
import Avatar from 'antd/lib/avatar/avatar';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import ImplementationInfo from './tabs/inplementationInfo';
import TrafficInfo from './tabs/trafficInfo';
import ReportConfirm from './tabs/reportConfirm';
import PaymentDetail from './tabs/paymentDetail';
import Yup from '../../../utils/yupUtil';
import moment from 'moment';
import './style.scss';

const ImplementationDetail = memo(() => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { activeTab } = useSelector(state => state.global);

  const { assignStaffDetail, calculationPayFlag, loading } = useSelector(
    state => state.matterDate
  );

  const { corporateId } = useSelector(state => state.account);

  const { matterDetailInfo } = assignStaffDetail ?? {};

  const isSp = checkIsSp();

  const onBack = () =>
    state.fromList
      ? navigate(-1)
      : navigate('/matter/assign/detail', {
          state: { id: state.matterDateNumber, fromDetail: true },
        });

  const formik = useFormik({
    initialValues: {
      advance: Options.codes.advanceCategory.map(c => ({
        categoryFlag: c.value,
      })),
    },
    validationSchema: Yup.object({
      beforePayAmount: Yup.string()
        .nullable()
        .integerString(`[精算内容]前払金`),
      allowanceAmount: Yup.string()
        .nullable()
        .integerString(`[精算内容]手当金`),
      advance: Yup.array().of(
        Yup.object({
          totalAmount: Yup.string()
            .nullable()
            .integerString(`[精算内容]立替金合計`),
          ...transform(
            [1, 2, 3, 4, 5],
            (result, i) => {
              result[`advanceAmount${i}`] = Yup.string()
                .nullable()
                .integerString(`[精算内容]立替金${i}`);
            },
            {}
          ),
        })
      ),
    }),
    onSubmit: values => {
      dispatch(
        updateAssignStaffDetail({
          ...values,
          ...state,
          ...transform(
            [
              'staffWeekPlanDatetime',
              'staffPreviousDayPlanDatetime',
              // 'staffGettingUpPlanDatetime',
              // 'staffDeparturePlanDatetime',
              'staffArrivePlanDatetime',
              'staffEndPlanDatetime',
            ],
            (result, datetime) => {
              const time = values[datetime.replace('Datetime', 'Time')];
              result[datetime] =
                time &&
                `${(assignStaffDetail?.[datetime]
                  ? moment(assignStaffDetail[datetime])
                  : moment()
                ).format('YYYY/M/D')} ${time}:00`;
            }
          ),
          staffGettingUpPlanDatetime:
            values?.staffGettingUpPlanTime
              ? `${values?.matterDate} ${values?.staffGettingUpPlanTime}:00`
              : null,
          staffDeparturePlanDatetime:
            values?.staffDeparturePlanTime
              ? `${values?.matterDate} ${values?.staffDeparturePlanTime}:00`
              : null,
        })
      )
        .unwrap()
        // .then(onBack);
    },
  });

  const tabs = [
    {
      key: 'implementationInfo',
      label: '実施情報',
      children: <ImplementationInfo formik={formik} />,
    },
    {
      key: 'trafficInfo',
      label: '交通情報',
      children: <TrafficInfo formik={formik} />,
    },
    {
      key: 'reportConfirm',
      label: '報告確認',
      children: <ReportConfirm formik={formik} />,
    },
    {
      key: 'paymentDetail',
      label: '精算内容',
      children: <PaymentDetail formik={formik} />,
    },
  ];

  const tabErrorGroup = {
    paymentDetail: ['beforePayAmount', 'allowanceAmount', 'advance'],
  };

  useEffect(() => {
    state && dispatch(fetchAssignStaffDetail({ ...state, corporateId }));
  }, [state, corporateId]);

  useEffect(() => {
    assignStaffDetail &&
      formik.setValues({
        ...formik.values,
        ...assignStaffDetail,
        ...transform(
          [
            'staffWeek',
            'staffPreviousDay',
            'staffGettingUp',
            'staffDeparture',
            'staffArrive',
            'staffEnd',
          ],
          (result, item) => {
            result[`${item}PlanTime`] = assignStaffDetail?.[
              `${item}PlanDatetime`
            ]
              ? moment(assignStaffDetail[`${item}PlanDatetime`]).format('HH:mm')
              : null;
          }
        ),
        advance: Options.codes.advanceCategory.map(c => ({
          categoryFlag: c.value,
          ...(assignStaffDetail?.advance ?? []).find(
            d => d.categoryFlag === c.value
          ),
        })),
      });
  }, [assignStaffDetail]);

  const {
    values: {
      payBaseFlag,
      punchInDatetime,
      punchOutDatetime,
      hourlyPayBase,
      dailyPayBase,
      overtimePay,
      payCutHourlyPay,
      advance,
      allowanceAmount,
    },
  } = formik;

  const totalAdvance = sumBy(
    (advance ?? []).map(ad => toInteger(ad.totalAmount))
  );

  useUpdateEffect(() => {
    handlerFormikFieldChange(
      formik,
      `payAmount`,
      numberConvert(
        (`${payBaseFlag}` === '0'
          ? diffMinute(punchInDatetime, punchOutDatetime) *
            (toInteger(hourlyPayBase) / 60)
          : toInteger(dailyPayBase)) +
          convertToNumber(overtimePay) -
          convertToNumber(payCutHourlyPay) +
          toInteger(allowanceAmount) +
          totalAdvance,
        calculationPayFlag
      )
    );
  }, [
    punchInDatetime,
    punchOutDatetime,
    overtimePay,
    payCutHourlyPay,
    calculationPayFlag,
    payBaseFlag,
    hourlyPayBase,
    dailyPayBase,
    allowanceAmount,
    advance,
  ]);

  return (
    !loading && (
      <>
        <div className={'page_base--container implement_page'}>
          <span className={'page_base--container_notice'}>*は必須項目です</span>
          <div className="implement_page--title display-linebreak">
            <div className="implement_page--title-left">
              <span>{`案件No.  ${
                matterDetailInfo?.matterNumber ?? ''
              }\t\t見積書No.   ${matterDetailInfo?.quoteNumber ?? ''}`}</span>
              <span>{matterDetailInfo?.matterName}</span>
            </div>
            <div className="implement_page--title-right">
              <div>
                <span>{`取引先 ${matterDetailInfo?.clientName ?? ''}`}</span>
                <span>{`開催地 ${matterDetailInfo?.venueName ?? ''}`}</span>
              </div>
              <div>
                <span>{`案件担当者  ${
                  matterDetailInfo?.matterManagerUserName ?? ''
                }`}</span>
                <span>{`営業担当者  ${
                  matterDetailInfo?.salesManagerUserName ?? ''
                }`}</span>
              </div>
              <div>
                <span>{`取引先担当者  ${
                  matterDetailInfo?.clientManagerUserName ?? ''
                }`}</span>
                <span>{` `}</span>
              </div>
            </div>
            <div className="implement_page--title-end">
              <div>
                <Avatar src={assignStaffDetail?.image} size={75} />
              </div>
              <div>
                <span>
                  {
                    Options.codes.accountPosition[
                      assignStaffDetail?.assignAccountFlag
                    ]?.[assignStaffDetail?.shiftPosition]
                  }
                </span>
                <span style={isSp ? { fontSize: '18px' } : {}}>{`${
                  assignStaffDetail?.assignId ?? ''
                }\t${assignStaffDetail?.assignName ?? ''}`}</span>
              </div>
            </div>
          </div>
          <div className="page_base--tabs">
            <Tabs
              activeKey={activeTab ? activeTab : tabs[0].key}
              onChange={tab => dispatch(globalActions.changeActiveTab(tab))}
            >
              {tabs.map(tab => (
                <Tabs.TabPane tab={tab.label} key={tab.key}>
                  {tab.children}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </div>
        <div
          className={'bottom_btn_area'}
          style={isSp ? { height: '100px' } : {}}
        >
          <div className={'bottom_btn_area--common_actions'}>
            <Button
              text={'戻る'}
              style={Options.buttonStyles.back}
              onClick={onBack}
            />
            <Button
              text={'更新'}
              style={Options.buttonStyles.submit}
              onClick={() => {
                handlerFormikSubmit(formik, tabErrorGroup)
                navigate(-1)
              }}
            />
          </div>
        </div>
      </>
    )
  );
});

export default ImplementationDetail;
