import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import moment from 'moment/moment';

const searchParams = {
  matterNumber: '',
  matterName: '',
  matterDateStart: moment().format('YYYY/M/D'),
  matterDateEnd:moment().format('YYYY/M/D'),
  venueId: null,
  matterManagerUserId: null,
  shiftName: '',
  status: [],
  offset: 0,
  count: 20,
  sortKey: 'matter_date',
  sortMethod: 'Desc',
};

const initialState = {
  searchParams,
  userList: [],
  venueList: [],
  assignStateList: [],
};

export const matterAssignStateInit = createAsyncThunk('matter/init', async () => {
  const [userRes, venueRes] = await fetchMultiRequest([
    fetchRequest(apiUrl.user.list, { status: 1 }),
    fetchRequest(apiUrl.venue.list, { status: 1 }),
  ]);

  return {
    userList: userRes?.item ?? [],
    venueList: venueRes?.item ?? [],
  };
});

export const matterAssignStateListFetch = createAsyncThunk('matter/assign-state/list', async params => {
  const assignRes = await fetchRequest(apiUrl.matter.assignStateList, params);
  const item = assignRes?.item?.map(a=>{
    return{
      ...a,
      assignCount: {
        d: {
          max: a?.matterShiftInfo ? a?.matterShiftInfo?.directorQuantity : null,
          count: a?.matterShiftInfo ? a?.matterShiftInfo?.directorCount : null,
        },
        ad: {
          max: a?.matterShiftInfo ? a?.matterShiftInfo?.adQuantity : null,
          count: a?.matterShiftInfo ? a?.matterShiftInfo?.adCount : null,
        },
        man: {
          max: a?.matterShiftInfo ? a?.matterShiftInfo?.manQuantity : null,
          count: a?.matterShiftInfo ? a?.matterShiftInfo?.manCount : null,
        },
        woman: {
          max: a?.matterShiftInfo ? a?.matterShiftInfo?.womanQuantity : null,
          count: a?.matterShiftInfo ? a?.matterShiftInfo?.womanCount : null,
        },
        gender: {
          max: a?.matterShiftInfo ? a?.matterShiftInfo?.unisexQuantity : null,
          count: a?.matterShiftInfo ? a?.matterShiftInfo?.unisexCount : null,
        },
      },
      doubleBookingList: {
        d: a?.matterShiftInfo?.directorDoubleBookingFlagList ?? [],
        ad: a?.matterShiftInfo?.adDoubleBookingFlagList ?? [],
        man: a?.matterShiftInfo?.manDoubleBookingFlagList ?? [],
        woman: a?.matterShiftInfo?.womanDoubleBookingFlagList ?? [],
        gender: a?.matterShiftInfo?.unisexDoubleBookingFlagList ?? [],
      },
      assignCountDetail: {
        d: a?.matterShiftInfo?.directorNameList ?? [],
        ad: a?.matterShiftInfo?.adNameList ?? [],
        man: a?.matterShiftInfo?.manNameList ?? [],
        woman: a?.matterShiftInfo?.womanNameList ?? [],
        gender: a?.matterShiftInfo?.unisexNameList ?? [],
      },
      shiftName: a?.matterShiftInfo?.shiftName
    }
  });

  return {
    assignStateList: {
      item,
      count: assignRes?.searchCount ?? 0,
    }
  }
});

export const matterAssignStateSlice = createSlice({
  name: 'matterAssignState',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    setSearch: state => {
      state.searchParams = { ...state.searchParams };
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
  },
  extraReducers: {
    [matterAssignStateInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [matterAssignStateListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const matterAssignStateActions = matterAssignStateSlice.actions;
export default matterAssignStateSlice.reducer;