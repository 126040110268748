import './style.scss';
import CustomInput from '../../../../components/customInput';
import Button from '../../../../components/button';
import Options from '../../../../constants/options';
import React, { useEffect, useState, memo } from 'react';
import { generateYears } from '../../forSp';
import { fetchRequest } from '../../../../api/fetch';
import { apiUrl } from '../../../../api/apiUrl';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../../constants/icons';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

const AttendForStaffSp = () => {
  const [showYear, setShowYear] = useState(new Date().getFullYear());
  const [showMonth, setShowMonth] = useState(new Date().getMonth() + 1);
  const [viewState, setViewState] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    status: false,
    submitDate: '',
    availableCount: null,
    attendCount: null,
  });
  const [dateList, setDateList] = useState([]);
  const accountInfo = useSelector(state => state.account);
    const padZero = (num) => {
        return num.toString().padStart(2, '0');
    };

    const openStartDay = () => {
        const previousDay = moment().subtract(1, 'd');
        const year = previousDay.year();
        const month = padZero(previousDay.month() + 1);
        const day = padZero(previousDay.date());

        return `${year}${month}${day}`;
    };
  const openEndDay =
    (moment().add(7, 'd').month() + 1).toString() +
    moment().add(7, 'd').date().toString().padStart(2, '0');

  const dispatch = useDispatch();
  const { staffId } = useSelector(state => state.account);
  const navigate = useNavigate();

  const setDateData = async () => {
    const lastDateOfMonth = new Date(showYear, showMonth, 0).getDate();
    let dateAry = [...new Array(lastDateOfMonth)].map((v, i) => {
      return { date: i + 1, weekday: '', holiday: false };
    });

    const JpH = require('japanese-holidays');
    const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
    const firstWeekDay = new Date(showYear, showMonth - 1, 1).getDay();

    dateAry.forEach((date, i) => {
      dateAry[i] = {
        ...date,
        weekday: weekDay[(firstWeekDay + i) % 7],
        holiday: JpH.isHoliday(new Date(showYear, showMonth - 1, date.date)),
      };
    });

    let dateList = [...new Array(dateAry.length)].map((v, i) => {
      return {
        ...dateAry[i],
        assignFlag: 0,
        matterNumber: '',
        matterName: '',
        workingStart: '',
        workingEnd: '',
        restStartTime: '',
        restEndTime: '',
        nearStation: '',
      };
    });

    const apiRes = await fetchRequest(
      apiUrl.staffSchedule.attend,
      {
        staffId: staffId,
        year: showYear,
        month: showMonth,
      },
      'GET'
    );

    // apiRes?.item?.forEach(shift => {
    //   dateList[dateList.findIndex(value => value.date === shift.date)] = {
    //     ...dateList[dateList.findIndex(value => value.date === shift.date)],
    //     ...shift,
    //     workingStart: shift?.workingStart
    //       ? shift?.workingStart.split(' ')[1].substring(0, 5)
    //       : '',
    //     workingEnd: shift?.workingEnd
    //       ? shift?.workingEnd.split(' ')[1].substring(0, 5)
    //       : '',
    //     restStartTime: shift?.restStartTime
    //       ? shift?.restStartTime.split(' ')[1].substring(0, 5)
    //       : '',
    //     restEndTime: shift?.restEndTime
    //       ? shift?.restEndTime.split(' ')[1].substring(0, 5)
    //       : '',
    //     enableAssign: 1,
    //   };
    // });

    let newData = apiRes?.item?.map(shift => {
      return {
        ...dateList?.[dateList?.findIndex(value => value.date === shift.date)],
        ...shift,
        workingStart: shift?.workingStart
          ? shift?.workingStart.split(' ')[1].substring(0, 5)
          : '',
        workingEnd: shift?.workingEnd
          ? shift?.workingEnd.split(' ')[1].substring(0, 5)
          : '',
        restStartTime: shift?.restStartTime
          ? shift?.restStartTime.split(' ')[1].substring(0, 5)
          : '',
        restEndTime: shift?.restEndTime
          ? shift?.restEndTime.split(' ')[1].substring(0, 5)
          : '',
        enableAssign: 1,
      }
    });

    dateList?.forEach(obj => {
      if (!newData?.some(value => value.date === obj.date)) {
        newData.push(obj);
      }
    });

    newData?.sort((a, b) => {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;

      if (a.matterDateNumber > b.matterDateNumber) return 1;
      if (a.matterDateNumber < b.matterDateNumber) return -1;
      return 0
    });

    setDateList(newData);
    return {
      status: apiRes.status,
      submitDate: apiRes.submitDate,
      availableCount: apiRes.availableCount,
      attendCount: apiRes.attendCount,
    };
  };

  const handleShow = async () => {
    setDateData().then(data =>
      setViewState({ ...data, year: showYear, month: showMonth })
    );
  };

  useEffect(() => {
    handleShow();
  }, []);

  return (
    <div className={'attend_forStaffSp-page_wrap'}>
      <div
        className={'shiftForSp--static_area'}
        style={{ paddingTop: '24px', height: 'fit-content' }}
      >
        <div className={'shiftForSp--static_date'} style={{ marginTop: '0' }}>
          <CustomInput
            inputType={'select'}
            inputName={'year_sp'}
            key={showYear ? showYear : 'year_sp'}
            initialValue={generateYears()}
            selectBoxOptions={generateYears()}
            selectedSelectBox={showYear}
            placeholder={'YYYY'}
            unit={'年'}
            style={{ areaStyle: { width: '50%' } }}
            onChange={e => {
              setShowYear(e);
            }}
          />
          <CustomInput
            inputType={'select'}
            inputName={'month_sp'}
            key={showMonth ? showMonth : 'month_sp'}
            initialValue={[...new Array(12)].map((v, i) => i + 1)}
            selectBoxOptions={[...new Array(12)].map((v, i) => i + 1)}
            selectedSelectBox={showMonth}
            unit={'月'}
            style={{ areaStyle: { width: '50%' } }}
            onChange={e => {
              setShowMonth(e);
            }}
          />
        </div>

        <Button
          style={{
            ...Options.buttonStyles.back,
            width: '100%',
            height: '40px',
            marginTop: '24px',
          }}
          text={'表示'}
          onClick={() => handleShow()}
        />

        <p
          className={'shiftForSp--static_state'}
          style={{
            position: 'relative',
            marginTop: '32px',
            marginBottom: '0',
            gap: '12px',
          }}
        >
          <strong style={{ whiteSpace: 'nowrap' }}>
            {viewState.year}年 {viewState.month}月分
          </strong>

          <div
            style={
              viewState.status
                ? {
                    color: '#6A6F7C',
                    backgroundColor: '#E9F2FC',
                    flexShrink: '0',
                  }
                : {
                    color: '#FFFFFF',
                    backgroundColor: '#D80245',
                    flexShrink: '0',
                  }
            }
            key={viewState.status ? '提出済' : '未提出'}
          >
            {viewState.status ? '提出済' : '未提出'}
          </div>

          <span
            key={viewState.submitDate ? viewState.submitDate : 'datetime'}
            style={{ whiteSpace: 'nowrap' }}
          >
            提出日時 {viewState.submitDate ? viewState.submitDate : '-'}
          </span>
        </p>

        <span className={'day_count'} style={{ marginTop: '24px' }}>
          出勤可能日数：
          {viewState.availableCount || viewState.availableCount == 0
            ? viewState.availableCount
            : '0'}
          日
        </span>
        <span className={'day_count'} style={{ marginTop: '8px' }}>
          出勤確定日数：
          {viewState.attendCount || viewState.attendCount == 0
            ? viewState.attendCount
            : '0'}
          日
        </span>
      </div>

      <Button
        icon={Icons.icon.penWh}
        text={'シフト入力'}
        style={{
          ...Options.buttonStyles.submit,
          width: '80%',
          height: '40px',
          margin: '24px 10% 16px',
        }}
        onClick={() => {
          navigate('/staff-plan/register', {
            state: { year: viewState.year, month: viewState.month },
          });
        }}
      />

      <div className={'attend_forStaffSp-calendar_area'}>
        {[...Array(new Date(showYear, showMonth, 0).getDate())].map((_, idx) => (
          <div
            className={'attend_forStaffSp-calendar_box'}
            key={`calendar_${idx}`}
          >
            <div
              className={'attend_forStaffSp-calendar_box_date'}
              style={
                dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.weekday === '日' || dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.holiday
                  ? { backgroundColor: '#FDF2F5' }
                  : dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.weekday === '土'
                    ? { backgroundColor: '#D7E2F1' }
                    : { backgroundColor: '#FFFFFF' }
              }
            >
              <span
                style={
                  dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.weekday === '日' || dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.holiday
                    ? { color: '#D80245', width: '75px' }
                    : dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.weekday === '土'
                      ? { color: '#3662A5', width: '75px' }
                      : { color: '#242424', width: '75px' }
                }
              >
                {`${dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.date ?? '-'}日(${dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.weekday ?? '-'}`}
                {dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.holiday ? '・祝)' : ')'}
              </span>
              {/*<span*/}
              {/*  style={*/}
              {/*    obj?.weekday === '日' || obj?.holiday*/}
              {/*      ? { color: '#D80245', width: '75px' }*/}
              {/*      : obj?.weekday === '土'*/}
              {/*        ? { color: '#3662A5', width: '75px' }*/}
              {/*        : { color: '#242424', width: '75px' }*/}
              {/*  }*/}
              {/*>*/}
              {/*  {`${obj.date}日(${obj['weekday']}`}*/}
              {/*  {obj.holiday ? '・祝)' : ')'}*/}
              {/*</span>*/}

              <span
                style={
                  dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.enableAssign
                    ? {
                      color: '#D80245',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      width: '20px',
                      height: '20px',
                      lineHeight: '20px',
                    }
                    : {
                      color: '#707070',
                      fontSize: '20px',
                      textAlign: 'center',
                      width: '20px',
                      height: '20px',
                      lineHeight: '20px',
                    }
                }
              >
                {dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.enableAssign ? '○' : '−'}
              </span>

              {/*<span*/}
              {/*  style={*/}
              {/*    obj?.enableAssign*/}
              {/*      ? {*/}
              {/*        color: '#D80245',*/}
              {/*        fontSize: '20px',*/}
              {/*        fontWeight: 'bold',*/}
              {/*        textAlign: 'center',*/}
              {/*        width: '20px',*/}
              {/*        height: '20px',*/}
              {/*        lineHeight: '20px',*/}
              {/*      }*/}
              {/*      : {*/}
              {/*        color: '#707070',*/}
              {/*        fontSize: '20px',*/}
              {/*        textAlign: 'center',*/}
              {/*        width: '20px',*/}
              {/*        height: '20px',*/}
              {/*        lineHeight: '20px',*/}
              {/*      }*/}
              {/*  }*/}
              {/*>*/}
              {/*  {obj?.enableAssign ? '○' : '−'}*/}
              {/*</span>*/}
            </div>

            {dateList?.filter(obj => obj.date === idx + 1)?.length >= 2 ? (
              <>
                {dateList?.filter(obj => obj.date === idx + 1)?.map((obj, index) => {
                  return (<>
                    {obj?.assignFlag ?
                      (<React.Fragment key={`${obj.matterNumber}${index}`}>
                        <div className={'attend_forStaffSp-calendar_box_detail'}>
                          <p>{obj?.matterName}</p>
                          <div>
                            <span style={{ color: '#646464', width: '75px' }}>
                              勤務時間
                            </span>
                            <span>
                              {obj?.workingStart}〜{obj?.workingEnd}
                              {obj?.restTime ? ` (休憩 ${obj.restTime ?? '-'}分)` : ' (休憩 - )'}
                            </span>
                          </div>

                          <div>
                            <span style={{ color: '#646464', width: '75px' }}>
                              開催地最寄駅
                            </span>
                            <span>{obj?.nearStation}</span>
                          </div>
                          {obj?.assignFlag &&
                            openStartDay() <=
                            showYear.toString() + showMonth.toString() +
                            (dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.date ?? 0).toString().padStart(2, '0') && (
                              <div
                                style={{
                                  display: 'block',
                                  width: '100%',
                                  textAlign: 'right',
                                  height: '18px',
                                  marginTop: 0,
                                }}
                              >
                                <Link
                                  to={'/recruit-matter/detail'}
                                  state={{
                                    matterNumber: obj?.matterNumber,
                                    matterDateNumber: obj?.matterDateNumber,
                                    shiftId: obj?.shiftId,
                                    assignAccountFlag: 0,
                                    assignId: accountInfo?.staffId,
                                  }}
                                >
                                  <span
                                    style={{
                                      color: '#7B7B7B',
                                      lineHeight: '10px',
                                      borderBottom: '1px solid #7B7B7B',
                                      fontSize: '10px'
                                    }}
                                  >
                                    案件詳細
                                  </span>
                                </Link>
                              </div>
                            )}
                        </div>
                      </React.Fragment>) :
                      (<></>)
                    }
                  </>)
                })}
              </>
            ) : (
              <>
                {dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.assignFlag ? (
                  <div className={'attend_forStaffSp-calendar_box_detail'}>
                    <p>{dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.matterName}</p>
                    <div>
                      <span style={{ color: '#646464', width: '75px' }}>
                        勤務時間
                      </span>
                      <span>
                        {dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.workingStart}〜{dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.workingEnd}
                        {dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.restStartTime && dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.restEndTime
                          ? `(休憩 ${dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.restStartTime}~
                      ${dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.restEndTime})`
                          : ''}
                      </span>
                    </div>

                    <div>
                      <span style={{ color: '#646464', width: '75px' }}>
                        開催地最寄駅
                      </span>
                      <span>{dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.nearStation}</span>
                    </div>
                    {dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.assignFlag &&
                        openStartDay() <=
                        showYear.toString() + showMonth.toString() +
                        (dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.date ?? 0).toString().padStart(2, '0') &&
                      // showMonth.toString() +
                      // dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.date.toString().padStart(2, '0') <=
                      // openEndDay &&
                      (
                        <div
                          style={{
                            display: 'block',
                            width: '100%',
                            textAlign: 'right',
                            height: '18px',
                            marginTop: 0,
                          }}
                        >
                          <Link
                            to={'/recruit-matter/detail'}
                            state={{
                              matterNumber: dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.matterNumber,
                              matterDateNumber: dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.matterDateNumber,
                              shiftId: dateList?.[dateList?.findIndex(obj => obj?.date === idx + 1)]?.shiftId,
                              assignAccountFlag: 0,
                              assignId: accountInfo?.staffId,
                            }}
                          >
                            <span
                              style={{
                                color: '#7B7B7B',
                                lineHeight: '10px',
                                borderBottom: '1px solid #7B7B7B',
                                fontSize: '10px'
                              }}
                            >
                              案件詳細
                            </span>
                          </Link>
                        </div>
                      )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}


            {/*{obj?.assignFlag ? (*/}
            {/*  <div className={'attend_forStaffSp-calendar_box_detail'}>*/}
            {/*    <p>{obj?.matterName}</p>*/}
            {/*    <div>*/}
            {/*      <span style={{ color: '#646464', width: '75px' }}>*/}
            {/*        勤務時間*/}
            {/*      </span>*/}
            {/*      <span>*/}
            {/*        {obj?.workingStart}〜{obj?.workingEnd}*/}
            {/*        {obj?.restStartTime && obj?.restEndTime*/}
            {/*          ? `(休憩 ${obj?.restStartTime}~*/}
            {/*          ${obj?.restEndTime})`*/}
            {/*          : ''}*/}
            {/*      </span>*/}
            {/*    </div>*/}

            {/*    <div>*/}
            {/*      <span style={{ color: '#646464', width: '75px' }}>*/}
            {/*        開催地最寄駅*/}
            {/*      </span>*/}
            {/*      <span>{obj?.nearStation}</span>*/}
            {/*    </div>*/}
            {/*    {obj?.assignFlag &&*/}
            {/*      openStartDay <=*/}
            {/*      showMonth.toString() +*/}
            {/*      obj.date.toString().padStart(2, '0') &&*/}
            {/*      showMonth.toString() +*/}
            {/*      obj.date.toString().padStart(2, '0') <=*/}
            {/*      openEndDay && (*/}
            {/*        <div*/}
            {/*          style={{*/}
            {/*            display: 'block',*/}
            {/*            width: '100%',*/}
            {/*            textAlign: 'right',*/}
            {/*            height: '18px',*/}
            {/*            marginTop: 0,*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <Link*/}
            {/*            to={'/recruit-matter/detail'}*/}
            {/*            state={{*/}
            {/*              matterNumber: obj.matterNumber,*/}
            {/*              matterDateNumber: obj.matterDateNumber,*/}
            {/*              shiftId: obj.shiftId,*/}
            {/*              assignAccountFlag: 0,*/}
            {/*              assignId: accountInfo.staffId,*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <span*/}
            {/*              style={{*/}
            {/*                color: '#7B7B7B',*/}
            {/*                lineHeight: '10px',*/}
            {/*                borderBottom: '1px solid #7B7B7B',*/}
            {/*                fontSize: '10px'*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              案件詳細*/}
            {/*            </span>*/}
            {/*          </Link>*/}
            {/*        </div>*/}
            {/*      )}*/}
            {/*  </div>*/}
            {/*) : (*/}
            {/*  <></>*/}
            {/*)}*/}
          </div>
        ))}

        {/*{dateList.map((obj, idx) => {*/}
        {/*  return (*/}
        {/*    <div*/}
        {/*      className={'attend_forStaffSp-calendar_box'}*/}
        {/*      key={`calendar_${idx}`}*/}
        {/*    >*/}
        {/*      <div*/}
        {/*        className={'attend_forStaffSp-calendar_box_date'}*/}
        {/*        style={*/}
        {/*          obj?.weekday === '日' || obj?.holiday*/}
        {/*            ? { backgroundColor: '#FDF2F5' }*/}
        {/*            : obj?.weekday === '土'*/}
        {/*            ? { backgroundColor: '#D7E2F1' }*/}
        {/*            : { backgroundColor: '#FFFFFF' }*/}
        {/*        }*/}
        {/*      >*/}
        {/*        <span*/}
        {/*          style={*/}
        {/*            obj?.weekday === '日' || obj?.holiday*/}
        {/*              ? { color: '#D80245', width: '75px' }*/}
        {/*              : obj?.weekday === '土'*/}
        {/*              ? { color: '#3662A5', width: '75px' }*/}
        {/*              : { color: '#242424', width: '75px' }*/}
        {/*          }*/}
        {/*        >*/}
        {/*          {`${obj.date}日(${obj['weekday']}`}*/}
        {/*          {obj.holiday ? '・祝)' : ')'}*/}
        {/*        </span>*/}
        {/*        <span*/}
        {/*          style={*/}
        {/*            obj?.enableAssign*/}
        {/*              ? {*/}
        {/*                  color: '#D80245',*/}
        {/*                  fontSize: '20px',*/}
        {/*                  fontWeight: 'bold',*/}
        {/*                  textAlign: 'center',*/}
        {/*                  width: '20px',*/}
        {/*                  height: '20px',*/}
        {/*                  lineHeight: '20px',*/}
        {/*                }*/}
        {/*              : {*/}
        {/*                  color: '#707070',*/}
        {/*                  fontSize: '20px',*/}
        {/*                  textAlign: 'center',*/}
        {/*                  width: '20px',*/}
        {/*                  height: '20px',*/}
        {/*                  lineHeight: '20px',*/}
        {/*                }*/}
        {/*          }*/}
        {/*        >*/}
        {/*          {obj?.enableAssign ? '○' : '−'}*/}
        {/*        </span>*/}
        {/*      </div>*/}
        {/*      {obj?.assignFlag ? (*/}
        {/*        <div className={'attend_forStaffSp-calendar_box_detail'}>*/}
        {/*          <p>{obj?.matterName}</p>*/}
        {/*          <div>*/}
        {/*            <span style={{ color: '#646464', width: '75px' }}>*/}
        {/*              勤務時間*/}
        {/*            </span>*/}
        {/*            <span>*/}
        {/*              {obj?.workingStart}〜{obj?.workingEnd}*/}
        {/*              {obj?.restStartTime && obj?.restEndTime*/}
        {/*                ? `(休憩 ${obj?.restStartTime}~*/}
        {/*              ${obj?.restEndTime})`*/}
        {/*                : ''}*/}
        {/*            </span>*/}
        {/*          </div>*/}

        {/*          <div>*/}
        {/*            <span style={{ color: '#646464', width: '75px' }}>*/}
        {/*              開催地最寄駅*/}
        {/*            </span>*/}
        {/*            <span>{obj?.nearStation}</span>*/}
        {/*          </div>*/}
        {/*          {obj?.assignFlag &&*/}
        {/*            openStartDay <=*/}
        {/*              showMonth.toString() +*/}
        {/*                obj.date.toString().padStart(2, '0') &&*/}
        {/*            showMonth.toString() +*/}
        {/*              obj.date.toString().padStart(2, '0') <=*/}
        {/*              openEndDay && (*/}
        {/*              <div*/}
        {/*                style={{*/}
        {/*                  display: 'block',*/}
        {/*                  width: '100%',*/}
        {/*                  textAlign: 'right',*/}
        {/*                  height: '18px',*/}
        {/*                  marginTop: 0,*/}
        {/*                }}*/}
        {/*              >*/}
        {/*                <Link*/}
        {/*                  to={'/recruit-matter/detail'}*/}
        {/*                  state={{*/}
        {/*                    matterNumber: obj.matterNumber,*/}
        {/*                    matterDateNumber: obj.matterDateNumber,*/}
        {/*                    shiftId: obj.shiftId,*/}
        {/*                    assignAccountFlag: 0,*/}
        {/*                    assignId: accountInfo.staffId,*/}
        {/*                  }}*/}
        {/*                >*/}
        {/*                  <span*/}
        {/*                    style={{*/}
        {/*                      color: '#7B7B7B',*/}
        {/*                      lineHeight: '10px',*/}
        {/*                      borderBottom: '1px solid #7B7B7B',*/}
        {/*                      fontSize: '10px'*/}
        {/*                    }}*/}
        {/*                  >*/}
        {/*                    案件詳細*/}
        {/*                  </span>*/}
        {/*                </Link>*/}
        {/*              </div>*/}
        {/*            )}*/}
        {/*        </div>*/}
        {/*      ) : (*/}
        {/*        <></>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  );*/}
        {/*})}*/}
      </div>
    </div>
  );
};

export default AttendForStaffSp;
