import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { getCheckedIndex, checkIsSp } from '../../../utils/fnUtil';
import { globalActions } from '../../../redux/slice/global';
import {
  matterInit,
  fetchMatterList,
  updateLock,
  updateReportSubmit,
  matterActions,
  deleteMatter,
  fetchMatterDetail,
  fetchMatterReportPdf,
} from '../../../redux/slice/matter';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import CsvExportModal from '../../../components/modal/csvExportModal';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Copyright from '../../../components/copyright';
import moment from 'moment';
import './style.scss';

const MatterList = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.account);

  const isSp = checkIsSp();

  const [unlockFlag, setUnlockFlag] = useState(false);

  const {
    searchParams,
    matterList,
    searchCount,
    clientList,
    venueList,
    userList,
    preliminaryIndexList,
    preliminaryList,
    csvExportVisible,
  } = useSelector(state => state.matter);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      const { preliminaryIndex, ...rest } = values;

      dispatch(
        matterActions.saveSearchParams({
          ...rest,
          preliminaryIndex,
          preliminaryId:
            preliminaryIndexList.findIndex(
              p => p.namingId === preliminaryIndex
            ) + 1,
        })
      );
    },
  });

  const {
    preliminaryIndex,
    sortKey: sortColumn,
    sortMethod,
    matterStartDate,
    matterEndDate,
  } = formik.values;

  const searchFormInfo = {
    jsx: () =>
      [
        {
          label: '案件No',
          inputName: 'matterNumber',
          placeholder: '案件Noを入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: isSp ? '100%' : 228 },
          },
        },
        {
          label: '見積書No',
          inputName: 'quoteNumber',
          placeholder: '見積書Noを入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: isSp ? '100%' : 228 },
          },
        },
        {
          label: '案件名',
          inputName: 'matterName',
          placeholder: '案件名を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: 400 },
          },
        },
        {
          union: true,
          inputs: [
            {
              inputType: 'inputDate',
              label: '実施期間',
              inputName: 'matterStartDate',
              placeholder: 'YYYY/MM/DD',
              customDisabledDate: current =>
                matterEndDate && current > moment(matterEndDate),
              unit: '～',
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                inputStyle: { width: 165 },
              },
            },
            {
              inputType: 'inputDate',
              label: ' ',
              inputName: 'matterEndDate',
              customDisabledDate: current => current < moment(matterStartDate),
              placeholder: 'YYYY/MM/DD',
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                areaStyle: {
                  marginLeft: -18,
                },
                inputStyle: { width: 165 },
              },
            },
          ],
        },
        {
          inputType: 'select',
          label: '取引先',
          inputName: 'clientId',
          initialValue: clientList.map(client => client.clientId),
          selectBoxOptions: clientList.map(
            client => `${client.clientId}:${client.clientName}`
          ),
          placeholder: '取引先を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: isSp ? '100%' : 258,
            },
          },
        },
        {
          inputType: 'select',
          label: '開催地',
          inputName: 'venueId',
          initialValue: venueList.map(venue => venue.venueId),
          selectBoxOptions: venueList.map(
            venue => `${venue.venueId}:${venue.venueName}`
          ),
          placeholder: '開催地を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          inputType: 'select',
          label: '社内案件担当者',
          inputName: 'matterManagerUserId',
          initialValue: userList.map(user => user.userId),
          selectBoxOptions: userList.map(
            user =>
              `${user.userId}:${user.name.lastName} ${user.name.firstName}`
          ),
          placeholder: '社内案件担当者を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          inputType: 'select',
          label: '社内営業担当者',
          inputName: 'salesManagerUserId',
          initialValue: userList.map(user => user.userId),
          selectBoxOptions: userList.map(
            user =>
              `${user.userId}:${user.name.lastName} ${user.name.firstName}`
          ),
          placeholder: '社内営業担当者を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          union: true,
          inputs: [
            {
              inputType: 'select',
              label: '予備項目',
              inputName: 'preliminaryIndex',
              showSearch: false,
              initialValue: preliminaryIndexList.map(p => p.namingId),
              selectBoxOptions: preliminaryIndexList.map(p => p.naming),
              extraOnChange: () => formik.setFieldValue('preliminary', []),
              placeholder: '予備項目を入力してください',
              className: {
                areaClass: 'column',
              },
              style: {
                areaStyle: { width: isSp ? '45%' : 228 },
              },
            },
            {
              inputType: 'select',
              label: '',
              inputName: 'preliminary',
              mode: 'multiple',
              allowClear: false,
              showSearch: false,
              initialValue: preliminaryList
                .filter(p => p.namingClassificationId === preliminaryIndex)
                .map(p => p.namingId),
              selectBoxOptions: preliminaryList
                .filter(p => p.namingClassificationId === preliminaryIndex)
                .map(p => `${p.namingId}:${p?.naming}`),
              placeholder: '選択してください',
              disabled: !preliminaryIndex,
              className: {
                areaClass: 'column',
              },
              style: {
                areaStyle: { width: isSp ? '55%' : 291, marginLeft: -18 },
              },
            },
          ],
        },
        {
          inputType: 'select',
          label: 'ステータス',
          inputName: 'matterStatus',
          mode: 'multiple',
          allowClear: false,
          showSearch: false,
          initialValue: Object.keys(Options.statusMap.matterList),
          selectBoxOptions: Object.entries(Options.statusMap.matterList).map(
            ([_, { label }]) => label
          ),
          placeholder: 'すべて',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: isSp ? '100%' : 200 },
          },
        },
      ].map((inputInfo, i) =>
        inputInfo.union ? (
          <div
            className="input_union"
            key={i}
            style={isSp ? { alignItems: 'end' } : {}}
          >
            {inputInfo.inputs.map(input => (
              <CustomFormikInput
                {...input}
                formik={formik}
                key={input.inputName}
              />
            ))}
          </div>
        ) : (
          <CustomFormikInput
            {...inputInfo}
            formik={formik}
            key={inputInfo.inputName}
          />
        )
      ),
  };

  const changeSort = e =>
    dispatch(
      matterActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
      })
    );

  const columnSettings = {
    checkAll: true,
    matterNumber: {
      label: '案件No',
      dataName: 'matter_number',
      jsx: matter => (
        <span className="matter_list--matter_no">
          {matter.permissionLockFlag === 1 && (
            <img
              className="matter_list--matter_no_lock"
              src={Icons.icon.lockGr}
              style={{ width: '24px', height: '24px', marginBottom: '4px' }}
            />
          )}
          <span>{matter.matterNumber}</span>
        </span>
      ),
    },
    quoteNumber: { label: '見積書No', dataName: 'quote_number' },
    matterName: { label: '案件名', dataName: 'matter_name' },
    eventDate: {
      label: '実施期間',
      dataName: 'matter_start_date',
      jsx: matter =>
        matter.matterStartDate && (
          <span>
            {matter.matterStartDate}
            <br />〜{matter.matterEndDate}
          </span>
        ),
    },
    clientName: { label: '取引先', dataName: 'client_name' },
    venueName: { label: '開催地', dataName: 'venue_name' },
    matterManagerName: {
      label: '社内案件担当者',
      dataName: 'matter_manager_name',
    },
    salesManagerName: {
      label: '社内営業担当者',
      dataName: 'sales_manager_name',
    },
    preliminary1Name: {
      label: preliminaryIndexList.find(
        p => p.namingId === 'PromotionPreliminary1'
      )?.naming,
      dataName: `preliminary1Name`,
    },
    matterStatus: {
      label: 'ステータス',
      dataName: 'matter_status',
      jsx: matter => (
        <span
          className="matter_list--status"
          style={{
            color: '#ffffff',
            background:
              Options.statusMap.matterList[matter.matterStatus]?.color,
            color: '#ffffff',
          }}
        >
          {Options.statusMap.matterList[matter.matterStatus]?.label}
        </span>
      ),
    },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
      {
        label: '実績報告',
        icon: Icons.icon.reportNv,
        onClick: async matter => {
          const matterDetail = await dispatch(
            fetchMatterDetail({
              matterNumber: matter.primaryKey,
            })
          ).unwrap();

          const { reportUpdatableId, reportUpdatableFlag } = matterDetail.item;

          const navToReport = () =>
            navigate('/matter/report', {
              state: { id: matter.primaryKey, status: matter.matterStatus.props.children },
            });

          reportUpdatableId === null
            ? navToReport()
            : userId === reportUpdatableId && reportUpdatableFlag === 0
            ? navToReport()
            : dispatch(
                globalActions.showSelectModal({
                  title:
                    '他のユーザーが編集中です。編集内容が失われる可能性がありますが編集しますか？',
                  buttons: [
                    {
                      btnText: '閉じる',
                      style: {
                        ...Options.buttonStyles.back,
                        width: isSp ? '148px' : '208px',
                      },
                    },
                    {
                      btnText: '編集する',
                      btnAction: navToReport,
                      style: {
                        ...Options.buttonStyles.submit,
                        width: isSp ? '148px' : '208px',
                      },
                    },
                  ],
                })
              );
        },
      },
    ],
  };

  const getCheckedMatterNumber = () => {
    const checkedIndex = getCheckedIndex();

    const checked =
      checkedIndex === 'all'
        ? matterList.map(matter => matter.matterNumber)
        : checkedIndex.map(i => matterList[i].matterNumber);

    checked.length === 0 &&
      dispatch(globalActions.showSingleModal('1つ以上選択してください。'));

    return checked;
  };

  const onPrintPdf = () => {
    const matterNumber = getCheckedMatterNumber();

    matterNumber.length > 1
      ? dispatch(globalActions.showSingleModal(`PDFは１つのみ選択可能です`))
      : matterNumber.length === 1 &&
        dispatch(
          fetchMatterReportPdf({
            matterNumber,
          })
        );
  };

  const buttonSettings = {
    viewPager: true,
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => dispatch(matterActions.setCsvExportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    addButton: {
      props: {
        type: '',
        url: '',
        displayText: true,
        disabled: '',
        onClick: () => navigate('/matter/register'),
      },
    },
  };

  useEffect(() => {
    dispatch(matterInit()).then(data => {
      setUnlockFlag(
        data.payload.userList.find(u => u.userId === userId)
          ?.unlockMatterFlag === 1
      );
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMatterList(searchParams));
  }, [dispatch, searchParams]);

  const onLock = permissionLockFlag => () => {
    const matterNumber = getCheckedMatterNumber();

    matterNumber.length > 0 &&
      dispatch(
        updateLock({
          permissionLockFlag,
          matterNumber,
        })
      );
  };

  const onReportSubmit = () => {
    const matterNumber = getCheckedMatterNumber();

    matterNumber.length > 0 &&
      dispatch(
        updateReportSubmit({
          matterNumber,
        })
      );
  };

  const { isOpenSideMenu } = useSelector(state => state.global);

  return (
    <>
      <div
        className={'commonPage--base matter_list matter_base'}
        style={isSp ? { paddingBottom: '190px' } : {}}
      >
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'matterNumber'}
          primaryName={'matterName'}
          apiData={{
            count: searchCount,
            item: matterList,
          }}
          sortSettings={{ sortColumn, sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(
              matterActions.saveSearchParams({
                count,
              })
            )
          }
          onChangeOffset={offset => dispatch(matterActions.saveOffset(offset))}
          onDelete={matterNumber =>
            dispatch(
              deleteMatter({
                matterNumber,
              })
            )
          }
          displayNotice={false}
        />
        <Copyright />
      </div>
      {isSp ? (
        <div
          className={`${
            unlockFlag
              ? 'matter_list_sp--multiple_bottom_area'
              : 'matter_list_sp--bottom_area'
          }`}
        >
          {unlockFlag && (
            <div className={'matter_list_sp--sub_btn_area'}>
              <Button
                text={'ロック'}
                icon={Icons.icon.lockNvT}
                onClick={onLock(1)}
                style={{
                  width: '100%',
                  height: '40px',
                  borderRadius: '6px',
                  margin: 0,
                  backgroundColor: '#FFFFFF',
                  color: '#242424',
                }}
              />
              <Button
                text={'ロック解除'}
                icon={Icons.icon.lockNv}
                onClick={onLock(0)}
                style={{
                  width: '100%',
                  height: '40px',
                  borderRadius: '6px',
                  margin: 0,
                  backgroundColor: '#FFFFFF',
                  color: '#242424',
                }}
              />
            </div>
          )}
          <Button
            text="報告書提出済にする"
            onClick={onReportSubmit}
            style={{
              width: '100%',
              backgroundColor: '#0056D3',
              height: '40px',
              margin: 0,
            }}
          />
        </div>
      ) : (
        <div
          className={'bottom_btn_area matter_list--bottom_area'}
          style={
            isSp
              ? unlockFlag
                ? { width: '100%', height: '230px' }
                : { width: '100%', height: '130px' }
              : {
                  width: isOpenSideMenu
                    ? 'calc(100% - 200px)'
                    : 'calc(100% - 56px)',
                }
          }
        >
          <div
            style={
              isSp
                ? {
                    display: 'flex',
                    position: 'absolute',
                    width: '100%',
                    bottom: '100px',
                  }
                : {}
            }
          >
            <Button
              text="報告書提出済にする"
              onClick={onReportSubmit}
              style={
                isSp
                  ? { minWidth: '82%', minHeight: '44px', borderRadius: '6px' }
                  : { borderRadius: '6px' }
              }
            />
          </div>
          <div
            className={'matter_list--submit_buttons'}
            style={isSp ? { width: '86%', top: 0, right: 'auto' } : {}}
          >
            {!isSp && (
              <Button
                text={'PDF出力'}
                icon={Icons.icon.navyDocument}
                onClick={onPrintPdf}
                style={{ borderRadius: '6px' }}
              />
            )}
            {unlockFlag && (
              <>
                <Button
                  text={'ロック'}
                  icon={Icons.icon.lockNvT}
                  onClick={onLock(1)}
                  style={{ borderRadius: '6px' }}
                />
                <Button
                  text={'ロック解除'}
                  icon={Icons.icon.lockNv}
                  onClick={onLock(0)}
                  style={{ borderRadius: '6px' }}
                />
              </>
            )}
          </div>
        </div>
      )}

      <CsvExportModal
        exportType={'matterList'}
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={() =>
          dispatch(matterActions.setCsvExportVisible(!csvExportVisible))
        }
        exportFunction={() => {}}
        searchParams={searchParams}
      />
    </>
  );
};

export default MatterList;
