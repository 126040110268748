import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quoteActions } from '../../../../../redux/slice/quote';
import Icons from '../../../../../constants/icons';
import './style.scss';

const FileUpload = () => {
  const dispatch = useDispatch();
  const { upFile } = useSelector(state => state.quote);

  const getFiles = () => {
    const filedom = document.getElementById('file');
    filedom.click();
  };

  const fileinputChange = e => {
    let fileDatashallow = [...upFile, e.target.files[0]];
    if (fileDatashallow === upFile) {
      return;
    } else {
      dispatch(quoteActions.setUpFile(fileDatashallow));
    }
    e.target.value = '';
  };
  return (
    <>
      <div>
        {upFile.map((e, i) => {
          return (
            <div
              key={e.name + i}
              style={{
                width: '240px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <a href="" className="quote_register--mail--files_nm">
                {e.name}
              </a>
              <img
                src={Icons.icon.xCircleWh}
                alt="del"
                style={{
                  width: '26px',
                  height: '30px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  let shallowCopy = upFile.slice();
                  shallowCopy.splice(i, 1);
                  dispatch(quoteActions.setUpFile(shallowCopy));
                }}
              />
            </div>
          );
        })}
      </div>
      <input
        id="file"
        type="file"
        multiple
        onChange={fileinputChange}
        style={{ display: 'none' }}
      />
      {upFile.length > 4 ? (
        <></>
      ) : (
        <button
          className="quote_register--mail--file_upload"
          onClick={getFiles}
        >
          <div className="quote_register--mail--file_upload--info">
            <img src={Icons.icon.clip} alt="clip" />
            <p style={{ margin: '0' }}>ファイル選択</p>
          </div>
        </button>
      )}
    </>
  );
};
export default FileUpload;
