import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
  staffMatterActions,
  staffMatterListInit,
  fetchRecruitMatterList,
} from '../../../redux/slice/staffMatter';
import Copyright from '../../../components/copyright';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Icons from '../../../constants/icons';
import Options from '../../../constants/options';
import Button from '../../../components/button';
import ListView from '../../../components/listView';
import moment from 'moment';
import prefectures from '../../../constants/prefectures';
import RecruitMatterDetailModal from '../../../components/modal/recruitMatterDetailModal';
import './style.scss';
import { checkIsSp } from '../../../utils/fnUtil';

const RecruitMatterList = () => {
  const dispatch = useDispatch();

  const { searchParams, matterList, matterCategoryList, searchCount } =
    useSelector(state => state.staffMatter);

  const { menuMatterOpenListFlag } = useSelector(state => state.account);

  const [selectedMatter, setSelectedMatter] = useState(-1);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(staffMatterActions.saveSearchParams(values));
    },
  });

  const { matterStartDate, matterEndDate } = formik.values;

  const searchFormInfo = {
    jsx: () =>
      [
        {
          union: true,
          inputs: [
            {
              inputType: 'inputDate',
              label: '実施期間',
              inputName: 'matterStartDate',
              placeholder: 'YYYY/MM/DD',
              customDisabledDate: current =>
                matterEndDate && current > moment(matterEndDate),
              unit: '～',
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                areaStyle: {
                  width: checkIsSp() ? 'calc(50% + 15px)' : '',
                  height: checkIsSp() ? '67px' : ''
                },
                inputStyle: {
                  width: checkIsSp() ? '' : 165
                }
              },
            },
            {
              inputType: 'inputDate',
              label: ' ',
              inputName: 'matterEndDate',
              customDisabledDate: current => current < moment(matterStartDate),
              placeholder: 'YYYY/MM/DD',
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                areaStyle: {
                  marginLeft: -18,
                  width: checkIsSp() ? 'calc(50% - 5px)' : '',
                  height: checkIsSp() ? '67px' : ''
                },
                inputStyle: {
                  width: checkIsSp() ? '' : 165
                }
              },
            },
          ],
        },
        {
          inputType: 'select',
          label: '案件カテゴリ',
          inputName: 'matterCategory',
          initialValue: matterCategoryList.map(c => c.namingId),
          selectBoxOptions: matterCategoryList.map(c => c.naming),
          placeholder: '案件カテゴリを選択してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          inputType: 'select',
          label: '開催都道府県',
          inputName: 'preCode',
          mode: 'multiple',
          allowClear: false,
          initialValue: prefectures.map(p => p.prefecturesCode),
          selectBoxOptions: prefectures.map(p => p.label),
          placeholder: '開催都道府県を選択してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          label: '開催場所',
          inputName: 'cityName',
          placeholder: '例：渋谷区',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          inputType: 'select',
          label: '表示順',
          inputName: 'displaySort',
          showSearch: false,
          initialValue: Options.codes.recruitMatterSort.map(s => s.value),
          selectBoxOptions: Options.codes.recruitMatterSort.map(s => s.label),
          placeholder: '表示順を選択してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: 200 },
          },
        },
      ].map((inputInfo, i) =>
        inputInfo.union ? (
          <div className="input_union" key={i}>
            {inputInfo.inputs.map(input => (
              <CustomFormikInput
                {...input}
                formik={formik}
                key={input.inputName}
              />
            ))}
          </div>
        ) : (
          <CustomFormikInput
            {...inputInfo}
            formik={formik}
            key={inputInfo.inputName}
          />
        )
      ),
  };

  const columnSettings = {
    period: {
      label: '募集期間',
      dataName: 'open_date',
      jsx: matter => `${matter.openDate}~${matter.closeDate}`,
    },
    matterTitle: { label: '案件名', dataName: 'matter_title' },
    matterDate: {
      label: '実施日',
      dataName: 'matter_date',
      jsx: matter =>
        `${matter.matterDate}(${moment(matter.matterDate).format('ddd')})`,
    },
    venueNearestStation: {
      label: '開催地最寄駅',
      dataName: 'venue_nearest_station',
    },
    status: {
      label: 'ステータス',
      dataName: 'applied_flag',
      jsx: matter =>
        matter.appliedFlag === 1 && <span className="status-box">応募済</span>,
    },
    statics: [
      {
        label: '詳細',
        icon: Icons.icon.navyDocument,
        onClick: (_, i) => setSelectedMatter(i),
      },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    ignoreAddBtn: true,
  };

  useEffect(() => {
    dispatch(staffMatterListInit());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchRecruitMatterList(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    const targetElm = document.querySelector('.input_union');
    const targetCp = document.querySelector('.copyright--contents');
    targetElm.style.justifyContent = checkIsSp() ? 'space-between' : '';
    targetCp.style.zIndex = -1;
  }, []);

  return (
    <>
      <div className="commonPage--base recruit-matter-staff">
        <div style={{backgroundColor: '#E9F2FC'}}>
          <ListView
            searchFormInfo={searchFormInfo}
            columnSettings={columnSettings}
            apiData={{
              count: searchCount,
              item: matterList,
            }}
            spView={matterList.map((matter, i) => (
              <div className="custom_sp_view">
                <span className="custom_sp_view--title">{`募集期間：${`${matter.openDate}~${matter.closeDate}`}`}</span>
                {matter.appliedFlag === 1 && (
                  <span className="status-box">応募済</span>
                )}
                <span className="custom_sp_view--matter_title">
                  {matter.matterTitle}
                </span>
                <div className="custom_sp_view--content">
                  <span>実施日</span>
                  <span>{`${matter.matterDate}(${moment(matter.matterDate).format(
                    'ddd'
                  )})`}</span>
                </div>
                <div className="custom_sp_view--content">
                  <span>開催地最寄駅</span>
                  <span>{matter.venueNearestStation}</span>
                </div>
                <Button text="実施詳細" onClick={() => setSelectedMatter(i)} style={{height: '38px'}} />
              </div>
            ))}
            searchTitle="絞り込み"
            tip="本メニューで応募していただいても、勤務が確約されるわけではありません。勤務が確定した方にのみ通知メールが届きます。"
            emptyText="現在募集中の案件はありません。"
            disabledTip={
              menuMatterOpenListFlag !== 1 && '現在ご利用いただけません'
            }
            buttonSettings={buttonSettings}
            sortSettings={{ sortColumn: '', sortMethod: '' }}
            onSearch={formik.handleSubmit}
            onChangeCount={count =>
              dispatch(
                staffMatterActions.saveSearchParams({
                  count,
                })
              )
            }
            onChangeOffset={offset =>
              dispatch(staffMatterActions.saveOffset(offset))
            }
          />
        </div>

        <Copyright />
        <RecruitMatterDetailModal
          selectedMatter={selectedMatter}
          onCancel={() => setSelectedMatter(-1)}
        />
      </div>
    </>
  );
};

export default RecruitMatterList;
