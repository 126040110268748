import './style.scss';
import './spStyle.scss';
import Icons from '../../constants/icons';
import { Select } from 'antd';
import React from 'react';
import classNames from 'classnames';
import { isNull } from 'lodash';
const { Option } = Select;

const SelectBox = ({
  inputName,
  placeholder,
  optionValues,
  optionTexts,
  disabledOptions = [],
  selected,
  onChange,
  onBlur,
  disabled,
  useValue,
  key,
  showSearch,
  error,
  touched,
  usePopContainer,
  allowClear,
  mode,
}) => {
  return (
    <div
      className={classNames('custom_select--area', {
        'custom_select--area_multiple': mode === 'multiple',
      })}
    >
      <Select
        key={key && key}
        id={inputName}
        name={inputName}
        {...(mode === 'multiple' && {
          mode,
          maxTagCount: 'responsive',
          maxTagPlaceholder: '...',
          removeIcon: () => (
            <img src={Icons.icon.xCircle} style={{ height: 24, width: 24 }} />
          ),
          showArrow: true,
        })}
        className={classNames({
          'custom_input--error': error && touched,
        })}
        placeholder={placeholder ? placeholder : ''}
        {...(useValue
          ? {
              value: selected,
              showSearch,
              filterOption: (input, option) =>
                `${option.children}`
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0,
            }
          : {
              defaultValue: selected && selected,
            })}
        allowClear={allowClear}
        defaultActiveFirstOption={false}
        clearIcon={
          <img
            style={{ width: '30px', height: '30px' }}
            src={Icons.icon.xCircle}
          />
        }
        menuItemSelectedIcon={
          <div className="custom_select--option_check_mark" />
        }
        suffixIcon={
          disabled ? (
            <img src={Icons.icon.downGr} />
          ) : (
            <img src={Icons.icon.downNv} />
          )
        }
        listHeight={288}
        dropdownStyle={{ boxShadow: '0 0 8px #2424244D', borderRadius: '6px' }}
        onChange={onChange && onChange}
        onBlur={onBlur && onBlur}
        disabled={disabled ? disabled : false}
        getPopupContainer={
          usePopContainer ? triggerNode => triggerNode.parentNode : null
        }
      >
        {optionValues &&
          optionTexts &&
          optionTexts.map((text, idx) => {
            return (
              <Option
                value={optionValues[idx]}
                key={idx}
                disabled={disabledOptions.includes(optionValues[idx])}
              >
                {text}
              </Option>
            );
          })}
      </Select>
    </div>
  );
};

export default SelectBox;
