import React, { useState } from 'react';
import ModalBase from '../../modalBase';
import Button from '../../../../components/button';
import CustomInput from '../../../../components/customInput';
import Options from '../../../../constants/options';
import Icons from '../../../../constants/icons';
import './style.scss';
import CustomFormikInput from '../../../customInput/customFormikInput';
import { useLocation } from 'react-router-dom';
import { checkIsSp } from '../../../../utils/fnUtil';

const ApprovalModal = props => {
  const { formik, approvalModalVisible, changeModalVisible, onSubmit, index } =
    props;
  const location = useLocation();
  const [message, setMessage] = useState('');
  const isSp = checkIsSp();

  return (
    <ModalBase
      dialogStyle={
        isSp
          ? {
              display: 'flex',
              flexFlow: 'column',
              gap: '64px',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '16px',
              width: '92%',
              boxSizing: 'border-box',
            }
          : {
              display: 'flex',
              flexFlow: 'column',
              gap: '64px',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 40px',
              width: '85%',
              height: 'calc(59% + 64px)',
              minWidth: '800px',
              minHeight: '500px',
              maxHeight: '750px',
            }
      }
      modalVisible={approvalModalVisible}
    >
      <div className="approval_modal--startest">
        <div
          className={'import_modal--title_area'}
          style={isSp ? { height: '24px', marginBottom: '6px' } : {}}
        >
          <p
            style={
              isSp
                ? {
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '16px',
                    color: '#646464',
                  }
                : {}
            }
          >
            捺印承認
          </p>
        </div>

        <div className="approval_modal--header">
          <p>書類への捺印申請を承認します。</p>
          <p>承認されると、捺印済みの書類が出力できるようになります。</p>
        </div>

        <div
          className="approval_modal--message"
          style={isSp ? { flexFlow: 'column', gap: '8px' } : {}}
        >
          <span className="approval_modal--message--span">メッセージ</span>
          <div
            className="approval_modal--message--box"
            style={isSp ? { width: '100%' } : {}}
          >
            {formik ? (
              <CustomFormikInput
                formik={formik}
                inputName={'message'}
                inputType="textBox"
                label=""
                placeholder="メッセージを入力してください"
                style={{
                  areaStyle: isSp ? { width: '100%' } : {},
                }}
              />
            ) : (
              <CustomInput
                inputType="textBox"
                label=""
                inputName="note"
                placeholder="メッセージを入力してください"
                style={{
                  areaStyle: isSp ? { width: '100%' } : {},
                }}
                useValue={true}
                onBlur={e => {
                  setMessage(e.target.value);
                }}
              />
            )}
          </div>
        </div>
        {isSp ? (
          <div
            style={{
              marginTop: '40px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              text={'戻る'}
              style={{ ...Options.buttonStyles.stampBack, width: '142px' }}
              onClick={changeModalVisible}
            />
            <Button
              text={'承認'}
              icon={Icons.icon.okWh}
              style={{ ...Options.buttonStyles.stampApproval, width: '142px' }}
              onClick={() => {
                location.pathname === '/quote/approval/list'
                  ? onSubmit(message)
                  : onSubmit();
              }}
            />
          </div>
        ) : (
          <>
            <div className="approval_modal--btndiv">
              <span className="approval_modal--btndiv--span">
                <Button
                  text={'戻る'}
                  style={Options.buttonStyles.stampBack}
                  onClick={changeModalVisible}
                />
              </span>
              <span className="approval_modal--btndiv--span">
                <Button
                  text={'承認'}
                  icon={Icons.icon.okWh}
                  style={Options.buttonStyles.stampApproval}
                  onClick={() => {
                    location.pathname === '/quote/approval/list'
                      ? onSubmit(message)
                      : onSubmit();
                  }}
                />
              </span>
            </div>
          </>
        )}
      </div>
    </ModalBase>
  );
};

export default ApprovalModal;
