import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  matterAppliedActions,
  matterAppliedInit,
  fetchMatterAppliedList,
} from '../../../redux/slice/matterApplied';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import CsvExportModal from '../../../components/modal/csvExportModal';
import Options from '../../../constants/options';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Copyright from '../../../components/copyright';
import moment from 'moment';
import './style.scss';
import { checkIsSp } from '../../../utils/fnUtil';

const AppliedList = () => {
  const dispatch = useDispatch();

  const {
    searchParams,
    matterAppliedList,
    searchCount,
    matterList,
    userList,
    matterCategoryList,
    csvExportVisible,
  } = useSelector(state => state.matterApplied);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => dispatch(matterAppliedActions.saveSearchParams(values)),
  });

  const {
    matterStartDate,
    matterEndDate,
    sortKey: sortColumn,
    sortMethod,
  } = formik.values;

  const searchFormInfo = {
    jsx: () =>
      [
        {
          label: '案件No',
          inputType: 'select',
          inputName: 'matterNumber',
          initialValue: matterList.map(m => m.matterNumber),
          selectBoxOptions: matterList.map(m => m.matterNumber),
          placeholder: '案件Noを選択してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: checkIsSp() ? '100%' : 240 },
          },
        },
        {
          label: '案件名',
          inputName: 'matterName',
          placeholder: '案件名を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: checkIsSp() ? '100%' : 400 },
          },
        },
        {
          union: true,
          inputs: [
            {
              inputType: 'inputDate',
              label: '案件実施日',
              inputName: 'matterStartDate',
              customDisabledDate: current =>
                matterEndDate && current > moment(matterEndDate),
              placeholder: 'YYYY/MM/DD',
              unit: '～',
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                labelStyle: {},
                inputStyle: { width: 165 },
                areaStyle: checkIsSp() ? { width: 'calc(100% + 36px)' } : {},
              },
            },
            {
              inputType: 'inputDate',
              label: ' ',
              inputName: 'matterEndDate',
              customDisabledDate: current => current < moment(matterStartDate),
              placeholder: 'YYYY/MM/DD',
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                areaStyle: {
                  marginLeft: -18,
                },
                inputStyle: { width: 165 },
              },
            },
          ],
        },
        {
          union: true,
          inputs: [
            {
              inputType: 'inputDate',
              label: '募集日',
              inputName: 'matterRecruitStartDate',
              placeholder: 'YYYY/MM/DD',
              unit: '～',
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                inputStyle: { width: 165 },
                areaStyle: checkIsSp() ? { width: 'calc(100% + 36px)' } : {},
              },
            },
            {
              inputType: 'inputDate',
              label: ' ',
              inputName: 'matterRecruitEndDate',
              placeholder: 'YYYY/MM/DD',
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                areaStyle: {
                  marginLeft: -18,
                },
                inputStyle: { width: 165 },
              },
            },
          ],
        },
        {
          inputType: 'select',
          label: '案件カテゴリ',
          inputName: 'matterCategory',
          initialValue: matterCategoryList.map(n => n.namingId),
          selectBoxOptions: matterCategoryList.map(n => n.naming),
          placeholder: '案件カテゴリを選択してください',
          showSearch: false,
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: checkIsSp() ? '100%' : 400,
            },
          },
        },
        {
          label: 'スタッフID',
          inputName: 'staffId',
          placeholder: 'スタッフIDを入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: checkIsSp() ? '100%' : 400,
            },
          },
        },
        {
          label: 'スタッフ名',
          inputName: 'staffName',
          placeholder: 'スタッフ名を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: checkIsSp() ? '100%' : 400,
            },
          },
        },
        {
          label: 'ポジション',
          inputType: 'select',
          inputName: 'staffPosition',
          showSearch: false,
          initialValue: Options.codes.position.map(p => p.value),
          selectBoxOptions: Options.codes.position.map(p => p.label),
          placeholder: '選択してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: checkIsSp() ? '100%' : 228,
            },
          },
        },
        ,
        {
          label: 'メイン担当者',
          inputType: 'select',
          mode: 'multiple',
          allowClear: false,
          inputName: 'matterManagerUserId',
          initialValue: userList.map(user => user.userId),
          selectBoxOptions: userList.map(
            user =>
              `${user.userId}:${user.name.lastName} ${user.name.firstName}`
          ),
          placeholder: 'メイン担当者を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: checkIsSp() ? '100%' : 400,
            },
          },
        },
        {
          inputType: 'select',
          label: 'ステータス',
          mode: 'multiple',
          allowClear: false,
          showSearch: false,
          inputName: 'status',
          initialValue: Options.statusMap.matterApplied.map(s => s.value),
          selectBoxOptions: Options.statusMap.matterApplied.map(s => s.label),
          placeholder: 'すべて',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: checkIsSp() ? '100%' : 150 },
          },
        },
      ].map((inputInfo, i) =>
        inputInfo.union ? (
          <div className="input_union" key={i}>
            {inputInfo.inputs.map(input => (
              <CustomFormikInput
                {...input}
                formik={formik}
                key={input.inputName}
              />
            ))}
          </div>
        ) : (
          <CustomFormikInput
            {...inputInfo}
            formik={formik}
            key={inputInfo.inputName}
          />
        )
      ),
  };

  const changeSort = e =>
    dispatch(
      matterAppliedActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
      })
    );

  const columnSettings = {
    matterNumber: { label: '案件No', dataName: 'matter_number' },
    matterName: { label: '案件名', dataName: 'matter_name' },
    matterDate: { label: '実施日', dataName: 'matter_date' },
    recruitmentPeriod: { label: '募集期間', dataName: 'open_date' },
    matterCategoryName: {
      label: '案件カテゴリ',
      dataName: 'matter_category_name',
    },
    staffId: { label: 'スタッフID', dataName: 'staff_id' },
    staffName: { label: 'スタッフ名', dataName: 'staff_name' },
    staffPosition: {
      label: 'ポジション',
      dataName: 'staff_position',
      jsx: apply =>
        Options.codes.position.find(p => p.value == apply.staffPosition)?.label,
    },
    staffEvaluation: {
      label: '評価',
      dataName: 'staff_evaluation',
      jsx: apply =>
        Options.codes.staffEvaluation.find(
          p => p.value == apply.staffEvaluation
        )?.label,
    },
    appliedDatetime: { label: '応募日時', dataName: 'applied_datetime' },
    matterManagerUserName: {
      label: 'メイン担当者',
      dataName: 'matter_manager_user_name',
    },
    status: {
      label: 'ステータス',
      dataName: 'status',
      jsx: apply =>
        Options.statusMap.matterApplied.find(p => p.value == apply.status)
          ?.label,
    },
    statics: [],
  };

  const buttonSettings = {
    viewPager: true,
    ignoreAddBtn: checkIsSp(),
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => dispatch(matterAppliedActions.setCsvExportVisible(true)),
      style: Options.buttonStyles.csv,
    },
  };

  useEffect(() => {
    dispatch(matterAppliedInit());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMatterAppliedList(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    const searchBase =document.querySelector('.list_view--search_base');
    searchBase.style.zIndex = 2;
  },[]);

  return (
    <>
      <div className={'commonPage--base staff_recruit_list matter_base'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          sortSettings={{ sortColumn, sortMethod }}
          apiData={{
            count: searchCount,
            item: matterAppliedList,
          }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(
              matterAppliedActions.saveSearchParams({
                count,
              })
            )
          }
          onChangeOffset={offset =>
            dispatch(matterAppliedActions.saveOffset(offset))
          }
        />
        <Copyright />
      </div>
      <CsvExportModal
        exportType={'appliedList'}
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={() =>
          dispatch(matterAppliedActions.setCsvExportVisible(!csvExportVisible))
        }
        exportFunction={() => {}}
        searchParams={searchParams}
      />
    </>
  );
};

export default AppliedList;
