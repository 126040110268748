import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { pick } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMatteStaffAssignList,
  matterStaffAssignActions,
  matterStaffAssignInit,
} from '../../../redux/slice/matterStaffAssign';
import { checkIsSp } from '../../../utils/fnUtil';
import { globalActions } from '../../../redux/slice/global';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Options from '../../../constants/options';
import ProfileInfo from '../../../components/profileInfo';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Copyright from '../../../components/copyright';
import moment from 'moment';
import './style.scss';
import { staffActions } from '../../../redux/slice/staff';
import { csvDownloadFetch } from '../../../redux/slice/other';
import { apiUrl } from '../../../api/apiUrl';
import { fetchRequest, showApiError } from "../../../api/fetch";

const StaffAssignList = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    searchParams,
    matterStaffAssignList,
    searchCount,
    venueList,
    userList,
  } = useSelector(state => state.matterStaffAssign);

  const { menuMatterDateListFlag } = useSelector(state => state.account);
  const { navigateFromDashboard } = useSelector(state => state.global);

  const isSp = checkIsSp();

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values =>
      dispatch(matterStaffAssignActions.saveSearchParams(values)),
  });

  const {
    matterStartDate,
    matterEndDate,
    sortKey: sortColumn,
    sortMethod,
  } = formik.values;

  const controlCheckbox = () => {
    if (navigateFromDashboard === '起床報告') {
      formik.setFieldValue('status', [5])
      return [5];
    } else if (navigateFromDashboard === '出発報告') {
      formik.setFieldValue('status', [6])
      return [6];
    } else if (navigateFromDashboard === '到着報告') {
      formik.setFieldValue('status', [7])
      return [7];
    } else if (navigateFromDashboard === '終業報告') {
      formik.setFieldValue('status', [8])
      return [8];
    } else if (navigateFromDashboard === '前日報告') {
      formik.setFieldValue('status', [4])
      return [4];
    } else if (navigateFromDashboard === '週報告') {
      formik.setFieldValue('status', [3])
      return [3];
    } else {
      return searchParams?.status;
    }
  }

  const searchFormInfo = {
    jsx: () =>
      [
        {
          union: true,
          inputs: [
            {
              inputType: 'inputDate',
              label: '案件実施日',
              inputName: 'matterStartDate',
              placeholder: 'YYYY/MM/DD',
              unit: '～',
              customDisabledDate: current =>
                matterEndDate && current > moment(matterEndDate),
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                inputStyle: { width: 165 },
              },
            },
            {
              inputType: 'inputDate',
              label: ' ',
              inputName: 'matterEndDate',
              placeholder: 'YYYY/MM/DD',
              customDisabledDate: current => current < moment(matterStartDate),
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                areaStyle: {
                  marginLeft: -18,
                },
                inputStyle: { width: 165 },
              },
            },
          ],
        },
        {
          inputType: 'select',
          label: '表示対象',
          inputName: 'assignAccountFlag',
          initialValue: Options.codes.assignAccountFlag.map(a => a.value),
          selectBoxOptions: Options.codes.assignAccountFlag.map(a => a.label),
          placeholder: 'すべて',
          mode: 'multiple',
          allowClear: false,
          showSearch: false,
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: isSp ? '100%' : 228 },
          },
        },
        {
          label: '案件名',
          inputName: 'matterName',
          placeholder: '案件名を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: 400 },
          },
        },
        {
          inputType: 'select',
          label: '開催地',
          inputName: 'venueId',
          initialValue: venueList.map(venue => venue.venueId),
          selectBoxOptions: venueList.map(
            venue => `${venue.venueId}:${venue.venueName}`
          ),
          placeholder: '開催地を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          label: 'シフト名',
          inputName: 'shiftName',
          placeholder: 'シフト名を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          label: 'メイン担当者',
          inputType: 'select',
          inputName: 'mainManagerUserId',
          mode: 'multiple',
          allowClear: false,
          initialValue: userList.map(user => user.userId),
          selectBoxOptions: userList.map(
            user =>
              `${user.userId}:${user.name.lastName} ${user.name.firstName}`
          ),
          placeholder: 'メイン担当者を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          label: 'スタッフID',
          inputName: 'assignId',
          placeholder: 'スタッフIDを入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          label: 'スタッフ名',
          inputName: 'assignName',
          placeholder: 'スタッフ名を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          inputType: 'checkBox',
          checkBoxLabel: 'ステータス',
          inputName: 'status',
          option: Options.statusMap.staffAssignStatus,
          mode: 'custom',
          style: isSp ? { areaStyle: { width: '40%' } } : {},
        },
        {
          inputType: 'checkBox',
          checkBoxLabel: '予定時刻',
          inputName: 'excessFlag',
          option: Options.codes.excessFlag,
          mode: 'custom',
          style: isSp ? { areaStyle: { width: '40%' } } : {},
        },
      ].map((inputInfo, i) =>
        inputInfo.union ? (
          <div className="input_union" key={`input_union_${i}`}>
            {inputInfo.inputs.map(input => (
              <CustomFormikInput
                {...input}
                formik={formik}
                key={input.inputName}
              />
            ))}
          </div>
        ) : inputInfo.inputType === 'checkBox' ? (
          <div className="input_union--checkbox" key={`input_union--checkbox_${i}`}>
            <label style={isSp ? { width: '100%' } : {}}>
              {inputInfo.checkBoxLabel}
            </label>
            <CustomFormikInput
              {...inputInfo}
              formik={formik}
              onChange={(e, i) => {
                const inputValue = formik.values[inputInfo.inputName];

                const optionValue = inputInfo.option[i].value;

                const updateValue = e.target.checked
                  ? [...inputValue, optionValue]
                  : inputValue.filter(v => v !== optionValue);

                formik.setFieldValue(inputInfo.inputName, updateValue);
              }}
            />
          </div>
        ) : (
          <CustomFormikInput
            {...inputInfo}
            formik={formik}
            key={inputInfo.inputName}
          />
        )
      ),
  };

  const changeSort = e =>
    dispatch(
      matterStaffAssignActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
      })
    );

  const columnSettings = {
    matterDate: { label: '実施日', dataName: 'matter_date' },
    matterNumber: { label: '案件No', dataName: 'matter_number' },
    matterName: { label: '案件名', dataName: 'matter_name' },
    venueName: { label: '開催地', dataName: 'venue_name' },
    mainManagerUserName: {
      label: 'メイン担当者',
      dataName: 'main_manager_user_name',
    },
    shift: {
      label: 'シフト',
      dataName: 'shift_name',
      jsx: shiftAssign => (
        <span className="display-linebreak">
          {`${shiftAssign.shiftName ? `${shiftAssign.shiftName}\n` : ''}${
            shiftAssign.workStartDatetime || shiftAssign.workEndDatetime
              ? `(${shiftAssign.workStartDatetime}~${shiftAssign.workEndDatetime})`
              : ''
          }`}
        </span>
      ),
    },
    planDatetime: {
      label: '予定時刻',
      dataName: 'plan_datetime',
      jsx: shiftAssign =>
        shiftAssign.planDatetime
          ? moment(shiftAssign.planDatetime).format(
              Options.statusMap.staffAssignStatus.find(
                s => s.value === shiftAssign.status
              )?.format || 'HH:mm'
            )
          : '-',
    },
    assignId: {
      label: 'アサイン者',
      dataName: 'assign_id',
      jsx: shiftAssign => (
        <ProfileInfo
          image={shiftAssign.image}
          position={
            Options.codes.accountPosition[shiftAssign.assignAccountFlag][
              shiftAssign.shiftPosition
            ]
          }
          id={shiftAssign.assignId}
          name={shiftAssign.assignName}
        />
      ),
    },
    status: {
      label: 'ステータス',
      dataName: 'status',
      jsx: shiftAssign => (
        <>
          {
            Options.statusMap.staffAssignStatus.find(
              s => s.value === shiftAssign.status
            )?.label
          }
          {shiftAssign.excessFlag === 1 && (
            <span style={{ color: '#d80245', marginLeft: 10 }}>
              <img src={Icons.icon.alertRd} />
              超過
            </span>
          )}
        </>
      ),
    },
    statics: [
      {
        label: '詳細',
        icon: Icons.icon.editSGr,
        onClick: (shiftAssign, i) => {
          if (shiftAssign?.assignId?.props?.position !== '協力会社') {
            if (menuMatterDateListFlag === 1) {
              dispatch(globalActions.setNavigateFromDashboard(null))
              navigate('/matter/implementation', {
                state: {
                  ...pick(matterStaffAssignList[i], [
                    'matterNumber',
                    'venueId',
                    'matterDateNumber',
                    'shiftId',
                    'assignAccountFlag',
                    'assignId',
                  ]),
                  fromList: true,
                },
              })
            } else {
              dispatch(globalActions.showSingleModal('権限がありません'))
            }
          }
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(matterStaffAssignInit());
  }, [dispatch]);

  useEffect(async () => {
    if (navigateFromDashboard !== null) {
      await dispatch(fetchMatteStaffAssignList({
        ...searchParams,
        status: controlCheckbox(),
      })).then(() => dispatch(globalActions.setNavigateFromDashboard(null)));
    } else {
      dispatch(fetchMatteStaffAssignList(searchParams));
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    const interval = setInterval(
      () => dispatch(matterStaffAssignActions.refresh()),
      180000
    );
    return () => clearInterval(interval);
  }, []);

  const isJsonString = str => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const exportCsv = () => {
    let newObj;
    if (searchParams) {
      newObj = { ...searchParams };
      Object.keys(newObj).forEach(key => {
        if (!newObj?.[key] || newObj?.[key]?.length === 0) {
          delete newObj?.[key];
        }
      });
    }

    let info = {
      url: apiUrl.matter.assignListCsv,
      params: {
        ...newObj,
        sortKey: "matter_date",
      },
    };

    dispatch(csvDownloadFetch(info)).then(data => {
      const blob = new Blob([data.payload], {
        type: 'text/csv',
      });

      const fileReader = new FileReader();
      fileReader.onload = event => {
        const fileContent = event.target.result;
        if (isJsonString(fileContent)) {
          const obj = JSON.parse(fileContent);
          if (obj.displayMessage) {
            showApiError(obj);
            return;
          }
        }

        if (data.error && data.error.message === 'Rejected') {
          return;
        }

        const ahref = document.createElement('a');
        ahref.download =
          'staffReportingStatus' +
          '_' +
          moment().format('YYYYMMDDHHmmssSSS') +
          '.csv';
        ahref.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        ahref.dispatchEvent(clickEvt);
        ahref.remove();
      };
      fileReader.readAsText(blob);
    });
  };

  return (
    <>
      <div className={'commonPage--base staff_assign_list'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          apiData={{
            count: searchCount,
            item: matterStaffAssignList,
          }}
          sortSettings={{ sortColumn, sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(
              matterStaffAssignActions.saveSearchParams({
                count,
              })
            )
          }
          onChangeOffset={offset =>
            dispatch(matterStaffAssignActions.saveOffset(offset))
          }
          buttonSettings={{
            viewPager: true,
            ignoreAddBtn: true,
            export: {
              icon: Icons.icon.export,
              text: 'CSV出力',
              onClick: exportCsv,
              style: Options.buttonStyles.csv,
            },
          }}
          staffAssignPage={true}
        />
        <Copyright />
      </div>
    </>
  );
};

export default StaffAssignList;
