import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { getCheckedIndex } from '../../../utils/fnUtil';
import { globalActions } from '../../../redux/slice/global';
import {
  matterDateInit,
  fetchMatterDateList,
  sendAssignMail,
  assignConfirm,
  matterDateActions,
} from '../../../redux/slice/matterDate';
import { checkIsSp } from '../../../utils/fnUtil';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import CsvExportModal from '../../../components/modal/csvExportModal';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Copyright from '../../../components/copyright';
import moment from 'moment';
import './style.scss';
import JpH from 'japanese-holidays';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { store } from '../../../redux/store';

const spBtnArea = { width: '100%', justifyContent: 'center' };

const MatterDateList = () => {
  localStorage.removeItem('matter_date_number');
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isSp = checkIsSp();

  const {
    searchParams,
    matterDateList,
    searchCount,
    clientList,
    venueList,
    allUserList,
    csvExportVisible,
  } = useSelector(state => state.matterDate);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => dispatch(matterDateActions.saveSearchParams(values)),
  });

  const { isOpenSideMenu } = useSelector(state => state.global);

  const {
    matterDateStart,
    matterDateEnd,
    sortKey: sortColumn,
    sortMethod,
  } = formik.values;

  const searchFormInfo = {
    jsx: () =>
      [
        {
          label: '案件No',
          inputName: 'matterNumber',
          placeholder: '案件Noを入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: isSp ? '100%' : 228 },
          },
        },
        {
          label: '案件名',
          inputName: 'matterName',
          placeholder: '案件名を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: 400 },
          },
        },
        {
          union: true,
          inputs: [
            {
              inputType: 'inputDate',
              label: '実施期間',
              inputName: 'matterDateStart',
              placeholder: 'YYYY/MM/DD',
              customDisabledDate: current =>
                matterDateEnd && current > moment(matterDateEnd),
              unit: '～',
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                inputStyle: { width: 165 },
              },
            },
            {
              inputType: 'inputDate',
              label: ' ',
              inputName: 'matterDateEnd',
              placeholder: 'YYYY/MM/DD',
              customDisabledDate: current => current < moment(matterDateStart),
              className: {
                areaClass: 'matter_list--date_column',
              },
              style: {
                areaStyle: {
                  marginLeft: -18,
                },
                inputStyle: { width: 165 },
              },
            },
          ],
        },
        {
          inputType: 'select',
          label: '取引先',
          inputName: 'clientId',
          initialValue: clientList.map(client => client.clientId),
          selectBoxOptions: clientList.map(
            client => `${client.clientId}:${client.clientName}`
          ),
          placeholder: '取引先を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: isSp ? '100%' : 258,
            },
          },
        },
        {
          inputType: 'select',
          label: '開催地',
          inputName: 'venueId',
          initialValue: venueList.map(venue => venue.venueId),
          selectBoxOptions: venueList.map(
            venue => `${venue.venueId}:${venue.venueName}`
          ),
          placeholder: '開催地を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          inputType: 'select',
          label: '社内案件担当者',
          inputName: 'matterManagerUserId',
          initialValue: allUserList.map(user => user.userId),
          selectBoxOptions: allUserList.map(
            user =>
              `${user.userId}:${user.name.lastName} ${user.name.firstName}`
          ),
          placeholder: '社内案件担当者を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          inputType: 'select',
          label: '社内営業担当者',
          inputName: 'salesManagerUserId',
          initialValue: allUserList.map(user => user.userId),
          selectBoxOptions: allUserList.map(
            user =>
              `${user.userId}:${user.name.lastName} ${user.name.firstName}`
          ),
          placeholder: '社内営業担当者を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: {
              width: 400,
            },
          },
        },
        {
          inputType: 'select',
          label: 'ステータス',
          inputName: 'status',
          showSearch: false,
          mode: 'multiple',
          allowClear: false,
          initialValue: Object.keys(Options.statusMap.matterDate),
          selectBoxOptions: Object.entries(Options.statusMap.matterDate).map(
            ([_, { label }]) => label
          ),
          placeholder: 'すべて',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: isSp ? '100%' : 200 },
          },
        },
      ].map((inputInfo, i) =>
        inputInfo.union ? (
          <div className="input_union" key={i}>
            {inputInfo.inputs.map(input => (
              <CustomFormikInput
                {...input}
                formik={formik}
                key={input.inputName}
              />
            ))}
          </div>
        ) : (
          <CustomFormikInput
            {...inputInfo}
            formik={formik}
            key={inputInfo.inputName}
          />
        )
      ),
  };

  const changeSort = e =>
    dispatch(
      matterDateActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
      })
    );

  const btnSty = {
    backgroundColor: 'white',
    color: 'black',
    width: '108px',
    height: '36px',
    margin: '0',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '6px',
    border: '1px solid #2B3245',
    flexShrink: '0',
  };

  const columnSettings = {
    checkAll: true,
    matterDate: { label: '実施日', dataName: 'matter_date' },
    matterNumber: { label: '案件No', dataName: 'matter_number' },
    matterName: {
      label: '案件名',
      dataName: 'matter_name',
      jsx: data => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <span>{data.matterName}</span>
          <Button
            text="複製"
            icon={Icons.icon.copyNv}
            style={btnSty}
            id={`matterDateCopyBtn_${data.matterNumber}_${data.matterDate}`}
            onClick={e => {
              e.stopPropagation();

              setCalendar(
                data.matterNumber,
                data.matterDateNumber,
                data.matterDate
              );
            }}
          />
        </div>
      ),
    },
    clientName: { label: '取引先', dataName: 'client_name' },
    venueName: { label: '開催地', dataName: 'venue_name' },
    matterManagerName: {
      label: '社内案件担当者',
      dataName: 'matter_manager_name',
    },
    salesManagerName: {
      label: '社内営業担当者',
      dataName: 'sales_manager_name',
    },
    status: {
      label: 'ステータス',
      dataName: 'status',
      jsx: matterDate => (
        <span
          className="matter_date_list--status"
          style={{
            background: Options.statusMap.matterDate[matterDate.status].color,
          }}
        >
          {Options.statusMap.matterDate[matterDate.status].label}
        </span>
      ),
    },
    statics: [
      {
        label: 'アサイン詳細',
        icon: Icons.icon.assignOkNv,
        onClick: matterDate =>
          navigate('/matter/assign/detail', {
            state: { id: matterDate.primaryKey },
          }),
      },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => dispatch(matterDateActions.setCsvExportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    ignoreAddBtn: isSp,
  };

  useEffect(() => {
    dispatch(matterDateInit());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMatterDateList(searchParams));
  }, [dispatch, searchParams]);

  const getCheckedMatterDateNumber = () => {
    const checkedIndex = getCheckedIndex();

    const checked =
      checkedIndex === 'all'
        ? matterDateList.map(matterDate => matterDate.matterDateNumber)
        : checkedIndex.map(i => matterDateList[i].matterDateNumber);

    checked.length === 0 &&
      dispatch(globalActions.showSingleModal('1つ以上選択してください。'));

    return checked;
  };

  const onUpdate = updateType => () => {
    const matterDateNumber = getCheckedMatterDateNumber();

    if (matterDateNumber.length > 0) {
      if (
        updateType === 'mail' &&
        matterDateList
          .filter(m => matterDateNumber.includes(m.matterDateNumber))
          .some(m => [0, 1].includes(m.status))
      ) {
        dispatch(
          globalActions.showSingleModal(
            'アサイン確定済の場合にのみ送信できます'
          )
        );

        return;
      }

      dispatch(
        (updateType === 'mail' ? sendAssignMail : assignConfirm)({
          matterDateNumber,
        })
      );
    }
  };

  // 複製用マルチカレンダー
  const weekStr = ['日', '月', '火', '水', '木', '金', '土'];
  const JpH = require('japanese-holidays');

  const [multiCalendarTopYM, setMultiCalendarTopYM] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  });
  const [multiCalendarVisible, setMultiCalendarVisible] = useState(false);
  const [multiCalendarData, setMultiCalendarData] = useState([]);
  const [multiCalendarSetDates, setMultiCalendarSetDates] = useState([]);
  const [multiCalendarAddDates, setMultiCalendarAddDates] = useState([]);

  const [copyDateRequestParams, setCopyDateRequestParams] = useState({
    matterNumber: '',
    matterDateNumber: '',
    copyDate: [],
  });

  const setCalendar = async (matterNum, matterDateNumber, date) => {
    const btnId = `matterDateCopyBtn_${matterNum}_${date}`;
    const cal = document.getElementById('multiCalendar');
    const btnPos = document.getElementById(btnId).getBoundingClientRect();
    const originPos = document
      .querySelector('div.list_view--list_wrapper')
      .getBoundingClientRect();

    cal.style.top = `${
      btnPos.y - 495 < 0
        ? btnPos.y - originPos.y + 41
        : btnPos.y - originPos.y - 495
    }px`;
    cal.style.left = `${btnPos.x - originPos.x}px`;

    if (!multiCalendarVisible) {
      setMultiCalendarVisible(true);
    }

    const copyDateRes = await fetchRequest(apiUrl.matterDate.list, {
      matterNumber: matterNum,
    });

    const setDates = copyDateRes.item.map(o => o.matterDate);

    setMultiCalendarSetDates(setDates);
    setMultiCalendarAddDates([]);

    setCopyDateRequestParams({
      ...copyDateRequestParams,
      matterNumber: matterNum,
      matterDateNumber: matterDateNumber,
      copyDate: [],
    });

    setMultiCalendarTopYM({
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
    });
  };

  const setAddDates = (year, month, date) => {
    setMultiCalendarData(
      multiCalendarData.map((object, idx) => {
        return object.year === year && object.month === month
          ? {
              ...object,
              dateList: object.dateList.map(o => {
                const thisDate = `${year}/${String(month).padStart(
                  2,
                  '0'
                )}/${String(date).padStart(2, '0')}`;

                if (multiCalendarAddDates.includes(thisDate)) {
                  setMultiCalendarAddDates(
                    multiCalendarAddDates.filter(v => v !== thisDate)
                  );
                } else {
                  setMultiCalendarAddDates([
                    ...multiCalendarAddDates,
                    thisDate,
                  ]);
                }

                return o.date === date ? { ...o, isAdd: !o.isAdd } : o;
              }),
            }
          : object;
      })
    );
  };

  const submitAddDates = async () => {
    await fetchRequest(
      apiUrl.matterDate.copy,
      { ...copyDateRequestParams, copyDate: multiCalendarAddDates },
      'POST'
    )
      .then(res => {
        if (res.code === '0') {
          store.dispatch(globalActions.showSingleModal('複製しました'));
        } else {
          store.dispatch(globalActions.showSingleModal('複製に失敗しました'));
        }
      })
      .catch(() => {
        store.dispatch(globalActions.showSingleModal('複製に失敗しました'));
      })
      .finally(() => {
        setMultiCalendarVisible(false);
        dispatch(fetchMatterDateList(searchParams));
      });
  };

  const multiCalendar = () => (
    <div
      className={'multiCalendar_base'}
      id={'multiCalendar'}
      style={
        multiCalendarVisible
          ? { visibility: 'visible', opacity: '1', transition: '.3s' }
          : { visibility: 'hidden', opacity: '0' }
      }
    >
      <div className={'multiCalendar_calendars_wrap'}>
        {multiCalendarData.map((monthData, index) => {
          return (
            <div
              className={'multiCalendar_calendar_box'}
              key={`calendarBox${index}`}
            >
              <div className={'multiCalendar_calendar_header'}>
                <div
                  className={'multiCalendar_calendar_header_arrows'}
                  style={{ marginLeft: '17px' }}
                >
                  {!index && (
                    <>
                      <img
                        src={Icons.icon.calendarArrowL2}
                        alt={'arrowL2'}
                        className={'multiCalendar_element'}
                        onClick={() => {
                          setMultiCalendarTopYM({
                            ...multiCalendarTopYM,
                            year: multiCalendarTopYM.year - 1,
                          });
                        }}
                      />
                      <img
                        src={Icons.icon.calendarArrowL1}
                        alt={'arrowL1'}
                        className={'multiCalendar_element'}
                        onClick={() => {
                          setMultiCalendarTopYM({
                            year:
                              multiCalendarTopYM.month - 1 < 1
                                ? multiCalendarTopYM.year - 1
                                : multiCalendarTopYM.year,
                            month:
                              multiCalendarTopYM.month - 1 < 1
                                ? multiCalendarTopYM.month + 12 - 1
                                : multiCalendarTopYM.month - 1,
                          });
                        }}
                      />
                    </>
                  )}
                </div>
                <span className={'multiCalendar_element'}>
                  {monthData.year}年 {monthData.month}月
                </span>
                <div
                  className={'multiCalendar_calendar_header_arrows'}
                  style={{ marginRight: '17px' }}
                >
                  {!index && (
                    <>
                      <img
                        src={Icons.icon.calendarArrowR1}
                        alt={'arrowR1'}
                        className={'multiCalendar_element'}
                        onClick={() => {
                          setMultiCalendarTopYM({
                            year:
                              multiCalendarTopYM.month + 1 > 11
                                ? multiCalendarTopYM.year + 1
                                : multiCalendarTopYM.year,
                            month: (multiCalendarTopYM.month + 1) % 12,
                          });
                        }}
                      />
                      <img
                        src={Icons.icon.calendarArrowR2}
                        alt={'arrowR2'}
                        className={'multiCalendar_element'}
                        onClick={() => {
                          setMultiCalendarTopYM({
                            ...multiCalendarTopYM,
                            year: multiCalendarTopYM.year + 1,
                          });
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className={'multiCalendar_calendar_body'}>
                <div className={'multiCalendar_calendar_body_days'}>
                  {['月', '火', '水', '木', '金', '土', '日'].map(str => {
                    return (
                      <span
                        className={'multiCalendar_element'}
                        style={{
                          color:
                            str === '土'
                              ? '#3662A5'
                              : str === '日'
                              ? '#D80245'
                              : '#242424',
                          opacity: '1',
                        }}
                        key={`calendar_body_days_${str}`}
                      >
                        {str}
                      </span>
                    );
                  })}
                </div>

                <div className={'multiCalendar_calendar_body_dates'}>
                  {monthData.prevMonthDates.map(date => {
                    return (
                      <span
                        className={'multiCalendar_element'}
                        style={{
                          color: '#A0AAB8',
                          opacity: '1',
                        }}
                        key={`calendar_body_prev_date_${date}`}
                      >
                        {date}
                      </span>
                    );
                  })}

                  {monthData.dateList.map(dateObj => {
                    return (
                      <span
                        className={'multiCalendar_element'}
                        style={{
                          cursor: dateObj.isSet ? 'default' : 'pointer',
                          color:
                            dateObj.isSet || dateObj.isAdd
                              ? 'white'
                              : dateObj.holiday || dateObj.dayStr === '日'
                              ? '#D80245'
                              : dateObj.dayStr === '土'
                              ? '#3662A5'
                              : '#242424',
                          border: dateObj.isToday
                            ? '1px solid #0056D3'
                            : 'none',
                          backgroundColor: dateObj.isSet
                            ? '#A0AAB8'
                            : dateObj.isAdd
                            ? '#0056D3'
                            : 'white',
                        }}
                        key={`calendar_body_date_${dateObj.date}`}
                        onClick={
                          !dateObj.isSet
                            ? () => {
                                setAddDates(
                                  monthData.year,
                                  monthData.month,
                                  dateObj.date
                                );
                              }
                            : () => {}
                        }
                      >
                        {dateObj.date}
                      </span>
                    );
                  })}

                  {[
                    ...new Array(
                      7 -
                        ((monthData.prevMonthDates.length +
                          monthData.dateList.length) %
                          7)
                    ),
                  ].map((_, i) => {
                    return (
                      <span
                        className={'multiCalendar_element'}
                        style={{
                          color: '#A0AAB8',
                        }}
                        key={`calendar_body_next_date_${i + 1}`}
                      >
                        {i + 1}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Button
        className={'multiCalendar_element'}
        text={'登録'}
        style={{
          ...Options.buttonStyles.submit,
          width: '240px',
          height: '44px',
          position: 'sticky',
          bottom: '20px',
          left: '55px',
        }}
        onClick={submitAddDates}
      />
    </div>
  );

  useEffect(() => {
    setMultiCalendarData(
      [...new Array(3)].map((_, i) => {
        return {
          year:
            multiCalendarTopYM.month + 1 + i > 12
              ? multiCalendarTopYM.year + 1
              : multiCalendarTopYM.year,
          month:
            multiCalendarTopYM.month + 1 + i > 12
              ? multiCalendarTopYM.month + 1 + i - 12
              : multiCalendarTopYM.month + 1 + i,
          prevMonthDates: [
            ...new Array(
              new Date(
                multiCalendarTopYM.year,
                multiCalendarTopYM.month + i,
                1
              ).getDay()
                ? new Date(
                    multiCalendarTopYM.year,
                    multiCalendarTopYM.month + i,
                    1
                  ).getDay() - 1
                : 6
            ),
          ].map((_, idx, ary) => {
            return (
              new Date(
                multiCalendarTopYM.year,
                multiCalendarTopYM.month + i,
                0
              ).getDate() -
              ary.length +
              1 +
              idx
            );
          }),
          dateList: [
            ...new Array(
              new Date(
                multiCalendarTopYM.year,
                multiCalendarTopYM.month + 1 + i,
                0
              ).getDate()
            ),
          ].map((v, j) => {
            return {
              date: j + 1,
              day: new Date(
                multiCalendarTopYM.year,
                multiCalendarTopYM.month + i,
                j + 1
              ).getDay(),
              dayStr:
                weekStr[
                  new Date(
                    multiCalendarTopYM.year,
                    multiCalendarTopYM.month + i,
                    j + 1
                  ).getDay()
                ],
              holiday:
                JpH.isHoliday(
                  new Date(
                    multiCalendarTopYM.year,
                    multiCalendarTopYM.month + i,
                    j + 1
                  )
                ) ?? '',
              isToday:
                Date().replace(/.[0-9]:.[0-9]:.[0-9]/, '00:00:00') ===
                new Date(
                  multiCalendarTopYM.year,
                  multiCalendarTopYM.month + i,
                  j + 1
                ).toString(),
              isSet: multiCalendarSetDates.includes(
                new Date(
                  multiCalendarTopYM.year,
                  multiCalendarTopYM.month + i,
                  j + 1
                )
                  .toLocaleDateString()
                  .replace('00:00:00', '')
                  .trim()
              ),
              isAdd: false,
            };
          }),
        };
      })
    );
  }, [multiCalendarTopYM]);

  useEffect(() => {
    document
      .querySelector('div.commonPage--base.matter_list.matter_base')
      .addEventListener('click', e => {
        let isCalendarElem = false;

        e.target.classList.forEach(v => {
          if (v.includes('multiCalendar')) isCalendarElem = true;
        });

        if (!isCalendarElem && !e.target.id.includes('matterDateCopyBtn')) {
          setMultiCalendarVisible(false);
        }
      });
  }, []);

  return (
    <>
      <div
        className={'commonPage--base matter_list matter_base'}
        style={isSp ? { paddingBottom: '190px' } : {}}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'matterDateNumber'}
          apiData={{
            count: searchCount,
            item: matterDateList,
          }}
          sortSettings={{ sortColumn, sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(
              matterDateActions.saveSearchParams({
                count,
              })
            )
          }
          onChangeOffset={offset =>
            dispatch(matterDateActions.saveOffset(offset))
          }
          multiCalendar={multiCalendar()}
        />
        <Copyright />
      </div>
      <div className={'bottom_btn_area'} style={isSp ? { ...spBtnArea } : {width: isOpenSideMenu ? 'calc(100% - 200px)' : 'calc(100% - 56px)'}}>
        <div className={'matter_date_list--submit_buttons'}>
          <Button
            text={'アサイン通知送信'}
            icon={Icons.icon.mailNv}
            onClick={onUpdate('mail')}
          />
          <Button text={'アサイン確定'} onClick={onUpdate('assignConfirm')} />
        </div>
        <CsvExportModal
          exportType={'matterDateList'}
          csvExportVisible={csvExportVisible}
          setCsvExportVisible={() =>
            dispatch(matterDateActions.setCsvExportVisible(!csvExportVisible))
          }
          exportFunction={() => {}}
          searchParams={searchParams}
        />
      </div>
    </>
  );
};

export default MatterDateList;
