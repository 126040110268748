import React, { useEffect } from 'react';
import CustomInput from '../../../../components/customInput';
import './style.scss';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import { handlerFormikFieldChange } from '../../../../utils/fnUtil';

const AbleMenu = ({ detailValue, isEdit, formik, sysAuth }) => {
  const parentStyle = {
    areaStyle: {
      padding: '15px',
    },
    textStyle: {
      fontWeight: 'bold',
    }
  };

  const childStyle = {
    areaStyle: {
      marginLeft: '40px',
      padding: '15px',
    },
  };

  const handleChange = e => {
    const cb = e.target;
    let corName;

    Object.keys(checkData).forEach((key, idx) => {
      if (checkData[key].find(el => el.column === cb.id)) {
        corName = key;
      }
    });

    const corChecked = checkData[corName]
      .map((item, index) => {
        return typeof item !== 'string'
          ? document.getElementById(item.column).checked
          : '';
      })
      .filter(v => v !== '');

    let boo = true;

    corChecked.forEach(val => {
      if (!val) boo = false;
    });

    document.getElementById(checkData[corName][0]).checked = boo;

    handlerFormikFieldChange(formik, cb.id, cb.checked ? 1 : 0);
  };

  const handleChangeAll = e => {
    const cb = e.target;
    let corName;

    Object.keys(checkData).forEach((key, idx) => {
      if (checkData[key].find(el => el === cb.id)) {
        corName = key;
      }
    });

    checkData[corName].forEach((item) => {
      if (typeof item !== 'string') {
        document.getElementById(item.column).checked = cb.checked;
        handlerFormikFieldChange(formik, item.column, cb.checked ? 1 : 0);
      }
    })
  };

  const handleAuthStaffCheck = () => {
    let target = document.getElementById('authStaffSystemUse').checked;
    formik.setFieldValue('authStaffSystemUse', target ? 1 : 0);
  }

  const handleSurveyFlagCheck = () => {
    let target = document.getElementById('surveyFlag').checked;
    formik.setFieldValue('surveyFlag', target ? 1 : 0);
  }

  const checkData = {
    quoteCor: [
      '見積管理',
      { label: '見積一覧', column: 'menuQuoteListFlag' },
      { label: '申請一覧', column: 'menuQuoteApprovalListFlag' },
      { label: '見積書No管理', column: 'menuQuoteDocumentNumberFlag' },
      { label: '見積テンプレート一覧', column: 'menuQuoteTemplateListFlag' },
      { label: '見積承認フロー一覧', column: 'menuQuoteFlowListFlag' },
    ],
    matterCor: [
      '案件管理',
      { label: '案件一覧', column: 'menuMatterListFlag' },
      { label: '実施日別案件一覧', column: 'menuMatterDateListFlag' },
      { label: 'スタッフ報告状況', column: 'menuMatterAssignListFlag' },
      { label: 'アサインスタッフ状況', column: 'menuAssignStaffStatesFlag' },
      { label: 'スタッフ募集案件一覧', column: 'menuMatterOpenListFlag' },
      { label: '実施日別募集者一覧', column: 'menuMatterAppliedListFlag' },
    ],
    invCor: ['請求管理', { label: '請求一覧', column: 'menuInvoiceListFlag' }],
    staffCor: [
      'スタッフ管理',
      { label: 'スタッフ一覧', column: 'menuStaffListFlag' },
    ],
    staffShiftCor: [
      'スタッフシフト管理',
      { label: 'スタッフシフト一覧', column: 'menuStaffPlanListFlag' },
    ],
    staffPayCor: [
      'スタッフ支払管理',
      { label: 'スタッフ支払一覧', column: 'menuStaffPayListFlag' },
      { label: '年間給与一覧', column: 'menuStaffPayYearListFlag' },
      { label: '給与締処理', column: 'menuAttendClosingFlag' },
    ],
    analysis: [
      '分析',
      { label: 'スタッフ動向分析', column: 'menuAnalyzeTrendFlag' },
      { label: '売上分析', column: 'menuAnalyzeProfitFlag' },
    ],
    clientManage: [
      '取引先管理',
      { label: '取引先一覧', column: 'menuClientListFlag' },
      { label: '開催地一覧', column: 'menuVenueListFlag' },
    ],
    corSet: [
      '表示制御設定',
      { label: '案件スケジュール一覧', column: 'menuSettingScheduleFlag' },
    ],
    varMst: [
      '各種マスタ',
      { label: '社員一覧', column: 'menuUserListFlag' },
      { label: '協力会社一覧', column: 'menuPartnerListFlag' },
      { label: '法人一覧', column: 'menuCorporateListFlag' },
      { label: '支払条件一覧', column: 'menuPayTermListFlag' },
      { label: '名称一覧', column: 'menuNamingListFlag' },
      { label: '所得税一覧', column: 'menuIncomeTaxListFlag' },
    ],
  };

  const isSelectAll = cor => {
    let ary = checkData[cor];
    let childrenData = ary
      .map((item, index) => {
        return !index ? '' : formik?.values[item.column] ?? '';
      })
      .filter(v => v === 0 || v === 1);

    // console.log(`${cor}: `, childrenData);

    let ret = 1;

    childrenData.forEach(val => {
      if (!val) ret = 0;
    });

    return ret;
  };

  return (
    <div className="able_menu--check_boxes">
      {/* 第一列 */}
      <div className="able_menu--check_boxes--area">
        <div>
          {checkData.quoteCor.map((item, i) =>
            i === 0 ? (
              <CustomInput
                inputType={'checkBox'}
                label={item}
                inputName={item}
                key={formik.values.availableMenus ? `${item}_loaded` : item}
                initialValue={isEdit ? isSelectAll('quoteCor') : 'quoteCor'}
                style={parentStyle}
                onChange={handleChangeAll}
                disabled={isEdit && !sysAuth}
              />
            ) : (
              <CustomFormikInput
                inputType={'checkBox'}
                label={item.label}
                inputName={item.column}
                key={item.column}
                formik={formik}
                style={childStyle}
                onChange={handleChange}
                disabled={isEdit && !sysAuth}
              />
            )
          )}
        </div>
        <div>
          {checkData.matterCor.map((item, i) =>
            i === 0 ? (
              <CustomInput
                inputType={'checkBox'}
                label={item}
                inputName={item}
                key={formik.values.availableMenus ? `${item}_loaded` : item}
                initialValue={isEdit ? isSelectAll('matterCor') : 'matterCor'}
                style={parentStyle}
                onChange={handleChangeAll}
                disabled={isEdit && !sysAuth}
              />
            ) : (
              <CustomFormikInput
                inputType={'checkBox'}
                label={item.label}
                inputName={item.column}
                key={item.column}
                formik={formik}
                style={childStyle}
                onChange={handleChange}
                disabled={isEdit && !sysAuth}
              />
            )
          )}
        </div>
        <div>
          <CustomInput
            inputType={'checkBox'}
            label={checkData.invCor[0]}
            inputName={checkData.invCor[0]}
            key={
              formik.values.availableMenus
                ? `${checkData.invCor[0]}_loaded`
                : checkData.invCor[0]
            }
            initialValue={isEdit ? isSelectAll('invCor') : 'invCor'}
            style={parentStyle}
            onChange={handleChangeAll}
            disabled={isEdit && !sysAuth}
          />
          <CustomFormikInput
            inputType={'checkBox'}
            label={checkData.invCor[1].label}
            inputName={checkData.invCor[1].column}
            key={checkData.invCor[1].column}
            formik={formik}
            style={childStyle}
            onChange={handleChange}
            disabled={isEdit && !sysAuth}
          />
        </div>
      </div>

      {/* 第二列 */}
      <div className="able_menu--check_boxes--area">
        <div>
          <CustomInput
            inputType={'checkBox'}
            label={checkData.staffCor[0]}
            inputName={checkData.staffCor[0]}
            key={
              formik.values.availableMenus
                ? `${checkData.staffCor[0]}_loaded`
                : checkData.staffCor[0]
            }
            initialValue={isEdit ? isSelectAll('staffCor') : 'staffCor'}
            style={parentStyle}
            onChange={handleChangeAll}
            disabled={isEdit && !sysAuth}
          />
          <CustomFormikInput
            inputType={'checkBox'}
            label={checkData.staffCor[1].label}
            inputName={checkData.staffCor[1].column}
            key={checkData.staffCor[1].column}
            formik={formik}
            style={childStyle}
            onChange={handleChange}
            disabled={isEdit && !sysAuth}
          />
        </div>
        <div>
          <CustomInput
            inputType={'checkBox'}
            label={checkData.staffShiftCor[0]}
            inputName={checkData.staffShiftCor[0]}
            key={
              formik.values.availableMenus
                ? `${checkData.staffShiftCor[0]}_loaded`
                : checkData.staffShiftCor[0]
            }
            initialValue={isEdit ? isSelectAll('staffShiftCor') : 'staffShiftCor'}
            style={parentStyle}
            onChange={handleChangeAll}
            disabled={isEdit && !sysAuth}
          />
          <CustomFormikInput
            inputType={'checkBox'}
            label={checkData.staffShiftCor[1].label}
            inputName={checkData.staffShiftCor[1].column}
            key={checkData.staffShiftCor[1].column}
            formik={formik}
            style={childStyle}
            onChange={handleChange}
            disabled={isEdit && !sysAuth}
          />
        </div>
        <div>
          {checkData.staffPayCor.map((item, i) =>
            i === 0 ? (
              <CustomInput
                inputType={'checkBox'}
                label={item}
                inputName={item}
                key={formik.values.availableMenus ? `${item}_loaded` : item}
                initialValue={isEdit ? isSelectAll('staffPayCor') : 'staffPayCor'}
                style={parentStyle}
                onChange={handleChangeAll}
                disabled={isEdit && !sysAuth}
              />
            ) : (
              <CustomFormikInput
                inputType={'checkBox'}
                label={item.label}
                inputName={item.column}
                key={item.column}
                formik={formik}
                style={childStyle}
                onChange={handleChange}
                disabled={isEdit && !sysAuth}
              />
            )
          )}
        </div>
        <div>
          {checkData.analysis.map((item, i) =>
            item === '分析' ? (
              <CustomInput
                inputType={'checkBox'}
                label={item}
                inputName={item}
                key={formik.values.availableMenus ? `${item}_loaded` : item}
                initialValue={isEdit ? isSelectAll('analysis') : 'analysis'}
                style={parentStyle}
                onChange={handleChangeAll}
                disabled={isEdit && !sysAuth}
              />
            ) : (
              <CustomFormikInput
                inputType={'checkBox'}
                label={item.label}
                inputName={item.column}
                key={item.column}
                formik={formik}
                style={childStyle}
                onChange={handleChange}
                disabled={isEdit && !sysAuth}
              />
            )
          )}
        </div>
      </div>

      {/* 第三列 */}
      <div className="able_menu--check_boxes--area">
        <div>
          {checkData.clientManage.map((item, i) =>
            item === '取引先管理' ? (
              <CustomInput
                inputType={'checkBox'}
                label={item}
                inputName={item}
                key={formik.values.availableMenus ? `${item}_loaded` : item}
                initialValue={isEdit ? isSelectAll('clientManage') : 'clientManage'}
                style={parentStyle}
                onChange={handleChangeAll}
                disabled={isEdit && !sysAuth}
              />
            ) : (
              <CustomFormikInput
                inputType={'checkBox'}
                label={item.label}
                inputName={item.column}
                key={item.column}
                formik={formik}
                style={childStyle}
                onChange={handleChange}
                disabled={isEdit && !sysAuth}
              />
            )
          )}
        </div>
        <div>
          <CustomInput
            inputType={'checkBox'}
            label={checkData.corSet[0]}
            inputName={checkData.corSet[0]}
            key={
              formik.values.availableMenus
                ? `${checkData.corSet[0]}_loaded`
                : checkData.corSet[0]
            }
            initialValue={isEdit ? isSelectAll('corSet') : 'corSet'}
            style={parentStyle}
            onChange={handleChangeAll}
            disabled={isEdit && !sysAuth}
          />
          <CustomFormikInput
            inputType={'checkBox'}
            label={checkData.corSet[1].label}
            inputName={checkData.corSet[1].column}
            key={checkData.corSet[1].column}
            formik={formik}
            style={childStyle}
            onChange={handleChange}
            disabled={isEdit && !sysAuth}
          />
        </div>
        <div>
          {checkData.varMst.map((item, i) =>
            item === '各種マスタ' ? (
              <CustomInput
                inputType={'checkBox'}
                label={item}
                inputName={item}
                key={formik.values.availableMenus ? `${item}_loaded` : item}
                initialValue={isEdit ? isSelectAll('varMst') : 'varMst'}
                style={parentStyle}
                onChange={handleChangeAll}
                disabled={isEdit && !sysAuth}
              />
            ) : (
              <CustomFormikInput
                inputType={'checkBox'}
                label={item.label}
                inputName={item.column}
                key={item.column}
                formik={formik}
                style={childStyle}
                onChange={handleChange}
                disabled={isEdit && !sysAuth}
              />
            )
          )}
        </div>
        {/* スタッフ画面利用 */}
        <div className="corporate_register--able_menu--staff_view">
          <CustomFormikInput
            inputType="checkBox"
            label="スタッフ画面利用"
            inputName={'authStaffSystemUse'}
            key={'authStaffSystemUse'}
            formik={formik}
            onChange={handleAuthStaffCheck}
            disabled={isEdit && !sysAuth}
          />
        </div>
        {/* アンケート取得を許可する */}
        <div className="corporate_register--able_menu--survey_view">
          <CustomFormikInput
            inputType="checkBox"
            label="アンケート取得を許可する"
            inputName={'surveyFlag'}
            key={'surveyFlag'}
            formik={formik}
            onChange={handleSurveyFlagCheck}
            disabled={isEdit && !sysAuth}
          />
        </div>
      </div>
    </div>
  );
};

export default AbleMenu;
