import React, { useEffect, useState } from 'react';
import Icons from '../../../constants/icons';
import './style.scss';
import Button from '../../../components/button';
import {useLocation, useNavigate} from 'react-router-dom';
import {useFormik} from "formik";
import {
    quoteActions, quoteDocumentMail,
    quoteDocumentPdf,
    quoteDocumentUpdate
} from "../../../redux/slice/quote";
import options from "../../../constants/options";
import {globalActions} from "../../../redux/slice/global";
import {useDispatch, useSelector} from "react-redux";
import {Tabs} from "antd";
import CustomFormikInput from "../../../components/customInput/customFormikInput";
import InfoTable from "../../quote/register/quotation/quotationInfo/infoTable";
import {
    invoiceActions, invoiceDocumentEditPdf,
    invoiceDocumentInit, invoiceDocumentPdf,
    invoiceDocumentUpdate,
} from "../../../redux/slice/invoice";
import {handlerFormikFieldChange, handlerFormikSubmit} from "../../../utils/fnUtil";
import { checkIsSp } from '../../../utils/fnUtil';
import Yup from '../../../utils/yupUtil';
const { TabPane } = Tabs;
const InvoiceCreate = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const {
        invoiceDocument,
        quoteDocument,
        salesTaxList,
        pdfOutputFlag,
    } = useSelector(state => state.invoice);
    const { corporateId } = useSelector(state => state.account);
    const invoiceFormik = useFormik({
        initialValues: { ...invoiceDocument, companySeal: false},
        validateOnMount: true,
        validate: () => {
            if (invoiceFormik?.errors?.quoteBody?.length > 0) {
                invoiceFormik.errors.quoteBody = [{salesTaxId: "消費税:税率を統一してください"}]
            }
        },
        validationSchema: Yup.object({
            quoteBody: Yup.array().of(
              Yup.object({
                  salesTaxId: Yup.string().nullable().test(
                    'salesTaxId',
                    '消費税:税率を統一してください',
                    (value, context) => {
                        const salesTaxIds = invoiceFormik?.values?.quoteBody?.map(obj => obj.salesTaxId)?.filter(o => o !== '');
                        for (let i = 1; i < salesTaxIds?.length; i++) {
                            if (salesTaxIds[i] !== salesTaxIds[0]) {
                                return false;
                            }
                        }
                        return true;
                    }).label('消費税'),
              }),
            ),
        }),
        onSubmit: values => {
            const totalLine = [...new Array(3)].map((v, i) => values?.quoteBody?.length - 2 + i);
            if(pdfOutputFlag){
                dispatch(invoiceDocumentEditPdf({
                    invoiceNumber:values?.invoiceNumber,
                    invoiceDocumentDate: selectedInvoiceDate,
                    stampFlag: companySeal ? 1 : 0,
                    paymentLimitDate: values?.paymentLimitDate[
                        values?.invoiceDocumentDate?.indexOf(
                            selectedInvoiceDate
                        )],
                    payee: values?.bankingList?.find(
                        b => b?.value == values?.bankMasterId
                    )?.label,
                    invoiceBodies:values?.quoteBody.filter(q=>!totalLine.includes(q?.rowIndex)),
                    note: values?.note,
                    totalAmount: values?.quoteAmount,
                    salesTaxAmount: values?.quoteAmountTax,
                }));
                dispatch(invoiceActions.setPdfOutputFlag(false));
            }else{
                dispatch(invoiceDocumentUpdate({
                    invoiceNumber:values?.invoiceNumber,
                    note: values?.note,
                    invoiceAmount: values?.quoteAmount,
                    invoiceTotalAmount: values?.quoteAmountTotal,
                    invoiceBody:values?.multiInvoiceBody
                        .concat(values?.quoteBody.filter(q=>!totalLine.includes(q?.rowIndex))),
                    invoiceDocumentDate:values?.selectedInvoiceDate,
                })).unwrap().then((result)=>{
                    if(result.code === "0"){
                        dispatch(globalActions.showSingleModal(`更新しました`))
                        navigate('/invoice/list');
                    }
                })
            }
        },
    });
    const quoteFormik = useFormik({
        initialValues: {...quoteDocument,message:null},
        validateOnMount: true,
        onSubmit: values => {
            dispatch(quoteDocumentUpdate({
                calculationTaxFlag: values?.calculationTaxFlag,
                note: values?.note,
                quoteAmount: values?.quoteAmount,
                quoteBody:values?.quoteBody.filter(q=>!options.codes.totalLine.includes(q?.rowIndex)),
                quoteDocumentDate:values?.quoteDocumentDate,
                quoteDocumentBranchNumber:values?.quoteDocumentBranchNumber,
                quoteDocumentLimit: values?.quoteDocumentLimit,
                quoteNumber:values?.quoteNumber,
            })).unwrap().then((result)=>{
                if(result.code === "0"){
                    if(pdfOutputFlag){
                        dispatch(quoteDocumentPdf({quoteNumber: quoteNumber}));
                        dispatch(invoiceActions.setPdfOutputFlag(false));
                    } else {
                        dispatch(globalActions.showSingleModal(`更新しました`))
                        navigate('/invoice/list');
                    }
                }
            })
        },
    });
    const { quoteNumber } = quoteFormik.values;
    const { invoiceDocumentDate, selectedInvoiceDate, companySeal, quoteBody, multiInvoiceBody } = invoiceFormik.values;
    const [pageTab, setPageTab] = useState(true);
    const [viewStatus, setViewStatus] = useState(false);
    const buttonStyle = {
        registerButton: {
            width: '240px',
            height: '44px',
            border: '1px solid #0056D3',
            borderRadius: '6px',
            backgroundColor: '#0056D3',
            color: '#FFFFFF',
            fontSize: '14px',
            margin: '0 0',
            padding: '0 0',
            userSelect: 'none',
        },
        backButton: {
            width: '240px',
            height: '44px',
            borderRadius: '6px',
            backgroundColor: '#E9F2FC',
            color: '#486083',
            fontSize: '14px',
            margin: '0 0',
            padding: '0 0',
            userSelect: 'none',
        }
    }
    // 初期処理
    useEffect(()=>{
        // dispatch(globalActions.)
        location?.state?.id &&
        dispatch(invoiceDocumentInit({
            invoiceNumber: location?.state?.id,
            corporateId,
            deletedFlag: 0
        } )).unwrap()
            .then((result) => {
                invoiceFormik.setValues(result.resInvoiceDocument);
                quoteFormik.setValues(result.resQuoteDocument);
                dispatch(quoteActions.setDelete(false));
                setViewStatus(true);
            });
    },[])
    useEffect(()=>{
        if(selectedInvoiceDate){
            const invoiceTotalLine = [...new Array(3)].map(
                (v, i) => multiInvoiceBody?.filter(i=> i.invoiceDate == selectedInvoiceDate)?.length === 0
                    ? 2 + i
                    : multiInvoiceBody?.filter(i=> i.invoiceDate == selectedInvoiceDate)?.length + 1 + i
            );
            const multiLine = multiInvoiceBody?.concat(
                quoteBody?.filter(q=>q?.invoiceDate))?.filter((obj, index, self) =>
                    index === self?.findIndex((t) => (
                        t?.rowIndex === obj?.rowIndex && t?.invoiceDate === obj?.invoiceDate
                    ))
            );
            handlerFormikFieldChange(invoiceFormik,'quoteBody',
                multiInvoiceBody.filter(q=>q?.invoiceDate === selectedInvoiceDate)?.length > 0
                    ? multiInvoiceBody.filter(q=>q?.invoiceDate === selectedInvoiceDate).concat(
                        options.codes.quoteBodyTotal.map((o, i) => {
                                return { ...o, rowIndex: invoiceTotalLine[i] };
                            })
                        )
                    : options.codes.quoteBody.map((o=>{return { ...o, invoiceDate: selectedInvoiceDate}}))
                        .concat(options.codes.quoteBodyTotal.map((o, i) => {
                            return { ...o, rowIndex: invoiceTotalLine[i] };
                        })
                    ));
            handlerFormikFieldChange(invoiceFormik,'multiInvoiceBody',
                multiLine.filter(q=>q?.invoiceDate != selectedInvoiceDate)
            );
        }
    },[selectedInvoiceDate]);

  return (
    <>
        <div className="page_base--container">
              <div className="page_base--tabs">
                  {viewStatus && (<>
                  <Tabs defaultActiveKey="0"onChange={(e)=>{
                      dispatch(quoteActions.setDelete(false));
                      setPageTab(e == "0"?true:false)}}>
                      <TabPane tab="請求書" key="0" >
                          <div className={'invoice_create--area'}>
                              {invoiceDocumentDate?.length > 1 && (
                                  <CustomFormikInput
                                      formik={invoiceFormik}
                                      inputType="select"
                                      inputName="selectedInvoiceDate"
                                      initialValue={invoiceDocumentDate}
                                      selectBoxOptions={invoiceDocumentDate}
                                      placeholder="請求日を選択してください"
                                      style={{
                                          areaStyle: { width: '35%', minWidth: '250px' },
                                          labelStyle: {},
                                          inputStyle: {},
                                          buttonStyle: {},
                                      }}
                                  />
                              )}
                              <InfoTable
                                  formik={invoiceFormik}
                                  invoiceFlag={true}
                                  salesTaxList={salesTaxList}
                                  selectDate={selectedInvoiceDate}
                              />
                          </div>
                      </TabPane>
                      {quoteNumber && (
                          <TabPane tab="見積書" key="1">
                              <div className={'invoice_create--area'}>
                                  <InfoTable
                                      formik={quoteFormik}
                                      salesTaxList={salesTaxList}
                                  />
                              </div>
                          </TabPane>
                      )}
                  </Tabs>
                  </>)}
              </div>
        </div>
        {pageTab &&(
            <div className={'invoice_create--btn_area'} style={{ height: checkIsSp() ? '135px':'92px' }}>
                <div className={'invoice_create--btn'}>
                    <Button
                        text={'戻る'}
                        style={buttonStyle.backButton}
                        onClick={() => {navigate(-1)}}
                    />
                    <Button
                        text={pageTab ? '更新' : '承認申請'}
                        style={buttonStyle.registerButton}
                        onClick={() => {
                            pageTab
                                ? handlerFormikSubmit(invoiceFormik)
                                : handlerFormikSubmit(quoteFormik);
                        }}
                    />

                    <div
                      className={'invoice_create--sub_btn_area'}
                      style={pageTab ? {justifyContent: checkIsSp() ? 'flex-end' : ''} : {width: checkIsSp() ? '100%':'372px'}}
                    >
                        <div style={checkIsSp() ? {
                            width: 'calc(50% - 11.5px)',
                            textAlign: '-webkit-right'
                        } : {}}>
                            <CustomFormikInput
                              formik={invoiceFormik}
                              inputName={"companySeal"}
                              inputType="checkBox"
                              inputField={'companySeal'}
                              label="社印"
                              style={{
                                  areaStyle: {
                                      width: checkIsSp() ?'62px' : '',
                                      height: '36px',
                                      padding: '15px 5px',
                                      backgroundColor: '#E0E0E0',
                                      borderRadius: '6px',
                                  },
                              }}
                            />
                        </div>
                        <Button
                            text={'PDF表示'}
                            icon={Icons.icon.navyDocument}
                            style={{
                                backgroundColor: '#FFFFFF',
                                color: 'black',
                                width: checkIsSp() ? 'calc(50% - 11.5px)' : '140px',
                                height: '36px',
                                margin: '0',
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: '6px',
                            }}
                            onClick={()=>{
                                dispatch(invoiceActions.setPdfOutputFlag(true));
                                handlerFormikSubmit(invoiceFormik);
                            }}
                        />
                        {!pageTab &&
                            <Button
                                text={'メール送信'}
                                icon={Icons.icon.mailNv}
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    color: 'black',
                                    width: '140px',
                                    height: '36px',
                                    margin: '0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: '6px',
                                }}
                                onClick={()=>{dispatch(quoteDocumentMail({quoteNumber: quoteNumber}))}}
                            />
                        }
                    </div>
                </div>
            </div>
        )}
    </>
  );
};

export default InvoiceCreate;
