import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  quoteDocumentInit,
  quoteDocumentStampRequest,
} from '../../../redux/slice/quote';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import { CheckOutlined } from '@ant-design/icons';
import ModalBase from '../modalBase';
import CustomInput from '../../../components/customInput';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import Icons from '../../../constants/icons';
import './style.scss';
import { dispatchError } from '../../../utils/fnUtil';
import { checkIsSp } from '../../../utils/fnUtil';
import CustomFormikInput from "../../customInput/customFormikInput";

const ApprovalApplyModal = ({ modalVisible, changeModalVisible, onOk= false ,formik = false }) => {
  const dispatch = useDispatch();
  const { quoteNumberId, quoteDocument } = useSelector(state => state.quote);
  const { approvalSteps } = quoteDocument;

  const denialIcon = (
    <div className="info--step" style={{ borderColor: '#D80245' }}>
      <span>
        <img src={Icons.icon.closeRd} alt="close" style={{ width: '32px' }} />
      </span>
    </div>
  );
  const approvalIcon = (
    <div className="info--step">
      <span>
        <CheckOutlined style={{ color: '#A0AAB8', fontSize: '18px' }} />
      </span>
    </div>
  );

  const approvalClick = () => {
    try {
      dispatch(
        quoteDocumentStampRequest({
          quoteNumber: quoteNumberId,
          message: document.getElementById('note').value,
        })
      ).then(() => {
        store.dispatch(globalActions.showSingleModal('承認申請しました'));
        changeModalVisible();
      });
    } catch (error) {
      dispatchError('通信に失敗しました\r\n通信環境の良い場所で再度お試しください');
    }
  };

  if (modalVisible) {
    return (
      <ModalBase
        dialogStyle={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: checkIsSp() ? '32px 16px' : '0 40px',
          width: checkIsSp() ? 'calc(100% - 64px)' : '1632px',
          height: checkIsSp() ? 'auto' : '799px',
        }}
        modalVisible={true}
      >
        <div className="approval_apply--main">
          <div>
            <div className="import_modal--title_area">
              <p>捺印申請</p>
            </div>
            <div>
              書類の捺印を申請します。承認されると、捺印済みの書類が出力できるようになります。
            </div>
          </div>

          <div className="approval_apply--steps--area">
            {approvalSteps.map((item, i) => {
              return (
                <div
                  className={
                    item.status === 2
                      ? 'info--step--divs step_active'
                      : 'info--step--divs step_wait'
                  }
                  key={i}
                >
                  <div className="info--step--area">
                    {item.status === 2 ? (
                      denialIcon
                    ) : item.status === 3 ? (
                      approvalIcon
                    ) : (
                      <div
                        className="info--step"
                        style={{ borderColor: '#BCBCBC' }}
                      >
                        <span>{i + 1}</span>
                      </div>
                    )}
                    <div className="info--step--title">
                      <span>{`${item.approver}`}</span>
                      {item.approverCount > 1 ? (
                        <span>{`他${item.approverCount - 1}名`}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="approval_apply--main--message">
            <span className="approval_apply--main--message--span">
              メッセージ
            </span>
            <div
              className="approval_apply--main--message--box"
              style={{ width: '100%' }}
            >
              {formik ?(
                  <CustomFormikInput
                      formik={formik}
                      inputType="textBox"
                      label=""
                      inputName="message"
                      placeholder="メッセージを入力してください"
                  />
              ):(
                  <CustomInput
                      inputType="textBox"
                      label=""
                      inputName="note"
                      placeholder="メッセージを入力してください"
                  />
              )}
            </div>
          </div>

          <div className="all_bank_modal--btndiv">
            <Button
              text={'戻る'}
              style={Options.buttonStyles.stampBack}
              onClick={changeModalVisible}
            />
            <Button
              text={'承認申請'}
              style={Options.buttonStyles.stampApproval}
              onClick={onOk ? onOk : approvalClick}
            />
          </div>
        </div>
      </ModalBase>
    );
  } else {
    return <></>;
  }
};

export default ApprovalApplyModal;
