import { Router, useNavigate } from 'react-router-dom';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Copyright from '../../../components/copyright';
import Options from '../../../constants/options';
import { useState, useEffect } from 'react';
import CsvImportModal from '../../../components/modal/csvImportModal';
import CsvExportModal from '../../../components/modal/csvExportModal';
import './style.scss';
import {
  namingActions,
  namingCategoryInit,
  namingDelete,
  namingFetch,
  namingInit,
} from '../../../redux/slice/naming';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { matterActions } from '../../../redux/slice/matter';
import { checkIsSp } from '../../../utils/fnUtil';

const NameList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    namingCategoryList,
    namingList,
    searchParams,
    csvImportVisible,
    csvExportVisible,
  } = useSelector(state => state.naming);
  const NamingInfos = {
    listSearchArea: {
      inputs: [
        {
          inputType: 'select',
          showSearch: true,
          mode: 'multiple',
          allowClear: false,
          label: '名称区分',
          inputName: 'namingClassificationId',
          initialValue: namingCategoryList.map(n => n?.namingId),
          selectBoxOptions: namingCategoryList.map(
            n => `${n?.namingId}:${n?.naming}`
          ),
          placeholder: '名称区分を選択してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: checkIsSp() ? '100%' : '300px' },
          },
        },
        {
          inputType: 'input',
          label: '名称',
          inputName: 'naming',
          initialValue: '',
          placeholder: '名称を入力してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: checkIsSp() ? '100%' : '400px' },
          },
        },
        {
          inputType: 'select',
          showSearch: false,
          allowClear: false,
          label: 'ステータス',
          inputName: 'status',
          initialValue: [99, 1, 2],
          selectedSelectBox: 99,
          selectBoxOptions: ['すべて', '表示', '削除済'],
          placeholder: 'ステータスを選択してください',
          className: {
            areaClass: 'column',
          },
          style: {
            areaStyle: { width: checkIsSp() ? '100%' : '150px' },
          },
        },
      ],
    },
  };
  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => dispatch(namingActions.saveSearchParams(values)),
  });

  const { sortKey: sortColumn, sortMethod } = formik.values;
  useEffect(() => {
    dispatch(namingFetch(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(namingCategoryInit());
    return () => {
      dispatch(namingActions.saveParam());
      dispatch(namingActions.refresh());
    };
  }, []);

  const namingListSearchArea = () => (
    <div className={'naming_list--search_area'}>
      <div className={'naming_list--input_area'}>
        {NamingInfos.listSearchArea.inputs.map((info, idx) => (
          <CustomFormikInput {...info} formik={formik} key={info.inputName} />
        ))}
      </div>
    </div>
  );

  //ユーザーがお気に入りしたcsv出力設定(これをpropsでモーダルコンポーネントに渡す予定)
  const csvExportUserSettings = {
    ['名称区分']: true,
    ['表示優先順']: false,
    ['新規登録者名']: true,
    ['名称区分名']: false,
    ['ステータス']: true,
    ['更新日']: false,
    ['名称']: true,
    ['備考']: true,
    ['更新者名']: false,
    ['名称(カナ)']: true,
    ['新規登録日時']: false,
  };

  const searchFormInfo = {
    jsx: namingListSearchArea,
  };
  //
  const columnSettings = {
    namingClassificationIdLabel: {
      label: '名称区分',
      sort: 'none',
      dataName: 'namingClassificationId',
    },
    namingLabel: { label: '名称', sort: 'none', dataName: 'naming' },
    namingKana: { label: '名称(カナ)', sort: 'none', dataName: 'namingKana' },
    priority: { label: '表示優先順', sort: 'none', dataName: 'priority' },
    status: { label: 'ステータス', sort: 'none', dataName: 'status' },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    import: {
      icon: Icons.icon.import,
      text: 'CSV取込',
      onClick: () => dispatch(namingActions.setCsvImportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => dispatch(namingActions.setCsvExportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    addButton: {
      props: {
        type: '', //roundを指定するとボタンがまるくなる
        onClick: () => {
          navigate('/master/naming/register');
        }, //ボタンをクリックしたときにfunctionが発火する（urlより優先される）
        url: '', //ボタンをクリックした時の遷移先を指定する「'/main/invoice/list'」
        displayText: true, // trueを指定すると「新規追加」吹き出しが表示される
        disabled: '', //trueを指定するとdisableになる
        // style: {backgroundColor: '#FFFFFF'}, //styleを指定することが可能になる
      },
    },
  };

  const changeSort = e => {
    dispatch(
      namingActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`,
      })
    );
  };
  return (
    <>
      <div className={'commonPage--base naming_list_page'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'namingId'}
          primaryName={'namingLabel'}
          apiData={namingList}
          sortSettings={{ sortColumn, sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(
              namingActions.saveSearchParams({
                count,
              })
            )
          }
          onChangeOffset={offset => dispatch(namingActions.saveOffset(offset))}
          onDelete={namingId => {
            dispatch(
              namingDelete({
                namingId,
                namingClassificationId: namingList?.item?.find(
                  n => n.namingId === namingId
                )?.namingClassificationId,
              })
            );
          }}
        />
        <Copyright />
      </div>

      <CsvImportModal
        importType={'namingList'}
        csvImportVisible={csvImportVisible}
        setCsvImportVisible={() =>
          dispatch(namingActions.setCsvImportVisible(!csvImportVisible))
        }
      />
      <CsvExportModal
        exportType={'namingList'}
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={() =>
          dispatch(namingActions.setCsvExportVisible(!csvExportVisible))
        }
        exportFunction={() => {}}
        searchParams={searchParams}
      />
    </>
  );
};

export default NameList;
