import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import { globalActions } from './global';

const initialSearchParams = {
  recruitmentDateFrom: '',
  recruitmentDateTo: '',
  assignAccountFlag: [],
  matterName: '',
  venueId: null,
  matterManagerUserId: [],
  salesManagerUserId: [],
  matterCategory: null,
  prefCode: null,
  status: null,
  offset: 0,
  count: 20,
  sortKey: 'open_date',
  sortMethod: 'Desc',
};

const initialState = {
  searchParams: initialSearchParams,
  staffRecruitMatterList: [],
  venueList: [],
  userList: [],
  matterCategoryList: [],
  matterList: [],
  matterDateList: [],
  matterShiftList: [],
  staffRecruitMatterDetail: null,
  holdingItemList: [],
  loading: false,
  searchCount: 0,
};

export const staffRecruitMatterInit = createAsyncThunk(
  'matter/staff/recruit/init',
  async () => {
    const [venueRes, userRes, categoryRes] = await fetchMultiRequest([
      fetchRequest(apiUrl.venue.list, { status: 1 }),
      fetchRequest(apiUrl.user.list, { status: 1 }),
      fetchRequest(apiUrl.naming.list, {
        namingClassificationId: 'PromotionCategory',
        status: 1,
      }),
    ]);

    return {
      venueList: venueRes?.item ?? [],
      userList: userRes?.item ?? [],
      matterCategoryList: categoryRes?.item ?? [],
    };
  }
);

export const fetchStaffRecruitMatterList = createAsyncThunk(
  'matter/staff/recruit/list',
  async params => await fetchRequest(apiUrl.staffRecruitMatter.list, params)
);

export const staffRecruitMatterDetailInit = createAsyncThunk(
  'matter/staff/recruit/detail/init',
  async params => {
    const [matterRes, namingRes] = await fetchMultiRequest([
      fetchRequest(apiUrl.matter.list, params),
      fetchRequest(apiUrl.naming.list, {
        namingClassificationId: 'Holdingitem',
        status: 1,
      }),
    ]);

    return {
      matterList: matterRes?.item ?? [],
      holdingItemList: namingRes?.item ?? [],
    };
  }
);

export const fetchMatterDate = createAsyncThunk(
  'matter/staff/recruit/date/list',
  async params => await fetchRequest(apiUrl.matterDate.list, params)
);

export const fetchMatterShift = createAsyncThunk(
  'matter/staff/recruit/shift/list',
  async params => await fetchRequest(apiUrl.matterDate.assignDetail, params)
);

export const fetchStaffRecruitMatterDetail = createAsyncThunk(
  'matter/staff/recruit/detail',
  async params => await fetchRequest(apiUrl.staffRecruitMatter.detail, params)
);

export const updateStaffRecruitMatter = createAsyncThunk(
  'matter/recruit/update',
  async (params, { dispatch }) => {
    await fetchRequest(
      apiUrl.staffRecruitMatter[params.updateType],
      params,
      'POST'
    );

    dispatch(
      globalActions.showSingleModal(
        `${params.updateType === 'update' ? '更新' : '登録'}しました`
      )
    );
  }
);

export const deleteStaffRecruitMatter = createAsyncThunk(
  'matter/recruit/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.staffRecruitMatter.delete, params, 'POST');

    dispatch(staffRecruitMatterActions.refresh());
  }
);

export const staffRecruitMatterSlice = createSlice({
  name: 'staffRecruitMatter',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    clearDetail: state => {
      state.staffRecruitMatterDetail = null;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [staffRecruitMatterInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffRecruitMatterDetailInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchStaffRecruitMatterDetail.fulfilled]: (state, { payload }) => {
      state.staffRecruitMatterDetail = payload.item;
    },
    [fetchStaffRecruitMatterList.fulfilled]: (state, { payload }) => {
      state.staffRecruitMatterList = payload.item ?? [];
      state.searchCount = payload.total ?? 0;
    },
    [fetchMatterDate.fulfilled]: (state, { payload }) => {
      state.matterDateList = payload.item ?? [];
    },
    [fetchMatterShift.fulfilled]: (state, { payload }) => {
      state.matterShiftList = payload.item?.shift ?? [];
    },
  },
});

export const staffRecruitMatterActions = staffRecruitMatterSlice.actions;
export default staffRecruitMatterSlice.reducer;
