import { memo } from 'react';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import SwitchInput from '../../../../components/switch/switchInput';
import Options from '../../../../constants/options';
import './style.scss';

const GuestAttribute = memo(({ formik }) => {
  const percentOption = [...Array(101)].map((_, i) => i);

  return (
    <div className="tab_content customer_attribute">
      <SwitchInput
        item="総人数"
        formik={formik}
        reportInputName="guestAttribute.reportTotalFlag"
        staffInputName="guestAttribute.staffTotalFlag"
        toggleSize={'default'}
        inputComponent={
          <CustomFormikInput
            formik={formik}
            inputName="guestAttribute.totalNumber"
            placeholder="0"
            style={{
              inputStyle: {
                width: 160,
              },
            }}
            unit="人"
          />
        }
      />
      <SwitchInput
        item="男女比"
        formik={formik}
        reportInputName="guestAttribute.reportGenderRatioFlag"
        staffInputName="guestAttribute.staffGenderRatioFlag"
        toggleSize={'default'}
        inputComponent={
          <div className="customer_attribute--inputs">
            <CustomFormikInput
              label="男性"
              formik={formik}
              inputName="guestAttribute.manRate"
              inputType="select"
              initialValue={percentOption}
              selectBoxOptions={percentOption}
              placeholder="00"
              otherErrorInputName={['guestAttribute.manWomanRateTotal']}
              unit="%"
              style={{
                areaStyle: {
                  width: 163,
                  marginRight: 70,
                },
              }}
            />
            <CustomFormikInput
              label="女性"
              formik={formik}
              inputName="guestAttribute.womanRate"
              inputType="select"
              initialValue={percentOption}
              selectBoxOptions={percentOption}
              otherErrorInputName={['guestAttribute.manWomanRateTotal']}
              placeholder="00"
              unit="%"
              style={{
                areaStyle: {
                  width: 163,
                },
              }}
            />
          </div>
        }
      />
      <SwitchInput
        item="客層比"
        formik={formik}
        reportInputName="guestAttribute.reportGuestFlag"
        staffInputName="guestAttribute.staffGuestFlag"
        toggleSize={'default'}
        inputComponent={
          <div className="customer_attribute--inputs">
            {Options.codes.guestGroup.map(option => (
              <CustomFormikInput
                key={option.label}
                formik={formik}
                label={option.label}
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                placeholder="00"
                unit="%"
                inputName={option.inputName}
                otherErrorInputName={['guestAttribute.segmentRateTotal']}
                style={{
                  areaStyle: {
                    width: 163,
                    marginRight: 70,
                  },
                }}
              />
            ))}
          </div>
        }
      />
      <SwitchInput
        item="年代別客層"
        formik={formik}
        reportInputName="guestAttribute.reportAgeGroupFlag"
        staffInputName="guestAttribute.staffAgeGroupFlag"
        toggleSize={'default'}
        inputComponent={
          <div className="customer_attribute--inputs customer_attribute--inputs_flexwrap">
            {Options.codes.ageGroup.map(option => (
              <CustomFormikInput
                key={option.label}
                formik={formik}
                label={option.label}
                inputType="select"
                initialValue={percentOption}
                selectBoxOptions={percentOption}
                placeholder="00"
                unit="%"
                inputName={option.inputName}
                otherErrorInputName={['guestAttribute.ageRateTotal']}
                style={{
                  labelStyle: {
                    textAlign: 'right',
                    minWidth: 80,
                  },
                  areaStyle: {
                    width: 205,
                  },
                }}
              />
            ))}
          </div>
        }
      />
    </div>
  );
});

export default GuestAttribute;
