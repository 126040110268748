import { notification } from 'antd';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { globalActions } from '../../redux/slice/global';

const Lisener = memo(() => {
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalActions.changeActiveTab(''));
    notification.destroy();
  }, [location]);

  return <></>;
});

export default Lisener;
