import { Pie } from '@ant-design/plots';
import React, { memo } from 'react';

const PieGraph = memo(({data, color}) => {

  const config = {
    legend: false,
    tooltip: false,
    data,
    angleField: 'rate',
    colorField: 'answer',
    radius: 0.9,
    pieStyle: {
      lineWidth: 0,
    },
    label: {
      type: 'inner',
      offset: '-40%',
      rotate: 0,
      autoRotate: false,
      formatter: (text) => {
        if (text?.rate !== 0) {
          return `${text?.rate?.toFixed(0)}%`
        }
      },
      style: {
        fontSize: 12,
        textAlign: 'center',
        fontWeight: 'bold',
      },
    },
  };

  return <Pie height={210} {...config} color={color} />;
});

export default PieGraph;