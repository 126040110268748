import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import Copyright from '../../../components/copyright';
import AddButton from '../../../components/addButton';
import DeleteModal from '../../../components/modal/deleteModal';
import Icons from '../../../constants/icons';
import './style.scss';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import { payTermsDelete, payTermsInit } from '../../../redux/slice/payTerm';
import { globalActions } from '../../../redux/slice/global';
import { fetchApi, fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { checkIsSp } from '../../../utils/fnUtil';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#838a91',
      fontSize: '18px',
    }}
  />
));

const { Column } = Table;
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const PayTermList = () => {
  const isSp = checkIsSp();
  const dispatch = useDispatch();
  const accountInfo = useSelector(state => state.account);

  const { payTermsList } = useSelector(state => state.payTerms);

  useEffect(() => {
    dispatch(payTermsInit());
    const handleAddButton = document.getElementsByClassName('add_button--text');

    if (!isSp) {
      handleAddButton[0].innerHTML = '新規登録';
    }
  }, []);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setDataSource(payTermsList);
  }, [payTermsList]);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      await setDataSource(newData);
      let postPayTermsId = [];
      newData.forEach(obj => {
        postPayTermsId.push(obj.payTermsId);
      });
      await fetchRequest(
        apiUrl.payment.multiUpdate,
        { userId: accountInfo.userId, payTermsId: postPayTermsId },
        'POST'
      );
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      x => x.priority === Number(restProps['data-row-key'])
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const [deleteKye, setDeleteKye] = useState('');
  const setDeleteInfo = (name, key) => {
    setDeleteName(name);
    setDeleteKye(key);
    toggleDeleteModal();
  };
  const toggleDeleteModal = () => {
    setDeleteModalVisible(!deleteModalVisible);
  };

  return (
    <>
      <div
        className="pay_list"
        style={{
          height: isSp ? 'fit-content' : 'auto',
          marginBottom: isSp ? '0' : '109px',
        }}
      >
        <div
          className={'commonPage--base'}
          style={isSp ? { paddingBottom: '0' } : {}}
        >
          <div className="pay_list--add--btn">
            <AddButton
              url="/master/pay-term/register"
              displayText={true}
              style={isSp ? { width: '92%', height: '40px', margin: '0 auto' } : {}}
              spListViewMode={isSp}
            />
          </div>
        </div>
        {!isSp && <Copyright />}
        <div className="pay_list--main_table">
          <Table
            pagination={false}
            dataSource={dataSource}
            rowKey="priority"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          >
            <Column
              title="並替"
              dataIndex="payTermsId"
              key="payTermsId"
              width={176}
              className="pay_list--main_table--column"
              render={() => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '39px',
                      padding: '0 7px',
                    }}
                    className="pay_list--main_table--column--order"
                  >
                    <DragHandle />
                    <p
                      style={{
                        color: '#28D300',
                        margin: '0',
                        visibility: 'hidden',
                        fontWeight: 'bold',
                        width: '64px',
                      }}
                    >
                      優先表示
                    </p>
                  </div>
                </>
              )}
            />
            <Column
              title="支払条件名"
              dataIndex="payTermsName"
              key="payTermName"
              width={457}
              className="pay_list--main_table--column"
            />
            <Column
              title="支払方法区分"
              dataIndex="payCategoryName"
              key="payCategoryName"
              className="pay_list--main_table--column"
            />
            <Column
              title="締日"
              dataIndex="closingDateName"
              key="closingDateName"
              className="pay_list--main_table--column"
            />
            <Column
              title="支払期限"
              // dataIndex= 'limit_month/limit_day'
              dataIndex="payPeriodDayName"
              key="payPeriodDayName"
              className="pay_list--main_table--column"
            />
            <Column
              title="編集"
              key="edit"
              width={68}
              className="pay_list--main_table--edit"
              render={(_, record) => (
                <img
                  src={Icons.icon.editSNv}
                  alt="edit"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate('/master/pay-term/edit', {
                      state: { id: record.payTermsId },
                    });
                  }}
                />
              )}
            />
            <Column
              title="削除"
              key="del"
              width={68}
              className="pay_list--main_table--del"
              render={(_, record) => {
                if (record.deleteIconState) {
                  return (
                    <img
                      src={Icons.icon.deleteSNv}
                      alt="delete"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        setDeleteInfo(
                          record.payTermsName,
                          Number(record.payTermsId)
                        )
                      }
                    />
                  );
                } else {
                  return <img src={Icons.icon.deleteSGr} alt="delete" />;
                }
              }}
            />
          </Table>
        </div>

        {isSp && <Copyright style={{ zIndex: '0', position: 'relative' }} />}

        <DeleteModal
          deleteName={deleteName}
          deleteKey={deleteKye}
          deleteFunction={key => {
            dispatch(payTermsDelete({ payTermsId: key })).then(() => {
              dispatch(globalActions.showSingleModal(`削除しました`));
              dispatch(payTermsInit());
            });
            setDeleteModalVisible(!deleteModalVisible);
          }}
          deleteModalVisible={deleteModalVisible}
          toggleVisible={toggleDeleteModal}
          displayNotice={false}
          disableKey={true}
        />
      </div>
    </>
  );
};

export default PayTermList;
