import './style.scss';
import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import moment from 'moment';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  checkIsSp,
  convertDatetime,
  handlerFormikSubmit
} from '../../../utils/fnUtil';
import { useSelector, useDispatch } from 'react-redux';
import { globalActions } from '../../../redux/slice/global';
import { useLocation } from 'react-router-dom';
import {
  staffPayDetailFetch,
  staffPayUpdateFetch,
} from '../../../redux/slice/staffPay';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Button from '../../../components/button';
import StaffPayApprovalForSp from './forSp';

const StaffPayApproval = () => {
  const loginType = useSelector(state => state.loginType.type);
  const staffId = useSelector(state => state.account.staffId);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpenSideMenu } = useSelector(state => state.global);

  // 年月
  const yearMonth = {
    year: location?.state?.year,
    month: location?.state?.month,
  };

  const formik = useFormik({
    initialValues: {
      staffPayApproval: {
        netPayAmount: 0,
        payTotalAmount: 0,
        advancePayTotalAmount: 0,
        incomeTaxAmount: 0,
        beforePayTotalAmount: 0,
        payList: [],
      },
      staffApproval: '3',
      staffNote: '',
      showButton: true,
    },
    onSubmit: values => {
      let val = {
        year: yearMonth.year,
        month: yearMonth.month,
        staffId: staffId,
        mailFlag: values.sendMailFlag,
        staffApproval: Number(values.staffApproval),
        staffNote: values.staffNote,
      };
      dispatch(staffPayUpdateFetch(val))
        .unwrap()
        .then(() => {
          dispatch(globalActions.showSingleModal('送信しました'));
          navigate('/');
        });
    },
  });

  const { staffApproval, staffNote, showButton } = formik.values;

  const tableTitle = ['総支給予定額', '給与手当', '立替金', '所得税', '前払金'];

  const tableDiligenceTitle = [
    '案件名',
    '勤務時間',
    '支給',
    '基本給',
    '残業',
    '遅刻・早退',
    '手当',
    '立替金',
    'その他',
    '前払金',
    '合計',
  ];

  const getWeek = (year, month, day) => {
    const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
    let targetDay = new Date(year, month - 1, day);

    return weekDay[targetDay.getDay()];
  };

  const setFormikData = data => {
    let formatData = {
      ...data,
      staffPayApproval: data?.staffPayEditList?.staffPayDetail,
      sendMailFlag: data?.staffPayEditList?.staffPayDetail?.sendMailFlag ?? 0,
      staffApproval:
        String(data?.staffPayEditList?.staffPayDetail?.staffApproval) ?? '3',
      staffNote: data?.staffPayEditList?.staffPayDetail?.staffNote ?? '',
      showButton: data?.staffPayEditList?.staffPayDetail?.staffApproval !== 3,
    };

    formik.setValues(formatData);
  };

  useEffect(() => {
    dispatch(
      staffPayDetailFetch({
        staffId: staffId,
        year: yearMonth.year,
        month: yearMonth.month,
      })
    )
      .unwrap()
      .then(data => {
        setFormikData(data);
      });
  }, []);

  const payApproval = () => (
    <div>
      <div className={'staff_pay_approval--body'}>
        {/*表題（○年○月度） */}
        <div className={'staff_pay_approval--title'}>
          {yearMonth.year}
          {'年'}
          {yearMonth.month}
          {'月度'}
        </div>
        <div className={'staff_pay_approval--sub_title'}>{'給与'}</div>
        <div className={'staff_pay_approval--pay_detail'}>
          {/*総支給額のエリア*/}
          <div className={'staff_pay_approval--item_box'}>
            <div className={'staff_pay_approval--item_box_title'}>
              {tableTitle[0]}
            </div>
            <div
              className={
                'staff_pay_approval--item_box_contents staff_pay_approval--solo_contents'
              }
              style={{
                font: 'normal normal bold 16px/20px Noto Sans CJK JP',
              }}
            >
              {formik?.values?.staffPayApproval?.netPayAmount
                ? formik?.values?.staffPayApproval?.netPayAmount.toLocaleString()
                : '0'}
              円
            </div>
          </div>

          {/*総支給額以外のエリア*/}
          <div className={'staff_pay_approval--item_box_unity'}>
            <div className={'staff_pay_approval--item_box_left'}>
              <div className={'staff_pay_approval--item_box_title'}>
                {tableTitle[1]}
              </div>

              <div className={'staff_pay_approval--item_box_contents'}>
                {formik?.values?.staffPayApproval?.payTotalAmount
                  ? formik?.values?.staffPayApproval?.payTotalAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>

            <div className={'staff_pay_approval--item_box_center'}>
              <div className={'staff_pay_approval--item_box_title'}>
                {tableTitle[2]}
              </div>

              <div className={'staff_pay_approval--item_box_contents'}>
                <div
                  className={'staff_pay_approval--item_box_center_item'}
                  style={{ borderRight: '1px solid #A0AAB8' }}
                >
                  {formik?.values?.staffPayApproval?.advancePayTotalAmount
                    ? formik?.values?.staffPayApproval?.advancePayTotalAmount.toLocaleString()
                    : '0'}
                  円
                </div>
              </div>
            </div>

            <div className={'staff_pay_approval--item_box_center'}>
              <div className={'staff_pay_approval--item_box_title'}>
                {tableTitle[3]}
              </div>

              <div
                className={'staff_pay_approval--item_box_contents'}
                style={{ borderRight: '1px solid #A0AAB8' }}
              >
                <div
                  className={'staff_pay_approval--item_box_center_item'}
                  style={{ borderLeft: '1px solid #A0AAB8' }}
                >
                  {formik?.values?.staffPayApproval?.incomeTaxAmount
                    ? formik?.values?.staffPayApproval?.incomeTaxAmount.toLocaleString()
                    : '0'}
                  円
                </div>
              </div>
            </div>

            <div className={'staff_pay_approval--item_box_right'}>
              <div className={'staff_pay_approval--item_box_title'}>
                {tableTitle[4]}
              </div>

              <div className={'staff_pay_approval--item_box_contents'}>
                {formik?.values?.staffPayApproval?.beforePayTotalAmount
                  ? formik?.values?.staffPayApproval?.beforePayTotalAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
          </div>
        </div>

        {/* 勤怠エリア*/}
        <div className={'staff_pay_approval--sub_title'}>{'勤怠'}</div>

        {formik?.values?.staffPayApproval?.payList?.map((payList, idx) => {
          return (
            <table className={'staff_pay_approval--table'} key={idx}>
              <thead>
                <tr>
                  <td
                    colSpan="9"
                    className={'staff_pay_approval--table_caption'}
                  >
                    {payList?.matterDate?.split('/')[1]}月
                    {payList?.matterDate?.split('/')[2]}
                    日（
                    {getWeek(
                      payList?.matterDate?.split('/')[0],
                      payList?.matterDate?.split('/')[1],
                      payList?.matterDate?.split('/')[2]
                    )}
                    ）
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={'staff_pay_approval--table_cell_title'}
                    style={{ height: '40px', width: '11%' }}
                  >
                    {tableDiligenceTitle[0]}
                  </td>
                  <td
                    colSpan="8"
                    className={'staff_pay_approval--table_cell_colum_detail'}
                  >
                    {payList?.matterName}
                  </td>
                </tr>
                <tr>
                  <td
                    className={'staff_pay_approval--table_cell_title'}
                    style={{ height: '40px' }}
                  >
                    {tableDiligenceTitle[1]}
                  </td>
                  <td
                    colSpan="8"
                    className={'staff_pay_approval--table_cell_colum_detail'}
                  >
                    {payList?.punchInDatetime && convertDatetime(payList?.punchInDatetime)?.split(' ')[1]}
                    {'~'}
                    {payList?.punchOutDatetime && convertDatetime(payList?.punchOutDatetime)?.split(' ')[1]}
                    {' （遅刻:'}
                    {payList?.payCutTime ?? 0}
                    {'分、残業:'}
                    {payList?.overtime ?? 0}
                    {'分）'}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td
                    rowSpan={'2'}
                    className={'staff_pay_approval--table_cell_title'}
                    style={{ height: '60px' }}
                  >
                    {tableDiligenceTitle[2]}
                  </td>
                  <td
                    className={'staff_pay_approval--table_cell_title_column'}
                    style={{ height: '25px', width: '10%' }}
                  >
                    {tableDiligenceTitle[3]}
                  </td>
                  <td
                    className={'staff_pay_approval--table_cell_title_column'}
                    style={{ height: '25px', width: '10%' }}
                  >
                    {tableDiligenceTitle[4]}
                  </td>
                  <td
                    className={'staff_pay_approval--table_cell_title_column'}
                    style={{ height: '25px', width: '10%' }}
                  >
                    {tableDiligenceTitle[5]}
                  </td>
                  <td
                    className={'staff_pay_approval--table_cell_title_column'}
                    style={{ height: '25px', width: '10%' }}
                  >
                    {tableDiligenceTitle[6]}
                  </td>
                  <td
                    className={'staff_pay_approval--table_cell_title_column'}
                    style={{ height: '25px', width: '10%' }}
                  >
                    {tableDiligenceTitle[7]}
                  </td>
                  <td
                    className={'staff_pay_approval--table_cell_title_column'}
                    style={{ height: '25px', width: '10%' }}
                  >
                    {tableDiligenceTitle[8]}
                  </td>
                  <td
                    className={'staff_pay_approval--table_cell_title_column'}
                    style={{ height: '25px', width: '10%' }}
                  >
                    {tableDiligenceTitle[9]}
                  </td>
                  <td
                    className={'staff_pay_approval--table_cell_title_column'}
                    style={{ height: '25px', width: '19%' }}
                  >
                    {tableDiligenceTitle[10]}
                  </td>
                </tr>
                <tr>
                  <td className={'staff_pay_approval--table_cell_column'}>
                    {payList?.payBaseAmount
                      ? payList?.payBaseAmount?.toLocaleString()
                      : '0'}
                    円
                  </td>
                  <td className={'staff_pay_approval--table_cell_column'}>
                    {payList?.overtimePayAmount
                      ? payList?.overtimePayAmount?.toLocaleString()
                      : '0'}
                    円
                  </td>
                  <td className={'staff_pay_approval--table_cell_column'}>
                    {payList?.payCutAmount
                      ? payList?.payCutAmount?.toLocaleString()
                      : '0'}
                    円
                  </td>
                  <td className={'staff_pay_approval--table_cell_column'}>
                    {payList?.allowanceAmount
                      ? payList?.allowanceAmount?.toLocaleString()
                      : '0'}
                    円
                  </td>
                  <td className={'staff_pay_approval--table_cell_column'}>
                    {payList?.advanceAmount
                      ? payList?.advanceAmount?.toLocaleString()
                      : '0'}
                    円
                  </td>
                  <td className={'staff_pay_approval--table_cell_column'}>
                    {payList?.othersAmount
                      ? payList?.othersAmount?.toLocaleString()
                      : '0'}
                    円
                  </td>
                  <td className={'staff_pay_approval--table_cell_column'}>
                    {payList?.beforePayAmount
                      ? payList?.beforePayAmount?.toLocaleString()
                      : '0'}
                    円
                  </td>
                  <td className={'staff_pay_approval--table_cell_column'}>
                    {payList?.totalAmount
                      ? payList?.totalAmount?.toLocaleString()
                      : '0'}
                    円
                  </td>
                </tr>
              </tfoot>
            </table>
          );
        })}

        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <td>
                <CustomFormikInput
                  formik={formik}
                  inputType="radioButton"
                  inputName="staffApproval"
                  radioButtonLabelAndChecked={[
                    {
                      label: '上記内容に承認します',
                      checked: staffApproval === '3',
                    },
                    {
                      label: '上記内容に誤りがあります',
                      checked: staffApproval === '2',
                    },
                  ]}
                  initialValue={['3', '2']}
                />
              </td>
              <td
                style={{
                  width: '60%',
                }}
              >
                <CustomFormikInput
                  formik={formik}
                  inputName="staffNote"
                  placeholder={'誤りがある場合は、該当箇所を記載してください'}
                />
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );

  //送信ボタン設定
  const buttonStyle = {
    registerButton: {
      width: '240px',
      height: '44px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    backButton: {
      width: '240px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
  };

  const myPageInfos = {
    detail: {
      tabs: {
        payApproval: {
          jsx: payApproval,
        },
      },
    },
  };

  //要素を修正する処理
  useEffect(() => {
    let targetElm = document.getElementsByClassName('register_base--container');
    let targetNoteSpan = document.getElementsByClassName(
      'register_base--container_notice'
    );
    let targetRegisterBase = document.getElementsByClassName(
      'register_base--container'
    );
    let targetTabLine = document.getElementsByClassName('ant-tabs-nav');

    Array.from(targetNoteSpan).forEach(elm => {
      elm.remove();
    });

    Array.from(targetElm).forEach(elm => {
      elm.style.margin = '40px auto 0';
    });

    Array.from(targetRegisterBase).forEach(elm => {
      elm.style.marginBottom = '113px';
    });

    Array.from(targetTabLine).forEach(elm => {
      elm.remove();
    });
  });

  return (
    <>
      {loginType === 'staff' && !checkIsSp() && (
        <div className={'shift_forPc'}>
          <RegisterBase
            image={Icons.icon.noImage}
            imageShape={'round'}
            infos={myPageInfos}
          />
          {showButton && (
            <div className={'bottom_btn_area'} style={{
              height: '92px',
              width: isOpenSideMenu ? 'calc(100% - 200px)' : 'calc(100% - 56px)',
              transition: 'width .2s'
            }}>
              <div className={'staff_pay_approval--submit_buttons'} style={{
                paddingTop: '24px'
              }}>
                <Button
                  text={'送信'}
                  style={buttonStyle.registerButton}
                  onClick={() => handlerFormikSubmit(formik)}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {loginType === 'staff' && checkIsSp() && (
        <div className={'shift_forSp'}>{StaffPayApprovalForSp()}</div>
      )}
    </>
  );
};

export default StaffPayApproval;
