import './style.scss';
import AssignStateListInputs from '../inputsInfos';
import CustomListView from '../customListView';
import Copyright from '../../../../components/copyright';
import Icons from '../../../../constants/icons';
import { useFormik } from 'formik';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import {
  matterAssignStateActions,
  matterAssignStateInit,
  matterAssignStateListFetch,
} from '../../../../redux/slice/matterAssignState';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsSp } from '../../../../utils/fnUtil';

const AssignStateList = () => {
  const dispatch = useDispatch();
  const isSp = checkIsSp();
  const {
    searchParams,
    userList,
    venueList,
    assignStateList
  } = useSelector(state => state.matterAssignState);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => dispatch(matterAssignStateActions.saveSearchParams(values)),
  });

  const searchFormInfo = {
    jsx: () => (
      <div className='assign_state_list--search_area'>

        <div className='assign_state_list--matter_date_area'>
          <CustomFormikInput
            formik={formik}
            {...AssignStateListInputs.search[0]}
            disabledFutureDate={formik.values.matterDateEnd}
          />
          <span
            className='assign_state_list--matter_date_unit'
            style={{lineHeight: isSp ? '40px' : '44px'}}
          >
            〜
          </span>
          <CustomFormikInput
            formik={formik}
            {...AssignStateListInputs.search[1]}
            disabledDate={formik.values.matterDateStart}
          />
        </div>

        <CustomFormikInput
          formik={formik}
          {...AssignStateListInputs.search[2]}
          style={{
            areaStyle: { width: isSp ? '100%' : '228px'}
          }}
        />
        <CustomFormikInput
          formik={formik}
          {...AssignStateListInputs.search[3]}
          style={{
            areaStyle: { width: isSp ? '100%' : '400px'}
          }}
        />
        <CustomFormikInput
          formik={formik}
          {...AssignStateListInputs.search[4]}
          initialValue={venueList?.map(v => v.venueId)}
          selectBoxOptions={venueList?.map(v => `${v.venueId}:${v.venueName}`)}
          style={{
            areaStyle: { width: isSp ? '100%' : '258px'}
          }}
        />
        <CustomFormikInput
          formik={formik}
          {...AssignStateListInputs.search[5]}
          initialValue={userList?.map(u => u.userId)}
          selectBoxOptions={userList?.map(u => `${u.userId}:${u.name.lastName} ${u.name.firstName}`)}
          style={{
            areaStyle: { width: isSp ? '100%' : '400px'}
          }}
        />
        <CustomFormikInput
          formik={formik}
          {...AssignStateListInputs.search[6]}
          style={{
            areaStyle: { width: isSp ? '100%' : '400px'}
          }}
        />
        <CustomFormikInput
          formik={formik}
          {...AssignStateListInputs.search[7]}
          style={{
            areaStyle: { width: isSp ? '100%' : '150px'}
          }}
        />
      </div>
    ),
  };

  const columnSettings = {
    matterDate: { label: '実施日', sort: 'Desc', dataName: 'matter_date' },
    matterNumber: { label: '案件No', sort: 'none', dataName: 'matter_number' },
    matterDateNumber: { label: '案件No', sort: 'none', dataName: 'matter_date_number' },
    matterName: { label: '案件名', sort: 'none', dataName: 'matter_name' },
    venueName: { label: '開催地', sort: 'none', dataName: 'venue_name' },
    matterManagerName: { label: '社内案件担当者', sort: 'none', dataName: 'matter_manager_name' },
    shiftName: { label: 'シフト', sort: 'none', dataName: 'shift_name' },
    assignCount: {label: 'アサインスタッフ', sort: 'none', dataName: 'assign_count'},
    status: { label: 'ステータス', sort: 'none', dataName: 'status' },
    assignCountDetail: { label: 'アサイン詳細データ', sort: 'none', dataName: 'assign_count_detail' },
    statics: [
      { label: 'アサイン詳細', icon: Icons.icon.assignOkNv },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    ignoreAddBtn: true,
  }

  const {
    sortKey,
    sortMethod,
  } = formik.values;

  const changeSort = e => {
    if (e.target.id !== 'assign_count') {
      dispatch(
        matterAssignStateActions.setSort({
          sortKey: e.target.id,
          sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        })
      );
    }
  };

  const handleChangeListPerPage = num => {
    dispatch(matterAssignStateActions.saveSearchParams({ count: num }));
  };

  const handleChangePage = num => {
    dispatch(matterAssignStateActions.saveOffset(num));
  };

  useEffect(() => {
    dispatch(matterAssignStateListFetch(searchParams))
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(matterAssignStateInit()).then(() => formik.setValues({ ...searchParams }));
  }, []);

  return (
    <div className={'commonPage--base'}>
      <CustomListView
        searchFormInfo={searchFormInfo}
        columnSettings={columnSettings}
        buttonSettings={buttonSettings}
        primaryKey={'matterId'}
        primaryName={'matterName'}
        apiData={assignStateList}
        sortSettings={{ sortColumn: sortKey, sortMethod: sortMethod }}
        changeSort={changeSort}
        onSearch={formik.handleSubmit}
        onChangeCount={handleChangeListPerPage}
        onChangeOffset={handleChangePage}
        defaultOpened={true} //アコーディオンをオープンした状態で表示するかどうか
      />
      <Copyright />
    </div>
  );
};

export default AssignStateList;