import './style.scss';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import CustomInput from '../../../components/customInput';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import JpH from 'japanese-holidays';
import {
  handlerFormikFieldChange,
  handlerFormikSubmit,
} from '../../../utils/fnUtil';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from '../../../redux/slice/global';
import { cloneDeep } from 'lodash';
import SpConfirmModal from '../../../components/modal/spConfirmModal';
import { useLocation, useNavigate } from 'react-router-dom';
import Yup from "../../../utils/yupUtil";
import moment from "moment";

export const generateYears = () => {
  let years = [];
  const thisYear = new Date().getFullYear();

  years.push(thisYear + 2);
  years.push(thisYear + 1);

  for (let i = 0; i < 10; i++) {
    years.push(thisYear - i);
  }

  return years;
};

const ShiftPlanForSp = ({shiftsData}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showYear, setShowYear] = useState(
    location?.state ? location.state.year : new Date().getFullYear()
  );
  const [showMonth, setShowMonth] = useState(
    location?.state ? location.state.month : new Date().getMonth() + 1
  );
  const [viewYM, setViewYM] = useState({
    year: location?.state ? location.state.year : new Date().getFullYear(),
    month: location?.state ? location.state.month : new Date().getMonth() + 1,
  });
  const [dateList, setDateList] = useState([]);
  const { staffId, shiftStyleFlag } = useSelector(state => state.account);
  const [firstData, setFirstData] = useState({});
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);

  const spFormik = useFormik({
    initialValues: {
      shifts: [],
      statusFlag: '',
      submitDatetime: '',
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      shifts: Yup.array().of(
          Yup.object({
            startTime: Yup.string().nullable().label('開始時間'),
            endTime: Yup.string().nullable().label('終了時間'),
            shiftRange: Yup.string()
                .nullable()
                .test(
                    'dateInvalid',
                    '時間:開始時間は終了時間より前の時間を入力してください。',
                    (_, context) => {
                      const { shiftStyleFlag, startTime, endTime } =
                          context.from[0].value;
                      if (
                          shiftStyleFlag == 0 ||
                          (startTime == null && endTime == null)
                      ) {
                        return true;
                      }
                      return (
                          shiftStyleFlag == 1 &&
                          startTime &&
                          endTime &&
                          moment(endTime, 'HH:mm').isAfter(moment(startTime, 'HH:mm'))
                      );
                    }
                )
                .test(
                    'dateInvalid',
                    '時間:時間指定の場合時間を入力してください。',
                    (_, context) => {
                      const { shiftStyleFlag, startTime, endTime } =
                          context.from[0].value;
                      return !(
                          shiftStyleFlag == 1 &&
                          startTime == null &&
                          endTime == null
                      );
                    }
                ),
            staffNote: Yup.string().nullable().max(31).label('備考'),
          })
      ),
    }),
    onSubmit: async values => {
      let formatValues = cloneDeep({
        ...values,
        statusFlag: 1,
        staffId: staffId,
        yearMonth: showYear + '/' + String(showMonth).padStart(2, '0'),
        status: 1,
      });

      for (let k in formatValues) {
        if (
          formatValues[k] === '' ||
          formatValues[k] === null ||
          formatValues[k] === undefined ||
          k === 'undefined'
        ) {
          delete formatValues[k];
        }
      }

      formatValues.shifts = formatValues.shifts.map(obj => {
        return { ...obj, year: showYear, month: showMonth, staffId: staffId };
      });
      await fetchRequest(apiUrl.staffPlan.update, formatValues, 'POST')
        .then(result => {
          if (result.code === '0') {
            dispatch(globalActions.showSingleModal(`提出しました`));
            navigate('/');
          } else {
            dispatch(globalActions.showSingleModal(`提出に失敗しました`));
          }
        })
        .catch(() => {
          dispatch(globalActions.showSingleModal(`提出に失敗しました`));
        })
        .finally(() => {
          setModalVisible(false);
          handleShow();
        });
    },
  });

  const { shifts, statusFlag, lastSubmitDatetime } = spFormik.values;

  const setDateData = async () => {
    const lastDateOfMonth = new Date(showYear, showMonth, 0).getDate();
    let dateAry = [...new Array(lastDateOfMonth)].map((v, i) => {
      return { date: i + 1, weekday: '', holiday: false };
    });

    const JpH = require('japanese-holidays');
    const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
    const firstWeekDay = new Date(showYear, showMonth - 1, 1).getDay();

    dateAry.forEach((date, i) => {
      dateAry[i] = {
        ...date,
        weekday: weekDay[(firstWeekDay + i) % 7],
        holiday: JpH.isHoliday(new Date(showYear, showMonth - 1, date.date)),
      };
    });

    let setShifts = [...new Array(dateAry.length)].map((v, i) => {
      return {
        date: dateAry[i].date,
        enableAssignFlag: 0,
        shiftStyleFlag: 0,
        startTime: null,
        endTime: null,
        staffNote: '',
        managerNote: '',
      };
    });

    const apiRes = await fetchRequest(apiUrl.staffPlan.detail, {
      staffId: staffId,
      yearMonth: showYear + '/' + String(showMonth).padStart(2, '0'),
    });

    apiRes.detail.shifts.forEach(shift => {
      setShifts[setShifts.findIndex(value => value.date === shift.date)] =
        shift;
    });

    await spFormik.setValues({
      shifts: setShifts,
      statusFlag: apiRes.detail.statusFlag,
      lastSubmitDatetime: apiRes?.detail?.lastSubmitDatetime
        ? apiRes?.detail?.lastSubmitDatetime.replaceAll('-', '/').slice(0, 16)
        : '',
    });

    setFirstData({
      shifts: setShifts,
      statusFlag: apiRes.detail.statusFlag,
      lastSubmitDatetime: apiRes?.detail?.lastSubmitDatetime
        ? apiRes?.detail?.lastSubmitDatetime.replaceAll('-', '/').slice(0, 16)
        : '',
    });

    setDateList(dateAry);
  };

  const handleShow = async () => {
    setDateData();
    setViewYM({ year: showYear, month: showMonth });
  };

  const handleSave = async () => {
    let formatValues = cloneDeep({
      ...spFormik.values,
      statusFlag: 0,
      staffId: staffId,
      yearMonth: showYear + '/' + String(showMonth).padStart(2, '0'),
      status: 0,
    });

    for (let k in formatValues) {
      if (
        formatValues[k] === '' ||
        formatValues[k] === null ||
        formatValues[k] === undefined ||
        k === 'undefined'
      ) {
        delete formatValues[k];
      }
    }

    formatValues.shifts = formatValues.shifts.map(obj => {
      return { ...obj, year: showYear, month: showMonth, staffId: staffId };
    });
    await fetchRequest(apiUrl.staffPlan.update, formatValues, 'POST')
      .then(result => {
        if (result.code === '0') {
          dispatch(globalActions.showSingleModal(`保存しました`));
        } else {
          dispatch(globalActions.showSingleModal(`保存に失敗しました`));
        }
      })
      .catch(() => {
        dispatch(globalActions.showSingleModal(`保存に失敗しました`));
      });
  };
  const isCheckAllSp = e => {
    const isChecked = e.target.checked;
    const newShiftsData = [...shifts];
    const checkBoxes = document.getElementsByClassName('custom_input--toggle_input');
    if (isChecked) {
      Array.from(checkBoxes)?.map(elm => elm.checked = true);
      newShiftsData?.map((obj, idx) =>
        handlerFormikFieldChange(spFormik, `shifts[${idx}].enableAssignFlag`, 1)
      );
    } else {
      shifts?.map((obj, idx) =>
        handlerFormikFieldChange(
          spFormik,
          `shifts[${idx}].enableAssignFlag`,
          shiftsData[idx].enableAssignFlag === 0 ? 0 : 1
        ));
      Array.from(checkBoxes)?.map((elm, idx) =>
        shiftsData[idx].enableAssignFlag === 0 ? elm.checked = false : elm.checked = true
      );
    }
  }

  useEffect(() => {
    setDateData();
  }, []);

  return (
    <>
      <div className={'shiftForSp--static_area'}>
        <div className={'shiftForSp--static_date'}>
          <CustomInput
            inputType={'select'}
            inputName={'year_sp'}
            key={showYear ? showYear : 'year_sp'}
            initialValue={generateYears()}
            selectBoxOptions={generateYears()}
            selectedSelectBox={showYear}
            placeholder={'YYYY'}
            unit={'年'}
            style={{ areaStyle: { width: '50%' } }}
            onChange={e => {
              setShowYear(e);
            }}
            usePopContainer={true}
          />
          <CustomInput
            inputType={'select'}
            inputName={'month_sp'}
            key={showMonth ? showMonth : 'month_sp'}
            initialValue={[...new Array(12)].map((v, i) => i + 1)}
            selectBoxOptions={[...new Array(12)].map((v, i) => i + 1)}
            selectedSelectBox={showMonth}
            unit={'月'}
            style={{ areaStyle: { width: '50%' } }}
            onChange={e => {
              setShowMonth(e);
            }}
            usePopContainer={true}
          />
        </div>

        <Button
          style={{
            ...Options.buttonStyles.back,
            width: '100%',
            height: '40px',
            marginTop: '24px',
          }}
          text={'表示'}
          onClick={() => handleShow()}
        />

        <div className={'shiftForSp--sp_check_all_area'}>
          <CustomInput
            inputType={'checkBox'}
            inputName={'checkAllSp'}
            onChange={e => isCheckAllSp(e)}
            initialValue={!shifts?.some(obj => obj.enableAssignFlag === 0)}
            key={!shifts?.some(obj => obj.enableAssignFlag === 0)}
          />
          <span style={{fontSize: '12px'}}>全日程可</span>
        </div>

        <p className={'shiftForSp--static_state'}>
          <strong>
            {viewYM.year}年 {viewYM.month}月分
          </strong>

          <div
            style={
              statusFlag
                ? { color: '#6A6F7C', backgroundColor: '#E9F2FC' }
                : { color: '#FFFFFF', backgroundColor: '#D80245' }
            }
            key={statusFlag ? '提出済' : '未提出'}
          >
            {statusFlag ? '提出済' : '未提出'}
          </div>

          <span key={lastSubmitDatetime ? lastSubmitDatetime : 'datetime'}>
            提出日時{' '}
            {statusFlag ? lastSubmitDatetime : '-'}
          </span>
        </p>
      </div>

      <div className={'shiftForSp--calendar_area'}>
        {dateList.map((date, i) => {
          return (
            <div
              className={'shiftForSp--calendar_box'}
              key={`calBox_${date.date}`}
            >
              <div
                className={'shiftForSp--calendar_head'}
                style={
                  date.weekday === '日' || date.holiday
                    ? { backgroundColor: '#FDF2F5' }
                    : date.weekday === '土'
                    ? { backgroundColor: '#D7E2F1' }
                    : {}
                }
              >
                <span
                  style={
                    date.weekday === '日' || date.holiday
                      ? { color: '#D80245' }
                      : date.weekday === '土'
                      ? { color: '#3662A5' }
                      : {}
                  }
                >
                  {date.date}日(
                  {date.holiday ? `${date.weekday}・祝` : date.weekday})
                </span>
              </div>

              <div className={'shiftForSp--calendar_body'}>
                <div className={'shiftForSp--calendar_toggle_btn_wrap'}>
                  <span>シフト提出</span>
                  <CustomFormikInput
                    inputType={'toggleButton'}
                    formik={spFormik}
                    inputName={`shifts[${i}].enableAssignFlag`}
                    relativeInputName={[`shifts[${i}]`]}
                    key={
                      spFormik?.values?.shifts[i]?.enableAssignFlag ??
                      `shifts[${i}].enableAssignFlag`
                    }
                    checkBoxChecked={
                      spFormik?.values?.shifts[i]?.enableAssignFlag === 1
                    }
                    onChange={e => {
                      spFormik.setFieldValue(
                        `shifts[${i}].enableAssignFlag`,
                        e.target.checked ? 1 : 0
                      );
                    }}
                    disabled={
                      spFormik?.values?.statusFlag &&
                      firstData?.shifts[i]?.enableAssignFlag
                    }
                  />
                </div>

                {spFormik?.values?.shifts[i]?.enableAssignFlag ? (
                  <>
                    {shiftStyleFlag == 1 ? (
                      <>
                        <div className={'shiftForSp--calendar_detail_wrap'}>
                          <span style={{fontWeight: 'bold', color: '#646464'}}>条件</span>
                          <CustomFormikInput
                            formik={spFormik}
                            inputType={'radioButton'}
                            labelRequired={true}
                            inputName={`shifts[${i}].shiftStyleFlag`}
                            disabled={
                              spFormik?.values?.statusFlag &&
                              firstData?.shifts[i]?.enableAssignFlag
                            }
                            radioButtonLabelAndChecked={[
                              {
                                label: '終日',
                                checked:
                                  spFormik?.values?.shifts[i]?.shiftStyleFlag ==
                                  0,
                              },
                              {
                                label: '時間指定',
                                checked:
                                  spFormik?.values?.shifts[i]?.shiftStyleFlag ==
                                  1,
                              },
                            ]}
                            initialValue={['0', '1']}
                          />
                        </div>

                        {spFormik?.values?.shifts[i]?.shiftStyleFlag == 1 ? (
                          <div className={'shiftForSp--calendar_detail_wrap'}>
                            <span style={{fontWeight: 'bold', color: '#646464'}}>時間</span>
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '7px',
                              }}
                            >
                              <CustomFormikInput
                                formik={spFormik}
                                inputType="inputTime"
                                relativeInputName={[`shifts[${i}]`]}
                                inputName={`shifts[${i}].startTime`}
                                placeholder="00:00"
                                style={{
                                  areaStyle: {
                                    width: '100%',
                                  },
                                }}
                                disabled={
                                  spFormik?.values?.statusFlag &&
                                  firstData?.shifts[i]?.enableAssignFlag
                                }
                              />
                              <span>〜</span>
                              <CustomFormikInput
                                formik={spFormik}
                                inputType="inputTime"
                                relativeInputName={[`shifts[${i}]`]}
                                inputName={`shifts[${i}].endTime`}
                                placeholder="00:00"
                                style={{
                                  areaStyle: {
                                    width: '100%',
                                  },
                                }}
                                disabled={
                                  spFormik?.values?.statusFlag &&
                                  firstData?.shifts[i]?.enableAssignFlag
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <div className={'shiftForSp--calendar_detail_wrap'}>
                      <span style={{fontWeight: 'bold', color: '#646464'}}>備考</span>
                      <CustomFormikInput
                        formik={spFormik}
                        inputType={
                          spFormik?.values?.statusFlag &&
                          firstData?.shifts[i]?.enableAssignFlag
                            ? 'label'
                            : 'input'
                        }
                        inputName={`shifts[${i}].staffNote`}
                        placeholder={'備考を入力してください'}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className={'myPageSp--bottom_float'} style={{ zIndex: '900' }}>
        {/*{!spFormik?.values?.statusFlag ? (*/}
        {/*  <Button*/}
        {/*    text={'一時保存'}*/}
        {/*    style={{*/}
        {/*      ...Options.buttonStyles.back,*/}
        {/*      width: '160px',*/}
        {/*      height: '40px',*/}
        {/*    }}*/}
        {/*    onClick={handleSave}*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <></>*/}
        {/*)}*/}

        <Button
          text={'確定'}
          style={{
            ...Options.buttonStyles.submit,
            width: '160px',
            height: '40px',
          }}
          onClick={() => setModalVisible(true)}
        />
      </div>

      <SpConfirmModal
        visible={modalVisible}
        mainText={'シフトを提出しますか？'}
        subText={'一度出勤可能にした日程は、\r\n提出後に変更できません。'}
        backBtnText={'戻る'}
        submitBtnText={'提出'}
        handleCloseBtn={() => setModalVisible(false)}
        handleSubmitBtn={() => {
            setModalVisible(false);
            handlerFormikSubmit(spFormik);
        }}
      />
    </>
  );
};

export default ShiftPlanForSp;
