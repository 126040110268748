import './style.scss';
import { useState, createRef } from 'react';

const Hamburger = (props) => {
  const { isOpen } = props;
  const span1 = createRef();
  const span2 = createRef();
  const span3 = createRef();

  const init = () => {
    span1.current.style.width = '100%';
    span1.current.style.transform = 'rotate(0)';
    span1.current.style.marginTop = '2px';
    if (!isOpen) span1.current.style.right = '0px';
    if (isOpen) span1.current.style.left = '0px';

    span3.current.style.width = '100%';
    span3.current.style.transform = 'rotate(0)';
    span3.current.style.marginTop = '22px';
    if (!isOpen) span3.current.style.right = '0px';
    if (isOpen) span3.current.style.left = '0px';
  };

  const handleMouseOver = () => {
    if (!isOpen) {
      span1.current.style.width = '65%';
      span1.current.style.transform = 'rotate(40deg)';
      span1.current.style.left = 'unset';
      span1.current.style.right = '-2px';
      span1.current.style.marginTop = '7px';

      span3.current.style.width = '65%';
      span3.current.style.transform = 'rotate(-40deg)';
      span3.current.style.left = 'unset';
      span3.current.style.right = '-2px';
      span3.current.style.marginTop = '17px';
    }
  };

  const handleMouseOut = () => {
    if (!isOpen) {
      init();
    }
  };

  const handleClick = () => {
    if (!isOpen) {
      span1.current.style.width = '65%';
      span1.current.style.transform = 'rotate(-40deg)';
      span1.current.style.left = '-2px';
      span1.current.style.marginTop = '7px';

      span3.current.style.width = '65%';
      span3.current.style.transform = 'rotate(40deg)';
      span3.current.style.left = '-2px';
      span3.current.style.marginTop = '17px';
    } else {
      init();
    }
  };

  return (
    <div
      className={'hamburger--box'}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onClick={handleClick}
    >
      {
        (isOpen) ?
          <>
            <span ref={span1} style={{
              marginTop: '7px',
              width: '65%',
              transform: 'rotate(-40deg)',
              left: '-2px',
            }} />
            <span ref={span2} style={{ marginTop: '12px' }} />
            <span ref={span3} style={{
              marginTop: '17px',
              width: '65%',
              transform: 'rotate(40deg)',
              left: '-2px',
            }} />
          </>
          :
          <>
            <span ref={span1} style={{ marginTop: '2px', right: '0px' }} />
            <span ref={span2} style={{ marginTop: '12px' }} />
            <span ref={span3} style={{ marginTop: '22px', right: '0px' }} />
          </>
      }

    </div>
  );
};

export default Hamburger;