import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  quoteFlowDetail,
  quoteFlowInsert,
  quoteFlowUpdate,
  quoteUserList,
  quoteDeptList,
} from '../../../../redux/slice/quote';
import { store } from '../../../../redux/store';
import { globalActions } from '../../../../redux/slice/global';
import { useFormik } from 'formik';
import RegisterBase from '../../../../components/registerBase';
import Button from '../../../../components/button';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import Options from '../../../../constants/options';
import Icons from '../../../../constants/icons';
import Yup from '../../../../utils/yupUtil';
import { checkIsSp, handlerFormikSubmit } from '../../../../utils/fnUtil';
import './style.scss';

const selSty = {
  labelStyle: { width: '111.17px' },
  areaStyle: { width: 'calc(65% + 8px)' },
};
const deptSty = {
  labelStyle: { width: '111.17px' },
  areaStyle: { width: 'calc(65% - 24px)' },
};

const QuoteFlowRegister = () => {
  const location = useLocation();
  const isEdit = location.pathname.slice(5).includes('edit');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { flowDetail, userList, deptList } = useSelector(state => state.quote);
  const isSp = checkIsSp();

  const remNull = arr => {
    let list = arr.filter(
      e =>
        !['', null, undefined, NaN, false, true].includes(e) && e.length !== 0
    );
    return list;
  };

  const formik = useFormik({
    initialValues: flowDetail,

    validateOnMount: true,
    validationSchema: Yup.object({
      approvalFlowName: Yup.string().required().label('承認フロー名').max(100),
      stepApprover: Yup.array()
        .of(Yup.array().max(20, { message: '上限は20人です。' }))
        .test(
          'stepApprover',
          '承認者は必須です。',
          value =>
            value[0] !== null && value[0] !== undefined && value[0].length !== 0
        ),
    }),

    onSubmit: values => {
      let postData = {
        ...values,
        approvalFlowId: location.state?.id,
        availableDept: values.availableDept.map(item =>
          deptList.find(e => e.namingId == item)
        ),
        step1Approver: values.stepApprover[0]
          ?.map(item => userList.find(e => e.userId == item))
          ?.map(e => ({ ...e, userName: e?.name })),
        step2Approver:
          values.stepApprover[1] === undefined
            ? []
            : values.stepApprover[1]
                .map(item => userList.find(e => e.userId == item))
                .map(e => ({ ...e, userName: e?.name })),
        step3Approver:
          values.stepApprover[2] === undefined
            ? []
            : values.stepApprover[2]
                .map(item => userList.find(e => e.userId == item))
                .map(e => ({ ...e, userName: e?.name })),
        step4Approver:
          values.stepApprover[3] === undefined
            ? []
            : values.stepApprover[3]
                .map(item => userList.find(e => e.userId == item))
                .map(e => ({ ...e, userName: e?.name })),
      };

      isEdit
        ? dispatch(quoteFlowUpdate(postData)).then(({ error }) => {
            if (!error) {
              store.dispatch(globalActions.showSingleModal('更新しました'));
              navigate(-1);
            }
          })
        : dispatch(quoteFlowInsert(postData)).then(({ error }) => {
            if (!error) {
              store.dispatch(globalActions.showSingleModal('登録しました'));
              navigate(-1);
            }
          });
    },
  });

  // 社員一覧
  const apiParams = {
    status: 1,
    count: 999,
    sort: 'userIdDesc',
  };

  useEffect(() => {
    dispatch(quoteUserList(apiParams));
    dispatch(quoteDeptList());
    isEdit &&
      dispatch(quoteFlowDetail({ approvalFlowId: location.state.id })).then(
        ({ payload }) => {
          let data = {
            ...payload?.detail,
            availableDept: payload?.detail.availableDept.map(e => e.namingId),
            stepApprover: remNull([
              payload?.detail.step1Approver.map(e => e.userId),
              payload?.detail.step2Approver.map(e => e.userId),
              payload?.detail.step3Approver.map(e => e.userId),
              payload?.detail.step4Approver.map(e => e.userId),
            ]),
          };
          formik.setValues(data);
        }
      );
  }, []);

  const { stepApprover } = formik.values;

  const FlowContents = () => (
    <div className="quote_flow">
      <div className="quote_flow--top_area">
        <CustomFormikInput
          label="承認フロー名"
          labelRequired={true}
          formik={formik}
          inputName="approvalFlowName"
          placeholder="承認フロー名を入力してください"
          style={{
            labelStyle: { width: isSp ? '100%' : '111.17px' },
            areaStyle: { width: isSp ? '100%' : 'calc(65% - 24px)' },
          }}
        />
        <CustomFormikInput
          label="利用可能部署"
          formik={formik}
          inputName="availableDept"
          placeholder="利用可能部署を選択してください"
          unit={
            isSp ? (
              ''
            ) : (
              <Tooltip
                placement="bottom"
                title="設定されている利用可能部署に所属するユーザーがこの承認フローを利用することができます。"
                mouseEnterDelay={0.5}
                overlayClassName="quote_flow--top_area--help_tip"
                overlayInnerStyle={{
                  borderRadius: '6px',
                  padding: '14px 24px 12px 24px',
                  minWidth: isSp ? 'none' : '400px',
                }}
              >
                <img src={Icons.icon.help} alt="help" />
              </Tooltip>
            )
          }
          inputType="select"
          mode="multiple"
          allowClear={false}
          showSearch={false}
          initialValue={deptList?.map(e => e.namingId)}
          selectBoxOptions={deptList?.map(e => e.naming)}
          style={
            isSp
              ? {
                  areaStyle: { width: '100%' },
                }
              : selSty
          }
        />
      </div>
      <div className="quote_flow--main">
        {stepApprover.map((_, i) => (
          <div
            key={i}
            style={
              isSp
                ? { display: 'flex', width: '100%', position: 'relative' }
                : {}
            }
          >
            {i === 0 ? '' : <div className="quote_flow--main--step_line"></div>}
            <CustomFormikInput
              label={
                <div className={'quote_flow--main--step'}>
                  <span>{i + 1}</span>
                </div>
              }
              formik={formik}
              inputName={`stepApprover[${i}]`}
              placeholder="承認者を入力してください"
              unit={
                stepApprover.length === 1 ? (
                  ''
                ) : (
                  <img
                    src={Icons.icon.xCircleWh}
                    alt="del"
                    style={{ width: '28px', cursor: 'pointer' }}
                    onClick={() => {
                      let shallowCopy = stepApprover.slice();
                      shallowCopy.splice(i, 1);
                      formik.setFieldValue('stepApprover', shallowCopy);
                    }}
                  />
                )
              }
              inputType="select"
              mode="multiple"
              allowClear={false}
              showSearch={false}
              initialValue={userList?.map(e => e.userId)}
              selectBoxOptions={userList?.map(
                e => e.name.lastName + e.name.firstName
              )}
              style={
                isSp
                  ? {
                      labelStyle: { width: '44px' },
                      areaStyle: {
                        flexFlow: 'row',
                        width: '100%',
                        alignItems: 'center',
                        marginBottom: '30px',
                      },
                    }
                  : stepApprover.length === 1
                  ? deptSty
                  : selSty
              }
            />
          </div>
        ))}
      </div>
      <div
        className="quote_flow--add_btn"
        style={
          isSp
            ? {
                width: '100%',
                display: 'block',
                margin: '-14px 0 0',
                paddingLeft: '4px'
              }
            : {}
        }
      >
        {stepApprover.length > 3 ? (
          <></>
        ) : (
          <Button
            className="quote_flow--add_btn--icon"
            icon={Icons.icon.plus24}
            onClick={() => {
              let shallowCopy = [...stepApprover, []];
              formik.setFieldValue('stepApprover', shallowCopy);
            }}
          />
        )}
      </div>
    </div>
  );

  const infos = {
    detail: {
      tabs: {
        quoteTerm: {
          label: '承認フロー',
          jsx: FlowContents,
        },
      },
    },
  };

  return (
    <>
      <RegisterBase infos={infos} />

      <div className="bottom_btn_area" style={{ height: '92px' }}>
        <div className="quote_register--submit_buttons">
          <Button
            text="戻る"
            style={Options.buttonStyles.stampBack}
            onClick={() => {
              navigate('/quote/flow/list');
            }}
          />
          <Button
            text={isEdit ? '更新' : '登録'}
            style={Options.buttonStyles.stampApproval}
            onClick={() => {
              handlerFormikSubmit(formik);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default QuoteFlowRegister;
