import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  handlerFormikFieldChange,
  checkIsSp,
} from '../../../../../utils/fnUtil';
import { sendAssignMail } from '../../../../../redux/slice/matterDate';
import Button from '../../../../../components/button';
import Icons from '../../../../../constants/icons';
import CustomFormikInput from '../../../../../components/customInput/customFormikInput';
import moment from 'moment';
import './style.scss';

const ReportItem = memo(
  ({
    item,
    flag,
    confirmItem,
    dateTime,
    punchDateTimeItem,
    planDateTimeItem,
    confirmUserItem,
    confirmDateTimeItem,
    formik,
    format = 'YYYY/M/D HH:mm',
  }) => {
    const { allUserList } = useSelector(state => state.matterDate);

    const dispatch = useDispatch();

    const { userId } = useSelector(state => state.account);

    const { assignStaffDetail } = useSelector(state => state.matterDate);

    const values = formik.values;

    const reportDateTime = values[dateTime];

    const confirmDateTime = values[confirmDateTimeItem];

    const confirmUserId = values[confirmUserItem];

    const planDateTime = values[planDateTimeItem];

    const isSp = checkIsSp();

    return (
      <div className="report_item">
        <div className="report_item_left">
          {item && (
            <div>
              <div>
                <div className="report_item_wrapper">
                  <span className="report_item_label">{item}</span>
                  <span className="report-text display-linebreak">
                    {reportDateTime
                      ? `報告済 \t${moment(reportDateTime).format(format)}`
                      : '未報告'}
                  </span>
                </div>
              </div>
              <div className="report_item_wrapper">
                <span className="report_item_label">予定時刻</span>
                <span className="report_item_plan">
                  <span>{planDateTime ? `\t${moment(planDateTime).format('YYYY/M/D')}` : formik?.values?.matterDate}</span>
                  <CustomFormikInput
                    formik={formik}
                    inputType="inputTime"
                    disabled={
                      (values[flag] == 0 && reportDateTime) ||
                      confirmDateTime ||
                      values[flag] == 2
                    }
                    inputName={planDateTimeItem.replace('Datetime', 'Time')}
                    placeholder="00:00"
                    style={{
                      areaStyle: {
                        width: 115,
                        marginLeft: 20,
                      },
                    }}
                  />
                </span>
              </div>
              <div className="report_item_wrapper">
                <span className="report_item_label">報告方法</span>
                <CustomFormikInput
                  formik={formik}
                  inputType={'radioButton'}
                  inputName={flag}
                  disabled={!!reportDateTime || !!confirmDateTime}
                  radioButtonLabelAndChecked={[
                    {
                      label: 'システム入力',
                      checked: values[flag] == 0,
                    },
                    { label: '電話', checked: values[flag] == 1 },
                    { label: '報告不要', checked: values[flag] == 2 },
                  ]}
                  initialValue={[0, 1, 2]}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <div style={isSp ? { textAlign: 'left' } : {}}>
            <span>{item ? '確認者' : 'アサイン通知送信'}</span>
            <span className="sub_text">
              {confirmUserId
                ? `${
                    allUserList.find(u => u.userId === values[confirmUserItem])
                      ?.userName
                      ? allUserList.find(
                          u => u.userId === values[confirmUserItem]
                        )?.userName
                      : ''
                  }　${moment(confirmDateTime).format('YYYY/M/D HH:mm')}`
                : ''}
            </span>
          </div>
          <Button
            text={confirmItem}
            icon={
              !item &&
              (values.notificationFlag === 1
                ? Icons.icon.mailOpWh
                : Icons.icon.mailWh)
            }
            disabled={
              item
                ? !!confirmDateTime || values[flag] == 2
                : ![2, 3].includes(assignStaffDetail?.status) ||
                  assignStaffDetail?.assignAccountFlag === 2
            }
            onClick={async () => {
              if (!item) {
                await dispatch(
                  sendAssignMail({
                    matterDateNumber: [assignStaffDetail.matterDateNumber],
                    assignId: assignStaffDetail.assignId,
                    assignAccountFlag: assignStaffDetail.assignAccountFlag,
                  })
                ).unwrap();

                handlerFormikFieldChange(formik, 'notificationFlag', 1);
              } else {
                const currentDateTime = moment().format('YYYY/M/D HH:mm:ss');
                handlerFormikFieldChange(
                  formik,
                  confirmDateTimeItem,
                  currentDateTime
                );
                handlerFormikFieldChange(formik, confirmUserItem, userId);

                if (values[flag] == 1) {
                  handlerFormikFieldChange(formik, dateTime, currentDateTime);
                  punchDateTimeItem &&
                    handlerFormikFieldChange(
                      formik,
                      punchDateTimeItem,
                      currentDateTime
                    );
                }
              }
            }}
          />
        </div>
      </div>
    );
  }
);

export default ReportItem;
