import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    quoteCounter: [0, 0],
    matterCounter: [0, 0],
    invoiceCounter: [0, 0],
    staffCounter: [0, 0],
    clientCounter: [0, 0],
    userCounter: [0, 0],
    corporateCounter: [0, 0],
    partnerCounter: [0, 0],
    venueCounter: [0, 0],
  },
  reducers: {
    setCounter: (state, action) => {
      state.quoteCounter = action.payload.quoteCounter;
      state.matterCounter = action.payload.matterCounter;
      state.invoiceCounter = action.payload.invoiceCounter;
      state.staffCounter = action.payload.staffCounter;
      state.clientCounter = action.payload.clientCounter;
      state.userCounter = action.payload.userCounter;
      state.corporateCounter = action.payload.corporateCounter;
      state.partnerCounter = action.payload.partnerCounter;
      state.venueCounter = action.payload.venueCounter;
    },
  },
});

export const { setCounter } = counterSlice.actions;

export default counterSlice.reducer;
