import './style.scss';
import Button from '../button';
import { useState } from 'react';
import button from '../button';

const Pager = ({ searchCount, listPerPage, currentPage, setPage }) => {
  const pages = Math.floor(searchCount / listPerPage) + ((searchCount % listPerPage) ? 1 : 0);

  const generateButtonsList = (num) => {
    let numberButtons = [];
    let ret = [];

    for (let i = 1; i <= num; i++) {
      numberButtons.push(i);
    }

    if (pages < 5) {
      ret = [...numberButtons];
    } else {
      ret = [1, currentPage - 1, currentPage, currentPage + 1, pages];

      ret.forEach((value, index) => {
        if (value === 0 || value > pages) {
          ret.splice(index, 1);
        }
      })

      ret.forEach((value, index) => {
        if (value === ret[index + 1]) {
          ret.splice(index, 1)
        }
      })

      ret.forEach((value, index) => {
        if ((ret[index + 1] - value) >= 2 ) {
          ret.splice(index + 1, 0, '…');
        }
      })
    }

    if (ret.toString() === [1, 2, '…', pages].toString()) {
      ret = [1, 2, 3, '…', pages];
    } else if (ret.toString() === [1, '…', pages - 1, pages].toString()) {
      ret = [1, '…', pages - 2, pages - 1, pages];
    }

    return ret;
  };

  const buttonList = generateButtonsList(pages);

  const defaultButtonStyle = {
    width: '44px',
    height: '44px',
    border: '1px solid #2B3245',
    borderRadius: '6px',
    backgroundColor: '#FFFFFF',
    color: '#2B3245',
    fontSize: '14px',
    margin: '0 0',
    userSelect: 'none',
    lineHeight: '44px',
    display: 'flex', //種市追加
    justifyContent: 'center',
    alignItems: 'center',
  }

  const activeButtonStyle = {
    width: '44px',
    height: '44px',
    border: '1px solid #0056D3',
    borderRadius: '6px',
    backgroundColor: '#0056D3',
    color: '#FFFFFF',
    // fontSize: '16px',
    margin: '0 0',
    userSelect: 'none',
    display: 'flex', //種市追加
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <div className={'pager--wrapper'}>
      {
        buttonList.map((value, index) => {
          return (
            <Button
              key={index}
              text={!value ? '' : value}
              value={(value ==='…') ? (index < 2) ? '-' : '+' : !value ? '' : value}
              style={(value === currentPage) ? activeButtonStyle : defaultButtonStyle}
              onClick={setPage}
            />
            )
        })
      }
    </div>
  );
};

export default Pager;