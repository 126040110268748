import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApi, fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import options from '../../constants/options';
import moment from 'moment';
import { store } from '../store';

const namingValidList = [
  'PromotionPreliminary1',
  'PromotionPreliminary2',
  'PromotionPreliminary3',
  'PromotionPreliminary4',
  'PromotionPreliminary5',
];
const CategorySearchParams = {
  namingClassificationId:
    'Index,PromotionPreliminary1,PromotionPreliminary2,PromotionPreliminary3,' +
    'PromotionPreliminary4,PromotionPreliminary5,PromotionCategory,PromotionIndustryPrimary,PromotionIndustrySecondary',
  sort: 'namingIdAsc',
  status: 1,
};
const initialSearchParams = {
  invoiceNumber: null,
  matterName: null,
  clientId: [],
  requestDateFrom: null,
  requestDateTo: null,
  orderStatus: [0, 1],
  requestStatus: [0, 1],
  matterManagerId: [],
  namingClassificationId: null,
  namingId: [],
  count: 20,
  offset: 0,
  sort: 'invoiceNumberDesc',
  sortColumn: 'invoiceNumber',
  sortMethod: 'Asc',
  companySeal: false,
};

const initialEditParams = {
  matterNumber: null,
  matterName: ``,
  clientId: null,
  clientManagerName: null,
  matterManagerUserId: null,
  salesManagerUserId: null,
  payTermsId: null,
  invoiceRegistrationNumber: '',
  orderStatus: 0,
  invoiceTiming: 0,
  billedDate: [],
  matterLastDate: null,
  payMethod: 0,
  bankMasterId: null,
  matterCategory: null,
  matterCategoryOther: null,
  matterIndustryPrimary: null,
  matterIndustryPrimaryOther: null,
  matterIndustrySecondary: null,
  preliminary1: null,
  preliminary2: null,
  preliminary3: null,
  preliminary4: null,
  preliminary5: null,
  note: null,
  invoiceNumber: null,
  createdUser: {},
  updatedUser: {},
  branchNumber: null,
};
const initialQuoteDocument = {
  quoteNumber: '',
  matterName: '', // 案件名
  invoiceNumber: null,
  clientId: '', // 取引先ID
  quoteDocumentBranchNumber: '', //見積書枝番
  quoteDocumentDate: '', // 見積日
  quoteDocumentLimit: '', // 有効期限
  payTermsId: null, // 支払条件ID
  // 法人情報
  corporateInfo: {
    name: '',
    // 郵便番号
    postCode: {
      number1: '',
      number2: '',
    },
    prefCode: '', // 都道府県
    cityName: '', // 市区町村
    streetName: '', // 街
    buildingName: '', // 建物名
    // 電話
    tel: {
      number1: '',
      number2: '',
      number3: '',
    },
    fax: {
      number1: '',
      number2: '',
      number3: '',
    },
    quoteAmountViewFlag: 0, // 見積請求金額表示
  },
  quoteAmount: 0, // 見積合計金額
  quoteAmountTotal: 0, // 見積そう合計
  quoteAmountTax: 0, // 税金
  calculationTaxFlag: 0, // 消費税計算
  // 見積Bodyの配列
  quoteBody: options.codes.quoteBody.concat(options.codes.quoteBodyTotal),
  stampMessage: '', // 捺印申請メッセージ
  stampLimitDate: '', // 捺印期限
  stampDatetime: '', // 捺印日時
  approvalSteps: [], // 承認ステータス
  note: '', //,
  message: '', //　承認否認メッセージ
};
const initialInvoiceDocument = {
  quoteNumber: '',
  matterName: '', // 案件名
  clientId: '', // 取引先ID
  quoteDocumentBranchNumber: '', //見積書枝番
  quoteDocumentDate: '', // 見積日
  quoteDocumentLimit: '', // 有効期限
  payTermsId: null, // 支払条件ID
  // 法人情報
  corporateInfo: {
    name: '',
    // 郵便番号
    postCode: {
      number1: '',
      number2: '',
    },
    prefCode: '', // 都道府県
    cityName: '', // 市区町村
    streetName: '', // 街
    buildingName: '', // 建物名
    // 電話
    tel: {
      number1: '',
      number2: '',
      number3: '',
    },
    fax: {
      number1: '',
      number2: '',
      number3: '',
    },
    quoteAmountViewFlag: 0, // 見積請求金額表示
  },
  quoteAmount: 0, // 見積合計金額
  quoteAmountTotal: 0, // 見積そう合計
  quoteAmountTax: 0, // 税金
  calculationTaxFlag: 0, // 消費税計算
  // 見積Bodyの配列
  quoteBody: options.codes.quoteBody.concat(options.codes.quoteBodyTotal),
  quoteNote: '', //,
  stampMessage: '', // 捺印申請メッセージ
  stampLimitDate: '', // 捺印期限
  stampDatetime: '', // 捺印日時
  approvalSteps: [], // 承認ステータス
  // 請求Bodyの配列
  invoiceBody: options.codes.quoteBody.concat(options.codes.quoteBodyTotal),
  invoiceAmount: 0, // 見積合計金額
  invoiceAmountTotal: 0, // 見積そう合計
  invoiceAmountTax: 0, // 税金
  invoiceNote: '', //,
  message: '', //　承認否認メッセージ
};

const initialState = {
  searchParams: initialSearchParams,
  searchWorkParams: initialSearchParams,
  editParams: initialEditParams,
  invoiceDocument: initialInvoiceDocument,
  quoteDocument: initialQuoteDocument,
  searchCount: 0,
  totalCount: 0,
  activeCount: 0,
  namingCategoryList: [],
  invoiceList: [],
  namingList: [],
  matterCategoryList: [],
  PromotionCategoryList: [],
  PromotionIndustryPrimaryList: [],
  PromotionIndustrySecondaryList: [],
  clientList: [],
  matterList: [],
  userList: [],
  paymentList: [],
  bankingList: [],
  editParam: [],
  salesTaxList: [],
  templateList: [],
  navigation: null,
  csvImportVisible: false,
  csvExportVisible: false,
  pdfExportVisible: false,
  pdfOutputFlag: false,
  calculation: false,
  deleteFlag: false,
  nextId: 0,
  officeMasters: [],
};

export const invoiceCategoryInit = createAsyncThunk(
  'invoice/category/init',
  async () => {
    const [userRes, clientRes, namingRes] = await Promise.all([
      fetchRequest(apiUrl.user.list, { status: 1 }),
      fetchRequest(apiUrl.client.list, { status: 1 }),
      fetchRequest(apiUrl.naming.list, CategorySearchParams),
    ]);
    return {
      namingCategoryList: namingRes?.item
        ?.filter(n => namingValidList.includes(n?.namingId ?? ''))
        .map(n => {
          return {
            ...n,
            value: n?.namingId,
            label: n?.naming,
          };
        }),
      namingList: namingRes?.item
        ?.filter(n => n?.namingClassificationId !== 'Index')
        .map(n => {
          return {
            ...n,
            value: n?.namingId,
            label: `${n?.namingId}:${n.naming}`,
          };
        }),
      userList: userRes?.item?.map(u => {
        return {
          ...u,
          value: u?.userId,
          label: `${u?.userId}:${u?.name?.lastName ?? ''} ${
            u?.name?.firstName ?? ''
          }`,
        };
      }),
      clientList: clientRes?.item?.map(c => {
        return {
          ...c,
          value: c?.clientId,
          label: `${c?.clientId}:${c?.clientName ?? ''}`,
        };
      }),
    };
  }
);
export const invoiceRequestUpdate = createAsyncThunk(
  'invoice/request',
  async params => await fetchRequest(apiUrl.invoice.request, params, 'POST')
);
export const invoiceLockUpdate = createAsyncThunk(
  'invoice/lock',
  async params => await fetchRequest(apiUrl.invoice.lock, params, 'POST')
);
export const invoiceFetch = createAsyncThunk('invoice/list', async params => {
  const invoiceRes = await fetchRequest(apiUrl.invoice.list, params);

  const item = invoiceRes?.item?.map(n => {
    return {
      ...n,
      orderStatus:
        n?.orderStatus === 0
          ? '見積中'
          : n?.orderStatus === 1
          ? '受注済'
          : '失注',
      requestStatus: n?.requestStatus === 0 ? '未請求' : '請求済',
      deleteIconState: n?.quoteNumber ? 1 : n.deleteLockFlag,
    };
  });
  return {
    invoiceList: {
      item,
      count: invoiceRes?.searchCount ?? 0,
      totalCount: invoiceRes?.totalCount ?? 0,
      activeCount: invoiceRes?.activeCount ?? 0,
    },
  };
});
export const invoiceGetId = createAsyncThunk(
  'invoice/next_id',
  async params => await fetchRequest(apiUrl.invoice.next_id, params)
);
export const invoiceDetailGetInfo = createAsyncThunk(
  'invoice/detail/info',
  async params => {
    const [
      corpRes,
      userRes,
      clientRes,
      paymentRes,
      matterRes,
      matterCategoryRes,
      namingRes,
    ] = await Promise.all([
      fetchRequest(apiUrl.corporate.detail, params),
      fetchRequest(apiUrl.user.list, { status: 1 }),
      fetchRequest(apiUrl.client.list, { status: 1 }),
      fetchRequest(apiUrl.payment.list),
      fetchRequest(apiUrl.matter.list),
      fetchRequest(apiUrl.matter.classList),
      fetchRequest(apiUrl.naming.list, CategorySearchParams),
    ]);
    return {
      bankingList: corpRes?.detail?.bankMasters?.map(b => {
        return {
          ...b,
          value: b?.bankMasterId,
          label: `${b?.bankName} ${b?.bankBranchName} ${b?.bankAccountNumber}  ${b?.bankAccountName}`,
        };
      }),
      paymentList: paymentRes?.item?.map(p => {
        return {
          ...p,
          value: p?.payTermsId,
          label: `${p?.payTermsId}:${p?.payTermsName}`,
        };
      }),
      matterList: matterRes?.item
        ?.filter(m => !m.quoteNumber)
        .map(m => {
          return {
            ...m,
            value: m?.matterNumber,
            label: `${m?.matterNumber}:${m?.matterName}`,
          };
        }),
      matterCategoryList: matterCategoryRes?.item,
      namingCategoryList: namingRes?.item
        ?.filter(n => namingValidList.includes(n?.namingId ?? ''))
        .map(n => {
          return {
            ...n,
            value: n?.namingId,
            label: n?.naming,
          };
        }),
      namingList: namingRes?.item
        ?.filter(
          n =>
            n?.namingClassificationId !== 'Index' ||
            n?.namingClassificationId !== 'PromotionCategory' ||
            n?.namingClassificationId !== 'PromotionIndustryPrimary' ||
            n?.namingClassificationId !== 'PromotionIndustrySecondary'
        )
        .map(n => {
          return {
            ...n,
            value: n?.namingId,
            label: `${n?.namingId}:${n.naming}`,
          };
        }),
      PromotionCategoryList: namingRes?.item
        ?.filter(n => n?.namingClassificationId === 'PromotionCategory')
        .map(n => {
          return {
            ...n,
            value: n?.namingId,
            label: `${n?.namingId}:${n?.naming}`,
          };
        }),
      PromotionIndustryPrimaryList: namingRes?.item
        ?.filter(n => n?.namingClassificationId === 'PromotionIndustryPrimary')
        .map(n => {
          return {
            ...n,
            value: n?.namingId,
            label: `${n?.namingId}:${n?.naming}`,
          };
        }),
      PromotionIndustrySecondaryList: namingRes?.item
        ?.filter(
          n => n?.namingClassificationId === 'PromotionIndustrySecondary'
        )
        .map(n => {
          return {
            ...n,
            value: n?.namingId,
            label: `${n?.namingId}:${n?.naming}`,
          };
        }),
      userList: userRes?.item?.map(u => {
        return {
          ...u,
          value: u?.userId,
          label: `${u?.userId}:${u?.name?.lastName ?? ''} ${
            u?.name?.firstName ?? ''
          }`,
        };
      }),
      clientList: clientRes?.item?.map(c => {
        return {
          ...c,
          value: c?.clientId,
          label: `${c?.clientId}:${c?.clientName ?? ''}`,
        };
      }),
      officeMasters: corpRes?.detail?.officeMasters?.map((o, idx) => {
        return {
          ...o,
          value: idx + 1,
          label: `${idx + 1}:${o?.officeName}`,
        };
      }),
    };
  }
);
export const invoiceDetail = createAsyncThunk(
  'invoice/detail',
  async params => await fetchRequest(apiUrl.invoice.detail, params)
);
export const invoiceInsert = createAsyncThunk(
  'invoice/insert',
  async params => await fetchRequest(apiUrl.invoice.insert, params, 'POST')
);

export const invoiceUpdate = createAsyncThunk(
  'invoice/update',
  async params => await fetchRequest(apiUrl.invoice.update, params, 'POST')
);

export const invoiceDelete = createAsyncThunk(
  'invoice/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.invoice.delete, params, 'POST');
    dispatch(invoiceActions.refresh());
  }
);

// 詳細取得
export const invoiceDocumentInit = createAsyncThunk(
  'invoice/document/init',
  async params => {
    const [invoiceRes, templateRes, paymentRes, salesTaxRes, corpRes] =
      await Promise.all([
        fetchRequest(apiUrl.invoice.documentDetail, params),
        fetchRequest(apiUrl.quote.template),
        fetchRequest(apiUrl.payment.list),
        fetchRequest(apiUrl.salesTax.list, { sort: 'salesTaxIdDesc' }),
        fetchRequest(apiUrl.corporate.detail, params),
      ]);
    const templateList = templateRes?.item?.map(t => {
      return {
        ...t,
        label: `${t?.templateId}: ${t?.templateName}`,
        value: t?.templateId,
      };
    });
    const salesTaxList = salesTaxRes?.item
      ?.map(t => {
        return {
          ...t,
          label: `${t?.taxRate}`,
          value: t?.salesTaxId,
        };
      })
      .sort((a, b) => {
        return a.value > b.value ? -1 : 1;
      });

    const quoteTotalLine = [...new Array(3)].map((v, i) =>
      invoiceRes?.quoteDetail?.quoteBody?.length === 0
        ? 2 + i
        : invoiceRes?.quoteDetail?.quoteBody?.length + 1 + i
    );
    const invoiceTotalLine = [...new Array(3)].map((v, i) =>
      invoiceRes?.invoiceDetail?.invoiceBody?.filter(
        i => i.invoiceDate == invoiceRes?.invoiceDetail?.invoiceDocumentDate[0]
      )?.length === 0
        ? 2 + i
        : invoiceRes?.invoiceDetail?.invoiceBody?.filter(
            i =>
              i.invoiceDate == invoiceRes?.invoiceDetail?.invoiceDocumentDate[0]
          )?.length +
          1 +
          i
    );
    var quoteAmountTaxSum =
      invoiceRes.quoteDetail?.quoteBody?.length === 0
        ? 0
        : invoiceRes.quoteDetail?.quoteBody
            ?.filter(q => quoteTotalLine.includes(q?.rowIndex))
            .reduce((s, q) => {
              const taxRate =
                salesTaxRes?.item?.find(t => t?.salesTaxId == q?.salesTaxId)
                  ?.taxRate ?? 1;
              return s + q?.quantity * q?.amount * taxRate;
            }, 0);
    switch (invoiceRes.quoteDetail?.calculationTaxFlag) {
      case 0:
        quoteAmountTaxSum = Math.ceil(quoteAmountTaxSum / 100);
        break;
      case 1:
        quoteAmountTaxSum = Math.floor(quoteAmountTaxSum / 100);
        break;
      case 2:
        quoteAmountTaxSum = Number(quoteAmountTaxSum / 100).toFixed(0);
        break;
      default:
        break;
    }
    const salesTax = salesTaxList.length > 0 ? salesTaxList[0].value : null;
    return {
      salesTaxList,
      templateList,
      isEdit: invoiceRes.invoiceDetail?.quoteBody?.length !== 0,
      resQuoteDocument: {
        ...invoiceRes.quoteDetail,
        quoteBody:
          invoiceRes.quoteDetail?.quoteBody?.length === 0
            ? options.codes.quoteBody
                .map(q => {
                  return {
                    ...q,
                    salesTaxId: salesTax,
                    invoiceDate:
                      invoiceRes.invoiceDetail?.invoiceDocumentDate[0],
                  };
                })
                .concat(
                  options.codes.quoteBodyTotal.map((o, i) => {
                    return { ...o, rowIndex: quoteTotalLine[i] };
                  })
                )
            : invoiceRes.quoteDetail?.quoteBody
                ?.map((q, i) => {
                  return {
                    ...q,
                    index: i,
                    invoiceDate:
                      invoiceRes.invoiceDetail?.invoiceDocumentDate[0],
                  };
                })
                .concat(
                  options.codes.quoteBodyTotal.map((o, i) => {
                    return { ...o, rowIndex: quoteTotalLine[i] };
                  })
                ),
        calculationTaxFlag:
          invoiceRes?.quoteDetail?.calculationTaxFlag === null
            ? corpRes?.detail?.calculationTaxFlag
            : invoiceRes?.quoteDetail?.calculationTaxFlag,
        quoteAmountTotal:
          invoiceRes?.quoteDetail?.quoteAmount ?? 0 + quoteAmountTaxSum,
        quoteAmountTax: quoteAmountTaxSum,
        payTermName: paymentRes?.item?.find(
          p => p?.payTermsId == invoiceRes?.quoteDetail?.payTermsId
        )?.payTermsName,
        message: null,
      },
      resInvoiceDocument: {
        ...invoiceRes.invoiceDetail,
        multiInvoiceBody:
          invoiceRes.invoiceDetail?.invoiceBody?.length === 0
            ? options.codes.quoteBody.map(q => {
                return {
                  ...q,
                  salesTaxId: salesTax,
                  invoiceDate: invoiceRes.invoiceDetail?.invoiceDocumentDate[0],
                };
              })
            : invoiceRes.invoiceDetail?.invoiceBody?.map((q, i) => {
                return { ...q, index: i };
              }),
        quoteBody:
          invoiceRes.invoiceDetail?.invoiceBody?.filter(
            i =>
              i.invoiceDate == invoiceRes?.invoiceDetail?.invoiceDocumentDate[0]
          )?.length === 0
            ? options.codes.quoteBody
                .map(q => {
                  return {
                    ...q,
                    salesTaxId: salesTax,
                    invoiceDate:
                      invoiceRes.invoiceDetail?.invoiceDocumentDate[0],
                  };
                })
                .concat(
                  options.codes.quoteBodyTotal.map((o, i) => {
                    return { ...o, rowIndex: invoiceTotalLine[i] };
                  })
                )
            : invoiceRes.invoiceDetail?.invoiceBody
                ?.map((q, i) => {
                  return { ...q, index: i };
                })
                .concat(
                  options.codes.quoteBodyTotal.map((o, i) => {
                    return { ...o, rowIndex: invoiceTotalLine[i] };
                  })
                ),
        invoiceBody:
          invoiceRes.invoiceDetail?.invoiceBody?.length === 0
            ? options.codes.quoteBody
                .map(q => {
                  return {
                    ...q,
                    salesTaxId:
                      salesTaxList.length > 0 ? salesTaxList[0].value : null,
                    invoiceDate: invoiceRes.invoiceDetail?.paymentLimitDate[0],
                  };
                })
                .concat(
                  options.codes.quoteBodyTotal.map((o, i) => {
                    return { ...o, rowIndex: invoiceTotalLine[i] };
                  })
                )
            : invoiceRes.invoiceDetail?.invoiceBody
                ?.map((q, i) => {
                  return { ...q, index: i };
                })
                .concat(
                  options.codes.quoteBodyTotal.map((o, i) => {
                    return { ...o, rowIndex: invoiceTotalLine[i] };
                  })
                ),
        calculationTaxFlag:
          invoiceRes?.invoiceDetail?.calculationTaxFlag === null
            ? corpRes?.detail?.calculationTaxFlag
            : invoiceRes?.invoiceDetail?.calculationTaxFlag,
        quoteAmountTotal:
          invoiceRes?.invoiceDetail?.quoteAmount ?? 0 + quoteAmountTaxSum,
        quoteAmountTax: quoteAmountTaxSum,
        bankingList: corpRes?.detail?.bankMasters?.map(b => {
          return {
            ...b,
            value: b?.bankMasterId,
            label: `${b?.bankName} ${b?.bankBranchName} ${b?.bankAccountNumber}  ${b?.bankAccountName}`,
          };
        }),
        selectedInvoiceDate:
          invoiceRes?.invoiceDetail?.invoiceDocumentDate?.length > 0 &&
          invoiceRes?.invoiceDetail?.invoiceDocumentDate[0],
      },
    };
  }
);
export const invoiceDocumentUpdate = createAsyncThunk(
  'invoice/document/update',
  async params =>
    await fetchRequest(apiUrl.invoice.documentUpdate, params, 'POST')
);

// 見積テンプレート 詳細取得
export const invoiceTemplateDetailFetch = createAsyncThunk(
  'invoice/template/detail',
  async params => {
    const res = await fetchRequest(apiUrl.quote.templateDetail, params);
    const body = res?.detail?.templateBody
      .map((t, i) => {
        return {
          ...t,
          salesTaxId: null,
          totalAmount: null,
          salesTaxAmount: null,
          index: i,
          rowIndex: i + 1,
        };
      })
      .concat(options.codes.quoteBodyTotal);
    return {
      ...res,
      template: body,
    };
  }
);

export const invoiceDocumentEditPdf = createAsyncThunk(
  'invoice/document/pdf/edit',
  async params => {
    const result = await fetchRequest(
      apiUrl.invoice.documentEditPdf,
      params,
      'POST',
      'arraybuffer'
    );
    const blob = new Blob([result], { type: 'application/pdf' });

    if (blob.size == 0) {
      return null;
    }
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileName = `請求書_${moment().format('YYYYMMDDHHmmss')}.pdf`;
    a.download = fileName;
    a.href = url;
    a.target = '_self';
    a.click();
    a.remove();
    setTimeout(() => {
      window.open(url, '_blank');
    }, 1);
    return result;
  }
);
// 請求書PDF出力
export const invoiceDocumentPdf = createAsyncThunk(
  'invoice/document/pdf',
  async params => {
    const result = await fetchRequest(
      apiUrl.invoice.documentPdf,
      params,
      'GET',
      'arraybuffer'
    );
    const blob = new Blob([result], { type: 'application/pdf' });

    if (blob.size == 0) {
      return null;
    }
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileName = `請求書_${moment().format('YYYYMMDDHHmmss')}.pdf`;
    a.download = fileName;
    a.href = url;
    a.target = '_self';
    a.click();
    a.remove();
    setTimeout(() => {
      window.open(url, '_blank');
    }, 1);
    return result;
  }
);

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveParam: (state, { payload }) => {
      state.searchWorkParams = { ...state.searchParams };
    },
    resetParam: (state, { payload }) => {
      state.searchParams = {
        ...state.searchWorkParams,
        offset: 0,
        count: 20,
      };
      state.searchWorkParams = initialSearchParams;
    },
    setEdit: state => {
      state.editParam = { ...state.editParam };
    },
    refresh: state => {
      state.searchParams = initialSearchParams;
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    setPdfExportVisible: (state, { payload }) => {
      state.pdfExportVisible = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    setCalculation: (state, { payload }) => {
      state.calculation = payload;
    },
    setDelete: (state, { payload }) => {
      state.deleteFlag = payload;
    },
    setQuoteNumber: (state, { payload }) => {
      state.quoteNumberId = payload;
    },
    setNavigation: (state, { payload }) => {
      state.navigation = payload;
    },
    setPdfOutputFlag: (state, { payload }) => {
      state.pdfOutputFlag = payload;
    },
  },
  extraReducers: {
    [invoiceCategoryInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [invoiceFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [invoiceGetId.fulfilled]: (state, { payload }) => ({
      ...state,
      nextId: payload?.nextinvoiceId ?? 1,
    }),
    [invoiceDetailGetInfo.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [invoiceDocumentInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [invoiceTemplateDetailFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [invoiceInsert.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [invoiceUpdate.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [invoiceDocumentPdf.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [invoiceRequestUpdate.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [invoiceLockUpdate.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const invoiceActions = invoiceSlice.actions;
export default invoiceSlice.reducer;
