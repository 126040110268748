import { memo } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import StaffRegisterInfos from "./inputInfos";
import {useSelector} from "react-redux";

const Body = memo(({ formik }) => {

  const { holdingItemsList } = useSelector((state) => state.staff);

  return(
      <div className={'staff_register--body_infos_area'}>
          {StaffRegisterInfos.body.inputs.map((info, idx) => {
              return idx < 4 && (
                  <CustomFormikInput
                      formik={formik}
                      {...info}
                      key={info.inputName}
                  />);
          })}
          <CustomFormikInput
              formik={formik}
              {...StaffRegisterInfos.body.inputs[4]}
              initialValue={holdingItemsList?.map(h=>h?.value)}
              selectBoxOptions={holdingItemsList?.map(h=>h?.label)}
          />
      </div>
  )
});
export default Body;
