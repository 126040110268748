import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import prefectures from '../../constants/prefectures';
import options from '../../constants/options';
import Icons from '../../constants/icons';
import { store } from '../store';
import { globalActions } from './global';

const namingValidList = [
  'UserPreliminary1',
  'UserPreliminary2',
  'UserPreliminary3',
  'UserPreliminary4',
  'UserPreliminary5',
];

const CategorySearchParams = {
  namingClassificationId:
    'Index,UserPreliminary1,UserPreliminary2,UserPreliminary3,' +
    'UserPreliminary4,UserPreliminary5,Dept,',
  sort: 'priorityAsc',
  status: 1,
};

const initialSearchParams = {
  userId: null,
  name: '',
  deptId: null,
  mailAddress: '',
  auth: null,
  status: 99,
  namingClassificationId: null,
  namingId: [],
  offset: 0,
  count: 20,
  sort: 'userIdDesc',
  sortColumn: 'userId',
  sortMethod: 'Desc',
};

const initialState = {
  searchParams: initialSearchParams,
  // editParams: initialEditParams,
  searchCount: 0,
  totalCount: 0,
  activeCount: 0,
  namingCategoryList: [],
  namingList: [],
  deptList: [],
  userList: [],
  csvImportVisible: false,
  csvExportVisible: false,
  nextId: 0,
};

export const userInit = createAsyncThunk('user/init', async () => {
  const namingRes = await fetchRequest(
    apiUrl.naming.list,
    CategorySearchParams
  );

  return {
    namingCategoryList: namingRes?.item
      ?.sort((a, b) => (a.namingId < b.namingId ? -1 : 1))
      ?.filter(n => namingValidList.includes(n?.namingId ?? ''))
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    namingList: namingRes?.item
      ?.sort((a, b) => (a.namingId < b.namingId ? -1 : 1))
      ?.filter(
        n =>
          n?.namingClassificationId !== 'Index' &&
          n?.namingClassificationId !== 'Dept'
      )
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    deptList: namingRes?.item
      ?.filter(n => n?.namingClassificationId == 'Dept')
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
  };
});

export const userListFetch = createAsyncThunk('user/list', async params => {
  const [userRes, namingRes] = await Promise.all([
    fetchRequest(apiUrl.user.list, params ? params : { status: '0,1' }),
    fetchRequest(apiUrl.naming.list, CategorySearchParams),
  ]);
  const item = userRes?.item.map(c => {
    const dept = namingRes?.item
      ?.filter(n => n?.namingClassificationId === 'Dept')
      ?.find(v => v?.namingId === c?.deptId)?.naming;
    const reserve = namingRes?.item?.find(
      v => v?.namingId === c?.preliminary1?.namingId
    )?.naming;

    return {
      ...c,
      userId: `${c?.userId ?? ''}`,
      image: `${c?.userImage ?? ''} `,
      name: `${c?.name?.lastName ?? ''} ${c?.name?.firstName ?? ''} `,
      dept: `${dept ? dept : ''} `,
      mail: `${c?.mailAddress} `,
      authority:
        c?.auth === 0
          ? 'システム管理者'
          : c?.auth === 1
          ? '法人管理者'
          : '社員',
      reserve: `${reserve ? reserve : ''} `,
      status: c?.status === 1 ? '有効' : c?.status === 0 ? '無効' : '削除済',
      preliminary1: c?.preliminary1?.naming ?? '',
      editIconState: c?.status === 2 ? 1 : 0,
      deleteIconState: c?.status === 2 ? 2 : 0,
      deletedFlag: c?.deletedFlag === '0' ? 0 : c?.deletedFlag,
    };
  });
  return {
    userList: {
      item,
      count: userRes?.searchCount ?? 0,
      topCount: [userRes?.totalCount ?? 0, userRes?.activeCount ?? 0],
    },
  };
});

export const userDetailFetch = createAsyncThunk('user/detail', async params => {
  return await fetchRequest(apiUrl.user.detail, params);
});
// export const staffInfoFetch = createAsyncThunk('staff/info', async params => {
//   const [userRes, namingRes, bankRes] = await Promise.all([
//     fetchRequest(apiUrl.user.list),
//     fetchRequest(apiUrl.naming.list, CategorySearchParams),
//     fetchRequest(apiUrl.bank_code_jp, {
//       apiKey: '',
//       limit: 2000,
//     }),
//   ]);
//   const bankItem = bankRes?.data?.map(b => {
//     return {
//       label: b?.name,
//       value: b?.code,
//     };
//   });
//   return {
//     userList: userRes.item ?? [],
//     namingCategoryList: namingRes?.item
//       ?.filter(n => namingValidList.includes(n?.namingId ?? ''))
//       .map(n => {
//         return {
//           ...n,
//           value: n?.namingId,
//           label: n?.naming,
//         };
//       }),
//     namingList: namingRes?.item
//       ?.filter(n => n?.namingClassificationId !== 'Index')
//       .map(n => {
//         return {
//           ...n,
//           value: n?.namingId,
//           label: n?.naming,
//         };
//       }),
//     certificationsList: namingRes?.item
//       ?.filter(n => n?.namingClassificationId == 'Certification')
//       .map(n => {
//         return {
//           ...n,
//           value: n?.namingId,
//           label: n?.naming,
//         };
//       }),
//     retireReasonList: namingRes?.item
//       ?.filter(n => n?.namingClassificationId == 'RetireReason')
//       .map(n => {
//         return {
//           ...n,
//           value: n?.namingId,
//           label: n?.naming,
//         };
//       }),
//     recruitingMediaList: namingRes?.item
//       ?.filter(n => n?.namingClassificationId == 'RecruitingMedia')
//       .map(n => {
//         return {
//           ...n,
//           value: n?.namingId,
//           label: n?.naming,
//         };
//       }),
//     holdingItemsList: namingRes?.item
//       ?.filter(n => n?.namingClassificationId == 'Holdingitem')
//       .map(n => {
//         return {
//           ...n,
//           value: n?.namingId,
//           label: n?.naming,
//         };
//       }),
//     bankingList: bankItem ?? [],
//   };
// });
// export const bankingFetch = createAsyncThunk('banking/info', async params => {
//   const bankRes = await fetchRequest(
//     `${apiUrl.bank_code_jp}/${params}/branches`,
//     {
//       apiKey: '',
//       limit: 2000,
//     }
//   );
//   const branchesItem = bankRes?.data?.map(b => {
//     return {
//       label: b?.name,
//       value: b?.code,
//     };
//   });
//   return {
//     branchesList: branchesItem ?? [],
//   };
// });

export const userInsert = createAsyncThunk(
  'user/insert',
  async params => await fetchRequest(apiUrl.user.insert, params, 'POST')
);

export const uploadImage = createAsyncThunk(
  'user/upload/image',
  async params => await fetchRequest(apiUrl.other.imageUpload, params, 'POST')
);
// export const userInsert = createAsyncThunk(
//   'user/insert',
//   async params => await fetchRequest(apiUrl.user.insert, params, 'POST')
// );
// export const userUpdate = createAsyncThunk(
//   'user/update',
//   async params => await fetchRequest(apiUrl.user.update, params, 'POST')
// );

export const userDelete = createAsyncThunk(
  'user/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.user.delete, params, 'POST').then(() =>
      store.dispatch(globalActions.showSingleModal('削除しました。'))
    );
    dispatch(userActions.refresh());
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    setEdit: state => {
      state.editParam = { ...state.editParam };
    },
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvImportVisible: (state, { payload }) => {
      state.csvImportVisible = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    // editReset: (state, { payload }) => {
    //   state.editParams = initialEditParams;
    // },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    // bankReset: (state, { payload }) => {
    //   state.branchesList = [];
    // },
  },
  extraReducers: {
    [userInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [userListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    // [userDetailFetch.fulfilled]: (state, { payload }) => ({
    //   ...state,
    //   editParams: {
    //     staffId: payload?.detail?.staffId ?? '',
    //     lastName: payload?.detail?.staffName?.lastName ?? '',
    //     firstName: payload?.detail?.staffName?.firstName ?? '',
    //     lastNameKana: payload?.detail?.staffNameKana?.lastName ?? '',
    //     firstNameKana: payload?.detail?.staffNameKana?.firstName ?? '',
    //     mailAddress: payload?.detail?.mailAddress ?? '',
    //     startDate: payload?.detail?.startDate ?? '',
    //     endDate: payload?.detail?.endDate ?? '',
    //     staffImage: payload?.detail?.staffImage ?? '',
    //     year: payload?.detail?.birthday?.year ?? '',
    //     month: payload?.detail?.birthday?.month ?? '',
    //     day: payload?.detail?.birthday?.day ?? '',
    //     gender: payload?.detail?.birthday?.gender ?? 0,
    //     postCode1: payload?.detail?.postCode.number1 ?? '',
    //     postCode2: payload?.detail?.postCode.number2 ?? '',
    //     prefCode: payload?.detail?.prefCode ?? '',
    //     cityName: payload?.detail?.cityName ?? '',
    //     streetName: payload?.detail?.streetName ?? '',
    //     buildingName: payload?.detail?.buildingName ?? '',
    //     tel1: payload?.detail?.daytimeTel?.number1 ?? '',
    //     tel2: payload?.detail?.daytimeTel?.number2 ?? '',
    //     tel3: payload?.detail?.daytimeTel?.number3 ?? '',
    //     fax1: payload?.detail?.homeTel?.number1 ?? '',
    //     fax2: payload?.detail?.homeTel?.number2 ?? '',
    //     fax3: payload?.detail?.homeTel?.number3 ?? '',
    //     lineId: payload?.detail?.lineId ?? null,
    //     hopeAssign: payload?.detail?.hopeAssign ?? null,
    //     weekMethodFlag: payload?.detail?.weekMethodFlag ?? null,
    //     previousDayMethodFlag: payload?.detail?.previousDayMethodFlag ?? null,
    //     gettingUpMethodFlag: payload?.detail?.gettingUpMethodFlag ?? null,
    //     departureMethodFlag: payload?.detail?.departureMethodFlag ?? null,
    //     arriveMethodFlag: payload?.detail?.arriveMethodFlag ?? null,
    //     endMethodFlag: payload?.detail?.endMethodFlag ?? null,
    //     joinDate: payload?.detail?.joinDate ?? null,
    //     retireDate: payload?.detail?.retireDate ?? null,
    //     retireReason: payload?.detail?.retireReason ?? null,
    //     mainManagerUserId: payload?.detail?.mainManagerUserId ?? null,
    //     subManagerUserId: payload?.detail?.subManagerUserId ?? null,
    //     employmentType: payload?.detail?.employmentType ?? null,
    //     taxClassification: payload?.detail?.taxClassification ?? null,
    //     dependents: payload?.detail?.dependents ?? null,
    //     dailyPay: payload?.detail?.dailyPay ?? null,
    //     hourlyPay: payload?.detail?.hourlyPay ?? null,
    //     recruitMedia: payload?.detail?.recruitMedia ?? null,
    //     recruitCost: payload?.detail?.recruitCost ?? null,
    //     position: payload?.detail?.position ?? null,
    //     evaluation: payload?.detail?.evaluation ?? null,
    //     nearestStations: payload?.detail?.nearestStations ?? [],
    //     height: payload?.detail?.height ?? null,
    //     weight: payload?.detail?.weight ?? null,
    //     shoeSize: payload?.detail?.shoeSize ?? null,
    //     wearSize: payload?.detail?.wearSize ?? null,
    //     holdingItems: payload?.detail?.holdingItems ?? [],
    //     certifications: payload?.detail?.certifications ?? [],
    //     schoolName: payload?.detail?.schoolName ?? null,
    //     admissionYear: payload?.detail?.admissionYear ?? null,
    //     payMethod: payload?.detail?.payMethod ?? null,
    //     bankName: payload?.detail?.bankName ?? null,
    //     bankCode: payload?.detail?.bankCode ?? null,
    //     bankBranchName: payload?.detail?.bankBranchName ?? null,
    //     bankBranchCode: payload?.detail?.bankBranchCode ?? null,
    //     bankAccountFlag: payload?.detail?.bankAccountFlag ?? null,
    //     bankAccountNumber: payload?.detail?.bankAccountNumber ?? null,
    //     bankAccountName: payload?.detail?.bankAccountName ?? null,
    //     preliminary1: payload?.detail?.preliminary1 ?? null,
    //     preliminary2: payload?.detail?.preliminary2 ?? null,
    //     preliminary3: payload?.detail?.preliminary3 ?? null,
    //     preliminary4: payload?.detail?.preliminary4 ?? null,
    //     preliminary5: payload?.detail?.preliminary5 ?? null,
    //     note: payload?.detail?.note ?? null,
    //     createdUser: payload?.detail?.createdUser ?? {},
    //     updatedUser: payload?.detail?.updatedUser ?? {},
    //     createdStaff: payload?.detail?.createdStaff ?? {},
    //   },
    // }),
    // [staffInfoFetch.fulfilled]: (state, { payload }) => ({
    //   ...state,
    //   ...payload,
    // }),
    // [bankingFetch.fulfilled]: (state, { payload }) => ({
    //   ...state,
    //   ...payload,
    // }),
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
