const staffAttribute = {
  gender: {
    0: '男性',
    1: '女性',
  },
  age: {
    0: '10代',
    1: '20代',
    2: '30代',
    3: '40代',
    4: '50代',
    5: '60代以上',
  },
  profession: {
    0: '学生',
    1: 'その他(社会人、主婦など)',
  },
  position: {
    0: 'スタッフ',
    1: 'アシスタントディレクター',
    2: 'ディレクター',
  },
}
export default staffAttribute;