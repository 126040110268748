import { memo } from 'react';
import classNames from 'classnames';
import ReportSwitch from './reportSwitch';
import './style.scss';

const SwitchInput = memo(({ item, className, showSwitch = true, inputComponent, ...rest }) => (
  <div className={classNames('switch_input', className)}>
    <div className="switch_input--switch">
      <span className="switch_input--label">{item}</span>
      {showSwitch && <ReportSwitch {...rest} />}
    </div>
    <div className="switch_input--input">{inputComponent}</div>
  </div>
));

export default SwitchInput;
