import './style.scss';
import Icons from '../../constants/icons';
import { checkIsSp } from '../../utils/fnUtil';

const TitleBar = props => {
  const {
    icon,
    title,
    counter: [],
  } = props;

  const colors = {
    staff: '#5FCF98',
    client: '#4DA0EB',
    quote: '#4DA0EB',
    plan: '#CA7339',
    venue: '#4DA0EB',
    employee: '#AAAAAA',
    matter: '#4DA0EB',
    corporate: '#AAAAAA',
    invoice: '#4DA0EB',
    payTerm: '#AAAAAA',
    naming: '#AAAAAA',
    setting: '#AAAAAA',
    partner: '#AAAAAA',
    staffPay: '#5FCF98',
    myPage: '#5FCF98',
    staffShift: '#5FCF98',
    attendance: '#4DA0EB',
    staffPayDetail: '#5FCF98',
    analyze: '#003BA5',
    user: '#AAAAAA',
    survey: '#4DA0EB',
  };

  return (
    <div
      className={'title_bar--base'}
      style={{ backgroundColor: colors[icon] }}
    >
      <div className={'title_bar--icon_title_area'}>
        <img src={Icons.icon[icon]} alt={'Icon'} />
        <p>{title}</p>
      </div>

      {props.counter && checkIsSp() ? (
        <div className={'title_bar--counter_area'}>
          {props.counter.map((ary, index) => {
            return (
              <p key={index}>
                {ary[0]}
                <span>{ary[1]}</span>
              </p>
            );
          })}
        </div>
      ) : (
        <div className={'title_bar--counter_area'}>
          {props.counter.map((str, index) => {
            return <p key={index}>{str}</p>;
          })}
        </div>
      )}
    </div>
  );
};

export default TitleBar;
