import './style.scss';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import SurveySearchInputs from './searchInputs';
import { useEffect } from 'react';
import {
  surveyActions,
  surveyDelete,
  surveyListFetch,
} from '../../../redux/slice/survey';
import Icons from '../../../constants/icons';
import Copyright from '../../../components/copyright';
import { useNavigate } from 'react-router-dom';
import SurveyListView from '../customListView';
import Options from '../../../constants/options';
import moment from 'moment';
import CsvExportModal from '../../../components/modal/csvExportModal';

const SurveyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { searchParams, csvExportVisible, surveyList } = useSelector(
    state => state.survey
  );

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      if (!values.searchDateFrom) {
        values.searchDateFrom = null;
      }
      if (!values.searchDateTo) {
        values.searchDateTo = null;
      }
      dispatch(
        surveyActions.saveSearchParams({
          ...values,
          count: 20,
          offset: 0,
        })
      );
    },
  });

  const { sortKey, sortMethod } = formik.values;

  const surveyListSearchArea = {
    jsx: () => (
      <div className="survey_search_area">
        {SurveySearchInputs.map((input, index) => {
          const { inputName } = input;
          const disabledFutureDate =
            index === 1
              ? moment(formik.values.searchDateTo, 'YYYY/MM/DD').format(
                  'YYYY/MM/DD'
                )
              : undefined;
          const disabledDate =
            index === 2
              ? moment(formik.values.searchDateFrom, 'YYYY/MM/DD').format(
                  'YYYY/MM/DD'
                )
              : undefined;
          return (
            <CustomFormikInput
              key={inputName}
              {...input}
              formik={formik}
              disabledFutureDate={disabledFutureDate}
              disabledDate={disabledDate}
            />
          );
        })}
      </div>
    ),
  };

  const columnSettings = {
    surveyId: { label: 'アンケートID', sort: 'none', dataName: 'survey_id' },
    surveyTitle: { label: 'タイトル', sort: 'none', dataName: 'surveyTitle' },
    surveyDateFromTo: {
      label: '実施期間',
      sort: 'none',
      dataName: 'survey_date_from_to',
    },
    surveyInfos: {
      label: 'アンケート詳細',
      sort: 'none',
      dataName: 'survey_infos',
    },
    surveyType: {
      label: 'アンケート種別',
      sort: 'none',
      dataName: 'survey_type',
    },
    surveyTargetCount: {
      label: '対象者数/対象目安人数',
      sort: 'none',
      dataName: 'survey_target_count',
    },
    targetCount: {
      label: '対象者数',
      sort: 'none',
      dataName: 'targetCount',
    },
    batchCount: {
      label: '対象目安人数',
      sort: 'none',
      dataName: 'batchCount',
    },
    surveyAnswerTotalCount: {
      label: '回答者数',
      sort: 'none',
      dataName: 'survey_answer_total_count',
    },
    surveyAnswerTotalCountRate: {
      label: '回答率',
      sort: 'none',
      dataName: 'survey_answer_total_count_rate',
    },
    surveyRetireTotalCountRate: {
      label: '離脱率',
      sort: 'none',
      dataName: 'survey_retire_total_count_rate',
    },
    status: { label: 'ステータス', sort: 'none', dataName: 'status' },
    statics: [
      { label: 'CSV', icon: Icons.icon.export },
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => dispatch(surveyActions.setCsvExportVisible(true)),
      style: Options.buttonStyles.csv,
    },
    addButton: {
      props: {
        type: '',
        onClick: () => {
          navigate('/survey/register');
        },
        displayText: true,
      },
    },
  };

  const changeSort = e => {
    dispatch(
      surveyActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
      })
    );

    dispatch(
      surveyActions.saveSearchParams({
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`
      })
    );
  };

  const handleChangeListPerPage = num => {
    dispatch(surveyActions.saveSearchParams({ count: num }));
  };

  const handleChangePage = num => {
    dispatch(surveyActions.saveOffset(num));
  };

  useEffect(() => {
    dispatch(surveyListFetch(searchParams));
  }, [dispatch, searchParams]);

  return (
    <>
      <div className={'commonPage--base'}>
        <SurveyListView
          searchFormInfo={surveyListSearchArea}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'surveyId'}
          primaryName={'surveyTitle'}
          apiData={JSON.parse(JSON.stringify(surveyList))}
          sortSettings={{ sortColumn: sortKey, sortMethod: sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={handleChangeListPerPage}
          onChangeOffset={handleChangePage}
          onDelete={surveyId =>
            dispatch(surveyDelete({ surveyId: String(surveyId) }))
          }
          defaultOpened={false}
          displayNotice={false}
        />
        <Copyright />
      </div>

      <CsvExportModal
        exportType={'surveyList'}
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={() =>
          dispatch(surveyActions.setCsvExportVisible(!csvExportVisible))
        }
        exportFunction={() => {}}
        searchParams={searchParams}
      />
    </>
  );
};

export default SurveyList;
