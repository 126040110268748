import { createSlice } from '@reduxjs/toolkit';
import Options from '../../constants/options';

const initialModalConfig = {
  title: '',
  message: '',
  tip: '',
  buttons: [],
  deleteModal: false,
};

const initialState = {
  globalModalConfig: initialModalConfig,
  activeTab: '',
  loading: false,
  multiLoading: false,
  primaryLoading: false,
  sort: {
    column: '',
    method: '',
  },
  quoteNo: false,
  cutOffStaffPay: false,
  isOpenSideMenu: false,
  navigateFromDashboard: null,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changeSort: (state, { payload }) => {
      state.sort.column = payload.column;
      state.sort.method = payload.method;
    },
    changeActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    showSingleModal: (state, { payload }) => {
      state.globalModalConfig = {
        title: payload,
        message: '',
        tip: '',
        buttons: [
          {
            btnText: '閉じる',
            style: Options.buttonStyles.back,
          },
        ],
      };
    },
    showErrorModal: (state, { payload }) => {
      state.globalModalConfig = {
        title: payload.title,
        message: payload.message,
        tip: '',
        buttons: [
          {
            btnText: '閉じる',
            btnTheme: 'black',
          },
        ],
      };
    },
    showSelectModal: (state, { payload }) => {
      state.globalModalConfig = {
        title: payload.title,
        message: payload.message,
        tip: payload.tip,
        buttons: payload.buttons.map((btn, i) => ({
          btnText: btn.btnText || (i === 0 ? 'はい' : 'いいえ'),
          btnAction: btn.btnAction,
          style: btn.style,
        })),
      };
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setMultiLoading: (state, { payload }) => {
      state.multiLoading = payload;
    },
    setPrimaryLoading: (state, { payload }) => {
      state.primaryLoading = payload;
    },
    loadingOff: state => {
      state.loading = false;
      state.multiLoading = false;
      state.primaryLoading = false;
    },
    reset: state => {
      state.globalModalConfig = initialModalConfig;
    },
    setQuoteNoModal: (state, { payload }) => {
      state.quoteNo = payload;
    },
    setCutOffModal: (state, { payload }) => {
      state.cutOffStaffPay = payload;
    },
    toggleIsOpenSideMenu: (state, { payload }) => {
      state.isOpenSideMenu = payload;
    },
    setNavigateFromDashboard: (state, { payload }) => {
      state.navigateFromDashboard = payload;
    },
  },
  extraReducers: {},
});

export const globalActions = globalSlice.actions;
export default globalSlice.reducer;
