import './style.scss';
import RegisterBase from '../../../components/registerBase';
import React, { memo, useEffect, useState } from 'react';
import Button from '../../../components/button';
import { useLocation } from 'react-router-dom';
import Options from '../../../constants/options';
import prefectures from '../../../constants/prefectures';
import { useNavigate } from 'react-router-dom';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { handlerFormikSubmit } from '../../../utils/fnUtil';
import { useSelector } from 'react-redux';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import { fetchApi, fetchRequest, zipApi } from '../../../api/fetch';
import { useFormik } from 'formik';
import Yup from '../../../utils/yupUtil';
import { apiUrl } from '../../../api/apiUrl';
import moment from 'moment/moment';
import { checkIsSp } from '../../../utils/fnUtil';

const isSp = checkIsSp();

const style = {
  labelStyle: { width: isSp ? '100%' : '175px' },
  inputStyle: { width: isSp ? '100%' : '55%' },
};

const tabStyle = {
  width: '100%',
  margin: '40px 0 0 0',
  display: 'flex',
  justifyContent: 'flex-start',
  flexFlow: 'column',
  gap: '24px 8px',
};

const PartnerRegister = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.pathname.slice(5).includes('edit');
  const loginType = useSelector(state => state.loginType.type);
  const account = useSelector(state => state.account);

  const [userList, setUserList] = useState([]);

  if (!account.menuPartnerListFlag) {
    navigate('/');
    store.dispatch(
      globalActions.showSingleModal('ご利用いただけないメニューです')
    );
  }

  const nowDate = new Date();

  const formik = useFormik({
    initialValues: {
      createdUser: {
        id: '',
        name: '',
        datetime: '',
      },
      updatedUser: {
        id: '',
        name: '',
        datetime: '',
      },
      image: '',
      partnerCompanyId: '',
      partnerCompanyName: '',
      partnerCompanyNameKana: '',
      startDate: !isEdit && moment(nowDate).format('YYYY/M/D'),
      endDate: !isEdit && '2999/12/31',
      zip1: '',
      zip2: '',
      prefCode: null,
      cityName: '',
      streetName: '',
      buildingName: '',
      tel1: '',
      tel2: '',
      tel3: '',
      fax1: '',
      fax2: '',
      fax3: '',
      partnerCompanyManagerName: '',
      managerUserId: null,
      note: '',
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      partnerCompanyId: Yup.string().required().integer().label('協力会社ID'),
      partnerCompanyName: Yup.string().required().label('協力会社名'),
      partnerCompanyNameKana: Yup.string()
        .required()
        .kana('協力会社名(カナ)')
        .label('協力会社名(カナ)'),
      startDate: Yup.string().required().label('利用開始日'),
      endDate: Yup.string().required().label('利用終了日'),
      zip1: Yup.string().nullable().integer('[基本情報]郵便番号'),
      zip2: Yup.string().nullable().integer('[基本情報]郵便番号'),
      tel1: Yup.string().nullable().integer('[基本情報]TEL'),
      tel2: Yup.string().nullable().integer('[基本情報]TEL'),
      tel3: Yup.string().nullable().integer('[基本情報]TEL'),
      fax1: Yup.string().nullable().integer('[基本情報]FAX'),
      fax2: Yup.string().nullable().integer('[基本情報]FAX'),
      fax3: Yup.string().nullable().integer('[基本情報]FAX'),
    }),
    onSubmit: values => {
      let postParams = {
        ...values,
        partnerCompanyId: isEdit
          ? location?.state?.id ?? null
          : values.partnerCompanyId,
        postCode:
          values.zip1 && values.zip2
            ? { number1: values.zip1, number2: values.zip2 }
            : null,
        tel:
          values.tel1 && values.tel2 && values.tel3
            ? {
                number1: values.tel1,
                number2: values.tel2,
                number3: values.tel3,
              }
            : null,
        fax:
          values.fax1 && values.fax2 && values.fax3
            ? {
                number1: values.fax1,
                number2: values.fax2,
                number3: values.fax3,
              }
            : null,
      };

      for (let k in postParams) {
        if (
          postParams[k] === '' ||
          postParams[k] === null ||
          postParams[k] === undefined
        ) {
          delete postParams[k];
        }
      }

      // postParams.startDate = postParams.startDate.replace(/\//g, '-');
      // postParams.endDate = postParams.endDate.replace(/\//g, '-');

      fetchRequest(
        isEdit ? apiUrl.partner.update : apiUrl.partner.insert,
        postParams,
        'POST'
      )
        .then(() => {
          store.dispatch(
            globalActions.showSingleModal(
              isEdit ? '更新しました' : '登録しました'
            )
          );
          navigate('/master/partner/list');
        })
        .catch(() => {
          store.dispatch(globalActions.showErrorModal('失敗しました'));
        });
    },
  });

  const imageInputChange = e => {
    let fileData = e.target.files[0];

    if (fileData) {
      if (fileData.size > 1024 * 1024) {
        alert('画像は1MB以下でアップロードしてください。');
      } else {
        const reader = new FileReader();
        reader.onload = async event => {
          const base64Text = event.currentTarget.result;

          const body = {
            userId: account.userId,
            image: base64Text,
            contentFlag: '3',
          };

          store.dispatch(globalActions.setLoading(true));

          await fetchApi(
            'other',
            'imageUpload',
            'POST',
            loginType,
            account.corporateId,
            account.accessToken,
            body
          ).then(data => formik.setFieldValue('image', data.imageUrl));

          store.dispatch(globalActions.setLoading(false));
        };
        reader.readAsDataURL(fileData);
      }
    }
  };

  const Common = () => (
    <div style={tabStyle}>
      <div
        className={'tel_area_for_CFI'}
        style={isSp ? { width: '100%' } : { width: '460px' }}
      >
        <CustomFormikInput
          inputType="input"
          label="郵便番号"
          inputName={'zip1'}
          key={'zip1'}
          formik={formik}
          placeholder={'123'}
          unit={'-'}
          style={
            isSp
              ? {
                  areaStyle: {
                    width: 'calc(((100% - 40px) / 2) + 20px)',
                  },
                  inputStyle: { marginRight: '8px' },
                  unitStyle: { width: '6px' },
                }
              : {
                  labelStyle: { width: '175px' },
                  inputStyle: {
                    width: 'calc(((460px - 175px - 24px) - 18.86px) / 2)',
                  },
                }
          }
        />
        <CustomFormikInput
          inputType="input"
          label=" "
          inputName={'zip2'}
          key={'zip2'}
          formik={formik}
          placeholder={'4567'}
          style={
            isSp
              ? {
                  areaStyle: {
                    width: 'calc(((100% - 40px) / 2) + 20px)',
                    paddingLeft: '8px',
                  },
                }
              : {
                  labelStyle: { display: 'none' },
                  inputStyle: {
                    width: 'calc(((460px - 175px - 24px) - 18.86px) / 2)',
                  },
                }
          }
        />
      </div>
      <CustomFormikInput
        inputType="select"
        label="都道府県"
        inputName="prefCode"
        initialValue={prefectures.map(p => p.prefecturesCode)}
        selectBoxOptions={prefectures.map(p => p.label)}
        key={'prefCode'}
        formik={formik}
        placeholder="都道府県"
        style={{
          areaStyle: { width: isSp ? '100%' : '360px' },
          labelStyle: { width: '175px' },
        }}
      />
      <CustomFormikInput
        inputType="input"
        label="市区町村"
        inputName="cityName"
        key={'cityName'}
        formik={formik}
        placeholder="市区町村を入力してください"
        style={style}
      />
      <CustomFormikInput
        inputType="input"
        label="丁目番地"
        inputName="streetName"
        key={'streetName'}
        formik={formik}
        placeholder="丁目番地を入力してください"
        style={style}
      />
      <CustomFormikInput
        inputType="input"
        label="建物名"
        inputName="buildingName"
        key={'buildingName'}
        formik={formik}
        placeholder="建物名を入力してください"
        style={style}
      />
      <div
        className={'tel_area_for_CFI'}
        style={{ width: isSp ? '100%' : '600px' }}
      >
        <CustomFormikInput
          inputType="input"
          label="TEL"
          inputName={'tel1'}
          key={'tel1'}
          formik={formik}
          placeholder={'03'}
          unit={'-'}
          style={
            isSp
              ? {
                  areaStyle: {
                    width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                  },
                }
              : {
                  labelStyle: { width: '175px' },
                  inputStyle: {
                    width: 'calc(((600px - 175px - 24px) - 18.86px * 2) / 3)',
                  },
                }
          }
        />
        <CustomFormikInput
          inputType="input"
          label={' '}
          inputName={'tel2'}
          key={'tel2'}
          formik={formik}
          placeholder={'0000'}
          unit={'-'}
          style={
            isSp
              ? {
                  areaStyle: {
                    width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                  },
                }
              : {
                  labelStyle: { display: 'none' },
                  inputStyle: {
                    width: 'calc(((600px - 175px - 24px) - 18.86px * 2) / 3)',
                  },
                }
          }
        />
        <CustomFormikInput
          inputType="input"
          label={' '}
          inputName={'tel3'}
          key={'tel3'}
          formik={formik}
          placeholder={'0000'}
          style={
            isSp
              ? {
                  areaStyle: {
                    width: 'calc(((100% - 18.86px * 2) / 3))',
                  },
                }
              : {
                  labelStyle: { display: 'none' },
                  inputStyle: {
                    width: 'calc(((600px - 175px - 24px) - 18.86px * 2) / 3)',
                  },
                }
          }
        />
      </div>
      <div
        className={'tel_area_for_CFI'}
        style={{ width: isSp ? '100%' : '600px' }}
      >
        <CustomFormikInput
          inputType="input"
          label="FAX"
          inputName={'fax1'}
          key={'fax1'}
          formik={formik}
          placeholder={'03'}
          unit={'-'}
          style={
            isSp
              ? {
                  areaStyle: {
                    width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                  },
                }
              : {
                  labelStyle: { width: '175px' },
                  inputStyle: {
                    width: 'calc(((600px - 175px - 24px) - 18.86px * 2) / 3)',
                  },
                }
          }
        />
        <CustomFormikInput
          inputType="input"
          label={' '}
          inputName={'fax2'}
          key={'fax2'}
          formik={formik}
          placeholder={'0000'}
          unit={'-'}
          style={
            isSp
              ? {
                  areaStyle: {
                    width: 'calc(((100% - 18.86px * 2) / 3) + 18.86px)',
                  },
                }
              : {
                  labelStyle: { display: 'none' },
                  inputStyle: {
                    width: 'calc(((600px - 175px - 24px) - 18.86px * 2) / 3)',
                  },
                }
          }
        />
        <CustomFormikInput
          inputType="input"
          label={' '}
          inputName={'fax3'}
          key={'fax3'}
          formik={formik}
          placeholder={'0000'}
          style={
            isSp
              ? {
                  areaStyle: {
                    width: 'calc(((100% - 18.86px * 2) / 3))',
                  },
                }
              : {
                  labelStyle: { display: 'none' },
                  inputStyle: {
                    width: 'calc(((600px - 175px - 24px) - 18.86px * 2) / 3)',
                  },
                }
          }
        />
      </div>
      <CustomFormikInput
        inputType={'input'}
        label={'協力会社担当者'}
        inputName={'partnerCompanyManagerName'}
        key={'partnerCompanyManagerName'}
        placeholder={'協力会社担当者を入力してください'}
        style={style}
        formik={formik}
      />
      <CustomFormikInput
        inputType={'select'}
        label={'社内担当者'}
        inputName={'managerUserId'}
        key={userList[0] ? userList : 'managerUserId'}
        placeholder={'社内担当者を入力してください'}
        style={{
          labelStyle: { width: '175px' },
          areaStyle: {
            width: isSp ? '100%' : 'calc((100% - 200px) * 0.55 + 200px)',
          },
        }}
        formik={formik}
        initialValue={
          userList[0]
            ? userList
                ?.filter(u => u.deletedFlag === '0')
                ?.map(obj => obj.userId)
            : []
        }
        selectBoxOptions={
          userList[0]
            ? userList
                ?.filter(u => u.deletedFlag === '0')
                ?.map(
                  obj =>
                    `${obj.userId}:${obj.name.lastName} ${obj.name.firstName}`
                )
            : []
        }
      />
      <CustomFormikInput
        inputType={'textBox'}
        label={'備考'}
        inputName={'note'}
        key={'note'}
        placeholder={'備考を入力してください'}
        style={{
          labelStyle: { width: '175px' },
          areaStyle: {
            width: isSp ? '100%' : '80%',
          },
        }}
        formik={formik}
      />
    </div>
  );

  const infos = {
    ignoreConvert: true,
    updates: isEdit
      ? [
          {
            label: '新規登録',
            datetime:
              moment(formik?.values?.createdUser?.datetime).format(
                'YYYY/MM/DD hh:mm'
              ) ?? '-',
            name: formik?.values?.createdUser?.name,
          },
          {
            label: '最終更新',
            datetime:
              moment(formik?.values?.updatedUser?.datetime).format(
                'YYYY/MM/DD hh:mm'
              ) ?? '-',
            name: formik?.values?.updatedUser?.name,
          },
        ]
      : [],
    basic: {
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '24px 16px',
      },
      inputs: [
        {
          inputType: isEdit ? 'label' : 'input', //inputのtype
          label: '協力会社ID', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'partnerCompanyId', //DBのカラム名
          placeholder: isEdit ? '' : '協力会社IDを入力してください',
          key: 'partnerCompanyId',
          className: {areaClass: isSp && isEdit ? 'partner_register--partner_company_id_field' : ''},
          style: {
            labelStyle: { width: isSp ? 'auto' : '120px' },
            inputStyle: {}
          },
        },
        {
          inputType: 'input', //inputのtype
          label: '協力会社名', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'partnerCompanyName', //DBのカラム名
          placeholder: '協力会社名を入力してください',
          key: 'partnerCompanyName',
          style: {
            labelStyle: { width: '120px' },
          },
        },
        {
          inputType: 'input', //inputのtype
          label: '協力会社名(カナ)', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'partnerCompanyNameKana', //DBのカラム名
          placeholder: '協力会社名(カナ)を入力してください',
          key: 'partnerCompanyNameKana',
          style: {
            labelStyle: { width: '120px' },
          },
        },
        {
          inputType: 'inputDate', //inputのtype
          label: '開始日', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'startDate', //DBのカラム名
          placeholder: 'YYYY/MM/DD',
          key: `startDate`,
          disabledFutureDate: formik.values.endDate ?? null,
          style: {
            areaStyle: { width: isSp ? '50%' : '280px' },
            labelStyle: { width: '120px' },
          },
        },
        {
          inputType: 'inputDate', //inputのtype
          label: '終了日', //ラベルの名前
          labelRequired: true, //ラベルの※マークの有無
          inputName: 'endDate', //DBのカラム名
          placeholder: 'YYYY/MM/DD',
          key: 'endDate',
          disabledDate: formik.values.startDate ?? null,
          style: {
            areaStyle: { width: isSp ? '50%' : '280px' },
            labelStyle: { width: '120px' },
          },
        },
      ],
    },
    detail: {
      tabs: {
        common: {
          label: '基本情報',
          jsx: Common,
        },
      },
    },
  };

  const setFormikData = data => {
    let formatData = {
      ...data,
      zip1: data?.postCode?.number1,
      zip2: data?.postCode?.number2,
      tel1: data?.tel?.number1,
      tel2: data?.tel?.number2,
      tel3: data?.tel?.number3,
      fax1: data?.fax?.number1,
      fax2: data?.fax?.number2,
      fax3: data?.fax?.number3,
    };

    formik.setValues(formatData);
    formik.setFieldValue('partnerCompanyId', location.state.id);
  };

  const getDetailValues = async () => {
    const pId = location.state.id;

    store.dispatch(globalActions.setLoading(true));

    await fetchApi(
      'partner',
      'detail',
      'GET',
      loginType,
      account.corporateId,
      account.accessToken,
      {
        partnerId: pId,
        deletedFlag: location.state?.deletedFlag ?? 0,
      }
    ).then(data => {
      setFormikData(data.detail);
    });
  };

  const getUserListAndDefaultId = async () => {
    store.dispatch(globalActions.setLoading(true));

    await fetchApi(
      'user',
      'list',
      'GET',
      loginType,
      account.corporateId,
      account.accessToken,
      { offset: 0 }
    )
      .then(data => {
        setUserList(data.item);
      })
      .catch(error => {
        console.log(error);
        store.dispatch(
          globalActions.showErrorModal(
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
          )
        );
      });

    if (!isEdit) {
      await fetchApi(
        'partner',
        'list',
        'GET',
        loginType,
        account.corporateId,
        account.accessToken,
        { count: 1, offset: 0, sort: 'partnerIdDesc' }
      )
        .then(data =>
          formik.setFieldValue(
            'partnerCompanyId',
            data?.item[0]?.partnerId ? data?.item[0]?.partnerId + 1 : 1
          )
        )
        .catch(error => {
          console.log(error);
          store.dispatch(
            globalActions.showErrorModal(
              '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
            )
          );
        });
    }

    store.dispatch(globalActions.setLoading(false));
  };

  // 住所検索
  useEffect(() => {
    zipApi(formik, 'zip1', 'zip2', 'prefCode', 'cityName', 'streetName');
  }, [formik.values.zip1, formik.values.zip2]);

  useEffect(() => {
    getUserListAndDefaultId();

    if (isEdit) {
      getDetailValues().then(() =>
        store.dispatch(globalActions.setLoading(false))
      );
    }
  }, []);

  useEffect(() => {
    const basicInfoImageAreaWrap = document.querySelector('.register_base--basic_info_image_area_wrap');
    basicInfoImageAreaWrap.style.backgroundColor = '#F3F3F3';
  },[]);

  return (
    <>
      <RegisterBase
        image={formik?.values?.image}
        setImage={imageInputChange}
        infos={infos}
        formik={formik}
      />
      <div className={'bottom_btn_area'} style={{ height: '92px' }}>
        <div
          className={'partner_register--submit_buttons'}
          style={
            isEdit && location.state.deletedFlag == 0
              ? { marginTop: '24px', justifyContent: 'center' }
              : { marginTop: '24px', alignItems: 'center', justifyContent: 'center' }
          }
        >
          <Button
            text={'戻る'}
            style={Options.buttonStyles.back}
            onClick={() => navigate('/master/partner/list')}
          />
          {!isEdit ? (
            <Button
              text={'登録'}
              style={Options.buttonStyles.submit}
              onClick={() => {
                handlerFormikSubmit(formik);
              }}
            />
          ) : location.state.deletedFlag == 0 ? (
            <Button
              text={'更新'}
              style={Options.buttonStyles.submit}
              onClick={() => {
                handlerFormikSubmit(formik);
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
});

export default PartnerRegister;
