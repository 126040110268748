import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import Button from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import StaffRegisterInfos from '../inputInfos';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Yup from '../../../utils/yupUtil';
import {
  checkIsSp,
  handlerFormikFieldChange,
  handlerFormikSubmit,
} from '../../../utils/fnUtil';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { globalActions } from '../../../redux/slice/global';
import BasicInfos from '../register/basicInfos';
import AccessInfos from '../register/accessInfos';
import BodyInfos from '../register/bodyInfos';
import BackGroundInfos from '../register/backgroundInfos';
import SalaryInfos from '../register/salaryInfos';
import AttributeInfos from '../register/attributeInfos';
import {
  bankingCodeFetch,
  bankingFetch,
  staffActions,
  staffDetailFetch,
  staffInfoFetch,
  staffInsert,
  staffUpdate,
} from '../../../redux/slice/staff';
import { uploadImage } from '../../../redux/slice/client';
import moment from 'moment';

const StaffEdit = () => {
  //ログイン情報やページングに必要な設定
  const loginType = useSelector(state => state.loginType.type);
  const isStaff = loginType == 'staff';
  //const isStaff = true;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const accountInfo = useSelector(state => state.account);
  const { editParams } = useSelector(state => state.staff);
  //formik設定と登録API マイページと切り替える必要あり
  const tabErrorGroup = {
    common: [
      'subManagerName',
      'postCode',
      'postCode1',
      'postCode2',
      'tel1',
      'tel2',
      'tel3',
      'fax1',
      'fax2',
      'fax3',
      'hopeAssign',
      'dependents',
      'dailyPay',
      'hourlyPay',
      'recruitCost',
    ],
    body: ['weight', 'shoeSize'],
    skill: ['admissionYear'],
    pay: ['bankCode', 'bankAccountNumber'],
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editParams,
    validateOnMount: true,
    validationSchema: Yup.object({
      staffId: Yup.string()
        .nullable()
        .required()
        .matches(/^\d*$/, 'スタッフID:半角数字を入力してください。')
        .max(8, 'スタッフID:8桁以下で入力してください')
        .label('スタッフID'),
      lastName: Yup.string().nullable().required().label('名前 姓'),
      firstName: Yup.string().nullable().required().label('名前 名'),
      lastNameKana: Yup.string()
        .nullable()
        .required()
        .kana('名前(カナ)セイ')
        .label('名前(カナ)セイ'),
      firstNameKana: Yup.string()
        .nullable()
        .required()
        .kana('名前(カナ)セイ')
        .label('名前(カナ)メイ'),
      mailAddress: Yup.string()
        .nullable()
        .required()
        .mail('メールアドレス')
        .mail_local('メールアドレス')
        .label('メールアドレス'),
      startDate: Yup.string().nullable().required().label('利用開始日'),
      endDate: Yup.string().nullable().required().label('利用終了日'),
      // bankCode: Yup.string()
      //   .when('bankName', (value, schema) =>
      //     value === 'ゆうちょ銀行'
      //       ? schema.max(0, '銀行コード:未記入にしてください。')
      //       : schema
      //   )
      //   .nullable()
      //   .label('金融機関名'),
      bankAccountNumber: Yup.string()
        .when('bankName', (value, schema) =>
          value === 'ゆうちょ銀行' ? schema.required() : schema.nullable()
        )
        .label('口座番号'),
      postCode: Yup.string().nullable().postCode('[基本情報]郵便番号'),
      postCode1: Yup.string().nullable().integer('[基本情報]郵便番号'),
      postCode2: Yup.string().nullable().integer('[基本情報]郵便番号'),
      tel: Yup.string().nullable().tel('[基本情報]TEL(スマートフォン)'),
      tel1: Yup.string().nullable().integer('[基本情報]TEL(スマートフォン)'),
      tel2: Yup.string().nullable().integer('[基本情報]TEL(スマートフォン)'),
      tel3: Yup.string().nullable().integer('[基本情報]TEL(スマートフォン)'),
      fax: Yup.string().nullable().fax('[基本情報]TEL(自宅)'),
      fax1: Yup.string().nullable().integer('[基本情報]TEL(自宅)'),
      fax2: Yup.string().nullable().integer('[基本情報]TEL(自宅)'),
      fax3: Yup.string().nullable().integer('[基本情報]TEL(自宅)'),
      hopeAssign: Yup.string().nullable().integer('[基本情報]稼働希望日数'),
      dependents: Yup.string().nullable().integer('[基本情報]扶養人数'),
      dailyPay: Yup.string().nullable().integer('[基本情報]基本日給'),
      hourlyPay: Yup.string().nullable().integer('[基本情報]基本時給'),
      recruitCost: Yup.string().nullable().integer('[基本情報]採用単価'),
      weight: Yup.string().nullable().integer('[身長・体重など]体重(kg)'),
      shoeSize: Yup.string()
        .nullable()
        .shoeSize('[身長・体重など]靴のサイズ(cm)'),
      admissionYear: Yup.string().nullable().integer('入学年'),
      // subManagerUserId: Yup.string().nullable().identical('[基本情報]サブ担当者'),
    }),
    onSubmit: values => {
      dispatch(
        staffUpdate({
          ...values,
          nameKana: {
            firstName: values.firstNameKana,
            lastName: values.lastNameKana,
          },
          name: {
            firstName: values.firstName,
            lastName: values.lastName,
          },
          birthday:
            values.year && values.month && values.day
              ? {
                  year: values.year,
                  month: values.month,
                  day: values.day,
                }
              : null,
          postCode:
            values.postCode1 && values.postCode2
              ? {
                  number1: values.postCode1,
                  number2: values.postCode2,
                }
              : null,
          homeTel:
            values.fax1 && values.fax2 && values.fax3
              ? {
                  number1: values.fax1,
                  number2: values.fax2,
                  number3: values.fax3,
                }
              : null,
          daytimeTel:
            values.tel1 && values.tel2 && values.tel3
              ? {
                  number1: values.tel1,
                  number2: values.tel2,
                  number3: values.tel3,
                }
              : null,
          sendMailFlag: values.sendMailFlag ? 1 : 0,
        })
      )
        .then(result => {
          if (result?.payload?.code === '0') {
            dispatch(globalActions.showSingleModal(`更新しました`));
            dispatch(staffActions.editReset());
            navigate(-1);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
  });
  const { sendMailFlag, staffImage, startDate, endDate } = formik.values;

  useEffect(() => {
    const targetElm = document.querySelector('.register_base--basic_info_image_area_wrap_overlay');
    targetElm.style.borderRadius = '50%';
    dispatch(staffActions.resetParam());
    dispatch(staffInfoFetch());
    dispatch(bankingCodeFetch());
    dispatch(
      staffDetailFetch({
        staffId: location?.state?.id,
        deletedFlag: location.state.deletedFlag,
      })
    )
      .unwrap()
      .then(result => {
        formik.setValues({
          staffId: result?.detail?.staffId ?? null,
          lastName: result?.detail?.staffName?.lastName ?? null,
          firstName: result?.detail?.staffName?.firstName ?? null,
          lastNameKana: result?.detail?.staffNameKana?.lastName ?? null,
          firstNameKana: result?.detail?.staffNameKana?.firstName ?? null,
          mailAddress: result?.detail?.mailAddress ?? null,
          startDate: result?.detail?.startDate ?? null,
          endDate: result?.detail?.endDate ?? null,
          staffImage: result?.detail?.staffImage ?? null,
          year: result?.detail?.birthday?.year ?? null,
          month: result?.detail?.birthday?.month ?? null,
          day: result?.detail?.birthday?.day ?? null,
          gender: result?.detail?.gender ?? 0,
          postCode1: result?.detail?.postCode?.number1 ?? null,
          postCode2: result?.detail?.postCode?.number2 ?? null,
          prefCode: result?.detail?.prefCode ?? null,
          cityName: result?.detail?.cityName ?? null,
          streetName: result?.detail?.streetName ?? null,
          buildingName: result?.detail?.buildingName ?? null,
          tel1: result?.detail?.daytimeTel?.number1 ?? null,
          tel2: result?.detail?.daytimeTel?.number2 ?? null,
          tel3: result?.detail?.daytimeTel?.number3 ?? null,
          fax1: result?.detail?.homeTel?.number1 ?? null,
          fax2: result?.detail?.homeTel?.number2 ?? null,
          fax3: result?.detail?.homeTel?.number3 ?? null,
          lineId: result?.detail?.lineId ?? null,
          hopeAssign: result?.detail?.hopeAssign ?? null,
          weekMethodFlag: result?.detail?.weekMethodFlag ?? null,
          previousDayMethodFlag: result?.detail?.previousDayMethodFlag ?? null,
          gettingUpMethodFlag: result?.detail?.gettingUpMethodFlag ?? null,
          departureMethodFlag: result?.detail?.departureMethodFlag ?? null,
          arriveMethodFlag: result?.detail?.arriveMethodFlag ?? null,
          endMethodFlag: result?.detail?.endMethodFlag ?? null,
          joinDate: result?.detail?.joinDate ?? null,
          retireDate: result?.detail?.retireDate ?? null,
          retireReason: result?.detail?.retireReason ?? null,
          mainManagerUserId: result?.detail?.mainManagerUserId ?? null,
          subManagerUserId: result?.detail?.subManagerUserId ?? null,
          employmentType: result?.detail?.employmentType ?? null,
          taxClassification: result?.detail?.taxClassification ?? null,
          dependents: result?.detail?.dependents ?? null,
          dailyPay: result?.detail?.dailyPay ?? null,
          hourlyPay: result?.detail?.hourlyPay ?? null,
          recruitMedia: result?.detail?.recruitMedia ?? null,
          recruitCost: result?.detail?.recruitCost ?? null,
          position: result?.detail?.position ?? null,
          evaluation: result?.detail?.evaluation ?? null,
          nearestStations: result?.detail?.nearestStations ?? [],
          height: result?.detail?.height ?? null,
          weight: result?.detail?.weight ?? null,
          shoeSize: result?.detail?.shoeSize ?? null,
          wearSize: result?.detail?.wearSize ?? null,
          holdingItems: result?.detail?.holdingItems ?? [],
          certifications: result?.detail?.certifications ?? [],
          profession: result?.detail?.profession ?? null,
          schoolName: result?.detail?.schoolName ?? null,
          admissionYear: result?.detail?.admissionYear ?? null,
          payMethod: result?.detail?.payMethod ?? null,
          bankName: result?.detail?.bankName ?? null,
          bankCode: result?.detail?.bankCode ?? null,
          bankBranchName: result?.detail?.bankBranchName ?? null,
          bankBranchCode: result?.detail?.bankBranchCode ?? null,
          bankAccountFlag: result?.detail?.bankAccountFlag ?? null,
          bankAccountNumber: result?.detail?.bankAccountNumber ?? null,
          bankAccountName: result?.detail?.bankAccountName ?? null,
          preliminary1: result?.detail?.preliminary1 ?? null,
          preliminary2: result?.detail?.preliminary2 ?? null,
          preliminary3: result?.detail?.preliminary3 ?? null,
          preliminary4: result?.detail?.preliminary4 ?? null,
          preliminary5: result?.detail?.preliminary5 ?? null,
          note: result?.detail?.note ?? null,
          createdUser: result?.detail?.createdUser ?? {},
          updatedUser: result?.detail?.updatedUser ?? {},
          createdStaff: result?.detail?.createdStaff ?? {},
        });
        dispatch(bankingFetch(result?.detail?.bankCode));
      });
  }, []);
  //各種API
  const handleFileChange = e => {
    let fileData = e.target.files[0];
    if (fileData?.size > 1024 * 1024) {
      alert('画像は1MB以下でアップロードしてください。');
    } else {
      const reader = new FileReader();
      reader.onload = async event => {
        const imageUri = event.currentTarget.result;
        dispatch(
          uploadImage({
            image: imageUri,
            contentFlag: 3,
            userId: accountInfo.userId,
          })
        ).then(
          ({ payload }) =>
            payload?.code === '0' &&
            handlerFormikFieldChange(formik, 'staffImage', payload.imageUrl)
        );
      };
      reader.readAsDataURL(fileData);
    }
  };

  //各種ページjsx
  const basicArea = () => (
    <div className={'staff_edit--basic_area'}>
      <div className={'staff_edit--staff_id_area'}>
        <label style={{fontSize: checkIsSp() ? '12px' : '14px'}}>
          スタッフID<span>*</span>
        </label>
        <div className={'staff_edit--staff_id'} id={'staffId'} style={{fontSize: checkIsSp() ? '12px' : '14px'}}>
          {location?.state?.id}
        </div>
      </div>

      <div className={'staff_register--search_area_name'} style={{gap: checkIsSp() ? '8px' : '16px'}}>
        <div className={'staff_register--search_area_label'} style={{fontSize: checkIsSp() ? '12px' : '14px'}}>
          名前<span>*</span>
        </div>
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[1]}
          formik={formik}
        />
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[2]}
          formik={formik}
        />
      </div>

      <div className={'staff_register--search_area_name'} style={{gap: checkIsSp() ? '8px' : '16px'}}>
        <div className={'staff_register--search_area_label'} style={{fontSize: checkIsSp() ? '12px' : '14px'}}>
          名前(カナ)<span>*</span>
        </div>
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[3]}
          formik={formik}
        />
        <CustomFormikInput
          {...StaffRegisterInfos.base.inputs[4]}
          formik={formik}
        />
      </div>

      <CustomFormikInput
        {...StaffRegisterInfos.base.inputs[5]}
        formik={formik}
      />
      {!isStaff && (
        <div className={'staff_edit--calendar_area'}>
          <CustomFormikInput
            {...StaffRegisterInfos.base.inputs[6]}
            formik={formik}
            customDisabledDate={current =>
              current && current > moment(endDate, 'YYYY/MM/DD')
            }
          />
          <CustomFormikInput
            {...StaffRegisterInfos.base.inputs[7]}
            formik={formik}
            customDisabledDate={current =>
              current && current < moment(startDate, 'YYYY/MM/DD')
            }
          />
        </div>
      )}
    </div>
  );

  //registerベースに送信する設定(管理側)updatesを修正する必要あり
  const infos = {
    updates: [
      {
        label: '新規登録',
        datetime: formik?.values?.createdUser?.datetime ?? '',
        name: formik?.values?.createdUser?.name ?? '-',
      },
      {
        label: '社員最終更新',
        datetime: formik?.values?.updatedUser?.datetime ?? '-',
        name: formik?.values?.updatedUser?.name ?? '-',
      },
      {
        label: 'スタッフ最終更新',
        datetime: formik?.values?.createdStaff?.datetime ?? '-',
        name: formik?.values?.createdStaff?.name ?? '-',
      },
    ],
    basic: {
      style: {
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        gap: '24px',
      },
      jsx: basicArea,
    },

    detail: {
      tabs: {
        common: {
          label: '基本情報',
          jsx: () => <BasicInfos formik={formik} />,
        },
        traffic: {
          label: '交通情報',
          jsx: () => <AccessInfos formik={formik} />,
        },
        body: {
          label: '身長・体重など',
          jsx: () => <BodyInfos formik={formik} />,
        },
        skill: {
          label: '学歴・資格など',
          jsx: () => <BackGroundInfos formik={formik} />,
        },
        pay: {
          label: '給与支払情報',
          jsx: () => (
            <SalaryInfos formik={formik} dispatch={dispatch} isEdit={true} />
          ),
        },
        naming: {
          label: '属性情報',
          jsx: () => <AttributeInfos formik={formik} />,
        },
      },
    },
  };

  //registerベースに送信する設定(マイページ)
  const myPageInfos = {
    basic: {
      style: {
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        gap: '24px',
      },
      jsx: basicArea,
    },
    detail: {
      tabs: {
        common: {
          label: '基本情報',
          jsx: () => <BasicInfos formik={formik} isStaff={isStaff} />,
        },
        traffic: {
          label: '交通情報',
          jsx: () => <AccessInfos formik={formik} isStaff={isStaff} />,
        },
        body: {
          label: '身長・体重など',
          jsx: () => <BodyInfos formik={formik} isStaff={isStaff} />,
        },
        skill: {
          label: '学歴・資格など',
          jsx: () => <BackGroundInfos formik={formik} isStaff={isStaff} />,
        },
        pay: {
          label: '給与振込情報',
          jsx: () => (
            <SalaryInfos
              formik={formik}
              dispatch={dispatch}
              isStaff={isStaff}
            />
          ),
        },
      },
    },
  };

  //送信ボタン設定
  const buttonStyle = {
    updateButton: {
      width: '240px',
      height: '44px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    backButton: {
      width: '240px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
  };

  //useEffect
  return (
    <>
      <RegisterBase
        image={staffImage ? staffImage : Icons.icon.noImage}
        imageShape={'round'}
        infos={isStaff ? myPageInfos : infos}
        setImage={handleFileChange}
        imageDisabled={isStaff}
      />

      {isStaff ? (
        <div className={'my_page--bottom_btn_area'} style={{ height: '88px' }}>
          <Button text={'更新'} style={buttonStyle.updateButton} />
        </div>
      ) : (
        <div className={'bottom_btn_area'} style={{ height: '136px' }}>
          <div className={'staff_register--mail_send_check'}>
            <CustomFormikInput
              inputType={'checkBox'}
              label={'登録通知メールを送信する'}
              inputName={'sendMailFlag'}
              key={'sendMailFlag'}
              formik={formik}
              onChange={() => {
                handlerFormikFieldChange(formik, 'sendMailFlag', !sendMailFlag);
              }}
            />
          </div>

          <div
            className={'staff_register--submit_buttons'}
            style={{justifyContent: !checkIsSp() && location.state.deletedFlag !== 0 ? 'center' : ''}}
          >
            <Button
              text={'戻る'}
              style={buttonStyle.backButton}
              onClick={() => {
                dispatch(staffActions.editReset());
                navigate(`/staff/list`);
              }}
            />
            {location.state.deletedFlag === 0 && (
              <Button
                text={'更新'}
                style={buttonStyle.updateButton}
                onClick={() => handlerFormikSubmit(formik, tabErrorGroup)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default StaffEdit;
