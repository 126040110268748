import { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkIsSp,
  handlerFormikFieldChange,
  handlerFormikSubmit,
  numberFormat,
} from '../../../utils/fnUtil';
import { matterActions } from '../../../redux/slice/matter';
import { sumBy, toInteger } from 'lodash';
import Options from '../../../constants/options';
import Button from '../../button';
import CustomFormikInput from '../../customInput/customFormikInput';
import moment from 'moment';
import Yup from '../../../utils/yupUtil';
import './style.scss';

const CostSettingModal = memo(({ formik, modalVisible, setModalVisible }) => {
  const { matterName, venueId, matterDate, matterQuoteAmount } = formik.values;
  const {
    matterQuoteDetail: { quoteNumber, quoteAmount, clientName, matterQuote },
    venueList,
  } = useSelector(state => state.matter);

  const isSp = checkIsSp();

  const quoteFormik = useFormik({
    initialValues: {
      currentQuoteAmount: matterQuoteAmount,
      matterQuote,
    },
    validationSchema: Yup.object({
      currentQuoteAmount: Yup.string()
        .nullable()
        .integerString('[案件見積費用設定]案件見積金額')
        .label('[案件見積費用設定]案件見積金額')
        .test(
          'amountInvalid',
          '[案件見積費用設定]案件見積金額:見積金額不足です',
          (v, context) =>
            quoteAmount -
              toInteger(v) -
              sumBy(
                (context.parent.matterQuote ?? []).map(quote =>
                  toInteger(quote.matterQuoteAmount)
                )
              ) >=
            0
        ),
      matterQuote: Yup.array().of(
        Yup.object({
          matterQuoteAmount: Yup.string()
            .nullable()
            .integerString('[案件見積費用設定]関連案件見積金額')
            .test(
              'amountOtherInvalid',
              '[案件見積費用設定]関連案件見積金額:見積金額不足です',
              (_, context) =>
                quoteAmount -
                  toInteger(context.from[1].value.currentQuoteAmount) -
                  sumBy(
                    (context.from[1].value.matterQuote ?? []).map(quote =>
                      toInteger(quote.matterQuoteAmount)
                    )
                  ) >=
                0
            ),
        })
      ),
    }),
    onSubmit: values => {
      handlerFormikFieldChange(
        formik,
        'matterQuoteAmount',
        values.currentQuoteAmount
      );
      handlerFormikFieldChange(formik, 'matterQuote', values.matterQuote);
      dispatch(matterActions.updateQuoteAmount(restAmount));
      setModalVisible(false);
    },
  });

  useEffect(() => {
    handlerFormikFieldChange(formik, 'matterQuote', matterQuote);
    handlerFormikFieldChange(quoteFormik, 'matterQuote', matterQuote);
  }, [matterQuote]);

  useEffect(() => {
    handlerFormikFieldChange(
      quoteFormik,
      'currentQuoteAmount',
      matterQuoteAmount
    );
  }, [matterQuoteAmount]);

  const dateArray = matterDate.map(d => moment(d.matterDate));

  const dispatch = useDispatch();

  const restAmount =
    quoteAmount -
    toInteger(quoteFormik.values.currentQuoteAmount) -
    sumBy(
      quoteFormik.values.matterQuote.map(quote =>
        toInteger(quote.matterQuoteAmount)
      )
    );

  return (
    <Modal
      visible={modalVisible}
      style={{
        minWidth: 1632,
      }}
      footer={null}
      centered
      closable={false}
      destroyOnClose={true}
      onCancel={() => setModalVisible(false)}
      className="common_modal_base cost_setting_modal_base"
    >
      <div className="cost_setting_modal">
        <div className="common_modal_title">案件見積調整</div>
        {isSp ? (
          <div className={'cost_setting_modal_table_wrapper'}>
            <table className="head_table">
              <thead>
                <tr>
                  <th>見積書No</th>
                  <th>取引先</th>
                  <th>見積金額</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{quoteNumber}</td>
                  <td>{clientName}</td>
                  <td className="amount_text">
                    {numberFormat(quoteAmount, '円', '-')}
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th>案件</th>
                  <th>開催地</th>
                  <th>実施期間</th>
                  <th colSpan="2">案件見積金額</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matterName}</td>
                  <td>
                    {venueList.find(venue => venue.venueId == venueId)
                      ?.venueName ?? '-'}
                  </td>
                  <td>
                    {dateArray.length > 0
                      ? `${moment.min(dateArray).format('YYYY/M/D')}~${moment
                          .max(dateArray)
                          .format('YYYY/M/D')}`
                      : '-'}
                  </td>
                  <td colSpan="2" style={isSp ? { padding: '0 8px' } : {}}>
                    <CustomFormikInput
                      formik={quoteFormik}
                      inputName="currentQuoteAmount"
                      style={{
                        areaStyle: {
                          margin: 0,
                        },
                      }}
                      unit="円"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th>関連案件</th>
                  <th>開催地</th>
                  <th>実施期間</th>
                  <th colSpan="2">案件見積金額</th>
                </tr>
              </thead>
              <tbody>
                {quoteFormik.values.matterQuote.map((matter, i) => (
                  <tr key={matter.matterNumber}>
                    <td>{`${matter.matterNumber} ${matter.matterName}`}</td>
                    <td>{matter.venueName ?? '-'}</td>
                    <td>
                      {matter.matterStartDate || matter.matterEndDate
                        ? `${matter.matterStartDate ?? ''}~${
                            matter.matterEndDate
                          }`
                        : '-'}
                    </td>
                    <td colSpan="2">
                      <CustomFormikInput
                        formik={quoteFormik}
                        inputName={`matterQuote.${i}.matterQuoteAmount`}
                        style={{
                          areaStyle: {
                            margin: 0,
                          },
                        }}
                        unit="円"
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="no-border" colSpan={3} />
                  <td className="cost_setting_modal--label-td">見積残金</td>
                  <td>{numberFormat(restAmount, '円', '-')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <>
            <table className="head_table">
              <thead>
                <tr>
                  <th>見積書No</th>
                  <th>取引先</th>
                  <th>見積金額</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{quoteNumber}</td>
                  <td>{clientName}</td>
                  <td className="amount_text">
                    {numberFormat(quoteAmount, '円', '-')}
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th>案件</th>
                  <th>開催地</th>
                  <th>実施期間</th>
                  <th colSpan="2">案件見積金額</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matterName}</td>
                  <td>
                    {venueList.find(venue => venue.venueId == venueId)
                      ?.venueName ?? '-'}
                  </td>
                  <td>
                    {dateArray.length > 0
                      ? `${moment.min(dateArray).format('YYYY/M/D')}~${moment
                          .max(dateArray)
                          .format('YYYY/M/D')}`
                      : '-'}
                  </td>
                  <td colSpan="2">
                    <CustomFormikInput
                      formik={quoteFormik}
                      inputName="currentQuoteAmount"
                      style={{
                        areaStyle: {
                          margin: 0,
                        },
                      }}
                      unit="円"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th>関連案件</th>
                  <th>開催地</th>
                  <th>実施期間</th>
                  <th colSpan="2">案件見積金額</th>
                </tr>
              </thead>
              <tbody>
                {quoteFormik.values.matterQuote.map((matter, i) => (
                  <tr key={matter.matterNumber}>
                    <td>{`${matter.matterNumber} ${matter.matterName}`}</td>
                    <td>{matter.venueName ?? '-'}</td>
                    <td>
                      {matter.matterStartDate || matter.matterEndDate
                        ? `${matter.matterStartDate ?? ''}~${
                            matter.matterEndDate
                          }`
                        : '-'}
                    </td>
                    <td colSpan="2">
                      <CustomFormikInput
                        formik={quoteFormik}
                        inputName={`matterQuote.${i}.matterQuoteAmount`}
                        style={{
                          areaStyle: {
                            margin: 0,
                          },
                        }}
                        unit="円"
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="no-border" colSpan={3} />
                  <td className="cost_setting_modal--label-td">見積残金</td>
                  <td>{numberFormat(restAmount, '円', '-')}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        <div className={'cost_setting_modal--actions'}>
          <Button
            text={'戻る'}
            style={Options.buttonStyles.back}
            onClick={() => setModalVisible(false)}
          />
          <Button
            text={'更新'}
            style={Options.buttonStyles.submit}
            onClick={() => handlerFormikSubmit(quoteFormik)}
          />
        </div>
      </div>
    </Modal>
  );
});

export default CostSettingModal;
