const pref = {
  '1': '北海道',
  '2': '青森県',
  '3': '岩手県',
  '4': '宮城県',
  '5': '秋田県',
  '6': '山形県',
  '7': '福島県',
  '8': '茨城県',
  '9': '栃木県',
  '10': '群馬県',
  '11': '埼玉県',
  '12': '千葉県',
  '13': '東京都',
  '14': '神奈川県',
  '15': '新潟県',
  '16': '富山県',
  '17': '石川県',
  '18': '福井県',
  '19': '山梨県',
  '20': '長野県',
  '21': '岐阜県',
  '22': '静岡県',
  '23': '愛知県',
  '24': '三重県',
  '25': '滋賀県',
  '26': '京都府',
  '27': '大阪府',
  '28': '兵庫県',
  '29': '奈良県',
  '30': '和歌山県',
  '31': '鳥取県',
  '32': '島根県',
  '33': '岡山県',
  '34': '広島県',
  '35': '山口県',
  '36': '徳島県',
  '37': '香川県',
  '38': '愛媛県',
  '39': '高知県',
  '40': '福岡県',
  '41': '佐賀県',
  '42': '長崎県',
  '43': '熊本県',
  '44': '大分県',
  '45': '宮崎県',
  '46': '鹿児島県',
  '47': '沖縄県',
  '99': '海外'
}

const prefCode = () => {
  let prefCode = []
  Object.keys(pref).map(key => {
    prefCode.push(key);
  })

  return prefCode;
}

const prefName = () => {
  let prefName = [];

  prefCode().map(code => {
    prefName.push(pref[code]);
  })

  return prefName;
}

const heightValue = () => {
  let height = [];
  [...Array(251)].map((_, index) => {
    if (index > 99) {
      height[index] = index;
    }
  })

  return height;
};

const timeRequiredValues = () => {
  let timeRequiredValues = [];
  [...Array(101)].map((_, idx) => {
    timeRequiredValues.push(idx);
  });

  return timeRequiredValues;
}

const StaffInfos = {
  base: {
    inputs: [
      {
        label: 'スタッフID',
        inputName: 'staff_id',
        labelRequired: true,
        placeholder: 'スタッフIDを入力してください',
        style: {
          labelStyle: { width: '119px' },
        },
      },
      {
        inputType: 'input',
        inputName: 'last_name',
        placeholder: '姓',
        style: {
          areaStyle: { width: '100%', marginLeft: '8px' },
        },
      },
      {
        inputType: 'input',
        inputName: 'first_name',
        placeholder: '名',
        style: {
          areaStyle: { width: '100%'},
        },
      },
      {
        inputType: 'input',
        inputName: 'last_name_kana',
        placeholder: 'セイ',
        style: {
          areaStyle: { width: '100%', marginLeft: '8px' },
        },
      },
      {
        inputType: 'input',
        inputName: 'first_name_kana',
        placeholder: 'メイ',
        style: {
          areaStyle: { width: '100%'},
        },
      },
      {
        inputType: 'input',
        label: 'メールアドレス',
        inputName: 'mail_address',
        placeholder: 'メールアドレスを入力してください',
        labelRequired: true,
        style: {
          labelStyle: { width: '119px' },
        },
      },
      {
        inputType: 'inputDate',
        label: '利用開始日',
        labelRequired: true,
        inputName: 'start_date',
        placeholder: 'YYYY/MM/DD',
        className: {
          areaClass: 'staff_edit--input_month',
        },
        style: {
          areaStyle: { width: '288px' },
          labelStyle: { width: '119px' },
        }
      },
      {
        inputType: 'inputDate',
        label: '利用終了日',
        labelRequired: true,
        inputName: 'end_date',
        placeholder: 'YYYY/MM/DD',
        className: {
          areaClass: 'staff_edit--input_month',
        },
        style: {
          areaStyle: { width: '288px' },
          labelStyle: { width: '119px' },
        }
      },
    ],
  },
  basic: {
    inputs: [
      {
        radioButtonTitle: '性別',
        labelRequired: true,
        radioButtonLabelAndChecked: [
          {label: '男性', checked: true},
          {label: '女性', checked: false},
        ],
        inputName: 'gender',
        initialValue: [1, 2],
        inputType: 'radioButton',
        style: {
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '郵便番号',
        labelRequired: true,
        inputName: 'zip_code_1',
        initialValue: '',
        placeholder: '123',
        style: {
          areaStyle: {width: '366px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        inputName: 'zip_code_2',
        initialValue: '',
        placeholder: '456',
        style: {
          areaStyle: {width: '110px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '都道府県',
        labelRequired: true,
        inputName: 'prefCode',
        initialValue: prefCode(),
        selectBoxOptions: prefName(),
        placeholder: '都道府県',
        showSearch: false,
        style: {
          areaStyle: {width: '392px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '市区町村',
        labelRequired: true,
        inputName: 'cityName',
        initialValue: '',
        placeholder: '市区町村',
        style: {
          areaStyle: {width: 'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '丁目番地',
        labelRequired: true,
        inputName: 'streetName',
        initialValue: '',
        placeholder: '丁目番地',
        style: {
          areaStyle: {width: 'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: 'それ以降',
        inputName: 'buildingName',
        initialValue: '',
        placeholder: 'マンション・部屋番号等',
        style: {
          areaStyle: {width: 'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'inputTell',
        label: 'TEL(スマートフォン)',
        labelRequired: true,
        inputName: ['phone_area_number', 'phone_city_number', 'phone_subscriber_number'],
        initialValue: ['', '', ''],
        placeholder: ['03', '0000', '0000'],
        unit: ['-', '-', ''],
        style: {
          areaStyle: {width: '626px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'inputTell',
        label: 'TEL(自宅)',
        inputName: ['tel_area_number', 'tel_city_number', 'tel_subscriber_number'],
        initialValue: ['', '', ''],
        placeholder: ['03', '0000', '0000'],
        unit: ['-', '-', ''],
        style: {
          areaStyle: {width: '626px'},
          labelStyle: {width: '232px'},
          unitStyle: {width: 'auto'},
        }
      },
      {
        inputType: 'input',
        label: 'LINE ID',
        inputName: 'lineId',
        initialValue: '',
        placeholder: 'LINE IDを入力してください',
        style: {
          areaStyle: {width: 'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'label',
        label: '税額表区分',
        inputName: 'taxClassification',
        initialValue: '',
        style: {
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'label',
        label: '扶養人数',
        inputName: 'dependents',
        style: {
          labelStyle: {width: '232px',color: ''},
        }
      },
    ]
  },
  access: {
    inputs: [
      {
        inputType: 'input',
        label: '最寄駅',
        labelRequired: true,
        inputName: 'nearest_station',
        initialValue: '',
        placeholder: '駅名を入力してください',
        style: {
          areaStyle: {width: '80%', marginTop: '4px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '最寄駅までの所要時間(分)',
        labelRequired: true,
        inputName: 'time_required',
        initialValue: timeRequiredValues(),
        selectBoxOptions: timeRequiredValues(),
        placeholder: '0',
        unit: '分',
        style: {
          areaStyle: {width: '380px', minWidth: ''},
          labelStyle: {width: '232px'},
        },
        showSearch: false,
      },
      {
        inputType: 'select',
        label: '最寄駅までの交通手段',
        labelRequired: true,
        inputName: 'way_station',
        initialValue: [0, 1, 2, 99],
        selectBoxOptions: ['徒歩', 'バス', '車', 'その他'],
        placeholder: '選択してください',
        style: {
          areaStyle: {width: '410px', flexShrink: '0'},
          labelStyle: {width: '232px'},
        },
        showSearch: false,
      },
      {
        inputType: 'input',
        placeholder: 'バス停名を入力してください',
        style: {
          areaStyle: {
            width: '100%'
          }
        }
      },
      {
        inputType: 'input',
        placeholder: 'バス停名を入力してください',
        style: {
          areaStyle: {
            width: '100%'
          }
        }
      },
    ]
  },
  body: {
    inputs: [
      {
        inputType: 'select',
        label: '身長(cm)',
        inputName: 'height',
        labelRequired: true,
        showSearch: false,
        initialValue: heightValue(),
        selectBoxOptions: heightValue(),
        placeholder: '155',
        unit: 'cm',
        style: {
          areaStyle: {width: '398px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '体重(kg)',
        inputName: 'weight',
        initialValue: '',
        placeholder: '0',
        unit: 'kg',
        style: {
          areaStyle: {width: '398px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '靴のサイズ(cm)',
        inputName: 'shoeSize',
        initialValue: '',
        placeholder: '0',
        unit: 'cm',
        style: {
          areaStyle: {width: '398px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '服のサイズ',
        inputName: 'wearSize',
        showSearch: false,
        initialValue: [0, 1, 2, 3, 4, 5, 6],
        selectBoxOptions: ['XS', 'S', 'M', 'L', 'XL', '2L', '3L'],
        placeholder: 'M',
        style: {
          areaStyle: {width: '367px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'multiSelect',
        label: '持っているもの',
        inputName: 'holdingItems',
        initialValue: ['0001', '0002', '0003', '0004', '0005', '0006'],
        selectBoxOptions: ['白スニーカー', '黒チノパン', 'ベージュチノパン', '白シャツ', 'スーツ(パンツ)', 'スーツ(スカート)'],
        placeholder: '選択してください',
        style: {
          areaStyle: {width: '81%', minWidth: '800px'},
          labelStyle: {width: '232px'},
          inputStyle: {width: '100%'},
        }
      },
    ]
  },
  background: {
    inputs: [
      {
        inputType: 'multiSelect',
        label: '資格',
        inputName: 'certifications',
        placeholder: '選択してください',
        style: {
          areaStyle: {width: '81%', minWidth: '800px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        radioButtonTitle: '職業',
        inputName: 'profession1',
        initialValue: [0, 1],
        inputType: 'radioButton',
        style: {
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '学校名',
        inputName: 'schoolName',
        initialValue: '',
        placeholder: '学校名を入力してください',
        style: {
          areaStyle: {width: '68%', minWidth: '650px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '入学年',
        inputName: 'admissionYear',
        initialValue: '',
        placeholder: '2022',
        unit: '年',
        style: {
          areaStyle: {width: '472px'},
          labelStyle: {width: '232px'},
        }
      },
    ]
  },
  salary: {
    inputs: [
      {
        radioButtonTitle: '給与受取方法',
        inputName: 'payMethod1',
        initialValue: [0, 1],
        inputType: 'radioButton',
        style: {
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '金融期間名',
        inputName: 'bankName',
        placeholder: '振込先金融機関名を入力してください',
        style: {
          areaStyle: {width: '583px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '銀行コード',
        inputName: 'bankCode',
        placeholder: '0000',
        style: {
          areaStyle: {width: '366px'},
          labelStyle: {width: '232px'},
        },
        // note: 'ゆうちょの場合は未記入にしてください'
      },
      {
        inputType: 'select',
        label: '支店名',
        inputName: 'bankBranchName',
        placeholder: '支店名を入力してください',
        style: {
          areaStyle: {width: '583px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '支払コード',
        inputName: 'bankBranchCode',
        placeholder: '0000',
        style: {
          areaStyle: {width: '366px'},
          labelStyle: {width: '232px'},
        },
        // note: 'ゆうちょの場合は口座番号を入力してください'
      },
      {
        radioButtonTitle: '口座種別',
        inputName: 'bankAccountFlag1',
        initialValue: [1, 2, 4],
        inputType: 'radioButton',
        style: {
          areaStyle: {},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '口座番号',
        inputName: 'bankAccountNumber',
        placeholder: '0000000',
        style: {
          areaStyle: {width: '583px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '口座名義人(カナ)',
        inputName: 'bankAccountName',
        placeholder: '口座名義人(カナ)を入力してください',
        style: {
          areaStyle: {width: '67%', minWidth: '800px'},
          labelStyle: {width: '232px'},
        }
      },
    ]
  },
}

export default StaffInfos;
