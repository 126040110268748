import './styleSp.scss';
import { Tooltip } from 'antd';
import Icons from '../../../constants/icons';
import React, { useEffect } from 'react';
import Button from '../../button';
import Options from '../../../constants/options';
import CustomFormikInput from '../../customInput/customFormikInput';

const AttendanceProcessModalSp = ({
  formik,
  changeModalVisible,
  handlerFormikSubmit,
  staffDesi,
  setStaffDesi,
  helpMessage,
}) => {

  const {
    isSelectYearMonth,
    closingFromDate,
    closingToDate,
    staffConfirmFromDate,
    staffConfirmToDate,
    staffList = [],
  } = formik.values;

  return (
    <div className={'attendance_process_modal_sp--main'}>
      <div className={'attendance_process_modal_sp--title'}>
        <span>給与締処理</span>
        <Tooltip
          placement="bottom"
          title={helpMessage}
          mouseEnterDelay={0.5}
          overlayClassName="attendance_process_modal_sp--tooltip"
          overlayInnerStyle={{
            borderRadius: '6px',
            padding: '22px 24px',
            minWidth: '100%',
            fontSize: '12px',
          }}
        >
          <img
            src={Icons.icon.help}
            alt="help"
            style={{width: '20px', height: '20px', padding: '2.5px 0 0'}}
          />
        </Tooltip>
      </div>

      <div className={'attendance_process_modal_sp--contents'}>
        <CustomFormikInput
          formik={formik}
          inputType="inputMonth" //inputのtype
          label="支払年月" //ラベルの名前
          labelRequired={true}
          inputName="yearMonth" //DBのカラム名
          defaultValue="" //inputの初期値
          placeholder="YYYY/MM"
          className={{ areaClass: 'attendance_process_modal_sp--common_field' }}
        />
        <div className={'attendance_process_modal_sp--item'}>
          <CustomFormikInput
            formik={formik}
            inputType="inputDate" //inputのtype
            label="集計対象期間"
            labelRequired={true}
            inputName="closingFromDate" //DBのカラム名
            initialValue="" //inputの初期値
            placeholder="YYYY/MM/DD"
            disabledFutureDate={closingToDate}
            disabled={formik.values.yearMonth === ''}
            className={{ areaClass: 'attendance_process_modal_sp--common_field' }}
          />
          <span style={{lineHeight: '40px'}}>〜</span>
          <CustomFormikInput
            formik={formik}
            inputType="inputDate" //inputのtype
            label='　'
            inputName="closingToDate" //DBのカラム名
            initialValue="" //inputの初期値
            placeholder="YYYY/MM/DD"
            disabledDate={closingFromDate}
            disabled={isSelectYearMonth}
            className={{ areaClass: 'attendance_process_modal_sp--common_field' }}
          />
        </div>
        <div className={'attendance_process_modal_sp--item'}>
          <CustomFormikInput
            formik={formik}
            inputType="inputDate" //inputのtype
            label="スタッフ給与確認期間"
            labelRequired={false}
            inputName="staffConfirmFromDate" //DBのカラム名
            initialValue="" //inputの初期値
            placeholder="YYYY/MM/DD"
            disabledFutureDate={staffConfirmToDate}
            disabled={isSelectYearMonth}
            className={{ areaClass: 'attendance_process_modal_sp--common_field' }}
          />
          <span style={{lineHeight: '40px'}}>〜</span>
          <CustomFormikInput
            formik={formik}
            inputType="inputDate" //inputのtype
            label='　'
            inputName="staffConfirmToDate" //DBのカラム名
            initialValue="" //inputの初期値
            placeholder="YYYY/MM/DD"
            disabledDate={staffConfirmFromDate}
            disabled={isSelectYearMonth}
            className={{ areaClass: 'attendance_process_modal_sp--common_field' }}
          />
        </div>
        <CustomFormikInput
          formik={formik}
          inputType="inputDate" //inputのtype
          label="給与締日"
          labelRequired={false}
          inputName="payClosingDate" //DBのカラム名
          initialValue="" //inputの初期値
          placeholder="YYYY/MM/DD"
          disabled={isSelectYearMonth}
          className={{ areaClass: 'attendance_process_modal_sp--pay_closing_date' }}
        />
        <div className={'attendance_process_modal_sp--calc_area'}>
          <span className={'attendance_process_modal_sp--calc_title'} style={{lineHeight: '18px', marginBottom: '16px'}}>給与再計算</span>
          <CustomFormikInput
            formik={formik}
            inputType="radioButton"
            radioButtonTitle="スタッフ指定"
            labelRequired={true}
            inputName="staffDesi"
            radioButtonLabelAndChecked={[
              { label: '指定しない', checked: staffDesi == '0' },
              { label: '指定する', checked: staffDesi == '1' },
            ]}
            disabled={!formik.values.yearMonth}
            initialValue={['0', '1']}
            onChange={e => {
              alert()
              setStaffDesi(e.target.value);
            }}
          />
          <CustomFormikInput
            formik={formik}
            inputType="select"
            mode={'multiple'}
            inputName="staffIdList"
            placeholder="スタッフを選択してください"
            disabled={staffDesi === '0' ? true : false}
            selectedSelectBox=""
            style={{
              areaStyle: { width: '100%' },
            }}
            initialValue={staffList.map(p => p?.staffId)}
            selectBoxOptions={staffList.map(p => p?.staffName)}
            allowClear={false}
          />
        </div>
      </div>

      <div className={'attendance_process_modal_sp--btn'}>
        <Button
          text={'戻る'}
          style={{
            ...Options.buttonStyles.back,
            width: '100%',
            height: '40px',
            fontSize: '12px',
          }}
          onClick={() => changeModalVisible(false)}
        />
        <Button
          text={'集計'}
          style={{
            ...Options.buttonStyles.submit,
            width: '100%',
            height: '40px',
            fontSize: '12px',
          }}
          onClick={() => {
            handlerFormikSubmit(formik);
          }}
        />
      </div>
    </div>
  )
};

export default AttendanceProcessModalSp;