import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { store } from '../../../../redux/store';
import { globalActions } from '../../../../redux/slice/global';
import {
  quoteActions,
  quoteTemplateDelete,
  quoteTemplateListFetch,
  quoteTemplateSort,
} from '../../../../redux/slice/quote';
import { MenuOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import Copyright from '../../../../components/copyright';
import AddButton from '../../../../components/addButton';
import DeleteModal from '../../../../components/modal/deleteModal';
import Icons from '../../../../constants/icons';
import './style.scss';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { checkIsSp } from '../../../../utils/fnUtil';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#838a91',
      fontSize: '18px',
    }}
  />
));
const { Column } = Table;

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const isSp = checkIsSp();

const QuoteTemplateList = () => {
  const dispatch = useDispatch();

  const [apiData, setApiData] = useState([
    {
      templateId: null,
      templateName: '',
      templateType: '',
    },
  ]);

  useEffect(() => {
    const handleAddButton = document.getElementsByClassName('add_button--text');

    if (!isSp) {
      handleAddButton[0].innerHTML = '新規登録';
    }
  }, []);

  useEffect(() => {
    dispatch(quoteActions.editReset());
    // 見積一覧取得Api
    dispatch(quoteTemplateListFetch()).then(({ payload }) => {
      setApiData(payload.templateListParams);
    });
  }, []);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        apiData.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      setApiData(newData);
      postApi(newData);
    }
  };
  const postApi = newData => {
    let values = {
      templateId: newData
        .map(row => {
          return [row['templateId']];
        })
        .join(','),
    };
    dispatch(quoteTemplateSort(values));
    dispatch(quoteTemplateListFetch()).then(({ payload }) => {
      setApiData(payload.templateListParams);
    });
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = apiData.findIndex(
      x => x.templateId === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const [deleteKye, setDeleteKye] = useState('');
  const setDeleteInfo = (name, key) => {
    setDeleteName(name);
    setDeleteKye(key);
    toggleDeleteModal();
  };
  const toggleDeleteModal = () => {
    setDeleteModalVisible(!deleteModalVisible);
  };

  return (
    <>
      <div
        className="quote_template_list"
        style={isSp ? { paddingTop: '40px' } : {}}
      >
        <div
          className="quote_template_list--add--btn"
          style={
            isSp
              ? { width: '92%', margin: '32px auto 20px', float: 'unset' }
              : {}
          }
        >
          <AddButton
            url="/quote/template/register"
            displayText={true}
            spListViewMode={isSp}
            style={isSp ? { width: '100%' } : {}}
          />
        </div>

        {!isSp && <Copyright />}

        <div
          className="quote_template_list--main_table"
          style={
            isSp
              ? {
                  width: '92%',
                  height: 'fit-content',
                  margin: '0 auto',
                  overflow: 'scroll',
                  minWidth: 'unset',
                }
              : {}
          }
        >
          <Table
            //subpage
            pagination={false}
            dataSource={apiData}
            rowKey="templateId"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            style={isSp ? { minWidth: '800px' } : {}}
          >
            <Column
              title="並替"
              width={10}
              className="quote_template_list--main_table--column"
              render={() => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  className="quote_template_list--main_table--column--order"
                >
                  <DragHandle />
                </div>
              )}
            />
            <Column
              title="テンプレート名"
              dataIndex="templateName"
              width={450}
              className="quote_template_list--main_table--column"
            />
            <Column
              title="種別"
              dataIndex="templateType"
              className="quote_template_list--main_table--column"
              render={(_, record) => {
                switch (record.templateType) {
                  case 0:
                    return '共通';
                  default:
                    return '個人';
                }
              }}
            />
            <Column
              title="編集"
              key="edit"
              width={60}
              className="quote_template_list--main_table--edit"
              render={(_, record) => (
                <img
                  src={Icons.icon.editSNv}
                  alt="edit"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate('/quote/template/edit', {
                      state: {
                        templateId: record.templateId,
                        templateName: record.templateName,
                        templateType: record.templateType,
                      },
                    });
                  }}
                />
              )}
            />
            <Column
              title="削除"
              key="del"
              width={60}
              className="quote_template_list--main_table--del"
              render={(_, record) => (
                <img
                  src={Icons.icon.deleteSNv}
                  alt="delete"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setDeleteInfo(record.templateName, record.templateId)
                  }
                />
              )}
            />
          </Table>
        </div>

        {isSp && (
          <Copyright style={{ position: 'relative', marginTop: '70px' }} />
        )}

        <DeleteModal
          deleteName={deleteName}
          deleteKey={deleteKye}
          deleteFunction={key => {
            dispatch(quoteTemplateDelete({ templateId: key })).then(() => {
              dispatch(quoteTemplateListFetch()).then(({ payload }) => {
                setApiData(payload.templateListParams);
              });
            });
            setDeleteModalVisible(!deleteModalVisible);
          }}
          deleteModalVisible={deleteModalVisible}
          toggleVisible={toggleDeleteModal}
          displayNotice={false}
        />
      </div>
    </>
  );
};

export default QuoteTemplateList;
