import { checkIsSp } from '../../../utils/fnUtil';

const isSp = checkIsSp()

const SurveySearchInputs = [
  {
    inputType: 'input',
    label: 'タイトル',
    inputName: 'surveyTitle',
    placeholder: 'タイトルを入力してください',
    className: {
      areaClass: 'column',
    },
    style: {
      areaStyle: {width: isSp ? '100%':'456px'},
    }
  },
  {
    inputType: 'inputDate',
    label: '実施期間',
    inputName: 'searchDateFrom',
    placeholder: 'YYYY/MM/DD',
    unit: '〜',
    className: {
      areaClass: 'survey_search_area--date',
    },
    style: {
      areaStyle: {width: isSp ? '100%':'165px'},
    }
  },
  {
    inputType: 'inputDate',
    label: '　',
    inputName: 'searchDateTo',
    placeholder: 'YYYY/MM/DD',
    className: {
      areaClass: 'survey_search_area--date',
    },
    style: {
      areaStyle: {width: isSp ? '100%':'165px', marginLeft: '7px'},
    }
  },
  {
    inputType: 'select',
    showSearch: false,
    label: 'アンケート種別',
    inputName: 'surveyType',
    initialValue: [0, 1],
    selectBoxOptions: ['毎日アンケート', '企業アンケート'],
    placeholder: '種別を選択してください',
    className: {
      areaClass: 'column',
    },
    style: {
      areaStyle: {width: isSp ? '100%':'228px'},
    }
  },
  {
    inputType: 'select',
    showSearch: false,
    allowClear: false,
    label: 'ステータス',
    inputName: 'status',
    initialValue: [99, 0, 1, 2],
    selectedSelectBox: 99,
    selectBoxOptions: ['すべて', '未実施', '実施中', '実施済'],
    className: {
      areaClass: 'column',
    },
    style: {
      areaStyle: {width: isSp ? '100%':'150px'},
    }
  },
];

export default SurveySearchInputs;