import React from 'react';
import { Table } from 'antd';
import PayTotal from './payTotal';
import PayStamp from './payStamp';
import { useSelector } from 'react-redux';
import { convertDatetime } from '../../../../utils/fnUtil';
import './style.scss';

const PayDetail = () => {
  const { staffPayEditList } = useSelector(state => state.staffPay);

  //コンマを入れる
  const toThousands = num => {
    let isMinus = num < 0;

    if (isMinus) {
      num = num * -1;
    }
    var num = (num || 0).toString(),
      result = '';
    while (num.length > 3) {
      result = ',' + num.slice(-3) + result;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }

    if (isMinus) {
      result = '▲' + result;
    }
    return result;
  };

  const getWeek = (year, month, day) => {
    const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
    let targetDay = new Date(year, month - 1, day);

    return weekDay[targetDay.getDay()];
  };

  var noNum = 0;
  var payBaseAmountTotal = 0;
  var overtimePayAmountTotal = 0;
  var payCutAmountTotal = 0;
  var allowanceAmountTotal = 0;
  var fareAmountTotal = 0;
  var busAmountTotal = 0;
  var taxiAmountTotal = 0;
  var communicationAmountTotal = 0;
  var deliveryAmountTotal = 0;
  var othersAmountTotal = 0;
  var beforePayAmountTotal = 0;
  var payAmountAllowance = 0;
  const columns = [
    {
      key: 'info',
      render: item =>
        item.key === 'subtotal' ? (
          <></>
        ) : item.key === 1 ? (
          <div className="pay_detail--table--column--info">勤 務 情 報</div>
        ) : (
          <></>
        ),
      onCell: item => {
        if (item.key === 1) {
          return { rowSpan: data.length - 1 };
        } else if (item.key !== 'subtotal') {
          return { rowSpan: 0 };
        }
        return {
          colSpan: item.key === 'subtotal' ? 4 : 1,
        };
      },
      width: 30,
    },
    //No
    {
      title: 'No',
      key: 'num',
      render: () => {
        return (noNum += 1);
      },
      onCell: item => ({
        colSpan: item.key === 'subtotal' ? 0 : 1,
      }),
      width: 30,
    },
    //実施日
    {
      title: '実施日',
      dataIndex: 'matterDate',
      key: 'matterDate',
      onCell: item => ({
        colSpan: item.key === 'subtotal' ? 0 : 1,
      }),
      width: 120,
    },
    //案件
    {
      title: '案件',
      dataIndex: 'matterName',
      key: 'matterName',
      onCell: item => ({
        colSpan: item.key === 'subtotal' ? 0 : 1,
      }),
      width: 300,
    },
    //開始
    {
      title: '開始',
      dataIndex: 'punchInDatetime',
      key: 'punchInDatetime',
      render: data =>
        data === undefined ? (
          <div className="pay_detail--table--column--subtotal">小計</div>
        ) : (
          <div className="pay_detail--table--data--center">{data}</div>
        ),
      onCell: item => ({
        colSpan: item.key === 'subtotal' ? 2 : 1,
      }),
      width: 50,
    },
    //終了
    {
      title: '終了',
      dataIndex: 'punchOutDatetime',
      key: 'punchOutDatetime',
      render: data => (
        <div className="pay_detail--table--data--center">{data}</div>
      ),
      onCell: item => ({
        colSpan: item.key === 'subtotal' ? 0 : 1,
      }),
      width: 50,
    },
    //基本給
    {
      title: '基本給',
      key: 'payBaseAmount',
      render: record => {
        <div className="pay_detail--table--data--center"></div>;
        payBaseAmountTotal += record.payBaseAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(payBaseAmountTotal) + '円'
              : toThousands(record.payBaseAmount) + '円'}
          </div>
        );
      },
      width: 85,
    },
    //残業
    {
      title: '残業',
      key: 'overtimePayAmount',
      render: record => {
        overtimePayAmountTotal += record.overtimePayAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(overtimePayAmountTotal) + '円'
              : toThousands(record.overtimePayAmount) + '円'}
          </div>
        );
      },
      width: 70,
    },
    //遅刻早退
    {
      title: '遅刻・早退',
      key: 'payCutAmount',
      render: record => {
        payCutAmountTotal += record.payCutAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? payCutAmountTotal === 0
                ? toThousands(payCutAmountTotal) + '円'
                : '▲' + toThousands(payCutAmountTotal) + '円'
              : record.payCutAmount === 0
              ? toThousands(record.payCutAmount) + '円'
              : '▲' + toThousands(record.payCutAmount) + '円'}
          </div>
        );
      },
      width: 75,
    },
    //手当
    {
      title: '手当',
      key: 'allowanceAmount',
      render: record => {
        allowanceAmountTotal += record.allowanceAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(allowanceAmountTotal) + '円'
              : toThousands(record.allowanceAmount) + '円'}
          </div>
        );
      },
      width: 70,
    },
    //交通費
    {
      title: '交通費',
      key: 'fareAmount',
      render: record => {
        fareAmountTotal += record.fareAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(fareAmountTotal) + '円'
              : toThousands(record.fareAmount) + '円'}
          </div>
        );
      },
      width: 65,
    },
    //バス
    {
      title: 'バス',
      key: 'busAmount',
      render: record => {
        busAmountTotal += record.busAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(busAmountTotal) + '円'
              : toThousands(record.busAmount) + '円'}
          </div>
        );
      },
      width: 65,
    },
    //タクシー
    {
      title: 'タクシー',
      key: 'taxiAmount',
      render: record => {
        taxiAmountTotal += record.taxiAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(taxiAmountTotal) + '円'
              : toThousands(record.taxiAmount) + '円'}
          </div>
        );
      },
      width: 65,
    },
    //通信
    {
      title: '通信',
      key: 'communicationAmount',
      render: record => {
        communicationAmountTotal += record.communicationAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(communicationAmountTotal) + '円'
              : toThousands(record.communicationAmount) + '円'}
          </div>
        );
      },
      width: 65,
    },
    //宅急
    {
      title: '宅配',
      key: 'deliveryAmount',
      render: record => {
        deliveryAmountTotal += record.deliveryAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(deliveryAmountTotal) + '円'
              : toThousands(record.deliveryAmount) + '円'}
          </div>
        );
      },
      width: 65,
    },
    //その他
    {
      title: 'その他',
      key: 'othersAmount',
      render: record => {
        othersAmountTotal += record.othersAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(othersAmountTotal) + '円'
              : toThousands(record.othersAmount) + '円'}
          </div>
        );
      },
      width: 65,
    },
    //前払金
    {
      title: '前払金',
      key: 'beforePayAmount',
      render: record => {
        beforePayAmountTotal += record.beforePayAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(beforePayAmountTotal) + '円'
              : toThousands(record.beforePayAmount) + '円'}
          </div>
        );
      },
      width: 65,
    },
    //合計
    {
      title: '合計',
      key: 'total',
      render: record => {
        let total = record.totalAmount;

        payAmountAllowance += record.totalAmount * 1;
        return (
          <div className="pay_detail--table--data--right">
            {record.key === 'subtotal'
              ? toThousands(payAmountAllowance) + '円'
              : toThousands(total) + '円'}
          </div>
        );
      },
      width: 85,
    },
  ];

  const apiData = [...staffPayEditList.staffPayDetail.payList].map((c, i) => ({
    ...c,
    key: i + 1,
    matterDate:
      c.matterDate +
      '(' +
      getWeek(
        c.matterDate.split('/')[0],
        c.matterDate.split('/')[1],
        c.matterDate.split('/')[2]
      ) +
      ')',
    punchInDatetime: c.punchInDatetime ? convertDatetime(c.punchInDatetime).split(' ')[1] : '',
    punchOutDatetime: c.punchOutDatetime ? convertDatetime(c.punchOutDatetime).split(' ')[1] : '',
  }));

  const data = [
    ...apiData,
    {
      key: 'subtotal',
      payBaseAmount: '',
      overtimePayAmount: '',
      payCutAmount: '',
      allowanceAmount: '',
      fareAmount: '',
      busAmount: '',
      taxiAmount: '',
      communicationAmount: '',
      deliveryAmount: '',
      othersAmount: '',
      beforePayAmount: '',
      totalAmount: '',
    },
  ];

  const totalAmountData = staffPayEditList.staffPayDetail;

  return (
    <>
      <div className="pay_detail--table">
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
      <PayTotal toThousands={toThousands} dataSource={totalAmountData} />
      <PayStamp dataSource={totalAmountData} />
    </>
  );
};
export default PayDetail;
