import './style.scss';
import ModalBase from '../modalBase';
import Button from '../../button';
import React, { useState } from 'react';
import CustomForm from '../../customForm';
import FormInfos from '../../../constants/formInfos';
import { useNavigate } from 'react-router-dom';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import { encryptPass, checkIsSp } from '../../../utils/fnUtil';

const ChangePassModal = ({ modalVisible, toggleVisible, backButton }) => {
  const navigate = useNavigate();

  const isSp = checkIsSp();

  if (backButton) {
    FormInfos.changePassModal.subButton['onClick'] = () => {
      toggleVisible();
    };
  } else {
    FormInfos.changePassModal.subButton['onClick'] = () => {
      navigate(`/login`);
    };
  }

  const onSubmit = () => {
    fetchRequest(
      apiUrl.account.change,
      {
        currentPassword: encryptPass(document.getElementById('now_pass').value),
        newPassword: encryptPass(document.getElementById('new_pass').value),
      },
      'POST'
    )
      .then(() => {
        store.dispatch(globalActions.showSingleModal('変更しました'));

        closeModal();
      })
      .catch(() =>
        store.dispatch(globalActions.showSingleModal('失敗しました'))
      );
  };

  const closeModal = () => {
    toggleVisible(false);
  };
  return (
    <ModalBase
      dialogStyle={{
        display: 'flex',
        flexFlow: 'column',
        gap: isSp ? '24px' : '40px',
        alignItems: 'center',
        justifyContent: 'center',
        width: isSp ? 'calc(100% - 100px)' : '520px',
        padding: isSp ? '40px 24px' : '64px 40px',
      }}
      modalVisible={modalVisible}
      closeModalFunction={closeModal}
    >
      <div className={'change_pass_modal--title_area'}>パスワード変更</div>

      <div className={'change_pass_modal--input_area'}>
        <CustomForm formInfo={FormInfos.changePassModal} onSubmit={onSubmit} />
      </div>
    </ModalBase>
  );
};

export default ChangePassModal;
