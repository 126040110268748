import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchParams: {
    corporateId: '',
    corporateName: [],
    status: 99,
    offset: 0,
    count: 20,
    sort: {
      column: 'corporateId',
      method: 'Desc',
    },
  },
};

export const corporateSlice = createSlice({
  name: 'corporate',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        // offset: 0,
      },
    }),
  },
});


export const corporateActions = corporateSlice.actions;
export default corporateSlice.reducer;