import './staffDashboardStyle.scss';
import moment from 'moment/moment';

const StaffDashBordContentSp = ({props, nextPlanDatetime, navigate, state, label}) => {
  return (
   <>
     {nextPlanDatetime &&
       props[nextPlanDatetime] &&
       moment().isBefore(
         moment(props[nextPlanDatetime])
       ) && props?.staffEndDatetime === null && (
         <div
           className={'dashboard_staff--alert'}
           onClick={() =>
             navigate('/recruit-matter/detail', {
               state: {
                 ...state,
                 activeTab: 'attendanceReport',
               },
             })
           }
         >
           {`${moment(props[nextPlanDatetime]).format(
             'M/D HH:mm'
           )}までに${label}をしてください`}
         </div>
       )}

     <div className={'dashboard_staff--matter_items'}>
       <div className={'dashboard_staff--matter_name'}>{props.matterName}</div>

       <div className={'dashboard_staff--matter_details'}>
         <div className={'dashboard_staff--matter_label'}>実施場所</div>
         <div className={'dashboard_staff--matter_value'}>{props.venueName}</div>
       </div>

       <div className={'dashboard_staff--matter_details'}>
         <div className={'dashboard_staff--matter_label'}>開催地最寄駅</div>
         <div className={'dashboard_staff--matter_value'}>{props.venueNearestStation}</div>
       </div>

       <div className={'dashboard_staff--matter_details'}>
         <div className={'dashboard_staff--matter_label'}>勤務時間</div>
         <div className={'dashboard_staff--matter_value'}>
           {`${
             props.workStartDatetime ||
             props.workEndDatetime
               ? `${props.workStartDatetime}~${props.workEndDatetime}`
               : ''
           }${
             props.restMinute !== null
               ? `(休憩${props.restMinute}分)`
               : ''
           }`}
         </div>
       </div>

       <div className={'dashboard_staff--matter_details'}>
         <div className={'dashboard_staff--matter_label'}>出発予定時間</div>
         <div className={'dashboard_staff--matter_value'}>
           {props?.staffDeparturePlanDatetime &&
             moment(
               props.staffDeparturePlanDatetime
             ).format('HH:mm')}
         </div>
       </div>
     </div>

     <div
       className={'dashboard_staff--matter_link'}
       onClick={() =>
         navigate('/recruit-matter/detail', {
           state,
         })
       }
       style={{cursor: 'pointer'}}
     >
       案件詳細を表示
     </div>
   </>
  )
};

export default StaffDashBordContentSp;