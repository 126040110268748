import React, { useEffect } from "react";
import { useState } from 'react';
import CustomInput from '../../../../components/customInput';
import './style.scss';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import { handlerFormikFieldPaymentChange } from "../../../../utils/fnUtil";
import { bankingFetch, staffActions } from "../../../../redux/slice/staff";
import { useDispatch, useSelector } from "react-redux";
import { checkIsSp } from "../../../../utils/fnUtil";

const PayInfo = ({ formik, sysAuth, isEdit }) => {
  const labelSty = checkIsSp()?{ width: '100%', textAlign: 'left', color: '#646464' }:{ width: '175px', textAlign: 'right', color: '#646464' };
  const unitSty = {
    color: '#D80245',
    width: '308px',
    position: 'absolute',
    left: '0',
  };
  const boxSty = {
    margin: '26px 0',
    display: 'flex',
    flexFlow: 'column',
    gap: '24px',
  };

  const {paymentBankName, paymentBankBranchName} = formik.values;
  const { bankingList, branchesList } = useSelector((state) => state.staff);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(paymentBankName){
      handlerFormikFieldPaymentChange(formik,'paymentBankCode',bankingList?.find(b=> b.label == paymentBankName)?.value ?? '');
      dispatch(bankingFetch(bankingList?.find(b=> b.label == paymentBankName)?.value ?? ''));
    } else {
      dispatch(staffActions.bankReset());
    }
  },[paymentBankName])
  useEffect(()=>{
    if(paymentBankBranchName && branchesList?.length > 0){
      // handlerFormikFieldChange(formik,'paymentBankBranchCode',branchesList?.find(b=> b?.label == paymentBankBranchName)?.value ?? '');
      formik.setFieldValue('paymentBankBranchCode', branchesList?.find(b=> b?.label === paymentBankBranchName)?.value ?? '');
    }
  },[paymentBankBranchName]);

  return (
    <>
      <div style={boxSty} className="corporate_register--pay_info">
        {/* 支払金融機関名 */}
        <CustomFormikInput
          inputType="select"
          label="支払金融機関名"
          inputName="paymentBankName"
          key={'paymentBankName'}
          formik={formik}
          placeholder="支払金融機関名を入力してください"
          style={{
            areaStyle: { width: checkIsSp()?'100%':'500px' },
            labelStyle: labelSty,
          }}
          disabled={isEdit && !sysAuth}
          initialValue={bankingList?.map(b=>b?.label)}
          selectBoxOptions={bankingList?.map(b=>b?.label)}
        />
        {/* 銀行コード */}
        <div className={'corporate_register--pay_info_unity'}>
          <CustomFormikInput
            inputType="input"
            label="銀行コード"
            inputName="paymentBankCode"
            key={'paymentBankCode'}
            formik={formik}
            placeholder="0000"
            style={{
              areaStyle: { width: '100%' },
              inputStyle: {width: '110px'}
            }}
            disabled={true}
          />
        </div>
        {/* 支店名 */}
        <CustomFormikInput
          inputType="select"
          label="支店名"
          inputName="paymentBankBranchName"
          key={'paymentBankBranchName'}
          formik={formik}
          placeholder="支店名を入力してください"
          style={{
            areaStyle: { width: checkIsSp()?'100%':'500px' },
            labelStyle: labelSty,
          }}
          disabled={isEdit && !sysAuth}
          initialValue={branchesList?.map(b=>b?.label)}
          selectBoxOptions={branchesList?.map(b=>b?.label)}
        />
        {/* 支店コード */}
        <div className={'corporate_register--pay_info_unity'}>
          <CustomFormikInput
            inputType="input"
            label="支店コード"
            inputName="paymentBankBranchCode"
            key={'paymentBankBranchCode'}
            formik={formik}
            placeholder="0000"
            style={{
              areaStyle: { width: '100%' },
              inputStyle: {width: '110px'}
            }}
            disabled={paymentBankName !== 'ゆうちょ銀行'}
          />
        </div>
        {/* 口座種別 */}
        <CustomFormikInput
          inputType="radioButton"
          radioButtonTitle="口座種別"
          inputName="paymentBankAccountFlag"
          key={'paymentBankAccountFlag'}
          formik={formik}
          radioButtonLabelAndChecked={[
            {
              label: '普通',
              checked: formik?.values?.paymentBankAccountFlag
                ? formik?.values?.paymentBankAccountFlag == 1
                : true,
            },
            {
              label: '当座',
              checked: formik?.values?.paymentBankAccountFlag
                ? formik?.values?.paymentBankAccountFlag == 2
                : false,
            },
            {
              label: '貯蓄',
              checked: formik?.values?.paymentBankAccountFlag
                ? formik?.values?.paymentBankAccountFlag == 4
                : false,
            },
          ]}
          initialValue={[1, 2, 4]}
          style={{
            labelStyle: labelSty,
            areaStyle: { margin: '10px 0' },
          }}
          disabled={isEdit && !sysAuth}
        />
        {/* 口座番号 */}
        <CustomFormikInput
          inputType="input"
          label="口座番号"
          inputName="paymentBankAccountNumber"
          key={'paymentBankAccountNumber'}
          formik={formik}
          placeholder="0000000"
          style={{
            inputStyle: { width: checkIsSp()?'100%':'300px' },
            labelStyle: labelSty,
          }}
          disabled={isEdit && !sysAuth}
        />
        {/* 口座名義(カナ) */}
        <CustomFormikInput
          inputType="input"
          label="口座名義(カナ)"
          inputName="paymentBankAccountName"
          key={'paymentBankAccountName'}
          formik={formik}
          placeholder="口座名義(カナ)を入力してください"
          style={{
            inputStyle: { width: checkIsSp()?'100%':'717px' },
            labelStyle: labelSty,
          }}
          disabled={isEdit && !sysAuth}
        />
      </div>
    </>
  );
};

export default PayInfo;
