import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import { useFormik } from 'formik';
import Button from '../../../../components/button';
import CustomInput from '../../../../components/customInput';
import Options from '../../../../constants/options';
import Icons from '../../../../constants/icons';
import {
  handlerFormikFieldChange,
  handlerFormikSubmit,
} from '../../../../utils/fnUtil';
import './style.scss';
import {
  quoteActions,
  quoteCopyDocumentInit,
  quoteDocumentEditPdf,
  quoteDocumentInit,
  quoteDocumentInsert,
  quoteDocumentPdf,
  quoteDocumentStampRequest,
  quoteDocumentStatus,
  quoteDocumentUpdate,
  quoteTemplateDetailFetch,
} from '../../../../redux/slice/quote';
import InfoSteps from './quotationInfo/infoSteps';
import InfoTable from './quotationInfo/infoTable';
import options from '../../../../constants/options';
import ApprovalModal from '../../../../components/modal/stampModal/approvalModal';
import DenialModal from '../../../../components/modal/stampModal/denialModal';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import ApprovalApplyModal from '../../../../components/modal/approvalApplyModal';
import { globalActions } from '../../../../redux/slice/global';
import { checkIsSp } from '../../../../utils/fnUtil';
import { store } from '../../../../redux/store';
import Yup from '../../../../utils/yupUtil';
import ConfirmModal from '../../../../components/modal/confirmModal';

const { TabPane } = Tabs;

const QuoteRegisterQuotation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const copyId = location?.state?.copyId;
  const {
    templateList,
    quoteDocument,
    isEdit,
    approvalModalFlag,
    denialModalFlag,
    pdfOutputFlag,
    navigation,
    salesTaxList,
    mailFlag,
    applyModalVisible,
    confirmModalVisible,
    isCreateUserSkipFlag
  } = useSelector(state => state.quote);
  const { corporateId, userId, skipQuoteApprovalFlag } = useSelector(
    state => state.account
  );

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: quoteDocument,
    validateOnMount: true,
    validate: () => {
      if (formik?.errors?.quoteBody?.length > 0) {
        formik.errors.quoteBody = [{salesTaxId: "消費税:税率を統一してください"}]
      }
    },
    validationSchema: Yup.object({
      quoteBody: Yup.array().of(
        Yup.object({
          salesTaxId: Yup.string().nullable().test(
            'salesTaxId',
            '消費税:税率を統一してください',
            (value, context) => {
              const salesTaxIds = formik.values.quoteBody.map(obj => obj.salesTaxId).filter(o => o !== '');
              for (let i = 1; i < salesTaxIds.length; i++) {
                if (salesTaxIds[i] !== salesTaxIds[0]) {
                  return false;
                }
              }
              return true;
          }).label('消費税'),
        }),
      ),
    }),
    onSubmit: values => {
      const totalLine = [...new Array(3)].map(
        (v, i) => values?.quoteBody.length - 2 + i
      );
      // pdf出力
      if (pdfOutputFlag) {
        dispatch(
          quoteDocumentEditPdf({
            calculationTaxFlag: values?.calculationTaxFlag,
            note: values?.note,
            totalAmount: values?.quoteAmount,
            quoteBodies: values?.quoteBody,
            salesTaxAmount: values?.quoteAmountTax,
            quoteDocumentDate: values?.quoteDocumentDate,
            quoteDocumentBranchNumber: values?.quoteDocumentBranchNumber,
            quoteDocumentLimit: values?.quoteDocumentLimit,
            quoteNumber: values?.quoteNumber,
            stampFlag: values?.stampFlag ? 1 : 0,
          })
        );
        dispatch(quoteActions.setPdfOutputFlag(false));
        return;
      }
      isEdit
        ? dispatch(
            quoteDocumentUpdate({
              calculationTaxFlag: values?.calculationTaxFlag,
              note: values?.note,
              quoteAmount: values?.quoteAmount,
              quoteBody: values?.quoteBody.filter(
                q => !totalLine.includes(q?.rowIndex)
              ),
              quoteDocumentDate: values?.quoteDocumentDate,
              quoteDocumentBranchNumber: values?.quoteDocumentBranchNumber,
              quoteDocumentLimit: values?.quoteDocumentLimit,
              quoteNumber: values?.quoteNumber,
            })
          )
            .unwrap()
            .then(result => {
              if (result.code === '0') {
                if (mailFlag) {
                  navigate(navigation, {
                    state: {
                      id: [location.state.id],
                      nm: values?.clientName,
                      quoteNm: [values?.matterName],
                      stampFlag: values?.stampFlag,
                    },
                  });
                  dispatch(quoteActions.setMailFlag(false));
                } else if (navigation) {
                  navigate(navigation);
                  dispatch(quoteActions.quoteDocumentReset());
                } else {
                  dispatch(
                    quoteDocumentStampRequest({
                      quoteNumber: location?.state?.id,
                    })
                  )
                    .unwrap()
                    .then(result => {
                      if (result.code === '0') {
                        dispatch(globalActions.showSingleModal(`申請しました`));
                        navigate('/quote/list');
                      }
                    });
                }
              }
            })
        : dispatch(
            quoteDocumentInsert({
              calculationTaxFlag: values?.calculationTaxFlag,
              note: values?.note,
              quoteAmount: values?.quoteAmount,
              quoteBody: values?.quoteBody.filter(
                q => !totalLine.includes(q?.rowIndex)
              ),
              quoteDocumentDate: values?.quoteDocumentDate,
              quoteDocumentBranchNumber: values?.quoteDocumentBranchNumber,
              quoteDocumentLimit: values?.quoteDocumentLimit,
              quoteNumber: copyId ? location?.state?.id : values?.quoteNumber,
            })
          )
            .unwrap()
            .then(result => {
              if (result.code === '0') {
                if (mailFlag) {
                  navigate(navigation, {
                    state: {
                      id: [location.state.id],
                      nm: values?.clientName,
                      quoteNm: [values?.matterName],
                      stampFlag: values?.stampFlag,
                    },
                  });
                  dispatch(quoteActions.setMailFlag(false));
                } else if (navigation) {
                  navigate(navigation);
                  dispatch(quoteActions.quoteDocumentReset());
                } else {
                  dispatch(
                    quoteDocumentStampRequest({
                      quoteNumber: location?.state?.id,
                    })
                  )
                    .unwrap()
                    .then(result => {
                      if (result.code === '0') {
                        dispatch(globalActions.showSingleModal(`申請しました`));
                        navigate('/quote/list');
                      }
                    });
                }
              }
            });
    },
  });
  const { message, selectedTemplateId, approvalStatus, createdUser } =
    formik.values;
  useEffect(() => {
    selectedTemplateId &&
      dispatch(quoteTemplateDetailFetch({ templateId: selectedTemplateId }))
        .unwrap()
        .then(result => {
          handlerFormikFieldChange(
            formik,
            'quoteBody',
            result.template.map(t => {
              return {
                ...t,
                salesTaxAmount: null,
                salesTaxId: salesTaxList[0].salesTaxId,
                totalAmount: null,
              };
            })
          );
        });
  }, [selectedTemplateId]);
  useEffect(() => {
    if (copyId) {
      dispatch(
        quoteCopyDocumentInit({
          quoteNumber: copyId,
          corporateId,
          deletedFlag: 0,
          newQuoteNumber: location?.state?.id,
        })
      )
        .unwrap()
        .then(result => {
          formik.setValues(result.quoteDocument);
        });
    } else {
      dispatch(
        quoteDocumentInit({
          quoteNumber: location?.state?.id,
          corporateId,
          deletedFlag: 0,
        })
      )
        .unwrap()
        .then(result => {
          formik.setValues(result.quoteDocument);
        });
    }
  }, []);
  return (
    <>
      <div
        className="page_base--container"
        style={checkIsSp() ? { width: '92%' } : {}}
      >
        <div
          className="page_base--tabs"
          style={checkIsSp() ? { padding: '32px 16px 24px' } : {}}
        >
          <Tabs defaultActiveKey="0">
            <TabPane tab="見積書" key="見積書">
              <div className="quote_register--quotation--quote_info">
                <InfoSteps
                  formik={formik}
                  denialButton={() =>
                    dispatch(quoteActions.setDenialModal(true))
                  }
                  approvalButton={() =>
                    dispatch(quoteActions.setApprovalModal(true))
                  }
                  totalStepUserList={quoteDocument?.totalStepUserList}
                  stepNow={quoteDocument?.stepNow}
                />
                <div>
                  <CustomFormikInput
                    formik={formik}
                    inputType="select"
                    inputName="selectedTemplateId"
                    initialValue={templateList?.map(t => t?.value)}
                    selectBoxOptions={templateList?.map(t => t?.label)}
                    // selectedSelectBox={null}
                    placeholder="見積テンプレートを選択"
                    style={{
                      areaStyle: {
                        width: checkIsSp() ? '100%' : '30%',
                        minWidth: checkIsSp() ? '100%' : '250px',
                      },
                      labelStyle: {},
                      inputStyle: {},
                      buttonStyle: {},
                    }}
                  />
                </div>
                <InfoTable
                  formik={formik}
                  invoiceFlag={false}
                  salesTaxList={salesTaxList}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>

      <div
        className="bottom_btn_area custom_bottom_btn_area"
        style={{ height: checkIsSp() ? '135px' : '92px' }}
      >
        <div
          className="quote_register--submit_buttons"
          style={checkIsSp() ? { padding: '16px' } : {}}
        >
          <Button
            text={'登録して戻る'}
            style={Options.buttonStyles.stampBack}
            onClick={() => {
              if (isEdit && !isCreateUserSkipFlag && (formik?.values?.approvalStatus === 3 || formik?.values?.approvalStatus === 1)) {
                dispatch(quoteActions.setConfirmFlag(true));
              } else {
                dispatch(quoteActions.setNavigation('/quote/list'));
                handlerFormikSubmit(formik);
              }
            }}
          />
          {approvalStatus === 0 && userId == createdUser?.id && !skipQuoteApprovalFlag && (
            <Button
              text={'承認申請'}
              style={Options.buttonStyles.stampApproval}
              onClick={() => {
                dispatch(quoteActions.setApplyModalFlag(true));
              }}
            />
          )}
          {approvalStatus === 2 && userId == createdUser?.id && !skipQuoteApprovalFlag && (
            <Button
              text={'承認再申請'}
              style={Options.buttonStyles.stampApproval}
              onClick={() => {
                dispatch(quoteActions.setApplyModalFlag(true));
              }}
            />
          )}
          <div
            className="quote_register--quotation--nano_btn_area"
            style={
              checkIsSp()
                ? {
                    padding: '8px 16px',
                    backgroundColor: 'rgba(255, 255, 255,0.6)',
                    borderRadius: '6px',
                  }
                : {}
            }
          >
            {skipQuoteApprovalFlag ? (
              <CustomFormikInput
                formik={formik}
                inputName={'stampFlag'}
                inputField={'stampFlag'}
                inputType="checkBox"
                label="社印"
                style={
                  checkIsSp()
                    ? {}
                    : {
                        areaStyle: {
                          padding: '15px 5px',
                          backgroundColor: 'rgba(255, 255, 255,0.6)',
                          borderRadius: '6px',
                        },
                      }
                }
              />
            ) : (
              ''
            )}

            <Button
              text={'PDF表示'}
              icon={Icons.icon.navyDocument}
              style={{
                backgroundColor: 'white',
                color: 'black',
                width: '140px',
                height: '36px',
                margin: '0',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '6px',
              }}
              onClick={() => {
                dispatch(quoteActions.setPdfOutputFlag(true));
                handlerFormikSubmit(formik);
              }}
              disabled={!(skipQuoteApprovalFlag || approvalStatus == 3)}
            />
            <Button
              text={'メール送信'}
              icon={Icons.icon.mailNvLight}
              style={{
                backgroundColor: 'white',
                color: 'black',
                width: '140px',
                height: '36px',
                margin: '0',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '6px',
              }}
              onClick={
                skipQuoteApprovalFlag || approvalStatus == 3
                  ? () => {
                      dispatch(quoteActions.setMailFlag(true));
                      dispatch(
                        quoteActions.setNavigation('/quote/register/mail')
                      );
                      handlerFormikSubmit(formik);
                    }
                  : () => {
                      dispatch(
                        globalActions.showSingleModal(
                          '承認済ステータスのみ送信できます'
                        )
                      );
                    }
              }
            />
          </div>
        </div>
      </div>
      <ApprovalModal
        formik={formik}
        approvalModalVisible={approvalModalFlag}
        changeModalVisible={() => {
          handlerFormikFieldChange(formik, 'message', null);
          dispatch(quoteActions.setApprovalModal(false));
        }}
        onSubmit={() => {
          dispatch(
            quoteDocumentStatus({
              quoteNumber: location?.state?.id,
              approvalType: 1,
              approvalMessage: message,
            })
          )
            .unwrap()
            .then(result => {
              dispatch(quoteActions.setApprovalModal(false));
              if (result.code === '0') {
                dispatch(globalActions.showSingleModal(`承認しました`));
                handlerFormikFieldChange(formik, 'message', null);

                dispatch(
                  quoteDocumentInit({
                    quoteNumber: location?.state?.id,
                    corporateId,
                    deletedFlag: 0,
                  })
                )
                  .unwrap()
                  .then(result => {
                    formik.setValues(result.quoteDocument);
                  });
              }
            });
        }}
      />
      <DenialModal
        formik={formik}
        denialModalVisible={denialModalFlag}
        changeModalVisible={() => {
          handlerFormikFieldChange(formik, 'message', null);
          dispatch(quoteActions.setDenialModal(false));
        }}
        onSubmit={() => {
          dispatch(
            quoteDocumentStatus({
              quoteNumber: location?.state?.id,
              approvalType: 0,
              approvalMessage: message,
            })
          )
            .unwrap()
            .then(result => {
              dispatch(quoteActions.setDenialModal(false));
              if (result.code === '0') {
                dispatch(globalActions.showSingleModal(`否認しました`));
                handlerFormikFieldChange(formik, 'message', null);

                dispatch(
                  quoteDocumentInit({
                    quoteNumber: location?.state?.id,
                    corporateId,
                    deletedFlag: 0,
                  })
                )
                  .unwrap()
                  .then(result => {
                    formik.setValues(result.quoteDocument);
                  });
              }
            });
        }}
      />
      <ApprovalApplyModal
        modalVisible={applyModalVisible}
        changeModalVisible={() => {
          handlerFormikFieldChange(formik, 'message', null);
          dispatch(quoteActions.setApplyModalFlag(false));
        }}
        onOk={() => {

          //承認申請時と同時に見積書の内容をsubmit
          const totalLine = [...new Array(3)].map(
            (v, i) => formik?.values?.quoteBody.length - 2 + i
          );
          isEdit ?
            dispatch(
              quoteDocumentUpdate({
                calculationTaxFlag: formik?.values?.calculationTaxFlag,
                note: formik?.values?.note,
                quoteAmount: formik?.values?.quoteAmount,
                quoteBody: formik?.values?.quoteBody.filter(
                  q => !totalLine.includes(q?.rowIndex)
                ),
                quoteDocumentDate: formik?.values?.quoteDocumentDate,
                quoteDocumentBranchNumber: formik?.values?.quoteDocumentBranchNumber,
                quoteDocumentLimit: formik?.values?.quoteDocumentLimit,
                quoteNumber: formik?.values?.quoteNumber,
              })
            ) :
            dispatch(
              quoteDocumentInsert({
                calculationTaxFlag: formik?.values?.calculationTaxFlag,
                note: formik?.values?.note,
                quoteAmount: formik?.values?.quoteAmount,
                quoteBody: formik?.values?.quoteBody.filter(
                  q => !totalLine.includes(q?.rowIndex)
                ),
                quoteDocumentDate: formik?.values?.quoteDocumentDate,
                quoteDocumentBranchNumber: formik?.values?.quoteDocumentBranchNumber,
                quoteDocumentLimit: formik?.values?.quoteDocumentLimit,
                quoteNumber: copyId ? location?.state?.id : formik?.values?.quoteNumber,
              })
            )
          // //承認申請時と同時に見積書の内容をsubmit↑

          dispatch(
            quoteDocumentStampRequest({
              quoteNumber: location?.state?.id,
              message: message,
            })
          ).then(() => {
            navigate('/quote/list');
            dispatch(globalActions.showSingleModal('承認申請しました'));
            handlerFormikFieldChange(formik, 'message', null);
            dispatch(quoteActions.setApplyModalFlag(false));
            dispatch(
              quoteDocumentInit({
                quoteNumber: location?.state?.id,
                corporateId,
                deletedFlag: 0,
              })
            )
              .unwrap()
              .then(result => {
                formik.setValues(result.quoteDocument);
              });
          });
        }}
        formik={formik}
      />
      <ConfirmModal
        modalVisible={confirmModalVisible}
        onOk={() => {
          dispatch(quoteActions.setConfirmFlag(false));
          dispatch(quoteActions.setNavigation('/quote/list'));
          handlerFormikSubmit(formik);
        }}
        onCancel={() => {
          dispatch(quoteActions.setConfirmFlag(false));
        }}
        message={'承認が解除されますが、更新してよろしいですか？'}
      />
    </>
  );
};

export default QuoteRegisterQuotation;
