import React from 'react';
import moment from 'moment';

const getWeek = date => {
  const JapaneseHolidays = require('japanese-holidays');

  const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
  let targetDay = new Date(date);

  const holiday = JapaneseHolidays.isHoliday(targetDay);

  if (date) {
    if (holiday) {
      return weekDay[targetDay.getDay()] + '/祝';
    } else {
      return weekDay[targetDay.getDay()];
    }
  } else {
    return '';
  }
};

export const graphConfig = {
  pie: {
    legend: false,
    tooltip: false,
    appendPadding: 0,
    padding: 0,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    pieStyle: {
      strokeOpacity: 0.5,
      lineWidth: 0,
    },
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(1)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
        fontWeight: 'bold',
        opacity: 0,
      },
      autoRotate: false,
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  },
  pieLabels: [
    [
      {
        label: '男性',
        color: '#F7A56F',
      },
      {
        label: '女性',
        color: '#F1758B',
      },
    ],
    [
      {
        label: '10代',
        color: '#44D89E',
      },
      {
        label: '20代',
        color: '#4ACDE5',
      },
      {
        label: '30代',
        color: '#C08DDC',
      },
      {
        label: '40代',
        color: '#F890D5',
      },
      {
        label: '50代',
        color: '#F1758B',
      },
      {
        label: '60代以上',
        color: '#F7A56F',
      },
    ],
    [
      {
        label: '1年未満',
        color: '#F7A56F',
      },
      {
        label: '1年',
        color: '#F1758B',
      },
      {
        label: '2年',
        color: '#F890D5',
      },
      {
        label: '3年',
        color: '#C08DDC',
      },
      {
        label: '4年',
        color: '#4ACDE5',
      },
      {
        label: '5年以上',
        color: '#44D89E',
      },
    ],
    [
      {
        label: 'スタッフ',
        color: '#44D89E',
      },
      {
        label: 'アシスタント\n' + 'ディレクター',
        color: '#4ACDE5',
      },
      {
        label: 'ディレクター',
        color: '#C08DDC',
      },
    ],
  ],
  pieColors: [
    ['#F7A56F', '#F1758B'],
    ['#44D89E', '#4ACDE5', '#C08DDC', '#F890D5', '#F1758B', '#F7A56F'],
    ['#F7A56F', '#F1758B', '#F890D5', '#C08DDC', '#4ACDE5', '#44D89E'],
    ['#44D89E', '#4ACDE5', '#C08DDC'],
  ],
  pieTitles: ['性別', '年齢', '勤続年数', 'ポジション'],
  column: {
    tooltip: false,
    xField: 'type',
    yField: 'value',
    color: '#87ACC2',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 14,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        offsetY: 13,
        offsetX: -10,
        rotate: 5.9,
        style: {
          fill: '#242424',
          fontSize: 14,
        },
      },
    },
    yAxis: {
      tickCount: 9,
      min: 0,
      max: 20,
      label: {
        formatter: (text, item, i) => {
          if ((i & 1) === 0) {
            return text;
          } else {
            return;
          }
        },
        offset: 15,
        style: {
          fill: '#242424',
          fontSize: 14,
        },
      },
    },
  },
  line: {
    tooltip: false,
    xField: 'type',
    yField: ['value', 'assignValue'],
    yAxis: {
      value: {
        tickCount: 10,
        max: 10, //ここにアサインの最大値を入力する
      },
      assignValue: {
        tickCount: 10,
        max: 10, //ここにアサインの最大値を入力する
        label: false,
      },
      matter: {
        min: 0,
        max: 21, //ここにアサインの最大値を入力する
        tickCount: 10,
        label: {
          formatter: (text, item, i) => {
            if ((i & 1) === 0) {
              return text;
            } else {
              return;
            }
          },
          style: {
            fill: '#242424',
            fontSize: 14,
          },
        },
      },
      assign: {
        min: 0,
        max: 21, //ここにアサインの最大値を入力する(二つを揃えるために同じ数字にすること)
        tickCount: 10,
        label: false,
      },
    },
    legend: false,
    xAxis: {
      label: {
        formatter: text => {
          return moment(text).format('D') + `\n\n(` + getWeek(text) + ')';
        },
        autoHide: true,
        autoRotate: false,
        style: text => {
          if (text.includes('土')) {
            return { fill: '#3662A5', fontSize: 14 };
          } else if (text.includes('日') || text.includes('祝')) {
            return { fill: '#D80245', fontSize: 14 };
          } else {
            return { fill: '#242424', fontSize: 14 };
          }
        },
      },
    },
    geometryOptions: [
      {
        geometry: 'column',
        color: '#87C2A5',
        columnWidthRatio: 0.7,
        label: {
          position: 'middle',
          style: {
            fill: '#FFFFFF',
            fontWeight: 'bold',
            fontSize: 14,
          },
        },
      },
      {
        geometry: 'line',
        smooth: false,
        color: '#5AD8A6',
        point: {
          size: 5,
          shape: 'circle',
          style: {
            fill: '#C87E7E',
            stroke: '#C87E7E',
            lineWidth: 2,
          },
        },
        lineStyle: {
          lineWidth: 3,
          stroke: '#C87E7E',
        },
      },
    ],
  },
};
