import { useState, useEffect } from 'react';
import ListView from '../../../components/listView';
import CsvExportModal from '../../../components/modal/csvExportModal';
import Button from '../../../components/button';
import CustomInput from '../../../components/customInput';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Copyright from '../../../components/copyright';
import Options from '../../../constants/options';
import Icons from '../../../constants/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  staffPayYearActions,
  staffPayYearInfoFetch,
  staffPayYearListFetch,
} from '../../../redux/slice/staffPay';
import { useFormik } from 'formik';
import './style.scss';
import { checkIsSp } from '../../../utils/fnUtil';

const StaffPayYearList = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOut, setIsOut] = useState(true);
  const [baseMon, setBaseMon] = useState('1');

  const { searchParams, staffPayYearList, managerUserIdList } = useSelector(
    state => state.staffPayYear
  );

  const formik = useFormik({
    initialValues: {
      searchParams,
      year: String(searchParams.year),
      employmentTypeArray: searchParams.employmentTypeArray,
      managerUserIdArray: searchParams.managerUserIdArray,
      isShowRetiree: true,
    },
    enableReinitialize: true,
    onSubmit: values => {
      let val = {
        ...values,
        employmentType:
          employmentTypeArray.join(',') !== ''
            ? employmentTypeArray.join(',')
            : null,
        managerUserId:
          managerUserIdArray.join(',') !== ''
            ? managerUserIdArray.join(',')
            : null,
        retireeStatus: values.isShowRetiree ? 1 : 0,
      };
      dispatch(staffPayYearActions.saveSearchParams(val));
    },
  });

  const {
    employmentTypeArray,
    managerUserIdArray,
    isShowRetiree,
    sortKey: sortColumn,
    sortMethod,
  } = formik.values;

  useEffect(() => {
    dispatch(staffPayYearInfoFetch());
    const searchBase =document.querySelector('.list_view--search_base');
    searchBase.style.zIndex = 2;
  }, []);
  useEffect(() => {
    dispatch(staffPayYearListFetch(searchParams));
  }, [dispatch, searchParams]);

  const baseMonthOptVal = {
    options: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  };

  const StaffPayYearListSearch = () => {
    return (
      <>
        <div
          style={{
            gap: '24px',
            display: 'flex',
            flexFlow: 'row wrap',
          }}
        >
          <CustomFormikInput
            formik={formik}
            inputType="inputYear"
            allowClear={false}
            label="支払年"
            inputName="year"
            placeholder="YYYY"
            className={{ areaClass: 'matter_schedule--head_select' }}
          />
          <CustomInput
            formik={formik}
            inputType="select"
            mode={'multiple'}
            showSearch={false}
            allowClear={false}
            label="スタッフ契約形態"
            inputName="employmentTypeArray"
            selectedSelectBox={employmentTypeArray}
            initialValue={Object.keys(Options.codes.employmentType)}
            selectBoxOptions={Object.values(Options.codes.employmentType)}
            placeholder="スタッフ契約形態を選択してください"
            className={{ areaClass: 'column' }}
            style={{
              areaStyle: { width: checkIsSp() ? '100%' : '327px' },
            }}
            onChange={e => {
              formik.setFieldValue('employmentTypeArray', e);
            }}
          />

          <CustomFormikInput
            formik={formik}
            inputType="input"
            label="スタッフID"
            inputName="staffId"
            placeholder="スタッフIDを入力してください"
            className={{ areaClass: 'column' }}
            style={{ areaStyle: { width: checkIsSp() ? '100%' : '258px' } }}
          />
          <CustomFormikInput
            formik={formik}
            inputType="input"
            label="スタッフ名"
            inputName="staffName"
            placeholder="スタッフ名を入力してください"
            className={{ areaClass: 'column' }}
            style={{ areaStyle: { width: checkIsSp() ? '100%' : '300px' } }}
          />
          <div style={checkIsSp() ? { width: '100%' } : {}}>
            <CustomFormikInput
              formik={formik}
              inputType="select"
              mode={'multiple'}
              showSearch={true}
              allowClear={false}
              label="メイン担当者"
              inputName="managerUserIdArray"
              initialValue={managerUserIdList.map(p => p?.userId)}
              selectBoxOptions={managerUserIdList.map(
                p => p?.userId + ':' + p?.userName
              )}
              selectedSelectBox={managerUserIdArray}
              placeholder="メイン担当者を入力してください"
              className={{ areaClass: 'column' }}
              style={{ areaStyle: { width: checkIsSp() ? '100%' : '400px' } }}
              onChange={e => {
                formik.setFieldValue('managerUserIdArray', e);
              }}
            />
          </div>
          <div
            onChange={() => {
              setIsOut(!isOut);
            }}
          >
            <CustomInput
              inputType="checkBox"
              label="退職者を表示する"
              initialValue={isShowRetiree}
              onChange={e => {
                formik.setFieldValue('isShowRetiree', e.target.checked);
              }}
              style={
                checkIsSp()
                  ? {}
                  : {
                      areaStyle: {
                        position: 'absolute',
                        right: '40px',
                        bottom: '50px',
                      },
                    }
              }
            />
          </div>
        </div>
      </>
    );
  };
  const searchFormInfo = {
    jsx: StaffPayYearListSearch,
  };

  const baseMonthColumn = {
    jan: {
      label: '1月',
      sort: '',
      dataName: 'jan',
    },
    feb: {
      label: '2月',
      sort: '',
      dataName: 'feb',
    },
    mar: {
      label: '3月',
      sort: '',
      dataName: 'mar',
    },
    apr: {
      label: '4月',
      sort: '',
      dataName: 'apr',
    },
    may: {
      label: '5月',
      sort: '',
      dataName: 'may',
    },
    jun: {
      label: '6月',
      sort: '',
      dataName: 'jun',
    },
    jul: {
      label: '7月',
      sort: '',
      dataName: 'jul',
    },
    aug: {
      label: '8月',
      sort: '',
      dataName: 'aug',
    },
    sep: {
      label: '9月',
      sort: '',
      dataName: 'sep',
    },
    oct: {
      label: '10月',
      sort: '',
      dataName: 'oct',
    },
    nov: {
      label: '11月',
      sort: '',
      dataName: 'nov',
    },
    dec: {
      label: '12月',
      sort: '',
      dataName: 'dec',
    },
  };
  const baseMonsArray = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];

  let replaceBaseMonsArray = baseMonsArray
    .slice(baseMon - 1)
    .concat(baseMonsArray.slice(0, baseMon - 1));

  const columnSettings = {
    staffId: { label: 'スタッフID', sort: '', dataName: 'staffId' },
    staffName: { label: 'スタッフ名', sort: '', dataName: 'staffName' },
    totalPay: {
      label: '年間給与',
      sort: '',
      dataName: 'totalPay',
    },
    incomeTaxAmount: {
      label: '所得税',
      sort: '',
      dataName: 'incomeTaxAmount',
    },
    statics: [],
  };

  replaceBaseMonsArray.map(key => {
    columnSettings[key] = baseMonthColumn[key];
  });

  const buttonSettings = {
    viewPager: true,
    ignoreAddBtn: checkIsSp(),
    staffYearSel: {
      inputType: 'selectBox', //inputのtype
      label: '基準月', //ラベルの名前
      inputName: 'baseMon', //DBのカラム名
      selectBoxOptions: baseMonthOptVal.options,
      initialValue: baseMonthOptVal.value, //inputの初期値
      selectedSelectBox: 1,
      unit: (
        <Button
          text="更新"
          style={{
            width: '100px',
            height: '44px',
            borderRadius: '6px',
            backgroundColor: '#0056D3',
            color: '#FFFFFF',
            fontSize: '14px',
            margin: '0 24px 0 0',
            padding: '0 0',
            userSelect: 'none',
          }}
          onClick={() => {
            setBaseMon(document.getElementById('baseMon').value);
          }}
        />
      ),
      style: {
        areaStyle: { width: 'auto' },
        labelStyle: {},
        inputStyle: { width: '153px' },
      },
    },
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => {
        toggleCsvExportVisible();
      },
      style: Options.buttonStyles.csv,
    },
  };

  const [csvExportVisible, setCsvExportVisible] = useState(false);
  const toggleCsvExportVisible = bool => {
    setCsvExportVisible(bool === undefined ? !csvExportVisible : bool);
  };
  const csvExportFunction = category => {
    alert(category);
  };

  const changeSort = e => {
    dispatch(
      staffPayYearActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`,
      })
    );
  };

  return (
    <>
      <div className={'commonPage--base'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'staffId'}
          primaryName={'staffName'}
          apiData={staffPayYearList}
          sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
          // changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={searchCount =>
            dispatch(
              staffPayYearActions.saveSearchParams({ count: searchCount })
            )
          }
          onChangeOffset={offset =>
            dispatch(staffPayYearActions.saveOffset(offset))
          }
        />
        <Copyright />
      </div>

      <CsvExportModal
        exportType={'staffYearList'}
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={toggleCsvExportVisible}
        exportFunction={csvExportFunction}
        searchParams={searchParams}
      />
    </>
  );
};

export default StaffPayYearList;
