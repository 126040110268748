import React from 'react';
import { Column } from '@ant-design/plots';
import { memo } from 'react';

const ShiftGraph = memo(({ graphData, isSp }) => {
  const labelName = ['在籍スタッフ', 'シフト', 'シフト確定済'];
  const labelColor = ['#DCDCDC', '#68B8FF', '#DBEDFF'];

  //縦のグラフ最大値を算出する処理
  const graphMaxCount = () => {
    let yearList = [];
    graphData?.forEach(obj => {
      yearList.push(obj.yearMonth);
    });

    const set = new Set(yearList);
    const newArr = [...set]; //yearListの重複した値を削除

    let numList = [];

    newArr?.forEach(y => {
      //各yearMonthの合算値を配列にまとめる
      graphData
        ?.filter(d => d?.yearMonth === y)
        .forEach(obj => {
          numList.push(obj.value);
        });
    });

    let maxCount = 0;
    if (numList.length !== 0) {
      //配列の中から最大値を取得
      const aryMax = (a, b) => Math.max(a, b);
      maxCount = numList?.reduce(aryMax);
    }

    //配列の中から最大値を丸める
    const round = n =>
      (m => m * Math.round(n / m) * 2)(10 ** (`${n}`.length - 1));

    return round(maxCount);
  };

  //グラフ設定
  const config = {
    data: graphData,
    xField: 'yearMonth',
    yField: 'value',
    seriesField: 'type',
    isGroup: true,
    color: labelColor, //グラフのカラーを指定
    legend: false,
    autoFit: !isSp,
    width: 1000,
    tooltip: {
      //フォーカスした時に表示される詳細データの設定
      customItems: item => {
        let newAry = [];
        item.map(obj => {
          return newAry.push({
            ...obj,
            value: `${Number(obj.value).toLocaleString()}人`,
          });
        });
        return newAry;
      },
      domStyles: {
        'g2-tooltip': { width: '170px' },
        'g2-tooltip-list-item': { width: '100%', fontSize: '11px' },
      },
      position: 'top',
    },
    label: {
      position: 'top',
      formatter: text =>
        text.value !== 0 ? Number(text.value).toLocaleString() : '', //データ0を削除
      style: {
        fill: '#5A5F6C',
        fontSize: isSp ? 10 : 12,
      },
    },
    xAxis: {
      //横軸のラベル部分の設定
      label: {
        style: {
          fill: '#242424',
          fontSize: isSp ? 12 : 14,
        },
      },
    },
    yAxis: {
      //縦軸のラベル部分の設定
      tickCount: 20,
      min: 0,
      max: graphMaxCount() < 10 ? 10 : graphMaxCount(), //データの最大値によって値を変更する必要あり
      label: {
        formatter: (text, item, i) => {
          if (i % 2 === 0) {
            return Number(text).toLocaleString();
          } else {
            return '';
          }
        },
        style: {
          fill: '#242424',
          fontSize: isSp ? 12 : 14,
        },
      },
    },
  };

  return isSp ? (
    <div className={'analyze_trend--graph_area'}>
      {/*右側のラベル部分(SPでは上部)*/}
      <div
        className={'analyze_trend--graph_label_area'}
        style={{ width: '100%' }}
      >
        {labelName.map((name, idx) => {
          return (
            <div
              className={'analyze_trend--label'}
              key={`analyze_trend${idx}`}
              style={{ width: '40%' }}
            >
              <div
                style={{ backgroundColor: labelColor[idx], width: '50px' }}
              />
              <span>{name}</span>
            </div>
          );
        })}
      </div>
      <div className={'analyze_trend--graph_unit'}>
        <span>人数(人)/件数(件)</span>
      </div>

      <div className={'analyze_trend--graph_display'}>
        {/*グラフ部分*/}
        <div className={'analyze_trend--graph'}>
          <Column {...config} />
        </div>
      </div>
    </div>
  ) : (
    <div className={'analyze_trend--graph_area'}>
      <div className={'analyze_trend--graph_unit'}>
        <span>人数(人)/件数(件)</span>
      </div>

      <div className={'analyze_trend--graph_display'}>
        {/*グラフ部分*/}
        <div className={'analyze_trend--graph'}>
          <Column {...config} />
        </div>

        {/*右側のラベル部分*/}
        <div
          className={'analyze_trend--graph_label_area'}
          style={{ width: '144px' }}
        >
          {labelName.map((name, idx) => {
            return (
              <div
                className={'analyze_trend--label'}
                key={`analyze_trend${idx}`}
                style={{ width: '144px' }}
              >
                <div style={{ backgroundColor: labelColor[idx] }} />
                <span>{name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default ShiftGraph;
