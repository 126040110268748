import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../redux/store';
import { staffListFetch } from '../../../redux/slice/staff';
import { staffPayClosingFetch } from '../../../redux/slice/staffPay';
import { globalActions } from '../../../redux/slice/global';
import ModalBase from '../modalBase';
import { Tooltip } from 'antd';
import { useFormik } from 'formik';
import { fetchApi } from '../../../api/fetch';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Button from '../../../components/button';
import Icons from '../../../constants/icons';
import Options from '../../../constants/options';
import { handlerFormikSubmit } from '../../../utils/fnUtil';
import Yup from '../../../utils/yupUtil';
import moment from 'moment';
import './style.scss';
import { checkIsSp } from '../../../utils/fnUtil';
import AttendanceProcessModalSp from './indexSp';

const AttendanceProcessModal = props => {
  const navigate = useNavigate();
  const { modalVisible, changeModalVisible } = props;
  const [staffDesi, setStaffDesi] = useState('0');
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const loginType = useSelector(state => state.loginType.type);
  const location = useLocation();

  const helpMessage =
    '①集計を実行すると、集計対象期間中に実施した案件の給与計算が行われ、' +
    'スタッフ給与確認開始日から、スタッフ画面で給与承認の確認が行えるようになります。' +
    '\r\n※社員は承認、スタッフは未承認ステータス' +
    '\r\n※勤怠データや時給日給を修正した場合は、給与再計算をすると給与データが更新されます。' +
    '\r\n\r\n' +
    '②スタッフ給与確認終了日になると、スタッフ画面で給与承認の確認が行えなくなります。' +
    '\r\n※社員は未承認、スタッフは承認ステータス' +
    '\r\n※社員が否認すると、社員ステータスが変更になり、スタッフに通知が届きます。' +
    '\r\n\r\n' +
    '③給与締日になると、給与が確定しスタッフ給与一覧のステータスが承認済みになり、' +
    'スタッフ年間給与一覧に給与データが作成されます。';

  const formik = useFormik({
    initialValues: {
      isSelectYearMonth: true,
      yearMonth: '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      yearMonth: Yup.string().required().nullable().label('支払年月'),
      closingFromDate: Yup.string().required().nullable().label('集計対象期間'),
      closingToDate: Yup.string().required().nullable().label('集計対象期間'),
      staffIdList: Yup.array()
        .nullable()
        .test(
          'staffIdList',
          'スタッフ指定:スタッフを選択してください',
          (value, context) => {
            if (context.parent.staffDesi === '1' && value.length === 0) {
              return false;
            } else {
              return true;
            }
          })
        .label('スタッフ指定'),
    }),
    onSubmit: values => {
      let val = {
        yearMonth: values.yearMonth,
        year: Number(values.yearMonth.split('/')[0]),
        month: Number(values.yearMonth.split('/')[1]),
        closingFromDate: values.closingFromDate,
        closingToDate: values.closingToDate,
        staffConfirmFromDate: values.staffConfirmFromDate,
        staffConfirmToDate: values.staffConfirmToDate,
        payClosingDate: values.payClosingDate,
      };
      if (values.staffId != '') {
        val.staffId = values.staffId;
      }
      dispatch(staffPayClosingFetch(val))
        .unwrap()
        .then(() => {
          dispatch(globalActions.showSingleModal('集計しました'));
          changeModalVisible(false);
        });
    },
  });

  const {
    isSelectYearMonth,
    yearMonth,
    closingFromDate,
    closingToDate,
    staffConfirmFromDate,
    staffConfirmToDate,
    payClosingDate,
    staffId = null,
    staffIdList = [],
    staffList = [],
  } = formik.values;

  const getDetailValue = async () => {
    store.dispatch(globalActions.setLoading(true));

    await fetchApi(
      'corporate',
      'detail',
      'GET',
      loginType,
      account.corporateId,
      account.accessToken,
      { corporateId: account.corporateId, deletedFlag: '0' }
    )
      .then(data => {
        let formatDate = 'YYYY/MM/DD';
        let isLastDay = data.detail.payClosingDate === 99;
        let isClosingLastDay = data.detail.staffPayConfirmClosingDate === 99;
        let staffPayConfirmClosingDate = data.detail.staffPayConfirmClosingDate;
        let payClosingDate = data.detail.payClosingDate;
        let closingDate = new Date(
          Number(yearMonth.split('/')[0]),
          isLastDay
            ? Number(yearMonth.split('/')[1])
            : Number(yearMonth.split('/')[1]) - 1,
          isLastDay ? 0 : data.detail.payClosingDate
        );
        let closingDateFrom = new Date(
          Number(yearMonth.split('/')[0]),
          Number(yearMonth.split('/')[1]) - 1,
          isLastDay ? 0 : data.detail.payClosingDate
        );

        const staffConfirmToDate = new Date(
          Number(yearMonth.split('/')[0]),
          isClosingLastDay
            ? Number(yearMonth.split('/')[1])
            : Number(yearMonth.split('/')[1]) - 1,
          isClosingLastDay ? 0 : data.detail.staffPayConfirmClosingDate
        );
        const staffConfirmFromDate = new Date(staffConfirmToDate).setDate(staffConfirmToDate.getDate() - 7);

        let payDate = new Date(
          Number(yearMonth.split('/')[0]),
          isLastDay
            ? Number(yearMonth.split('/')[1])
            : Number(yearMonth.split('/')[1]) - 1,
          isLastDay ? 0 : payClosingDate
        );

        if (staffPayConfirmClosingDate > payClosingDate) {
          payDate.setMonth(payDate.getMonth() + 1);
        }

        formik.setFieldValue(
          'staffConfirmFromDate',
          moment(staffConfirmFromDate).format(formatDate)
        );
        formik.setFieldValue(
          'staffConfirmToDate',
          moment(staffConfirmToDate).format(formatDate)
        );
        formik.setFieldValue(
          'payClosingDate',
          moment(payDate).format(formatDate)
        );
      })
      .catch(error => alert(error));
  };

  useEffect(() => {
    formik.setFieldValue('isSelectYearMonth', yearMonth === '');
    if (yearMonth === '') {
      formik.setFieldValue('closingFromDate', '');
      formik.setFieldValue('closingToDate', '');
      formik.setFieldValue('staffConfirmFromDate', '');
      formik.setFieldValue('staffConfirmToDate', '');
      formik.setFieldValue('payClosingDate', '');
    } else {
      getDetailValue().then(() =>
        store.dispatch(globalActions.setLoading(false))
      );
      formik.setFieldValue('closingFromDate', '');
      formik.setFieldValue('closingToDate', '');
    }
  }, [formik.values.yearMonth]);

  useEffect(() => {
    formik.setFieldValue(
      'staffId',
      staffIdList === [] ? '' : staffIdList.join(',')
    );
  }, [formik.values.staffIdList]);

  useEffect(() => {
    formik.setFieldValue(
      'staffId',
      staffDesi === '0' ? '' : staffIdList.join(',')
    );
    formik.setFieldValue('staffIdList', staffDesi === '0' ? [] : staffIdList);
  }, [formik.values.staffDesi]);

  useEffect(() => {
    dispatch(staffListFetch({ status: '99', sort: 'staffIdAsc' }))
      .unwrap()
      .then(data => {
        formik.setFieldValue('staffList', data.staffList.item);
      });
  }, []);

  return (
    <ModalBase
      dialogStyle={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: checkIsSp()? '0 16px': '0 40px',
        width: checkIsSp()? 'calc(100% - 64px)':'530px',
        height: checkIsSp()? '470px':'576px',
      }}
      modalVisible={modalVisible}
    >
      {checkIsSp() ?
        <AttendanceProcessModalSp
          formik={formik}
          helpMessage={helpMessage}
          handlerFormikSubmit={handlerFormikSubmit}
          changeModalVisible={changeModalVisible}
          closingToDate={closingToDate}
          staffDesi={staffDesi}
          setStaffDesi={setStaffDesi}
        />
      :
        <div className="attendance_process_modal--main">
          <div>
            <div className={'attendance_process_modal--title_area'}>
              <p style={{ height: '35px' }}>
                給与締処理
                <Tooltip
                  placement="bottom"
                  title={helpMessage}
                  mouseEnterDelay={0.5}
                  overlayClassName="quote_flow--top_area--help_tip, attendance_process_modal--tooltip"
                  overlayInnerStyle={{
                    borderRadius: '6px',
                    padding: '22px 24px 22px 24px',
                    minWidth: checkIsSp()? '100%':'400px',
                  }}
                >
                  <img
                    src={Icons.icon.help}
                    alt="help"
                    style={{
                      padding:checkIsSp()?'0 0 2px 5px': '0 0 0 5px',
                    }}
                  />
                </Tooltip>
              </p>
              {!checkIsSp() &&<div className="attendance_process_modal--sel_date">
                <CustomFormikInput
                  formik={formik}
                  inputType="inputMonth" //inputのtype
                  label="支払年月" //ラベルの名前
                  labelRequired={true}
                  inputName="yearMonth" //DBのカラム名
                  defaultValue="" //inputの初期値
                  placeholder="YYYY/MM"
                />
              </div>}
            </div>
          </div>
          {checkIsSp() &&<div className="attendance_process_modal--sel_date" style={{marginTop:'-16px'}}>
            <CustomFormikInput
              formik={formik}
              inputType="inputMonth" //inputのtype
              label="支払年月" //ラベルの名前
              labelRequired={true}
              inputName="yearMonth" //DBのカラム名
              defaultValue="" //inputの初期値
              placeholder="YYYY/MM"
            />
          </div>}
          <div className="attendance_process_modal--sel_date" style={checkIsSp()?{marginTop:'-16px'}:{}}>
            <div className="attendance_process_modal--view_date">
              <CustomFormikInput
                formik={formik}
                inputType="inputDate" //inputのtype
                label="集計対象期間"
                labelRequired={true}
                inputName="closingFromDate" //DBのカラム名
                initialValue="" //inputの初期値
                placeholder="YYYY/MM/DD"
                disabledFutureDate={closingToDate}
                disabled={formik.values.yearMonth === ''}
              />
              {checkIsSp()? <div style={{height:'32px'}}>～</div>:
                '～'}
              <CustomFormikInput
                formik={formik}
                inputType="inputDate" //inputのtype
                inputName="closingToDate" //DBのカラム名
                initialValue="" //inputの初期値
                placeholder="YYYY/MM/DD"
                disabledDate={closingFromDate}
                disabled={isSelectYearMonth}
              />
            </div>
            <div className="attendance_process_modal--view_date">
              <CustomFormikInput
                formik={formik}
                inputType="inputDate" //inputのtype
                label="スタッフ給与確認期間"
                labelRequired={false}
                inputName="staffConfirmFromDate" //DBのカラム名
                initialValue="" //inputの初期値
                placeholder="YYYY/MM/DD"
                disabledFutureDate={staffConfirmToDate}
                disabled={isSelectYearMonth}
              />
              {checkIsSp()? <div style={{height:'32px'}}>～</div>:
                '～'}
              <CustomFormikInput
                formik={formik}
                inputType="inputDate" //inputのtype
                inputName="staffConfirmToDate" //DBのカラム名
                initialValue="" //inputの初期値
                placeholder="YYYY/MM/DD"
                disabledDate={staffConfirmFromDate}
                disabled={isSelectYearMonth}
              />
            </div>
            <div className="attendance_process_modal--view_date">
              <CustomFormikInput
                formik={formik}
                inputType="inputDate" //inputのtype
                label="給与締日"
                labelRequired={false}
                inputName="payClosingDate" //DBのカラム名
                initialValue="" //inputの初期値
                placeholder="YYYY/MM/DD"
                disabled={isSelectYearMonth}
              />
            </div>
            <div className="attendance_process_modal--view_date">
              <div className="attendance_process_modal--onlyLabel">
                給与再計算
              </div>
            </div>
            <div
              className="attendance_process_modal--view_date"
              onChange={e => {
                setStaffDesi(e.target.value);
              }}
              style={{ marginBottom: '-8px' }}
            >
              <CustomFormikInput
                formik={formik}
                inputType="radioButton"
                radioButtonTitle="スタッフ指定"
                labelRequired={true}
                inputName="staffDesi"
                radioButtonLabelAndChecked={[
                  { label: '指定しない', checked: staffDesi == '0' },
                  { label: '指定する', checked: staffDesi == '1' },
                ]}
                disabled={!formik.values.yearMonth}
                initialValue={['0', '1']}
              />
            </div>
            {checkIsSp()?
              <CustomFormikInput
                formik={formik}
                inputType="select"
                mode={'multiple'}
                inputName="staffIdList"
                placeholder="スタッフを選択してください"
                disabled={staffDesi === '0' ? true : false}
                selectedSelectBox=""
                style={{
                  labelStyle: { width: '148px' },
                  inputStyle: {
                    height: '44px',
                    boxSizing: 'border-box',
                  },
                  areaStyle: { width: '100%' },
                }}
                initialValue={staffList.map(p => p?.staffId)}
                selectBoxOptions={staffList.map(p => p?.staffName)}
                allowClear={false}
              />:
              <div className={'attendance_process_modal--select_area'}>
                <CustomFormikInput
                  formik={formik}
                  inputType="select"
                  mode={'multiple'}
                  label=" "
                  inputName="staffIdList"
                  placeholder="スタッフを選択してください"
                  disabled={staffDesi === '0' ? true : false}
                  selectedSelectBox=""
                  style={{
                    labelStyle: { width: '148px' },
                    inputStyle: {
                      height: '44px',
                      boxSizing: 'border-box',
                    },
                    areaStyle: { width: '490px' },
                  }}
                  initialValue={staffList.map(p => p?.staffId)}
                  selectBoxOptions={staffList.map(p => p?.staffName)}
                  allowClear={false}
                />
              </div>
            }
          </div>
          <div className="attendance_process_modal--btndiv">
            <Button
              text={'戻る'}
              style={Options.buttonStyles.back}
              onClick={() => changeModalVisible(false)}
            />
            <Button
              text={'集計'}
              style={Options.buttonStyles.submit}
              onClick={() => {
                handlerFormikSubmit(formik);
              }}
            />
          </div>
        </div>
      }
    </ModalBase>
  );
};

export default AttendanceProcessModal;
