import { useNavigate } from 'react-router-dom';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Copyright from '../../../components/copyright';
import Options from '../../../constants/options';
import { useEffect, useState } from 'react';
import Button from '../../../components/button';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  invoiceActions,
  invoiceCategoryInit,
  invoiceDelete,
  invoiceDocumentPdf,
  invoiceFetch,
  invoiceLockUpdate,
  invoiceRequestUpdate,
} from '../../../redux/slice/invoice';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import options from '../../../constants/options';
import { namingFetch } from '../../../redux/slice/naming';
import { globalActions } from '../../../redux/slice/global';
import moment from 'moment';
import { checkIsSp } from '../../../utils/fnUtil';

const InvoiceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    searchParams,
    invoiceList,
    namingList,
    clientList,
    userList,
    namingCategoryList,
  } = useSelector(state => state.invoice);
  const accountInfo = useSelector(state => state.account);
  const [menuDisplay, setMenuDisplay] = useState(false);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => dispatch(invoiceActions.saveSearchParams(values)),
  });
  const {
    namingClassificationId,
    requestDateFrom,
    requestDateTo,
    sortKey: sortColumn,
    sortMethod,
    companySeal,
  } = formik.values;

  const searchFormInfos = {
    inputs: [
      {
        inputType: 'input',
        label: '請求書No',
        inputName: 'invoiceNumber',
        initialValue: '',
        placeholder: '請求書Noを入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: {
            width: checkIsSp() ? '100%' : '15%',
            minWidth: checkIsSp() ? '' : '240px',
          },
        },
      },
      {
        inputType: 'input',
        label: '案件名',
        inputName: 'matterName',
        initialValue: '',
        placeholder: '案件名を入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '24%' },
        },
      },
      {
        inputType: 'select',
        label: '取引先',
        mode: 'multiple',
        showSearch: true,
        allowClear: false,
        inputName: 'clientId',
        initialValue: clientList.map(c => c.value),
        selectBoxOptions: clientList.map(c => c.label),
        placeholder: '取引先を入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: {
            width: checkIsSp() ? '100%' : '15%',
            minWidth: checkIsSp() ? '' : '240px',
          },
        },
      },
      {
        inputType: 'inputDate',
        label: '請求(予定)日',
        inputName: 'requestDateFrom',
        customDisabledDate: current =>
          requestDateTo && current > moment(requestDateTo),
        initialValue: '',
        placeholder: 'YYYY/MM/DD',
      },
      {
        inputType: 'inputDate',
        label: ' ',
        inputName: 'requestDateTo',
        customDisabledDate: current => current < moment(requestDateFrom),
        initialValue: '',
        placeholder: 'YYYY/MM/DD',
      },
      {
        inputType: 'select',
        label: '受注ステータス',
        mode: 'multiple',
        showSearch: true,
        allowClear: false,
        inputName: 'orderStatus',
        initialValue: options.codes.orderStatus.map(o => o.value),
        selectBoxOptions: options.codes.orderStatus.map(o => o.label),
        selectedSelectBox: formik.values.orderStatus,
        placeholder: '受注ステータスを選択してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: {
            width: checkIsSp() ? '100%' : '9%',
            minWidth: checkIsSp() ? '100%' : '240px',
          },
        },
      },
      {
        inputType: 'select',
        label: '請求ステータス',
        mode: 'multiple',
        inputName: 'requestStatus',
        showSearch: true,
        allowClear: false,
        initialValue: options.codes.requestStatus.map(o => o.value),
        selectBoxOptions: options.codes.requestStatus.map(o => o.label),
        selectedSelectBox: formik.values.requestStatus,
        placeholder: '請求ステータスを選択してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: {
            width: checkIsSp() ? '100%' : '9%',
            minWidth: checkIsSp() ? '100%' : '240px',
          },
        },
      },
      {
        inputType: 'select',
        label: '社内担当者',
        inputName: 'matterManagerId',
        showSearch: true,
        allowClear: false,
        mode: 'multiple',
        initialValue: userList.map(c => c.value),
        selectBoxOptions: userList.map(c => c.label),
        placeholder: '社内担当者を入力してください',
        className: {
          areaClass: 'column',
        },
        style: {
          areaStyle: { width: checkIsSp() ? '100%' : '24%' },
        },
      },
      {
        inputType: 'select',
        label: '予備項目',
        inputName: 'namingClassificationId',
        showSearch: true,
        allowClear: true,
        initialValue: namingCategoryList.map(p => p?.value),
        selectBoxOptions: namingCategoryList.map(p => p?.label),
        selectedSelectBox: formik.values.namingClassificationId,
        placeholder: '予備項目を入力してください',
        className: { areaClass: 'column' },
        style: {
          areaStyle: {
            width: checkIsSp() ? '100%' : '45%',
            minWidth: checkIsSp() ? '' : '240px',
          },
        },
      },
      {
        inputType: 'select',
        inputName: 'namingId',
        showSearch: false,
        mode: 'multiple',
        allowClear: false,
        initialValue: namingList
          ?.filter(n => n?.namingClassificationId === namingClassificationId)
          .map(p => p?.value),
        selectBoxOptions: namingList
          ?.filter(n => n?.namingClassificationId === namingClassificationId)
          .map(p => `${p?.namingId}:${p?.label}`),
        selectedSelectBox: formik.values.namingId,
        disabled: !formik?.values?.namingClassificationId,
        placeholder: '選択してください',
        className: { areaClass: 'column' },
        style: { areaStyle: { width: checkIsSp() ? '100%' : '55%' } },
      },
    ],
  };

  const invoiceListSearchArea = () => (
    <div className={'invoice_list--search_area'}>
      {searchFormInfos.inputs.map((inputInfo, idx) => {
        return (
          idx < 3 && (
            <CustomFormikInput formik={formik} {...inputInfo} key={idx} />
          )
        );
      })}
      <div className={'invoice_list--search_area_date'}>
        <CustomFormikInput formik={formik} {...searchFormInfos.inputs[3]} />
        <span className={'invoice_list--search_area_date_unit'}>〜</span>
        <CustomFormikInput formik={formik} {...searchFormInfos.inputs[4]} />
      </div>

      <CustomFormikInput formik={formik} {...searchFormInfos.inputs[5]} />
      <CustomFormikInput formik={formik} {...searchFormInfos.inputs[6]} />
      <CustomFormikInput formik={formik} {...searchFormInfos.inputs[7]} />

      <div className={'invoice_list--search_area_select'}>
        <CustomFormikInput
          {...searchFormInfos.inputs[8]}
          formik={formik}
          onChange={value => {
            handlerFormikFieldChange(
              formik,
              searchFormInfos.inputs[8].inputName,
              value
            );
            handlerFormikFieldChange(
              formik,
              searchFormInfos.inputs[9].inputName,
              []
            );
          }}
        />
        <CustomFormikInput
          {...searchFormInfos.inputs[9]}
          formik={formik}
          disabled={!namingClassificationId}
        />
      </div>
    </div>
  );

  const searchFormInfo = {
    jsx: invoiceListSearchArea,
  };

  const columnSettings = {
    checkAll: true,
    invoiceNumber: {
      label: '請求書No',
      sort: 'none',
      dataName: 'invoiceNumber',
      jsx: invoice => (
        <span
          className="matter_list--matter_no"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: '60px',
          }}
        >
          {invoice.deleteLockFlag === 1 && (
            <img
              className="matter_list--matter_no_lock"
              src={Icons.icon.lockGr}
              style={{ width: '24px', height: '24px', marginBottom: '4px' }}
            />
          )}
          <span style={{ width: '100%', textAlign: 'right' }}>
            {invoice.invoiceNumber}
          </span>
        </span>
      ),
    },
    matterName: { label: '案件名', sort: 'none', dataName: 'matterName' },
    clientName: { label: '取引先', sort: 'none', dataName: 'clientName' },
    requestDate: { label: '請求日', sort: 'none', dataName: 'invoiceDate' },
    matterManagerName: {
      label: '社内案件担当者',
      sort: 'none',
      dataName: 'matterUserName',
    },
    salesManagerName: {
      label: '社内営業担当者',
      sort: 'none',
      dataName: 'salesUserName',
    },
    orderStatus: {
      label: '受注ステータス',
      sort: 'none',
      dataName: 'orderStatus',
    },
    requestStatus: {
      label: '請求ステータス',
      sort: 'none',
      dataName: 'billedStatus',
    },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };

  const buttonSettings = {
    viewPager: true,
    addButton: {
      props: {
        type: '', //roundを指定するとボタンがまるくなる
        onClick: () => navigate('/invoice/register'),
        url: '',
        displayText: true,
        disabled: '',
      },
    },
  };

  const changeSort = e => {
    dispatch(
      invoiceActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`,
      })
    );
  };

  const customInputInfo = {
    inputName: 'companySeal',
    inputField: 'companySeal',
    inputType: 'checkBox',
    label: '社印',
  };

  const getCheckedIndex = params => {
    const checkAll = document.getElementById('checkAll').checked;
    const checks = document.querySelectorAll('input[id^="check_"]');

    let ret = '';
    if (checkAll) {
      ret = 'all';
    } else {
      ret = [];
      checks.forEach((elm, index) => {
        if (elm.checked) {
          ret.push(index);
        }
      });
      ret.filter(v => v);
    }
    if (params === 1) {
      if (ret.length < 1) {
        dispatch(globalActions.showSingleModal(`１つ以上選択してください`));
        return;
      } else if (ret === 'all' || ret.length > 1) {
        dispatch(globalActions.showSingleModal(`PDFは１請求のみ出力可能です`));
        return;
      }
    } else if (
      (params === 2 || params === 3 || params === 4) &&
      ret.length < 1 &&
      ret !== 'all'
    ) {
      dispatch(globalActions.showSingleModal(`１つ以上選択してください`));
      return;
    }
    const param =
      ret === 'all'
        ? invoiceList?.item?.map(i => {
            return i.invoiceNumber;
          })
        : invoiceList?.item
            ?.filter((item, i) => ret.includes(i))
            .map(i => {
              return i.invoiceNumber;
            });

    // PDF表示
    if (params === 1) {
      dispatch(
        invoiceDocumentPdf({
          invoiceNumber: param[0],
          stampFlag: companySeal ? 1 : 0,
          invoiceDocumentDate: invoiceList?.item?.find(
            i => i?.invoiceNumber == param
          )?.requestDate,
        })
      );
    }
    // 報告書ロック
    else if (params === 2) {
      dispatch(invoiceLockUpdate({ invoiceNumber: param, lockStatus: 1 }))
        .unwrap()
        .then(() => {
          dispatch(invoiceFetch(searchParams));
        });
    }
    // 報告書ロック解除
    else if (params === 3) {
      dispatch(invoiceLockUpdate({ invoiceNumber: param, lockStatus: 0 }))
        .unwrap()
        .then(() => {
          dispatch(invoiceFetch(searchParams));
        });
    }
    // 請求済ステータス更新
    else if (params === 4) {
      dispatch(invoiceRequestUpdate({ invoiceNumber: param }))
        .unwrap()
        .then(() => {
          dispatch(invoiceFetch(searchParams));
        });
    }
  };

  //メニュー...
  const toggleMenu = () => {
    setMenuDisplay(() => !menuDisplay);
  };

  useEffect(() => {
    dispatch(invoiceCategoryInit());
    dispatch(namingFetch());
    return () => {
      dispatch(invoiceActions.saveParam());
      dispatch(invoiceActions.refresh());
    };
  }, []);

  useEffect(() => {
    dispatch(invoiceFetch(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    const searchBase = document.querySelector('.list_view--search_base');
    searchBase.style.zIndex = 2;
  }, []);

  const isSp = checkIsSp();
  const { isOpenSideMenu } = useSelector(state => state.global);

  return (
    <>
      <div className={'commonPage--base'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'invoiceNumber'}
          primaryName={'matterName'}
          apiData={invoiceList}
          sortSettings={{ sortColumn, sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={count =>
            dispatch(invoiceActions.saveSearchParams({ count }))
          }
          onChangeOffset={offset => dispatch(invoiceActions.saveOffset(offset))}
          onDelete={invoiceNumber => dispatch(invoiceDelete({ invoiceNumber }))}
          displayNotice={false}
        />
        <Copyright />

        <div
          className="bottom_btn_area invoice_list--button_area"
          style={
            checkIsSp()
              ? {
                  bottom: '88px',
                  backgroundColor: 'rgba(106, 111, 124, 0.28)',
                  display: menuDisplay ? 'flex' : 'none',
                }
              : {
                  width: isOpenSideMenu
                    ? 'calc(100% - 200px)'
                    : 'calc(100% - 56px)',
                  transition: 'width .2s',
                }
          }
        >
          {!checkIsSp() && (
            <Button
              text="請求済にする"
              style={
                checkIsSp()
                  ? { ...Options.buttonStyles.stampApproval, width: '80%' }
                  : Options.buttonStyles.stampApproval
              }
              onClick={() => getCheckedIndex(4)}
            />
          )}
          <div className={'invoice_list--button_area_unity'}>
            <div
              className={'invoice_list--button_area_unity_sub_area'}
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                width: checkIsSp()
                  ? 'calc((200% - 52px - 24px - 64px)/ 3 + 120px)'
                  : '',
              }}
            >
              <div className={'invoice_list--checkbox'}>
                <CustomFormikInput formik={formik} {...customInputInfo} />
              </div>

              <Button
                text={'PDF表示'}
                icon={Icons.icon.navyDocument}
                style={Options.buttonStyles.invoiceListButton}
                onClick={() => getCheckedIndex(1)}
              />
            </div>
            {accountInfo.unlockInvoiceFlag === 1 && (
              <>
                <div
                  className={'invoice_list--button_area_unity_sub_area'}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                    width: checkIsSp()
                      ? 'calc(((200% - 52px - 24px - 64px)/ 3) * 2 + 16px)'
                      : '',
                  }}
                >
                  <Button
                    text={'ロック'}
                    icon={Icons.icon.lockNvT}
                    style={Options.buttonStyles.invoiceListButton}
                    onClick={() => getCheckedIndex(2)}
                  />
                  <Button
                    text={'ロック解除'}
                    icon={Icons.icon.unLockNv}
                    style={Options.buttonStyles.invoiceListButton}
                    onClick={() => getCheckedIndex(3)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {checkIsSp() && (
          <div className={'invoice_list--button_area'}>
            <div className="quote_list--menu_button" style={{ width: '40px' }}>
              <Button
                text={menuDisplay ? '×' : '...'}
                style={{
                  width: '100%',
                  height: '40px',
                  border: '1px solid #2b3245',
                  borderRadius: '6px',
                  backgroundColor: '#FFFFFF',
                  color: '#000000',
                  fontSize: '20px',
                  margin: '0 0',
                  padding: '0 0',
                }}
                onClick={toggleMenu}
              />
            </div>

            <Button
              text="請求済にする"
              style={
                checkIsSp()
                  ? { ...Options.buttonStyles.stampApproval, width: '80%' }
                  : Options.buttonStyles.stampApproval
              }
              onClick={() => getCheckedIndex(4)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default InvoiceList;
