import Icons from '../../../constants/icons';
import Button from '../../../components/button';
import './style.scss';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Inputs from './registerInputs';
import Options from '../../../constants/options';
import CustomInput from '../../../components/customInput';
import { surveyActions, surveyTargetCount } from '../../../redux/slice/survey';
import CsvImportModal from '../../../components/modal/csvImportModal';
import { useSelector } from 'react-redux';

const SurveyRegisterHeader = ({ formik, dispatch }) => {
  const {
    targetMethod,
    targetType,
    gender,
    ages,
    professions,
    staffPositions,
    prefFlag,
    surveyQuestionInfos,
  } = formik?.values;

  const surveyBatchFlag = surveyQuestionInfos?.some(obj => obj?.batchFlag === 1)
    ? 1
    : 0;

  const { csvImportVisible, targetCount } = useSelector(state => state.survey);

  const toggleOpenSearchForm = () => {
    const searchFormArea = document.querySelector(
      '.list_view--search_form_area'
    );
    const searchBase = document.querySelector('.list_view--search_base');
    const triangle = document.getElementById('searchFormTriangle');

    triangle.style.transition = 'all .5s';

    if (
      triangle.style.transform === '' ||
      triangle.style.transform === 'rotate(0deg)'
    ) {
      searchFormArea.style.opacity = '0';
      searchFormArea.style.height = '0';
      searchFormArea.style.overflow = 'hidden';
      triangle.style.transform = 'rotate(180deg)';
      searchBase.style.maxHeight = '20px';
    } else {
      searchFormArea.style.opacity = '1';
      searchFormArea.style.height = 'fit-content';
      searchFormArea.style.overflow = 'visible';
      triangle.style.transform = 'rotate(0deg)';
      searchBase.style.maxHeight = '1000px';
    }
  };

  const handleFileChange = e => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = e => {
      const contents = e.target.result;
      let trimmedDataString;

      // txtファイルの場合は直接値をセットする
      if (file.name.endsWith('.txt')) {
        trimmedDataString = contents.replace(/\s+/g, '').replace(/^,|,$/g, '');
      } else {
        // それ以外のファイル形式の場合は処理を行う
        const numberArray = contents
          .split(/\r?\n/)
          .map(item => item.trim())
          .filter(item => !isNaN(item));
        trimmedDataString = numberArray.join(',').replace(/^,|,$/g, '');
      }

      formik.setFieldValue('staffList', trimmedDataString);
    };

    reader.readAsText(file);
  };

  const checkDisabled = () => {
    return surveyBatchFlag === 1 || targetMethod != 1;
  };

  const countStaffId = () => {
    const staffList = formik.values.staffList;
    const staffIdList = staffList?.split(',')?.map(Number);
    return staffIdList?.length;
  };

  return (
    <>
      <div
        className="commonPage--base"
        style={{ paddingTop: '0', paddingBottom: '0', zIndex: 3 }}
      >
        {/* 検索条件部分 */}
        <div className="list_view--search_base">
          <div className="list_view--search_title_area">
            <img
              id="searchFormTriangle"
              src={Icons.icon.downNv}
              onClick={toggleOpenSearchForm}
            />
            <p>基本情報</p>
            <p className={'survey_register--search_area_note'}>
              *は必須項目です
            </p>
          </div>
          <div className="list_view--search_form_area">
            <div className={'survey_register--segment_area'}>
              {/*指定方法*/}
              <div className={'survey_register--segment_area--mass'}>
                <CustomFormikInput
                  {...Inputs[0]}
                  formik={formik}
                  radioButtonLabelAndChecked={[
                    { label: 'セグメント指定', checked: targetMethod == 0 },
                    {
                      label: '自社スタッフIDアップロード',
                      checked: targetMethod == 1,
                    },
                  ]}
                  disabled={surveyBatchFlag === 1}
                />
                <>
                  <label
                    className={'import_modal--file_selector'}
                    htmlFor={'fileInput'}
                    style={{
                      ...Options.buttonStyles.csv,
                      width: '200px',
                      border: checkDisabled()
                        ? '1px solid #BCBCBC'
                        : '1px solid #2B3245',
                    }}
                  >
                    <img
                      src={
                        checkDisabled()
                          ? Icons.icon.importGr
                          : Icons.icon.import
                      }
                      alt={'Clip'}
                    />
                    <span
                      style={{
                        color: checkDisabled() ? '#BCBCBC' : '#2B3245',
                      }}
                    >
                      対象者アップロード
                    </span>
                  </label>
                  <input
                    id={'fileInput'}
                    type={'file'}
                    hidden={true}
                    onChange={e => handleFileChange(e)}
                    accept={'.txt, .csv'}
                    disabled={checkDisabled()}
                  />
                </>

                {targetMethod != 0 &&
                !checkDisabled() &&
                formik?.values?.staffList !== null
                  ? `${countStaffId().toLocaleString()}件`
                  : ``}
              </div>

              {/*実施対象*/}
              <CustomFormikInput
                {...Inputs[1]}
                formik={formik}
                radioButtonLabelAndChecked={[
                  { label: '自社+他社', checked: targetType == 0 },
                  { label: '自社のみ', checked: targetType == 1 },
                  { label: '他社のみ', checked: targetType == 2 },
                ]}
                disabled={surveyBatchFlag === 1 || targetMethod != 0}
              />

              {/*性別*/}
              <CustomFormikInput
                {...Inputs[2]}
                formik={formik}
                radioButtonLabelAndChecked={[
                  { label: '指定しない', checked: gender == 0 },
                  { label: '男性のみ', checked: gender == 1 },
                  { label: '女性のみ', checked: gender == 2 },
                ]}
                disabled={surveyBatchFlag === 1 || targetMethod != 0}
              />

              {/*年代*/}
              <div className={'survey_register--segment_area--mass'}>
                <CustomFormikInput
                  {...Inputs[3]}
                  formik={formik}
                  radioButtonLabelAndChecked={[
                    { label: '指定しない', checked: ages == 0 },
                    { label: '指定する', checked: ages != 0 },
                  ]}
                  disabled={surveyBatchFlag === 1 || targetMethod != 0}
                />
                <CustomInput
                  {...Inputs[4]}
                  disabled={
                    surveyBatchFlag === 1 || targetMethod != 0 || ages == 0
                  }
                  onChange={e => {
                    const oldValue = formik.values?.agesCheckBox ?? '';
                    const elm = e.target.id;
                    const newValue = parseInt(elm.slice(-1));
                    const updatedValue = e.target.checked
                      ? oldValue + newValue
                      : oldValue.replace(newValue, '');
                    formik.setFieldValue(`agesCheckBox`, updatedValue);
                  }}
                />
              </div>

              {/*職業*/}
              <div className={'survey_register--segment_area--mass'}>
                <CustomFormikInput
                  {...Inputs[5]}
                  formik={formik}
                  radioButtonLabelAndChecked={[
                    { label: '指定しない', checked: professions == 0 },
                    { label: '指定する', checked: professions != 0 },
                  ]}
                  disabled={surveyBatchFlag === 1 || targetMethod != 0}
                />
                <CustomInput
                  {...Inputs[6]}
                  disabled={
                    surveyBatchFlag === 1 ||
                    targetMethod != 0 ||
                    professions == 0
                  }
                  onChange={e => {
                    const oldValue = formik.values?.professionsCheckBox ?? '';
                    const elm = e.target.id;
                    const newValue = parseInt(elm.slice(-1));
                    const updatedValue = e.target.checked
                      ? oldValue + newValue
                      : oldValue.replace(newValue, '');
                    formik.setFieldValue(`professionsCheckBox`, updatedValue);
                  }}
                />
              </div>

              {/*スタッフポジション*/}
              <div className={'survey_register--segment_area--mass'}>
                <CustomFormikInput
                  {...Inputs[7]}
                  formik={formik}
                  radioButtonLabelAndChecked={[
                    { label: '指定しない', checked: staffPositions == 0 },
                    { label: '指定する', checked: staffPositions != 0 },
                  ]}
                  disabled={surveyBatchFlag === 1 || targetMethod != 0}
                />
                <CustomInput
                  {...Inputs[8]}
                  disabled={
                    surveyBatchFlag === 1 ||
                    targetMethod != 0 ||
                    staffPositions == 0
                  }
                  onChange={e => {
                    const oldValue =
                      formik.values?.staffPositionsCheckBox ?? '';
                    const elm = e.target.id;
                    const newValue = parseInt(elm.slice(-1));
                    const updatedValue = e.target.checked
                      ? oldValue + newValue
                      : oldValue.replace(newValue, '');
                    formik.setFieldValue(
                      `staffPositionsCheckBox`,
                      updatedValue
                    );
                  }}
                />
              </div>

              {/*居住都道府県*/}
              <div
                className={'survey_register--segment_area--mass'}
                style={{ alignItems: 'flex-start' }}
              >
                <CustomFormikInput
                  {...Inputs[9]}
                  formik={formik}
                  radioButtonLabelAndChecked={[
                    { label: '指定しない', checked: prefFlag == 0 },
                    { label: '指定する', checked: prefFlag != 0 },
                  ]}
                  disabled={surveyBatchFlag === 1 || targetMethod != 0}
                  onChange={e => {
                    formik.setFieldValue('prefFlag', e.target.id);
                    if (e.target.id == 0) {
                      formik.setFieldValue('prefList', []);
                    }
                  }}
                />
                <CustomFormikInput
                  {...Inputs[10]}
                  formik={formik}
                  onChange={e => formik.setFieldValue('prefList', e)}
                  disabled={
                    surveyBatchFlag === 1 || targetMethod != 0 || prefFlag == 0
                  }
                />
              </div>

              <div className={'survey_register--segment_area--mass'}>
                <Button
                  text={'対象目安人数を確認する'}
                  style={{
                    ...Options.buttonStyles.submit,
                    marginLeft: '256px',
                  }}
                  onClick={() => {
                    dispatch(
                      surveyTargetCount({
                        targetMethod: 0,
                        targetType: Number(formik.values.targetType),
                        gender: Number(formik.values.gender),
                        ages:
                          formik.values.ages == '0' ||
                          formik.values.agesCheckBox === ''
                            ? '0'
                            : formik.values.agesCheckBox
                                .split('')
                                .sort()
                                .join(','),
                        professions:
                          formik.values.professions == '0' ||
                          formik.values.professionsCheckBox === ''
                            ? '0'
                            : formik.values.professionsCheckBox
                                .split('')
                                .sort()
                                .join(','),
                        staffPositions:
                          formik.values.staffPositions == '0' ||
                          formik.values.staffPositionsCheckBox === ''
                            ? '0'
                            : formik.values.staffPositionsCheckBox
                                .split('')
                                .sort()
                                .join(','),
                        prefFlag: Number(formik.values.prefFlag),
                        prefList:
                          formik.values.prefFlag == 0
                            ? null
                            : !formik.values.prefList ||
                              formik.values.prefList.length === 0
                            ? null
                            : formik.values.prefList?.join(','),
                      })
                    ).then(data => {
                      formik.setFieldValue(
                        'targetCount',
                        data?.payload?.targetCount
                      );
                      dispatch(surveyActions.countFresh(0));
                    });
                  }}
                  disabled={surveyBatchFlag === 1 || targetMethod != 0}
                />
                <span style={{color: surveyBatchFlag === 1 ? '#bcbcbc' : '#242424'}}>
                  {`対象人数：${
                    targetMethod == 1 ?
                      (formik?.values?.staffList !== null ? countStaffId().toLocaleString() : 0) :
                      (formik?.values?.targetCount !== 0 ? formik?.values?.targetCount?.toLocaleString() : targetCount?.toLocaleString())
                  }人`}
                </span>
              </div>

              <CustomFormikInput
                {...Inputs[11]}
                formik={formik}
                disabled={surveyBatchFlag === 1}
              />
            </div>
          </div>
        </div>
      </div>

      <CsvImportModal
        importType={'surveyStaffList'}
        csvImportVisible={csvImportVisible}
        setCsvImportVisible={() =>
          dispatch(surveyActions.setCsvImportVisible(!csvImportVisible))
        }
      />
    </>
  );
};

export default SurveyRegisterHeader;
