import { Modal } from 'antd';
import Options from '../../../constants/options';
import Button from '../../button';
import './style.scss';

const ApplyFormatModal = ({ modalVisible, onApplyFormat, onCancel }) => {
  return (
    <Modal
      visible={modalVisible}
      style={{
        minWidth: 600,
      }}
      bodyStyle={{
        height: 307,
      }}
      footer={null}
      centered
      closable={false}
      className="common_modal_base apply_format_modal_wrapper"
      onCancel={onCancel}
    >
      <div className="apply_format_modal">
        <div className="apply_format_modal--item">
          <div className="apply_format_modal--title">
            フォーマットを適用します
          </div>
          <div className="apply_format_modal--text">
            <span>作成中のデータは失われてしまいます</span>
            <span>フォーマットを適用してよろしいですか？</span>
          </div>
          <div className={'apply_format_modal--actions'}>
            <Button
              text={'戻る'}
              style={Options.buttonStyles.back}
              onClick={onCancel}
            />
            <Button
              text={'適用する'}
              style={Options.buttonStyles.submit}
              onClick={onApplyFormat}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApplyFormatModal;
