import Icons from './icons';
import { store } from '../redux/store';
import { globalActions } from '../redux/slice/global';
import { apiUrl } from '../api/apiUrl';
import moment from 'moment';

const Options = {
  counterSubject: {
    path: {
      '/staff/list': ['全スタッフ数', '有効スタッフ数'],
      '/client/list': ['全取引先数', '有効取引先数'],
      '/venue/list': ['全開催地数', '有効開催地数'],
      '/master/user/list': ['全社員数', '有効社員数'],
      '/master/corporate/list': ['全数法人数', '有効法人数'],
      '/master/partner/list': ['全協力会社数'],
    },
  },
  menus: {
    backoffice: {
      dashboard: {
        path: '/',
        icon: Icons.icon.dashboard,
        mainText: 'ダッシュボード',
        flag: '',
      },
      quote: {
        path: '/quote',
        icon: Icons.icon.quote,
        mainText: '見積管理',
        sub: [
          {
            path: '/quote/list',
            text: '見積一覧',
            flag: 'menuQuoteListFlag',
          },
          {
            path: '/quote/approval/list',
            text: '見積申請一覧',
            flag: 'menuQuoteApprovalListFlag',
          },
          {
            path: '/',
            text: '見積書No管理',
            flag: 'menuQuoteDocumentNumberFlag',
            setState: boo => {
              store.dispatch(globalActions.setQuoteNoModal(boo));
            },
          },
          {
            path: '/quote/template/list',
            text: '見積テンプレート一覧',
            flag: 'menuQuoteTemplateListFlag',
          },
          {
            path: '/quote/flow/list',
            text: '見積承認フロー一覧',
            flag: 'menuQuoteFlowListFlag',
          },
        ],
      },
      matter: {
        path: '/matter',
        icon: Icons.icon.matter,
        mainText: '案件管理',
        sub: [
          {
            path: '/matter/list',
            text: '案件一覧',
            flag: 'menuMatterListFlag',
          },
          {
            path: '/matter/date/list',
            text: '実施日別案件一覧',
            flag: 'menuMatterDateListFlag',
          },
          {
            path: '/matter/assign-state/list',
            text: 'アサインスタッフ状況',
            flag: 'menuMatterAssignStateListFlag',
          },
          {
            path: '/matter/assign/list',
            text: 'スタッフ報告状況',
            flag: 'menuMatterAssignListFlag',
          },
          {
            path: '/matter/recruit/list',
            text: 'スタッフ募集案件一覧',
            flag: 'menuMatterOpenListFlag',
          },
          {
            path: '/matter/applied/list',
            text: '案件実施日別応募者一覧',
            flag: 'menuMatterAppliedListFlag',
          },
        ],
      },
      invoice: {
        path: '/invoice',
        icon: Icons.icon.invoice,
        mainText: '請求管理',
        sub: [
          {
            path: '/invoice/list',
            text: '請求一覧',
            flag: 'menuInvoiceListFlag',
          },
        ],
      },
      staff: {
        path: '/staff',
        icon: Icons.icon.staff,
        mainText: 'スタッフ管理',
        sub: [
          {
            path: '/staff/list',
            text: 'スタッフ一覧',
            flag: 'menuStaffListFlag',
          },
        ],
      },
      plan: {
        path: '/staff-plan',
        icon: Icons.icon.plan,
        mainText: 'スタッフシフト管理',
        sub: [
          {
            path: '/staff-plan/list',
            text: 'スタッフシフト予定一覧',
            flag: 'menuStaffPlanListFlag',
          },
        ],
      },
      pay: {
        path: '/staff-pay',
        icon: Icons.icon.pay,
        mainText: 'スタッフ支払管理',
        sub: [
          {
            path: '/staff-pay/list',
            text: 'スタッフ支払一覧',
            flag: 'menuStaffPayListFlag',
          },
          {
            path: '/staff-pay/year/list',
            text: 'スタッフ年間給与一覧',
            flag: 'menuStaffPayYearListFlag',
          },
          {
            path: '/',
            text: '給与締処理',
            flag: 'menuAttendClosingFlag',
            setState: boo => {
              store.dispatch(globalActions.setCutOffModal(boo));
            },
          },
        ],
      },
      analyze: {
        path: '/analyze',
        icon: Icons.icon.analyze,
        mainText: '分析',
        sub: [
          {
            path: '/analyze/trend',
            text: 'スタッフ動向分析',
            flag: 'menuAnalyzeTrendFlag',
          },
          {
            path: '/analyze/profit',
            text: '売上分析',
            flag: 'menuAnalyzeProfitFlag',
          },
        ],
      },
      client: {
        path: '/client' | '/venue',
        icon: Icons.icon.client,
        mainText: '取引先管理',
        sub: [
          {
            path: '/client/list',
            text: '取引先一覧',
            flag: 'menuClientListFlag',
          },
          // { path: '/client/branch/list', text: '取引先支社一覧' },
          // { path: '/client/manager/list', text: '取引先担当者一覧' },
          {
            path: '/venue/list',
            text: '開催地一覧',
            flag: 'menuVenueListFlag',
          },
        ],
      },
      setting: {
        path: '/setting',
        icon: Icons.icon.setting,
        mainText: '表示制御設定',
        sub: [
          // { path: '/setting/lock', text: 'データロック設定' },
          {
            path: '/setting/schedule',
            text: '案件公開スケジュール',
            flag: 'menuSettingScheduleFlag',
          },
        ],
      },
      survey: {
        path: '/survey',
        icon: Icons.icon.survey,
        mainText: 'アンケート管理',
        sub: [
          {
            path: '/survey/list',
            text: 'アンケート一覧',
            flag: '',
          },
        ],
      },
      master: {
        path: '/master',
        icon: Icons.icon.master,
        mainText: '各種マスタ',
        sub: [
          {
            path: '/master/user/list',
            text: '社員一覧',
            flag: 'menuUserListFlag',
          },
          {
            path: '/master/partner/list',
            text: '協力会社一覧',
            flag: 'menuPartnerListFlag',
          },
          {
            path: '/master/corporate/list',
            text: '法人一覧',
            flag: 'menuCorporateListFlag',
          },
          {
            path: '/master/pay-term/list',
            text: '支払条件一覧',
            flag: 'menuPayTermListFlag',
          },
          {
            path: '/master/naming/list',
            text: '名称一覧',
            flag: 'menuNamingListFlag',
          },
          {
            path: '/master/income-tax/list',
            text: '所得税一覧',
            flag: 'menuIncomeTaxListFlag',
          },
        ],
      },
    },
    staff: {
      dashboard: {
        path: '/',
        icon: Icons.icon.dashboard,
        mainText: 'ホーム',
      },
      myPage: {
        path: '/my-page/edit',
        icon: Icons.icon.myPage,
        mainText: 'マイページ',
      },
      staffPlan: {
        path: '/staff-plan/register',
        icon: Icons.icon.plan,
        mainText: 'シフト予定登録',
      },
      attend: {
        path: '/attend',
        icon: Icons.icon.calendarWh,
        mainText: '勤怠確認',
      },
      staffPay: {
        path: '/staff-pay/detail',
        icon: Icons.icon.yen,
        mainText: '給与一覧',
      },
      searchJob: {
        path: '/recruit-matter/list',
        icon: Icons.icon.searchWh,
        mainText: 'お仕事を探す',
      },
      changePass: {
        path: '',
        icon: '',
        mainText: 'パスワード変更',
      },
      logout: {
        path: '',
        icon: '',
        mainText: 'ログアウト',
      },
    },
  },
  codes: {
    userStatus: [
      { value: 99, label: 'すべて' },
      { value: 1, label: '有効' },
      { value: 0, label: '無効' },
      { value: 2, label: '削除済' },
    ],
    clientStatus: [
      { value: 99, label: 'すべて' },
      { value: 1, label: '表示' },
      { value: 0, label: '非表示' },
      { value: 2, label: '削除済' },
    ],
    staffStatus: [
      { value: 99, label: 'すべて' },
      { value: 1, label: '有効' },
      { value: 0, label: '無効' },
      { value: -1, label: '削除済' },
    ],
    authority: [
      { value: 0, label: 'システム管理者' },
      { value: 1, label: '法人管理者' },
      { value: 2, label: '社員' },
    ],
    gender: [
      { value: 1, label: '男性' },
      { value: 2, label: '女性' },
      { value: 0, label: '未設定' },
    ],
    genderWithoutEmpty: [
      { value: 1, label: '男性' },
      { value: 2, label: '女性' },
    ],
    searchGender: [
      { value: 99, label: '全て' },
      { value: 1, label: '男性' },
      { value: 2, label: '女性' },
    ],
    inflowRoute: [
      {
        label: 'DM',
        value: 0,
      },
      {
        label: '通りがかり',
        value: 1,
      },
      {
        label: 'SNS',
        value: 2,
      },
      {
        label: '普段から利用している',
        value: 3,
      },
    ],
    reportInfo: [
      {
        label: '案件名',
        item: 'matterName',
      },
      {
        label: '実施概要',
        item: 'eventAbout',
        inputType: 'textBox',
      },
      {
        label: '実施目的',
        item: 'eventPurpose',
      },
      {
        label: '会場',
        item: 'venue',
      },

      {
        label: '入場条件',
        item: 'admissionTerm',
      },
      {
        label: '事前準備',
        item: 'standby',
      },
      {
        label: '主導者',
        item: 'president',
      },
      {
        label: '運営統括',
        item: 'managerLeader',
      },
      {
        label: '会場運営',
        item: 'manager',
      },
      {
        label: 'スタッフ人数',
        item: 'staffNumbers',
        inputType: 'select',
        unit: '人',
        placeholder: '0',
        initialValue: [...Array(1001).keys()],
        selectBoxOptions: [...Array(1001).keys()],
      },
      {
        label: 'スタッフ服装',
        item: 'staffWear',
      },
    ],

    continueFlag: [
      {
        label: '初回',
        value: 0,
      },
      {
        label: '2回以上',
        value: 1,
      },
    ],
    guestVoiceInflowRouteGroup: [
      {
        label: 'DM',
        inputName: 'guestVoice.dmRate',
        item: 'dmRate',
      },
      {
        label: '通りがかり',
        inputName: 'guestVoice.passingRate',
        item: 'passingRate',
      },
      {
        label: 'SNS',
        inputName: 'guestVoice.snsRate',
        item: 'snsRate',
      },
      {
        label: '普段から利用している',
        inputName: 'guestVoice.dailyUseRate',
        item: 'dailyUseRate',
      },
      {
        label: 'その他',
        inputName: 'guestVoice.otherRate',
        item: 'otherRate',
      },
    ],
    guestVoiceContinueRouteGroup: [
      {
        label: '初回',
        inputName: 'guestVoice.firstRate',
        item: 'firstRate',
      },
      {
        label: '2回以上',
        inputName: 'guestVoice.twiceRate',
        item: 'twiceRate',
      },
    ],
    guestGroup: [
      {
        label: '単身',
        inputName: 'guestAttribute.singleRate',
        item: 'singleRate',
      },
      {
        label: '複数',
        inputName: 'guestAttribute.multipleRate',
        item: 'multipleRate',
      },
      {
        label: '子連れ',
        inputName: 'guestAttribute.withChildrenRate',
        item: 'withChildrenRate',
      },
    ],
    ageGroup: [
      {
        label: '10代未満',
        inputName: 'guestAttribute.underTeensRate',
        item: 'underTeensRate',
      },
      {
        label: '40代',
        inputName: 'guestAttribute.fortiesRate',
        item: 'fortiesRate',
      },
      {
        label: '10代',
        inputName: 'guestAttribute.teensRate',
        item: 'teensRate',
      },
      {
        label: '50代',
        inputName: 'guestAttribute.fiftiesRate',
        item: 'fiftiesRate',
      },
      {
        label: '20代',
        inputName: 'guestAttribute.twentiesRate',
        item: 'twentiesRate',
      },
      {
        label: '60代',
        inputName: 'guestAttribute.sixtiesRate',
        item: 'sixtiesRate',
      },
      {
        label: '30代',
        inputName: 'guestAttribute.thirtiesRate',
        item: 'thirtiesRate',
      },
      {
        label: '70代以上',
        inputName: 'guestAttribute.overSeventiesRate',
        item: 'overSeventiesRate',
      },
    ],
    position: [
      {
        label: 'ディレクター',
        value: 2,
      },
      {
        label: 'アシスタントディレクター',
        value: 1,
      },
      {
        label: 'スタッフ',
        value: 0,
      },
    ],
    shiftPosition: {
      0: 'ディレクター',
      1: 'アシスタントディレクター',
      2: 'スタッフ(男性)',
      3: 'スタッフ(女性)',
      4: 'スタッフ(性別不問)',
    },
    shiftPositionComp: {
      0: 'D',
      1: 'AD',
      2: 'S男',
      3: 'S女',
      4: 'S',
    },
    staffShiftPosition: {
      0: 'ディレクター',
      1: 'アシスタントディレクター',
      2: 'スタッフ',
      3: 'スタッフ',
      4: 'スタッフ',
    },
    accountPosition: {
      0: {
        0: 'ディレクター',
        1: 'アシスタントディレクター',
        2: 'スタッフ',
        3: 'スタッフ',
        4: 'スタッフ',
      },
      1: {
        0: '社員',
        1: '社員',
        2: '社員',
        3: '社員',
        4: '社員',
      },
      2: {
        0: '協力会社',
        1: '協力会社',
        2: '協力会社',
        3: '協力会社',
        4: '協力会社',
      },
    },
    assignAccountType: [
      {
        label: 'スタッフ',
        value: 0,
      },
      {
        label: '社員',
        value: 1,
      },
      {
        label: '協力会社',
        value: 2,
      },
    ],
    employmentType: {
      0: '業務委託',
      1: 'パート・アルバイト',
    },
    payMethod: {
      0: '銀行振込',
      1: '手渡し',
    },
    assignFlag: [
      {
        label: '出勤可能',
        value: 1,
      },
      {
        label: '出勤不可',
        value: 0,
      },
    ],
    staffEvaluation: [
      {
        label: 'S',
        value: 0,
      },
      {
        label: 'A',
        value: 1,
      },
      {
        label: 'B',
        value: 2,
      },
      {
        label: 'C',
        value: 3,
      },
      {
        label: 'D',
        value: 4,
      },
    ],
    satisfactionLevel: [
      {
        label: 'ぜひまた参加したい',
        value: 5,
      },
      {
        label: 'また参加したい',
        value: 4,
      },
      {
        label: 'どちらとも思わない',
        value: 3,
      },
      {
        label: 'できれば参加したくない',
        value: 2,
      },
      {
        label: '参加したくない',
        value: 1,
      },
    ],
    sort: [
      {
        label: '同一カテゴリ経験回数',
        value: 'experiences_number DESC',
      },
      {
        label: '評価が高い順',
        value: 'staff_evaluation ASC',
      },
      {
        label: 'スタッフ希望度',
        value: 'desire DESC',
      },
    ],
    recruitMatterSort: [
      {
        label: '実施日が近い順',
        value: 'matter_date ASC',
      },
      {
        label: '実施日が遠い順',
        value: 'matter_date DESC',
      },
      {
        label: '新着順',
        value: 'open_date DESC',
      },
      {
        label: '募集終了日が近い順',
        value: 'close_date ASC',
      },
    ],
    advanceCategory: [
      {
        label: '交通費',
        value: 0,
      },
      {
        label: 'バス',
        value: 1,
      },
      {
        label: 'タクシー',
        value: 2,
      },
      {
        label: '通信費',
        value: 3,
      },
      {
        label: '宅配便',
        value: 4,
      },
      {
        label: 'その他',
        value: 9,
      },
    ],
    assignAccountFlag: [
      {
        label: 'スタッフ',
        value: 0,
      },
      {
        label: '社員',
        value: 1,
      },
      {
        label: '協力会社',
        value: 2,
      },
    ],
    excessFlag: [
      {
        label: '未超過',
        value: 0,
      },
      {
        label: '超過',
        value: 1,
      },
    ],
    reportType: [
      {
        label: '週確認　',
        fieldName: 'staffWeekDatetime',
        method: 'staffWeekMethodFlag',
      },
      {
        label: '前日報告',
        fieldName: 'staffPreviousDayDatetime',
        method: 'staffPreviousDayMethodFlag',
      },
      {
        label: '起床報告',
        fieldName: 'staffGettingUpDatetime',
        method: 'staffGettingUpMethodFlag',
      },
      {
        label: '出発報告',
        fieldName: 'staffDepartureDatetime',
        method: 'staffDepartureMethodFlag',
      },
      {
        label: '到着報告',
        fieldName: 'staffArriveDatetime',
        method: 'staffArriveMethodFlag',
      },
      {
        label: '終業報告',
        fieldName: 'staffEndDatetime',
        method: 'staffEndMethodFlag',
      },
    ],
    placeLocation: [
      {
        label: '屋上',
        value: 0,
      },
      {
        label: '入口すぐ',
        value: 1,
      },
      {
        label: '入口奥',
        value: 2,
      },
      {
        label: '店頭',
        value: 3,
      },
      {
        label: 'イベントスペース',
        value: 4,
      },
      {
        label: 'その他',
        value: 99,
      },
    ],
    height: [...Array(151)].map((_, index) => {
      return index + 100;
    }),
    wearSize: [
      { value: 0, label: 'XS' },
      { value: 1, label: 'S' },
      { value: 2, label: 'M' },
      { value: 3, label: 'L' },
      { value: 4, label: 'XL' },
      { value: 5, label: '2L' },
      { value: 6, label: '3L' },
    ],
    quoteItem: [
      {
        label: '通常',
        key: '1',
        className: 'insert_table--pull_down--sub_menu',
      },
      {
        label: '見出し行',
        key: '2',
        className: 'insert_table--pull_down--sub_menu',
      },
      {
        label: '小計行',
        key: '3',
        className: 'insert_table--pull_down--sub_menu',
      },
    ],
    orderStatus: [
      { label: '見積中', value: 0 },
      { label: '受注確定', value: 1 },
      { label: '失注', value: 2 },
    ],
    requestStatus: [
      { label: '未請求', value: 0 },
      { label: '請求済', value: 1 },
    ],
    weekDay: ['日', '月', '火', '水', '木', '金', '土'],
    selTaxRate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    monthOption: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    yearOption: [...Array(21)].map((_, i) => {
      return parseInt(moment().format('YYYY')) - 10 + i;
    }),
    //
    quoteBody: [
      {
        rowIndex: 1,
        rowType: 1,
        detail: '',
        quantity: null,
        unit: null,
        amount: null,
        salesTaxId: null,
        totalAmount: 0,
        salesTaxAmount: 0,
        index: 0,
      },
    ],
    quoteBodyTotal: [
      {
        rowIndex: 8,
        rowType: 1,
        detail: '',
        quantity: null,
        unit: null,
        amount: null,
        salesTaxId: '',
        salesTaxAmount: null,
        totalAmount: '',
        index: 'subtotal',
      },
      {
        rowIndex: 9,
        rowType: 1,
        detail: '',
        quantity: null,
        unit: null,
        amount: null,
        salesTaxId: '',
        salesTaxAmount: null,
        totalAmount: '',
        index: 'salesTax',
      },
      {
        rowIndex: 10,
        rowType: 1,
        detail: '',
        quantity: null,
        unit: null,
        amount: null,
        salesTaxId: '',
        salesTaxAmount: null,
        totalAmount: '',
        index: 'total',
      },
    ],
    pref_code: {
      1: '北海道',
      2: '青森県',
      3: '岩手県',
      4: '宮城県',
      5: '秋田県',
      6: '山形県',
      7: '福島県',
      8: '茨城県',
      9: '栃木県',
      10: '群馬県',
      11: '埼玉県',
      12: '千葉県',
      13: '東京都',
      14: '神奈川県',
      15: '新潟県',
      16: '富山県',
      17: '石川県',
      18: '福井県',
      19: '山梨県',
      20: '長野県',
      21: '岐阜県',
      22: '静岡県',
      23: '愛知県',
      24: '三重県',
      25: '滋賀県',
      26: '京都府',
      27: '大阪府',
      28: '兵庫県',
      29: '奈良県',
      30: '和歌山県',
      31: '鳥取県',
      32: '島根県',
      33: '岡山県',
      34: '広島県',
      35: '山口県',
      36: '徳島県',
      37: '香川県',
      38: '愛媛県',
      39: '高知県',
      40: '福岡県',
      41: '佐賀県',
      42: '長崎県',
      43: '熊本県',
      44: '大分県',
      45: '宮崎県',
      46: '鹿児島県',
      47: '沖縄県',
    },
  },
  buttonStyles: {
    submit: {
      width: '208px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    back: {
      width: '208px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    csv: {
      width: '148px',
      height: '42px',
      border: '1px solid #2B3245',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
      color: '#2B3245',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
      boxSizing: 'content-box',
    },
    plus: {
      width: '42px',
      height: '42px',
      border: '1px solid #2B3245',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
      color: '#2B3245',
      margin: '0 0',
      userSelect: 'none',
    },
    search: {
      width: '300px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0',
      userSelect: 'none',
    },
    denialShort: {
      width: '160px',
      height: '44px',
      border: '1px solid #D80245',
      borderRadius: '6px',
      backgroundColor: '#D80245',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    denialShortSp: {
      width: '238px',
      height: '42px',
      border: '1px solid #D80245',
      borderRadius: '6px',
      backgroundColor: '#D80245',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
      boxSizing: 'content-box',
    },
    denialLong: {
      width: '238px',
      height: '42px',
      border: '1px solid #D80245',
      borderRadius: '6px',
      backgroundColor: '#D80245',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    stampBack: {
      width: '238px',
      height: '42px',
      border: '1px solid #E9F2FC',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
      boxSizing: 'content-box',
    },
    stampBackSp: {
      width: '100%',
      minWidth: '132.5px',
      maxWidth: '160px',
      height: '38px',
      border: '1px solid #E9F2FC',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '12px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
      boxSizing: 'content-box',
    },
    stampApproval: {
      width: '238px',
      height: '42px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
      boxSizing: 'content-box',
    },
    stampApprovalSp: {
      width: '100%',
      minWidth: '132.5px',
      maxWidth: '160px',
      height: '38px',
      border: '1px solid #0056D3',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '12px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
      boxSizing: 'content-box',
    },
    allBank: {
      width: '178px',
      height: '42px',
      border: '1px solid #2B3245',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
      color: '#2B3245',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
      boxSizing: 'content-box',
    },
    recruitMatterSubmit: {
      width: '208px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#0056D3',
      color: '#FFFFFF',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    staffPayDetailButton: {
      width: '208px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#EAEFF6',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    reportInputButton: {
      width: '170px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#ffffff',
      color: '#0056d3',
      border: '1px solid #0056d3',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    exportPdf: {
      width: '180px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#ffffff',
      color: '#2B3245',
      border: '1px solid #2B3245',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    invoiceListButton: {
      width: '140px',
      height: '36px',
      borderRadius: '6px',
      backgroundColor: '#ffffff',
      color: '#2B3245',
      border: '1px solid #2B3245',
      fontSize: '14px',
      margin: '0 0',
      padding: '0 0',
      userSelect: 'none',
    },
    previewBack: {
      width: '300px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#E9F2FC',
      color: '#486083',
      fontSize: '14px',
      margin: '0 0 24px',
      padding: '0 0',
      userSelect: 'none',
      boxSizing: 'border-box',
    },
    mailButton: {
      width: '240px',
      height: '44px',
      borderRadius: '6px',
      backgroundColor: '#ffffff',
      color: '#2B3245',
      border: '1px solid #2B3245',
      fontSize: '14px',
      padding: '0 24px',
      userSelect: 'none',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 24px',
    },
  },
  csvExport: {
    type: {
      staffList: [
        {
          screenType: '4',
          url: apiUrl.staff.downloadCsv,
          fileName: 'staff',
          labels: [
            //スタッフ情報1行目
            {
              label: '基本情報',
              columns: ['basic_info'],
            },
            {
              label: '身長・体重など',
              columns: ['physical_info'],
            },
            {
              label: '給与支払情報',
              columns: ['pay_info'],
            },
            //スタッフ情報2行目
            {
              label: '交通情報',
              columns: ['transportation_info'],
            },
            {
              label: '学歴・資格など',
              columns: ['education_info'],
            },
            {
              label: '属性情報',
              columns: ['attribute_info'],
            },
          ],
        },
      ],
      matterList: [
        {
          screenType: '9',
          category: '案件情報',
          url: apiUrl.matter.downloadCsv,
          fileName: 'matter',
          labels: [
            //案件情報1行目
            {
              label: '案件No',
              columns: ['matter_number'],
            },
            {
              label: '案件名',
              columns: ['matter_name'],
            },
            {
              label: '新規登録者名',
              columns: ['created_user'],
            },
            //案件情報2行目
            {
              label: '見積書No',
              columns: ['quote_number'],
            },
            {
              label: '社内案件担当者ID',
              columns: ['matter_manager_id'],
            },
            {
              label: '社員最終更新日時',
              columns: ['updated_date'],
            },
            //案件情報3行目
            {
              label: '取引先ID',
              columns: ['client_id'],
            },
            {
              label: '社内案件担当者名',
              columns: ['matter_manager'],
            },
            {
              label: '社員最終更新者名',
              columns: ['updated_user'],
            },
            //案件情報4行目
            {
              label: '取引先名',
              columns: ['client_name'],
            },
            {
              label: '社内営業担当者ID',
              columns: ['sales_manager_id'],
            },
            {
              label: 'スタッフ最終更新日時',
              columns: ['updated_staff_date'],
            },
            //案件情報5行目
            {
              label: '開催地ID',
              columns: ['venue_id'],
            },
            {
              label: '社内営業担当者名',
              columns: ['sales_manager'],
            },
            {
              label: 'スタッフ最終更新者名',
              columns: ['updated_staff'],
            },
            //案件情報6行目
            {
              label: '開催地名',
              columns: ['venue_name'],
            },
            {
              label: '新規登録日時',
              columns: ['created_date'],
            },
            {
              placeholder: true,
            },
          ],
        },
        {
          category: '案件見積費用設定',
          labels: [
            //案件見積費用設定1行目
            {
              label: '見積金額',
              columns: ['quote_amount'],
            },
            {
              label: '案件カテゴリ',
              columns: ['matter_category'],
            },
            {
              label: '業界中分類',
              columns: ['matter_industry_secondary'],
            },
            //案件見積費用設定2行目
            {
              label: '見積残金額',
              columns: ['quote_balance'],
            },
            {
              label: '案件カテゴリ詳細',
              columns: ['matter_category_other'],
              newLine: true,
            },
            //案件見積費用設定3行目
            {
              label: '案件見積金額',
              columns: ['matter_quote_amount'],
            },
            {
              label: '業界大分類',
              columns: ['matter_industry_primary'],
            },
          ],
        },
        {
          category: '備考情報',
          labels: [
            //備考情報1行目
            {
              label: '予備項目1',
              columns: ['preliminary_1'],
            },
            {
              label: '予備項目3',
              columns: ['preliminary_3'],
            },
            {
              label: '予備項目5',
              columns: ['preliminary_5'],
            },
            //備考情報2行目
            {
              label: '予備項目2',
              columns: ['preliminary_2'],
            },
            {
              label: '予備項目4',
              columns: ['preliminary_4'],
            },
            {
              label: '備考',
              columns: ['note'],
            },
          ],
        },
        {
          category: '実情情報',
          disabled: true,
          labels: [
            //実情情報1行目
            {
              label: '案件名称',
              columns: ['report_matter_name'],
            },
            {
              label: '会場',
              columns: ['venue'],
            },
            {
              label: '運営統括',
              columns: ['manager_leader'],
            },
            //実情情報2行目
            {
              label: '案件概要',
              columns: ['event_about'],
            },
            {
              label: '入場条件',
              columns: ['admission_term'],
            },
            {
              label: '会場運営',
              columns: ['manager'],
            },
            //実情情報3行目
            {
              label: '実施目的',
              columns: ['event_purpose'],
            },
            {
              label: '事前準備',
              columns: ['standby'],
            },
            {
              label: 'スタッフ人数',
              columns: ['staff_numbers'],
            },
            //実情情報4行目
            {
              label: '実施日別天気・気温',
              columns: ['event_weather'],
            },
            {
              label: '主導者',
              columns: ['president'],
            },
            {
              label: 'スタッフ服装',
              columns: ['staff_wear'],
            },
          ],
        },
        {
          category: 'お客様の声',
          disabled: true,
          labels: [
            //お客様の声1行目
            {
              label: 'お客様の声総人数',
              columns: ['voice_total_number'],
            },
            {
              label: '流入経路',
              columns: ['inflow_route'],
            },
            {
              label: '購入動機',
              columns: ['motive'],
            },
            //お客様の声2行目
            {
              label: '継続購入可否',
              columns: ['repeating'],
            },
          ],
        },
        {
          category: 'お客様の属性',
          disabled: true,
          labels: [
            //お客様の属性1行目
            {
              label: 'お客様の属性総人数',
              columns: ['attribute_total_number'],
            },
            {
              label: '男女比',
              columns: ['gender_ratio'],
            },
            {
              label: '客層比',
              columns: ['guest'],
            },
            //お客様の属性2行目
            {
              label: '年齢別客層比',
              columns: ['age_group'],
            },
          ],
        },
      ],
      matterDateList: [
        {
          screenType: '10',
          category: '実施日別案件',
          url: apiUrl.matterDate.downloadCsv,
          fileName: 'matterDate',
          labels: [
            {
              label: '実施日',
              columns: ['matter_date'],
            },
            {
              label: '案件No',
              columns: ['matter_number'],
            },
            {
              label: '案件名',
              columns: ['matter_name'],
            },
            {
              label: '取引先',
              columns: ['client_name'],
            },
            {
              label: '開催地',
              columns: ['venue_name'],
            },
            {
              label: '社内案件担当者',
              columns: ['matter_manager_name'],
            },
            {
              label: '社内営業担当者',
              columns: ['sales_manager_name'],
            },
            {
              label: 'ステータス',
              columns: ['status'],
            },
          ],
        },
      ],
      quoteList: [
        {
          screenType: '8',
          category: '見積情報',
          url: apiUrl.quote.downloadCsv,
          fileName: 'quote',
          labels: [
            //見積情報1行目
            {
              label: '見積書No',
              columns: ['quote_number'],
            },
            {
              label: '支払条件',
              columns: ['pay_terms'],
            },
            {
              label: '備考',
              columns: ['note'],
            },
            //見積情報2行目
            {
              label: '取引先ID',
              columns: ['client_id'],
            },
            {
              label: '受注ステータス',
              columns: ['order_status'],
            },
            {
              label: '承認ステータス',
              columns: ['approval_status'],
            },
            //見積情報3行目
            {
              label: '取引先名',
              columns: ['client_name'],
            },
            {
              label: '請求タイミング',
              columns: ['request_timing'],
            },
            {
              label: '見積有効期限',
              columns: ['quote_document_limit_text'],
            },
            //見積情報4行目
            {
              label: '取引先名(カナ)',
              columns: ['client_name_kana'],
            },
            {
              label: '請求予定日',
              columns: ['request_date'],
            },
            {
              label: '合計金額',
              columns: ['quote_amount'],
            },
            //見積情報5行目
            {
              label: '郵便番号',
              columns: ['post_code'],
            },
            {
              label: '案件最終実施日',
              columns: ['matter_last_date'],
            },
            {
              label: '小計',
              columns: ['quote_subtotal_amount'],
            },
            //見積情報6行目
            {
              label: '都道府県',
              columns: ['pref_code'],
            },
            {
              label: '支払方法',
              columns: ['pay_method'],
            },
            {
              label: '消費税',
              columns: ['sales_tax'],
            },
            //見積情報7行目
            {
              label: '市区町村',
              columns: ['city_name'],
            },
            {
              label: '案件カテゴリ',
              columns: ['matter_category'],
            },
            {
              label: '合計金額(明細欄)',
              columns: ['quote_amount_detail'],
            },
            //見積情報8行目
            {
              label: '丁目番地',
              columns: ['street_name'],
            },
            {
              label: '案件カテゴリ詳細',
              columns: ['matter_category_other'],
            },
            {
              label: '見積書備考',
              columns: ['quote_document_note'],
            },
            //見積情報9行目
            {
              label: 'TEL',
              columns: ['tel_number'],
            },
            {
              label: '業界大分類',
              columns: ['matter_industry_primary'],
            },
            {
              label: '見積行明細',
              columns: ['quote_detail'],
            },
            //見積情報10行目
            {
              label: 'FAX',
              columns: ['fax_number'],
            },
            {
              label: '業界中分類',
              columns: ['matter_industry_secondary'],
            },
            {
              label: '新規登録日時',
              columns: ['created_date'],
            },
            //見積情報11行目
            {
              label: '取引先担当者',
              columns: ['client_manager_name'],
            },
            {
              label: '予備項目１',
              columns: ['preliminary_1'],
            },
            {
              label: '新規登録者名',
              columns: ['created_user'],
            },
            //見積情報12行目
            {
              label: '件名',
              columns: ['matter_name'],
            },
            {
              label: '予備項目２',
              columns: ['preliminary_2'],
            },
            {
              label: '最終更新日時',
              columns: ['updated_date'],
            },
            //見積情報13行目
            {
              label: '社内担当者ID',
              columns: ['matter_manager_user_id'],
            },
            {
              label: '予備項目３',
              columns: ['preliminary_3'],
            },
            {
              label: '最終更新者名',
              columns: ['updated_user'],
            },
            //見積情報14行目
            {
              label: '社内担当者名',
              columns: ['matter_manager_user'],
            },
            {
              label: '予備項目４',
              columns: ['preliminary_4'],
              newLine: true,
            },
            //見積情報15行目
            {
              label: '承認フロー',
              columns: ['approval_flow'],
            },
            {
              label: '予備項目５',
              columns: ['preliminary_5'],
            },
          ],
        },
      ],
      invoiceList: [
        {
          category: '案件情報',
          labels: [
            {
              label: '取引先ID',
              columns: ['client_id'],
            },
            {
              label: '取引終了日',
              columns: ['end_date'],
            },
            {
              label: 'TEL(担当者直通)',
              columns: ['client_manager_tel'],
            },
            {
              label: '取引先名',
              columns: ['client_name'],
            },
            {
              label: 'ステータス',
              columns: ['approval_status'],
            },
            {
              label: '社内担当社員ID',
              columns: ['house_manager_id'],
            },
            {
              label: '取引先名(カナ)',
              columns: ['client_name_kana'],
            },
            {
              label: '請求先ID',
              columns: ['未定'],
            },
            {
              label: '社内担当社員名',
              columns: ['house_manager_name'],
            },
            {
              label: '郵便番号',
              columns: ['zip_code'],
            },
            {
              label: '請求先名',
              columns: ['未定'],
            },
            {
              label: '予備項目1',
              columns: ['pre_column_1'],
            },
            {
              label: '都道府県',
              columns: ['pref_name'],
            },
            {
              label: '請求先名(カナ)',
              columns: ['未定'],
            },
            {
              label: '予備項目2',
              columns: ['pre_column_2'],
            },
            {
              label: '市区町村',
              columns: ['city_name'],
            },
            {
              label: '支払条件',
              columns: ['pay_term'],
            },
            {
              label: '予備項目3',
              columns: ['pre_column_3'],
            },
            {
              label: '丁目番地',
              columns: ['street_name'],
            },
            {
              label: '担当者名',
              columns: ['client_manager_name'],
            },
            {
              label: '予備項目4',
              columns: ['pre_column_4'],
            },
            {
              label: 'TEL',
              columns: ['tel_number'],
            },
            {
              label: '担当者名(カナ)',
              columns: ['client_manager_name_kana'],
            },
            {
              label: '予備項目5',
              columns: ['pre_column_5'],
            },
            {
              label: 'FAX',
              columns: ['fax_number'],
            },
            {
              label: '担当者属部署',
              columns: ['client_manger_department'],
            },
            {
              label: '備考',
              columns: ['note'],
            },
            {
              label: '取引開始日',
              columns: ['start_date'],
            },
            {
              label: '担当者メールアドレス',
              columns: ['client_manager_mail_address'],
            },
          ],
        },
      ],
      namingList: [
        {
          screenType: '3',
          category: '名称',
          url: apiUrl.naming.downloadCsv,
          fileName: 'naming',
          labels: [
            //名称1行目
            {
              label: '名称区分ID',
              columns: ['naming_classification_id'],
            },
            {
              label: '名称(カナ)',
              columns: ['naming_kana'],
            },
            {
              label: '新規登録日時',
              columns: ['created_date'],
            },
            //名称2行目
            {
              label: '名称区分名',
              columns: ['naming_classification'],
            },
            {
              label: '表示優先順',
              columns: ['priority'],
            },
            {
              label: '新規登録者名',
              columns: ['created_user'],
            },
            //名称3行目
            {
              label: '名称ID',
              columns: ['naming_id'],
            },
            {
              label: 'ステータス',
              columns: ['status'],
            },
            {
              label: '最終更新日時',
              columns: ['updated_date'],
            },
            //名称4行目
            {
              label: '名称',
              columns: ['naming'],
            },
            {
              label: '備考',
              columns: ['note'],
            },
            {
              label: '最終更新者',
              columns: ['updated_user'],
            },
          ],
        },
      ],
      clientList: [
        {
          screenType: '1',
          category: '基本情報',
          url: apiUrl.client.downloadCsv,
          fileName: 'client',
          labels: [
            //基本情報1行目
            {
              label: '取引先ID',
              columns: ['client_id'],
            },
            {
              label: '都道府県',
              columns: ['pref_code'],
            },
            {
              label: '取引先担当者',
              columns: ['client_manager_name'],
            },
            //基本情報2行目
            {
              label: '取引先名',
              columns: ['client_name'],
            },
            {
              label: '市区町村',
              columns: ['city_name'],
            },
            //基本情報3行目
            {
              label: '取引先名(カナ)',
              columns: ['client_name_kana'],
            },
            {
              label: '丁目番地',
              columns: ['street_name'],
            },
            {
              label: 'ステータス',
              columns: ['status'],
            },
            //基本情報4行目
            {
              label: '開始日',
              columns: ['start_date'],
            },
            {
              label: '建物名',
              columns: ['building_name'],
            },
            {
              label: '新規登録日時',
              columns: ['created_date'],
            },
            //基本情報5行目
            {
              label: '終了日',
              columns: ['end_date'],
            },
            {
              label: 'TEL',
              columns: ['tel_number'],
            },
            {
              label: '新規登録者',
              columns: ['created_user'],
            },
            //基本情報6行目
            {
              label: '国税庁法人番号',
              columns: ['nta_number'],
            },
            {
              label: 'FAX',
              columns: ['fax_number'],
            },
            {
              label: '最終更新日時',
              columns: ['updated_date'],
            },
            //基本情報7行目
            {
              label: '区分',
              columns: ['division'],
            },
            {
              label: 'URL',
              columns: ['url'],
            },
            {
              label: '最終更新者',
              columns: ['updated_user'],
            },
            //基本情報8行目
            {
              label: '郵便番号',
              columns: ['post_code'],
            },
            {
              label: '社内担当者',
              columns: ['manager_user'],
            },
          ],
        },
        {
          category: '請求情報',
          labels: [
            {
              label: 'デフォルト支払条件',
              columns: ['pay_term'],
            },
          ],
        },
        {
          category: '備考情報',
          labels: [
            //備考情報1行目
            {
              label: '予備項目1',
              columns: ['preliminary_1'],
            },
            {
              label: '予備項目3',
              columns: ['preliminary_3'],
            },
            {
              label: '予備項目5',
              columns: ['preliminary_5'],
            },
            //備考情報2行目
            {
              label: '予備項目2',
              columns: ['preliminary_2'],
            },
            {
              label: '予備項目4',
              columns: ['preliminary_4'],
            },
            {
              label: '備考',
              columns: ['note'],
            },
          ],
        },
      ],
      venueList: [
        {
          screenType: '2',
          category: '開催地情報',
          url: apiUrl.venue.downloadCsv,
          fileName: 'venue',
          labels: [
            //開催地情報1行目
            {
              label: '開催地ID',
              columns: ['venue_id'],
            },
            {
              label: '建物名',
              columns: ['building_name'],
            },
            {
              label: '最寄駅5',
              columns: ['nearest_station_5'],
            },
            //開催地情報2行目
            {
              label: '種別',
              columns: ['venue'],
            },
            {
              label: 'TEL',
              columns: ['tel_number'],
            },
            {
              label: 'ステータス',
              columns: ['status'],
            },
            //開催地情報3行目
            {
              label: '開催地名',
              columns: ['venue_name'],
            },
            {
              label: 'FAX',
              columns: ['fax_number'],
            },
            {
              label: '新規登録日時',
              columns: ['created_date'],
            },
            //開催地情報4行目
            {
              label: '開催地名(カナ)',
              columns: ['venue_name_kana'],
            },
            {
              label: 'URL',
              columns: ['url'],
            },
            {
              label: '新規登録者名',
              columns: ['created_user'],
            },
            //開催地情報5行目
            {
              label: '郵便番号',
              columns: ['post_code'],
            },
            {
              label: '最寄駅1',
              columns: ['nearest_station_1'],
            },
            {
              label: '最終更新日時',
              columns: ['updated_date'],
            },
            //開催地情報6行目
            {
              label: '都道府県',
              columns: ['pref_code'],
            },
            {
              label: '最寄駅2',
              columns: ['nearest_station_2'],
            },
            {
              label: '最終更新者',
              columns: ['updated_user'],
            },
            //開催地情報7行目
            {
              label: '市区町村',
              columns: ['city_name'],
            },
            {
              label: '最寄駅3',
              columns: ['nearest_station_3'],
              newLine: true,
            },
            //開催地情報8行目
            {
              label: '丁目番地',
              columns: ['street_name'],
            },
            {
              label: '最寄駅4',
              columns: ['nearest_station_4'],
              newLine: true,
            },
          ],
        },
        {
          category: '備考情報',
          labels: [
            //備考情報1行目
            {
              label: '予備項目1',
              columns: ['preliminary_1'],
            },
            {
              label: '予備項目3',
              columns: ['preliminary_3'],
            },
            {
              label: '予備項目5',
              columns: ['preliminary_5'],
            },
            //備考情報2行目
            {
              label: '予備項目2',
              columns: ['preliminary_2'],
            },
            {
              label: '予備項目4',
              columns: ['preliminary_4'],
            },
            {
              label: '備考',
              columns: ['note'],
            },
          ],
        },
      ],
      userList: [
        {
          screenType: '0',
          category: '基本情報',
          url: apiUrl.user.downloadCsv,
          fileName: 'user',
          labels: [
            //基本情報1行目
            {
              label: '社員ID',
              columns: ['user_id_flag'],
            },
            {
              label: 'メールアドレス',
              columns: ['mail_address'],
            },
            {
              label: '新規登録日時',
              columns: ['created_date'],
            },
            //基本情報2行目
            {
              label: '名前',
              columns: ['user_name'],
            },
            {
              label: '利用開始日',
              columns: ['start_date'],
            },
            {
              label: '新規登録者',
              columns: ['created_user'],
            },
            //基本情報3行目
            {
              label: '社員名(カナ)',
              columns: ['user_name_kana'],
            },
            {
              label: '利用終了日',
              columns: ['end_date'],
            },
            {
              label: '最終更新日時',
              columns: ['updated_date'],
            },
            //基本情報4行目
            {
              label: 'TEL',
              columns: ['tel'],
            },
            {
              label: 'ステータス',
              columns: ['status'],
            },
            {
              label: '最終更新者',
              columns: ['updated_user'],
            },
          ],
        },
        {
          category: '所属情報',
          labels: [
            {
              label: '入社日',
              columns: ['join_date'],
            },
            {
              label: '退職日',
              columns: ['retire_date'],
            },
            {
              label: '部署',
              columns: ['dept_flag'],
            },
            {
              label: '承認フロー',
              columns: ['approval_flow_id_list_flag'],
            },
          ],
        },
        {
          category: '権限設定',
          labels: [
            //権限設定1行目
            {
              label: '見積機能権限',
              columns: ['quote_auth'],
            },
            {
              label: '請求機能権限',
              columns: ['invoice_auth'],
            },
            {
              label: '権限',
              columns: ['auth'],
            },
            //権限設定2行目
            {
              label: '案件機能権限',
              columns: ['matter_auth'],
            },
            {
              label: '参照元社員',
              columns: ['reference_user'],
            },
            {
              label: '利用メニュー',
              columns: ['menu'],
            },
          ],
        },
        {
          category: '備考情報',
          labels: [
            //備考情報1行目
            {
              label: '備考予備項目１',
              columns: ['preliminary_1'],
            },
            {
              label: '備考予備項目3',
              columns: ['preliminary_3'],
            },
            {
              label: '備考予備項目5',
              columns: ['preliminary_5'],
            },
            //備考情報2行目
            {
              label: '備考予備項目2',
              columns: ['preliminary_2'],
            },
            {
              label: '備考予備項目4',
              columns: ['preliminary_4'],
            },
            {
              label: '備考',
              columns: ['note'],
            },
          ],
        },
      ],
      appliedList: [
        {
          screenType: '14',
          url: apiUrl.matterDate.appliedDownloadCsv,
          fileName: 'matterDateApplied',
          labels: [
            //案件実施日別応募者1行目
            {
              label: '案件No',
              columns: ['matter_number'],
            },
            {
              label: '業界大分類',
              columns: ['matter_industry_primary'],
            },
            {
              label: 'スタッフ評価',
              columns: ['staff_evaluation'],
            },
            //案件実施日別応募者2行目
            {
              label: '案件名',
              columns: ['matter_name'],
            },
            {
              label: '業界中分類',
              columns: ['matter_industry_secondary'],
            },
            {
              label: '応募日時',
              columns: ['applied_datetime'],
            },
            //案件実施日別応募者3行目
            {
              label: '案件実施日',
              columns: ['matter_date'],
            },
            {
              label: 'スタッフID',
              columns: ['staff_id'],
            },
            {
              label: 'メイン担当者',
              columns: ['manager_user'],
            },
            //案件実施日別応募者4行目
            {
              label: '募集期間',
              columns: ['recruit_date'],
            },
            {
              label: 'スタッフ名',
              columns: ['staff_name'],
            },
            {
              label: 'ステータス',
              columns: ['status'],
            },
            //案件実施日別応募者5行目
            {
              label: '案件カテゴリ',
              columns: ['matter_category'],
            },
            {
              label: 'スタッフ名(カナ)',
              columns: ['staff_name_kana'],
              newLine: true,
            },
            //案件実施日別応募者6行目
            {
              label: '案件カテゴリ詳細',
              columns: ['matter_category_other'],
            },
            {
              label: 'スタッフポジション',
              columns: ['staff_position'],
            },
          ],
        },
      ],
      partnerList: [
        {
          screenType: '5',
          category: '基本情報',
          url: apiUrl.partner.downloadCsv,
          fileName: 'partner',
          labels: [
            //基本情報1行目
            {
              label: '協力会社ID',
              columns: ['partner_company_id'],
            },
            {
              label: '市区町村',
              columns: ['city_name'],
            },
            {
              label: '社内担当者名',
              columns: ['manager_user'],
            },
            //基本情報2行目
            {
              label: '協力会社名',
              columns: ['partner_company_name'],
            },
            {
              label: '丁目番地',
              columns: ['street_name'],
            },
            {
              label: '備考',
              columns: ['note'],
            },
            //基本情報3行目
            {
              label: '協力会社名(カナ)',
              columns: ['partner_company_name_kana'],
            },
            {
              label: '建物名',
              columns: ['building_name'],
            },
            {
              label: 'ステータス',
              columns: ['status'],
            },
            //基本情報4行目
            {
              label: '開始日',
              columns: ['start_date'],
            },
            {
              label: 'TEL',
              columns: ['tel_number'],
            },
            {
              label: '新規登録日時',
              columns: ['created_date'],
            },
            //基本情報5行目
            {
              label: '終了日',
              columns: ['end_date'],
            },
            {
              label: 'FAX',
              columns: ['fax_number'],
            },
            {
              label: '新規登録者名',
              columns: ['created_user'],
            },
            //基本情報6行目
            {
              label: '郵便番号',
              columns: ['post_code'],
            },
            {
              label: '協力会社担当者',
              columns: ['partner_company_manager'],
            },
            {
              label: '最終更新日時',
              columns: ['updated_date'],
            },
            //基本情報7行目
            {
              label: '都道府県',
              columns: ['pref_code'],
            },
            {
              label: '社内担当者ID',
              columns: ['manager_user_id'],
            },
            {
              label: '最終更新者名',
              columns: ['updated_user'],
            },
          ],
        },
      ],
      staffPlanEdit: [
        {
          screenType: '7',
          category: 'スタッフシフト予定編集',
          url: apiUrl.staffPlan.detailDownloadCsv,
          fileName: 'staffPlanEdit',
          labels: [
            {
              label: '日付',
              columns: ['day'],
            },
            {
              label: '曜日',
              columns: ['day_of_week'],
            },
            {
              label: '条件',
              columns: ['conditions'],
            },
            {
              label: '時間',
              columns: ['time'],
            },
            {
              label: 'シフト可否',
              columns: ['shift'],
            },
            {
              label: 'スタッフ備考',
              columns: ['staff_note'],
            },
            {
              label: '担当社員備考',
              columns: ['manager_user_note'],
            },
          ],
        },
      ],
      staffPayList: [
        {
          screenType: '12',
          category: '支払明細',
          url: apiUrl.staffPay.downloadCsv,
          fileName: 'staffPay',
          labels: [
            //支払明細1行目
            {
              label: '支払年月',
              columns: ['closing_year_month'],
            },
            {
              label: '控除小計',
              columns: ['deduction_subtotal'],
            },
            {
              label: '新規登録日時',
              columns: ['created_date'],
            },
            //支払明細2行目
            {
              label: 'スタッフID',
              columns: ['staff_id'],
            },
            {
              label: '支払合計',
              columns: ['net_pay_amount'],
            },
            {
              label: '新規登録者',
              columns: ['created_user'],
            },
            //支払明細3行目
            {
              label: 'スタッフ名',
              columns: ['staff_name'],
            },
            {
              label: 'ステータス',
              columns: ['status'],
            },
            {
              label: '最終更新日時',
              columns: ['updated_date'],
            },
            //支払明細4行目
            {
              label: '給与手当',
              columns: ['pay_amount'],
            },
            {
              label: 'スタッフ担当社員ID',
              columns: ['manager_user_id'],
            },
            {
              label: '最終更新者',
              columns: ['updated_user'],
            },
            //支払明細5行目
            {
              label: '立替金',
              columns: ['advance_pay_amount'],
            },
            {
              label: 'スタッフ担当者名',
              columns: ['manager_user'],
              newLine: true,
            },
            //支払明細6行目
            {
              label: '支給小計',
              columns: ['pay_subtotal'],
            },
            {
              label: 'スタッフ担当社員承認日時',
              columns: ['manager_approval_datetime'],
              newLine: true,
            },
            //支払明細7行目
            {
              label: '所得税',
              columns: ['income_tax_amount'],
            },
            {
              label: 'スタッフ承認日時',
              columns: ['staff_approval_datetime'],
              newLine: true,
            },
            //支払明細8行目
            {
              label: '前払金',
              columns: ['before_pay_amount'],
            },
            {
              label: '経理最終承認日時',
              columns: ['accountant_approval_datetime'],
            },
          ],
        },
      ],
      staffPlanList: [
        {
          screenType: '6',
          category: 'スタッフシフト予定',
          url: apiUrl.staffPlan.downloadCsv,
          fileName: 'staffPlan',
          labels: [
            {
              label: '年月',
              columns: ['year_month_flag'],
            },
            {
              label: 'スタッフID',
              columns: ['staff_id'],
            },
            {
              label: 'スタッフ名',
              columns: ['staff_name'],
            },
            {
              label: '初回提出日時',
              columns: ['first_submit_datetime'],
            },
            {
              label: '最終提出日時',
              columns: ['last_submit_datetime'],
            },
            {
              label: 'メイン担当者',
              columns: ['main_manager_user'],
            },
            {
              label: 'サブ担当者',
              columns: ['sub_manager_user'],
            },
            {
              label: 'ステータス',
              columns: ['status'],
            },
          ],
        },
      ],
      staffYearList: [
        {
          screenType: '13',
          category: 'スタッフ年間給与情報',
          url: apiUrl.staffPay.yearDownloadCsv,
          fileName: 'staffPayYear',
          labels: [
            //スタッフ年間給与情報1行目
            {
              label: '年度',
              columns: ['year'],
            },
            {
              label: '2月支払金額',
              columns: ['pay_amount_2'],
            },
            {
              label: '8月支払金額',
              columns: ['pay_amount_8'],
            },
            //スタッフ年間給与情報2行目
            {
              label: 'スタッフID',
              columns: ['staff_id'],
            },
            {
              label: '3月支払金額',
              columns: ['pay_amount_3'],
            },
            {
              label: '9月支払金額',
              columns: ['pay_amount_9'],
            },
            //スタッフ年間給与情報3行目
            {
              label: 'スタッフ名',
              columns: ['staff_name'],
            },
            {
              label: '4月支払金額',
              columns: ['pay_amount_4'],
            },
            {
              label: '10月支払金額',
              columns: ['pay_amount_10'],
            },
            //スタッフ年間給与情報4行目
            {
              label: '年間給与',
              columns: ['yearly_pay_amount'],
            },
            {
              label: '5月支払金額',
              columns: ['pay_amount_5'],
            },
            {
              label: '11月支払金額',
              columns: ['pay_amount_11'],
            },
            //スタッフ年間給与情報5行目
            {
              label: '年間所得税',
              columns: ['yearly_income_tax_amount'],
            },
            {
              label: '6月支払金額',
              columns: ['pay_amount_6'],
            },
            {
              label: '12月支払金額',
              columns: ['pay_amount_12'],
            },
            //スタッフ年間給与情報6行目
            {
              label: '1月支払金額',
              columns: ['pay_amount_1'],
            },
            {
              label: '7月支払金額',
              columns: ['pay_amount_7'],
            },
          ],
        },
      ],
      analyzeProfitList: [
        {
          screenType: '11',
          url: apiUrl.analyse.downloadCsv,
          fileName: 'analyzeProfit',
          labels: [
            //売上分析情報1行目
            {
              label: '案件No',
              columns: ['matter_number'],
            },
            {
              label: '案件担当社員ID',
              columns: ['matter_manager_user_id'],
            },
            {
              label: '社員数',
              columns: ['user_count'],
            },
            //売上分析情報2行目
            {
              label: '案件名',
              columns: ['matter_name'],
            },
            {
              label: '案件担当社員名',
              columns: ['matter_manager_user'],
            },
            {
              label: '外注数',
              columns: ['partner_count'],
            },
            //売上分析情報3行目
            {
              label: '案件カテゴリ',
              columns: ['matter_category'],
            },
            {
              label: '予算金額',
              columns: ['quote_amount'],
            },
            {
              label: '自社手配率',
              columns: ['rate'],
            },
            //売上分析情報4行目
            {
              label: '営業担当社員ID',
              columns: ['sales_manager_user_id'],
            },
            {
              label: '実績金額',
              columns: ['actual_amount'],
              newLine: true,
            },
            //売上分析情報5行目
            {
              label: '営業担当社員名',
              columns: ['sales_manager_user'],
            },
            {
              label: '自社スタッフ数',
              columns: ['staff_count'],
            },
          ],
        },
      ],
      surveyList: [
        {
          screenType: '15',
          url: apiUrl.survey.downloadCsv,
          fileName: 'survey',
          labels: [
            {
              label: 'アンケートNo',
              columns: ['survey_id'],
            },
            {
              label: '回答者所属法人ID',
              columns: ['survey_answer_staff_corporate_id'],
            },
            {
              label: 'LINE ID',
              columns: ['survey_answer_staff_line_id'],
            },
            {
              label: 'タイトル',
              columns: ['survey_title'],
            },
            {
              label: '回答者所属法人名',
              columns: ['survey_answer_staff_corporate_name'],
            },
            {
              label: '回答内容',
              columns: ['survey_answer_detail'],
            },
            {
              label: '実施開始日',
              columns: ['start_date'],
            },
            {
              label: '回答者名',
              columns: ['survey_answer_staff_name'],
            },
            {
              label: '回答日時',
              columns: ['answer_date'],
            },
            {
              label: '実施終了日',
              columns: ['end_date'],
            },
            {
              label: '回答者名(カナ)',
              columns: ['survey_answer_staff_name_kana'],
            },
            {
              label: '回答歴',
              columns: ['answer_history'],
            },
            {
              label: 'アンケート種別',
              columns: ['survey_type'],
            },
            {
              label: 'メールアドレス',
              columns: ['survey_answer_staff_mail_address'],
            },
          ],
        },
      ],
    },
  },
  statusMap: {
    matterList: {
      0: {
        color: '#bcbcbc',
        label: '受注済み',
      },
      1: {
        color: '#f9be00',
        label: '実施中',
      },
      2: {
        color: '#d80245',
        label: '実施済',
      },
      3: {
        color: '#28d300',
        label: '報告書提出済',
      },
    },
    matterDate: {
      0: {
        color: '#bcbcbc',
        label: 'シフト未登録',
      },
      1: {
        color: '#d80245',
        label: 'アサイン未確定',
      },
      2: {
        color: '#383f52',
        label: 'アサイン確定済',
      },
      3: {
        color: '#28d300',
        label: '報告書提出済',
      },
    },
    staffRecruit: {
      0: {
        color: '#f9be00',
        label: '募集開始前',
      },
      1: {
        color: '#28d300',
        label: '募集中',
      },
      2: {
        color: '#383f52',
        label: '募集終了',
      },
    },
    staffAssignStatus: [
      {
        label: 'アサイン確定',
        value: 1,
      },
      {
        label: 'アサイン通知済',
        value: 2,
      },
      {
        label: '週確認',
        value: 3,
        format: 'YYYY/M/D HH:mm',
        nextPlanDatetime: 'staffWeekPlanDatetime',
      },
      {
        label: '前日確認',
        value: 4,
        format: 'YYYY/M/D HH:mm',
        nextPlanDatetime: 'staffPreviousDayPlanDatetime',
      },
      {
        label: '起床報告',
        value: 5,
        format: 'HH:mm',
        nextPlanDatetime: 'staffGettingUpPlanDatetime',
      },
      {
        label: '当日出発',
        value: 6,
        format: 'HH:mm',
        nextPlanDatetime: 'staffDeparturePlanDatetime',
      },
      {
        label: '当日到着',
        value: 7,
        format: 'HH:mm',
        nextPlanDatetime: 'staffArrivePlanDatetime',
      },
      {
        label: '当日終業',
        value: 8,
        format: 'HH:mm',
        nextPlanDatetime: 'staffEndPlanDatetime',
      },
    ],
    matterApplied: [
      {
        label: '選考中',
        value: 0,
      },
      {
        label: '不採用',
        value: 1,
      },
      {
        label: '採用',
        value: 2,
      },
    ],
    staff_recruitSort: [
      {
        label: '新着順',
        value: 'openDateDesc',
      },
      {
        label: '募集終了日が近い順',
        value: 'closeDateDesc',
      },
      {
        label: '実施日が近い順',
        value: 'openDateAsc',
      },
      {
        label: '実施日が遠い順',
        value: 'openDateDesc',
      },
    ],
    staffPayListApprovalStatus: {
      1: {
        label: '未承認',
        color: '#ffffff',
        background: '#d80245',
      },
      2: {
        label: '否認',
        color: '#ffffff',
        background: '#383f52',
      },
      3: {
        label: '承認済',
        color: '#ffffff',
        background: '#28d300',
      },
    },
    staffPayListPaymentStatus: {
      0: {
        label: '給与確定',
        color: '#ffffff',
        background: '#d80245',
      },
      1: {
        label: '支払済み',
        color: '#ffffff',
        background: '#28d300',
      },
    },
  },
  errorMenu: [
    {
      name: 'staffId',
      code: ['E30000'],
    },
    {
      name: 'mailAddress',
      code: ['E30002'],
    },
  ],
};

export default Options;
