import { memo } from 'react';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import Options from '../../../../constants/options';
import PaymentItem from './item/paymentItem';
import './style.scss';

const PaymentDetail = memo(({ formik }) => {
  const { advanceApprovalFlag } = formik.values;

  return (
    <div className="payment_tab_content">
      <div>
        <div className="payment_tab_content--item">
          <CustomFormikInput
            formik={formik}
            inputName="beforePayAmount"
            label="前払金"
            placeholder="0"
            unit="円"
            style={{
              labelStyle: { width: 170 },
            }}
          />
          <CustomFormikInput
            formik={formik}
            inputName="beforePayReason"
            placeholder="前払い理由を入力してください"
          />
        </div>
        <CustomFormikInput
          formik={formik}
          inputName="beforePayDate"
          inputType="inputDate"
          label="前払金受渡日"
          placeholder="YYYY/MM/DD"
          style={{
            labelStyle: { width: 170 },
          }}
        />
        <div className="payment_tab_content--item">
          <CustomFormikInput
            label="手当金"
            formik={formik}
            inputName="allowanceAmount"
            placeholder="0"
            unit="円"
            style={{
              labelStyle: { width: 170 },
            }}
          />
          <CustomFormikInput
            formik={formik}
            inputName="allowanceReason"
            placeholder="手当金理由を入力してください"
          />
        </div>
      </div>
      <div className="payment_tab_content--item_list">
        {Options.codes.advanceCategory.map((category, i) => (
          <PaymentItem
            formik={formik}
            inputName={`advance.[${i}]`}
            label={category.label}
            key={category.label}
          />
        ))}
      </div>
      <CustomFormikInput
        formik={formik}
        radioButtonTitle="立替金承認"
        inputName="advanceApprovalFlag"
        inputType="radioButton"
        radioButtonLabelAndChecked={[
          {
            label: '否認',
            checked: advanceApprovalFlag == 2,
          },
          { label: '承認', checked: advanceApprovalFlag == 1 },
          { label: '未承認', checked: advanceApprovalFlag == 0 },
        ]}
        initialValue={[2, 1, 0]}
        style={{
          labelStyle: { width: 170 },
          areaStyle: {
            marginTop: 50,
            marginBottom: 30,
          },
        }}
      />
      <CustomFormikInput
        formik={formik}
        label="否認理由"
        inputType="textBox"
        inputName="advanceRemandReason"
        placeholder="否認理由を入力してください"
        style={{
          labelStyle: { width: 170 },
        }}
      />
    </div>
  );
});

export default PaymentDetail;
