import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import ListView from '../../../components/listView';
import Copyright from '../../../components/copyright';
import AllBankOutputModal from '../../../components/modal/allBankOutputModal';
import Button from '../../../components/button';
import CustomInput from '../../../components/customInput';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import Options from '../../../constants/options';
import Icons from '../../../constants/icons';
import CsvExportModal from '../../../components/modal/csvExportModal';
import {
  staffPayActions,
  staffPayInfoFetch,
  staffPayListFetch,
  staffPayStatusUpdateFetch,
} from '../../../redux/slice/staffPay';
import { globalActions } from '../../../redux/slice/global';
import './style.scss';
import { checkIsSp } from '../../../utils/fnUtil';

const StaffPayList = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSp = checkIsSp();

  //全銀データ出力モダルコントローラ
  const [allBankModalVisible, setAllBankModalVisible] = useState(false);
  const changeAllBankModalVisible = () => {
    setAllBankModalVisible(!allBankModalVisible);
  };

  const { searchParams, staffPayList, managerUserIdList } = useSelector(
    state => state.staffPay
  );

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      let val = {
        ...values,
        year: values.yearMonth.split('/')[0],
        month: values.yearMonth.split('/')[1],
        employmentType: employmentTypeArray
          ? employmentTypeArray.join(',')
          : null,
        managerUserId: managerUserIdArray ? managerUserIdArray.join(',') : null,
        status: statusArray ? statusArray.join(',') : null,
      };
      dispatch(staffPayActions.saveSearchParams(val));
    },
  });

  const {
    employmentTypeArray,
    managerUserIdArray,
    statusArray,
    isZero,
    statusMode,
    sortKey: sortColumn,
    sortMethod,
  } = formik.values;

  const statusObject = {
    options: [
      'すべて',
      '本部未承認',
      '本部承認済',
      'スタッフ未承認',
      'スタッフ承認済',
      '経理未承認',
      '経理承認済',
      '給与確定',
      '支払済',
    ],
    values: [99, 0, 1, 2, 3, 4, 5, 6, 7],
  };

  useEffect(() => {
    dispatch(staffPayInfoFetch());
    const searchBase =document.querySelector('.list_view--search_base');
    searchBase.style.zIndex = 2;
  }, []);
  useEffect(() => {
    dispatch(staffPayListFetch(searchParams));
    dispatch(
      staffPayActions.setEdit({
        year: searchParams.year,
        month: searchParams.month,
      })
    );
  }, [searchParams]);

  const StaffPayListSearch = () => {
    return (
      <>
        <div
          style={{
            gap: '24px',
            display: 'flex',
            flexFlow: 'row wrap',
            width: isSp ? '100%' : 'auto',
          }}
        >
          <CustomFormikInput
            formik={formik}
            inputType="inputMonth"
            label="支払年月"
            inputName="yearMonth"
            placeholder="YYYY/MM"
            className={{ areaClass: 'matter_schedule--head_select' }}
            style={
              isSp
                ? {
                    areaStyle: { width: '40%' },
                  }
                : {}
            }
          />
          <CustomFormikInput
            formik={formik}
            inputType="select"
            mode={'multiple'}
            showSearch={false}
            allowClear={false}
            label="スタッフ契約形態"
            inputName="employmentTypeArray"
            initialValue={Object.keys(Options.codes.employmentType)}
            selectBoxOptions={Object.values(Options.codes.employmentType)}
            selectedSelectBox={employmentTypeArray}
            placeholder="スタッフ契約形態を選択してください"
            className={{ areaClass: 'column' }}
            style={{
              areaStyle: { width: isSp ? '100%' : '327px' },
            }}
            onChange={e => {
              formik.setFieldValue('employmentTypeArray', e);
            }}
          />
          <CustomFormikInput
            formik={formik}
            inputType="input"
            label="スタッフID"
            inputName="staffId"
            placeholder="スタッフIDを入力してください"
            className={{ areaClass: 'column' }}
            style={{ areaStyle: { width: isSp ? '100%' : '258px' } }}
          />
          <CustomFormikInput
            formik={formik}
            inputType="input"
            label="スタッフ名"
            inputName="staffName"
            placeholder="スタッフ名を入力してください"
            className={{ areaClass: 'column' }}
            style={{ areaStyle: { width: isSp ? '100%' : '300px' } }}
          />

          <CustomFormikInput
            formik={formik}
            inputType="select"
            mode={'multiple'}
            showSearch={true}
            allowClear={false}
            label="メイン担当者"
            inputName="managerUserIdArray"
            initialValue={managerUserIdList.map(p => p?.userId)}
            selectBoxOptions={managerUserIdList.map(
              p => p?.userId + ':' + p?.userName
            )}
            selectedSelectBox={managerUserIdArray}
            placeholder="メイン担当者を入力してください"
            className={{ areaClass: 'column' }}
            style={{ areaStyle: { width: isSp ? '100%' : '400px' } }}
            onChange={e => {
              formik.setFieldValue('managerUserIdArray', e);
            }}
          />

          <CustomFormikInput
            formik={formik}
            inputType="select"
            mode={statusMode}
            showSearch={false}
            allowClear={false}
            label="ステータス"
            inputName="statusArray"
            initialValue={statusObject.values}
            selectBoxOptions={statusObject.options}
            selectedSelectBox={statusArray}
            className={{ areaClass: 'column' }}
            style={{
              areaStyle: { width: isSp ? '100%' : '180px' },
              labelStyle: { width: '80px' },
            }}
            onChange={e => {
              let toString = Object.prototype.toString;
              if (toString.call(e) === '[object Array]' && e.includes(99)) {
                formik.setFieldValue('statusMode', '');
                formik.setFieldValue('statusArray', [99]);
              } else {
                formik.setFieldValue('statusMode', 'multiple');
                formik.setFieldValue('statusArray', e);
              }
            }}
          />

          <CustomInput
            formik={formik}
            inputType="checkBox"
            label="支払額0円の明細を表示する"
            initialValue={isZero}
            onChange={e => {
              formik.setFieldValue('isZero', e.target.checked);
            }}
            style={{
              areaStyle: isSp
                ? {}
                : {
                    position: 'absolute',
                    right: '40px',
                    bottom: '50px',
                  },
            }}
          />
        </div>
      </>
    );
  };
  const searchFormInfo = {
    jsx: StaffPayListSearch,
  };
  const columnSettings = {
    checkAll: true,
    staffId: { label: 'スタッフID', sort: 'Asc', dataName: 'staffId' },
    staffName: { label: 'スタッフ名', sort: 'Asc', dataName: 'staffName' },
    employmentType: {
      label: '契約形態',
      sort: 'Asc',
      dataName: 'employmentType',
      jsx: employmentType => (
        <span>
          {Options.codes.employmentType[employmentType.employmentType]}
        </span>
      ),
    },
    payMethod: {
      label: '支払方法',
      sort: 'Asc',
      dataName: 'payMethod',
      jsx: payMethod => (
        <span>{Options.codes.payMethod[payMethod.payMethod]}</span>
      ),
    },
    payAmount: {
      label: '支払額',
      sort: 'Asc',
      dataName: 'payAmount',
    },
    managerName: {
      label: 'メイン担当者',
      sort: 'Asc',
      dataName: 'managerName',
    },
    managerStatus: {
      label: '本部',
      sort: 'Asc',
      dataName: 'managerStatus',
      jsx: manager => (
        <span
          className="staff_pay_list--status"
          style={{
            background:
              Options.statusMap.staffPayListApprovalStatus[
                manager.managerStatus
              ]?.background,
            color:
              Options.statusMap.staffPayListApprovalStatus[
                manager.managerStatus
              ]?.color,
          }}
        >
          {
            Options.statusMap.staffPayListApprovalStatus[manager.managerStatus]
              ?.label
          }
        </span>
      ),
    },
    staffStatus: {
      label: 'スタッフ',
      sort: 'Asc',
      dataName: 'staffStatus',
      jsx: staff => (
        <span
          className="staff_pay_list--status"
          style={{
            background:
              Options.statusMap.staffPayListApprovalStatus[staff.staffStatus]
                ?.background,
            color:
              Options.statusMap.staffPayListApprovalStatus[staff.staffStatus]
                ?.color,
          }}
        >
          {
            Options.statusMap.staffPayListApprovalStatus[staff.staffStatus]
              ?.label
          }
        </span>
      ),
    },
    accountantStatus: {
      label: '経理',
      sort: 'Asc',
      dataName: 'accountantStatus',
      jsx: accountant => (
        <span
          className="staff_pay_list--status"
          style={{
            background:
              Options.statusMap.staffPayListApprovalStatus[
                accountant.accountantStatus
              ]?.background,
            color:
              Options.statusMap.staffPayListApprovalStatus[
                accountant.accountantStatus
              ]?.color,
          }}
        >
          {
            Options.statusMap.staffPayListApprovalStatus[
              accountant.accountantStatus
            ]?.label
          }
        </span>
      ),
    },
    paymentStatus: {
      label: '支払状況',
      sort: 'Asc',
      dataName: 'paymentStatus',
      jsx: payment => (
        <span
          className="staff_pay_list--status"
          style={{
            background:
              Options.statusMap.staffPayListPaymentStatus[payment.paymentStatus]
                ?.background,
            color:
              Options.statusMap.staffPayListPaymentStatus[payment.paymentStatus]
                ?.color,
          }}
        >
          {
            Options.statusMap.staffPayListPaymentStatus[payment.paymentStatus]
              ?.label
          }
        </span>
      ),
    },
    statics: [{ label: '編集', icon: Icons.icon.editSNv }],
  };
  const buttonSettings = {
    viewPager: true,
    allBank: {
      icon: Icons.icon.navyDocument,
      text: '全銀データ出力',
      style: Options.buttonStyles.allBank,
      onClick: () => {
        changeAllBankModalVisible();
      },
    },
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => {
        toggleCsvExportVisible();
      },
      style: Options.buttonStyles.csv,
    },
    ignoreAddBtn: isSp,
  };

  const [csvExportVisible, setCsvExportVisible] = useState(false);
  const toggleCsvExportVisible = bool => {
    setCsvExportVisible(bool === undefined ? !csvExportVisible : bool);
  };
  const csvExportFunction = category => {
    alert(category);
  };

  const changeSort = e => {
    dispatch(
      staffPayActions.setSort({
        sortKey: e.target.id,
        sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
        sort: `${e.target.id}${sortMethod === 'Asc' ? 'Desc' : 'Asc'}`,
      })
    );
  };

  const approvalStatusUpdate = (param, text) => {
    if (param.staffId === '') {
      dispatch(globalActions.showSingleModal('1つ以上選択してください'));
    } else {
      dispatch(staffPayStatusUpdateFetch(param))
        .unwrap()
        .then(() => {
          dispatch(globalActions.showSingleModal(text));
          dispatch(staffPayActions.listRefresh());
        });
    }
  };

  const getCheckedStaffId = () => {
    const checkAll = document.getElementById('checkAll').checked;
    const checks = document.querySelectorAll('input[id^="check_"]');

    let ret = '';
    if (checkAll) {
      ret = 'all';
    } else {
      ret = [];
      checks.forEach((elm, index) => {
        if (elm.checked) {
          ret.push(index);
        }
      });
      ret.filter(v => v);
    }

    const checkedStaffId =
      ret === 'all'
        ? staffPayList?.item?.map(i => {
            return i.staffId;
          })
        : staffPayList?.item
            ?.filter((item, i) => ret.includes(i))
            .map(i => {
              return i.staffId;
            });

    return checkedStaffId;
  };

  const statusUpdate = methodName => {
    const checkedStaffId = getCheckedStaffId();

    const yearMonthParam = {
      year: formik.values.yearMonth.split('/')[0],
      month: formik.values.yearMonth.split('/')[1],
    };
    // 担当社員承認済にする
    if (methodName === 'managerApprove') {
      const param = {
        ...yearMonthParam,
        staffId: checkedStaffId.join(','),
        managerApproval: 1,
      };
      dispatch(
        globalActions.showSelectModal({
          title: '担当社員承認済にしますか？',
          buttons: [
            {
              btnText: '戻る',
              style: {
                ...Options.buttonStyles.back,
                height: checkIsSp() ? '40px' : '44px',
                fontSize: checkIsSp() ? '12px' : '14px',
                width:  checkIsSp() ? '100%' : '208px',
                maxWidth: checkIsSp() ? '208px' : '',
                marginTop: checkIsSp() ? '24px' : '',
              },
            },
            {
              btnText: '承認済にする',
              btnAction: () => {
                approvalStatusUpdate(param, `承認済に更新しました`);
              },
              style: {
                ...Options.buttonStyles.submit,
                height: checkIsSp() ? '40px' : '44px',
                fontSize: checkIsSp() ? '12px' : '14px',
                width:  checkIsSp() ? '100%' : '208px',
                maxWidth: checkIsSp() ? '208px' : '',
                marginTop: checkIsSp() ? '24px' : '',
              },
            },
          ],
        })
      );
      // 給与確定メール
    } else if (methodName === 'payMentMail') {
      const param = {
        ...yearMonthParam,
        staffId: checkedStaffId.join(','),
        mailFlag: 1,
      };
      dispatch(
        globalActions.showSelectModal({
          title: '給与確定メールをしますか？',
          buttons: [
            {
              btnText: '戻る',
              style: {
                ...Options.buttonStyles.back,
                height: checkIsSp() ? '40px' : '44px',
                fontSize: checkIsSp() ? '12px' : '14px',
                width:  checkIsSp() ? '100%' : '208px',
                maxWidth: checkIsSp() ? '208px' : '',
                marginTop: checkIsSp() ? '24px' : '',
              },
            },
            {
              btnText: 'メールをする',
              btnAction: () => {
                approvalStatusUpdate(param, `メールを送信しました`);
              },
              style: {
                ...Options.buttonStyles.submit,
                height: checkIsSp() ? '40px' : '44px',
                fontSize: checkIsSp() ? '12px' : '14px',
                width:  checkIsSp() ? '100%' : '208px',
                maxWidth: checkIsSp() ? '208px' : '',
                marginTop: checkIsSp() ? '24px' : '',
              },
            },
          ],
        })
      );
      // 支払済にする
    } else if (methodName === 'accountantApprovalUpdate') {
      const param = {
        ...yearMonthParam,
        staffId: checkedStaffId.join(','),
        paymentStatus: 1,
      };
      dispatch(
        globalActions.showSelectModal({
          title: '支払済にしますか？',
          buttons: [
            {
              btnText: '戻る',
              style: {
                ...Options.buttonStyles.back,
                height: checkIsSp() ? '40px' : '44px',
                fontSize: checkIsSp() ? '12px' : '14px',
                width:  checkIsSp() ? '100%' : '208px',
                maxWidth: checkIsSp() ? '208px' : '',
                marginTop: checkIsSp() ? '24px' : '',
              },
            },
            {
              btnText: '支払済にする',
              btnAction: () => {
                approvalStatusUpdate(param, `支払済に更新しました`);
              },
              style: {
                ...Options.buttonStyles.submit,
                height: checkIsSp() ? '40px' : '44px',
                fontSize: checkIsSp() ? '12px' : '14px',
                width:  checkIsSp() ? '100%' : '208px',
                maxWidth: checkIsSp() ? '208px' : '',
                marginTop: checkIsSp() ? '24px' : '',
              },
            },
          ],
        })
      );
      // 全銀データ出力
    } else if (methodName === 'paymentStatusUpdate') {
      const param = {
        ...yearMonthParam,
        staffId: checkedStaffId.join(','),
        paymentStatus: 1,
      };
      dispatch(staffPayStatusUpdateFetch(param));
    }
  };

  const { isOpenSideMenu } = useSelector(state => state.global);

  return (
    <>
      <div className={'commonPage--base staff_pay_base'}>
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey={'staffId'}
          primaryName={'staffName'}
          apiData={staffPayList}
          sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
          changeSort={changeSort}
          onSearch={formik.handleSubmit}
          onChangeCount={searchCount =>
            dispatch(staffPayActions.saveSearchParams({ count: searchCount }))
          }
          onChangeOffset={offset =>
            dispatch(staffPayActions.saveOffset(offset))
          }
          keepCheckedFlag={!csvExportVisible}
        />
        <Copyright />
      </div>

      <div
        className={'bottom_btn_area'}
        style={
          isSp
            ? { height: '200px', justifyContent: 'center', padding: '20px' }
            : {
                height: '96px',
                flexFlow: 'row',
                gap: '40px',
                justifyContent: 'center',
                width: isOpenSideMenu ? 'calc(100% - 200px)' : 'calc(100% - 56px)',
              }
        }
      >
        <Button
          text={'担当社員承認済にする'}
          style={{
            width: '238px',
            height: '42px',
            border: '1px solid #2B3245',
            borderRadius: '6px',
            backgroundColor: '#FFFFFF',
            color: '#2B3245',
            fontSize: '14px',
            margin: '0 0',
            padding: '0 0',
            userSelect: 'none',
            boxSizing: 'content-box',
          }}
          onClick={() => statusUpdate('managerApprove')}
        />
        <Button
          text={'給与確定メール'}
          icon={Icons.icon.mailNv}
          style={{
            width: '238px',
            height: '42px',
            border: '1px solid #2B3245',
            borderRadius: '6px',
            backgroundColor: '#FFFFFF',
            color: '#2B3245',
            fontSize: '14px',
            margin: '0 0',
            padding: '0 0',
            userSelect: 'none',
            boxSizing: 'content-box',
          }}
          onClick={() => statusUpdate('payMentMail')}
        />
        <Button
          text={'支払済にする'}
          style={Options.buttonStyles.stampApproval}
          onClick={() => statusUpdate('accountantApprovalUpdate')}
        />
      </div>

      <CsvExportModal
        exportType={'staffPayList'}
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={toggleCsvExportVisible}
        exportFunction={csvExportFunction}
        searchParams={searchParams}
      />
      <AllBankOutputModal
        modalVisible={allBankModalVisible}
        changeModalVisible={changeAllBankModalVisible}
        getCheckedStaffId={getCheckedStaffId}
        searchParams={searchParams}
      />
    </>
  );
};

export default StaffPayList;
