import { memo, useEffect, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { get, isNil, toInteger } from 'lodash';
import { Table, Modal } from 'antd';
import { checkIsSp, handlerFormikFieldChange, numberFormat } from "../../../utils/fnUtil";
import {
  fetchMatterRecommendDetail,
  fetchMatterRecommendStaffList,
  matterDateActions,
} from '../../../redux/slice/matterDate';
import Paging from '../../pager/paging';
import SearchTitle from '../../searchTitle';
import Checkbox from '../../checkbox';
import CustomFormikInput from '../../customInput/customFormikInput';
import Button from '../../button';
import Icons from '../../../constants/icons';
import Avatar from 'antd/lib/avatar/avatar';
import classNames from 'classnames';
import Options from '../../../constants/options';
import moment from 'moment';
import './style.scss';

const StaffSearchModal = memo(
  ({ visible, onCancel, shiftId, index, formik, plannedPayment }) => {
    const dispatch = useDispatch();

    const {
      staffSearchParams,
      staffList,
      assignDetail,
      holdingItemList,
      certificationList,
      staffSearchCount,
      staffAssignList,
    } = useSelector(state => state.matterDate);

    const { shift: currentShift } = formik.values;

    const { shiftPosition, currentAssign, payAmount } =
      currentShift[index] ?? {};

    const { matterDetail, dateInfo } = assignDetail ?? {};

    const { matterCategory, matterNumber } = matterDetail ?? {};

    const [currentPage, setCurrentPage] = useState(1);

    const baseSearchParams = {
      matterCategory,
      matterNumber,
      matterDate:
        dateInfo?.matterDate &&
        moment(dateInfo.matterDate).format('YYYY-MM-DD'),
      matterDateNumber: dateInfo?.matterDateNumber,
      shiftId,
    };

    const staffSearchFormik = useFormik({
      initialValues: {
        ...staffSearchParams,
        ...baseSearchParams,
      },
      enableReinitialize: true,
      onSubmit: values =>
        dispatch(matterDateActions.saveStaffSearchParams(values)),
    });

    useUpdateEffect(() => {
      visible && dispatch(fetchMatterRecommendStaffList(staffSearchParams));
    }, [staffSearchParams]);

    useEffect(() => {
      // visible &&
      //   dispatch(matterDateActions.saveStaffSearchParams({ count: 20 }));
      !visible && dispatch(matterDateActions.clearStaffList());
    }, [visible]);

    useEffect(() => {
      if (shiftPosition >= 0) {
        const target = {
          0: {
            position: 2,
          },
          1: {
            position: 1,
          },
          2: {
            position: 0,
            gender: 1,
          },
          3: {
            position: 0,
            gender: 2,
          },
          4: {
            position: 0,
            gender: null,
          },
        }[shiftPosition];

        handlerFormikFieldChange(
          staffSearchFormik,
          'staffPosition',
          target.position
        );
        handlerFormikFieldChange(
          staffSearchFormik,
          'gender',
          target?.gender ?? null
        );
      }
    }, [shiftPosition]);

    const { sortKey, sortMethod, sort1, sort2, sort3, staffPosition } =
      staffSearchFormik.values;

    const noteController = (staffId) => {
      if (currentShift?.length > 0) {
        return currentShift?.some(obj => {
          return obj?.shiftAssign?.some(o => o?.assignId === staffId)
        });
      } else {
        return false;
      }
    }

    return (
      <Modal
        style={{
          minWidth: '90%',
        }}
        visible={visible}
        closable={false}
        footer={null}
        centered
        onCancel={onCancel}
        destroyOnClose={true}
        className="staff_search_modal common_modal_base"
      >
        <div className="common_modal_title">スタッフ検索</div>
        <div className="common_modal_content">
          <div className="common_search_area">
            <SearchTitle />
            <div className="search_form_base search_form_staff">
              <CustomFormikInput
                formik={staffSearchFormik}
                placeholder="スタッフIDを入力してください"
                label="スタッフID"
                inputName="staffId"
              />
              <CustomFormikInput
                formik={staffSearchFormik}
                placeholder="スタッフ名を入力してください"
                label="スタッフ名"
                inputName="staffName"
              />
              {[2, 3].includes(shiftPosition) ? (
                <div className="search_form_staff--custom_input gender_input">
                  <label>性別</label>
                  <div>
                    {
                      Options.codes.genderWithoutEmpty.find(
                        g => g.value === (shiftPosition === 2 ? 1 : 2)
                      )?.label
                    }
                  </div>
                </div>
              ) : (
                <CustomFormikInput
                  formik={staffSearchFormik}
                  placeholder="すべて"
                  label="性別"
                  initialValue={Options.codes.genderWithoutEmpty.map(
                    g => g.value
                  )}
                  selectBoxOptions={Options.codes.genderWithoutEmpty.map(
                    g => g.label
                  )}
                  inputName="gender"
                  inputType="select"
                />
              )}

              <CustomFormikInput
                formik={staffSearchFormik}
                placeholder="すべて"
                label="評価"
                initialValue={Options.codes.staffEvaluation.map(g => g.value)}
                selectBoxOptions={Options.codes.staffEvaluation.map(
                  g => g.label
                )}
                inputName="staffEvaluation"
                inputType="select"
              />
              <CustomFormikInput
                formik={staffSearchFormik}
                placeholder="ポジションを選択してください"
                label="ポジション"
                initialValue={Options.codes.position.map(g => g.value)}
                selectBoxOptions={Options.codes.position.map(g => g.label)}
                inputName="staffPosition"
                inputType="select"
                style={{
                  areaStyle: {
                    width: '260px',
                  },
                }}
                showSearch={!checkIsSp()}
              />
              <CustomFormikInput
                formik={staffSearchFormik}
                placeholder="持っているものを選択してください"
                label="持っているもの"
                initialValue={holdingItemList.map(h => h.namingId)}
                selectBoxOptions={holdingItemList.map(h => h.naming)}
                inputName="holdingItemList"
                inputType="select"
                mode="multiple"
                allowClear={false}
                showSearch={!checkIsSp()}
              />
              <CustomFormikInput
                formik={staffSearchFormik}
                placeholder="資格を選択してください"
                label="資格"
                initialValue={certificationList.map(h => h.namingId)}
                selectBoxOptions={certificationList.map(h => h.naming)}
                inputName="certificationList"
                inputType="select"
                mode="multiple"
                allowClear={false}
                showSearch={!checkIsSp()}
              />
              <CustomFormikInput
                formik={staffSearchFormik}
                placeholder="すべて"
                label="シフト状況"
                initialValue={Options.codes.assignFlag.map(g => g.value)}
                selectBoxOptions={Options.codes.assignFlag.map(g => g.label)}
                inputName="enableAssignFlag"
                inputType="select"
              />
              {[
                {
                  filter: [sort2, sort3],
                },
                {
                  filter: [sort1, sort3],
                },
                {
                  filter: [sort1, sort2],
                },
              ].map((s, i) => (
                <CustomFormikInput
                  formik={staffSearchFormik}
                  placeholder={`ソート順${i + 1}を選択してください`}
                  label={`ソート順${i + 1}`}
                  initialValue={Options.codes.sort
                    .filter(c => !s.filter.includes(c.value))
                    .map(c => c.value)}
                  selectBoxOptions={Options.codes.sort
                    .filter(c => !s.filter.includes(c.value))
                    .map(c => c.label)}
                  inputName={`sort${i + 1}`}
                  inputType="select"
                />
              ))}
              <div className="search_form_base--action">
                <Checkbox
                  label="応募者のみ表示する"
                  checked={staffSearchFormik.values.appliedFlag === 1}
                  onChange={a => {
                    handlerFormikFieldChange(
                      staffSearchFormik,
                      'appliedFlag',
                      a.target.checked ? 1 : null
                    );
                  }}
                />
                <Button
                  text="検索"
                  onClick={() => {
                    staffSearchFormik.handleSubmit();

                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="table_area">
            <Paging
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              searchCount={staffSearchCount}
              onChangeCount={count =>
                dispatch(
                  matterDateActions.saveStaffSearchParams({
                    count,
                  })
                )
              }
              onChangeOffset={offset =>
                dispatch(matterDateActions.saveStaffSearchOffset(offset))
              }
              component={
                <Table
                  scroll={{ x: 'max-content' }}
                  pagination={false}
                  columns={[
                    {
                      title: 'No',
                      render: (_a, _b, i) => i + 1,
                    },
                    {
                      title: '名前',
                      sortKey: 'staff_name',
                      width: 200,
                      render: (_, record) => (
                        <div className="detail_info">
                          <div>
                            <Avatar src={record.staffImage} size={60} />
                            {record.appliedFlag === 1 && <span>応募</span>}
                          </div>
                          <div>
                            <span>{record.staffId}</span>
                            <span>{record.staffName}</span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      title: '性別',
                      dataIndex: 'gender',
                      sortKey: 'gender',
                      render: value =>
                        Options.codes.gender.find(g => g.value === value)
                          ?.label,
                    },
                    {
                      title: '出勤可否',
                      dataIndex: 'enableAssignFlag',
                      sortKey: 'enable_assign_flag',
                      render: value =>
                        ({ 0: '出勤不可', 1: '出勤可能', 2: '出勤不可' }[
                          value
                        ]),
                    },
                    {
                      title: '同一案件カテゴリ\n経験回数',
                      dataIndex: 'experiencesNumber',
                      sortKey: 'experiences_number',
                      render: value => `${value ?? '-'}回`,
                    },
                    {
                      title: 'スタッフ希望度',
                      dataIndex: 'desire',
                      sortKey: 'desire',
                    },
                    {
                      title: '評価',
                      dataIndex: 'staffEvaluation',
                      sortKey: 'staff_evaluation',
                      render: value => {
                        const staffEvaluationLabel =
                          Options.codes.staffEvaluation.find(
                            s => s.value === value
                          )?.label;

                        return staffEvaluationLabel
                          ? `${staffEvaluationLabel}評価`
                          : '-';
                      },
                    },
                    {
                      title: '当月アサイン済\n案件数',
                      dataIndex: 'assignCount',
                      sortKey: 'assign_count',
                      render: (value, record) =>
                        value > 15 || record.continuousDays >= 5 ? (
                          <span className="warning_text">{`${
                            value ?? '-'
                          }件`}</span>
                        ) : (
                          `${value ?? '-'}件`
                        ),
                    },
                    {
                      title: '当年支払額',
                      dataIndex: 'yearTotalAmount',
                      sortKey: 'year_total_amount',
                      render: value =>
                        value + plannedPayment > 1030000 ? (
                          <span className="warning_text">
                            {numberFormat(value, '円', '-')}
                          </span>
                        ) : (
                          numberFormat(value, '円', '-')
                        ),
                    },
                    {
                      title: '支払予定額',
                      dataIndex: 'plannedPayment',
                      render: (_, record) =>
                        plannedPayment + record.yearTotalAmount > 1030000 ? (
                          <span className="warning_text">
                            {numberFormat(plannedPayment, '円', '-')}
                          </span>
                        ) : (
                          numberFormat(plannedPayment, '円', '-')
                        ),
                    },
                    {
                      title: '',
                      align: 'center',
                      render: (_a, record) => {
                        const assignDuplication = staffAssignList.some(
                          a =>
                            a.assignId === record.staffId &&
                            a.assignAccountFlag === 0
                        );

                        const addFinish = currentShift
                          .flatMap(s => s.shiftAssign)
                          .some(
                            a =>
                              a.assignId === record.staffId &&
                              a.assignAccountFlag === 0
                          );

                        const assigned = currentShift?.[index]?.shiftAssign?.some( //シフトにアサインされてたらfalse
                          a =>
                            a.assignId === record.staffId &&
                            a.assignAccountFlag === 0
                        );

                        const doubleBooking = record.enableAssignFlag === 1 && record?.doubleBookingFlag === 1; //同じ案件実施日の別の案件にアサインされてたらtrue

                        const isOver =
                          record.yearTotalAmount + plannedPayment > 1030000;

                        const isPlanOver =
                          record.yearTotalAmount + plannedPayment >
                          // +  toInteger(payAmount)
                          1030000;

                        const disabled =
                          // assignDuplication ||
                          // addFinish ||
                          assigned ||
                          record.enableAssignFlag !== 1;

                        return (
                          <div className="action_area">
                            <Button
                              onClick={async () => {
                                const detailRes = await dispatch(
                                  fetchMatterRecommendDetail({
                                    ...baseSearchParams,
                                    staffId: record.staffId,
                                  })
                                ).unwrap();

                                const updateShiftAssign = {
                                  ...get(
                                    formik.values,
                                    `shift.[${index}].shiftAssign.[${currentAssign}]`
                                  ),
                                  ...record,
                                  image: record.staffImage,
                                  assignAccountFlag: 0,
                                  assignId: record.staffId,
                                  assignName: record.staffName,
                                  staffWeekMethodFlag: record.weekMethodFlag,
                                  staffPreviousDayMethodFlag:
                                    record.previousDayMethodFlag,
                                  staffDepartureMethodFlag:
                                    record.departureMethodFlag,
                                  staffArriveMethodFlag:
                                    record.arriveMethodFlag,
                                  staffEndMethodFlag: record.endMethodFlag,
                                  staffGettingUpMethodFlag:
                                    record.gettingUpMethodFlag,
                                };

                                handlerFormikFieldChange(
                                  formik,
                                  `shift.[${index}].shiftAssign.[${currentAssign}]`,
                                  updateShiftAssign
                                );

                                onCancel();
                              }}
                              disabled={disabled}
                              className={classNames('add_btn', {
                                add_btn_disabled: disabled,
                              })}
                              text={
                                // assignDuplication
                                //   ? 'アサイン重複'
                                //   // : addFinish
                                //   : assigned
                                assigned
                                  ? '追加済'
                                  : '追加'
                              }
                            />
                            {!assigned && (noteController(record.staffId) || doubleBooking) ? (
                              <span className="warning_text">Ｗブッキング</span>
                            ) : isOver ? (
                              <span className="warning_text">103万円超過</span>
                            ) : (
                              isPlanOver && (
                                <span className="warning_text">
                                  103万円超過見込み
                                </span>
                              )
                            )}
                            {record.continuousDays >= 5 && (
                              <span className="warning_text">6連勤以上</span>
                            )}
                          </div>
                        );
                      },
                    },
                  ].map(c => ({
                    ...c,
                    title: (
                      <div
                        onClick={() =>
                          c.sortKey &&
                          dispatch(
                            matterDateActions.setStaffSearchSort({
                              sortKey: c.sortKey,
                              sortMethod: sortMethod === 'Asc' ? 'Desc' : 'Asc',
                            })
                          )
                        }
                      >
                        {c.title}
                        {sortKey === c.sortKey ? (
                          sortMethod === 'Asc' ? (
                            <img
                              src={Icons.icon.downGr}
                              style={{ transform: 'rotate(180deg)' }}
                            />
                          ) : (
                            <img src={Icons.icon.downGr} />
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    ),
                  }))}
                  dataSource={staffList}
                />
              }
            />
          </div>
        </div>
      </Modal>
    );
  }
);

export default StaffSearchModal;
