import CustomInput from '../customInput';
import Button from '../button';
import {Formik} from 'formik';
import './style.scss';
import { checkIsSp } from '../../utils/fnUtil';

const CustomForm = ({ formInfo, onSubmit }) => {

  const isSp = checkIsSp();

  const customFormClassName = () => {
    let className = formInfo.form.formClassName;
    if (formInfo.isParams && formInfo.pageName === 'login') {
      className = className + ' is_corporate_id_form';
    }
    return className;
  }

  const submitButtonArea = () => {
    let submitButtonArea;
    if (formInfo.pageName === 'login' || formInfo.pageName === 'changePass') {
      submitButtonArea = 'custom_form--submit_button_area';
    } else if (formInfo.pageName === 'forgetPass') {
      submitButtonArea = 'custom_form--double_button_area';
    }
    return submitButtonArea;
  }

  const initialValues = {};
  formInfo.inputs.forEach((obj) => {
    Array.isArray(obj.inputName) 
    ? obj.initialValue.forEach((_, idx) => {initialValues[obj.inputName[idx]] = obj.initialValue[idx]})
    : initialValues[obj.inputName] = obj.initialValue;
  });
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formInfo.form.validation}
      onSubmit={onSubmit}
      >
      {(props, isSubmitting) => (
        <form className={customFormClassName()} onSubmit={props.handleSubmit}>
          {formInfo.inputs.map((inputInfos) => 
            <CustomInput 
              {...inputInfos}
              isParam={formInfo.isParams}
              key={inputInfos.inputName}
              onChange={props.handleChange}
              value={props.values[inputInfos.inputName]}            
              touched={props.touched[inputInfos.inputName]}
              onBlur={props.handleBlur}
              error={props.errors[inputInfos.inputName]}
              setFieldValue={props.setFieldValue}
            />
          )}
          <div className={submitButtonArea()}>
            {formInfo.pageName === 'forgetPass' &&
              <Button
                disabled={isSubmitting}
                type="button"
                width={formInfo.subButton.width}
                text={formInfo.subButton.text}
                style={{
                  ...formInfo.subButton.style,
                  height: isSp ? '40px' : '44px',
                  fontSize: isSp ? '14px' : '12px'
                }}
                onClick={formInfo.subButton.onClick}
              />
            }
            <Button
              disabled={isSubmitting}
              type="submit"
              width={formInfo.button.width}
              text={formInfo.button.text}
              style={{
                ...formInfo.button.style,
                height: isSp ? '40px' : '44px',
                fontSize: isSp ? '14px' : '12px'
              }}
              onClick={formInfo.button.onClick}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CustomForm;