import React, { useState } from 'react';
import { Steps, Tooltip } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Button from '../../../../../../components/button';
import Icons from '../../../../../../constants/icons';
import Options from '../../../../../../constants/options';
import './style.scss';
import { useSelector } from 'react-redux';
import { checkIsSp } from '../../../../../../utils/fnUtil';

//承認ステータスエリアのstyle
const stepsStatus = {
  0: {
    background: '#BCBCBC',
  },
  1: {
    background: '#0056D3',
  },
  2: {
    borderRadius: '3px',
    background: '#D80245',
  },
  3: {
    borderRadius: '3px',
    background: '#0056D3',
  },
};
//承認ステータス外divのstyle
const stepsStatusBox = {
  0: {
    border: '2px solid #BCBCBC',
  },
  1: {
    border: '2px solid #0056D3',
    borderRadius: '3px 3px 0px 0px',
  },
  2: {
    border: '2px solid #D80245',
    borderRadius: '3px',
  },
  3: {
    borderRadius: '3px 3px 0px 0px',
  },
};
const statusLetter = {
  0: '承認ステータス：見積中',
  1: '承認ステータス：申請中',
  2: '承認ステータス：否認',
  3: '承認ステータス：承認',
};

const messageOk = msg => {
  if (msg) {
    return (
      <Tooltip
        placement="bottom"
        title={msg}
        arrowPointAtCenter
        color="#242424CC"
        overlayClassName="quote_register--quotation_info--steps--message"
        overlayInnerStyle={{
          padding: '2px 8px 1px 8px',
          minWidth: '144px',
          minHeight: '28px',
          textAlign: 'center',
        }}
      >
        <img src={Icons.icon.chatNv} alt="chat" />
      </Tooltip>
    );
  }
};

const InfoSteps = ({ formik, denialButton, approvalButton, totalStepUserList, stepNow }) => {
  const { approvalSteps, approvalStatus, applyUserList, createdUser } =
    formik.values;
  const { userId } = useSelector(state => state.account);

  return (
    <div
      className="quote_register--quotation_info--steps"
      style={stepsStatusBox[approvalStatus]}
    >
      <div
        className="quote_register--quotation_info--steps--status"
        style={{ ...stepsStatus[approvalStatus], width: checkIsSp() && '100%', margin: checkIsSp() && '0 auto' }}
      >
        <div style={{ marginLeft: '24px' }}>{statusLetter[approvalStatus]}</div>
      </div>

      {/*{approvalSteps[stepNow]?.status !== 3 && ( //承認フローリストの中に一人でも承認ステータス以外の人がいると表示する*/}
      {approvalStatus !== 0 && approvalStatus !== 3 && ( //修正まえ
        <>
          <div className="quote_register--quotation_info--steps--area">
            {approvalSteps?.map((a, i) => (
              <div
                className={
                  a?.step === approvalSteps?.length && a?.status === 3
                    ? 'info--step--divs error_finish'
                    : a?.status === 1
                    ? 'info--step--divs step_wait'
                    : a?.status === 2
                    ? 'info--step--divs step_error'
                    : a?.status === 3
                    ? 'info--step--divs step_active'
                    : 'info--step--divs'
                }
              >
                <div className="info--step--area">
                  {a?.status === 1 ? (
                    <div
                      className="info--step"
                      style={{ borderColor: '#BCBCBC' }}
                    >
                      <span>{a?.step}</span>
                    </div>
                  ) : a?.status === 2 ? (
                    <div
                      className="info--step"
                      style={{ borderColor: '#D80245' }}
                    >
                      <span>
                        <img
                          src={Icons.icon.closeRd}
                          alt="close"
                          style={{ width: '32px' }}
                        />
                      </span>
                    </div>
                  ) : (
                    <div
                      className="info--step"
                      style={{ borderColor: '#003B94' }}
                    >
                      <span>
                        <CheckOutlined
                          style={{ color: '#003B94', fontSize: '18px' }}
                        />
                      </span>
                    </div>
                  )}
                  <div className="info--step--title">
                    <span>{!i ? createdUser?.name : a?.approver}</span>
                    {a?.message !== null && (
                      <span>{messageOk(a?.message)}</span>
                    )}
                  </div>
                  {a?.approverCount !== 1 && (
                    <div>{`他 ${a?.approverCount - 1}人`}</div>
                  )}
                  <div>{!i ? createdUser?.datetime : a?.datetime}</div>
                </div>
              </div>
            ))}
          </div>

          {statusLetter[approvalStatus] === '承認ステータス：申請中' && (totalStepUserList[stepNow] ? totalStepUserList[stepNow]?.includes(userId) : false) &&
            <div
              className={
                'quote_register--quotation_info--steps--stamp_buttons'
              }
            >
              <Button
                text={'否認'}
                icon={Icons.icon.ngWh}
                style={Options.buttonStyles.denialShort}
                onClick={denialButton}
              />
              <Button
                text={'承認'}
                icon={Icons.icon.okWh}
                style={Options.buttonStyles.stampApproval}
                onClick={approvalButton}
              />
            </div>
          }
          {/*下記変更前処理*/}
          {/*{applyUserList?.includes(userId) &&*/}
          {/*  approvalStatus !== 0 &&*/}
          {/*  approvalStatus !== 2 && (*/}
          {/*    <div*/}
          {/*      className={*/}
          {/*        'quote_register--quotation_info--steps--stamp_buttons'*/}
          {/*      }*/}
          {/*    >*/}
          {/*      <Button*/}
          {/*        text={'否認'}*/}
          {/*        icon={Icons.icon.ngWh}*/}
          {/*        style={Options.buttonStyles.denialShort}*/}
          {/*        onClick={denialButton}*/}
          {/*      />*/}
          {/*      <Button*/}
          {/*        text={'承認'}*/}
          {/*        icon={Icons.icon.okWh}*/}
          {/*        style={Options.buttonStyles.stampApproval}*/}
          {/*        onClick={approvalButton}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  )}*/}
        </>
      )}
    </div>
  );
};

export default InfoSteps;
