import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiRequest, fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';

const initialSearchParams = {
  matterNumber: null,
  matterName: '',
  matterStartDate: '',
  matterEndDate: '',
  matterRecruitStartDate: '',
  matterRecruitEndDate: '',
  matterManagerUserId: [],
  matterCategory: null,
  staffId: '',
  staffName: '',
  staffPosition: null,
  matterManagerUserId: [],
  status: [],
  offset: 0,
  count: 20,
  sortKey: 'matter_number',
  sortMethod: 'Desc',
};

const initialState = {
  searchParams: initialSearchParams,
  matterList: [],
  matterAppliedList: [],
  matterCategoryList: [],
  userList: [],
  csvExportVisible: false,
};

export const matterAppliedInit = createAsyncThunk(
  'matter/applied/init',
  async () => {
    const [matterRes, categoryRes, userRes] = await fetchMultiRequest([
      fetchRequest(apiUrl.matter.list),
      fetchRequest(apiUrl.naming.list, {
        namingClassificationId: 'PromotionCategory',
        status: 1,
      }),
      fetchRequest(apiUrl.user.list, { status: 1 }),
    ]);

    return {
      matterList: matterRes?.item ?? [],
      matterCategoryList: categoryRes?.item ?? [],
      userList: userRes?.item ?? [],
    };
  }
);

export const fetchMatterAppliedList = createAsyncThunk(
  'matter/applied/list',
  async params => await fetchRequest(apiUrl.matterDate.appliedList, params)
);

export const matterAppliedSlice = createSlice({
  name: 'matterApplied',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [matterAppliedInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchMatterAppliedList.fulfilled]: (state, { payload }) => {
      state.matterAppliedList = payload.item ?? [];
      state.searchCount = payload.total ?? 0;
    },
  },
});

export const matterAppliedActions = matterAppliedSlice.actions;
export default matterAppliedSlice.reducer;
