import { memo } from 'react';
import Checkbox from '../../../../../components/checkbox';
import CustomFormikInput from '../../../../../components/customInput/customFormikInput';
import { checkIsSp, handlerFormikFieldChange } from '../../../../../utils/fnUtil';
import './style.scss';

const InfoItem = memo(
  ({ formik, textInputName, checkboxInputName, placeholder, label }) => (
    <div className="info_item">
      <div className="tab_label">
        <label>{label}</label>
      </div>
      <div>
        <Checkbox
          label="スタッフ表示"
          checked={formik.values[checkboxInputName] === 1}
          onChange={e =>
            handlerFormikFieldChange(
              formik,
              checkboxInputName,
              e.target.checked ? 1 : 0
            )
          }
        />
        {textInputName === 'privateInfo' ? (
          <CustomFormikInput
            formik={formik}
            inputType={'textBox'}
            inputName={textInputName}
            placeholder={placeholder}
            style={{areaStyle: {marginTop: checkIsSp() ? '16px' : 0}}}
          />
        ) : (
          <p style={{margin: '16px 0 20px', whiteSpace: 'pre-line'}}>
            {formik.values[textInputName]}
          </p>
        )}
      </div>
    </div>
  )
);

export default InfoItem;
