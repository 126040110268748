import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import Button from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Yup from '../../../utils/yupUtil';
import {
  namingActions,
  namingFetch,
  namingUpdate,
} from '../../../redux/slice/naming';
import { globalActions } from '../../../redux/slice/global';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { handlerFormikSubmit } from '../../../utils/fnUtil';
import { useEffect, useState } from 'react';
import { checkIsSp } from '../../../utils/fnUtil';

const editInfos = [
  {
    inputType: 'input',
    label: '名称',
    labelRequired: true,
    inputName: 'naming',
    initialValue: '',
    placeholder: '名称を入力してください',
    style: {
      areaStyle: { width: checkIsSp() ? '100%' : '67%' },
      labelStyle: { width: '232px' },
    },
  },
  {
    inputType: 'input',
    label: '名称(カナ)',
    inputName: 'namingKana',
    initialValue: '',
    placeholder: '名称(カナ)を入力してください',
    style: {
      areaStyle: { width: checkIsSp() ? '100%' : '67%' },
      labelStyle: { width: '232px' },
    },
  },
  {
    inputType: 'select',
    label: '表示優先順',
    labelRequired: true,
    inputName: 'priority',
    initialValue: [...Array(100)].map((_, i) => i + 1),
    selectedSelectBox: '',
    selectBoxOptions: [...Array(100)].map((_, i) => i + 1),
    placeholder: '表示優先順を選択してください',
    style: {
      areaStyle: { width: checkIsSp() ? '100%' : '359px' },
      labelStyle: { width: '232px' },
    },
  },
  {
    inputType: 'textBox',
    label: '備考',
    inputName: 'note',
    initialValue: '',
    placeholder: '備考を入力してください',
    style: {
      areaStyle: { width: checkIsSp() ? '100%' : '87%' },
      labelStyle: { width: '232px' },
    },
  },
];

const buttonStyle = {
  updateButton: {
    width: '240px',
    height: '44px',
    border: '1px solid #0056D3',
    borderRadius: '6px',
    backgroundColor: '#0056D3',
    color: '#FFFFFF',
    fontSize: '14px',
    margin: '0 0',
    padding: '0 0',
    userSelect: 'none',
  },
  backButton: {
    width: '240px',
    height: '44px',
    borderRadius: '6px',
    backgroundColor: '#E9F2FC',
    color: '#486083',
    fontSize: '14px',
    margin: '0 0',
    padding: '0 0',
    userSelect: 'none',
  },
};

const NamingEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { namingList, namingCategoryList, searchParams } = useSelector(
    state => state.naming
  );

  const isEdit = location.pathname.slice(5).includes('edit');
  const [item, setItem] = useState(
    namingList?.item?.find(n => n.namingId === location?.state?.id)
  );
  const [viewId, setViewId] = useState(
    namingCategoryList?.filter(
      n => n?.namingId === item?.namingClassificationId
    )[0]
  );

  const tabErrorGroup = {
    common: ['naming', 'namingClassificationId', 'namingId', 'namingKana'],
  };
  const formik = useFormik({
    initialValues: {
      naming: item?.naming ?? '',
      namingClassificationId: item?.namingClassificationId ?? '',
      namingId: item?.namingId ?? '',
      namingKana: item?.namingKana ?? '',
      note: item?.note ?? '',
      priority: item?.priority,
      displayFlag: item?.displayFlag,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      naming: Yup.string().required().nullable().label('[名称情報]名称'),
      namingKana: Yup.string()
        .nullable()
        .kana('[名称情報]名称カナ')
        .label('[名称情報]名称カナ'),
      priority: Yup.string()
        .required()
        .nullable()
        .label('[名称情報]表示優先順'),
    }),
    onSubmit: values => {
      dispatch(namingUpdate(values))
        .unwrap()
        .then(() => {
          dispatch(
            globalActions.showSingleModal(`${isEdit ? '更新' : '登録'}しました`)
          );
          navigate(-1);
        });
    },
  });
  useEffect(() => {
    dispatch(namingActions.resetParam());

    if (location?.state?.id) {
      setItem(namingList?.item?.find(n => n.namingId === location?.state?.id));
      setViewId(
        namingCategoryList?.filter(
          n => n?.namingId === item?.namingClassificationId
        )[0]
      );
    }

    dispatch(namingFetch(searchParams));
  }, []);
  const { namingId } = formik.values;

  const NamingRegisterInfo = () => (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '20px',
      }}
    >
      <div className={'naming_edit--area'}>
        <div className={'naming_edit--label_area'}>
          <label>
            名称区分<span>*</span>
          </label>
        </div>
        <div className={'naming_edit--display_area'}>
          {`${viewId?.namingId} : ${viewId?.naming}`}
        </div>
      </div>
      <div className={'naming_edit--area'}>
        <div className={'naming_edit--label_area'}>
          <label>
            名称ID<span>*</span>
          </label>
        </div>
        <div className={'naming_edit--display_area'}>{namingId}</div>
      </div>

      {editInfos.map((info, idx) => (
        <CustomFormikInput {...info} formik={formik} key={info.inputName} />
      ))}
    </div>
  );

  const infos = {
    detail: {
      tabs: {
        common: {
          label: '名称情報',
          // inputs: [{}, {}],
          jsx: NamingRegisterInfo,
        },
      },
    },
  };

  return (
    <>
      <RegisterBase
        image={Icons.test.account}
        imageShape={'round'}
        infos={infos}
      />

      <div
        className={'bottom_btn_area'}
        style={{ height: '92px', justifyContent: 'center' }}
      >
        <div className={'staff_register--submit_buttons'}>
          <Button
            text={'戻る'}
            style={buttonStyle.backButton}
            onClick={() => {
              navigate(`/master/naming/list`);
            }}
          />
          {location?.state?.deletedFlag === 0 && (
            <Button
              text={'更新'}
              style={buttonStyle.updateButton}
              onClick={() => {
                handlerFormikSubmit(formik, tabErrorGroup);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default NamingEdit;
