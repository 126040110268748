import { memo, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Tabs } from 'antd';
import {
  handlerFormikFieldChange,
  handlerFormikSubmit,
  checkIsSp,
} from '../../../utils/fnUtil';
import { toInteger, transform } from 'lodash';
import { useLocation, useNavigate } from 'react-router';
import { globalActions } from '../../../redux/slice/global';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMatterReportDetail,
  fetchReportFormat,
  updateMatterReport,
} from '../../../redux/slice/matter';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import PerformanceInformation from './tabs/performanceInformation';
import ApplyFormatModal from '../../../components/modal/applyFormatModal';
import PerformanceTable from './tabs/performanceTable';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import ReportImage from './tabs/reportImage';
import StaffVoice from './tabs/staffVoice';
import GuestVoice from './tabs/guestVoice';
import GuestAttribute from './tabs/guestAttribute';
import FreeFrom from './tabs/freeForm';
import Yup from '../../../utils/yupUtil';
import './style.scss';

const MatterReport = memo(() => {
  const { state } = useLocation();

  const dispatch = useDispatch();

  const { matterReportDetail, matterFormatList, loading, purchaseMotiveList } =
    useSelector(state => state.matter);

  const { userId } = useSelector(state => state.account);

  const [applyFormatModalVisible, setApplyFormatModalVisible] = useState(false);

  const { activeTab } = useSelector(state => state.global);

  const navigate = useNavigate();

  const isSp = checkIsSp();

  const tabErrorGroup = {
    performanceInformation: ['info'],
    performanceTable: ['reportPage'],
    reportImage: ['folder'],
    guestVoice: ['guestVoice'],
    staffVoice: ['staffVoice'],
    guestAttribute: ['totalNumber'],
    freeForm: ['free'],
  };

  const formik = useFormik({
    initialValues: {
      matterNumber: '',
      applyFormat: false,
      formatMatterNumber: null,
      info: {
        reportMatterNameFlag: 1,
        staffMatterNameFlag: 1,
        matterName: '',
        reportEventAboutFlag: 1,
        staffEventAboutFlag: 1,
        eventAbout: '',
        reportEventPurposeFlag: 1,
        staffEventPurposeFlag: 1,
        eventPurpose: '',
        reportEventWeatherFlag: 1,
        reportVenueFlag: 1,
        staffVenueFlag: 1,
        venue: '',
        reportAdmissionTermFlag: 1,
        staffAdmissionTermFlag: 1,
        admissionTerm: '',
        reportStandbyFlag: 1,
        staffStandbyFlag: 1,
        standby: '',
        reportPresidentFlag: 1,
        staffPresidentFlag: 1,
        president: '',
        reportManagerLeaderFlag: 1,
        staffManagerLeaderFlag: 1,
        managerLeader: '',
        reportManagerFlag: 1,
        staffManagerFlag: 1,
        manager: '',
        reportStaffNumbersFlag: 1,
        staffStaffNumbersFlag: 1,
        staffNumbers: null,
        reportStaffWearFlag: 1,
        staffStaffWearFlag: 1,
        staffWear: '',
      },
      reportPage: [],
      folder: [],
      guestVoice: {
        reportTotalFlag: 1,
        staffTotalFlag: 1,
        voiceTotalNumber: null,
        reportInflowRouteFlag: 1,
        staffInflowRouteFlag: 1,
        dmRate: null,
        passingRate: null,
        snsRate: null,
        dailyUseRate: null,
        otherRate: null,
        reportMotiveFlag: 1,
        staffMotiveFlag: 1,
        reportContinueFlag: 1,
        staffContinueFlag: 1,
        firstRate: null,
        twiceRate: null,
        // reportMotiveFlag: 1,
        // staffMotiveFlag: 1,
        // motive: null,
        // reportInflowRouteFlag: 1,
        // staffInflowRouteFlag: 1,
        // inflowRouteFlag: 0,
        // reportContinueFlag: 1,
        // staffContinueFlag: 1,
        // continueFlag: 0,
        voiceInput: [],
      },
      staffVoice: [],
      guestAttribute: {
        reportTotalFlag: 1,
        staffTotalFlag: 1,
        totalNumber: null,
        reportGenderRatioFlag: 1,
        staffGenderRatioFlag: 1,
        manRate: null,
        womanRate: null,
        reportGuestFlag: 1,
        staffGuestFlag: 1,
        singleRate: null,
        multipleRate: null,
        withChildrenRate: null,
        reportAgeGroupFlag: 1,
        staffAgeGroupFlag: 1,
        underTeensRate: null,
        teensRate: null,
        twentiesRate: null,
        thirtiesRate: null,
        fortiesRate: null,
        fiftiesRate: null,
        sixtiesRate: null,
        overSeventiesRate: null,
      },
      free: [],
    },
    validationSchema: Yup.object({
      matterNumber: Yup.string().required(),
      info: Yup.object({
        matterName: Yup.string().nullable().label('[実績情報]案件名').max(100),
        eventPurpose: Yup.string()
          .nullable()
          .label('[実績情報]実施目的')
          .max(100),
        venue: Yup.string().nullable().label('[実績情報]会場').max(100),
        admissionTerm: Yup.string()
          .nullable()
          .label('[実績情報]入場条件')
          .max(100),
        standby: Yup.string().nullable().label('[実績情報]事前準備').max(100),
        president: Yup.string().nullable().label('[実績情報]主導者').max(100),
        managerLeader: Yup.string()
          .nullable()
          .label('[実績情報]運営統括')
          .max(100),
        manager: Yup.string().nullable().label('[実績情報]会場運営').max(100),
        staffWear: Yup.string()
          .nullable()
          .label('[実績情報]スタッフ服装')
          .max(100),
      }).nullable(),
      folder: Yup.object({
        folderName: Yup.string()
          .nullable()
          .label('[報告画像]フォルダ名')
          .max(100),
      }).nullable(),
      staffVoice: Yup.array()
        .of(
          Yup.object({
            inputTitle: Yup.string()
              .required()
              .label('[スタッフの声]項目名')
              .max(100),
          })
        )
        .nullable(),
      guestVoice: Yup.object({
        voiceInput: Yup.array().of(
          Yup.object({
            inputTitle: Yup.string()
              .required()
              .label('[お客様の声]項目名')
              .max(100),
          })
        ),
        totalNumber: Yup.string()
          .nullable()
          .integerString(`[お客様の声]:総人数`),
        staffInflowRouteTotal: Yup.number()
          .nullable()
          .test(
            'staffInflowRouteTotal',
            `[お客様の声]:流入経路:合計が100％ではありません。`,
            (_, context) => {
              const { dmRate, passingRate, snsRate, dailyUseRate, otherRate } =
                context.from[0].value;
              const totalRate =
                toInteger(dmRate) +
                toInteger(passingRate) +
                toInteger(snsRate) +
                toInteger(dailyUseRate) +
                toInteger(otherRate);
              return (
                totalRate === 100 ||
                [dmRate, passingRate, snsRate, dailyUseRate, otherRate].every(
                  rate => rate === null
                )
              );
            }
          ),
        staffMotiveRateTotal: Yup.number()
          .nullable()
          .test(
            'staffInflowRouteTotal',
            `[お客様の声]:購入動機:合計が100％ではありません。`,
            (_, context) => {
              const totalRate = purchaseMotiveList?.reduce((sum, obj) => {
                const key = `staffMotiveId_${obj.namingId}`;
                const rate = context.from[0].value?.[key] ?? 0;
                return sum + rate;
              }, 0);
              const allNull = purchaseMotiveList?.every(obj => {
                const key = `staffMotiveId_${obj.namingId}`;
                return context.from[0].value?.[key] === null;
              });
              const allUndefined = purchaseMotiveList?.every(obj => {
                const key = `staffMotiveId_${obj.namingId}`;
                return context.from[0].value?.[key] === undefined;
              });
              return allUndefined ? true : allNull ? true : totalRate === 100;
            }
          ),
        staffContinueRouteTotal: Yup.number()
          .nullable()
          .test(
            'staffInflowRouteTotal',
            `[お客様の声]:継続購入可否:合計が100％ではありません。`,
            (_, context) => {
              const { firstRate, twiceRate } = context.from[0].value;
              const totalRate = toInteger(firstRate) + toInteger(twiceRate);
              return (
                totalRate === 100 ||
                [firstRate, twiceRate].every(
                  rate => rate === null
                )
              );
            }
          ),
      }).nullable(),
      guestAttribute: Yup.object({
        totalNumber: Yup.string()
          .nullable()
          .integerString(`[お客様の属性]:総人数`),
        manWomanRateTotal: Yup.number()
          .nullable()
          .test(
            'manWomanRateTotal',
            `[お客様の属性]:男女比:合計で100％を超えてます。`,
            (_, context) => {
              const { manRate, womanRate } = context.from[0].value;
              return toInteger(manRate) + toInteger(womanRate) <= 100;
            }
          ),
        segmentRateTotal: Yup.number()
          .nullable()
          .test(
            'segmentRateTotal',
            `[お客様の属性]:客層比:合計で100％を超えてます。`,
            (_, context) => {
              const { singleRate, multipleRate, withChildrenRate } =
                context.from[0].value;
              return (
                [singleRate, multipleRate, withChildrenRate]
                  .map(toInteger)
                  .reduce((pre, next) => pre + next, 0) <= 100
              );
            }
          ),
        ageRateTotal: Yup.number()
          .nullable()
          .test(
            'ageRateTotal',
            `[お客様の属性]:年代別客層:合計で100％を超えてます。`,
            (_, context) => {
              const {
                underTeensRate,
                teensRate,
                twentiesRate,
                thirtiesRate,
                fortiesRate,
                fiftiesRate,
                sixtiesRate,
                overSeventiesRate,
              } = context.from[0].value;
              return (
                [
                  underTeensRate,
                  teensRate,
                  twentiesRate,
                  thirtiesRate,
                  fortiesRate,
                  fiftiesRate,
                  sixtiesRate,
                  overSeventiesRate,
                ]
                  .map(toInteger)
                  .reduce((pre, next) => pre + next, 0) <= 100
              );
            }
          ),
      }),
      reportPage: Yup.array().of(
        Yup.object({
          pageName: Yup.string().nullable().label('[実績表]ページ名').max(30),
          rowTotal: Yup.number()
            .nullable()
            .test(
              'tableTotal',
              `[実績表]行数合計:1ページにつき最大20行です。`,
              (_, context) =>
                context.parent.layout
                  .flatMap(l => l.table)
                  .map(t => toInteger(t.tableRows))
                  .reduce((pre, next) => pre + next, 0) <= 20
            ),
          layout: Yup.array()
            .arrayRequired('[実績表]レイアウト')
            .of(
              Yup.object({
                table: Yup.array().of(
                  Yup.object({
                    tableTitle: Yup.string()
                      .nullable()
                      .label('[実績表]テーブルタイトル')
                      .max(30),
                    tableRows: Yup.string()
                      .nullable()
                      .required()
                      .label(`[実績表]列数`),
                    columnTotal: Yup.string()
                      .nullable()
                      .integerString(`[実績表]合計`),
                    ...transform([1, 2, 3], (result, i) => {
                      [...Array(20)].forEach(
                        (_, j) =>
                          (result[`column${i}Row${j + 1}`] = Yup.string()
                            .nullable()
                            .integerString(`[実績表]行${j + 1}列${i}`))
                      );
                      result[`column${i}Total`] = Yup.string()
                        .nullable()
                        .integerString(`[実績表]列${i}合計`);

                      result[`column${i}Title`] = Yup.string()
                        .nullable()
                        .label(`[実績表]コラム${i}`)
                        .max(100);
                    }),
                    ...transform(
                      [...Array(20)].map((_, i) => i + 1),
                      (result, i) => {
                        result[`row${i}`] = Yup.string()
                          .nullable()
                          .label(`[実績表]行${i}`)
                          .max(50);
                      }
                    ),
                  })
                ),
              })
            ),
        })
      ),
      free: Yup.array().of(
        Yup.object({
          layoutPattern: Yup.string()
            .nullable()
            .required()
            .label(`[フリーフォーム]レイアウト`)
            .test(
              'inputRequired',
              `[フリーフォーム]:フォーム入力内容を選択してください`,
              (_, context) => {
                const value = context.from[0].value;

                return [
                  ...Array(value.layoutPattern === 3 ? 4 : value.layoutPattern),
                ].every(
                  (_, i) =>
                    value[`form${i + 1}Text`] !== null ||
                    value[`form${i + 1}Image`] !== null
                );
              }
            ),
        })
      ),
    }),
    onSubmit: values => {
      const { reportPage, folder, staffVoice, guestVoice, free } = values;

      let motive = '';
      Object.keys(guestVoice)
        .filter(key => key.startsWith('staffMotiveId_'))
        .forEach(key => {
          const id = key.match(/\d+$/)[0];
          const value = guestVoice[key];
          motive += `${id}:${value ?? null},`;
        });
      motive = motive.slice(0, -1);

      const inflowRoute = () => {
        const rates = [
          { rate: guestVoice?.dmRate, prefix: '0' },
          { rate: guestVoice?.passingRate, prefix: '1' },
          { rate: guestVoice?.snsRate, prefix: '2' },
          { rate: guestVoice?.dailyUseRate, prefix: '3' },
          { rate: guestVoice?.otherRate, prefix: '4' },
        ];

        const inflowRoute = rates
          .filter(({ rate }) => rate !== undefined)
          .map(({ rate, prefix }) => `${prefix}:${rate ?? null}`)
          .join(',');

        return !inflowRoute ? null : inflowRoute;
      };

      const repeating = () => {
        const rates = [
          { rate: guestVoice?.firstRate, prefix: '0' },
          { rate: guestVoice?.twiceRate, prefix: '1' },
        ];

        const repeating = rates
          .filter(({ rate }) => rate !== undefined)
          .map(({ rate, prefix }) => `${prefix}:${rate ?? null}`)
          .join(',');

        return !repeating ? null : repeating;
      };

      dispatch(
        updateMatterReport({
          ...values,
          reportPage: reportPage.map((page, i) => ({
            ...page,
            pageNumber: i + 1,
            layout: page.layout.map((layout, j) => ({
              ...layout,
              layoutNumber: j + 1,
              pageNumber: i + 1,
              table: layout.table.map((table, k) => ({
                ...table,
                pageNumber: i + 1,
                layoutNumber: j + 1,
                tableNumber: k + 1,
              })),
            })),
          })),
          folder: folder.map((f, i) => ({
            ...f,
            folderNumber: i + 1,
          })),
          staffVoice: staffVoice.map((s, i) => ({
            ...s,
            inputNumber: i + 1,
          })),
          guestVoice: {
            ...guestVoice,
            inflowRoute: inflowRoute(),
            motive: !motive ? null : motive,
            repeating: repeating(),
            voiceInput: guestVoice.voiceInput.map((v, i) => ({
              ...v,
              inputNumber: i + 1,
            })),
          },
          free: free.map((f, i) => ({
            ...f,
            pageNumber: i + 1,
          })),
        })
      )
        .unwrap()
        .then(() => navigate(-1));
    },
  });

  const tabs = [
    {
      key: 'performanceInformation',
      label: '実績情報',
      children: <PerformanceInformation formik={formik} />,
    },
    {
      key: 'performanceTable',
      label: '実績表',
      children: <PerformanceTable formik={formik} />,
    },
    {
      key: 'reportImage',
      label: '報告画像',
      children: <ReportImage formik={formik} />,
    },
    {
      key: 'staffVoice',
      label: 'スタッフの声',
      children: <StaffVoice formik={formik} />,
    },
    {
      key: 'guestVoice',
      label: 'お客様の声',
      children: <GuestVoice formik={formik} />,
    },
    {
      key: 'guestAttribute',
      label: 'お客様の属性',
      children: <GuestAttribute formik={formik} />,
    },
    {
      key: 'freeForm',
      label: 'フリーフォーム',
      children: <FreeFrom formik={formik} />,
    },
  ];

  const {
    values: { formatMatterNumber, applyFormat },
    setValues,
  } = formik;

  useEffect(() => {
    state.id &&
      dispatch(
        fetchMatterReportDetail({
          matterNumber: state.id,
          userId,
        })
      );
  }, [state.id, userId]);

  useEffect(() => {
    formatMatterNumber &&
      applyFormat &&
      dispatch(
        fetchReportFormat({
          matterNumber: formatMatterNumber,
        })
      );
  }, [formatMatterNumber, applyFormat]);

  useEffect(() => {
    matterReportDetail &&
      setValues({
        ...formik.values,
        ...matterReportDetail,
      });
  }, [matterReportDetail]);

  const {
    matterNumber,
    quoteNumber,
    matterName,
    clientName,
    venueName,
    matterManagerUserName,
    clientManagerUserName,
    salesManagerUserName,
  } = matterReportDetail ?? {};

  return (
    <div className="report_page_container">
      {!loading && (
        <div className={'page_base--container report_page'}>
          <span className={'page_base--container_notice'}>*は必須項目です</span>
          <div className="report_page--title display-linebreak">
            <div className="report_page--title-left">
              <span>{`案件No.  ${matterNumber ?? ''}\t\t見積書No.   ${
                quoteNumber ?? ''
              }`}</span>
              <span>{matterName}</span>
            </div>
            <div className="report_page--title-right display-linebreak">
              <div>
                <span>{`取引先\t ${clientName ?? ''}`}</span>
                <span>{`開催地\t ${venueName ?? ''}`}</span>
              </div>
              <div>
                <span>{`案件担当者\t ${matterManagerUserName ?? ''}`}</span>
                <span>{`営業担当者\t ${salesManagerUserName ?? ''}`}</span>
              </div>
              <div>
                <span>{`取引先担当者\t ${clientManagerUserName ?? ''}`}</span>
                <span className="placeholder">{` `}</span>
              </div>
              <div className="report_page--title-end">
                <div>
                  <span>別案件から</span>
                  <span>フォーマットを複製</span>
                </div>
                <div>
                  <CustomFormikInput
                    formik={formik}
                    onChange={v => {
                      handlerFormikFieldChange(
                        formik,
                        'formatMatterNumber',
                        v ?? null
                      );
                      handlerFormikFieldChange(formik, 'applyFormat', false);
                      v && setApplyFormatModalVisible(true);
                    }}
                    usePopContainer={false}
                    inputName="formatMatterNumber"
                    placeholder="案件を選択してください"
                    inputType="select"
                    initialValue={matterFormatList.map(
                      matter => matter.matterNumber
                    )}
                    selectBoxOptions={matterFormatList.map(
                      matter => `${matter.matterNumber}:${matter.matterName}`
                    )}
                    style={{
                      areaStyle: {
                        width: 292,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="page_base--tabs">
            <Tabs
              activeKey={activeTab ? activeTab : tabs[0].key}
              onChange={tab => dispatch(globalActions.changeActiveTab(tab))}
            >
              {tabs.map(tab => (
                <Tabs.TabPane tab={tab.label} key={tab.key}>
                  {tab.children}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      )}
      <div className={'bottom_btn_area'} style={isSp ? { height: '88px' } : {}}>
        <div className={'bottom_btn_area--common_actions'}>
          <Button
            text={'戻る'}
            style={Options.buttonStyles.back}
            onClick={() => navigate(-1)}
          />
          {state?.status !== "報告書提出済" ?
            <Button
              text={'更新'}
              style={Options.buttonStyles.submit}
              onClick={() => handlerFormikSubmit(formik, tabErrorGroup)}
            /> : <></>
          }
        </div>
      </div>
      <ApplyFormatModal
        modalVisible={applyFormatModalVisible}
        onCancel={() => setApplyFormatModalVisible(false)}
        onApplyFormat={() => {
          handlerFormikFieldChange(formik, 'applyFormat', true);
          setApplyFormatModalVisible(false);
        }}
      />
    </div>
  );
});

export default MatterReport;
