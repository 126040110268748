import { memo } from 'react';
import { deleteArrayItem, checkIsSp } from '../../../../utils/fnUtil';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import AddLineButton from '../../../../components/addLineButton';
import DeleteIcon from '../../../../components/deleteIcon';
import moment from 'moment';
import './style.scss';

const MatterDate = memo(({ formik }) => {
  const isSp = checkIsSp();

  const {
    values: { matterDate },
    setFieldValue,
  } = formik;

  return (
    <div className="matter_date">
      {matterDate.map((_, i) => (
        <div
          className="matter_date--container"
          key={i}
          style={
            isSp
              ? {
                  width: '100%',
                  padding: '25px 15px',
                  justifyContent: 'center',
                }
              : {}
          }
        >
          <DeleteIcon
            title="削除"
            onClick={() =>
              setFieldValue('matterDate', deleteArrayItem(matterDate, i))
            }
            style={isSp ? { width: '35px', height: '35px' } : {}}
          />
          <CustomFormikInput
            formik={formik}
            label="案件実施日"
            inputType="inputDate"
            relativeInputName={['matterDate']}
            inputName={`matterDate.[${i}].matterDate`}
            otherErrorInputName={['matterDateDulpicate']}
            placeholder="YYYY/MM/DD"
            disabledDate={moment().format('YYYY/MM/DD')}
            style={
              isSp
                ? {
                    labelStyle: {
                      fontSize: '14px',
                    },
                  }
                : {}
            }
          />
          <CustomFormikInput
            formik={formik}
            inputType="inputTime"
            relativeInputName={['matterDate']}
            inputName={`matterDate.[${i}].matterStartDatetime`}
            otherErrorInputName={[`matterDate.[${i}].matterDateRange`]}
            placeholder="00:00"
            style={
              isSp
                ? {
                    areaStyle: {
                      maxWidth: '43%',
                      marginTop: '20px',
                    },
                  }
                : {}
            }
          />
          <CustomFormikInput
            formik={formik}
            inputType="inputTime"
            relativeInputName={['matterDate']}
            inputName={`matterDate.[${i}].matterEndDatetime`}
            otherErrorInputName={[`matterDate.[${i}].matterDateRange`]}
            placeholder="00:00"
            label="~"
            style={
              isSp
                ? {
                    areaStyle: {
                      maxWidth: '53%',
                      flexFlow: 'row',
                      alignItems: 'end',
                    },
                    labelStyle: {
                      width: '20%',
                      textAlign: 'center',
                      marginBottom: '12px',
                    },
                  }
                : {}
            }
          />
        </div>
      ))}
      <AddLineButton
        title={isSp ? '' : '行追加'}
        onClick={() => setFieldValue('matterDate', [...matterDate, {}])}
      />
    </div>
  );
});
export default MatterDate;
