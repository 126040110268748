import './style.scss';
import Button from '../../button';
import Options from '../../../constants/options';

const SpConfirmModal = ({
  visible,
  mainText,
  subText,
  backBtnText,
  submitBtnText,
  handleCloseBtn,
  handleSubmitBtn,
}) => {
  return (
    <>
      {visible ? (
        <>
          <div className={'spConfirmModal--overlay'}></div>
          <div className={'spConfirmModal--base'}>
            <p>{mainText}</p>
            {subText ? <span className={'subText'}>{subText}</span> : <></>}

            <div className={'spConfirmModal--buttons_wrap'}>
              <Button
                style={Options.buttonStyles.back}
                onClick={handleCloseBtn}
                text={backBtnText}
              />

              <Button
                style={Options.buttonStyles.submit}
                onClick={handleSubmitBtn}
                text={submitBtnText}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SpConfirmModal;
