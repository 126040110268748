import * as yup from 'yup';

// const passWordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\\\]{}|'])[a-zA-Z0-9!@#$%^&*()_+\-=\[\\\]{}|']{8,}$/;
const passWordRules = /^(?=.*[a-z])(?=.*[0-9])([a-zA-Z0-9]{6,})$/;
const emailRules = /^[a-z0-9\-._+]{1,64}@+[a-z0-9@._-]*$/;

export const loginFormSchema = yup.object().shape({
  corporate_id: yup.string().required('必須項目です'),
  mail_address: yup.string()
    .matches(emailRules, { message: 'メールアドレスの形式が異なります' })
    .email('メールアドレスの形式が異なります')
    .required("必須項目です"),
  pass: yup.string().required('必須項目です'),
  // account_type: yup.string().oneOf(['usuaslly', 'currsssent', 'savisssngs'], '選択してください').required('必須項目です'),
  // selectBox: yup.string().oneOf(['dog', 'cat', 'bard'], '選択してください').required('必須項目です'),
});

export const forgetPassFormSchema = yup.object().shape({
  corporate_id: yup.string().required('必須項目です'),
  mail_address: yup.string()
    .matches(emailRules, { message: 'メールアドレスの形式が異なります' })
    .email('メールアドレスの形式が異なります')
    .required("必須項目です"),
});

export const changePassFormSchema = yup.object().shape({
  new_pass: yup.string().required('必須項目です').matches(passWordRules, { message: '6桁以上英数混在で入力してください' }),
  confirm_pass: yup.string().oneOf([yup.ref("new_pass"), null], "新しいパスワードと一致しません").required("必須項目です"),
});

export const changePassModalFormSchema = yup.object().shape({
  now_pass: yup.string().required('必須項目です'),
  new_pass: yup.string().required('必須項目です').matches(passWordRules, { message: '6桁以上英数混在で入力してください' }),
  confirm_pass: yup.string().oneOf([yup.ref("new_pass"), null], "新しいパスワードと一致しません").required("必須項目です"),
});
