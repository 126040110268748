import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { omitBy, isNil } from 'lodash';
import moment from 'moment';
import Icons from '../../../../constants/icons';
import Button from '../../../../components/button';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import ReportModal from '../../../../components/modal/reportModal';
import { apiUrl } from '../../../../api/apiUrl';
import { checkIsSp } from '../../../../utils/fnUtil';
import { useEffect } from 'react';

const AttendanceReport = ({
  formik,
  onUpdate,
  reportModalVisible,
  setReportModalVisible,
}) => {
  const { fullName } = useSelector(state => state.account);

  const { matterDetail, assignDetail, assignStaffDetail } = useSelector(
    state => state.staffMatter
  );

  const { shift } = assignDetail ?? {};

  const arriveDatetime =
    assignStaffDetail?.arriveStationDatetime &&
    moment(assignStaffDetail.arriveStationDatetime);

  const attendanceReportInputLabels = [
    {
      item: '週確認',
      flag: 'staffWeekMethodFlag',
      dateTime: 'staffWeekDatetime',
      planDateTimeItem: 'staffWeekPlanDatetime',
    },
    {
      item: '前日報告',
      flag: 'staffPreviousDayMethodFlag',
      dateTime: 'staffPreviousDayDatetime',
      planDateTimeItem: 'staffPreviousDayPlanDatetime',
    },
    {
      item: '起床報告',
      flag: 'staffGettingUpMethodFlag',
      dateTime: 'staffGettingUpDatetime',
      planDateTimeItem: 'staffGettingUpPlanDatetime',
      formatTime: true,
    },
    {
      item: '出発報告',
      flag: 'staffDepartureMethodFlag',
      dateTime: 'staffDepartureDatetime',
      planDateTimeItem: 'staffDeparturePlanDatetime',
      formatTime: true,
    },
    {
      item: '到着報告',
      confirmItem: '当日到着報告確認',
      flag: 'staffArriveMethodFlag',
      dateTime: 'staffArriveDatetime',
      planDateTimeItem: 'staffArrivePlanDatetime',
      formatTime: true,
    },
    {
      item: '終業報告',
      confirmItem: '当日終業報告確認',
      flag: 'staffEndMethodFlag',
      dateTime: 'staffEndDatetime',
      planDateTimeItem: 'staffEndPlanDatetime',
      formatTime: true,
    },
  ];

  const {
    values: {
      staffArriveTime,
      staffArriveDatetime,
      staffEndTime,
      staffEndDatetime,
    },
    setFieldValue,
  } = formik;

  const btnController = (label, custom) => {
    if (!custom && formik?.values[label.dateTime.replace('Datetime', 'Time')]) {
      return true;
    } else if (assignStaffDetail?.staffGettingUpMethodFlag !== 2 && assignStaffDetail?.staffDepartureMethodFlag !== 2) {
      return !(formik?.values?.staffGettingUpPlanDatetime && formik?.values?.staffDeparturePlanDatetime)
    } else if (assignStaffDetail?.staffGettingUpMethodFlag === 2 && assignStaffDetail?.staffDepartureMethodFlag !== 2) {
      return !formik?.values?.staffDeparturePlanDatetime
    } else if (assignStaffDetail?.staffGettingUpMethodFlag !== 2 && assignStaffDetail?.staffDepartureMethodFlag === 2) {
      return !formik?.values?.staffGettingUpPlanDatetime
    }
  }

  const noteController = () => {
    if (!formik?.values?.staffPreviousDayDatetime) {
      if (assignStaffDetail?.staffGettingUpMethodFlag !== 2 && assignStaffDetail?.staffDepartureMethodFlag !== 2) {
        return !(formik?.values?.staffGettingUpPlanDatetimeInput && formik?.values?.staffDeparturePlanDatetimeInput)
      } else if (assignStaffDetail?.staffGettingUpMethodFlag === 2 && assignStaffDetail?.staffDepartureMethodFlag !== 2) {
        return !(formik?.values?.staffDeparturePlanDatetimeInput)
      } else if (assignStaffDetail?.staffGettingUpMethodFlag !== 2 && assignStaffDetail?.staffDepartureMethodFlag === 2) {
        return !(formik?.values?.staffGettingUpPlanDatetimeInput)
      } else if (assignStaffDetail?.staffGettingUpMethodFlag === 2 && assignStaffDetail?.staffDepartureMethodFlag === 2) {
        return false;
      }
    }
  }

  useUpdateEffect(() => {
    setFieldValue(
      'punchInDatetime',
      `${(staffArriveDatetime ? moment(staffArriveDatetime) : moment()).format(
        'YYYY/M/D'
      )} ${staffArriveTime}:00`
    );
  }, [staffArriveTime, staffArriveDatetime]);

  useUpdateEffect(() => {
    setFieldValue(
      'punchOutDatetime',
      `${(staffEndDatetime ? moment(staffEndDatetime) : moment()).format(
        'YYYY/M/D'
      )} ${staffEndTime}:00`
    );
  }, [staffEndTime, staffEndDatetime]);

  useEffect(() => {
    formik.setFieldValue('staffGettingUpPlanDatetimeInput',
      formik?.values?.staffGettingUpPlanDatetime ? moment(formik?.values?.staffGettingUpPlanDatetime).format('HH:mm') : ''
    );
    formik.setFieldValue('staffDeparturePlanDatetimeInput',
      formik?.values?.staffDeparturePlanDatetime ? moment(formik?.values?.staffDeparturePlanDatetime).format('HH:mm') : ''
    );
  }, []);

  useEffect(() => {
    if (!formik?.values?.staffPreviousDayDatetime) {
      if (assignStaffDetail?.staffGettingUpMethodFlag !== 2 && assignStaffDetail?.staffDepartureMethodFlag !== 2) {
        return formik?.values?.staffGettingUpPlanDatetimeInput && formik?.values?.staffDeparturePlanDatetimeInput ? true : formik?.setFieldValue('staffPreviousDayTime', '');
      } else if (assignStaffDetail?.staffGettingUpMethodFlag === 2 && assignStaffDetail?.staffDepartureMethodFlag !== 2) {
        return formik?.values?.staffDeparturePlanDatetimeInput ? true :  formik?.setFieldValue('staffPreviousDayTime', '');
      } else if (assignStaffDetail?.staffGettingUpMethodFlag !== 2 && assignStaffDetail?.staffDepartureMethodFlag === 2) {
        return formik?.values?.staffGettingUpPlanDatetimeInput ? true :  formik?.setFieldValue('staffPreviousDayTime', '');
      }
    }
  }, [formik?.values?.staffGettingUpPlanDatetimeInput, formik?.values?.staffDeparturePlanDatetimeInput]);
  
  return (
    <div className={'recruit_matter_detail--attendance_report_area'}>
      <div className={'recruit_matter_detail--attendance_report_infos_area'}>
        <div className={'recruit_matter_detail--attendance_report_infos_upper'}>
          {[
            {
              label: '開催地最寄駅',
              value: assignStaffDetail?.venueNearestStation,
            },
            {
              label: '集合場所',
              value: assignStaffDetail?.meetingPlace,
            },
            {
              label: '集合時間',
              value: shift?.gatheringDatetime,
            },
          ].map(l => (
            <div
              key={l.label}
              className={
                'recruit_matter_detail--attendance_report_infos_upper_item'
              }
            >
              <label>{l.label}</label>
              <span>{l.value}</span>
            </div>
          ))}
        </div>

        <div className={'recruit_matter_detail--attendance_report_schedule'}>
          <span>{`${fullName}さんの出勤スケジュール`}</span>
        </div>
        <div
          className={
            'recruit_matter_detail--attendance_report_infos_upper_info_item'
          }
          style={{ marginTop: checkIsSp() ? 0 : '8px' }}
        >
          <label>出発駅</label>
          <span>{assignStaffDetail?.departureStation}</span>
          <div
            className={
              'recruit_matter_detail--attendance_report_infos_upper_info_link'
            }
          >
            <a
              href={`${apiUrl.norikaeApi}?${new URLSearchParams(
                omitBy(
                  {
                    eki1: assignStaffDetail?.departureStation,
                    eki2: assignStaffDetail?.venueNearestStation,
                    Cway: 1,
                    Cfp: 1,
                    Czu: 2,
                    S: '検索',
                    Csg: 1,
                    ...(arriveDatetime && {
                      Dyy: arriveDatetime.year(),
                      Dmm: arriveDatetime.format('M'),
                      Ddd: arriveDatetime.format('D'),
                      Dhh: arriveDatetime.format('H'),
                      Dmn1: arriveDatetime.format('mm')[0],
                      Dmn2: arriveDatetime.format('mm')[1],
                    }),
                  },
                  isNil
                )
              )}`}
              target="_blank"
            >
              乗り換え案内
            </a>
            <img
              className={'recruit_matter_detail--link_icon'}
              src={Icons.icon.linkGr}
            />
          </div>
        </div>

        {/*<div*/}
        {/*  className={*/}
        {/*    'recruit_matter_detail--attendance_report_infos_upper_info_item'*/}
        {/*  }*/}
        {/*  style={{ marginTop: checkIsSp() ? 0 : '8px' }}*/}
        {/*>*/}
        {/*  <label>自宅出発予定時刻</label>*/}
        {/*  <span>*/}
        {/*    {assignStaffDetail?.staffDeparturePlanDatetime &&*/}
        {/*      moment(assignStaffDetail.staffDeparturePlanDatetime).format(*/}
        {/*        'HH:mm'*/}
        {/*      )}*/}
        {/*  </span>*/}
        {/*</div>*/}

        {(assignStaffDetail?.staffGettingUpMethodFlag !== 2 || assignStaffDetail?.staffDepartureMethodFlag !== 2) &&
          <div className={'recruit_matter_detail--staff_report_input_area'}>
            {assignStaffDetail?.staffGettingUpMethodFlag !== 2 &&
              <CustomFormikInput
                formik={formik}
                label={'起床予定時刻'}
                inputType="inputTime"
                placeholder="00:00"
                inputName={'staffGettingUpPlanDatetimeInput'}
                style={
                  {
                    inputStyle: {
                      width: '115px',
                    }
                  }
                }
                disabled={formik?.values?.staffPreviousDayDatetime || formik?.values?.managerPreviousDayDatetime}
              />
            }
            {assignStaffDetail?.staffDepartureMethodFlag !== 2 &&
              <CustomFormikInput
                formik={formik}
                label={'出発予定時刻'}
                inputType="inputTime"
                key={'staffDeparturePlansDatetime'}
                placeholder="00:00"
                inputName={'staffDeparturePlanDatetimeInput'}
                style={
                  {
                    inputStyle: {
                      width: '115px',
                    }
                  }
                }
                disabled={formik?.values?.staffPreviousDayDatetime || formik?.values?.managerPreviousDayDatetime}
              />
            }
          </div>
        }
      </div>
      <div className={'recruit_matter_detail--attendance_report_input_area'}>
        {attendanceReportInputLabels
          .filter(
            label =>
              (assignStaffDetail?.[label.planDateTimeItem] &&
                assignStaffDetail?.[label.flag] !== 2) ||
              label.item === '終業報告'
          )
          .map(label => (
            <div
              className={'recruit_matter_detail--attendance_report_input'}
              key={label.item}
              style={
                label.item === '終業報告'
                  ? { flexWrap: 'wrap', height: 'auto', gap: '8px 24px' }
                  : {}
              }
            >
              <div
                className={
                  'recruit_matter_detail--attendance_report_input_label'
                }
                style={{height: (label.item === '起床報告' || label.item === '出発報告') && !checkIsSp() ? '44px' : ''}}
              >
                <label
                  style={{
                    fontSize: checkIsSp() ? '12px' : '14px',
                    lineHeight: !checkIsSp() && (label.item === '起床報告' || label.item === '出発報告') ? '44px' : checkIsSp() && (label.item === '起床報告' || label.item === '出発報告') ? '24px' : ''
                  }}
                >
                  {assignStaffDetail?.[label.planDateTimeItem] &&
                  assignStaffDetail?.[label.flag] !== 2
                    ? label.item
                    : ''}
                </label>
                <span>
                  {assignStaffDetail?.[label.planDateTimeItem] &&
                  assignStaffDetail?.[label.flag] !== 2 && label.item !== '起床報告' && label.item !== '出発報告'
                    ? `予定日時:${moment(
                        assignStaffDetail?.[label.planDateTimeItem]
                      ).format(label.formatTime ? 'HH:mm' : 'YYYY/M/D HH:mm')}`
                    : ''}
                </span>
              </div>
              {assignStaffDetail?.[label.planDateTimeItem] &&
                assignStaffDetail?.[label.flag] === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: checkIsSp() ? 'flex-start' : 'center',
                      gap: checkIsSp() ? '16px' : '8px',
                    }}
                    className={'recruit_matter_detail--attendance_report_input_field'}
                  >
                    {checkIsSp() && label.item === '前日報告' && noteController() ?
                      <></> :
                      <CustomFormikInput
                        formik={formik}
                        inputType="inputTime"
                        placeholder="00:00"
                        disabled={true}
                        inputName={label.dateTime.replace('Datetime', 'Time')}
                        style={
                          checkIsSp()
                            ? {
                              areaStyle: {
                                width: 'unset',
                              },
                            }
                            : {}
                        }
                      />
                    }

                    {label.item === '前日報告' && noteController() ?
                      <span className={'recruit_matter_detail--alert_message'}>起床・出発時刻を入力してください</span> : <></>
                    }

                    {label.item === '前日報告' && !noteController() &&
                      <Button
                        // icon={Icons.icon.penGr}
                        text={label.item}
                        style={{
                          backgroundColor: 'white',
                          color: '#242424',
                          width: checkIsSp() ? '100%' : '144px',
                          height: checkIsSp() ? '40px' : '44px',
                          border: '1px solid #242424',
                          margin: checkIsSp() ? '0' : '20px 10px',
                        }}
                        onClick={() => {
                          formik.setFieldValue(
                            label.dateTime.replace('Datetime', 'Time'),
                            `${new Date()
                              .getHours()
                              .toString()
                              .padStart(2, '0')}:${new Date()
                              .getMinutes()
                              .toString()
                              .padStart(2, '0')}`
                          );
                        }}
                        disabled={
                          formik?.values[
                            label.dateTime.replace('Datetime', 'Time')
                            ]
                        }
                      />
                    }

                    {label.item !== '前日報告' &&
                      <Button
                        // icon={Icons.icon.penGr}
                        text={label.item}
                        style={{
                          backgroundColor: 'white',
                          color: '#242424',
                          width: checkIsSp() ? '100%' : '144px',
                          height: checkIsSp() ? '40px' : '44px',
                          border: '1px solid #242424',
                          margin: checkIsSp() ? '0' : '20px 10px',
                        }}
                        onClick={() => {
                          formik.setFieldValue(
                            label.dateTime.replace('Datetime', 'Time'),
                            `${new Date()
                              .getHours()
                              .toString()
                              .padStart(2, '0')}:${new Date()
                              .getMinutes()
                              .toString()
                              .padStart(2, '0')}`
                          );
                        }}
                        disabled={
                          formik?.values[
                            label.dateTime.replace('Datetime', 'Time')
                            ]
                        }
                      />
                    }

                  </div>
                )}
              {assignStaffDetail?.[label.planDateTimeItem] &&
                assignStaffDetail?.[label.flag] === 1 && (
                  <>
                    {/*{label.item === '前日報告' && noteController() ?*/}
                    {/*  <span className={'recruit_matter_detail--alert_message'}>起床・出発時刻を入力してください</span> : <></>*/}
                    {/*}*/}
                    {label.item === '前日報告' &&
                      <a href={`tel:${assignStaffDetail?.managerTel ?? ''}`}>
                        <Button
                          icon={Icons.icon.tel}
                          text={`${label.item}の電話をかける`}
                          className="report-btn"
                          style={{ height: checkIsSp() ? '40px' : '44px' }}
                          // disabled={
                          //   label.item === '前日報告' ? btnController(label, true) : ''
                          // }
                        />
                      </a>
                    }

                    {label.item !== '前日報告' &&
                      <a href={`tel:${assignStaffDetail?.managerTel ?? ''}`}>
                        <Button
                          icon={Icons.icon.tel}
                          text={`${label.item}の電話をかける`}
                          className="report-btn"
                          style={{ height: checkIsSp() ? '40px' : '44px' }}
                          disabled={
                            label.item === '前日報告' ? btnController(label, true) : ''
                          }
                        />
                      </a>
                    }
                  </>

                )}
              {assignStaffDetail?.[label.dateTime] && (
                <span
                  className={'recruit_matter_detail--report_text'}
                  style={{ fontSize: checkIsSp() ? '12px' : '14px' }}
                >
                  {`報告済み ${moment(assignStaffDetail[label.dateTime]).format(
                    label.formatTime ? 'HH:mm' : 'YYYY/M/D HH:mm'
                  )}`}
                </span>
              )}
              {label.item === '終業報告' && (
                <Button
                  icon={Icons.icon.penWh}
                  text="終業報告を入力する"
                  className="report-btn end-report-btn"
                  onClick={() => setReportModalVisible(true)}
                  style={{
                    marginTop: checkIsSp() ? '19px' : '',
                    height: checkIsSp() ? '40px' : '44px',
                    width: checkIsSp() ? '100%' : '',
                  }}
                />
              )}
            </div>
          ))}
      </div>

      <ReportModal
        formik={formik}
        modalVisible={reportModalVisible}
        onUpdate={onUpdate}
        onCancel={() => setReportModalVisible(false)}
      />
    </div>
  );
};

export default AttendanceReport;
