import { useSelector } from 'react-redux';
import Options from '../../../../constants/options';
import PaymentItem from '../../../matter/implementationDetail/tabs/item/paymentItem';

const VariousApplication = ({ formik }) => {
  const { assignStaffDetail } = useSelector(state => state.staffMatter);
  return (
    <div className={'recruit_matter_detail--various_application_info_area'}>
      {assignStaffDetail?.advanceApprovalFlag === 2 && (
        <div className="remand_info">
          <span>申請差戻</span>
          {assignStaffDetail?.advanceRemandReason && (
            <span>{`差戻理由:${assignStaffDetail.advanceRemandReason}`}</span>
          )}
        </div>
      )}
      <div className="payment_tab_content--item_list">
        {Options.codes.advanceCategory.map((category, i) => (
          <PaymentItem
            formik={formik}
            inputName={`advance.[${i}]`}
            label={category.label}
            key={category.label}
          />
        ))}
      </div>
    </div>
  );
};

export default VariousApplication;
