import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  BrowserRouter,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import './App.scss';
import Home from './pages/home';
import Login from './pages/login';
import ForgetPass from './pages/forgetPass';
import ChangePass from './pages/changePass';
import Main from './pages/main';
import MatterList from './pages/matter/list';
import StaffList from './pages/staff/list';
import ClientList from './pages/client/list';
import VenueList from './pages/venue/list';
import UserList from './pages/user/list';
import Dashboard from './pages/dashboard';
import StaffRegister from './pages/staff/register';
import ClientRegister from './pages/client/register';
import VenueRegister from './pages/venue/register';
import UserRegister from './pages/user/register';
import Sub from './pages/sub';
import MatterSchedule from './pages/matter/schedule';
import MatterRegister from './pages/matter/register';
import MatterReport from './pages/matter/report';
import CorporateList from './pages/corporate/list';
import CorporateRegister from './pages/corporate/register';
import PayTermList from './pages/payTerm/list';
import NameList from './pages/name/list';
import NamingRegister from './pages/name/register';
import InvoiceList from './pages/invoice/list';
import InvoiceRegister from './pages/invoice/register';
import IncomeTaxRegister from './pages/incomeTax/register';
import NamingEdit from './pages/name/edit';
import MatterDateList from './pages/matter/dateList';
import AssignDetail from './pages/matter/assignDetail';
import PayTermRegister from './pages/payTerm/register';
import QuoteList from './pages/quote/list';
import QuoteRegister from './pages/quote/register';
import QuoteApproval from './pages/quote/approval';
import QuoteTemplateList from './pages/quote/template/list';
import QuoteTemplateRegister from './pages/quote/template/register';
import ImplementationDetail from './pages/matter/implementationDetail';
import StaffAssignList from './pages/matter/staffAssignList';
import StaffRecruitMatterList from './pages/matter/staffRecruitMatterList';
import AppliedList from './pages/matter/appliedList';
import StaffPlanList from './pages/staffPlan/list';
import StaffPlanEdit from './pages/staffPlan/edit';
import StaffEdit from './pages/staff/edit';
import StaffMatterRegister from './pages/matter/staffMatterRegister';
import ForgetPassComplete from './pages/forgetPass/complete';
import PartnerList from './pages/partner/list';
import PartnerRegister from './pages/partner/register';
import QuoteRegisterMail from './pages/quote/register/mail';
import QuoteFlowList from './pages/quote/flow/list';
import QuoteFlowRegister from './pages/quote/flow/register';
import IncomeTaxList from './pages/incomeTax/list';
import StaffPayList from './pages/staffPay/list';
import StaffPayEdit from './pages/staffPay/edit';
import StaffPlanRegister from './pages/staffPlan/register';
import StaffPlanConfirm from './pages/staffPlan/confirm';
import RecruitMatterList from './pages/recruitMatter/list';
import StaffPayApproval from './pages/staffPay/approval';
import StaffPayDetail from './pages/staffPay/detail';
import RecruitMatterDetail from './pages/recruitMatter/detail';
import IncomeTaxEdit from './pages/incomeTax/edit';
import InvoiceEdit from './pages/invoice/edit';
import QuoteRegisterQuotation from './pages/quote/register/quotation';
import StaffPayYearList from './pages/staffPay/yearList';
import AnalyzeProfit from './pages/analyze/profit';
import InvoiceCreate from './pages/invoice/create';
import Lisener from './components/lisener';
import Loading from './components/loading';
import MyPage from './pages/myPage/edit';
import AnalyzeTrend from './pages/analyze/trend';
import AssignStateList from './pages/matter/assignStateList/list';
import SurveyList from './pages/survey/list';
import SurveyRegister from './pages/survey/register';

const App = () => {
  const stateStore = useSelector(state => state);

  const loginType = stateStore.loginType.type;

  return (
    <BrowserRouter>
      <Lisener />
      <Loading />
      <Routes>
        <Route path={'/login'} element={<Login />} />
        <Route path={'/forget-password'} element={<ForgetPass />} />
        <Route
          path={'/forget-password/complete'}
          element={<ForgetPassComplete />}
        />
        <Route path={'/change-password'} element={<ChangePass />} />

        {/*ダッシュボードorホーム*/}
        <Route
          path={'/'}
          element={
            <Main
              icon={'dashboard'}
              title={loginType === 'backoffice' ? 'ダッシュボード' : 'ホーム'}
              PageComponent={Dashboard}
            />
          }
        />

        {/*見積管理*/}
        <Route
          path={'/quote/list'}
          element={
            <Main icon={'quote'} title={'見積一覧'} PageComponent={QuoteList} />
          }
        />
        <Route
          path={'/quote/register'}
          element={
            <Sub
              icon={'quote'}
              title={'見積書作成'}
              PageComponent={QuoteRegister}
            />
          }
        />
        <Route
          path={'/quote/edit'}
          element={
            <Sub
              icon={'quote'}
              title={'見積書編集'}
              PageComponent={QuoteRegister}
            />
          }
        />
        <Route
          path={'/quote/register/mail'}
          element={
            <Sub
              icon={'quote'}
              title={'見積書作成'}
              PageComponent={QuoteRegisterMail}
            />
          }
        />
        <Route
          path={'/quote/register/quotation'}
          element={
            <Sub
              icon={'quote'}
              title={'見積書作成'}
              PageComponent={QuoteRegisterQuotation}
            />
          }
        />
        <Route
          path={'/quote/approval/list'}
          element={
            <Main
              icon={'quote'}
              title={'見積申請一覧'}
              PageComponent={QuoteApproval}
            />
          }
        />
        <Route
          path={'/quote/template/list'}
          element={
            <Main
              icon={'quote'}
              title={'見積テンプレート'}
              PageComponent={QuoteTemplateList}
            />
          }
        />
        <Route
          path={'/quote/template/register'}
          element={
            <Sub
              icon={'quote'}
              title={'見積テンプレート作成'}
              PageComponent={QuoteTemplateRegister}
            />
          }
        />
        <Route
          path={'/quote/template/edit'}
          element={
            <Sub
              icon={'quote'}
              title={'見積テンプレート編集'}
              PageComponent={QuoteTemplateRegister}
            />
          }
        />
        <Route
          path={'/quote/flow/list'}
          element={
            <Main
              icon={'quote'}
              title={'承認フロー'}
              PageComponent={QuoteFlowList}
            />
          }
        />
        <Route
          path={'/quote/flow/register'}
          element={
            <Sub
              icon={'quote'}
              title={'承認フロー登録'}
              PageComponent={QuoteFlowRegister}
            />
          }
        />
        <Route
          path={'/quote/flow/edit'}
          element={
            <Sub
              icon={'quote'}
              title={'承認フロー編集'}
              PageComponent={QuoteFlowRegister}
            />
          }
        />

        {/*案件管理*/}
        <Route
          path={'/matter/list'}
          element={
            <Main
              icon={'matter'}
              screen={'matter'}
              title={'案件一覧'}
              PageComponent={MatterList}
            />
          }
        />
        <Route
          path={'/matter/register'}
          element={
            <Sub
              icon={'matter'}
              title={'案件新規登録'}
              PageComponent={MatterRegister}
            />
          }
        />
        <Route
          path={'/matter/edit'}
          element={
            <Sub
              icon={'matter'}
              title={'案件編集'}
              PageComponent={MatterRegister}
            />
          }
        />
        <Route
          path={'/matter/report'}
          element={
            <Sub
              icon={'matter'}
              title={'実績報告書作成'}
              PageComponent={MatterReport}
            />
          }
        />
        <Route
          path={'/matter/implementation'}
          element={
            <Sub
              icon={'matter'}
              title={'個別実施詳細'}
              PageComponent={ImplementationDetail}
            />
          }
        />
        <Route
          path={'/matter/date/list'}
          element={
            <Main
              icon={'matter'}
              title={'実施日別案件一覧'}
              PageComponent={MatterDateList}
            />
          }
        />
        <Route
          path={'/matter/assign-state/list'}
          element={
            <Main
              icon={'matter'}
              title={'アサインスタッフ状況'}
              PageComponent={AssignStateList}
            />
          }
        />
        <Route
          path={'/matter/assign/list'}
          element={
            <Main
              icon={'matter'}
              title={'スタッフ報告状況'}
              PageComponent={StaffAssignList}
            />
          }
        />
        <Route
          path={'/matter/assign/detail'}
          element={
            <Sub
              icon={'matter'}
              title={'アサイン詳細'}
              PageComponent={AssignDetail}
            />
          }
        />
        <Route
          path={'/matter/recruit/list'}
          element={
            <Main
              icon={'matter'}
              title={'スタッフ募集案件一覧'}
              PageComponent={StaffRecruitMatterList}
            />
          }
        />
        <Route
          path={'/matter/recruit/register'}
          element={
            <Sub
              icon={'matter'}
              title={'スタッフ募集案件登録'}
              PageComponent={StaffMatterRegister}
            />
          }
        />
        <Route
          path={'/matter/recruit/edit'}
          element={
            <Sub
              icon={'matter'}
              title={'スタッフ募集案件編集'}
              PageComponent={StaffMatterRegister}
            />
          }
        />
        <Route
          path={'/matter/applied/list'}
          element={
            <Main
              icon={'matter'}
              title={'案件実施日別応募者一覧'}
              PageComponent={AppliedList}
            />
          }
        />

        {/*請求管理*/}
        <Route
          path={'/invoice/list'}
          element={
            <Main
              icon={'invoice'}
              title={'請求一覧'}
              PageComponent={InvoiceList}
            />
          }
        />
        <Route
          path={'/invoice/edit'}
          element={
            <Sub
              icon={'invoice'}
              title={'請求書編集'}
              PageComponent={InvoiceRegister}
            />
          }
        />
        <Route
          path={'/invoice/register'}
          element={
            <Sub
              icon={'invoice'}
              title={'請求書登録'}
              PageComponent={InvoiceRegister}
            />
          }
        />
        <Route
          path={'/invoice/create'}
          element={
            <Sub
              icon={'invoice'}
              title={'請求書作成'}
              PageComponent={InvoiceCreate}
            />
          }
        />

        {/*スタッフ管理*/}
        <Route
          path={'/staff/list'}
          element={
            <Main
              icon={'staff'}
              screen={'staff'}
              title={'スタッフ一覧'}
              PageComponent={StaffList}
            />
          }
        />
        <Route
          path={'/staff/register'}
          element={
            <Sub
              icon={'staff'}
              title={'スタッフ新規登録'}
              PageComponent={StaffRegister}
            />
          }
        />
        <Route
          path={'/staff/edit'}
          element={
            <Sub
              icon={'staff'}
              title={'スタッフ編集'}
              PageComponent={StaffEdit}
            />
          }
        />

        {/*スタッフシフト管理*/}
        <Route
          path={'/staff-plan/list'}
          element={
            <Main
              icon={'staffShift'}
              title={'スタッフシフト予定一覧'}
              PageComponent={StaffPlanList}
            />
          }
        />
        <Route
          path={'/staff-plan/edit'}
          element={
            <Sub
              icon={'staffShift'}
              title={'スタッフシフト予定編集'}
              PageComponent={StaffPlanEdit}
            />
          }
        />

        {/*スタッフ支払管理*/}
        <Route
          path={'/staff-pay/list'}
          element={
            <Main
              icon={'staffPay'}
              title={'スタッフ支払一覧'}
              PageComponent={StaffPayList}
            />
          }
        />
        <Route
          path={'/staff-pay/edit'}
          element={
            <Sub
              icon={'staffPay'}
              title={'スタッフ支払明細'}
              PageComponent={StaffPayEdit}
            />
          }
        />
        <Route
          path={'/staff-pay/year/list'}
          element={
            <Main
              icon={'staffPay'}
              title={'スタッフ年間給与一覧'}
              PageComponent={StaffPayYearList}
            />
          }
        />

        {/*分析*/}
        <Route
          path={'/analyze/profit'}
          element={
            <Main
              icon={'analyze'}
              title={'売上分析'}
              PageComponent={AnalyzeProfit}
            />
          }
        />
        <Route
          path={'/analyze/trend'}
          element={
            <Main
              icon={'analyze'}
              title={'スタッフ動向分析'}
              PageComponent={AnalyzeTrend}
            />
          }
        />

        {/*取引先管理*/}
        <Route
          path={'/client/list'}
          element={
            <Main
              icon={'client'}
              screen={'client'}
              title={'取引先一覧'}
              PageComponent={ClientList}
            />
          }
        />
        <Route
          path={'/client/register'}
          element={
            <Sub
              icon={'client'}
              title={'取引先新規登録'}
              PageComponent={ClientRegister}
            />
          }
        />
        <Route
          path={'/client/edit'}
          element={
            <Sub
              icon={'client'}
              title={'取引先編集'}
              PageComponent={ClientRegister}
            />
          }
        />
        <Route
          path={'/venue/list'}
          element={
            <Main
              icon={'venue'}
              screen={'venue'}
              title={'開催地一覧'}
              PageComponent={VenueList}
            />
          }
        />
        <Route
          path={'/venue/register'}
          element={
            <Sub
              icon={'venue'}
              title={'開催地新規登録'}
              PageComponent={VenueRegister}
            />
          }
        />
        <Route
          path={'/venue/edit'}
          element={
            <Sub
              icon={'venue'}
              title={'開催地編集'}
              PageComponent={VenueRegister}
            />
          }
        />

        {/*表示制御設定*/}
        <Route
          path={'/setting/schedule'}
          element={
            <Main
              icon={'setting'}
              title={'案件公開スケジュール'}
              PageComponent={MatterSchedule}
            />
          }
        />

        アンケート
        <Route
          path={'/survey/list'}
          element={
            <Main
              icon={'survey'}
              title={'アンケート一覧'}
              PageComponent={SurveyList}
            />
          }
        />
        <Route
          path={'/survey/register'}
          element={
            <Sub
              icon={'survey'}
              title={'アンケート登録'}
              PageComponent={SurveyRegister}
            />
          }
        />
        <Route
          path={'/survey/edit'}
          element={
            <Sub
              icon={'survey'}
              title={'アンケート編集'}
              PageComponent={SurveyRegister}
            />
          }
        />

        {/*各種マスタ*/}
        <Route
          path={'/master/user/list'}
          element={
            <Main
              icon={'user'}
              screen={'user'}
              title={'社員一覧'}
              PageComponent={UserList}
            />
          }
        />
        <Route
          path={'/master/user/register'}
          element={
            <Sub
              icon={'user'}
              title={'社員新規登録'}
              PageComponent={UserRegister}
            />
          }
        />
        <Route
          path={'/master/user/edit'}
          element={
            <Sub
              icon={'user'}
              title={'社員編集'}
              PageComponent={UserRegister}
            />
          }
        />
        <Route
          path={'/master/partner/list'}
          element={
            <Main
              icon={'partner'}
              screen={'partner'}
              title={'協力会社一覧'}
              PageComponent={PartnerList}
            />
          }
        />
        <Route
          path={'/master/partner/register'}
          element={
            <Sub
              icon={'partner'}
              title={'協力会社登録'}
              PageComponent={PartnerRegister}
            />
          }
        />
        <Route
          path={'/master/partner/edit'}
          element={
            <Sub
              icon={'partner'}
              title={'協力会社編集'}
              PageComponent={PartnerRegister}
            />
          }
        />

        <Route
          path={'/master/corporate/list'}
          element={
            <Main
              icon={'corporate'}
              screen={'corporate'}
              title={'法人一覧'}
              PageComponent={CorporateList}
            />
          }
        />
        <Route
          path={'/master/corporate/register'}
          element={
            <Sub
              icon={'corporate'}
              title={'法人登録'}
              PageComponent={CorporateRegister}
            />
          }
        />
        <Route
          path={'/master/corporate/edit'}
          element={
            <Sub
              icon={'corporate'}
              title={'法人編集'}
              PageComponent={CorporateRegister}
            />
          }
        />

        <Route
          path={'/master/pay-term/list'}
          element={
            <Main
              icon={'payTerm'}
              title={'支払条件一覧'}
              PageComponent={PayTermList}
            />
          }
        />
        <Route
          path={'/master/pay-term/register'}
          element={
            <Sub
              icon={'payTerm'}
              title={'支払条件登録'}
              PageComponent={PayTermRegister}
            />
          }
        />
        <Route
          path={'/master/pay-term/edit'}
          element={
            <Sub
              icon={'payTerm'}
              title={'支払条件編集'}
              PageComponent={PayTermRegister}
            />
          }
        />

        <Route
          path={'/master/naming/list'}
          element={
            <Main icon={'naming'} title={'名称一覧'} PageComponent={NameList} />
          }
        />
        <Route
          path={'/master/naming/edit'}
          element={
            <Sub
              icon={'naming'}
              title={'名称編集'}
              PageComponent={NamingEdit}
            />
          }
        />
        <Route
          path={'/master/naming/register'}
          element={
            <Sub
              icon={'naming'}
              title={'名称登録'}
              PageComponent={NamingRegister}
            />
          }
        />

        <Route
          path={'/master/income-tax/list'}
          element={
            <Main
              icon={'naming'}
              title={'所得税一覧'}
              PageComponent={IncomeTaxList}
            />
          }
        />
        <Route
          path={'/master/income-tax/register'}
          element={
            <Sub
              icon={'naming'}
              title={'所得税登録'}
              PageComponent={IncomeTaxRegister}
            />
          }
        />
        <Route
          path={'/master/income-tax/edit'}
          element={
            <Sub
              icon={'naming'}
              title={'所得税編集'}
              PageComponent={IncomeTaxEdit}
            />
          }
        />

        {/*スタッフ画面専用*/}
        <Route
          path={'/my-page/edit'}
          element={
            <Main icon={'myPage'} title={'マイページ'} PageComponent={MyPage} />
          }
        />
        <Route
          path={'/staff-plan/register'}
          element={
            <Main
              icon={'staffShift'}
              title={'シフト予定登録'}
              PageComponent={StaffPlanRegister}
            />
          }
        />
        <Route
          path={'/attend'}
          element={
            <Main
              icon={'attendance'}
              title={'勤怠確認'}
              PageComponent={StaffPlanConfirm}
            />
          }
        />
        <Route
          path={'/recruit-matter/list'}
          element={
            <Main
              icon={'attendance'}
              title={'案件募集一覧'}
              PageComponent={RecruitMatterList}
            />
          }
        />
        <Route
          path={'/recruit-matter/detail'}
          element={
            <Main
              icon={'attendance'}
              title={'案件詳細'}
              PageComponent={RecruitMatterDetail}
            />
          }
        />
        <Route
          path={'/staff-pay/detail'}
          element={
            <Main
              icon={'staffPayDetail'}
              title={'給与一覧'}
              PageComponent={StaffPayDetail}
            />
          }
        />
        <Route
          path={'/staff-pay/approval'}
          element={
            <Main
              icon={'staffPayDetail'}
              title={'勤怠・給与承認'}
              PageComponent={StaffPayApproval}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
