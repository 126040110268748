import Yoko from '../images/logo/PROTEC_yoko.png';
import Account from '../images/test/account.jpg';
import Eye from '../images/icon/icon_eye.svg';
import EyeOff from '../images/icon/icon_eye_off.svg';
import WhiteDocument from '../images/icon/icon_document_wh.svg';
import NavyDocument from '../images/icon/icon_document_nv.svg';
import WhiteLock from '../images/icon/icon_lock_wh.svg';
import DownNv from '../images/icon/icon_down_nv.svg';
import DownGr from '../images/icon/icon_down_gr.svg';
import PlusNv from '../images/icon/icon_plus_nv.svg';
import PlusWh from '../images/icon/icon_plus_wh.svg';
import ExternalLink from '../images/icon/icon_link_rd.svg';
import DeleteGr from '../images/icon/icon_delete_gr.svg';
import DeleteNv from '../images/icon/icon_delete_nv.svg';
import DeleteSGr from '../images/icon/icon_delete_s_gr.svg';
import DeleteSNv from '../images/icon/icon_delete_s_nv.svg';
import EditGr from '../images/icon/icon_edit_gr.svg';
import EditNv from '../images/icon/icon_edit_nv.svg';
import EditSGr from '../images/icon/icon_edit_s_gr.svg';
import EditSNv from '../images/icon/icon_edit_s_nv.svg';
import CloseRd from '../images/icon/icon_close_rd.svg';
import AddMatter from '../images/icon/icon_add_matter.svg';
import MasterWh from '../images/icon/icon_master_wh.svg';
import InvoiceWh from '../images/icon/icon_seikyu_wh.svg';
import Home from '../images/icon/icon_home_wt.svg';
import Quote from '../images/icon/icon_mitumori_wh.svg';
import Matter from '../images/icon/icon_noto_wh.svg';
import Staff from '../images/icon/icon_staff_wh.svg';
import Plan from '../images/icon/icon_shift_wh.svg';
import Pay from '../images/icon/icon_yenlist_wh.svg';
import Analyze from '../images/icon/icon_analysis_wh.svg';
import AnalyzeNv from '../images/icon/icon_analysis_nv.svg';
import Client from '../images/icon/icon_building_wh.svg';
import Setting from '../images/icon/icon_list_wh.svg';
import Import from '../images/icon/icon_download.svg';
import ImportGr from '../images/icon/icon_download_gr.svg';
import Export from '../images/icon/icon_upload.svg';
import XCircle from '../images/icon/icon_x-circle.svg';
import MailNvBold from '../images/icon/icon_mail_nv_bold.svg';
import MailNvLight from '../images/icon/icon_mail_nv_light.svg';
import MailNv from '../images/icon/icon_mail_nv.svg';
import MailOpNv from '../images/icon/icon_mail_op_nv.svg';
import MailOpWh from '../images/icon/icon_mail_op_wh.svg';
import MailRgr from '../images/icon/icon_mail_rgr.svg';
import MailWh from '../images/icon/icon_mail_wh.svg';
import LockGr from '../images/icon/icon_lock_gr.svg';
import LockNvT from '../images/icon/icon_lock_nv_02.svg';
import LockNv from '../images/icon/icon_lock_nv.svg';
import LockYe from '../images/icon/icon_lock_ye.svg';
import MyPage from '../images/icon/icon_mypage_wh.svg';
import Calendar from '../images/icon/icon_calendar_gr.svg';
import Yen from '../images/icon/icon_yen_wh.svg';
import Search from '../images/icon/icon_search_nv.svg';
import ForStaff from '../images/logo/logo_staff.png';
import CopyNv from '../images/icon/icon_copy_nv.svg';
import CopyWh from '../images/icon/icon_copy_wh.svg';
import BookmarkNv from '../images/icon/icon_bookmark_nv.svg';
import BookmarkWh from '../images/icon/icon_bookmark_wh.svg';
import Clip from '../images/icon/icon_clip.svg';
import XCircleWh from '../images/icon/icon_x-circle_wh.svg';
import NgWh from '../images/icon/icon_ng_wh.svg';
import OkWh from '../images/icon/icon_ok_wh.svg';
import UnLockWh from '../images/icon/icon_unlock_wh.svg';
import UnLockNv from '../images/icon/icon_unlock_nv.svg';
import CalendarPlusGr from '../images/icon/icon_calendar_plus_gr.svg';
import CalendarPlusNv from '../images/icon/icon_calendar_plus_nv.svg';
import SortNv from '../images/icon/icon_sort_nv.svg';
import SearchWh from '../images/icon/icon_search_wh.svg';
import SearchNv from '../images/icon/icon_search_nv.svg';
import SearchGr from '../images/icon/icon_search_gr.svg';
import LightNv from '../images/icon/icon_light_nv.svg';
import RightNv from '../images/icon/icon_right_nv.svg';
import Help from '../images/icon/icon_help_rgr.svg';
import Plus24 from '../images/icon/icon_plus24_nv.svg';
import ClockGr from '../images/icon/icon_clock_gr.svg';
import ClockRgr from '../images/icon/icon_clock_rgr.svg';
import TagBl from '../images/icon/icon_tag_bl.svg';
import TagNv from '../images/icon/icon_tag_nv.svg';
import StaffShift from '../images/icon/icon_shift_wh.svg';
import CalendarWh from '../images/icon/icon_calendar_wh.svg';
import StaffPayDetail from '../images/icon/icon_yen_wh.svg';
import LinkGr from '../images/icon/icon_link_gr.svg';
import AlertRd from '../images/icon/icon_alert_triangle_rd.svg';
import ChatNv from '../images/icon/icon_chat_nv.svg';
import AlertGr from '../images/icon/icon_alert_triangle.svg';
import NoImage from '../images/icon/icon_no_image.svg';
import CorporateIn from '../images/icon/icon_in_no_image_r.svg';
import PenWh from '../images/icon/icon_pen_wh.svg';
import PenBl from '../images/icon/icon_pen_bl.svg';
import PenGr from '../images/icon/icon_pen_gr.svg';
import PenNv from '../images/icon/icon_pen_nv.svg';
import Photo from '../images/icon/icon_photo.svg';
import PhotoB from '../images/icon/icon_photo_b.svg';
import Photo24Nv from '../images/icon/icon_photo24_nv.svg';
import TablePlus from '../images/icon/icon_table_plus.svg';
import TablePlusB from '../images/icon/icon_table_plus_b.svg';
import TablePlusNv from '../images/icon/icon_table_plus_nv.svg';
import AssignOkNv from '../images/icon/icon_assign_ok_nv.svg';
import DeleteSWh from '../images/icon/icon_delete_s_wh.svg';
import BellWh from '../images/icon/icon_bell_wh.svg';
import CallGr from '../images/icon/icon_call_gr.svg';
import CallNv from '../images/icon/icon_call_nv.svg';
import Tel from '../images/icon/icon_tel.svg';
import ReportNv from '../images/icon/icon_report_nv.svg';
import CalendarArrowRight1 from '../images/icon/icon_24_gr_right.svg';
import CalendarArrowRight2 from '../images/icon/icon_24_gr_right2.svg';
import CalendarArrowLeft1 from '../images/icon/icon_24_gr_left.svg';
import CalendarArrowLeft2 from '../images/icon/icon_24_gr_left2.svg';
import EditSGr1 from '../images/icon/icon_edit_s_gr_new.svg';
import DetailSGr from '../images/icon/icon_detail_s_gr.svg';
import SurveyNv from '../images/icon/icon_questionnaire_nv.svg';
import SurveyWh from '../images/icon/icon_questionnaire_wh.svg';
import Character from '../images/icon/floating.png';
import Character2 from '../images/icon/character.png';
import CancelWh from '../images/icon/icon_cancel_wh.svg';
import UploadCsvGr from '../images/icon/icon_upload_gr.svg';

const Icons = {
  logo: {
    yoko: Yoko,
    forStaff: ForStaff,
  },
  icon: {
    addMatter: AddMatter,
    eye: Eye,
    eyeOff: EyeOff,
    whiteDocument: WhiteDocument,
    navyDocument: NavyDocument,
    deleteGr: DeleteGr,
    deleteNv: DeleteNv,
    deleteSGr: DeleteSGr,
    deleteSNv: DeleteSNv,
    editGr: EditGr,
    editNv: EditNv,
    editSGr: EditSGr,
    editSNv: EditSNv,
    closeRd: CloseRd,
    whiteLock: WhiteLock,
    staff: Staff,
    client: Client,
    venue: Client,
    user: MasterWh,
    partner: MasterWh,
    matter: Matter,
    downNv: DownNv,
    downGr: DownGr,
    plusNv: PlusNv,
    plusWh: PlusWh,
    dashboard: Home,
    corporate: MasterWh,
    file: Eye,
    pay: Pay,
    externalLink: ExternalLink,
    naming: MasterWh,
    invoice: InvoiceWh,
    quote: Quote,
    plan: Plan,
    analyze: Analyze,
    analyzeNv: AnalyzeNv,
    setting: Setting,
    master: MasterWh,
    import: Import,
    importGr: ImportGr,
    export: Export,
    xCircle: XCircle,
    mailNvBold: MailNvBold,
    mailNvLight: MailNvLight,
    mailNv: MailNv,
    mailOpNv: MailOpNv,
    mailOpWh: MailOpWh,
    mailRgr: MailRgr,
    mailWh: MailWh,
    lockNvT: LockNvT,
    lockGr: LockGr,
    lockNv: LockNv,
    lockYe: LockYe,
    myPage: MyPage,
    calendar: Calendar,
    yen: Yen,
    search: Search,
    copyNv: CopyNv,
    copyWh: CopyWh,
    payTerm: MasterWh,
    bookmarkNv: BookmarkNv,
    bookmarkWh: BookmarkWh,
    clip: Clip,
    noImage: NoImage,
    xCircleWh: XCircleWh,
    ngWh: NgWh,
    okWh: OkWh,
    unLockWh: UnLockWh,
    unLockNv: UnLockNv,
    calendarPlusGr: CalendarPlusGr,
    calendarPlusNv: CalendarPlusNv,
    sortNv: SortNv,
    searchWh: SearchWh,
    searchNv: SearchNv,
    searchGr: SearchGr,
    lightNv: LightNv,
    rightNv: RightNv,
    help: Help,
    plus24: Plus24,
    clockGr: ClockGr,
    clockRgr: ClockRgr,
    tagBl: TagBl,
    tagNv: TagNv,
    staffPay: Pay,
    staffShift: StaffShift,
    attendance: CalendarWh,
    staffPayDetail: StaffPayDetail,
    linkGr: LinkGr,
    alertRd: AlertRd,
    chatNv: ChatNv,
    alertGr: AlertGr,
    corporateIn: CorporateIn,
    penWh: PenWh,
    penBl: PenBl,
    penGr: PenGr,
    penNv: PenNv,
    photo: Photo,
    photoB: PhotoB,
    photo24Nv: Photo24Nv,
    tablePlus: TablePlus,
    tablePlusB: TablePlusB,
    tablePlusNv: TablePlusNv,
    assignOkNv: AssignOkNv,
    deleteSWh: DeleteSWh,
    bellWh: BellWh,
    callGr: CallGr,
    callNv: CallNv,
    tel: Tel,
    reportNv: ReportNv,
    calendarWh: CalendarWh,
    calendarArrowR1: CalendarArrowRight1,
    calendarArrowR2: CalendarArrowRight2,
    calendarArrowL1: CalendarArrowLeft1,
    calendarArrowL2: CalendarArrowLeft2,
    editSGr1: EditSGr1,
    detailSGr: DetailSGr,
    surveyNv: SurveyNv,
    survey: SurveyWh,
    character: Character,
    character2: Character2,
    cancelWh: CancelWh,
    uploadCsvGr: UploadCsvGr,
  },
  test: {
    account: Account,
  },
};

export default Icons;
