import './style.scss';
import Button from '../../button';
import ModalBase from '../modalBase';
import staffListCsv from '../../../constants/templateCsv/staffList.csv';
import { createRef, useEffect, useRef, useState } from 'react';
import Icons from '../../../constants/icons';
import Options from '../../../constants/options';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import { useSelector, useDispatch } from 'react-redux';
import { toJSON } from 'lodash/seq';
import axios from 'axios';
import { dispatchError, isAllNullOrAllNotNull } from '../../../utils/fnUtil';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { notification } from 'antd';

export class SuperFileReader extends FileReader {
  constructor() {
    super();
  }

  #readAs(blob, ctx) {
    return new Promise((res, rej) => {
      super.addEventListener('load', ({ target }) => res(target.result));
      super.addEventListener('error', ({ target }) => rej(target.error));
      super[ctx](blob);
    });
  }

  readAsArrayBuffer(blob) {
    return this.#readAs(blob, 'readAsArrayBuffer');
  }

  readAsDataURL(blob) {
    return this.#readAs(blob, 'readAsDataURL');
  }

  readAsText(blob) {
    return this.#readAs(blob, 'readAsText');
  }

  readAsBinaryString(blob) {
    return this.#readAs(blob, 'readAsBinaryString');
  }
}

const CsvImportModal = ({
  importType,
  csvImportVisible,
  setCsvImportVisible,
}) => {
  const { corporateId, userId, accessToken } = useSelector(
    state => state.account
  );
  const loginType = useSelector(state => state.loginType.type);
  const importTypes = {
    userList: '0',
    clientList: '1',
    staffList: '2',
    partnerList: '3',
    namingList: '4',
    venueList: '5',
    surveyStaffList: '6',
  };

  const [fileState, setFileState] = useState({
    name: '',
    type: '',
    columns: [],
    records: [],
    counts: {
      allCount: 0,
      okCount: 0,
      ngCount: 0,
    },
  });

  const [importFile, setImportFile] = useState('');
  const [isCheckedFile, setIsCheckedFile] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectImportFile = async e => {
    store.dispatch(globalActions.setLoading(true));

    notification.destroy();

    const fileObj = e.target.files[0];
    const reader = await new SuperFileReader().readAsText(fileObj);

    let fileDetail = {
      name: '',
      type: '',
      columns: [],
      records: [],
      counts: {
        allCount: 0,
        okCount: 0,
        ngCount: 0,
      },
    };

    fileDetail.name = fileObj.name;
    fileDetail.type = fileObj.type;

    if (fileDetail.type !== 'text/csv') {
      setFileState({
        name: '',
        type: '',
        columns: [],
        records: [],
        counts: {
          allCount: 0,
          okCount: 0,
          ngCount: 0,
        },
      });
      dispatchError('ファイル形式が正しくありません');
      store.dispatch(globalActions.setLoading(false));
      setIsCheckedFile(true);
      return;
    }

    fileDetail.columns = reader
      .replace(/(\r\n){2,}|\r{2,}|\n{2,}/g, '\r\n')
      .trim()
      .split(/\r|\n|\r\n/)[0]
      .split(',');
    fileDetail.columns.unshift('行番号');
    fileDetail.columns.unshift('エラー理由');

    fileDetail.records = reader
      .replace(/(\r\n){2,}|\r{2,}|\n{2,}/g, '\r\n')
      .trim()
      .split(/\r|\n|\r\n/)
      .map((data, index) => {
        return index ? data.split(',') : '';
      })
      .filter(v => v)
      .filter(v => !v.every(j => !j));

    fileDetail.counts.allCount = fileDetail.records.length;

    const jsonBlob = new Blob(
      [JSON.stringify({ screenType: importTypes[importType] })],
      { type: 'application/json' }
    );

    let formData = new FormData();
    formData.append('jsonValue', jsonBlob);
    formData.append('csvFile', fileObj);

    await fetch(apiUrl.other.checkCsv, {
      method: 'POST',
      headers: {
        corporate_id: corporateId,
        access_token: accessToken,
        request_from: loginType,
      },
      body: formData,
    })
      .then(res => res.json())
      .then(json => {
        const normalMessage = '正常終了';
        if (json.code === '0' && json.displayMessage === 'フォーマット不正') {
          fileDetail.counts.ngCount = fileDetail.counts.allCount;

          fileDetail.records = fileDetail.records.map((record, index) => {
            return ['フォーマット不正', index + 1].concat(record);
          });

          setIsCheckedFile(true);
          setFileState(fileDetail);
        } else if (json.code === '0') {
          fileDetail.counts.okCount = json.messages.reduce((prev, obj) => {
            return prev + (obj.message === normalMessage ? 1 : 0);
          }, 0);

          fileDetail.counts.ngCount =
            fileDetail.counts.allCount - fileDetail.counts.okCount;

          const formatMessages = () => {
            let formatted = cloneDeep(json.messages);

            formatted = formatted.map(obj => {
              let joinMessage =
                obj.message === normalMessage ? obj.message : '';

              if (obj.message !== normalMessage) {
                formatted.forEach(o => {
                  if (obj.id === o.id) {
                    const message = `${o.columnName}:${o.message}`;
                    joinMessage +=
                      joinMessage === '' ? message : `, ${message}`;
                  }
                });
              }

              return { ...obj, message: joinMessage };
            });

            formatted.forEach((obj, idx) => {
              let duplicate = formatted.findIndex(({ id }) => id === obj.id);

              if (duplicate !== -1 && duplicate !== idx) {
                formatted[duplicate] = '';
              }
            });

            return formatted.filter(v => v);
          };

          const formattedMessages = formatMessages();

          setIsCheckedFile(false);

          fileDetail.records = fileDetail.records.map((record, index) => {
            if (formattedMessages[index].message !== '正常終了') {
              setIsCheckedFile(true);
            }
            return [
              formattedMessages[index].message,
              formattedMessages[index].id,
            ].concat(record);
          });

          setFileState(fileDetail);
        }
      })
      .catch(() => {
        dispatchError(
          'ファイルチェックに失敗しました\r\n通信環境の良い場所で再度お試しください'
        );
        setIsCheckedFile(true);
      })
      .finally(() => store.dispatch(globalActions.setLoading(false)));

    // 同名ファイルを再度選択できるように
    setImportFile(document.getElementById(e.target.id).files[0]);
    document.getElementById(e.target.id).value = '';
  };

  const closeModal = () => {
    setFileState({
      name: '',
      type: '',
      columns: [],
      records: [],
      counts: {
        allCount: 0,
        okCount: 0,
        ngCount: 0,
      },
    });
    notification.destroy();
    document.getElementById('inputFile').value = '';
    setCsvImportVisible(false);
    setIsCheckedFile(true);
  };

  const handleSubmit = async () => {
    store.dispatch(globalActions.setLoading(true));

    const fileObj = importFile;
    const jsonBlob = new Blob(
      [JSON.stringify({ screenType: importTypes[importType], userId: userId })],
      { type: 'application/json' }
    );

    let formData = new FormData();
    formData.append('jsonValue', jsonBlob);
    formData.append('csvFile', fileObj);

    await fetch(apiUrl.other.uploadCsv, {
      method: 'POST',
      headers: {
        corporate_id: corporateId,
        access_token: accessToken,
        request_from: loginType,
      },
      body: formData,
    })
      .then(res => res.json())
      .then(json => {
        if (json.code === '0') {
          closeModal();
          store.dispatch(globalActions.showSingleModal('登録しました'));
        }
      })
      .catch(() =>
        dispatchError(
          '登録に失敗しました\r\n通信環境の良い場所で再度お試しください'
        )
      )
      .finally(() => {
        store.dispatch(globalActions.setLoading(false));
        setImportFile('');
      });
  };

  useEffect(() => {
    setFileState({
      name: '',
      type: '',
      columns: [],
      records: [],
      counts: {
        allCount: 0,
        okCount: 0,
        ngCount: 0,
      },
    });
  }, []);

  return (
    <ModalBase
      dialogStyle={{
        width: 'calc(85% - 100px)',
      }}
      modalVisible={csvImportVisible}
    >
      <div className={'import_modal--title_area'}>
        <p>CSV取込</p>
        <a href={apiUrl.templateCsv + '/' + importType + '.csv'} download>
          フォーマットダウンロード
        </a>
      </div>

      <div className={'import_modal--file_area'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <label
            className={'import_modal--file_selector'}
            htmlFor={'inputFile'}
          >
            <img src={Icons.icon.clip} alt={'Clip'} />
            <span>ファイル選択</span>
          </label>
          <input
            id={'inputFile'}
            type={'file'}
            hidden={true}
            onChange={handleSelectImportFile}
            accept={'.txt, .csv'}
          />

          <div className={'import_modal--file_area_counter'}>
            <span>更新可能件数：{fileState?.counts?.okCount}</span>
            <span>エラー件数：{fileState?.counts?.ngCount}</span>
          </div>
        </div>

        <p>
          検証結果が正常なデータのみ更新されます。
          <br />
          ファイル形式：csvまたはtxt
        </p>
      </div>

      <div className={'import_modal--preview_area'}>
        {fileState.name ? (
          <div className={'import_modal--preview_table_wrap'}>
            <table className={'import_modal--preview_table'}>
              <thead className={'import_modal--preview_table_head'}>
                <tr>
                  {fileState?.columns?.map((column, i) => {
                    return (
                      <th
                        key={column}
                        style={i ? { borderLeft: '1px solid #E0E0E0' } : {}}
                      >
                        {column}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody className={'import_modal--preview_table_body'}>
                {fileState?.records?.map((record, idx) => {
                  return (
                    <tr key={`records_tr_${idx}`}>
                      {record.map((data, index) => {
                        return (
                          <td
                            key={`${index}_${data}`}
                            style={
                              index
                                ? {
                                    borderLeft: '1px dashed rgb(224, 224, 224)',
                                  }
                                : {}
                            }
                          >
                            {data === '正常終了' ? (
                              <p
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  margin: '0',
                                  textAlign: 'center',
                                }}
                              >
                                −
                              </p>
                            ) : (
                              data
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p className={'import_modal--preview_no_file'}>
            選択したファイルのプレビューがこちらに表示されます。
          </p>
        )}
      </div>
      <div className={'import_modal--button_area'}>
        <Button
          text={'戻る'}
          style={Options.buttonStyles.back}
          onClick={closeModal}
        />
        <Button
          text={'登録'}
          style={Options.buttonStyles.submit}
          onClick={handleSubmit}
          disabled={isCheckedFile}
        />
      </div>
    </ModalBase>
  );
};

export default CsvImportModal;
