import moment from 'moment/moment';
import { handlerFormikSubmit } from '../../../utils/fnUtil';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SurveyRegisterHeader from '../registerHeader';
import { useFormik } from 'formik';
import {
  surveyActions,
  surveyDetailFetch,
  surveyInsert, surveyTargetCount,
  surveyUpdate,
} from '../../../redux/slice/survey';
import RegisterBase from '../../../components/registerBase';
import SurveyRegisterTab from '../registerTab';
import { useLocation, useNavigate } from 'react-router-dom';
import Yup from '../../../utils/yupUtil';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';

const SurveyRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEdit = useLocation()?.state?.id ?? false;

  const { registerParams, targetCount } = useSelector(
    state => state.survey
  );

  //セグメントの制御処理
  const getInputValues = (idPrefix, defaultVal) => {
    const inputs = Array.from(document.querySelectorAll(`[id^="${idPrefix}"]`));
    const checkedInputs = inputs.filter(input => input.checked);
    const inputValues = checkedInputs
      .map(input => input.id.slice(-1))
      .join(',');
    return inputValues || defaultVal;
  };

  const formik = useFormik({
    initialValues: registerParams,
    enableReinitialize: true,
    validateOnMount: true,
    validate: () => {
      formik?.errors?.surveyQuestionInfos?.map((obj, idx) => {
        if (obj !== undefined) {
          if (
            formik.values.surveyType == 0 &&
            !formik?.values?.surveyQuestionInfos?.[idx]?.answerDate
          ) {
            obj.answerDate = '回答日:必須項目です。';
            delete obj?.answerDateFrom;
            delete obj?.answerDateTo;
          } else if (
            formik.values.surveyType == 1 &&
            !formik?.values?.surveyQuestionInfos?.[idx]?.answerDateFrom &&
            !formik?.values?.surveyQuestionInfos?.[idx]?.answerDateTo
          ) {
            delete obj?.answerDate;
            obj.answerDateFrom = '回答日(開始):必須項目です。';
            obj.answerDateTo = '回答日(終了):必須項目です。';
          } else if (
            formik.values.surveyType == 1 &&
            !formik?.values?.surveyQuestionInfos?.[idx]?.answerDateFrom
          ) {
            delete obj?.answerDate;
            obj.answerDateFrom = '回答日(開始):必須項目です。';
            delete obj?.answerDateTo;
          } else if (
            formik.values.surveyType == 1 &&
            !formik?.values?.surveyQuestionInfos?.[idx]?.answerDateTo
          ) {
            delete obj?.answerDate;
            delete obj?.answerDateFrom;
            obj.answerDateTo = '回答日(終了):必須項目です。';
          } else {
            delete obj?.answerDate;
            delete obj?.answerDateFrom;
            delete obj?.answerDateTo;
          }

          if (
            formik?.values?.surveyQuestionInfos[idx].answerMethod == 2 ||
            formik?.values?.surveyQuestionInfos[idx].choices.some(
              choice => choice.choice !== ''
            )
          ) {
            obj.choices = [];
          } else {
            obj.choices = [{ choice: '選択肢:一つ以上入力してください。' }];
          }

          if (formik?.values?.surveyQuestionInfos[idx].answerMethod == 2) {
            delete obj.aggregationProcessGraphType
          }

          if (formik.values.surveyType == 1) {
            delete obj.answerResultGraphType;
          } else if (
            formik?.values?.surveyQuestionInfos?.[idx]?.answerResultFlag == 1
          ) {
            delete obj?.answerResultGraphType;
          } else if (
            formik?.values?.surveyQuestionInfos?.[idx]?.answerResultFlag == 0 &&
            formik?.values?.surveyQuestionInfos?.[idx]
              ?.answerResultGraphType !== null
          ) {
            delete obj?.answerResultGraphType;
          } else {
            obj.answerResultGraphType = '回答結果表示方法:必須項目です。';
          }

          if (formik?.values?.surveyQuestionInfos?.[idx]?.batchFlag) {
            delete obj.answerDate;
            delete obj.answerDateFrom;
            delete obj.answerDateTo;
            delete obj.questionDescription;
            delete obj.choices;
            delete obj.answerResultGraphType;
            delete obj.aggregationProcessGraphType;
          }
        }
        return obj;
      });
    },
    validationSchema: Yup.object({
      surveyTitle: Yup.string().required().label('タイトル'),
      surveyDescription: Yup.string().required().label('アンケート説明'),
      surveyType: Yup.string().required().label('アンケート種別'),
      surveyQuestionInfos: Yup.array().of(
        Yup.object({
          answerDate: Yup.string()
            .nullable()
            .test('answerDate', '回答日:必須項目です。', (_, context) => {
              const surveyType = formik.values.surveyType;
              const answerDate = context.from[0].value.answerDate;
              const batchFlag = context.from[0].value.batchFlag;
              return batchFlag === 1
                ? true
                : surveyType == 1
                ? true
                : answerDate;
            }),
          answerDateFrom: Yup.string()
            .nullable()
            .test(
              'answerDateFrom',
              '回答日(開始):必須項目です。',
              (_, context) => {
                const surveyType = formik.values.surveyType;
                const answerDateFrom = context.from[0].value.answerDateFrom;
                const batchFlag = context.from[0].value.batchFlag;
                return batchFlag === 1
                  ? true
                  : surveyType == 0
                  ? true
                  : answerDateFrom;
              }
            ),
          answerDateTo: Yup.string()
            .nullable()
            .test(
              'answerDateTo',
              '回答日(終了):必須項目です。',
              (_, context) => {
                const surveyType = formik.values.surveyType;
                const answerDateTo = context.from[0].value.answerDateTo;
                const batchFlag = context.from[0].value.batchFlag;
                return batchFlag === 1
                  ? true
                  : surveyType == 0
                  ? true
                  : answerDateTo;
              }
            ),
          questionDescription: Yup.string()
            .nullable()
            .test(
              'questionDescription',
              '設問内容:必須項目です。',
              (_, context) => {
                const questionDescription =
                  context.from[0].value.questionDescription;
                const batchFlag = context.from[0].value.batchFlag;
                return batchFlag === 1 ? true : !!questionDescription;
              }
            ),
          choices: Yup.array().of(
            Yup.object({
              choice: Yup.string()
                .nullable()
                .test(
                  'choice',
                  '選択肢:一つ以上入力してください。',
                  (_, context) => {
                    let regex = /\[(\d+)\]/g;
                    let match = regex.exec(context.path);
                    let index = match[1];
                    const choices =
                      formik?.values?.surveyQuestionInfos?.[index]?.choices;
                    const answerMethod =
                      formik?.values?.surveyQuestionInfos?.[index]
                        ?.answerMethod;
                    const batchFlag = context?.from?.[0]?.value?.batchFlag;
                    return batchFlag === 1
                      ? true
                      : answerMethod == 2
                      ? true
                      : choices?.some(choice => choice.choice !== '');
                  }
                ),
            })
          ),
          answerResultGraphType: Yup.string()
            .nullable()
            .test(
              'answerResultGraphType',
              '回答結果表示方法:必須項目です。',
              (_, context) => {
                let regex = /\[(\d+)\]/g;
                let match = regex.exec(context.path);
                let index = match[1];
                const batchFlag = context.from[0].value.batchFlag;
                const answerResultFlag =
                  formik?.values?.surveyQuestionInfos?.[index]
                    ?.answerResultFlag;
                const surveyType = formik.values.surveyType;
                const answerResultGraphType =
                  context?.from?.[0]?.value?.answerResultGraphType;
                return batchFlag === 1
                  ? true
                  : surveyType == 1
                  ? true
                  : answerResultFlag == 1
                  ? true
                  : answerResultGraphType !== null;
              }
            ),
          aggregationProcessGraphType: Yup.array()
            .nullable()
            .test(
              'aggregationProcessGraphType',
              '集計処理:必須項目です。',
              (_, context) => {
                const aggregationProcessGraphType =
                  context?.from?.[0]?.value?.aggregationProcessGraphType;
                const batchFlag = context?.from?.[0]?.value?.batchFlag;
                const answerMethod = context?.from?.[0]?.value?.answerMethod;
                return answerMethod == 2 ?
                  true :
                  batchFlag === 1
                  ? true
                  : aggregationProcessGraphType.length !== 0;
              }
            ),
        })
      ),
    }),
    onSubmit: values => {
      let copiedValues = JSON.parse(JSON.stringify(values));

      //セグメント制御
      if (copiedValues.targetMethod == '1') {
        copiedValues.targetType = 1;
        copiedValues.gender = 0;
        copiedValues.ages = '0';
        copiedValues.professions = '0';
        copiedValues.staffPositions = '0';
        copiedValues.prefFlag = 0;
        copiedValues.prefList = null;
        copiedValues.targetCount = copiedValues.staffList?.split(',')?.map(Number)?.length ?? 0;
      } else {
        if (copiedValues.ages != 0) {
          copiedValues.ages = !copiedValues.agesCheckBox
            ? '0'
            : copiedValues.agesCheckBox.split('').sort().join(',');
        }
        if (copiedValues.professions != 0) {
          copiedValues.professions = !copiedValues.professionsCheckBox
            ? '0'
            : copiedValues.professionsCheckBox.split('').sort().join(',');
        }
        if (copiedValues.staffPositions != 0) {
          copiedValues.staffPositions = !copiedValues.staffPositionsCheckBox
            ? '0'
            : copiedValues.staffPositionsCheckBox.split('').sort().join(',');
        }
        if (copiedValues.prefFlag == 0) {
          copiedValues.prefList = null;
        } else {
          copiedValues.prefList = !copiedValues.prefList
            ? null
            : copiedValues.prefList?.join(',');
        }
        copiedValues.gender = Number(copiedValues.gender);
        copiedValues.prefFlag = Number(copiedValues.prefFlag);
        copiedValues.targetType = Number(copiedValues.targetType);
        copiedValues.targetCount = Number(copiedValues.targetCount);
      }

      copiedValues.surveyType = Number(copiedValues.surveyType);

      //毎日アンケートか企業アンケートの制御
      if (copiedValues.surveyType == '0') {
        copiedValues.clientName = null;
        copiedValues.surveyQuestionInfos?.forEach((obj, i) => {
          obj.answerDateFrom = null;
          obj.answerDateTo = null;
          if (obj.answerResultFlag == 1) {
            obj.answerResultGraphType = null;
          }
          // else {
          //   obj.answerResultGraphType =
          //     formik.values.surveyQuestionInfos[i].answerResultGraphType;
          // }
        });
      } else {
        copiedValues.surveyQuestionInfos?.forEach(obj => {
          obj.answerDate = null;
          obj.answerResultFlag = 1;
          obj.answerResultGraphType = null;
        });
      }

      // //画像の振り分け処理
      copiedValues.surveyQuestionInfos.forEach(questionInfo => {
        const folderList = Array.from(
          { length: 5 },
          (_, i) => questionInfo.folder[0][`folderImage${i + 1}`]
        ).reduce((acc, curr) => (curr ? [...acc, curr] : acc), []);
        for (let i = 1; i <= 5; i++) {
          questionInfo[`questionImage_${i}`] = folderList[i - 1] ?? null;
        }
      });

      // //選択肢の整理処理
      copiedValues.surveyQuestionInfos?.forEach(obj => {
        if (obj.choices?.length > 0) {
          const hoge = obj.choices
            .filter(o => o.choice !== '')
            .map(o => o.choice)
            .slice(0, 10);

          for (let i = 1; i <= 10; i++) {
            obj[`choice_${i}`] = hoge[i - 1] ?? null;
          }
        }
      });

      //集計処理グラフの処理
      for (const questionInfo of copiedValues.surveyQuestionInfos) {
        questionInfo.aggregationProcessGraphType =
          questionInfo.aggregationProcessGraphType?.join(',') || null;
      }

      //集計処理グラフのラベル処理
      copiedValues.surveyQuestionInfos = copiedValues.surveyQuestionInfos.map(
        (questionInfo, idx) => ({
          ...questionInfo,
          aggregationProcessLabelList: getInputValues(
            `surveyQuestionInfos.[${idx}].label`,
            null
          ),
        })
      );

      copiedValues.surveyQuestionInfos?.map((obj, i) => {
        if (obj.answerDate) {
          obj.answerDate = moment(obj.answerDate).format('YYYY-MM-DD');
        }
        if (obj.answerDateFrom) {
          obj.answerDateFrom = moment(obj.answerDateFrom).format('YYYY-MM-DD');
        }
        if (obj.answerDateTo) {
          obj.answerDateTo = moment(obj.answerDateTo).format('YYYY-MM-DD');
        }
        if (obj.answerMethod == 2) {
          for (let i = 1; i <= 10; i++) {
            obj[`choice_${i}`] = null;
          }
          obj.aggregationProcessGraphType = null;
        }
        obj.surveyBodyNumber = (i + 1);
        obj.answerMethod = Number(obj.answerMethod);
        obj.answerResultFlag = Number(obj.answerResultFlag);
        obj.questionRequiredFlag =
          typeof obj.questionRequiredFlag === 'number'
            ? obj.questionRequiredFlag
            : obj.questionRequiredFlag
            ? 1
            : 0;
        if (obj.answerResultFlag === 1) {
          obj.answerResultGraphType = null;
        }
        return obj;
      });

      copiedValues.targetMethod = Number(copiedValues.targetMethod);

      dispatch(
        isEdit
          ? surveyUpdate({ ...copiedValues, surveyId: Number(isEdit) })
          : surveyInsert(copiedValues)
      )
        .then(() => {
          store.dispatch(
            globalActions.showSingleModal(
              isEdit ? '更新しました' : '登録しました'
            )
          );
          navigate('/survey/list');
        })
        .catch(() => {
          store.dispatch(globalActions.showErrorModal('失敗しました'));
        });
    },
  });

  const infos = {
    detail: {
      tabs: {
        survey: {
          label: '設問情報',
          jsx: () => <SurveyRegisterTab formik={formik} isEdit={isEdit} />,
        },
      },
    },
  };

  useEffect(() => {
    document
      .querySelectorAll('.register_base--container_notice')
      .forEach(elm => {
        elm.remove();
      });
  }, []);

  useEffect(() => {
    if (isEdit) {
      dispatch(surveyDetailFetch({ surveyId: isEdit }))
        .unwrap()
        .then(data => {
          for (const id of data?.detail?.ages?.split(',')) {
            let agesElm = document.getElementById(`agesId${id}`);
            if (agesElm) {
              agesElm.checked = true;
            }
          }
          for (const id of data?.detail?.professions?.split(',')) {
            let professionsElm = document.getElementById(`professions${id}`);
            if (professionsElm) {
              professionsElm.checked = true;
            }
          }
          for (const id of data?.detail?.staffPositions?.split(',')) {
            let staffPositionsElm = document.getElementById(
              `staffPositions${id}`
            );
            if (staffPositionsElm) {
              staffPositionsElm.checked = true;
            }
          }
          data?.detail?.surveyQuestionInfos?.forEach((obj, idx) => {
            if (obj.aggregationProcessLabelList !== null) {
              let ary = obj.aggregationProcessLabelList?.split(',');
              ary.forEach(o => {
                let elm = document.getElementById(
                  `surveyQuestionInfos.[${idx}].label${o}`
                );
                if (elm) {
                  elm.checked = true;
                }
              });
            }
          });
          formik.setValues({
            ...data?.detail,
            ages: data?.detail?.ages === '0' ? '0' : '1',
            agesCheckBox: data?.detail?.ages?.replace(/,/g, ''),
            professions: data?.detail?.professions === '0' ? '0' : '1',
            professionsCheckBox: data?.detail?.professions?.replace(/,/g, ''),
            staffPositions: data?.detail?.staffPositions === '0' ? '0' : '1',
            staffPositionsCheckBox: data?.detail?.staffPositions?.replace(
              /,/g,
              ''
            ),
            prefList: data?.detail?.prefList?.split(','),
            surveyQuestionInfos: data?.detail?.surveyQuestionInfos?.map(d => {
              let imgAry = {};
              let choiceAry = [];
              let labelList;

              for (let i = 1; i < 6; i++) {
                if (d?.[`questionImage_${i}`]) {
                  imgAry[`folderImage${i}`] = d?.[`questionImage_${i}`];
                }
              }

              for (let i = 1; i < 11; i++) {
                if (d?.[`choice_${i}`]) {
                  choiceAry.push({ choice: d?.[`choice_${i}`] });
                }
              }

              labelList = d.aggregationProcessLabelList?.split(',');

              return {
                ...d,
                aggregationProcessGraphType:
                  d.aggregationProcessGraphType?.split(','),
                folder: [imgAry] ?? [{}],
                choices:
                  choiceAry?.length === 0
                    ? [{ choice: '' }, { choice: '' }]
                    : choiceAry,
                label0: labelList?.some(o => o === '0') ? ['on'] : [],
                label1: labelList?.some(o => o === '1') ? ['on'] : [],
                label2: labelList?.some(o => o === '2') ? ['on'] : [],
                label3: labelList?.some(o => o === '3') ? ['on'] : [],
                label4: labelList?.some(o => o === '4') ? ['on'] : [],
              };
            }),
          });
        });
    }
  }, [isEdit]);

  return (
    <>
      <SurveyRegisterHeader
        formik={formik}
        dispatch={dispatch}
      />
      <RegisterBase infos={infos} />
      <div className={'bottom_btn_area'} style={{ height: '92px' }}>
        <div
          className={'user_register--submit_buttons'}
          style={{ marginTop: '24px' }}
        >
          <Button
            text={'戻る'}
            style={{ ...Options.buttonStyles.back, width: '240px' }}
            onClick={() => {
              navigate(`/survey/list`);
            }}
          />
          <Button
            text={isEdit ? '更新' : '登録'}
            style={{ ...Options.buttonStyles.submit, width: '240px' }}
            onClick={() => {
              if (isEdit && formik.values.targetMethod == 0) {
                dispatch(
                  surveyTargetCount({
                    targetMethod: 0,
                    targetType: Number(formik.values.targetType),
                    gender: Number(formik.values.gender),
                    ages:
                      formik.values.ages == '0' ||
                      formik.values.agesCheckBox === ''
                        ? '0'
                        : formik.values.agesCheckBox
                          .split('')
                          .sort()
                          .join(','),
                    professions:
                      formik.values.professions == '0' ||
                      formik.values.professionsCheckBox === ''
                        ? '0'
                        : formik.values.professionsCheckBox
                          .split('')
                          .sort()
                          .join(','),
                    staffPositions:
                      formik.values.staffPositions == '0' ||
                      formik.values.staffPositionsCheckBox === ''
                        ? '0'
                        : formik.values.staffPositionsCheckBox
                          .split('')
                          .sort()
                          .join(','),
                    prefFlag: Number(formik.values.prefFlag),
                    prefList:
                      formik.values.prefFlag == 0
                        ? null
                        : !formik.values.prefList ||
                        formik.values.prefList.length === 0
                          ? null
                          : formik.values.prefList?.join(','),
                  })
                ).then(data => {
                  formik.setFieldValue(
                    'targetCount',
                    data?.payload?.targetCount
                  );
                  dispatch(surveyActions.countFresh(0));
                  handlerFormikSubmit(formik);
                });
              } else {
                handlerFormikSubmit(formik);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SurveyRegister;
