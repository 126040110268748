import { memo } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import BirthdaySelector from "../../../components/birthdaySelector";
import StaffRegisterInfos from "../inputInfos";
import prefectures from "../../../constants/prefectures";
import {handleRadio} from "./functions";
import React, { useEffect } from 'react';
import {fetchRequest} from "../../../api/fetch";
import {apiUrl} from "../../../api/apiUrl";
import {handlerFormikFieldChange} from "../../../utils/fnUtil";
import {useSelector} from "react-redux";
import CustomInput from "../../../components/customInput";

const AttributeInfos = memo(({ formik }) => {
    const {occupation} = formik.values;

    const { namingCategoryList, namingList } = useSelector((state) => state.staff);
  return(
      <div className={'staff_register--background_infos_area'}>
          {StaffRegisterInfos.attributeInfos.inputs.map((obj, idx) => {
              return idx < 5 && (
                  <CustomFormikInput
                      {...obj}
                      formik={formik}
                      label={namingCategoryList?.filter(n=> n.namingId === `StaffPreliminary${idx + 1}`).map(p=>p?.label)}
                      inputType={'select'}
                      labelRequired={false}
                      initialValue={namingList?.filter(n=> n.namingClassificationId === `StaffPreliminary${idx + 1}`)
                          .sort((a, b) => ((parseInt(a.value) < parseInt(b.value)) ? -1 : 1))
                          .map(p=>p?.value)}
                      selectBoxOptions={namingList?.filter(n=> n.namingClassificationId === `StaffPreliminary${idx + 1}`)
                          .sort((a, b) => ((parseInt(a.value) < parseInt(b.value)) ? -1 : 1))
                          .map(p=>p?.label)}
                  />
              )
          })}
          <CustomFormikInput
              formik={formik}
              {...StaffRegisterInfos.attributeInfos.inputs[5]}
          />
      </div>
  )
});
export default AttributeInfos;
