import { memo } from 'react';
import { useSelector } from 'react-redux';
import { checkIsSp, handlerFormikFieldChange } from '../../../../utils/fnUtil';
import { Radio } from 'antd';
import Options from '../../../../constants/options';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import './style.scss';

const MatterDateInfo = memo(({ formik }) => {
  const {
    values: { dateInfo },
  } = formik;

  const { nearestStation, assignDetail } = useSelector(
    state => state.matterDate
  );

  const isSp = checkIsSp();

  return (
    <div className="assign_info">
      <div>
        <span
          className="assign_info--label"
          style={isSp ? { fontSize: '12px' } : {}}
        >
          開催日
        </span>
        　{dateInfo?.matterDate}
      </div>
      <div className={'matter_date_info_venue_address_area'}>
        <span
          className="assign_info--label"
          style={
            isSp
              ? {
                  fontSize: '12px',
                  minWidth: 'unset',
                  whiteSpace: 'nowrap',
                  marginRight: '0',
                }
              : {}
          }
        >
          開催地住所
        </span>
        <p className={'matter_date_info_venue_address_data'}>
          <span>
            {assignDetail?.postCode ? '〒 ' + assignDetail.postCode : ''}　
            {assignDetail?.address ?? ''}
          </span>
          <span>{assignDetail?.tel ? 'TEL： ' + assignDetail.tel : ''}</span>
          <span>{assignDetail?.fax ? 'FAX： ' + assignDetail.fax : ''}</span>
        </p>
      </div>
      <CustomFormikInput
        formik={formik}
        label="開催地最寄駅"
        inputType="comboBox"
        placeholder="開催地最寄駅を選択してください"
        initialValue={nearestStation}
        selectBoxOptions={nearestStation}
        inputName="dateInfo.venueNearestStation"
        style={{
          areaStyle: {
            margin: '20px 0',
            maxWidth: 666,
          },
        }}
      />
      <CustomFormikInput
        formik={formik}
        label="実施場所"
        placeholder="実施場所を入力してください"
        inputName="dateInfo.placeName"
        style={{
          areaStyle: {
            margin: '20px 0',
            maxWidth: 666,
          },
        }}
      />
      <CustomFormikInput
        formik={formik}
        label="集合場所"
        placeholder="集合場所を入力してください"
        inputName="dateInfo.meetingPlace"
        style={{
          areaStyle: {
            maxWidth: 666,
          },
        }}
      />
      <div className="assign_info--checkbox_container">
        <span
          className="assign_info--label"
          style={isSp ? { fontSize: '12px' } : {}}
        >
          実施場所立地
        </span>
        <div className="assign_info--checkbox_area">
          {Options.codes.placeLocation.map(option => (
            <Radio.Group
              key={option.label}
              onChange={e =>
                handlerFormikFieldChange(
                  formik,
                  'dateInfo.placeLocation',
                  e.target.value
                )
              }
              value={dateInfo.placeLocation}
            >
              {option.value === 99 ? (
                <div className="assign_info--checkbox_area_other">
                  <Radio
                    key={option.label}
                    value={option.value}
                    style={isSp ? { fontSize: '12px' } : {}}
                  >
                    {option.label}
                  </Radio>
                  <CustomFormikInput
                    formik={formik}
                    placeholder="立地を入力してください"
                    inputName="dateInfo.placeLocationOther"
                    disabled={dateInfo.placeLocation !== 99}
                    style={{
                      areaStyle: {
                        maxWidth: 392,
                      },
                    }}
                  />
                </div>
              ) : (
                <Radio key={option.label} value={option.value}>
                  {option.label}
                </Radio>
              )}
            </Radio.Group>
          ))}
        </div>
      </div>
      <CustomFormikInput
        formik={formik}
        inputType="textBox"
        label="実施詳細"
        placeholder="実施詳細を入力してください"
        inputName="dateInfo.matterDetail"
        style={{
          areaStyle: {
            margin: '20px 0',
          },
        }}
      />
      <CustomFormikInput
        formik={formik}
        inputType="textBox"
        label="前日伝達事項"
        placeholder="前日伝達事項を入力してください"
        inputName="dateInfo.previousDayInfo"
        style={{
          areaStyle: {
            margin: '20px 0',
          },
        }}
      />
      <CustomFormikInput
        formik={formik}
        inputType="textBox"
        label="当日詳細"
        placeholder="当日詳細を入力してください"
        inputName="dateInfo.shiftDayDetail"
        style={{
          areaStyle: {
            margin: '20px 0',
          },
        }}
      />
    </div>
  );
});

export default MatterDateInfo;
