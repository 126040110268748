import { memo } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import StaffRegisterInfos from './inputInfos';
import React, { useEffect } from 'react';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import { useSelector } from 'react-redux';
import { bankingFetch, staffActions } from '../../../redux/slice/staff';

const Salary = memo(({ formik, dispatch, isStaff }) => {
  const { payMethod1, bankAccountFlag1, bankName, bankBranchName } =
    formik.values;
  const { bankingList, branchesList } = useSelector(state => state.staff);

  useEffect(() => {
    if (bankName) {
      handlerFormikFieldChange(
        formik,
        'bankCode',
        bankingList?.find(b => b.label == bankName)?.value ?? ''
      ); //一時的に削除全銀APIが使えるまで
      dispatch(
        bankingFetch(bankingList?.find(b => b.label == bankName)?.value ?? '')
      );
    } else {
      dispatch(staffActions.bankReset());
      formik.setFieldValue('bankCode', '');
    }
  }, [bankName]);

  useEffect(() => {
    if (bankBranchName && branchesList?.length > 0) {
      formik.setFieldValue(
        'bankBranchCode',
        branchesList?.find(b => b?.label === bankBranchName)?.value ?? ''
      );
    } else {
      formik.setFieldValue('bankBranchCode', '');
    }
  }, [bankBranchName]);

  return (
    <div className={'staff_register--background_infos_area'}>
      <CustomFormikInput
        formik={formik}
        radioButtonLabelAndChecked={[
          { label: '振込', checked: payMethod1 == 0 },
          { label: '手渡し', checked: payMethod1 == 1 },
        ]}
        {...StaffRegisterInfos.salary.inputs[0]}
        labelRequired={isStaff}
      />
      <CustomFormikInput
        initialValue={bankingList?.map(b => b?.label)}
        selectBoxOptions={bankingList?.map(b => b?.label)}
        formik={formik}
        labelRequired={isStaff}
        {...StaffRegisterInfos.salary.inputs[1]}
      />
      <div className={'staff_register--input_note_area'}>
        <CustomFormikInput
          {...StaffRegisterInfos.salary.inputs[2]}
          formik={formik}
          disabled={true}
          labelRequired={isStaff}
        />
      </div>
      <CustomFormikInput
        {...StaffRegisterInfos.salary.inputs[3]}
        initialValue={branchesList?.map(b => b?.label)}
        selectBoxOptions={branchesList?.map(b => b?.label)}
        formik={formik}
        labelRequired={isStaff}
      />
      <div className={'staff_register--input_note_area'}>
        <CustomFormikInput
          {...StaffRegisterInfos.salary.inputs[4]}
          formik={formik}
          labelRequired={isStaff}
          disabled={true}
        />
      </div>

      <div className={'staff_register--bankAccountNumber'}>
        <CustomFormikInput
          formik={formik}
          radioButtonLabelAndChecked={[
            { label: '普通預金', checked: bankAccountFlag1 == 1 },
            { label: '当座預金', checked: bankAccountFlag1 == 2 },
            { label: '貯蓄預金', checked: bankAccountFlag1 == 4 },
          ]}
          {...StaffRegisterInfos.salary.inputs[5]}
          labelRequired={isStaff}
        />
      </div>
      <CustomFormikInput
        formik={formik}
        {...StaffRegisterInfos.salary.inputs[6]}
        labelRequired={isStaff}
      />
      <CustomFormikInput
        formik={formik}
        {...StaffRegisterInfos.salary.inputs[7]}
        labelRequired={isStaff}
      />
    </div>
  );
});
export default Salary;
