import { memo } from 'react';
import InfoItem from './item/infoItem';
import Options from '../../../../constants/options';
import { checkIsSp } from '../../../../utils/fnUtil';

const ImplementationInfo = memo(({ formik }) => {
  return (
    <>
      <div className={'implementation_tab_common_info'}>
        <div className={'implementation_tab_common_info_row'}>
          <span className={'implementation_tab_common_info_label'}>実施日</span>
          <span className={'implementation_tab_common_info_content'}>
            {formik.values.matterDate}
          </span>
        </div>

        <div className={'implementation_tab_common_info_row'}>
          <span className={'implementation_tab_common_info_label'}>
            駅到着時間
          </span>
          <span className={'implementation_tab_common_info_content'}>
            {formik?.values?.arriveStationDatetime
              ? formik?.values?.arriveStationDatetime.split(' ')[1].substring(0, 5)
              : '--:--'}
          </span>
        </div>

        <div className={'implementation_tab_common_info_row'}>
          <span className={'implementation_tab_common_info_label'}>
            集合時間
          </span>
          <span className={'implementation_tab_common_info_content'}>
            {formik?.values?.gatheringDatetime
              ? formik?.values?.gatheringDatetime.split(' ')[1].substring(0, 5)
              : '--:--'}
          </span>
        </div>

        <div className={'implementation_tab_common_info_row'}>
          <span className={'implementation_tab_common_info_label'}>
            就業時間
          </span>
          <span className={'implementation_tab_common_info_content'}>
            {formik?.values?.workStartDatetime
              ? formik?.values?.workStartDatetime.split(' ')[1].substring(0, 5) +
                ' '
              : '--:-- '}
            〜
            {formik?.values?.workEndDatetime
              ? ' ' + formik?.values?.workEndDatetime.split(' ')[1].substring(0, 5)
              : ' --:--'}
            (休憩：{formik?.values?.restMinute ?? '-'}分)
          </span>
        </div>

        <div className={'implementation_tab_common_info_row'}>
          <span className={'implementation_tab_common_info_label'}>
            ピックアップ担当者
          </span>
          <span className={'implementation_tab_common_info_content'}>
            {formik?.values?.pickup ?? '-'}
          </span>
        </div>

        <div className={'implementation_tab_common_info_row'} style={{marginBottom: '0'}}>
          <span className={'implementation_tab_common_info_label'} style={{display: 'flex', flexWrap: 'wrap', justifyContent: !checkIsSp() ? 'flex-end' : ''}}>
            <span style={{whiteSpace: 'nowrap'}}>アサイン</span>
            <span style={{whiteSpace: 'nowrap'}}>メンバー</span>
          </span>
          <span className={'implementation_tab_common_info_content'}>
            {formik?.values?.assignMember
              ? formik?.values?.assignMember.map(shift => {
                  return (
                    <div style={{marginBottom: '16px'}}>
                      <p style={{marginBottom: '0'}}>{shift.shiftName}</p>
                      <p style={{marginBottom: '0'}}>
                        【駅到着】
                        {shift?.arriveStationDatetime
                          ? shift?.arriveStationDatetime
                              .split(' ')[1]
                              .substring(0, 5)
                          : '--:--'}
                        　 【集合時間】
                        {shift?.gatheringDatetime
                          ? shift?.gatheringDatetime.split(' ')[1].substring(0, 5)
                          : '--:--'}
                        　 【就業時間】
                        {shift?.workStartDatetime
                          ? shift?.workStartDatetime.split(' ')[1].substring(0, 5)
                          : '--:--'}
                        〜
                        {shift.workEndDatetime
                          ? shift.workEndDatetime.split(' ')[1].substring(0, 5)
                          : '--:--'}
                        　 【ピックアップ担当者】
                        {shift?.pickup ?? '-'}
                      </p>

                      <p style={{marginBottom: '0'}}>
                        {shift?.member &&
                          shift.member
                            .map(mem => {
                              return `${mem.name}（${
                                Options.codes.shiftPositionComp[mem.position]
                              }）`;
                            })
                            .join('、')}
                      </p>
                    </div>
                  );
                })
              : '-'}
          </span>
        </div>
      </div>
      <div className="implementation_tab_content">
        {[
          {
            label: '案件詳細',
            placeholder: '案件詳細を入力してください',
            textInputName: 'matterDetail',
            checkboxInputName: 'matterDetailVisibilityFlag',
          },
          {
            label: '前日伝達事項',
            placeholder: '前日伝達事項を入力してください',
            textInputName: 'previousDayInfo',
            checkboxInputName: 'previousDayInfoVisibilityFlag',
          },
          {
            label: '当日詳細',
            placeholder: '当日詳細を入力してください',
            textInputName: 'shiftDayDetail',
            checkboxInputName: 'shiftDayDetailVisibilityFlag',
          },
          {
            label: '個別伝達事項',
            placeholder: '個別伝達事項を入力してください',
            textInputName: 'privateInfo',
            checkboxInputName: 'privateInfoVisibilityFlag',
          },
        ].map(option => (
          <InfoItem {...option} key={option.label} formik={formik} />
        ))}
      </div>
    </>
  );
});

export default ImplementationInfo;
