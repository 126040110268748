import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { handlerFormikSubmit } from '../../../utils/fnUtil';
import { sumBy, toInteger, isNil } from 'lodash';
import {
  fetchMatterDetail,
  fetchMatterQuoteList,
  matterActions,
  matterDetailInit,
  updateMatter,
} from '../../../redux/slice/matter';
import MatterInfo from './tabs/matterInfo';
import MatterRemark from './tabs/matterRemark';
import RegisterBase from '../../../components/registerBase';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import CostSetting from './tabs/costSetting';
import MatterDate from './tabs/matterDate';
import Yup from '../../../utils/yupUtil';
import moment from 'moment';
import './style.scss';

const MatterRegister = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    matterDetail,
    venueList,
    matterCategoryList,
    matterQuoteDetail: { quoteAmount },
    loading,
  } = useSelector(state => state.matter);

  const tabErrorGroup = {
    matterInfo: [
      'clientId',
      'venueId',
      'matterName',
      'matterCategory',
      'matterIndustryPrimary',
      'matterManagerUserId',
      'salesManagerUserId',
    ],
    matterCost: ['matterQuoteAmount', 'matterAmount'],
    matterDate: ['matterDate'],
  };

  const formik = useFormik({
    initialValues: {
      quoteNumber: null,
      clientId: null,
      venueId: null,
      matterName: '',
      deptId: '',
      matterManagerUserId: null,
      salesManagerUserId: null,
      clientManagerUserName: '',
      matterCategory: null,
      matterCategoryOther: '',
      matterIndustryPrimary: null,
      matterIndustryPrimaryOther: '',
      matterIndustrySecondary: null,
      matterQuoteAmount: null,
      matterDate: [],
      preliminary1: null,
      preliminary2: null,
      preliminary3: null,
      preliminary4: null,
      preliminary5: null,
      matterQuote: [],
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      clientId: Yup.number().nullable().numberRequired('[案件情報]取引先'),
      venueId: Yup.number().nullable().numberRequired('[案件情報]開催地'),
      matterName: Yup.string().required().label('[案件情報]案件名').max(100),
      matterCategory: Yup.string()
        .nullable()
        .required()
        .label('[案件情報]案件カテゴリ'),
      matterIndustryPrimary: Yup.string()
        .nullable()
        .required()
        .label('[案件情報]業界'),
      matterManagerUserId: Yup.number()
        .nullable()
        .numberRequired('[案件情報]案件担当者'),
      salesManagerUserId: Yup.number()
        .nullable()
        .numberRequired('[案件情報]営業担当者'),
      matterDate: Yup.array().of(
        Yup.object({
          matterDate: Yup.string().required().label('[案件実施日]案件実施日'),
          matterStartDatetime: Yup.string()
            .required()
            .label('[案件実施日]開始時間'),
          matterEndDatetime: Yup.string()
            .required()
            .label('[案件実施日]終了時間'),
          matterDateRange: Yup.string()
            .nullable()
            .test(
              'dateInvalid',
              '[案件実施日]実施時間:開始時間は終了時間より前の時間を入力してください。',
              (_, context) => {
                const { matterStartDatetime, matterEndDatetime } =
                  context.from[0].value;

                return (
                  matterStartDatetime &&
                  matterEndDatetime &&
                  moment(matterEndDatetime, 'HH:mm').isAfter(
                    moment(matterStartDatetime, 'HH:mm')
                  )
                );
              }
            ),
        })
      ),
      matterDateDulpicate: Yup.string()
        .nullable()
        .test(
          'dateDulpicate',
          '[案件実施日]実施日:日付重複。',
          (_, context) => {
            const matterDate = context.from[0].value.matterDate;

            return (
              new Set(matterDate.map(m => m.matterDate)).size ===
              matterDate.length
            );
          }
        ),
      matterQuote: Yup.array().of(
        Yup.object({
          matterQuoteAmount: Yup.string()
            .nullable()
            .integerString('[案件見積費用設定]案件見積金額'),
        })
      ),
      matterQuoteAmount: Yup.string()
        .nullable()
        .integerString('[案件見積費用設定]案件見積金額')
        .test(
          'amountInvalid',
          '[案件見積費用設定]案件見積金額:見積金額不足です',
          (v, context) =>
            isNil(context.parent.quoteNumber) ||
            quoteAmount -
              toInteger(v) -
              sumBy(
                (context.parent.matterQuote ?? []).map(quote =>
                  toInteger(quote.matterQuoteAmount)
                )
              ) >=
              0
        ),
    }),
    onSubmit: values => {
      const { venueLatitude, venueLongitude } =
        venueList.find(v => v.venueId === values.venueId) ?? {};
      dispatch(
        updateMatter({
          ...values,
          matterDate: values.matterDate.map(date => ({
            ...date,
            matterStartDatetime: `${date.matterDate} ${date.matterStartDatetime}:00`,
            matterEndDatetime: `${date.matterDate} ${date.matterEndDatetime}:00`,
          })),
          matterCategoryOther:
            matterCategoryList.find(m => m.namingId === values.matterCategory)
              ?.naming === 'その他'
              ? values.matterCategoryOther
              : '',
          ...(values.matterIndustryPrimary === '017'
            ? {
                matterIndustrySecondary: null,
              }
            : {
                matterIndustryPrimaryOther: '',
              }),
          matterQuoteAmount: values.matterQuoteAmount ?? null,
          matterQuote: (values.matterQuote ?? []).map(quote => ({
            ...quote,
            matterQuoteAmount: quote.matterQuoteAmount ?? null,
          })),
          venueLatitude,
          venueLongitude,
          updateType: state?.id ? 'update' : 'create',
        })
      )
        .unwrap()
        .then(() => state?.id ? null : navigate(-1));
    },
  });

  const { matterNumber, quoteNumber } = formik.values;

  useEffect(() => {
    dispatch(matterDetailInit());

    return () => dispatch(matterActions.matterDetailClear());
  }, [dispatch]);

  useEffect(() => {
    state?.id &&
      dispatch(
        fetchMatterDetail({
          matterNumber: state.id,
        })
      );
  }, [state?.id]);

  useEffect(() => {
    if (matterDetail) {
      const cpMatterDate = JSON.parse(JSON.stringify(matterDetail?.matterDate));
      const sortedMatterDate = cpMatterDate?.sort((a,b) => a.matterDate < b.matterDate ? -1 : 1);
      formik.setValues({
        ...formik.values,
        ...matterDetail,
        matterDate: sortedMatterDate,
      });
    }
  }, [matterDetail]);

  useEffect(
    () =>
      !isNil(quoteNumber)
        ? dispatch(
            fetchMatterQuoteList({
              quoteNumber,
              matterNumber,
            })
          )
        : dispatch(matterActions.clearMatterQuoteDetail()),
    [quoteNumber]
  );

  const infos = {
    ignoreConvert: true,
    updates: state?.id && [
      {
        label: '新規登録',
        datetime: matterDetail?.createdUserDatetime ? matterDetail?.createdUserDatetime.slice(0, -3) : '-',
        name: matterDetail?.createdUserName ?? '-',
      },
      {
        label: '最終更新(社員)',
        datetime: matterDetail?.updatedUserDatetime ? matterDetail?.updatedUserDatetime.slice(0, -3) : '-',
        name: matterDetail?.updatedUserName ?? '-',
      },
      {
        label: '最終更新(スタッフ)',
        datetime: matterDetail?.updatedStaffDatetime ? matterDetail?.updatedStaffDatetime.slice(0, -3) : '-',
        name: matterDetail?.updatedStaffName ?? '-',
      },
    ],
    detail: {
      tabs: {
        matterInfo: {
          label: '案件情報',
          jsx: () => <MatterInfo formik={formik} stateId={state?.id} />,
        },
        matterCost: {
          label: '案件見積費用設定',
          jsx: () => <CostSetting formik={formik} />,
        },
        matterDate: {
          label: '案件実施日',
          jsx: () => <MatterDate formik={formik} />,
        },
        remark: {
          label: '備考情報',
          jsx: () => <MatterRemark formik={formik} />,
        },
      },
    },
  };

  return (
    <div className="matter_register--base">
      {!loading && (
        <>
          <RegisterBase infos={infos} />
          <div className={'bottom_btn_area'}>
            <div className={'bottom_btn_area--common_actions'}>
              <Button
                text={'戻る'}
                style={Options.buttonStyles.back}
                onClick={() => navigate(-1)}
              />
              <Button
                text={state?.id ? '更新' : '登録'}
                style={Options.buttonStyles.submit}
                onClick={() => {
                  handlerFormikSubmit(formik, tabErrorGroup);
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MatterRegister;
