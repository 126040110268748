import React from 'react';
import { useState } from 'react';
import CustomInput from '../../../../components/customInput';
import './style.scss';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import { checkIsSp } from '../../../../utils/fnUtil';

const DefSet = props => {
  const {
    changeShiftStyle,
    matterOpen,
    changeMatterOpen,
    detailValue,
    formik,
  } = props;
  const labelSty = checkIsSp()
    ? { width: '100%', textAlign: 'left', color: '#646464' }
    : { width: '175px', textAlign: 'right', color: '#646464' };
  const selSty = checkIsSp() ? { width: '100%' } : { width: '291px' };

  const dayArray = [
    Array(29)
      .fill(0)
      .map((v, i) => {
        return i === 28 ? '末' : i + 1;
      }),
    Array(29)
      .fill(0)
      .map((v, i) => {
        return i === 28 ? 99 : i + 1;
      }),
  ];

  const confirmDayArray = new Array(30).fill(0).map((v, i) => {
    return i + 1;
  });

  return (
    <div className="corporate_register--def_set">
      {/* 消費税計算 */}
      <div
        style={
          checkIsSp()
            ? {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '8px',
                height: 'auto',
                width: '100%',
                flexDirection: 'column',
                fontSize: '12px',
              }
            : {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '24px',
                height: '44px',
                width: '600px',
              }
        }
      >
        <span style={{ ...labelSty }}>見積請求消費税計算</span>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            height: '44px',
            width: checkIsSp() ? '100%' : '300px',
          }}
        >
          <span style={{ whiteSpace: 'nowrap' }}>小数点以下</span>

          <CustomFormikInput
            inputType="select"
            label=""
            inputName="calculationTaxFlag"
            selectBoxOptions={['切り上げ', '切り捨て', '四捨五入']}
            initialValue={[0, 1, 2]}
            key={'calculationTaxFlag'}
            placeholder="選択してください"
            showSearch={false}
            style={{
              areaStyle: { width: '100%' },
            }}
            formik={formik}
          />
        </div>
      </div>

      <div
        style={
          checkIsSp()
            ? {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '8px',
                height: 'auto',
                width: '100%',
                flexDirection: 'column',
                fontSize: '12px',
              }
            : {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '24px',
                height: '44px',
                width: '600px',
              }
        }
      >
        <span style={{ ...labelSty }}>給与計算</span>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            height: '44px',
            width: checkIsSp() ? '100%' : '300px',
          }}
        >
          <span style={{ whiteSpace: 'nowrap' }}>小数点以下</span>

          <CustomFormikInput
            inputType="select"
            label=""
            inputName="calculationPayFlag"
            selectBoxOptions={['切り上げ', '切り捨て', '四捨五入']}
            initialValue={[0, 1, 2]}
            key={'calculationPayFlag'}
            placeholder="選択してください"
            showSearch={false}
            style={{
              areaStyle: { width: '100%' },
            }}
            formik={formik}
          />
        </div>
      </div>

      <div className="corporate_register--def_set--attend_closing_day">
        <div style={labelSty}>スタッフ給与確認締日</div>
        <div className="corporate_register--def_set--attend_closing_day--sel">
          毎月
          <CustomFormikInput
            inputType="select"
            inputName="staffPayConfirmClosingDate"
            selectBoxOptions={dayArray[0]}
            initialValue={dayArray[1]}
            key={'staffPayConfirmClosingDate'}
            showSearch={false}
            placeholder="0"
            unit="日"
            style={{
              areaStyle: { width: '132px' },
            }}
            formik={formik}
          />
        </div>
      </div>

      <div className="corporate_register--def_set--attend_closing_day">
        <div style={labelSty}>給与締日</div>
        <div className="corporate_register--def_set--attend_closing_day--sel">
          毎月
          <CustomFormikInput
            inputType="select"
            inputName="payClosingDate"
            selectBoxOptions={dayArray[0]}
            initialValue={dayArray[1]}
            showSearch={false}
            key={'payClosingDate'}
            placeholder="0"
            unit="日"
            style={{
              areaStyle: { width: '132px' },
            }}
            formik={formik}
          />
        </div>
      </div>

      <div className="corporate_register--def_set--attend_closing_day">
        <div style={labelSty}>給与支払予定日</div>
        <div className="corporate_register--def_set--attend_closing_day--sel">
          毎月
          <CustomFormikInput
            inputType="select"
            inputName="payday"
            selectBoxOptions={dayArray[0]}
            initialValue={dayArray[1]}
            showSearch={false}
            key={'payday'}
            placeholder="0"
            unit="日"
            style={{
              areaStyle: { width: '132px' },
            }}
            formik={formik}
          />
        </div>
      </div>

      {/* 見積金額表示 */}
      <CustomFormikInput
        inputType="select"
        label="見積金額表示"
        inputName="quoteAmountViewFlag"
        selectBoxOptions={['税込表示', '税抜表示']}
        initialValue={[0, 1]}
        showSearch={false}
        key={'quoteAmountViewFlag'}
        placeholder="選択してください"
        style={{
          areaStyle: { width: checkIsSp() ? '100%' : '500px' },
          labelStyle: labelSty,
        }}
        formik={formik}
      />

      {/* 請求金額表示 */}
      <CustomFormikInput
        inputType="select"
        label="請求金額表示"
        inputName="invoiceAmountViewFlag"
        selectBoxOptions={['税込表示', '税抜表示']}
        initialValue={[0, 1]}
        showSearch={false}
        key={'invoiceAmountViewFlag'}
        placeholder="選択してください"
        style={{
          areaStyle: { width: checkIsSp() ? '100%' : '500px' },
          labelStyle: labelSty,
        }}
        formik={formik}
      />

      {/* シフト形態 */}
      <div
        id="shift_style"
        onChange={e => {
          changeShiftStyle(e.target.value);
        }}
      >
        <CustomFormikInput
          inputType="radioButton"
          radioButtonTitle="シフト形態"
          inputName="shiftStyleFlag"
          radioButtonLabelAndChecked={[
            { label: '日単位', checked: formik?.values?.shiftStyleFlag == 0 },
            {
              label: '時間単位',
              checked: formik?.values?.shiftStyleFlag == 1,
            },
          ]}
          initialValue={[0, 1]}
          style={{
            labelStyle: labelSty,
          }}
          key={'shiftStyleFlag'}
          formik={formik}
        />
      </div>
      {/*不要なコード????*/}
      {/*/!* 勤怠締日 *!/*/}
      {/*<div className="corporate_register--def_set--attend_closing_day">*/}
      {/*  <div style={labelSty}>勤怠締日</div>*/}
      {/*  <div className="corporate_register--def_set--attend_closing_day--sel">*/}
      {/*    毎月*/}
      {/*    <CustomFormikInput*/}
      {/*      inputType="select"*/}
      {/*      inputName="attendClosingDay"*/}
      {/*      selectBoxOptions={dayArray[0]}*/}
      {/*      initialValue={dayArray[1]}*/}
      {/*      key={'attendClosingDay'}*/}
      {/*      placeholder="0"*/}
      {/*      unit="日"*/}
      {/*      style={{*/}
      {/*        areaStyle: { width: '132px' },*/}
      {/*      }}*/}
      {/*      formik={formik}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*/!* 給与支払予定日 *!/*/}
      {/*<div*/}
      {/*  className="corporate_register--def_set--attend_closing_day"*/}
      {/*  style={{ marginTop: '-10px' }}*/}
      {/*>*/}
      {/*  <div style={labelSty}>給与支払予定日</div>*/}
      {/*  <div className="corporate_register--def_set--attend_closing_day--sel">*/}
      {/*    毎月*/}
      {/*    <CustomFormikInput*/}
      {/*      inputType="select"*/}
      {/*      inputName="payday"*/}
      {/*      selectBoxOptions={dayArray[0]}*/}
      {/*      initialValue={dayArray[1]}*/}
      {/*      key={'payday'}*/}
      {/*      placeholder="0"*/}
      {/*      unit="日"*/}
      {/*      style={{*/}
      {/*        areaStyle: { width: '132px' },*/}
      {/*      }}*/}
      {/*      formik={formik}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*/!* 案件公開 *!/*/}
      {/*<div*/}
      {/*  onChange={e => {*/}
      {/*    changeMatterOpen(e.target.value);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <CustomFormikInput*/}
      {/*    inputType="radioButton"*/}
      {/*    radioButtonTitle="案件公開"*/}
      {/*    inputName="matterOpenFlag"*/}
      {/*    radioButtonLabelAndChecked={[*/}
      {/*      { label: '毎月', checked: formik?.values?.matterOpenFlag == 0 },*/}
      {/*      { label: '毎週', checked: formik?.values?.matterOpenFlag == 1 },*/}
      {/*      { label: '毎日', checked: formik?.values?.matterOpenFlag == 2 },*/}
      {/*    ]}*/}
      {/*    initialValue={[0, 1, 2]}*/}
      {/*    style={{*/}
      {/*      labelStyle: labelSty,*/}
      {/*    }}*/}
      {/*    key={'matterOpenFlag'}*/}
      {/*    formik={formik}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className="corporate_register--def_set--matter_open">*/}
      {/*  <div style={labelSty}></div>*/}
      {/*  <div className="corporate_register--def_set--matter_open--sel">*/}
      {/*    <CustomFormikInput*/}
      {/*      inputType="select"*/}
      {/*      inputName="matterOpenFrequency"*/}
      {/*      selectBoxOptions={*/}
      {/*        formik?.values?.matterOpenFlag == 0*/}
      {/*          ? dayArray[0].map((val) => `${val}日`)*/}
      {/*          : formik?.values?.matterOpenFlag == 1*/}
      {/*          ? [*/}
      {/*              '月曜日',*/}
      {/*              '火曜日',*/}
      {/*              '水曜日',*/}
      {/*              '木曜日',*/}
      {/*              '金曜日',*/}
      {/*              '土曜日',*/}
      {/*              '日曜日',*/}
      {/*            ]*/}
      {/*          : null*/}
      {/*      }*/}
      {/*      initialValue={*/}
      {/*        formik?.values?.matterOpenFlag == 0*/}
      {/*          ? dayArray[1]*/}
      {/*          : formik?.values?.matterOpenFlag == 1*/}
      {/*          ? [0, 1, 2, 3, 4, 5, 6]*/}
      {/*          : null*/}
      {/*      }*/}
      {/*      key={'matterOpenFrequency'}*/}
      {/*      placeholder="選択してください"*/}
      {/*      disabled={formik?.values?.matterOpenFlag === '2'}*/}
      {/*      style={{ areaStyle: selSty }}*/}
      {/*      formik={formik}*/}
      {/*    />*/}
      {/*    <div>に</div>*/}
      {/*    <CustomFormikInput*/}
      {/*      inputType="select"*/}
      {/*      inputName="matterOpenSpan"*/}
      {/*      selectBoxOptions={Array(50)*/}
      {/*        .fill(0)*/}
      {/*        .map((v, i) => `${i + 1}日先`)}*/}
      {/*      initialValue={Array(50)*/}
      {/*        .fill(0)*/}
      {/*        .map((v, i) => i + 1)}*/}
      {/*      key={'matterOpenSpan'}*/}
      {/*      placeholder="選択してください"*/}
      {/*      style={{ areaStyle: selSty }}*/}
      {/*      formik={formik}*/}
      {/*    />*/}
      {/*    <span style={{ width: '224px', display: 'flex', flexShrink: '0' }}>*/}
      {/*      まで実施される案件を公開する*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className={'corporate_register--schedule_settings'}>
        <div className={'corporate_register--schedule_settings_label'}>
          スケジュール設定
        </div>

        <div className={'corporate_register--schedule_settings_items'}>
          <div className="corporate_register--def_set--attend_closing_day">
            <div style={labelSty}>シフト提出</div>
            <div
              className="corporate_register--def_set--attend_closing_day--sel"
              style={
                checkIsSp()
                  ? {
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }
                  : {}
              }
            >
              前月
              <CustomFormikInput
                inputType="select"
                inputName="shiftSubmitDay"
                selectBoxOptions={dayArray[0]}
                initialValue={dayArray[1]}
                showSearch={false}
                key={'shiftSubmitDay'}
                placeholder="0"
                style={{
                  areaStyle: { width: '110px' },
                }}
                formik={formik}
                allowClear={false}
              />
              日をシフト提出の期限とする
            </div>
          </div>

          <div
            className={'corporate_register--schedule_settings_unity'}
            style={
              checkIsSp()
                ? {
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }
                : {}
            }
          >
            <CustomFormikInput
              label={'案件公開'}
              inputType="select"
              inputName="matterOpenLimitDay"
              selectBoxOptions={dayArray[0]}
              initialValue={dayArray[1]}
              showSearch={false}
              key={'matterOpenLimitDay'}
              placeholder="0"
              style={{
                areaStyle: { width: checkIsSp() ? '110px' : '309px' },
                labelStyle: { width: '175px' },
              }}
              formik={formik}
              allowClear={false}
            />
            日までの1ヶ月間で実施する案件は、前月
            <CustomFormikInput
              inputType="select"
              inputName="matterOpenDay"
              selectBoxOptions={dayArray[0]}
              initialValue={dayArray[1]}
              showSearch={false}
              key={'matterOpenDay'}
              placeholder="0"
              style={{
                areaStyle: { width: '110px' },
              }}
              formik={formik}
              allowClear={false}
            />
            日以降に案件公開する
          </div>

          <div
            className={'corporate_register--schedule_settings_unity'}
            style={
              checkIsSp()
                ? {
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }
                : {}
            }
          >
            <CustomFormikInput
              label={'週確認'}
              inputType="select"
              inputName="weekConfirmDay"
              selectBoxOptions={confirmDayArray}
              initialValue={confirmDayArray}
              showSearch={false}
              key={'weekConfirmDay'}
              placeholder="0"
              style={{
                areaStyle: { width: checkIsSp() ? '110px' : '309px' },
                labelStyle: { width: '175px' },
              }}
              formik={formik}
              allowClear={false}
            />
            日前を週確認の期限にする
          </div>

          <div
            className={'corporate_register--schedule_settings_unity'}
            style={
              checkIsSp()
                ? {
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }
                : {}
            }
          >
            <CustomFormikInput
              label={'前日報告'}
              inputType="select"
              inputName="beforeDayConfirmDay"
              selectBoxOptions={confirmDayArray}
              initialValue={confirmDayArray}
              showSearch={false}
              key={'beforeDayConfirmDay'}
              placeholder="0"
              style={{
                areaStyle: { width: checkIsSp() ? '110px' : '309px' },
                labelStyle: { width: '175px' },
              }}
              formik={formik}
              allowClear={false}
            />
            日前を前日報告期限とする
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefSet;
