import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { matterApply } from '../../../redux/slice/staffMatter';
import { pick } from 'lodash-es';
import Button from '../../button';
import Options from '../../../constants/options';
import './style.scss';
import { checkIsSp } from '../../../utils/fnUtil';
import { useEffect } from 'react';

const RecruitMatterDetailModal = ({ selectedMatter, onCancel }) => {
  const { holdingItemList, matterList } = useSelector(
    state => state.staffMatter
  );

  const dispatch = useDispatch();

  const matterDetail = matterList[selectedMatter];

  const items = [
    {
      label: '案件名',
      value: matterDetail?.matterTitle,
    },
    {
      label: '実施日',
      value: matterDetail?.matterDate,
    },
    {
      label: '業務内容',
      value: (
        <>
          <span>{`${matterDetail?.matterJob ?? ''}`}</span>
          <span>{`${matterDetail?.matterDetail ?? ''}`}</span>
        </>
      ),
    },
    {
      label: 'シフト例',
      value: `${
        matterDetail?.workStartDatetime || matterDetail?.workEndDatetime
          ? `${matterDetail?.workStartDatetime ?? ''}~${
              matterDetail?.workEndDatetime ?? ''
            }`
          : ''
      } ${
        matterDetail?.restMinute ? `(休憩${matterDetail?.restMinute}分)` : ''
      }`,
    },
    {
      label: '実施場所',
      value: matterDetail?.placeName,
    },
    {
      label: '開催地最寄駅',
      value: matterDetail?.venueNearestStation,
    },
    ,
    {
      label: '単価',
      value: `${matterDetail?.payAmount ?? ''}(${matterDetail?.payFare ?? ''})`,
    },
    ,
    {
      label: '服装',
      value: (matterDetail?.wear ?? [])
        .map(w => holdingItemList.find(h => h.namingId === w)?.naming)
        .filter(Boolean)
        .join(','),
    },
  ];

  const isApplied = matterDetail?.appliedFlag === 1;

  useEffect(() => {
    const targetElm = document.querySelector('.header--base');
    if (checkIsSp()) {
      targetElm.style.zIndex = 1;
    }
  }, []);

  return (
    <Modal
      visible={selectedMatter >= 0}
      onCancel={onCancel}
      style={{
        minWidth: 600,
      }}
      centered
      footer={null}
      closable={false}
      className="common_modal_base matter_detail_modal recruit_matter_detail_modal"
      bodyStyle={{
        paddingBottom: checkIsSp() ? 40 : 50,
      }}
    >
      <div className={'recruit_matter_detail_modal--title'}>案件詳細</div>

      <div className={'recruit_matter_detail_modal--content'}>
        {items.map(i => (
          <div key={i.label} className={'recruit_matter_detail_modal--info'}>
            <div className={'recruit_matter_detail_modal--info_label'}>
              {i.label}
            </div>
            <div className={'recruit_matter_detail_modal--info_detail'}>
              {i.value}
            </div>
          </div>
        ))}
      </div>

      <div className={'recruit_matter_detail_modal--button'}>
        <Button
          text={'戻る'}
          style={{
            ...Options.buttonStyles.back,
            height: checkIsSp() ? '40px' : '44px',
            fontSize: checkIsSp() ? '12px' : '14px',
          }}
          onClick={onCancel}
        />
        <Button
          text={isApplied ? '応募済み' : 'この案件に応募する'}
          style={{
            ...Options.buttonStyles.recruitMatterSubmit,
            height: checkIsSp() ? '40px' : '44px',
            fontSize: checkIsSp() ? '12px' : '14px',
          }}
          disabled={isApplied}
          onClick={() =>
            dispatch(
              matterApply(
                pick(matterDetail, [
                  'matterNumber',
                  'matterDateNumber',
                  'shiftId',
                ])
              )
            )
          }
        />
      </div>
    </Modal>
  );
};

export default RecruitMatterDetailModal;
