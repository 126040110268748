import React from 'react';
import { memo } from 'react';
import { Table } from 'antd';
import { get } from 'lodash';
import Button from '../button';
import DeleteIcon from '../deleteIcon';
import CustomFormikInput from '../customInput/customFormikInput';
import './style.scss';

const CaculateTable = memo(
  ({ formik, inputName, tableSettings, staffInput, onDeleteTable }) => {
    const columns = [
      {
        title: <span className="caculate_table--empty_title" />,
        render: (_t, _r, i) => {
          return (
            <React.Fragment key={i}>
              {staffInput ? (
                get(formik.values, `${inputName}.row${i + 1}`)
              ) : (
                <CustomFormikInput
                  key={i}
                  formik={formik}
                  inputName={`${inputName}.row${i + 1}`}
                  placeholder="00:00~00:00"
                />
              )}
            </React.Fragment>
          );
        },
      },
      ...[...Array(tableSettings.tableColumns)].map((_, i) => {
        return {
          title: (
            <React.Fragment key={i}>
              {staffInput ? (
                <span className="caculate_table--input_value">
                  {get(formik.values, `${inputName}.column${i + 1}Title`)}
                </span>
              ) : (
                <CustomFormikInput
                  formik={formik}
                  inputName={`${inputName}.column${i + 1}Title`}
                  placeholder="入力してください"
                />
              )}
            </React.Fragment>
          ),
          render: (_g, _h, j) => {
            return (
              <CustomFormikInput
                key={j}
                formik={formik}
                placeholder="0"
                inputName={`${inputName}.column${i + 1}Row${j + 1}`}
                style={{
                  inputStyle: {
                    width: 173,
                  },
                }}
              />
            );
          },
        };
      }),
    ];

    const dataSource = [...Array(tableSettings.tableRows)].map((_, i) => ({
      key: i,
    }));

    return (
      <div className="caculate_table">
        <div style={{ position: 'relative' }}>
          {staffInput ? (
            <div className="caculate_table--title_text">
              {get(formik.values, `${inputName}.tableTitle`)}
            </div>
          ) : (
            <>
              <CustomFormikInput
                formik={formik}
                placeholder="タイトルを入力してください"
                inputName={`${inputName}.tableTitle`}
                style={{
                  areaStyle: {
                    paddingRight: 50,
                  },
                }}
              />
              <DeleteIcon title="表削除" onClick={onDeleteTable} />
            </>
          )}
        </div>
        {tableSettings.totalDisplayFlag === 1 && (
          <div className="caculate_table--total">
            <Button text="合計" />
            <CustomFormikInput
              formik={formik}
              inputName={`${inputName}.columnTotal`}
              placeholder="0"
              style={{
                inputStyle: {
                  height: 52,
                  borderRadius: 0,
                  textAlign: 'right',
                  fontWeight: 'bold',
                },
              }}
            />
          </div>
        )}
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
          className="caculate_table--table"
          scroll={{
            x: 0,
          }}
          summary={() => (
            <Table.Summary.Row className="caculate_table--summary">
              <Table.Summary.Cell>
                <Button text="合計" />
              </Table.Summary.Cell>
              {[...Array(tableSettings.tableColumns)].map((_, i) => (
                <Table.Summary.Cell key={i}>
                  <CustomFormikInput
                    formik={formik}
                    inputName={`${inputName}.column${i + 1}Total`}
                    placeholder="0"
                    style={{
                      inputStyle: {
                        textAlign: 'right',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>
          )}
        />
      </div>
    );
  }
);

export default CaculateTable;
