import React, { useEffect, useState } from 'react';
import ModalBase from '../modalBase';
import CustomInput from '../../../components/customInput';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import './style.scss';
import CustomFormikInput from '../../customInput/customFormikInput';
import { checkIsSp, handlerFormikSubmit } from '../../../utils/fnUtil';
import moment from 'moment/moment';

const unitSty = {
  marginTop: '11px',
  float: 'left',
  textAlign: 'center',
};

const ScheduleModal = ({
  modalVisible,
  changeModalVisible,
  formik,
  editStatus,
  selYear,
  selMonth,
}) => {
  const isSp = checkIsSp();

  const selectStyle = {
    areaStyle: { width: isSp ? '100%' : '45%', marginLeft: isSp ? 0 : '125px' },
    labelStyle: {},
    inputStyle: {},
  };
  const titleDate = formik.values.scheduleDate;
  const scheduleFlag = formik.values.scheduleFlag;

  useEffect(() => {
    if (scheduleFlag === 1) {
      const firstDayNextMonth = new Date(selYear, selMonth + 1, 1);
      const lastDayNextMonth = new Date(selYear, selMonth + 2, 0);
      formik.setFieldValue('submitShiftPlanFrom', moment(firstDayNextMonth)?.format('YYYY/M/D'));
      formik.setFieldValue('submitShiftPlanTo', moment(lastDayNextMonth)?.format('YYYY/M/D'));
    }
  }, [scheduleFlag]);

  if (modalVisible) {
    return (
      <ModalBase
        dialogStyle={{
          display: 'flex',
          flexFlow: 'column',
          gap: '64px',
          alignItems: 'center',
          justifyContent: 'center',
          padding: isSp ? '40px 16px' : '64px 40px',
          width: isSp ? 'calc(90% - 32px)' : '80%',
          height: 'fit-content',
          minWidth: isSp ? '' : '720px',
        }}
        modalVisible={true}
      >
        <div className="schedule_modal--startest">
          <div className={'import_modal--title_area'} style={isSp ? {fontSize: "18px", justifyContent: 'center'} : {}}>
            <p>スケジュール設定</p>
          </div>

          <div className="schedule_modal--main">
            <div className="schedule_modal--main_content">
              <CustomInput
                inputType={'select'}
                // formik={formik}
                key={'scheduleFlag'}
                label={''}
                labelRequired={false}
                inputName={'scheduleFlag'}
                initialValue={['シフト提出', '案件公開', '週確認', '前日報告']}
                selectBoxOptions={[
                  'シフト提出',
                  '案件公開',
                  '週確認',
                  '前日報告',
                ]}
                selectedSelectBox={
                  formik.values.scheduleFlag == 1
                    ? 'シフト提出'
                    : formik.values.scheduleFlag == 2
                    ? '案件公開'
                    : formik.values.scheduleFlag == 3
                    ? '週確認'
                    : '前日報告'
                }
                style={selectStyle}
                onChange={e => {
                  if (e === 'シフト提出') {
                    document.getElementById('shift').style.display = '';
                    document.getElementById('matter').style.display = 'none';
                    document.getElementById('week').style.display = 'none';
                    document.getElementById('before').style.display = 'none';
                    formik.setValues({
                      ...formik.values,
                      isEdit: editStatus.shift,
                      scheduleFlag: 1,
                    });
                  }
                  if (e === '案件公開') {
                    document.getElementById('shift').style.display = 'none';
                    document.getElementById('matter').style.display = '';
                    document.getElementById('week').style.display = 'none';
                    document.getElementById('before').style.display = 'none';
                    formik.setValues({
                      ...formik.values,
                      isEdit: editStatus.matter,
                      scheduleFlag: 2,
                    });
                  }
                  if (e === '週確認') {
                    document.getElementById('shift').style.display = 'none';
                    document.getElementById('matter').style.display = 'none';
                    document.getElementById('week').style.display = '';
                    document.getElementById('before').style.display = 'none';
                    formik.setValues({
                      ...formik.values,
                      isEdit: editStatus.week,
                      scheduleFlag: 3,
                    });
                  }
                  if (e === '前日報告') {
                    document.getElementById('shift').style.display = 'none';
                    document.getElementById('matter').style.display = 'none';
                    document.getElementById('week').style.display = 'none';
                    document.getElementById('before').style.display = '';
                    formik.setValues({
                      ...formik.values,
                      isEdit: editStatus.before,
                      scheduleFlag: 4,
                    });
                  }
                  document.getElementById('selectType').value = e;
                }}
              />
              <input
                type="hidden"
                id="selectType"
                value={
                  formik.values.scheduleFlag == 1
                    ? 'シフト提出'
                    : formik.values.scheduleFlag == 2
                    ? '案件公開'
                    : formik.values.scheduleFlag == 3
                    ? '週確認'
                    : '前日報告'
                }
              />
            </div>
            <div
              id="shift"
              className="schedule_modal--main_content"
              style={{
                display: formik.values.scheduleFlag == 1 ? '' : 'none',
                marginLeft: isSp ? 0 : '125px',
                width: isSp ? '100%' : '50%',
              }}
            >
              <div className="schedule_modal--main_date--pick">
                <CustomFormikInput
                  inputType="inputDate"
                  inputName={'submitShiftPlanFrom'}
                  key={'submitShiftPlanFrom'}
                  formik={formik}
                  placeholder="YYYY/MM/DD"
                  unit="〜"
                  style={{
                    areaStyle: isSp ? {width: 'calc(50% + 5px)'} : {}
                  }}
                  disabled={true}
                />
                <CustomFormikInput
                  inputType="inputDate"
                  inputName={'submitShiftPlanTo'}
                  key={'submitShiftPlanTo'}
                  formik={formik}
                  placeholder="YYYY/MM/DD"
                  style={{
                    areaStyle: isSp ? {width: 'calc(50% - 15px)'} : {}
                  }}
                  unit={
                    !isSp &&
                    <div>
                      <p>分のシフトの提出期限を&nbsp;&nbsp;</p>
                      <b>{titleDate}</b>
                      <p>&nbsp;&nbsp;とする</p>
                    </div>
                  }
                  disabledDate={formik?.values?.submitShiftPlanFrom}
                  disabled={true}
                />
              </div>
              {isSp &&
                <div className={'schedule_modal_sp--text'}>
                  <p>分のシフトの提出期限を&nbsp;&nbsp;</p>
                  <b>{titleDate}&nbsp;&nbsp;</b>
                  <p>とする</p>
                </div>
              }
            </div>
            <div
              id="matter"
              className="schedule_modal--main_content"
              style={{
                display: formik.values.scheduleFlag == 2 ? '' : 'none',
                marginLeft: isSp ? 0 : '125px',
                width: isSp ? '100%' : '50%',
                gap: '8px'
              }}
            >
              <CustomFormikInput
                inputType="inputDate"
                inputName={'openMatterFrom'}
                key={'openMatterFrom'}
                formik={formik}
                placeholder="YYYY/MM/DD"
                unit="〜"
                style={{
                  areaStyle: isSp ? {width: 'calc(50% + 5px)'} : {}
                }}
              />
              <CustomFormikInput
                inputType="inputDate"
                inputName={'openMatterTo'}
                key={'openMatterTo'}
                formik={formik}
                placeholder="YYYY/MM/DD"
                style={{
                  areaStyle: isSp ? {width: 'calc(50% - 15px)'} : {}
                }}
                unit={
                  !isSp &&
                    <div>
                      <p>までに実施する案件は&nbsp;&nbsp;</p>
                      <b>{titleDate}</b>
                      <p>&nbsp;&nbsp;に案件を公開する</p>
                    </div>
                }
                disabledDate={formik?.values?.openMatterFrom}
              />
              {isSp &&
                <div className={'schedule_modal_sp--text'}>
                  <p>までに実施する案件は&nbsp;&nbsp;</p>
                  <b>{titleDate}&nbsp;&nbsp;</b>
                  <p>に案件を公開する</p>
                </div>
              }
            </div>
            <div
              id="week"
              className="schedule_modal--main_content"
              style={{
                display: formik.values.scheduleFlag == 3 ? '' : 'none',
                marginLeft: isSp ? 0 : '125px',
                width: isSp ? '100%' : '50%',
                gap: '8px'
              }}
            >
              <CustomFormikInput
                inputType="inputDate"
                inputName={'weekConfirmFrom'}
                key={'weekConfirmFrom'}
                formik={formik}
                placeholder="YYYY/MM/DD"
                unit="〜"
                style={{
                  areaStyle: isSp ? {width: 'calc(50% + 5px)'} : {}
                }}
              />
              <CustomFormikInput
                inputType="inputDate"
                placeholder="YYYY/MM/DD"
                style={{
                  areaStyle: isSp ? {width: 'calc(50% - 15px)'} : {}
                }}
                unit={
                  !isSp &&
                    <div>
                      <p>までに実施する案件は&nbsp;&nbsp;</p>
                      <b>{titleDate}</b>
                      <p>&nbsp;&nbsp;を週確認の期限とする</p>
                    </div>
                }
                inputName={'weekConfirmTo'}
                key={'weekConfirmTo'}
                formik={formik}
                disabledDate={formik?.values?.weekConfirmFrom}
              />
              {isSp &&
                <div className={'schedule_modal_sp--text'}>
                  <p>までに実施する案件は&nbsp;&nbsp;</p>
                  <b>{titleDate}&nbsp;&nbsp;</b>
                  <p>を週確認の期限とする</p>
                </div>
              }
            </div>
            <div
              id="before"
              className="schedule_modal--main_content"
              style={{
                display: formik.values.scheduleFlag == 4 ? '' : 'none',
                marginLeft: isSp ? 0 : '125px',
                width: isSp ? '100%' : '50%',
                gap: '8px'
              }}
            >
              <div className="schedule_modal--main_date--pick">
                <CustomFormikInput
                  inputType="inputDate"
                  inputName={'beforeDayConfirmFrom'}
                  key={'beforeDayConfirmFrom'}
                  formik={formik}
                  placeholder="YYYY/MM/DD"
                  unit="〜"
                  style={{
                    areaStyle: isSp ? {width: 'calc(50% + 5px)'} : {}
                  }}
                />
                <CustomFormikInput
                  inputType="inputDate"
                  inputName={'beforeDayConfirmTo'}
                  key={'beforeDayConfirmTo'}
                  formik={formik}
                  placeholder="YYYY/MM/DD"
                  style={{
                    areaStyle: isSp ? {width: 'calc(50% - 15px)'} : {}
                  }}
                  unit={
                    !isSp &&
                      <div>
                        <p>までに実施する案件の前日報告期日は&nbsp;&nbsp;</p>
                        <b>{titleDate}</b>
                        <p>&nbsp;&nbsp;とする</p>
                      </div>
                  }
                  disabledDate={formik?.values?.beforeDayConfirmFrom}
                />
              </div>
              {isSp &&
                <div className={'schedule_modal_sp--text'}>
                  <p>までに実施する案件の前日報告期日は&nbsp;&nbsp;</p>
                  <b>{titleDate}&nbsp;&nbsp;</b>
                  <p>とする</p>
                </div>
              }
            </div>
          </div>
          <div className="schedule_modal--btndiv">
            <Button
              text={'戻る'}
              style={Options.buttonStyles.back}
              onClick={changeModalVisible}
            />
            {formik.values.batchFlag == 0 && (
              <Button
                text={formik.values.isEdit ? '更新' : '登録'}
                style={Options.buttonStyles.submit}
                onClick={() => handlerFormikSubmit(formik)}
              />
            )}
          </div>
        </div>
      </ModalBase>
    );
  } else {
    return <></>;
  }
};

export default ScheduleModal;
