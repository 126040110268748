import { memo } from 'react';
import './style.scss';

const ProfileInfo = memo(({ image, position, id, name }) => (
  <div className="profile-info">
    <div>
      <img src={image ?? 'https://protech.tenco.co.jp/images/common/icon_no_image.png'} className="profile-image" />
    </div>
    <div className="profile-info-column">
      <span className="position-text">{position}</span>
      <span className="display-linebreak">{`${id}\t${name}`}</span>
    </div>
  </div>
));

export default ProfileInfo;
