import { memo } from 'react';
import Icons from '../../constants/icons';
import DeleteIcon from '../deleteIcon';
import Checkbox from '../checkbox';
import BaseUpload from '.';
import './style.scss';

const ImageUploader = memo(
  ({ image, onDelete, onAddImage, imageChecked, onCheckChange }) => {
    return image ? (
      <div className="image_uploader">
        <img src={image} className="image_uploader--upload-image" />
        {onCheckChange && (
          <Checkbox checked={imageChecked} onChange={onCheckChange} />
        )}
        <DeleteIcon onClick={onDelete} />
      </div>
    ) : (
      <BaseUpload onChange={onAddImage}>
        <div className="image_uploader">
          <img
            src={Icons.icon.photoB}
            className="image_uploader--empty_image"
          />
        </div>
      </BaseUpload>
    );
  }
);

export default ImageUploader;
