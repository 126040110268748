import { memo } from 'react';
import { get } from 'lodash';
import {
  handlerFormikFieldChange,
  stringNumberFormat,
} from '../../utils/fnUtil';
import CustomInput from '.';

const CustomFormikInput = memo(
  ({
    formik,
    onChange,
    showSearch = true,
    usePopContainer = true,
    allowClear = true,
    formatNumber,
    extraOnChange,
    relativeInputName,
    replaceNumber,
    otherErrorInputName = [],
    ...rest
  }) => {
    const value = get(formik?.values, rest.inputName);

    return (
      <CustomInput
        {...rest}
        setFieldValue={(inputName, v) => {
          formik.setFieldValue(inputName, v);
          extraOnChange?.(v);
        }}
        value={
          !rest.inputType
            ? value === null
              ? ''
              : formatNumber
              ? stringNumberFormat(value)
              : value
            : value
        }
        selectedSelectBox={value}
        error={
          get(formik?.errors, rest.inputName) ||
          otherErrorInputName.some(o => get(formik?.errors, o))
        }
        touched={
          (relativeInputName &&
            relativeInputName.some(r => !!get(formik?.touched, r))) ||
          get(formik?.touched, rest.inputName)
        }
        onChange={
          onChange ??
          (['select', 'selectAddBtn', 'multiSelect', 'comboBox'].includes(
            rest.inputType
          )
            ? v => {
                handlerFormikFieldChange(formik, rest.inputName, v ?? null);
                extraOnChange?.(v);
              }
            : formik.handleChange)
        }
        useValue={true}
        showSearch={showSearch}
        usePopContainer={usePopContainer}
        allowClear={allowClear}
      />
    );
  }
);

export default CustomFormikInput;
