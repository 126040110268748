import React, { useEffect, useState } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import {
  bankingCodeFetch,
  bankingFetch,
  staffActions,
} from '../../../redux/slice/staff';
import { useUpdateEffect } from 'react-use';

const MyPageForSpPage3 = ({ spFormik, currentPage }) => {
  const [certificationsList, setCertificationsList] = useState([]);

  const getCertifications = async () => {
    const res = await fetchRequest(apiUrl.naming.list, {
      namingClassificationId:
        'Index,StaffPreliminary1,StaffPreliminary2,StaffPreliminary3,' +
        'StaffPreliminary4,StaffPreliminary5,Certification,Holdingitem,' +
        'RetireReason,RecruitingMedia',
      count: 999,
      sort: 'namingIdAsc',
    });

    const list = res?.item
      ?.filter(n => n?.namingClassificationId == 'Certification')
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      });

    setCertificationsList(list);
  };

  useEffect(() => {
    dispatch(bankingCodeFetch());
    getCertifications();
  }, []);

  const { bankName, bankBranchName } = spFormik.values;
  const { bankingList, branchesList } = useSelector(state => state.staff);
  const dispatch = useDispatch();

  useUpdateEffect(() => {
    if (bankName !== null) {
      handlerFormikFieldChange(
        spFormik,
        'bankCode',
        bankingList?.find(b => b?.label === bankName)?.value ?? spFormik?.values?.bankCode
      );
      dispatch(
        bankingFetch(bankingList?.find(b => b?.label === bankName)?.value ?? spFormik?.values?.bankCode)
      );
    } else {
      dispatch(staffActions.bankReset());
    }
  }, [bankName]);

  useUpdateEffect(() => {
    if (bankBranchName) {
      handlerFormikFieldChange(
        spFormik,
        'bankBranchCode',
        branchesList?.find(b => b?.label === bankBranchName)?.value ?? spFormik?.values?.bankBranchCode
      );
    }
  }, [bankBranchName]);

  return (
    <div
      className={'myPageSp--info_base page3'}
      style={{ display: currentPage === 3 ? 'flex' : 'none' }}
    >
      <div className={'myPageSp--info_title'}>
        <p>
          <span></span>
          学歴・資格など
        </p>
      </div>

      <CustomFormikInput
        inputType={'select'}
        showSearch={false}
        allowClear={false}
        mode={'multiple'}
        inputName={'certifications'}
        label={'資格'}
        labelRequired={false}
        initialValue={certificationsList?.map(h => h?.value)}
        selectBoxOptions={certificationsList?.map(h => h?.label)}
        placeholder={'選択してください'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '100%' },
        }}
      />

      <CustomFormikInput
        radioButtonTitle={'職業'}
        inputName={'profession'}
        initialValue={[0, 1]}
        inputType={'radioButton'}
        className={{ areaClass: 'column' }}
        formik={spFormik}
        radioButtonLabelAndChecked={[
          { label: '学生', checked: spFormik?.values?.profession == 0 },
          {
            label: 'その他(社会人、主婦など)',
            checked: spFormik?.values?.profession == 1,
          },
        ]}
      />

      <CustomFormikInput
        inputType={'input'}
        inputName={'schoolName'}
        label={'学校名'}
        placeholder={'学校名を入力してください'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '100%' },
        }}
        disabled={spFormik?.values?.profession != 0}
      />

      <CustomFormikInput
        inputType={'input'}
        inputName={'admissionYear'}
        label={'入学年'}
        placeholder={'入学年を入力してください'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '100%' },
        }}
        unit={'年'}
        disabled={spFormik?.values?.profession != 0}
      />

      <div className={'myPageSp--info_title'}>
        <p>
          <span></span>
          給与受取情報
        </p>
      </div>

      <CustomFormikInput
        radioButtonTitle={'給与受取方法'}
        labelRequired={true}
        inputName={'payMethod'}
        initialValue={[0, 1]}
        inputType={'radioButton'}
        className={{ areaClass: 'column' }}
        formik={spFormik}
        radioButtonLabelAndChecked={[
          { label: '銀行振込', checked: spFormik?.values?.payMethod == 0 },
          { label: '手渡し', checked: spFormik?.values?.payMethod == 1 },
        ]}
      />

      <CustomFormikInput
        inputType={'select'}
        showSearch={true}
        allowClear={false}
        inputName={'bankName'}
        label={'金融機関名'}
        labelRequired={true}
        initialValue={bankingList?.map(b => b?.label)}
        selectBoxOptions={bankingList?.map(b => b?.label)}
        placeholder={'選択してください'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '100%' },
        }}
      />

      <CustomFormikInput
        inputType="input"
        label="銀行コード"
        labelRequired={true}
        inputName="bankCode"
        key={'bankCode'}
        formik={spFormik}
        placeholder="0000"
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '50%' },
        }}
        disabled={true}
      />

      <CustomFormikInput
        inputType="select"
        label="支店名"
        labelRequired={true}
        allowClear={false}
        inputName="bankBranchName"
        key={'bankBranchName'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        placeholder="支店名を入力してください"
        initialValue={branchesList?.map(b => b?.label)}
        selectBoxOptions={branchesList?.map(b => b?.label)}
        style={{
          areaStyle: { width: '100%' },
        }}
      />

      <CustomFormikInput
        inputType="input"
        label="支店コード"
        labelRequired={true}
        inputName="bankBranchCode"
        key={'bankBranchCode'}
        formik={spFormik}
        className={{ areaClass: 'column' }}
        placeholder="0000"
        style={{
          areaStyle: { width: '50%' },
        }}
        disabled={true}
      />

      <CustomFormikInput
        inputType="radioButton"
        radioButtonTitle="口座種別"
        labelRequired={true}
        inputName="bankAccountFlag"
        key={'bankAccountFlag'}
        className={{ areaClass: 'column' }}
        formik={spFormik}
        radioButtonLabelAndChecked={[
          {
            label: '普通',
            checked: spFormik?.values?.bankAccountFlag
              ? spFormik?.values?.bankAccountFlag == 1
              : true,
          },
          {
            label: '当座',
            checked: spFormik?.values?.bankAccountFlag
              ? spFormik?.values?.bankAccountFlag == 2
              : false,
          },
          {
            label: '貯蓄',
            checked: spFormik?.values?.bankAccountFlag
              ? spFormik?.values?.bankAccountFlag == 4
              : false,
          },
        ]}
        initialValue={[1, 2, 4]}
      />

      <CustomFormikInput
        inputType="input"
        label="口座番号"
        labelRequired={true}
        inputName="bankAccountNumber"
        key={'bankAccountNumber'}
        formik={spFormik}
        placeholder="0000000"
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '100%' },
        }}
      />

      <CustomFormikInput
        inputType="input"
        label="口座名義(カナ)"
        labelRequired={true}
        inputName="bankAccountName"
        key={'bankAccountName'}
        formik={spFormik}
        placeholder="口座名義(カナ)を入力してください"
        className={{ areaClass: 'column' }}
        style={{
          areaStyle: { width: '100%' },
        }}
      />
    </div>
  );
};

export default MyPageForSpPage3;
