import './style.scss';
import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import React, { useEffect, useState } from 'react';
import CustomInput from '../../../components/customInput';
import StaffPayDetailInputs from './staffPayInputInfos';
import StaffPlanShiftInfos from '../../staffPlan/register/inputInfos';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import { checkIsSp } from '../../../utils/fnUtil';
import Copyright from '../../../components/copyright';
import { Tabs } from 'antd';
import { globalActions } from '../../../redux/slice/global';
import { useDispatch, useSelector } from 'react-redux';
import { generateYears } from '../../staffPlan/forSp';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import { cloneDeep } from 'lodash';

const StaffPayDetail = () => {
  const isSp = checkIsSp();
  const { staffId } = useSelector(state => state.account);
  const { isOpenSideMenu } = useSelector(state => state.global);

  const [userPayDetail, setUserPayDetail] = useState({
    netPayAmount: null,
    payTotalAmount: null,
    advancePayTotalAmount: null,
    incomeTaxAmount: null,
    beforePayTotalAmount: null,
  });

  // 年ごと給与リスト仮データ
  const [userYearPayList, setUserYearPayList] = useState({
    jan: null,
    feb: null,
    mar: null,
    apr: null,
    may: null,
    jun: null,
    jul: null,
    aug: null,
    sep: null,
    oct: null,
    nov: null,
    dec: null,
    incomeTaxAmount: null,
    totalPay: null,
  });

  const monthNum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const monthStr = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];

  const nowDate = new Date();
  const nowYear = nowDate.getFullYear();
  const nowMonth = nowDate.getMonth() + 1;

  //検索部分の年セレクトボックスのオプション
  let selectBoxYearOptions = [];
  [...Array(11)].map((_, idx) => {
    selectBoxYearOptions.push(nowYear + idx);
    selectBoxYearOptions.push(nowYear - idx);
  });

  selectBoxYearOptions = Array.from(new Set(selectBoxYearOptions));
  selectBoxYearOptions.sort((a, b) => a - b);

  let selectBoxMonthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const [searchYearYear, setSearchYearYear] = useState(nowYear);
  const [searchYear, setSearchYear] = useState(nowYear);
  const [searchMonth, setSearchMonth] = useState(nowMonth);

  const displayShiftMonth = async () => {
    const res = await fetchRequest(
      apiUrl.staffPay.detail,
      { year: searchYear, month: searchMonth, staffId: staffId },
      'GET'
    );

    setUserPayDetail(res.detail);
  };

  const displayShiftYear = async () => {
    const res = await fetchRequest(
      apiUrl.staffPay.yearList,
      { year: searchYearYear, staffId: staffId },
      'GET'
    );

    if (res.item[0]) {
      let formatList = cloneDeep(userYearPayList);
      Object.keys(res.item[0]).forEach(key => {
        formatList[key] = res.item[0][key];
      });

      setUserYearPayList(formatList);
    } else {
      setUserYearPayList({
        jan: null,
        feb: null,
        mar: null,
        apr: null,
        may: null,
        jun: null,
        jul: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        incomeTaxAmount: null,
        totalPay: null,
      });
    }
  };

  const tableTitle = ['総支給額', '給与手当', '立替金', '所得税', '前払金'];

  const tableYearTitle = ['年間合計金額', '所得税合計', '支給月', '支給額'];

  const payDetail = () => (
    <div className={'staff_pay_detail--area'}>
      {/*検索エリア*/}
      <div className={'staff_pay_detail--search'}>
        <div className={'staff_pay_detail--search_input'}>
          <CustomInput
            {...StaffPayDetailInputs.search[0]}
            initialValue={selectBoxYearOptions}
            selectBoxOptions={selectBoxYearOptions}
            selectedSelectBox={searchYear}
            onChange={e => {
              setSearchYear(e);
            }}
          />
          <CustomInput
            {...StaffPayDetailInputs.search[1]}
            initialValue={selectBoxMonthOptions}
            selectBoxOptions={selectBoxMonthOptions}
            selectedSelectBox={searchMonth}
            onChange={e => {
              setSearchMonth(e);
            }}
          />
        </div>

        <div className={'staff_pay_detail--search_button'}>
          <Button
            text={'表示'}
            style={Options.buttonStyles.staffPayDetailButton}
            onClick={displayShiftMonth}
          />
        </div>
      </div>

      {userPayDetail.netPayAmount && employmentType == 0 ? (
        <div className={'staff_pay_detail--pay_detail'}>
          {/*総支給額のエリア*/}
          <div className={'staff_pay_detail--item_box'}>
            <div className={'staff_pay_detail--item_box_title'}>
              {tableTitle[0]}
            </div>
            <div
              className={
                'staff_pay_detail--item_box_contents staff_pay_detail--solo_contents'
              }
              style={{ font: 'normal normal bold 16px/20px Noto Sans CJK JP' }}
            >
              {userPayDetail.netPayAmount
                ? userPayDetail.netPayAmount.toLocaleString()
                : '0'}
              円
            </div>
          </div>

          {/*総支給額以外のエリア*/}
          <div className={'staff_pay_detail--item_box_unity'}>
            <div className={'staff_pay_detail--item_box_left'}>
              <div className={'staff_pay_detail--item_box_title'}>
                {tableTitle[1]}
              </div>

              <div className={'staff_pay_detail--item_box_contents'}>
                {userPayDetail.payTotalAmount
                  ? userPayDetail.payTotalAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>

            <div className={'staff_pay_detail--item_box_center'}>
              <div className={'staff_pay_detail--item_box_title'}>
                {tableTitle[2]}
              </div>

              <div className={'staff_pay_detail--item_box_contents'}>
                <div
                  className={'staff_pay_detail--item_box_center_item'}
                  style={{ borderRight: '1px solid #A0AAB8' }}
                >
                  {userPayDetail.advancePayTotalAmount
                    ? userPayDetail.advancePayTotalAmount.toLocaleString()
                    : '0'}
                  円
                </div>
              </div>
            </div>

            <div className={'staff_pay_detail--item_box_center'}>
              <div className={'staff_pay_detail--item_box_title'}>
                {tableTitle[3]}
              </div>

              <div
                className={'staff_pay_detail--item_box_contents'}
                style={{ borderRight: '1px solid #A0AAB8' }}
              >
                <div
                  className={'staff_pay_detail--item_box_center_item'}
                  style={{ borderLeft: '1px solid #A0AAB8' }}
                >
                  {userPayDetail.incomeTaxAmount
                    ? userPayDetail.incomeTaxAmount.toLocaleString()
                    : '0'}
                  円
                </div>
              </div>
            </div>

            <div className={'staff_pay_detail--item_box_right'}>
              <div className={'staff_pay_detail--item_box_title'}>
                {tableTitle[4]}
              </div>

              <div className={'staff_pay_detail--item_box_contents'}>
                {userPayDetail.beforePayTotalAmount
                  ? userPayDetail.beforePayTotalAmount.toLocaleString()
                  : '0'}
                円
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={'staff_pay_detail--no-data'}>
          確定している明細がありません
        </div>
      )}
    </div>
  );

  const payYearInfo = () => (
    <div className={'staff_pay_detail--area'}>
      {/*検索エリア*/}
      <div className={'staff_pay_detail--search'}>
        <div className={'staff_pay_detail--search_input_short'}>
          <CustomInput
            {...StaffPayDetailInputs.search[0]}
            initialValue={selectBoxYearOptions}
            selectBoxOptions={selectBoxYearOptions}
            selectedSelectBox={searchYearYear}
            onChange={e => {
              setSearchYearYear(e);
            }}
          />
        </div>

        <div className={'staff_pay_detail--search_button'}>
          <Button
            text={'表示'}
            style={Options.buttonStyles.staffPayDetailButton}
            onClick={displayShiftYear}
          />
        </div>
      </div>

      {userYearPayList.totalPay && employmentType == 0 ? (
        <div className={'staff_pay_detail--pay_year_area'}>
          <div className={'staff_pay_detail--total_info'}>
            <div className={'staff_pay_detail--total_info_left'}>
              <div className={'staff_pay_detail--total_info_left_title'}>
                {tableYearTitle[0]}
              </div>

              <div
                className={'staff_pay_detail--total_info_left_contents'}
                style={{ borderRadius: '0 0 0 3px' }}
              >
                <div
                  className={'staff_pay_detail--total_info_left_contents_item'}
                >
                  {userYearPayList.totalPay.toLocaleString()}円
                </div>
              </div>
            </div>

            <div className={'staff_pay_detail--total_info_right'}>
              <div className={'staff_pay_detail--total_info_left_title_right'}>
                {tableYearTitle[1]}
              </div>

              <div
                className={'staff_pay_detail--total_info_left_contents'}
                style={{
                  borderRight: '1px solid #A0AAB8',
                  borderRadius: '0 0 3px 0',
                }}
              >
                {userYearPayList.incomeTaxAmount.toLocaleString()}円
              </div>
            </div>
          </div>

          {/*左の要素*/}
          <div className={'staff_pay_detail--pay_year_list'}>
            <div className={'staff_pay_detail--pay_year_list_contents'}>
              <div className={'staff_pay_detail--pay_year_list_title'}>
                {tableYearTitle[2]}
              </div>

              {monthNum.map((month, idx) => {
                return (
                  <div
                    key={idx}
                    className={'staff_pay_detail--pay_year_list_items'}
                    style={{
                      borderBottom: idx === 11 && '1px solid #A0AAB8',
                      borderRadius: idx === 11 && '0 0 0 3px',
                    }}
                  >
                    {monthNum[idx]}月
                  </div>
                );
              })}
            </div>

            <div
              className={'staff_pay_detail--pay_year_list_contents'}
              style={{ width: '56%' }}
            >
              <div
                className={'staff_pay_detail--pay_year_list_title'}
                style={{
                  width: '100%',
                  borderRadius: '0 3px 0 0',
                  borderRight: 'none',
                }}
              >
                {tableYearTitle[3]}
              </div>

              {monthStr.map((month, idx) => {
                return (
                  <div
                    key={month}
                    className={'staff_pay_detail--pay_year_list_items'}
                    style={{
                      width: '100%',
                      borderLeft: 'none',
                      borderBottom: idx === 11 && '1px solid #A0AAB8',
                      borderRadius: idx === 11 && '0 0 3px 0',
                    }}
                  >
                    {userYearPayList[month]
                      ? userYearPayList[month].toLocaleString()
                      : '0'}
                    円
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className={'staff_pay_detail--no-data'}>
          確定している明細がありません
        </div>
      )}
    </div>
  );

  const myPageInfos = {
    detail: {
      tabs: {
        payDetail: {
          label: '給与明細',
          jsx: payDetail,
        },
        yearPays: {
          label: '年間給与',
          jsx: payYearInfo,
        },
      },
    },
  };

  //要素を修正する処理
  useEffect(() => {
    let targetElm = document.getElementsByClassName('register_base--container');
    let targetNoteSpan = document.getElementsByClassName(
      'register_base--container_notice'
    );
    let targetRegisterBase = document.getElementsByClassName(
      'register_base--container'
    );

    Array.from(targetNoteSpan).forEach(elm => {
      elm.remove();
    });

    Array.from(targetElm).forEach(elm => {
      elm.style.margin = '40px auto 0';
    });

    Array.from(targetRegisterBase).forEach(elm => {
      elm.style.marginBottom = '40px';
    });
  });

  useEffect(() => {
    displayShiftYear();
    displayShiftMonth();
    handleShowYear();
    handleShowMonth();
  }, [])

  const { TabPane } = Tabs;
  const tabs = myPageInfos.detail.tabs;
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.global);

  const [showYear, setShowYear] = useState(new Date().getFullYear());
  const [showMonth, setShowMonth] = useState(new Date().getMonth() + 1);
  const [showYearYear, setShowYearYear] = useState(new Date().getFullYear());
  const { employmentType } = useSelector(state => state.account);

  const handleShowMonth = async () => {
    const res = await fetchRequest(
      apiUrl.staffPay.detail,
      { year: showYear, month: showMonth, staffId: staffId },
      'GET'
    );

    setUserPayDetail(res.detail);
  };

  const handleShowYear = async () => {
    const res = await fetchRequest(
      apiUrl.staffPay.yearList,
      { year: showYearYear, staffId: staffId },
      'GET'
    );

    if (res.item[0]) {
      let formatList = cloneDeep(userYearPayList);
      Object.keys(res.item[0]).forEach(key => {
        formatList[key] = res.item[0][key];
      });

      setUserYearPayList(formatList);
    } else {
      setUserYearPayList({
        jan: null,
        feb: null,
        mar: null,
        apr: null,
        may: null,
        jun: null,
        jul: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        incomeTaxAmount: null,
        totalPay: null,
      });
    }
  };

  if (isSp) {
    return (
      <div style={{ height: 'fit-content' }}>
        <div className={'staffPayDetail_forSp--wrap'}>
          <div className={'staffPayDetail_forSp--base'} id={'top'}>
            <Tabs
              defaultActiveKey={'payDetail'}
              activeKey={activeTab ? activeTab : 'payDetail'}
              onChange={tab => {
                dispatch(globalActions.changeActiveTab(tab));
              }}
            >
              <TabPane tab={'給与明細'} key={'payDetail'}>
                <div
                  className={'shiftForSp--static_date'}
                  style={{ marginTop: '8px' }}
                >
                  <CustomInput
                    inputType={'select'}
                    inputName={'year_sp_summary'}
                    key={showYear ? showYear : 'year_sp_summary'}
                    initialValue={generateYears()}
                    selectBoxOptions={generateYears()}
                    selectedSelectBox={showYear}
                    placeholder={'YYYY'}
                    unit={'年'}
                    style={{ areaStyle: { width: '50%' } }}
                    onChange={e => {
                      setShowYear(e);
                    }}
                  />
                  <CustomInput
                    inputType={'select'}
                    inputName={'month_sp'}
                    key={showMonth ? showMonth : 'month_sp'}
                    initialValue={[...new Array(12)].map((v, i) => i + 1)}
                    selectBoxOptions={[...new Array(12)].map((v, i) => i + 1)}
                    selectedSelectBox={showMonth}
                    unit={'月'}
                    style={{ areaStyle: { width: '50%' } }}
                    onChange={e => {
                      setShowMonth(e);
                    }}
                  />
                </div>

                <Button
                  style={{
                    ...Options.buttonStyles.back,
                    width: '100%',
                    height: '40px',
                    marginTop: '24px',
                  }}
                  text={'表示'}
                  onClick={() => handleShowMonth()}
                />

                {userPayDetail.netPayAmount && employmentType == 0 ? (
                  <div className={'staffPayDetail_forSp--dataArea'}>
                    <div className={'totalAmount'}>
                      <span>総支給額</span>
                      <p>{userPayDetail?.netPayAmount?.toLocaleString()}円</p>
                    </div>

                    <div className={'summary'}>
                      <h3>明細</h3>
                      <div>
                        <article>
                          <label>給与手当</label>
                          <span>
                            {userPayDetail?.payTotalAmount?.toLocaleString()}円
                          </span>
                        </article>

                        <article>
                          <label>立替金</label>
                          <span>
                            {userPayDetail?.advancePayTotalAmount?.toLocaleString()}
                            円
                          </span>
                        </article>

                        <article
                          style={{
                            marginTop: '2px',
                            borderTop: '1px solid #A0AAB8',
                          }}
                        >
                          <label>所得税</label>
                          <span>
                            {userPayDetail?.incomeTaxAmount?.toLocaleString()}円
                          </span>
                        </article>

                        <article>
                          <label>前払金</label>
                          <span>
                            {userPayDetail?.beforePayTotalAmount?.toLocaleString()}
                            円
                          </span>
                        </article>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p
                    style={{
                      width: '100%',
                      margin: '32px 0 0',
                      fontSize: '14px',
                      textAlign: 'center',
                      color: '#242424',
                    }}
                  >
                    現在ご利用いただけません
                  </p>
                )}
              </TabPane>

              <TabPane tab={'年間給与'} key={'yearPays'}>
                <div
                  className={'shiftForSp--static_date'}
                  style={{ marginTop: '8px' }}
                >
                  <CustomInput
                    inputType={'select'}
                    inputName={'year_sp_yearPays'}
                    key={showYearYear ? showYearYear : 'year_sp_yearPays'}
                    initialValue={generateYears()}
                    selectBoxOptions={generateYears()}
                    selectedSelectBox={showYearYear}
                    placeholder={'YYYY'}
                    unit={'年'}
                    style={{ areaStyle: { width: '100%' } }}
                    onChange={e => {
                      setShowYearYear(e);
                    }}
                  />
                </div>

                <Button
                  style={{
                    ...Options.buttonStyles.back,
                    width: '100%',
                    height: '40px',
                    marginTop: '24px',
                  }}
                  text={'表示'}
                  onClick={() => handleShowYear()}
                />

                {userYearPayList.totalPay && employmentType == 0 ? (
                  <div className={'staffPayDetail_forSp--dataArea'}>
                    <div className={'totalAmount'}>
                      <span>年間合計金額</span>
                      <p>{userYearPayList.totalPay.toLocaleString()}円</p>
                    </div>

                    <div
                      className={'totalAmount'}
                      style={{ marginTop: '16px' }}
                    >
                      <span>所得税合計</span>
                      <p>
                        {userYearPayList.incomeTaxAmount.toLocaleString()}円
                      </p>
                    </div>

                    <div className={'year_table'}>
                      <div className={'head'}>
                        <span
                          style={{
                            width: '34%',
                            borderRadius: '3px 0 0 0',
                            borderRight: '1px solid #A0AAB8',
                          }}
                        >
                          支給月
                        </span>
                        <span
                          style={{ width: '66%', borderRadius: '0 3px 0 0' }}
                        >
                          支給額
                        </span>
                      </div>

                      {monthNum.map((num, idx) => {
                        return (
                          <div
                            key={`monthNum_${idx}`}
                            className={'body'}
                            style={
                              num === 12
                                ? {
                                    borderBottom: 'none',
                                    borderRadius: '0 0 3px 3px',
                                  }
                                : {}
                            }
                          >
                            <span className={'month'}>{num}月</span>
                            {Object.values(userYearPayList)[idx] ? (
                              <span className={'amount'}>
                                {Object.values(userYearPayList)[
                                  idx
                                ].toLocaleString()}
                                円
                              </span>
                            ) : (
                              <span className={'amount'}>−</span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <p
                    style={{
                      width: '100%',
                      margin: '32px 0 0',
                      fontSize: '14px',
                      textAlign: 'center',
                      color: '#242424',
                    }}
                  >
                    現在ご利用いただけません
                  </p>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>

        <Copyright
          style={{
            position:
              activeTab === 'payDetail' || !activeTab ? 'absolute' : 'sticky',
            bottom: '6px',
          }}
        />
      </div>
    );
  } else {
    return (
      <>
        <RegisterBase
          image={Icons.icon.noImage}
          imageShape={'round'}
          infos={myPageInfos}
        />

        <Copyright
          style={
            activeTab === 'payDetail' || !activeTab
              ? {
                  position: 'absolute',
                  bottom: '6px',
                  width: isSp ? '100%' : isOpenSideMenu ? 'calc(100% - 200px)' : 'calc(100% - 56px)', transition: 'width .2s'
                }
              : { position: 'sticky', width: '100%', bottom: '6px' }
          }
        />
      </>
    );
  }
};

export default StaffPayDetail;
