import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import moment from 'moment';

const nowDate = new Date();
nowDate.setMonth(nowDate.getMonth() + 1);
nowDate.setHours(0, 0, 0, 0); // 次の月の最初の日に設定

const nextMonthYear = nowDate.getFullYear();
const nextMonth = nowDate.getMonth() + 1; // 0から11を1から12に変更

const initialSearchParams = {
  yearMonth: nextMonthYear + '/' + String(nextMonth).padStart(2, '0'),
  staffId: null,
  staffName: null,
  managerUserId: [],
  status: 99,
  offset: 0,
  count: 20,
  sort: 'staffIdAsc',
  sortColumn: 'staffId',
  sortMethod: 'Asc',
};

const initialSearchDetailParams = {
  yearMonth: nextMonthYear + '-' + nextMonth,
  staffId: 5963,
};

const updateParams = {
  year: '',
  month: '',
  date: '',
  enableAssignFlag: 1,
  shiftStyleFlag: 1,
  startTime: '',
  endTime: '',
  staffNote: '',
  managerNote: '',
};

const initialState = {
  searchParams: initialSearchParams,
  csvExportVisible: false,
  staffPlanList: [],
  editParam: [],
  searchCount: 0,
  activeCount: 0,
  submitCount: 0,
  addDateFlag: false,
  reduceDateFlag: false,
  shiftFlag: false,
};

const initialEditDetailParams = {
  shiftStyleFlag: 0,
};

const initialDetailState = {
  detailList: [],
  searchDetailList: [],
  editParams: initialEditDetailParams,
};

export const staffPlanFetch = createAsyncThunk(
  'staff_schedule/list',
  async params => {
    const res = await fetchRequest(apiUrl.staffPlan.list, params);
    const item = res?.item.map(c => {
      return {
        ...c,
        staffName: `${c?.staffName?.lastName ?? ''} ${
          c?.staffName?.firstName ?? ''
        } `,
        mainManagerName: `${c?.mainManagerName?.lastName ?? ''} ${
          c?.mainManagerName?.firstName ?? ''
        } `,
        subManagerName: `${c?.subManagerName?.lastName ?? ''} ${
          c?.subManagerName?.firstName ?? ''
        } `,
        firstSubmitDatetime: c?.firstSubmitDatetime
          ? moment(c?.firstSubmitDatetime).format('YYYY/MM/DD HH:mm')
          : '-',
        lastSubmitDatetime: c?.lastSubmitDatetime
          ? moment(c?.lastSubmitDatetime).format('YYYY/MM/DD HH:mm')
          : '-',
        editIconState: 0,
        status:
          c?.status === 1 ? '提出済' : c?.status === 0 ? '未提出' : '全て',
      };
    });
    return {
      staffPlanList: {
        item,
        count: res?.searchCount ?? 0,
        submitCount: res?.submitCount ?? 0,
        activeCount: res?.activeCount ?? 0,
      },
    };
  }
);

export const staffPlanUserFetch = createAsyncThunk(
  'staffPlan/info',
  async params => {
    const [userRes] = await Promise.all([
      fetchRequest(apiUrl.user.list, { status: 1 }),
    ]);
    return {
      userList: userRes.item ?? [],
    };
  }
);

// 表示ボタン,次月,前月
export const staffPlanDetailFetch = createAsyncThunk(
  'staffPlan/detail/list',
  async params => {
    const res = await fetchRequest(apiUrl.staffPlan.detail, params);
    return {
      detail: res?.detail ?? [],
      searchDetailList: {
        detail: res?.detail ?? [],
        shiftList: res?.detail.shifts ?? [],
      },
    };
  }
);

// 初期表示スタッフシフト詳細一覧取得
export const staffPlanDetailInit = createAsyncThunk(
  'staffPlan/detail/init',
  async () => {
    const detailRes = await fetchRequest(
      apiUrl.staffPlan.detail,
      initialSearchDetailParams
    );
    return {
      detailList: {
        detail: detailRes?.detail ?? [],
        shiftList: detailRes?.detail?.shifts ?? [],
        statusFlag: detailRes?.detail?.statusFlag ?? 0,
      },
    };
  }
);

export const staffPlanCorpFetch = createAsyncThunk(
  'staffPlan/corp',
  async params => {
    const [corpRes] = await Promise.all([
      fetchRequest(apiUrl.corporate.detail, params),
    ]);
    return {
      detail: corpRes?.detail ?? {},
    };
  }
);

export const staffPlanDetailUpdate = createAsyncThunk(
  'staffPlan/detail/update',
  async params => await fetchRequest(apiUrl.staffPlan.update, params, 'POST')
);

export const staffPlanRequestUpdate = createAsyncThunk(
  'staffPlan/request/update',
  async params => await fetchRequest(apiUrl.staffPlan.request, params, 'POST')
);

export const staffPlanRemind = createAsyncThunk(
  'staffPlan/remind',
  async params => await fetchRequest(apiUrl.staffPlan.remind, params, 'POST')
);

export const staffPlanSlice = createSlice({
  name: 'staffPlan',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        yearMonth: `${payload.yearMonth.split('/')[0]}/${payload.yearMonth
          .split('/')[1]
          .padStart(2, '0')}`,
        offset: 0,
      },
    }),
    setEdit: state => {
      state.editParam = { ...state.editParam };
    },
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    setAddDate: (state, { payload }) => {
      state.addDateFlag = payload;
    },
    setShiftFlag: (state, { payload }) => {
      state.shiftFlag = payload;
    },
    setReduceDate: (state, { payload }) => {
      state.reduceDateFlag = payload;
    },
  },
  extraReducers: {
    [staffPlanFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffPlanUserFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffPlanRequestUpdate.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffPlanRemind.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const staffPlanDetailSlice = createSlice({
  name: 'staffPlanDetail',
  initialState: initialDetailState,
  reducers: {
    saveSearchDetailParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    setEdit: state => {
      state.editParam = { ...state.editParam };
    },
    refresh: state => {
      state.searchParams = { ...state.searchParams };
    },
  },
  extraReducers: {
    [staffPlanDetailInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffPlanDetailFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffPlanDetailUpdate.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const staffPlanActions = staffPlanSlice.actions;
export const staffPlanDetailActions = staffPlanDetailSlice.actions;
export const staffPlanReducer = staffPlanSlice.reducer;
export const staffPlanDetailReducer = staffPlanDetailSlice.reducer;
