import { memo } from 'react';
import Tooltip from 'antd/es/tooltip';
import Icons from '../../constants/icons';
import './style.scss';

const AddLineButton = memo(({ onClick, title, disabled, style = {} }) => (
  <Tooltip
    placement="top"
    title={title}
    onClick={onClick}
    className={disabled ? 'add_line_button--disabled' : `add_line_button`}
  >
    <img src={disabled ? Icons.icon.plusWh : Icons.icon.plusNv} style={style} />
  </Tooltip>
));

export default AddLineButton;
