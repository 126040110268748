import './style.scss';
import Icons from '../../constants/icons';
import CustomInput from '../customInput';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from '../../redux/slice/global';
import React from 'react';
import CustomFormikInput from '../customInput/customFormikInput';
import { checkIsSp } from '../../utils/fnUtil';

const RegisterBase = ({
  image,
  imageShape,
  infos,
  setImage,
  formik,
  imageDisabled,
}) => {
  const { TabPane } = Tabs;
  const tabs = infos.detail.tabs;

  const dispatch = useDispatch();

  const { activeTab } = useSelector(state => state.global);

  const selectUploadFile = () => {
    const fileDom = document.getElementById('registerBaseImageInput');
    fileDom.click();
  };

  const convertDatetime = datetime => {
    let ret = datetime.replace(/\s+/g, '');

    ret = ret.replaceAll('-', '/');
    ret = ret.split('');
    ret.splice(10, 0, ' ');

    for (let i = 0; i < 3; i++) {
      ret.pop();
    }

    if (ret[8] === '0') {
      ret.splice(8, 1);
    }
    if (ret[5] === '0') {
      ret.splice(5, 1);
    }

    ret = ret.join('');
    return ret;
  };

  const isSp = checkIsSp();

  return (
    <>
      <div className={'register_base--container'}>
        <span className={'register_base--container_notice'}>
          *は必須項目です
        </span>
        {infos.updates && !isSp ? (
          <div className={'register_base--update_area'}>
            {infos.updates.map(obj => {
              return (
                <span
                  key={obj.label}
                  className={'register_base--update_text'}
                >{`${obj.label}　${
                  infos.ignoreConvert
                    ? obj?.datetime
                      ? obj.datetime
                      : ''
                    : convertDatetime(obj.datetime)
                } ${obj.name}`}</span>
              );
            })}
          </div>
        ) : (
          infos.updates && (
            <div className={'register_base--update_areaSp'}>
              {infos.updates.map(obj => {
                return (
                  <div
                    key={obj.label}
                    className={'register_base--update_text'}
                  >{`${obj.label}　${
                    infos.ignoreConvert
                      ? obj?.datetime
                        ? obj.datetime
                        : ''
                      : convertDatetime(obj.datetime)
                  } ${obj.name}`}</div>
                );
              })}
            </div>
          )
        )}

        {infos.basic && (
          <div className={'register_base--basic_info'}>
            {!imageDisabled && (
              <div className={'register_base--basic_info_image_area'}>
                <input
                  id="registerBaseImageInput"
                  type="file"
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                  onChange={setImage}
                  style={{ display: 'none' }}
                />
                <div
                  className={'register_base--basic_info_image_area_wrap'}
                  onClick={selectUploadFile}
                >
                  <img
                    key={image}
                    src={image ? image : Icons.icon.noImage}
                    alt={'image'}
                    style={{
                      objectFit: 'contain',
                      ...(imageShape === 'round' && { borderRadius: '50%' }),
                    }}
                    // className={'register_base--basic_info_image_img'}
                  />
                  <div
                    className={
                      'register_base--basic_info_image_area_wrap_overlay'
                    }
                  >
                    <span>画像を変更</span>
                  </div>
                </div>

                <p>
                  png,gif,jpg,jpeg形式のみアップロード可。
                  <br />
                  画像は１M以下でアップロードしてください。
                  <br />
                  推奨画像サイズ：正方形
                </p>
              </div>
            )}
            <div className={'register_base--basic_info_input_area'}>
              <div style={infos.basic.style}>
                {infos.basic.jsx
                  ? infos.basic.jsx()
                  : infos.basic.inputs.map((obj, index) => {
                      return formik ? (
                        <CustomFormikInput
                          formik={formik}
                          key={`basic_${index}`}
                          {...obj}
                        />
                      ) : (
                        <CustomInput key={`basic_${index}`} {...obj} />
                      );
                    })}
              </div>
            </div>
          </div>
        )}

        <div className={'register_base--detail_info_base'} id={'detailBase'}>
          <Tabs
            defaultActiveKey={Object.keys(tabs)[0]}
            activeKey={activeTab ? activeTab : Object.keys(tabs)[0]}
            onChange={tab => {
              dispatch(globalActions.changeActiveTab(tab));
              // if (isSp) window.location.href = '#detailBase';
            }}
          >
            {Object.keys(tabs).map(key => {
              return (
                <TabPane tab={tabs[key].label} key={key}>
                  {tabs[key].inputs ? (
                    <div className={'register_base--detail_info_tab_page'}>
                      {tabs[key].inputs.map((obj, index) => {
                        return formik ? (
                          <CustomFormikInput
                            formik={formik}
                            key={`${tabs[key].label}_${index}`}
                            {...obj}
                          />
                        ) : (
                          <CustomInput
                            key={`${tabs[key].label}_${index}`}
                            {...obj}
                          />
                        );
                      })}
                    </div>
                  ) : tabs[key].jsx ? (
                    tabs[key].jsx()
                  ) : (
                    tabs[key].component
                  )}
                </TabPane>
              );
            })}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default RegisterBase;
