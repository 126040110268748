import { memo } from 'react';
import { deleteArrayItem } from '../../../../utils/fnUtil';
import InputItem from '../../../../components/inputItem';
import AddLineButton from '../../../../components/addLineButton';
import './style.scss';

const StaffVoice = memo(({ formik }) => {
  const {
    values: { staffVoice = [] },
    setFieldValue,
  } = formik;

  return (
    <div>
      {staffVoice.map((_, i) => (
        <InputItem
          formik={formik}
          label="入力項目"
          key={i}
          relativeInputName={['staffVoice']}
          itemInputName={`staffVoice.[${i}].inputTitle`}
          contentInputName={`staffVoice.[${i}].inputText`}
          reportInputName={`staffVoice.[${i}].reportVoiceFlag`}
          staffInputName={`staffVoice.[${i}].staffVoiceFlag`}
          toggleSize={'default'}
          onDelete={() =>
            setFieldValue('staffVoice', deleteArrayItem(staffVoice, i))
          }
        />
      ))}
      <div>
        <AddLineButton
          title="入力項目追加"
          onClick={() =>
            setFieldValue('staffVoice', [
              ...staffVoice,
              {
                reportVoiceFlag: 1,
                staffVoiceFlag: 1,
              },
            ])
          }
        />
      </div>
    </div>
  );
});

export default StaffVoice;
