import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import ListView from '../../../components/listView';
import Button from '../../../components/button';
import DenialModal from '../../../components/modal/stampModal/denialModal';
import ApprovalModal from '../../../components/modal/stampModal/approvalModal';
import Options from '../../../constants/options';
import Icons from '../../../constants/icons';
import { fetchApi, fetchRequest } from '../../../api/fetch';
import './style.scss';
import { apiUrl } from '../../../api/apiUrl';
import { useNavigate } from 'react-router-dom';
import { checkIsSp } from '../../../utils/fnUtil';

const QuoteApproval = () => {
  const isSp = checkIsSp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginType = useSelector(state => state.loginType.type);
  const accountInfo = useSelector(state => state.account);
  const [apiData, setApiData] = useState([
    {
      quoteNumber: null,
      matterName: null,
      clientName: null,
      managerUserName: {
        firstName: null,
        lastName: null,
      },
      quoteAmount: null,
      stampLimitDate: null,
      approvalFlowName: null,
    },
  ]);
  const [checkedQuoteNum, setCheckedQuoteNum] = useState(0);

  // 見積申請一覧取得Api
  const getApiData = async () => {
    store.dispatch(globalActions.setLoading(true));
    let apiData;
    await fetchApi(
      'quote',
      'requestList',
      'GET',
      loginType,
      accountInfo.corporateId,
      accountInfo.accessToken,
      { sort: 'quoteNumberDesc' }
    ).then(data => {
      apiData = data.item;
    });
    updateApiData(apiData);
  };
  const updateApiData = data => {
    if (data !== apiData) {
      setApiData(data);
    }
    store.dispatch(globalActions.setLoading(false));
  };

  useEffect(() => {
    getApiData();
  }, []);

  //モダルの開閉をコントロール
  const [denialModalVisible, setDenialModalVisible] = useState(false);
  const changeDenialModalVisible = () => {
    if (getCheckedIndex().length !== 1 && !denialModalVisible) {
      dispatch(globalActions.showSingleModal('申請を1つ選択してください'));
    } else if (denialModalVisible) {
      setDenialModalVisible(!denialModalVisible);
    } else {
      setCheckedQuoteNum(apiData[getCheckedIndex()[0]].quoteNumber);
      setDenialModalVisible(!denialModalVisible);
    }
  };
  const [approvalModalVisible, setApprovalModalVisible] = useState(false);
  const changeApprovalModalVisible = () => {
    if (getCheckedIndex().length !== 1 && !approvalModalVisible) {
      dispatch(globalActions.showSingleModal('申請を1つ選択してください'));
    } else if (approvalModalVisible) {
      setApprovalModalVisible(!approvalModalVisible);
    } else {
      setCheckedQuoteNum(apiData[getCheckedIndex()[0]].quoteNumber);
      setApprovalModalVisible(!approvalModalVisible);
    }
  };

  // チェックされたindexを取得
  const getCheckedIndex = () => {
    const checkAll = document.getElementById('checkAll')?.checked;
    const checks = document.querySelectorAll('input[id^="check_"]');

    let ret = '';

    if (checkAll) {
      ret = 'all';
    } else {
      ret = [];
      checks.forEach((elm, index) => {
        if (elm.checked) {
          ret.push(index);
        }
      });

      ret.filter(v => v);
    }

    return ret;
  };

  const columnSettings = {
    checkAll: true,
    quoteNumber: { label: '見積書No', sort: 'asc', dataName: 'quoteNumber' },
    matterName: { label: '案件名', sort: 'none', dataName: 'matterName' },
    clientName: { label: '取引先', sort: 'none', dataName: 'clientName' },
    managerUserName: {
      label: '社内担当者',
      sort: 'none',
      dataName: 'managerUserName',
    },
    quoteAmount: { label: '金額', sort: 'none', dataName: 'quoteAmount' },
    stampLimitDate: {
      label: '捺印期間',
      sort: 'none',
      dataName: 'stampLimitDate',
    },
    approvalFlowName: {
      label: '承認フロー',
      sort: 'none',
      dataName: 'approvalFlowName',
    },
    statics: [{ label: '見積詳細', icon: Icons.icon.editSGr }],
  };

  const [{ sortColumn, sortMethod }, setSort] = useState({
    sortColumn: 'quoteNumber',
    sortMethod: 'asc',
  });

  const changeSort = e => {
    if (e.target.id === sortColumn) {
      sortMethod === 'asc'
        ? setSort({ sortColumn: sortColumn, sortMethod: 'desc' })
        : setSort({ sortColumn: sortColumn, sortMethod: 'asc' });
    } else {
      setSort({ sortColumn: e.target.id, sortMethod: 'asc' });
    }
  };

  const apiDataList = useMemo(() => {
    if (JSON.stringify(apiData) !== undefined) {
      const apiDataCopy = JSON.parse(JSON.stringify(apiData));
      apiDataCopy.map(item => {
        item.managerUserName =
          item.managerUserName.firstName + item.managerUserName.lastName;
        if (!!item.quoteAmount) {
          return item.quoteAmount.toLocaleString() + '円';
        }
      });
      return {
        count: 0,
        item: apiDataCopy,
      };
    } else {
      return {
        count: 0,
        item: apiData,
      };
    }
  }, [apiData]);

  const handleSubmitDeny = async message => {
    const res = await fetchRequest(
      apiUrl.quote.documentStatusUpdate,
      {
        quoteNumber: checkedQuoteNum,
        approvalType: 0,
        approvalMessage: message,
      },
      'POST'
    );

    if (res.code === '0') {
      dispatch(globalActions.showSingleModal('否認しました'));
      changeDenialModalVisible();
      getApiData();
    } else {
      dispatch(globalActions.showSingleModal('通信に失敗しました'));
    }
  };

  const handleSubmitApprove = async message => {
    const res = await fetchRequest(
      apiUrl.quote.documentStatusUpdate,
      {
        quoteNumber: checkedQuoteNum,
        approvalType: 1,
        approvalMessage: message,
      },
      'POST'
    );

    if (res.code === '0') {
      dispatch(globalActions.showSingleModal('承認しました'));
      changeApprovalModalVisible();
      getApiData();
    } else {
      dispatch(globalActions.showSingleModal('通信に失敗しました'));
    }
  };

  return (
    <>
      <div className={'commonPage--base'}>
        <div style={{ height: '40px' }}></div>
        <ListView
          columnSettings={columnSettings}
          apiData={apiDataList}
          sortSettings={{ sortColumn: sortColumn, sortMethod: sortMethod }}
          changeSort={changeSort}
        />
      </div>

      <div
        className={'bottom_btn_area'}
        style={
          isSp ? { height: '88px', padding: '0 16px' } : { height: '92px' }
        }
      >
        <div className={'quote_approval--stamp_buttons'}>
          <Button
            text={'否認'}
            icon={Icons.icon.ngWh}
            style={
              isSp
                ? Options.buttonStyles.denialShortSp
                : Options.buttonStyles.denialShort
            }
            onClick={changeDenialModalVisible}
          />
          <Button
            text={'承認'}
            icon={Icons.icon.okWh}
            style={Options.buttonStyles.stampApproval}
            onClick={changeApprovalModalVisible}
          />
        </div>
      </div>

      <DenialModal
        denialModalVisible={denialModalVisible}
        changeModalVisible={c => changeDenialModalVisible(c)}
        onSubmit={handleSubmitDeny}
      />
      <ApprovalModal
        approvalModalVisible={approvalModalVisible}
        changeModalVisible={c => changeApprovalModalVisible(c)}
        onSubmit={handleSubmitApprove}
      />
    </>
  );
};

export default QuoteApproval;
