import { memo } from 'react';
import { get, isString } from 'lodash';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import BaseUpload from '../imageUploader';
import Icons from '../../constants/icons';
import Button from '../button';
import DeleteIcon from '../deleteIcon';
import classNames from 'classnames';
import './style.scss';

const FreeFormCell = memo(
  ({ index, onImageButton, formik, inputName, onAddImage }) => {
    const { values } = formik;

    const formContent = get(values, inputName);

    const formText = formContent[`form${index + 1}Text`];

    const formImage = formContent[`form${index + 1}Image`];

    const inputType = isString(formText)
      ? 'text'
      : isString(formImage)
      ? 'image'
      : 'empty';

    return (
      <div
        className={classNames('free_form_cell', {
          'free_form_cell--text': inputType === 'text',
        })}
      >
        {inputType !== 'empty' ? (
          <DeleteIcon
            title={`${inputType === 'text' ? '入力枠' : '画像'}削除`}
            onClick={() => {
              handlerFormikFieldChange(
                formik,
                `${inputName}.form${index + 1}Text`,
                null
              );
              handlerFormikFieldChange(
                formik,
                `${inputName}.form${index + 1}Image`,
                null
              );
            }}
          />
        ) : (
          <>
            <Button
              text="テキスト入力"
              icon={Icons.icon.penNv}
              onClick={() =>
                handlerFormikFieldChange(
                  formik,
                  `${inputName}.form${index + 1}Text`,
                  ''
                )
              }
            />
            {
              <BaseUpload onChange={onAddImage} disabled={!onAddImage}>
                <Button
                  text="画像選択"
                  icon={Icons.icon.photo24Nv}
                  onClick={onImageButton}
                />
              </BaseUpload>
            }
          </>
        )}
        <>
          {inputType === 'text' && (
            <textarea
              value={formText}
              onChange={e => {
                handlerFormikFieldChange(
                  formik,
                  `${inputName}.form${index + 1}Text`,
                  e.target.value ?? ''
                );
              }}
            />
          )}
          {inputType === 'image' && (
            <img src={formImage} className="free_form_image" />
          )}
        </>
      </div>
    );
  }
);

export default FreeFormCell;
