import options from "../../constants/options";
import prefectures from "../../constants/prefectures";
import moment from "moment";
import { checkIsSp } from "../../utils/fnUtil";

const pref = {
  '1': '北海道',
  '2': '青森県',
  '3': '岩手県',
  '4': '宮城県',
  '5': '秋田県',
  '6': '山形県',
  '7': '福島県',
  '8': '茨城県',
  '9': '栃木県',
  '10': '群馬県',
  '11': '埼玉県',
  '12': '千葉県',
  '13': '東京都',
  '14': '神奈川県',
  '15': '新潟県',
  '16': '富山県',
  '17': '石川県',
  '18': '福井県',
  '19': '山梨県',
  '20': '長野県',
  '21': '岐阜県',
  '22': '静岡県',
  '23': '愛知県',
  '24': '三重県',
  '25': '滋賀県',
  '26': '京都府',
  '27': '大阪府',
  '28': '兵庫県',
  '29': '奈良県',
  '30': '和歌山県',
  '31': '鳥取県',
  '32': '島根県',
  '33': '岡山県',
  '34': '広島県',
  '35': '山口県',
  '36': '徳島県',
  '37': '香川県',
  '38': '愛媛県',
  '39': '高知県',
  '40': '福岡県',
  '41': '佐賀県',
  '42': '長崎県',
  '43': '熊本県',
  '44': '大分県',
  '45': '宮崎県',
  '46': '鹿児島県',
  '47': '沖縄県',
  '99': '海外'
}

const heightValue = () => {
  let height = [];
  [...Array(251)].map((_, index) => {
    if (index > 99) {
      height[index] = index;
    }
  })
  return height;
};

const prefCode = () => {
  let prefCode = []
  Object.keys(pref).map(key => {
    prefCode.push(key);
  })

  return prefCode;
}

const prefName = () => {
  let prefName = [];

  prefCode().map(code => {
    prefName.push(pref[code]);
  })

  return prefName;
}

const timeRequiredValues = () => {
  let timeRequiredValues = [];
  [...Array(101)].map((_, idx) => {
    timeRequiredValues.push(idx);
  });

  return timeRequiredValues;
}

const StaffRegisterInfos = {
  base: {
    inputs: [
      {
        label: 'スタッフID',
        inputName: 'staffId',
        labelRequired: true,
        placeholder: 'スタッフIDを入力してください',
        style: {
          labelStyle: { width: '119px' },
        },
      },
      {
        inputType: 'input',
        inputName: 'lastName',
        initialValue: '',
        placeholder: '姓',
        style: {
          areaStyle: { width: '100%', marginLeft: checkIsSp()? '0px':'8px' },
        },
      },
      {
        inputType: 'input',
        inputName: 'firstName',
        initialValue: '',
        placeholder: '名',
        style: {
          areaStyle: { width: '100%'},
        },
      },
      {
        inputType: 'input',
        inputName: 'lastNameKana',
        initialValue: '',
        placeholder: 'セイ',
        style: {
          areaStyle: { width: '100%', marginLeft: checkIsSp()? '0px':'8px' },
        },
      },
      {
        inputType: 'input',
        inputName: 'firstNameKana',
        initialValue: '',
        placeholder: 'メイ',
        style: {
          areaStyle: { width: '100%'},
        },
      },

      {
        inputType: 'input',
        label: 'メールアドレス',
        inputName: 'mailAddress',
        placeholder: 'メールアドレス',
        labelRequired: true,
        style: {
          labelStyle: { width: '119px' },
        },
      },
      {
        inputType: 'inputDate',
        label: '利用開始日',
        labelRequired: true,
        inputName: 'startDate',
        placeholder: 'YYYY/MM/DD',
        className: {
          areaClass: 'staff_edit--input_month',
        },
        style: {
          areaStyle: { width: '288px' },
          labelStyle: { width: '119px' },
        }
      },
      {
        inputType: 'inputDate',
        label: '利用終了日',
        labelRequired: true,
        inputName: 'endDate',
        placeholder: 'YYYY/MM/DD',
        className: {
          areaClass: 'staff_edit--input_month',
        },
        style: {
          areaStyle: {width: '288px'},
          labelStyle: { width: '119px' },
        }
      },
    ],
  },
  basicInfos: {
    inputs: [
      {
        radioButtonTitle: '性別',
        inputName: 'gender',
        initialValue: [1, 2],
        inputType: 'radioButton',
        style: {
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '郵便番号',
        inputName: 'postCode1',
        initialValue: '',
        placeholder: '123',
        style: {
          areaStyle: {width: '366px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        inputName: 'postCode2',
        initialValue: '',
        placeholder: '456',
        style: {
          areaStyle: {width: '110px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '都道府県',
        inputName: 'prefCode',
        initialValue: prefectures.map(p=>p?.prefecturesCode),
        selectBoxOptions: prefectures.map(p=>p?.label),
        placeholder: '都道府県',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'392px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '市区町村',
        inputName: 'cityName',
        initialValue: '',
        placeholder: '市区町村',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '丁目番地',
        inputName: 'streetName',
        initialValue: '',
        placeholder: '丁目番地',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: 'それ以降',
        inputName: 'buildingName',
        initialValue: '',
        placeholder: 'マンション名・部屋番号等',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'inputTell',
        label: 'TEL(スマートフォン)',
        inputName: ['phone_area_number', 'phone_city_number', 'phone_subscriber_number'],
        initialValue: ['', '', ''],
        placeholder: ['03', '0000', '0000'],
        unit: ['-', '-', ''],
        className: {
          areaClass: 'staff_register--tel_area',
        },
        style: {
          areaStyle: {width: checkIsSp()? '100%':'626px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'inputTell',
        label: 'TEL(自宅)',
        inputName: ['tel_area_number', 'tel_city_number', 'tel_subscriber_number'],
        initialValue: ['', '', ''],
        placeholder: ['03', '0000', '0000'],
        unit: ['-', '-', ''],
        className: {
          areaClass: 'staff_register--tel_area',
        },
        style: {
          areaStyle: {width: '626px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: 'LINE ID',
        inputName: 'lineId',
        initialValue: '',
        placeholder: 'LINE IDを入力してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '稼働希望日数',
        inputName: 'hopeAssign',
        initialValue: '',
        placeholder: '0',
        unit: '日',
        className: {
          areaClass: 'staff_register--hope_assign',
        },
        style: {
          areaStyle: {width: checkIsSp()? '165px':'388px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        radioButtonTitle: '週報告',
        radioButtonLabelAndChecked: [
          {label: 'システム入力', checked: true},
          {label: "電話", checked: false},
          {label: "報告不要", checked: false},
        ],
        inputName: 'weekMethodFlag',
        initialValue: [0, 1, 2],
        inputType: 'radioButton',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'410px'},
          labelStyle: {width: checkIsSp() ?'20%':'64px'},
        }
      },
      {
        radioButtonTitle: '前日報告',
        radioButtonLabelAndChecked: [
          {label: 'システム入力', checked: true},
          {label: "電話", checked: false},
          {label: "報告不要", checked: false},
        ],
        inputName: 'previousDayMethodFlag',
        initialValue: [0, 1, 2],
        inputType: 'radioButton',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'410px'},
          labelStyle: {width: checkIsSp() ?'20%':'64px'},
        }
      },
      {
        radioButtonTitle: '起床報告',
        radioButtonLabelAndChecked: [
          {label: 'システム入力', checked: true},
          {label: "電話", checked: false},
          {label: "報告不要", checked: false},
        ],
        inputName: 'gettingUpMethodFlag',
        initialValue: [0, 1, 2],
        inputType: 'radioButton',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'410px'},
          labelStyle: {width: checkIsSp() ?'20%':'64px'},
        }
      },
      {
        radioButtonTitle: '出発報告',
        radioButtonLabelAndChecked: [
          {label: 'システム入力', checked: true},
          {label: "電話", checked: false},
          {label: "報告不要", checked: false},
        ],
        inputName: 'departureMethodFlag',
        initialValue: [0, 1, 2],
        inputType: 'radioButton',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'410px'},
          labelStyle: {width: checkIsSp() ?'20%':'64px'},
        }
      },
      {
        radioButtonTitle: '到着報告',
        radioButtonLabelAndChecked: [
          {label: 'システム入力', checked: true},
          {label: "電話", checked: false},
          {label: "報告不要", checked: false},
        ],
        inputName: 'arriveMethodFlag',
        initialValue: [0, 1, 2],
        inputType: 'radioButton',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'410px'},
          labelStyle: {width: checkIsSp() ?'20%':'64px'},
        }
      },
      {
        radioButtonTitle: '終業報告',
        radioButtonLabelAndChecked: [
          {label: 'システム入力', checked: true},
          {label: "電話", checked: false},
          {label: "報告不要", checked: false},
        ],
        inputName: 'endMethodFlag',
        initialValue: [0, 1, 2],
        inputType: 'radioButton',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'410px'},
          labelStyle: {width: checkIsSp() ?'20%':'64px'},
        }
      },
      {
        inputType: 'inputDate',
        label: '入社日',
        inputName: 'joinDate',
        initialValue: '',
        placeholder: 'YYYY/MM/DD',
        style: {
          areaStyle: {width: checkIsSp()? '165px':'421px'},
          labelStyle: checkIsSp()? {}:{width: '232px'},
        }
      },
      {
        inputType: 'inputDate',
        label: '退職日',
        inputName: 'retireDate',
        initialValue: '',
        placeholder: 'YYYY/MM/DD',
        style: {
          areaStyle: {width: checkIsSp()? '165px':'237px'},
        }
      },
      {
        inputType: 'select',
        inputName: 'retireReason',
        initialValue: ['0001', '0002', '0003'],
        selectBoxOptions: ['自己都合', '会社都合', 'その他'],
        placeholder: '退職理由を選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'calc(100% - 237px)'},
        }
      },
      {
        inputType: 'select',
        label: 'メイン担当者',
        inputName: 'mainManagerUserId',
        showSearch: false,
        placeholder: 'メイン担当者を選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: 'サブ担当者',
        showSearch: false,
        inputName: 'subManagerUserId',
        placeholder: 'サブ担当者を選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        radioButtonTitle: '契約形態',
        labelRequired: true,
        radioButtonLabelAndChecked: [
          {label: '業務委託', checked: true},
          {label: "パート・アルバイト", checked: false},
        ],
        inputName: 'employmentType',
        initialValue: [0, 1],
        inputType: 'radioButton',
        style: {
          labelStyle: {width: '232px'},
        }
      },
      {
        radioButtonTitle: '税額表区分',
        radioButtonLabelAndChecked: [
          {label: '甲', checked: true},
          {label: '乙', checked: false},
        ],
        inputName: 'taxClassification',
        initialValue: [0, 1],
        inputType: 'radioButton',
        style: {
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '扶養人数',
        inputName: 'dependents',
        initialValue: '',
        placeholder: '0',
        unit: '人',
        style: {
          areaStyle: {width: checkIsSp()? '165px':'388px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '基本日給',
        inputName: 'dailyPay',
        initialValue: '',
        placeholder: '基本日給を入力してください',
        unit: '円',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'50%'},
        }
      },
      {
        inputType: 'input',
        label: '基本時給',
        inputName: 'hourlyPay',
        initialValue: '',
        placeholder: '基本時給を入力してください',
        unit: '円',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'50%'},
        }
      },
      {
        inputType: 'select',
        label: '採用媒体',
        inputName: 'recruitMedia',
        initialValue: ['0001', '0002', '0003'],
        selectBoxOptions: ['マイナビ', 'リクナビ', 'エン転職'],
        placeholder: '採用媒体を選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'min(95%, 1052px)'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '採用単価',
        inputName: 'recruitCost',
        initialValue: '',
        placeholder: '0',
        unit: '円',
        className: {
          areaClass: 'staff_register--recruit_cost',
        },
        style: {
          areaStyle: {width: checkIsSp()? '100%':'480px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: 'ポジション',
        inputName: 'position',
        initialValue: [2, 1, 0],
        selectBoxOptions: ['ディレクター', 'アシスタントディレクター', 'スタッフ'],
        placeholder: 'ポジションを選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'615px'},
          labelStyle: {width: '232px'},
        },
        allowClear: false
      },
      {
        inputType: 'select',
        label: '評価',
        inputName: 'evaluation',
        initialValue: [0, 1, 2, 3, 4],
        selectBoxOptions: ['S', 'A', 'B', 'C', 'D'],
        placeholder: '評価を選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'615px'},
          labelStyle: {width: '232px'},
        }
      },
    ]
  },
  accessInfos: {
    inputs: [
      {
        inputType: 'input',
        label: '最寄駅',
        inputName: 'stationName',
        initialValue: '',
        placeholder: '駅名を入力してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'80%', marginTop: '4px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '最寄駅までの所要時間(分)',
        inputName: 'timeRequired',
        initialValue: timeRequiredValues(),
        selectBoxOptions: timeRequiredValues(),
        placeholder: '0',
        unit: '分',
        style: {
          areaStyle: {width: checkIsSp()? '182px':'380px', minWidth: ''},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '最寄駅までの交通手段',
        inputName: 'wayStation',
        initialValue: [0, 1, 2, 99],
        selectBoxOptions: ['徒歩', 'バス', '車', 'その他'],
        placeholder: '選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '160px':'410px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        inputName: 'busStationNameFrom',
        initialValue: '',
        placeholder: 'バス停名を入力してください',
        unit: checkIsSp()? '〜':'~',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'30%', marginTop: '4px'},
          labelStyle: {width: '10px'},
        }
      },
      {
        inputType: 'input',
        inputName: 'busStationNameTo',
        initialValue: '',
        placeholder: 'バス停名を入力してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'30%', marginTop: checkIsSp()? '-8px':'4px',marginBottom:checkIsSp()? '20px':''},
          labelStyle: {width: '184px'},
        }
      },
    ]
  },
  bodyInfos: {
    inputs: [
      {
        inputType: 'select',
        label: '身長(cm)',
        inputName: 'height',
        initialValue: options.codes.height,
        selectedSelectBox: '100',
        selectBoxOptions: options.codes.height,
        placeholder: '155',
        unit: 'cm',
        style: {
          areaStyle: {width: checkIsSp()? '188px':'398px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '体重(kg)',
        inputName: 'weight',
        initialValue: '',
        placeholder: '0',
        unit: 'kg',
        style: {
          areaStyle: {width: checkIsSp()? '188px':'398px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '靴のサイズ(cm)',
        inputName: 'shoeSize',
        initialValue: '',
        placeholder: '0',
        unit: 'cm',
        style: {
          areaStyle: {width: checkIsSp()? '188px':'398px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '服のサイズ',
        inputName: 'wearSize',
        initialValue: options.codes.wearSize.map(w=>w.value),
        selectBoxOptions: options.codes.wearSize.map(w=>w.label),
        placeholder: 'M',
        style: {
          areaStyle: {width: checkIsSp()? '160px':'367px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'multiSelect',
        label: '持っているもの',
        initialValue: [],
        selectBoxOptions: [],
        inputName: 'holdingItems',
        placeholder: '選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'81%', minWidth: checkIsSp()? '100%':'800px'},
          labelStyle: {width: '232px'},
          inputStyle: {width: '100%'},
        }
      },
    ]
  },
  backgroundInfos: {
    inputs: [
      {
        inputType: 'multiSelect',
        label: '資格',
        inputName: 'certifications',
        placeholder: '選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'81%', minWidth: checkIsSp()? '100%':'800px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        radioButtonTitle: '職業',
        inputName: 'profession',
        initialValue: [0, 1],
        inputType: 'radioButton',
        style: {
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '学校名',
        inputName: 'schoolName',
        initialValue: '',
        placeholder: '学校名を入力してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'68%', minWidth: checkIsSp()? '100%':'650px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '入学年',
        inputName: 'admissionYear',
        initialValue: '',
        placeholder: '2022',
        unit: '年',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'472px'},
          labelStyle: {width: '232px'},
        }
      },
    ]
  },
  salaryInfos: {
    inputs: [
      {
        label: '給与支払方法',
        radioButtonTitle: '給与受取方法',
        inputName: 'payMethod',
        initialValue: [0, 1],
        selectBoxOptions: ['銀行振込', '手渡し'],
        placeholder: '給与支払方法を選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'583px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '金融機関名',
        inputName: 'bankName',
        initialValue: '',
        placeholder: '振込先金融機関名を入力してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'583px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '銀行コード',
        inputName: 'bankCode',
        initialValue: '',
        placeholder: '0000',
        style: {
          areaStyle: {width: checkIsSp()? '140px':'366px'},
          labelStyle: {width: '232px'},
        },
        // note: '※ゆうちょ銀行の場合は未記入にしてください。'
      },
      {
        inputType: 'select',
        label: '支店名',
        inputName: 'bankBranchName',
        initialValue: '',
        placeholder: '支店名を入力してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'583px'},
          labelStyle: {width: '232px'},
        },
      },
      {
        inputType: 'input',
        label: '支払コード',
        inputName: 'bankBranchCode',
        initialValue: '',
        placeholder: '0000',
        style: {
          areaStyle: {width: checkIsSp()? '140px':'366px'},
          labelStyle: {width: '232px'},
        },
        // note: '※ゆうちょ銀行の場合は口座番号を入力してください。'
      },
      {
        radioButtonTitle: '口座種別',
        inputName: 'bankAccountFlag',
        initialValue: [1, 2, 4, 9],
        inputType: 'radioButton',
        style: {
          areaStyle: {},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '口座番号',
        inputName: 'bankAccountNumber',
        initialValue: '',
        placeholder: '0000000',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'583px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'input',
        label: '口座名義人(カナ)',
        inputName: 'bankAccountName',
        initialValue: '',
        placeholder: '口座名義人(カナ)を入力してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'67%', minWidth: checkIsSp()? '100%':'800px'},
          labelStyle: {width: '232px'},
        }
      },
      {
        radioButtonTitle: '所得税計算方法',
        radioButtonLabelAndChecked: [
          {label: '甲', checked: true},
          {label: '乙', checked: false},
        ],
        inputName: 'classification',
        initialValue: [0, 1],
        style: {
          labelStyle: {width: '232px'},
        }
      },
      {
        label: '扶養人数',
        inputName: 'dependents',
        initialValue: '',
        placeholder: '0000',
        unit: '人',
        style: {
          areaStyle: {width: '388px'},
          labelStyle: {width: '232px'},
        }
      },
    ]
  },
  attributeInfos: {
    inputs: [
      {
        inputType: 'select',
        label: '予備項目1',
        inputName: 'preliminary1',
        placeholder: '選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'87%'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '予備項目2',
        inputName: 'preliminary2',
        placeholder: '選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'87%'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '予備項目3',
        inputName: 'preliminary3',
        placeholder: '選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'87%'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '予備項目4',
        inputName: 'preliminary4',
        placeholder: '選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'87%'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'select',
        label: '予備項目5',
        inputName: 'preliminary5',
        placeholder: '選択してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'87%'},
          labelStyle: {width: '232px'},
        }
      },
      {
        inputType: 'textBox',
        label: '備考',
        inputName: 'note',
        initialValue: '',
        placeholder: '備考を入力してください',
        style: {
          areaStyle: {width: checkIsSp()? '100%':'87%'},
          labelStyle: {width: '232px'},
        }
      },
    ]
  },
  myPage: {
    inputs: [
      {
        inputType: 'inputWithInput',
        label: '名前',
        labelRequired: true,
        inputName: ['last_name', 'first_name'],
        initialValue: ['', ''],
        placeholder: ['姓', '名'],
        style: {
          areaStyle: { width: '100%' },
          labelStyle: { width: '272px' },
        },
      },
      {
        inputType: 'inputWithInput',
        label: '名前(カナ)',
        labelRequired: true,
        inputName: ['last_name', 'first_name'],
        initialValue: ['', ''],
        placeholder: ['セイ', 'メイ'],
        style: {
          areaStyle: { width: '100%' },
          labelStyle: { width: '272px' },
        },
      },
      {
        inputType: 'input',
        label: 'メールアドレス',
        inputName: 'mail_address',
        placeholder: 'メールアドレスを入力してください',
        labelRequired: true,
        style: {
          labelStyle: { width: '272px' },
        },
      },
    ],
  },
  buttonArea: {
    inputName: 'sendMailFlag',
    inputType: 'checkBox',
    initialValue: false,
    label: '登録通知メールを送信する',
  }
}

export default StaffRegisterInfos;
