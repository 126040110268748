import React, { useEffect, useState } from 'react';
import Copyright from '../../components/copyright';
import Foundation from '../../components/foundation';
import FormInfos from '../../constants/formInfos';
import './style.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchRequest, loginApi } from '../../api/fetch';
import { store } from '../../redux/store';
import { apiUrl } from '../../api/apiUrl';
import { globalActions } from '../../redux/slice/global';
import account, { setAccount } from '../../redux/slice/account';
import { useDispatch, useSelector } from 'react-redux';

const ForgetPass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);

  const query = location.search;
  const queryCId = query ? query.replace('?corporateId=', '') : '';

  FormInfos.forgetPass.subButton['onClick'] = () => {
    if (FormInfos.login.loginUrl) {
      navigate('/login' + FormInfos.login.loginUrl);
    } else {
      navigate('/login');
    }
  };

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const iPadMiniScreen = 800;
  let customButtonSize = {};

  windowDimensions.width > iPadMiniScreen
    ? (customButtonSize = { width: '240px', height: '44px', fontSize: '14px' })
    : (customButtonSize = {
        width: '100%',
        height: '40px',
        margin: '8px 0 32px',
        fontSize: '12px',
      });
  Object.assign(FormInfos.forgetPass.button.style, customButtonSize);
  Object.assign(FormInfos.forgetPass.subButton.style, customButtonSize);

  const handleSubmit = () => {
    dispatch(
      setAccount({
        ...account,
        corporateId: queryCId
          ? queryCId
          : document.getElementById('corporate_id').value,
      })
    );

    fetchRequest(
      apiUrl.account.forgotUrl,
      {
        mailAddress: document.getElementById('mail_address').value,
      },
      'POST','json',true
    )
      .then(() => {
        store.dispatch(globalActions.showSingleModal('送信しました'));

        if (FormInfos.login.loginUrl) {
          navigate('/login' + FormInfos.login.loginUrl);
        } else {
          navigate('/login');
        }
      })
      .catch((error) =>
              store.dispatch(globalActions.showSingleModal(error.displayMessage))
      );
  };

  return (
    <div className={'forget_pass--background'}>
      <Foundation
        formInfo={FormInfos.forgetPass}
        onSubmit={() => handleSubmit()}
      />
      <Copyright />
    </div>
  );
};

export default ForgetPass;
