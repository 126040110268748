import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import prefectures from '../../constants/prefectures';

const namingValidList = [
  'ClientPreliminary1',
  'ClientPreliminary2',
  'ClientPreliminary3',
  'ClientPreliminary4',
  'ClientPreliminary5',
];
const CategorySearchParams = {
  namingClassificationId:
    'Index,ClientPreliminary1,ClientPreliminary2,ClientPreliminary3,' +
    'ClientPreliminary4,ClientPreliminary5',
  sort: 'priorityAsc',
  status: 1,
};

const initialSearchParams = {
  clientId: '',
  clientName: '',
  clientImage: '',
  prefCode: [],
  managerUserName: [],
  namingClassificationId: null,
  naming: [],
  namingId: [],
  status: 99,
  offset: 0,
  count: 20,
  sort: 'clientIdDesc',
  sortColumn: 'clientId',
  sortMethod: 'Desc',
};
const initialEditParams = {
  clientId: '',
  clientName: '',
  clientNameKana: '',
  startDate: '',
  endDate: '',
  clientImage: '',
  ntaNumber: '',
  division: 0,
  postCode1: '',
  postCode2: '',
  prefCode: null,
  cityName: '',
  streetName: '',
  buildingName: '',
  tel1: '',
  tel2: '',
  tel3: '',
  fax1: '',
  fax2: '',
  fax3: '',
  url: '',
  payTermId: null,
  managerUserId: null,
  clientManagerName: '',
  preliminary1: null,
  preliminary2: null,
  preliminary3: null,
  preliminary4: null,
  preliminary5: null,
  note: '',
};

const initialState = {
  searchParams: initialSearchParams,
  searchWorkParams: initialSearchParams,
  editParams: initialEditParams,
  searchCount: 0,
  totalCount: 0,
  activeCount: 0,
  namingCategoryList: [],
  namingList: [],
  clientList: [],
  userList: [],
  venueList: [],
  payTermList: [],
  zipSearch: false,
  editStatus: false,
  csvImportVisible: false,
  csvExportVisible: false,
  nextId: 0,
};

export const clientInit = createAsyncThunk('client/init', async () => {
  const [userRes, namingRes] = await Promise.all([
    fetchRequest(apiUrl.user.list),
    fetchRequest(apiUrl.naming.list, CategorySearchParams),
  ]);
  return {
    namingLabel: namingRes?.item
      ?.filter(
        n =>
          namingValidList.includes(n?.namingId ?? '') &&
          n.namingClassificationId === 'Index'
      )
      .map(n => {
        return {
          ...n,
          label: n?.naming,
        };
      }),
    namingCategoryList: namingRes?.item
      ?.sort((a, b) => (a.namingId < b.namingId ? -1 : 1))
      ?.filter(n => namingValidList.includes(n?.namingId ?? ''))
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    namingList: namingRes?.item
      ?.sort((a, b) => (a.namingId < b.namingId ? -1 : 1))
      ?.filter(n => n?.namingClassificationId !== 'Index')
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    namingListPriorityAsc: namingRes?.item
      ?.sort((a, b) => (a.priority < b.priority ? -1 : 1))
      ?.filter(n => n?.namingClassificationId !== 'Index')
      .map(n => {
        return {
          ...n,
          value: n?.namingId,
          label: n?.naming,
        };
      }),
    userList: userRes?.item?.map(u => {
      return {
        ...u,
        value: u?.userId,
        label: `${u?.userId}:${u?.name?.lastName ?? ''} ${
          u?.name?.firstName ?? ''
        }`,
      };
    }),
  };
});

export const clientListFetch = createAsyncThunk('client/list', async params => {
  const clientRes = await fetchRequest(apiUrl.client.list, params);
  const item = clientRes?.item?.map(c => {
    const prefName = prefectures.find(
      p => p?.prefecturesCode === c?.prefCode
    )?.label;
    return {
      ...c,
      prefCode: `${prefName ? prefName : ''}`,
      preliminary: c?.preliminary1?.naming,
      editIconState: c?.status === 2 ? 1 : 0,
      deleteIconState: c?.status === 2 ? 2 : 0,
      status: c?.status === 1 ? '表示' : c?.status === 0 ? '非表示' : '削除済',
      deletedFlag: c?.status === 2 ? c?.deletedFlag : 0,
    };
  });
  return {
    clientList: {
      item,
      count: clientRes?.searchCount ?? 0,
      totalCount: clientRes?.totalCount ?? 0,
      activeCount: clientRes?.activeCount ?? 0,
      topCount: [
        clientRes?.totalCount?.toLocaleString() ?? 0,
        clientRes?.activeCount?.toLocaleString() ?? 0,
      ],
    },
  };
});

export const getClientId = createAsyncThunk('client/list', async params => {
  const clientRes = await fetchRequest(apiUrl.client.list, {
    count: 1,
    sort: 'clientIdDesc',
  });
  const item = clientRes?.item?.map(c => {
    const prefName = prefectures.find(
      p => p?.prefecturesCode === c?.prefCode
    )?.label;
    return {
      ...c,
      prefName: `${prefName ? prefName : ''}`,
      idxClbk: c?.preliminary1?.naming,
      editIconState: c?.status !== 1 ? 1 : 0,
      deleteIconState: c?.status !== 1 ? 1 : 0,
      status: c?.status === 1 ? '表示' : c?.status === 0 ? '非表示' : '削除済',
    };
  });
  return {
    clientId: {
      item,
      count: clientRes?.searchCount ?? 0,
      totalCount: clientRes?.totalCount ?? 0,
      activeCount: clientRes?.activeCount ?? 0,
    },
  };
});

export const clientDetailFetch = createAsyncThunk(
  'client/detail',
  async params => {
    return await fetchRequest(apiUrl.client.detail, params);
  }
);
export const clientInfoFetch = createAsyncThunk('client/info', async params => {
  const [userRes, venueRes, paymentRes] = await Promise.all([
    fetchRequest(apiUrl.user.list),
    fetchRequest(apiUrl.venue.list),
    fetchRequest(apiUrl.payment.list),
  ]);
  return {
    userList: userRes?.item?.map(u => {
      return {
        ...u,
        value: u?.userId,
        label: `${u?.userId}:${u?.name?.lastName ?? ''} ${
          u?.name?.firstName ?? ''
        }`,
      };
    }),
    venueList: venueRes?.item?.map(v => {
      return {
        ...v,
        value: v?.venueId,
        label: `${v?.venueId}: ${v?.venueName}`,
      };
    }),
    payTermList: paymentRes?.item?.map(p => {
      return {
        ...p,
        value: p?.payTermsId,
        label: `${p?.payTermsId}: ${p?.payTermsName}`,
      };
    }),
  };
});

export const uploadImage = createAsyncThunk(
  'client/upload/image',
  async params => await fetchRequest(apiUrl.other.imageUpload, params, 'POST')
);
export const clientInsert = createAsyncThunk(
  'client/insert',
  async params => await fetchRequest(apiUrl.client.insert, params, 'POST')
);
export const clientUpdate = createAsyncThunk(
  'client/update',
  async params => await fetchRequest(apiUrl.client.update, params, 'POST')
);

export const clientDelete = createAsyncThunk(
  'client/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.client.delete, params, 'POST');
    dispatch(clientActions.refresh());
  }
);

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    setEdit: state => {
      state.editParam = { ...state.editParam };
    },
    saveParam: (state, { payload }) => {
      state.searchWorkParams = { ...state.searchParams };
    },
    resetParam: (state, { payload }) => {
      state.searchParams = {
        ...state.searchWorkParams,
        offset: 0,
        count: 20,
      };
      state.searchWorkParams = initialSearchParams;
    },
    refresh: state => {
      state.searchParams = initialSearchParams;
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvImportVisible: (state, { payload }) => {
      state.csvImportVisible = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    editReset: (state, { payload }) => {
      state.editParams = initialEditParams;
    },
    zipSearchFlag: (state, { payload }) => {
      state.zipSearch = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
  },
  extraReducers: {
    [clientInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [clientListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [clientDetailFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [clientInfoFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const clientActions = clientSlice.actions;
export default clientSlice.reducer;
