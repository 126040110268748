import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import { globalActions } from './global';
import payTermInfo from '../../constants/payTermInfo';
import { store } from '../store';

const initaialEditParam = {
  closingDate: '',
  closingDateName: '',
  payCategory: '',
  payDate: '',
  payPeriodDay: '',
  payPeriodMonth: '',
  payTermsName: '',
  priority: '',
};
const initialState = {
  searchCount: 0,
  totalCount: 0,
  activeCount: 0,
  payTermsCategoryList: [],
  payTermsList: [],
  editParam: initaialEditParam,
  csvImportVisible: false,
  csvExportVisible: false,
  nextId: 0,
};

export const payTermsInit = createAsyncThunk('payTerms/init', async () => {
  const res = await fetchRequest(apiUrl.payment.list);

  const item = res?.item?.map((p, i) => {
    const payPeriod = p.payDate
      ? `${
          payTermInfo?.payCategoryPayDay?.find(c => c?.value === p?.payDate)
            ?.label
        }`
      : `${
          payTermInfo?.payPeriodMonth?.find(c => c?.value === p?.payPeriodMonth)
            ?.label
        } /
                     ${
                       payTermInfo?.payCategoryPayDay?.find(
                         c => c?.value === p?.payPeriodDay
                       )?.label === '99日'
                         ? '末日'
                         : payTermInfo?.payCategoryPayDay?.find(
                             c => c?.value === p?.payPeriodDay
                           )?.label
                     } `;
    return {
      ...p,
      key: i,
      payCategoryName: payTermInfo?.payCategory?.find(
        c => c?.value === p?.payCategory
      )?.label,
      closingDateName: payTermInfo?.closingDate?.find(
        c => c?.value === p?.closingDate
      )?.label,
      payPeriodDayName: payPeriod,
      editIconState: p?.deleteLockFlag !== 1 ? 1 : 0,
      deleteIconState: p?.deleteLockFlag !== 1 ? 1 : 0,
    };
  });

  return {
    payTermsList: item,
  };
});

export const payTermsInsert = createAsyncThunk(
  'payTerms/insert',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.payment.insert, params, 'POST');
    dispatch(globalActions.showSingleModal(`登録しました`));
  }
);

export const payTermsUpdate = createAsyncThunk(
  'payTerms/update',
  async (params, { dispatch }) => {
    if (params?.closingDate && params.closingDate === 99) {
      params.closingDateName = '月末';
    } else if (params?.closingDate) {
      params.closingDateName = `${params.closingDate}日`;
    }
    await fetchRequest(apiUrl.payment.update, params, 'POST');
    dispatch(globalActions.showSingleModal(`更新しました`));
  }
);
export const payTermsMultiUpdate = createAsyncThunk(
  'payTerms/multiUpdate',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.payment.update, params, 'POST');
    dispatch(globalActions.showSingleModal(`更新しました`));
  }
);
export const payTermsDelete = createAsyncThunk(
  'payTerms/delete',
  async (params, { dispatch }) => {
    await fetchRequest(apiUrl.payment.delete, params, 'POST');
    store.dispatch(globalActions.showSingleModal('削除しました'));
    dispatch(payTermsActions.refresh());
  }
);

export const payTermsSlice = createSlice({
  name: 'payTerms',
  initialState,
  reducers: {},
  extraReducers: {
    [payTermsInit.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const payTermsActions = payTermsSlice.actions;
export default payTermsSlice.reducer;
