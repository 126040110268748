import React, { useEffect, useState } from 'react';
import ModalBase from '../modalBase';
import CustomInput from '../../customInput';
import Button from '../../button';
import Options from '../../../constants/options';
import './style.scss';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkIsSp,
  dispatchError,
  handlerFormikSubmit,
} from '../../../utils/fnUtil';
import { quoteNumUpdate } from '../../../redux/slice/quote';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { apiUrl } from '../../../api/apiUrl';
import { fetchRequest } from '../../../api/fetch';
import { log } from '@craco/craco/lib/logger';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import Yup from '../../../utils/yupUtil';

const QuoteNumModal = props => {
  const { modalVisible, changeModalVisible } = props;

  const isSp = checkIsSp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { corporateId, accessToken } = useSelector(state => state.account);

  //登録formik
  const formik = useFormik({
    initialValues: {
      quoteNumber: '',
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      quoteNumber: Yup.string()
        .nullable()
        .required()
        .nonZeroInteger('見積書No')
        .label('見積書No'),
    }),
    onSubmit: async values => {
      store.dispatch(globalActions.setLoading(true));

      const body = JSON.stringify({ quoteNumber: values.quoteNumber });

      //登録API
      await fetch(apiUrl.quote.numUpdate, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          corporate_id: corporateId,
          access_token: accessToken,
          request_from: 'backoffice',
        },
        body: body,
      })
        .then(res => {
          return res.json();
        })
        .then(json => {
          if (json.code == 0) {
            dispatch(globalActions.showSingleModal('更新しました'));
            changeModalVisible(false);
          } else {
            dispatchError(json.displayMessage);
          }
        })
        .catch(error => {
          dispatchError(
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
          );
          console.log('error: ', error);
        })
        .finally(() => store.dispatch(globalActions.setLoading(false)));

      // const response = await fetchRequest(
      //   apiUrl.quote.numUpdate,
      //   values,
      //   'POST'
      // );
      // console.log(response);
      // .then(json => {
      //   console.log(json);
      // })
      // .catch(error => {
      //   console.log(error);
      // });
      // dispatch(quoteNumUpdate())
      //   .unwrap()
      //   .then(data => {
      //     console.log(data);
      //     navigate(-1);
      //   });
    },
  });

  useEffect(() => {
    if (modalVisible) {
      (async () => {
        const res = await fetchRequest(
          apiUrl.quote.list,
          { count: 1, offset: 0, sort: 'quoteNumberDesc' },
          'GET'
        );

        return res.item[0];
      })().then(data =>
        formik.setFieldValue('quoteNumber', data.quoteNumber + 1)
      );
    }
  }, [modalVisible]);

  if (modalVisible) {
    return (
      <ModalBase
        dialogStyle={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: isSp ? 'flex-start' : 'center',
          width: isSp ? '95.5%' : '520px',
          height: isSp ? 'auto' : '415px',
          padding: isSp ? '32px 16px' : '20px 40px',
          boxSizing: isSp ? 'border-box' : '',
        }}
        modalVisible={true}
      >
        <div className="quote_num_modal--startest">
          <div className={'import_modal--title_area'}>
            <p>見積書No管理</p>
          </div>
          <div className="quote_num_modal--header">
            <p>見積書Noと発注書Noは同一になります。</p>
            <p>※見積書Noは重複不可です、本画面を開いた時点で</p>
            <p>
              　登録されている見積Noの最大値以下の数値は指定不可となります。
            </p>
          </div>
          <div className="quote_num_modal--main">
            <CustomFormikInput
              inputName={'quoteNumber'}
              formik={formik}
              inputType="input"
              label="見積書No"
              placeholder="見積書No(半角数字)を入力してください"
              style={{ inputStyle: { boxSizing: 'border-box' } }}
            />
          </div>
          <div className="quote_num_modal--btndiv">
            <Button
              text={'戻る'}
              style={
                isSp
                  ? Options.buttonStyles.stampBackSp
                  : Options.buttonStyles.stampBack
              }
              onClick={() => {
                changeModalVisible(false);
              }}
            />
            <Button
              text={'更新'}
              style={
                isSp
                  ? Options.buttonStyles.stampApprovalSp
                  : Options.buttonStyles.stampApproval
              }
              onClick={() => {
                handlerFormikSubmit(formik);
              }}
            />
          </div>
        </div>
      </ModalBase>
    );
  } else {
    return <></>;
  }
};

export default QuoteNumModal;
