import { Column } from '@ant-design/plots';
import { memo } from 'react';

const VerticalGraph = memo(({ data, color }) => {
  const config = {
    data,
    legend: false,
    tooltip: false,
    xField: 'answer',
    yField: 'rate',
    colorField: 'answer',
    barWidthRatio: 0.8,
    pattern: ({ answer }) => {
      return {
        type: 'dot',
        cfg: {
          size: 0.000001,
          backgroundColor:
            color[data?.findIndex(info => info?.answer === answer)],
        },
      };
    },
    label: {
      formatter: (text) => {
        return `${text?.rate?.toFixed(0)}%`;
      },
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 12,
      },
    },
    xAxis: false,
    yAxis: {
      min: 0,
      max: 100,
      label: {
        style: {
          fill: '#242424',
          fontSize: 12,
        },
      },
    },
  };

  return <Column height={210} {...config} />;
});

export default VerticalGraph;
