import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { globalActions } from '../../../redux/slice/global';
import {
  analyzeActions,
  analyzeStaffInit,
  analyzeStaffList,
} from '../../../redux/slice/analyze';
import { useFormik } from 'formik';
import Button from '../../../components/button';
import Copyright from '../../../components/copyright';
import Options from '../../../constants/options';
import Icons from '../../../constants/icons';
import Yup from '../../../utils/yupUtil';
import './style.scss';
import { Tabs } from 'antd';
import StaffGraph from './staffGraph';
import {
  checkIsSp,
  handlerFormikFieldChange,
  handlerFormikSubmit,
} from '../../../utils/fnUtil';
import RetireGraph from './retireGraph';
import ShiftGraph from './shiftGraph';
import ArrangeGraph from './arrangeGraph';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import moment from 'moment';

const monthDivSty = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

const AnalyzeTrend = () => {
  const dispatch = useDispatch();
  const isSp = checkIsSp();
  const { TabPane } = Tabs;
  const {
    searchStaffParams,
    deptList,
    retireReasonList,
    userList,
    staffAnalyze,
    shiftAnalyze,
    retireAnalyze,
    arrangeAnalyzeY,
    arrangeAnalyzeX,
  } = useSelector(state => state.analyze);
  const { activeTab, isOpenSideMenu } = useSelector(state => state.global);

  //検索条件の開閉
  const toggleOpenSearchForm = () => {
    const searchFormArea = document.querySelector(
      '.list_view--search_form_area'
    );
    const searchButtonArea = document.querySelector(
      '.list_view--search_button_area'
    );
    const searchBase = document.querySelector('.list_view--search_base');
    const triangle = document.getElementById('searchFormTriangle');

    triangle.style.transition = 'all .5s';

    if (
      triangle.style.transform === '' ||
      triangle.style.transform === 'rotate(0deg)'
    ) {
      searchFormArea.style.opacity = '0';
      searchButtonArea.style.opacity = '0';
      triangle.style.transform = 'rotate(180deg)';
      searchBase.style.maxHeight = '20px';
    } else {
      searchFormArea.style.opacity = '1';
      searchButtonArea.style.opacity = '1';
      triangle.style.transform = 'rotate(0deg)';
      searchBase.style.maxHeight = '1000px';
    }
  };

  //各グラフの最大値を算出(シフトタブを除く)
  const graphMaxCount = data => {
    let yearList = [];
    data?.forEach(obj => {
      yearList.push(obj.yearMonth);
    });

    const set = new Set(yearList);
    const newArr = [...set]; //yearListの重複した値を削除

    let numList = [];

    newArr?.forEach(y => {
      //各yearMonthの合算値を配列にまとめる
      let num = 0;
      data
        ?.filter(d => d?.yearMonth === y)
        .forEach(obj => {
          num = num + obj.value;
        });
      numList.push(num);
    });

    let maxCount = 0;
    if (numList.length !== 0) {
      //配列の中から最大値を取得
      const aryMax = (a, b) => Math.max(a, b);
      maxCount = numList?.reduce(aryMax);
    }

    //配列の中から最大値を丸める
    const round = n =>
      (m => m * Math.round(n / m) * 2)(10 ** (`${n}`.length - 1));

    return round(maxCount);
  };

  const formik = useFormik({
    initialValues: searchStaffParams,
    enableReinitialize: true,
    validateOnMount: true,
    validate: () => {
      delete formik?.errors?.yearMonthTo;
    },
    validationSchema: Yup.object({
      yearMonthFrom: Yup.string().required().diffFromToDate().label('年月'),
      yearMonthTo: Yup.string().required().diffFromToDate().label('年月'),
    }),
    onSubmit: values => {
      dispatch(analyzeActions.saveSearchStaffParams(values));
    },
  });

  const tabsInfo = {
    staff: {
      label: 'スタッフ数推移',
      jsx: () => (
        <StaffGraph
          graphData={staffAnalyze}
          isSp={isSp}
          maxCount={graphMaxCount(staffAnalyze)}
        />
      ),
    },
    shift: {
      label: 'シフト提出数',
      jsx: () => <ShiftGraph graphData={shiftAnalyze} isSp={isSp} />,
    },
    retire: {
      label: '離職理由',
      jsx: () => (
        <RetireGraph
          graphData={retireAnalyze}
          isSp={isSp}
          retireReasonList={retireReasonList}
          maxCount={graphMaxCount(retireAnalyze)}
        />
      ),
    },
    arrange: {
      label: '自社手配率',
      jsx: () => (
        <ArrangeGraph
          graphDataX={arrangeAnalyzeX}
          graphDataY={arrangeAnalyzeY}
          isSp={isSp}
          maxCount={graphMaxCount(arrangeAnalyzeY)}
        />
      ),
    },
  };

  useEffect(() => {
    dispatch(analyzeStaffInit()).then(() =>
      formik.setValues({
        ...searchStaffParams,
      })
    );
    dispatch(analyzeStaffList(searchStaffParams));
  }, [dispatch, searchStaffParams]);

  return (
    <>
      <div className="commonPage--base" style={{ paddingBottom: '0', zIndex: 3 }}>
        {/* 検索条件部分 */}
        <div className="list_view--search_base">
          <div className="list_view--search_title_area">
            <img
              id="searchFormTriangle"
              src={Icons.icon.downNv}
              onClick={toggleOpenSearchForm}
            />
            <p>検索条件</p>
          </div>
          <div className="list_view--search_form_area">
            <div className={'analyze_trend--search_from_to'}>
              <p style={{ marginBottom: '8px' }}>年月</p>
              <div
                className={'analyze_trend--calendar_unity'}
                style={monthDivSty}
              >
                <CustomFormikInput
                  inputType="inputMonth"
                  inputName="yearMonthFrom"
                  formik={formik}
                  placeholder="YYYY/MM"
                  disabledFutureDate={moment(
                    formik?.values?.yearMonthTo,
                    'YYYY-M'
                  ).format('YYYY/M')}
                  key={'yearMonthFrom'}
                />
                <p>～</p>
                <CustomFormikInput
                  inputType="inputMonth"
                  inputName="yearMonthTo"
                  formik={formik}
                  placeholder="YYYY/MM"
                  key={'yearMonthTo'}
                  disabledDate={moment(
                    formik?.values?.yearMonthFrom,
                    'YYYY-M'
                  ).format('YYYY/M')}
                />
              </div>
            </div>
            <CustomFormikInput
              inputType="select"
              inputName="deptId"
              formik={formik}
              mode="multiple"
              showSearch={true}
              label="部署"
              initialValue={deptList?.map(e => e.val)}
              selectBoxOptions={deptList?.map(e => e.text)}
              selectedSelectBox={formik?.values?.deptId}
              placeholder="部署名を入力してください"
              allowClear={false}
              className={{ areaClass: 'column' }}
              style={{
                areaStyle: { width: isSp ? '100%' : '258px' },
              }}
              onChange={v => {
                handlerFormikFieldChange(formik, 'deptId', v ?? null);
              }}
              usePopContainer={true} //これを設定するとポップアップがスクロールした時に動かなくなる。
            />
            <CustomFormikInput
              inputType="select"
              inputName="managerUserId"
              key={'managerUserId'}
              formik={formik}
              mode="multiple"
              showSearch={true}
              label="メイン担当者"
              initialValue={userList?.map(e => e.val)}
              selectBoxOptions={userList?.map(e => e.text)}
              selectedSelectBox={formik.values.managerUserId}
              allowClear={false}
              placeholder="メイン担当者を入力してください"
              className={{ areaClass: 'column' }}
              style={{
                areaStyle: { width: isSp ? '100%' : '400px' },
              }}
              onChange={v => {
                handlerFormikFieldChange(formik, 'managerUserId', v ?? null);
              }}
              usePopContainer={true}
            />
          </div>
          <div className="list_view--search_button_area">
            <Button
              text="検索"
              style={
                isSp
                  ? { ...Options.buttonStyles.search, width: '100%' }
                  : Options.buttonStyles.search
              }
              onClick={() => handlerFormikSubmit(formik)}
            />
          </div>
        </div>
      </div>

      <div className={'analyze_trend--main'} style={{ zIndex: 2 }}>
        <Tabs
          defaultActiveKey={Object.keys(tabsInfo)[0]}
          activeKey={activeTab ? activeTab : Object.keys(tabsInfo)[0]}
          onChange={tab => {
            dispatch(globalActions.changeActiveTab(tab));
            if (isSp) window.location.href = '#detailBase';
          }}
        >
          {Object.keys(tabsInfo).map(key => {
            return (
              <TabPane tab={tabsInfo[key].label} key={key}>
                {tabsInfo[key].jsx()}
              </TabPane>
            );
          })}
        </Tabs>
      </div>

      <Copyright
        style={{
          zIndex: 1,
          width: isSp ? '100%' : isOpenSideMenu ? 'calc(100% - 200px)' : 'calc(100% - 56px)'
      }} />
    </>
  );
};

export default AnalyzeTrend;
