const StaffPlanShiftInfos = {
  inputs: [
    {
      inputType: 'toggleButton',
      style: {
        areaStyle: {width: '64px'},
      }
    },
    {
      relativeInputName:['shiftStyleFlag'],
      initialValue: [0,1],
      inputType: 'radioButton',
    },
    {
      inputType: 'inputTime', //inputのtype
      relativeInputName:['startTime'],
      initialValue: '', //inputの初期値
      placeholder: '09:00',
      unit: '', //インプット横の単位
    },
    {
      inputType: 'inputTime', //inputのtype
      relativeInputName:['endTime'],
      initialValue: '', //inputの初期値
      placeholder: '15:00',
      unit: '', //インプット横の単位
    },
    {
      initialValue: '',
      placeholder: '備考を入力してください',
      className: {
        areaClass: '480px',
      },
      style: {
        areaStyle: {width: '100%', paddingRight: '24px'},
        labelStyle: {},
        inputStyle: {},
      }
    },
    {
      initialValue: '',
      placeholder: '備考を入力してください',
      className: {
        areaClass: '480px',
      },
      style: {
        areaStyle: {width: '100%', paddingRight: '24px'},
        labelStyle: {},
        inputStyle: {},
      }
    },
  ]
}

export default StaffPlanShiftInfos;
