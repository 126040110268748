import { Bar } from '@ant-design/plots';
import { memo } from 'react';

const HorizontalGraph = memo(({ data, color }) => {
  const config = {
    legend: false,
    tooltip: false,
    data,
    xField: 'rate',
    yField: 'answer',
    colorField: 'answer',
    barWidthRatio: 0.8,
    pattern: ({ answer }) => {
      return {
        type: 'dot',
        cfg: {
          size: 0.000001,
          backgroundColor:
            color[data?.findIndex(info => info?.answer === answer)],
        },
      };
    },
    label: {
      formatter: (text, item, i) => {
        return `${text?.rate?.toFixed(0)}%`;
      },
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 12,
      },
    },
    yAxis: false,
    xAxis: {
      min: 0,
      max: 100,
      label: {
        style: {
          fill: '#242424',
          fontSize: 12,
        },
      },
    },
  };

  return <Bar height={210} {...config} />;
});

export default HorizontalGraph;
