import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../constants/icons';
import ListView from '../../../components/listView';
import Copyright from '../../../components/copyright';
import CustomInput from '../../../components/customInput';
import { fetchApi, fetchRequest, zipApi } from '../../../api/fetch';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setCounter } from '../../../redux/slice/counter';
import { store } from '../../../redux/store';
import global, { globalActions } from '../../../redux/slice/global';
import { apiUrl } from '../../../api/apiUrl';
import { corporateActions } from "../../../redux/slice/corporate";
import { checkIsSp } from "../../../utils/fnUtil";

const moment = require('moment');

const CorporateList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiData, setApiData] = useState({ item: [] });
  const [allCorporate, setAllCorporate] = useState({ item: [] });

  const loginType = useSelector(state => state.loginType.type);
  const accountInfo = useSelector(state => state.account);
  const sysAuth = accountInfo.auth === 0;

  if (!accountInfo.menuCorporateListFlag) {
    navigate('/');
    store.dispatch(
      globalActions.showSingleModal('ご利用いただけないメニューです')
    );
  }

  const [searchParams, setSearchParams] = useState({
    corporateId: '',
    corporateName: [],
    status: 99,
    offset: 0,
    count: 20,
    sort: {
      column: 'corporateId',
      method: 'Desc',
    },
  });

  const searchInfo = useSelector(state => state.corporate.searchParams);
  const setSearchInfo = (info) => dispatch(corporateActions.saveSearchParams(info))

  const CorporateListSearch = () => {
    return (
      <>
        <div style={{ width: '100%' }}>
          <div style={checkIsSp() ?{ display: 'flex', gap: '24px',flexDirection:'column' }:{ display: 'flex', gap: '24px' }}>
            <CustomInput
              inputType="input"
              label="法人ID"
              inputName="searchCorporateId"
              placeholder="法人IDを入力してください"
              className={{ areaClass: 'column' }}
              style={{ areaStyle: { width: checkIsSp() ?'100%':'400px' } }}
            />
            <div>
              <CustomInput
                inputType="select"
                showSearch={true}
                useValue={true}
                mode={'multiple'}
                allowClear={false}
                label="法人名"
                inputName="searchCorporateName"
                placeholder="法人名を入力してください"
                initialValue={allCorporate?.item?.filter(c => c.deletedFlag === '0')?.map(obj => obj.corporateId)}
                selectBoxOptions={allCorporate?.item?.filter(c => c.deletedFlag === '0')?.map(
                  obj => obj.corporateName
                )}
                className={{ areaClass: 'column' }}
                style={{ areaStyle: { width: checkIsSp() ?'100%':'400px' } }}
                key={allCorporate.item[0] ? allCorporate.item[0] : ''}
                onChange={(e) => setSearchParams({...searchParams, corporateName: e})}
              />
            </div>
            <CustomInput
              inputType="select"
              showSearch={false}
              allowClear={false}
              label="ステータス"
              inputName="status"
              initialValue={[99, 1, 0, -1]}
              selectBoxOptions={['すべて', '有効', '無効', '削除済']}
              selectedSelectBox="すべて"
              placeholder=""
              className={{ areaClass: 'column' }}
              style={{ areaStyle: { width: checkIsSp() ?'100%':'150px' } }}
              onChange={e => setSearchParams({...searchParams, status: e})}
            />
          </div>
        </div>
      </>
    );
  };
  const searchFormInfo = {
    jsx: CorporateListSearch,
  };

  const columnSettings = {
    corporateId: { label: '法人ID', sort: 'asc', dataName: 'corporateId' },
    ntaNumber: {
      label: '国税庁法人番号',
      sort: 'none',
      dataName: 'ntaNumber',
    },
    corporateName: {
      label: '法人名',
      sort: 'none',
      dataName: 'corporateName',
    },
    startDate: { label: '利用開始日', sort: 'none', dataName: 'startDate' },
    endDate: { label: '利用終了日', sort: 'none', dataName: 'endDate' },
    status: { label: 'ステータス', sort: 'none', dataName: 'status' },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
    ],
  };

  const buttonSettings = sysAuth
    ? {
        viewPager: true,
        addButton: {
          props: {
            type: '', //roundを指定するとボタンがまるくなる
            onClick: checkIsSp()? () => {
              navigate('/master/corporate/register');
            }: '', //ボタンをクリックしたときにfunctionが発火する（urlより優先される）
            url: '/master/corporate/register', //ボタンをクリックした時の遷移先を指定する「'/main/invoice/list'」
            displayText: true, // trueを指定すると「新規追加」吹き出しが表示される
            disabled: '', //trueを指定するとdisableになる
            style: { cursor: 'pointer' }, //styleを指定することが可能になる
          },
        },
      }
    : {
        viewPager: true,
      };

  const changeSort = e => {
    if (e.target.id === searchInfo.sort.column) {
      setSearchInfo({
        ...searchInfo,
        sort: {
          ...searchInfo.sort,
          method: searchInfo.sort.method === 'Desc' ? 'Asc' : 'Desc',
        },
      });
    } else {
      setSearchInfo({
        ...searchInfo,
        sort: {
          column: e.target.id,
          method: 'Desc',
        },
      });
    }
  };

  const apiDataCopy = JSON.parse(JSON.stringify(apiData));

  apiDataCopy.item.map((item, i) => {
    switch (item.status) {
      case 0:
        item.status = '無効';
        break;
      case 1:
        item.status = '有効';
        break;
      case -1:
        item.status = '削除済';
        break;
    }
    item.endDate = moment(item.endDate).format('YYYY/MM/DD');
    item.startDate = moment(item.startDate).format('YYYY/MM/DD');
  });

  const apiDataList = {
    count: apiData.searchCount ? apiData.searchCount : 0,
    item: apiDataCopy.item,
  };

  const counterState = useSelector(state => state.counter);

  const updateApiData = data => {
    const titleCount = [data?.totalCount ?? 0, data?.activeCount ?? 0];

    const formatData = {
      ...data,
      item: data.item.map((obj, index) => {
        return obj.status === -1
          ? { ...obj, editIconState: 1, deleteIconState: 2 }
          : obj;
      }),
    };

    setApiData(formatData);
    dispatch(setCounter({ ...counterState, corporateCounter: titleCount }));
    store.dispatch(globalActions.setLoading(false));
  };

  const getApiData = async () => {
    let apiData;
    let requestBody = { ...searchInfo };

    for (let k in requestBody) {
      if (requestBody[k] === '' || requestBody[k] === undefined) {
        delete requestBody[k];
      }
    }

    store.dispatch(globalActions.setLoading(true));

    await fetchApi(
      'corporate',
      'list',
      'GET',
      loginType,
      accountInfo.corporateId,
      accountInfo.accessToken,
      {
        ...requestBody,
        sort: `${searchInfo.sort.column}${searchInfo.sort.method}`,
      }
    )
      .then(data => {
        apiData = data;
      })
      .then(() => {
        updateApiData(apiData);
      })
      .catch(error => {
        navigate('/');
        store.dispatch(globalActions.setLoading(false));
        store.dispatch(
          globalActions.showSingleModal(
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
          )
        );
      });

    await fetchApi(
      'corporate',
      'list',
      'GET',
      loginType,
      accountInfo.corporateId,
      accountInfo.accessToken,
      { count: 100000, offset: 0 }
    )
      .then(data => {
        setAllCorporate(data);
      })
      .catch(error => {
        navigate('/');
        store.dispatch(globalActions.setLoading(false));
        store.dispatch(
          globalActions.showSingleModal(
            '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
          )
        );
      });
  };

  const onSearch = () => {
    const cId = document.getElementById('searchCorporateId')?.value;

    setSearchInfo({
      ...searchInfo,
      corporateId: cId,
      corporateName: searchParams.corporateName.join(','),
      status: searchParams.status,
      offset: 0,
    });
  };

  useEffect(() => {
    if (sysAuth && !checkIsSp()) {
      document.getElementsByClassName('add_button--text')[0].innerHTML =
        '新規登録';
    }

    getApiData();
  }, [searchInfo]);

  const handleDelete = key => {
    fetchRequest(apiUrl.corporate.delete, { corporateId: key }, 'POST').then(
      () => {
        store.dispatch(globalActions.showSingleModal('削除しました'));
        getApiData();
      }
    );
  };

  const handleChangeListPerPage = num => {
    setSearchInfo({ ...searchInfo, count: num });
  };

  const handleChangePage = num => {
    setSearchInfo({ ...searchInfo, offset: num });
  };

  return (
    <div className="corporate_list">
      <div className="commonPage--base">
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey="corporateId"
          primaryName="corporateName"
          apiData={apiDataList}
          sortSettings={{
            sortColumn: searchInfo.sort.column,
            sortMethod: searchInfo.sort.method,
          }}
          changeSort={changeSort}
          onSearch={onSearch}
          onDelete={handleDelete}
          onChangeCount={handleChangeListPerPage}
          onChangeOffset={handleChangePage}
          displayNotice={false}
        />
        <Copyright />
      </div>
    </div>
  );
};

export default CorporateList;
