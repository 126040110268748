import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  quoteTemplateInfoFetch,
  quoteTemplateDetailFetch,
  quoteTemplateInsert,
  quoteTemplateUpdate,
  quoteActions,
} from '../../../../redux/slice/quote';
import { store } from '../../../../redux/store';
import { globalActions } from '../../../../redux/slice/global';
import { useFormik } from 'formik';
import { Tabs } from 'antd';
import Button from '../../../../components/button';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import Options from '../../../../constants/options';
import InsertTable from './insertTable';
import TableFooter from './tableFooter';
import { checkIsSp, handlerFormikSubmit } from '../../../../utils/fnUtil';
import Yup from '../../../../utils/yupUtil';
import './style.scss';

const divSty = {
  padding: '26px 0 0 0',
  display: 'flex',
  flexFlow: 'column',
  gap: '24px',
};

const { TabPane } = Tabs;
const isSp = checkIsSp();

const getVal = data =>
  data.map(item => ({
    detail: document?.getElementById(`detail${item.rowIndex - 1}`)?.value,
    amount: document?.getElementById(`amount${item.rowIndex - 1}`)?.value,
    quantity: document?.getElementById(`quantity${item.rowIndex - 1}`)?.value,
    unit: document?.getElementById(`unit${item.rowIndex - 1}`)?.value,
    rowType: item.rowType,
    rowIndex: item.rowIndex,
  }));

const QuoteTemplateRegister = () => {
  const { templateEdit, templateBody } = useSelector(state => state.quote);
  const isEdit = useLocation().pathname.slice(5).includes('edit');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const getTepmId = () => {
    if (isEdit) {
      return location.state.templateId;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    isEdit &&
      dispatch(quoteTemplateDetailFetch({ templateId: getTepmId() })).then(
        ({ payload }) => {
          formik.setValues(payload.detail);
          // let editData = payload.detail.templateBody.map(item => ({
          //   rowIndex: item.rowIndex,
          //   rowType: item.rowType,
          //   detail: item.detail,
          //   quantity: item.quantity,
          //   unit: item.unit,
          //   amount: item.amount,
          // }));
          let editData = [...payload.detail.templateBody];
          dispatch(quoteActions.setTemplateBody(editData));
        }
      );
  }, []);

  const formik = useFormik({
    initialValues: { ...templateEdit },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      templateName: Yup.string().required().label('テンプレート名').max(100),
    }),
    onSubmit: values => {
      let bodyData = getVal(templateBody);
      dispatch(quoteActions.setTemplateBody(bodyData));
      values.templateId = location.state?.templateId;
      values.templateBody = bodyData;

      isEdit
        ? dispatch(quoteTemplateUpdate(values)).then(({ error }) => {
            if (!error) {
              store.dispatch(globalActions.showSingleModal('更新しました'));
              navigate(-1);
            }
          })
        : dispatch(quoteTemplateInsert(values)).then(({ error }) => {
            if (!error) {
              store.dispatch(globalActions.showSingleModal('登録しました'));
              navigate(-1);
            }
          });
    },
  });
  const { templateType } = formik.values;

  return (
    <>
      <div className="page_base--container">
        <div className="page_base--tabs">
          <Tabs defaultActiveKey="0">
            <TabPane tab="見積テンプレート" key="見積テンプレート">
              <div style={divSty}>
                {isEdit ? (
                  <div
                    className="quote_template_register--template_type"
                    style={isSp ? { display: 'block' } : {}}
                  >
                    <div className="quote_template_register--template_type--lable">
                      種別<span>*</span>
                    </div>
                    <div
                      className="quote_template_register--template_type--word"
                      style={isSp ? { marginTop: '8px', fontSize: '12px' } : {}}
                    >
                      {templateType === 0 ? '共通' : '個人'}
                    </div>
                  </div>
                ) : (
                  <div
                    onChange={() => {
                      dispatch(
                        quoteActions.setTemplateBody(getVal(templateBody))
                      );
                    }}
                  >
                    <CustomFormikInput
                      inputType="radioButton"
                      formik={formik}
                      radioButtonTitle="種別"
                      labelRequired={true}
                      inputName="templateType"
                      radioButtonLabelAndChecked={[
                        { label: '共通', checked: templateType == 0 },
                        { label: '個人', checked: templateType == 1 },
                      ]}
                      initialValue={[0, 1]}
                      style={{ labelStyle: { width: '175px' } }}
                    />
                  </div>
                )}
                <div
                  onChange={() => {
                    dispatch(
                      quoteActions.setTemplateBody(getVal(templateBody))
                    );
                  }}
                >
                  <CustomFormikInput
                    formik={formik}
                    label="テンプレート名"
                    labelRequired={true}
                    inputName="templateName"
                    placeholder="テンプレート名を入力してください"
                    style={{
                      labelStyle: { width: '175px' },
                      inputStyle: { width: '68%' },
                    }}
                  />
                </div>
              </div>
              {isSp ? (
                <div style={{ width: '100%', overflowX: 'scroll' }}>
                  <div className="quote_template_register--main">
                    <InsertTable getVal={getVal} />
                    <div style={{ marginTop: '19px' }}>
                      <TableFooter formik={formik} getVal={getVal} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="quote_template_register--main">
                  <InsertTable getVal={getVal} />
                  <div style={{ marginTop: '19px' }}>
                    <TableFooter formik={formik} getVal={getVal} />
                  </div>
                </div>
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>

      <div className="bottom_btn_area" style={{ height: '92px' }}>
        <div className="quote_register--submit_buttons">
          <Button
            text="戻る"
            style={Options.buttonStyles.stampBack}
            onClick={() => {
              navigate(-1);
            }}
          />
          <Button
            text={isEdit ? '更新' : '登録'}
            style={Options.buttonStyles.stampApproval}
            onClick={() => {
              let bodyData = getVal(templateBody);
              dispatch(quoteActions.setTemplateBody(bodyData));
              handlerFormikSubmit(formik);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default QuoteTemplateRegister;
