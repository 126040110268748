import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';

const nowDate = new Date();
const nowYear = nowDate.getFullYear();
const nowMonth = nowDate.getMonth() + 1;

const initialSearchParams = {
  year: nowYear,
  month: nowMonth,
  yearMonth: nowYear + '/' + nowMonth,
  employmentType: null,
  staffId: '',
  staffName: '',
  managerUserId: null,
  status: 99,
  count: 20,
  offset: 0,
  sort: 'staffIdAsc',
  sortColumn: 'staffId',
  sortMethod: 'Asc',
  isZero: true,
  employmentTypeArray: [],
  managerUserIdArray: [],
  statusArray: [99],
  statusMode: '',
};

const initialEditParams = {
  staffId: '',
  year: '',
  month: '',
};

const updateParams = {
  year: 1,
  month: 1,
  staffId: 5963,
  mailFlag: 0,
  managerApproval: 0,
  managerNote: '',
  staffApproval: 0,
  staffNote: '',
  accountantApproval: 0,
  accountantNote: '',
};

const yearSearchParams = {
  year: nowYear,
  employmentType: null,
  staffId: null,
  staffName: null,
  managerUserId: null,
  retireeStatus: 1,
  count: 20,
  offset: 0,
  employmentTypeArray: [],
  managerUserIdArray: [],
};

const initialClosingParams = {
  year: 1,
  month: 1,
  closingFromDate: '',
  closingToDate: '',
  staffConfirmFromDate: '',
  staffConfirmToDate: '',
  payClosingDate: '',
  staffId: '',
};

const notUpdateCategory = ['Index'];

const initialEditDetailParams = {
  staffDetail: {
    staffImage: '',
    staffId: '',
    staffName: {
      firstName: '',
      lastName: '',
    },
    daytimeTel: null,
    nearestStations: { stationName: '' },
    payMethod: null,
    nearestStations: [
      {
        stationName: '',
      },
    ],
  },
  staffPayDetail: {
    payList: [],
  },
};

const initialState = {
  searchParams: initialSearchParams,
  editParams: initialEditParams,
  searchWorkParams: initialSearchParams,
  staffPayList: [],
  managerUserIdList: [],
  staffPayEditList: initialEditDetailParams,
  searchCount: 0,
  activeCount: 0,
  submitCount: 0,
};

const initialDetailState = {
  detailList: [],
  searchDetailList: [],
  editParams: initialEditDetailParams,
};

const yearState = {
  searchParams: yearSearchParams,
  staffPayYearList: [],
  managerUserIdList: [],
  editParam: [],
  searchCount: 0,
  activeCount: 0,
  submitCount: 0,
};

const initialClosingState = {
  editParam: [],
};

const convertLocaleString = amount => {
  return amount ? amount.toLocaleString() + '円' : '0円';
};

const convertStaffYearItem = c => {
  return {
    ...c,
    staffName: c?.staffName?.lastName + c?.staffName?.firstName,
    totalPay: convertLocaleString(c?.totalPay),
    incomeTaxAmount: convertLocaleString(c?.incomeTaxAmount),
    jan: convertLocaleString(c?.jan),
    feb: convertLocaleString(c?.feb),
    mar: convertLocaleString(c?.mar),
    apr: convertLocaleString(c?.apr),
    may: convertLocaleString(c?.may),
    jun: convertLocaleString(c?.jun),
    jul: convertLocaleString(c?.jul),
    aug: convertLocaleString(c?.aug),
    sep: convertLocaleString(c?.sep),
    oct: convertLocaleString(c?.oct),
    nov: convertLocaleString(c?.nov),
    dec: convertLocaleString(c?.dec),
  };
};

export const staffPayListFetch = createAsyncThunk(
  'staff_pay/list',
  async params => {
    const listRes = await fetchRequest(apiUrl.staffPay.list, params, 'GET');
    const filterListRes = listRes?.item?.filter(
      c => !(!params.isZero && c?.payAmount === 0)
    );
    const staffListItem = filterListRes?.map(c => {
      return {
        ...c,
        staffName: c?.staffName?.lastName + c?.staffName?.firstName,
        managerName: c?.managerName?.lastName + c?.managerName?.firstName,
        payAmount: c?.payAmount?.toLocaleString() + '円',
      };
    });

    return {
      staffPayList: {
        item: staffListItem,
        count: listRes?.searchCount ?? 0,
      },
    };
  }
);

export const staffPayInfoFetch = createAsyncThunk(
  'staff_pay/list',
  async params => {
    const [userRes, listRes] = await Promise.all([
      fetchRequest(apiUrl.user.list),
      fetchRequest(apiUrl.staffPay.list, {
        ...initialSearchParams,
      }),
    ]);
    const userIdArray = userRes?.item?.map(c => {
      return {
        userId: c?.userId,
        userName: c?.name.lastName + c?.name.firstName,
      };
    });
    const staffListItem = listRes?.item?.map(c => {
      return {
        ...c,
        staffName: c?.staffName?.lastName + c?.staffName?.firstName,
        managerName: c?.managerName?.lastName + c?.managerName?.firstName,
        payAmount: c?.payAmount?.toLocaleString() + '円',
      };
    });
    return {
      managerUserIdList: userIdArray,
      staffPayList: {
        item: staffListItem,
        count: listRes?.searchCount ?? 0,
      },
    };
  }
);

export const staffPayDetailFetch = createAsyncThunk(
  'staff_pay/detail',
  async params => {
    const [staffRes, detailRes] = await Promise.all([
      fetchRequest(apiUrl.staff.detail, params, 'GET'),
      fetchRequest(apiUrl.staffPay.detail, params, 'GET'),
    ]);
    return {
      staffPayEditList: {
        staffDetail: staffRes.detail,
        staffPayDetail: detailRes.detail,
      },
    };
  }
);

export const staffPayUpdateFetch = createAsyncThunk(
  'staff_pay/update',
  async params => await fetchRequest(apiUrl.staffPay.update, params, 'POST')
);

export const staffPayStatusUpdateFetch = createAsyncThunk(
  'staff_pay/status/update',
  async params =>
    await fetchRequest(apiUrl.staffPay.statusUpdate, params, 'POST')
);

export const staffPayYearListFetch = createAsyncThunk(
  'staff_pay/year/list',
  async params => {
    const yearListRes = await fetchRequest(apiUrl.staffPay.yearList, params);
    const item = yearListRes?.item?.map(c => {
      return convertStaffYearItem(c);
    });

    item.sort((id1, id2) => {
      return id1.staffId - id2.staffId;
    });

    return {
      staffPayYearList: {
        item,
        count: yearListRes?.searchCount ?? 0,
      },
    };
  }
);

export const staffPayYearInfoFetch = createAsyncThunk(
  'staff_pay/year/info',
  async () => {
    const [userRes, yearListRes] = await Promise.all([
      fetchRequest(apiUrl.user.list),
      fetchRequest(apiUrl.staffPay.yearList, { year: nowYear }),
    ]);

    const userIdArray = userRes?.item?.filter(u => u.deletedFlag === '0')?.map(c => {
      return {
        userId: c?.userId,
        userName: c?.name.lastName + c?.name.firstName,
      };
    });
    const staffYearItem = yearListRes?.item?.map(c => {
      return convertStaffYearItem(c);
    });

    staffYearItem.sort((id1, id2) => {
      return id1.staffId - id2.staffId;
    });

    return {
      managerUserIdList: userIdArray,
      staffPayYearList: {
        item: staffYearItem,
        count: yearListRes?.searchCount ?? 0,
      },
    };
  }
);

export const staffPayClosingFetch = createAsyncThunk(
  'staff_pay/closing',
  async params => await fetchRequest(apiUrl.staffPay.closing, params, 'POST')
);

export const staffPaySlice = createSlice({
  name: 'staffPay',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveParam: (state, { payload }) => {
      state.searchWorkParams = { ...state.searchParams };
    },
    resetParam: (state, { payload }) => {
      state.searchParams = {
        ...state.searchWorkParams,
        offset: 0,
        count: 20,
      };
      state.searchWorkParams = initialSearchParams;
    },
    setEdit: (state, { payload }) => ({
      ...state,
      editParams: {
        ...state.editParams,
        ...payload,
      },
    }),
    refresh: state => {
      state.searchParams = initialSearchParams;
    },
    listRefresh: state => {
      state.searchParams = { ...state.searchParams };
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvImportVisible: (state, { payload }) => {
      state.csvImportVisible = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
  },
  extraReducers: {
    [staffPayInfoFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffPayListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffPayDetailFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const staffPayYearSlice = createSlice({
  name: 'staffPayYear',
  initialState: yearState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveParam: (state, { payload }) => {
      state.searchWorkParams = { ...state.searchParams };
    },
    resetParam: (state, { payload }) => {
      state.searchParams = {
        ...state.searchWorkParams,
        offset: 0,
        count: 20,
      };
      state.searchWorkParams = yearSearchParams;
    },
    setEdit: state => {
      state.editParam = { ...state.editParam };
    },
    refresh: state => {
      state.searchParams = yearSearchParams;
    },
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setCsvImportVisible: (state, { payload }) => {
      state.csvImportVisible = payload;
    },
    setCsvExportVisible: (state, { payload }) => {
      state.csvExportVisible = payload;
    },
    setSort: (state, { payload }) => {
      state.searchParams = {
        ...state.searchParams,
        ...payload,
      };
    },
  },
  extraReducers: {
    [staffPayYearInfoFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [staffPayYearListFetch.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const staffPayActions = staffPaySlice.actions;
export const staffPayReducer = staffPaySlice.reducer;
export const staffPayYearActions = staffPayYearSlice.actions;
export const staffPayYearReducer = staffPayYearSlice.reducer;
