import { memo } from 'react';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { imageUpload } from '../../../../redux/slice/matter';
import {
  deleteArrayItem,
  handlerFormikFieldChange,
  checkIsSp,
} from '../../../../utils/fnUtil';
import DeleteIcon from '../../../../components/deleteIcon';
import ImageUploader from '../../../../components/imageUploader/imageUploader';
import ReportSwitch from '../../../../components/switch/reportSwitch';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import AddLineButton from '../../../../components/addLineButton';

const ReportImage = memo(({ formik }) => {
  const {
    values: { folder = [] },
  } = formik;

  const dispatch = useDispatch();

  const isSp = checkIsSp();

  return (
    <div>
      {folder.map((f, i) => (
        <div key={i}>
          <div className="report_image tab_content">
            <DeleteIcon
              title="フォルダ削除"
              onClick={() =>
                handlerFormikFieldChange(
                  formik,
                  'folder',
                  deleteArrayItem(folder, i)
                )
              }
              style={isSp ? { width: '30px', height: '30px' } : {}}
            />
            <CustomFormikInput
              formik={formik}
              inputName={`folder.[${i}].folderName`}
              label="フォルダ名"
              placeholder="フォルダ名を入力してください"
              style={{
                areaStyle: {
                  maxWidth: 700,
                },
              }}
            />
            <ReportSwitch
              formik={formik}
              reportInputName={`folder.[${i}].reportFlag`}
              staffInputName={`folder.[${i}].staffFlag`}
              toggleSize={'default'}
            />
          </div>
          <div className="report_image--images">
            {Array(16)
              .fill('')
              .map((_, k) => (
                <ImageUploader
                  image={f[`folderImage${k + 1}`]}
                  imageChecked={f[`folderImage${k + 1}Active`] === 1}
                  onCheckChange={() =>
                    handlerFormikFieldChange(
                      formik,
                      `folder[${i}].folderImage${k + 1}Active`,
                      get(folder, `[${i}].folderImage${k + 1}Active`) === 1
                        ? 0
                        : 1
                    )
                  }
                  key={k}
                  onDelete={() => {
                    handlerFormikFieldChange(
                      formik,
                      `folder.[${i}].folderImage${k + 1}`,
                      ''
                    );
                  }}
                  onAddImage={async img => {
                    const imageUrl = await dispatch(imageUpload(img)).unwrap();

                    handlerFormikFieldChange(
                      formik,
                      `folder[${i}].folderImage${k + 1}`,
                      imageUrl
                    );
                    handlerFormikFieldChange(
                      formik,
                      `folder[${i}].folderImage${k + 1}Active`,
                      1
                    );
                  }}
                />
              ))}
            {[...Array(16)].map(() => (
              <i />
            ))}
          </div>
        </div>
      ))}
      {folder.length < 5 && (
        <div className="report_image--action">
          <AddLineButton
            title="フォルダ追加"
            onClick={() =>
              handlerFormikFieldChange(formik, 'folder', [
                ...folder,
                {
                  reportFlag: 1,
                  staffFlag: 1,
                },
              ])
            }
          />
          <span className="report_image--action_tip">
            フォルダは5つまで追加可能です
          </span>
        </div>
      )}
    </div>
  );
});

export default ReportImage;
