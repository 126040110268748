import './style.scss';
import Icons from '../../constants/icons';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import Pager from '../pager';
import icons from '../../constants/icons';
import CustomInput from '../customInput';
import Button from '../button';
import { Router, useNavigate } from 'react-router-dom';
import DeleteModal from '../modal/deleteModal';
import { testData } from './testData';
import SearchSelectBox from '../searchSelectBox';
import AddButton from '../addButton';
import Options from '../../constants/options';
import { useDispatch, useSelector } from 'react-redux';
import { setCounter } from '../../redux/slice/counter';
import { checkIsSp, mapColumn } from '../../utils/fnUtil';
import classNames from 'classnames';
import { isNil } from 'lodash';

const ListView = ({
  searchFormInfo,
  columnSettings,
  buttonSettings,
  primaryKey,
  primaryName,
  apiData,
  sortSettings,
  changeSort,
  onSearch,
  onChangeCount,
  onChangeOffset,
  onDelete,
  displayNotice,
  tip,
  searchTitle = '検索条件',
  emptyText,
  disabledTip,
  spView,
  keepCheckedFlag = false,
  multiCalendar,
  staffAssignPage,
}) => {
  const registerType = useLocation().pathname.match(/\/(.*)\//)[1];
  const navigate = useNavigate();
  const [isCheckAll, setCheckAll] = useState(false);

  const toggleChecks = boo => {
    const checkBoxes =
      document
        .getElementsByClassName('list_view--list_table')?.[0]
        ?.querySelectorAll('input, #check_') ?? [];

    Array.prototype.forEach.call(checkBoxes, elm => {
      elm.checked = boo;
    });
  };

  const { width } = useWindowSize();

  const toggleCheckAll = () => {
    setCheckAll(!isCheckAll);

    toggleChecks(!isCheckAll);
  };

  const searchCount = apiData.count ? apiData.count : 0;
  // const data = apiData.item;
  const data = (apiData?.item ?? []).map(item =>
    mapColumn(item, columnSettings, primaryKey)
  );

  useEffect(() => {
    if (!keepCheckedFlag) toggleChecks(isCheckAll);
  });

  const [listPerPage, setListPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const pages =
    Math.floor(searchCount / listPerPage) + (searchCount % listPerPage ? 1 : 0);

  const changeListPerPage = e => {
    setListPerPage(e);
    onChangeCount?.(e);

    setCurrentPage(1);
  };

  const changeCurrentPage = e => {
    if (e.target.value === '+') {
      setCurrentPage(currentPage + 3);
    } else if (e.target.value === '-') {
      setCurrentPage(currentPage - 3);
    } else {
      setCurrentPage(Number(e.target.value));
    }
    setCurrentPage(current => {
      onChangeOffset?.(listPerPage * (current - 1));
      return current;
    });
  };

  const adjustZIndex = () => {
    const commonPageBase = document.querySelector('.commonPage--base');
    commonPageBase.style.zIndex = 'auto';
  }

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteKey, setDeleteKey] = useState('');
  const [deleteName, setDeleteName] = useState('');
  const [deleteIndex, setDeleteIndex] = useState(null);

  const toggleDeleteModal = () => {
    setDeleteModalVisible(!deleteModalVisible);
  };

  const setDeleteInfo = (key, name, index) => {
    setDeleteKey(key);
    setDeleteName(name);
    setDeleteIndex(index);
    toggleDeleteModal();
  };

  const toggleOpenSearchForm = () => {
    const searchFormArea = document.querySelector(
      '.list_view--search_form_area'
    );
    const searchButtonArea = document.querySelector(
      '.list_view--search_button_area'
    );
    const searchBase = document.querySelector('.list_view--search_base');
    const triangle = document.getElementById('searchFormTriangle');

    triangle.style.transition = 'all .5s';

    if (
      triangle.style.transform === '' ||
      triangle.style.transform === 'rotate(0deg)'
    ) {
      searchFormArea.style.opacity = '0';
      searchButtonArea.style.opacity = '0';
      triangle.style.transform = 'rotate(180deg)';
      searchBase.style.maxHeight = checkIsSp() ? '80px' : '20px';
    } else {
      searchFormArea.style.opacity = '1';
      searchButtonArea.style.opacity = '1';
      triangle.style.transform = 'rotate(0deg)';
      searchBase.style.maxHeight = '1000px';
    }
  };

  useEffect(() => {
    if (checkIsSp() && columnSettings.checkAll) {
      const checkAllLabelText = document.querySelector(
        'label.check_box--label_text[for=checkAll]'
      );

      checkAllLabelText.style.display = 'none';
    }
  }, []);

  return (
    <>
      {searchFormInfo ? (
        <div
          className={classNames('list_view--search_base', {
            'list_view--search_base_with_tip': tip,
          })}
        >
          {tip && <div className="list_view--search_title_tip">{tip}</div>}
          <div className={'list_view--search_title_area'}>
            <img
              id={'searchFormTriangle'}
              src={Icons.icon.downNv}
              onClick={toggleOpenSearchForm}
            />
            <p>{searchTitle}</p>
          </div>
          {!disabledTip && (
            <>
              <div className={'list_view--search_form_area'}>
                {searchFormInfo.jsx
                  ? searchFormInfo.jsx()
                  : searchFormInfo.inputs.map((inputInfo, index) => {
                      if (inputInfo.inputType === 'searchSelect') {
                        return <SearchSelectBox key={index} {...inputInfo} />;
                      } else {
                        return <CustomInput key={index} {...inputInfo} />;
                      }
                    })}
              </div>
              <div className={'list_view--search_button_area'}>
                <Button
                  text={'検索'}
                  style={
                    checkIsSp()
                      ? { ...Options.buttonStyles.search, width: '100%', height: '40px', fontSize: '12px' }
                      : Options.buttonStyles.search
                  }
                  // onClick={onSearch}
                  onClick={() => {
                    onSearch();
                    setCurrentPage(1);
                  }}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}

      {buttonSettings ? (
        <div className={'list_view--buttons_base'}>
          {checkIsSp() ? (
            <>
              {buttonSettings.viewPager && (
                <>
                  <p className={'list_view--count_search'}>
                    検索結果：{searchCount}件
                  </p>

                  <div className={'list_view--count_forSp'}>
                    <CustomInput
                      onChange={changeListPerPage}
                      inputType={'select'}
                      inputName={'count_select'}
                      selectBoxOptions={[20, 50, 100]}
                      initialValue={[20, 50, 100]}
                      selectedSelectBox={20}
                      allowClear={false}
                      style={{ areaStyle: { width: '120px' } }}
                      usePopContainer={true}
                    />
                    <span>件</span>
                  </div>

                  {apiData.count !== 0 && (
                    <div className={'list_view--pagerWrap_forSp'}>
                      <Pager
                        searchCount={!searchCount ? 0 : searchCount}
                        listPerPage={listPerPage}
                        currentPage={currentPage}
                        setPage={changeCurrentPage}
                      />
                    </div>
                  )}
                  {searchCount !== 0 && (
                    <p className={'list_view--count_page'}>
                      {currentPage}/{pages}ページ
                    </p>
                  )}

                  {!buttonSettings.ignoreAddBtn && (
                    <AddButton
                      spListViewMode={true}
                      onClick={() => buttonSettings.addButton.props.onClick()}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <div className={'list_view--buttons_left'}>
                {buttonSettings.viewPager && (
                  <>
                    <div className={'list_view--count_area'}>
                      <p
                        className={'list_view--count_search'}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        検索結果：{Number(searchCount).toLocaleString()}件
                      </p>
                    </div>

                    {!(emptyText && searchCount === 0) && (
                      <div
                        className={'list_view--count_select'}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <CustomInput
                          onChange={changeListPerPage}
                          inputType={'select'}
                          inputName={'count_select'}
                          selectBoxOptions={[20, 50, 100]}
                          initialValue={[20, 50, 100]}
                          selectedSelectBox={20}
                          allowClear={false}
                          usePopContainer={true}
                        />
                        件
                      </div>
                    )}

                    {apiData.count !== 0 && (
                      <Pager
                        searchCount={!searchCount ? 0 : searchCount}
                        listPerPage={listPerPage}
                        currentPage={currentPage}
                        setPage={changeCurrentPage}
                      />
                    )}

                    <div className={'list_view--count_area'}>
                      {searchCount !== 0 && (
                        <p
                          className={'list_view--count_page'}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {currentPage}/{pages}ページ
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className={'list_view--buttons_right'}>
                {Object.keys(buttonSettings).map(key => {
                  if (key !== 'viewPager' && key !== 'ignoreAddBtn') {
                    if (key === 'addButton') {
                      return (
                        <AddButton
                          key={key}
                          {...buttonSettings[key].props}
                          screenType={true}
                        />
                      );
                    }
                    if (key === 'staffYearSel') {
                      return <CustomInput key={key} {...buttonSettings[key]} />;
                    } else {
                      return (
                        <Button
                          key={key}
                          icon={buttonSettings[key].icon}
                          text={buttonSettings[key].text}
                          style={buttonSettings[key].style}
                          onClick={buttonSettings[key].onClick}
                          iconStyle={buttonSettings[key].iconStyle}
                        />
                      );
                    }
                  }
                })}
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}

      {disabledTip ? (
        <span className="list_view--empty_text">{disabledTip}</span>
      ) : emptyText && searchCount === 0 ? (
        <span className="list_view--empty_text">{emptyText}</span>
      ) : spView && width <= 800 ? (
        spView
      ) : (
        <div className={'list_view--list_wrapper'}>
          {multiCalendar && multiCalendar}
          <table className={'list_view--list_table'}>
            <thead>
              <tr>
                {columnSettings.checkAll && (
                  <th
                    key={'checkAll'}
                    id={'th_checkAll'}
                    className={'list_view--static_th'}
                  >
                    <CustomInput
                      inputName={'checkAll'}
                      inputType={'checkBox'}
                      initialValue={false}
                      onChange={toggleCheckAll}
                      style={{
                        areaStyle: { justifyContent: 'center', gap: 'unset' },
                      }}
                    />
                  </th>
                )}
                {Object.keys(columnSettings).map(key => {
                  if (key !== 'statics' && key !== 'checkAll') {
                    return key !== 'image' ? (
                      <th
                        key={key}
                        onClick={changeSort}
                        id={columnSettings[key].dataName}
                        className={
                          columnSettings[key].sort === ''
                            ? 'list_view_table_th'
                            : ''
                        }
                      >
                        {columnSettings[key].label}
                        {sortSettings.sortColumn ===
                        columnSettings[key].dataName ? (
                          sortSettings.sortMethod === 'Asc' ? (
                            <div className={'list_view--sort_icon'}>
                              <img
                                src={icons.icon.downGr}
                                style={{ transform: 'rotate(180deg)' }}
                                id={columnSettings[key].dataName}
                              />
                            </div>
                          ) : (
                            <div className={'list_view--sort_icon'}>
                              <img
                                src={icons.icon.downGr}
                                id={columnSettings[key].dataName}
                              />
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                      </th>
                    ) : (
                      <th
                        key={key}
                        style={{ cursor: 'unset', border: 'none' }}
                      ></th>
                    );
                  }
                })}
                {columnSettings.statics.map(obj => {
                  return (
                    <th className={'list_view--static_th'} key={obj.label}>
                      {obj.label}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {data.map((obj, index) => {
                return (
                  <tr
                    key={index}
                    className={classNames({
                      'tr-excess':
                        (apiData.item ?? [])[index]?.excessFlag === 1,
                    })}
                    style={{
                      backgroundColor: index % 2 ? '#F8F8F8' : '#FFFFFF',
                    }}
                  >
                    {columnSettings.checkAll && (
                      <td
                        key={`check_${index}`}
                        id={`td_check_${index}`}
                        style={{ borderRight: '1px dashed #E0E0E0' }}
                      >
                        <CustomInput
                          inputName={`check_${index}`}
                          inputType={'checkBox'}
                          initialValue={false}
                          style={{
                            areaStyle: {
                              justifyContent: 'center',
                              gap: 'unset',
                            },
                          }}
                        />
                      </td>
                    )}

                    {Object.keys(obj)
                      .filter(key => key !== 'primaryKey')
                      .map(key => {
                        return key !== 'image' && key !== 'secret_key' ? (
                          <td
                            key={key}
                            style={{ borderRight: '1px dashed #E0E0E0' }}
                          >
                            {typeof obj[key] === 'function'
                              ? obj[key]()
                              : !isNil(obj[key])
                              ? !Number.isNaN(obj[key])
                                ? obj[key]
                                : ''
                              : ''}
                          </td>
                        ) : (
                          key === 'image' && (
                            <td
                              style={{ width: '90px', textAlign: 'center' }}
                              key={key}
                            >
                              <a
                                style={{
                                  display: 'block',
                                  width: '60px',
                                  height: '60px',
                                  backgroundImage: `url(${obj[key]})`,
                                  backgroundPosition: 'center center',
                                  backgroundSize: 'cover',
                                  borderRadius: '50%',
                                  margin: 'auto auto',
                                  cursor: 'pointer'
                                }}
                                href={obj[key]}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                              ></a>
                            </td>
                          )
                        );
                      })}
                    {columnSettings.statics.map(staticObj => {
                      if (staticObj.label === '編集' && !staticObj.onClick) {
                        return (
                          <td
                            style={{ textAlign: 'center' }}
                            key={staticObj.label}
                          >
                            {!apiData?.item?.filter((d, i) => i === index)[0]
                              ?.editIconState ||
                            apiData?.item?.filter((d, i) => i === index)[0]
                              ?.editIconState === 0 ? (
                              <img
                                src={staticObj.icon}
                                alt={'edit'}
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/${registerType}/edit`, {
                                    state: {
                                      id: obj.primaryKey ?? obj[primaryKey],
                                      deletedFlag: 0,
                                    },
                                  })
                                }
                              />
                            ) : (
                              apiData?.item?.filter((d, i) => i === index)[0]
                                ?.editIconState === 1 && (
                                <img
                                  src={Icons.icon.editSGr1}
                                  style={{ fill: '#bcbcbc' }}
                                  alt={'edit_disabled'}
                                />
                              )
                            )}
                          </td>
                        );
                      } else if (staticObj.label === '削除') {
                        return (
                          <td
                            style={{ textAlign: 'center' }}
                            key={staticObj.label}
                          >
                            {!apiData?.item?.filter((d, i) => i === index)[0]
                              ?.deleteIconState ||
                            apiData?.item?.filter((d, i) => i === index)[0]
                              ?.deleteIconState === 0 ? (
                              <img
                                src={staticObj.icon}
                                alt={'delete'}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setDeleteInfo(
                                    obj.primaryKey ?? obj[primaryKey],
                                    obj[primaryName],
                                    index,
                                  );
                                  adjustZIndex();
                                }
                                }
                              />
                            ) : apiData?.item?.filter((d, i) => i === index)[0]
                                ?.deleteIconState === 1 ? (
                              <img
                                src={Icons.icon.deleteSGr}
                                alt={'delete_disabled'}
                              />
                            ) : (
                              apiData?.item?.filter((d, i) => i === index)[0]
                                ?.deleteIconState === 2 && (
                                <img
                                  src={Icons.icon.searchNv}
                                  alt={'search_icon'}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    navigate(`/${registerType}/edit`, {
                                      state: {
                                        id: obj.primaryKey ?? obj[primaryKey],
                                        deletedFlag:
                                          apiData.item[index].deletedFlag,
                                      },
                                    });
                                  }}
                                />
                              )
                            )}
                          </td>
                        );
                      } else if (staticObj.label === '見積詳細') {
                        return (
                          <td
                            style={{ textAlign: 'center' }}
                            key={staticObj.label}
                          >
                            <img
                              style={{ cursor: 'pointer' }}
                              src={staticObj.icon}
                              onClick={() =>
                                navigate('/quote/register/quotation', {
                                  state: { id: obj.quoteNumber },
                                })
                              }
                            />
                          </td>
                        );
                      } else if (staticObj.label === '案件作成') {
                        return (
                          <td
                            style={{ textAlign: 'center' }}
                            key={staticObj.label}
                          >
                            <img
                              style={{ cursor: 'pointer' }}
                              src={staticObj.icon}
                              onClick={() =>
                                navigate('/matter/register', {
                                  state: { quoteNumber: obj.quoteNumber },
                                })
                              }
                            />
                          </td>
                        );
                      } else if (staffAssignPage) {
                        return (
                          <td
                            style={{ textAlign: 'center' }}
                            key={staticObj.label}
                          >
                            <img
                              style={{ cursor: 'pointer' }}
                              src={obj?.assignId?.props?.position === '協力会社' ? Icons.icon.detailSGr : Icons.icon.editSGr}
                              onClick={() => staticObj.onClick?.(obj, index)}
                            />
                          </td>
                        )
                      } else {
                        return (
                          <td
                            style={{ textAlign: 'center' }}
                            key={staticObj.label}
                          >
                            <img
                              style={{ cursor: 'pointer' }}
                              src={staticObj.icon}
                              onClick={() => staticObj.onClick?.(obj, index)}
                            />
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {buttonSettings && buttonSettings.viewPager && apiData.count !== 0 && (
        <div className={'list_view--bottom_pager_wrapper'}>
          <Pager
            searchCount={searchCount}
            listPerPage={listPerPage}
            currentPage={currentPage}
            setPage={changeCurrentPage}
          />
        </div>
      )}

      <DeleteModal
        deleteKey={deleteKey}
        deleteName={deleteName}
        deleteFunction={key => {
          onDelete?.(key, deleteIndex);
          setDeleteModalVisible(false);
        }}
        deleteModalVisible={deleteModalVisible}
        toggleVisible={toggleDeleteModal}
        displayNotice={displayNotice}
      />
    </>
  );
};

export default ListView;
