import { memo } from 'react';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import '../edit/style.scss';
import BirthdaySelector from "../../../components/birthdaySelector";
import StaffRegisterInfos from "../inputInfos";
import prefectures from "../../../constants/prefectures";
import {handleRadio} from "./functions";
import React, { useEffect } from 'react';
import {fetchRequest} from "../../../api/fetch";
import {apiUrl} from "../../../api/apiUrl";
import {handlerFormikFieldChange} from "../../../utils/fnUtil";
import {useSelector} from "react-redux";
import CustomInput from "../../../components/customInput";


const style = {
  areaStyle: { width: '65%' },
  labelStyle: { width: '110px' },
  inputStyle: {},
};

const BackGroundInfos = memo(({ formik }) => {
    const {profession} = formik.values;

    const { certificationsList } = useSelector((state) => state.staff);

  return(
      <div
          className={'staff_register--background_infos_area'}
      >
          <CustomFormikInput
              formik={formik}
              {...StaffRegisterInfos.backgroundInfos.inputs[0]}
              initialValue={certificationsList?.map(h=>h?.value)}
              selectBoxOptions={certificationsList?.map(h=>h?.label)}
          />
          <CustomFormikInput
              formik={formik}
              radioButtonLabelAndChecked={[
                  {label: '学生', checked: profession == 0},
                  {label: "その他(社会人、主婦など)", checked: profession == 1},
              ]}
              {...StaffRegisterInfos.backgroundInfos.inputs[1]}
          />
          {StaffRegisterInfos.backgroundInfos.inputs.map((obj, idx) => {
              return idx > 1 && (
                  <CustomFormikInput
                      formik={formik}
                      {...StaffRegisterInfos.backgroundInfos.inputs[idx]}
                      disabled={profession != 0}
                  />
              )
          })}
      </div>
  )
});
export default BackGroundInfos;
