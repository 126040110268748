import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRequest } from '../../api/fetch';
import { apiUrl } from '../../api/apiUrl';
import prefectures from '../../constants/prefectures';
import options from '../../constants/options';
import moment from 'moment';

const initialDownloadParams = {
  screenType: null,
  startDate: null,
  endDate: null,
  columns: [],
};

const initialState = {
  downloadParams: initialDownloadParams,
};

export const csvDownloadFetch = createAsyncThunk(
  'other/csv/download',
  async info => {
    const csvFile = await fetchRequest(info.url, info.params, 'GET', 'csv');
    return csvFile;
  }
);

export const favoriteItemFetch = createAsyncThunk(
  'other/csv/favorite_item',
  async info => {
    const favoriteItems = await fetchRequest(
      apiUrl.other.favorite,
      info,
      'GET'
    );
    return favoriteItems;
  }
);

export const updateFavoriteItemFetch = createAsyncThunk(
  'other/csv/favorite_item/update',
  async info => {
    const favoriteUpdateItems = await fetchRequest(
      apiUrl.other.favoriteUpdate,
      info,
      'POST'
    );
    return favoriteUpdateItems;
  }
);

export const csvZenginDownloadFetch = createAsyncThunk(
  'other/csv/zenginDownload',
  async params => {
    const csvFile = await fetchRequest(
      apiUrl.staffPay.zenginDownloadCsv,
      params,
      'GET',
      'csv'
    );
    return csvFile;
  }
);
