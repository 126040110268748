import { Calendar, Col, Row, Tooltip } from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import 'moment/locale/zh-cn';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../../api/apiUrl';
import { fetchRequest } from '../../../api/fetch';
import Copyright from '../../../components/copyright';
import CustomInput from '../../../components/customInput';
import ListView from '../../../components/listView';
import ScheduleModal from '../../../components/modal/scheduleModal/index';
import Icons from '../../../constants/icons';
import { globalActions } from '../../../redux/slice/global';
import { store } from '../../../redux/store';
import Yup from '../../../utils/yupUtil';
import './style.scss';
import { checkIsSp } from '../../../utils/fnUtil';

let childClick = false;

let requestValues = {
  scheduleDate: null,
  batchFlag: 0,
  scheduleFlag: null,
  scheduleFrom: null,
  scheduleTo: null,
};

let status = {
  shift: false,
  matter: false,
  week: false,
  before: false,
};

const MatterSchedule = () => {
  const columnSettings = {
    statics: [],
  };

  const JpH = require('japanese-holidays');

  moment.updateLocale('ja', {
    week: {
      dow: 1
    }
  });

  const [dateVal, setDateVal] = useState(moment());
  const [selYear, setSelYear] = useState(moment().year());
  const [selMonth, setSelMonth] = useState(moment().month());
  // console.log('選択された年' + selYear, '選択された月' + (selMonth + 1));
  //selYearとselMonthはセレクトボックスの値です、moment.jsの月は'0'から始まるなので、値渡しの時注意してください

  const [apiData, setApiData] = useState([]);
  const navigate = useNavigate();
  const account = useSelector(state => state.account);
  const loginType = useSelector(state => state.loginType.type);
  const { isOpenSideMenu } = useSelector(state => state.global);

  //モダルの開閉をコントロール
  // const [childClick, setChildClick] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editStatus, setEditStatus] = useState(status);
  const changeModalVisible = () => {
    setModalVisible(!modalVisible);
  };
  //選択された日付を取得
  const onSelect = selectDate => {
    const formatDate = moment(selectDate).format('YYYY/M/D');

    const selectedDateData = apiData.find(
      obj => obj.scheduleDate === formatDate
    );

    const selectedDateDataBatch = apiData.find(
      obj => obj.scheduleDate === formatDate && obj.batchFlag == 1
    );

    const selectedDateDataPage = apiData.find(
      obj => obj.scheduleDate === formatDate && obj.batchFlag == 0
    );

    if (childClick) {
      childClick = false;
      return;
    } else {
      if (
        (!selectedDateDataPage && selectedDateDataBatch) ||
        !selectedDateData
      ) {
        status.shift = false;
        status.matter = false;
        status.week = false;
        status.before = false;
        setEditStatus(status);
        const firstDayNextMonth = new Date(selYear, selMonth + 1, 1);
        const lastDayNextMonth = new Date(selYear, selMonth + 2, 0);
        formik.setValues({
          ...formik.initialValues,
          scheduleDate: formatDate,
          'submitShiftPlanFrom': moment(firstDayNextMonth)?.format('YYYY/M/D'),
          'submitShiftPlanTo': moment(lastDayNextMonth)?.format('YYYY/M/D'),
        });
      }
      if (selectedDateDataPage) {
        status.shift = selectedDateDataPage.submitShiftPlan == 1 ? true : false;
        status.matter = selectedDateDataPage.openMatter == 1 ? true : false;
        status.week = selectedDateDataPage.weekConfirm == 1 ? true : false;
        status.before =
          selectedDateDataPage.beforeDayConfirm == 1 ? true : false;
        setEditStatus(status);
        formik.setValues({
          ...selectedDateDataPage,
          scheduleFlag: status.shift
            ? 1
            : status.matter
            ? 2
            : status.week
            ? 3
            : 4,
          isEdit: true,
        });
      }
    }

    // if (selectedDateData) {
    //   // formik.setValues({
    //   //   ...selectedDateData,
    //   //   isEdit: true,
    //   // });
    //   return;
    // } else {
    //   status.shift = false;
    //   status.matter = false;
    //   status.week = false;
    //   status.before = false;
    //   setEditStatus(status);
    //   formik.setValues({ ...formik.initialValues, scheduleDate: formatDate });
    // }
    changeModalVisible();
    childClick = false;
  };

  //特定期間選択
  const itemSelect = bubble => {
    childClick = true;
    const formatDate = moment(bubble.scheduleDate).format('YYYY/M/D');

    if (bubble.batch == 0) {
      status.shift = apiData.find(
        obj =>
          obj.scheduleDate === formatDate &&
          obj.batchFlag == bubble.batch &&
          obj.submitShiftPlan == 1
      )
        ? true
        : false;
      status.matter = apiData.find(
        obj =>
          obj.scheduleDate === formatDate &&
          obj.batchFlag == bubble.batch &&
          obj.openMatter == 1
      )
        ? true
        : false;
      status.week = apiData.find(
        obj =>
          obj.scheduleDate === formatDate &&
          obj.batchFlag == bubble.batch &&
          obj.weekConfirm == 1
      )
        ? true
        : false;
      status.before = apiData.find(
        obj =>
          obj.scheduleDate === formatDate &&
          obj.batchFlag == bubble.batch &&
          obj.beforeDayConfirm == 1
      )
        ? true
        : false;
    }
    setEditStatus(status);

    const selectedDateData = apiData.find(
      obj => obj.scheduleDate === formatDate && obj.batchFlag == bubble.batch
    );
    formik.setValues({
      ...selectedDateData,
      scheduleFlag: bubble.scheduleFlag,
      isEdit: true,
    });
    changeModalVisible();
  };

  const handleDelete = async (date, name) => {
    const formatDate = moment(date).format('YYYY/MM/DD');
    let newData = cloneDeep(apiData);

    let dataIndex;

    newData.forEach((obj, index) => {
      if (moment(obj.scheduleDate).format('YYYY/MM/DD') === formatDate) {
        newData[index][name] = 0;
        dataIndex = index;
      }
    });

    requestValues.scheduleDate = formatDate;
    requestValues.scheduleFlag =
      name === 'submitShiftPlan'
        ? 1
        : name === 'openMatter'
        ? 2
        : name === 'weekConfirm'
        ? 3
        : 4;
    await fetchRequest(apiUrl.setting.delete, requestValues, 'POST');
    getApiData();
  };

  const dateCellRender = date => {
    const formatDate = moment(date).format('YYYY/M/D');

    const dateObj = apiData.find(
      obj => obj.scheduleDate === formatDate && obj.batchFlag == 0
    );
    const dateObjBatch = apiData.find(
      obj => obj.scheduleDate === formatDate && obj.batchFlag == 1
    );

    let bubbles = dateObj
      ? Object.keys(dateObj)
          .map(key => {
            if (dateObj[key]) {
              if (key === 'submitShiftPlan') {
                return {
                  scheduleDate: dateObj.scheduleDate,
                  scheduleFlag: 1,
                  name: 'submitShiftPlan',
                  label: 'シフト提出',
                  start: dateObj.submitShiftPlanFrom,
                  end: dateObj.submitShiftPlanTo,
                  batch: 0,
                };
              } else if (key === 'openMatter') {
                return {
                  scheduleDate: dateObj.scheduleDate,
                  scheduleFlag: 2,
                  name: 'openMatter',
                  label: '案件公開',
                  start: dateObj.openMatterFrom,
                  end: dateObj.openMatterTo,
                  batch: 0,
                };
              } else if (key === 'weekConfirm') {
                return {
                  scheduleDate: dateObj.scheduleDate,
                  scheduleFlag: 3,
                  name: 'weekConfirm',
                  label: '週確認',
                  start: dateObj.weekConfirmFrom,
                  end: dateObj.weekConfirmTo,
                  batch: 0,
                };
              } else if (key === 'beforeDayConfirm') {
                return {
                  scheduleDate: dateObj.scheduleDate,
                  scheduleFlag: 4,
                  name: 'beforeDayConfirm',
                  label: '前日報告',
                  start: dateObj.beforeDayConfirmFrom,
                  end: dateObj.beforeDayConfirmTo,
                  batch: 0,
                };
              }
            } else {
              return '';
            }
          })
          .filter(v => v)
      : '';

    const bubblesBatch = dateObjBatch
      ? Object.keys(dateObjBatch)
          .map(key => {
            if (dateObjBatch[key]) {
              if (key === 'submitShiftPlan') {
                return {
                  scheduleDate: dateObjBatch.scheduleDate,
                  scheduleFlag: 1,
                  name: 'submitShiftPlanBatch',
                  label: 'シフト提出',
                  start: dateObjBatch.submitShiftPlanFrom,
                  end: dateObjBatch.submitShiftPlanTo,
                  batch: 1,
                };
              } else if (key === 'openMatter') {
                return {
                  scheduleDate: dateObjBatch.scheduleDate,
                  scheduleFlag: 2,
                  name: 'openMatterBatch',
                  label: '案件公開',
                  start: dateObjBatch.openMatterFrom,
                  end: dateObjBatch.openMatterTo,
                  batch: 1,
                };
              } else if (key === 'weekConfirm') {
                return {
                  scheduleDate: dateObjBatch.scheduleDate,
                  scheduleFlag: 3,
                  name: 'weekConfirmBatch',
                  label: '週確認',
                  start: dateObjBatch.weekConfirmFrom,
                  end: dateObjBatch.weekConfirmTo,
                  batch: 1,
                };
              } else if (key === 'beforeDayConfirm') {
                return {
                  scheduleDate: dateObjBatch.scheduleDate,
                  scheduleFlag: 4,
                  name: 'beforeDayConfirmBatch',
                  label: '前日報告',
                  start: dateObjBatch.beforeDayConfirmFrom,
                  end: dateObjBatch.beforeDayConfirmTo,
                  batch: 1,
                };
              }
            } else {
              return '';
            }
          })
          .filter(v => v)
      : '';

    if (bubbles && bubblesBatch) {
      bubbles = bubbles.concat(bubblesBatch);
    } else if (!bubbles && bubblesBatch) {
      bubbles = bubblesBatch;
    }

    if (bubbles) {
      return bubbles.map(bubble => {
        if (bubble.batch == 0) {
          return (
            <div
              onClick={() => itemSelect(bubble)}
              key={`${date}_${bubble.name}`}
              className={
                bubble.name === 'openMatter'
                  ? 'matter_schedule--matter--open'
                  : bubble.name === 'weekConfirm'
                  ? 'matter_schedule--week--check'
                  : bubble.name === 'beforeDayConfirm'
                  ? 'matter_schedule--matter--report'
                  : bubble.name === 'submitShiftPlan'
                  ? 'matter_schedule--attendance--submit'
                  : bubble.name === 'openMatterBatch'
                  ? 'matter_schedule--matter--open'
                  : bubble.name === 'weekConfirmBatch'
                  ? 'matter_schedule--week--check'
                  : bubble.name === 'beforeDayConfirmBatch'
                  ? 'matter_schedule--matter--report'
                  : 'matter_schedule--attendance--submit'
              }
            >
              <div style={{ padding: '8px 4px 4px 4px', marginBottom: '4px' }}>
                <p style={{ position: "relative" }}>
                  {`${bubble.start}〜${bubble.end}`}
                  <Tooltip placement="top" title="削除" >
                    <img
                      src={Icons.icon.xCircleWh}
                      alt="del"
                      style={{ width: '26px', position: 'absolute', right: '0', top: '0' }}
                      onClick={e => {
                        e.stopPropagation();
                        handleDelete(date, bubble.name);
                      }}
                    />
                  </Tooltip>
                </p>
                <p>{bubble.label}</p>
              </div>
            </div>
          );
        }
        if (bubble.batch == 1) {
          return (
            <div
              onClick={() => itemSelect(bubble)}
              key={`${date}_${bubble.name}`}
              className={
                bubble.name === 'openMatter'
                  ? 'matter_schedule--matter--open'
                  : bubble.name === 'weekConfirm'
                  ? 'matter_schedule--week--check'
                  : bubble.name === 'beforeDayConfirm'
                  ? 'matter_schedule--matter--report'
                  : bubble.name === 'submitShiftPlan'
                  ? 'matter_schedule--attendance--submit'
                  : bubble.name === 'openMatterBatch'
                  ? 'matter_schedule--matter--open'
                  : bubble.name === 'weekConfirmBatch'
                  ? 'matter_schedule--week--check'
                  : bubble.name === 'beforeDayConfirmBatch'
                  ? 'matter_schedule--matter--report'
                  : 'matter_schedule--attendance--submit'
              }
            >
              <div style={{ padding: '8px 4px 4px 4px', marginBottom: '4px' }}>
                <p>{`${bubble.start}〜${bubble.end}`}</p>
                <p>{bubble.label}</p>
              </div>
            </div>
          );
        }
      });
    }
  };

  const getApiData = async () => {
    await fetchRequest(
      apiUrl.setting.list,
      {
        yearMonth: `${selYear}/${
          selMonth + 1 < 10 ? '0' + (selMonth + 1) : selMonth + 1
        }`,
      },
      'GET'
    ).then(data => {
      setApiData(data.item);
    });
  };

  const validChoiceAnyone = function (arg) {
    const { submitShiftPlan, openMatter, weekConfirm, beforeDayConfirm } =
      arg.parent;

    return !(
      !submitShiftPlan &&
      !openMatter &&
      !weekConfirm &&
      !beforeDayConfirm
    );
  };

  const formik = useFormik({
    initialValues: {
      scheduleFlag: 1,
      batchFlag: 0,
      submitShiftPlanFrom: '',
      submitShiftPlanTo: '',
      openMatterFrom: '',
      openMatterTo: '',
      weekConfirmFrom: '',
      weekConfirmTo: '',
      beforeDayConfirmFrom: '',
      beforeDayConfirmTo: '',
      isEdit: false,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      submitShiftPlanFrom: Yup.date()
        .nullable()
        .test(
          'submitShiftPlanFrom',
          'シフト提出(開始)：必須項目です。',
          (value, context) => {
            if (
              (!document.getElementById('selectType').value ||
                document.getElementById('selectType').value == 'シフト提出') &&
              (value == '' || value == null)
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      submitShiftPlanTo: Yup.date()
        .nullable()
        .test(
          'submitShiftPlanTo',
          'シフト提出(終了)：必須項目です。',
          (value, context) => {
            if (
              (!document.getElementById('selectType').value ||
                document.getElementById('selectType').value == 'シフト提出') &&
              (value == '' || value == null)
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      openMatterFrom: Yup.date()
        .nullable()
        .test(
          'openMatterFrom',
          '案件公開(開始)：必須項目です。',
          (value, context) => {
            if (
              document.getElementById('selectType').value == '案件公開' &&
              (value == '' || value == null)
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      openMatterTo: Yup.date()
        .nullable()
        .test(
          'openMatterTo',
          '案件公開(終了)：必須項目です。',
          (value, context) => {
            if (
              document.getElementById('selectType').value == '案件公開' &&
              (value == '' || value == null)
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      weekConfirmFrom: Yup.date()
        .nullable()
        .test(
          'weekConfirmFrom',
          '週確認(開始)：必須項目です。',
          (value, context) => {
            if (
              document.getElementById('selectType').value == '週確認' &&
              (value == '' || value == null)
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      weekConfirmTo: Yup.date()
        .nullable()
        .test(
          'weekConfirmTo',
          '週確認(終了)：必須項目です。',
          (value, context) => {
            if (
              document.getElementById('selectType').value == '週確認' &&
              (value == '' || value == null)
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      beforeDayConfirmFrom: Yup.date()
        .nullable()
        .test(
          'beforeDayConfirmFrom',
          '前日報告(開始)：必須項目です。',
          (value, context) => {
            if (
              document.getElementById('selectType').value == '前日報告' &&
              (value == '' || value == null)
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      beforeDayConfirmTo: Yup.date()
        .nullable()
        .test(
          'beforeDayConfirmTo',
          '前日報告(終了)：必須項目です。',
          (value, context) => {
            if (
              document.getElementById('selectType').value == '前日報告' &&
              (value == '' || value == null)
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
    }),
    onSubmit: values => {
      requestValues.scheduleDate = values.scheduleDate;
      if (
        !document.getElementById('selectType').value ||
        document.getElementById('selectType').value == 'シフト提出'
      ) {
        requestValues.scheduleFlag = 1;
        requestValues.scheduleFrom = values.submitShiftPlanFrom;
        requestValues.scheduleTo = values.submitShiftPlanTo;
      } else if (document.getElementById('selectType').value == '案件公開') {
        requestValues.scheduleFlag = 2;
        requestValues.scheduleFrom = values.openMatterFrom;
        requestValues.scheduleTo = values.openMatterTo;
      } else if (document.getElementById('selectType').value == '週確認') {
        requestValues.scheduleFlag = 3;
        requestValues.scheduleFrom = values.weekConfirmFrom;
        requestValues.scheduleTo = values.weekConfirmTo;
      } else if (document.getElementById('selectType').value == '前日報告') {
        requestValues.scheduleFlag = 4;
        requestValues.scheduleFrom = values.beforeDayConfirmFrom;
        requestValues.scheduleTo = values.beforeDayConfirmTo;
      }

      fetchRequest(apiUrl.setting.update, requestValues, 'POST').then(() => {
        store.dispatch(
          globalActions.showSingleModal(
            formik.values.isEdit ? '更新しました' : '登録しました'
          )
        );
        setModalVisible(false);
        getApiData();
      });
    },
  });

  useEffect(() => {
    getApiData();
  }, [selYear, selMonth]);

  useEffect(() => {
    //土曜日と日曜日タグの色を変更
    const weeks = document.querySelectorAll('th');
    Array.prototype.forEach.call(weeks, e => {
      if (e.innerHTML === '土') {
        e.style.backgroundColor = '#3662A5';
      } else if (e.innerHTML === '日') {
        e.style.backgroundColor = '#D80245';
      }
    });
    //祝日の色を変更
    const festivals = document.querySelectorAll('td');
    // festivals.forEach((elm, idx) => idx >= 35 ? elm.remove() : elm); これは不必要な翌月の日付を削除する処理
    Array.prototype.forEach.call(festivals, e => {
      if (JpH.isHoliday(new Date(e.title))) {
        e.style.backgroundColor = '#fdf2f5';
        e.classList.add('holiday');
      } else {
        e.style.backgroundColor = '';
        e.classList.remove('holiday');
      }
    });
  });

  useEffect(() => {
    const calenderArea = document.querySelector('.ant-picker-panel');
    const calender = document.querySelector('.ant-picker-date-panel');
    calenderArea.style.width = '100%';
    calenderArea.style.overflow = 'auto';
    calender.style.minWidth = '900px';
  }, []);

  return (
    <>
      <div className="matter_schedule--search">
        <div className={'commonPage--base'}>
          <ListView columnSettings={columnSettings} apiData={apiData} />
        </div>
      </div>
      <div className="matter_schedule--calendar">
        <Calendar
          locale={locale}
          value={dateVal}
          dateCellRender={dateCellRender}
          onSelect={selectDate => {
            if (selectDate.month() == dateVal.month()) {
              onSelect(selectDate)
            }
          }}
          headerRender={({ value }) => {
            const start = 0;
            const end = 12;
            const months = [];
            const monthOptions = [];
            const options = [];
            const yearOptions = [];

            //今年
            const year = value.year();

            //月の値とオプション
            for (let i = start; i < end; i++) {
              months.push(i);
            }
            for (let i = 1; i < 13; i++) {
              monthOptions.push(i + '月');
            }
            //年の値とオプション
            for (let i = year - 10; i < year + 10; i += 1) {
              options.push(i);
            }
            for (let i = year - 10; i < year + 10; i += 1) {
              yearOptions.push(i + '年');
            }

            return (
              <div
                style={{
                  padding: '0 0 16px 0',
                  backgroundColor: '#e9f2fc',
                }}
              >
                <Row gutter={8}>
                  <Col>
                    <CustomInput
                      inputType="select"
                      inputName="testYear"
                      initialValue={options}
                      selectBoxOptions={yearOptions}
                      selectedSelectBox={selYear}
                      onChange={e => {
                        setSelYear(e);
                        setDateVal(moment([e, selMonth]));
                      }}
                      style={{ areaStyle: { width: '120px' } }}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      inputType="select"
                      inputName="testMonth"
                      initialValue={months}
                      selectBoxOptions={monthOptions}
                      selectedSelectBox={selMonth}
                      onChange={e => {
                        setSelMonth(e);
                        setDateVal(moment([selYear, e]));
                      }}
                      style={{ areaStyle: { width: '120px' } }}
                    />
                  </Col>
                </Row>
              </div>
            );
          }}
        />
      </div>

      <Copyright style={{width: checkIsSp() ? '100%' : isOpenSideMenu ? 'calc(100% - 200px)' : 'calc(100% - 56px)'}} />

      <ScheduleModal
        modalVisible={modalVisible}
        changeModalVisible={c => changeModalVisible(c)}
        formik={formik}
        editStatus={editStatus}
        selYear={selYear}
        selMonth={selMonth}
      />
    </>
  );
};

export default MatterSchedule;
