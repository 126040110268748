import './style.scss';
import Icons from '../../../../constants/icons';
import { checkIsSp, deleteArrayItem } from '../../../../utils/fnUtil';
import React, { useEffect } from 'react';
import CustomFormikInput from '../../../../components/customInput/customFormikInput';
import AddButton from '../../../../components/addButton';
import { zipApiList } from '../../../../api/fetch';

const AddressList = ({formik, prefectures}) => {

  const {
    values: { officeMasters },
    setFieldValue,
  } = formik;

  const addSty = {
    width: '36px',
    height: '36px',
    cursor: 'pointer',
  };

  const delSty = {
    width: '26px',
    height: '26px',
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
    top: '6px',
  };

  useEffect(() => {
    zipApiList(formik, 0);
  }, [officeMasters?.[0]?.zip1, officeMasters?.[0]?.zip2]);

  useEffect(() => {
    zipApiList(formik, 1);
  }, [officeMasters?.[1]?.zip1, officeMasters?.[1]?.zip2]);

  useEffect(() => {
    zipApiList(formik, 2);
  }, [officeMasters?.[2]?.zip1, officeMasters?.[2]?.zip2]);

  useEffect(() => {
    zipApiList(formik, 3);
  }, [officeMasters?.[3]?.zip1, officeMasters?.[3]?.zip2]);

  useEffect(() => {
    zipApiList(formik, 4);
  }, [officeMasters?.[4]?.zip1, officeMasters?.[4]?.zip2]);

  return (
    <>
      <div className={'corporate_register--address_list_area'}>
        {formik?.values?.officeMasters?.map((obj, i) => {
          return (
            <div
              className={'corporate_register--address_list_contents'}
              key={`corporate_register--address_list_contents_${i}`}
            >
              <img
                style={delSty}
                src={Icons.icon.xCircleWh}
                alt="del"
                onClick={() => {
                  setFieldValue('officeMasters', deleteArrayItem(officeMasters, i));
                }}
              />

              <div className={'corporate_register--address_list_info'}>
                <CustomFormikInput
                  inputType="input"
                  label="事業所名"
                  inputName={`officeMasters.[${i}].officeName`}
                  key={`officeMasters.[${i}].officeName`}
                  formik={formik}
                  placeholder="事業所名を入力してください"
                  style={{
                    areaStyle: { width: '100%' },
                    labelStyle: { width: '175px' },
                    inputStyle: { width: checkIsSp() ? '100%' : '55%' },
                  }}
                />

                <div className={'corporate_register--address_list_number'}>
                  <CustomFormikInput
                    inputType="input"
                    label="郵便番号"
                    inputName={`officeMasters.[${i}].zip1`}
                    key={`officeMasters.[${i}].zip1`}
                    formik={formik}
                    placeholder={'123'}
                    style={{
                      labelStyle: { width: checkIsSp() ? '100%' : '175px' },
                      inputStyle: {
                        width: checkIsSp()
                          ? '100%'
                          : '110px',
                      },
                      areaStyle: checkIsSp()
                        ? { width: '100%' }
                        : {width: 'fit-content'},
                    }}
                  />
                  <span>-</span>
                  <CustomFormikInput
                    inputType="input"
                    inputName={`officeMasters.[${i}].zip2`}
                    key={`officeMasters.[${i}].zip2`}
                    formik={formik}
                    placeholder={'4567'}
                    style={{
                      labelStyle: { width: checkIsSp() ? '100%' : '175px' },
                      inputStyle: {
                        width: checkIsSp()
                          ? '100%'
                          : '110px',
                      },
                      areaStyle: checkIsSp()
                        ? { width: '100%' }
                        : {width: '110px'},
                    }}
                  />
                </div>

                <CustomFormikInput
                  inputType="select"
                  label="都道府県"
                  inputName={`officeMasters.[${i}].prefCode`}
                  key={`officeMasters.[${i}].prefCode`}
                  formik={formik}
                  placeholder="都道府県"
                  style={{
                    areaStyle: { width: '100%' },
                    labelStyle: { width: '175px' },
                    selectStyle: { width: checkIsSp() ? '100%' : '136px' },
                  }}
                  initialValue={prefectures
                    .filter(p => p.prefecturesCode !== '')
                    .map(p => p.prefecturesCode)}
                  selectBoxOptions={prefectures
                    .filter(p => p.prefecturesCode !== '')
                    .map(p => p.label)}
                />

                <CustomFormikInput
                  inputType="input"
                  label="市区町村"
                  inputName={`officeMasters.[${i}].cityName`}
                  key={`officeMasters.[${i}].cityName`}
                  formik={formik}
                  placeholder="事業所名を入力してください"
                  style={{
                    areaStyle: { width: '100%' },
                    labelStyle: { width: '175px' },
                    inputStyle: { width: checkIsSp() ? '100%' : '55%' },
                  }}
                />

                <CustomFormikInput
                  inputType="input"
                  label="丁目番地"
                  inputName={`officeMasters.[${i}].streetName`}
                  key={`officeMasters.[${i}].streetName`}
                  formik={formik}
                  placeholder="事業所名を入力してください"
                  style={{
                    areaStyle: { width: '100%' },
                    labelStyle: { width: '175px' },
                    inputStyle: { width: checkIsSp() ? '100%' : '55%' },
                  }}
                />

                <CustomFormikInput
                  inputType="input"
                  label="建物名"
                  inputName={`officeMasters.[${i}].buildingName`}
                  key={`officeMasters.[${i}].buildingName`}
                  formik={formik}
                  placeholder="事業所名を入力してください"
                  style={{
                    areaStyle: { width: '100%' },
                    labelStyle: { width: '175px' },
                    inputStyle: { width: checkIsSp() ? '100%' : '55%' },
                  }}
                />

                <div className={'corporate_register--address_list_number'}>
                  <CustomFormikInput
                    inputType="input"
                    label="TEL"
                    inputName={`officeMasters.[${i}].tel1`}
                    key={`officeMasters.[${i}].tel1`}
                    formik={formik}
                    placeholder={'03'}
                    style={{
                      labelStyle: { width: checkIsSp() ? '100%' : '175px' },
                      inputStyle: {
                        width: checkIsSp()
                          ? '100%'
                          : '110px',
                      },
                      areaStyle: checkIsSp()
                        ? { width: '100%' }
                        : {width: 'fit-content'},
                    }}
                  />
                  <span>-</span>
                  <CustomFormikInput
                    inputType="input"
                    inputName={`officeMasters.[${i}].tel2`}
                    key={`officeMasters.[${i}].tel2`}
                    formik={formik}
                    placeholder={'0000'}
                    style={{
                      labelStyle: { width: checkIsSp() ? '100%' : '175px' },
                      inputStyle: {
                        width: checkIsSp()
                          ? '100%'
                          : '110px',
                      },
                      areaStyle: checkIsSp()
                        ? { width: '100%' }
                        : {width: '110px'},
                    }}
                  />
                  <span>-</span>
                  <CustomFormikInput
                    inputType="input"
                    inputName={`officeMasters.[${i}].tel3`}
                    key={`officeMasters.[${i}].tel3`}
                    formik={formik}
                    placeholder={'0000'}
                    style={{
                      labelStyle: { width: checkIsSp() ? '100%' : '175px' },
                      inputStyle: {
                        width: checkIsSp()
                          ? '100%'
                          : '110px',
                      },
                      areaStyle: checkIsSp()
                        ? { width: '100%' }
                        : {width: '110px'},
                    }}
                  />
                </div>

                <div className={'corporate_register--address_list_number'}>
                  <CustomFormikInput
                    inputType="input"
                    label="FAX"
                    inputName={`officeMasters.[${i}].fax1`}
                    key={`officeMasters.[${i}].fax1`}
                    formik={formik}
                    placeholder={'03'}
                    style={{
                      labelStyle: { width: checkIsSp() ? '100%' : '175px' },
                      inputStyle: {
                        width: checkIsSp()
                          ? '100%'
                          : '110px',
                      },
                      areaStyle: checkIsSp()
                        ? { width: '100%' }
                        : {width: 'fit-content'},
                    }}
                  />
                  <span>-</span>
                  <CustomFormikInput
                    inputType="input"
                    inputName={`officeMasters.[${i}].fax2`}
                    key={`officeMasters.[${i}].fax2`}
                    formik={formik}
                    placeholder={'0000'}
                    style={{
                      labelStyle: { width: checkIsSp() ? '100%' : '175px' },
                      inputStyle: {
                        width: checkIsSp()
                          ? '100%'
                          : '110px',
                      },
                      areaStyle: checkIsSp()
                        ? { width: '100%' }
                        : {width: '110px'},
                    }}
                  />
                  <span>-</span>
                  <CustomFormikInput
                    inputType="input"
                    inputName={`officeMasters.[${i}].fax3`}
                    key={`officeMasters.[${i}].fax3`}
                    formik={formik}
                    placeholder={'0000'}
                    style={{
                      labelStyle: { width: checkIsSp() ? '100%' : '175px' },
                      inputStyle: {
                        width: checkIsSp()
                          ? '100%'
                          : '110px',
                      },
                      areaStyle: checkIsSp()
                        ? { width: '100%' }
                        : {width: '110px'},
                    }}
                  />
                </div>
              </div>
            </div>
          )
        })}

        {/*ボタンエリア*/}
        <div className={'corporate_register--address_list_add_btn_area'}>
          <AddButton
            style={addSty}
            type="round"
            onClick={() => {
              setFieldValue('officeMasters', [
                ...officeMasters,
                {
                  officeName: '',
                  zip: '',
                  zip1: '',
                  zip2: '',
                  prefCode: null,
                  cityName: '',
                  streetName: '',
                  buildingName: '',
                  tel: '',
                  tel1: '',
                  tel2: '',
                  tel3: '',
                  fax: '',
                  fax1: '',
                  fax2: '',
                  fax3: '',
                },
              ]);
            }}
            disabled={formik?.values?.officeMasters?.length > 4}
          />
        </div>
      </div>
    </>
  )
};

export default AddressList;