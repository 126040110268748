import React, { memo, useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RegisterBase from '../../../components/registerBase';
import Icons from '../../../constants/icons';
import Button from '../../../components/button';
import CustomInput from '../../../components/customInput';
import Options from '../../../constants/options';
import DefSet from './defSet';
import BillInfo from './billInfo';
import AbleMenu from './ableMenu';
import PayInfo from './payInfo';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApi, fetchRequest, zipApi } from '../../../api/fetch';
import {
  handlerFormikFieldChange,
  handlerFormikSubmit,
  isAllNullOrAllNotNull,
  openNotification,
} from '../../../utils/fnUtil';
import { originalValidation } from '../../../utils/validation';
import { useFormik } from 'formik';
import Yup from '../../../utils/yupUtil';
import CustomFormikInput from '../../../components/customInput/customFormikInput';
import { apiUrl } from '../../../api/apiUrl';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import prefectures from '../../../constants/prefectures';
import { bankingCodeFetch, staffInfoFetch } from '../../../redux/slice/staff';
import moment from 'moment/moment';
import { checkIsSp } from '../../../utils/fnUtil';
import AddressList from './addressList';

const prefList = Object.values(Options.codes.pref_code);
const preCode = Object.keys(Options.codes.pref_code).sort();

const divSty = checkIsSp()
  ? { flexDirection: 'column', display: 'flex', gap: '16px' }
  : { display: 'flex', gap: '16px', marginTop: '26px' };

const getFiles = () => {
  const fileDom = document.getElementById('file');
  fileDom.click();
};

const CorporateRegister = memo(() => {
  // 法人印url
  const [fileSrc, setFileSrc] = useState('');
  const [shiftStyle, setShiftStyle] = useState('0');
  const [matterOpen, setMatterOpen] = useState('');
  const navigate = useNavigate();
  const isEdit = useLocation().pathname.slice(5).includes('edit');
  const location = useLocation();
  const loginType = useSelector(state => state.loginType.type);

  const account = useSelector(state => state.account);
  const sysAuth = account.auth === 0;
  const [loginUserMailAddress, setLoginUserMailAddress] = useState('');

  if (!account.menuCorporateListFlag) {
    navigate('/');
    store.dispatch(
      globalActions.showSingleModal('ご利用いただけないメニューです')
    );
  }
  const [corporateImageSrc, setCorporateImageSrc] = useState('');

  const fileInputChange = e => {
    let fileData = e.target.files[0];

    if (fileData) {
      if (fileData.size > 1024 * 1024) {
        alert('画像は1MB以下でアップロードしてください。');
      } else {
        const reader = new FileReader();
        reader.onload = async event => {
          const base64Text = event.currentTarget.result;

          const body = {
            userId: account.userId,
            image: base64Text,
            contentFlag: '3',
          };

          let imageUrl = '';

          store.dispatch(globalActions.setLoading(true));

          await fetchApi(
            'other',
            'imageUpload',
            'POST',
            loginType,
            account.corporateId,
            account.accessToken,
            body
          ).then(data => (imageUrl = data.imageUrl));
          formik.setFieldValue('corporateStampImage', imageUrl);

          // stamp ? setFileSrc(imageUrl) : setCorporateImageSrc(imageUrl);
          setFileSrc(imageUrl);
          store.dispatch(globalActions.setLoading(false));
        };
        reader.readAsDataURL(fileData);
      }
    }
  };

  const getLoginUserData = async () => {
    await fetchApi(
      'user',
      'detail',
      'GET',
      loginType,
      account.corporateId,
      account.accessToken,
      { userId: account.userId, deletedFlag: 0 }
    ).then(data => setLoginUserMailAddress(data?.mailAddress));
  };

  const corporateImageInputChange = e => {
    let fileData = e.target.files[0];

    if (fileData) {
      if (fileData.size > 1024 * 1024) {
        alert('画像は1MB以下でアップロードしてください。');
      } else {
        const reader = new FileReader();
        reader.onload = async event => {
          const base64Text = event.currentTarget.result;

          const body = {
            userId: account.userId,
            image: base64Text,
            contentFlag: '3',
          };

          let imageUrl = '';

          store.dispatch(globalActions.setLoading(true));

          await fetchApi(
            'other',
            'imageUpload',
            'POST',
            loginType,
            account.corporateId,
            account.accessToken,
            body
          ).then(data => (imageUrl = data.imageUrl));
          formik.setFieldValue('corporateImage', imageUrl);

          setCorporateImageSrc(imageUrl);
          store.dispatch(globalActions.setLoading(false));
        };
        reader.readAsDataURL(fileData);
      }
    }
  };

  const checkMenu = values => {
    if (
      values.menuQuoteListFlag === 1 ||
      values.menuQuoteApprovalListFlag === 1 ||
      values.menuQuoteDocumentNumberFlag === 1 ||
      values.menuQuoteTemplateListFlag === 1 ||
      values.menuQuoteFlowListFlag === 1 ||
      values.menuMatterListFlag === 1 ||
      values.menuMatterDateListFlag === 1 ||
      values.menuMatterAssignListFlag === 1 ||
      values.menuAssignStaffStatesFlag === 1 ||
      values.menuMatterOpenListFlag === 1 ||
      values.menuMatterAppliedListFlag === 1 ||
      values.menuInvoiceListFlag === 1 ||
      values.menuStaffListFlag === 1 ||
      values.menuStaffPlanListFlag === 1 ||
      values.menuStaffPayListFlag === 1 ||
      values.menuStaffPayYearListFlag === 1 ||
      values.menuAttendClosingFlag === 1 ||
      values.menuAnalyzeTrendFlag === 1 ||
      values.menuAnalyzeProfitFlag === 1 ||
      values.menuClientListFlag === 1 ||
      values.menuVenueListFlag === 1 ||
      values.menuSettingScheduleFlag === 1 ||
      values.menuUserListFlag === 1 ||
      values.menuPartnerListFlag === 1 ||
      values.menuCorporateListFlag === 1 ||
      values.menuPayTermListFlag === 1 ||
      values.menuNamingListFlag === 1 ||
      values.menuIncomeTaxListFlag === 1 ||
      values.authStaffSystemUse === 1 ||
      values.surveyFlag === 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const nowDate = new Date();
  const emailRules = /^[a-z0-9\-._+]{1,64}@+[a-z0-9@._-]*$/;

  const formik = useFormik({
    initialValues: {
      createdSystemUser: {
        id: '',
        name: '',
        datetime: '',
      },
      updatedSystemUser: {
        id: '',
        name: '',
        datetime: '',
      },
      updatedUser: {
        id: '',
        name: '',
        datetime: '',
      },
      corporateId: '',
      corporateImage: '',
      corporateName: '',
      corporateNameKana: '',
      startDate: !isEdit ? moment(nowDate).format('YYYY/M/D') : '',
      endDate: !isEdit ? '2999/12/31' : '',
      corporateStampImage: '',
      ntaNumber: '',
      zip: '',
      zip1: '',
      zip2: '',
      prefCode: null,
      cityName: '',
      streetName: '',
      buildingName: '',
      tel: '',
      tel1: '',
      tel2: '',
      tel3: '',
      fax: '',
      fax1: '',
      fax2: '',
      fax3: '',
      managerNameLastName: '',
      managerNameFirstName: '',
      managerNameKanaLastName: '',
      managerNameKanaFirstName: '',
      managerMailAddress: '',
      note: '',
      calculationTaxFlag: 1,
      calculationPayFlag: 2,
      staffPayConfirmClosingDate: 20,
      payClosingDate: 99,
      payday: 99,
      quoteAmountViewFlag: 1,
      invoiceAmountViewFlag: 0,
      shiftStyleFlag: 0,
      shiftSubmitDay: 5,
      matterOpenLimitDay: 99,
      matterOpenDay: 5,
      weekConfirmDay: 7,
      beforeDayConfirmDay: 1,
      matterOpenFlag: 0,
      matterOpenFrequency: 1,
      matterOpenSpan: 1,
      bankMasters: [],
      officeMasters: [
        {
          officeName: '',
          zip: '',
          zip1: '',
          zip2: '',
          prefCode: null,
          cityName: '',
          streetName: '',
          buildingName: '',
          tel: '',
          tel1: '',
          tel2: '',
          tel3: '',
          fax: '',
          fax1: '',
          fax2: '',
          fax3: '',
        },
      ],
      paymentBankName: null,
      paymentBankCode: '',
      paymentBankBranchName: null,
      paymentBankBranchCode: '',
      paymentBankAccountFlag: 1,
      paymentBankAccountNumber: '',
      paymentBankAccountName: '',
      menuQuoteListFlag: 1,
      menuQuoteApprovalListFlag: 1,
      menuQuoteDocumentNumberFlag: 1,
      menuQuoteTemplateListFlag: 1,
      menuQuoteFlowListFlag: 1,
      menuMatterListFlag: 1,
      menuMatterDateListFlag: 1,
      menuMatterAssignListFlag: 1,
      menuAssignStaffStatesFlag: 1,
      menuMatterOpenListFlag: 1,
      menuMatterAppliedListFlag: 1,
      menuInvoiceListFlag: 1,
      menuStaffListFlag: 1,
      menuStaffPlanListFlag: 1,
      menuStaffPayListFlag: 1,
      menuStaffPayYearListFlag: 1,
      menuAttendClosingFlag: 1,
      menuAnalyzeTrendFlag: 1,
      menuAnalyzeProfitFlag: 1,
      menuClientListFlag: 1,
      menuVenueListFlag: 1,
      menuSettingScheduleFlag: 1,
      menuUserListFlag: 1,
      menuPartnerListFlag: 1,
      menuCorporateListFlag: 1,
      menuPayTermListFlag: 1,
      menuNamingListFlag: 1,
      menuIncomeTaxListFlag: 1,
      authStaffSystemUse: 1,
      surveyFlag: 1,
      menuFlag: 1,
    },
    validateOnMount: true,
    validate: () => {
      formik?.errors?.officeMasters?.forEach(obj => {
        delete obj?.zip1
        delete obj?.zip2
        delete obj?.tel1
        delete obj?.tel2
        delete obj?.tel3
        delete obj?.fax1
        delete obj?.fax2
        delete obj?.fax3
      });
    },
    validationSchema: Yup.object({
      corporateName: Yup.string().required().label('法人名'),
      corporateNameKana: Yup.string()
        .required()
        .kana('法人名(カナ)')
        .label('法人名(カナ)'),
      startDate: Yup.string().required().label('利用開始日'),
      endDate: Yup.string().required().label('利用終了日'),
      corporateStampImage: Yup.string().nullable().label('[基本情報]法人印'),
      ntaNumber: Yup.string()
        .required()
        .integer('[基本情報]国税庁法人番号')
        .test(
          'ntaNumber',
          '[基本情報]国税庁法人番号:13桁の数字で入力してください。',
          value => {
            if (Number(value).toString().length === 13) {
              return true;
            } else {
              return false;
            }
          }
        )
        .label('[基本情報]国税庁法人番号'),
      managerNameLastName: Yup.string()
        .required()
        .label('[基本情報]担当者名(姓)'),
      managerNameFirstName: Yup.string()
        .required()
        .label('[基本情報]担当者名(名)'),
      managerNameKanaLastName: Yup.string()
        .nullable()
        .kana('[基本情報]担当者名(セイ)')
        .label('[基本情報]担当者名(セイ)'),
      managerNameKanaFirstName: Yup.string()
        .nullable()
        .kana('[基本情報]担当者名(メイ)')
        .label('[基本情報]担当者名(メイ)'),
      managerMailAddress: Yup.string()
        .nullable()
        .required()
        .matches(emailRules, {
          message: '[基本情報]担当者メールアドレス:形式が異なります。',
        })
        .email('[基本情報]担当者メールアドレス:形式が異なります。')
        .test(
          'managerMailAddress',
          '[基本情報]担当者メールアドレス:利用できないメールアドレスです。',
          value => {
            if (!isEdit && value === loginUserMailAddress) {
              return false;
            } else {
              return true;
            }
          }
        )
        .label('[基本情報]担当者メールアドレス'),
      officeMasters: Yup.array().of(
        Yup.object({
          officeName: Yup.string()
            .nullable()
            .label('[基本情報]事業所名'),
          zip: Yup.string()
            .nullable()
            .test(
              'zip',
              '[基本情報]郵便番号:形式が異なります。',
              (value, context) =>
                isAllNullOrAllNotNull([context.parent.zip1, context.parent.zip2]) &&
                /^(\s*|\d+)$/.test(
                  `${context.parent.zip1 ?? ''}${context.parent.zip2 ?? ''}`
                )
            )
            .label('[基本情報]郵便番号'),
          zip1: Yup.string()
            .nullable()
            .test('zip1', 'error', (value, idx) => {
              const index = idx?.path?.slice(0, -1)?.replace(/[^0-9]/g, ""); //どこの配列か割り出す
              if (!formik?.errors?.officeMasters?.[index]?.zip) {
                return true;
              } else {
                return false;
              }
            }),
          zip2: Yup.string()
            .nullable()
            .test('zip2', 'error', (value, idx) => {
              const index = idx?.path?.slice(0, -1)?.replace(/[^0-9]/g, "");
              if (!formik?.errors?.officeMasters?.[index]?.zip) {
                return true;
              } else {
                return false;
              }
            }),
          tel: Yup.string()
            .nullable()
            .test(
              'tel',
              '[基本情報]TEL:形式が異なります。',
              (value, context) =>
                isAllNullOrAllNotNull([
                  context.parent.tel1,
                  context.parent.tel2,
                  context.parent.tel3,
                ]) &&
                /^(\s*|\d+)$/.test(
                  `${context.parent.tel1 ?? ''}${context.parent.tel2 ?? ''}${
                    context.parent.tel3 ?? ''
                  }`
                )
            )
            .label('[基本情報]TEL'),
          tel1: Yup.string()
            .nullable()
            .test('tel1', 'error', (value, idx) => {
              const index = idx?.path?.slice(0, -1)?.replace(/[^0-9]/g, "");
              if (!formik?.errors?.officeMasters?.[index]?.tel) {
                return true;
              } else {
                return false;
              }
            }),
          tel2: Yup.string()
            .nullable()
            .test('tel2', 'error', (value, idx) => {
              const index = idx?.path?.slice(0, -1)?.replace(/[^0-9]/g, "");
              if (!formik?.errors?.officeMasters?.[index]?.tel) {
                return true;
              } else {
                return false;
              }
            }),
          tel3: Yup.string()
            .nullable()
            .test('tel3', 'error', (value, idx) => {
              const index = idx?.path?.slice(0, -1)?.replace(/[^0-9]/g, "");
              if (!formik?.errors?.officeMasters?.[index]?.tel) {
                return true;
              } else {
                return false;
              }
            }),
          fax: Yup.string()
            .nullable()
            .test(
              'fax',
              '[基本情報]FAX:形式が異なります。',
              (value, context) =>
                isAllNullOrAllNotNull([
                  context.parent.fax1,
                  context.parent.fax2,
                  context.parent.fax3,
                ]) &&
                /^(\s*|\d+)$/.test(
                  `${context.parent.fax1 ?? ''}${context.parent.fax2 ?? ''}${
                    context.parent.fax3 ?? ''
                  }`
                )
            )
            .label('[基本情報]TEL'),
          fax1: Yup.string()
            .nullable()
            .test('fax1', 'error', (value, idx) => {
              const index = idx?.path?.slice(0, -1)?.replace(/[^0-9]/g, "");
              if (!formik?.errors?.officeMasters?.[index]?.fax) {
                return true;
              } else {
                return false;
              }
            }),
          fax2: Yup.string()
            .nullable()
            .test('fax2', 'error', (value, idx) => {
              const index = idx?.path?.slice(0, -1)?.replace(/[^0-9]/g, "");
              if (!formik?.errors?.officeMasters?.[index]?.fax) {
                return true;
              } else {
                return false;
              }
            }),
          fax3: Yup.string()
            .nullable()
            .test('fax3', 'error', (value, idx) => {
              const index = idx?.path?.slice(0, -1)?.replace(/[^0-9]/g, "");
              if (!formik?.errors?.officeMasters?.[index]?.fax) {
                return true;
              } else {
                return false;
              }
            }),
          prefCode: Yup.string()
            .nullable()
            .label('[請求情報]都道府県'),
          cityName: Yup.string()
            .nullable()
            .label('[請求情報]市区町村'),
          streetName: Yup.string()
            .nullable()
            .label('[請求情報]丁目番地'),
          buildingName: Yup.string()
            .nullable()
            .label('[請求情報]建物名'),
        })
      ),
      bankMasters: Yup.array().of(
        Yup.object({
          bankName: Yup.string()
            .nullable()
            .required()
            .label('[請求情報]振込金融機関名'),
          // bankCode: Yup.string()
          //   .nullable()
          //   .when('bankName', (value, schema) =>
          //     value === 'ゆうちょ銀行'
          //       ? schema.max(0, '[請求情報]銀行コード:未記入にしてください。')
          //       : schema.required()
          //   )
          //   .label('[請求情報]銀行コード'),
          bankBranchName: Yup.string()
            .nullable()
            .required()
            .label('[請求情報]支店名'),
          bankBranchCode: Yup.string().required().label('[請求情報]支店コード'),
          bankAccountNumber: Yup.string()
            .required()
            .label('[請求情報]口座番号'),
          bankAccountName: Yup.string()
            .required()
            .kana('[請求情報]口座名義(カナ)')
            .label('[請求情報]口座名義(カナ)'),
          priority: Yup.string()
            .nullable()
            .required()
            .label('[請求情報]表示優先順'),
        })
      ),
      paymentBankName: Yup.string()
        .nullable()
        .label('[支払情報]支払金融機関名'),
      // paymentBankCode: Yup.string()
      //   .when('paymentBankName', (value, schema) =>
      //     value === 'ゆうちょ銀行'
      //       ? schema.max(0, '[支払情報]銀行コード:未記入にしてください。')
      //       : schema.nullable()
      //   )
      //   .nullable()
      //   .label('[支払情報]銀行コード'),
      paymentBankBranchName: Yup.string().nullable().label('[支払情報]支店名'),
      paymentBankBranchCode: Yup.string()
        .nullable()
        .label('[支払情報]支店コード'),
      paymentBankAccountNumber: Yup.string()
        .nullable()
        .label('[支払情報]口座番号'),
      paymentBankAccountName: Yup.string()
        .nullable()
        .kana('[支払情報]口座名義(カナ)')
        .label('[支払情報]口座名義(カナ)'),
      menuFlag: Yup.string()
        .nullable()
        .test(
          'menu',
          '[利用可能メニュー]利用可能メニュー:一つ以上の項目を選択してください。',
          (_, context) => {
            return checkMenu(context.parent);
          }
        )
        .label('[利用可能メニュー]利用可能メニュー'),
    }),
    onSubmit: values => {
      const officeMastersNum = values?.officeMasters?.length ?? 0;

      let postParams = {
        ...values,
        corporateImage: corporateImageSrc,
        corporateStampImage: fileSrc,
        officeMasters: officeMastersNum === 0 ? null : [...Array(officeMastersNum)].map((_, idx) => {
          return {
            ...values?.officeMasters?.[idx],
            postCode: !values?.officeMasters?.[idx]?.zip1 ? null : {
              number1: values?.officeMasters?.[idx]?.zip1,
              number2: values?.officeMasters?.[idx]?.zip2,
            },
            tel: !values?.officeMasters?.[idx]?.tel1 ? null : {
              number1: values?.officeMasters?.[idx]?.tel1,
              number2: values?.officeMasters?.[idx]?.tel2,
              number3: values?.officeMasters?.[idx]?.tel3,
            },
            fax: !values?.officeMasters?.[idx]?.fax1 ? null : {
              number1: values?.officeMasters?.[idx]?.fax1,
              number2: values?.officeMasters?.[idx]?.fax2,
              number3: values?.officeMasters?.[idx]?.fax3,
            }
          }
        }),
        managerName: {
          lastName: values.managerNameLastName,
          firstName: values.managerNameFirstName,
        },
        managerNameKana: {
          lastName: values.managerNameKanaLastName,
          firstName: values.managerNameKanaFirstName,
        },
        availableMenus: {
          menuQuoteListFlag: values.menuQuoteListFlag,
          menuQuoteApprovalListFlag: values.menuQuoteApprovalListFlag,
          menuQuoteDocumentNumberFlag: values.menuQuoteDocumentNumberFlag,
          menuQuoteTemplateListFlag: values.menuQuoteTemplateListFlag,
          menuQuoteFlowListFlag: values.menuQuoteFlowListFlag,
          menuMatterListFlag: values.menuMatterListFlag,
          menuMatterDateListFlag: values.menuMatterDateListFlag,
          menuMatterAssignListFlag: values.menuMatterAssignListFlag,
          menuAssignStaffStatesFlag: values.menuAssignStaffStatesFlag,
          menuMatterOpenListFlag: values.menuMatterOpenListFlag,
          menuMatterAppliedListFlag: values.menuMatterAppliedListFlag,
          menuInvoiceListFlag: values.menuInvoiceListFlag,
          menuStaffListFlag: values.menuStaffListFlag,
          menuStaffPlanListFlag: values.menuStaffPlanListFlag,
          menuStaffPayListFlag: values.menuStaffPayListFlag,
          menuStaffPayYearListFlag: values.menuStaffPayYearListFlag,
          menuAttendClosingFlag: values.menuAttendClosingFlag,
          menuAnalyzeTrendFlag: values.menuAnalyzeTrendFlag,
          menuAnalyzeProfitFlag: values.menuAnalyzeProfitFlag,
          menuClientListFlag: values.menuClientListFlag,
          menuVenueListFlag: values.menuVenueListFlag,
          menuSettingScheduleFlag: values.menuSettingScheduleFlag,
          menuUserListFlag: values.menuUserListFlag,
          menuPartnerListFlag: values.menuPartnerListFlag,
          menuCorporateListFlag: values.menuCorporateListFlag,
          menuPayTermListFlag: values.menuPayTermListFlag,
          menuNamingListFlag: values.menuNamingListFlag,
          menuIncomeTaxListFlag: values.menuIncomeTaxListFlag,
          authStaffSystemUse: values.authStaffSystemUse,
          surveyFlag: values.surveyFlag,
        },
      };

      for (let k in postParams) {
        if (postParams[k] === '') {
          delete postParams[k];
          // postParams[k] = 0;
        }
      }

      for (let k in postParams.availableMenus) {
        if (postParams.availableMenus[k] === '') {
          postParams.availableMenus[k] = 0;
        }
      }

      Object.keys(postParams).forEach(key => {
        if (
          postParams[key] !== null &&
          typeof postParams[key] === 'object' &&
          key !== 'availableMenus'
        ) {
          const fullLength = Object.values(postParams[key]).length;

          if (
            Object.values(postParams[key]).filter(v => v !== '').length !==
            fullLength
          ) {
            delete postParams[key];
          }
        }
      });

      if (!postParams.bankMasters[0]) delete postParams.bankMasters;

      if (
        !postParams?.managerName?.lastName &&
        !postParams?.managerName?.firstName
      ) {
        postParams.managerName = null;
      }

      if (
        !postParams?.managerNameKana?.lastName &&
        !postParams?.managerNameKana?.firstName
      ) {
        postParams.managerNameKana = null;
      }

      if (!postParams?.note) {
        postParams.note = null;
      }
      if (!postParams?.corporateStampImage) {
        postParams.corporateStampImage = apiUrl.corporateStampImage;
      }

      if (!postParams?.bankMasters) {
        postParams.bankMasters = [];
      }

      postParams.shiftStyleFlag = Number(values.shiftStyleFlag);

      fetchRequest(
        isEdit ? apiUrl.corporate.update : apiUrl.corporate.insert,
        postParams,
        'POST'
      )
        .then(() => {
          store.dispatch(
            globalActions.showSingleModal(
              isEdit ? '更新しました' : '登録しました'
            )
          );
          navigate('/master/corporate/list');
        })
        .catch(() => {
          store.dispatch(globalActions.showErrorModal('失敗しました'));
        });
    },
  });

  const tabErrorGroup = {
    // basic: ['corporateName', 'corporateNameKana', 'startDate', 'endDate'],
    information: [
      'corporateName',
      'corporateNameKana',
      'startDate',
      'endDate',
      'ntaNumber',
      'zip',
      'prefCode',
      'cityName',
      'streetName',
      'buildingName',
      'tel',
      'fax',
      'managerNameLastName',
      'managerNameFirstName',
      'managerNameKanaLastName',
      'managerNameKanaFirstName',
      'managerNameKanaLastName',
      'managerNameKanaFirstName',
      'managerMailAddress',
      'note',
    ],
    defaultSetting: [
      'calculationTaxFlag',
      'quoteAmountViewFlag',
      'invoiceAmountViewFlag',
      'shiftStyleFlag',
      'attendClosingDay',
      'payday',
      'matterOpenFlag',
      'matterOpenFrequency',
      'matterOpenSpan',
    ],
    bill: ['bankMasters'],
    payInfo: [
      'paymentBankName',
      'paymentBankCode',
      'paymentBankBranchName',
      'paymentBankBranchCode',
      'paymentBankAccountFlag',
      'paymentBankAccountNumber',
      'paymentBankAccountName',
    ],
    availableMenus: [
      'menuMatterListFlag',
      'menuMatterDateListFlag',
      'menuMatterAssignListFlag',
      'menuMatterOpenListFlag',
      'menuMatterAppliedListFlag',
      'menuInvoiceListFlag',
      'menuStaffListFlag',
      'menuStaffPlanListFlag',
      'menuStaffPayListFlag',
      'menuStaffPayYearListFlag',
      'menuAttendClosingFlag',
      'menuAnalyzeTrendFlag',
      'menuAnalyzeProfitFlag',
      'menuClientListFlag',
      'menuVenueListFlag',
      'menuSettingScheduleFlag',
      'menuUserListFlag',
      'menuPartnerListFlag',
      'menuCorporateListFlag',
      'menuPayTermListFlag',
      'menuNamingListFlag',
      'menuIncomeTaxListFlag',
      'authStaffSystemUse',
      'surveyFlag',
      'menuFlag',
    ],
  };

  const setFormikData = data => {
    let officeMastersNum = data?.officeMasters?.length ?? 0;
    const officeMastersData = officeMastersNum === 0 ?
      [
        {
          officeName: '',
          zip: '',
          zip1: '',
          zip2: '',
          prefCode: null,
          cityName: '',
          streetName: '',
          buildingName: '',
          bankAccountName: '',
          tel: '',
          tel1: '',
          tel2: '',
          tel3: '',
          fax: '',
          fax1: '',
          fax2: '',
          fax3: '',
        },
      ] :
      [...Array(officeMastersNum)]?.map((_, idx) => {
      return {
        ...data?.officeMasters?.[idx],
        zip:
          data?.officeMasters?.[idx]?.postCode?.number1 ?
            `${data?.officeMasters?.[idx]?.postCode?.number1}${data?.officeMasters?.[idx]?.postCode?.number2}` :
            '',
        zip1: data?.officeMasters?.[idx]?.postCode?.number1 ?? '',
        zip2: data?.officeMasters?.[idx]?.postCode?.number2 ?? '',
        tel: data?.officeMasters?.[idx]?.tel?.number1 ?
          `${data?.officeMasters?.[idx]?.tel?.number1}${data?.officeMasters?.[idx]?.tel?.number2}${data?.officeMasters?.[idx]?.tel?.number3}` :
          '',
        tel1: data?.officeMasters?.[idx]?.tel?.number1 ?? '',
        tel2: data?.officeMasters?.[idx]?.tel?.number2 ?? '',
        tel3: data?.officeMasters?.[idx]?.tel?.number3 ?? '',
        fax: data?.officeMasters?.[idx]?.fax?.number1 ?
          `${data?.officeMasters?.[idx]?.fax?.number1}${data?.officeMasters?.[idx]?.fax?.number2}${data?.officeMasters?.[idx]?.fax?.number3}` :
          '',
        fax1: data?.officeMasters?.[idx]?.fax?.number1 ?? '',
        fax2: data?.officeMasters?.[idx]?.fax?.number2 ?? '',
        fax3: data?.officeMasters?.[idx]?.fax?.number3 ?? '',
      }
    })

    let formatData = {
      ...data,
      managerNameLastName: data?.managerName?.lastName,
      managerNameFirstName: data?.managerName?.firstName,
      managerNameKanaLastName: data?.managerNameKana?.firstName,
      managerNameKanaFirstName: data?.managerNameKana?.lastName,
      ...data?.availableMenus,
    };

    formatData.officeMasters = officeMastersData;

    formik.setValues(formatData);
  };

  const CorporateInfo = () => {
    return (
      <>
        <div style={{ marginTop: '26px', position: 'relative' }}>
          <CustomFormikInput
            inputType="input"
            label="国税庁法人番号"
            labelRequired={true}
            inputName={'ntaNumber'}
            key={'ntaNumber'}
            formik={formik}
            placeholder="国税庁法人番号を入力してください"
            style={{
              areaStyle: {},
              labelStyle: { width: '175px' },
              inputStyle: { width: checkIsSp() ? '100%' : '55%' },
            }}
          />
          <span className="corporate_register--corporate--info--ntanumber">
            {!checkIsSp() && <div style={{ width: '199px' }}></div>}
            <div style={{ width: '100%' }}>
              <div
                style={{
                  width: checkIsSp() ? '100%' : '55%',
                  display: 'flex',
                  justifyContent: checkIsSp() ? '' : 'flex-end',
                  alignItems: 'flex-end',
                  fontSize: checkIsSp() ? '12px' : '',
                }}
              >
                国税庁法人番号検索は
                <a
                  href={apiUrl.houjinBangou}
                  target="_blank"
                  style={{
                    color: '#D80245',
                    textDecoration: 'underline',
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  こちら
                  <img
                    src={Icons.icon.externalLink}
                    alt="oh"
                    style={checkIsSp() ? { width: '16px', height: '16px' } : {}}
                  />
                </a>
              </div>
            </div>
          </span>
          {/* 法人印 */}
          <div
            className="corporate_register--corporate--info--corporate_seal--area"
            onClick={getFiles}
          >
            <input
              id="file"
              type="file"
              accept="image/png, image/gif, image/jpg, image/jpeg"
              onChange={fileInputChange}
              style={{ display: 'none' }}
            />
            <div className="corporate_register--corporate--info--corporate_seal">
              <img
                src={fileSrc ? fileSrc : Icons.icon.corporateIn}
                alt="corporate_stamp"
                id="corporate_stamp"
                className="corporate_register--corporate--info--corporate_stamp"
              />
            </div>
            <div>法人印</div>
            <div>png,gif,jpg,jpeg形式のみアップロード可。</div>
            <div> 画像は１M以下でアップロードしてください。</div>
            <div>推奨画像サイズ：正方形</div>
          </div>
        </div>

        <div
          className="corporate_register--corporate--info--input"
          style={divSty}
        >
          <CustomFormikInput
            inputType="input"
            label="担当者名"
            labelRequired={true}
            inputName="managerNameLastName"
            placeholder="姓"
            key={'managerNameLastName'}
            formik={formik}
            style={{
              labelStyle: { width: '175px' },
              areaStyle: { width: checkIsSp() ? '100%' : '35%' },
            }}
            disabled={isEdit && !sysAuth}
          />
          <CustomFormikInput
            inputType="input"
            inputName="managerNameFirstName"
            placeholder="名"
            key={'managerNameFirstName'}
            formik={formik}
            style={{
              areaStyle: { width: checkIsSp() ? '100%' : 'calc(38% - 204px)' },
            }}
            disabled={isEdit && !sysAuth}
          />
        </div>
        <div
          className="corporate_register--corporate--info--input"
          style={divSty}
        >
          <CustomFormikInput
            inputType="input"
            label="担当者名(カナ)"
            inputName="managerNameKanaLastName"
            placeholder="セイ"
            key={'managerNameKanaLastName'}
            formik={formik}
            style={{
              labelStyle: { width: '175px' },
              areaStyle: { width: checkIsSp() ? '100%' : '35%' },
            }}
            disabled={isEdit && !sysAuth}
          />
          <CustomFormikInput
            inputType="input"
            inputName="managerNameKanaFirstName"
            placeholder="メイ"
            key={'managerNameKanaFirstName'}
            formik={formik}
            style={{
              areaStyle: { width: checkIsSp() ? '100%' : 'calc(38% - 204px)' },
            }}
            disabled={isEdit && !sysAuth}
          />
        </div>
        <div className="corporate_register--corporate--info--input" style={{marginBottom: '34px'}}>
          <CustomFormikInput
            inputType="input"
            label="担当者メールアドレス"
            labelRequired={true}
            inputName="managerMailAddress"
            placeholder="担当者メールアドレスを入力してください"
            key={'managerMailAddress'}
            formik={formik}
            style={{
              labelStyle: { width: '175px' },
              inputStyle: { width: checkIsSp() ? '100%' : '55%' },
            }}
            disabled={isEdit && !sysAuth}
          />
        </div>

        <AddressList formik={formik} prefectures={prefectures} />
        <div className="corporate_register--corporate--info--input">
          <CustomFormikInput
            inputType="textBox"
            label="備考"
            inputName="note"
            key={'note'}
            formik={formik}
            placeholder="備考を入力してください"
            style={{
              areaStyle: { width: checkIsSp() ? '100%' : '87%' },
              labelStyle: { width: '175px' },
            }}
          />
        </div>
      </>
    );
  };

  const infos = {
    basic: {
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '24px 16px',
        flexDirection: checkIsSp() ? 'column' : '',
      },
      inputs: isEdit
        ? [
            {
              inputType: 'label', //inputのtype
              label: '法人ID', //ラベルの名前
              inputName: 'corporateId', //DBのカラム名
              key: 'corporateId',
              style: {
                areaStyle: {},
                labelStyle: { width: checkIsSp() ? 'auto' : '120px' },
                inputStyle: {},
              },
            },
            {
              inputType: 'input', //inputのtype
              label: '法人名', //ラベルの名前
              labelRequired: true, //ラベルの※マークの有無
              inputName: 'corporateName', //DBのカラム名
              placeholder: '法人名を入力してください',
              key: 'corporateName',
              style: {
                areaStyle: {},
                labelStyle: { width: '120px' },
                inputStyle: {},
              },
            },
            {
              inputType: 'input', //inputのtype
              label: '法人名(カナ)', //ラベルの名前
              labelRequired: true, //ラベルの※マークの有無
              inputName: 'corporateNameKana', //DBのカラム名
              placeholder: '法人名(カナ)を入力してください',
              key: 'corporateNameKana',
              style: {
                areaStyle: {},
                labelStyle: { width: '120px' },
                inputStyle: {},
              },
            },
            {
              inputType: 'inputDate', //inputのtype
              label: '利用開始日', //ラベルの名前
              labelRequired: true, //ラベルの※マークの有無
              inputName: 'startDate', //DBのカラム名
              disabledFutureDate: formik.values.endDate,
              placeholder: 'YYYY/MM/DD',
              key: `startDate`,
              style: {
                areaStyle: { width: checkIsSp() ? '165px' : '280px' },
                labelStyle: { width: '120px' },
                inputStyle: {},
              },
              disabled: isEdit && !sysAuth,
            },
            {
              inputType: 'inputDate', //inputのtype
              label: '利用終了日', //ラベルの名前
              labelRequired: true, //ラベルの※マークの有無
              inputName: 'endDate', //DBのカラム名
              disabledDate: formik.values.startDate,
              placeholder: 'YYYY-MM-DD',
              key: 'endDate',
              style: {
                areaStyle: { width: checkIsSp() ? '165px' : '280px' },
                labelStyle: { width: '120px' },
                inputStyle: {},
              },
              disabled: isEdit && !sysAuth,
            },
          ]
        : [
            {
              inputType: 'input', //inputのtype
              label: '法人名', //ラベルの名前
              labelRequired: true, //ラベルの※マークの有無
              inputName: 'corporateName', //DBのカラム名
              placeholder: '法人名を入力してください',
              key: 'corporateName',
              style: {
                areaStyle: {},
                labelStyle: { width: '120px' },
                inputStyle: {},
              },
            },
            {
              inputType: 'input', //inputのtype
              label: '法人名(カナ)', //ラベルの名前
              labelRequired: true, //ラベルの※マークの有無
              inputName: 'corporateNameKana', //DBのカラム名
              placeholder: '法人名(カナ)を入力してください',
              key: 'corporateNameKana',
              style: {
                areaStyle: {},
                labelStyle: { width: '120px' },
                inputStyle: {},
              },
            },
            {
              inputType: 'inputDate', //inputのtype
              label: '利用開始日', //ラベルの名前
              labelRequired: true, //ラベルの※マークの有無
              inputName: 'startDate', //DBのカラム名
              disabledFutureDate: formik.values.endDate,
              placeholder: 'YYYY/MM/DD',
              key: `startDate`,
              style: {
                areaStyle: { width: checkIsSp() ? '165px' : '280px' },
                labelStyle: { width: '120px' },
                inputStyle: {},
              },
              disabled: isEdit && !sysAuth,
            },
            {
              inputType: 'inputDate', //inputのtype
              label: '利用終了日', //ラベルの名前
              labelRequired: true, //ラベルの※マークの有無
              inputName: 'endDate', //DBのカラム名
              disabledDate: formik.values.startDate,
              placeholder: 'YYYY-MM-DD',
              key: 'endDate',
              style: {
                areaStyle: { width: checkIsSp() ? '165px' : '280px' },
                labelStyle: { width: '120px' },
                inputStyle: {},
              },
              disabled: isEdit && !sysAuth,
            },
          ],
    },
    detail: {
      tabs: {
        information: {
          label: '基本情報',
          jsx: CorporateInfo,
        },
        defaultSetting: {
          label: 'デフォルト設定',
          jsx: () => (
            <DefSet
              changeShiftStyle={setShiftStyle}
              matterOpen={matterOpen}
              changeMatterOpen={setMatterOpen}
              formik={formik}
            />
          ),
        },
        bill: {
          label: '請求情報',
          jsx: () => <BillInfo isEdit={isEdit} formik={formik} />,
        },
        payInfo: {
          label: '支払情報',
          jsx: () => (
            <PayInfo isEdit={isEdit} formik={formik} sysAuth={sysAuth} />
          ),
        },
        availableMenus: {
          label: '利用可能メニュー',
          jsx: () => (
            <AbleMenu isEdit={isEdit} formik={formik} sysAuth={sysAuth} />
          ),
        },
      },
    },
    updates: isEdit
      ? sysAuth
        ? [
            {
              label: '新規登録',
              datetime:
                moment(formik?.values?.createdSystemUser?.datetime).format(
                  'YYYY/MM/DD hh:mm'
                ) ?? '-',
              name: formik?.values?.createdSystemUser?.name ?? '-',
            },
            {
              label: '最終更新',
              datetime:
                moment(formik?.values?.updatedSystemUser?.datetime).format(
                  'YYYY/MM/DD hh:mm'
                ) ?? '-',
              name: formik?.values?.updatedSystemUser?.name ?? '-',
            },
            {
              label: '法人内更新',
              datetime:
                moment(formik?.values?.updatedUser?.datetime).format(
                  'YYYY/MM/DD hh:mm'
                ) ?? '-',
              name: formik?.values?.updatedUser?.name ?? '-',
            },
          ]
        : [
            {
              label: '法人内更新',
              datetime:
                moment(formik?.values?.updatedUser?.datetime).format(
                  'YYYY/MM/DD hh:mm'
                ) ?? '-',
              name: formik?.values?.updatedUser?.name ?? '-',
            },
          ]
      : '',
    ignoreConvert: true,
  };

  const getDetailValue = async () => {
    const cId = location.state.id;

    store.dispatch(globalActions.setLoading(true));

    await fetchApi(
      'corporate',
      'detail',
      'GET',
      loginType,
      account.corporateId,
      account.accessToken,
      { corporateId: cId, deletedFlag: location.state.deletedFlag }
    )
      .then(data => {
        setCorporateImageSrc(data.detail.corporateImage);
        setFileSrc(data.detail.corporateStampImage);
        setFormikData(data.detail);
      })
      .catch(error => alert(error));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEdit) {
      getDetailValue().then(() =>
        store.dispatch(globalActions.setLoading(false))
      );
    }

    dispatch(bankingCodeFetch());
  }, []);

  useEffect(() => {
    getLoginUserData();
  }, []);

  if (isEdit) {
    return (
      <>
        <div className="corporate_register">
          <RegisterBase
            image={corporateImageSrc ? corporateImageSrc : Icons.icon.noImage}
            infos={infos}
            setImage={corporateImageInputChange}
            formik={formik}
          />
        </div>

        <div
          className={'bottom_btn_area'}
          style={{ height: checkIsSp() ? '88px' : '92px' }}
        >
          <div className={'corporate_register--submit_buttons'}>
            <Button
              text={'戻る'}
              style={Options.buttonStyles.back}
              onClick={() => {
                navigate('/master/corporate/list');
              }}
            />
            {location.state.deletedFlag === 0 && (
              <Button
                text={'更新'}
                style={Options.buttonStyles.submit}
                onClick={() => {
                  handlerFormikSubmit(formik, tabErrorGroup);
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="corporate_register">
          <RegisterBase
            image={corporateImageSrc ? corporateImageSrc : Icons.icon.noImage}
            infos={infos}
            setImage={corporateImageInputChange}
            formik={formik}
          />
        </div>

        <div
          className={'bottom_btn_area'}
          style={{ height: checkIsSp() ? '88px' : '92px' }}
        >
          <div className={'corporate_register--submit_buttons'}>
            <Button
              text={'戻る'}
              style={Options.buttonStyles.back}
              onClick={() => {
                navigate('/master/corporate/list');
              }}
            />
            <Button
              text={'登録'}
              style={Options.buttonStyles.submit}
              onClick={() => {
                handlerFormikSubmit(formik, tabErrorGroup);
              }}
            />
          </div>
        </div>
      </>
    );
  }
});

export default CorporateRegister;
