import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { store } from '../../../redux/store';
import { globalActions } from '../../../redux/slice/global';
import {
  quoteActions,
  quoteDocumentInit,
  quoteDocumentPdf,
  quoteListFetch,
  quoteListInit,
  quoteListNaming,
} from '../../../redux/slice/quote';
import ListView from '../../../components/listView';
import CsvExportModal from '../../../components/modal/csvExportModal';
import Button from '../../../components/button';
import CustomInput from '../../../components/customInput';
import ApprovalApplyModal from '../../../components/modal/approvalApplyModal';
import Options from '../../../constants/options';
import Icons from '../../../constants/icons';
import { fetchRequest } from '../../../api/fetch';
import { apiUrl } from '../../../api/apiUrl';
import './style.scss';
import { checkIsSp } from '../../../utils/fnUtil';

const btnSty = {
  backgroundColor: 'white',
  color: 'black',
  width: '140px',
  height: '36px',
  margin: '0',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '6px',
};

const lockSty = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const moment = require('moment');

const selectStatus = ['見積中', '受注確定', '失注'];
const preColumn = {
  opt: ['予備項目1', '予備項目2', '予備項目3', '予備項目4', '予備項目5'],
  val: [
    'PromotionPreliminary1',
    'PromotionPreliminary2',
    'PromotionPreliminary3',
    'PromotionPreliminary4',
    'PromotionPreliminary5',
  ],
};

const apiParams = {
  clientList: {
    status: 1,
    count: 999,
    sort: 'clientIdAsc',
  },
  userList: {
    status: 1,
    count: 999,
    sort: 'userCodeAsc',
  },
};
const statuesOptVal = {
  opt: ['作成中', '未承認', '承認済', '否認'],
  val: [0, 1, 3, 2],
};

const QuoteList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchItem, setSearchItem] = useState({
    clientId: null, // 取引先
    orderStatus: [0, 1],
    managerUserId: null, // 社内担当者
    approvalStatus: [0, 1, 3],
    namingClassificationId: null,
    offset: 0,
    count: 20,
    sort: {
      column: 'quoteNumber',
      method: 'Desc',
    },
  });
  const preDisable = !searchItem.namingClassificationId;
  const [doSearch, setDoSearch] = useState(false);
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [invDate, setInvDate] = useState('');

  const accountInfo = useSelector(state => state.account);

  const isSp = checkIsSp();

  const {
    stampFlag,
    flowDetail,
    quoteList,
    listSelOpt,
    namingSelOpt,
    quoteNumberId,
  } = useSelector(state => state.quote);
  const { clientSel, userSel } = listSelOpt;

  // 見積書No管理モダル
  const [modalVisible, setModalVisible] = useState(false);

  // 捺印申請モダル
  const [applyModalVisible, setApplyModalVisible] = useState(false);
  const changeApplyModalVisible = () => {
    setApplyModalVisible(!applyModalVisible);
  };

  const [preliminaryList, setPreliminaryList] = useState([]);

  // urlのパラメータを取得
  const getQueryVariable = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  };

  const getPreliminaryList = async () => {
    const res = await fetchRequest(
      apiUrl.naming.list,
      { namingClassificationId: 'Index' },
      'GET'
    );

    let ary = res.item;

    ary.forEach((obj, idx) => {
      if (!obj.namingId.includes('PromotionPreliminary')) {
        delete ary[idx];
      }
    });

    ary = ary.filter(v => v);

    const list = ary.map((obj, idx) => {
      return ary[
        ary.findIndex(obj => obj.namingId === `PromotionPreliminary${idx + 1}`)
      ].naming;
    });

    setPreliminaryList(list);
  };

  useEffect(() => {
    if (getQueryVariable('noChange') === '1') {
      setModalVisible(true);
    }
  }, [getQueryVariable('noChange')]);

  useEffect(() => {
    dispatch(
      quoteListFetch({
        ...searchItem,
        sort: `${searchItem.sort.column}${searchItem.sort.method}`,
      })
    );
  }, [doSearch]);

  useEffect(() => {
    getPreliminaryList();
    dispatch(quoteListInit(apiParams));
    dispatch(
      quoteListNaming({
        namingClassificationId: 'PromotionPreliminary1',
        sort: 'namingClassificationIdDesc',
      })
    );
  }, []);

  useLayoutEffect(() => {
    dispatch(
      quoteListNaming({
        namingClassificationId: 'PromotionPreliminary1',
        sort: 'namingClassificationIdDesc',
      })
    );
  }, []);

  const QuoteListSearch = () => (
    <div
      style={{
        width: '100%',
        gap: '24px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="quote_list_search--header">
        <CustomInput
          inputType="input"
          label="見積書No"
          inputName="quoteNumber"
          placeholder="見積書Noを入力してください"
          className={{ areaClass: 'column' }}
          style={{ areaStyle: { width: checkIsSp() ? '100%' : '258px' } }}
        />
        <CustomInput
          inputType="input"
          label="件名"
          inputName="matterName"
          placeholder="件名を入力してください"
          className={{ areaClass: 'column' }}
          style={{ areaStyle: { width: checkIsSp() ? '100%' : '400px' } }}
        />
        <CustomInput
          inputType="select"
          label="取引先"
          inputName="clientName"
          mode="multiple"
          showSearch={true}
          useValue={true}
          usePopContainer={true}
          initialValue={clientSel?.map(e => e.val)}
          selectBoxOptions={clientSel?.map(e => `${e.val}:${e.opt}`)}
          placeholder="取引先を入力してください"
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: checkIsSp() ? '100%' : '258px' },
            area: { gap: '0' },
            input: { width: checkIsSp() ? '100%' : '258px' },
          }}
          onChange={e => {
            setSearchItem({ ...searchItem, clientId: e });
          }}
        />
        <CustomInput
          inputType="select"
          label="受注ステータス"
          inputName="orderStatus"
          mode="multiple"
          allowClear={false}
          showSearch={false}
          usePopContainer={true}
          initialValue={selectStatus}
          selectBoxOptions={selectStatus}
          selectedSelectBox={['見積中', '受注確定']}
          placeholder="受注ステータスを選択してください"
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: checkIsSp() ? '100%' : '240px' },
            labelStyle: { width: '112px' },
          }}
          onChange={e => {
            setSearchItem({
              ...searchItem,
              orderStatus: e.map(str =>
                str === '見積中' ? 0 : str === '受注確定' ? 1 : 2
              ),
            });
          }}
        />
      </div>

      <div className="quote_list_search--header">
        <CustomInput
          inputType="select"
          label="社内担当者"
          mode="multiple"
          showSearch={true}
          allowClear={false}
          useValue={true}
          usePopContainer={true}
          inputName="managerUserName"
          initialValue={userSel?.map(e => e.val)}
          selectBoxOptions={userSel?.map(e => `${e.val}:${e.opt}`)}
          placeholder="社内担当者を入力してください"
          className={{ areaClass: 'column' }}
          style={{
            area: { gap: '0' },
            areaStyle: { width: checkIsSp() ? '100%' : '400px' },
          }}
          onChange={e => {
            setSearchItem({ ...searchItem, managerUserId: e });
          }}
        />
        <div
          style={
            checkIsSp()
              ? { display: 'flex', gap: '5px', marginTop: '2px', width: '100%' }
              : { display: 'flex', gap: '5px', marginTop: '2px' }
          }
        >
          <div
            onBlur={e => {
              setInvDate(
                moment(e.target.value, 'YYYY/MM/DD').format('YYYY-MM-DD')
              );
            }}
            style={checkIsSp() ? { width: 'calc(100% + 40px)' } : {}}
          >
            <CustomInput
              inputType="inputDate"
              label="請求予定日"
              inputName="invoiceDate1"
              placeholder="YYYY/MM/DD"
              unit={['〜']}
              className={{ areaClass: 'matter_schedule--head_select' }}
            />
          </div>
          <CustomInput
            inputType="inputDate"
            label="　"
            inputName="invoiceDate2"
            placeholder="YYYY/MM/DD"
            className={{ areaClass: 'matter_schedule--head_select' }}
            disabledDate={invDate}
          />
        </div>
        <div
          style={
            checkIsSp()
              ? {
                  display: 'flex',
                  gap: '14px',
                  flexDirection: 'column',
                  width: '100%',
                }
              : { display: 'flex', gap: '5px' }
          }
        >
          <CustomInput
            inputType="select"
            label="予備項目"
            inputName="preColumn"
            allowClear={true}
            showSearch={false}
            usePopContainer={true}
            initialValue={preColumn.val}
            selectBoxOptions={preliminaryList}
            placeholder="予備項目を選択してください"
            className={{ areaClass: 'column' }}
            style={{
              areaStyle: { width: checkIsSp() ? '100%' : '150px' },
            }}
            onChange={e => {
              setSearchItem({ ...searchItem, namingClassificationId: e });
              dispatch(
                quoteListNaming({
                  namingClassificationId: e,
                  sort: 'namingClassificationIdDesc',
                })
              );
            }}
          />
          {checkIsSp() ? (
            <CustomInput
              inputType="select"
              inputName="preColumnItem"
              mode={'multiple'}
              showSearch={false}
              allowClear={false}
              useValue={true}
              usePopContainer={true}
              initialValue={namingSelOpt?.map(e => e.namingId)}
              selectBoxOptions={namingSelOpt?.map(
                e => `${e.namingId}:${e.naming}`
              )}
              placeholder="予備項目を選択してください"
              className={{ areaClass: 'column' }}
              disabled={preDisable}
              style={{
                areaStyle: { width: '100%' },
              }}
              onChange={e => {
                setSearchItem({ ...searchItem, namingId: e });
              }}
            />
          ) : (
            <CustomInput
              inputType="select"
              label="　"
              inputName="preColumnItem"
              mode={'multiple'}
              showSearch={false}
              allowClear={false}
              useValue={true}
              initialValue={namingSelOpt?.map(e => e.namingId)}
              selectBoxOptions={namingSelOpt?.map(
                e => `${e.namingId}:${e.naming}`
              )}
              placeholder="予備項目を入力してください"
              className={{ areaClass: 'column' }}
              disabled={preDisable}
              style={{
                areaStyle: { width: '300px' },
              }}
              onChange={e => {
                setSearchItem({ ...searchItem, namingId: e });
              }}
            />
          )}
        </div>
        <CustomInput
          inputType="select"
          label="ステータス"
          mode="multiple"
          showSearch={false}
          allowClear={false}
          usePopContainer={true}
          inputName="approvalStatus"
          selectBoxOptions={statuesOptVal.opt}
          initialValue={statuesOptVal.val}
          // useValue={true}
          selectedSelectBox={[0, 1, 3]}
          placeholder="ステータスを選択してください"
          className={{ areaClass: 'column' }}
          style={{
            areaStyle: { width: checkIsSp() ? '100%' : '300px' },
            labelStyle: { width: '80px' },
          }}
          onChange={e => {
            setSearchItem({ ...searchItem, approvalStatus: e });
          }}
        />
      </div>
    </div>
  );
  const searchFormInfo = {
    jsx: QuoteListSearch,
  };

  // csvモダル
  const [csvExportVisible, setCsvExportVisible] = useState(false);
  const toggleCsvExportVisible = bool => {
    setCsvExportVisible(bool === undefined ? !csvExportVisible : bool);
  };
  const csvExportFunction = category => {
    alert(category);
  };

  // 予備項目コラム
  let colPreSetting;
  switch (searchItem.namingClassificationId) {
    case 'PromotionPreliminary2':
      colPreSetting = {
        preliminary2: {
          label: `${preliminaryList[1] ? preliminaryList[1] : '予備項目'}`,
          sort: 'asc',
          dataName: 'preliminary2',
        },
      };
      break;
    case 'PromotionPreliminary3':
      colPreSetting = {
        preliminary3: {
          label: `${preliminaryList[2] ? preliminaryList[2] : '予備項目'}`,
          sort: 'asc',
          dataName: 'preliminary3',
        },
      };
      break;
    case 'PromotionPreliminary4':
      colPreSetting = {
        preliminary4: {
          label: `${preliminaryList[3] ? preliminaryList[3] : '予備項目'}`,
          sort: 'asc',
          dataName: 'preliminary4',
        },
      };
      break;
    case 'PromotionPreliminary5':
      colPreSetting = {
        preliminary5: {
          label: `${preliminaryList[4] ? preliminaryList[4] : '予備項目'}`,
          sort: 'asc',
          dataName: 'preliminary5',
        },
      };
      break;
    default:
      colPreSetting = {
        preliminary1: {
          label: `${preliminaryList[0] ? preliminaryList[0] : '予備項目'}`,
          sort: 'asc',
          dataName: 'preliminary1',
        },
      };
      break;
  }
  const columnSettings = {
    checkAll: true,
    quoteNumber: {
      label: '見積書No',
      sort: 'asc',
      dataName: 'quoteNumber',
      jsx: e =>
        e.deleteLockFlag === 1 ? (
          <div style={lockSty}>
            <img
              src={Icons.icon.lockGr}
              alt="lock"
              style={{ width: '24px', height: '24px' }}
            />
            {e.quoteNumber}
          </div>
        ) : (
          e.quoteNumber
        ),
    },
    matterName: { label: '件名', sort: 'asc', dataName: 'matterName' },
    clientName: { label: '取引先名', sort: 'asc', dataName: 'clientName' },
    orderStatus: {
      label: '受注ステータス',
      sort: 'asc',
      dataName: 'orderStatus',
      jsx: e => {
        switch (e.orderStatus) {
          case 0:
            return '見積中';
          case 1:
            return '受注済';
          case 2:
            return '失注';
          default:
            break;
        }
      },
    },
    managerUserName: {
      label: '社内担当者',
      sort: 'asc',
      dataName: 'managerUserName',
    },
    invoiceDate: {
      label: '請求予定日',
      sort: 'asc',
      dataName: 'invoicePlanDate',
    },
    preliminary1: {
      label: `${preliminaryList[0] ? preliminaryList[0] : '予備項目1'}`,
      sort: 'asc',
      dataName: 'preliminary',
    },
    // ...colPreSetting,
    approvalStatus: {
      label: 'ステータス',
      sort: 'asc',
      dataName: 'approvalStatus',
    },
    statics: [
      { label: '編集', icon: Icons.icon.editSNv },
      { label: '削除', icon: Icons.icon.deleteSNv },
      { label: '案件作成', icon: Icons.icon.addMatter },
    ],
  };
  const buttonSettings = {
    viewPager: true,
    export: {
      icon: Icons.icon.export,
      text: 'CSV出力',
      onClick: () => {
        toggleCsvExportVisible();
      },
      style: Options.buttonStyles.csv,
    },
    addButton: {
      props: {
        // url: '/quote/register', //ボタンをクリックした時の遷移先を指定する「'/main/invoice/list'」
        onClick: () => {
          navigate('/quote/register');
        },
        displayText: true,
        style: { cursor: 'pointer' },
      },
    },
  };

  const changeSort = e => {
    let sortNm = e.target.id;
    switch (sortNm) {
      case 'invoiceDate':
        sortNm = 'invoicePlanDate';
        break;
      case 'preliminary1':
        sortNm = 'preliminary';
        break;
      default:
        break;
    }
    if (sortNm === searchItem.sort.column) {
      setSearchItem({
        ...searchItem,
        sort: {
          ...searchItem.sort,
          method: searchItem.sort.method === 'Desc' ? 'Asc' : 'Desc',
        },
      });
    } else {
      setSearchItem({
        ...searchItem,
        sort: {
          column: sortNm,
          method: 'Desc',
        },
      });
    }
    setDoSearch(!doSearch);
  };

  // チェックされたindexを取得
  const getCheckedIndex = () => {
    const checkAll = document.getElementById('checkAll')?.checked;
    const checks = document.querySelectorAll('input[id^="check_"]');

    let ret = '';

    if (checkAll) {
      ret = 'all';
    } else {
      ret = [];
      checks.forEach((elm, index) => {
        if (elm.checked) {
          ret.push(index);
        }
      });

      ret.filter(v => v);
    }

    return ret;
  };

  // 承認申請
  const approvalClick = async () => {
    let index = getCheckedIndex();

    if (getCheckedIndex().length === 1) {
      if (quoteList.item[index[0]].deletedFlag) {
        store.dispatch(globalActions.showSingleModal('この見積は削除済みです'));
      } else if (quoteList.item[index[0]].approvalStatus !== 0) {
        store.dispatch(globalActions.showSingleModal('この見積は申請済みです'));
      } else {
        const detailRes = await fetchRequest(
          apiUrl.quote.detail,
          { quoteNumber: quoteList.item[index[0]].quoteNumber },
          'GET'
        );
        const detail = detailRes.detail;
        const createdUserId = detail?.createdUser?.id ?? '';
        const managerUserId = detail?.managerUserId ?? '';

        if (
          accountInfo.userId === createdUserId ||
          accountInfo.userId === managerUserId
        ) {
          dispatch(
            quoteActions.setQuoteNumber(quoteList.item[index[0]].quoteNumber)
          );
          dispatch(
            quoteDocumentInit({
              quoteNumber: quoteList.item[index[0]].quoteNumber,
              corporateId: accountInfo.corporateId,
              deletedFlag: 0,
            })
          )
            .unwrap()
            .then(() => {
              changeApplyModalVisible();
            });
        } else {
          store.dispatch(
            globalActions.showSingleModal(
              '見積作成者または社内担当者のみ申請可能です'
            )
          );
        }
      }
    } else {
      store.dispatch(globalActions.showSingleModal('1つ選択してください'));
    }
  };

  // メール送信
  const mailClick = () => {
    let checkedData = [];
    if (getCheckedIndex().length === 0) {
      store.dispatch(globalActions.showSingleModal('1つ以上選択してください'));
      return;
    } else if (getCheckedIndex() === 'all') {
      quoteList.item.map(item => {
        return checkedData.push(item.clientName);
      });
    } else {
      getCheckedIndex().map(item => {
        return checkedData.push(quoteList.item[item]);
      });
    }
    let checkedClientName = [];
    let checkedQuoteNumber = [];
    let checkedQuoteName = [];
    let checkedUserLastName = [];
    let checkedUserFirstName = [];
    let checkedClientManagerName = [];

    checkedData.map(item => {
      checkedQuoteNumber.push(item.quoteNumber);
      checkedClientName.push(item.clientName);
      checkedQuoteName.push(item.matterName);
      checkedUserLastName.push(item?.managerUserName?.firstName);
      checkedUserFirstName.push(item?.managerUserName?.lastName);
      checkedClientManagerName.push(item?.clientManagerName);
    });
    if ([...new Set(checkedClientName)].length === 1) {
      navigate('/quote/register/mail', {
        state: {
          id: checkedQuoteNumber,
          nm: [...new Set(checkedClientName)][0],
          quoteNm: checkedQuoteName,
          stampFlag: stampFlag,
          userLastName: checkedUserLastName,
          userFirstName: checkedUserFirstName,
          clientName: checkedClientManagerName,
        },
      });
    } else {
      store.dispatch(
        globalActions.showSingleModal('同一取引先のみ選択可能です')
      );
    }
  };

  // 検索
  const onSearch = () => {
    const searchTerms = {
      ...searchItem,
      quoteNumber: document.getElementById('quoteNumber')?.value,
      matterName: document.getElementById('matterName')?.value,
      invoicePlanDateFrom: document.getElementById('invoiceDate1')?.value,
      invoicePlanDateTo: document.getElementById('invoiceDate2')?.value,
    };
    Object.keys(searchTerms).map(item => {
      if (!searchTerms[item]) {
        delete searchTerms[item];
      }
      return true;
    });
    setSearchItem(searchTerms);
    setDoSearch(!doSearch);
  };

  // 削除
  const onDelete = quoteNumber => {
    fetchRequest(
      apiUrl.quote.delete,
      { quoteNumber: quoteNumber },
      'POST'
    ).then(({ code }) => {
      if (code === '0') {
        store.dispatch(globalActions.showSingleModal('削除しました'));
      }
      setDoSearch(!doSearch);
    });
  };

  // カウント数変更
  const handleChangeListPerPage = num => {
    setSearchItem({ ...searchItem, count: num });
    setDoSearch(!doSearch);
  };

  // ページャー変更
  const handleChangePage = num => {
    setSearchItem({ ...searchItem, offset: num });
    setDoSearch(!doSearch);
  };

  // PDF出力
  const pdfClick = async stamp => {
    let checkedIndex = getCheckedIndex().filter(v => v === 0 || v);
    let isMixedClients = false;

    checkedIndex.forEach(v => {
      if (
        quoteList.item[checkedIndex[0]].clientId !== quoteList.item[v].clientId
      ) {
        isMixedClients = true;
      }
    });

    if (checkedIndex.length < 1) {
      store.dispatch(globalActions.showSingleModal('1つ以上選択してください'));
    } else if (isMixedClients) {
      store.dispatch(
        globalActions.showSingleModal('同一取引先のみ選択可能です')
      );
    } else if (
      accountInfo.skipQuoteApprovalFlag === 0 &&
      quoteList.item[checkedIndex[0]].approvalStatus !== 3
    ) {
      store.dispatch(
        globalActions.showSingleModal('承認ステータスのみ選択可能です')
      );
    } else {
      for (let i = 0; i < checkedIndex.length; i++) {
        const v = checkedIndex[i];
        dispatch(
          quoteDocumentPdf({
            quoteNumber: quoteList.item[v].quoteNumber,
            stampFlag: stampFlag ? 1 : 0,
          })
        );
      }
    }
  };

  // ロッククリック
  const lockClick = async flag => {
    if (!getCheckedIndex().length) {
      store.dispatch(globalActions.showSingleModal('1つ以上選択してください'));
    } else {
      let values;
      let isLocked = false;

      if (getCheckedIndex() !== 'all') {
        values = getCheckedIndex().map(i => {
          return quoteList.item[i].quoteNumber;
        });
      } else {
        values = quoteList.item.map(e => {
          return e.quoteNumber;
        });
      }

      values.forEach(num => {
        const isMatter =
          quoteList.item[quoteList.item.findIndex(o => o.quoteNumber === num)]
            .isLinkedMatter;

        if (isMatter) isLocked = true;
      });

      if (isLocked) {
        store.dispatch(
          globalActions.showSingleModal(
            '案件が設定されている見積が含まれているため、ロックできません'
          )
        );
      } else {
        await fetchRequest(
          apiUrl.quote.lock,
          {
            deleteLockFlag: flag,
            quoteNumber: values,
          },
          'POST'
        );
        dispatch(
          quoteListFetch({
            ...searchItem,
            sort: `${searchItem.sort.column}${searchItem.sort.method}`,
          })
        );
      }
    }
  };

  const [copyListVisible, setCopyListVisible] = useState(false);

  // コピークリック
  const copyClick = () => {
    setCopyListVisible(!copyListVisible);
  };

  const handleCopyQuote = mode => {
    if (getCheckedIndex().length !== 1) {
      if (getCheckedIndex().length == 0) {
        store.dispatch(globalActions.showSingleModal('1つ選択してください'));
      } else {
        store.dispatch(globalActions.showSingleModal('複数選択できません'));
      }
    } else if (getCheckedIndex() === 'all') {
      store.dispatch(globalActions.showSingleModal('複数選択できません'));
    } else {
      const checkedQuoteNum = quoteList.item[getCheckedIndex()[0]].quoteNumber;
      navigate('/quote/register', {
        state: {
          id: checkedQuoteNum,
          copyMode: mode,
        },
      });
    }
  };

  //メニュー...
  const toggleMenu = () => {
    setMenuDisplay(() => !menuDisplay);
  };

  const apiDataList = useMemo(() => {
    // apiデータの処理
    const apiDataCopy = JSON.parse(JSON.stringify(quoteList));
    apiDataCopy.item?.map(item => {
      // ステータス
      item.approvalStatus =
        item.approvalStatus == 0 ? (
          <div className="quote_list--approval_status--0">作成中</div>
        ) : item.approvalStatus == 1 ? (
          <div className="quote_list--approval_status--1">未承認</div>
        ) : item.approvalStatus == 2 ? (
          <div className="quote_list--approval_status--2">否認</div>
        ) : item.approvalStatus == 3 ? (
          <div className="quote_list--approval_status--3">承認</div>
        ) : (
          <div className="quote_list--approval_status--4">削除済</div>
        );
      // 社内担当者
      item.managerUserName = `${item.managerUserName.lastName} ${item.managerUserName.firstName}`;
      // アイコンステータス
      item.editIconState = 0;
      item.deleteIconState = item.deleteLockFlag === 1 ? 1 : 0;

      item.preliminary1 = namingSelOpt?.find(
        o => o.namingId === item.preliminary1
      )?.naming;
    });

    return {
      count: quoteList.searchCount === undefined ? 0 : quoteList.searchCount,
      item: apiDataCopy.item,
    };
  }, [quoteList.item]);
  const skipQuoteApprovalFlag = accountInfo.skipQuoteApprovalFlag;

  return (
    <>
      <div
        className="commonPage--base quote_list"
        style={isSp ? { paddingBottom: '190px' } : {}}
      >
        <ListView
          searchFormInfo={searchFormInfo}
          columnSettings={columnSettings}
          buttonSettings={buttonSettings}
          primaryKey="quoteNumber"
          primaryName="matterName"
          apiData={apiDataList}
          sortSettings={{
            sortColumn: searchItem.sort.column,
            sortMethod: searchItem.sort.method,
          }}
          changeSort={changeSort}
          onSearch={onSearch}
          onDelete={onDelete}
          onChangeCount={handleChangeListPerPage}
          onChangeOffset={handleChangePage}
          displayNotice={false}
        />
      </div>
      <div
        className="bottom_btn_area quote_list--bottom_area"
        style={
          skipQuoteApprovalFlag === 0 && checkIsSp()
            ? {
                bottom: '88px',
                backgroundColor: 'rgba(106, 111, 124, 0.28)',
                display: menuDisplay ? 'flex' : 'none',
              }
            : {}
        }
      >
        <div className="quote_list--submit_button">
          {skipQuoteApprovalFlag === 0 && !checkIsSp() && (
            <Button
              text="承認申請"
              style={Options.buttonStyles.stampApproval}
              onClick={approvalClick}
            />
          )}
        </div>
        <div className="quote_list--setting_buttons">
          <div
            className="quote_list--setting_buttons_btn"
            style={
              checkIsSp()
                ? {
                    padding: '8px 16px',
                    backgroundColor: 'rgba(255, 255, 255,0.6)',
                    borderRadius: '6px',
                  }
                : {}
            }
          >
            {skipQuoteApprovalFlag == 0 && (
              <CustomInput
                inputType="checkBox"
                label="社印"
                style={
                  checkIsSp()
                    ? {}
                    : {
                        areaStyle: {
                          padding: '15px 5px',
                          backgroundColor: 'rgba(255, 255, 255,0.6)',
                          borderRadius: '6px',
                        },
                      }
                }
                onClick={e => {
                  dispatch(quoteActions.setStampFlag(e.target.checked));
                }}
              />
            )}
            <div
              style={
                window.matchMedia &&
                window.matchMedia('(max-device-width: 340px)').matches
                  ? {
                      display: 'flex',
                      flexFlow: 'column',
                      width: '100%',
                      gap: '8px',
                    }
                  : checkIsSp()
                  ? {
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      gap: '8px',
                    }
                  : {
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                    }
              }
            >
              <Button
                text="PDF表示"
                icon={Icons.icon.navyDocument}
                style={btnSty}
                onClick={() => {
                  pdfClick(0);
                }}
              />
              <Button
                text="メール送信"
                icon={Icons.icon.mailNvLight}
                style={btnSty}
                onClick={() => {
                  mailClick();
                }}
              />
            </div>
          </div>
          <div className="quote_list--setting_buttons_btn">
            {!checkIsSp() && (
              <CustomInput
                inputType="checkBox"
                label=""
                style={{
                  areaStyle: { visibility: 'hidden' },
                }}
              />
            )}
            {accountInfo.unlockQuoteFlag === 1 && (
              <Button
                text="ロック"
                icon={Icons.icon.lockNvT}
                style={btnSty}
                onClick={() => {
                  lockClick(1);
                }}
              />
            )}
            {accountInfo.unlockQuoteFlag === 1 && (
              <Button
                text="ロック解除"
                icon={Icons.icon.unLockNv}
                style={btnSty}
                onClick={() => {
                  lockClick(0);
                }}
              />
            )}
            <div
              className={'quote_list--copy_list'}
              style={{
                display: copyListVisible ? 'block' : 'none',
                opacity: copyListVisible ? '1' : '0',
              }}
            >
              <p>見積複製</p>
              <span onClick={() => handleCopyQuote('only')}>見積情報のみ</span>
              <span onClick={() => handleCopyQuote('all')}>すべて</span>
            </div>
            <Button
              text="複製"
              icon={Icons.icon.copyNv}
              style={btnSty}
              onClick={copyClick}
            />
          </div>
        </div>
      </div>
      {checkIsSp() && skipQuoteApprovalFlag == 0 && (
        <div
          className="bottom_btn_area quote_list--bottom_area2"
          style={{ height: '88px' }}
        >
          <div
            className="quote_list--submit_button"
            style={{
              height: '88px',
              padding: '24px 16px',
              width: '100%',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <div className="quote_list--menu_button" style={{ width: '40px' }}>
              <Button
                text={menuDisplay ? '×' : '...'}
                style={{
                  width: '100%',
                  height: '40px',
                  border: '1px solid #2b3245',
                  borderRadius: '6px',
                  backgroundColor: '#FFFFFF',
                  color: '#000000',
                  fontSize: '20px',
                  margin: '0 0',
                  padding: '0 0',
                }}
                onClick={toggleMenu}
              />
            </div>
            <Button
              text="承認申請"
              style={Options.buttonStyles.stampApproval}
              onClick={approvalClick}
            />
          </div>
        </div>
      )}
      {/* CSVモダル */}
      <CsvExportModal
        exportType="quoteList"
        csvExportVisible={csvExportVisible}
        setCsvExportVisible={toggleCsvExportVisible}
        exportFunction={csvExportFunction}
        searchParams={searchItem}
      />

      {/* 捺印申請モダル */}
      <ApprovalApplyModal
        modalVisible={applyModalVisible}
        changeModalVisible={changeApplyModalVisible}
        stepData={flowDetail}
      />
    </>
  );
};

export default QuoteList;
