import React from 'react';
import { Table } from 'antd';
import './style.scss';

const PayTotal = props => {
  const { toThousands, dataSource } = props;

  const colWid = 120;
  const columnAdd = [
    {
      key: 'payment',
      render: () => (
        <div className="pay_total--table--column--payment">支給</div>
      ),
      width: 36,
    },
    {
      title: '給与手当',
      dataIndex: 'salaryAllowance',
      key: 'salaryAllowance',
      width: colWid,
      render: record => {
        return toThousands(record) + '円';
      },
    },
    {
      title: '立替金',
      dataIndex: 'advanceMoney',
      key: 'advanceMoney',
      width: colWid,
      render: record => {
        return toThousands(record) + '円';
      },
    },
    { title: '', key: 'paymentNull' },
  ];
  const dataAdd = [
    {
      key: '1',
      salaryAllowance: dataSource.payTotalAmount,
      advanceMoney: dataSource.advancePayTotalAmount,
    },
  ];

  const columnMin = [
    {
      key: 'subsidy',
      render: () => (
        <div className="pay_total--table--column--payment">控除</div>
      ),
      width: 36,
    },
    {
      title: '所得税',
      dataIndex: 'incomeTax',
      key: 'incomeTax',
      width: colWid,
      render: record => {
        return toThousands(record) + '円';
      },
    },
    {
      title: '前払金',
      dataIndex: 'advancePayment',
      key: 'advancePayment',
      width: colWid,
      render: record => {
        return toThousands(record) + '円';
      },
    },
    { title: '', key: 'subsidyNull' },
  ];
  const dataMin = [
    {
      key: '1',
      incomeTax: dataSource.incomeTaxAmount,
      advancePayment: dataSource.beforePayTotalAmount,
    },
  ];
  const addMinTotal =
    dataAdd[0].salaryAllowance * 1 +
    dataAdd[0].advanceMoney * 1 -
    dataMin[0].incomeTax * 1 -
    dataMin[0].advancePayment * 1;

  return (
    <>
      <div className="pay_total--table">
        <Table columns={columnAdd} dataSource={dataAdd} pagination={false} />
        <Table columns={columnMin} dataSource={dataMin} pagination={false} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className="pay_total--total">
          <div className="pay_total--total--top">差引支払合計</div>
          <div className="pay_total--total--bottom">
            {toThousands(addMinTotal) + '円'}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayTotal;
