import { prefData } from '../../staff/settingsFile/constant';
const pref = prefData;

const Inputs = [
  {
    inputType: 'radioButton',
    radioButtonTitle: '指定方法',
    labelRequired: true,
    inputName: 'targetMethod',
    initialValue: ['0', '1'],
    style: {
      labelStyle: { width: '232px' },
    },
  },
  {
    inputType: 'radioButton',
    radioButtonTitle: '実施対象',
    inputName: 'targetType',
    initialValue: ['0', '1', '2'],
    style: {
      labelStyle: { width: '232px' },
    },
  },
  {
    inputType: 'radioButton',
    radioButtonTitle: '性別',
    inputName: 'gender',
    initialValue: ['0', '1', '2'],
    style: {
      labelStyle: { width: '232px' },
    },
  },
  {
    inputType: 'radioButton',
    radioButtonTitle: '年代',
    inputName: 'ages',
    initialValue: ['0', '1'],
    style: {
      labelStyle: { width: '232px' },
    },
  },
  {
    inputName: ['agesId1', 'agesId2', 'agesId3', 'agesId4', 'agesId5', 'agesId6'],
    initialValue: ['0', '1', '2', '3', '4', '5'],
    mode: 'survey',
    inputType: 'checkBox',
    defaultChecked: [false, false, false, false, false, false],
    label: ['10代', '20代', '30代', '40代', '50代', '60代以上'],
  },
  {
    inputType: 'radioButton',
    radioButtonTitle: '職業',
    inputName: 'professions',
    initialValue: ['0', '1'],
    style: {
      labelStyle: { width: '232px' },
    },
  },
  {
    inputName: ['professions1', 'professions2'],
    initialValue: ['0', '1'],
    mode: 'survey',
    inputType: 'checkBox',
    defaultChecked: [false, false],
    label: ['学生', 'その他(社会人、主婦など)'],
  },
  {
    inputType: 'radioButton',
    radioButtonTitle: 'スタッフポジション',
    inputName: 'staffPositions',
    initialValue: ['0', '1'],
    style: {
      labelStyle: { width: '232px' },
    },
  },
  {
    inputName: ['staffPositions1', 'staffPositions2', 'staffPositions3'],
    inputType: 'checkBox',
    mode: 'survey',
    initialValue: ['0', '1', '2'],
    label: ['スタッフ', 'アシスタントディレクター', 'ディレクター'],
    defaultChecked: [false, false, false],
  },
  {
    inputType: 'radioButton',
    radioButtonTitle: '居住都道府県',
    inputName: 'prefFlag',
    initialValue: ['0', '1'],
    style: {
      areaStyle: { lineHeight: '44px' },
      labelStyle: { width: '232px' },
    },
  },
  {
    inputType: 'multiSelect',
    inputName: 'prefList',
    initialValue: Object.keys(pref)?.sort()?.map(key => key),
    selectBoxOptions: Object.keys(pref)?.sort()?.map(key => pref[key]),
    placeholder: '都道府県を選択してください',
    style: {
      areaStyle: { width: '100%', maxWidth: '287px' },
    },
  },
  {
    inputType: 'textBox',
    inputName: 'note',
    label: '備考',
    placeholder: '入力してください',
    style: {
      areaStyle: { width: '100%', maxWidth: '1018px' },
      labelStyle: { width: '232px' },
    },
  },
];

export default Inputs;
