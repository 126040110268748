import { memo } from 'react';
import { useWindowSize } from 'react-use';
import { Switch } from 'antd';
import classNames from 'classnames';
import './style.scss';

const CommonSwitch = memo(
  ({ label, theme = 'green', marginRight = 20, checked, onChange, toggleSize, marginNone, padding }) => {
    const { width } = useWindowSize();

    return (
      <span
        style={{ marginRight: marginNone ? 0 : marginRight }}
        className={classNames('common_switch', {
          [`switch-${theme}`]: true,
        })}
      >
        {label && <span className="common_switch--label" style={{ paddingRight: padding }}>{label}</span>}
        <Switch
          checked={checked}
          onChange={onChange}
          defaultChecked={true}
          // size={'default'}
          size={toggleSize ? toggleSize : width <= 500 ? 'small' : 'default'}
        />
      </span>
    );
  }
);

export default CommonSwitch;
