import React from 'react';
import { DualAxes } from '@ant-design/plots';
import { memo } from 'react';

const ProfitGraph = memo(
  ({ graphDataX, graphDataY, isSp, maxCountX, maxCountY }) => {
    const labelName = [
      '予算(当年)',
      '売上(当年)',
      '売上(前年)',
      '当年累計売上',
      '前年累計売上',
    ];
    const labelColor = ['#505050', '#EC5E3A', '#FDC27A', '#3E97EB', '#DBEDFF'];

    const config = {
      legend: false,
      autoFit: !isSp,
      width: 1000,
      padding: 'auto',
      data: [graphDataY, graphDataX],
      xField: 'yearMonth',
      yField: ['count', 'value'],
      tooltip: {
        //フォーカスした時に表示される詳細データの設定
        customItems: item => {
          let newAry = [];
          item.forEach(obj => {
            if (obj.name === '予算(当年)') {
              newAry[0] = {
                ...obj,
                value: `${Number(obj.value).toLocaleString()}円`,
                color: labelColor[0],
              };
            } else if (obj.name === '売上(当年)') {
              newAry[1] = {
                ...obj,
                value: `${Number(obj.value).toLocaleString()}円`,
                color: labelColor[1],
              };
            } else if (obj.name === '売上(前年)') {
              newAry[2] = {
                ...obj,
                value: `${Number(obj.value).toLocaleString()}円`,
                color: labelColor[2],
              };
            } else if (obj.name === '当年累計売上') {
              newAry[3] = {
                ...obj,
                value: `${Number(obj.value).toLocaleString()}円`,
                color: labelColor[3],
              };
            } else if (obj.name === '前年累計売上') {
              newAry[4] = {
                ...obj,
                value: `${Number(obj.value).toLocaleString()}円`,
                color: labelColor[4],
              };
            }
          });
          return newAry;
        },
        domStyles: {
          'g2-tooltip': { width: '170px' },
          'g2-tooltip-list-item': { width: '100%', fontSize: '11px' },
        },
        position: 'top',
      },
      yAxis: {
        count: {
          //折れ線グラフの設定
          tickCount: 10,
          min: 0,
          max:
            Math.max(maxCountX, maxCountY) === 0
              ? 100000
              : Math.max(maxCountX, maxCountY),
          label: {
            formatter: (text, item, i) => {
              if ((i & 1) === 0) {
                return (Number(text) / 1000).toLocaleString();
              } else {
                return;
              }
            },
            offset: 49,
            style: {
              fill: '#242424',
              fontWeight: 'normal',
              fontFamily: 'Noto Sans CJK JP',
              textAlign: 'left',
              fontSize: isSp ? 12 : 14,
            },
          },
          grid: {
            line: {
              style: {
                stroke: '#F3F3F3',
              },
            },
          },
        },
        value: {
          //棒グラフの設定
          tickCount: 0,
          min: 0,
          max:
            Math.max(maxCountX, maxCountY) === 0
              ? 100000
              : Math.max(maxCountX, maxCountY),
          count: {},
        },
      },
      xAxis: {
        top: true,
        line: {
          style: {
            stroke: '#242424',
            lineWidth: 2,
          },
        },
        label: {
          style: {
            fill: '#242424',
            fontWeight: 'normal',
            fontFamily: 'Noto Sans CJK JP',
          },
        },
      },
      geometryOptions: [
        {
          geometry: 'line',
          // 折れ線の数
          seriesField: 'name',
          color: ({ name }) => {
            switch (name) {
              case '予算(当年)':
                return '#505050';
              case '売上(当年)':
                return '#EC5E3A';
              case '売上(前年)':
                return '#FDC27A';
            }
          },
          // 折れ線の点
          point: {
            size: 5,
          },
        },
        {
          geometry: 'column',
          isGroup: true,
          seriesField: 'type',
          // グループ棒間の隙間
          marginRatio: 0,
          // 棒width
          columnWidthRatio: 0.6,
          color: ({ type }) => {
            switch (type) {
              case '当年累計売上':
                return '#3E97EB';
              case '前年累計売上':
                return '#DBEDFF';
            }
          },
        },
      ],
    };

    return isSp ? (
      <div className={'analyze_trend--graph_area'}>
        {/*右側のラベル部分*/}
        <div
          className={'analyze_trend--graph_label_area'}
          style={{ width: '100%' }}
        >
          {labelName.map((name, idx) => {
            return idx < 3 ? (
              <div
                className={'analyze_trend--label'}
                key={`analyze_trend${idx}`}
                style={{ width: '29%' }}
              >
                <div className={'analyze_trend--mark_unity'}>
                  <div
                    className={'analyze_trend--mark_circle'}
                    style={{ backgroundColor: labelColor[idx] }}
                  />
                  <div
                    className={'analyze_trend--mark_line'}
                    style={{ backgroundColor: labelColor[idx] }}
                  />
                </div>
                <span>{name}</span>
              </div>
            ) : (
              <div
                className={'analyze_trend--label'}
                key={`analyze_trend${idx}`}
                style={{ width: '45%' }}
              >
                <div style={{ backgroundColor: labelColor[idx] }} />
                <span>{name}</span>
              </div>
            );
          })}
        </div>
        <div className={'analyze_trend--graph_unit'}>
          <span>金額(千円)</span>
        </div>

        <div className={'analyze_trend--graph_display'}>
          <div className={'analyze_trend--graph'}>
            <DualAxes {...config} />
          </div>
        </div>
      </div>
    ) : (
      <div className={'analyze_trend--graph_area'}>
        <div className={'analyze_trend--graph_unit'}>
          <span>金額(千円)</span>
        </div>

        <div className={'analyze_trend--graph_display'}>
          <div className={'analyze_trend--graph'}>
            <DualAxes {...config} />
          </div>

          {/*右側のラベル部分*/}
          <div
            className={'analyze_trend--graph_label_area'}
            style={{ width: '144px' }}
          >
            {labelName.map((name, idx) => {
              return idx < 3 ? (
                <div
                  className={'analyze_trend--label'}
                  key={`analyze_trend${idx}`}
                  style={{ width: '144px' }}
                >
                  <div className={'analyze_trend--mark_unity'}>
                    <div
                      className={'analyze_trend--mark_circle'}
                      style={{ backgroundColor: labelColor[idx] }}
                    />
                    <div
                      className={'analyze_trend--mark_line'}
                      style={{ backgroundColor: labelColor[idx] }}
                    />
                  </div>
                  <span>{name}</span>
                </div>
              ) : (
                <div
                  className={'analyze_trend--label'}
                  key={`analyze_trend${idx}`}
                  style={{ width: '144px' }}
                >
                  <div style={{ backgroundColor: labelColor[idx] }} />
                  <span>{name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

export default ProfitGraph;
