import Icons from '../../constants/icons';
import './style.scss';

const EmptyLayout = ({ onClick }) => {
  return (
    <div className="performance_table--empty_layout" onClick={onClick}>
      <img src={Icons.icon.tablePlus} />
      <span>クリックして表示する</span>
      <span>時間帯と列数を選択してください。</span>
    </div>
  );
};

export default EmptyLayout;
