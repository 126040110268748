import { createSlice } from '@reduxjs/toolkit';

export const loginTypeSlice = createSlice({
  name: 'loginType',
  initialState: {
    // type: 'backoffice',
    type: window.location.hostname.match(/backoffice/) ? 'backoffice' : 'staff'
  },
  reducers: {
    setLoginType: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const { setLoginType } = loginTypeSlice.actions;

export default loginTypeSlice.reducer;
