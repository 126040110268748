import Yup from "../../../utils/yupUtil";

export const formikUtils = {
  initialValues: {
    staffId: '',
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    mailAddress: '',
    year: null,
    month: null,
    day: null,
    gender: 1,
    post1: '',
    post2: '',
    prefCode: null,
    cityName: '',
    streetName: '',
    buildingName: '',
    dayTel1: '',
    dayTel2: '',
    dayTel3: '',
    homeTel1: '',
    homeTel2: '',
    homeTel3: '',
    lineId: '',
    taxClassification: '',
    dependents: '1',
    nearestStations: [],
    height: null,
    weight: '',
    shoeSize: '',
    wearSize: null,
    holdingItems: [],
    certifications: [],
    profession: 1,
    schoolName: '',
    admissionYear: '',
    payMethod: 0,
    bankName: null,
    bankCode: '',
    bankBranchName: null,
    bankBranchCode: '',
    bankAccountFlag: '',
    bankAccountNumber: '',
    bankAccountName: '',
  },
  pageGroups: {
    1: [
      'lastName',
      'firstName',
      'lastNameKana',
      'firstNameKana',
      'mailAddress',
      'year',
      'month',
      'day',
      'gender',
      'post1',
      'post2',
      'prefCode',
      'cityName',
      'streetName',
      'buildingName',
      'dayTel1',
      'dayTel2',
      'dayTel3',
      'homeTel1',
      'homeTel2',
      'homeTel3',
      'lineId',
    ],
    2: [
      'nearestStations',
      'height',
      'weight',
      'shoeSize',
      'wearSize',
      'holdingItems',
    ],
    3: [
      'certifications',
      'profession',
      'schoolName',
      'admissionYear',
      'payMethod',
      'bankName',
      'bankCode',
      'bankBranchName',
      'bankBranchCode',
      'bankAccountFlag',
      'bankAccountNumber',
      'bankAccountName',
    ],
  },
};
