import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import {
  staffMatterDetailInit,
  updateAssignStaffDetail,
  updateStaffMatterReport,
  fetchMatterDetail,
} from '../../../redux/slice/staffMatter';
import { checkIsSp, handlerFormikSubmit } from '../../../utils/fnUtil';
import { globalActions } from '../../../redux/slice/global';
import { transform, toInteger, isNil } from 'lodash';
import ReportDetail from './tabs/reportDetail';
import ImplementationDetail from './tabs/implementationDetail';
import AttendanceReport from './tabs/attendanceReport';
import VariousApplication from './tabs/variousApplication';
import Button from '../../../components/button';
import Options from '../../../constants/options';
import Icons from '../../../constants/icons';
import Yup from '../../../utils/yupUtil';
import moment from 'moment';
import './style.scss';
import { apiUrl } from '../../../api/apiUrl';
import { fetchRequest } from '../../../api/fetch';

const RecruitMatterDetail = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const {
    assignDetail,
    assignStaffDetail,
    matterDetail,
    reportDetail,
    toggle,
    purchaseMotiveList,
  } = useSelector(state => state.staffMatter);

  const { dateInfo, shift } = assignDetail ?? {};

  const [reportInput, setReportInput] = useState(false);

  const [reportModalVisible, setReportModalVisible] = useState(false);

  const { activeTab, isOpenSideMenu } = useSelector(state => state.global);

  const { staffId, corporateId } = useSelector(state => state.account);

  const dispatch = useDispatch();

  const tabErrorGroup = {
    attendanceReport: ['overtime'],
    variousApplication: ['advance'],
  };

  const [venueDetail, setVenueDetail] = useState({});

  const formik = useFormik({
    initialValues: {
      advance: Options.codes.advanceCategory.map(c => ({
        categoryFlag: c.value,
      })),
    },
    validationSchema: Yup.object({
      overtime: Yup.string().nullable().integerString('[勤怠報告]:残業時間'),
      advance: Yup.array().of(
        Yup.object({
          totalAmount: Yup.string()
            .nullable()
            .integerString(`[各種申請]立替金合計`),
          ...transform([1, 2, 3, 4, 5], (result, i) => {
            result[`advanceAmount${i}`] = Yup.string()
              .nullable()
              .integerString(`[各種申請]立替金${i}`);
          }),
        })
      ),
    }),
    onSubmit: async values => {
      const nowDetail = await fetchRequest(
        apiUrl.matterDate.assignDetail,
        {
          matterNumber: matterDetail.matterNumber,
          matterDateNumber: dateInfo.matterDateNumber,
          shiftId: shift.shiftId,
          assignAccountFlag: shift.assignAccountFlag,
          assignId: staffId,
          corporateId: corporateId,
        },
        'GET'
      );

      if (values?.staffGettingUpPlanDatetimeInput) {
        values.staffGettingUpPlanDatetime = `${values?.matterDate} ${
          values?.staffGettingUpPlanDatetimeInput + ':00'
        }`;
      } else {
        values.staffGettingUpPlanDatetime = null;
      }
      if (values?.staffDeparturePlanDatetimeInput) {
        values.staffDeparturePlanDatetime = `${values?.matterDate} ${
          values?.staffDeparturePlanDatetimeInput + ':00'
        }`;
      } else {
        values.staffDeparturePlanDatetime = null;
      }

      if (
        nowDetail?.item?.shift
          ?.find(o => o?.shiftId === shift.shiftId)
          ?.shiftAssign?.find(o => o?.assignId === staffId)
          ?.staffPreviousDayDatetime ||
        nowDetail?.item?.shift
          ?.find(o => o?.shiftId === shift.shiftId)
          ?.shiftAssign?.find(o => o?.assignId === staffId)
          ?.managerPreviousDayDatetime
      ) {
        values.staffDeparturePlanDatetime = nowDetail?.item?.shift
          ?.find(o => o?.shiftId === shift.shiftId)
          ?.shiftAssign?.find(
            o => o?.assignId === staffId
          )?.staffDeparturePlanDatetime;

        values.staffGettingUpPlanDatetime = nowDetail?.item?.shift
          ?.find(o => o?.shiftId === shift.shiftId)
          ?.shiftAssign?.find(
            o => o?.assignId === staffId
          )?.staffGettingUpPlanDatetime;
      }

      dispatch(
        updateAssignStaffDetail({
          ...values,
          ...state,
          ...transform(
            [
              'staffWeekDatetime',
              'staffPreviousDayDatetime',
              'staffGettingUpDatetime',
              'staffDepartureDatetime',
              'staffArriveDatetime',
              'staffEndDatetime',
            ],
            (result, datetime) => {
              const time = values[datetime.replace('Datetime', 'Time')];
              result[datetime] =
                time &&
                `${(assignStaffDetail?.[datetime]
                  ? moment(assignStaffDetail[datetime])
                  : moment()
                ).format('YYYY/M/D')} ${time}:00`;
            }
          ),
          advance: values.advance
            ? values.advance.map((o, i) => {
                return { ...o, categoryFlag: i === 5 ? 9 : i };
              })
            : [],
        })
      )
        .unwrap()
        .then(() => {
          setReportModalVisible(false);
        });
    },
  });

  const reportFormik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      info: Yup.object({
        ...transform(Options.codes.reportInfo, (result, i) => {
          result[i.item] = Yup.string()
            .nullable()
            .label(`[実績情報]${i.label}`)
            .when(
              `staff${i.item.charAt(0).toUpperCase()}${i.item.slice(1)}Flag`,
              {
                is: 1,
                then: Yup.string()
                  .nullable()
                  .required()
                  .max(i.item === 'eventAbout' ? 9999999 : 100),
              }
            );
        }),
      }),
      folder: Yup.array().of(
        Yup.object({
          imageExist: Yup.string()
            .nullable()
            .test(
              'imageExist',
              '[報告画像]画像:1枚以上登録必須です',
              (_, context) =>
                context.parent.staffFlag === 0 ||
                Object.keys(context.parent)
                  .filter(key => /folderImage\d{1,2}$/.test(key))
                  .some(key => context.parent[key])
            ),
        })
      ),
      staffVoice: Yup.array().of(
        Yup.object({
          inputText: Yup.string()
            .nullable()
            .test(
              'inputText',
              (
                v,
                { parent: { staffVoiceFlag, inputTitle }, path, createError }
              ) =>
                staffVoiceFlag === 0 ||
                !isNil(v) ||
                createError({
                  message: `[スタッフの声]${inputTitle ?? ''}:必須項目です。`,
                  path,
                })
            ),
        })
      ),
      guestVoice: Yup.object({
        // motive: Yup.array()
        //   .nullable()
        //   .when('staffMotiveFlag', {
        //     is: 1,
        //     then: Yup.array().nullable().arrayRequired('[お客様の声]購入動機'),
        //   }),
        // inflowRoute: Yup.string()
        //   .nullable()
        //   .label('[お客様の声]流入経路')
        //   .when('staffInflowRouteFlag', {
        //     is: 1,
        //     then: Yup.string().nullable(),
        //   }),
        // continueFlag: Yup.string()
        //   .nullable()
        //   .label('[お客様の声]継続購入可否')
        //   .when('staffContinueFlag', {
        //     is: 1,
        //     then: Yup.string().nullable().required(),
        //   }),
        voiceTotalNumber: Yup.string()
          .nullable()
          .label(`[お客様の声]:総人数`)
          .when('staffTotalFlag', {
            is: 1,
            then: Yup.string()
              .nullable()
              .required()
              .integerString(`[お客様の声]:総人数`),
          }),
        staffInflowRouteTotal: Yup.number()
          .nullable()
          .test(
            'manWomanRateTotal',
            `[お客様の声]流入経路:合計が100％ではありません。`,
            (_, context) => {
              const {
                dmRate,
                passingRate,
                snsRate,
                dailyUseRate,
                otherRate,
                staffInflowRouteFlag,
              } = context.from[0].value;
              return (
                staffInflowRouteFlag === 0 ||
                toInteger(dmRate) +
                  toInteger(passingRate) +
                  toInteger(snsRate) +
                  toInteger(dailyUseRate) +
                  toInteger(otherRate) ===
                  100
              );
            }
          ),
        staffMotiveRateTotal: Yup.number()
          .nullable()
          .test(
            'manWomanRateTotal',
            `[お客様の声]購入動機:合計が100％ではありません。`,
            (_, context) => {
              const { staffMotiveFlag } = context.from[0].value;
              const totalRate = purchaseMotiveList.reduce((sum, obj) => {
                const key = `staffMotiveId_${obj.namingId}`;
                return sum + (context.from[0].value?.[key] ?? 0);
              }, 0);
              return staffMotiveFlag === 0 || totalRate === 100;
            }
          ),
        staffContinueRouteTotal: Yup.number()
          .nullable()
          .test(
            'manWomanRateTotal',
            `[お客様の声]継続購入可否:合計が100％ではありません。`,
            (_, context) => {
              const { firstRate, twiceRate, staffContinueFlag } =
                context.from[0].value;
              return (
                staffContinueFlag === 0 ||
                toInteger(firstRate) + toInteger(twiceRate) === 100
              );
            }
          ),
        voiceInput: Yup.array().of(
          Yup.object({
            inputText: Yup.string()
              .nullable()
              .test(
                'inputText',
                (
                  v,
                  { parent: { staffVoiceFlag, inputTitle }, path, createError }
                ) =>
                  staffVoiceFlag === 0 ||
                  !isNil(v) ||
                  createError({
                    message: `[お客様の声]${inputTitle ?? ''}:必須項目です。`,
                    path,
                  })
              ),
          })
        ),
      }),
      guestAttribute: Yup.object({
        totalNumber: Yup.string()
          .nullable()
          .label(`[お客様の属性]:総人数`)
          .when('staffTotalFlag', {
            is: 1,
            then: Yup.string()
              .nullable()
              .required()
              .integerString(`[お客様の属性]:総人数`),
          }),
        ...transform(
          [
            {
              item: 'manRate',
              label: '男性率',
            },
            {
              item: 'womanRate',
              label: '女性率',
            },
          ],
          (result, i) => {
            result[i.item] = Yup.string()
              .nullable()
              .label(`[お客様の属性]${i.label}`)
              .when('staffGenderRatioFlag', {
                is: 1,
                then: Yup.string().nullable().required(),
              });
          }
        ),
        ...transform(
          [
            {
              item: 'manRate',
              label: '男性率',
            },
            {
              item: 'womanRate',
              label: '女性率',
            },
          ],
          (result, i) => {
            result[i.item] = Yup.string()
              .nullable()
              .label(`[お客様の属性]${i.label}`)
              .when('staffGenderRatioFlag', {
                is: 1,
                then: Yup.string().nullable().required(),
              });
          }
        ),
        ...transform(Options.codes.guestGroup, (result, i) => {
          result[i.item] = Yup.string()
            .nullable()
            .label(`[お客様の属性]${i.label}率`)
            .when('staffGuestFlag', {
              is: 1,
              then: Yup.string().nullable().required(),
            });
        }),
        ...transform(Options.codes.ageGroup, (result, i) => {
          result[i.item] = Yup.string()
            .nullable()
            .label(`[お客様の属性]${i.label}率`)
            .when('staffAgeGroupFlag', {
              is: 1,
              then: Yup.string().nullable().required(),
            });
        }),
        manWomanRateTotal: Yup.number()
          .nullable()
          .test(
            'manWomanRateTotal',
            `[お客様の属性]男女比:合計で100％を超えてます。`,
            (_, context) => {
              const { manRate, womanRate, staffGenderRatioFlag } =
                context.from[0].value;
              return (
                staffGenderRatioFlag === 0 ||
                toInteger(manRate) + toInteger(womanRate) <= 100
              );
            }
          ),
        segmentRateTotal: Yup.number()
          .nullable()
          .test(
            'segmentRateTotal',
            `[お客様の属性]客層比:合計で100％を超えてます。`,
            (_, context) => {
              const {
                singleRate,
                multipleRate,
                withChildrenRate,
                staffGuestFlag,
              } = context.from[0].value;
              return (
                staffGuestFlag === 0 ||
                [singleRate, multipleRate, withChildrenRate]
                  .map(toInteger)
                  .reduce((pre, next) => pre + next, 0) <= 100
              );
            }
          ),
        ageRateTotal: Yup.number()
          .nullable()
          .test(
            'ageRateTotal',
            `[お客様の属性]年代別客層:合計で100％を超えてます。`,
            (_, context) => {
              const {
                staffAgeGroupFlag,
                underTeensRate,
                teensRate,
                twentiesRate,
                thirtiesRate,
                fortiesRate,
                fiftiesRate,
                sixtiesRate,
                overSeventiesRate,
              } = context.from[0].value;
              return (
                staffAgeGroupFlag === 0 ||
                [
                  underTeensRate,
                  teensRate,
                  twentiesRate,
                  thirtiesRate,
                  fortiesRate,
                  fiftiesRate,
                  sixtiesRate,
                  overSeventiesRate,
                ]
                  .map(toInteger)
                  .reduce((pre, next) => pre + next, 0) <= 100
              );
            }
          ),
      }),
      reportPage: Yup.array().of(
        Yup.object({
          layout: Yup.array()
            .nullable()
            .when('staffFlag', {
              is: 1,
              then: Yup.array().of(
                Yup.object({
                  table: Yup.array().of(
                    Yup.object({
                      columnTotal: Yup.string()
                        .nullable()
                        .label(`[実績表]合計`)
                        .integerString(`[実績表]合計`)
                        .when('totalDisplayFlag', {
                          is: 1,
                          then: Yup.string().nullable().required(),
                        }),
                      ...transform([1, 2, 3], (result, i) => {
                        [...Array(20)].forEach(
                          (_, j) =>
                            (result[`column${i}Row${j + 1}`] = Yup.string()
                              .nullable()
                              .label(`[実績表]行${j + 1}列${i}`)
                              .integerString(`[実績表]行${j + 1}列${i}`)
                              .when(['tableColumns', 'tableRows'], {
                                is: (col, row) => i <= col && j + 1 <= row,
                                then: Yup.string().nullable().required(),
                              }))
                        );
                        result[`column${i}Total`] = Yup.string()
                          .nullable()
                          .label(`[実績表]列${i}合計`)
                          .integerString(`[実績表]列${i}合計`)
                          .when('tableColumns', {
                            is: col => i <= col,
                            then: Yup.string().nullable().required(),
                          });
                      }),
                    })
                  ),
                })
              ),
            }),
        })
      ),
    }),
    onSubmit: values => {
      const { guestVoice, ...rest } = values;

      let motive = '';
      Object.keys(guestVoice)
        .filter(key => key.startsWith('staffMotiveId_'))
        .forEach(key => {
          const id = key.match(/\d+$/)[0];
          const value = guestVoice[key];
          motive += `${id}:${value ?? 0},`;
        });
      motive = motive.slice(0, -1);

      const inflowRoute = () => {
        const rates = [
          { rate: guestVoice?.dmRate, prefix: '0' },
          { rate: guestVoice?.passingRate, prefix: '1' },
          { rate: guestVoice?.snsRate, prefix: '2' },
          { rate: guestVoice?.dailyUseRate, prefix: '3' },
          { rate: guestVoice?.otherRate, prefix: '4' },
        ];

        const inflowRoute = rates
          .filter(({ rate }) => rate !== undefined)
          .map(({ rate, prefix }) => `${prefix}:${rate ?? 0}`)
          .join(',');

        return !inflowRoute ? null : inflowRoute;
      };

      const repeating = () => {
        const rates = [
          { rate: guestVoice?.firstRate, prefix: '0' },
          { rate: guestVoice?.twiceRate, prefix: '1' },
        ];

        const repeating = rates
          .filter(({ rate }) => rate !== undefined)
          .map(({ rate, prefix }) => `${prefix}:${rate ?? 0}`)
          .join(',');

        return !repeating ? null : repeating;
      };

      dispatch(
        updateStaffMatterReport({
          ...rest,
          guestVoice: {
            ...guestVoice,
            inflowRoute: inflowRoute(),
            motive: !motive ? null : motive,
            repeating: repeating(),
          },
        })
      )
        .unwrap()
        .then(() => setReportInput(false))
        .catch(e => {
          if (e.code === 'C10010') {
            dispatch(
              globalActions.showSelectModal({
                title: '現在編集できません',
                message:
                  '更新に失敗しました。\n他のユーザーによって編集されたため入力内容を保存できませんでした。',
                buttons: [
                  {
                    btnText: '戻る',
                    style: Options.buttonStyles.back,
                  },
                ],
              })
            );
            setReportInput(false);
          }
        });
    },
  });

  useEffect(() => {
    state && dispatch(staffMatterDetailInit({ ...state, corporateId }));
  }, [state, toggle, corporateId]);

  useEffect(() => {
    state?.activeTab &&
      dispatch(globalActions.changeActiveTab(state.activeTab));
  }, [state]);

  useEffect(() => {
    assignStaffDetail &&
      formik.setValues({
        ...formik.values,
        advance: formik.values.advance
          ? formik.values.advance.map((o, i) => {
              return { ...o, categoryFlag: i === 5 ? 9 : i };
            })
          : [],
        ...assignStaffDetail,
        satisfactionLevel: assignStaffDetail?.satisfactionLevel ?? 5,
        ...transform(
          [
            'staffWeek',
            'staffPreviousDay',
            'staffGettingUp',
            'staffDeparture',
            'staffArrive',
            'staffEnd',
          ],
          (result, item) => {
            result[`${item}Time`] = assignStaffDetail?.[`${item}Datetime`]
              ? moment(assignStaffDetail?.[`${item}Datetime`]).format('HH:mm')
              : null;
          }
        ),
      });
  }, [assignStaffDetail]);

  useEffect(() => {
    reportDetail &&
      reportFormik.setValues({
        ...reportDetail,
      });
  }, [reportDetail]);

  const onUpdate = () => handlerFormikSubmit(formik, tabErrorGroup);

  const tabs = [
    {
      key: 'implementationDetail',
      label: '実施詳細',
      children: <ImplementationDetail formik={formik} />,
    },
    {
      key: 'attendanceReport',
      label: '勤怠報告',
      children: (
        <AttendanceReport
          formik={formik}
          onUpdate={onUpdate}
          reportModalVisible={reportModalVisible}
          setReportModalVisible={setReportModalVisible}
        />
      ),
    },
    {
      key: 'variousApplication',
      label: '各種申請',
      children: <VariousApplication formik={formik} />,
    },
  ];

  const payBase =
    `${shift?.payBaseFlag}` === '0'
      ? shift?.hourlyPayBase?.toLocaleString()
      : shift?.dailyPayBase?.toLocaleString(); 

  return (
    <>
      <div className={'recruit_matter_detail--upper_area'}>
        <div className={'recruit_matter_detail--headline'}>
          <div className={'recruit_matter_detail--title'}>
            {matterDetail?.matterName}
          </div>
          {[
            {
              label: '実施日',
              content: dateInfo?.matterDate,
            },
            {
              label: '開催地',
              content: () => (
                <p style={{ margin: '0' }}>
                  <span style={{ display: 'block' }}>
                    {matterDetail?.venueName ?? ''}
                  </span>
                  <span style={{ display: 'block' }}>
                    {matterDetail?.venueDetail?.post ?? ''}　
                    {matterDetail?.venueDetail?.address ?? ''}
                  </span>
                  <span style={{ display: 'block' }}>
                    {matterDetail?.venueDetail?.tel ?? ''}
                  </span>
                  <span style={{ display: 'block' }}>
                    {matterDetail?.venueDetail?.fax ?? ''}
                  </span>
                </p>
              ),
            },
            {
              label: '実施場所',
              content: dateInfo?.placeName,
            },
            {
              label: '勤務時間',
              content: `${
                shift?.workStartDatetime || shift?.workEndDatetime
                  ? `${shift?.workStartDatetime ?? ''}~${
                      shift?.workEndDatetime ?? ''
                    }`
                  : ''
              } ${shift?.restMinute ? `(休憩 ${shift?.restMinute}分)` : ''}`,
            },
            {
              label: 'シフト名',
              content: shift?.shiftName ?? '',
            },
            {
              label: '基本給',
              content: payBase ? `${payBase}円` : '-',
            },
            {
              label: '手当金',
              content: shift
                ?.shiftAssign?.find(o => o?.assignId === staffId)
                ?.allowanceAmount?.toLocaleString() ?? 0 + '円',
            },
            {
              label: '手当理由',
              content: shift
                ?.shiftAssign?.find(o => o?.assignId === staffId)
                ?.allowanceReason ?? '',
            },
            {
              label: '開催地最寄駅',
              content: dateInfo?.venueNearestStation ?? '',
            },
            {
              label: '集合場所',
              content: assignStaffDetail?.meetingPlace ?? '',
            },
            {
              label: 'ピックアップ担当者',
              content: shift?.pickup ?? '',
            },
          ].map(l => (
            <div
              className={'recruit_matter_detail--contents'}
              key={l.label}
              style={{ alignItems: 'flex-start' }}
            >
              <label style={{ flexShrink: '0' }}>{l.label}</label>
              <span>{l.label === '開催地' ? l.content() : l.content}</span>
            </div>
          ))}
        </div>
        <div className={'recruit_matter_detail--button_area'}>
          {!reportInput && assignStaffDetail?.reportInputFlag === 1 && (
            <Button
              text={'報告書入力'}
              icon={Icons.icon.penBl}
              style={{
                ...Options.buttonStyles.reportInputButton,
                height: checkIsSp() ? '40px' : '44px',
              }}
              onClick={async () => {
                const matterDetail = await dispatch(
                  fetchMatterDetail({
                    matterNumber: state.matterNumber,
                  })
                ).unwrap();

                const { reportUpdatableId, reportUpdatableFlag } =
                  matterDetail.item;

                reportUpdatableId === null ||
                (reportUpdatableId === staffId && reportUpdatableFlag === 1)
                  ? setReportInput(true)
                  : dispatch(
                      globalActions.showSelectModal({
                        title:
                          '他のユーザーが編集中です。編集内容が失われる可能性がありますが編集しますか？',
                        buttons: [
                          {
                            btnText: '閉じる',
                            style: Options.buttonStyles.back,
                          },
                          {
                            btnText: '編集する',
                            btnAction: () => setReportInput(true),
                            style: Options.buttonStyles.submit,
                          },
                        ],
                      })
                    );
              }}
              disabled={!matterDetail?.reportExist}
            />
          )}
        </div>
      </div>

      <div className="recruit_matter_detail--content_area">
        {reportInput ? (
          <Tabs activeKey={'reportDetail'}>
            <Tabs.TabPane tab={'報告書入力'} key={'reportDetail'}>
              <ReportDetail formik={reportFormik} />
            </Tabs.TabPane>
          </Tabs>
        ) : (
          <Tabs
            activeKey={activeTab ? activeTab : tabs[0].key}
            onChange={tab => dispatch(globalActions.changeActiveTab(tab))}
          >
            {tabs.map(tab => (
              <Tabs.TabPane tab={tab.label} key={tab.key}>
                {tab.children}
              </Tabs.TabPane>
            ))}
          </Tabs>
        )}
      </div>
      {['attendanceReport', 'variousApplication', 'reportDetail'].includes(
        activeTab
      ) || reportInput ? (
        <div
          className={'recruit_matter_detail--bottom_btn_area'}
          style={{
            width: checkIsSp()
              ? '100%'
              : isOpenSideMenu
              ? 'calc(100% - 200px)'
              : 'calc(100% - 56px)',
            height: checkIsSp() ? '88px' : '92px',
          }}
        >
          {reportInput && (
            <div className="update-tip report_tab--for_sp_label">
              更新完了まで時間がかかる場合があります。
            </div>
          )}
          <Button
            text={'報告内容を確定する'}
            style={{
              ...Options.buttonStyles.submit,
              height: checkIsSp() ? '40px' : '44px',
              fontSize: checkIsSp() ? '12px' : '14px',
            }}
            onClick={
              reportInput ? () => handlerFormikSubmit(reportFormik) : onUpdate
            }
          />
        </div>
      ) : (
        <p className={'staff_pay_detail--copy_right'}>
          ©︎ GOODWAVE All right reserved.
        </p>
      )}
    </>
  );
};

export default RecruitMatterDetail;
