import { checkIsSp } from '../../../utils/fnUtil';

const isSp = checkIsSp();

const incomeTaxInputs = [
  {
    inputType: 'input',
    label: '月額課税対象額',
    labelRequired: true,
    inputName: 'incomeAmountFrom',
    initialValue: '',
    placeholder: '0',
    unit: '円',
    style: {
      areaStyle: { width: isSp ? '45%' : '556px' },
      labelStyle: { width: '232px' },
    },
  },
  {
    inputType: 'input',
    inputName: 'incomeAmountTo',
    initialValue: '',
    placeholder: '0',
    unit: '円',
    style: {
      areaStyle: { width: isSp ? '45%' : '300px' },
      labelStyle: isSp ? { bottom: '10px', position: 'relative' } : {},
    },
  },
  {
    inputType: 'radioButton',
    radioButtonTitle: '(乙)計算方法',
    labelRequired: true,
    radioButtonLabelAndChecked: [{ label: '課税率', checked: true }],
    inputName: 'calculationTaxFlag',
    initialValue: [0],
    style: {
      areaStyle: { width: 'auto' },
      labelStyle: { width: '232px' },
    },
  },
  {
    inputType: 'input',
    inputName: 'taxRate',
    initialValue: '',
    placeholder: '0',
    unit: '%',
    style: {
      areaStyle: { width: isSp ? '100%' : '120px' },
    },
  },
  {
    inputType: 'radioButton',
    radioButtonLabelAndChecked: [{ label: '課税額', checked: false }],
    inputName: 'calculationTaxFlag',
    initialValue: [1],
    style: {
      areaStyle: { width: 'auto' },
      labelStyle: { width: '232px' },
    },
  },
  {
    inputType: 'input',
    inputName: 'taxAmount',
    initialValue: '',
    placeholder: '0',
    unit: '円',
    style: {
      areaStyle: { width: isSp ? '100%' : '120px' },
    },
  },
  {
    inputType: 'input',
    inputName: 'kouTax',
    initialValue: '',
    placeholder: '0',
    unit: '円',
    style: {
      areaStyle: { width: '90%', minWidth: '75px' },
    },
  },
];

export default incomeTaxInputs;
